import { ReactNode, useLayoutEffect, useState } from 'react'

import styles from '../../styles/auth/AuthLayout.module.scss'
import siarlLogo from '../../assets/images/siarl_logo_sFundo.svg'
import pocurLogo from '../../assets/images/POSEUR_PT2020_FC_Bom.png';
import ReactLoading from 'react-loading'
import { NotificationGroup , Notification} from '@progress/kendo-react-notification'
import { Fade } from "@progress/kendo-react-animation";
import { telaMobile } from '../common/ItemRenderMais'

type Props = {
  children: ReactNode
}

export default function AuthLayout({ children }: Props) {
  const [isMobile, setIsMobile] = useState(false);
  const [showLoader, setShowLoader] = useState<any>();
  const [success, setSuccess] = useState(false);
  const [msg, setMsg] = useState(false);
  const [error, setError] = useState(false);
  const [percentLeft, setPercentLeft] = useState("50%");
  const [errorMsg, setErrorMsg] = useState("Ocorreu um erro ao gravar.");
   const [info, setInfo] = useState(false);
  const [infoMsg, setInfoMsg] = useState("");
  const [msgValue, setMsgValue] = useState("Info");
  addEventListener(
    'message',
    (event: MessageEvent) => {
        if(event.data.type == "showLoader") {
          setShowLoader(true);
        }  
        if(event.data.type == "hideLoader") {
          setShowLoader(false);
        } 
        else if (event.data.type == "sucessMsg") {
          setSuccess(true);
          setError(false);
          if (event?.data?.value) {
            setMsgValue(event.data?.value?.message);
            if (event?.data?.value?.left) {
              setPercentLeft(event?.data?.left);
            }
          }
          setTimeout(() => {
            setSuccess(false);
          }, 5000);
        }
        else if (event.data.type == "hideInformationMsg") {
          setMsg(false);
        }
        else if (event.data.type == "errorMsg") {
          if (event?.data?.value?.message) {
            setErrorMsg(event?.data?.value?.message);
          }
          setError(true);
          setSuccess(false);
          setTimeout(() => {
            setError(false);
          }, 5000);
        }else if (event.data.type == "infoMsg") {
          if (event?.data?.value?.message) {
            setInfoMsg(event?.data?.value?.message);
          }
          setInfo(true);
          setError(false);
          setSuccess(false);
          setTimeout(() => {
            setInfo(false);
          }, 5000);
        }    
    },
    false
);

useLayoutEffect(() => {

  const handleResize = () => {

    if (window.innerWidth <= telaMobile) {
      
      setIsMobile(true);

    }else{

      setIsMobile(false);

    }

  };

  window.addEventListener('resize', handleResize);

  handleResize();

  return () => {
    window.removeEventListener('resize', handleResize);
  };

}, []); 

  return (
    <> <div className={showLoader ? styles.layoutLoader : styles.layout}>
      <div className={styles.imageWrapper} />
      <div className={styles.formWrapper}>
        <img
          src={siarlLogo}
          className={styles.logo}
          alt="Siarl - Sistema de Administração do Recurso Litoral"
        />
        {children}
        <img
          src={pocurLogo}
          className={styles.logoPOCUR}
          alt="POCUR"
        />
      </div>
    </div>
    <NotificationGroup
      style={{ top: "10%", left:(isMobile? (Number(percentLeft.split('%')[0])-5)+'%' : percentLeft), transform: "translateX(-50%)" }}
    >
        <Fade>
          {success && (
            <Notification
              style={{color: "#fff"}}
              type={{ style: "success", icon: true }}
              closable={true}
              onClose={() => setSuccess(false)}
            >
              <span>{msgValue}</span>
            </Notification>
          )}
          {msg && (
            <Notification
              style={{color: "#fff"}}
              type={{ style: "success", icon: true }}
              closable={true}
              onClose={() => setMsg(false)}
            >
              <span>{msgValue}</span>
            </Notification>
          )}
          {error && (
            <Notification
              style={{color: "#fff"}}
              type={{ style: "error", icon: true }}
              closable={true}
              onClose={() => setError(false)}
            >
              <span>{errorMsg}</span>
            </Notification>
          )} {info && (
            <Notification
              style={{color: "#fff"}}
              type={{ style: "info", icon: true }}
              closable={true}
              onClose={() => setInfo(false)}
            >
              <span>{infoMsg}</span>
            </Notification>
          )}
        </Fade>
      </NotificationGroup>
      {showLoader ? <div className="overlay"><ReactLoading className={'spinner-center'} type={'spin'} color={'#1ba49c'} height={'10%'} width={'10%'} /></div> : <></>}
    </>
  )
}
