import { process } from '@progress/kendo-data-query';
import {
  Dispatch,
  MouseEvent as ReactMouseEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Button, DropDownButton } from '@progress/kendo-react-buttons';
import { PagerTargetEvent } from '@progress/kendo-react-data-tools';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Grid, GridCellProps, GridColumn, GridPageChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { format } from 'date-fns';
import React from 'react';
import { CSVLink } from 'react-csv';
import SessionStorageService from '../../services/SessionStorageService';
import { GetUserNameTecnico, b64toBlob, coordenadasPoligono, coordenadasSelected, exportPDFWithMethod, get, handleGenericError, hasPermission, post,setCoordenadasPoligono,setFotografiaObliquaSelected } from '../../services/utils';
import styles from '../../styles/vooDrone/VooDrone.module.scss';
import { ResultadoFotoCommandGridCell } from './ResultadoFotoCommandGridCell';
import { PESQUISAR_VOO_DRONE ,GET_DOCUMENTO_BY_VOOS_DRONE, PESQUISAR_VOO_DRONE_PATH_BY_UUID, DOWNLOAD_RESOURCE_VOO_DRONE_PATH_BY_ID, DOWNLOAD_VIDEO_BY_ID, AUTH_LOCALSTORAGE, API_URL, GET_VOO_DRONE_BY_VOO_DRONE_PATH_ID, GET_REGISTO_VOO_BY_ID, CONSULTAR_REGISTO_VOO, PERMISSION_INSERIR_FO_REGISTO_VOO, CONSULTAR_FO_FOTO, EPSG_GLOBAL, EPSG_MAP, CURRENT_TIMEZONE} from '../../utils/constants';
import { useFotografiaStore } from '../../stores/fotoObliqua';
import MaximizeButton from '../common/MaximizeButton';
import { useCommonStore } from '../../stores/common';
import commonStyles from '../../styles/common/Common.module.scss'
import clsx from 'clsx';
import VisualizadorColunas from '../Generico/VisualizadorColunas ';
import useVisibleColumns, { Key } from '../../hooks/useVIsibleColumns';
import ReactGA from 'react-ga4';
import { ResultadoFotoDocumentoCommandGridCell } from './ResultadoFotoDocumentoCommandGridCell';
import { Dialog } from '@progress/kendo-react-dialogs'
import ImagePreviewV2 from '../viewer/ImagePreviewV2';
import proj4 from 'proj4';
import moment from 'moment';

type Props = {
  onCloseClick?: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
  setShowResultadoFoto: Dispatch<SetStateAction<boolean>>
  setShowRegistarFoto: Dispatch<SetStateAction<boolean>>
}

const options = [
  {
    key: 'ExportarFichasResultados',
    text: 'Exportar fichas resultados',
    icon: <span className={`icon-siarl icon-exportar ${styles.btnImprimir}`}></span>,
  }
]
type PageState = {
  skip: number
  take: number
}
const initialDataState: PageState = { skip: 0, take: 20 }
const SRT_FORMAT= "SRT";
const KML_FORMAT= "KML";

const keyPainelPrincipal:Key = 'fotografiaColunasVisiveis';
const keyPainelDocumentos:Key = 'fotografiaDocumentosColunasVisiveis';
export default function ResultadoFotoForm({
  onCloseClick,
  setShowResultadoFoto,
  setShowRegistarFoto,
}: Props) {
  const [foto, setFoto] = useState([])
  const [documentosFoto, setDocumentosFoto] = useState([])

  const [openFormViewDocumento, setOpenFormViewDocumento] = useState<boolean>(false)
  const [urlDoDocumento, setUrlDoDocumento] = useState("");
  const [idDoDocumento, setIdDoDocumento] = useState("");
  const [dataFotoTitle, setDataFotoTitle] = useState("");
  const [nomeVooTitle, setNomeVooTitle] = useState("");

  const [selected, setSelected] = useState<number>(0)
  const [page, setPage] = useState<PageState>(initialDataState)

  const isCurrentViewMaximized = useCommonStore(state => state.isCurrentViewMaximized)
  const restoreView = useCommonStore(state => state.restoreView)
  

  const selectFotografiaResult = useFotografiaStore(
    (state) => state.selectFotoResult
  )
  const selectFotografiaResultId = useFotografiaStore(
    (state) => state.selectFotoResultId
  )
   const selectFotoPathResult = useFotografiaStore(
        (state) => state.selectFotoResult
    )
  const [pageSizeValue, setPageSizeValue] = useState<
    number | string | undefined
  >()

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/ResultadoFotoAerea" });
  }, []);
  
  const {getVisibleColumns, removeColumnsKeys} = useVisibleColumns();

 
     const handleMessageEvent = useCallback((event: MessageEvent) => {
        if (event.data.type == "resultadoFotoPesquisaChange") {
            SessionStorageService.setItem("resultadoFotoPesquisa", event.data.value);
        }
    }, [])

     useEffect(() => {
        window.addEventListener(
            'message',
            handleMessageEvent,
            false
        )

        return () => {
            window.removeEventListener(
                'message',
                handleMessageEvent,
                false
            )
        }
    }, [handleMessageEvent])

    const consultarRegistoVoo = async (data: any) => await post<any, any>(`${CONSULTAR_REGISTO_VOO}`, data)
    const consultarFotos = async(data: any) => await post<any, any>(CONSULTAR_FO_FOTO, data);

    const initialized = useRef(false)
    useEffect(() => {
        if (!initialized.current) {
          initialized.current = true;            
          if (SessionStorageService.getItem("lastSearchFotografiaAerea")  && !SessionStorageService.getItem("firstCallFotoObliqua")) {
          const objToSearch = SessionStorageService.getItem("lastSearchFotografiaAerea") ?? '';
          consultarRegistoVoo(objToSearch).then((data) => {
            SessionStorageService.setItem("resultadoFotoPesquisa", JSON.stringify(data));
            setResultsFotografiaAerea();
          })
        } 
        else {
          setResultsFotografiaAerea();
      }
    }
      }, [])

    const setResultsFotografiaAerea = async () => {
        const results = JSON.parse(SessionStorageService.getItem('resultadoFotoPesquisa') ?? '')
        setFoto(results.map((fotos: any) => ({
          ...fotos,
          data_registo: fotos?.data_registo ? format(new Date(fotos.data_registo), 'dd/MM/yyyy HH:mm') : undefined,
          data_voo: fotos?.data_voo ? format(new Date(fotos.data_voo), 'dd/MM/yyyy') : undefined,
        })))
        const ids = results.map((x: any) => x.id);
        postMessage({type: "showLoader"}, "*");
        const objToSearch = SessionStorageService.getItem("lastSearchFotografiaAerea") ?? '';
        if (objToSearch) {
          const resultFotos: any = await consultarFotos({
            ...objToSearch,
            flatCoordinatesCriterioGeografico: objToSearch.flatCoordinates,
            id_registo_voos: ids,
          })
          setDocumentosFoto(resultFotos.map((x: any) => ({
            ...x,
            date_time_original: moment.utc(x.date_time_original).tz(CURRENT_TIMEZONE).format("DD/MM/YYYY HH:mm")
          })));
          resultFotos.forEach((documentosLocal: any) => {
            marcaFotosMapa(documentosLocal);
          });
        }
        postMessage({type: "hideLoader"}, "*");
        setCoordenadasPoligono(undefined);
        postMessage({ type: 'hideLoader' }, '*');

   }

   const marcaFotosMapa = (documentoItem: any) => {
    const latLongMap = proj4(EPSG_GLOBAL, EPSG_MAP, [documentoItem.longitude?? 0,documentoItem.latitude?? 0]);
    const dataToSendLatLong = {
        longitude: latLongMap[0],
        latitude: latLongMap[1],
        id: documentoItem.id
      }
      const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.contentWindow?.postMessage(
          { type: 'SiarlFotoObliquaSearch', value: dataToSendLatLong },
          '*'
      )
   }

  const clearSearchResults = () => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlIntFotoClearLayers", value: "true" }, '*')
    SessionStorageService.removeItem('pesquisarFotoObliqua')
    SessionStorageService.removeItem('lastSearchFotografiaAerea')
    selectFotografiaResult(undefined)
    selectFotografiaResultId(undefined)
    setFotografiaObliquaSelected(undefined);
    setFoto([]);
    removeColumnsKeys(keyPainelDocumentos)
    removeColumnsKeys(keyPainelPrincipal)
    SessionStorageService.removeItem("resultadoFotoPesquisa");
    postMessage({ type: 'resultadoFotoPesquisaChange' }, '*');
    SessionStorageService.removeItem("coordenadasPesquisaPoligonoFoto");
    postMessage({ type: 'stopSlideShowPhoto' }, '*');
    iframe.contentWindow?.postMessage({ type: "SiarlClearFotoAereaLine", value: "true" }, '*')
    iframe.contentWindow.postMessage({type: "SiarlFotoObliquaClearLayers", value: "true"}, "*")
    
  }

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected)
  }
  const handlePageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent
    const take = event.page.take

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value)
    }

    setPage({
      ...event.page,
      take,
    })
  }


  useEffect(() => {

    try {
      const results = JSON.parse(
        SessionStorageService.getItem('resultadoFotoPesquisa') ?? ''
      )
      setFoto(results);
      getVisibleColumns(keyPainelPrincipal, setColums)
      getVisibleColumns(keyPainelDocumentos, setColumsDocuments)
        // const idsVooDrone = results.map((o: any) => o.id)
        // getDocumentosByvoosDroneIDs(idsVooDrone).then(response => {
        //   setDocumentosvoosDrone(response)
        // }).catch(error=>{
        //   console.log(error)
        // })
      } catch (error) {
        console.error('Erro ao buscar os dados:', error)
      }
  }, [])

   const getFotoo = async (data: any) =>
    await get<any, any>(`${GET_REGISTO_VOO_BY_ID}/` + data)

  const handleInfoClickById = (registoFotoLocal: Record<string, any>) => async () => {
    try {

      const id = registoFotoLocal.id_registo_voo ?? registoFotoLocal.id;

      postMessage({type: "showLoader"}, "*");
      const iframe: any = document.getElementById('iframeGeoPortal')

      iframe.contentWindow.postMessage({type: "SiarlFotoObliquaClearLayers", value: "true"}, "*")
      const idFotoLineString = id; // TO DO COLOCAR NO MAPA
      iframe.contentWindow?.postMessage({ type: "SiarlFotografiaSearch", value: [idFotoLineString] }, '*')

      const foto = await getFotoo(id);

      const objToShow = {
        ...foto,
        data_registo: foto?.data_registo? new Date(foto?.data_registo):null,
        descricao: foto?.descricao ?? undefined,
        comentario: foto?.comentario ?? undefined,
        tecnicoRegisto : await GetUserNameTecnico(foto?.utilizador_id)
      }

      sessionStorage.setItem('novaFoto', 'false')
      selectFotoPathResult(objToShow)
      selectFotografiaResult(objToShow)
      selectFotografiaResultId(foto?.id)
      setFotografiaObliquaSelected(objToShow);
      setShowResultadoFoto(false)
      setShowRegistarFoto(true)

      restoreView();
    } catch (error) {
      postMessage({ type: 'errorMsg', value: { message: "Não foi possivel efetuar esta operação" } }, '*')
    }finally{
      postMessage({type: "hideLoader"}, "*");
    }
  }
  
  const FotoCustomCell = (props: GridCellProps) => (
    <ResultadoFotoCommandGridCell
      {...props}
      onInfoClick={handleInfoClickById}
      onLocationClick={handleLocationClickById}
    />
  )
  const FotoDocumentCustomCell = (props: GridCellProps) => (
    <ResultadoFotoDocumentoCommandGridCell
    onLocationClick={handleLocationClickById} 
    {...props}
    onDocumentPreviewClick={handleDocumentPreviewClickById}
    onInfoClick={handleInfoClickById}    />
  )

const handleLocationClickById = (documentosLocal: Record<string, any>) => async () => {
  const id = documentosLocal.id_registo_voo ?? documentosLocal.id;
  const iframe: any = document.getElementById('iframeGeoPortal')
  iframe.contentWindow?.postMessage(
    { type: 'SiarlClearFotoAereaLine', value: "true" },
    '*'
);
  iframe.contentWindow?.postMessage(
      { type: 'SiarlFotoAereaLine', value: [id] },
      '*'
  );
                    
}


    const documentosToPreview = documentosFoto

  const handleDocumentPreviewClickById = (documentosLocal: Record<string, any>) => async () => {

    try {

      postMessage({ type: "showLoader" }, "*");

      setIdDoDocumento(documentosLocal.id);
      setUrlDoDocumento(documentosLocal.url_externa);
      setOpenFormViewDocumento(true);

      setDataFotoTitle(documentosLocal.date_time_original);

      setNomeVooTitle(documentosLocal?.registo_voo?.descricao ?? "")

      if (documentosLocal.latitude && documentosLocal.longitude) {
        const latLongMap = proj4(EPSG_GLOBAL, EPSG_MAP, [documentosLocal.longitude?? 0,documentosLocal.latitude?? 0]);
        const dataToSendLatLong = {
            longitude: latLongMap[0],
            latitude: latLongMap[1],
            id : documentosLocal.id
          }
          const iframe: any = document.getElementById('iframeGeoPortal')
          iframe.contentWindow?.postMessage(
              { type: 'SiarlFotoObliquaHighlight', value: dataToSendLatLong },
              '*'
          )
        }

    } catch (error) {
      handleGenericError(error, "Não foi possivel abrir o documento!")
      setOpenFormViewDocumento(false);

    } finally {

      postMessage({ type: "hideLoader" }, "*");

    }
  }

  const nextPhoto = async (e: any) => {

    try{

      postMessage({ type: "showLoader" }, "*");
      const nextIds = documentosToPreview
        .filter((doc: any) => doc.id < idDoDocumento && !!doc.url_externa)
        .map((doc: any) => doc.id);
  
      if (nextIds.length > 0) {
        const nextId = Math.max(...nextIds);
        console.log(`O próximo ID é ${nextId}`);
  
        const documento: any = documentosToPreview.find((x: any) => x.id == nextId);
  
        setIdDoDocumento(nextId.toString());
        setUrlDoDocumento(documento?.url_externa);

        setDataFotoTitle(documento.date_time_original);

        setNomeVooTitle(documento?.registo_voo?.descricao ?? "")

        if (documento.latitude && documento.longitude) {
          const latLongMap = proj4(EPSG_GLOBAL, EPSG_MAP, [documento.longitude?? 0,documento.latitude?? 0]);
          const dataToSendLatLong = {
              longitude: latLongMap[0],
              latitude: latLongMap[1],
              id : documento.id
            }
            const iframe: any = document.getElementById('iframeGeoPortal')
            iframe.contentWindow?.postMessage(
                { type: 'SiarlFotoObliquaHighlight', value: dataToSendLatLong },
                '*'
            )
          }
  
      } else {
        console.log("Não há um próximo ID na lista.");
      }

    }catch(error){

      handleGenericError(error, "Não foi possivel abrir o documento!")

    }finally{

      postMessage({ type: "hideLoader" }, "*");

      e.preventDefault();
    }
   
  }

  const previousPhoto = async (e: any) => {

    try{

      postMessage({ type: "showLoader" }, "*");
      const previousIds = documentosToPreview
        .filter((doc: any) => doc.id > idDoDocumento && !!doc.url_externa)
        .map((doc: any) => doc.id);
  
      if (previousIds.length > 0) {
        const previousId = Math.min(...previousIds);
        console.log(`O anterior ID é ${previousId}`);
  
        const documento: any = documentosToPreview.find((x: any) => x.id == previousId);
  
        setIdDoDocumento(previousId.toString());
  
        setIdDoDocumento(previousId.toString());
        setUrlDoDocumento(documento.url_externa);

        setDataFotoTitle(documento.date_time_original);

        setNomeVooTitle(documento?.registo_voo?.descricao ?? "")

        if (documento.latitude && documento.longitude) {
          const latLongMap = proj4(EPSG_GLOBAL, EPSG_MAP, [documento.longitude?? 0,documento.latitude?? 0]);
          const dataToSendLatLong = {
              longitude: latLongMap[0],
              latitude: latLongMap[1],
              id : documento.id
            }
            const iframe: any = document.getElementById('iframeGeoPortal')
            iframe.contentWindow?.postMessage(
                { type: 'SiarlFotoObliquaHighlight', value: dataToSendLatLong },
                '*'
            )
          }
        restoreView();

      } else {
        console.log("Não há um anterior ID na lista.");
      }

    }catch(error){

      handleGenericError(error, "Não foi possivel abrir o documento!")

    }finally{

      postMessage({ type: "hideLoader" }, "*");

      e.preventDefault();
    }
       
  }

  const [dataState, setDataState] = React.useState({
    skip: 0,
    take: 20,
    sort: [],
    group: [],
  });
  const [dataStateDocumentos, setDataStateDocumentos] = React.useState({
    skip: 0,
    take: 20,
    sort: [],
    group: [],
  });

  const dataStateChange = (event: any) => {
    setDataResult(process(foto, event.dataState));
    setDataState(event.dataState);
  };
  const [dataResult, setDataResult] = React.useState(
    process(foto, dataState)
  );
  const dataStateChangeDocumentos = (event: any) => {
    setDataResultDocumentos(process(documentosFoto, event.dataState));
    setDataStateDocumentos(event.dataState);
  };

  const [dataResultDocumentos, setDataResultDocumentos] = React.useState(
    process(documentosFoto, dataStateDocumentos)
  );

  const itemRender = (props: { item: any; itemIndex: number }) => {
    const isTitleItem = props.item.key === 'titulo';
    if (isTitleItem) {
      return (
        <div className={styles.DropDownButtonTitulo}>
          <span>{props.item.text}</span>
        </div>
      );
    } else {
      return (
        <div className={styles.DropDownButtonItem}>
          <span>{props.item.text}</span>
        </div>
      );
    }
  };

  const optionsExportar = [
    {
      key: 'ExportarListaResultadosExcel',
      text: 'excel',
    },
  ]

  let _export: any;
const exportExcel = () => {
  _export.save();
};

  const onItemClickExportar = (event: any) => {
    exportExcel();
  };

  let _pdfExport: any;
  const exportPDF = () => {
    _pdfExport.save();
  };

  const openPdfNewTab = () => {
    return new Promise<void>((resolve, reject) => {
        
        postMessage({ type: "showLoader" }, "*");

        setTimeout(() => {
           const columnsToShow = colums.columns.filter(x => x.show);
            const dataToExport = foto.map((item: any) => {
                const filteredItem: any = {};
                columnsToShow.forEach((column: any) => {
                    filteredItem[column.field] = item[column.field];
                });
                return filteredItem;
            });
            
            exportPDFWithMethod(columnsToShow, dataToExport, 8)
                .then((pdfData: any) => {
                    postMessage({ type: "hideLoader" }, "*");

                        const base64Data = pdfData.split(";base64,")[1];
                        console.log(base64Data);
                        const blob = b64toBlob(base64Data, 'application/pdf');
                        const blobUrl = URL.createObjectURL(blob);

                      
                        window.open(blobUrl, '_blank');
                        resolve();
                  
                })
                .catch(error => {
                    console.error('Error exporting PDF:', error);
                    reject(error);
                });
        }, 1000);
    });
};

  const onItemClickImprimir = async (event: any) => {
    openPdfNewTab();
  }

  const registarNovoClick = () => {
    selectFotografiaResult(undefined);
    selectFotografiaResultId(undefined);
    postMessage({ type: 'form', value: 'registarFotoObliqua' }, '*')
      sessionStorage.setItem('novaFoto', 'true')
  }

  const onMaximizeClick = () => {
    const iframe: any = document.getElementById('iframeGeoPortal');
    const elementoForm: any = document.getElementById('BaseForm');
    const elementoFooter: any = document.getElementById('FooterForm');
    const collapsedSidebarMenu: any = document.getElementById('CollapsedSidebarMenu');
    const floatingMenu: any = document.getElementById('FloatingMenu');

    if (iframe.style.display == 'none') {
        iframe.style.display = '';
        elementoForm.style.maxWidth = '50vw';
        elementoFooter.style.minWidth = '50vw';
        if(collapsedSidebarMenu && floatingMenu){
          collapsedSidebarMenu.style.display = '';
          floatingMenu.style.display = '';
        }
    } else {
        iframe.style.display = 'none';
        elementoForm.style.maxWidth = '100vw';
        elementoFooter.style.minWidth = '100vw';
        if(collapsedSidebarMenu && floatingMenu){
          collapsedSidebarMenu.style.display = 'none';
          floatingMenu.style.display = 'none';
        }
    }
}


const [columsDocuments, setColumsDocuments] = useState<{ columns: { field: string, title: string, width: number | string, show: boolean }[] }>({
  columns: [
    { field: "id", width: 70, title: "ID", show: true },
    { field: "date_time_original", width: 190, title: "Data original", show: true },
    { field: "latitude", width: 150, title: "latitude", show: true },
    { field: "longitude", width: 150, title: "longitude", show: true },
    { field: "registo_voo.descricao", width: 180, title: "Registo voo", show: true }
  ]
})

const [colums, setColums]=useState<{columns:{field:string, title:string, width:number|string, show:boolean}[]}>({
  columns:[
    { field: "id", title: "ID", show: true, width: 70 },
    { field: "descricao", title: "Descrição", show: true, width: 170 },
    { field: "data_registo", title: "Data registo", show: true, width: 180 },
    { field: "data_voo", title: "Data voo", show: true, width: 170 },
    { field: "duracao", title: "Duração", show: true, width: 140 },
    { field: "distancia", title: "Distância", show: true, width: 170 },
  ]
})
useEffect(() => {
  SessionStorageService.setItem(keyPainelDocumentos, JSON.stringify(columsDocuments));
}, [columsDocuments]);

useEffect(() => {
  SessionStorageService.setItem(keyPainelPrincipal, JSON.stringify(colums));
}, [colums]);

const combinedData = dataResult.data.length > 0
? dataResult
: foto?.slice(page.skip, page.take + page.skip);


return (
  <><div className={clsx(styles.base, isCurrentViewMaximized && commonStyles.maximizedBaseForm)}>
    <div className={styles.header}>
      <div>
        <div className={styles.nomeDoModulo}>Foto aérea</div>
        <div className={styles.tituloDaPagina}>Resultados de pesquisa</div>
      </div>

      <div>
        {hasPermission(PERMISSION_INSERIR_FO_REGISTO_VOO) ?
          <Button onClick={registarNovoClick} className={styles.btnNovoRegisto}>
            <span className="icon-siarl icon-registar btnIconRegistar"></span> &nbsp; Nova Fotografia Aérea
          </Button>
          : ''}
        <CSVLink
          title="Imprimir resultados"
          id="print-results"
          separator={';'}
          data={foto?.map((Foto: any) => ({
            ...Foto,
            entidade: Foto?.entidade?.nome,
            tipoEntidade: Foto?.entidade?.tipoEntidade?.designacao,
            tipoAcesso: Foto?.tipoAcesso?.designacao,
            foto: '',
          }))}
          filename={`siarl-documentos-${format(new Date(), 'ddMMyyyHHmm')}`}
          hidden
        ></CSVLink><VisualizadorColunas
          columns={selected === 0 ? colums : columsDocuments}
          setColumns={selected === 0 ? setColums : setColumsDocuments}
          styles={styles.btnEditar2} /><button className={styles.btnEditar2} onClick={onItemClickImprimir} id='imprimirFicha' title='Imprimir'>
          <span className="icon-siarl icon-imprimir"></span>
        </button><DropDownButton
          items={optionsExportar}
          className={styles.btnImprimir}
          style={{marginTop: '-4px'}}
          iconClass="icon-siarl icon-exportar"
          onItemClick={onItemClickExportar}
          fillMode={'flat'}
          itemRender={itemRender}
          title='Exportar'
        ></DropDownButton><button className={styles.btnFechar} onClick={onCloseClick} title="Minimizar">
          <span className="icon-siarl icon-minimizar"></span>
        </button><MaximizeButton className={styles.btnMaximizar} />
    </div>
  </div><TabStrip selected={selected} onSelect={handleSelect}>
      <TabStripTab title="Voos">
        <ExcelExport
          fileName='Resultado_FotografiaAerea'
          data={foto}
          ref={(exporter) => {
            _export = exporter;
          } }
        >
          <Grid
            className={styles.form}
            sortable={true}
            onDataStateChange={dataStateChange}
            data={combinedData}
            {...dataState}
            pageable={{
              buttonCount: 5,
              info: true,
              type: 'numeric',
              pageSizes: true,
              previousNext: true,
            }}
            total={foto.length}
            //groupable={true}
          >
            <GridColumn cell={FotoCustomCell} width={56} />
            {colums?.columns?.map((column, i) => {
              return (
                column.show && (
                  <GridColumn key={i} field={column.field} width={column.width} title={column.title} />
                )
              );
            })}

          </Grid>
        </ExcelExport>

        <GridPDFExport
          fileName='Resultado_FotografiaAerea'
          ref={(element) => {
            _pdfExport = element;
          } }
          margin="1cm"
          paperSize={['340mm', '297mm']}

        >
          <Grid
            className={styles.form}
            sortable={true}
            onDataStateChange={dataStateChange}
            data={dataResult.data.length > 0 ? dataResult : foto}
            {...dataState}
            pageable={{
              buttonCount: 5,
              info: true,
              type: 'numeric',
              pageSizes: true,
              previousNext: true,
            }}
            //groupable={true}
          >
            <GridColumn cell={FotoCustomCell} width={30} />
            {colums?.columns?.map((column, i) => {
              return (
                column.show && (
                  <GridColumn key={i} field={column.field} width={column.width} title={column.title} />
                )
              );
            })}
          </Grid>
        </GridPDFExport>
      </TabStripTab>

      <TabStripTab title="Fotos">
        <ExcelExport
          fileName='Resultado_Documentos_FotografiaAerea'
          data={documentosFoto}
          ref={(exporter) => {
            _export = exporter;
          } }
        >
          <Grid
            className={styles.form}
            sortable={true}
            onDataStateChange={dataStateChangeDocumentos}
            data={dataResultDocumentos.data.length > 0
              ? dataResultDocumentos
              : documentosFoto}
            {...dataState}
            pageable={{
              buttonCount: 5,
              info: true,
              type: 'numeric',
              pageSizes: true,
              previousNext: true,
            }}
            //groupable={true}
          >
            <GridColumn cell={FotoDocumentCustomCell} width={86} />
            {columsDocuments?.columns?.map((column, i) => {
              return (
                column.show && (
                  <GridColumn key={i} field={column.field} width={column.width} title={column.title} />
                )
              );
            })}
          </Grid>
        </ExcelExport>

        <GridPDFExport
          fileName='Resultado_Documentos_FotografiaAerea'
          ref={(element) => {
            _pdfExport = element;
          } }
          margin="1cm"
        >
          <Grid
            className={styles.form}
            sortable={true}
            onDataStateChange={dataStateChangeDocumentos}
            data={dataResultDocumentos.data.length > 0
              ? dataResultDocumentos
              : documentosFoto}
            {...dataState}
            pageable={{
              buttonCount: 5,
              info: true,
              type: 'numeric',
              pageSizes: true,
              previousNext: true,
            }}
            //groupable={true}
          >
            {columsDocuments?.columns?.map((column, i) => {
              return (
                column.show && (
                  <GridColumn key={i} field={column.field} width={column.width} title={column.title} />
                )
              );
            })}
          </Grid>
        </GridPDFExport>
      </TabStripTab>
    </TabStrip><div
      className={clsx(styles.footer, styles.justify_one_child, isCurrentViewMaximized && commonStyles.maximizedFooter)}
    >
      <Button className={styles.btnPesquisar} onClick={clearSearchResults} title='Nova Pesquisa'>
        <span className="icon-siarl icon-pesquisar"></span>
        <span className={styles.rotulo}>
          {' '}&nbsp;{' '}
          Nova pesquisa
        </span>
      </Button>
    </div>
  </div>
  {openFormViewDocumento && (
        <Dialog
        className="ModalDocumentos"
          title={`Foto: ${idDoDocumento}; ${dataFotoTitle}; ${nomeVooTitle}`}
          onClose={() => {
            setOpenFormViewDocumento(false)
            documentosFoto.forEach((documentosLocal: any) => {
              const latLongMap = proj4(EPSG_GLOBAL, EPSG_MAP, [documentosLocal.longitude?? 0,documentosLocal.latitude?? 0]);
              const dataToSendLatLong = {
                  longitude: latLongMap[0],
                  latitude: latLongMap[1],
                  id: documentosLocal.id
                }
                const iframe: any = document.getElementById('iframeGeoPortal')
                iframe.contentWindow?.postMessage(
                    { type: 'SiarlFotoObliquaSearch', value: dataToSendLatLong },
                    '*'
                )
              });
          }}
          style={{ zIndex: 999 }}
        >
          
            <ImagePreviewV2
              documentUrl={urlDoDocumento}
              next={(e: any) => nextPhoto(e)}
              previous={(e: any) => previousPhoto(e)}
            />

        </Dialog>
      )}
  </>
)

} 