import { Button, DropDownButton } from '@progress/kendo-react-buttons'
import { PagerTargetEvent } from '@progress/kendo-react-data-tools'
import { format } from 'date-fns'
import { CSVLink } from 'react-csv'
import { GetUserNameTecnico, b64toBlob, clearProcesso, exportPDFWithMethod, get, handleDownload, handleGenericError, hasPermission, post, setCoordenadasPoligono, setProcessoSelected } from '../../services/utils'
import {
    CONSULTAR_DH_PROCESSO,
    CURRENT_TIMEZONE,
    GET_DH_PROCESSO_SHAPEFILE,
    GET_DOCUMENTO,
    GET_DOCUMENTOS_BY_PROCESSOS,
    GET_PROCESSO,
    GET_RELATORIO_DE_PROCESSOS,
    PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_EDICAO,
    PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_LEITURA,
    PERMISSION_DOMINIO_HIDRICO_PERFIL_TOTAL,
    PROCESSO_MODULE_NAME,
    URL_AJUDA_DOMINIO_HIDRICO_PROCESSO,
    clearLayers
} from '../../utils/constants'
import { ResultadoProcessoCommandGridCell } from './ResultadoProcessoCommandGridCell'


import styles from '../../styles/ocorrencia/ConsultarOcorrenciaForm.module.scss'
import commonStyles from '../../styles/common/Common.module.scss'

import {
    Dispatch,
    MouseEvent as ReactMouseEvent,
    SetStateAction,
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react'

import {
    Grid,
    GridCellProps,
    GridColumn,
    GridPageChangeEvent,
} from '@progress/kendo-react-grid'
import { process } from '@progress/kendo-data-query';

import {
    TabStrip,
    TabStripSelectEventArguments,
    TabStripTab,
} from '@progress/kendo-react-layout'
import SessionStorageService from '../../services/SessionStorageService'
import { useDhProcessoStore } from '../../stores/dhProcesso'
import MaximizeButton from '../common/MaximizeButton'
import clsx from 'clsx'
import { useCommonStore } from '../../stores/common'
import useClearMap from '../../hooks/useClearMap'
import VisualizadorColunas from '../Generico/VisualizadorColunas '
import useVisibleColumns, { Key } from '../../hooks/useVIsibleColumns'
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais'
import ReactGA from 'react-ga4';
import { GridPDFExport } from '@progress/kendo-react-pdf'
import React from 'react'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { ResultadoDocumentoCommandGridCell } from '../documentos/ResultadoDocumentoCommandGridCell'
import ImagePreviewV2 from '../viewer/ImagePreviewV2'
import { Dialog } from '@progress/kendo-react-dialogs'
import moment from 'moment'
import "moment-timezone"

type Props = {
    onCloseClick: (
        e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
    ) => void | undefined
    setShowResults: Dispatch<SetStateAction<boolean>>
    setShowRegistarProcessoForm?: Dispatch<SetStateAction<boolean>>
}
type PageState = {
    skip: number
    take: number
}

const initialDataState: PageState = { skip: 0, take: 20 }
const keyPainelPrincipal:Key = 'processoColunasVisiveis';
const keyPainelDocumentos:Key = 'processoDocumentosColunasVisiveis';
export default function ResultadoProcessoForm({
    onCloseClick,
    setShowResults,
    setShowRegistarProcessoForm,
}: Props) {
    const [isMobile, setIsMobile] = useState(false);
    const [page, setPage] = useState<PageState>(initialDataState)
    const [pageSizeValue, setPageSizeValue] = useState<number | string | undefined>()
    const [processos, setProcessos] = useState([])
    const [documentosProcessos, setDocumentosProcessos] = useState([])
    const [idDoDocumento, setIdDoDocumento] = useState("");
    const [urlDoDocumento, setUrlDoDocumento] = useState("");
    const [openForm, setOpenForm] = useState<boolean>(false)
    const selectProcessoResult = useDhProcessoStore((state) => state.selectDhProcessoResult)
    const selectProcessoResultId = useDhProcessoStore((state) => state.selectDhProcessoResultId)
    const [processoFinal, setProcessoFinal] = useState<any>([]);
    const [pageable, setPageable] = useState<any>({
      buttonCount: 5,
      info: true,
      type: 'numeric',
      pageSizes: true,
      previousNext: true,
    })

    const getDocumento = async (data: any) =>
    await get<any, any>(`${GET_DOCUMENTO}/` + data)
    const getProcesso = async (data: any) => await get<any, any>(`${GET_PROCESSO}/` + data)
    const getDocumentosByProcessos = async(data:any) => await post<any,any>(GET_DOCUMENTOS_BY_PROCESSOS, data);
    const getShapeFile = async (data: any) =>
      await post<any, any>(`${GET_DH_PROCESSO_SHAPEFILE}`, data)

    const consultarProcesso = async (data: any) => await post<any, any>(`${CONSULTAR_DH_PROCESSO}`, data)

     const canSeeDominioHidricoEdit = hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_EDICAO) || hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_TOTAL);

     const completoLeitura = hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_LEITURA)
    

     const [dataState, setDataState] = React.useState({
        skip: 0,
        take: 20,
        sort: [],
        group: [],
      });
    
      const [dataStateDocumentos, setDataStateDocumentos] = React.useState({
        skip: 0,
        take: 20,
        sort: [],
        group: [],
      });

     const [dataResult, setDataResult] = React.useState(
        process(processos, dataState)
      );
      
     const dataStateChange = (event: any) => {
        setDataResult(process(processos, event.dataState));
        setDataState(event.dataState);
      };

    const getRelatorioDeProcessos = async () => await get<any, any>(GET_RELATORIO_DE_PROCESSOS)

    const isCurrentViewMaximized = useCommonStore(state => state.isCurrentViewMaximized)
    const [clearMap] = useClearMap();
    const {getVisibleColumns, removeColumnsKeys} = useVisibleColumns();

    const handleMessageEvent = useCallback((event: MessageEvent) => {
        if (event.data.type == "resultadoProcessosPesquisaChange") {
            SessionStorageService.setItem("resultadoProcessosPesquisa", event.data.value);
        }
    }, [])

    useEffect(() => {
        window.addEventListener(
            'message',
            handleMessageEvent,
            false
        )

        return () => {
            window.removeEventListener(
                'message',
                handleMessageEvent,
                false
            )
        }
    }, [handleMessageEvent])

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/ResultadoProcesso" });
      }, []);

      
      const initialized = useRef(false)
      useEffect(() => {
          if (!initialized.current) {
            initialized.current = true;
        if (SessionStorageService.getItem("lastSearchProcessos") && !SessionStorageService.getItem("firstCallProcesso")) {
          postMessage({ type: "showLoader" }, "*");
          const objToSearch = SessionStorageService.getItem("lastSearchProcessos") ?? '';
          consultarProcesso(objToSearch).then((data) => {
            SessionStorageService.setItem("resultadoProcessosPesquisa", JSON.stringify(data));
            setResultsProcessos();
            postMessage({ type: "hideLoader" }, "*");
          })
        } 
        else {
            setResultsProcessos();
      }
    }
      }, [])

    const setResultsProcessos = () => {
        const results = JSON.parse(SessionStorageService.getItem('resultadoProcessosPesquisa') ?? '')
        setProcessos(results)
        const iframe: any = document.getElementById('iframeGeoPortal')
        const ids: number[] = []
        const idsApoio: number[] = []
        results.forEach((element: any) => {
       
            ids.push(element.id)
        })
        iframe.contentWindow?.postMessage(
            { type: 'SiarlProcessoSearch', value: ids },
            '*'
        )
        getDocumentosByProcessos(ids).then(result => {
            setDocumentosProcessos(result);
        });

        // setProcessos(results);
        // setProcessoFinal(results.slice(page.skip, page.take + page.skip))
        getVisibleColumns(keyPainelPrincipal, setColums)
        getVisibleColumns(keyPainelDocumentos, setColumsDocuments)
      
        postMessage({ type: 'hideLoader' }, '*');
        try{
            const processosColunasVisiveis = JSON.parse(SessionStorageService.getItem('processosColunasVisiveis') ?? '')
            setColums(processosColunasVisiveis)
        }catch(ex){
            // TO DO ERROR HANDLE
        }

        }


        const openPdfNewTab = () => {
          return new Promise<void>((resolve, reject) => {
              
              postMessage({ type: "showLoader" }, "*");
      
              setTimeout(() => {
                 const columnsToShow = colums.columns.filter(x => x.show);
                  const dataToExport = processos.map((item: any) => {
                      const filteredItem: any = {};
                      columnsToShow.forEach((column: any) => {
                          filteredItem[column.field] = item[column.field];
                      });
                      return filteredItem;
                  });
                  
                  exportPDFWithMethod(columnsToShow, dataToExport, 8)
                      .then((pdfData: any) => {
                          postMessage({ type: "hideLoader" }, "*");
      
                              const base64Data = pdfData.split(";base64,")[1];
                              console.log(base64Data);
                              const blob = b64toBlob(base64Data, 'application/pdf');
                              const blobUrl = URL.createObjectURL(blob);
      
                            
                              window.open(blobUrl, '_blank');
                              resolve();
                        
                      })
                      .catch(error => {
                          console.error('Error exporting PDF:', error);
                          reject(error);
                      });
              }, 1000);
          });
      };
      
    useEffect(() => {
        const iframe: any = document.getElementById('iframeGeoPortal')
        const ids: number[] = []
        processos.forEach((element: any) => {
          ids.push(element.id)
        })
        iframe.contentWindow?.postMessage(
          { type: 'SiarlProcessoSearch', value: ids },
          '*'
        )
      }, [])

    const clearSearchResults = () => {
        SessionStorageService.removeItem('resultadoProcessosPesquisa')
        postMessage({ type: 'resultadoProcessosPesquisaChange' }, '*')
        SessionStorageService.removeItem("lastSearchProcessos");
        selectProcessoResult(undefined)
        setCoordenadasPoligono(undefined);
        clearProcesso();
        removeColumnsKeys(keyPainelDocumentos)
        removeColumnsKeys(keyPainelPrincipal)
        clearMap(clearLayers["CLEAR_LAYERS_PROCESSOS"])

        const iframe: any = document.getElementById('iframeGeoPortal')

        iframe.contentWindow?.postMessage(
          { type: 'SiarlDhAutosClearLayers', value: "true" },
          '*'
        )

        iframe.contentWindow?.postMessage(
          { type: 'SiarlDhAutosVerticesClearLayers', value: "true" },
          '*'
        )
    }

    const handleInfoClickById = (processoLocal: Record<string, any>) => async () => {

        const id = processoLocal?.processo_id ?? processoLocal?.id;
        const processo = await getProcesso(id);

        const tecnico = await GetUserNameTecnico(processo.tecnico_id);
        
        if (!processo) {
        postMessage({type: "infoMsg", value: { message: "Registo desativado" }}, "*");
        return;
        }

        const seletedProcessoReulst = {
            ...processo,
            tecnico_id : tecnico,
            id_tipo_processo: processo.dhTipoProcesso ?? undefined,
            id_localizacao_processo: processo.dhLocalizacaoProcesso ?? undefined,
            id_fase_pr: processo.dhFaseProcedimentoDelimitacao ?? undefined,
            id_tipo_va: processo.dhValidacaoProva ?? undefined,
            id_tipo_pa: processo.dhTipoParcela ?? undefined,
            id_tipo_se: processo.dhTipoSentenca ?? undefined,
            id_tipo_ac: processo.dhTipoDecisaoFinal ?? undefined,
            id_tipo_es: processo.dhEstadoProcesso ?? undefined,
            id_tipo_pe: processo.dhTipoPendencia ?? undefined,
            id_tipo_no: processo.dhTipoNotificacao ?? undefined,
            id_situ_no: processo.dhTipoSituacaoNotificacao ?? undefined,
            id_cond_pr: processo.dhCondicionantesProcesso ?? undefined,
            id_tipo_aj: processo.dhTipoAcaoJudicial ?? undefined,
            id_tipo_de: processo.dhTipoDesafetacao ?? undefined,
            id_tipo_aq: processo.dhTipoAquisicao ?? undefined,
            id_tipo_in: processo.dhTipoIniciativa ?? undefined,
            
            data_requer: processo.data_requer ? moment.utc(processo.data_requer).tz(CURRENT_TIMEZONE) : undefined ,
            data_registo: processo.data_registo ? moment.utc(processo.data_registo).tz(CURRENT_TIMEZONE) : undefined,

            tipo_geom_indefinida: processo.tipo_geom_indefinida ?? undefined,
            np_ent_por: processo.np_ent_por ?? undefined,
            np_apa_rpp: processo.np_apa_rpp ?? undefined,
            requerente: processo.requerente ?? undefined,
            representante: processo.representante ?? undefined,
            assunto: processo.assunto ?? undefined,
            np_mp: processo.np_mp ?? undefined,
            np_comum: processo.np_comum ?? undefined,
            lugar: processo.lugar ?? undefined,
        }

        sessionStorage.setItem('novoProcesso', 'false')
        selectProcessoResultId(processo.id as number)
        selectProcessoResult(seletedProcessoReulst)
        setProcessoSelected(seletedProcessoReulst);
        setShowResults(false)
        const iframe: any = document.getElementById('iframeGeoPortal')

        iframe.contentWindow?.postMessage(
            { type: 'SiarlProcessoSearch', value: [processoLocal.id] },
            '*'
        )
        iframe.contentWindow?.postMessage(
            { type: 'SiarlProcessoCenter', value: [processoLocal.id] },
            '*'
        )

        if (!!processo.dhAutos && processo.dhAutos.length > 0) {
            const ids = processo.dhAutos.map((x: any) => x.id);
            const iframe: any = document.getElementById('iframeGeoPortal')
            iframe.contentWindow?.postMessage({ type: "SiarlDhAutosSearch", value: ids }, '*')
        }
        if (!!processo.dhAutosVertices && processo.dhAutosVertices.length > 0) {
            const ids = processo.dhAutosVertices.map((x: any) => x.id);
            const iframe: any = document.getElementById('iframeGeoPortal')
            iframe.contentWindow?.postMessage({ type: "SiarlDhAutosVerticesSearch", value: ids }, '*')
        }
        if (setShowRegistarProcessoForm) setShowRegistarProcessoForm(true)
    }

    const conditionalProps = completoLeitura || canSeeDominioHidricoEdit
    ? { 
        onInfoClick: handleInfoClickById
    } 
    : {};

    const handlePageChange = (event: GridPageChangeEvent) => {
        const targetEvent = event.targetEvent as PagerTargetEvent
        const take = event.page.take

        if (targetEvent.value) {
            setPageSizeValue(targetEvent.value)
        }

        setPage({
            ...event.page,
            take,
        })
    }

    const CommandCell = (props: GridCellProps) => (
        <ResultadoProcessoCommandGridCell
            {...props}
            onInfoClick={handleInfoClickById}
            onMapCenter={mapCenter}
          {...conditionalProps}

        />
    )

    const mapCenter = (item: Record<string, any>) => async () => {
        const id = item.processo_id ?? item.id;
        const iframe: any = document.getElementById('iframeGeoPortal');
        iframe.contentWindow?.postMessage(
            { type: 'SiarlProcessoCenter', value: [id] },
            '*'
        )
    }

    const [selected, setSelected] = useState<number>(0)

    const handleSelect = (e: TabStripSelectEventArguments) => {
        setSelected(e.selected)
    }

    const onItemClick = (event: any) => {

        const link: HTMLAnchorElement | null = document.querySelector('#print-results');

        if (link) {
            link.click();
        }

        // if (event.item.key == 'ExportarFichasResultados') {

            

        // } else if (event.item.key == 'ExportarListaResultados') {

        //     console.log(event.item.text);

        // }

    };

    const options = [
        {
            key: 'ExportarFichasResultados',
            text: 'Fichas',
        },
        {
            key: 'ExportarListaResultados',
            text: 'Resultados',
        },
    ]

    const itemRender = (props: { item: any; itemIndex: number }) => {
        const isTitleItem = props.item.key === 'titulo';
        if (isTitleItem) {
            // Item de título personalizado
            return (
                <div className={styles.DropDownButtonTitulo}>
                    <span>{props.item.text}</span>
                </div>
            );
        } else {
            // Demais itens
            return (
                <div className={styles.DropDownButtonItem}>
                    <span>{props.item.text}</span>
                </div>
            );
        }
    };

    useEffect(() => {
        sessionStorage.setItem("SubModulo", PROCESSO_MODULE_NAME);
    }, []);

    const optionsExportar = [
        {
            key: 'ExportarListaResultadosExcel',
            text: 'Excel',
        },
        {
          key: 'ExportarListaResultadosShapeFile',
          text: 'Shapefile',
        },
    ]
    
    let _pdfExport: any;
    const exportExcel = () => {
      _export.save();
    };

    const onItemClickExportar = async (event: any) => {
      if (event.item.key == 'ExportarListaResultadosExcel') {
        exportExcel();
      }
      else if (event.item.key == "ExportarListaResultadosShapeFile") {
        const results = JSON.parse(
          SessionStorageService.getItem('resultadoProcessosPesquisa') ?? ''
        )
    
        if (results) {
          const ids: number[] = []
          results.forEach((element: any) => {
            ids.push(element.id)
          })
  
          const file: any = await getShapeFile(ids);
          const filePath = 'shapeFileDhProcessos.zip';
  
          handleDownload(file, filePath);
      }
    } 
  }


    let _export: any;
    const exportPDF = () => {
      _pdfExport.save();
    };

    const onItemClickImprimir = async (event: any) => {
      //exportPDF();
    openPdfNewTab();
    }
    
    const [columsDocuments, setColumsDocuments] = useState<{ columns: { field: string, title: string, width: number | string, show: boolean }[] }>({
        columns: [
          { field: "id", width: 70, title: "ID", show: true },
          { field: "filedoc_cod", width: 190, title: "ID Documento Filedoc", show: true },
          { field: "data_publicacao", width: 180, title: "Data publicação", show: true },
          { field: "tipoRecurso.designacao", width: 180, title: "Tipo de recurso", show: true },
          { field: "titulo", width: 180, title: "Título", show: true },
          { field: "autores", width: 180, title: "Autor", show: true },
          { field: "entidade.nome", width: 180, title: "Entidade", show: true },
          { field: "id_estado", width: 180, title: "Estado", show: true },
        ]
      })
    
      const [colums, setColums]=useState<{columns:{field:string, title:string, width:number|string, show:boolean}[]}>({
        columns:[
            { field: "id", title: "Id ", show: true, width: 70 },
            { field: "np_filedoc", title: "Nº Filedoc", show: true, width: 170 },
            { field: "localizacao", title: "Localizacao ", show: true, width: 170 },
            { field: "requerente", title: "Requerente", show: true, width: 170 },
            { field: "assunto", title: "Assunto", show: true, width: 170 },
            { field: "estado_processo", title: "Estado", show: true, width: 180 },
        ]
      })
      useEffect(() => {
        SessionStorageService.setItem(keyPainelDocumentos, JSON.stringify(columsDocuments));
      }, [columsDocuments]);
      
      useEffect(() => {
        SessionStorageService.setItem(keyPainelPrincipal, JSON.stringify(colums));
      }, [colums]);

      useLayoutEffect(() => {

        const handleResize = () => {
    
          if (window.innerWidth <= telaMobile) {
            
            setIsMobile(true);
            ocultaFloatingMenu_e_Mapa();
    
          }else{
    
            setIsMobile(false);
            mostraFloatingMenu_e_Mapa();
    
          }
    
        };
      
        window.addEventListener('resize', handleResize);
      
        handleResize();
      
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    
      }, []);

      const handleNewRegistoClick = () => {
        selectProcessoResult(undefined);
        setProcessoSelected(undefined);
        setShowResults(false);
        selectProcessoResultId(undefined)
        sessionStorage.setItem('novoProcesso', 'true')


        if (setShowRegistarProcessoForm) 
        setShowRegistarProcessoForm(true);
    }
    
      const onItemClickMais = (event: any) => {
    
        elementoClickMais(event);

        onItemClickExportar(event);
        
        onItemClickImprimir(event);
      };

      const documentsCustomCell = (props: GridCellProps) => (
        <ResultadoDocumentoCommandGridCell
        onLocationMouseEnter={function (documentos: Record<string, unknown>): void {
          console.log("n/a");
        } } onLocationMouseLeave={function (documentos: Record<string, unknown>): void {
          console.log("n/a");
        } } onLocationClick={mapCenter} onDocumentPreviewClick={handleDocumentPreviewClickById} {...props}
        onInfoClick={handleInfoClickById}    />
      )
    
    const optionsMais = [
        {
            key: 'titulo',
            text: 'Imprimir',
        },
        {
            key: 'imprimirFicha',
            text: 'Resultados',
            className: 'icon-siarl icon-imprimir btnIconRegistar'
        },
        {
            key: 'titulo',
            text: 'Exportar',
        },
        ...optionsExportar.map(option => ({
            ...option,
            className: 'icon-siarl icon-exportar btnIconRegistar',
        })),
    ]

    const dataStateChangeDocumentos = (event: any) => {
        setDataResultDocumentos(process(documentosProcessos, event.dataState));
        setDataStateDocumentos(event.dataState);
      };
    
      const [dataResultDocumentos, setDataResultDocumentos] = React.useState(
        process(documentosProcessos, dataStateDocumentos)
      );

    const handleDocumentPreviewClickById = (documentosLocal: Record<string, any>) => async () => {

        try {
    
          postMessage({ type: "showLoader" }, "*");
          const documentos = await getDocumento(documentosLocal.id);
    
          setIdDoDocumento(documentosLocal.id);
          setUrlDoDocumento(documentos.url);
          setOpenForm(true);
    
        } catch (error) {
    
          handleGenericError(error, "Não foi possivel abrir o documento!")
          setOpenForm(false);
    
        } finally {
    
          postMessage({ type: "hideLoader" }, "*");
    
        }
      }
      const documentosToPreview = documentosProcessos;
      const nextPhoto = async (e: any) => {

        try{
    
          postMessage({ type: "showLoader" }, "*");
          const nextIds = documentosToPreview
            .filter((doc: any) => doc.id < idDoDocumento && doc.url !== '')
            .map((doc: any) => doc.id);
      
          if (nextIds.length > 0) {
            const nextId = Math.max(...nextIds);
            console.log(`O próximo ID é ${nextId}`);
      
            const documento = await getDocumento(nextId);
      
            setIdDoDocumento(nextId.toString());
            setUrlDoDocumento(documento.url);
    
            const iframe: any = document.getElementById('iframeGeoPortal')
            const processo_id = e.processo_id;
            iframe.contentWindow?.postMessage(
              {
                type: 'SiarlIntIntervencaoHighlight',
                value: [processo_id],
              },
              '*'
            )
      
          } else {
            console.log("Não há um próximo ID na lista.");
          }
    
        }catch(error){
    
          postMessage({ type: "errorMsg", value: "Não foi possivel abrir o documento!" }, "*");
    
        }finally{
    
          postMessage({ type: "hideLoader" }, "*");
    
          e.preventDefault();
        }
       
      }
    
      const previousPhoto = async (e: any) => {
    
        try{
    
          postMessage({ type: "showLoader" }, "*");
          const previousIds = documentosToPreview
            .filter((doc: any) => doc.id > idDoDocumento && doc.url !== '')
            .map((doc: any) => doc.id);
      
          if (previousIds.length > 0) {
            const previousId = Math.min(...previousIds);
            console.log(`O anterior ID é ${previousId}`);
      
            const documento = await getDocumento(previousId);
      
            setIdDoDocumento(previousId.toString());
            setUrlDoDocumento(documento.url);
    
            const iframe: any = document.getElementById('iframeGeoPortal')
            const processo_id = e.processo_id;
            iframe.contentWindow?.postMessage(
              {
                type: 'SiarlIntIntervencaoHighlight',
                value: [processo_id],
              },
              '*'
            )
          } else {
            console.log("Não há um anterior ID na lista.");
          }
    
        } catch(error){
    
          handleGenericError(error, "Não foi possivel abrir o documento!")
    
        }finally{
    
          postMessage({ type: "hideLoader" }, "*");
    
          e.preventDefault();
        }
           
      }

      const openAjuda = () => {
        window.open(URL_AJUDA_DOMINIO_HIDRICO_PROCESSO, "_blank")
      }

    return (
      <>
        <div className={clsx(styles.base, isCurrentViewMaximized && commonStyles.maximizedBaseForm)}>

            <div className={styles.header}>

                <div>
                    <div className={styles.nomeDoModulo}>
                        Processos
                    </div>
                    <div className={styles.tituloDaPagina}>
                        Resultados de pesquisa
                    </div>
                </div>

                <div>

                    { canSeeDominioHidricoEdit &&
                        <Button
                        onClick={handleNewRegistoClick}
                        className={styles.btnNovoRegisto}
                        style={{ display: isMobile ? 'none' : '' }}
                        id='novoRegisto'
                        title='Novo processo'
                        >
                        <span className="icon-siarl icon-registar btnIconRegistar" /> &nbsp;{' '}
                        Novo processo
                        </Button>
                    }

                    <CSVLink
                        title="Imprimir resultados"
                        id="print-results"
                        data={processos}
                        filename={`siarl-processos-${format(
                            new Date(),
                            'ddMMyyyHHmm'
                        )}`}
                        hidden
                    >
                    </CSVLink>

                    {!isMobile &&
                        <VisualizadorColunas
                            columns={selected === 0 ? colums : columsDocuments}
                            setColumns={selected === 0 ? setColums : setColumsDocuments}
                            styles={styles.btnEditar2}
                        />
                    }
                    
                    <button className={styles.btnEditar2} onClick={onItemClickImprimir} title='Imprimir'>
                      <span className="icon-siarl icon-imprimir"></span>
                    </button>

                    {!isMobile &&
                        <DropDownButton
                            items={optionsExportar}
                            className={styles.btnImprimir}
                            style={{marginTop: '-4px'}}
                            iconClass="icon-siarl icon-exportar"
                            onItemClick={onItemClickExportar}
                            fillMode={'flat'}
                            itemRender={itemRender}
                            title='Exportar'
                        ></DropDownButton>
                    }

                        {isMobile &&
                        <DropDownButton
                            items={optionsMais}
                            className={styles.btnMais}
                            iconClass="icon-siarl icon-mais"
                            onItemClick={onItemClickMais}
                            fillMode={'flat'}
                            itemRender={itemRenderMais}
                            title="Mais Opções"
                        />
                        }

                    <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
                        <span className="icon-siarl icon-ajuda"></span>
                    </button>

                    <button className={styles.btnFechar} onClick={onCloseClick} title="Minimizar">
                        <span className="icon-siarl icon-minimizar"></span>
                    </button>

                    {!isMobile &&
                        <MaximizeButton className={styles.btnMaximizar} />
                    }
                </div>
            </div>

            <TabStrip selected={selected} onSelect={handleSelect} scrollable={isMobile}>
                <TabStripTab title="Processos">
                    
                <ExcelExport
                fileName='Resultado_Processos'
            data={processos}
            ref={(exporter) => {
              _export = exporter;
            }}
          >
                    <Grid className={styles.form}
                        data={processos.slice(page.skip, page.take + page.skip)}
                        pageable={pageable}
                        skip={page.skip}
                        take={page.take}
                        total={processos.length}
                        onPageChange={handlePageChange}
                      
                    >
                        <GridColumn cell={CommandCell} width={56} />
                         {colums?.columns?.map((column, i) => {
                                return (
                                column.show && (
                                    <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
                                )
                                );
                            })}
                       
                    </Grid>
                    </ExcelExport>

        <GridPDFExport
          fileName='Resultado_Processo'
          ref={(element) => {
            _pdfExport = element
          }}    
          paperSize={['340mm', '297mm']}         
            margin="1cm"
        >
          <Grid
            className={styles.form}
            sortable={true}
            onDataStateChange={dataStateChange}
            data={processos}

          total={processos.length}
          {...dataState}
            pageable={{
              buttonCount: 5,
              info: true,
              type: 'numeric',
              pageSizes: true,
              previousNext: true,
            }}
            //groupable={true}
          >
              <GridColumn cell={CommandCell} width={30} />
              {colums?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
          </Grid>
        </GridPDFExport>
        </TabStripTab>
        <TabStripTab title="Documentos">
        <ExcelExport
          fileName='Resultado_Documentos_Processo'
          data={documentosProcessos}
          ref={(exporter) => {
            _export = exporter
          }}
        >
          
          <Grid
            className={styles.form}
            sortable={true}
            onDataStateChange={dataStateChangeDocumentos}
            data={dataResultDocumentos.data.length > 0 ? dataResultDocumentos : documentosProcessos.map((documentos: any) => ({
                ...documentos,
                data_publicacao: documentos?.data_publicacao ? format(new Date(documentos.data_publicacao), 'dd/MM/yyyy HH:mm') : undefined,
        })).slice(page.skip, page.take + page.skip)
      }        
            {...dataState}
            pageable={{
              buttonCount: 5,
              info: true,
              type: 'numeric',
              pageSizes: true,
              previousNext: true,
            }}
            //groupable={true}
            total={documentosProcessos.length}

          >
            <GridColumn cell={documentsCustomCell} width={86} />
            {columsDocuments?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
          </Grid>
        </ExcelExport>

        <GridPDFExport
          fileName='Resultado_Documentos_Processo'
          ref={(element) => {
            _pdfExport = element
          }}
          margin="1cm"
        >
          <Grid
            className={styles.form}
            sortable={true}
            onDataStateChange={dataStateChangeDocumentos}
            data={
              dataResultDocumentos.data.length > 0
                ? dataResultDocumentos
                : documentosProcessos.map((documentos: any) => ({
                    ...documentos,
                    data_publicacao: documentos?.data_publicacao ? format(new Date(documentos.data_publicacao), 'dd/MM/yyyy HH:mm') : undefined,
            }))        
            }
            {...dataState}
            pageable={{
              buttonCount: 5,
              info: true,
              type: 'numeric',
              pageSizes: true,
              previousNext: true,
            }}
            //groupable={true}
          >
           {columsDocuments?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
          </Grid>
        </GridPDFExport>
      </TabStripTab>

            </TabStrip>

            <div className={clsx(styles.footer, styles.justify_one_child, isCurrentViewMaximized && commonStyles.maximizedFooter)}>

                <Button
                    className={styles.btnPesquisar}
                    onClick={clearSearchResults}
                    title='Nova Pesquisa'
                >
                    <span className="icon-siarl icon-pesquisar"></span>
                    <span className={styles.rotulo}>
                        {' '}&nbsp;{' '}
                        Nova pesquisa
                    </span>              
                </Button>

            </div>
        </div>
        {openForm && (
          <Dialog
          className="ModalDocumentos"
            title={`Documento: ${idDoDocumento}`}
            onClose={() => setOpenForm(false)}
            style={{ zIndex: 999 }}
          >
              <ImagePreviewV2
                documentUrl={urlDoDocumento}
                next={(e: any) => nextPhoto(e)}
                previous={(e: any) => previousPhoto(e)}
              />
          </Dialog>
        )}
        </>
    )
    
}