import { create } from 'zustand'

type AgDesconformidadeState = {
  selectedAgDesconformidadeResult?: unknown
  selectAgDesconformidadeResult: (payload: unknown) => void
  selectedAgDesconformidadeResultId?: number
  selectAgDesconformidadeResultId: (payload?: number) => void

  identificacaoTabIsValid: boolean
  selectUsoDoSoloIdentificacaoTabIsValid: (payload: unknown) => void
  identificacaoTabValidationObject: Record<string, boolean>
  addIdentificacaoTabValidatedField: (payload: Record<string, boolean>) => void

}

export const useAgDesconformidadeStore = create<AgDesconformidadeState>((set, get) => ({
  identificacaoTabIsValid: false,
  identificacaoTabValidationObject: {},
  selectUsoDoSoloIdentificacaoTabIsValid: (payload?: any) =>
  set({ identificacaoTabIsValid: payload }),
  selectedAgDesconformidadeResult: null,
  addIdentificacaoTabValidatedField: (payload) => {
    const validationObj = get().identificacaoTabValidationObject
    const newValidationObj = { ...validationObj, ...payload }
    set({ identificacaoTabValidationObject: newValidationObj })
    const validationValues = Object.values(newValidationObj)
    set({ identificacaoTabIsValid: validationValues.every(value => value === true) })
},
  selectAgDesconformidadeResult: (payload: unknown) =>
    set({ selectedAgDesconformidadeResult: payload }),
  selectAgDesconformidadeResultId: (payload?: number) =>
    set({ selectedAgDesconformidadeResultId: payload })
    
}))
