import { Dispatch, SetStateAction, useEffect } from "react";
import SessionStorageService from "../services/SessionStorageService";
export type Key = 'intervencoesColunasVisiveis'
|'intervencoesDocumentosColunasVisiveis'
|'processoColunasVisiveis'
|'processoDocumentosColunasVisiveis'
|'demarcacaoDocumentosColunasVisiveis'
|'demarcacaoColunasVisiveis' 
|'patrimonioDocumentosColunasVisiveis'
|'patrimonioColunasVisiveis'
|'acaoDocumentosColunasVisiveis'
|'acaoColunasVisiveis'
|'mobilizacaoDocumentosColunasVisiveis'
|'mobilizacaoColunasVisiveis' 
|'candidaturaDocumentosColunasVisiveis'
|'candidaturaColunasVisiveis' 
|'candidaturaDocumentosColunasVisiveis'
|'candidaturaColunasVisiveis'
|'desconformidadeColunasVisiveis'
|'desconformidadeDocumentosColunasVisiveis'
|'pretensaoColunasVisiveis'
|'pretensaoDocumentosColunasVisiveis'
|'documentoColunasVisiveis'
|'documentoDocumentosColunasVisiveis'
|'entidadeColunasVisiveis'
|'entidadeDocumentosColunasVisiveis'
|'ocorrenciaColunasVisiveis'
|'ocorrenciaDocumentosColunasVisiveis'
|'modeloColunasVisiveis'
|'modeloDocumentosColunasVisiveis'
|'tituloUsoSoloColunasVisiveis'
|'tituloUsoSoloDocumentosColunasVisiveis'
|'valorUsoSoloColunasVisiveis'
|'valorUsoSoloDocumentosColunasVisiveis'
|'mdtsColunasVisiveis'
|'fotografiaColunasVisiveis'
|'fotografiaDocumentosColunasVisiveis'
|'vooDroneColunasVisiveis'
|'vooDroneDocumentosColunasVisiveis'
|'estrategiaAdaptacaoColunasVisiveis'
|'estrategiaAdaptacaoDocumentosColunasVisiveis'
;
export default function useVisibleColumns() {
 
    const getVisibleColumns = (key:Key, setStateAction:Dispatch<SetStateAction<any>>) => {
      try {
        const colunasRegistadasStored = SessionStorageService.getItem(`${key}Server`);
        const colunasVisiveis =colunasRegistadasStored? JSON.parse(colunasRegistadasStored): undefined;
        try {
          setStateAction((prevValue:any) => {
            if (colunasVisiveis) return colunasVisiveis;
            return prevValue;
          })
        } catch (ex) {
          console.log({ ex });
        }
      } catch (error) {
        console.error({error})
      }
    }
    const removeColumnsKeys=(key:Key)=>{
        SessionStorageService.removeItem(`${key}Server`);
        SessionStorageService.removeItem(`${key}`);
    }
    const setVisibleColumns = (element: { key: Key, value: any }) => {
        try {
            SessionStorageService.setItem(`${element.key}Server`, element.value);
        }
        catch (ex) {
            console.log({ setVisibleColumnsException: ex })
        }

    }
//    const useEffectVisibleColumnsStorage= (key:Key, data:any)=>{
//     useEffect(()=>{
//         useEffect(() => {
//             SessionStorageService.setItem(key, JSON.stringify(data));
//           }, [key, data]);

//     },[key, data])

//    }
      return {
        setVisibleColumns,
        getVisibleColumns,
       // useEffectVisibleColumnsStorage,
        removeColumnsKeys
    };
}
