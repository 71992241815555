import { Button, DropDownButton } from '@progress/kendo-react-buttons'
import { Form, FormElement } from '@progress/kendo-react-form'
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from '@progress/kendo-react-layout'
import { useEffect, useState } from 'react'

import styles from '../../styles/entidade/RegistarEntidade.module.scss'

import { MouseEvent as ReactMouseEvent } from 'react'
import {
  GetUserNameTecnico,
  blobToBase64,
  clearFotoObliqua,
  del,
  get,
  getFotografiaObliquaSelected,
  handleGenericError,
  hasPermission,
  post,
  put,
  setCoordenadasPoligono,
  setFotografiaObliquaSelected,
  useCombos
} from '../../services/utils'
import { useFotografiaStore } from '../../stores/fotoObliqua'
import {
  AUTH_LOCALSTORAGE,
  CREATE_LINE_FROM_POINTS,
  DELETE_FO_FOTO,
  DELETE_FO_REGISTO_VOO,
  DELETE_GEOM_JURISDICAO_ENTIDADE,
  EPSG_GLOBAL,
  EPSG_MAP,
  GET_COORDINATES_FOTO_OBLIQUA,
  GET_ENTIDADE,
  GET_FOTOS_REGISTO_VOO,
  GET_FO_IMAGE_BASE64,
  GET_REGISTO_VOO_BY_ID,
  PERMISSION_ATUALIZAR_FO_REGISTO_VOO,
  PERMISSION_INSERIR_FO_REGISTO_VOO,
  PROJECTION_MAP,
  SAVE_NEW_ENTIDADE,
  SAVE_NEW_FOTO,
  SAVE_REGISTO_VOO,
  UPDATE_REGISTO_VOO,
  UPLOAD_FO_FOTO,
  URL_AJUDA_APOIO_GESTAO_DESCONFORMIDADE
} from '../../utils/constants'
import { AssociacaoListagem } from '../associacao/Associacao'
import { DocumentoListagem } from '../documentos/registar/documento/documento'
import { FotografiaIdentificacao } from './FotografiaIdentificacao'
import DocumentPreview from '../viewer/DocumentPreview'
import proj4 from 'proj4'
import { Dialog } from '@progress/kendo-react-dialogs'
import ReactGA from 'react-ga4';
import { v4 as uuidv4 } from 'uuid';
import SessionStorageService from '../../services/SessionStorageService'

type Props = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
}

export const PHOTO_FORMAT: string[] = [".JPG", ".PNG"];


type RegistoVoo = {
  id: number
  descricao : string
  data_registo : unknown
}

type Foto = {
  id: number
}

const TOTAL_TABS = 2
const INITIAL_SELECTED_TAB_VALUE = 0
export default function RegistarFotografia({ onCloseClick }: Props) {
  const [selected, setSelected] = useState<number>(0)
  let formState: any
 
  proj4.defs(EPSG_MAP, PROJECTION_MAP);
  const deleteGeomApoio = async (data: any) => await post<any, any>(`${DELETE_GEOM_JURISDICAO_ENTIDADE}`, data);

  const selectedFotografiaResult: any = useFotografiaStore(
    (state) => state.selectedFotoResult
  )
  const selectedFotografiaResultId: any = useFotografiaStore((state) => state.selectedFotoResultId)

  const selectFotografiaResult = useFotografiaStore((state) => state.selectFotoResult)
  const selectFotografiaResultId = useFotografiaStore((state) => state.selectFotoResultId)

  const saveRegistoVoo = async (data: any) =>
    await post<any, any>(`${SAVE_REGISTO_VOO}`, data)

     const saveFoto = async (data: any) =>
    await post<any, any>(`${SAVE_NEW_FOTO}`, data)

  const updateRegistoVoo = async (data: any) =>
    await put<any, any>(`${UPDATE_REGISTO_VOO}`, data)
  
  const uploadFoto = async (data: any) =>
    await post<any, any>(`${UPLOAD_FO_FOTO}`, data) 
  
  const createLineFromPoints = async (data: any) =>
    await post<any, any>(`${CREATE_LINE_FROM_POINTS}`, data) 
  

  const getCoordinatesFromFotoObliqua = async (data: any) =>
    await get<any,any>(`${GET_COORDINATES_FOTO_OBLIQUA}/` + data);

  let contadorUpload = 999;
  const selectFotoResult = useFotografiaStore(
        (state) => state.selectFotoResult
    )
    const selectFotoPathResult = useFotografiaStore(
        (state) => state.selectFotoResult
    )
    const selectedFotoResult: any = useFotografiaStore(
        (state) => state.selectedFotoResult
    )
    const selectedFotoPathResult: any = useFotografiaStore(
        (state) => state.selectedFotoPathResult
    )
    
    const getFotoo = async (data: any) =>
    await get<any, any>(`${GET_REGISTO_VOO_BY_ID}/` + data)

    const deleteRegistoVoo = (id: number) => del(`${DELETE_FO_REGISTO_VOO}/${id}`, {})
    const deleteFoto = (id: number) => del(`${DELETE_FO_FOTO}/${id}`, {})


  useEffect(() => {
    const fotografiaSelected = getFotografiaObliquaSelected()

    console.log('ID Fotografia : ' + fotografiaSelected?.id)
  }, [])


  const [openModalDescartar, setOpenModalDescartar] = useState<any>()

  const cancelModalDescartar = () => {
    setOpenModalDescartar(false)
  }

  const handleCancel = ()  => {
        refreshForm();
        postMessage({ type: "form", value: "" }, "*");
        setTimeout(() => {
            postMessage({ type: "form", value: "registarFotoObliqua" }, "*");
          }, 10)
    }

   const handleOpenModalDescartar = () => {
   if (
      sessionStorage.getItem('novaFoto') &&
      sessionStorage.getItem('novaFoto') == 'true'
    ) {
      setOpenModalDescartar(true);
    } else {
      onEditItemClick("SiarlVisualizar");
      handleCancel();

    }
  }
  
  const refreshForm = async(id: any = undefined) => {
  let idFoto = id;
  if (!idFoto) {
    if (selectedFotografiaResult?.id) {
      idFoto = selectedFotografiaResult?.id;
    } else {
      return;
    }
  }
      const foto = await getFotoo(idFoto);

    const objToShow = {
        ...foto,
        data_registo: foto?.data_registo? new Date(foto?.data_registo):null,
        tecnicoRegisto: await GetUserNameTecnico(foto?.utilizador_id)
      }

    selectFotografiaResult(objToShow);
    setFotografiaObliquaSelected(objToShow);
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlFotografiaSearch", value: [foto.id] }, '*')
}

  const [deleteClicked, setDeleteClicked] = useState(false);

  const [estadoFalse, setEstado] = useState(false)
  const [estadoTrue, setEstadoTrue] = useState(true)

  const [canUpdate, setCanUpdate] = useState(false);
  const [canInsert, setCanInsert] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [viewMode, setViewMode] = useState(false);

  useEffect(() => {
    setCanUpdate(hasPermission(PERMISSION_ATUALIZAR_FO_REGISTO_VOO));
    setCanInsert(hasPermission(PERMISSION_INSERIR_FO_REGISTO_VOO))
  }, [])

  useEffect(() => {
    setViewMode(!editMode && sessionStorage.getItem("novaFoto") != 'true');
  }, [])

  const handleDescartar = async (e: any) => {

    setOpenModalDescartar(false)
    setDeleteClicked(false);
   const fotoObliquaSelected = getFotografiaObliquaSelected()
   const userId = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '')
      ?.user?.id


      const dataToSend : any = {
        id: fotoObliquaSelected?.id ?? undefined,
        status: estadoFalse
      }
    
  
        let ProcessoSaved: any
      if (fotoObliquaSelected?.id) {
           postMessage({ type: 'showLoader' }, '*')
            ProcessoSaved = await updateRegistoVoo(dataToSend)
            selectFotoResult({
              ...selectedFotoResult,
              ...ProcessoSaved,})
            postMessage({ type: 'hideLoader' }, '*')
  
            }
    SessionStorageService.removeItem("firstCallFotoObliqua");
    postMessage({ type: 'form', value: 'pesquisarFotoObliqua' }, '*')


}

     const userLocal = JSON.parse(
    localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}')

  const handleSelect = (e: TabStripSelectEventArguments) => {
    if (e.selected > 0 && !selectedFotografiaResult) {
      postMessage({type:"infoMsg", value: {message: "É necessário gravar primeiro."}})
      return;
    }
    setSelected(e.selected)
  
  }

  const selectMapAction = async (item: any) => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    if (item.key == "removerArea") {
      setCoordenadasPoligono(undefined);
      const fotografiaSelected = getFotografiaObliquaSelected()
      await deleteGeomApoio(fotografiaSelected?.id);
      iframe.contentWindow?.postMessage(
        { type: 'SiarlFotografiaSearch', value: [fotografiaSelected?.id] },
        '*'
      )
      postMessage({ type: "sucessMsg" }, "*");
    } else {
      
      iframe.contentWindow?.postMessage({ type: item.key, value: 'true' }, '*')
    }
  }


  const voltar = () => {
    clearFotoObliqua();
    postMessage({ type: 'form', value: 'pesquisarFotoObliqua' }, '*')
    postMessage({ type: 'stopSlideShowPhoto' }, '*');
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlClearFotoAereaLine", value: "true" }, '*')
  }

  const handleSubmit = async (e: any) => {
        postMessage({ type: 'showLoader' }, '*')
        try {
            let objToSend = {}
            const formValues: any = e?.values;
            let  arquivos:any[] = formValues?.arquivo;
            let photos = [];
             if(arquivos && arquivos.length>0 ){
                photos =  arquivos.filter((_arquivo:any)=>(PHOTO_FORMAT.includes(_arquivo.extension.toUpperCase())))
                arquivos = arquivos.filter((_arquivo:any)=>(!PHOTO_FORMAT.includes(_arquivo.extension.toUpperCase())))
                 
                objToSend = {arquivos};
               }

            let registoVoo: any;
            const fotoObliqua =selectedFotoResult?.id

            if (selectedFotografiaResultId) {
              formValues['id'] = selectedFotografiaResultId;
              formValues['tecnicoRegisto'] = await GetUserNameTecnico(selectedFotoResult?.utilizador_id);
              registoVoo = await updateRegistoVoo({...formValues })

            }else{
              formValues['tecnicoRegisto'] = await GetUserNameTecnico(undefined);
              registoVoo = await saveRegistoVoo({...formValues});

            }        

               const foto = await saveFoto({
                ...objToSend,
                id_registo_voo: registoVoo?.id
            })
            const unicIdVoo = uuidv4();
             selectFotoPathResult({...selectedFotoPathResult, ...foto});
             contadorUpload = photos.length;
             if(photos){
              for(let i=0; i< photos.length; i++) {
                const photo = photos[i];
                const photoBase64 =  await blobToBase64(photo.getRawFile());
                const dataToSend = {
                  nomeArquivo: photo.name,
                  extensao: photo.extension,
                  foFotoBase64: photoBase64,
                  idRegistoVoo: registoVoo?.id,
                  folder: registoVoo?.id + "_" + unicIdVoo
                }
                const resultFoto = await uploadFoto(dataToSend);
                contadorUpload--;
                if (resultFoto.latitude && resultFoto.longitude) {
                const latLongMap = proj4(EPSG_GLOBAL, EPSG_MAP, [resultFoto.longitude?? 0,resultFoto.latitude?? 0]);
                const dataToSendLatLong = {
                    longitude: latLongMap[0],
                    latitude: latLongMap[1],
                    id: resultFoto.id
                  }
                  const iframe: any = document.getElementById('iframeGeoPortal')
                  iframe.contentWindow?.postMessage(
                      { type: 'SiarlFotoObliquaSee', value: dataToSendLatLong },
                      '*'
                  )
                }
                if (contadorUpload == 0) {
                    await createLineFromPoints(registoVoo?.id);
                    const iframe: any = document.getElementById('iframeGeoPortal')
                    iframe.contentWindow?.postMessage(
                        { type: 'SiarlFotoAereaLine', value: [selectedFotografiaResultId] },
                        '*'
                    );
                    
                }
              }       
            }

      refreshForm(registoVoo.id);
      
      if (foto) {
          setTimeout(() => {
            postMessage({ type: 'sucessMsg' }, '*')
          }, 500)
        }

        } catch (error) {
            console.log({ error });
            handleGenericError(error);
        } finally {
            postMessage({ type: 'hideLoader' }, '*')
        }
    }

  const [selectedOption, setSelectedOption] = useState(null)

  const handleOptionSelect = (option: any) => {
    setSelectedOption(option)
    // You can perform any additional actions when an option is selected here
  }

  const mapActionsSelectItemChange = (item: any) => {
    selectMapAction(item.value)
  }

  const itemRender = (props: { item: any; itemIndex: number }) => {
    const isTitleItem = props.item.key === 'titulo'
    if (isTitleItem) {
      // Item de título personalizado
      return (
        <div className={styles.DropDownButtonTitulo}>
          <span>{props.item.text}</span>
        </div>
      )
    } else {
      // Demais itens
      return (
        <div className={styles.DropDownButtonItem}>
          <span>{props.item.text}</span>
        </div>
      )
    }
  }

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/RegistarFotografia" });
  }, []);

  const onItemClick = (event: any) => {
    selectMapAction(event.item)
  }

  const optionsEditar = [
    {
      key: 'SiarlEditar',
      text: 'Editar',
    },
    {
      key: 'SiarlApagar',
      text: 'Apagar',
    },
    
  ]

  useEffect(() => {
    if (!selectedFotoResult?.id) {
      return;
    }
  }, [])

    const onEditItemClick= (event:any) => {
    if (event == "SiarlEditar" || event?.item?.key == "SiarlEditar") {
      setEditMode(true);
      setViewMode(false);
      return;
    }
    if (event == "SiarlVisualizar") {
      setEditMode(false);
      setViewMode(true);
    }
    if (event?.item?.key == "SiarlApagar") {
      setDeleteClicked(true);
      setOpenModalDescartar(true);
    }
  }

  useEffect(() => {
    if (viewMode) {
      sessionStorage.removeItem("novaFoto");
    }
  })

  const openAjuda = () => {
    window.open(URL_AJUDA_APOIO_GESTAO_DESCONFORMIDADE, "_blank")
  }

  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <div>
          <div className={styles.nomeDoModulo}>Foto aérea</div>
          <div className={styles.tituloDaPagina}>
           {!selectedFotografiaResult || !selectedFotografiaResult?.id ? 'Nova Fotografia' : (editMode ? 'Editar Fotografia' : 'Fotografia')}
          </div>
        </div>

        <div>
            {canUpdate && (viewMode || editMode) && (sessionStorage.getItem(" novaFoto") != 'true') ? 
          <DropDownButton
            items={optionsEditar}
            className={styles.btnImprimir}
            style={{marginTop: '-4px'}}
            iconClass="icon-siarl icon-editar"
            onItemClick={onEditItemClick}
            fillMode={'flat'}
            itemRender={itemRender}
            title="Editar"
          ></DropDownButton>
          : ''}

          <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
            <span className="icon-siarl icon-ajuda"></span>
          </button>

          <button className={styles.btnFechar} onClick={voltar} title="Fechar">
            <span className="icon-siarl icon-fechar"></span>
          </button>
        </div>
      </div>


            {openModalDescartar ? (
          <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={cancelModalDescartar}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                  <fieldset>
                    <span className="textDescartar">{deleteClicked ? 'Apagar' : 'Cancelar'} registo  </span>
                    <br></br>
                    <span>{deleteClicked ? 'Ao apagar este registo deixará de ter acesso, sendo necessária uma intervenção rigorosa para realizar a sua recuperação.' : 'Ao cancelar este registo irá perder os dados inseridos até ao momento.'}</span>
                      <br></br>
                    <span>{deleteClicked ? 'Tem a certeza que pretende apagar?' : 'Tem a certeza que pretende cancelar?'}</span>
                  </fieldset>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={cancelModalDescartar}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={handleDescartar}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}
      
      
      <Form
        initialValues={
          {
            ...selectedFotoResult ?? undefined,
            data_registo : selectedFotoResult?.data_registo ?? new Date(),
            data_voo : selectedFotoResult?.data_voo ? new Date(selectedFotoResult?.data_voo) : undefined
          } 
        }
        onSubmitClick={handleSubmit}
        render={(formRenderProps) => (
          <FormElement>
            <TabStrip
              selected={selected}
              onSelect={handleSelect}
              className={`${styles.innerStyleTabContent} ${styles.backgroundWhite} ${viewMode? 'tabsVisualizar' : ''}`}
            >
              <TabStripTab title="Identificação">
                <FotografiaIdentificacao viewMode={viewMode} />
              </TabStripTab>
            
            </TabStrip>


          {(editMode || sessionStorage.getItem("novaFoto") == 'true')
            && !viewMode ?
            <div
              className={`${styles.footer} ${styles.justify_multiple_children}`}
            >
              <Button className={styles.btnSeguinte} type="button" onClick={handleOpenModalDescartar} title='Cancelar'>
                <span className="icon-siarl icon-fechar"></span>
                <span className={styles.rotulo}>
                  {' '}&nbsp;{' '}
                  Cancelar
                </span>
              </Button>

              <Button
                className={styles.btnSubmeter}
                onClick={formRenderProps.onSubmit}
                title='Gravar'
              >
                <span className="icon-siarl icon-submeter btnIconRegistar"></span>
                <span className={styles.rotulo}>
                  {' '}&nbsp;{' '}
                  Gravar
                </span>
              </Button>
            </div>
            :''}
          </FormElement>
        )}
      />
    </div>
  )
}
