import { Input, InputProps } from '@progress/kendo-react-inputs'
import { Error } from '@progress/kendo-react-labels'
import { useEffect } from 'react'

import { useOcorrenciaStore } from '../../../stores/ocorrencias'

import type { FieldRenderProps } from '@progress/kendo-react-form'

export default function ValidatedIdentificacaoInput(fieldRenderProps: FieldRenderProps) {
  const addIdentificacaoTabValidatedField = useOcorrenciaStore(state => state.addIdentificacaoTabValidatedField)
  
  useEffect(() => {
    addIdentificacaoTabValidatedField({ [fieldRenderProps.name]: !!fieldRenderProps.value })
  }, [fieldRenderProps.value])

  return (
    <div>
      <Input {...fieldRenderProps as InputProps} />
      {fieldRenderProps.visited && fieldRenderProps.validationMessage && <Error>{fieldRenderProps.validationMessage}</Error>}
    </div>
  )
}
