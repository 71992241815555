import {
  Grid,
  GridCellProps,
  GridColumn,
  GridDataStateChangeEvent,
} from '@progress/kendo-react-grid'
import type { ConsultarMapasResult } from './ListarGeovisualizadores'
import { useState } from 'react'
import {
  GroupDescriptor,
  SortDescriptor,
  process,
} from '@progress/kendo-data-query'
import styles from '../../styles/geovisualizadores/Geovisualizadores.module.scss'
import ImagemMapaCell from './ImagemMapaCell'
import { ActionsCell } from './ActionsCell'
import ShareModal from '../viewer/ShareModal'
import { useGeovisualizadoresStore } from '../../stores/geovisualizador'
import { GV_NAMESPACE, PERMISSION_GEOVISUALIZADOR_PERFIL_BASICO, PERMISSION_GEOVISUALIZADOR_PERFIL_COMPLETO_EDICAO, PERMISSION_GEOVISUALIZADOR_PERFIL_COMPLETO_LEITURA, PERMISSION_GEOVISUALIZADOR_PERFIL_INTERMEDIO_EDICAO, PERMISSION_GEOVISUALIZADOR_PERFIL_INTERMEDIO_LEITURA, PERMISSION_GEOVISUALIZADOR_PERFIL_TOTAL } from '../../utils/constants'
import { get, hasPermission } from '../../services/utils'
import SharedMapModal from './SharedMapModal'
import DeleteSharedMapModal from './DeleteSharedMapModal'

type DataItem =
  ConsultarMapasResult[number]['gvSubTemas'][number]['gvMapaPartilhados'][number]

type GvMapaPartilhado = {
  gvMapaPartilhadoEstado: {
    id: number
    descricao: string
  }
  tema: {
    id: number
    descricao: string
  }
  sub_tema: {
    id: number
    descricao: string
  }
  id: number
  id_utilizador: number
  descricao: string
  data_registo: string
  data_publicacao: string
  img_mapa: string
  titulo: string
  link_gerado: string
  id_gv_estado: number
  id_gv_tema: number
  id_gv_sub_tema: number
  uid_mapa: string
  base64: string
}

type Props = {
  mapas: ConsultarMapasResult[number]['gvSubTemas'][number]['gvMapaPartilhados']
}

export default function MapaItemGrid({ mapas }: Props) {
  const [dataState, setDataState] = useState<{
    skip?: number
    take?: number
    sort?: SortDescriptor[]
    group?: GroupDescriptor[]
  }>({
    skip: 0,
    take: 20,
    sort: [],
    group: [],
  })
  const [dataResult, setDataResult] = useState(process(mapas, dataState))
  const [showShareModal, setShowShareModal] = useState(false)
  const [showViewModal, setShowViewModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const selectGVResult = useGeovisualizadoresStore(
    (state) => state.selectGVResult
  )
  const selectGVResultId = useGeovisualizadoresStore(
    (state) => state.selectGVResultId
  )

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    setDataResult(process(mapas, event.dataState))
    setDataState(event.dataState)
  }

  const toggleShareModal = () => {
    setShowShareModal((previousState) => !previousState)
  }

  const toggleViewModal = () => {
    setShowViewModal((previousState) => !previousState)
  }

  const toggleDeleteModal = () => {
    setShowDeleteModal((previousState) => !previousState)
  }

  const handleCopyClick = (dataItem: DataItem) => async () => {

    try {
    postMessage({ type: 'showLoader' }, '*')
      const result = await get<GvMapaPartilhado>(`${GV_NAMESPACE}/${dataItem.id}`)
  
      setShowViewModal(true)
      if (result) selectGVResult(result)
      selectGVResultId(dataItem.id)
    } catch (error) {
      postMessage({ type: 'errorMsg', value:{message:"Não foi possivel efetuar esta operação"} }, '*')
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  const handleShowClick = (dataItem: DataItem) => async () => {
    const result = await get<GvMapaPartilhado>(`${GV_NAMESPACE}/${dataItem.id}`)
    window.open(result?.link_gerado ?? "", "_blank");
    return; 
  }

  const handleEditClick = (dataItem: DataItem) => async () => {
    try {
      postMessage({ type: 'showLoader' }, '*')
      const result = await get<GvMapaPartilhado>(`${GV_NAMESPACE}/${dataItem.id}`)
  
      setShowShareModal(true)
      if (result) selectGVResult(result)
      selectGVResultId(dataItem.id)
    } catch (error) {
      postMessage({ type: 'errorMsg', value:{message:"Não foi possivel efetuar esta operação"} }, '*')
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  const handleDeleteClick = (dataItem: DataItem) => () => {
    setShowDeleteModal(true)
    selectGVResultId(dataItem.id)
  }

  const canSeeTema = hasPermission(PERMISSION_GEOVISUALIZADOR_PERFIL_BASICO) ||
  hasPermission(PERMISSION_GEOVISUALIZADOR_PERFIL_COMPLETO_EDICAO) ||
  hasPermission(PERMISSION_GEOVISUALIZADOR_PERFIL_COMPLETO_LEITURA) ||
  hasPermission(PERMISSION_GEOVISUALIZADOR_PERFIL_INTERMEDIO_EDICAO) ||
  hasPermission(PERMISSION_GEOVISUALIZADOR_PERFIL_INTERMEDIO_LEITURA) ||
  hasPermission(PERMISSION_GEOVISUALIZADOR_PERFIL_TOTAL);

  const conditionalProps = canSeeTema
    ? { 
      onEditClick: handleEditClick,
      onDeleteClick: handleDeleteClick
     } 
    : {};

  const CustomActionCell = (props: GridCellProps) => (
    <ActionsCell
      {...props}
      onCopyClick={handleCopyClick}
      onShowClick={handleShowClick}
      {...conditionalProps}
    />
  )

  return (
    <>
      <Grid
        className={styles.subtemaGrid}
        sortable={true}
        onDataStateChange={dataStateChange}
        data={dataResult.data.length > 0 ? dataResult : mapas}
        {...dataState}
        pageable={{
          buttonCount: 5,
          info: true,
          type: 'numeric',
          pageSizes: true,
          previousNext: true,
        }}
      >
        <GridColumn cell={ImagemMapaCell} width={180} title="Imagem" />
        <GridColumn field="titulo" title="Título" />
        <GridColumn field="descricao" title="Descrição" />
        <GridColumn cell={CustomActionCell} title="Ações" />
      </Grid>
      {showShareModal && <ShareModal toggleDialog={toggleShareModal} />}
      {showViewModal && <SharedMapModal toggleDialog={toggleViewModal} />}
      {showDeleteModal && (
        <DeleteSharedMapModal toggleDialog={toggleDeleteModal} />
      )}
    </>
  )
}
