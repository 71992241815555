import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@progress/kendo-react-buttons'
import { Form, FormElement } from '@progress/kendo-react-form'
import { Stepper } from '@progress/kendo-react-layout'
import { useCallback, useEffect, useState } from 'react'

import { Fade } from '@progress/kendo-react-animation'
import {
  Notification,
  NotificationGroup,
} from '@progress/kendo-react-notification'
import { useNavigate } from 'react-router'
import { DocumentoListagem } from '../../../components/documentos/registar/documento/documento'
import { OcorrenciaTerrenoConclusao } from '../../../components/ocorrencia/terreno/Conclusao'
import { OcorrenciaTerrenoDetalhe } from '../../../components/ocorrencia/terreno/Detalhes'
import { OcorrenciaTerrenoIdentificacao } from '../../../components/ocorrencia/terreno/Identificacao'
import { coordenadasPoligono, post } from '../../../services/utils'
import styles from '../../../styles/ocorrencia/terreno/Registar.module.scss'
import { AUTH_LOCALSTORAGE, SAVE_OCORRENCIA } from '../../../utils/constants'
import ReactGA from 'react-ga4';

export default function RegistarOcorrenciaTerreno() {
  const [step, setStep] = useState(0)
  const [formState, setFormState] = useState({})
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const navigate = useNavigate()
  const saveOcorrencia = async (data: any) =>
    await post<any, any>(`${SAVE_OCORRENCIA}`, data)
  const [steps, setSteps] = useState([
    {
      label: 'Identificação',
      isValid: undefined,
    },
    {
      label: 'Detalhes',
      isValid: undefined,
    },
    {
      label: 'Anexos',
      isValid: undefined,
    },
    {
      label: 'Conclusão',
      isValid: undefined,
    },
  ])

  const stepPages = [
    <OcorrenciaTerrenoIdentificacao />,
    <OcorrenciaTerrenoDetalhe />,
    <DocumentoListagem viewMode={false} />,
    <OcorrenciaTerrenoConclusao props={formState} />,
  ]

  const lastStepIndex = steps.length - 1
  const isLastStep = lastStepIndex === step
  const isPreviousStepsValid =
    steps
      .slice(0, step)
      .findIndex((currentStep) => currentStep.isValid === false) === -1
  const onStepSubmit = useCallback(
    async (event: { isValid: any; values: any }) => {
      const { isValid, values } = event
      const currentSteps = steps.map((currentStep, index) => ({
        ...currentStep,
        isValid: index === step ? isValid : currentStep.isValid,
      }))
      setSteps(currentSteps)
      setStep(() => Math.min(step + 1, lastStepIndex))
      setFormState(values)
      if (isLastStep && isPreviousStepsValid && isValid) {
        const userId = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '')
          ?.user?.id
        const dataToSend = {
          utilizador_id: userId,
          entidade_id: 7,
          data_ocorrencia: values.dataHoraOcorrencia,
          data_registo: values.dataHoraRegistro,
          freguesia_cod: '080402',
          tipo_medida_mitigacao_id: 0,
          tipo_pessoas_risco_id: 0,
          tipo_danos_sistemas_naturais_id: 0,
          conseq_danos_infraestruturas: true,
          observacoes: values.observacao,
          flatCoordinates: coordenadasPoligono,
          //flatCoordinates: "23559.491961167398 218336.84896618803,69199.4919611674 213296.84896618803,56879.4919611674 218336.84896618803,81239.4919611674 242416.84896618803,67239.4919611674 186976.84896618803,23559.491961167398 218336.84896618803"
        }
        console.log(JSON.stringify(values))
        try {
          await saveOcorrencia(dataToSend)
          setTimeout(() => {
            postMessage({ type: 'sucessMsg' }, '*')
          }, 500)

          navigate('/')
        } catch {
          setError(true)
        }
      }
    },
    [steps, isLastStep, isPreviousStepsValid, step, lastStepIndex]
  )

  const onPrevClick = useCallback(
    (event: { preventDefault: () => void }) => {
      event.preventDefault()
      setStep(() => Math.max(step - 1, 0))
    },
    [step, setStep]
  )

  const voltar = () => {
    postMessage({ type: 'form', value: 'consultaOcorrencias' }, '*')
  }

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/RegistarOcorrencia" });
  }, []);

  return (
    <>
      <div className={styles.registarOcorrencia}>
        <div className={styles.menu}>
          <div
            className={styles.container}
            style={{ paddingLeft: 24, paddingRight: 24 }}
          >
            <button onClick={voltar}>
              <FontAwesomeIcon icon={faArrowLeft} size="xl" />
              Voltar para Ocorrências
            </button>
            <Stepper value={step} items={steps} />
          </div>
        </div>
        <div
          className={styles.container}
          style={{
            paddingLeft: 24,
            paddingBottom: 16,
            paddingRight: 24,
          }}
        >
          <Form
            initialValues={formState}
            onSubmitClick={onStepSubmit}
            render={(formRenderProps) => (
              <div>
                <FormElement
                  style={{
                    width: '100%',
                    maxWidth: 980,
                  }}
                >
                  {stepPages[step]}
                  <span
                    style={{
                      marginTop: '40px',
                    }}
                  />
                  <div
                    style={{
                      justifyContent: 'space-between',
                      alignContent: 'center',
                    }}
                  >
                    <div
                      style={{
                        marginRight: '16px',
                        marginTop: 80,
                        marginBottom: 80,
                      }}
                    >
                      {step !== 0 ? (
                        <Button
                          style={{
                            marginRight: '16px',
                          }}
                          onClick={onPrevClick}
                        >
                          <FontAwesomeIcon icon={faArrowLeft} /> Anterior
                        </Button>
                      ) : undefined}
                      <Button
                        className={styles.btnSubmit}
                        disabled={isLastStep ? !isPreviousStepsValid : false}
                        onClick={formRenderProps.onSubmit}
                      >
                        {isLastStep ? 'Submit' : 'Seguinte'}{' '}
                        <FontAwesomeIcon icon={faArrowRight} />
                      </Button>
                    </div>
                  </div>
                </FormElement>
              </div>
            )}
          />
        </div>
        <NotificationGroup
          style={{ top: 0, left: '25%', transform: 'translateX(-50%)' }}
        >
          <Fade>
            {success && (
              <Notification
                type={{ style: 'success', icon: true }}
                closable={true}
                onClose={() => setSuccess(false)}
              >
                <span>Salvo com sucesso.</span>
              </Notification>
            )}
            {error && (
              <Notification
                type={{ style: 'error', icon: true }}
                closable={true}
                onClose={() => setError(false)}
              >
                <span>Ocorreu um erro ao gravar.</span>
              </Notification>
            )}
          </Fade>
        </NotificationGroup>
      </div>
    </>
  )
}
