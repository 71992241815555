import { create } from 'zustand'

type DhPatrimonioState = {
    selectedDhPatrimonioResult?: unknown
    selectDhPatrimonioResult: (payload: unknown) => void
    selectedDhPatrimonioResultId?: number
    selectDhPatrimonioResultId: (payload?: number) => void
}

export const useDhPatrimonioStore = create<DhPatrimonioState>((set, get) => ({
    selectedDhPatrimonioResult: null,
    selectDhPatrimonioResult: (payload: unknown) => set({ selectedDhPatrimonioResult: payload }),
    selectDhPatrimonioResultId: (payload?: number) => set({ selectedDhPatrimonioResultId: payload }),
}))
