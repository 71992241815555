import { Button, DropDownButton } from '@progress/kendo-react-buttons'
import { Form, FormElement } from '@progress/kendo-react-form'
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from '@progress/kendo-react-layout'
import { useEffect, useLayoutEffect, useState } from 'react'

import { MouseEvent as ReactMouseEvent } from 'react'
import {
  GetUserNameTecnico,
  b64toBlob,
  clearAcao,
  coordenadasPoligono,
  del,
  entidadeUser,
  fillCombosAuxiliares,
  get,
  getAcaoSelected,
  getEntidadeSelected,
  handleGenericError,
  hasPermission,
  post,
  preventPropsNullValues,
  put,
  setAcaoSelected,
  setCoordenadasPoligono,
  setCoordenadasSelected,
  tecnico,
  useCombos
} from '../../services/utils'
import { useAcaoStore } from '../../stores/acao'
import styles from '../../styles/intervencao/Intervencao.module.scss'
import {
  AUTH_LOCALSTORAGE,
  DELETE_INTERVENCAO_BY_ID,
  GET_URL_FROM_FILE_DOC,
  IMPORT_GEOM_ACAO,
  PERMISSION_INTERVENCAO_PERFIL_BASICO,
  PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO,
  PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA,
  PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO,
  PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_LEITURA,
  PERMISSION_INTERVENCAO_PERFIL_TOTAL,
  PERMISSION_ATUALIZAR_ACAO,
  PERMISSION_INSERIR_ACAO,
  PESQUISAR_ACAO,
  RELATORIO_ACAO,
  SAVE_NEW_ACAO,
  UPDATE_ACAO,
  clearLayers,
  URL_AJUDA_INTERVENCOES_ACAO,
  REMOVER_AREA_INT_ACAO,
  INT_ACAO_POINT_NAME,
  CURRENT_TIMEZONE,
  LAYER_INDEX_INT_ACAO,
  OPERATIONAL_LAYER
} from '../../utils/constants'
import { AssociacaoListagem } from '../associacao/Associacao'
import { DocumentoListagem } from '../documentos/registar/documento/documento'
import IdentificacaoAcao from './IdentificaoAcao'
import ImportarGeometriaComponent from '../apoiogestao/registar/ImportarGeometriaForm'
import { Dialog } from '@progress/kendo-react-dialogs'
import SessionStorageService from '../../services/SessionStorageService'
import useClearMap from '../../hooks/useClearMap'
import { async } from 'q'
import html2canvas from 'html2canvas'
import ReactGA from 'react-ga4';
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais'
import moment from 'moment'
import { IdentificacaoAcaoExecucao } from './IdentificacaoAcaoExecucao'
import { parse } from 'date-fns'
type Props = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
}
type AcaoForm = {
  id: number
  id_interve: number
  id_rh: number
  id_concelho: number
  id_fregues: number
  id_tipo_massa: number
  id_tipo_n1: number
  id_tipo_n2: number
  id_tipo_n3: number
  id_tipo_n4: number
  id_tipo_acao: number
  id_natureza_acao: number
  id_int_acao_componente: string
  designacao: string
  descricao: string
  id_tipo_contratacao: number
   data_tutela: string
  data_tc: string
  n_filedoc: number
  n_processo_aquisitivo: number
  id_estado: number,
  acaoEstado: any,
  data_ini_p: any,
  data_con_p: any
  data_ini_e: any
  data_con_e: any
  inv_previs: number
  val_contrat: number
  inv_realizado: number
  obs: string
  flatCoordinates: string
  id_int_acao_planeamento: number
  tipo_engenharia: number
  designacao_candidatura: string
  numero_candidatura: number
  lstEntidades :  number[]
  local: string | undefined
  geom_imported: string | undefined
  utilizador_id: number
  lstIntervencaoAcao: any
  id_proc_contratacao: any
  lstExecucaoAcao: any
  valor_por_executar : any
  taxa_execucao : any
  data_adjudicacao: Date
  lstIndicadores: any
  revisao_precos: any
  lstResumoExecucao: any
  data_registo : any
  id_entidade: any
}

const TOTAL_TABS = 3

export default function RegistarAcao({ onCloseClick }: Props) {
  const [isMobile, setIsMobile] = useState(false);
  const [selected, setSelected] = useState<number>(0)
  const TAB_IDENTIFICACAO = 0;
  const TAB_EXECUCAO = 1;
  
  const deleteAcao = (id: number) => del(`${DELETE_INTERVENCAO_BY_ID}/${id}`, {})

  const [deleteClicked, setDeleteClicked] = useState(false);

  const [canUpdate, setCanUpdate] = useState(false);
  const [canInsert, setCanInsert] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [openRemoveAreaModal, setOpenRemoveAreaModal] = useState<any>()

  

   useEffect(() => {
    setViewMode(!editMode && sessionStorage.getItem("novaAcao") != 'true');
  }, [])

  useEffect(() => {
    setCanUpdate(hasPermission(PERMISSION_ATUALIZAR_ACAO));
    setCanInsert(hasPermission(PERMISSION_INSERIR_ACAO))
  }, [])

  const selectedAcaoResult: any = useAcaoStore(
    (state) => state.selectedAcaoResult)

  const selectedAcaoResultId: any = useAcaoStore(
    (state) => state.selectedAcaoResultId
  )
  const [openImportModal, setOpenImportModal] = useState<any>()
  const selectAcaoResult = useAcaoStore((state) => state.selectAcaoResult)
  const selectAcaoResultId = useAcaoStore((state) => state.selectAcaoResult)
 const importGeomIntAcao = async (data: any) =>
    await post<any, any>(`${IMPORT_GEOM_ACAO}`, data)
const getAcaoById = async (id: any) =>
    await get<any, any>(`${PESQUISAR_ACAO}/` + id)
  const saveAcao = async (data: any) =>
    await post<any, any>(`${SAVE_NEW_ACAO}`, data)
  const updateAcao = async (data: any) =>
    await put<any, any>(`${UPDATE_ACAO}`, data)
  const getUrlFileDoc = async (data: any) => await get<any>(`${GET_URL_FROM_FILE_DOC}/` + encodeURIComponent(data));

  const relatorioAcao = async (data: any) =>
    await post<any, any>(`${RELATORIO_ACAO}`, data)

  const removerAreaAcao = async (data: any) =>
    await post<any, any>(`${REMOVER_AREA_INT_ACAO}`, data)

  useEffect(() => {
      
      fillCombosAuxiliares();
  }, []);

   useEffect(() => {
      
      fillCombosAuxiliares();
    
  }, []);

  useEffect(() => {
    
    ReactGA.send({ hitType: "pageview", page: "/RegistarIntAcao" });

  }, []);
  
  useEffect(() => {
    addEventListener('message', (event: MessageEvent) => {
      if (event.data.type == 'CoordenadasSelected') {
        console.log(event.data.value)
        if (event.data.value) {
          const flatPointCoordinates =
            event.data.value.x + ', ' + event.data.value.y
          setCoordenadasSelected(flatPointCoordinates)
        }
      } else if (event.data.type == 'CoordenadasFlatSelected') {
        let polygonString = ''
        const values: any[] = event.data.value
        values?.forEach((value, index) => {
          if (index % 2 == 0) {
            polygonString += value + ' '
          } else {
            polygonString += value + ','
          }
        })
        polygonString = polygonString.substring(0, polygonString.length - 1)
        setCoordenadasPoligono(polygonString)
        console.log(polygonString)
      }
    })
  }, [])

  const handleSelect = (e: TabStripSelectEventArguments) => {
    if (e.selected > 1 && !selectedAcaoResult) {
      postMessage({type:"infoMsg", value: {message: "É necessário gravar primeiro."}})
      return;
    }
    setSelected(e.selected)
  }

  const selectMapAction =  (item: any) => {
    const iframe: any = document.getElementById('iframeGeoPortal')

    if (item.key == "removerArea") {
      setOpenRemoveAreaModal(true)
    } else {
      iframe.contentWindow?.postMessage({ type: item.key, value: getAcaoSelected()?.id, editGpLayer: INT_ACAO_POINT_NAME }, '*')
    }
  }


const removeArea = async () => {
  const iframe: any = document.getElementById('iframeGeoPortal')
  iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: 'true' }, '*')
  setCoordenadasPoligono(undefined);
  if (!selectedAcaoResult?.id) {
    setOpenRemoveAreaModal(false)
    return;
  }
  await removerAreaAcao(selectedAcaoResult?.id);
 
  iframe.contentWindow?.postMessage(
    { type: 'SiarlIntAcaoSearch', value: [selectedAcaoResult?.id] },
    '*'
  )
  refreshForm(selectedAcaoResult?.id)
  setOpenRemoveAreaModal(false)

}


  const voltar = () => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
       {
         type: 'SiarlCoordenadaBoxClose',
         value: 'true',
       },
       '*'
     )
    clearAcaoMap();
    postMessage({ type: 'form', value: 'pesquisarAcao' }, '*');
   
  }
  

  const [clearMap] = useClearMap();
  const clearAcaoMap = () => {
    clearMap(clearLayers["CLEAR_LAYERS_ACAO"]);
    setAcaoSelected(undefined)
    selectAcaoResult(undefined)
  }

  const cancelImport = () => {
    setOpenImportModal(false)
  }

   const importGeo = async (objToSend: any) => {
    postMessage({ type: "showLoader" }, "*");
    if (!objToSend) {
      postMessage({ type: "errorMsg", value: "Não foi possivel salvar" }, "*");
      postMessage({ type: "hideLoader" }, "*");
      return;

    }
    let int_acao;
    let int_acao_id;
    const acaoSelected = selectedAcaoResult ?? getAcaoSelected();
    if (acaoSelected && acaoSelected?.id) {
      int_acao = acaoSelected;
      int_acao_id = acaoSelected?.id;
    } else {
      int_acao = await saveAcao({});
      int_acao_id = int_acao?.id;
      selectAcaoResult(int_acao);
      selectAcaoResultId(int_acao_id);

    }
    try {
      objToSend = { ...objToSend, int_acao_id }
      const result = await importGeomIntAcao(objToSend)
      if (!result) {
        postMessage({ type: "errorMsg", value: "Não foi possivel salvar" }, "*");
        return;
      }

      try {
        int_acao = await getAcaoById(int_acao_id);
      } catch (error) {
        handleGenericError(error,  "Registo salvo mas, Não é possivel mostrar os dados neste momento" );

      } finally {
        int_acao = { ...int_acao, ...objToSend };
        selectAcaoResultId(int_acao_id);
        selectAcaoResult(int_acao);
        setAcaoSelected(int_acao)
        refreshForm(int_acao_id);
      }
      try {
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage(
          { type: 'SiarlIntAcaoSearch', value: [int_acao_id] },
          '*'
        )
      } catch (error) {
        handleGenericError(error, "Registo salvo mas, Não é possivel mostrar os dados neste momento")
      }

      setOpenImportModal(false)
    } catch (err) {
      handleGenericError(err)

    } finally {
      postMessage({ type: "hideLoader" }, "*");
    }
  }

  const [openModalDescartar, setOpenModalDescartar] = useState<any>()

  const cancelModalDescartar = () => {
    setOpenModalDescartar(false)
  }

  const handleCancel= ()  => {

        refreshForm();
        postMessage({ type: "form", value: "" }, "*");
        setTimeout(() => {
            postMessage({ type: "form", value: "registarAcao" }, "*");
          }, 10)
    }

   const handleOpenModalDescartar = () => {
    if (
      sessionStorage.getItem('novaAcao') &&
      sessionStorage.getItem('novaAcao') == 'true'
    ) {
      setOpenModalDescartar(true);
    } else {
      onEditItemClick("SiarlEditar");
      handleCancel();

    }  }

  const refreshForm = async(id: any = undefined) => {

    const acaoSelected = getAcaoSelected()

    let iAcao = id;
    if (!iAcao) {
      if (acaoSelected?.id) {
        iAcao = acaoSelected?.id
      } else {
        return;
      }
    }
    const acao = await getAcaoById(iAcao);
    const tecnico = await GetUserNameTecnico(acao.utilizador_id);

    const objToShow = {
      ...acao,
      utilizador_id: tecnico,
      id: acao.id,
      n_processo_aquisitivo: acao.n_processo_aquisitivo ?? undefined,
      n_filedoc: acao.n_filedoc ?? undefined,
      designacao: acao.designacao ?? undefined,
      acaoEstado: acao.acaoEstado ?? undefined,
      nuts1: acao.nuts1 ?? undefined,
      nuts2: acao.nuts2 ?? undefined,
      nuts3: acao.nuts3 ?? undefined,
      distrito : acao.distrito ?? undefined,
      concelho: acao.mobilizacao ?? undefined,
      freguesia: acao.freguesia ?? undefined,
      arh: acao.arh ?? undefined,
      capitania : acao.capitania ?? undefined,
      massaagua: acao.massaagua ?? undefined,

      
      data_ini_p : acao.data_ini_p? moment.utc(new Date(acao?.data_ini_p)).tz(CURRENT_TIMEZONE).format("DD/MM/yyyy") :null,
      data_con_p : acao.data_con_p? moment.utc(new Date(acao?.data_con_p)).tz(CURRENT_TIMEZONE).format("DD/MM/yyyy") :null,
      data_ini_e : acao.data_ini_e? moment.utc(new Date(acao?.data_ini_e)).tz(CURRENT_TIMEZONE).format("DD/MM/yyyy") :null,
      data_con_e : acao.data_con_e? moment.utc(new Date(acao?.data_con_e)).tz(CURRENT_TIMEZONE).format("DD/MM/yyyy") :null,
      data_tutela : acao.data_tutela? moment.utc(new Date(acao?.data_tutela)).tz(CURRENT_TIMEZONE).format("DD/MM/yyyy") :null,
      data_tc : acao.data_tc? moment.utc(new Date(acao?.data_tc)).tz(CURRENT_TIMEZONE).format("DD/MM/yyyy") :null,



      id_tipo_n1: acao?.tipo_n1 ?? undefined,
      id_tipo_n2: acao?.tipo_n2 ?? undefined,
      id_tipo_n3: acao?.tipo_n3 ?? undefined,
      id_proc_contratacao: acao?.proc_contratacao ?? undefined,


      tipo_engenharia : acao.tipo_engenharia ?? undefined,
      id_natureza_acao : acao.id_natureza_acao ?? undefined,
      id_int_acao_componente : acao.int_acao_componente ?? undefined,
      id_int_acao_planeamento: acao.int_acao_planeamento ?? undefined,
      comunitario : acao.comunitario ?? undefined,
      nacional : acao.nacional ?? undefined,
      numero_candidatura : acao.numero_candidatura ?? undefined,
      designacao_candidatura : acao.designacao_candidatura ?? undefined,
      inv_previs : acao.inv_previs ?? undefined,
      val_contrat : acao.val_contrat ?? undefined,
      inv_realizado : acao.inv_realizado ?? undefined,
      lstEntidades: preventPropsNullValues(acao.lstEntidades, "nome") ?? undefined,
    }

    selectAcaoResult(objToShow);
    setAcaoSelected(objToShow);
    const iframe: any = document.getElementById('iframeGeoPortal')

    iframe.contentWindow?.postMessage({ type: "SiarlIntAcaoSearch", value: [acao?.id] }, '*')
}

  const [estadoFalse, setEstado] = useState(false)
  const [estadoTrue, setEstadoTrue] = useState(true)


  const handleDescartar = async (e: any) => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
       {
         type: 'SiarlCoordenadaBoxClose',
         value: 'true',
       },
       '*'
     )
     iframe.contentWindow?.postMessage(
      {
        type: 'SiarlRemoveSelection',
        value: 'true',
      },
      '*'
    )
    setOpenModalDescartar(false)
    setDeleteClicked(false);

   const acaoSelected = getAcaoSelected()

    const dataToSend : any = {
      id: acaoSelected?.id ?? undefined,
      status: estadoFalse,
    }

      let AcaoSaved: any
   
    if (acaoSelected?.id) {
      postMessage({ type: 'showLoader' }, '*')
          AcaoSaved = await updateAcao(dataToSend)
          selectAcaoResult({
            ...selectedAcaoResult,
            ...AcaoSaved,})
          }
          postMessage({ type: 'hideLoader' }, '*')

   SessionStorageService.removeItem("firstCallAcao")
   postMessage({ type: 'form', value: 'pesquisarAcao' }, '*')

}


  const isUUID = (str: string) => {
    const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidPattern.test(str);
  };

  const handleSubmit = async (e: any) => {
    if (!isFormValid) {
      return
    }

    const lstIntervencaoAcao = SessionStorageService.getItem("dataIntervencaoAcao");

    const formattedIntervencaoAcao = lstIntervencaoAcao?.map((item : any) => ({
      id: isUUID(item?.id) ? undefined : item?.id,
      id_interv: item?.id_interv,
      designacao : item?.designacao ?? undefined,
      codigo: item?.codigo ?? undefined,
      id_int_pal_xxi_tip_nivel_1: item?.id_int_palXXI_tip_nivel1?.id ?? undefined,
      id_int_pal_xxi_tip_nivel_2: item?.id_int_palXXI_tip_nivel2?.id ?? undefined,
      id_int_pal_xxi_tip_nivel_3: item?.id_int_palXXI_tip_nivel3?.id ?? undefined,
      percentagem: item?.percentagem ?? undefined
  }));

   const lstAcaoExecucao = SessionStorageService.getItem("dataAcaoExecucao");
   let formattedAcaoExecucao : any; 
    if(lstAcaoExecucao != undefined){
       formattedAcaoExecucao = lstAcaoExecucao?.map((item : any) => ({
        id: isUUID(item?.id) ? undefined : item?.id,
        data_fatura: item?.data_fatura ? parse(item.data_fatura, 'dd/MM/yyyy', new Date()) : undefined,
        numero_fatura: item?.numero_fatura ?? undefined,
        descricao: item?.descricao ?? undefined,
        valor_total: item?.valor_total === "" ? undefined : item?.valor_total,
      }));
  }

  const lstIndicadores = SessionStorageService.getItem("dataIndicadores");

  const formattedIndicadores = lstIndicadores?.map((item : any) => ({
    id: isUUID(item.id) ? undefined : item.id,
    id_indicador: item?.id_indicador?.id ?? undefined,
    valor: item?.valor === "" ? undefined : item?.valor,
}));

  const lstResumoExecucao = SessionStorageService.getItem("dataResumoExecucao");

  const formattedResumoExecucao = lstResumoExecucao?.map((item : any) => ({
    id: isUUID(item.id) ? undefined : item.id,
    ano: item?.ano ?? undefined,
    numero_registos: item?.numero_registos ?? undefined,
    valor: item?.valor ?? undefined
}));

  const inv_realizado = (
  (lstAcaoExecucao?.reduce((sum: number, item: any) => sum + (parseFloat(item?.valor_total) ?? 0), 0) || 0) +
  (e.values?.revisao_precos ? parseFloat(e.values.revisao_precos) : 0));

   const valor_por_executar = (e.values?.val_contrat - inv_realizado) > 0 ? (e.values?.val_contrat - inv_realizado) : 0;
   const taxa_execucao = (e.values?.val_contrat - inv_realizado) > 0 ? ((inv_realizado / e.values?.val_contrat) * 100)  : 100;


    const acaoSelected = getAcaoSelected()

    const userId = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '')
      ?.user?.id

    const dataIniP: any = e.values?.data_ini_p ? moment.utc(e.values?.data_ini_p).tz(CURRENT_TIMEZONE) : undefined
    const dataConP : any = e.values?.data_con_p ? moment.utc(e.values?.data_con_p).tz(CURRENT_TIMEZONE) : undefined
    const dataIniE : any = e.values?.data_ini_e ? moment.utc(e.values?.data_ini_e).tz(CURRENT_TIMEZONE) : undefined
    const dataConE : any = e.values?.data_con_e ? moment.utc(e.values?.data_con_e).tz(CURRENT_TIMEZONE) : undefined
    const dataTutela: any = e?.values?.data_tutela ? moment.utc(e.values?.data_tutela).tz(CURRENT_TIMEZONE) : undefined
    const dataTC: any = e?.values?.data_tc ? moment.utc(e.values?.data_tc).tz(CURRENT_TIMEZONE) : undefined
    const dataRegisto: any = e?.values?.data_registo ? moment.utc(e.values?.data_registo).tz(CURRENT_TIMEZONE) : undefined

    const acaoRequest: AcaoForm = {
      id: acaoSelected?.id ?? undefined,
      id_interve: e.values?.id_interve ?? undefined,
      n_filedoc: e.values?.n_filedoc ?? undefined,
      designacao: e.values?.designacao ?? undefined,
      n_processo_aquisitivo: e.values?.n_processo_aquisitivo ?? undefined,
      id_estado: e.values?.acaoEstado ? e.values?.acaoEstado?.id : undefined,
      acaoEstado: e.values?.acaoEstado ?? undefined,
      id_rh: e.values?.id_rh ?? undefined,
      id_concelho: e.values?.concelho ? e.values?.concelho?.id : undefined,
      id_fregues: e.values?.freguesia ? e.values?.freguesia?.id : undefined,
      id_tipo_massa: e.values?.massaAgua ? e.values?.massaAgua.id : undefined,
      data_ini_p: dataIniP ? dataIniP._d : undefined,
      
      data_con_p: dataConP ? dataConP._d : undefined,
      data_ini_e: dataIniE ? dataIniE._d : undefined,
      data_con_e: dataConE ? dataConE._d : undefined,
      id_tipo_n1: e.values?.id_tipo_n1 ? e.values?.id_tipo_n1?.id : undefined,
      id_tipo_n2: e.values?.id_tipo_n2 ? e.values?.id_tipo_n2?.id : undefined,
      id_tipo_n3: e.values?.id_tipo_n2 ? e.values?.id_tipo_n3?.id : undefined,
      id_tipo_n4: e.values?.id_tipo_n4 ? e.values?.id_tipo_n4?.id : undefined,
      tipo_engenharia: e.values?.tipo_engenharia ? e.values?.tipo_engenharia?.id : undefined,
      id_natureza_acao: e.values?.id_natureza_acao ? e.values?.id_natureza_acao?.id : undefined,
      id_int_acao_componente: e.values?.id_int_acao_componente ? e.values?.id_int_acao_componente.id : undefined,
      id_int_acao_planeamento: e.values?.id_int_acao_planeamento ? e.values?.id_int_acao_planeamento?.id : undefined,
      designacao_candidatura: e.values?.designacao_candidatura ?? undefined,
      numero_candidatura: e.values?.numero_candidatura ?? undefined,
      descricao: e.values?.descricao ?? undefined,
      inv_previs: e.values?.inv_previs ? JSON.parse(e.values?.inv_previs) : undefined,
      inv_realizado: inv_realizado ?? e.values?.inv_realizado ?? undefined,  
      val_contrat: e.values?.val_contrat ? JSON.parse(e.values?.val_contrat) : undefined,
      flatCoordinates: coordenadasPoligono ?? undefined,
      obs: e.values?.obs ?? undefined,
      //
      id_tipo_acao: e.values?.tipo_acao ? e.values?.tipo_acao?.id : undefined,
      id_tipo_contratacao: e.values?.id_tipo_contratacao ?? undefined,
      lstEntidades: e.values?.lstEntidades ?? undefined,
      local: e?.values?.local ?? undefined,
      geom_imported: acaoSelected?.geom_imported ?? undefined,
      utilizador_id : userId,
      lstIntervencaoAcao: formattedIntervencaoAcao ?? undefined,
      lstExecucaoAcao: formattedAcaoExecucao ?? undefined,

      id_proc_contratacao: e?.values?.id_proc_contratacao?.id ?? undefined,
      data_tutela: dataTutela ? dataTutela._d  : undefined,
      data_tc: dataTC ? dataTC._d  : undefined,
      valor_por_executar : valor_por_executar ?? e.values?.valor_por_executar ?? undefined,
      taxa_execucao: taxa_execucao ?? e.values?.taxa_execucao ?? undefined,
      data_adjudicacao : e.values?.data_adjudicacao ?? undefined,
      lstIndicadores : formattedIndicadores ?? undefined,
      revisao_precos : e.values?.revisao_precos ?? undefined,
      lstResumoExecucao : formattedResumoExecucao ?? undefined,
      data_registo: dataRegisto ? dataRegisto._d : undefined,
      id_entidade: e.values?.entidade?.id ?? undefined
       
    }
  
    try {
      let acaoSaved: any
      const acaoSelected = getAcaoSelected()

      if (selected == TAB_IDENTIFICACAO || selected == TAB_EXECUCAO) {
        if (acaoSelected?.id) {
          acaoSaved = await updateAcao(acaoRequest)
          selectAcaoResult({
            ...acaoSaved,
          })
          setAcaoSelected(acaoSaved)
        } else {
          acaoSaved = await saveAcao(acaoRequest)
          selectAcaoResultId(Number(acaoSaved?.id))

          setAcaoSelected(acaoSaved)
          selectAcaoResult({
            ...acaoSaved,
          })
        }
      }

      setCoordenadasPoligono(undefined)

    setTimeout(() => {
        postMessage({ type: 'sucessMsg' }, '*')
      }, 500)
      selectAcaoResult(acaoSaved?.id as number)
      refreshForm(acaoSaved?.id);
      
    } catch (error) {
      handleGenericError(error);
    }

    postMessage({ type: 'hideLoader' }, '*')
  }

  const itemRender = (props: { item: any; itemIndex: number }) => {
    const isTitleItem = props.item.key === 'titulo'
    if (isTitleItem) {
      // Item de título personalizado
      return (
        <div className={styles.DropDownButtonTitulo}>
          <span>{props.item.text}</span>
        </div>
      )
    } else {
      // Demais itens
      return (
        <div className={styles.DropDownButtonItem}>
          <span>{props.item.text}</span>
        </div>
      )
    }
  }

 const onItemClick = (event: any) => {
  if(event?.item?.key == "ImportGeom"){
    ImportGeom(event.item)
   } else {
    selectMapAction(event.item)
   }    
  }

  const optionsArea = [

    {
      key: 'SiarlGeoPortalAdicionarArea',
      text: 'Desenhar Área',
    },
    {
      key: 'SiarlGeoPortalAdicionarArea',
      text: 'Editar Área',
    },
    {
      key: 'removerArea',
      text: 'Remover Área',
    },
    {
      key: 'ImportGeom',
      text: 'Importar Geom',
    },
  ]

  const onItemClickImprimir = async (event: any) => {
    let mapImage = ''

    if (!window.location.href.includes('localhost')) {
      const mapIframe = document.querySelector(
        '#iframeGeoPortal'
      ) as HTMLIFrameElement

      const mapIframeInnerContent =
        mapIframe.contentWindow?.document.querySelector(
          'div.ol-viewport'
        ) as HTMLElement;

      const mapBussula =  mapIframe.contentWindow?.document.querySelector(
        'button.fixedNorthArrowButton'
      ) as HTMLElement;

      mapIframeInnerContent.appendChild(mapBussula);
      
      const generatedImage = await html2canvas(mapIframeInnerContent);
      mapImage = generatedImage.toDataURL()
    }

    console.log(selectedAcaoResult);

    const objToSendImgMapa : any = {
      CodPrintTemplate: "RELATORIO_INT_ACAO",
      extendInfo: {
        spatialReference: {
          wkid: 0
        },
        xmin: 0,
        ymin: 0,
        xmax: 0,
        ymax: 0
      },
      ListOperationalLayerInfo: [
        {
          IdOperationLayer: OPERATIONAL_LAYER,
          UseLayerExtent: false,
          DefinitionExpression: "",
          ListServiceLayer: [
            {
                LayerId: LAYER_INDEX_INT_ACAO,
                DefinitionExpression: "id=" + selectedAcaoResult?.id,
                UseLayerExtent: true
            }
          ]
        }
      ]
    }

    try {
      postMessage({ type: 'showLoader' }, '*')

      const documentoBase64 = await relatorioAcao({
        id: selectedAcaoResult?.id,
        dados: {
          'REGISTO' : '',
          'Nº de processo aquisitivo:': selectedAcaoResult?.n_processo_aquisitivo ?? '',
          'Nº processo APA:': selectedAcaoResult?.n_filedoc ?? '',
          'Designação:': selectedAcaoResult?.designacao ?? '',
          'Estado:': selectedAcaoResult?.acaoEstado?.descricao ?? '',
          'Técnico:': selectedAcaoResult?.tecnico ?? '',

          'LOCALIZAÇÃO' : '',
          'Nuts1:': selectedAcaoResult?.nuts1[0]?.nome ?? '',
          'Nuts2:': selectedAcaoResult?.nuts2[0]?.nome ?? '',
          'Nuts3:': selectedAcaoResult?.nuts3[0]?.nome ?? '',
          'Distritos:': selectedAcaoResult?.distritos[0]?.nome ?? '',
          'Concelhos:': selectedAcaoResult?.concelhos[0]?.nome ?? '',
          'Freguesias:': selectedAcaoResult?.freguesias[0]?.nome ?? '',

          'DATAS' : '',
          'Data prevista início:': selectedAcaoResult?.data_ini_p ? new Date(selectedAcaoResult?.data_ini_p).toLocaleDateString() : '',  
          'Data prevista conclusão:': selectedAcaoResult?.data_con_p ? new Date(selectedAcaoResult?.data_con_p).toLocaleDateString() : '', 
          'Data de início de execução:': selectedAcaoResult?.data_ini_e ? new Date(selectedAcaoResult?.data_ini_e).toLocaleDateString() : '',
          'Data de conclusão de execução:': selectedAcaoResult?.data_con_e ? new Date(selectedAcaoResult?.data_con_e).toLocaleDateString() : '',

          // 'ENTIDADE REPONSÁVEL' : '',
          // 'Entidades:': selectedAcaoResult?.lstEntidades.map((entidade: any) => entidade.nome) ?? '',

          'CARACTERIZAÇÃO' : '',
          'Tipologia nível 1:': selectedAcaoResult?.tipo_n1?.descricao ?? '',
          'Tipologia nível 2:': selectedAcaoResult?.tipo_n2?.descricao ?? '',
          'Tipologia nível 3:': selectedAcaoResult?.tipo_n3?.descricao ?? '',
          'Tipologia nível 4:': selectedAcaoResult?.tipo_n4?.descricao ?? '',
          'Natureza da ação:': selectedAcaoResult?.acao_natureza?.descricao ?? '',
          'Componente da ação:': selectedAcaoResult?.int_acao_componente?.descricao ?? '',
          'Tipo de planeamento:': selectedAcaoResult?.int_acao_planeamento?.descricao ?? '',

          'FONTE FINANCEIRA' : '',
          'Nº de candidatura: ': selectedAcaoResult?.numero_candidatura ?? '',
          'Designação de candidatura:': selectedAcaoResult?.designacao_candidatura ?? '',

          'INVESTIMENTO' : '',
          'Investimento previsto:': selectedAcaoResult?.inv_previs ?? '',
          'Valor do contrato:': selectedAcaoResult?.val_contrat ?? '',
          'Investimento realizado:': selectedAcaoResult?.inv_realizado ?? '',

          
        },      
        img_mapa: "",
        objJsonToCreateImg: objToSendImgMapa
      });

      if (documentoBase64) {
        const blob = b64toBlob(documentoBase64, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
        postMessage({ type: 'hideLoader' }, '*')
      }
    } catch (error) {
      postMessage({ type: 'errorMsg', value: { message: "Não foi possivel efetuar esta operação" } }, '*')
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
    
  }

  const optionsImprimir = [

    {
      key: 'imprimirFichas',
      text: 'Fichas',
    },
    {
      key: 'imprimirResultados',
      text: 'Resultados',
    },
  ]

  const onItemClickExportar = async (event: any) => {

    let mapImage = ''

    if (!window.location.href.includes('localhost')) {
      const mapIframe = document.querySelector(
        '#iframeGeoPortal'
      ) as HTMLIFrameElement

      const mapIframeInnerContent =
        mapIframe.contentWindow?.document.querySelector(
          'div.ol-viewport'
        ) as HTMLElement;

      const mapBussula =  mapIframe.contentWindow?.document.querySelector(
        'button.fixedNorthArrowButton'
      ) as HTMLElement;

      mapIframeInnerContent.appendChild(mapBussula);
      
      const generatedImage = await html2canvas(mapIframeInnerContent);
      mapImage = generatedImage.toDataURL()
    }

    console.log(selectedAcaoResult);

    try {
      postMessage({ type: 'showLoader' }, '*')

      const documentoBase64 = await relatorioAcao({
        id: selectedAcaoResult?.id,
        dados: {
          'REGISTO' : '',
          'Nº de processo aquisitivo:': selectedAcaoResult?.n_processo_aquisitivo ?? '',
          'Nº processo APA:': selectedAcaoResult?.n_filedoc ?? '',
          'Designação:': selectedAcaoResult?.designacao ?? '',
          'Estado:': selectedAcaoResult?.acaoEstado?.descricao ?? '',
          'Técnico:': selectedAcaoResult?.utilizador_id ?? '',

          'LOCALIZAÇÃO' : '',
          'Nuts1:': selectedAcaoResult?.nuts1[0]?.nome ?? '',
          'Nuts2:': selectedAcaoResult?.nuts2[0]?.nome ?? '',
          'Nuts3:': selectedAcaoResult?.nuts3[0]?.nome ?? '',
          'Distritos:': selectedAcaoResult?.distritos[0]?.nome ?? '',
          'Concelhos:': selectedAcaoResult?.concelhos[0]?.nome ?? '',
          'Freguesias:': selectedAcaoResult?.freguesias[0]?.nome ?? '',

          'DATAS' : '',
          'Data prevista início:': selectedAcaoResult?.data_ini_p ? new Date(selectedAcaoResult?.data_ini_p).toLocaleDateString() : '',  
          'Data prevista conclusão:': selectedAcaoResult?.data_con_p ? new Date(selectedAcaoResult?.data_con_p).toLocaleDateString() : '', 
          'Data de início de execução:': selectedAcaoResult?.data_ini_e ? new Date(selectedAcaoResult?.data_ini_e).toLocaleDateString() : '',
          'Data de conclusão de execução:': selectedAcaoResult?.data_con_e ? new Date(selectedAcaoResult?.data_con_e).toLocaleDateString() : '',

          // 'ENTIDADE REPONSÁVEL' : '',
          // 'Entidades:': selectedAcaoResult?.lstEntidades.map((entidade: any) => entidade.nome) ?? '',

          'CARACTERIZAÇÃO' : '',
          'Tipologia nível 1:': selectedAcaoResult?.tipo_n1?.descricao ?? '',
          'Tipologia nível 2:': selectedAcaoResult?.tipo_n2?.descricao ?? '',
          'Tipologia nível 3:': selectedAcaoResult?.tipo_n3?.descricao ?? '',
          'Tipologia nível 4:': selectedAcaoResult?.tipo_n4?.descricao ?? '',
          'Natureza da ação:': selectedAcaoResult?.acao_natureza?.descricao ?? '',
          'Componente da ação:': selectedAcaoResult?.int_acao_componente?.descricao ?? '',
          'Tipo de planeamento:': selectedAcaoResult?.int_acao_planeamento?.descricao ?? '',

          'FONTE FINANCEIRA' : '',
          'Nº de candidatura: ': selectedAcaoResult?.numero_candidatura ?? '',
          'Designação de candidatura:': selectedAcaoResult?.designacao_candidatura ?? '',

          'INVESTIMENTO' : '',
          'Investimento previsto:': selectedAcaoResult?.inv_previs ?? '',
          'Valor do contrato:': selectedAcaoResult?.val_contrat ?? '',
          'Investimento realizado:': selectedAcaoResult?.inv_realizado ?? '',

          
        },      
        img_mapa: mapImage
      });

      if (documentoBase64) {
        const blob = b64toBlob(documentoBase64, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
    
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = 'reporte_acao.pdf'; 
        link.click();
        
        postMessage({ type: 'hideLoader' }, '*')
      }
    } catch (error) {
      postMessage({ type: 'errorMsg', value: { message: "Não foi possivel efetuar esta operação" } }, '*')
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
   
  }

  const optionsExportar = [

    {
      key: 'exportarFichas',
      text: 'Fichas',
    },
    {
      key: 'exportarResultados',
      text: 'Resultados',
    },
  ]

     const importarGeometria = [
    {
      key: 'ImportGeom',
      text: 'Importar Geom',
    },
  ]
   const ImportGeom = async (item: any) => {
    if (item.key == 'ImportGeom') {
      setOpenImportModal(true)
    }
  }

    const optionsEditar = [
    {
      key: 'SiarlEditar',
      text: 'Editar',
    },
    {
      key: 'SiarlApagar',
      text: 'Apagar',
    },
    
  ]

  const optionsFileDoc = [

    {
      key: 'AbrirFileDoc',
      text: 'Abrir Filedoc',
    },
    
  ]

 
  const onEditItemClick= (event:any) => {
    if (event == "SiarlEditar" || event?.item?.key == "SiarlEditar") {
      setEditMode(true);
      setViewMode(false);
      return;
    }
    if (event == "SiarlVisualizar") {
      setEditMode(false);
      setViewMode(true);
    }
    if (event?.item?.key == "SiarlApagar") {
      setDeleteClicked(true);
      setOpenModalDescartar(true);
    }
  }

    useEffect(() => {
    if (viewMode) {
      sessionStorage.removeItem("novaAcao");
    }
  })

  const onImportGeomClick = (event: any) => {
    ImportGeom(event.item)
  }

  const onFileDocClick = async() => {
    const acaoSelected = getAcaoSelected()
    if (!acaoSelected?.n_filedoc) {
      postMessage({ type: "infoMsg", value: {message: "É necessário preencher o nº processo APA (filedoc)."} }, "*");
      return;
    }
    postMessage({ type: 'showLoader' }, '*');
    const urlFileDoc = await getUrlFileDoc(acaoSelected?.filedoc_cod);
    postMessage({ type: 'hideLoader' }, '*')
    window.open(urlFileDoc, '_blank');
    return;
  }

  const isFormValid = selected === 0 || selected === 1 || selected === 2

  useLayoutEffect(() => {

    const handleResize = () => {

      if (window.innerWidth <= telaMobile) {
        
        setIsMobile(true);
        ocultaFloatingMenu_e_Mapa();

      }else{

        setIsMobile(false);
        mostraFloatingMenu_e_Mapa();

      }

    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);  

  const onItemClickMais = (event: any) => {

    elementoClickMais(event);

    onEditItemClick(event);
    onImportGeomClick(event);
    onItemClick(event);
    
    if(optionsExportar.some(option => option.key === event.item.key)){
      onItemClickExportar(event);
    }

  };

  const optionsMais = [
    {
      key: 'titulo',
      text: 'Registo',
    },
    ...optionsEditar.map(option => ({
      ...option,
      className: 'icon-siarl icon-editar btnIconRegistar',
    })),
    {
      key: 'titulo',
      text: 'Opções',
    },
    {
      key: 'openFileDoc',
      text: 'Diretório de documentos',
      className: 'icon-siarl icon-filedoc btnIconRegistar'
    },  
    {
      key: 'titulo',
      text: 'Geometria',
    },
    ...importarGeometria.map(option => ({
      ...option,
      className: 'icon-siarl icon-registar btnIconRegistar',
    })),  
    {
      key: 'titulo',
      text: 'Área',
    },
    ...optionsArea.map(option => ({
      ...option,
      className: 'icon-siarl icon-area btnIconRegistar',
    })),
    {
      key: 'titulo',
      text: 'Imprimir',
    },
    {
      key: 'imprimirFicha',
      text: 'Fichas',
      className: 'icon-siarl icon-imprimir btnIconRegistar'
    },
    {
      key: 'titulo',
      text: 'Exportar',
    },
    ...optionsExportar.map(option => ({
      ...option,
      className: 'icon-siarl icon-exportar btnIconRegistar',
    })),
  ]

  const openAjuda = () => {
    window.open(URL_AJUDA_INTERVENCOES_ACAO, "_blank")
  }

  const canSeeAcao = hasPermission(PERMISSION_INTERVENCAO_PERFIL_BASICO) ||
  hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO) ||
  hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA) ||
  hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO) ||
  hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_LEITURA) ||
  hasPermission(PERMISSION_INTERVENCAO_PERFIL_TOTAL);
  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <div>
          <div className={styles.nomeDoModulo}>Intervenções</div>
          <div className={styles.tituloDaPagina}>
          {!selectedAcaoResult || !selectedAcaoResult?.id ? 'Nova ação' : (editMode ? 'Editar ação' : 'Ação')}

          </div>
        </div>
        <div>

          {!isMobile && canUpdate && (viewMode || editMode) && (sessionStorage.getItem("novaAcao") != 'true') ? 
          <DropDownButton
            items={optionsEditar}
            className={styles.btnEditar2}
            iconClass="icon-siarl icon-editar"
            onItemClick={onEditItemClick}
            fillMode={'flat'}
            itemRender={itemRender}
            title="Editar"
          ></DropDownButton>
          : ''}

          <button className={styles.btnEditar2} title='Filedoc' onClick={onFileDocClick}
          style={{ display: isMobile ? 'none' : '' }} id='openFileDoc'>
            <span className="icon-siarl icon-filedoc"></span>
          </button>

          {!isMobile &&
          <DropDownButton
            items={optionsArea}
            className={styles.btnEditar2}
            iconClass="icon-siarl icon-area"
            onItemClick={onItemClick}
            fillMode={'flat'}
            title='Geometria - Área'
            itemRender={itemRender}
             disabled={viewMode}
          ></DropDownButton>
          }

          <button className={styles.btnEditar2} onClick={onItemClickImprimir}
          style={{ display: isMobile ? 'none' : '' }} id='imprimirFicha' title='Imprimir'
          >
            <span className="icon-siarl icon-imprimir"></span>
          </button>

          <button id='exportarFicha' className={styles.btnImprimir} onClick={onItemClickExportar}
          style={{ display: isMobile ? 'none' : '' }} title='Exportar'>
            <span className="icon-siarl icon-exportar"></span>
          </button>

          {isMobile &&
            <DropDownButton
              items={optionsMais}
              className={styles.btnMais}
              iconClass="icon-siarl icon-mais"
              onItemClick={onItemClickMais}
              fillMode={'flat'}
              itemRender={itemRenderMais}
              title="Mais Opções"
            />
          }

          <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
            <span className="icon-siarl icon-ajuda"></span>
          </button>

          <button className={styles.btnFechar} onClick={voltar} title="Fechar">
            <span className="icon-siarl icon-fechar"></span>
          </button>
        </div>
      </div>

       {openImportModal ?
        <ImportarGeometriaComponent onSubmit={importGeo} cancelImport={cancelImport} /> : <></>
      }

{openRemoveAreaModal ? (
               <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={() => setOpenRemoveAreaModal(false)}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                    <span className="textDescartar">Remover área </span>
                    <br></br>
                    <span>{'Tem a certeza que pretende remover a área selecionada?'}</span>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={() => setOpenRemoveAreaModal(false)}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={removeArea}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}
      
  {openModalDescartar ? (
          <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={cancelModalDescartar}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                  <fieldset>
                    <span className="textDescartar">{deleteClicked ? 'Apagar' : 'Cancelar'} registo  </span>
                    <br></br>
                    <span>{deleteClicked ? 'Ao apagar este registo deixará de ter acesso, sendo necessária uma intervenção rigorosa para realizar a sua recuperação.' : 'Ao cancelar este registo irá perder os dados inseridos até ao momento.'}</span>
                      <br></br>
                    <span>{deleteClicked ? 'Tem a certeza que pretende apagar?' : 'Tem a certeza que pretende cancelar?'}</span>
                  </fieldset>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={cancelModalDescartar}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={handleDescartar}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}
      <Form
        initialValues={{
          ...getAcaoSelected(),
          data_registo: selectedAcaoResult?.data_registo
          ? new Date(selectedAcaoResult?.data_registo)
          : new Date(),
      
        } ?? undefined}
        onSubmitClick={handleSubmit}
        render={(formRenderProps) => (
          <FormElement>
            <TabStrip
              selected={selected}
              onSelect={handleSelect}
              className={`${styles.innerStyleTabContent} ${viewMode? 'tabsVisualizar' : ''}`}
              scrollable={isMobile}
            >
              {canSeeAcao?
              <TabStripTab title="Identificação" key={'Identificação'}>
                <IdentificacaoAcao onCloseClick={onCloseClick} viewMode={viewMode} formRenderProps={formRenderProps} />
              </TabStripTab>
             
             :<></>
             
            }
             <TabStripTab title="Execução" key={'Execução'}>
              <IdentificacaoAcaoExecucao viewMode={viewMode} formRenderProps={formRenderProps} />
            </TabStripTab>
              <TabStripTab title="Documentos"  key={'Documentos'}>
                <DocumentoListagem pageName="acao" viewMode={viewMode}/>
              </TabStripTab>
              <TabStripTab title="Associação"  key={'Associação'}>
                <AssociacaoListagem pageName="acao" viewMode={viewMode}/>
              </TabStripTab>
            </TabStrip>

             {(editMode || sessionStorage.getItem("novaAcao") == 'true')
            && !viewMode ?

                  <div
                    className={`${styles.footer} ${styles.justify_multiple_children}`}
                  >

                  <Button type="button" className={styles.btnSeguinte} onClick={handleOpenModalDescartar} title='Cancelar'>
                  <span className="icon-siarl icon-fechar"></span>
                  <span className={styles.rotulo}>
                    {' '}&nbsp;{' '}
                    Cancelar
                  </span>
                 </Button>    

                 <Button
                      className={styles.btnSubmeter}
                       onClick={(e) => {
                    formRenderProps.onSubmit(e);
                    e.preventDefault();
                  }}
                  title='Gravar'
                    >
                      <span className="icon-siarl icon-submeter btnIconRegistar"></span>
                      <span className={styles.rotulo}>
                        {' '}&nbsp;{' '}
                        Gravar
                      </span>
                    </Button>
                  </div>
            : ''}

          </FormElement>
        )}
      />
    </div>
  )
}
