import { create } from 'zustand'

type GvMapaPartilhado = {
  gvMapaPartilhadoEstado: {
    id: number
    descricao: string
  }
  tema: {
    id: number
    descricao: string
  }
  sub_tema: {
    id: number
    descricao: string
  }
  id: number
  id_utilizador: number
  descricao: string
  data_registo: string
  data_publicacao: string
  img_mapa: string
  titulo: string
  link_gerado: string
  id_gv_estado: number
  id_gv_tema: number
  id_gv_sub_tema: number
  uid_mapa: string
  base64: string
}

type DocumentosState = {
  selectedGVResult?: GvMapaPartilhado
  selectGVResult: (payload: GvMapaPartilhado) => void
  selectedGVResultId?: number
  selectGVResultId: (payload?: number) => void
  shareModalIsValid: boolean
  setShareModalIsValid: (payload: boolean) => void
  shareModalValidationObject: Record<string, boolean>
  addShareModalValidatedField: (payload: Record<string, boolean>) => void
  shouldRefetchList: boolean
  setShouldRefetchList: (payload: boolean) => void
}

export const useGeovisualizadoresStore = create<DocumentosState>(
  (set, get) => ({
    shareModalIsValid: false,
    shareModalValidationObject: {},
    shouldRefetchList: false,
    selectGVResult: (payload: GvMapaPartilhado) =>
      set({ selectedGVResult: payload }),
    selectGVResultId: (payload?: number) =>
      set({ selectedGVResultId: payload }),
    setShareModalIsValid: (payload?: boolean) =>
      set({ shareModalIsValid: payload }),
    addShareModalValidatedField: (payload) => {
      const validationObj = get().shareModalValidationObject
      const newValidationObj = { ...validationObj, ...payload }
      set({ shareModalValidationObject: newValidationObj })
      const validationValues = Object.values(newValidationObj)
      set({
        shareModalIsValid: validationValues.every((value) => value === true),
      })
    },
    setShouldRefetchList: (payload) => set({ shouldRefetchList: payload }),
  })
)
