import { process } from '@progress/kendo-data-query';
import {
  Dispatch,
  MouseEvent as ReactMouseEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { Button, DropDownButton } from '@progress/kendo-react-buttons';
import { PagerTargetEvent } from '@progress/kendo-react-data-tools';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Grid, GridCellProps, GridColumn, GridPageChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { format } from 'date-fns';
import React from 'react';
import { CSVLink } from 'react-csv';
import SessionStorageService from '../../services/SessionStorageService';
import { GetUserNameTecnico, b64toBlob, exportPDFWithMethod, get, handleDownload, handleGenericError, hasPermission, post, setCoordenadasPoligono, setIntervencaoSelected, setMobilizacaoSelected, setModeloSelected } from '../../services/utils';
import styles from '../../styles/intervencao/Intervencao.module.scss'
import { AUTH_LOCALSTORAGE, CONSULTAR_MOBILIZACAO, GET_DOCUMENTO, GET_DOCUMENTO_BY_INTERVENCAO, GET_DOCUMENTO_BY_MOBILIZACAO, GET_INT_MOBILIZACAO_SHAPEFILE, GET_LIST_RESULTS_INT_MOBILIZACAO, LAYER_INDEX_INT_MOBILIZACAO, OPERATIONAL_LAYER, PERMISSION_INSERIR_INTERVENCAO, PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO, PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA, PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO, PESQUISAR_DOCUMENTOS_INTERVENCAO, PESQUISAR_INTERVENCAO, PESQUISAR_MOBILIZACAO,RECORDS_LIMIT,RELATORIO_MERGE_MOBILIZACAO,URL_AJUDA_INTERVENCOES_MOBILIZACAO, clearLayers } from '../../utils/constants';
import { ResultadoIntervencaoCommandGridCell } from './ResultadoIntervencaoCommandGridCell';
import { useIntervencaoStore } from '../../stores/intervencao';
import { useMobilizacaoStore } from '../../stores/mobilizacao';
import { ResultadoMobilizacaoCommandGridCell } from './ResultadoMobilizacaoCommandGridCell';
import clsx from 'clsx';
import commonStyles from '../../styles/common/Common.module.scss'
import { useCommonStore } from '../../stores/common';
import MaximizeButton from '../common/MaximizeButton';
import { Dialog } from '@progress/kendo-react-dialogs';
import ImagePreviewV2 from '../viewer/ImagePreviewV2';
import VisualizadorColunas from '../Generico/VisualizadorColunas ';
import useVisibleColumns, { Key } from '../../hooks/useVIsibleColumns';
import ReactGA from 'react-ga4';
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais';
import useClearMap from '../../hooks/useClearMap';

type Props = {
  onCloseClick?: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
  setShowResultadoMobilizacao: Dispatch<SetStateAction<boolean>>
  setShowRegistarMobilizacao: Dispatch<SetStateAction<boolean>>
}

const options = [
  {
    key: 'ExportarFichasResultados',
    text: 'Exportar fichas resultados',
    icon: <span className={`icon-siarl icon-exportar ${styles.btnImprimir}`}></span>,
  }
]
type PageState = {
  skip: number
  take: number
}
const initialDataState: PageState = { skip: 0, take: 20 }

const keyPainelPrincipal:Key = 'mobilizacaoColunasVisiveis';
const keyPainelDocumentos:Key = 'mobilizacaoDocumentosColunasVisiveis';

export default function ResultadoMobilizacaoForm({
  onCloseClick,
  setShowResultadoMobilizacao,
  setShowRegistarMobilizacao,
}: Props) {
  const [isMobile, setIsMobile] = useState(false);
  const [mobilizacoes, setmobilizacoes] = useState([])
  const [documentosMobilizacoes, setDocumentosMobilizacoes] = useState([])

  const [selected, setSelected] = useState<number>(0)
  const [page, setPage] = useState<PageState>(initialDataState)
  const [urlDoDocumento, setUrlDoDocumento] = useState("");
  const [idDoDocumento, setIdDoDocumento] = useState("");
  const [openForm, setOpenForm] = useState<boolean>(false)
  const getDocumento = async (data: any) =>
  await get<any, any>(`${GET_DOCUMENTO}/` + data)

  const documentosToPreview = documentosMobilizacoes
  const isCurrentViewMaximized = useCommonStore(state => state.isCurrentViewMaximized)

  const selectMobilizacaoResult = useMobilizacaoStore(
    (state) => state.selectMobilizacaoResult
  )
  const selectMobilizacaoResultId = useMobilizacaoStore(
    (state) => state.selectMobilizacaoResultId
  )
  const [pageSizeValue, setPageSizeValue] = useState<
    number | string | undefined
  >()

  const getMobilizacao = async (data: any) =>
    await get<any, any>(`${PESQUISAR_MOBILIZACAO}/` + data)

  const relatorioMobilizacaoMerge = async (data: any) =>
    await post<any, any>(`${RELATORIO_MERGE_MOBILIZACAO}`, data)


      const getDocumentosByMobilizacoesIDs = async (data: any) =>
  await post<any, any>(`${GET_DOCUMENTO_BY_MOBILIZACAO}` , data)
  const getShapeFile = async (data: any) =>
  await post<any, any>(`${GET_INT_MOBILIZACAO_SHAPEFILE}`, data)

     const handleMessageEvent = useCallback((event: MessageEvent) => {
        if (event.data.type == "resultadoMobilizacaoPesquisaChange") {
            SessionStorageService.setItem("resultadoMobilizacaoPesquisa", event.data.value);
        }
    }, [])

    
  const getMobilizacoesPorIds = async (data: any) =>
    await post<any, any>(`${GET_LIST_RESULTS_INT_MOBILIZACAO}`, data)


     useEffect(() => {
        window.addEventListener(
            'message',
            handleMessageEvent,
            false
        )

        return () => {
            window.removeEventListener(
                'message',
                handleMessageEvent,
                false
            )
        }
    }, [handleMessageEvent])

    const consultarMobilizacao = async (data: any) =>
    await post<any, any>(`${CONSULTAR_MOBILIZACAO}`, data)

    const initialized = useRef(false)
    useEffect(() => {
        if (!initialized.current) {
          initialized.current = true;      if (SessionStorageService.getItem("lastSearchMobilizacao") && !SessionStorageService.getItem("firstCallMobilizacao")) {
        postMessage({ type: "showLoader" }, "*");
        const objToSearch = SessionStorageService.getItem("lastSearchMobilizacao") ?? '';
        consultarMobilizacao(objToSearch).then((data) => {
          SessionStorageService.setItem("resultadoMobilizacaoPesquisa", JSON.stringify(data));
          setResultsMobilizacao();
          postMessage({ type: "hideLoader" }, "*");

        })
      } 
      else {
        setResultsMobilizacao();
    }
  }
    }, [])

    const openPdfNewTab = () => {
      return new Promise<void>((resolve, reject) => {
          
          postMessage({ type: "showLoader" }, "*");
  
          setTimeout(() => {
             const columnsToShow = colums.columns.filter(x => x.show);
              const dataToExport = mobilizacoes.map((item: any) => {
                  const filteredItem: any = {};
                  columnsToShow.forEach((column: any) => {
                      filteredItem[column.field] = item[column.field];
                  });
                  return filteredItem;
              });
              
              exportPDFWithMethod(columnsToShow, dataToExport, 8)
                  .then((pdfData: any) => {
                      postMessage({ type: "hideLoader" }, "*");
  
                          const base64Data = pdfData.split(";base64,")[1];
                          console.log(base64Data);
                          const blob = b64toBlob(base64Data, 'application/pdf');
                          const blobUrl = URL.createObjectURL(blob);
  
                        
                          window.open(blobUrl, '_blank');
                          resolve();
                    
                  })
                  .catch(error => {
                      console.error('Error exporting PDF:', error);
                      reject(error);
                  });
          }, 1000);
      });
  };

    const setResultsMobilizacao = () => {

        let results = JSON.parse(SessionStorageService.getItem('resultadoMobilizacaoPesquisa') ?? '')
        results = results?.map((mobilizacao: any) => ({
          ...mobilizacao,
          data_mobilizacao_formatted: mobilizacao?.data 
          ? format(new Date(mobilizacao?.data), 'dd/MM/yyyy') 
          : ' ',
  
        }))
        setmobilizacoes(results)
        // const iframe: any = document.getElementById('iframeGeoPortal')
        // const ids: any[] = []
        // results.forEach((element: any) => {
        //   ids.push(element.id)
        //   })
        //   iframe.contentWindow?.postMessage(
        //     { type: 'SiarlIntMobilizacaoSearch', value: ids },
        //     '*'
        //   )
        postMessage({ type: 'hideLoader' }, '*');

    }

    useEffect(() => {
      ReactGA.send({ hitType: "pageview", page: "/ResultadoIntMobilizacao" });
    }, []);

    useEffect(() => {
      const iframe: any = document.getElementById('iframeGeoPortal')
      const ids: number[] = []
      mobilizacoes.forEach((element: any) => {
        ids.push(element.id)
      })
      iframe.contentWindow?.postMessage(
        { type: 'SiarlIntMobilizacaoSearch', value: ids },
        '*'
      )
    }, [])

  const [clearMap] = useClearMap();

  
  const clearSearchResults = () => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlIntMobilizacaoClearLayers", value: "true" }, '*')

    iframe.contentWindow?.postMessage({ type: "SiarlMobilizacaoCaraterizacaoClearLayers", value: "true" }, '*')
    SessionStorageService.removeItem('resultadoMobilizacaoPesquisa')
    removeColumnsKeys(keyPainelDocumentos)
    removeColumnsKeys(keyPainelPrincipal)
    SessionStorageService.removeItem("lastSearchMobilizacao");
    postMessage({ type: "form", value: "pesquisarMobilizacao" }, "*");
    setmobilizacoes([]);
    setCoordenadasPoligono(undefined);
    clearMap(clearLayers["CLEAR_LAYERS_MOBILIZACAO"])
  }

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected)
  }
  const handlePageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent
    const take = event.page.take

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value)
    }

    setPage({
      ...event.page,
      take,
    })
  }

  useEffect(() => {

    try {

      const results = JSON.parse(
        SessionStorageService.getItem('resultadoMobilizacaoPesquisa') ?? ''
      )

      setmobilizacoes(results);
      try {
        const idsMobilizacao = results.map((o: any) => o.id)
        getDocumentosByMobilizacoesIDs(idsMobilizacao).then(response => {
          setDocumentosMobilizacoes(response)
          console.log(response)
        })

      } catch (error) {
        console.error('Erro ao buscar os dados:', error)
      }


      getVisibleColumns(keyPainelPrincipal, setColums)
      getVisibleColumns(keyPainelDocumentos, setColumsDocuments)

    } catch (e) {
      setmobilizacoes([]);
    }

  }, [])

  const canSeeCandidatura = 
  hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO) ||
  hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO) 

  const completoLeitura =   hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA) 


  
 

  const handleInfoClickById = (mobilizacaoLocal: Record<string, any>) => async () => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    const id = mobilizacaoLocal.int_mobilizacao_id ?? mobilizacaoLocal.id;
    const mobilizacao = await getMobilizacao(id);
    const tecnico = await GetUserNameTecnico(mobilizacao.utilizador_id);

    
    if (!mobilizacao) {
      postMessage({type: "infoMsg", value: {message: "Registo desativado."}}, "*");
      return;
    }
    const objToShow = {
      ...mobilizacao,
      id: mobilizacao.id ?? undefined,
      descricao: mobilizacao.descricao ?? undefined,
      nuts1: mobilizacao.nuts1 ?? undefined,
      nuts2: mobilizacao.nuts2 ?? undefined,
      nuts3: mobilizacao.nuts3 ?? undefined,
      distrito : mobilizacao.distrito ?? undefined,
      concelho: mobilizacao.mobilizacao ?? undefined,
      freguesia: mobilizacao.freguesia ?? undefined,
      arh: mobilizacao.arh ?? undefined,
      capitania : mobilizacao.capitania ?? undefined,
      massaagua: mobilizacao.massaagua ?? undefined,
      data_mobilizacao: mobilizacao?.data? new Date(mobilizacao?.data):null,
      data_registo: mobilizacao?.data_registo? new Date(mobilizacao?.data_registo):null,
      id_int_mobilizacao_funcao: mobilizacao.int_mobilizacao_funcao ?? undefined,
      id_tipo_mo : mobilizacao.tipo_mo ?? undefined,
      volume: mobilizacao.volume ?? undefined,
      tipo_mancha_nivel_1_id: mobilizacao.intMobMancEmprestimoNivel1 ?? undefined,
      tipo_mancha_nivel_2_id: mobilizacao.intMobMancEmprestimoNivel2 ?? undefined,
      tipo_metodo_dragagem_id : mobilizacao.mobMetodoDragagem ?? undefined,
      tipo_local_deposicao_1_id : mobilizacao.intMobDescritivoLocalDeposicaoNivel1 ?? undefined,
      tipo_local_deposicao_2_id : mobilizacao.intMobDescritivoLocalDeposicaoNivel2 ?? undefined,
      tipo_mob_metodo_deposicao : mobilizacao.mobMetodoDeposicao ?? undefined,
      tipo_mob_objetivo_dep_1_id: mobilizacao.intMobObjetivoDeposicaoNivel1 ?? undefined,
      tipo_mob_objetivo_dep_2_id: mobilizacao.intMobObjetivoDeposicaoNivel2 ?? undefined,
      td_mob_qualidade: mobilizacao.mobQualidade ?? undefined,
      td_mob_granulometria : mobilizacao.mobGranulometria ?? undefined,
      int_mobilizacao_estado: mobilizacao.int_mobilizacao_estado ?? undefined,
      id_entidade: mobilizacao.entidade ?? undefined,
      utilizador_id: tecnico

    }
    sessionStorage.setItem('novaMobilizacao', 'false')
    SessionStorageService.setItem('mobilizacaoAntiga', "true")
      SessionStorageService.setItem('mobilizacaoSubmetida', "false")


    selectMobilizacaoResult(objToShow)
    selectMobilizacaoResultId(mobilizacao?.id)
    setMobilizacaoSelected(objToShow);
    setShowResultadoMobilizacao(false)
    setShowRegistarMobilizacao(true)
    const idToSend = [mobilizacao?.id];

    iframe.contentWindow?.postMessage({ type: "SiarlIntMobilizacaoSearch", value: idToSend }, '*')

    iframe.contentWindow?.postMessage({ type: "SiarlIntMobilizacaoCenter", value: idToSend }, '*')
  }

  const conditionalProps =  canSeeCandidatura || completoLeitura
  ? { 
      onInfoClick: handleInfoClickById
  } 
  : {};

  const handleLocationClick = (mobilizacaoLocal: Record<string, any>) => async () => { 
    const id = mobilizacaoLocal.int_mobilizacao_id ?? mobilizacaoLocal.id;
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlIntMobilizacaoCenter", value: [id] }, '*')
  }
  const mobilizacaoCustomCell = (props: GridCellProps) => (
    <ResultadoMobilizacaoCommandGridCell
      {...props}
      onInfoClick={handleInfoClickById}
      onDocumentPreviewClick={handleDocumentPreviewClickById}
      onLocationClick={handleLocationClick}
      {...conditionalProps}

    />
  )

  const handleDocumentPreviewClickById = (documentosLocal: Record<string, any>) => async () => {

    try {

      postMessage({ type: "showLoader" }, "*");
      const documentos = await getDocumento(documentosLocal.id);

      setIdDoDocumento(documentosLocal.id);
      setUrlDoDocumento(documentos.url);
      setOpenForm(true);

    } catch (error) {

      handleGenericError(error, "Não foi possivel abrir o documento!")
      setOpenForm(false);

    } finally {

      postMessage({ type: "hideLoader" }, "*");

    }
  }

  const nextPhoto = async (e: any) => {

    try{

      postMessage({ type: "showLoader" }, "*");
      const nextIds = documentosToPreview
        .filter((doc: any) => doc.id < idDoDocumento && doc.url !== '')
        .map((doc: any) => doc.id);
  
      if (nextIds.length > 0) {
        const nextId = Math.max(...nextIds);
        console.log(`O próximo ID é ${nextId}`);
  
        const documento = await getDocumento(nextId);
  
        setIdDoDocumento(nextId.toString());
        setUrlDoDocumento(documento.url);

        const iframe: any = document.getElementById('iframeGeoPortal')
        
        iframe.contentWindow?.postMessage(
          {
            type: 'SiarlIntMobilizacaoSearchHighlightClick',
            value: [e.int_mobilizacao_id],
          },
          '*'
        )
  
      } else {
        console.log("Não há um próximo ID na lista.");
      }

    }catch(error){

      postMessage({ type: "errorMsg", value: "Não foi possivel abrir o documento!" }, "*");

    }finally{

      postMessage({ type: "hideLoader" }, "*");

      e.preventDefault();
    }
   
  }

  const previousPhoto = async (e: any) => {

    try{

      postMessage({ type: "showLoader" }, "*");
      const previousIds = documentosToPreview
        .filter((doc: any) => doc.id > idDoDocumento && doc.url !== '')
        .map((doc: any) => doc.id);
  
      if (previousIds.length > 0) {
        const previousId = Math.min(...previousIds);
        console.log(`O anterior ID é ${previousId}`);
  
        const documento = await getDocumento(previousId);
  
        setIdDoDocumento(previousId.toString());
        setUrlDoDocumento(documento.url);

        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage(
          {
            type: 'SiarlIntMobilizacaoSearchHighlightClick',
            value: [e.int_mobilizacao_id],
          },
          '*'
        )
      } else {
        console.log("Não há um anterior ID na lista.");
      }

    }catch(error){

      handleGenericError(error, "Não foi possivel abrir o documento!")

    }finally{

      postMessage({ type: "hideLoader" }, "*");

      e.preventDefault();
    }
       
  }

  const [dataState, setDataState] = React.useState({
    skip: 0,
    take: 20,
    sort: [],
    group: [],
  });

  const [dataStateDocumentos, setDataStateDocumentos] = React.useState({
    skip: 0,
    take: 20,
    sort: [],
    group: [],
  });

  const dataStateChange = (event: any) => {
    setDataResult(process(mobilizacoes, event.dataState));
    setDataState(event.dataState);
  };

  const [dataResult, setDataResult] = React.useState(
    process(mobilizacoes, dataState)
  );

  const dataStateChangeDocumentos = (event: any) => {
    setDataResultDocumentos(process(documentosMobilizacoes, event.dataState));
    setDataStateDocumentos(event.dataState);
  };

  const [dataResultDocumentos, setDataResultDocumentos] = React.useState(
    process(documentosMobilizacoes, dataStateDocumentos)
  );

  const optionsImprimir = [
    {
      key: 'ExportarListaResultados',
      text: 'Resultados',
    },
  ]

  const optionsExportar = [
    {
      key: 'ExportarListaResultadosExcel',
      text: 'excel',
    },
    {
      key: 'ExportarListaResultadosShapeFile',
      text: 'Shapefile',
    },
    {
      key: 'ExportarTodasFichas',
      text: 'Fichas',
    },
    
  ]

  const itemRender = (props: { item: any; itemIndex: number }) => {
    const isTitleItem = props.item.key === 'titulo';
    if (isTitleItem) {
      // Item de título personalizado
      return (
        <div className={styles.DropDownButtonTitulo}>
          <span>{props.item.text}</span>
        </div>
      );
    } else {
      // Demais itens
      return (
        <div className={styles.DropDownButtonItem}>
          <span>{props.item.text}</span>
        </div>
      );
    }
  };

  const onItemClickExportar = async (event: any) => {

    if(event.item.key == "ExportarTodasFichas"){
      onItemClickExportarFichas();
    }

    if (event.item.key == 'ExportarListaResultadosExcel') {

      exportExcel();

    } else if (event.item.key == 'ExportarListaResultadosPdf') {

      exportPDF();

    } else if (event.item.key == "ExportarListaResultadosShapeFile") {
      const results = JSON.parse(
        SessionStorageService.getItem('resultadoMobilizacaoPesquisa') ?? ''
      )
  
      if (results) {
        const iframe: any = document.getElementById('iframeGeoPortal')
        const ids: number[] = []
        results.forEach((element: any) => {
          ids.push(element.id)
        })

        const file: any = await getShapeFile(ids);
        const filePath = 'shapeFileIntMobilizacao.zip';

        handleDownload(file, filePath);
    } 
  }
};

  const onItemClickImprimirMobilizacao = (event: any) => {

    const link: HTMLAnchorElement | null = document.querySelector('#print-results-mobilizacao');

    if (link) {
      link.click();
    }

    // if (event.item.key == 'ExportarListaResultados') {

      

    // }

  };

  const onItemClickImprimirDocumentos = (event: any) => {

    const link: HTMLAnchorElement | null = document.querySelector('#print-results-documentos');

    if (link) {
      link.click();
    }

    // if (event.item.key == 'ExportarListaResultados') {

      

    // }

  };

  const onItemClick = (event: any) => {

    const link: HTMLAnchorElement | null = document.querySelector('#print-results');

    if (link) {
      link.click();
    }

    // if (event.item.key == 'ExportarFichasResultados') {

      

    // } else if (event.item.key == 'ExportarListaResultados') {

    //   console.log(event.item.text);

    // }

  };

 
  let _pdfExport: any;
  const exportExcel = () => {
    _export.save();
  };

  let _export: any;
  const exportPDF = () => {
    _pdfExport.save();
  };

  const onItemClickImprimir = async (event: any) => {
    openPdfNewTab();
  }

  const close = () => {
    setOpenForm(false);
  }

  const {getVisibleColumns, removeColumnsKeys} = useVisibleColumns();

  const [columsDocuments, setColumsDocuments] = useState<{ columns: { field: string, title: string, width: number | string, show: boolean }[] }>({
    columns: [
      { field: "id", width: 70, title: "ID", show: true },
      { field: "filedoc_cod", width: 190, title: "ID Documento Filedoc", show: true },
      { field: "data_publicacao", width: 170, title: "Data publicação", show: true },
      { field: "tipoRecurso.designacao", width: 180, title: "Tipo de recurso", show: true },
      { field: "titulo", width: 170, title: "Título", show: true },
      { field: "autores", width: 170, title: "Autor", show: true },
      { field: "entidade.nome", width: 190, title: "Entidade", show: true },
      { field: "id_estado", width: 180, title: "Estado", show: true },
    ]
  })

  const [colums, setColums]=useState<{columns:{field:string, title:string, width:number|string, show:boolean}[]}>({
    columns:[
      { field: "id", title: "ID", show: true,width:70 },  
      { field: "descricao", title: "Descrição", show: true,width: 180 },  
      { field: "data_mobilizacao_formatted", title: "Data mobilização", show: true,width: 190 },  
      { field: "volume", title: "Volume", show: true,width: 150 },  
      { field: "tipo_mo.descricao", title: "Tipo de mobilização", show: true,width: 180 },
      { field: "int_mobilizacao_estado.descricao", title: "Estado", show: true,width: 180 },
      { field: "intMobDescritivoLocalDeposicaoNivel1.descricao", title: "Local inicio da deposição", show: true,width: 220 },  
      { field: "intMobDescritivoLocalDeposicaoNivel2.descricao", title: "Local fim da deposição", show: true,width: 220 },   
      { field: "utilizador.name", title: "Técnico", show: true,width: 150 }, 
      { field: "int_mobilizacao_funcao.descricao", title: "Função", show: true,width: 180 }, 
      
    ]
  })

  useEffect(() => {
    SessionStorageService.setItem(keyPainelDocumentos, JSON.stringify(columsDocuments));
  }, [columsDocuments]);
  
  useEffect(() => {
    SessionStorageService.setItem(keyPainelPrincipal, JSON.stringify(colums));
  }, [colums]);

  useLayoutEffect(() => {

    const handleResize = () => {

      if (window.innerWidth <= telaMobile) {
        
        setIsMobile(true);
        ocultaFloatingMenu_e_Mapa();

      }else{

        setIsMobile(false);
        mostraFloatingMenu_e_Mapa();

      }

    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);  


  const onItemClickMais = (event: any) => {

    elementoClickMais(event);

    onItemClickExportar(event);

    onItemClickImprimir(event);

  };

  const optionsMais = [
    {
      key: 'titulo',
      text: 'Imprimir',
    },
    {
      key: 'imprimirFicha',
      text: 'Resultados',
      className: 'icon-siarl icon-imprimir btnIconRegistar'
    },
    {
      key: 'titulo',
      text: 'Exportar',
    },
    ...optionsExportar.map(option => ({
      ...option,
      className: 'icon-siarl icon-exportar btnIconRegistar',
    })),
  ]

  const handleNewRegistoClick = () => {
      setMobilizacaoSelected(undefined);
      selectMobilizacaoResult(undefined);
      setShowResultadoMobilizacao(false);
      SessionStorageService.setItem('mobilizacaoSubmetida', "false")
      SessionStorageService.setItem('mobilizacaoAntiga',"false")
        const loggedUser = JSON.parse(
        localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}'
      )
      setMobilizacaoSelected({ utilizador_id: loggedUser?.user?.name })
      sessionStorage.setItem('novaMobilizacao', 'true')
      if (setShowRegistarMobilizacao) setShowRegistarMobilizacao(true)
  }

  const openAjuda = () => {
    window.open(URL_AJUDA_INTERVENCOES_MOBILIZACAO, "_blank");
  }

  const onItemClickExportarFichas = async () => {
    try {
  
    
      const relatorios = [];
  
      const results = JSON.parse(
        SessionStorageService.getItem('resultadoMobilizacaoPesquisa') ?? ''
      );
    
   

      const idsMobilizacao = results.map((o: any) => o.id);

      if(idsMobilizacao.length  > RECORDS_LIMIT){
        postMessage({ type: 'errorMsg', value: { message: "Não é possivel exportar mais de 100 fíchas" } }, '*')
        return false
        }
    
  
      const mobilizacoes = await getMobilizacoesPorIds(idsMobilizacao);
      
     

        postMessage({ type: 'showLoader' }, '*');
      
        for (const mobilizacaoId of idsMobilizacao) {
  
        const mobilizacao = mobilizacoes.find((mobilizacao: any) => mobilizacao.id === mobilizacaoId);
  
        const objToSendImgMapa : any = {
          CodPrintTemplate: "RELATORIO_INT_MOBILIZACAO",
          extendInfo: {
            spatialReference: {
              wkid: 0
            },
            xmin: 0,
            ymin: 0,
            xmax: 0,
            ymax: 0
          },
          ListOperationalLayerInfo: [
            {
              IdOperationLayer: OPERATIONAL_LAYER,
              UseLayerExtent: false,
              DefinitionExpression: "",
              ListServiceLayer: [
                {
                    LayerId: LAYER_INDEX_INT_MOBILIZACAO,
                    DefinitionExpression: "id=" + mobilizacao?.id,
                    UseLayerExtent: true
                }
              ]
            }
          ]
        }
     
        const relatorio = {
          id: mobilizacao?.id,
          dados: {
            //identificacao
            'Nº Processo APA:': mobilizacao.filedoc_cod ?? '',
            'Técnico:': mobilizacao.tecnico ?? '',
  
            //localizacao
            'Nuts1:': mobilizacao.nuts1[0]?.nome ?? '',
            'Nuts2:': mobilizacao.nuts2[0]?.nome ?? '',
            'Nuts3:': mobilizacao.nuts3[0]?.nome ?? '',
            'Freguesias:': mobilizacao.freguesias[0]?.nome ?? '',
            'Concelhos:': mobilizacao.concelhos[0]?.nome ?? '',
            'Distritos:': mobilizacao.distritos[0]?.nome ?? '',
  
            //data
            'Data de registo:': mobilizacao.data_registo ? new Date(mobilizacao.data_registo).toLocaleDateString() : '',
            'Data da mobilização:': mobilizacao.data_mobilizacao ? new Date(mobilizacao.data_mobilizacao).toLocaleDateString() :'',
  
            //caracterizacao
            'Função:': mobilizacao.int_mobilizacao_funcao?.descricao ?? '',
            'Tipologia:': mobilizacao.tipo_mob_metodo_deposicao?.descricao ?? '',
  
            //tipo de mancha de emprestimo
            'Mancha empréstimo nível 1:': mobilizacao.intMobMancEmprestimoNivel1?.descricao ?? '',
            'Mancha empréstimo nível 2:': mobilizacao.intMobMancEmprestimoNivel2?.descricao ?? '',
  
            //tipo metodo de dragagem
            'Método de dragagem:': mobilizacao.mobMetodoDragagem?.descricao ?? '',
  
            // //tipo de local de deposição
            'Local de deposição nível 1:': mobilizacao.intMobDescritivoLocalDeposicaoNivel1?.descricao ?? '',
            'Local de deposição nível 2:': mobilizacao.intMobDescritivoLocalDeposicaoNivel2?.descricao ?? '',
  
            //metodo deposição
            'Método de deposição:': mobilizacao.mobMetodoDeposicao?.descricao ?? '',
  
            //objetico deposição
            'Objetivo da deposição nivel 1:': mobilizacao.intMobObjetivoDeposicaoNivel1?.descricao ?? '',
            'Objetivo da deposição nivel 2:': mobilizacao.intMobObjetivoDeposicaoNivel2?.descricao ?? '',
  
            // //qualidade dos sedimentos
            'Qualidade dos sedimentos:': mobilizacao.mobQualidade?.descricao ?? '',
  
            //granulametria dos sedimentos
            'Granulometria:': mobilizacao.mobGranulometria?.descricao ?? '',
  
            //estado
            'Estado:': mobilizacao.int_mobilizacao_estado?.descricao ?? '',
  
            //entidade
            'Entidade:': mobilizacao.entidade?.nome ?? '',
  
            //volume
            'Volume:': mobilizacao.volume?.toString() ?? '',
            // 'Descrição': mobilizacoes.descricao ?? '',
   
            // 'td_mob_qualidade': mobilizacoes.td_mob_qualidade?.descricao ?? '',
            // 'td_mob_granulometria': mobilizacoes.td_mob_granulometria?.descricao ?? '',
          },        
          img_mapa: "",
          objJsonToCreateImg: objToSendImgMapa
        };

        
        relatorios.push(relatorio);
    }
  
      const mergedPdfBase64 = await relatorioMobilizacaoMerge(relatorios);
      if (mergedPdfBase64) {
        const blob = b64toBlob(mergedPdfBase64, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
      }
    } catch (error) {
      console.log(error);
      postMessage({ type: 'errorMsg', value: { left: '50%', message: "Verifique os campos obrigatórios." } }, '*');
    } finally {
      postMessage({ type: 'hideLoader' }, '*');
    }
  };

return (
  <div className={clsx(styles.base, isCurrentViewMaximized && commonStyles.maximizedBaseForm)}>
    <div className={styles.header}>
      <div>
        <div className={styles.nomeDoModulo}>Mobilizações</div>
        <div className={styles.tituloDaPagina}>Resultados de pesquisa</div>
      </div>

      <div>

        {hasPermission(PERMISSION_INSERIR_INTERVENCAO) && (  
          hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO) ||
          hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO)
          )? 
          <Button
            onClick={handleNewRegistoClick}
            className={styles.btnNovoRegisto}
            style={{ display: isMobile ? 'none' : '' }}
            id='novoRegisto'
            title='Nova mobilização'
          >
            <span className="icon-siarl icon-registar btnIconRegistar" /> &nbsp;{' '}
            Nova mobilização
          </Button>
        :  ''}

        <CSVLink
          title="Imprimir resultados"
          id="print-results-mobilizacao"
          separator={';'}
          data={mobilizacoes?.map((mobilizacao: any) => ({
            ...mobilizacao,
          }))}
          filename={`siarl-mobilizacao-${format(
            new Date(),
            'ddMMyyyHHmm'
          )}`}
          hidden
        ></CSVLink>

        {!isMobile &&
          <VisualizadorColunas
            columns={selected === 0 ? colums : columsDocuments}
            setColumns={selected === 0 ? setColums : setColumsDocuments}
            styles={styles.btnEditar2}
          />
        }

        <button className={styles.btnEditar2} onClick={onItemClickImprimir} style={{ display: isMobile ? 'none' : '' }} id='imprimirFicha' title='Imprimir'>
          <span className="icon-siarl icon-imprimir"></span>
        </button>

        {!isMobile &&
        <DropDownButton
          items={optionsExportar}
          className={styles.btnImprimir}
          style={{marginTop: '-4px'}}
          iconClass="icon-siarl icon-exportar"
          onItemClick={onItemClickExportar}
          fillMode={'flat'}
          itemRender={itemRender}
          title='Exportar'
        ></DropDownButton>
        }

        {isMobile &&
          <DropDownButton
            items={optionsMais}
            className={styles.btnMais}
            iconClass="icon-siarl icon-mais"
            onItemClick={onItemClickMais}
            fillMode={'flat'}
            itemRender={itemRenderMais}
            title="Mais Opções"
          />
        }

        <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
          <span className="icon-siarl icon-ajuda"></span>
        </button>
        <button className={styles.btnFechar} onClick={onCloseClick} title="Minimizar">
          <span className="icon-siarl icon-minimizar"></span>
        </button>

        {!isMobile &&
        <MaximizeButton className={styles.btnMaximizar} />
        }
      </div>
    </div>

    <TabStrip selected={selected} onSelect={handleSelect} scrollable={isMobile}>
      <TabStripTab title="Mobilizações">
        <ExcelExport
          fileName='Resultado_Mobilizacao'
          data={mobilizacoes}
          ref={(exporter) => {
            _export = exporter
          }}
        >
          <Grid
            className={styles.form}
            sortable={true}
            onDataStateChange={dataStateChange}
            data={dataResult.data.length > 0 ? dataResult : mobilizacoes.slice(page.skip, page.take + page.skip)}
            total={mobilizacoes.length}
            {...dataState}
            pageable={{
              buttonCount: 5,
              info: true,
              type: 'numeric',
              pageSizes: true,
              previousNext: true,
            }}
            //groupable={true}
            skip={page.skip}
            take={page.take}
            onPageChange={handlePageChange}
          >
            <GridColumn cell={mobilizacaoCustomCell} width={50} />
            {colums?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
          </Grid>
        </ExcelExport>

        <GridPDFExport 
          fileName='Resultado_Mobilizazao'
          ref={(element) => {
            _pdfExport = element
          }}
          paperSize={['340mm', '297mm']}         

          margin="1cm"
        >
          <Grid
            className={styles.form}
            sortable={true}
            onDataStateChange={dataStateChange}
             data={mobilizacoes}
              {...dataState}
            pageable={{
              buttonCount: 5,
              info: true,
              type: 'numeric',
              pageSizes: true,
              previousNext: true,
            }}
            //groupable={true}
            total={mobilizacoes.length}

          >
             <GridColumn cell={mobilizacaoCustomCell} width={30} />
             {colums?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
          </Grid>
        </GridPDFExport>
      </TabStripTab>

      <TabStripTab title="Documentos">
        <ExcelExport
          fileName='Resultado_DocumentosMobilizacoes'
          data={documentosMobilizacoes}
          ref={(exporter) => {
            _export = exporter
          }}
        >
          <Grid
            className={styles.form}
            sortable={true}
            onDataStateChange={dataStateChangeDocumentos}
            data={
              dataResultDocumentos.data.length > 0
                ? dataResultDocumentos
                : documentosMobilizacoes
            }
            {...dataState}
            pageable={{
              buttonCount: 5,
              info: true,
              type: 'numeric',
              pageSizes: true,
              previousNext: true,
            }}
            //groupable={true}
          >
            
            <GridColumn cell={mobilizacaoCustomCell} width={86} />
            {columsDocuments?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
          </Grid>
        </ExcelExport>

        <GridPDFExport
          fileName='Resultado_Documentos_Mobilizacao'
          ref={(element) => {
            _pdfExport = element
          }}
          margin="1cm"
        >
          <Grid
            className={styles.form}
            sortable={true}
            onDataStateChange={dataStateChangeDocumentos}
            data={
              dataResultDocumentos.data.length > 0
                ? dataResultDocumentos
                : documentosMobilizacoes
            }
            {...dataState}
            pageable={{
              buttonCount: 5,
              info: true,
              type: 'numeric',
              pageSizes: true,
              previousNext: true,
            }}
            //groupable={true}
          >
               {columsDocuments?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
          </Grid>
        </GridPDFExport>
      </TabStripTab>
    </TabStrip>
    {openForm && (
        <Dialog
        className="ModalDocumentos"
          title={`Documento: ${idDoDocumento}`}
          onClose={close}
          style={{ zIndex: 999 }}
        >
          
            <ImagePreviewV2
              documentUrl={urlDoDocumento}
              next={(e: any) => nextPhoto(e)}
              previous={(e: any) => previousPhoto(e)}
            />

        </Dialog>
      )}
    <div
      className={clsx(styles.footer, styles.justify_one_child, isCurrentViewMaximized && commonStyles.maximizedFooter)}
    >
      <Button className={styles.btnPesquisar} onClick={clearSearchResults} title='Nova Pesquisa'>
        <span className="icon-siarl icon-pesquisar"></span>
        <span className={styles.rotulo}>
          {' '}&nbsp;{' '}
          Nova pesquisa
        </span>
      </Button>
    </div>
  </div>
)
} 