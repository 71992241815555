import { Button } from '@progress/kendo-react-buttons';
import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import { ComboBox, ComboBoxFilterChangeEvent } from '@progress/kendo-react-dropdowns';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { Checkbox, Input, RadioGroup } from '@progress/kendo-react-inputs';
import {
  MouseEvent as ReactMouseEvent,
  useEffect,
  useState
} from 'react';
import SessionStorageService from '../../services/SessionStorageService';
import { arhs, capitania, concelhos, coordenadasPoligono, distritos,  entidades,  estadosVus, fillArhsAsync, fillCapitaniaAsync, fillCombosAuxiliares, fillConcelhosAsync, fillDistritosAsync, fillFreguesiasAsync, fillNuts1Async, fillNuts2Async, fillNuts3Async, fillPraiasAsync, fillTipoEntidades, fillTiposProcessoAsync, freguesias, handleGenericError, nuts1, nuts2, nuts3, post, praias, setCoordenadasPoligono, tipoEntidades, tipossProcesso, tipoTitulos, estadosTitulo, tecnicos, useCombos, tipoDeProcesso, fillUsTiposProcessoAsync, TiposUtilizacao, fillUsTiposUtilizacaoAsync } from '../../services/utils';
import styles from '../../styles/usodosolo/ConsultarUsoDoSoloTabsForm.module.scss';
import { AREA_VISIVEL, CONSULTAR_US_TITULO, NENHUM, POLIGONO_SELECAO, clearLayers } from '../../utils/constants';
import { filterBy, FilterDescriptor } from '@progress/kendo-data-query';
import FieldsetCombosLocalizacao from '../localizacao/FieldsetCombosLocalizacao';
import ReactGA from 'react-ga4';

type IProps = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
}

type UsoDoSoloTituloSearchForm = {
  // Registo
  id?: number;
  tecnico_id?: number;
  entidade_id?: number;
  id_processo?: number;
  estado_id?: number;
  origem?: any;
  de_data_registo?: any;
  ate_data_registo?: any;

  // Identificação
  designacao?: string;
  de_entrada_vigor?: string;
  ate_entrada_vigor?: string;
  de_expiracao?: string;
  ate_expiracao?: string;
  de_alteracao_licenciamento?: string;
  ate_alteracao_licenciamento?: string;
  nome_requerente?: string;
  nif_requerente?: number;
  codigo_processo?: string;
  codigo_utilizacao? : string;


  // Critério Geográfico
  criterio_geografico?: number;

  // Localização
  nuts1_cod?: string;
  nuts2_cod?: string;
  nuts3_cod?: string;
  distrito_cod?: string;
  concelho_cod?: string;
  freguesia_cod?: string;
  arh_id?: unknown;
  capitania_id?: number;
  massa_agua_id?: number;
  localidade?: string;
  praia_id?: number;

  // Tipologia
  tipo_titulo_id?: number;
  tipo_processo_id?: number;
  tipo_utilizacao_id?: number;
  

  descricao?: string;
  
  
  mo_modelo_subtipo_id?: number;
  mo_modelo_tipo_id?: number;
  flatCoordinates?: string;

}

type FormType = {
  // Registo
  Id: number;
  tecnico: any;
  entidade_id: any;
  processo_id: number;
  estadoRegisto: any;
  origem: any;
  de_data_registo: any;
  ate_data_registo: any;

  // Identificação
  designacao: string;
  de_entrada_vigor: string;
  ate_entrada_vigor: string;
  de_expiracao: string;
  ate_expiracao: string;
  de_alteracao_licenciamento: string;
  ate_alteracao_licenciamento: string;
  nomeRequerente: string;
  nifRequerente: number;
  codProcesso: string;
  codUtilizacao: string;

  // Critério Geográfico
  rbCriterio: number;
  
  // Localização
  nuts1: Nuts1;
  nuts2: Nuts2;
  nuts3: Nuts3;
  distrito: Nuts1;
  concelho: Concelho;
  freguesia: Freguesia;
  arh: Nuts1;
  capitania: any;
  massaAgua: Nuts1;
  //local: string;
  praia: any;

  // Tipologia
  tipoTitulo: any;
  tipoDeProcesso: any;
  tipoUtilizacao: any;
}


type TipoEntidade = {
  id: number;
  designacao: string;
}

type Freguesia = {
  id: number;
  codigo: string;
  concelho_cod: string;
  nome: string;
}

type Concelho = {
  id: number;
  codigo: string;
  nome: string;
  distrito_cod: string;
}

type Nuts3 = {
  id: number;
  codigo: string;
  nuts_ii_cod: string;
  nuts2?: any;
  nome: string;
}

type Nuts2 = {
  id: number;
  codigo: string;
  nuts_i_cod: string;
  nuts1?: any;
  nome: string;
}

type Nuts1 = {
  id: number;
  codigo: string;
  nome: string;
}

type Origem = {
  label: string;
  value: string;
}
export default function TituloForm({ onCloseClick }: IProps) {
  const [dataRegistoInicio, setDataRegistoInicio] = useState<any>()
  const [dataRegistoFim, setDataRegistoFim] = useState<any>()
  const [registoVisible, setRegistoVisible] = useState(true)
  const [criterioGeograficoVisible, setCriterioGeograficoVisible] = useState(false)
  const [identificacaoVisible, setIdentifcacaoVisible] = useState(false)
  const [localizacaoVisible, setLocalizacaoVisible] = useState(false)
  const [tipologiaVisible, setTipologiaVisible] = useState(false)
  const [requerenteVisible, setRequerenteVisible] = useState(false)
  const [outrosCriteriosVisible, setOutrosCriteriosVisible] = useState(false)
  const [dataDeAlteracao, setDataDeAlteracao] = useState<any>()
  const [dataAteAlteracao, setDataAteAlteracao] = useState<any>()
  const [dataDeEntradaVigor, setDataDeEntradaVigor] = useState<any>()
  const [dataAteEntradaVigor, setDataAteEntradaVigor] = useState<any>()
  const [dataDeExpiracao, setDataDeExpiracao] = useState<any>()
  const [dataAteExpiracao, setDataAteExpiracao] = useState<any>()
  const [minData, setMinDate] = useState<any>();
  const [tipoUtilizacaoData, setTipoUtilizacaoData] = useState<any>();

  const getTitulosUsoDoSolo = async (data: any) => await post<any, any>(`${CONSULTAR_US_TITULO}`, data)

  type combosKeys = 'entidades' | 'tipoEntidades' | 'tipoDeProcesso' | 'TiposUtilizacao' | 'tipoTitulos' | 'origens' | 'estadosTitulo' | 'tecnicos';

  const [combos, setCombos] = useState<any>({
    entidades,
    tipoEntidades,
    tipoDeProcesso,
    TiposUtilizacao:[],
    tipoTitulos,
    origens:[],
    estadosTitulo,
    tecnicos
    
  })
  const setDataOption: Record<combosKeys, { setFn: any, data: any[] }> = {
    entidades: { setFn: setCombos, data: entidades },
    tipoEntidades: { setFn: setCombos, data: tipoEntidades },
    tipoDeProcesso: { setFn: setCombos, data: tipoDeProcesso },
    TiposUtilizacao: { setFn: setCombos, data: TiposUtilizacao },
    tipoTitulos: { setFn: setCombos, data: tipoTitulos },
    origens: { setFn: setCombos, data: [] },
    estadosTitulo: { setFn: setCombos, data: estadosTitulo },
    tecnicos: { setFn: setCombos, data: tecnicos }
  };

  const filterCombo_Multiselect = (e: any, tipoCombo: combosKeys) => {
    const { setFn, data } = setDataOption[tipoCombo];
      setFn({
          ...combos,
          [tipoCombo]: filterBy(data?.slice()??[], e.filter)
      }
      );
}

useEffect(() => {
  ReactGA.send({ hitType: "pageview", page: "/ConsultarUsosSoloTitulo" });
}, []);

 useEffect(() => {
        
    if(!nuts1 ){
      fillNuts1Async();
      }
     if(!nuts2) {
      fillNuts2Async();
     }
     if(!nuts3){
      fillNuts3Async();
     }
     if(!distritos){
      fillDistritosAsync();
     }
     if(!concelhos){
      fillConcelhosAsync();
     }
     if(!freguesias){
      fillFreguesiasAsync();
     }
     if(!arhs){
      fillArhsAsync();
     }
     if(!capitania){
      fillCapitaniaAsync();
     }
     if(!praias){
      fillPraiasAsync();
     }
     if(!tipoEntidades){
      fillTipoEntidades();
     }
     if(!tipoDeProcesso){
      fillUsTiposProcessoAsync();
     }
     if (!TiposUtilizacao) {
      fillUsTiposUtilizacaoAsync();
     }
     setTipoUtilizacaoData(TiposUtilizacao.slice());
    
  }, []);
  const toggleRegisto = () => {
    setRegistoVisible(!registoVisible)
  }

  const toggleIdentificacao = () => {
    setIdentifcacaoVisible(!identificacaoVisible)
  }

  const toggleCriterioGeografico = () => {
    setCriterioGeograficoVisible(!criterioGeograficoVisible)
  }

  const toggleTipologia = () => {
    setTipologiaVisible(!tipologiaVisible)
  }

  const clearMap = () => {
    try {

      setCoordenadasPoligono(undefined)
      const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.contentWindow?.postMessage({ type: "SiarlUsTituloClearLayers", value: "true" }, '*')
      iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: "true" }, '*')
      iframe.contentWindow?.postMessage({ type: "SiarlDeselectMapArea", value: "true" }, '*')

    } catch (error) {
      console.log({ error })
    }
  }

  const changerbCriterio = (e: any) => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlDeselectMapArea", value: 'true' }, '*')
    if (e.value == POLIGONO_SELECAO) {
        postMessage(
            { type: 'informationMsg', value: 'Selecione a área de pesquisa' },
            '*'
        )
        iframe.contentWindow?.postMessage(
            { type: 'SiarlGeoPortalAdicionarArea', value: 'true' },
            '*'
        )
    }
    if (e.value == AREA_VISIVEL) {
        iframe.contentWindow?.postMessage({ type: "SiarlSelectMapArea", value: 'true' }, '*')
        postMessage(
            {
                type: 'informationMsg',
                value: 'Foi selecionada a área visível do mapa',
            },
            '*'
        )
       
        iframe.contentWindow?.postMessage(
            { type: 'SiarlGetMapArea', value: 'true' },
            '*'
        )
    }
    if (e.value == NENHUM) {
        //setCoordenadasPoligono(undefined)
        clearMap()
    }
  }

  const handleSubmit = async (e: any) => {

    try {
      postMessage({ type: 'showLoader' }, '*')
      console.log({ values: e.values })
      const formValues = e?.values as FormType
      const dataToSend: UsoDoSoloTituloSearchForm = {

        // Registo
        id: formValues?.Id ? Number(formValues?.Id) : undefined,
        estado_id: formValues?.estadoRegisto,
        tecnico_id: formValues?.tecnico?.id,
        //entidade_id: formValues?.entidade_id?.id,
        id_processo: formValues?.processo_id,
        origem: formValues?.origem?.id,
        de_data_registo: formValues?.de_data_registo,
        ate_data_registo: formValues.ate_data_registo,

        // Identificação
        designacao: formValues?.designacao,
        de_alteracao_licenciamento: formValues?.de_alteracao_licenciamento,
        ate_alteracao_licenciamento: formValues?.ate_alteracao_licenciamento,
        de_entrada_vigor: formValues?.de_entrada_vigor,
        ate_entrada_vigor: formValues?.ate_entrada_vigor,
        de_expiracao: formValues?.de_expiracao,
        ate_expiracao: formValues?.ate_expiracao,
        nome_requerente: formValues?.nomeRequerente,
        nif_requerente: formValues?.nifRequerente,
        codigo_processo: formValues?.codProcesso,
        codigo_utilizacao: formValues?.codUtilizacao,

        // Critério Geográfico
        criterio_geografico: formValues?.rbCriterio,

        // Localização
        nuts1_cod: formValues?.nuts1?.codigo,
        nuts2_cod: formValues?.nuts2?.codigo,
        nuts3_cod: formValues?.nuts3?.codigo,
        distrito_cod: formValues?.distrito?.codigo,
        concelho_cod: formValues?.concelho?.codigo,
        freguesia_cod: formValues?.freguesia?.codigo,
        arh_id: formValues?.arh?.codigo,
        massa_agua_id: formValues?.massaAgua?.id,
        capitania_id: formValues?.capitania?.id,
        //localidade: formValues?.local,
        praia_id: formValues?.praia?.id,

        // Tipologia
        tipo_titulo_id: formValues?.tipoTitulo?.id,
        tipo_processo_id: formValues?.tipoDeProcesso?.id,
        tipo_utilizacao_id: formValues?.tipoUtilizacao?.id,

        flatCoordinates: coordenadasPoligono,

        //descricao: formValues?.codProcesso,
      }

      SessionStorageService.setItem("lastSearchTitulo", dataToSend);
      const resultadoUsoDoSolo = await getTitulosUsoDoSolo(dataToSend);
       SessionStorageService.setItem(
         'resultadoUsoDoSoloPesquisa',
         JSON.stringify(resultadoUsoDoSolo)
       )
       SessionStorageService.setItem("firstCallUsTitulo", true);
      postMessage({ type: "form", value: "pesquisarUsoDoSolo" }, "*");
    } catch (error) {
      handleGenericError(error);
    } finally {
      postMessage({ type: 'hideLoader' }, '*')

    }
  }

  const filterChangeUtilizacao = (event: ComboBoxFilterChangeEvent) => {
    const data = TiposUtilizacao.slice();
    setTipoUtilizacaoData(filterBy(data, event.filter));
  };

  const PermitirNumeros = (e: React.ChangeEvent<HTMLInputElement>) => {
    const ApenasNumeros = e.target.value.replace(/\D/g, '');
    e.target.value = ApenasNumeros;
  };

  const tipos_registo = [
    {id: 1, text: "Registo Manual"},
    {id: 2, text: "Siliamb"}
  ]

  return (
    <Form
      onSubmitClick={handleSubmit}
      render={(formRenderProps: FormRenderProps) => (
        <>
          <FormElement className={styles.form}>
            <fieldset>
              <legend>
                <button type='button' className={styles.itemExpandBase} onClick={toggleRegisto}>
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span className={registoVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                  </div>
                  <span className={styles.fieldsetTitulo}>Registo</span>
                </button>
              </legend>
              {registoVisible && (
                <div className={styles.espacoVerticalFieldSet}>
                  <div className={styles.fields}>
                    <div>
                      <Field name="Id" label="ID" component={Input} />
                    </div>
                    <div>
                      <Field
                          name="estadoRegisto"
                          label="Estado do Registo"
                          component={ComboBox}
                          textField={'descricao'}
                          filterable={true}
                          onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'estadosTitulo')}}
                          data={combos?.estadosTitulo}
                        />
                    </div>
                  </div>

                  <div className={styles.fields}>
                    <div>
                    <Field
                          name="tecnico"
                          label="Técnico"
                          component={ComboBox}
                          textField={'name'}
                          filterable={true}
                          onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'tecnicos')}}
                          data={combos?.tecnicos}
                        />
                    </div>
                    <div>
                    <Field
                        name="entidade_id"
                        label="Entidade"
                        component={ComboBox}
                        textField={'nome'}
                        filterable={true}
                        onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'entidades')}}
                        data={combos?.entidades}
                      />
                    </div>
                  </div>

                  <div className={styles.fields}>
                    <div>
                    <Field
                        name="processo_id"
                        label="ID Processo"
                        component={Input}
                      />
                    </div>

                    <div>
                      <Field
                        name="origem"
                        label="Origem registo"
                        component={ComboBox}
                        textField={"text"}
                        filterable={true}
                        data={tipos_registo}
                      />
                    </div>
                  </div>

                  <div className={styles.fields}>
                    <div>
                      <Field
                        name="de_data_registo"
                        label="Data de registo de"
                        value={dataRegistoInicio}
                        format="dd/MM/yyyy HH:mm"
                        component={DateTimePicker}
                        onChange={(e) => {
                          setDataRegistoInicio(e.value)
                          setMinDate(e.value);
                        }}                        />
                    </div>
                    <div>
                      <Field
                        name="ate_data_registo"
                        label="Data de registo até"
                        value={dataRegistoFim}
                        format="dd/MM/yyyy HH:mm"
                        component={DateTimePicker}
                        onChange={(e) => {
                          setDataRegistoFim(e.value)
                        }}        
                        min={minData}                
                      />
                    </div>
                  </div>
                </div>
              )}

            </fieldset>

            <fieldset>
              <legend>
                <button type='button' className={styles.itemExpandBase} onClick={toggleIdentificacao}>
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span className={identificacaoVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                  </div>
                  <span className={styles.fieldsetTitulo}>Identificação</span>
                </button>
              </legend>
              {identificacaoVisible && (
                <div className={styles.espacoVerticalFieldSet}>
                  <div className={styles.fields}>
                    <Field
                      name="designacao"
                      label="Designação"
                      component={Input}
                    />
                  </div>

                    <div className={styles.fields}>
                      <div>
                        <Field
                          name="de_entrada_vigor"
                          label="Entrada em vigor de"
                          value={dataDeEntradaVigor}
                          format="dd/MM/yyyy HH:mm"
                          component={DateTimePicker}
                          onChange={(e) => {
                            setDataDeEntradaVigor(e.value)
                             setMinDate(e.value);
                          }}   
                        />
                      </div>
                      <div>
                        <Field
                          name="ate_entrada_vigor"
                          label="Entrada em vigor até"
                          value={dataAteEntradaVigor}
                          format="dd/MM/yyyy HH:mm"
                          component={DateTimePicker}
                          onChange={(e) => {
                            setDataAteEntradaVigor(e.value)
                          }} 
                          min={minData}
                        />
                      </div>
                    </div>

                    <div className={styles.fields}>
                      <div>
                        <Field
                          name="de_expiracao"
                          label="Data expiração de"
                          value={dataDeExpiracao}
                          format="dd/MM/yyyy HH:mm"
                          component={DateTimePicker}
                          onChange={(e) => {
                            setDataDeExpiracao(e.value)
                            setMinDate(e.value);
                          }}   
                        />
                      </div>

                      <div>
                        <Field
                          name="ate_expiracao"
                          label="Data expiração até"
                          value={dataAteExpiracao}
                          format="dd/MM/yyyy HH:mm"
                          component={DateTimePicker}
                          onChange={(e) => {
                            setDataAteExpiracao(e.value)
                          }}
                          min={minData}
                        />
                      </div>
                    </div>

                    <div className={styles.fields}>
                      <div>
                        <Field
                          name="de_alteracao_licenciamento"
                          label="Data alteração licenciamento de"
                          value={dataRegistoInicio}
                          format="dd/MM/yyyy HH:mm"
                          component={DateTimePicker}
                          onChange={(e) => {
                            setDataDeAlteracao(e.value)
                             setMinDate(e.value);
                          }}                        />
                      </div>
                      <div>
                        <Field
                          name="ate_alteracao_licenciamento"
                          label="Data alteração licenciamento até"
                          value={dataRegistoFim}
                          format="dd/MM/yyyy HH:mm"
                          component={DateTimePicker}
                          onChange={(e) => {
                            setDataAteAlteracao(e.value)
                          }}        
                          min={minData}                
                        />
                      </div>
                    </div>

                    <div className={styles.fields}>
                      <div>
                      <Field
                          name="nomeRequerente"
                          label="Nome do requerente"
                          component={Input}
                        />
                      </div>
                      <div>
                        <Field
                          name="nifRequerente"
                          label="NIF do requerente"
                          component={Input}
                          type={'number'}
                          maxLength={9}
                        />
                      </div>
                    </div>

                    <div className={styles.fields}>
                    <div>
                      <Field
                        name="codProcesso"
                        label="Código processo"
                        component={Input}
                      />
                    </div>
                    <div>
                      <Field
                        name="codUtilizacao"
                        label="Código Utilização"
                        component={Input}
                      />
                    </div>
                  </div>
                </div>
              )}
            </fieldset>

            <fieldset>
              <legend>
                <button type='button' className={styles.itemExpandBase} onClick={toggleCriterioGeografico}>
                    <div className={styles.btn_Expandir_Colapsar}>
                      <span className={criterioGeograficoVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                    </div>
                    <span className={styles.fieldsetTitulo}>Critério geográfico</span>
                  </button>
              </legend>

              {criterioGeograficoVisible && (
                <div className={styles.espacoVerticalFieldSet}>
                  <div className={styles.fields}>
                    <div>
                      <Field
                        id={'rbCriterio'}
                        name={'rbCriterio'}
                        layout={'horizontal'}
                        component={RadioGroup}
                        onChange={changerbCriterio}
                        data={[
                          { label: 'Nenhum', value: 0 },
                          { label: 'Área visível', value: 1 },
                          { label: 'Polígono de seleção', value: 2 },
                        ]}
                        defaultValue={0} // Set the default selected value to 0 (Nenhum)

                      />
                    </div>
                  </div>

                </div>
              )}

            </fieldset>

            <FieldsetCombosLocalizacao isPesquisaAvancada={true} styles={styles} haveLocal={true} havePraia={true} formRenderProps={formRenderProps}/>

            <fieldset>
              <legend>
                <button type='button' className={styles.itemExpandBase} onClick={toggleTipologia}>
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span className={tipologiaVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                  </div>
                  <span className={styles.fieldsetTitulo}>Tipologia</span>
                </button>
              </legend>
              {tipologiaVisible && (
                <div className={`${styles.espacoVerticalFieldSet}`}>
                    <div>
                      <Field
                        name="tipoTitulo"
                        label="Tipo de título"
                        component={ComboBox}
                        textField={'descricao'}
                        filterable={true}
                        onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'tipoTitulos')}}
                        data={combos?.tipoTitulos}
                      />
                    </div>
                    <div>
                      <Field
                        name="tipoDeProcesso"
                        label="Tipo de processo"
                        component={ComboBox}
                        textField={'nome'}
                        filterable={true}
                        onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'tipoDeProcesso')}}
                        data={combos?.tipoDeProcesso}
                      />
                    </div>

                    <div>
                      <Field
                        name="tipoUtilizacao"
                        label="Tipo de utilização"
                        component={ComboBox}
                        textField={'nome'}
                        filterable={true}
                        onFilterChange={filterChangeUtilizacao}
                        data={tipoUtilizacaoData}
                      />
                    </div>
                </div>
              )}
            </fieldset>

          </FormElement>
          <div className={`${styles.footer} ${styles.justify_multiple_children}`}>
            <Button
              onClick={() => { formRenderProps.onFormReset(); clearMap()}}
              className={styles.btnLimparPesquisa}
              title='Limpar pesquisa'
            >
              <span className="icon-siarl icon-limparcampos"></span>
              <span className={styles.rotulo}>
                {' '}&nbsp;{' '}
                Limpar pesquisa
              </span>        
            </Button>
            <Button
              onClick={formRenderProps.onSubmit}
              className={styles.btnPesquisar}
              title='Pesquisar'
            >
              <span className="icon-siarl icon-pesquisar"></span>
              <span className={styles.rotulo}>
                {' '}&nbsp;{' '}
                Pesquisar
              </span>       
            </Button>
          </div>
        </>
      )}
    />
  );
}