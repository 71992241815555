import { create } from 'zustand'

type AcaoState = {
    selectedAcaoResult?: unknown
    selectAcaoResult: (payload: unknown) => void
    selectedAcaoResultId?: number
    selectAcaoResultId: (payload?: number) => void
    identificacaoTabIsValid: boolean
    selectAcaoIdentificacaoTabIsValid: (payload: unknown) => void
     identificacaoTabValidationObject: Record<string, boolean>
    addIdentificacaoTabValidatedField: (payload: Record<string, boolean>) => void
   
}

export const useAcaoStore = create<AcaoState>((set, get) => ({
    selectedAcaoResult: null,
    identificacaoTabIsValid: false,
    identificacaoTabValidationObject: {},
    selectAcaoResult: (payload: unknown) =>
        set({ selectedAcaoResult: payload }),
    selectAcaoResultId: (payload?: number) =>
        set({ selectedAcaoResultId: payload }),
    selectAcaoIdentificacaoTabIsValid: (payload?: any) =>
        set({ identificacaoTabIsValid: payload }),
    addIdentificacaoTabValidatedField: (payload) => {
        const validationObj = get().identificacaoTabValidationObject
        const newValidationObj = { ...validationObj, ...payload }
        set({ identificacaoTabValidationObject: newValidationObj })
        const validationValues = Object.values(newValidationObj)
        set({ identificacaoTabIsValid: validationValues.every(value => value === true) })
    }
}))
