import { Dialog } from '@progress/kendo-react-dialogs'
import { Grid, GridColumn, GridPageChangeEvent } from '@progress/kendo-react-grid'
import { useRef, useState } from 'react'
import { PagerTargetEvent } from '@progress/kendo-react-data-tools'

type IProps ={
  label?:string, 
  list?:[], 
  property?:any
}
type PageState = {
  skip: number
  take: number
}

const initialDataState: PageState = { skip: 0, take: 10 }

export default function CheckMoreItem({label,list, property }: IProps) {
  const [openForm, setOpenForm] = useState<boolean>(false)

  const openOrclose = (e:any) => {
    setOpenForm(!openForm)
  }
 const dialogRef =useRef(null);
 const [page, setPage] = useState<PageState>(initialDataState)
 const [pageSizeValue, setPageSizeValue] = useState<
 number | string | undefined
>()
 const handlePageChange = (event: GridPageChangeEvent) => {
  const targetEvent = event.targetEvent as PagerTargetEvent
  const take = event.page.take

  if (targetEvent.value) {
    setPageSizeValue(targetEvent.value)
  }

  setPage({
    ...event.page,
    take,
  })
}

  return (
    <>
        <div>
                <div className='k-floating-label-container k-text-disabled' >
                  <div className='class="k-multiselect k-input k-input-md k-rounded-md k-input-solid'>
                    <div className='k-input-values' style={{margin:(list && list?.length>0)?7:0}}>
                        {(list && list?.length>0) ?
                      <div className='k-chip-list k-chip-list-md' >
                    
                      <div className='k-chip k-chip-md k-rounded-md k-chip-solid k-chip-solid-base k-disabled'>
                        <span className='k-chip-content'>
                            <span className='k-chip-label'> {`${(list?.at(0)?.[property?property:"designacao"])}`}</span>
                          <span className='k-chip-actions'>
                          </span>
                        </span>
                         
                      </div>
                      </div>: <div className='k-input-inner'>{label}</div>
                         }

                     
                       
                    </div>
                    {(list && list?.length> 1 ) &&
                    <button  title="Ver mais" type="button" aria-label="expand combobox" onClick={openOrclose} className="k-button k-button-md k-button-solid k-button-solid-base k-icon-button k-input-button">
                      <span className="k-icon k-i-caret-alt-down k-button-icon" role="presentation"></span>
                      </button>
                      }
                  </div>
                  {(list && list?.length> 0 ) && 
                  <label className='k-label'>
                      {`${label} ${(list && list?.length> 1) ? "("+list?.length+")":""}`  }
                  </label>
                  }
                </div>
                </div>
                {openForm && ( <Dialog title={label?label:"Elementos"} onClose={openOrclose} style={{zIndex:999}} key={`${label}`} ref={dialogRef.current}>
                                <div style={{ width: '100%', maxWidth: 680 }}>
                                <Grid
                                style={{height:500}}
                                data={list?.slice(page.skip, page.take + page.skip)}
                                pageable={{
                                    buttonCount: 5,
                                    info: true,
                                    type: 'numeric',
                                    pageSizes: true,
                                    previousNext: true,
                                  }}
                                  skip={page.skip}
                                  take={page.take}
                                  total={list?.length}
                                  onPageChange={handlePageChange}
                                >
                              <GridColumn field="codigo" width={100} title="Código" />
                              <GridColumn field="nome" title="Nome" />

                                </Grid>
                                </div>


                              </Dialog>)}
    </>
  );

  
}