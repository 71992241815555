import { DateTimePicker } from '@progress/kendo-react-dateinputs'
import { ComboBox } from '@progress/kendo-react-dropdowns'
import { Field, FormRenderProps } from '@progress/kendo-react-form'
import { Input, TextArea } from '@progress/kendo-react-inputs'
import { MouseEvent as ReactMouseEvent, useEffect, useState } from 'react'

import {
  capitania,
  concelhos,
  diplomas,
  distritos,
  fillCombosAuxiliares,
  fillConcelhosAsync,
  fillDistritosAsync,
  fillFreguesiasAsync,
  fillNuts1Async,
  fillNuts2Async,
  fillNuts3Async,
  freguesias,
  get,
  getPatrimonioSelected,
  hasPermission,
  lugarToponimico,
  massaagua,
  nuts1,
  nuts2,
  nuts3,
  post,
  put,
  regiaoHidrografica,
  setPatrimonioSelected,
  tecnico,
  useCombos,
  userLogged
} from '../../services/utils'
import styles from '../../styles/dominiohidrico/DominioHidrico.module.scss'

import { Button } from '@progress/kendo-react-buttons'
import { usePatrimonioStore } from '../../stores/patrimonio'
import { GET_PATRIMONIO, IMPORT_GEOM_DH_PATRIMONIO, PERMISSION_DOMINIO_HIDRICO_PERFIL_BASICO, PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_EDICAO, PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_LEITURA, PERMISSION_DOMINIO_HIDRICO_PERFIL_TOTAL, SAVE_NEW_PATRIMONIO, UPDATE_PATRIMONIO } from '../../utils/constants'
import ImportarGeometriaComponent from '../apoiogestao/registar/ImportarGeometriaForm'
import CheckMoreItem from '../documentos/registar/fields/CheckMoreItem'
import { filterBy } from '@progress/kendo-data-query'

type IProps = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
  formRenderProps: FormRenderProps
  viewMode?: boolean

}

export default function IdentificacaoPatrimonio({ onCloseClick, formRenderProps, viewMode }: IProps) {
  const [freguesiasFiltred, setFreguesiasFiltred] = useState<any>()
  const [openImportModal, setOpenImportModal] = useState<any>()
  const [nuts2Filtered, setNuts2Filtered] = useState<any>()
  const [nuts3Filtered, setNuts3Filtered] = useState<any>()
  const [concelhosFiltred, setConcelhosFiltred] = useState<any>()

  const selectedPatrimonioResult: any = usePatrimonioStore((state) => state.selectedPatrimonioResult);
  const selectedPatrimonioResultId: any = usePatrimonioStore((state) => state.selectedPatrimonioResultId);
  const selectPatrimonioResult = usePatrimonioStore((state) => state.selectPatrimonioResult)
  const selectPatrimonioResultId = usePatrimonioStore((state) => state.selectPatrimonioResultId)
  type combosKeys= 'diplomas'| 'nuts1' | 'nuts2' | 'nuts3' | 'distritos'| 'concelhos'|'freguesias' | 'regiaoHidrografica' | 'massaagua' | 'capitania';

  const [combos, setCombos] = useState<any>({
        diplomas,
        nuts1,
        nuts2,
        nuts3,
        distritos,
        concelhos,
        freguesias,
        massaagua, 
        regiaoHidrografica,
        capitania
      })

      const setDataOption: Record<combosKeys, { setFn: any, data: any[] }> = {
        diplomas: {
          setFn: setCombos,
          data: diplomas
        },
        nuts1: {
          setFn: setCombos, 
          data: nuts1
        },
        nuts2: {
          setFn: setCombos,
          data: nuts2Filtered
        },
        nuts3: {
          setFn: setCombos,
          data: nuts3Filtered
        },
        distritos: {
          setFn: setCombos,
          data: distritos
        },
        concelhos: {
          setFn: setCombos,
          data: concelhosFiltred
        },
        freguesias: {
          setFn: setCombos,
          data: freguesiasFiltred
        },
        massaagua: {
          setFn: setCombos, 
          data: massaagua 
        },
        regiaoHidrografica: {
          setFn: setCombos,
          data: regiaoHidrografica
        },
        capitania: {
          setFn: setCombos,
          data: capitania
        }
      };
      
      const filterCombo_Multiselect = (e: any, tipoCombo: combosKeys) => {
        const { setFn, data } = setDataOption[tipoCombo];
        setFn({
            ...combos,
            [tipoCombo]: filterBy(data?.slice()??[], e.filter)
        }
        );
    }
    
    useEffect(() => {
    
     if(!nuts1 ){
      fillNuts1Async();
      }
     if(!nuts2) {
      fillNuts2Async();
     }
     if(!nuts3){
      fillNuts3Async();
     }
     if(!distritos){
      fillDistritosAsync();
     }
     if(!concelhos){
      fillConcelhosAsync();
     }
     if(!freguesias){
      fillFreguesiasAsync();
     }
     

  }, []);

  useEffect(() => {
    if (!tecnico) {
      userLogged()
    }
  }, [tecnico])
  
  const importGeomDhProcesso = async (data: any) =>
    await post<any, any>(`${IMPORT_GEOM_DH_PATRIMONIO}`, data)
  const getDhPatrimonioById = async (id: any) =>
    await get<any, any>(`${GET_PATRIMONIO}/` + id)
  const saveDhPatrimonio = async (data: any) => await post<any, any>(`${SAVE_NEW_PATRIMONIO}`, data);
  const updateDhPatrimonio = async (data: any) => await put<any, any>(`${UPDATE_PATRIMONIO}`, data);
  

  const filterConcelho = (e: any) => {
    if (e?.value?.codigo) {
      const concelhosLocal = concelhos.filter(
        (x: any) => x.distrito_cod == e.value.codigo
      )
      setConcelhosFiltred(concelhosLocal)
    } else {
      setConcelhosFiltred(concelhos)
    }
  }

  const filterFreguesia = (e: any) => {
    if (e?.value?.codigo) {
      const freguesiaLocal = freguesias.filter(
        (x: any) => x.concelho_cod == e.value.codigo
      )
      setFreguesiasFiltred(freguesiaLocal)
    } else {
      setFreguesiasFiltred(freguesias)
    }
  }
  const filterNuts2 = (e: any) => {
    if (e?.value?.codigo) {
      const nutsLocal = nuts2.filter((x: any) => x.nuts_i_cod == e.value.codigo)
      setNuts2Filtered(nutsLocal)
    } else {
      setNuts2Filtered(nuts2)
    }
  }
  const filterNuts3 = (e: any) => {
    if (e?.value?.codigo) {
      const nutsLocal = nuts3.filter(
        (x: any) => x.nuts_ii_cod == e.value.codigo
      )
      setNuts3Filtered(nutsLocal)
    } else {
      setNuts3Filtered(nuts3)
    }
  }

  const canSeeDominioHidrico = 
                            hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_LEITURA) ||
                            hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_EDICAO) ||
                            hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_TOTAL) 

 const allPermissionDominioHidrico = 
                            hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_BASICO) ||
                            hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_LEITURA) ||
                            hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_EDICAO) ||
                            hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_TOTAL) 

                            


  const changeFileDoc = (e:any) => {
    setPatrimonioSelected({...getPatrimonioSelected(), filedoc_cod: e?.value})
  }

  return (
    <>
    

      <div className={`${styles.form} ${styles.removeScrollDuplicado}`}>
        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Registo</span>
          </legend>
          <div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}>
                  {canSeeDominioHidrico &&

              <><div>
                <Field
                  name="id"
                  label="ID"
                  component={Input}
                  disabled />
              </div>
              <div>
                  <Field
                    name="filedoc_cod"
                    label="Nº Processo APA"
                    component={Input}
                    onChange={changeFileDoc}
                    disabled={viewMode} />
                </div></>
           }
            </div>
            <div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}>
              {canSeeDominioHidrico || hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_BASICO) ?

              <div>
                <Field
                  name="tecnicoRegisto"
                  label="Técnico"
                  disabled
                  component={Input}
                  
                />
               </div>
              : ''
            } 
            {canSeeDominioHidrico &&
              <div>
                <Field
                  name="dataHoraRegisto"
                  label="Data/hora registo"
                  format="dd/MM/yyyy HH:mm"
                  component={DateTimePicker}
                  disabled
                  />
              </div>
              }
            </div>
        </fieldset>

      {allPermissionDominioHidrico &&
        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Identificação</span>
          </legend>

          <div className={styles.espacoVerticalFieldSet}>
            <div className={styles.fields}>
              <div>
                <Field
                  name="diploma"
                  label="Diploma Legal"
                  component={Input}
                  data={diplomas}
                  disabled={viewMode}
                />
              </div>
              <Field
                name="designacao"
                label="Designação"
                component={Input}
                disabled={viewMode}
              />
            </div>
          </div>
        </fieldset>
        }
        
      {allPermissionDominioHidrico &&

        <><fieldset>
            <legend>
              <span className={styles.fieldsetTitulo}>Localização</span>
            </legend>

            <div className={styles.espacoVerticalFieldSet}>
              <div className={styles.fields}>
                <div>
                  {getPatrimonioSelected() ?
                    <CheckMoreItem
                      list={getPatrimonioSelected()?.nuts1}
                      property={'nome'}
                      label="NUTS I" /> :
                    <Field
                      name="nuts1"
                      label="NUTS I"
                      component={ComboBox}
                      textField={'nome'}
                      disabled
                      onChange={filterNuts2}
                      filterable={true}
                      onFilterChange={(e: any) => { filterCombo_Multiselect(e, 'nuts1') } }
                      data={combos?.nuts1} />}
                </div>
                <div>
                  {getPatrimonioSelected() ?
                    <CheckMoreItem
                      list={getPatrimonioSelected()?.nuts2}
                      property={'nome'}
                      label="NUTS II" /> :
                    <Field
                      name="nuts2"
                      label="NUTS II"
                      component={ComboBox}
                      disabled
                      textField={'nome'}
                      onChange={filterNuts3}
                      filterable={true}
                      onFilterChange={(e: any) => { filterCombo_Multiselect(e, 'nuts2') } }
                      data={combos?.nuts2} />}
                </div>
                <div>
                  {getPatrimonioSelected() ?
                    <CheckMoreItem
                      list={getPatrimonioSelected()?.nuts3}
                      property={'nome'}
                      label="NUTS III" /> :
                    <Field
                      name="nuts3"
                      label="NUTS III"
                      disabled
                      component={ComboBox}
                      textField={'nome'}
                      filterable={true}
                      onFilterChange={(e: any) => { filterCombo_Multiselect(e, 'nuts3') } }
                      data={combos?.nuts3} />}
                </div>
              </div>

              <div className={styles.fields}>
                <div>
                  {getPatrimonioSelected() ?
                    <CheckMoreItem
                      list={getPatrimonioSelected()?.distritos}
                      property={'nome'}
                      label="Distrito" /> :
                    <Field
                      name="distrito"
                      label="Distrito"
                      disabled
                      component={ComboBox}
                      onChange={filterConcelho}
                      textField={'nome'}
                      filterable={true}
                      onFilterChange={(e: any) => { filterCombo_Multiselect(e, 'distritos') } }
                      data={combos?.distritos} />}
                </div>
                <div>
                  {getPatrimonioSelected() ?
                    <CheckMoreItem
                      list={getPatrimonioSelected()?.concelhos}
                      property={'nome'}
                      label="Concelho" /> :
                    <Field
                      name="concelho"
                      label="Concelho"
                      component={ComboBox}
                      onChange={filterFreguesia}
                      disabled
                      textField={'nome'}
                      filterable={true}
                      onFilterChange={(e: any) => { filterCombo_Multiselect(e, 'concelhos') } }
                      data={combos?.concelhos} />}
                </div>
                <div>
                  {getPatrimonioSelected() ?
                    <CheckMoreItem
                      list={getPatrimonioSelected()?.freguesias}
                      property={'nome'}
                      label="Freguesia" /> :
                    <Field
                      name="freguesia"
                      label="Freguesia"
                      component={ComboBox}
                      disabled
                      textField={'nome'}
                      filterable={true}
                      onFilterChange={(e: any) => { filterCombo_Multiselect(e, 'freguesias') } }
                      data={combos?.freguesias} />}
                </div>
              </div>
              <div className={styles.fields}>
                <div>
                {getPatrimonioSelected() ?
                  <CheckMoreItem
                      list={getPatrimonioSelected()?.arhs}
                      property={'nome'}
                      label="ARH" /> :
                  <Field
                    name="regiaoHidrografica"
                    label="ARH"
                    disabled
                    component={ComboBox}
                    //onChange={}
                    textField={'nome'}
                    filterable={true}
                    onFilterChange={(e: any) => { filterCombo_Multiselect(e, 'regiaoHidrografica') } }
                    data={combos?.regiaoHidrografica} />}
                </div>
                <div>
                {getPatrimonioSelected() ?
                  <CheckMoreItem
                      list={getPatrimonioSelected()?.capitanias}
                      property={'nome'}
                      label="Capitania"
                  /> :
                  <Field
                    name="capitania"
                    label="Capitania"
                    component={ComboBox}
                    textField={'nome'}
                    data={combos?.capitania}
                    filterable={true}
                    onFilterChange={(e: any) => { filterCombo_Multiselect(e, 'capitania') } }
                    disabled
                  />}
                </div>
                <div>
                  {getPatrimonioSelected() ?
                    <CheckMoreItem
                      list={getPatrimonioSelected()?.massasAgua}
                      property={'nome'}
                      label="Massa de água" /> :
                    <Field
                      name="massaagua"
                      label="Massa de água"
                      component={ComboBox}
                      textField={'nome'}
                      filterable={true}
                      onFilterChange={(e: any) => { filterCombo_Multiselect(e, 'massaagua') } }
                      data={combos?.massaagua}
                      disabled />}
                </div>
              </div>
              <div className={styles.fields}>
                <Field
                  name="lugar"
                  label="Lugar Toponímico"
                  component={Input}
                  disabled={viewMode}
                />
              </div>
            </div>
          </fieldset>
          <fieldset>
              <legend>
                <span className={styles.fieldsetTitulo}>Observações</span>
              </legend>
              <div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}>
                <Field
                  name="obs"
                  label="Observações"
                  component={TextArea}
                  disabled={viewMode} />
              </div>
            </fieldset></>
        }
      </div>
    
    </>
  )
}
