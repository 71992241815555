import { faFileEdit, faRemove } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PagerTargetEvent } from '@progress/kendo-react-data-tools'
import { Grid, GridCellProps, GridColumn, GridPageChangeEvent, GridToolbar } from '@progress/kendo-react-grid'
import { useEffect, useState } from 'react'
import { del, get, getAcaoSelected, getCandidaturaSelected, getDemarcacaoSelected, getDesconformidadeSelected, getDocumentoSelected, getEntidadeSelected, getIntervencaoSelected, getMobilizacaoSelected, getOcorrenciaSelected, getPatrimonioSelected, getPretensaoSelected, getProcessoSelected, getUsoSoloSelected, post, setDesconformidadeSelected, setDocumentoSelected, setPretensaoSelected } from '../../services/utils'
import { useDemarcacaoStore } from '../../stores/demarcacao'
import { useDhProcessoStore } from '../../stores/dhProcesso'
import { useEntidadeStore } from '../../stores/entidade'
import { useModeloStore } from '../../stores/modelo'
import { useOcorrenciaStore } from '../../stores/ocorrencias'
import { usePatrimonioStore } from '../../stores/patrimonio'

import stylesDominioHidrico from '../../styles/dominiohidrico/DominioHidrico.module.scss'
import stylesIntervencao from '../../styles/intervencao/Intervencao.module.scss'
import stylesApoioGestao from '../../styles/apoiogestao/ApoioGestao.module.scss'
import stylesEntidades from '../../styles/entidade/RegistarEntidade.module.scss'
import stylesModelos from '../../styles/modelos/RegistarModeloForm.module.scss'
import stylesUsoDoSolo from '../../styles/usodosolo/RegistarUsoDoSoloForm.module.scss'
import stylesOcorrencias from '../../styles/ocorrencia/OcorrenciasStylesv2.module.scss'
import stylesDocumentos from '../../styles/documentos/Documentos.module.scss'
import { default as styles } from '../../styles/documentos/GridDocumentos.module.scss'

import { APOIO_GESTAO_MODULE_NAME, DELETE_ASSOCIACAO, DOCUMENTOS_MODULE_NAME, DOMINIO_HIDRICO_MODULE_NAME, ENTIDADES_MODULE_NAME, INTERVENCAO_MODULE_NAME, MODELOS_MODULE_NAME, OCORRENCIAS_MODULE_NAME, PESQUISAR_ASSOCIACAO_ACAO, PESQUISAR_ASSOCIACAO_CANDIDATURA, PESQUISAR_ASSOCIACAO_DEMARCACAO, PESQUISAR_ASSOCIACAO_DESCONFORMIDADE, PESQUISAR_ASSOCIACAO_DOCUMENTO, PESQUISAR_ASSOCIACAO_ENTIDADE, PESQUISAR_ASSOCIACAO_INTERVENCAO, PESQUISAR_ASSOCIACAO_MOBILIZACAO, PESQUISAR_ASSOCIACAO_MODELO, PESQUISAR_ASSOCIACAO_OCORRENCIA, PESQUISAR_ASSOCIACAO_PATRIMONIO, PESQUISAR_ASSOCIACAO_PRETENSAO, PESQUISAR_ASSOCIACAO_PROCESSO, PESQUISAR_ASSOCIACAO_REGISTO_VOO_DRONE, PESQUISAR_ASSOCIACAO_USOS_SOLO, SAVE_NEW_ASSOCIATION, SAVE_NEW_ENTIDADE, UPDATE_ASSOCIATION, USO_DO_SOLO_MODULE_NAME, VOO_DRONE_MODULE_NAME } from '../../utils/constants'
import EditAssociacaoForm from './EditAssociacaoForm'
import { useIntervencaoStore } from '../../stores/intervencao'
import { useAcaoStore } from '../../stores/acao'
import { useCandidaturaStore } from '../../stores/candidatura'
import { useVooDroneStore } from '../../stores/vooDrone'
import { useUsoDoSoloStore } from '../../stores/usoDoSolo'
import { Form, FormElement } from '@progress/kendo-react-form'
import { Dialog } from '@progress/kendo-react-dialogs'
import parse from 'html-react-parser';
import clsx from 'clsx'

type PageState = {
  skip: number
  take: number
}
interface EditFormProps {
  cancelEdit: () => void;
  onSubmit: (event: any) => void;
  item: any;
}


type Props = {
  pageName?: string
  viewMode: boolean
}

interface EditCommandCellProps extends GridCellProps {
  enterEdit: (item: any) => void;
  deleteItem: (item: any) => void;
  pointOnMap: (item: any) => void;
}

const EditCommandCell = (props: EditCommandCellProps & { viewMode: boolean }) => {
  const { viewMode} = props;


  return (
    <td className="k-command-cell">
      <div style={
        {
          display: "flex",
          flexDirection: "row",
          margin: '0 0 0 8px'
        }
      }> <span ></span>
        {!viewMode &&
        <div
          role="button"
          onClick={() => props.deleteItem(props.dataItem)}
          
        >
          <FontAwesomeIcon icon={faRemove} />
        </div>
      }
      </div>
    </td>

  );
};

const initialDataState: PageState = { skip: 0, take: 10 }

export function AssociacaoListagem({ pageName, viewMode }: Props) {

  let selectedStyles;

  switch (pageName) {
    //  MODULO DOMINIO HIDRICO
    case 'processos':
      selectedStyles = stylesDominioHidrico;
      break;
    case 'patrimonio':
      selectedStyles = stylesDominioHidrico;
      break
    case 'demarcacao':
      selectedStyles = stylesDominioHidrico;
      break
    
    //  MODULO INTERVENÇÕES
    case 'intervencoes':
      selectedStyles = stylesIntervencao;
      break;
    case 'acao':
      selectedStyles = stylesIntervencao;
      break
    case 'candidatura':
      selectedStyles = stylesIntervencao;
      break
    case 'mobilizacao':
      selectedStyles = stylesIntervencao;
      break

    //  MODULO APOIO GESTÃO
    case 'pretensao':
      selectedStyles = stylesApoioGestao
      break
    case 'desconformidade':
      selectedStyles = stylesApoioGestao
      break

    case 'ocorrencias':
      selectedStyles = stylesOcorrencias;
      break;
    case 'usoDoSolo':
      selectedStyles = stylesUsoDoSolo;
      break;
    case 'entidades':
      selectedStyles = stylesEntidades;
      break;
    case 'modelos':
      selectedStyles = stylesModelos;
      break;
    case 'documentos':
      selectedStyles = stylesDocumentos;
      break;
    default:
      selectedStyles = styles;
  }

  const [instabiladeArribas, setInstabilidadeArribas] = useState(false)
  const [erosaoRecuo, setErosaoRecuo] = useState(false)
  const [protecaoDefesa, setProtecaoDefesa] = useState(false)
  const [page, setPage] = useState<PageState>(initialDataState)
  const [idToRemove, setIdToRemove] = useState(0);

  const getAssociacoesByProcesso = async (data: any) =>
    await get<any, any>(`${PESQUISAR_ASSOCIACAO_PROCESSO}/` + data);

  const getAssociacoesByEntidade = async (data: any) =>
    await get<any, any>(`${PESQUISAR_ASSOCIACAO_ENTIDADE}/` + data);

  const getAssociacoesByPretensoes = async (data: any) =>
    await get<any, any>(`${PESQUISAR_ASSOCIACAO_PRETENSAO}/` + data);

  const getAssociacoesByDesconformidades = async (data: any) =>
    await get<any, any>(`${PESQUISAR_ASSOCIACAO_DESCONFORMIDADE}/` + data);

  const getAssociacoesByOcorrencia = async (data: any) =>
    await get<any, any>(`${PESQUISAR_ASSOCIACAO_OCORRENCIA}/` + data);

  const getAssociacoesByDocumento = async (data: any) =>
    await get<any, any>(`${PESQUISAR_ASSOCIACAO_DOCUMENTO}/` + data);

  const getAssociacoesByUsosSolo = async (data: any) =>
    await get<any, any>(`${PESQUISAR_ASSOCIACAO_USOS_SOLO}/` + data);

  const selectedProcessoResult: any = useDhProcessoStore((state) => state.selectedDhProcessoResult);
  const selectProcessoResult = useDhProcessoStore((state) => state.selectDhProcessoResult)

  const getAssociacoesByPatrimonio = async (data: any) =>
    await get<any, any>(`${PESQUISAR_ASSOCIACAO_PATRIMONIO}/` + data);

  const getAssociacoesByIntervencao = async (data: any) =>
  await get<any, any>(`${PESQUISAR_ASSOCIACAO_INTERVENCAO}/` + data);

  const getAssociacoesByAcao = async (data: any) =>
  await get<any, any>(`${PESQUISAR_ASSOCIACAO_ACAO}/` + data);

  const getAssociacoesByCandidatura = async (data: any) =>
  await get<any, any>(`${PESQUISAR_ASSOCIACAO_CANDIDATURA}/` + data);

  const getAssociacoesByMobilizacao = async (data: any) =>
  await get<any, any>(`${PESQUISAR_ASSOCIACAO_MOBILIZACAO}/` + data);

  const getAssociacoesByDemarcacao = async (data: any) =>
  await get<any, any>(`${PESQUISAR_ASSOCIACAO_DEMARCACAO}/` + data);  
  
  const getAssociacoesByRegistoVooDrone = async (id: any) =>
  await get<any, any>(`${PESQUISAR_ASSOCIACAO_REGISTO_VOO_DRONE}/${id}`);

  const deleteAssociacao = async(id: any) => await del<any, any>(`${DELETE_ASSOCIACAO}/${id}`, {})

  const selectedEntidadeResult: any = useEntidadeStore(state => state.selectedEntidadeResult);
  const selectOcorrenciaResult = useOcorrenciaStore(
    (state) => state.selectOcorrenciaResult
  );
  const selectEntidadeResult = useEntidadeStore(
    (state) => state.selectEntidadeResult
  );

  const selectPatrimonioResult = usePatrimonioStore(
    (state) => state.selectPatrimonioResult
  );

  const selectDemarcacaoResult = useDemarcacaoStore(
    (state) => state.selectDemarcacaoResult
  );

    const selectModeloResult = useModeloStore(
    (state) => state.selectModeloResult
  );

   const selectIntervencaoResult = useIntervencaoStore(
    (state) => state.selectIntervencaoResult
  );

   const selectAcaoResult = useAcaoStore(
    (state) => state.selectAcaoResult
  );
  const selectCandidaturaResult = useCandidaturaStore(
    (state) => state.selectCandidaturaResult
  );
  const selectedOcorrenciaResult: any = useOcorrenciaStore(state => state.selectedOcorrenciaResult);

  const selectedModeloResult: any = useModeloStore(
    (state) => state.selectedModeloResult
  )
  const selectedModeloResultId = useModeloStore(
    (state) => state.selectedModeloResultId
  )

  const selectedVooDronePathResult: any = useVooDroneStore(
    (state) => state.selectedVooDronePathResult
  )

  const selectedUsoDoSoloResult: any = useUsoDoSoloStore(
    (state) => state.selectedUsoDoSoloResult
  )
  
  const [pageSizeValue, setPageSizeValue] = useState<
    number | string | undefined
  >()
  const [entidades, setEntidades] = useState([]);
  const createAssociation = async (data: any) => await post<any, any>(`${SAVE_NEW_ASSOCIATION}`, data);
  const updateAssociation = async (data: any) => await post<any, any>(`${UPDATE_ASSOCIATION}`, data);
  const createEntidade = async (data: any) =>
    await post<any, any>(`${SAVE_NEW_ENTIDADE}`, data)
  const [openModalDescartar, setOpenModalDescartar] = useState<any>()

  useEffect(() => {
    initForm();

  }, []);

  const initForm = () => {
    const modulo = sessionStorage.getItem("Modulo");

    switch (modulo) {
      case DOMINIO_HIDRICO_MODULE_NAME: {

        const patrimonioSelected = getPatrimonioSelected();
        const demarcacaoSelected = getDemarcacaoSelected();
        const processoSelected = getProcessoSelected();

        if (patrimonioSelected && patrimonioSelected?.id) {
          postMessage({ type: 'showLoader' }, '*');
          getAssociacoesByPatrimonio(patrimonioSelected?.id).then(result => {
            setData(result);
            postMessage({ type: 'hideLoader' }, '*');
          })
        } else if (demarcacaoSelected && demarcacaoSelected?.id) {
          postMessage({ type: 'showLoader' }, '*');
          getAssociacoesByDemarcacao(demarcacaoSelected?.id).then(result => {
            setData(result);
            postMessage({ type: 'hideLoader' }, '*');
          })
        } else if (processoSelected && processoSelected?.id) {
          postMessage({ type: 'showLoader' }, '*');
          getAssociacoesByProcesso(processoSelected?.id).then(result => {
            setData(result);
            postMessage({ type: 'hideLoader' }, '*');
          })
        } else {
          setData([]);
        }

        break;

      }
      case INTERVENCAO_MODULE_NAME: {

       
        const intervencaoSelected = getIntervencaoSelected();
        const acaoSelected = getAcaoSelected();
        const candidaturaSelected = getCandidaturaSelected();
        const mobilizacaoSelected = getMobilizacaoSelected();

        if (intervencaoSelected && intervencaoSelected?.id) {
          postMessage({ type: 'showLoader' }, '*');
          getAssociacoesByIntervencao(intervencaoSelected?.id).then(result => {
            setData(result);
            postMessage({ type: 'hideLoader' }, '*');
          })
        } else if(acaoSelected && acaoSelected.id) {
          postMessage({ type: 'showLoader' }, '*');
          getAssociacoesByAcao(acaoSelected?.id).then(result => {
            setData(result);
            postMessage({ type: 'hideLoader' }, '*');
          })
        }else if(candidaturaSelected && candidaturaSelected.id) {
          postMessage({ type: 'showLoader' }, '*');
          getAssociacoesByCandidatura(candidaturaSelected?.id).then(result => {
            setData(result);
            postMessage({ type: 'hideLoader' }, '*');
          })        
        }else if(mobilizacaoSelected && mobilizacaoSelected.id) {
          postMessage({ type: 'showLoader' }, '*');
          getAssociacoesByMobilizacao(mobilizacaoSelected?.id).then(result => {
            setData(result);
            postMessage({ type: 'hideLoader' }, '*');
          })
        
        }
        break;
      }
      case ENTIDADES_MODULE_NAME: {

        const entidadeSelected = getEntidadeSelected();
        if (entidadeSelected) {
          postMessage({ type: 'showLoader' }, '*')
          getAssociacoesByEntidade(entidadeSelected?.id).then(result => {
            setData(result);
            postMessage({ type: 'hideLoader' }, '*')
          });
        } else {
          setData([]);
        }

        break;

      }
      case OCORRENCIAS_MODULE_NAME: {

        if (selectedOcorrenciaResult) {
          postMessage({ type: 'showLoader' }, '*')
          getAssociacoesByOcorrencia(selectedOcorrenciaResult?.id ?? getOcorrenciaSelected().id).then(result => {
            setData(result);
            postMessage({ type: 'hideLoader' }, '*')
          })
        }

        break;

      }
      case USO_DO_SOLO_MODULE_NAME: {
          
          if (getUsoSoloSelected()?.id) {
            postMessage({ type: 'showLoader' }, '*');
          getAssociacoesByUsosSolo(selectedUsoDoSoloResult?.id).then(result => {
            setData(result);
            postMessage({ type: 'hideLoader' }, '*')
          })
      }

        break;

      }
      case DOCUMENTOS_MODULE_NAME: {
        if (getDocumentoSelected()?.id) {
          postMessage({ type: 'showLoader' }, '*');
          const documentoSelected = getDocumentoSelected();
          getAssociacoesByDocumento(documentoSelected?.id).then(result => {
            setData(result);
            postMessage({ type: 'hideLoader' }, '*')
          })
        }
        break;
      }
      case APOIO_GESTAO_MODULE_NAME: {

        const pretensaoSelected = getPretensaoSelected();
        const desconformidadeSelected = getDesconformidadeSelected();
        if (pretensaoSelected && pretensaoSelected.id) {
          postMessage({ type: 'showLoader' }, '*');
          getAssociacoesByPretensoes(pretensaoSelected?.id).then(result => {
            setData(result);
            postMessage({ type: 'hideLoader' }, '*');
          })
        } else if (desconformidadeSelected && desconformidadeSelected.id) {
          postMessage({ type: 'showLoader' }, '*');
          getAssociacoesByDesconformidades(desconformidadeSelected?.id).then(result => {
            setData(result);
            postMessage({ type: 'hideLoader' }, '*');
          })
        }

        break;
      }

      case VOO_DRONE_MODULE_NAME:{
        if(selectedVooDronePathResult && selectedVooDronePathResult?.id_registo_voo_drone){
          const id_registo_voo_drone = selectedVooDronePathResult?.id_registo_voo_drone;
          postMessage({ type: 'showLoader' }, '*');
          getAssociacoesByRegistoVooDrone(id_registo_voo_drone).then(result => {
            setData(result);
            postMessage({ type: 'hideLoader' }, '*');
          })

        }
        break;
      }
    }
  }

  const handlePageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent
    const take = event.page.take

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value)
    }

    setPage({
      ...event.page,
      take,
    })
  }
  const [data, setData] = useState<any>();

  const [openForm, setOpenForm] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<any>({ id: undefined });

  const enterEdit = (item: any) => {
    setOpenForm(true);
    setEditItem(item);
  };
  const pointOnMap = (item: any) => {
    console.log(item);
    return;
  };
  const deleteItem = (item: any) => {
    setIdToRemove(item.id);
    setOpenModalDescartar(true);
    initForm();
  };

  const handleSubmit = (event: any) => {

    const modulo = sessionStorage.getItem("Modulo");

    switch (modulo) {

      case DOMINIO_HIDRICO_MODULE_NAME: {

        const patrimonioSelected = getPatrimonioSelected();
        const demarcacaoSelected = getDemarcacaoSelected();
        const processoSelected = getProcessoSelected();

        if (patrimonioSelected && patrimonioSelected?.id) {
          if (!event.id) {
            if (!patrimonioSelected.id) {
              // show message to save
            } else {
              createNewAssociationPatrimonio(event, patrimonioSelected);
            }
          } else {
            saveAssociationPatrimonio(event);
          }
        }

        if (demarcacaoSelected && demarcacaoSelected?.id) {
          if (!event.id) {
            if (!demarcacaoSelected.id) {
              // show message to save
            } else {
              createNewAssociationDemarcacao(event, demarcacaoSelected);
            }
          } else {
            saveAssociationDemarcacao(event);
          }
        }

        if (processoSelected && processoSelected?.id) {
          if (!event.id) {
            if (!processoSelected.id) {
              // show message to save
            } else {
              createNewAssociationProcesso(event, processoSelected);
            }
          } else {
            saveAssociationProcesso(event);
          }
        }

        break;
      }

      case INTERVENCAO_MODULE_NAME: {
        
        const intervencaoSelected = getIntervencaoSelected();
        const acaoSelected = getAcaoSelected();
        const candidaturaSelected = getCandidaturaSelected();
        const mobilizacaoSelected = getMobilizacaoSelected();

        if (intervencaoSelected && intervencaoSelected?.id) {
          if (!event.id) {
            if (!intervencaoSelected.id) {
              // show message to save
            } else {
              createNewAssociationIntervencao(event, intervencaoSelected);
            }
          } else {
            saveAssociationIntervencao(event);
          }
        }
         if (acaoSelected && acaoSelected?.id) {
          if (!event.id) {
            if (!acaoSelected.id) {
              // show message to save
            } else {
              createNewAssociationAcao(event, acaoSelected);
            }
          } else {
            saveAssociationAcao(event);
          }
        }if (candidaturaSelected && candidaturaSelected?.id) {
          if (!event.id) {
            if (!candidaturaSelected.id) {
              // show message to save
            } else {
              createNewAssociationCandidatura(event, candidaturaSelected);
            }
          } else {
            saveAssociationCandidatura(event);
          }
        }if (mobilizacaoSelected && mobilizacaoSelected?.id) {
          if (!event.id) {
            if (!mobilizacaoSelected.id) {
              // show message to save
            } else {
              createNewAssociationMobilizacao(event, mobilizacaoSelected);
            }
          } else {
            saveAssociationMobilizacao(event);
          }
        }
        
        break;
      }

      case ENTIDADES_MODULE_NAME: {

        if (!event.id) {
          if (!selectedEntidadeResult.id) {
            createEntidade({ tipo_entidade_id: 1, nome: '' }).then(result => {
              createNewAssociationEntidade(event, result);
              postMessage({ type: 'guardarEntidade', value: { id: result.id } }, '*')
              selectEntidadeResult(result);
            });
          } else {
            createNewAssociationEntidade(event, selectedEntidadeResult);
          }
        } else {
          saveAssociationEntidade(event);
        }
        break;
      }

      case OCORRENCIAS_MODULE_NAME: {

        if (!event.id) {
          if (!selectedOcorrenciaResult.id && !getOcorrenciaSelected()?.id) {
            // show message to save
          } else {
            createNewAssociationOcorrencia(event, selectedOcorrenciaResult ?? getOcorrenciaSelected());
          }
        } else {
          const record = event ?? (selectedOcorrenciaResult ?? getOcorrenciaSelected());
          saveAssociationOcorrencia(record);
        }

        break;
      }

      case USO_DO_SOLO_MODULE_NAME: {

        if (!event.id) {
          if (!getUsoSoloSelected()?.id) {
            // show message to save
          } else {
            createNewAssociationUsTitulos(event, getUsoSoloSelected());
          }
        } else {
          saveAssociationUsTitulo(event);
        }

        break;
      }


      case DOCUMENTOS_MODULE_NAME: {

        if (!event.id) {
          const documentoSelected = getDocumentoSelected();
          if (!documentoSelected?.id) {
            postMessage({type: "errorMsg", value: {message: "É necessário gravar primeiro."}});
          } else {
            createNewAssociationDocumento(event, documentoSelected);
          }
        } else {
          saveAssociationDocumento(event);
        }

        break;
      }

      case APOIO_GESTAO_MODULE_NAME: {

        const pretensaoSelected = getPretensaoSelected();
        const desconformidadeSelected = getDesconformidadeSelected();
        if (pretensaoSelected && pretensaoSelected.id) {
          if (!event.id) {
            if (!pretensaoSelected.id) {
              // show message to save
            } else {
              createNewAssociationAgPretensao(event, pretensaoSelected);
            }
          } else {
            saveAssociationPretensoes(event);
          }
        }
        if (desconformidadeSelected && desconformidadeSelected.id) {
          if (!event.id) {
            if (!desconformidadeSelected.id) {
              // show message to save
            } else {
              createNewAssociationAgDesconformidade(event, desconformidadeSelected);
            }
          } else {
            saveAssociationDesconformidades(event);
          }
        }

        break;
      }
    }

  };

  const createNewAssociationProcesso = (event: any, result?: any) => {
    if (result) {
      selectProcessoResult(result);
    }
    const dataToSend = {
      oc_ocorrencia_id: event?.ocOcorrencia ? event?.ocOcorrencia.id : undefined,
      ag_pretensao_id: event?.agPretensao ? event?.agPretensao.id : undefined,
      ag_desconformidade_id: event?.agDesconformidade ? event?.agDesconformidade.id : undefined,
      do_documento_id: event?.doDocumento ? event?.doDocumento.id : undefined,
      us_titulo_id: event?.usTitulo?.id ?? undefined,
      dh_patrimonio_id: event?.dhPatrimonio ? event?.dhPatrimonio.id : undefined,
      dh_processo_id: result?.id,
      dh_demarcacao_id: event?.dhDemarcacao ? event?.dhDemarcacao.id : undefined,
      int_intervencao_id: event?.intIntervencao ? event?.intIntervencao.id : undefined,
      int_acao_id: event?.intAcao ? event?.intAcao.id : undefined,
      int_candidatura_id: event?.intCandidatura ? event?.intCandidatura.id : undefined,
      int_mobilizacao_id: event?.intMobilizacao ? event?.intMobilizacao.id : undefined,
      di_entidade_id: event?.diEntidade ? event?.diEntidade.id : undefined,
    }
    createAssociation(dataToSend).then(result => {
      postMessage({ type: 'showLoader' }, '*');
      getAssociacoesByProcesso(result?.dh_processo_id).then(result => {
        setData(result);
        postMessage({ type: 'hideLoader' }, '*')
      });
    });
    setOpenForm(false);
  }

  const createNewAssociationEntidade = (event: any, result?: any) => {
    if (result) {
      selectEntidadeResult(result);
    }
    const dataToSend = {
      oc_ocorrencia_id: event?.ocOcorrencia ? event?.ocOcorrencia.id : undefined,
      ag_pretensao_id: event?.agPretensao ? event?.agPretensao.id : undefined,
      ag_desconformidade_id: event?.agDesconformidade ? event?.agDesconformidade.id : undefined,
      do_documento_id: event?.doDocumento ? event?.doDocumento.id : undefined,
      dh_patrimonio_id: event?.dhPatrimonio ? event?.dhPatrimonio.id : undefined,
      dh_processo_id: event?.dhProcessos ? event?.dhProcessos.id : undefined,
      dh_demarcacao_id: event?.dhDemarcacao ? event?.dhDemarcacao.id : undefined,
      int_intervencao_id: event?.intIntervencao ? event?.intIntervencao.id : undefined,
      int_acao_id: event?.intAcao ? event?.intAcao.id : undefined,
      int_candidatura_id: event?.intCandidatura ? event?.intCandidatura.id : undefined,
      int_mobilizacao_id: event?.intMobilizacao ? event?.intMobilizacao.id : undefined,
      di_entidade_id: result?.id,
      us_titulo_id: event?.usTitulo?.id ?? undefined,

    }
    createAssociation(dataToSend).then(result => {
      postMessage({ type: 'showLoader' }, '*');
      getAssociacoesByEntidade(result?.di_entidade_id).then(result => {
        setData(result);
        postMessage({ type: 'hideLoader' }, '*')
      });
    });
    setOpenForm(false);
  }

  const createNewAssociationUsTitulos = (event: any, result?: any) => {
    if (result) {
      selectModeloResult(result);
    }
    const dataToSend = {
       oc_ocorrencia_id: event?.ocOcorrencia ? event?.ocOcorrencia.id : undefined,
      ag_pretensao_id: event?.agPretensao ? event?.agPretensao.id : undefined,
      ag_desconformidade_id: event?.agDesconformidade ? event?.agDesconformidade.id : undefined,
      do_documento_id: event?.doDocumento ? event?.doDocumento.id : undefined,
      us_titulo_id: result?.id,
      dh_patrimonio_id: event?.dhPatrimonio ? event?.dhPatrimonio.id : undefined,
      dh_processo_id: event?.dhProcessos ? event?.dhProcessos.id : undefined,
      dh_demarcacao_id: event?.dhDemarcacao ? event?.dhDemarcacao.id : undefined,
      int_intervencao_id: event?.intIntervencao ? event?.intIntervencao.id : undefined,
      int_acao_id: event?.intAcao ? event?.intAcao.id : undefined,
      int_candidatura_id: event?.intCandidatura ? event?.intCandidatura.id : undefined,
      int_mobilizacao_id: event?.intMobilizacao ? event?.intMobilizacao.id : undefined,
      di_entidade_id: event?.diEntidade ? event?.diEntidade.id : undefined,
    }

    createAssociation(dataToSend).then(result => {
      postMessage({ type: 'showLoader' }, '*');
      getAssociacoesByUsosSolo(result?.us_titulo_id).then(result => {
        setData(result);
        postMessage({ type: 'hideLoader' }, '*')
      });
    });
    setOpenForm(false);
  }

  const createNewAssociationDocumento = (event: any, result?: any) => {
    if (result) {
      setDocumentoSelected(result);
    }
    const dataToSend = {
     oc_ocorrencia_id: event?.ocOcorrencia ? event?.ocOcorrencia.id : undefined,
      ag_pretensao_id: event?.agPretensao ? event?.agPretensao.id : undefined,
      ag_desconformidade_id: event?.agDesconformidade ? event?.agDesconformidade.id : undefined,
      do_documento_id: result?.id,
      mo_modelo_id: event?.moModelo ? event?.moModelo.id : undefined,
      dh_patrimonio_id: event?.dhPatrimonio ? event?.dhPatrimonio.id : undefined,
      dh_processo_id: event?.dhProcessos ? event?.dhProcessos.id  : undefined,
      dh_demarcacao_id: event?.dhDemarcacao ? event?.dhDemarcacao.id : undefined,
      int_intervencao_id: event?.intIntervencao ? event?.intIntervencao.id : undefined,
      int_acao_id: event?.intAcao ? event?.intAcao.id : undefined,
      int_candidatura_id: event?.intCandidatura ? event?.intCandidatura.id : undefined,
      int_mobilizacao_id: event?.intMobilizacao ? event?.intMobilizacao.id : undefined,
      di_entidade_id: event?.diEntidade ? event?.diEntidade.id : undefined,
      us_titulo_id: event?.usTitulo?.id ?? undefined,
    }

    createAssociation(dataToSend).then(result => {
      postMessage({ type: 'showLoader' }, '*');
      getAssociacoesByDocumento(result?.do_documento_id).then(result => {
        setData(result);
        postMessage({ type: 'hideLoader' }, '*')
      });
    });
    setOpenForm(false);
  }

  const createNewAssociationAgPretensao = (event: any, result?: any) => {
    if (result) {
      setPretensaoSelected(result);
    }
    const dataToSend = {
      oc_ocorrencia_id: event?.ocOcorrencia ? event?.ocOcorrencia.id : undefined,
      ag_pretensao_id: result?.id,
      ag_desconformidade_id: event?.agDesconformidade?.id ?? undefined,
      do_documento_id: event?.doDocumento ? event?.doDocumento.id : undefined,
      mo_modelo_id: event?.moModelo ? event?.moModelo.id : undefined,
      dh_patrimonio_id: event?.dhPatrimonio ? event?.dhPatrimonio.id : undefined,
      dh_processo_id: event?.dhProcessos ? event?.dhProcessos.id  : undefined,
      dh_demarcacao_id: event?.dhDemarcacao ? event?.dhDemarcacao.id : undefined,
      int_intervencao_id: event?.intIntervencao ? event?.intIntervencao.id : undefined,
      int_acao_id: event?.intAcao ? event?.intAcao.id : undefined,
      int_candidatura_id: event?.intCandidatura ? event?.intCandidatura.id : undefined,
      int_mobilizacao_id: event?.intMobilizacao ? event?.intMobilizacao.id : undefined,
      di_entidade_id: event?.diEntidade ? event?.diEntidade.id : undefined,
      us_titulo_id: event?.usTitulo?.id ?? undefined,
    }

    createAssociation(dataToSend).then(result => {
      postMessage({ type: 'showLoader' }, '*');
      getAssociacoesByPretensoes(result?.ag_pretensao_id).then(result => {
        setData(result);
        postMessage({ type: 'hideLoader' }, '*')
      });
    });
    setOpenForm(false);
  }

  const createNewAssociationAgDesconformidade = (event: any, result?: any) => {
    if (result) {
      setDesconformidadeSelected(result);
    }
    const dataToSend = {
       oc_ocorrencia_id: event?.ocOcorrencia ? event?.ocOcorrencia.id : undefined,
      ag_pretensao_id: event?.agPretensao ? event?.agPretensao.id : undefined,
      ag_desconformidade_id: result?.id,
      do_documento_id: event?.doDocumento ? event?.doDocumento.id : undefined,
      mo_modelo_id: event?.moModelo ? event?.moModelo.id : undefined,
      dh_patrimonio_id: event?.dhPatrimonio ? event?.dhPatrimonio.id : undefined,
      dh_processo_id: event?.dhProcessos ? event?.dhProcessos.id  : undefined,
      dh_demarcacao_id: event?.dhDemarcacao ? event?.dhDemarcacao.id : undefined,
      int_intervencao_id: event?.intIntervencao ? event?.intIntervencao.id : undefined,
      int_acao_id: event?.intAcao ? event?.intAcao.id : undefined,
      int_candidatura_id: event?.intCandidatura ? event?.intCandidatura.id : undefined,
      int_mobilizacao_id: event?.intMobilizacao ? event?.intMobilizacao.id : undefined,
      di_entidade_id: event?.diEntidade ? event?.diEntidade.id : undefined,
      us_titulo_id: event?.usTitulo?.id ?? undefined,
    }

    createAssociation(dataToSend).then(result => {
      postMessage({ type: 'showLoader' }, '*');
      getAssociacoesByDesconformidades(result?.ag_desconformidade_id).then(result => {
        setData(result);
        postMessage({ type: 'hideLoader' }, '*')
      });
    });
    setOpenForm(false);
  }
  
  const createNewAssociationOcorrencia = (event: any, result?: any) => {
    if (result) {
      selectOcorrenciaResult(result);
    }
    const dataToSend = {
      oc_ocorrencia_id: result?.id,
      ag_pretensao_id: event?.agPretensao ? event?.agPretensao.id : undefined,
      ag_desconformidade_id: event?.agDesconformidade?.id ?? undefined,
      do_documento_id: event?.doDocumento ? event?.doDocumento.id : undefined,
      mo_modelo_id: event?.moModelo ? event?.moModelo.id : undefined,
      dh_patrimonio_id: event?.dhPatrimonio ? event?.dhPatrimonio.id : undefined,
      dh_processo_id: event?.dhProcessos ? event?.dhProcessos.id  : undefined,
      dh_demarcacao_id: event?.dhDemarcacao ? event?.dhDemarcacao.id : undefined,
      int_intervencao_id: event?.intIntervencao ? event?.intIntervencao.id : undefined,
      int_acao_id: event?.intAcao ? event?.intAcao.id : undefined,
      int_candidatura_id: event?.intCandidatura ? event?.intCandidatura.id : undefined,
      int_mobilizacao_id: event?.intMobilizacao ? event?.intMobilizacao.id : undefined,
      di_entidade_id: event?.diEntidade ? event?.diEntidade.id : undefined,
      us_titulo_id: event?.usTitulo?.id ?? undefined,
    }

    createAssociation(dataToSend).then(result => {
      postMessage({ type: 'showLoader' }, '*');
      getAssociacoesByOcorrencia(result?.oc_ocorrencia_id).then(result => {
        setData(result);
        postMessage({ type: 'hideLoader' }, '*')
      });
    });
    setOpenForm(false);
  }

  const createNewAssociationPatrimonio = (event: any, result?: any) => {
    if (result) {
      selectPatrimonioResult(result);
    }
    const dataToSend = {
      oc_ocorrencia_id: event?.ocOcorrencia ? event?.ocOcorrencia.id : undefined,
      ag_pretensao_id: event?.agPretensao ? event?.agPretensao.id : undefined,
      ag_desconformidade_id: event?.agDesconformidade?.id ?? undefined,
      do_documento_id: event?.doDocumento ? event?.doDocumento.id : undefined,
      mo_modelo_id: event?.moModelo ? event?.moModelo.id : undefined,
      dh_patrimonio_id: result?.id,
      dh_processo_id: event?.dhProcessos ? event?.dhProcessos.id  : undefined,
      dh_demarcacao_id: event?.dDemarcacao ? event?.dhDemarcacao.id : undefined,
      int_intervencao_id: event?.intIntervencao ? event?.intIntervencao.id : undefined,
      int_acao_id: event?.intAcao ? event?.intAcao.id : undefined,
      int_candidatura_id: event?.intCandidatura ? event?.intCandidatura.id : undefined,
      int_mobilizacao_id: event?.intMobilizacao ? event?.intMobilizacao.id : undefined,
      di_entidade_id: event?.diEntidade ? event?.diEntidade.id : undefined,
      us_titulo_id: event?.usTitulo?.id ?? undefined,
    }

    createAssociation(dataToSend).then(result => {
      postMessage({ type: 'showLoader' }, '*');
      getAssociacoesByPatrimonio(result?.dh_patrimonio_id).then(result => {
        setData(result);
        postMessage({ type: 'hideLoader' }, '*')
      });
    });
    setOpenForm(false);
  }

  const createNewAssociationDemarcacao = (event: any, result?: any) => {
    if (result) {
      selectDemarcacaoResult(result);
    }
    const dataToSend = {
      oc_ocorrencia_id: event?.ocOcorrencia ? event?.ocOcorrencia.id : undefined,
      ag_pretensao_id: event?.agPretensao ? event?.agPretensao.id : undefined,
      ag_desconformidade_id: event?.agDesconformidade?.id ?? undefined,
      do_documento_id: event?.doDocumento ? event?.doDocumento.id : undefined,
      mo_modelo_id: event?.moModelo ? event?.moModelo.id : undefined,
      dh_patrimonio_id: event?.dhPatrimonio ? event?.dhPatrimonio.id : undefined,
      dh_processo_id: event?.dhProcessos ? event?.dhProcessos.id  : undefined,
      dh_demarcacao_id: result?.id,
      int_intervencao_id: event?.intIntervencao ? event?.intIntervencao.id : undefined,
      int_acao_id: event?.intAcao ? event?.intAcao.id : undefined,
      int_candidatura_id: event?.intCandidatura ? event?.intCandidatura.id : undefined,
      int_mobilizacao_id: event?.intMobilizacao ? event?.intMobilizacao.id : undefined,
      di_entidade_id: event?.diEntidade ? event?.diEntidade.id : undefined,
      us_titulo_id: event?.usTitulo?.id ?? undefined,
    }

    createAssociation(dataToSend).then(result => {
      postMessage({ type: 'showLoader' }, '*');
      getAssociacoesByDemarcacao(result?.dh_demarcacao_id).then(result => {
        setData(result);
        postMessage({ type: 'hideLoader' }, '*')
      });
    });
    setOpenForm(false);
  }

  const createNewAssociationIntervencao = (event: any, result?: any) => {
    if (result) {
      selectIntervencaoResult(result);
    }
    const dataToSend = {
      oc_ocorrencia_id: event?.ocOcorrencia ? event?.ocOcorrencia.id : undefined,
      ag_pretensao_id: event?.agPretensao ? event?.agPretensao.id : undefined,
      ag_desconformidade_id: event?.agDesconformidade?.id ?? undefined,
      do_documento_id: event?.doDocumento ? event?.doDocumento.id : undefined,
      mo_modelo_id: event?.moModelo ? event?.moModelo.id : undefined,
      dh_patrimonio_id: event?.dhPatrimonio ? event?.dhPatrimonio.id : undefined,
      dh_processo_id: event?.dhProcessos ? event?.dhProcessos.id  : undefined,
      dh_demarcacao_id: event?.dhDemarcacao ? event?.dhDemarcacao.id : undefined,
      int_intervencao_id: result?.id,
      int_acao_id: event?.intAcao ? event?.intAcao.id : undefined,
      int_candidatura_id: event?.intCandidatura ? event?.intCandidatura.id : undefined,
      int_mobilizacao_id: event?.intMobilizacao ? event?.intMobilizacao.id : undefined,
      us_titulo_id: event?.usTitulo?.id ?? undefined,
      di_entidade_id: event?.diEntidade ? event?.diEntidade.id : undefined,
    }

    createAssociation(dataToSend).then(result => {
      postMessage({ type: 'showLoader' }, '*');
      getAssociacoesByIntervencao(result?.int_intervencao_id).then(result => {
        setData(result);
        postMessage({ type: 'hideLoader' }, '*')
      });
    });
    setOpenForm(false);
  }

  const createNewAssociationAcao = (event: any, result?: any) => {
    if (result) {
      selectAcaoResult(result);
    }
    const dataToSend = {
      oc_ocorrencia_id: event?.ocOcorrencia ? event?.ocOcorrencia.id : undefined,
      ag_pretensao_id: event?.agPretensao ? event?.agPretensao.id : undefined,
      ag_desconformidade_id: event?.agDesconformidade?.id ?? undefined,
      do_documento_id: event?.doDocumento ? event?.doDocumento.id : undefined,
      mo_modelo_id: event?.moModelo ? event?.moModelo.id : undefined,
      dh_patrimonio_id: event?.dhPatrimonio ? event?.dhPatrimonio.id : undefined,
      dh_processo_id: event?.dhProcessos ? event?.dhProcessos.id  : undefined,
      dh_demarcacao_id: event?.dhDemarcacao ? event?.dhDemarcacao.id : undefined,
      int_intervencao_id: event?.intIntervencao ? event?.intIntervencao.id : undefined,
      int_acao_id: result?.id,
      us_titulo_id: event?.usTitulo?.id ?? undefined,
      int_candidatura_id: event?.intCandidatura ? event?.intCandidatura.id : undefined,
      int_mobilizacao_id: event?.intMobilizacao ? event?.intMobilizacao.id : undefined,
      di_entidade_id: event?.diEntidade ? event?.diEntidade.id : undefined,
    }

    createAssociation(dataToSend).then(result => {
      postMessage({ type: 'showLoader' }, '*');
      getAssociacoesByAcao(result?.int_acao_id).then(result => {
        setData(result);
        postMessage({ type: 'hideLoader' }, '*')
      });
    });
    setOpenForm(false);
  }
  const createNewAssociationCandidatura = (event: any, result?: any) => {
    if (result) {
      selectCandidaturaResult(result);
    }
    const dataToSend = {
      oc_ocorrencia_id: event?.ocOcorrencia ? event?.ocOcorrencia.id : undefined,
      ag_pretensao_id: event?.agPretensao ? event?.agPretensao.id : undefined,
      ag_desconformidade_id: event?.agDesconformidade?.id ?? undefined,
      do_documento_id: event?.doDocumento ? event?.doDocumento.id : undefined,
      mo_modelo_id: event?.moModelo ? event?.moModelo.id : undefined,
      dh_patrimonio_id: event?.dhPatrimonio ? event?.dhPatrimonio.id : undefined,
      dh_processo_id: event?.dhProcessos ? event?.dhProcessos.id  : undefined,
      dh_demarcacao_id: event?.dhDemarcacao ? event?.dhDemarcacao.id : undefined,
      int_intervencao_id: event?.intIntervencao ? event?.intIntervencao.id : undefined,
      int_acao_id: event?.intAcao ? event?.intAcao.id : undefined,
      int_candidatura_id: result?.id,
      us_titulo_id: event?.usTitulo?.id ?? undefined,
      int_mobilizacao_id: event?.intMobilizacao ? event?.intMobilizacao.id : undefined,
      di_entidade_id: event?.diEntidade ? event?.diEntidade.id : undefined,
    }

    createAssociation(dataToSend).then(result => {
      postMessage({ type: 'showLoader' }, '*');
      getAssociacoesByCandidatura(result?.int_candidatura_id).then(result => {
        setData(result);
        postMessage({ type: 'hideLoader' }, '*')
      });
    });
    setOpenForm(false);
  }
  const createNewAssociationMobilizacao = (event: any, result?: any) => {
    if (result) {
      selectAcaoResult(result);
    }
    const dataToSend = {
      oc_ocorrencia_id: event?.ocOcorrencia ? event?.ocOcorrencia.id : undefined,
      ag_pretensao_id: event?.agPretensao ? event?.agPretensao.id : undefined,
      ag_desconformidade_id: event?.agDesconformidade?.id ?? undefined,
      do_documento_id: event?.doDocumento ? event?.doDocumento.id : undefined,
      mo_modelo_id: event?.moModelo ? event?.moModelo.id : undefined,
      dh_patrimonio_id: event?.dhPatrimonio ? event?.dhPatrimonio.id : undefined,
      dh_processo_id: event?.dhProcessos ? event?.dhProcessos.id  : undefined,
      dh_demarcacao_id: event?.dhDemarcacao ? event?.dhDemarcacao.id : undefined,
      int_intervencao_id: event?.intIntervencao ? event?.intIntervencao.id : undefined,
      int_acao_id: event?.intAcao ? event?.intAcao.id : undefined,
      int_candidatura_id: event?.intCandidatura ? event?.intCandidatura.id : undefined,
      int_mobilizacao_id: result?.id,
      us_titulo_id: event?.usTitulo?.id ?? undefined,
      di_entidade_id: event?.diEntidade ? event?.diEntidade.id : undefined,
    }

    createAssociation(dataToSend).then(result => {
      postMessage({ type: 'showLoader' }, '*');
      getAssociacoesByMobilizacao(result?.int_mobilizacao_id).then(result => {
        setData(result);
        postMessage({ type: 'hideLoader' }, '*')
      });
    });
    setOpenForm(false);
  }


  const saveAssociationProcesso = (event: any) => {
    const dataToSend = {
      id: event?.id,
      oc_ocorrencia_id: event?.ocOcorrencia ? event?.ocOcorrencia.id : undefined,
      ag_pretensao_id: event?.agPretensao ? event?.agPretensao.id : undefined,
      ag_desconformidade_id: event?.agDesconformidade?.id ?? undefined,
      do_documento_id: event?.doDocumento ? event?.doDocumento.id : undefined,
      mo_modelo_id: event?.moModelo ? event?.moModelo.id : undefined,
      dh_patrimonio_id: event?.dhPatrimonio ? event?.dhPatrimonio.id : undefined,
      dh_processo_id: event?.dhProcessos ? event?.dhProcessos.id  : undefined,
      dh_demarcacao_id: event?.dhDemarcacao ? event?.dhDemarcacao.id : undefined,
      int_intervencao_id: event?.intIntervencao ? event?.intIntervencao.id : undefined,
      int_acao_id: event?.intAcao ? event.intAcao.id : undefined,
      int_candidatura_id: event?.intCandidatura ? event?.intCandidatura.id : undefined,
      int_mobilizacao_id: event?.intMobilizacao ? event?.intMobilizacao.id : undefined,
      us_titulo_id: event?.usTitulo?.id ?? undefined,
      di_entidade_id: event?.di_entidade_id,
    }

    postMessage({ type: 'showLoader' }, '*');
    updateAssociation(dataToSend).then(result => {
      getAssociacoesByProcesso(result?.dh_processo_id).then(result1 => {
        setData(result1);
        setOpenForm(false);
        postMessage({ type: 'hideLoader' }, '*')
      });
    });
  }

  const saveAssociationEntidade = (event: any) => {
    const dataToSend = {
      id: event?.id,
      oc_ocorrencia_id: event?.ocOcorrencia ? event?.ocOcorrencia.id : undefined,
      ag_pretensao_id: event?.agPretensao ? event?.agPretensao.id : undefined,
      ag_desconformidade_id: event?.agDesconformidade?.id ?? undefined,
      do_documento_id: event?.doDocumento ? event?.doDocumento.id : undefined,
      mo_modelo_id: event?.moModelo ? event?.moModelo.id : undefined,
      dh_patrimonio_id: event?.dhPatrimonio ? event?.dhPatrimonio.id : undefined,
      dh_processo_id: event?.dhProcessos ? event?.dhProcessos.id  : undefined,
      dh_demarcacao_id: event?.dhDemarcacao ? event?.dhDemarcacao.id : undefined,
      int_intervencao_id: event?.intIntervencao ? event?.intIntervencao.id : undefined,
      int_acao_id: event?.intAcao ? event?.intAcao.id : undefined,
      int_candidatura_id: event?.intCandidatura ? event?.intCandidatura.id : undefined,
      int_mobilizacao_id: event?.intMobilizacao ? event?.intMobilizacao.id : undefined,
      di_entidade_id: event?.di_entidade_id,
      us_titulo_id: event?.usTitulo?.id ?? undefined,
    }

    postMessage({ type: 'showLoader' }, '*');
    updateAssociation(dataToSend).then(result => {
      getAssociacoesByEntidade(result?.di_entidade_id).then(result1 => {
        setData(result1);
        setOpenForm(false);
        postMessage({ type: 'hideLoader' }, '*')
      });
    });
  }

  const saveAssociationOcorrencia = (event: any) => {
    const dataToSend = {
      id: event?.id,
      oc_ocorrencia_id: event?.ocOcorrencia ? event?.ocOcorrencia.id : undefined,
      ag_pretensao_id: event?.agPretensao ? event?.agPretensao.id : undefined,
      ag_desconformidade_id: event?.agDesconformidade?.id ?? undefined,
      do_documento_id: event?.doDocumento ? event?.doDocumento.id : undefined,
      mo_modelo_id: event?.moModelo ? event?.moModelo.id : undefined,
      dh_patrimonio_id: event?.dhPatrimonio ? event?.dhPatrimonio.id : undefined,
      dh_processo_id: event?.dhProcessos ? event?.dhProcessos.id  : undefined,
      dh_demarcacao_id: event?.dhDemarcacao ? event?.dhDemarcacao.id : undefined,
      int_intervencao_id: event?.intIntervencao ? event?.intIntervencao.id : undefined,
      int_acao_id: event?.intAcao ? event?.intAcao.id : undefined,
      int_candidatura_id: event?.intCandidatura ? event?.intCandidatura.id : undefined,
      int_mobilizacao_id: event?.intMobilizacao ? event?.intMobilizacao.id : undefined,
      us_titulo_id: event?.usTitulo?.id ?? undefined,
      di_entidade_id: event?.di_entidade_id,
    }

    postMessage({ type: 'showLoader' }, '*');
    updateAssociation(dataToSend).then(result => {
      getAssociacoesByOcorrencia(result?.oc_ocorrencia_id).then(result => {
        setData(result);
        setOpenForm(false);
        postMessage({ type: 'hideLoader' }, '*')
      });
    });
  }

  const saveAssociationUsTitulo = (event: any) => {
    const dataToSend = {
     id: event?.id,
      oc_ocorrencia_id: event?.ocOcorrencia ? event?.ocOcorrencia.id : undefined,
      ag_pretensao_id: event?.agPretensao ? event?.agPretensao.id : undefined,
      ag_desconformidade_id: event?.agDesconformidade?.id ?? undefined,
      do_documento_id: event?.doDocumento ? event?.doDocumento.id : undefined,
      mo_modelo_id: event?.moModelo ? event?.moModelo.id : undefined,
      dh_patrimonio_id: event?.dhPatrimonio ? event?.dhPatrimonio.id : undefined,
      dh_processo_id: event?.dhProcessos ? event?.dhProcessos.id  : undefined,
      dh_demarcacao_id: event?.dhDemarcacao ? event?.dhDemarcacao.id : undefined,
      int_intervencao_id: event?.intIntervencao ? event?.intIntervencao.id : undefined,
      int_acao_id: event?.intAcao ? event?.intAcao.id : undefined,
      int_candidatura_id: event?.intCandidatura ? event?.intCandidatura.id : undefined,
      int_mobilizacao_id: event?.intMobilizacao ? event?.intMobilizacao.id : undefined,
      us_titulo_id: event?.usTitulo?.id ?? undefined,
      di_entidade_id: event?.di_entidade_id,
    }

    postMessage({ type: 'showLoader' }, '*');
    updateAssociation(dataToSend).then(result => {
      getAssociacoesByUsosSolo(result?.us_titulo_id).then(result1 => {
        setData(result1);
        setOpenForm(false);
        postMessage({ type: 'hideLoader' }, '*')
      });
    });
  }

  const saveAssociationDocumento = (event: any) => {
    const dataToSend = {
     id: event?.id,
      oc_ocorrencia_id: event?.ocOcorrencia ? event?.ocOcorrencia.id : undefined,
      ag_pretensao_id: event?.agPretensao ? event?.agPretensao.id : undefined,
      ag_desconformidade_id: event?.agDesconformidade?.id ?? undefined,
      do_documento_id: event?.doDocumento ? event?.doDocumento.id : undefined,
      mo_modelo_id: event?.moModelo ? event?.moModelo.id : undefined,
      dh_patrimonio_id: event?.dhPatrimonio ? event?.dhPatrimonio.id : undefined,
      dh_processo_id: event?.dhProcessos ? event?.dhProcessos.id  : undefined,
      dh_demarcacao_id: event?.dhDemarcacao ? event?.dhDemarcacao.id : undefined,
      int_intervencao_id: event?.intIntervencao ? event?.intIntervencao.id : undefined,
      int_acao_id: event?.intAcao ? event?.intAcao.id : undefined,
      int_candidatura_id: event?.intCandidatura ? event?.intCandidatura.id : undefined,
      int_mobilizacao_id: event?.intMobilizacao ? event?.intMobilizacao.id : undefined,
      us_titulo_id: event?.usTitulo?.id ?? undefined,
      di_entidade_id: event?.di_entidade_id,
    }

    postMessage({ type: 'showLoader' }, '*');
    updateAssociation(dataToSend).then(result => {
      getAssociacoesByDocumento(result?.do_documento_id).then(result1 => {
        setData(result1);
        setOpenForm(false);
        postMessage({ type: 'hideLoader' }, '*')
      });
    });
  }

  const saveAssociationPretensoes = (event: any) => {
    const dataToSend = {
      id: event?.id,
      oc_ocorrencia_id: event?.ocOcorrencia ? event?.ocOcorrencia.id : undefined,
      ag_pretensao_id: event?.agPretensao ? event?.agPretensao.id : undefined,
      ag_desconformidade_id: event?.agDesconformidade?.id ?? undefined,
      do_documento_id: event?.doDocumento ? event?.doDocumento.id : undefined,
      mo_modelo_id: event?.moModelo ? event?.moModelo.id : undefined,
      dh_patrimonio_id: event?.dhPatrimonio ? event?.dhPatrimonio.id : undefined,
      dh_processo_id: event?.dhProcessos ? event?.dhProcessos.id  : undefined,
      dh_demarcacao_id: event?.dhDemarcacao ? event?.dhDemarcacao.id : undefined,
      int_intervencao_id: event?.intIntervencao ? event?.intIntervencao.id : undefined,
      int_acao_id: event?.intAcao ? event?.intAcao.id : undefined,
      int_candidatura_id: event?.intCandidatura ? event?.intCandidatura.id : undefined,
      int_mobilizacao_id: event?.intMobilizacao ? event?.intMobilizacao.id : undefined,
      us_titulo_id: event?.usTitulo?.id ?? undefined,
      di_entidade_id: event?.di_entidade_id,
    }

    postMessage({ type: 'showLoader' }, '*');
    updateAssociation(dataToSend).then(result => {
      getAssociacoesByPretensoes(result?.ag_pretensao_id).then(result1 => {
        setData(result1);
        setOpenForm(false);
        postMessage({ type: 'hideLoader' }, '*')
      });
    });
  }

  const saveAssociationDesconformidades = (event: any) => {
    const dataToSend = {
       id: event?.id,
      oc_ocorrencia_id: event?.ocOcorrencia ? event?.ocOcorrencia.id : undefined,
      ag_pretensao_id: event?.agPretensao ? event?.agPretensao.id : undefined,
      ag_desconformidade_id: event?.agDesconformidade?.id ?? undefined,
      do_documento_id: event?.doDocumento ? event?.doDocumento.id : undefined,
      mo_modelo_id: event?.moModelo ? event?.moModelo.id : undefined,
      dh_patrimonio_id: event?.dhPatrimonio ? event?.dhPatrimonio.id : undefined,
      dh_processo_id: event?.dhProcessos ? event?.dhProcessos.id  : undefined,
      dh_demarcacao_id: event?.dhDemarcacao ? event?.dhDemarcacao.id : undefined,
      int_intervencao_id: event?.intIntervencao ? event?.intIntervencao.id : undefined,
      int_acao_id: event?.intAcao ? event?.intAcao.id : undefined,
      int_candidatura_id: event?.intCandidatura ? event?.intCandidatura.id : undefined,
      int_mobilizacao_id: event?.intMobilizacao ? event?.intMobilizacao.id : undefined,
      us_titulo_id: event?.usTitulo?.id ?? undefined,
      di_entidade_id: event?.di_entidade_id,
    }

    postMessage({ type: 'showLoader' }, '*');
    updateAssociation(dataToSend).then(result => {
      getAssociacoesByDesconformidades(result?.ag_desconformidade_id).then(result1 => {
        setData(result1);
        setOpenForm(false);
        postMessage({ type: 'hideLoader' }, '*')
      });
    });
  }

  const saveAssociationPatrimonio = (event: any) => {
    const dataToSend = {
      id: event?.id,
      oc_ocorrencia_id: event?.ocOcorrencia ? event?.ocOcorrencia.id : undefined,
      ag_pretensao_id: event?.agPretensao ? event?.agPretensao.id : undefined,
      ag_desconformidade_id: event?.agDesconformidade?.id ?? undefined,
      do_documento_id: event?.doDocumento ? event?.doDocumento.id : undefined,
      mo_modelo_id: event?.moModelo ? event?.moModelo.id : undefined,
      dh_patrimonio_id: event?.dhPatrimonio ? event?.dhPatrimonio.id : undefined,
      dh_processo_id: event?.dhProcessos ? event?.dhProcessos.id  : undefined,
      dh_demarcacao_id: event?.dhDemarcacao ? event?.dhDemarcacao.id : undefined,
      int_intervencao_id: event?.intIntervencao ? event?.intIntervencao.id : undefined,
      int_candidatura_id: event?.intCandidatura ? event?.intCandidatura.id : undefined,
      int_mobilizacao_id: event?.intMobilizacao ? event?.intMobilizacao.id : undefined,
      int_acao_id: event?.intAcao ? event?.intAcao.id : undefined,
      us_titulo_id: event?.usTitulo?.id ?? undefined,
      di_entidade_id: event?.di_entidade_id,
    }

    postMessage({ type: 'showLoader' }, '*');
    updateAssociation(dataToSend).then(result => {
      getAssociacoesByPatrimonio(result?.dh_patrimonio_id).then(result1 => {
        setData(result1);
        setOpenForm(false);
        postMessage({ type: 'hideLoader' }, '*')
      });
    });
  }

  const saveAssociationDemarcacao = (event: any) => {
    const dataToSend = {
      id: event?.id,
      oc_ocorrencia_id: event?.ocOcorrencia ? event?.ocOcorrencia.id : undefined,
      ag_pretensao_id: event?.agPretensao ? event?.agPretensao.id : undefined,
      ag_desconformidade_id: event?.agDesconformidade?.id ?? undefined,
      do_documento_id: event?.doDocumento ? event?.doDocumento.id : undefined,
      mo_modelo_id: event?.moModelo ? event?.moModelo.id : undefined,
      dh_patrimonio_id: event?.dhPatrimonio ? event?.dhPatrimonio.id : undefined,
      dh_processo_id: event?.dhProcessos ? event?.dhProcessos.id  : undefined,
      dh_demarcacao_id: event?.dhDemarcacao ? event?.dhDemarcacao.id : undefined,
      int_intervencao_id: event?.intIntervencao ? event?.intIntervencao.id : undefined,
      int_acao_id: event?.intAcao ? event?.intAcao.id : undefined,
      int_candidatura_id: event?.intCandidatura ? event?.intCandidatura.id : undefined,
      int_mobilizacao_id: event?.intMobilizacao ? event?.intMobilizacao.id : undefined,
      us_titulo_id: event?.usTitulo?.id ?? undefined,
      di_entidade_id: event?.di_entidade_id,
    }

    postMessage({ type: 'showLoader' }, '*');
    updateAssociation(dataToSend).then(result => {
      getAssociacoesByDemarcacao(result?.dh_demarcacao_id).then(result1 => {
        setData(result1);
        setOpenForm(false);
        postMessage({ type: 'hideLoader' }, '*')
      });
    });
  }

   const saveAssociationIntervencao = (event: any) => {
    const dataToSend = {
      id: event?.id,
      oc_ocorrencia_id: event?.ocOcorrencia ? event?.ocOcorrencia.id : undefined,
      ag_pretensao_id: event?.agPretensao ? event?.agPretensao.id : undefined,
      ag_desconformidade_id: event?.agDesconformidade?.id ?? undefined,
      do_documento_id: event?.doDocumento ? event?.doDocumento.id : undefined,
      mo_modelo_id: event?.moModelo ? event?.moModelo.id : undefined,
      dh_patrimonio_id: event?.dhPatrimonio ? event?.dhPatrimonio.id : undefined,
      dh_processo_id: event?.dhProcessos ? event?.dhProcessos.id  : undefined,
      dh_demarcacao_id: event?.dhDemarcacao ? event?.dhDemarcacao.id : undefined,
      int_intervencao_id: event?.intIntervencao ? event?.intIntervencao.id : undefined,
      int_acao_id: event?.intAcao ? event?.intAcao.id : undefined,
      int_candidatura_id: event?.intCandidatura ? event?.intCandidatura.id : undefined,
      int_mobilizacao_id: event?.intMobilizacao ? event?.intMobilizacao.id : undefined,
      us_titulo_id: event?.usTitulo?.id ?? undefined,
      di_entidade_id: event?.di_entidade_id,
    }

    postMessage({ type: 'showLoader' }, '*');
    updateAssociation(dataToSend).then(result => {
      getAssociacoesByIntervencao(result?.int_intervencao_id).then(result1 => {
        setData(result1);
        setOpenForm(false);
        postMessage({ type: 'hideLoader' }, '*')
      });
    });
  }

  const saveAssociationAcao = (event: any) => {
    const dataToSend = {
      id: event?.id,
      oc_ocorrencia_id: event?.ocOcorrencia ? event?.ocOcorrencia.id : undefined,
      ag_pretensao_id: event?.agPretensao ? event?.agPretensao.id : undefined,
      ag_desconformidade_id: event?.agDesconformidade?.id ?? undefined,
      do_documento_id: event?.doDocumento ? event?.doDocumento.id : undefined,
      mo_modelo_id: event?.moModelo ? event?.moModelo.id : undefined,
      dh_patrimonio_id: event?.dhPatrimonio ? event?.dhPatrimonio.id : undefined,
      dh_processo_id: event?.dhProcessos ? event?.dhProcessos.id  : undefined,
      dh_demarcacao_id: event?.dhDemarcacao ? event?.dhDemarcacao.id : undefined,
      int_intervencao_id: event?.intIntervencao ? event?.intIntervencao.id : undefined,
      int_acao_id: event?.intAcao ? event.intAcao.id : undefined,    
      int_candidatura_id: event?.intCandidatura ? event?.intCandidatura.id : undefined,  
      int_mobilizacao_id: event?.intMobilizacao ? event?.intMobilizacao.id : undefined,
      us_titulo_id: event?.usTitulo?.id ?? undefined,
      di_entidade_id: event?.di_entidade_id,
    }

    postMessage({ type: 'showLoader' }, '*');
    updateAssociation(dataToSend).then(result => {
      getAssociacoesByAcao(result?.int_acao_id).then(result1 => {
        setData(result1);
        setOpenForm(false);
        postMessage({ type: 'hideLoader' }, '*')
      });
    });
  }
  const saveAssociationCandidatura = (event: any) => {
    const dataToSend = {
      id: event?.id,
      oc_ocorrencia_id: event?.ocOcorrencia ? event?.ocOcorrencia.id : undefined,
      ag_pretensao_id: event?.agPretensao ? event?.agPretensao.id : undefined,
      ag_desconformidade_id: event?.agDesconformidade?.id ?? undefined,
      do_documento_id: event?.doDocumento ? event?.doDocumento.id : undefined,
      mo_modelo_id: event?.moModelo ? event?.moModelo.id : undefined,
      dh_patrimonio_id: event?.dhPatrimonio ? event?.dhPatrimonio.id : undefined,
      dh_processo_id: event?.dhProcessos ? event?.dhProcessos.id  : undefined,
      dh_demarcacao_id: event?.dhDemarcacao ? event?.dhDemarcacao.id : undefined,
      int_intervencao_id: event?.intIntervencao ? event?.intIntervencao.id : undefined,
      int_acao_id: event?.intAcao ? event?.intAcao.id : undefined,
      int_candidatura_id: event?.intCandidatura ? event?.intCandidatura.id : undefined,
      int_mobilizacao_id: event?.intMobilizacao ? event?.intMobilizacao.id : undefined,
      us_titulo_id: event?.usTitulo?.id ?? undefined,
      di_entidade_id: event?.di_entidade_id,
    }

    postMessage({ type: 'showLoader' }, '*');
    updateAssociation(dataToSend).then(result => {
      getAssociacoesByCandidatura(result?.int_candidatura_id).then(result1 => {
        setData(result1);
        setOpenForm(false);
        postMessage({ type: 'hideLoader' }, '*')
      });
    });
  }
   const saveAssociationMobilizacao = (event: any) => {
    const dataToSend = {
      id: event?.id,
      oc_ocorrencia_id: event?.ocOcorrencia ? event?.ocOcorrencia.id : undefined,
      ag_pretensao_id: event?.agPretensao ? event?.agPretensao.id : undefined,
      ag_desconformidade_id: event?.agDesconformidade?.id ?? undefined,
      do_documento_id: event?.doDocumento ? event?.doDocumento.id : undefined,
      mo_modelo_id: event?.moModelo ? event?.moModelo?.id : undefined,
      dh_patrimonio_id: event?.dhPatrimonio ? event?.dhPatrimonio.id : undefined,
      dh_processo_id: event?.dhProcessos ? event?.dhProcessos.id  : undefined,
      dh_demarcacao_id: event?.dhDemarcacao ? event?.dhDemarcacao.id : undefined,
      int_intervencao_id: event?.intIntervencao ? event?.intIntervencao.id : undefined,
      int_acao_id: event?.intAcao ? event?.intAcao.id : undefined,
      int_candidatura_id: event?.intCandidatura ? event?.intCandidatura.id : undefined,
      int_mobilizacao_id: event?.intMobilizacao ? event?.intMobilizacao.id : undefined,
      us_titulo_id: event?.usTitulo?.id ?? undefined,
      di_entidade_id: event?.di_entidade_id,
    }

    postMessage({ type: 'showLoader' }, '*');
    updateAssociation(dataToSend).then(result => {
      getAssociacoesByMobilizacao(result?.int_mobilizacao_id).then(result1 => {
        setData(result1);
        setOpenForm(false);
        postMessage({ type: 'hideLoader' }, '*')
      });
    });
  }

  const addNew = (e: any) => {
    setOpenForm(true);
    setEditItem({ id: undefined });
    e.preventDefault();
    return;
  };

  const handleCancelEdit = () => {
    setOpenForm(false);
  };

  const cancelModalDescartar = () => {
    setOpenModalDescartar(false)
  }

  const handleDescartar = async (e: any) => { 
    const result = await deleteAssociacao(idToRemove);
    setOpenModalDescartar(false);
    initForm();
  }


  const MyEditCommandCell = (props: GridCellProps) => (
    <EditCommandCell {...props} viewMode={viewMode} enterEdit={enterEdit} pointOnMap={pointOnMap} deleteItem={deleteItem} />
  );
  const CustomHtmlCell = (props: GridCellProps) => {
 
    return (
      <div>{parse(props.dataItem?.resumo)}</div>
    )
  }
  const editField = "inEdit";
  return (
    <>
      <Grid
        className={` ${selectedStyles.form} ${viewMode? 'customGridVisualizar' : 'k-grid.k-grid-md.GridDocumentos_form__Kg7bw.customGrid'} `}
        editField={editField}
        dataItemKey={"id"}
        data={
          data
            ?.filter((item : any)=> item?.tipo !== "Documento") 
            .map((data: any) => ({
              ...data,
              resumo: data?.resumo.replaceAll('|', '<br/>'),
              observacoesOcorrencia: data?.ocOcorrencia?.observacoes ? data?.ocOcorrencia?.observacoes.slice(0, 50) : '',
            }))
            .slice(page.skip, page.take + page.skip)
        }
        pageable={{
          buttonCount: 5,
          info: true,
          type: 'numeric',
          pageSizes: true,
          previousNext: true,
        }}
        skip={page.skip}
        take={page.take}
        total={entidades.length}
        onPageChange={handlePageChange}
      >
        {!viewMode ? (
        <GridToolbar>
          <button
            title="Nova associação"
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnPrimaryColor"
            onClick={(e) => { addNew(e) }}
            style={{textTransform: 'none'}}
          >
            <span className="icon-siarl icon-registar k-button-icon"></span>{' '}
            &nbsp;{' '}
            Nova associação
          </button>
        </GridToolbar>
        ) : ""}

        <GridColumn cell={MyEditCommandCell} width={'50px'} />
        <GridColumn field="tipo" title="Tipo" />
        <GridColumn cell={CustomHtmlCell} />
      </Grid>
      {openForm && (
        <EditAssociacaoForm
          cancelEdit={handleCancelEdit}
          onSubmit={handleSubmit}
          item={editItem}
        />
      )}
            {openModalDescartar ? (
          <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={cancelModalDescartar}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                  <fieldset>
                    <span className="textDescartar">Tem a certeza que deseja apagar esta associação?  </span>
                    <br></br>
                  </fieldset>
                  <br></br>
                  <div className="k-form-buttons btnModal">                    
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar"
                      onClick={handleDescartar}

                    >
                      Sim
                    </button>
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                      onClick={cancelModalDescartar}
                    >
                      Não
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}
    </>
  )
}
