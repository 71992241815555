import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import ReactLoading from 'react-loading'
import styles from '../../styles/viewer/ViewerLayout.module.scss'
import FloatingMenu from './FloatingMenu'
import SidebarMenu from './SidebarMenu'
import ViewerMap from './ViewerMap'

import RegistarDocumentosTabs from '../../routes/documentos/Registar'
import RegistarModelosTabs from '../../routes/modelos/Registar'
import RegistarOcorrenciaTerrenoTabs from '../../routes/ocorrencia/terreno/RegistarTabs'
import SessionStorageService from '../../services/SessionStorageService'
import {
  agDecisoes,
  agEstados,
  agTipoPretensoes,
  arhs,
  concelhos,
  distritos,
  fillCombosAuxiliares,
  fillLocation,
  freguesias,
  get,
  getEntidadeSelected,
  getPatrimonioSelected,
  nuts1,
  nuts2,
  nuts3,
  post,
  put,
  setCandidaturaSelected,
  setAcaoSelected,
  setCoordenadasLinhaSelected,
  setCoordenadasPoligono,
  setCoordenadasSelected,
  setDemarcacaoSelected,
  setDesconformidadeSelected,
  setDocumentoSelected,
  setEntidadeSelected,
  setIntervencaoSelected,
  setMobilizacaoSelected,
  setModeloSelected,
  setOcorrenciaSelected,
  setPatrimonioSelected,
  setPretensaoSelected,
  setProcessoSelected,
  getCurrentMapId,
  setCurrentMapId,
  useCombos,
  statusLocal,
  setIsEditingOcorrencia,
  handleGenericError,
  setusoSoloSelected,
  ocEstados,
  tecnicos,
  preventPropsNullValues,
  hasPermission,
  setEaRegistoAnalise,
  getModeloSelected,
  getUsoSoloSelected,
  getDocumentoSelected,
  getDesconformidadeSelected,
  getProcessoSelected,
  getDemarcacaoSelected,
  getIntervencaoSelected,
  getAcaoSelected,
  getMobilizacaoSelected,
  getEaRegistoAnalise,
  getPretensaoSelected,
  getOcorrenciaSelected,
  refreshOcorrencesSearch,
  mobilecheck,
  mapStore,
  isUserLogged,
  coordenadasPoligono,
  sendUserLogin,
  setGeoPortalUser,
  TiposUtilizacao,
  tipoTitulos
} from '../../services/utils'
import { v4 as uuid } from 'uuid';
import { useAgDesconformidadeStore } from '../../stores/agDesconformidade'
import { useAgPretensaoStore } from '../../stores/agPretensao'
import { useDocumentosStore } from '../../stores/documentos'
import { useEntidadeStore } from '../../stores/entidade'
import { useModeloStore } from '../../stores/modelo'
import { useOcorrenciaStore } from '../../stores/ocorrencias'
import {
  AG_DESCONFORMIDADE_POINT_NAME,
  AG_PRETENSAO_POINT_NAME,
  APOIO_GESTAO_MODULE_NAME,
  AUTH_LOCALSTORAGE,
  DH_DEMARCACAO_POINT_NAME,
  DH_PATRIMONIO_POINT_NAME,
  DH_PROCESSO_POINT_NAME,
  DOCUMENTO_POINT_NAME,
  DOMINIO_HIDRICO_MODULE_NAME,
  ENTIDADES_MODULE_NAME,
  ENTIDADE_POINT_NAME,
  FIND_OCORRENCIA,
  GET_ADDRESS_BY_LOCATION,
  GET_AG_DESCONFORMIDADE_BY_ID,
  GET_DEMARCACAO,
  GET_DOCUMENTO,
  GET_ENTIDADE,
  GET_MODELO,
  GET_OCORRENCIA,
  GET_PATRIMONIO,
  GET_PRETENSAO,
  GET_PROCESSO,
  INT_CANDIDATURA_POINT_NAME,
  INT_ACAO_POINT_NAME,
  INT_INTERVENCAO_POINT_NAME,
  INT_MOBILIZACAO_POINT_NAME,
  MODELO_POINT_NAME,
  OCORRENCIAS_MODULE_NAME,
  OCORRENCIA_POINT_NAME,
  PATRIMONIO_MODULE_NAME,
  PESQUISAR_CANDIDATURA_BY_ID,
  PESQUISAR_ACAO,
  PESQUISAR_ENTIDADEBYUSER,
  PESQUISAR_INTERVENCAO_BY_ID,
  PESQUISAR_MOBILIZACAO,
  PESQUISAR_OCORRENCIAS_SIMPLES,
  SAVE_NEW_AG_DESCONFORMIDADE,
  SAVE_NEW_ENTIDADE,
  SAVE_NEW_PATRIMONIO,
  SAVE_OCORRENCIA,
  UPDATE_AG_DESCONFORMIDADE,
  UPDATE_ENTIDADE,
  UPDATE_OCORRENCIA,
  UPDATE_PATRIMONIO,
  GET_GV_SESSION_STATE,
  SAVE_GV_SESSION_STATE,
  MODELOS_MODULE_NAME,
  GET_FREGUESIA_FROM_CODE,
  CALCULO_PERFIL_MODULE_NAME,
  GET_ALT_FROM_LINE,
  PESQUISAR_ACAO_BY_ID,
  US_TITULOS_POINT_NAME,
  GET_US_TITULO_BY_ID,
  GET_USER_BY_ID,
  USO_DO_SOLO_MODULE_NAME,
  PERMISSION_DOCUMENTOS_CONSULTA_AVANCADA,
  PERMISSION_CONSULTAR_OCORRENCIA,
  UPDATE_ENTIDADE_JURISDICAO,
  UPDATE_DH_PROCESSO,
  UPDATE_DEMARCACAO,
  UPDATE_INTERVENCAO,
  UPDATE_US_TITULO,
  US_TITULO_POINT_NAME,
  ENTIDADE_JURISDICAO_POINT_NAME,
  OCORRENCIA_APOIO_POINT_NAME,
  UPDATE_OCORRENCIA_APOIO,
  UPDATE_OCORRENCIA_GEOMETRY,
  UPDATE_MODELO,
  UPDATE_DOCUMENTO_GEOM,
  UPDATE_PRETENSAO,
  EA_POINT_NAME,
  GET_ANALISE,
  UPDATE_ANALISE,
  UPDATE_GEOM_ENTIDADE,
  UPDATE_US_TITULO_GEOM,
  RECORDS_PER_PAGE,
  FOTO_AEREA_POINT_NAME,
  GET_FO_FOTO_BY_ID,
  GET_FOTOS_REGISTO_VOO,
  CURRENT_TIMEZONE,
  EPSG_GLOBAL,
  EPSG_MAP,
  PERMISSION_OCORRENCIA_PERFIL_COMPLETO_LEITURA,
  PERMISSION_OCORRENCIA_PERFIL_TOTAL,
  PERMISSION_OCORRENCIA_PERFIL_COMPLETO_EDICAO,
  PERMISSION_OCORRENCIA_PERFIL_INTERMEDIO_EDICAO,
  INTERVENCAO_MODULE_NAME,
  ESTRATEGIA_ADAPATACAO_MODULE_NAME,
  FOTO_AEREA_MODULE_NAME,
  CRITERIO_SEDE,
  GEOPORTAL_MAPA_URL,
  GEOPORTAL_MAPA_PUBLICO_URL,
  USER_GUEST,
  PASS_GUEST
} from '../../utils/constants'
import ConsultarApoioGestaoTabsForm from '../apoiogestao/ConsultarApoioGestaoTabsForm'
import RegistarApoioGestaoDesconformidadeForm from '../apoiogestao/RegistarApoioGestaoDesconformidadeForm'
import RegistarApoioGestaoPretensaoForm from '../apoiogestao/RegistarApoioGestaoPretensaoForm'
import ResultadoApoiogestaoDesconformidade from '../apoiogestao/ResultadoApoioGestaoDesconformidade'
import ResultadoApoioGestaoPretensao from '../apoiogestao/ResultadoApoioGestaoPretensao'
import PesquisarDocumentosTabsForm from '../documentos/PesquisarDocumentosTabsForm'
import ResultadoDocumentosForm from '../documentos/ResultadoDocumentos'
import PesquisarDominioHidricoTabs from '../dominiohidrico/PesquisarDominioHidricoTabs'
import RegistarDemarcacaoForm from '../dominiohidrico/RegistarDemarcacaoForm'
import RegistarPatrimonioForm from '../dominiohidrico/RegistarPatrimonioForm'
import RegistarProcessoForm from '../dominiohidrico/RegistarProcessoForm'
import ResultadoProcessoForm from '../dominiohidrico/ResultadoProcessoForm'
import ConsultarEntidadesForm from '../entidade/ConsultarEntidadeForm'
import RegistarEntidade from '../entidade/RegistarEntidade'
import ResultadoEntidadeForm from '../entidade/ResultadoEntidadeForm'
import PesquisarIntervencaoTabs from '../intervencao/PesquisarIntervencaoTabs'
import ConsultaModeloSimplesForm from '../modelos/ConsultaModeloSimplesForm'
import ResultadoModeloForm from '../modelos/ResultadoModeloForm'
import ConsultarOcorrenciaForm from '../ocorrencia/ConsultarOcorrenciaTabsForm'
import ResultadoOcorrenciaForm from '../ocorrencia/ResultadoOcorrenciaForm'
import ConsultarUsoDoSoloTabsForm from '../usosdosolo/ConsultarUsoDoSoloTabsForm'
import DetalheValorUsoDoSolo from '../usosdosolo/DetalheValorUsoDoSolo'
import RegistarUsoDoSoloForm from '../usosdosolo/RegistarUsoDoSoloForm'
import ResultadoUsoDoSoloForm from '../usosdosolo/ResultadoUsoDoSoloForm'
import ResultadoValorUsoDoSolo from '../usosdosolo/ResultadoValorUsoDoSolo'
import CollapsedSidebarMenu from './CollapsedSidebarMenu'
import type { Item } from './SidebarMenu'
import ResultadoDemarcacaoForm from '../dominiohidrico/ResultadoDemarcacaoForm'
import ResultadoPatrimonioForm from '../dominiohidrico/ResultadoPatrimonioForm'
import { useDhPatrimonioStore } from '../../stores/dhPatrimonio'
import { useDhProcessoStore } from '../../stores/dhProcesso'
import { useDhDemarcacaoStore } from '../../stores/dhDemarcacao'
import RegistarAcao from '../intervencao/RegistarAcao'
import RegistarIntervencao from '../intervencao/RegistarIntervencao'
import ResultadoIntervencao from '../intervencao/ResultadoIntervencaoForm'
import ResultadoCandidatura from '../intervencao/ResultadoCandidaturaForm'
import ResultadoMobilizacao from '../intervencao/ResultadoMobilizacaoForm'
import RegistarCandidatura from '../intervencao/RegistarCandidatura'
import RegistarMobilizacao from '../intervencao/RegistarMobilizacao'
import ResultadoAcaoForm from '../intervencao/ResultadoAcaoForm'
import SobreForm from '../sobre/SobreForm'
import { useCandidaturaStore } from '../../stores/candidatura'
import ContactenosForm from '../contactenos/ContactenosForm'
import ResultadoMobilizacaoForm from '../intervencao/ResultadoMobilizacaoForm'
import { useCommonStore } from '../../stores/common'
import { useIntervencaoStore } from '../../stores/intervencao'
import { useAcaoStore } from '../../stores/acao'
import { useMobilizacaoStore } from '../../stores/mobilizacao'
import { useParams, useSearchParams } from 'react-router-dom'

import VooDroneFormTab from '../vooDrone/VooDroneFormTab'
import RegistarVooDroneForm from '../vooDrone/RegistarVooDroneForm'
import ListarGeovisualizadores from '../geovisualizadores/ListarGeovisualizadores'
import ConsultarFotografiasForm from '../fotografia/ConsultarFotografiaForm'
import RegistarFotografia from '../fotografia/RegistarFotografia'
import ResultadoVooDroneForm from '../vooDrone/ResultadoVooDroneForm'
import ResultadoFotoForm from '../fotografia/ResultadoFotoForm'
import CalculoPerfisGeograficosDinamicosForm from '../calculoPerfisGeograficosDinamicos/CalculoPerfisGeograficosDinamicosForm'
import GraficoCPGDForm, { listaResolucaoPontos } from '../calculoPerfisGeograficosDinamicos/GraficoCPGDForm'
import { useUsoDoSoloStore } from '../../stores/usoDoSolo'
import useVisibleColumns from '../../hooks/useVIsibleColumns'
import ConsultarEstrategiaAdaptacaoForm from '../estrategiaAdaptacao/ConsultarEstrategiaAdaptacaoForm'
import RegistarEstrategiaAdaptacaoTabs from '../estrategiaAdaptacao/RegistarEstrategiaAdaptacaoTabs'
import ResultadoEstrategiaAdaptacao from '../estrategiaAdaptacao/ResultadoEstrategiaAdaptacao'
import { EstrategiaAdaptacaoParametros } from '../estrategiaAdaptacao/Parametros'
import { mostraFloatingMenu_e_Mapa, mostraFloatingMenu_e_Mapa_e_Oculta_Form, mostraFormulario, ocultaFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa_e_Mostra_Form, telaMobile } from '../common/ItemRenderMais'
import FloatingBtnMapa from './FloatingBtnMapa'
import ConsultarClassificadoresForm from '../classificadores/ConsultarClassificadoresForm'
import { useEstrategiaAdaptacaoStore } from '../../stores/estrategiaAdaptacao'
import Contactenos from '../contactenos/ContactenosArcgis'
import ImagePreviewV2 from './ImagePreviewV2'
import { Dialog } from '@progress/kendo-react-dialogs'
import { format } from 'date-fns'
import moment from 'moment';
import "moment-timezone"
import proj4 from 'proj4'
import { login } from '../../services/auth'

const initialSelectedItem = {
  icon: <></>,
  route: '',
  selected: false,
  text: '',
}

export default function ViewerLayout() {
  const [showLoader, setShowLoader] = useState(false)
  const [insertedId, setInsertedId] = useState<any>()
  const [collapseSidebarMenu, setCollapseSidebarMenu] = useState(false)
  const [selectedMenuItem, setSelectedMenuItem] =
    useState<Item>(initialSelectedItem)
  const [hideFloatingMenu, setHideFloatingMenu] = useState(false)
  const [hideFloatingBtnMapa, setHideFloatingBtnMapa] = useState(false)
  const [initialValuesProcesso, setInitialValuesProcesso] = useState(undefined)
  const [initialValuesEntidade, setInitialValuesEntidade] = useState(undefined)
  const [showFotoAereaSelected, setShowFotoaereaSelected] = useState(false);
  const [idFotoaereaSelected, setIdFotoAereaSelected] = useState("");
  const [urlFotoAereaSelected, setUrlFotoAereaSelected] = useState("");
  const [dataFotoTitle, setDataFotoTitle] = useState("");
  const [nomeVooTitle, setNomeVooTitle] = useState("");
  const [fotosList, setFotosList] = useState([]);
  const queryParams = new URLSearchParams(window.location.search)
  const getOcorrencia = async (data: any) =>
    await get<any, any>(`${GET_OCORRENCIA}/` + data)
  const getEntidade = async (data: any) =>
    await get<any, any>(`${GET_ENTIDADE}/` + data)
  const getPatrimonio = async (data: any) =>
    await get<any, any>(`${GET_PATRIMONIO}/` + data)
  const getOcorrencias = async (data: any) =>
    await post<any, any>(`${PESQUISAR_OCORRENCIAS_SIMPLES}`, data)
  const saveOcorrencia = async (data: any) =>
    await post<any, any>(`${SAVE_OCORRENCIA}`, data)
  const getAddressFromLocation = async (data: any) =>
    await post<any, any>(`${GET_ADDRESS_BY_LOCATION}`, data)
  const getDocumento = async (data: any) =>
    await get<any, any>(`${GET_DOCUMENTO}/` + data)
  const getPretensao = async (data: any) =>
    await get<any>(`${GET_PRETENSAO}/` + data)
  const getDesconformidade = async (data: any) =>
    await get<any, any>(`${GET_AG_DESCONFORMIDADE_BY_ID}/` + data)
  const getProcesso = async (data: any) =>
    await get<any, any>(`${GET_PROCESSO}/` + data)
  const getDemarcacao = async (data: any) =>
    await get<any, any>(`${GET_DEMARCACAO}/` + data)
  const getIntervencao = async (data: any) =>
    await get<any, any>(`${PESQUISAR_INTERVENCAO_BY_ID}/` + data)
  const getCandidatura = async (data: any) =>
    await get<any, any>(`${PESQUISAR_CANDIDATURA_BY_ID}/` + data)
  const getAcao = async (data: any) =>
    await get<any, any>(`${PESQUISAR_ACAO_BY_ID}/` + data)
  const getSessionState = async (data: any) =>
    await get<any, any>(`${GET_GV_SESSION_STATE}/` + data)
  const getFreguesiaFromCode = async (data: any) =>
    await get<any, any>(`${GET_FREGUESIA_FROM_CODE}/` + data)
  const getUsoDoSolo = async (data: any) =>
    await get<any, any>(`${GET_US_TITULO_BY_ID}/` + data)
  const getFotografiaObliqua = async (data: any) =>
    await get<any, any>(`${GET_FO_FOTO_BY_ID}/` + data)
  const getFotosRegistoVoo = async (data: any) =>
    await get<any, any>(`${GET_FOTOS_REGISTO_VOO}/` + data)

  //const getAltFromLine = async (data: any) => await post<any, any>(GET_ALT_FROM_LINE, data);

  const getMobilizacao = async (data: any) =>
    await get<any, any>(`${PESQUISAR_MOBILIZACAO}/` + data)

  const getAnalise = async (data: any) =>
    await get<any, any>(`${GET_ANALISE}/` + data)

  const selectProcessoResult = useDhProcessoStore(
    (state) => state.selectDhProcessoResult
  )
  const selectProcessoResultId = useDhProcessoStore(
    (state) => state.selectDhProcessoResultId
  )

  const selectAgPretensaoResultId = useAgPretensaoStore(
    (state) => state.selectAgPretensaoResultId
  )
  const selectAgPretensaoResult = useAgPretensaoStore(
    (state) => state.selectAgPretensaoResult
  )

  const selectDesconformidadeResultId = useAgDesconformidadeStore(
    (state) => state.selectAgDesconformidadeResultId
  )
  const selectDesconformidadeResult = useAgDesconformidadeStore(
    (state) => state.selectAgDesconformidadeResult
  )

  const selectUsoDoSoloResultId = useUsoDoSoloStore(
    (state) => state.selectUsoDoSoloResultId
  )
  const selectUsoDoSoloResult = useUsoDoSoloStore(
    (state) => state.selectUsoDoSoloResult
  )

  const findOcorrencia = async (data: any) =>
    await post<any, any>(`${FIND_OCORRENCIA}`, data)

  const getEntidadeByUser = async (data: any) =>
    await get<any, any>(`${PESQUISAR_ENTIDADEBYUSER}/` + data)

  const updateOcorrencia = async (data: any) =>
    await put<any, any>(`${UPDATE_OCORRENCIA}`, data)
  const selectedOcorrenciaResultId = useOcorrenciaStore(
    (state) => state.selectedOcorrenciaResultId
  )

  const selectedOcorrenciaResult: any = useOcorrenciaStore(
    (state) => state.selectedOcorrenciaResult
  )

  const updateEntidade = async (data: any) =>
    await put<any, any>(`${UPDATE_ENTIDADE}`, data)

    const updateGeomEntidade = async (data: any) =>
    await put<any, any>(`${UPDATE_GEOM_ENTIDADE}`, data)


  const updatePatrimonio = async (data: any) =>
    await put<any, any>(`${UPDATE_PATRIMONIO}`, data)

  const updateAnalise = async (data: any) =>
    await put<any, any>(`/${UPDATE_ANALISE}`, data)

  const selectOcorrenciaResult = useOcorrenciaStore(
    (state) => state.selectOcorrenciaResult
  )

  const selectEntidadeResult = useEntidadeStore(
    (state) => state.selectEntidadeResult
  )

  const selectPatrimonioResult = useDhPatrimonioStore(
    (state) => state.selectDhPatrimonioResult
  )

  const selectPatrimonioResultId = useDhPatrimonioStore(
    (state) => state.selectDhPatrimonioResultId
  )

  const selectDemarcacaoResult = useDhDemarcacaoStore(
    (state) => state.selectDhDemarcacaoResult
  )

  const selectDemarcacaoResultId = useDhDemarcacaoStore(
    (state) => state.selectDhDemarcacaoResultId
  )
  const selectCandidaturaResult = useCandidaturaStore(
    (state) => state.selectCandidaturaResult
  )

  const selectCandidaturaResultId = useCandidaturaStore(
    (state) => state.selectCandidaturaResultId
  )
  const selectDocumentoResult = useDocumentosStore(
    (state) => state.selectDocumentosResult
  )

  const selectDocumentosResultId = useDocumentosStore(
    (state) => state.selectDocumentosResultId
  )

  const selectOcorrenciaResultId = useOcorrenciaStore(
    (state) => state.selectOcorrenciaResultId
  )


  const selectIntervencaoResultId = useIntervencaoStore(
    (state) => state.selectIntervencaoResultId
  )

  const selectIntervencaoResult = useIntervencaoStore(
    (state) => state.selectIntervencaoResult
  )

  const selectAcaoResultId = useAcaoStore(
    (state) => state.selectAcaoResultId
  )

  const selectAcaoResult = useAcaoStore(
    (state) => state.selectAcaoResult
  )

  const selectMobilizacaoResult = useMobilizacaoStore(
    (state) => state.selectMobilizacaoResult
  )

  const selectMobilizacaoResultId = useMobilizacaoStore(
    (state) => state.selectMobilizacaoResultId
  )

  const setGPUrl = mapStore((state: any) => state.setUrl)

  const createEntidade = async (data: any) =>
    await post<any, any>(`${SAVE_NEW_ENTIDADE}`, data)

  const createPatrimonio = async (data: any) =>
    await post<any, any>(`${SAVE_NEW_PATRIMONIO}`, data)

  const getModelo = async (data: any) =>
    await get<any, any>(`${GET_MODELO}/` + data)

  const getUserById = async (data: any) =>
    await get<any, any>(`${GET_USER_BY_ID}/` + data)

  const selectedEntidadeResult: any = useEntidadeStore(
    (state) => state.selectedEntidadeResult
  )

  const selectEstrategiaResult = useEstrategiaAdaptacaoStore(
    (state) => state.selectEstrategiaResult
)
const selectEstrategiaResultId = useEstrategiaAdaptacaoStore(
  (state) => state.selectEstrategiaResultId
)

  const saveSessionState = async (data: any) =>
    await post<any, any>(`${SAVE_GV_SESSION_STATE}`, data);

  const [isMobile, setIsMobile] = useState(false);

  useLayoutEffect(() => {

    const handleResize = () => {

      if (window.innerWidth <= telaMobile) {
        
        setIsMobile(true);

      }else{

        setIsMobile(false);

      }

    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);  

  const [showBtnMapa, setShowBtnMapa] = useState(false)

  const [showResultsOcorrencia, setShowResultsOcorrencia] = useState(false)
  const [showResultadosModelo, setShowResultadosModelo] = useState(false)
  const [showConsultaOcorrencia, setShowConsultaOcorrencia] = useState(false)
  const [showConsultaDocumentos, setShowConsultaDocumentos] = useState(false)
  const [showConsultaModelos, setShowConsultaModelos] = useState(false)
  const [showResultadoVooDrone, setShowResultadoVooDrone] = useState(false)
  const [showRegistarModelos, setShowRegistarModelos] = useState(false)
  const [showRegistarOcorrencia, setShowRegistarOcorrencia] = useState(false)
  const [showRegistarDocumentos, setShowRegistarDocumentos] = useState(false)
  const [showResultsDocumentos, setShowResultsDocumentos] = useState(false)
  const [showConsultaEntidades, setShowConsultaEntidades] = useState(false)
  const [showResultsEntidade, setShowResultsEntidade] = useState(false)
  const [showRegistarEntidades, setShowRegistarEntidades] = useState(false)
  const [showConsultaEstrategiaAdaptacao, setShowConsultaEstrategiaAdaptacao] = useState(false)
  const [showResultsEstrategiaAdaptacao, setShowResultsEstrategiaAdaptacao] = useState(false)
  const [showRegistarEstrategiaAdaptacao, setShowRegistarEstrategiaAdaptacao] = useState(false)
  const [showParametrosEstrategiaAdaptacao, setShowParametrosEstrategiaAdaptacao] = useState(false)
  const [showClassificadores, setShowClassificadores] = useState(false);

  const [showConsultarUsoDoSolo, setShowConsultarUsoDoSolo] = useState(false)
  const [showRegistarUsoDoSolo, setShowRegistarUsoDoSolo] = useState(false)
  const [showResultadoUsoDoSolo, setShowResultadoUsoDoSolo] = useState(false)
  const [showResultadoValorUsoDoSolo, setShowResultadoValorUsoDoSolo] =
    useState(false)
  const [showDetalheValorUsoDoSolo, setShowDetalheValorUsoDoSolo] =
    useState(false)

  const [
    showConsultarApoioGestaoPretensao,
    setShowConsultarApoioGestaoPretensao,
  ] = useState(false)
  const [
    showResultadoApoioGestaoPretensao,
    setShowResultadoApoioGestaoPretensao,
  ] = useState(false)
  const [
    showRegistarApoioGestaoPretensao,
    setShowRegistarApoioGestaoPretensao,
  ] = useState(false)
  const [
    showConsultarApoioGestaoDesconformidade,
    setShowConsultarApoioGestaoDesconformidade,
  ] = useState(false)
  const [
    showRegistarApoioGestaoDesconformidade,
    setShowRegistarApoioGestaoDesconformidade,
  ] = useState(false)
  const [
    showResultadoApoioGestaoDesconformidade,
    setShowResultadoApoioGestaoDesconformidade,
  ] = useState(false)

  const [agTemas, setAgTemas] = useState<any>([])

  const [OpenModalDesconformidades, setOpenModalDesconformidades] =
    useState<any>()

  const cancelModalDesconformidades = () => {
    setOpenModalDesconformidades(false)
  }

  const selectedAgDesconformidadeResultId: any = useAgDesconformidadeStore(
    (state) => state.selectedAgDesconformidadeResultId
  )

  const updateAgDesconformidade = async (data: any) =>
    await put<any, any>(`${UPDATE_AG_DESCONFORMIDADE}`, data)

  const saveAgDesconformidade = async (data: any) =>
    await post<any, any>(`${SAVE_NEW_AG_DESCONFORMIDADE}`, data)

  const selectAgDesconformidadeResultId = useAgDesconformidadeStore(
    (state) => state.selectAgDesconformidadeResultId
  )
  const selectAgDesconformidadeResult = useAgDesconformidadeStore(
    (state) => state.selectAgDesconformidadeResult
  )

  //Intervenção

  //Dominio Hidrico
  const [showDominioHidrico, setShowDominioHidrico] = useState(false)
  const [showRegistarProcesso, setShowRegistarProcesso] = useState(false)
  const [showRegistarPatrimonio, setShowRegistarPatrimonio] = useState(false)
  const [showRegistarDemarcacao, setShowRegistarDemarcacao] = useState(false)
  const [showRegistoDemarcacao, setShowRegistoDemarcacao] = useState(false)

  const selectModeloResult = useModeloStore((state) => state.selectModeloResult)
  const selectModeloResultId = useModeloStore(
    (state) => state.selectModeloResultId
  )
  const [showResultadoProcesso, setShowResultadoProcesso] = useState(false)
  const [showResultadoPatrimonio, setShowResultadoPatrimonio] = useState(false)
  const [showResultadoDemarcacao, setShowResultadoDemarcacao] = useState(false)

  const [showIntervencao, setShowIntervencao] = useState(false)
  const [showCandidatura, setShowCandidatura] = useState(false)
  const [showRegistarAcao, setShowRegistarAcao] = useState(false)

  const [showResultadoAcao, setShowResultadoAcao] = useState(false)
  const [showRegistarIntervencao, setShowRegistarIntervencao] = useState(false)
  const [showResultadoIntervencao, setShowResultadoIntervencao] = useState(false)
  const [showResultadoMobilizacao, setShowResultadoMobilizacao] = useState(false)

  const [showRegistarCandidatura, setShowRegistarCandidatura] = useState(false)
  const [showResultadoCandidatura, setShowResultadoCandidatura] = useState(false)
  const [showRegistarMobilizacao, setShowRegistarMobilizacao] = useState(false)
  const [showSobre, setShowSobre] = useState(false)
  const [showVooDrone, setShowVooDrone] = useState(false)
  const [showRegistarVooDrone, setShowRegistarVooDrone] = useState(false)
  const [showContactenos, setShowContactenos] = useState(false)
  const [rodouEcra, setRodouEcra] = useState(false);
  const [isLandScapeMobile, setIsLandScapeMobile] = useState(false);

  const [showGeovisualizadores, setShowGeovisualizadores] = useState(false)

  const [showRegistarFoto, setShowRegistarFoto] = useState(false)
  const [showConsultarFoto, setShowConsultarFoto] = useState(false)
  const [showResultadoFoto, setShowResultadoFoto] = useState(false)
  const [showCalculoPerfisGeograficosDinamicos, setShowCalculoPerfisGeograficosDinamicos] = useState(false)
  const [showGraficoCPGD, setShowGraficoCPGD] = useState(false)
  const updateEntidadeJurisdicao = async(data: any) => await put<any,any>(`${UPDATE_ENTIDADE_JURISDICAO}`, data)
  const updateDhProcesso = async (data: any) => await put<any, any>(`${UPDATE_DH_PROCESSO}`, data);
  const updateDemarcacao = async (data: any) => await put<any, any>(`${UPDATE_DEMARCACAO}`, data)
  const updateIntervencao = async (data: any) => await put<any, any>(`${UPDATE_INTERVENCAO}`, data)
  const updateUsTitulo = async (data: any) => await put<any, any>(`${UPDATE_US_TITULO}`, data)
  const updateUsTituloGeom = async (data: any) => await put<any, any>(`${UPDATE_US_TITULO_GEOM}`, data)
  const updateOcorrenciaApoio = async (data: any) =>
  await put<any, any>(`${UPDATE_OCORRENCIA_APOIO}`, data)
const updateOcorrenciaGeom = async (data: any) =>
  await put<any, any>(`${UPDATE_OCORRENCIA_GEOMETRY}`, data)
const updateModelo = async (data: any) =>
await put<any, any>(`${UPDATE_MODELO}`, data)
const updateDocumentoGeom = async (data: any) =>
await put<any, any>(`${UPDATE_DOCUMENTO_GEOM}`, data)
const updatePretensao = async (data: any) =>
await put<any, any>(`${UPDATE_PRETENSAO}`, data)

  // Common store
  const restoreView = useCommonStore(state => state.restoreView)
  const isMapLoaded = useCommonStore(state => state.isMapLoaded)

  const userLocal = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}')
  const forcePortrait =(e: any) => {
    if (mobilecheck() && window.innerHeight > window.innerWidth) {
      setIsLandScapeMobile(true);
    } else {
      setIsLandScapeMobile(false);
    }
  }
  window.addEventListener('orientationchange', forcePortrait);
  const handleSubmit = async (formValues: any, isSubmitAction?: boolean) => {
    const desconformidadeRequest: any = {
      id: selectedAgDesconformidadeResultId ?? undefined,
      utilizador_id: userLocal.id,
      ag_tema_id: formValues?.values?.ag_tema?.id ?? undefined,
      ag_estado_id: formValues?.values?.ag_estado?.id ?? undefined,
      medida_corretiva_a_aplicar:
        formValues?.values?.medida_corretiva_a_aplicar ?? undefined,
      medida_corretiva_aplicada:
        formValues?.values?.medida_corretiva_aplicada ?? undefined,
      descricao: formValues?.values?.descricao ?? undefined,
    }
    try {
      let desconformidade: any
      if (selectedAgDesconformidadeResultId) {
        desconformidade = await updateAgDesconformidade(desconformidadeRequest)
      } else {
        desconformidade = await saveAgDesconformidade(desconformidadeRequest)
      }
      setTimeout(() => {
        postMessage({ type: 'sucessMsg' }, '*')
      }, 500)
      selectAgDesconformidadeResultId(desconformidade?.id as number)
      selectAgDesconformidadeResult(desconformidade as number)
      if (isSubmitAction) {
        postMessage(
          { type: 'form', value: 'pesquisarApoioGestaoPretensao' },
          '*'
        )
        selectAgDesconformidadeResultId(undefined)
        selectAgDesconformidadeResult(undefined)
      }
    } catch (error) {
      handleGenericError(error);
    }
  }

  const setOcorrencia = (ocorrencia: any, locationObj: any) => {
    let tecnico = "";
    if (ocorrencia.utilizador_id) {
      const user =tecnicos.find(x => x.id == ocorrencia?.utilizador_id);
      tecnico = user?.name;
      if (!tecnico) {
        tecnico = userLocal?.user?.name ?? undefined
      }
    } else {
      tecnico = userLocal?.user?.name ?? undefined
    }
    const seletedOcorrenciaReulst = {
      id: ocorrencia.id,
      // Idetificacao
      distrito: ocorrencia.distrito ?? undefined,
      concelho: ocorrencia.concelho ?? undefined,
      freguesia: ocorrencia.freguesia ?? undefined,
      capitania: ocorrencia.capitania ?? undefined,
      capitania_id: ocorrencia.capitania_id ?? undefined,
      praia: ocorrencia.praia ?? undefined,
      arh: ocorrencia.arh ?? undefined,
      nuts1: ocorrencia.nuts1 ?? undefined,
      nuts2: ocorrencia.nuts2 ?? undefined,
      nuts3: ocorrencia.nuts3 ?? undefined,
      massaAgua: ocorrencia.massa_agua ?? undefined,
      dataHoraRegistro: ocorrencia.data_registo
        ? new Date(`${ocorrencia.data_registo}`)
        : undefined,
      dataHoraOcorrencia: ocorrencia.data_ocorrencia
        ? new Date(`${ocorrencia.data_ocorrencia}`)
        : undefined,
      entidade: ocorrencia.entidade ?? undefined,
      observacao: ocorrencia.observacoes ?? undefined,
      tecnico: tecnico,
      detecaoOcorrencia: ocorrencia.tipo_detecao_id ?? undefined,
      // Detalhes
      instabiladeArribas: ocorrencia.tip_inst_arribas ?? undefined,
      movimentoMassa: ocorrencia.tip_mmv ?? undefined,
      tip_mmv_alcance_deposito: ocorrencia.tip_mmv_alcance_deposito ?? undefined,
      fendaTracao: ocorrencia.tip_fenda_visivel ?? undefined,
      erosaoRecuo: ocorrencia.tip_erosao_arribas ?? undefined,
      escarpamento: ocorrencia.tip_escarpamento_duna ?? undefined,
      tip_altura_maxima_escarpa: ocorrencia.tip_altura_maxima_escarpa ?? undefined,
      rebaixamento: ocorrencia.tip_rebaixamento_praia ?? undefined,
      tip_reb_praia_altura_max: ocorrencia.tip_reb_praia_altura_max ?? undefined,
      galgamento: ocorrencia.galgamento_costeiro ?? undefined,
      tip_galg_cost_alcance_max: ocorrencia.tip_galg_cost_alcance_max ?? undefined,
      protecaoDefesa:  ocorrencia.tip_dano_obra ?? undefined,
      estruturaAderente: ocorrencia.tip_dano_estrutura_aderente ?? undefined,
      esporao: ocorrencia.tip_dano_esporao ?? undefined,
      protecaoEstabilizacao:
        ocorrencia.tip_dano_estrutura_protecao ?? undefined,
      ref_localizacao: ocorrencia.ref_localizacao ?? undefined,
      pessoaEmRisco: ocorrencia.tipo_pessoas_risco_id ?? undefined,
      fruicaoUsoPublico: ocorrencia.conseq_danos_areas_construidas ?? undefined,
      danosEdificacoes: ocorrencia.conseq_danos_edificacoes ?? undefined,
      outrosDanos: ocorrencia.conseq_danos_outras ?? undefined,
      outrosDanosDescricao: ocorrencia.conseq_danos_outras_obs ?? undefined,
      sistemasNaturais: ocorrencia.tipo_danos_sistemas_naturais_id ?? undefined,
      descricaoDanosSistemasNaturais:
        ocorrencia.danos_sistemas_naturais_obs ?? undefined,
      naoSabe: ocorrencia.causas_nao_sabe ?? undefined,
      tempestade: ocorrencia.causas_tempestade ?? undefined,
      descricaoTempestade: ocorrencia.causas_tempestade_obs ?? undefined,
      sismos: ocorrencia.causas_sistemos ?? undefined,
      outras: ocorrencia.causas_outras ?? undefined,
      descricaoOutras: ocorrencia.causas_ouras_obs ?? undefined,
      medidasMetigacao: ocorrencia.tipo_medida_mitigacao_id ?? undefined,
      sinalizacao: ocorrencia.mitig_sinalizacao ?? undefined,
      delimitacao: ocorrencia.mitig_delimitacao ?? undefined,
      saneamento: ocorrencia.mitig_saneamento ?? undefined,
      reabilitacao: ocorrencia.mitig_reabilitacao ?? undefined,
      reforco: ocorrencia.mitig_reforco ?? undefined,
      // Anexos
      uploadFotografia: ocorrencia.docs_fotos ?? undefined,
      uploadTopografia: '',
      uploadOutro: ocorrencia.docs_outros ?? undefined,
      utilizador_id: ocorrencia.utilizador_id,
      oc_estado: ocEstados.find(x => x.id == ocorrencia.estado_id),
      indeterminado: ocorrencia?.data_indeterminada ?? undefined,
      filedoc_cod: ocorrencia?.filedoc_cod,
      oc_ocorrencia_apoio_id: ocorrencia?.oc_ocorrencia_apoio_id
    }
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
      { type: 'SiarlOcorrenciasCenter', value: [ocorrencia.id] },
      '*'
    )
    if (ocorrencia.oc_ocorrencia_apoio_id) {
      iframe.contentWindow?.postMessage(
        {
          type: 'SiarlOcorrenciasApoioSearch',
          value: [ocorrencia.oc_ocorrencia_apoio_id],
        },
        '*'
      )
    }

    selectOcorrenciaResultId(ocorrencia.id as number)
    selectOcorrenciaResult(seletedOcorrenciaReulst)
    setOcorrenciaSelected(ocorrencia)

    if (ocorrencia.coord_x && ocorrencia.coord_y) {
      setCoordenadasSelected(
        ocorrencia.coord_x + ', ' + ocorrencia.coord_y
      )
    } else {
      setCoordenadasSelected(undefined)
    }
    hideAll()
    setCollapseSidebarMenu(true)
    setShowLoader(false)
    setTimeout(() => {
      setShowRegistarOcorrencia(true);
      //postMessage({ type: "form", value: "registarOcorrencia" }, "*");
    }, 10);
    
  }

  const setEntidade = (entidade: any, locationObj: any) => {
    const userLocal = JSON.parse(
      localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}'
    )
    const entidadeResult = {

      id: entidade.id,
      nome: entidade.nome,
      tecnicoRegisto: userLocal?.user?.name,
      sigla: entidade.codigo,
      morada: entidade.morada,
      localidade: entidade.localidade,
      tipo_entidade_id: entidade.tipo_entidade_id,
      tipoEntidade: entidade.tipoEntidade,
      email: entidade.oc_focal_point_email,
      url: entidade.url,
      jurisdicao: entidade.oc_notify_email_new_issue,
      telefone: entidade.telefone,
      codigoPostal: entidade.codigo_postal,
      tipoAcesso: entidade.tipoAcesso,
      tipo_acesso_id: entidade.tipo_acesso_id,
      dataHoraRegisto: entidade.data_registo
        ? new Date(entidade.data_registo)
        : undefined,
      freguesia: locationObj?.freguesia ?? undefined,
      concelho: locationObj?.concelho ?? undefined,
      distrito: locationObj?.distrito ?? undefined,
      nuts1: locationObj?.nuts1 ?? undefined,
      nuts2: locationObj?.nuts2 ?? undefined,
      nuts3: locationObj?.nuts3 ?? undefined,
      lstFreguesiasJurisdicao: entidade.lstFreguesiasJurisdicao ?? undefined,
      lstConcelhosJurisdicao: entidade.lstConcelhosJurisdicao ?? undefined,
      lstDistritosJurisdicao: entidade.lstDistritosJurisdicao ?? undefined,
      lstMassaAguaJurisdicao : entidade?.lstMassaAguaJurisdicao ?? undefined,
      lstArhsJurisdicao: entidade?.lstArhsJurisdicao ?? undefined,
      lstCapitaniasJurisdicao: entidade?.lstCapitaniasJurisdicao ?? undefined,
      geom_imported: entidade?.geom_imported,
    }
    sessionStorage.setItem(
      'jurisdicaoEntidade',
      entidade.oc_notify_email_new_issue ?? ''
    )
    selectEntidadeResult(entidadeResult)
    setEntidadeSelected(entidadeResult)
    sessionStorage.setItem('entidadeId', entidade.id)
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlEntidadesCenter", value: [entidade.id] }, '*')

    if (entidade?.diEntidadeJurisdicoes && entidade?.diEntidadeJurisdicoes.length > 0) {
      const idToSend = [entidade?.diEntidadeJurisdicoes[0].id];
      const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.contentWindow?.postMessage({ type: "SiarlEntidadesApoioSearch", value: idToSend }, '*')
    }

    hideAll()
    setCollapseSidebarMenu(true)
    setShowLoader(false)
    setTimeout(() => {
      postMessage({ type: "form", value: "registarEntidade" }, "*");
    }, 30);

  }

  const handleMessageEvent = useCallback((event: MessageEvent) => {

    

    if (event.data.type == 'hideFloatingMenu') {
      setHideFloatingMenu(true)
      setShowBtnMapa(false);
    }
    if (event.data.type == 'showFloatingMenu') {
      setHideFloatingMenu(false)
      setShowBtnMapa(true);
      setCollapseSidebarMenu(true)
    }

    else if (event.data.type == "SiarlPoligonoEdited") {
      console.log(event.data.value);
      const obj = event.data.value
      if (!event?.data?.value?.id) {
        return;
      }
      // const keys = event.data.value.id.split('.');
      const point = event.data.value.name;
      const id = event?.data?.value?.id;
      let polygonString = ''
      const values: any[] = obj.flatCoordinates;
      values?.forEach((value, index) => {
        if (index % 2 == 0) {
          polygonString += value + ' '
        } else {
          polygonString += value + ','
        }
      })
      polygonString = polygonString.substring(0, polygonString.length - 1)
      if (point == OCORRENCIA_APOIO_POINT_NAME) {
        const dataToSend = {
          id: JSON.parse(id),
          flatCoordinatesGeomApoio: polygonString
        }
        setCoordenadasPoligono(polygonString);
         //  updateOcorrenciaApoio(dataToSend).then(result => {
        //    postMessage({ type: "sucessMsg" }, "*");
        // });
      }
      else if (point == OCORRENCIA_POINT_NAME) {
        const dataToSend = {
          Id: JSON.parse(id),
          FlatCoordinates: polygonString
        }
        setCoordenadasSelected(polygonString);
        // updateOcorrenciaGeom(dataToSend).then(result => {
        //   setCoordenadasSelected(polygonString);
        //   getOcorrencia(result.id).then(async (ocorrencia) => {
        //     if (!ocorrencia ) {
        //       postMessage({type: "infoMsg", value: { message: "Registo desativado" }}, "*");
        //       return;
        //     }

        //     if (isMobile) {
        //       ocultaFloatingMenu_e_Mapa_e_Mostra_Form();
        //     }

        //     let tecnico = "";
        //     if (ocorrencia.utilizador_id) {
        //       const user = await getUserById(ocorrencia?.utilizador_id);
        //       tecnico = user?.name;
        //     } else {
        //       tecnico = userLocal?.user?.name ?? undefined
        //     }
  
        //     selectOcorrenciaResultId(ocorrencia.id)


        //     const seletedOcorrenciaReulst = {
        //       id: ocorrencia.id,
        //       // Idetificacao
        //       distrito: ocorrencia.distrito ?? undefined,
        //       concelho: ocorrencia.concelho ?? undefined,
        //       freguesia: ocorrencia.freguesia ?? undefined,
        //       capitania: ocorrencia.capitania ?? undefined,
        //       capitania_id: ocorrencia.capitania_id ?? undefined,
        //       praia: ocorrencia.praia ?? undefined,
        //       arh: ocorrencia.arh ?? undefined,
        //       nuts1: ocorrencia.nuts1 ?? undefined,
        //       nuts2: ocorrencia.nuts2 ?? undefined,
        //       nuts3: ocorrencia.nuts3 ?? undefined,
        //       dataHoraRegistro: ocorrencia.data_registo ? moment.utc(ocorrencia.data_registo).tz(CURRENT_TIMEZONE) : undefined,
        //       dataHoraOcorrencia: ocorrencia.data_ocorrencia ? moment.utc(ocorrencia.data_ocorrencia).tz(CURRENT_TIMEZONE) : undefined,
        //       entidade: ocorrencia.entidade ?? undefined,
        //       observacao: ocorrencia.observacoes ?? undefined,
        //       tecnico: tecnico,
        //       detecaoOcorrencia: ocorrencia.tipo_detecao_id ?? undefined,
        //       // Detalhes
        //       instabiladeArribas: ocorrencia.tip_inst_arribas ?? undefined,
        //       movimentoMassa: ocorrencia.tip_mmv ?? undefined,
        //       tip_mmv_alcance_deposito: ocorrencia.tip_mmv_alcance_deposito ?? undefined,
        //       fendaTracao: ocorrencia.tip_fenda_visivel ?? undefined,
        //       erosaoRecuo: ocorrencia.tip_erosao_arribas ?? undefined,
        //       escarpamento: ocorrencia.tip_escarpamento_duna ?? undefined,
        //       tip_altura_maxima_escarpa: ocorrencia.tip_altura_maxima_escarpa ?? undefined,
        //       rebaixamento: ocorrencia.tip_rebaixamento_praia ?? undefined,
        //       tip_reb_praia_altura_max: ocorrencia.tip_reb_praia_altura_max ?? undefined,
        //       galgamento: ocorrencia.galgamento_costeiro ?? undefined,
        //       tip_galg_cost_alcance_max: ocorrencia.tip_galg_cost_alcance_max ?? undefined,
        //       protecaoDefesa:  ocorrencia.tip_dano_obra ?? undefined,
        //       estruturaAderente: ocorrencia.tip_dano_estrutura_aderente ?? undefined,
        //       esporao: ocorrencia.tip_dano_esporao ?? undefined,
        //       protecaoEstabilizacao:
        //         ocorrencia.tip_dano_estrutura_protecao ?? undefined,
        //       ref_localizacao: ocorrencia.ref_localizacao ?? undefined,
        //       pessoaEmRisco: ocorrencia.tipo_pessoas_risco_id ?? undefined,
        //       fruicaoUsoPublico: ocorrencia.conseq_danos_areas_construidas ?? undefined,
        //       danosEdificacoes: ocorrencia.conseq_danos_edificacoes ?? undefined,
        //       outrosDanos: ocorrencia.conseq_danos_outras ?? undefined,
        //       outrosDanosDescricao: ocorrencia.conseq_danos_outras_obs ?? undefined,
        //       sistemasNaturais: ocorrencia.tipo_danos_sistemas_naturais_id ?? undefined,
        //       descricaoDanosSistemasNaturais:
        //         ocorrencia.danos_sistemas_naturais_obs ?? undefined,
        //       naoSabe: ocorrencia.causas_nao_sabe ?? undefined,
        //       tempestade: ocorrencia.causas_tempestade ?? undefined,
        //       descricaoTempestade: ocorrencia.causas_tempestade_obs ?? undefined,
        //       sismos: ocorrencia.causas_sistemos ?? undefined,
        //       outras: ocorrencia.causas_outras ?? undefined,
        //       descricaoOutras: ocorrencia.causas_ouras_obs ?? undefined,
        //       medidasMetigacao: ocorrencia.tipo_medida_mitigacao_id ?? undefined,
        //       sinalizacao: ocorrencia.mitig_sinalizacao ?? undefined,
        //       delimitacao: ocorrencia.mitig_delimitacao ?? undefined,
        //       saneamento: ocorrencia.mitig_saneamento ?? undefined,
        //       reabilitacao: ocorrencia.mitig_reabilitacao ?? undefined,
        //       reforco: ocorrencia.mitig_reforco ?? undefined,
        //       // Anexos
        //       uploadFotografia: ocorrencia.docs_fotos ?? undefined,
        //       uploadTopografia: '',
        //       uploadOutro: ocorrencia.docs_outros ?? undefined,
        //       utilizador_id: ocorrencia.utilizador_id,
        //       oc_estado: ocEstados.find(x => x.id == ocorrencia.estado_id),
        //       indeterminado: ocorrencia?.data_indeterminada ?? undefined,
        //       filedoc_cod: ocorrencia?.filedoc_cod
        //     }


        //     const ocorrenciaObject = {
        //       ...seletedOcorrenciaReulst,
        //       distrito: ocorrencia.distrito ?? undefined,
        //       concelho: ocorrencia.concelho ?? undefined,
        //       freguesia: ocorrencia.freguesia ?? undefined,
        //       capitania: ocorrencia.capitania_id ?? undefined,
        //       praia: ocorrencia.praia ?? undefined,
        //       arh: ocorrencia.arh ?? undefined,
        //       nuts1: ocorrencia.nuts1 ?? undefined,
        //       nuts2: ocorrencia.nuts2 ?? undefined,
        //       nuts3: ocorrencia.nuts3 ?? undefined,
        //     }
        //     selectOcorrenciaResult(ocorrenciaObject);
        //     setOcorrenciaSelected(ocorrenciaObject);

        //     sessionStorage.setItem('ocGeomEdited', 'true')
  
        //     SessionStorageService.setItem("novaOcorrencia", 'false');
        //     setShowLoader(false)
        //     hideAll();
        //     setTimeout(() => {
        //       postMessage({ type: "form", value: "registarOcorrencia" }, "*");
        //     }, 10);
  
        //     const results = JSON.parse(
        //       SessionStorageService.getItem('resultadoOcorrenciasPesquisa') ?? ''
        //     )
        
        //     if (results) {
        //       const iframe: any = document.getElementById('iframeGeoPortal')
        //       const ids: number[] = []
        //       const idsApoio: number[] = []
        //       results.forEach((element: any) => {
        //         ids.push(element.id)
        //       })
        //       iframe.contentWindow?.postMessage(
        //         { type: 'SiarlOcorrenciasSearchNoCenter', value: ids },
        //         '*'
        //       )
        //     }
    
        //     postMessage({type: "sucessMsg"}, "*");
        //   });
        // });
      }
      else if (point == ENTIDADE_JURISDICAO_POINT_NAME) {
        const dataToSend = {
          id: JSON.parse(id),
          flatCoordinatesGeom: polygonString
        }
        updateEntidadeJurisdicao(dataToSend).then(result => {
          postMessage({type: "sucessMsg"}, "*");
        })
      }
      else if (point == ENTIDADE_POINT_NAME) {
        const dataToSend = {
          id : JSON.parse(id),
          flatCoordinates: polygonString
        }
        setCoordenadasSelected(polygonString);
        // updateGeomEntidade(dataToSend).then(result => {
        //   getEntidade(result.id).then(async (entidade) => {
        //     if (!entidade ) {
        //       postMessage({type: "infoMsg", value: { message: "Registo desativado" }}, "*");
        //       return;
        //     }

        //     let tecnico = "";
        //     const userLocal = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? "{}");
        //     if (entidade.utilizador_id) {
        //       const user = await getUserById(entidade?.utilizador_id);
        //       tecnico = user?.name;
        //       if (!tecnico) {
        //         tecnico = userLocal?.user?.name ?? undefined
        //       }
        //     } else {
        //       tecnico = userLocal?.user?.name ?? undefined
        //     }
  
        //     const locationObj: any = await fillLocation(entidade);
        //     const entidadeResult = {
        //       ...entidade,
        //       id: entidade.id,
        //       freguesia: locationObj?.freguesia ?? undefined,
        //       concelho: locationObj?.concelho ?? undefined,
        //       distrito: locationObj?.distrito ?? undefined,
        //       nuts1: locationObj?.nuts1 ?? undefined,
        //       nuts2: locationObj?.nuts2 ?? undefined,
        //       nuts3: locationObj?.nuts3 ?? undefined,
        //     }
        //     selectEntidadeResult(entidadeResult);
        //     setEntidadeSelected(entidadeResult);

        //     sessionStorage.setItem('diGeomEdited', 'true')
  
        //     setShowLoader(false)
        //     hideAll();
        //     setTimeout(() => {
        //       postMessage({ type: "form", value: "registarEntidade" }, "*");
        //     }, 10);
  
        //     const results = JSON.parse(
        //       SessionStorageService.getItem('resultadoEntidadePesquisa') ?? ''
        //     )
        
        //     const idsJurisdicao: number[] = [];
        //     const ids: number[] = [];
        //     results.forEach((element: any) => {
        //       ids.push(element.id);
        //       if (element?.diEntidadeJurisdicoes && element?.diEntidadeJurisdicoes.length > 0) {
        //         idsJurisdicao.push(element?.diEntidadeJurisdicoes[0].id);
        //       }
        //     });
        //     const iframe: any = document.getElementById('iframeGeoPortal')
        //     const criterioEntidades = JSON.parse(SessionStorageService.getItem("criterioEntidades"));
        //     if (criterioEntidades == CRITERIO_SEDE) {
        //       SessionStorageService.setItem("criterioEntidades", JSON.stringify(CRITERIO_SEDE));
        //       iframe.contentWindow?.postMessage(
        //         { type: 'SiarlEntidadesSearch', value: ids, nocenter: true },
        //         '*'
        //       )
        //     }
        //     postMessage({type: "sucessMsg"}, "*");
        //   });
        // });
      }   else if (point == US_TITULO_POINT_NAME &&
        sessionStorage.getItem("Modulo") == USO_DO_SOLO_MODULE_NAME
      ) {
        const dataToSend = {
          id : JSON.parse(id),
          flatCoordinates: polygonString
        }
        // if (getUsoSoloSelected()?.id == id) {
        //   setShowLoader(false);
        //   return;
        // }
        setCoordenadasSelected(polygonString);
        // updateUsTituloGeom(dataToSend).then(result => {
        //   getUsoDoSolo(result.id).then((tituloUsoDoSolo) => {
        //     if (!tituloUsoDoSolo ) {
        //       postMessage({type: "infoMsg", value: { message: "Registo desativado" }}, "*");
        //       return;
        //     }
  
        //     selectUsoDoSoloResultId(tituloUsoDoSolo.id)
        //     const usosSoloObject = {
        //       ...tituloUsoDoSolo,
        //       nuts1: tituloUsoDoSolo?.nuts1??undefined,
        //       nuts2: tituloUsoDoSolo?.nuts2??undefined,
        //       nuts3: tituloUsoDoSolo?.nuts3??undefined,
        //       distrito: tituloUsoDoSolo?.distrito??undefined,
        //       concelho: tituloUsoDoSolo?.concelho??undefined,
        //       freguesia: tituloUsoDoSolo?.freguesia??undefined,
        //       arh: tituloUsoDoSolo?.arh??undefined,
        //       capitania: tituloUsoDoSolo?.capitania??undefined,
        //       praia: tituloUsoDoSolo?.praia??undefined,
        //     }
        //     selectUsoDoSoloResult(usosSoloObject);
        //     setusoSoloSelected(usosSoloObject);

        //     sessionStorage.setItem('usGeomEdited', 'true')
  
        //     SessionStorageService.setItem("novoUsTitulo", 'false');
        //     setShowLoader(false)
        //     hideAll();
        //     setTimeout(() => {
        //       postMessage({ type: "form", value: "registarUsoDoSolo" }, "*");
        //     }, 10);
  
        //     postMessage({type: "sucessMsg"}, "*");
        //   });
        // });
      }
      else if (point == MODELO_POINT_NAME) {
        const dataToSend = {
          id: JSON.parse(id),
          flatCoordinates: polygonString
        }
        setCoordenadasPoligono(polygonString);
        // updateModelo(dataToSend).then(result => {
        //   postMessage({type: "sucessMsg"}, "*");
        //   postMessage({ type: 'form', value: 'registarUsoDoSolo' }, '*')
        // });
      }
      else if (point == DOCUMENTO_POINT_NAME) {
        const dataToSend = {
          Id : JSON.parse(id),
          FlatCoordinates: polygonString
        }
        updateDocumentoGeom(dataToSend).then(result => {
          postMessage({type: "sucessMsg"}, "*");
        });
      }
      else if (point == AG_PRETENSAO_POINT_NAME) {
        const dataToSend = {
          id: JSON.parse(id),
          flatCoordinates: polygonString
        }
        setCoordenadasPoligono(polygonString);
        // updatePretensao(dataToSend).then(result => {
        //   postMessage({type: "sucessMsg"}, "*");
        // });
      } 
      else if (point == AG_DESCONFORMIDADE_POINT_NAME) {
        const dataToSend = {
          id: JSON.parse(id),
          flatCoordinates: polygonString
        }
        updateAgDesconformidade(dataToSend).then(result => {
          postMessage({type: "sucessMsg"}, "*");
        });
      }
      else if(point == DH_PATRIMONIO_POINT_NAME) {
        const dataToSend = {
          id : JSON.parse(id),
          flatCoordinates: polygonString
        }
        updatePatrimonio(dataToSend).then(result => {
          postMessage({type: "sucessMsg"}, "*");
        })
      }
      else if (point == DH_PROCESSO_POINT_NAME) {
        const dataToSend = {
          id: JSON.parse(id),
          flatCoordinates: polygonString
        }
        updateDhProcesso(dataToSend).then(result => {
          postMessage({type: "sucessMsg"}, "*");
        })
      }
      else if (point == DH_DEMARCACAO_POINT_NAME) {
        const dataToSend = {
          id: JSON.parse(id),
          flatCoordinatesLinha: polygonString
        }
        updateDemarcacao(dataToSend).then(result => {
          postMessage({type: "sucessMsg"}, "*");
        })
      }
      else if (point == INT_INTERVENCAO_POINT_NAME) {
        const dataToSend = {
          id: JSON.parse(id),
          flatCoordinates: polygonString
        }
        updateIntervencao(dataToSend).then(result => {
          postMessage({type: "sucessMsg"}, "*");
        })
      }
      else if (point == EA_POINT_NAME) {
        const dataToSend = {
          id: JSON.parse(id),
          flatCoordinates: polygonString
        }
        updateAnalise(dataToSend).then(result => {
          postMessage({type: "sucessMsg"}, "*");
        })
      }
      
    }

    if (event.data.type == 'SiarlLayerClicked') {
      console.log(event.data.value)
      setShowLoader(true)
      if (event.data.value?.name.indexOf(OCORRENCIA_POINT_NAME) != -1 &&
      event.data.value?.name.indexOf(OCORRENCIA_APOIO_POINT_NAME) == -1 &&
    sessionStorage.getItem("Modulo") == OCORRENCIAS_MODULE_NAME) {
        if (!hasPermission(PERMISSION_CONSULTAR_OCORRENCIA) &&
      !hasPermission(PERMISSION_OCORRENCIA_PERFIL_COMPLETO_LEITURA) &&
      !hasPermission(PERMISSION_OCORRENCIA_PERFIL_TOTAL) &&
      !hasPermission(PERMISSION_OCORRENCIA_PERFIL_COMPLETO_EDICAO) &&
      !hasPermission(PERMISSION_OCORRENCIA_PERFIL_INTERMEDIO_EDICAO)
    ) {
          setShowLoader(false);
          return false;

        }
        if (getOcorrenciaSelected()?.id == event.data.value?.id) {
          setShowLoader(false);
          return;
        }
        SessionStorageService.setItem("novaOcorrencia", 'false');
        SessionStorageService.setItem("ocorrenciaAntiga", 'true');
        const id = event.data.value?.id
        const userLocal = JSON.parse(
          localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}'
        )
        getOcorrencia(id).then((ocorrencia) => {
          fillLocation(ocorrencia).then(locationObj => {
            setOcorrencia(ocorrencia, locationObj);
          })
          const iframe: any = document.getElementById('iframeGeoPortal')
          
          iframe.contentWindow?.postMessage(
            { type: 'SiarlOcorrenciasCenter', value: [ocorrencia.id] },
            '*'
          )
        })
      
      } else if (event.data.value?.name.indexOf(ENTIDADE_POINT_NAME) != -1 &&
    event.data.value?.name.indexOf(ENTIDADE_JURISDICAO_POINT_NAME) == -1 &&
      sessionStorage.getItem("Modulo") == ENTIDADES_MODULE_NAME
  ) {

        const id = event.data.value?.id;
        if (getEntidadeSelected()?.id && getEntidadeSelected()?.id == id) {
          setShowLoader(false);
          return;
        }
        getEntidade(event.data.value?.id).then((entidade) => {
          fillLocation(entidade).then(locationObj => {
            setEntidade(entidade, locationObj);
          })
          const iframe: any = document.getElementById('iframeGeoPortal')
          //iframe.contentWindow?.postMessage({ type: "SiarlEntidadesSearch", value: [entidade.id] }, '*')
          iframe.contentWindow?.postMessage({ type: "SiarlEntidadesCenter", value: [entidade.id] }, '*')
      
        })
        console.log(event.data.value)
      } else if (event.data.value?.name.indexOf(DOCUMENTO_POINT_NAME) != -1) {

        const id = event.data.value?.id

        if (getDocumentoSelected()?.id && getDocumentoSelected()?.id == id) {
          setShowLoader(false);
          return;
        }

        getDocumento(id).then((documentos) => {
          console.log(documentos)
          const selectedDocumentosResults = {
            // Idetificacao
            id: documentos.id,
            Iddoc: documentos.filedoc_cod || undefined,
            Idfiledoc: documentos.filedoc_cod || undefined,
            idioma: documentos.idioma || undefined,
            dataHoraRegistro: documentos.data_publicacao || undefined,
            entidade: documentos.entidade || undefined,
            tipoAcesso: documentos.tipoAcesso || undefined,
            recurso: documentos.tipoRecurso || undefined,
            tipoRecurso: documentos.tipoRecurso || undefined,
            titulo: documentos.titulo || undefined,
            url: documentos.url || undefined,
            path: documentos.path || undefined,
            observacao: documentos.observacoes || undefined,
            estadoDoRegisto: documentos.estado_id || undefined,
            geom_imported: documentos.geom_imported ?? undefined,
          }
          selectDocumentosResultId(documentos.id as number)
          selectDocumentoResult(selectedDocumentosResults)
          setDocumentoSelected(selectedDocumentosResults)


          const idToSend = [documentos.id]
          const iframe: any = document.getElementById('iframeGeoPortal')
          iframe.contentWindow?.postMessage(
            { type: 'SiarlDocumentosHighlight', value: idToSend },
            '*'
          )

          hideAll()
          setCollapseSidebarMenu(true)
          setShowLoader(false)
          setTimeout(() => {
            postMessage({ type: "form", value: "registarDocumentos" }, "*");
          }, 30);

        })
      } else if (event.data.value?.name.indexOf(MODELO_POINT_NAME) != -1 &&
    sessionStorage.getItem("Modulo") == MODELOS_MODULE_NAME) {
        const id = event.data.value?.id
        if (getModeloSelected()?.id && getModeloSelected()?.id == id) {
          setShowLoader(false);
          return;
        }
        getModelo(id).then((modelo) => {
          selectModeloResult(modelo)
          selectModeloResultId(modelo?.id)
          setModeloSelected(modelo)
          const iframe: any = document.getElementById('iframeGeoPortal')
          iframe.contentWindow?.postMessage(
            { type: 'SiarlModelosSearch', value: [modelo?.id] },
            '*'
          )
          SessionStorageService.setItem("novoModelo", 'false');
          
          hideAll()
          setCollapseSidebarMenu(true)
          setShowLoader(false)
          setTimeout(() => {
            postMessage({ type: "form", value: "registarModelos" }, "*");
          }, 30);

          iframe.contentWindow?.postMessage({ type: "SiarlModelosSearch", value: modelo?.id }, '*')
          iframe.contentWindow?.postMessage({ type: "SiarlModelosCenter", value: modelo?.id }, '*')
        })
      } else if (
        event.data.value?.name.indexOf(AG_PRETENSAO_POINT_NAME) != -1 &&
        sessionStorage.getItem("Modulo") == APOIO_GESTAO_MODULE_NAME
      ) {
        const id = event.data.value?.id

        if (getPretensaoSelected()?.id && getPretensaoSelected()?.id == id) {
          setShowLoader(false);
          return;
        }

        getPretensao(id).then((pretensao) => {
          console.log(pretensao)
          const objToShow = {
            ...pretensao,
            decisao: agDecisoes?.filter(
              (x: any) => x.id == pretensao.ag_decisao_id
            )[0],
            id: pretensao.id,
            file_doc_numero: pretensao.file_doc_numero,
            numeroProcesso: pretensao.numero_do_processo,
            nifRequerente: pretensao.requerente_nif,
            nomeRequerente: pretensao.requerente_nome,
            dataHoraRegisto: new Date(pretensao.data_registo),
            assunto: pretensao.assunto,
            estado: agEstados?.filter(
              (x: any) => x.id == pretensao.ag_estado_id
            )[0],
            tipoPretensao: agTipoPretensoes?.filter(
              (x: any) => x.id == pretensao.ag_tipo_pretensao_id
            )[0],
            dataEntrada: new Date(pretensao.data_entrada),
            data_decisao: new Date(pretensao.data_decisao),
            local: pretensao.local,
            obs: pretensao.observacoes,
          }

          SessionStorageService.setItem("novaPretensao", 'false');

          selectAgPretensaoResult(objToShow)
          setPretensaoSelected(pretensao)
          selectAgPretensaoResultId(pretensao.id)
          const iframe: any = document.getElementById('iframeGeoPortal')
          iframe.contentWindow?.postMessage(
            { type: 'SiarlPretensaoSearch', value: [pretensao.id] },
            '*'
          )
          iframe.contentWindow?.postMessage(
            { type: 'SiarlPretensaoCenter', value: [pretensao.id] },
            '*'
          )
          hideAll()
          setCollapseSidebarMenu(true)
          setShowLoader(false)
          setTimeout(() => {
            postMessage({ type: "form", value: "registarApoioGestaoPretensao" }, "*");
          }, 30);

        })
      }
      else if (
        event.data.value?.name.indexOf(US_TITULOS_POINT_NAME) != -1 &&
        sessionStorage.getItem("Modulo") == USO_DO_SOLO_MODULE_NAME
      ) {
        const id = event.data.value?.id

        if (getUsoSoloSelected()?.id && getUsoSoloSelected()?.id == id) {
          setShowLoader(false);
          return;
        }

        getUsoDoSolo(event.data.value?.id).then((tituloUsoDoSolo) => {
          if (!tituloUsoDoSolo ) {
            postMessage({type: "infoMsg", value: { message: "Registo desativado" }}, "*");
            return;
          }

          selectUsoDoSoloResultId(tituloUsoDoSolo.id)
          const usosSoloObject = {
            ...tituloUsoDoSolo,
            titulo: tituloUsoDoSolo?.titulo,
            id: tituloUsoDoSolo?.id,
            idProcesso: tituloUsoDoSolo?.id_processo,
            estadoTitulo: tituloUsoDoSolo?.estadoDoTitulo,
            dataVigor: tituloUsoDoSolo?.data_entrada_vigor ? new Date(tituloUsoDoSolo?.data_entrada_vigor) : undefined,
            dataExpiracao: tituloUsoDoSolo?.data_expiracao ? new Date(tituloUsoDoSolo?.data_expiracao) : undefined,
            dataAlteracao: tituloUsoDoSolo?.data_alteracao_licenciamento ? new Date(tituloUsoDoSolo?.data_alteracao_licenciamento) : undefined,
            designacaoTitulo: tituloUsoDoSolo?.designacao,
            nuts1: tituloUsoDoSolo?.nuts1 ?? undefined,
            nuts2: tituloUsoDoSolo?.nuts2 ?? undefined,
            nuts3: tituloUsoDoSolo?.nuts3 ?? undefined,
            distrito: tituloUsoDoSolo?.distrito ?? undefined,
            concelho: tituloUsoDoSolo?.concelho ?? undefined,
            freguesia: tituloUsoDoSolo?.freguesia ?? undefined,
            arh: tituloUsoDoSolo?.arh ?? undefined,
            capitania: tituloUsoDoSolo?.capitania ?? undefined,
            praia: tituloUsoDoSolo?.praia ?? undefined,
            local: tituloUsoDoSolo?.local,
            tecnicoRegisto: tituloUsoDoSolo?.utilizador_id,
            dataHoraRegisto: tituloUsoDoSolo?.data_registo ? new Date(tituloUsoDoSolo?.data_registo) : undefined,
            designacaoIdentificacao: tituloUsoDoSolo?.designacao,
            tipoDeProcesso: tituloUsoDoSolo?.tipo_processo,
            codProcesso: tituloUsoDoSolo?.codigo_processo,
            tipoUtilizacao: tituloUsoDoSolo?.tipo_utilizacao_id ? TiposUtilizacao.find(x => x.id == tituloUsoDoSolo?.tipo_utilizacao_id) : undefined,
            codUtilizacao: tituloUsoDoSolo?.codigo_utilizacao,
            categoria: tituloUsoDoSolo?.categoria,
            nifRequerente: tituloUsoDoSolo?.nif_requerente,
            nomeRequerente: tituloUsoDoSolo?.nome_requerente,
            obs: tituloUsoDoSolo?.observacoes,
            tipo_registo: tituloUsoDoSolo?.tipo_registo ?? undefined,
            filedoc_cod: tituloUsoDoSolo?.filedoc_cod,
            tipoTitulo: tituloUsoDoSolo?.tipo_titulo_id ? tipoTitulos.find (x => x.id == tituloUsoDoSolo?.tipo_titulo_id) : undefined
          }
          selectUsoDoSoloResult(usosSoloObject);
          setusoSoloSelected(usosSoloObject);

          SessionStorageService.setItem("novoUsTitulo", 'false');
          setShowLoader(false)
          setTimeout(() => {
            postMessage({ type: "form", value: "registarUsoDoSolo" }, "*");
          }, 10);

          const iframe: any = document.getElementById('iframeGeoPortal')

          //iframe.contentWindow?.postMessage({ type: "SiarlUsTituloSearch", value: [tituloUsoDoSolo?.id] }, '*')
          iframe.contentWindow?.postMessage({ type: "SiarlUsTituloCenter", value: [tituloUsoDoSolo?.id] }, '*')
          
        });
      }
      else if (
        event.data.value?.name.indexOf(AG_DESCONFORMIDADE_POINT_NAME) != -1 &&
        sessionStorage.getItem("Modulo") == APOIO_GESTAO_MODULE_NAME
      ) {
        const id = event.data.value?.id

        if (getDesconformidadeSelected()?.id && getDesconformidadeSelected()?.id == id) {
          setShowLoader(false);
          return;
        }

        getDesconformidade(id).then((desconformidade) => {
          selectDesconformidadeResultId(desconformidade.id)
          selectDesconformidadeResult({
            ...desconformidade,
          })
          setDesconformidadeSelected(desconformidade)

          SessionStorageService.setItem("novaDesconformidade", 'false');
          SessionStorageService.setItem("NovoRegistoDesconformidade", 'false');
          

          const iframe: any = document.getElementById('iframeGeoPortal')
          iframe.contentWindow?.postMessage(
            { type: 'SiarlDesconformidadeSearch', value: [desconformidade.id] },
            '*'
          )
          iframe.contentWindow?.postMessage(
            { type: 'SiarlDesconformidadeCenter', value: [desconformidade.id] },
            '*'
          )

          hideAll()
          setCollapseSidebarMenu(true)
          setShowLoader(false)

          setTimeout(() => {
            postMessage({ type: "form", value: "registarApoioGestaoDesconformidade" }, "*");
          }, 30)

        })
      } else if (event.data.value?.name.indexOf(DH_PROCESSO_POINT_NAME) != -1 &&
      sessionStorage.getItem("Modulo") == DOMINIO_HIDRICO_MODULE_NAME
    ) {
        const id = event.data.value?.id

        if (getProcessoSelected()?.id && getProcessoSelected()?.id == id) {
          setShowLoader(false);
          return;
        }

        getProcesso(id).then((processo) => {
          const seletedProcessoReulst = {
            ...processo,
            id_tipo_processo: processo.dhTipoProcesso ?? undefined,
            id_localizacao_processo:
              processo.dhLocalizacaoProcesso ?? undefined,
            id_fase_pr: processo.dhFaseProcedimentoDelimitacao ?? undefined,
            id_tipo_va: processo.dhValidacaoProva ?? undefined,
            id_tipo_pa: processo.dhTipoParcela ?? undefined,
            id_tipo_se: processo.dhTipoSentenca ?? undefined,
            id_tipo_ac: processo.dhTipoDecisaoFinal ?? undefined,
            id_tipo_es: processo.dhEstadoProcesso ?? undefined,
            id_tipo_pe: processo.dhTipoPendencia ?? undefined,
            id_tipo_no: processo.dhTipoNotificacao ?? undefined,
            id_situ_no: processo.dhTipoSituacaoNotificacao ?? undefined,
            id_cond_pr: processo.dhCondicionantesProcesso ?? undefined,
            id_tipo_aj: processo.dhTipoAcaoJudicial ?? undefined,
            id_tipo_de: processo.dhTipoDesafetacao ?? undefined,
            id_tipo_aq: processo.dhTipoAquisicao ?? undefined,
            id_tipo_in: processo.dhTipoIniciativa ?? undefined,
            data_requer: processo.data_requer ? new Date(processo.data_requer) : undefined,
            dhAutos: processo.dhAutos ?? undefined,
            dhAutosVertices: processo.dhAutosVertices ?? undefined
          }

          selectProcessoResultId(processo.id as number)
          selectProcessoResult(seletedProcessoReulst)
          setProcessoSelected(seletedProcessoReulst)

          const iframe: any = document.getElementById('iframeGeoPortal')
          iframe.contentWindow?.postMessage(
            { type: 'SiarlProcessoSearch', value: [id] },
            '*'
          )
          iframe.contentWindow?.postMessage(
            { type: 'SiarlProcessoCenter', value: [id] },
            '*'
        )

          if (!!processo.dhAutos && processo.dhAutos.length > 0) {
            const idToSendAutos = [processo?.dhAutos[0].id];
            const iframe: any = document.getElementById('iframeGeoPortal')
            iframe.contentWindow?.postMessage({ type: "SiarlDhAutosSearch", value: idToSendAutos }, '*')
          }
          if (!!processo.dhAutosVertices && processo.dhAutosVertices.length > 0) {
            const idToSendAutosVertices = [processo?.dhAutosVertices[0].id];
            const iframe: any = document.getElementById('iframeGeoPortal')
            iframe.contentWindow?.postMessage({ type: "SiarlDhAutosVerticesSearch", value: idToSendAutosVertices }, '*')
          }

          hideAll();
          setCollapseSidebarMenu(true)
          setShowLoader(false)
          setTimeout(() => {
            postMessage({ type: "form", value: "registarProcesso" }, "*");
          }, 30)


        })


      } else if (event.data.value?.name.indexOf(DH_PATRIMONIO_POINT_NAME) != -1 &&
    sessionStorage.getItem("Modulo") == DOMINIO_HIDRICO_MODULE_NAME) {
        const id = event.data.value?.id

        if (getPatrimonioSelected()?.id && getPatrimonioSelected()?.id == id) {
          setShowLoader(false);
          return;
        }

        getPatrimonio(id).then((patrimonio) => {
          const seletedPatrimonioReulst = {
            ...patrimonio,
            id_tipo_patrimonio: patrimonio.dhTipoPatrimonio ?? undefined,
            id_localizacao_patrimonio:
              patrimonio.dhLocalizacaoPatrimonio ?? undefined,
            id_fase_pr: patrimonio.dhFaseProcedimentoDelimitacao ?? undefined,
            id_tipo_va: patrimonio.dhValidacaoProva ?? undefined,
            id_tipo_pa: patrimonio.dhTipoParcela ?? undefined,
            id_tipo_se: patrimonio.dhTipoSentenca ?? undefined,
            id_tipo_ac: patrimonio.dhTipoDecisaoFinal ?? undefined,
            id_tipo_es: patrimonio.dhEstadoPatrimonio ?? undefined,
            id_tipo_pe: patrimonio.dhTipoPendencia ?? undefined,
            id_tipo_no: patrimonio.dhTipoNotificacao ?? undefined,
            id_situ_no: patrimonio.dhTipoSituacaoNotificacao ?? undefined,
            id_cond_pr: patrimonio.dhCondicionantesPatrimonio ?? undefined,
            id_tipo_aj: patrimonio.dhTipoAcaoJudicial ?? undefined,
            id_tipo_de: patrimonio.dhTipoDesafetacao ?? undefined,
            id_tipo_aq: patrimonio.dhTipoAquisicao ?? undefined,
            id_tipo_in: patrimonio.dhTipoIniciativa ?? undefined,
            data_requer: patrimonio.data_requer
              ? new Date(patrimonio.data_requer)
              : undefined,
          }

          selectPatrimonioResultId(patrimonio.id as number)
          selectPatrimonioResult(seletedPatrimonioReulst)
          setPatrimonioSelected(seletedPatrimonioReulst)

          const iframe: any = document.getElementById('iframeGeoPortal')
          iframe.contentWindow?.postMessage(
            { type: 'SiarlPatrimonioSearch', value: [id] },
            '*'
          )
          // iframe.contentWindow?.postMessage(
          //   { type: 'SiarlPatrimonioCenter', value: [id] },
          //   '*'
          //   )
          hideAll()
          setCollapseSidebarMenu(true)
          setShowLoader(false);
          setTimeout(() => {
            postMessage({ type: "form", value: "registarPatrimonio" }, "*");
          }, 30)

        });

      } else if (event.data.value?.name.indexOf(DH_DEMARCACAO_POINT_NAME) != -1 &&
    sessionStorage.getItem("Modulo") == DOMINIO_HIDRICO_MODULE_NAME) {

        const id = event.data.value?.id;

        if (getDemarcacaoSelected()?.id && getDemarcacaoSelected()?.id == id) {
          setShowLoader(false);
          return;
        }

        getDemarcacao(id).then(demarcacao => {
          const seletedDemarcacaoReulst = {
            ...demarcacao,
            id_tipo_demarcacao: demarcacao.dhTipoDemarcacao ?? undefined,
            id_localizacao_demarcacao:
              demarcacao.dhLocalizacaoDemarcacao ?? undefined,
            id_fase_pr: demarcacao.dhFaseProcedimentoDelimitacao ?? undefined,
            id_tipo_va: demarcacao.dhValidacaoProva ?? undefined,
            id_tipo_pa: demarcacao.dhTipoParcela ?? undefined,
            id_tipo_se: demarcacao.dhTipoSentenca ?? undefined,
            id_tipo_ac: demarcacao.dhTipoDecisaoFinal ?? undefined,
            id_tipo_es: demarcacao.dhEstadoDemarcacao ?? undefined,
            id_tipo_pe: demarcacao.dhTipoPendencia ?? undefined,
            id_tipo_no: demarcacao.dhTipoNotificacao ?? undefined,
            id_situ_no: demarcacao.dhTipoSituacaoNotificacao ?? undefined,
            id_cond_pr: demarcacao.dhCondicionantesDemarcacao ?? undefined,
            id_tipo_aj: demarcacao.dhTipoAcaoJudicial ?? undefined,
            id_tipo_de: demarcacao.dhTipoDesafetacao ?? undefined,
            id_tipo_aq: demarcacao.dhTipoAquisicao ?? undefined,
            id_tipo_in: demarcacao.dhTipoIniciativa ?? undefined,
            data_requer: demarcacao.data_requer
              ? new Date(demarcacao.data_requer)
              : undefined,
          }

          selectDemarcacaoResultId(demarcacao.id as number)
          selectDemarcacaoResult(seletedDemarcacaoReulst)
          setDemarcacaoSelected(seletedDemarcacaoReulst)

          const iframe: any = document.getElementById('iframeGeoPortal')
          iframe.contentWindow?.postMessage(
            { type: 'SiarlDemarcacaoSearch', value: [demarcacao.id] },
            '*'
          )
          iframe.contentWindow?.postMessage(
            { type: 'SiarlDemarcacaoCenter', value: [demarcacao.id] },
            '*'
            )

          hideAll()
          setCollapseSidebarMenu(true)
          setTimeout(() => {
            setShowLoader(false)
            setShowRegistarDemarcacao(true)
          }, 100)
        })
      } else if (
        event.data.value?.name.indexOf(INT_INTERVENCAO_POINT_NAME) != -1 &&
        sessionStorage.getItem("Modulo") == INTERVENCAO_MODULE_NAME
      ) {
        sessionStorage.setItem("novaIntervencao", 'false');
        const id = event.data.value?.id

        if (getIntervencaoSelected()?.id && getIntervencaoSelected()?.id == id) {
          setShowLoader(false);
          return;
        }


        getIntervencao(id).then((intervencao) => {
          const objToShow = {
            ...intervencao,
            id: intervencao.id,
            localizacao: intervencao?.localizacao ?? undefined,
            nuts1: intervencao.nuts1 ?? undefined,
            nuts2: intervencao.nuts2 ?? undefined,
            nuts3: intervencao.nuts3 ?? undefined,
            distrito : intervencao.distrito ?? undefined,
            concelho: intervencao.mobilizacao ?? undefined,
            freguesia: intervencao.freguesia ?? undefined,
            arh: intervencao.arh ?? undefined,
            capitania : intervencao.capitania ?? undefined,
            massaagua: intervencao.massaagua ?? undefined,
            inicio: intervencao?.inicio? new Date(intervencao?.inicio):null,
            fim :intervencao?.inicio? new Date(intervencao?.fim):null,
            id_tipo_n1: intervencao?.tipo_n1 ?? undefined,
            id_tipo_n2: intervencao?.tipo_n2 ?? undefined,
            id_tipo_n3: intervencao?.tipo_n3 ?? undefined,
            id_prioridade: intervencao?.int_prioridade ?? undefined,
            id_int_pal_xxi_tip_nivel_1: intervencao?.int_pal_xxi_tip_nivel_1 ?? undefined,
            id_int_pal_xxi_tip_nivel_2: intervencao?.int_pal_xxi_tip_nivel_2 ?? undefined,
            id_int_pal_xxi_tip_nivel_3: intervencao?.int_pal_xxi_tip_nivel_3 ?? undefined,
            id_int_instrumento_planeamento: intervencao?.int_instrumento_planeamento ?? undefined,
            entidade: intervencao?.entidade,
            designacao: intervencao?.designacao ?? undefined,
            lstEntidades: preventPropsNullValues(intervencao.lstEntidades, "nome") ?? undefined,
            numero_intervencao: intervencao?.numero_intervencao ?? undefined,
            filedoc_cod: intervencao?.filedoc_cod ?? undefined,
            praia: intervencao?.praia ?? undefined
          }

          selectIntervencaoResultId(intervencao.id as number)
          selectIntervencaoResult(objToShow)
          setIntervencaoSelected(objToShow)

          const iframe: any = document.getElementById('iframeGeoPortal')
          iframe.contentWindow?.postMessage(
            { type: 'SiarlIntIntervencaoSearch', value: [intervencao.id] },
            '*'
          )
          iframe.contentWindow?.postMessage(
            { type: 'SiarlIntIntervencaoCenter', value: [intervencao.id] },
            '*'
          )

          setShowLoader(false);
          postMessage({ type: "form", value: "registarIntervencao" }, "*");
        })
      } else if (
        event.data.value?.name.indexOf(INT_ACAO_POINT_NAME) != -1
      ) {
        sessionStorage.setItem('novaAcao', 'false')

        if (getAcaoSelected()?.id && getAcaoSelected()?.id == event.data.value?.id) {
          setShowLoader(false);
          return;
        }


        getAcao(event.data.value?.id).then(acao => {

          const objToShow = {
            ...acao,
            id: acao.id,
            n_processo_aquisitivo: acao.n_processo_aquisitivo ?? undefined,
            n_filedoc: acao.n_filedoc ?? undefined,
            designacao: acao.designacao ?? undefined,
            id_estado: acao.id_estado ?? undefined,
            nuts1: acao.nuts1 ?? undefined,
            nuts2: acao.nuts2 ?? undefined,
            nuts3: acao.nuts3 ?? undefined,
            distrito: acao.distrito ?? undefined,
            concelho: acao.mobilizacao ?? undefined,
            freguesia: acao.freguesia ?? undefined,
            arh: acao.arh ?? undefined,
            capitania: acao.capitania ?? undefined,
            massaagua: acao.massaagua ?? undefined,


            data_ini_p: acao.data_ini_p ? new Date(acao?.data_ini_p) : null,
            data_con_p: acao.data_con_p ? new Date(acao?.data_con_p) : null,
            data_ini_e: acao.data_ini_e ? new Date(acao?.data_ini_e) : null,
            data_con_e: acao.data_con_e ? new Date(acao?.data_con_e) : null,
            id_tipo_n1: acao?.tipo_n1 ?? undefined,
            id_tipo_n2: acao?.tipo_n2 ?? undefined,
            id_tipo_n3: acao?.tipo_n3 ?? undefined,


            entidade: acao.entidade ?? undefined,
            tipo_engenharia: acao.tipo_engenharia ?? undefined,
            id_natureza_acao: acao.id_natureza_acao ?? undefined,
            id_int_acao_componente: acao.int_acao_componente ?? undefined,
            id_int_acao_planeamento: acao.int_acao_planeamento ?? undefined,
            comunitario: acao.comunitario ?? undefined,
            nacional: acao.nacional ?? undefined,
            numero_candidatura: acao.numero_candidatura ?? undefined,
            designacao_candidatura: acao.designacao_candidatura ?? undefined,
            inv_previs: acao.inv_previs ?? undefined,
            val_contrat: acao.val_contrat ?? undefined,
            inv_realizado: acao.inv_realizado ?? undefined,

          }
          selectAcaoResult(objToShow)
          setAcaoSelected(objToShow);
          setShowResultadoAcao(false)
          setShowRegistarAcao(true)
          setShowLoader(false);
          const idToSend = [acao?.id];
          const iframe: any = document.getElementById('iframeGeoPortal')
          iframe.contentWindow?.postMessage(
            { type: 'SiarlIntAcaoSearch', value: [acao.id] },
            '*'
          )
          iframe.contentWindow?.postMessage({ type: "SiarlIntAcaoCenter", value: idToSend }, '*')

          setTimeout(() => {
            postMessage({ type: "form", value: "registarAcao" }, "*");
          }, 30);

        })
      } else if (
        event.data.value?.name.indexOf(INT_MOBILIZACAO_POINT_NAME) != -1 &&
        sessionStorage.getItem("Modulo") == INTERVENCAO_MODULE_NAME
      ) {
        sessionStorage.setItem('novaMobilizacao', 'false')

        const iframe: any = document.getElementById('iframeGeoPortal')
        const id = event.data.value?.id

        if (getMobilizacaoSelected()?.id && getMobilizacaoSelected()?.id == id) {
          setShowLoader(false);
          return;
        }

        getMobilizacao(id).then(mobilizacao => {
          const objToShow = {
            ...mobilizacao,
            id: mobilizacao.id ?? undefined,
            descricao: mobilizacao.descricao ?? undefined,
            nuts1: mobilizacao.nuts1 ?? undefined,
            nuts2: mobilizacao.nuts2 ?? undefined,
            nuts3: mobilizacao.nuts3 ?? undefined,
            distrito: mobilizacao.distrito ?? undefined,
            concelho: mobilizacao.mobilizacao ?? undefined,
            freguesia: mobilizacao.freguesia ?? undefined,
            arh: mobilizacao.arh ?? undefined,
            capitania: mobilizacao.capitania ?? undefined,
            massaagua: mobilizacao.massaagua ?? undefined,
            data_mobilizacao: mobilizacao?.data_mobilizacao ? new Date(mobilizacao?.data_mobilizacao) : null,
            data_registo: mobilizacao?.data_registo ? new Date(mobilizacao?.data_registo) : null,
            int_mobilizacao_funcao: mobilizacao.int_mobilizacao_funcao ?? undefined,
            id_tipo_mo: mobilizacao.tipo_mo ?? undefined,
            volume: mobilizacao.volume ?? undefined,
            tipo_mancha_nivel_1_id: mobilizacao.intMobMancEmprestimoNivel1 ?? undefined,
            tipo_mancha_nivel_2_id: mobilizacao.intMobMancEmprestimoNivel2 ?? undefined,
            tipo_metodo_dragagem_id: mobilizacao.mobMetodoDragagem ?? undefined,
            tipo_local_deposicao_1_id: mobilizacao.intMobDescritivoLocalDeposicaoNivel1 ?? undefined,
            tipo_local_deposicao_2_id: mobilizacao.intMobDescritivoLocalDeposicaoNivel2 ?? undefined,
            tipo_mob_metodo_deposicao: mobilizacao.mobMetodoDeposicao ?? undefined,
            tipo_mob_objetivo_dep_1_id: mobilizacao.intMobObjetivoDeposicaoNivel1 ?? undefined,
            tipo_mob_objetivo_dep_2_id: mobilizacao.intMobObjetivoDeposicaoNivel2 ?? undefined,
            td_mob_qualidade: mobilizacao.mobQualidade ?? undefined,
            td_mob_granulometria: mobilizacao.mobGranulometria ?? undefined,
            estado: mobilizacao.status ?? undefined,
            id_entidade: mobilizacao.entidade ?? undefined,
            filedoc_cod: mobilizacao?.filedoc_cod ?? undefined
          }
          selectMobilizacaoResult(objToShow)
          selectMobilizacaoResultId(mobilizacao?.id)
          setMobilizacaoSelected(objToShow);

          setShowLoader(false);
          const idToSend = [mobilizacao?.id];
          setTimeout(() => {
            setShowResultadoMobilizacao(false)
            setShowRegistarMobilizacao(true)
          }, 30);
          iframe.contentWindow?.postMessage({ type: "SiarlIntMobilizacaoSearch", value: idToSend }, '*')
          iframe.contentWindow?.postMessage({ type: "SiarlIntMobilizacaoCenter", value: idToSend }, '*')

        })
      } else if (event.data.value?.name.indexOf(EA_POINT_NAME) != -1 &&
    sessionStorage.getItem("Modulo") == ESTRATEGIA_ADAPATACAO_MODULE_NAME) {
          const id = event.data.value?.id

          if (getEaRegistoAnalise()?.id && getEaRegistoAnalise()?.id == id) {
            setShowLoader(false);
            return;
          }

          getAnalise(id).then(analise => {
            if (!analise) {
              postMessage({type: "infoMsg", value: { message: "Registo desativado" }}, "*");
              postMessage({ type: "hideLoader" }, "*");
              return;
            }
            postMessage({ type: "hideLoader" }, "*");
           
           const iframe: any = document.getElementById('iframeGeoPortal')
           
           const objToShow = {
             ...analise,
             id: analise.id,
             nuts1: analise.nuts1 ?? undefined,
             nuts2: analise.nuts2 ?? undefined,
             nuts3: analise.nuts3 ?? undefined,
             distrito : analise.distrito ?? undefined,
             concelho: analise.mobilizacao ?? undefined,
             freguesia: analise.freguesia ?? undefined,
             arh: analise.arh ?? undefined,
             capitania : analise.capitania ?? undefined,
             massaagua: analise.massaagua ?? undefined,
             data_registo: analise?.data_registo? new Date(analise?.data_registo):null,
             data_hora_relatorio :analise?.data_hora_relatorio? new Date(analise?.data_hora_relatorio):null,
             filedoc_cod: analise?.filedoc_cod ?? undefined,
             estado_id: analise?.estado_id ?? undefined,
             utilizador_id: analise?.utilizador_id ?? undefined,
             entidade_id: analise?.entidade_id ?? undefined,
             titulo: analise?.titulo ?? undefined,
             poligono_area_analise: analise?.poligono_area_analise ?? undefined,
             poligono_area_abrangencia_obras_execucao: analise?.poligono_area_abrangencia_obras_execucao ?? undefined,
             retirada: analise?.retirada ?? undefined,
             defesa: analise?.defesa ?? undefined,
             adaptacao: analise?.adaptacao ?? undefined,
             defesa_adaptacao: analise?.defesa_adaptacao ?? undefined,
             salvaguarda_faixa_i: analise?.salvaguarda_faixa_i ?? undefined,
             salvaguarda_faixa_ii: analise?.salvaguarda_faixa_ii ?? undefined,
             markrisk_edificios: analise?.markrisk_edificios ?? undefined,
             carta_ocupacao_solo: analise?.carta_ocupacao_solo ?? undefined,
             universidade_nova: analise?.universidade_nova ?? undefined,
             servicos_ecosistema: analise?.servicos_ecosistema ?? undefined,
             lstTipologiasObra: preventPropsNullValues(analise?.lstTipologiasObra, "nome") ?? undefined,
             custo_adaptacao_edificio: analise?.custo_adaptacao_edificio ?? undefined,
             custo_demolicao: analise?.custo_demolicao ?? undefined,
             custo_renaturalizacao: analise?.custo_renaturalizacao ?? undefined,
             hasGeom: analise?.hasGeom
           }
           sessionStorage.setItem('novaAnaliseEstrategiaAdaptacao', 'false')
           sessionStorage.setItem('analiseAntiga', 'true')

           
          iframe.contentWindow?.postMessage(
            { type: 'SiarlEaRegistoAnaliseSearch', value: analise.id },
            '*'
          )
          iframe.contentWindow?.postMessage(
            { type: 'SiarlEaRegistoAnaliseCenter', value: analise.id },
            '*'
          )
           selectEstrategiaResult(objToShow)
           selectEstrategiaResultId(analise?.id)
           setEaRegistoAnalise(objToShow);
           setShowLoader(false)
           setTimeout(() => {
            hideAll();
            setShowRegistarEstrategiaAdaptacao(true);
          }, 30);
           
          });

       
        } else if (event.data.value?.name.indexOf(FOTO_AEREA_POINT_NAME) != -1 &&
      sessionStorage.getItem("Modulo") == FOTO_AEREA_MODULE_NAME) {
          const id = event.data.value?.id
          if (id) {
            getFotografiaObliqua(id).then((result: any)=> {
              setShowLoader(false)
              const dateOriginal = moment.utc(result.date_time_original);

              const dateFormatted = dateOriginal.clone().tz(CURRENT_TIMEZONE);
              const formattedTitle = dateFormatted.format("DD/MM/YYYY HH:mm");
              setDataFotoTitle(formattedTitle);

              setNomeVooTitle(result?.registo_voo?.descricao ?? "")
              getFotosRegistoVoo(result.id_registo_voo).then(resultFotos => {
                setFotosList(resultFotos);
              })
              setUrlFotoAereaSelected(result.url_externa);
              setIdFotoAereaSelected(result.id);
              setShowFotoaereaSelected(true);
            })
          } else {
            setShowLoader(false)
          }
        }
        
        else {
          setShowLoader(false)
        }
        setShowLoader(false);
      
    }
    if (event.data.type == 'SiarlRestoreFormClick') {
      if (window.innerWidth <= telaMobile) {
        ocultaFloatingMenu_e_Mapa();
      }
      mostraFormulario();
      
    }

    if (event.data.type == "closeForms") {
      hideAllExec(false);
      setCollapseSidebarMenu(true);
      mostraFloatingMenu_e_Mapa();
    }

    if (event.data.type == 'form') {
      hideAll()
      setCollapseSidebarMenu(true)
      if (event.data.value == 'consultaOcorrencias') {
        if (SessionStorageService.getItem('resultadoOcorrenciasPesquisa')) {
          setShowResultsOcorrencia(true)
        } else {
          setShowConsultaOcorrencia(true)
        }
      }
      if (event.data.value == 'pesquisarDocumentos') {
        if (SessionStorageService.getItem('resultadoDocumentosPesquisa')) {
          setShowResultsDocumentos(true)
        } else {
          setShowConsultaDocumentos(true)
        }
      }
      if (event.data.value == 'pesquisarModelos') {
        if (SessionStorageService.getItem('resultadoModelosPesquisa')) {
          setShowResultadosModelo(true)
        } else {
          console.log('tela de pesquisa')
          setShowConsultaModelos(true)
        }
      }
      if (event.data.value == 'pesquisarEstrategiaAdaptacao') {
        if (SessionStorageService.getItem('resultadoEstrategiaAdaptacaoPesquisa')) {
          setShowResultsEstrategiaAdaptacao(true)
        } else {
          setShowConsultaEstrategiaAdaptacao(true)
        }
      }
      if (event.data.value == 'pesquisarUsoDoSolo') {
        if (SessionStorageService.getItem('resultadoUsoDoSoloPesquisa')) {
          setShowResultadoUsoDoSolo(true)
        } else {
          setShowConsultarUsoDoSolo(true)
        }
      }
      if (event.data.value == 'listarGeovisualizadores') {
        setShowGeovisualizadores(true)
      }
      if (event.data.value == 'resultadoValorUsoDoSolo') {
        setShowResultadoValorUsoDoSolo(true)
      }
      if (event.data.value == 'detalheValorUsoDoSolo') {
        setShowDetalheValorUsoDoSolo(true)
      }
      if (event.data.value == 'minimizarCalculoPerfil') {
        setShowCalculoPerfisGeograficosDinamicos(false)
      }
      if (event.data.value == 'pesquisarIntervencao') {
        hideAll()
        setShowResultadoIntervencao(
          !!SessionStorageService.getItem('resultadoIntervencaoPesquisa')
        )
        setShowIntervencao(
          !SessionStorageService.getItem('resultadoIntervencaoPesquisa')
        )
        setCollapseSidebarMenu(true)
      }
      if (event.data.value == 'pesquisarFotoObliqua') {
        if(SessionStorageService.getItem('resultadoFotoPesquisa')){
          setShowResultadoFoto(true)
        }
        else{
          setShowConsultarFoto(true)
        }
      }
      if (event.data.value == 'pesquisarAcao') {
        hideAll()
        setShowResultadoAcao(
          !!SessionStorageService.getItem('resultadoAcaoPesquisa')
        )
        setShowIntervencao(
          !SessionStorageService.getItem('resultadoAcaoPesquisa')
        )
        setCollapseSidebarMenu(true)
      }
      if (event.data.value == 'pesquisarCandidatura') {
        hideAll()
        setShowResultadoCandidatura(
          !!SessionStorageService.getItem('resultadoCandidaturaPesquisa')
        )
        setShowIntervencao(
          !SessionStorageService.getItem('resultadoCandidaturaPesquisa')
        )
        setCollapseSidebarMenu(true)
      }
      if (event.data.value == 'pesquisarMobilizacao') {
        hideAll()
        setShowResultadoMobilizacao(
          !!SessionStorageService.getItem('resultadoMobilizacaoPesquisa')
        )
        setShowIntervencao(
          !SessionStorageService.getItem('resultadoMobilizacaoPesquisa')
        )
        setCollapseSidebarMenu(true)
      }
      if (event.data.value == 'pesquisarDominioHidrico') {
        if (SessionStorageService.getItem('resultadoProcessosPesquisa')) {
          setShowResultadoProcesso(true)
        } else {
          setShowDominioHidrico(true)
        }
      }
      if (event.data.value == 'pesquisarPatrimonio') {
        if (SessionStorageService.getItem('resultadoPatrimoniosPesquisa')) {
          setShowResultadoPatrimonio(true)
        } else {
          setShowDominioHidrico(true)
        }
      }
      if (event.data.value == 'pesquisarDemarcacao') {
        if (SessionStorageService.getItem('resultadoDemarcacoesPesquisa')) {
          setShowResultadoDemarcacao(true)
        } else {
          setShowDominioHidrico(true)
        }
      }
      if (event.data.value == 'pesquisarApoioGestaoPretensao') {
        const pretensoes = SessionStorageService.getItem(
          'resultadoApoioGestaoPretensao'
        )
        const desconformidades = SessionStorageService.getItem(
          'resultadoApoioGestaoDesconformidade'
        )
        if (!pretensoes && !desconformidades) {
          setShowConsultarApoioGestaoPretensao(true)
        }
        if (pretensoes) {
          setShowResultadoApoioGestaoPretensao(true)
          return
        }
        if (desconformidades) {
          setShowResultadoApoioGestaoDesconformidade(true)
          return
        }
      }

      if (event.data.value == 'pesquisarEntidades') {
        if (SessionStorageService.getItem('resultadoEntidadePesquisa')) {
          setShowResultsEntidade(true)
        } else {
          setShowConsultaEntidades(true)
        }
      }
      if (event.data.value == 'resultadoFotoPesquisaChange') {
        if (SessionStorageService.getItem('resultadoFotoPesquisa')) {
          setShowResultadoFoto(true)
        } else {
          setShowConsultarFoto(true)
        }
      }

      if (sessionStorage.getItem('fotoObliquaClicked')) {
        setShowConsultarFoto(true)
        sessionStorage.removeItem('fotoObliquaClicked')
      }
      if (event.data.value == 'registarFotoObliqua') {
        setShowRegistarFoto(true)
      }

      if (event.data.value == 'diretorioEntidades') {
        if (SessionStorageService.getItem('resultadoEntidadePesquisa')) {
          setShowResultsEntidade(true)
        } else {
          setShowConsultaEntidades(true)
        }
      }
      if (event.data.value == "BackofficeClassificadores") {
        setShowClassificadores(true);
      }
      if (event.data.value == 'registarOcorrencia') {
        setShowRegistarOcorrencia(true)
      }
      if (event.data.value == 'registarDocumentos') {
        setShowRegistarDocumentos(true)
      }
      if (event.data.value == 'registarEntidade') {
        setShowRegistarEntidades(true)
      }
      if (event.data.value == 'novaAnaliseEstrategiaAdaptacao') {
        setShowRegistarEstrategiaAdaptacao(true)
      }
      if (event.data.value == 'registarModelos') {
        setShowRegistarModelos(true)
      }
      if (event.data.value == 'registarUsoDoSolo') {
        setShowRegistarUsoDoSolo(true)
      }
      if (event.data.value == "registarProcesso") {
        setShowRegistarProcesso(true)
      }
      if (event.data.value == "registarPatrimonio") {
        setShowRegistarPatrimonio(true)
      }
      if (event.data.value == "registarDemarcacao") {
        setShowRegistarDemarcacao(true);
      }

      if (event.data.value == "registarIntervencao") {
        setShowRegistarIntervencao(true);
      }

      if (event.data.value == "registarAcao") {
        setShowRegistarAcao(true);
      }

      if (event.data.value == "registarCandidatura") {
        setShowRegistarCandidatura(true);
      }

      if (event.data.value == "registarMobilizacao") {
        setShowRegistarMobilizacao(true);
      }

      if (event.data.value == 'registarApoioGestaoPretensao') {
        setShowRegistarApoioGestaoPretensao(true)
      }
      if (event.data.value == 'registarApoioGestaoDesconformidade') {
        setShowRegistarApoioGestaoDesconformidade(true)
      }
      if (event.data.value == 'registarApoioGestaoDesconformidade') {
        setShowRegistarApoioGestaoDesconformidade(true)
      }
      if (event.data.value == 'sobre') {
        setShowSobre(true)
      }
      if (event.data.value == 'vooDrone') {
        const store = SessionStorageService.getItem('resultadoVooDronePesquisa');
        const results = store ? JSON.parse(store) : undefined;
        hideAll()
        setCollapseSidebarMenu(true)
        if (!results) {
          setShowVooDrone(true)
        } else {
          setShowResultadoVooDrone(true)
        }
      }
      if (event.data.value == 'calculoPerfisGeograficosDinamicos') {
        setShowCalculoPerfisGeograficosDinamicos(true);
      }
      if (event.data.value == 'graficoCPGDForm') {
        setShowGraficoCPGD(true);
      }
      if (event.data.value == 'registarVooDrone') {
        setShowRegistarVooDrone(true)
      }
      if (event.data.value == 'inicio') {
        hideAll()
        setCollapseSidebarMenu(true)

      if (isMobile) {
        setTimeout(() => {
          mostraFloatingMenu_e_Mapa_e_Oculta_Form();
        }, 0);
      }
        
      }
      if (event.data.value == 'Contactenos') {
        setShowContactenos(true)
      }
    }
    if (event.data.type == 'resultadoModelosPesquisaChange') {
      hideAll()
      setShowResultadosModelo(true)
      setShowConsultaModelos(false)
      setCollapseSidebarMenu(true)
    }
    if (event.data.type == 'resultadoEstrategiaAdaptacaoPesquisaChange') {
      hideAll()
      setShowResultsEstrategiaAdaptacao(true)
      setShowConsultaEstrategiaAdaptacao(false)
      setCollapseSidebarMenu(true)
    }
    if (event.data.type == 'resultadoUsoDoSoloPesquisaChange') {
      hideAll()
      setCollapseSidebarMenu(true)
      if (sessionStorage.getItem("Modulo") == USO_DO_SOLO_MODULE_NAME ) {
        setShowConsultarUsoDoSolo(true)
      } else {
        setShowConsultaEstrategiaAdaptacao(true);
      }

    }
    if (event.data.type == 'resultadoValorUsoDoSoloPesquisaChange') {
      hideAll()
      setCollapseSidebarMenu(true)
    }
    if (event.data.type == 'resultadoApoioGestaoPretensaoPesquisaChange') {
      hideAll()
      setCollapseSidebarMenu(true)
      setShowConsultarApoioGestaoPretensao(
        !SessionStorageService.getItem('resultadoApoioGestaoPretensao')
      )
      setShowResultadoApoioGestaoPretensao(
        !!SessionStorageService.getItem('resultadoApoioGestaoPretensao')
      )
    }
    if (event.data.type == 'hideFormsShowMenu') {
      hideAll()
      setCollapseSidebarMenu(false)
    }
    if (event.data.type == 'resultadoVooDronePesquisaChange') {
      hideAll()
      setCollapseSidebarMenu(true)
      setShowResultadoVooDrone(true)
    }
    if (
      event.data.type == 'resultadoApoioGestaoDesconformidadePesquisaChange'
    ) {
      hideAll()
      setCollapseSidebarMenu(true)
      setShowConsultarApoioGestaoDesconformidade(
        !SessionStorageService.getItem('resultadoApoioGestaoDesconformidade')
      )
      setShowResultadoApoioGestaoDesconformidade(
        !!SessionStorageService.getItem('resultadoApoioGestaoDesconformidade')
      )
    }
    if (event.data.type == 'resultadoOcorrenciasPesquisaChange') {
      hideAll()
      setShowResultsOcorrencia(
        !!SessionStorageService.getItem('resultadoOcorrenciasPesquisa')
      )
      setShowConsultaOcorrencia(
        !SessionStorageService.getItem('resultadoOcorrenciasPesquisa')
      )
      setCollapseSidebarMenu(true)
    }
    if (event.data.type == 'resultadoEntidadePesquisaChange') {
      hideAll()
      setShowResultsEntidade(
        !!SessionStorageService.getItem('resultadoEntidadePesquisa')
      )
      setShowConsultaEntidades(
        !SessionStorageService.getItem('resultadoEntidadePesquisa')
      )
      setCollapseSidebarMenu(true)
    }
    if (event.data.type == 'resultadoFotoPesquisaChange') {
      hideAll()
      setShowResultadoFoto(
        !!SessionStorageService.getItem('resultadoFotoPesquisa')
      )
      setShowConsultarFoto(
        !SessionStorageService.getItem('resultadoFotoPesquisa')
      )
      setCollapseSidebarMenu(true)
    }
    if (event.data.type == 'resultadoDocumentosPesquisaChange') {
      hideAll()
      setShowResultsDocumentos(
        !!SessionStorageService.getItem('resultadoDocumentosPesquisa')
      )
      setShowConsultaDocumentos(
        !SessionStorageService.getItem('resultadoDocumentosPesquisa')
      )
      setCollapseSidebarMenu(true)
    }
    if (event.data.type == 'resultadoProcessosPesquisaChange') {
      hideAll()
      setShowResultadoProcesso(
        !!SessionStorageService.getItem('resultadoProcessosPesquisa')
      )
      setShowDominioHidrico(
        !SessionStorageService.getItem('resultadoProcessosPesquisa')
      )
      setCollapseSidebarMenu(true)
    }
    if (event.data.type == 'resultadoPatrimoniosPesquisaChange') {
      hideAll()
      setShowResultadoPatrimonio(
        !!SessionStorageService.getItem('resultadoPatrimoniosPesquisa')
      )
      setShowDominioHidrico(
        !SessionStorageService.getItem('resultadoPatrimoniosPesquisa')
      )
      setCollapseSidebarMenu(true)
    }
    if (event.data.type == 'resultadoIntervencaoPesquisaChange') {
      hideAll()
      setShowResultadoIntervencao(
        !!SessionStorageService.getItem('resultadoIntervencaoPesquisa')
      )
      setShowIntervencao(
        !SessionStorageService.getItem('resultadoIntervencaoPesquisa')
      )
      setCollapseSidebarMenu(true)
    } if (event.data.type == 'resultadoAcaoPesquisaChange') {
      hideAll()
      setShowResultadoAcao(
        !!SessionStorageService.getItem('resultadoAcaoPesquisa')
      )
      setShowIntervencao(
        !SessionStorageService.getItem('resultadoAcaoPesquisa')
      )
      setCollapseSidebarMenu(true)
    }
    if (event.data.type == 'pesquisarCandidatura') {
      hideAll()
      setShowCandidatura(
        !!SessionStorageService.getItem('pesquisarCandidatura')
      )
      setShowRegistarCandidatura(
        !SessionStorageService.getItem('pesquisarCandidatura')
      )
      setCollapseSidebarMenu(true)
    }
    if (event.data.type == 'resultadoCandidaturaPesquisaChange') {
      hideAll()
      setCollapseSidebarMenu(true)
      setShowResultadoCandidatura(
        !!SessionStorageService.getItem('resultadoCandidaturaPesquisa')
      )
      setShowCandidatura(
        !SessionStorageService.getItem('resultadoCandidaturaPesquisa')
      )
    }
    if (event.data.type == 'resultadoMobilizacaoPesquisaChange') {
      hideAll()
      setShowResultadoMobilizacao(
        !!SessionStorageService.getItem('resultadoMobilizacaoPesquisa')
      )
      setShowIntervencao(
        !SessionStorageService.getItem('resultadoMobilizacaoPesquisa')
      )
      setCollapseSidebarMenu(true)
    }

    if (event.data.type == 'pesquisarFotoObliqua') {
      hideAll()
      setShowResultadoFoto(
        !!SessionStorageService.getItem('pesquisarFotoObliqua')
      )
      setShowRegistarIntervencao(
        !SessionStorageService.getItem('pesquisarFotoObliqua')
      )
      setCollapseSidebarMenu(true)
    }
    if (event.data.type == 'resultadoDemarcacoesPesquisaChange') {
      hideAll()
      setShowResultadoDemarcacao(
        !!SessionStorageService.getItem('resultadoDemarcacoesPesquisa')
      )
      setShowDominioHidrico(
        !SessionStorageService.getItem('resultadoDemarcacoesPesquisa')
      )
      setCollapseSidebarMenu(true)
    }
    if (event.data.type == 'CoordenadasSelected') {
      console.log(event.data.value)
      if (event.data.value) {
        const flatPointCoordinates =
          event.data.value.x + ', ' + event.data.value.y;
        setCoordenadasSelected(flatPointCoordinates)
        if (sessionStorage.getItem('Modulo') == OCORRENCIAS_MODULE_NAME) {
          if (
            sessionStorage.getItem('novoPontoOcorrencia') &&
            sessionStorage.getItem('novoPontoOcorrencia') == 'true'
          ) {
            submitCoordenadasOcorrencia(
              flatPointCoordinates,
              event.data.value.x,
              event.data.value.y
            )
          }
        }

        if (sessionStorage.getItem('Modulo') == ENTIDADES_MODULE_NAME) {
          const moradaDigitada = event.data.value.morada;
          updateCoordenadasEntidade(flatPointCoordinates, moradaDigitada);
        }
      }
    } else if (event.data.type == 'SiarlGetMapAreaResult') {
      const coord = event.data.value
      const flatCoordinates = getFlatCoordinatesFromObj(event.data.value)
      console.log(flatCoordinates)
      setCoordenadasPoligono(flatCoordinates)
    } else if (event.data.type == 'CoordenadasFlatSelected') {
      const polygonString = getFlatCoordinatesFromObj(event.data.value)
      setCoordenadasPoligono(polygonString)

      console.log(polygonString)

      if (sessionStorage.getItem('desconformidadeClicked')) {
        postMessage({ type: 'desconformidadeModal', value: 'openModal' }, '*')
      }
    } else if (event.data.type == 'CoordenadasLineSelected') {
      const polygonString = getFlatCoordinatesFromObj(event.data.value)
      setCoordenadasLinhaSelected(polygonString);
    }

    if (sessionStorage.getItem('perfilGeograficoClicked')) {
      postMessage({ type: "form", value: "calculoPerfisGeograficosDinamicos" }, "*");

    } else if (event.data.type == 'CoordenadasLineSelected') {

      const polygonString = getFlatCoordinatesFromObj(event.data.value)
      setCoordenadasLinhaSelected(polygonString);

      if (sessionStorage.getItem('Modulo') == CALCULO_PERFIL_MODULE_NAME) {
        
        SessionStorageService.setItem('CoordenadasLineSelectedCPGD', JSON.stringify(event.data))
        postMessage({ type: "hideLoader" }, "*");
        postMessage({ type: "form", value: "calculoPerfisGeograficosDinamicos" }, "*");
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage(
           {
             type: 'SiarlHideInfoMap',
             value: 'true',
           },
           '*'
         )
         
        // const lineLength = event.data.lineLenght;
        // const coordenadas = polygonString;
        // postMessage({ type: "showLoader" }, "*");
        // getAltFromLine({
        //   flatCoordinates: coordenadas,
        //   lineLengthMts: lineLength,
        //   resolucaoPontos: listaResolucaoPontos[3]
        // }).then(result => {

        //   console.log(result);

        //   SessionStorageService.setItem('resultadoMDTsPesquisa', JSON.stringify(result))

        //   postMessage({ type: "hideLoader" }, "*");
        //   postMessage({ type: "form", value: "calculoPerfisGeograficosDinamicos" }, "*");
        // })

      }
    } 

  }, [])

  useEffect(() => {
     if (isMapLoaded) {
      
      const mapIdSession = getCurrentMapId();
      if (queryParams.get("mapid")) {
        setGPUrl(GEOPORTAL_MAPA_PUBLICO_URL + "&mapid=" + queryParams.get("mapid"));
      }
      const mapid = queryParams.get("mapid") ?? (mapIdSession ?? uuid());
      setCurrentMapId(mapid);
      const newId = uuid();
      const iframe: any = document.getElementById('iframeGeoPortal')
  
      if (queryParams.get("mapid")) {
        window.history.replaceState({}, document.title, window.location.pathname);
      }
  
        getSessionState(mapid).then(sessionStates => {
  sessionStates.forEach((element: any) => {
          if (element.key.indexOf("resultado") != -1) {
            const valueObj: any[] = JSON.parse(element.value);
            const ids = valueObj.map((o: any) => o.id).slice(0, RECORDS_PER_PAGE);
            let eventName = "";
            if (element.key.indexOf("Ocorrencia") != -1) {
              eventName = "SiarlOcorrenciasSearch";
            }
            else if (element.key.indexOf("Entidade") != -1) {
              eventName = "SiarlEntidadesSearch";
            }
            else if (element.key.indexOf("Modelo") != -1) {
              eventName = "SiarlModelosSearch";
            }
            else if (element.key.indexOf("Patrimonio") != -1) {
              eventName = "SiarlPatrimoniosSearch";
            }
            else if (element.key.indexOf("Documento") != -1) {
              eventName = "SiarlDocumentoSearch";
            }
            else if (element.key.indexOf("Processo") != -1) {
              eventName = "SiarlProcessoSearch";
            }
  
            else if (element.key.indexOf("Demarcacao") != -1) {
              eventName = "SiarlDemarcacaoSearch";
            }
  
            else if (element.key.indexOf("Pretensao") != -1) {
              eventName = "SiarlPretensaoSearch";
            }
  
            else if(element.key.indexOf("UsoDoSolo") != -1) {
              eventName = "SiarlUsTituloSearch"
            }
  
            else if (element.key.indexOf("Desconformidade") != -1) {
              eventName = "SiarlDesconformidadeSearch"
            }
  
            else if (element.key.indexOf("Intervencao") != -1) {
              eventName = "SiarlIntIntervencaoSearch"
            }
  
            else if (element.key.indexOf("Acao") != -1) {
              eventName = "SiarlIntAcaoSearch"
            }
  
            else if (element.key.indexOf("Mobilizacao") != -1) {
              eventName = "SiarlIntMobilizacaoSearch"
            }
  
            else if (element.key.indexOf("EstrategiaAdaptacao") != -1) {
              eventName = "SiarlEaRegistoAnaliseSearch"
            }
  
            iframe.contentWindow?.postMessage(
              { type: eventName, value: ids },
              '*'
            )
  
            element['uid_mapa'] = newId;
            element['id'] = undefined;
            setCurrentMapId(newId);
  
            saveSessionState(element).then(result => {
              SessionStorageService.setItem(element.key, element.value);
            });
          } else if (element.key.indexOf("ColunasVisiveis")) {
            const value = JSON.parse(element.value);
            setVisibleColumns(value)
          }
          if (queryParams.get("mapid")) { 
            location.reload();
          }

        }); 
        
        });
     }    
  }, [isMapLoaded])

  useEffect(() => {
    window.addEventListener('message', handleMessageEvent, false)

    return () => {
      window.removeEventListener('message', handleMessageEvent, false)
    }
  }, [handleMessageEvent])

  useEffect(() => {
    if (!localStorage.getItem("geoportalUser")) {
      postMessage({ type: 'userLoginSended' }, '*');
    }
  })

  // const {
  //   condicionantesProcessos, fillCondicionantesProcessos,
  //   estadosProcesso, fillEstadosProcesso,
  //   fasesProcedimentoDelimitacao, fillFasesProcedimentoDelimitacao,
  //   localizacoesProcesso, fillLocalizacoesProcessos,
  //   tiposAcaoJudicial, fillTiposAcaoJudicial,
  //   tiposAquisicao, fillTiposAquisicao,
  //   tiposDecisaoFinal, fillTiposDecisaoFinal,
  //   tiposDesafetacao, fillTiposDesafetacao,
  //   tiposIniciativa, fillTiposIniciativa,
  //   tiposNotificacao, fillTiposNotificacao,
  //   tiposParcela, fillTiposParcela,
  //   tiposPendencia, fillTiposPendencia,
  //   tiposProcesso, fillTiposProcesso,
  //   tiposSentenca, fillTiposSentenca,
  //   tiposSituacaoNotificacao, fillTiposSituacaoNotificacao,
  //   validacoesProva, fillValidacoesProva,
  //   arhs, fillArhs,
  //   concelhos, fillConcelhos,
  //   distritos, fillDistritos,
  //   freguesias, fillFreguesias,
  //   massaagua, fillMassaAgua,
  //   nuts1, fillNuts1,
  //   nuts2, fillNuts2,
  //   nuts3, fillNuts3,
  //   praias, fillPraias,
  //   estados, fillEstado,
  //   entidades, fillEntidades,
  //   categorias, fillCategoria,
  //   tiporecurso, fillTipoRecurso,
  //   tipoacesso, fillTipoAcesso,
  //   idioma, fillIdiomas,
  //   palavraschave, fillPalavrasChave,
  //   capitania, fillCapitania,
  //   estadoCandidatura, fillEstadoCandidatura,
  //   acaoEstado, fillAcaoEstado,
  //   acaoPlaneamento1, fillAcaoPlaneamento1,
  //   acaoPlaneamento2, fillAcaoPlaneamento2,
  //   acaoPlaneamento3, fillAcaoPlaneamento3,
  //   acaoPlaneamento4, fillAcaoPlaneamento4,
  //   componenteAcao, fillComponenteAcao,
  //   naturezaAcao, fillNaturezaAcao,
  //   tipoEngenharia, fillTipoEngenharia,
  //   tipoPlaneamento, fillTipoPlanemaneto,
  //   instrumentoPlaneamento, fillInstrumentoPlaneamento,
  //   deposicaoLocal1, fillLocalReposicao1,
  //   deposicaoLocal2, fillLocalReposicao2,
  //   deposicaoObjetivo1, fillObjetivo1,
  //   deposicaoObjetivo2, fillObjetivo2,
  //   granulametriaSedimentos, fillGranulametriaSedimentos,
  //   mancha1, fillMancha1,
  //   mancha2, fillMancha2,
  //   metodoDeposicao, fillMetodoDeposicao,
  //   metotodoDragagem, fillMetodoDragagem,
  //   mobilizacaoEstado, fillMobilizacoEstado,
  //   mobilizacaoFuncoes, fillFuncoes,
  //   qualidadeSedimentos, fillQualidadeSedimentos,
  //   tipologiaMob, fillTipologiaMob,
  //   estadoIntervencao, fillEstadoIntervencao,
  //   instrumentoFinanciamento, fillInstrumentoFinanciamento,
  //   planeamento1, fillPlaneamento1,
  //   planeamento2, fillPlaneamento2,
  //   planeamento3, fillPlaneamento3,
  //   prioridade, fillPrioridade,
  //   tip1, fillTipologia1,
  //   tip2, fillTipologia2,
  //   tip3, fillTipologia3,
  //   fontesDadosVus, fillFontesDadosVus,
  //   tiposVus, fillTiposVus,
  //   vus, fillVus,
  //   tipoEntidades, fillTipoEntidades,
  //   agDecisoes, fillDecisoesAsync,
  //   agEstados, fillAgEstadosAsync,
  //   agTipoPretensoes, fillTipoPretensoesAsync,
  //   moEstados, fillMoEstados,
  //   modelosSubtipos, fillModelosSubTipos,
  //   modelosTipos, fillModelosTipos,
  //   status, fillStatus,
  // } = useCombos();

  // const combosParaMonitorar = [
  //   condicionantesProcessos,
  //   estadosProcesso,
  //   fasesProcedimentoDelimitacao,
  //   localizacoesProcesso,
  //   tiposAcaoJudicial,
  //   tiposAquisicao,
  //   tiposDecisaoFinal,
  //   tiposDesafetacao,
  //   tiposIniciativa,
  //   tiposNotificacao,
  //   tiposParcela,
  //   tiposPendencia,
  //   tiposProcesso,
  //   tiposSentenca,
  //   tiposSituacaoNotificacao,
  //   validacoesProva,
  //   arhs,
  //   concelhos,
  //   distritos,
  //   freguesias,
  //   massaagua,
  //   nuts1,
  //   nuts2,
  //   nuts3,
  //   praias,
  //   estados,
  //   entidades,
  //   categorias,
  //   tiporecurso,
  //   tipoacesso,
  //   idioma,
  //   palavraschave,
  //   capitania,
  //   estadoCandidatura,
  //   acaoEstado,
  //   acaoPlaneamento1,
  //   acaoPlaneamento2,
  //   acaoPlaneamento3,
  //   acaoPlaneamento4,
  //   componenteAcao,
  //   naturezaAcao,
  //   tipoEngenharia,
  //   tipoPlaneamento,
  //   instrumentoPlaneamento,
  //   deposicaoLocal1,
  //   deposicaoLocal2,
  //   deposicaoObjetivo1,
  //   deposicaoObjetivo2,
  //   granulametriaSedimentos,
  //   mancha1,
  //   mancha2,
  //   metodoDeposicao,
  //   metotodoDragagem,
  //   mobilizacaoEstado,
  //   mobilizacaoFuncoes,
  //   qualidadeSedimentos,
  //   tipologiaMob,
  //   estadoIntervencao,
  //   instrumentoFinanciamento,
  //   planeamento1,
  //   planeamento2,
  //   planeamento3,
  //   prioridade,
  //   tip1,
  //   tip2,
  //   tip3,
  //   fontesDadosVus,
  //   tiposVus,
  //   vus,
  //   tipoEntidades,
  //   agDecisoes,
  //   agEstados,
  //   agTipoPretensoes,
  //   moEstados,
  //   modelosSubtipos,
  //   modelosTipos,
  //   status,
  // ];

  // useEffect(() => {

  //    if (combosParaMonitorar.every(combo => combo.length !== 0)) {

  //      console.log('o carregamento das combos terminou!')

  //    }
  //  }, [...combosParaMonitorar]);

  // useEffect(() => {
  //   fillCondicionantesProcessos();
  //   fillEstadosProcesso();
  //   fillFasesProcedimentoDelimitacao();
  //   fillLocalizacoesProcessos();
  //   fillTiposAcaoJudicial();
  //   fillTiposAquisicao();
  //   fillTiposDecisaoFinal();
  //   fillTiposDesafetacao();
  //   fillTiposIniciativa();
  //   fillTiposNotificacao();
  //   fillTiposParcela();
  //   fillTiposPendencia();
  //   fillTiposProcesso();
  //   fillTiposSentenca();
  //   fillTiposSituacaoNotificacao();
  //   fillValidacoesProva();
  //   fillArhs();
  //   fillConcelhos();
  //   fillDistritos();
  //   fillFreguesias();
  //   fillMassaAgua();
  //   fillNuts1();
  //   fillNuts2();
  //   fillNuts3();
  //   fillPraias();
  //   fillEstado();
  //   fillEntidades();
  //   fillCategoria();
  //   fillTipoRecurso();
  //   fillTipoAcesso();
  //   fillIdiomas();
  //   fillPalavrasChave();
  //   fillCapitania();
  //   fillEstadoCandidatura();
  //   fillAcaoEstado();
  //   fillAcaoPlaneamento1();
  //   fillAcaoPlaneamento2();
  //   fillAcaoPlaneamento3();
  //   fillAcaoPlaneamento4();
  //   fillComponenteAcao();
  //   fillNaturezaAcao();
  //   fillTipoEngenharia();
  //   fillTipoPlanemaneto();
  //   fillInstrumentoPlaneamento();
  //   fillLocalReposicao1();
  //   fillLocalReposicao2();
  //   fillObjetivo1();
  //   fillObjetivo2();
  //   fillGranulametriaSedimentos();
  //   fillMancha1();
  //   fillMancha2();
  //   fillMetodoDeposicao();
  //   fillMetodoDragagem();
  //   fillMobilizacoEstado();
  //   fillFuncoes();
  //   fillQualidadeSedimentos();
  //   fillTipologiaMob();
  //   fillEstadoIntervencao();
  //   fillInstrumentoFinanciamento();
  //   fillPlaneamento1();
  //   fillPlaneamento2();
  //   fillPlaneamento3();
  //   fillPrioridade();
  //   fillTipologia1();
  //   fillTipologia2();
  //   fillTipologia3();
  //   fillFontesDadosVus();
  //   fillTiposVus();
  //   fillVus();
  //   fillTipoEntidades();
  //   fillDecisoesAsync();
  //   fillAgEstadosAsync();
  //   fillTipoPretensoesAsync();
  //   fillMoEstados();
  //   fillModelosSubTipos();
  //   fillModelosTipos();
  //   fillStatus();

  // }, []);


  const initialized = useRef(false)
  useEffect(() => {
      fillCombosAuxiliares();
  }, []);

  useEffect(() => {
    if (!isUserLogged()) {
    postMessage({type: "showLoaderCenter"}, "*");
       setTimeout(() => {
         if (!localStorage.getItem(AUTH_LOCALSTORAGE)) {
           const guestLogin = { username: USER_GUEST, password: PASS_GUEST }
           setGeoPortalUser(guestLogin)
           login(guestLogin).then(loginResponse => {
             if (loginResponse) {
                location.reload();
             }
           })
          
         }
      }, 1000);
    }
   });

  const {setVisibleColumns} = useVisibleColumns();

  useEffect(() => {
    const entidadeId = userLocal?.user?.entidadeId;
    if (entidadeId) {
      getEntidade(entidadeId).then(entidade => {
        if (entidade?.diEntidadeJurisdicoes && entidade?.diEntidadeJurisdicoes.length > 0) {
          const idToSend = [entidade?.diEntidadeJurisdicoes[0].id];
          const iframe: any = document.getElementById('iframeGeoPortal')
          iframe.contentWindow?.postMessage({ type: "SiarlEntidadesApoioCenter", value: idToSend }, '*')
        }
      });
    }
  }, [])

  const hideAll = () => {
    hideAllExec(true);
  }
  const hideAllExec = (removeSelection: boolean) => {
    
    if (isMobile) {
      mostraFloatingMenu_e_Mapa_e_Oculta_Form();
    }
    else{
      
      restoreView()

      setCollapseSidebarMenu(false)

      setShowRegistarOcorrencia(false)
      setShowRegistarDocumentos(false)
      setShowConsultaOcorrencia(false)
      setShowResultsOcorrencia(false)
      setShowResultadosModelo(false)   
      setShowConsultaDocumentos(false)
      setShowResultsDocumentos(false)
      setShowRegistarDocumentos(false)
      setShowConsultaEntidades(false)
      setShowRegistarEntidades(false)
      setShowResultsEntidade(false)
      setShowConsultaModelos(false)
      setShowRegistarModelos(false)
      setShowConsultarUsoDoSolo(false)
      setShowRegistarUsoDoSolo(false)
      setShowResultadoUsoDoSolo(false)
      setShowResultadoValorUsoDoSolo(false)
      setShowDetalheValorUsoDoSolo(false)
      setShowRegistarApoioGestaoDesconformidade(false)
      setShowRegistarApoioGestaoPretensao(false)
      setShowConsultarApoioGestaoDesconformidade(false)
      setShowConsultarApoioGestaoPretensao(false)
      setShowResultadoApoioGestaoDesconformidade(false)
      setShowResultadoApoioGestaoPretensao(false)
      setShowDominioHidrico(false)
      setShowRegistarProcesso(false)
      setShowRegistarPatrimonio(false)
      setShowRegistarDemarcacao(false)
      setShowIntervencao(false)
      setShowCandidatura(false)
      setShowRegistarIntervencao(false)
      setShowRegistarAcao(false)
      setShowResultadoProcesso(false)
      setShowResultadoPatrimonio(false)
      setShowResultadoDemarcacao(false)
      setShowResultadoIntervencao(false)
      setShowResultadoCandidatura(false)
      setShowRegistarCandidatura(false)
      setShowRegistarMobilizacao(false)
      setShowResultadoAcao(false)
      setShowResultadoMobilizacao(false)
      setShowSobre(false)
      setShowVooDrone(false)
      setShowRegistarVooDrone(false)
      setShowContactenos(false)
      setShowGeovisualizadores(false)
      setShowConsultarFoto(false)
      setShowRegistarFoto(false)
      setShowResultadoVooDrone(false)
      setShowResultadoFoto(false)
      setShowRegistarFoto(false)
      setShowCalculoPerfisGeograficosDinamicos(false)
      setShowGraficoCPGD(false)
      setShowResultsEstrategiaAdaptacao(false)
      setShowRegistarEstrategiaAdaptacao(false)
      setShowConsultaEstrategiaAdaptacao(false)
      setShowParametrosEstrategiaAdaptacao(false)
      setShowClassificadores(false)
    }
    SessionStorageService.removeItem("coordenadasPesquisaFoto");
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlDeselectMapArea", value: 'true' }, '*')
    if (removeSelection && !SessionStorageService.getItem("perfilGeograficoInProgress")) {
      iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: 'true' }, '*')
    }
    iframe.contentWindow?.postMessage({ type: "SiarlFotoObliquaClearLayers", value: "true" }, '*')
    iframe.contentWindow?.postMessage({ type: "SiarlMobilizacaoCaraterizacaoClearLayers", value: "true" }, '*')
  }

  const consultarFotografiaClose = () => {
    postMessage({ type: 'stopSlideShowPhoto' }, '*');
    hideAll();
  }

  const getFlatCoordinatesFromObj = (value: any) => {
    let polygonString = ''
    const values: any[] = value
    values?.forEach((value, index) => {
      if (index % 2 == 0) {
        polygonString += value + ' '
      } else {
        polygonString += value + ','
      }
    })
    polygonString = polygonString.substring(0, polygonString.length - 1)
    return polygonString
  }

  const updateCoordenadasEntidade = async (coordenada: any, morada: any = undefined) => {
    const entidadeSelected = getEntidadeSelected()
    const dataToSend = {
      id: entidadeSelected ? entidadeSelected?.id : undefined,
      flatCoordinates: coordenada,
    }
    let entidadeSaved: any
    try {
      if (entidadeSelected && entidadeSelected?.id) {
        entidadeSaved = await updateEntidade(dataToSend)
      } else {
        entidadeSaved = await createEntidade(dataToSend)
      }
    } catch (ex) {
      console.log(ex);
      postMessage({ type: 'errorMsg' }, '*');
      return;
    }
    const result = await getEntidade(JSON.parse(entidadeSaved?.id))

    const locationObj: any = await fillLocation(result)

    const loggedUser = JSON.parse(
      localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}'
    )
    const utilizadorId = loggedUser?.user?.id

    const ResultEntidade = await getEntidadeByUser(utilizadorId)
    console.log('entidade id: ' + ResultEntidade)

    Object.assign(entidadeSaved, locationObj)

    const addressObj = await getAddressFromLocation(coordenada)

    Object.assign(entidadeSaved, {
      morada: morada ?? addressObj.address.address,
      codigoPostal: addressObj.address.postal,
      localidade: addressObj.address.city
    })
    setEntidadeSelected(entidadeSaved)
    selectEntidadeResult(entidadeSaved)
    if (entidadeSaved) {
      const iframe: any = document.getElementById('iframeGeoPortal')
      // iframe.contentWindow?.postMessage(
      //   { type: 'SiarlEntidadesSearch', value: [entidadeSaved.id] },
      //   '*'
      // )
      iframe.contentWindow?.postMessage(
        { type: 'SiarlEntidadesCenter', value: [entidadeSaved.id] },
        '*'
      )
      postMessage({ type: 'sucessMsg' }, '*')
      postMessage({type: 'desativarPonto'}, "*");
    }
  }

  const updateCoordenadasPatrimonio = async (coordenada: any) => {
    const patrimonioSelected = getPatrimonioSelected()
    const dataToSend = {
      id: patrimonioSelected ? patrimonioSelected?.id : undefined,
      flatCoordinates: coordenada,
    }
    let patrimonioSaved: any
    if (patrimonioSelected && patrimonioSelected?.id) {
      patrimonioSaved = await updatePatrimonio(dataToSend)
    } else {
      patrimonioSaved = await createPatrimonio(dataToSend)
    }
    const result = await getPatrimonio(JSON.parse(patrimonioSaved?.id))

    const locationObj = await fillLocation(result)

    const loggedUser = JSON.parse(
      localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}'
    )
    const utilizadorId = loggedUser?.user?.id

    const ResultEntidade = await getEntidadeByUser(utilizadorId)
    console.log('entidade id: ' + ResultEntidade)

    Object.assign(patrimonioSaved, locationObj)

    const addressObj = await getAddressFromLocation(coordenada)

    Object.assign(patrimonioSaved, {
      morada: addressObj.address.address,
      codigoPostal: addressObj.address.postal,
      localidade: addressObj.address.city,
    })
    setPatrimonioSelected(patrimonioSaved)
    selectPatrimonioResult(patrimonioSaved)
    if (patrimonioSaved) {
      postMessage({ type: 'sucessMsg' }, '*')
    }
  }

  const submitCoordenadasOcorrencia = async (
    coordenada: any,
    x: any,
    y: any
  ) => {
    const userId = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '')
      ?.user?.id
    const dataToSend = {
      id: selectOcorrenciaResultId ?? undefined,
      flat_coordinates: coordenada,
      utilizador_id: userId,
      data_registo: new Date(),
      data_ocorrencia: new Date(),
      coord_x: x,
      coord_y: y,
    }
    try {
      setTimeout(() => {
        postMessage({ type: 'showLoader' }, '*')
      }, 500)
      let ocorrenciaSaved: any

      if (!selectedOcorrenciaResultId) {
        ocorrenciaSaved = await saveOcorrencia(dataToSend)
      } else {
        ocorrenciaSaved = await updateOcorrencia(dataToSend)
      }
      selectOcorrenciaResultId(ocorrenciaSaved?.id)
      setTimeout(() => {
        postMessage({ type: 'hideLoader' }, '*')
      }, 500)
      if(ocorrenciaSaved){
      if (SessionStorageService.getItem("resultadoOcorrenciasPesquisa")) {
        const ocorrenciasSearchSaved: any[] = JSON.parse(SessionStorageService.getItem("resultadoOcorrenciasPesquisa"));
        if (ocorrenciasSearchSaved) {
          ocorrenciasSearchSaved.push(ocorrenciaSaved);
          SessionStorageService.setItem("resultadoOcorrenciasPesquisa", JSON.stringify(ocorrenciasSearchSaved));
        }
        refreshOcorrencesSearch();
      } else {
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage(
          { type: 'SiarlOcorrenciasSearch', value: [ocorrenciaSaved?.id] },
          '*'
        )
      }
    }
      setCoordenadasPoligono(undefined)
      setCoordenadasSelected(undefined)
      getOcorrencia(JSON.parse(ocorrenciaSaved?.id)).then((result) => {

        const loggedUser = JSON.parse(
          localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}'
        )
        const utilizadorId = loggedUser?.user?.id

        getEntidadeByUser(utilizadorId).then((ResultEntidade) => {
          console.log('entidade id: ' + ResultEntidade)
          const ocorrenciasSelectedLocal = {
            id: ocorrenciaSaved?.id,
            freguesia: result.freguesia,
            concelho: result.concelho,
            distrito: result.distrito,
            capitania: result.capitania,
            nuts3: result.nuts3,
            nuts2: result.nuts2,
            nuts1: result.nuts1,
            praia: result.praia,
            arh: result.arh,
            massaAgua: result.massa_agua,
            tecnico: loggedUser?.user?.name,
            utilizador_id: userId,
            entidade: ResultEntidade,
            entidade_id: ResultEntidade?.id,
            oc_estado: ocEstados.find(x => x.id == result.estado_id),
            dataHoraRegistro: dataToSend.data_registo,
          }
          selectOcorrenciaResult(ocorrenciasSelectedLocal);

          setOcorrenciaSelected(ocorrenciasSelectedLocal)
          selectOcorrenciaResultId(ocorrenciaSaved?.id)
          sessionStorage.setItem('novoPontoOcorrencia', 'false')
          const iframe: any = document.getElementById('iframeGeoPortal')
          //to remove the point selected in menu, cliking again
          iframe.contentWindow?.postMessage(
            { type: 'SiarlGeoPortalAdicionarPonto', value: 'true' },
            '*'
          )
          setIsEditingOcorrencia(false);
          postMessage({ type: 'form', value: 'registarOcorrencia' }, '*')
        })
      })
    } catch(error) {
      handleGenericError(error)
    }
  }

  const nextPhoto = async (e: any) => {
  
    try{
      console.log(fotosList);
      
      const nextIds = fotosList
        .filter((doc: any) => doc.id < idFotoaereaSelected && doc.url_externa !== '')
        .map((doc: any) => doc.id);
  
      if (nextIds.length > 0) {
        const nextId = Math.max(...nextIds);
        console.log(`O próximo ID é ${nextId}`);
        
        const documento: any = fotosList.find((x: any) => x.id == nextId);
        //const documento = await getFotografiaObliqua(nextId);
  
        setIdFotoAereaSelected(nextId.toString());
        setUrlFotoAereaSelected(documento.url_externa);

        const dateOriginal = moment.utc(documento.date_time_original);

        const dateFormatted = dateOriginal.clone().tz(CURRENT_TIMEZONE);
        const formattedTitle = dateFormatted.format("DD/MM/YYYY HH:mm");
        setDataFotoTitle(formattedTitle);

        setNomeVooTitle(documento?.registo_voo?.descricao ?? "")
  
        if (documento.latitude && documento.longitude) {
          const latLongMap = proj4(EPSG_GLOBAL, EPSG_MAP, [documento.longitude?? 0,documento.latitude?? 0]);
          const dataToSendLatLong = {
              longitude: latLongMap[0],
              latitude: latLongMap[1],
              id : documento.id
            }
            const iframe: any = document.getElementById('iframeGeoPortal')
            iframe.contentWindow?.postMessage(
                { type: 'SiarlFotoObliquaHighlight', value: dataToSendLatLong },
                '*'
            )
          }
  
      } else {
        console.log("Não há um próximo ID na lista.");
      }
  
    }catch(error){
  
      postMessage({ type: "errorMsg", value: "Não foi possivel abrir o documento!" }, "*");
  
    }finally{
  
      postMessage({ type: "hideLoader" }, "*");
  
      e.preventDefault();
    }
   
  }

  const previeousPhoto = async (e: any) => {
  
    try{
      console.log(fotosList);
      postMessage({ type: "showLoader" }, "*");
      const previousIds = fotosList
        .filter((doc: any) => doc.id > idFotoaereaSelected && doc.url_externa !== '')
        .map((doc: any) => doc.id);
  
      if (previousIds.length > 0) {
        const previousId = Math.min(...previousIds);
        console.log(`O anterior ID é ${previousId}`);
  
        const documento: any = fotosList.find((x: any) => x.id == previousId);
  
        setIdFotoAereaSelected(previousId.toString());
        setUrlFotoAereaSelected(documento.url_externa);
  
        const iframe: any = document.getElementById('iframeGeoPortal')
        if (documento.latitude && documento.longitude) {
          const latLongMap = proj4(EPSG_GLOBAL, EPSG_MAP, [documento.longitude?? 0,documento.latitude?? 0]);
          const dataToSendLatLong = {
              longitude: latLongMap[0],
              latitude: latLongMap[1],
              id : documento.id
            }
            const iframe: any = document.getElementById('iframeGeoPortal')
            iframe.contentWindow?.postMessage(
                { type: 'SiarlFotoObliquaHighlight', value: dataToSendLatLong },
                '*'
            )
          }
  
      } else {
        console.log("Não há um próximo ID na lista.");
      }
  
    }catch(error){
  
      postMessage({ type: "errorMsg", value: "Não foi possivel abrir o documento!" }, "*");
  
    }finally{
  
      postMessage({ type: "hideLoader" }, "*");
  
      e.preventDefault();
    }
   
  }

  if (isLandScapeMobile) {
    return (
      <div className='centered-text'>Gire o seu dispositivo</div>
    )
  } else {
  return (
    <>
      <div className={showLoader ? styles.baseLoader : styles.base}>
        {!hideFloatingMenu ? (
          <FloatingMenu
            collapseSidebarMenu={collapseSidebarMenu}
            setCollapseSidebarMenu={setCollapseSidebarMenu}
            isOverlayVisible={
              showConsultaOcorrencia ||
              showResultsOcorrencia ||
              showRegistarOcorrencia ||
              showConsultaDocumentos ||
              showRegistarDocumentos ||
              showResultsDocumentos ||
              showRegistarModelos
            }
          />
        ) : (
          <></>
        )}
        {/* {!hideFloatingBtnMapa && (
          <FloatingBtnMapa showBtnMapa={showBtnMapa} />
        )} */}
        {collapseSidebarMenu ? (
          <CollapsedSidebarMenu/>
        ) : (
          <SidebarMenu setSelectedMenuItem={setSelectedMenuItem} />
        )}
        <div className={styles.base} id="mapContainer">
          <ViewerMap />
          {showConsultaOcorrencia ? (
            <ConsultarOcorrenciaForm onCloseClick={hideAll} />
          ) : (
            <></>
          )}
          {showConsultaDocumentos ? (
            <PesquisarDocumentosTabsForm onCloseClick={hideAll} />
          ) : (
            <></>
          )}
          {showResultsOcorrencia ? (
            <ResultadoOcorrenciaForm
              onCloseClick={hideAll}
              setShowResults={setShowResultsOcorrencia}
              setShowOcorrenciaForm={setShowRegistarOcorrencia}
            />
          ) : (
            <></>
          )}
          {showResultsDocumentos ? (
            <ResultadoDocumentosForm
              onCloseClick={hideAll}
              setShowResults={setShowResultsDocumentos}
              setShowDocumentosForm={setShowRegistarDocumentos}
            />
          ) : (
            <></>
          )}
          {showRegistarOcorrencia ? <RegistarOcorrenciaTerrenoTabs onCloseClick={hideAll} /> : <></>}
          {showRegistarDocumentos ? <RegistarDocumentosTabs /> : <></>}
          {showConsultaEntidades ? (
            <ConsultarEntidadesForm onCloseClick={hideAll} />
          ) : (
            <></>
          )}
          {showResultsEntidade ? (
            <ResultadoEntidadeForm
              onCloseClick={hideAll}
              setShowResults={setShowResultsEntidade}
              setShowEntidadeForm={setShowRegistarEntidades}
            />
          ) : (
            <></>
          )}
          {showRegistarEntidades ? (
            <RegistarEntidade onCloseClick={hideAll} />
          ) : (
            <></>
          )}
          {showConsultaModelos ? (
            <ConsultaModeloSimplesForm onCloseClick={hideAll} />
          ) : (
            <></>
          )}
          {showClassificadores ? <ConsultarClassificadoresForm onCloseClick={hideAll} /> : <></>}
          {showRegistarModelos ? <RegistarModelosTabs /> : <></>}
          {showResultadosModelo ? <ResultadoModeloForm onCloseClick={hideAll} setShowResultadosModelo={setShowResultadosModelo} setShowRegistarModeloForm={setShowRegistarModelos} /> : <></>}
          {showConsultarUsoDoSolo ? <ConsultarUsoDoSoloTabsForm onCloseClick={hideAll} /> : <></>}
          {showResultadoUsoDoSolo ? <ResultadoUsoDoSoloForm onCloseClick={hideAll} setShowResults={setShowResultadoUsoDoSolo} setShowRegistarUsoDoSoloForm={setShowRegistarUsoDoSolo} /> : <></>}
          {showResultadoValorUsoDoSolo ? <ResultadoValorUsoDoSolo onCloseClick={hideAll} /> : <></>}
          {showConsultarApoioGestaoPretensao ? <ConsultarApoioGestaoTabsForm onCloseClick={hideAll} /> : <></>}
          {showResultadoApoioGestaoPretensao ? <ResultadoApoioGestaoPretensao onCloseClick={hideAll} /> : <></>}
          {showResultadoApoioGestaoDesconformidade ? <ResultadoApoiogestaoDesconformidade onCloseClick={hideAll} setShowResults={setShowResultadoApoioGestaoDesconformidade} setShowRegistarDesconformidadeForm={setShowRegistarApoioGestaoDesconformidade} /> : <></>}
          {showRegistarApoioGestaoPretensao ? <RegistarApoioGestaoPretensaoForm onCloseClick={hideAll} /> : <></>}
          {showRegistarApoioGestaoDesconformidade ? <RegistarApoioGestaoDesconformidadeForm onCloseClick={hideAll} /> : <></>}
          {showRegistarUsoDoSolo ? <RegistarUsoDoSoloForm onCloseClick={hideAll} /> : <></>}
          {showDominioHidrico ? <PesquisarDominioHidricoTabs onCloseClick={hideAll} setShowResults={setShowDominioHidrico} setShowRegistoPatrimonio={setShowRegistarPatrimonio} setShowRegistarDemarcacao={setShowRegistarDemarcacao} setShowRegistarProcessoForm={setShowRegistarProcesso} /> : <></>}
          {showRegistarProcesso ? <RegistarProcessoForm onCloseClick={hideAll} initialValuesParam={initialValuesProcesso} /> : <></>}
          {showRegistarPatrimonio ? <RegistarPatrimonioForm onCloseClick={hideAll} /> : <></>}
          {showRegistarDemarcacao ? <RegistarDemarcacaoForm onCloseClick={hideAll} /> : <></>}
          {showResultadoProcesso ? <ResultadoProcessoForm onCloseClick={hideAll} setShowResults={setShowResultadoProcesso} setShowRegistarProcessoForm={setShowRegistarProcesso} /> : <></>}
          {showIntervencao ? <PesquisarIntervencaoTabs onCloseClick={hideAll} setShowResults={setShowIntervencao} setShowRegistarIntervencao={setShowRegistarIntervencao} setShowRegistarAcao={setShowRegistarAcao} setShowRegistarCandidatura={setShowRegistarCandidatura} setShowRegistarMobilizacao={setShowRegistarMobilizacao} /> : <></>}
          {showRegistarIntervencao ? <RegistarIntervencao onCloseClick={hideAll} /> : <></>}
          {showRegistarAcao ? <RegistarAcao onCloseClick={hideAll} /> : <></>}
          {showRegistarCandidatura ? <RegistarCandidatura onCloseClick={hideAll} /> : <></>}
          {showRegistarMobilizacao ? <RegistarMobilizacao onCloseClick={hideAll} /> : <></>}
          {showResultadoPatrimonio ? <ResultadoPatrimonioForm onCloseClick={hideAll} setShowResults={setShowResultadoPatrimonio} setShowRegistarPatrimonioForm={setShowRegistarPatrimonio} /> : <></>}
          {showResultadoDemarcacao ? <ResultadoDemarcacaoForm onCloseClick={hideAll} setShowResults={setShowResultadoDemarcacao} setShowRegistarDemarcacaoForm={setShowRegistarDemarcacao} /> : <></>}
          {showResultadoAcao ? <ResultadoAcaoForm onCloseClick={hideAll} setShowResultadoAcao={setShowResultadoAcao} setShowRegistarAcao={setShowRegistarAcao} /> : <></>}
          {showResultadoIntervencao ? <ResultadoIntervencao onCloseClick={hideAll} setShowResultadoIntervencao={setShowResultadoIntervencao} setShowRegistarIntervencao={setShowRegistarIntervencao} /> : <></>}
          {showResultadoMobilizacao ? <ResultadoMobilizacao onCloseClick={hideAll} setShowResultadoMobilizacao={setShowResultadoMobilizacao} setShowRegistarMobilizacao={setShowRegistarMobilizacao} /> : <></>}
          {showResultadoVooDrone ? <ResultadoVooDroneForm onCloseClick={hideAll} setShowResultadoVooDrone={setShowResultadoVooDrone} setShowRegistarVooDrone={setShowRegistarVooDrone} /> : <></>}
          {showSobre ? <SobreForm onCloseClick={hideAll} /> : <></>}
          {showVooDrone ? <VooDroneFormTab onCloseClick={hideAll} /> : <></>}
          {showRegistarVooDrone ? <RegistarVooDroneForm onCloseClick={hideAll} /> : <></>}
          {showResultadoCandidatura ? <ResultadoCandidatura onCloseClick={hideAll} setShowResultadoCandidatura={setShowResultadoCandidatura} setShowRegistarCandidatura={setShowRegistarCandidatura} /> : <></>}
          {showContactenos ? <Contactenos onCloseClick={hideAll} /> : <></>}
          {showGeovisualizadores ? <ListarGeovisualizadores onCloseClick={hideAll} /> : <></>}
          {showConsultarFoto ? (<ConsultarFotografiasForm onCloseClick={consultarFotografiaClose} />) : (<></>)}
          {showRegistarFoto ? (<RegistarFotografia onCloseClick={consultarFotografiaClose} />) : (<></>)}
          {showResultadoFoto ? <ResultadoFotoForm onCloseClick={consultarFotografiaClose} setShowResultadoFoto={setShowResultadoFoto} setShowRegistarFoto={setShowRegistarFoto} /> : <></>}
          {showCalculoPerfisGeograficosDinamicos ? <CalculoPerfisGeograficosDinamicosForm onCloseClick={hideAll} /> : <></>}
          {showGraficoCPGD ? <GraficoCPGDForm onCloseClick={hideAll} /> : <></>}
          {showConsultaEstrategiaAdaptacao ? (  <ConsultarEstrategiaAdaptacaoForm onCloseClick={hideAll} />) : (<></>)}
          {showRegistarEstrategiaAdaptacao ? (  <RegistarEstrategiaAdaptacaoTabs onCloseClick={hideAll}  />) : (<></>)}
          {showParametrosEstrategiaAdaptacao ? (  <EstrategiaAdaptacaoParametros />) : (<></>)}
          {showResultsEstrategiaAdaptacao ? (
            <ResultadoEstrategiaAdaptacao
              onCloseClick={hideAll}
              setShowResults={setShowResultsEstrategiaAdaptacao}
              setShowEstrategiaAdaptacaoForm={setShowRegistarEstrategiaAdaptacao}

            />
          ) : (
            <></>
          )}        </div>
      </div>

      <div>
        {showFotoAereaSelected ? 
        <Dialog
        className="ModalDocumentos"
          title={`Foto: ${idFotoaereaSelected}; ${dataFotoTitle}; ${nomeVooTitle}`}
          onClose={() => {
            setShowFotoaereaSelected(false)
          }}
          style={{ zIndex: 999 }}
        >
          
            <ImagePreviewV2
              documentUrl={urlFotoAereaSelected}
              next={(e: any) => nextPhoto(e)}
              previous={(e: any) => previeousPhoto(e)}
            />

        </Dialog>: ''}</div>
      {showLoader && (
        <div className="overlay">
          <ReactLoading
            className={'spinner'}
            type={'spin'}
            color={'#1ba49c'}
            height={'10%'}
            width={'10%'}
          />
        </div>
      )}
    </>
  )
}
}
