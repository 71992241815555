import { create } from 'zustand'

type DocumentosState = {
  selectedDocumentosResult?: unknown
  selectDocumentosResult: (payload: unknown) => void
  selectedDocumentosResultId?: number
  selectDocumentosResultId: (payload?: number) => void
  identificacaoTabIsValid: boolean
  selectDocumentoIdentificacaoTabIsValid: (payload: unknown) => void
  detalheTabIsValid: boolean
  selectDocumentoDetalheTabIsValid: (payload: unknown) => void
  identificacaoTabValidationObject: Record<string, boolean>
  addIdentificacaoTabValidatedField: (payload: Record<string, boolean>) => void
  detalheTabValidationObject: Record<string, boolean>
  addDetalheTabValidatedField: (payload: Record<string, boolean>) => void
}

export const useDocumentosStore = create<DocumentosState>((set, get) => ({
  selectedDocumentosResult: null,
  identificacaoTabIsValid: false,
  detalheTabIsValid: true,
  identificacaoTabValidationObject: {},
  detalheTabValidationObject: {},
  selectDocumentosResult: (payload: unknown) =>
    set({ selectedDocumentosResult: payload }),
  selectDocumentosResultId: (payload?: number) =>
    set({ selectedDocumentosResultId: payload }),
  selectDocumentoIdentificacaoTabIsValid: (payload?: any) =>
    set({ identificacaoTabIsValid: payload }),
  selectDocumentoDetalheTabIsValid: (payload?: any) =>
    set({ identificacaoTabIsValid: payload }),
  addIdentificacaoTabValidatedField: (payload) => {
    const validationObj = get().identificacaoTabValidationObject
    const newValidationObj = { ...validationObj, ...payload }
    set({ identificacaoTabValidationObject: newValidationObj })
    const validationValues = Object.values(newValidationObj)
    set({
      identificacaoTabIsValid: validationValues.every(
        (value) => value === true
      ),
    })
  },
  addDetalheTabValidatedField: (payload) => {
    const validationObj = get().detalheTabValidationObject
    const newValidationObj = { ...validationObj, ...payload }
    set({ detalheTabValidationObject: newValidationObj })
    const validationValues = Object.values(newValidationObj)
    set({
      detalheTabIsValid: validationValues.every(
        (value) => value === true
      ),
    })
  },
}))
