import {
  Dispatch,
  MouseEvent as ReactMouseEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
  
  import {
    Grid,
    GridCellProps,
    GridColumn,
    GridPageChangeEvent,
    GridToolbar,
  } from '@progress/kendo-react-grid'

  import styles from '../../styles/apoiogestao/ApoioGestao.module.scss'
  import commonStyles from '../../styles/common/Common.module.scss'
  import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from '@progress/kendo-react-layout'
import SessionStorageService from '../../services/SessionStorageService'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, DropDownButton } from '@progress/kendo-react-buttons'
import { ComboBox } from '@progress/kendo-react-dropdowns'
import { Tooltip } from '@progress/kendo-react-tooltip'
import { format } from 'date-fns'
import { CSVLink } from 'react-csv'
import { options } from 'yargs'
import { PagerTargetEvent } from '@progress/kendo-react-data-tools'
import { ResultadoApoioGestaoDesconformidadeCommandGridCell } from './ResultadoApoioGestaoDesconformidadeCommandGridCell'
import clsx from 'clsx'
import { GetUserNameTecnico, b64toBlob, exportPDFWithMethod, get, handleDownload, handleGenericError, hasPermission, post, setCoordenadasPoligono, setDesconformidadeSelected } from '../../services/utils'
import { CONSULTA_AG_DESCONFORMIDADE, CURRENT_TIMEZONE, GET_AG_DESCONFORMIDADE_BY_ID, GET_AG_DESCONFORMIDADE_SHAPEFILE, GET_DOCUMENTO, PERMISSION_APOIO_GESTAOPERFIL_INTERMEDIO_EDICAO, PERMISSION_APOIO_GESTAO_PERFIL_COMPLETO_EDICAO, PERMISSION_APOIO_GESTAO_PERFIL_TOTAL, PERMISSION_INSERIR_DESCONFORMIDADE, PERMISSION_INSERIR_PRETENSAO, PESQUISAR_DOCUMENTOS_BY_DESCONFORMIDADES_IDS, URL_AJUDA_APOIO_GESTAO_PRETENSAO, clearLayers } from '../../utils/constants'
import { useAgDesconformidadeStore } from '../../stores/agDesconformidade'

import { process } from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { GridPDFExport } from '@progress/kendo-react-pdf'
import { useCommonStore } from '../../stores/common'
import MaximizeButton from '../common/MaximizeButton'
import VisualizadorColunas from '../Generico/VisualizadorColunas '
import useVisibleColumns, { Key } from '../../hooks/useVIsibleColumns'
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais'
import ReactGA from 'react-ga4';
import { Dialog } from '@progress/kendo-react-dialogs'
import ImagePreviewV2 from '../viewer/ImagePreviewV2'
import { ResultadoDocumentoCommandGridCell } from '../documentos/ResultadoDocumentoCommandGridCell'
import moment from 'moment'
import "moment-timezone"

type IProps = {
    onCloseClick: (
      e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
    ) => void | undefined
    setShowResults: Dispatch<SetStateAction<boolean>>
    setShowRegistarDesconformidadeForm?: Dispatch<SetStateAction<boolean>>
  }
  
  type PageState = {
    skip: number
    take: number
  }
  
  const initialDataState: PageState = { skip: 0, take: 20 }
  
  type DesconformidadeResult =  {
    id: number;
   
  }
const keyPainelPrincipal:Key = 'desconformidadeColunasVisiveis';
const keyPainelDocumentos:Key = 'desconformidadeDocumentosColunasVisiveis';
export default function  ResultadoApoioGestaoDesconformidade({
  onCloseClick,
  setShowRegistarDesconformidadeForm,
  setShowResults
}:IProps){
  const [isMobile, setIsMobile] = useState(false);
  const [selected, setSelected] = useState<number>(0)
  const [desconformidades, setDesconformidades] = useState<any[]>([])
  const [page, setPage] = useState<PageState>(initialDataState)
  const [documentos, setDocumentos] = useState([])
  const [urlDoDocumento, setUrlDoDocumento] = useState("");
  const [idDoDocumento, setIdDoDocumento] = useState("");
  const [openForm, setOpenForm] = useState<boolean>(false)
  const [dataStateDocumentos, setDataStateDocumentos] = useState({
    skip: 0,
    take: 20,
    sort: [],
    group: [],
  });
  const [pageSizeValue, setPageSizeValue] = useState<
    number | string | undefined
  >()
  const selectAgDesconformidadeResult = useAgDesconformidadeStore(
    (state) => state.selectAgDesconformidadeResult
  )

  const selectAgDesconformidadeResultId = useAgDesconformidadeStore(
    (state) => state.selectAgDesconformidadeResultId
  )

  const isCurrentViewMaximized = useCommonStore(state => state.isCurrentViewMaximized)

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected)
  }
  const handlePageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent
    const take = event.page.take

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value)
    }

    setPage({
      ...event.page,
      take,
    })
  }
  const [dataState, setDataState] = useState({
    skip: 0,
    take: 20,
    sort: [],
    group: [],
  });
  const {getVisibleColumns, removeColumnsKeys} = useVisibleColumns();

  useEffect(() => {
    
    ReactGA.send({ hitType: "pageview", page: "/ResultadoApoioAGestaoDesconformidade" });

  }, []);

  const getDesconformidade = async (data: any) =>
  await get<any, any>(`${GET_AG_DESCONFORMIDADE_BY_ID}/` + data)
  const getDocumentosByDesconformidadeIds = async(data: any) => await post<any,any>(`${PESQUISAR_DOCUMENTOS_BY_DESCONFORMIDADES_IDS}`, data);
  const getDocumento = async (data: any) =>
  await get<any, any>(`${GET_DOCUMENTO}/` + data)
  const getShapeFile = async (data: any) =>
  await post<any, any>(`${GET_AG_DESCONFORMIDADE_SHAPEFILE}`, data)
  
  const clearSearchResults = () => {
    SessionStorageService.removeItem('resultadoApoioGestaoDesconformidade')
    SessionStorageService.removeItem('lastSearchDesconformidade')
    setCoordenadasPoligono(undefined);
    const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.contentWindow?.postMessage(
        { type: clearLayers["CLEAR_LAYERS_DESCONFORMIDADE"], value: 'true' },
        '*'
      )
      iframe.contentWindow?.postMessage(
        { type: 'SiarlRemoveSelection', value: 'true' },
        '*'
      )
      removeColumnsKeys(keyPainelDocumentos)
      removeColumnsKeys(keyPainelPrincipal)
      postMessage({ type: 'resultadoApoioGestaoPretensaoPesquisaChange' }, '*')
  }

  const selectDesconformidadeResultId = useAgDesconformidadeStore(
    (state) => state.selectAgDesconformidadeResultId
  )
  const selectDesconformidadeResult = useAgDesconformidadeStore(
    (state) => state.selectAgDesconformidadeResult
  )

  const consultarDesconformidade = async (data: any) =>
    await post<any, any>(`${CONSULTA_AG_DESCONFORMIDADE}`, data)

  const initialized = useRef(false)
    useEffect(() => {
        if (!initialized.current) {
          initialized.current = true;
if (SessionStorageService.getItem("lastSearchDesconformidade") && !SessionStorageService.getItem("firstCallAgDesconformidade")) {
      postMessage({ type: "showLoader" }, "*");
      const objToSearch = SessionStorageService.getItem("lastSearchDesconformidade") ?? '';
      consultarDesconformidade(objToSearch).then((data) => {
        SessionStorageService.setItem("resultadoApoioGestaoDesconformidade", JSON.stringify(data));
        setResultsDesconformidade();
        postMessage({ type: "hideLoader" }, "*");
      })
    } 
    else {
      setResultsDesconformidade();
  }
}
  }, [])
 
  const setResultsDesconformidade = () => {

    const desconformidadesStored = JSON.parse(
      SessionStorageService.getItem('resultadoApoioGestaoDesconformidade') ?? ''
    )
    setDesconformidades(desconformidadesStored)
    const ids: any[] = [];
    desconformidadesStored.forEach((item: any) => {
      ids.push(item.id);
    })
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
      { type: 'SiarlDesconformidadeSearch', value: ids },
      '*'
    )

    getVisibleColumns(keyPainelPrincipal, setColums)
    getVisibleColumns(keyPainelDocumentos, setColumsDocuments)
  
  

    postMessage({type: "showLoader"}, "*");
     const idsDocunentos = desconformidadesStored?.map((d: any) => d.id)
    getDocumentosByDesconformidadeIds(idsDocunentos).then(result => {
      setDocumentos(result);
    }).catch((err)=>{
      console.log({err})
    }).finally(()=>{
      postMessage({type: "hideLoader"}, "*");
    })

  }

  

  useEffect(() => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    const ids: number[] = []
    desconformidades.forEach((element: any) => {
      ids.push(element.id)
    })
    iframe.contentWindow?.postMessage(
      { type: 'SiarlDesconformidadeSearch', value: ids },
      '*'
    )
  }, [])

  const handleInfoClickById = (desconformidadeLine: any) => async () => {
    try {
      const desconformidade = await getDesconformidade(desconformidadeLine.id);
      const tecnico = await GetUserNameTecnico(desconformidade.utilizador_id);

     if (!desconformidade) {
      postMessage({type: "infoMsg", value: { message: "Registo desativado" }}, "*");
      return;
    }
    SessionStorageService.setItem("novaDesconformidade", 'false');
    SessionStorageService.setItem("NovoRegistoDesconformidade", "false");
    postMessage({type: "showLoader"}, "*");
    
    const agDesconformidadeObject = {
      ...desconformidade,
      id: desconformidade?.id ?? undefined,
      num_processo_file_doc: desconformidade?.num_processo_file_doc ?? undefined,
      tecnicoRegisto: tecnico,
      estado_id: desconformidade?.estado ?? undefined,
      medida_corretiva_a_aplicar: desconformidade?.medida_corretiva_a_aplicar ?? undefined,
      medida_corretiva_aplicada: desconformidade?.medida_corretiva_aplicada ?? undefined,
      local: desconformidade?.local ?? undefined,
      data_registo: desconformidade.data_registo ? moment.utc(desconformidade.data_registo).tz(CURRENT_TIMEZONE) : undefined,
      ag_decisao_id: desconformidade.ag_decisao ?? undefined


    }

    selectDesconformidadeResultId(desconformidade.id)
    selectDesconformidadeResult(agDesconformidadeObject)
    setDesconformidadeSelected(agDesconformidadeObject);
    
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
      { type: 'SiarlDesconformidadeSearch', value: [desconformidade.id] },
      '*'
    )
    iframe.contentWindow?.postMessage(
      { type: 'SiarlDesconformidadeCenter', value: [desconformidade.id] },
      '*'
    )
    setShowResults(false)
    if (setShowRegistarDesconformidadeForm) setShowRegistarDesconformidadeForm(true)
    } catch (error) {
      handleGenericError(error);
      console.error({error})
    }finally{

      postMessage({type: "hideLoader"}, "*");

    }
  
  }

  const CommandCell = (props: GridCellProps) => (
    <ResultadoApoioGestaoDesconformidadeCommandGridCell
      {...props}
      onDetailsClick={handleInfoClickById}
      onOpentitle={handleInfoClickById}
      onMapCenter={mapCenter}
      onOpenPdfReport={handleInfoClickById}
    />
  )

  const mapCenter = (item: Record<string, any>) => async () => {
    const iframe: any = document.getElementById('iframeGeoPortal');
    const id = item.desconformidade_id ?? item.id;
    iframe.contentWindow?.postMessage(
      { type: 'SiarlDesconformidadeCenter', value: [item.id] },
      '*'
    )
   }

  const dataStateChange = (event: any) => {
    setDataResult(process(desconformidades, event.dataState));
    setDataState(event.dataState);
  };

  const [dataResult, setDataResult] = useState(
    process(desconformidades, dataState)
  );

  const itemRender = (props: { item: any; itemIndex: number }) => {
    const isTitleItem = props.item.key === 'titulo';
    if (isTitleItem) {
      // Item de título personalizado
      return (
        <div className={styles.DropDownButtonTitulo}>
          <span>{props.item.text}</span>
        </div>
      );
    } else {
      // Demais itens
      return (
        <div className={styles.DropDownButtonItem}>
          <span>{props.item.text}</span>
        </div>
      );
    }
  };


  const optionsExportar = [
    {
      key: 'ExportarListaResultadosExcel',
      text: 'Excel',
    },
    {
      key: 'ExportarListaResultadosShapeFile',
      text: 'Shapefile',
    },
  ]
  
  let _pdfExport: any;
  const exportExcel = () => {
    _export.save();
  };

  const onItemClickExportar = async (event: any) => {
    if (event.item.key == 'ExportarListaResultadosExcel') {
      exportExcel();
    }
    else if (event.item.key == "ExportarListaResultadosShapeFile") {
      const results = JSON.parse(
        SessionStorageService.getItem('resultadoApoioGestaoDesconformidade') ?? ''
      )
  
      if (results) {
        const ids: number[] = []
        results.forEach((element: any) => {
          ids.push(element.id)
        })

        const file: any = await getShapeFile(ids);
        const filePath = 'shapeFileAgDesconformidade.zip';

        handleDownload(file, filePath);
    }
  } 
}


  let _export: any;
  const exportPDF = () => {
    _pdfExport.save();
  };

  const openPdfNewTab = () => {
    return new Promise<void>((resolve, reject) => {
        
        postMessage({ type: "showLoader" }, "*");

        setTimeout(() => {
           const columnsToShow = colums.columns.filter(x => x.show);
            const dataToExport = desconformidades.map((item: any) => {
                const filteredItem: any = {};
                columnsToShow.forEach((column: any) => {
                    filteredItem[column.field] = item[column.field];
                });
                return filteredItem;
            });
            
            exportPDFWithMethod(columnsToShow, dataToExport, 8)
                .then((pdfData: any) => {
                    postMessage({ type: "hideLoader" }, "*");

                        const base64Data = pdfData.split(";base64,")[1];
                        console.log(base64Data);
                        const blob = b64toBlob(base64Data, 'application/pdf');
                        const blobUrl = URL.createObjectURL(blob);

                      
                        window.open(blobUrl, '_blank');
                        resolve();
                  
                })
                .catch(error => {
                    console.error('Error exporting PDF:', error);
                    reject(error);
                });
        }, 1000);
    });
};

  const onItemClickImprimir = async (event: any) => {
    openPdfNewTab();
  }


  const [dataResultDocumentos, setDataResultDocumentos] = useState(
    process(documentos, dataStateDocumentos)
  );

  const dataStateChangeDocumentos = (event: any) => {
    setDataResultDocumentos(process(documentos, event.dataState));
    setDataStateDocumentos(event.dataState);
  };

  const onItemClickImprimirDocumentos = (event: any) => {

    const link: HTMLAnchorElement | null = document.querySelector('#print-results-documentos');

    if (link) {
      link.click();
    }

    // if (event.item.key == 'ExportarListaResultados') {

      

    // }

  };

  const [columsDocuments, setColumsDocuments] = useState<{ columns: { field: string, title: string, width: number | string, show: boolean }[] }>({
    columns: [
      { field: "id", width: 70, title: "ID", show: true },
      { field: "filedoc_cod", width: 190 , title: "ID Documento Filedoc", show: true },
      { field: "data_publicacao", width: 190, title: "Data publicação", show: true },
      { field: "tipoRecurso.designacao", width: 190, title: "Tipo de recurso", show: true },
      { field: "titulo", width: 170, title: "Título", show: true },
      { field: "autores", width: 180, title: "Autor", show: true },
      { field: "entidade.nome", width: 180, title: "Entidade", show: true },
      { field: "id_estado", width: 'auto', title: "Estado", show: true },
    ]
  })

  const [colums, setColums]=useState<{columns:{field:string, title:string, width:number|string, show:boolean}[]}>({
    columns:[
      { field: "id", title: "ID", show: true, width: 70 },
      { field: "descricao", title: "Descrição", show: true,width: 180 },
      { field: "ag_estado.descricao", title: "Estado", show: true,width: 170 },
      { field: "ag_decisao.descricao", title: "Decisão", show: true ,width: 190},
    ]
  })

  useEffect(() => {
    SessionStorageService.setItem(keyPainelDocumentos, JSON.stringify(columsDocuments));
  }, [columsDocuments]);
  
  useEffect(() => {
    SessionStorageService.setItem(keyPainelPrincipal, JSON.stringify(colums));
  }, [colums]);
  
  const documentosToPreview = documentos

  const handleDocumentPreviewClickById = (documentosLocal: Record<string, any>) => async () => {

    try {
  
      postMessage({ type: "showLoader" }, "*");
      const documentos = await getDocumento(documentosLocal.id);
  
      setIdDoDocumento(documentosLocal.id);
      setUrlDoDocumento(documentos.url);
      setOpenForm(true);
  
    } catch (error) {
  
      handleGenericError(error, "Não foi possivel abrir o documento!")
      setOpenForm(false);
  
    } finally {
  
      postMessage({ type: "hideLoader" }, "*");
  
    }
  }
  
  const nextPhoto = async (e: any) => {
  
    try{
  
      postMessage({ type: "showLoader" }, "*");
      const nextIds = documentosToPreview
        .filter((doc: any) => doc.id < idDoDocumento && doc.url !== '')
        .map((doc: any) => doc.id);
  
      if (nextIds.length > 0) {
        const nextId = Math.max(...nextIds);
        console.log(`O próximo ID é ${nextId}`);
  
        const documento = await getDocumento(nextId);
  
        setIdDoDocumento(nextId.toString());
        setUrlDoDocumento(documento.url);
  
        const iframe: any = document.getElementById('iframeGeoPortal')
        const desconformidade_id = e.desconformidade_id;
        iframe.contentWindow?.postMessage(
          {
            type: 'SiarlDesconformidadeCenter',
            value: [desconformidade_id],
          },
          '*'
        )
  
      } else {
        console.log("Não há um próximo ID na lista.");
      }
  
    }catch(error){
  
      postMessage({ type: "errorMsg", value: "Não foi possivel abrir o documento!" }, "*");
  
    }finally{
  
      postMessage({ type: "hideLoader" }, "*");
  
      e.preventDefault();
    }
   
  }
  
  const previousPhoto = async (e: any) => {
  
    try{
  
      postMessage({ type: "showLoader" }, "*");
      const previousIds = documentosToPreview
        .filter((doc: any) => doc.id > idDoDocumento && doc.url !== '')
        .map((doc: any) => doc.id);
  
      if (previousIds.length > 0) {
        const previousId = Math.min(...previousIds);
        console.log(`O anterior ID é ${previousId}`);
  
        const documento = await getDocumento(previousId);
  
        setIdDoDocumento(previousId.toString());
        setUrlDoDocumento(documento.url);
  
        const iframe: any = document.getElementById('iframeGeoPortal')
        const desconformidade_id = e.desconformidade_id;
        iframe.contentWindow?.postMessage(
          {
            type: 'SiarlDesconformidadeCenter',
            value: [desconformidade_id],
          },
          '*'
        )
      } else {
        console.log("Não há um anterior ID na lista.");
      }
  
    } catch(error){
  
      handleGenericError(error, "Não foi possivel abrir o documento!")
  
    }finally{
  
      postMessage({ type: "hideLoader" }, "*");
  
      e.preventDefault();
    }
       
  }

  useLayoutEffect(() => {

    const handleResize = () => {

      if (window.innerWidth <= telaMobile) {
        
        setIsMobile(true);
        ocultaFloatingMenu_e_Mapa();

      }else{

        setIsMobile(false);
        mostraFloatingMenu_e_Mapa();

      }

    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);  


  const onItemClickMais = (event: any) => {

    elementoClickMais(event);

    onItemClickExportar(event);

    onItemClickImprimir(event);
  };

  const optionsMais = [
    {
      key: 'titulo',
      text: 'Exportar',
    },
    ...optionsExportar.map(option => ({
      ...option,
      className: 'icon-siarl icon-exportar btnIconRegistar',
    })),
  ]

  const documentsCustomCell = (props: GridCellProps) => (
    <ResultadoDocumentoCommandGridCell
    onLocationMouseEnter={function (documentos: Record<string, unknown>): void {
      console.log("n/a");
    } } onLocationMouseLeave={function (documentos: Record<string, unknown>): void {
      console.log("n/a");
    } } onLocationClick={mapCenter} onDocumentPreviewClick={handleDocumentPreviewClickById} {...props}
    onInfoClick={handleInfoClickById}    />
  )
  

  const registarNovoClick = () => {
    setDesconformidadeSelected(undefined);
    selectAgDesconformidadeResult(undefined);
    selectAgDesconformidadeResultId(undefined);
    SessionStorageService.setItem("novaDesconformidade", 'true');
    postMessage({ type: "form", value: "registarApoioGestaoDesconformidade" }, "*");
  }

  const openAjuda = () => {
    window.open(URL_AJUDA_APOIO_GESTAO_PRETENSAO, "_blank");
  }

return (
  <>
    <div className={clsx(styles.base, isCurrentViewMaximized && commonStyles.maximizedBaseForm)}>
      <div className={styles.header}>
        <div>
          <div className={styles.nomeDoModulo}>Apoio à Gestão</div>
          <div className={styles.tituloDaPagina}>Resultados de pesquisa</div>
        </div>

        <div>

        {hasPermission(PERMISSION_INSERIR_DESCONFORMIDADE) ||
          (hasPermission(PERMISSION_APOIO_GESTAOPERFIL_INTERMEDIO_EDICAO)) ||
          (hasPermission(PERMISSION_APOIO_GESTAO_PERFIL_TOTAL)) ||
          (hasPermission(PERMISSION_APOIO_GESTAO_PERFIL_COMPLETO_EDICAO))
          ?
          <Button
            onClick={registarNovoClick}
            className={styles.btnNovoRegisto}
            style={{ display: isMobile ? 'none' : '' }}
            id='novoRegisto'
            title='Nova Desconformidade'
          >
            <span className="icon-siarl icon-registar btnIconRegistar"></span>{' '}
            &nbsp;{' '}
            Nova Desconformidade
          </Button>
        : ''}

          <CSVLink
            title="Imprimir resultados"
            id="print-results-pretensoes"
            separator={';'}
            data={desconformidades.map((desconformidade: any) => ({
              ...desconformidade,
              data_registo: format(
                new Date(desconformidade?.data_registo),
                'dd/MM/yyyHHmm'
              ),
            }))}
            filename={`siarl-pretensoes-${format(
              new Date(),
              'ddMMyyyHHmm'
            )}`}
            hidden
          ></CSVLink>

          {!isMobile &&
          <VisualizadorColunas 
              columns={selected===0?colums:columsDocuments} 
              setColumns={selected===0?setColums:setColumsDocuments}
              styles={styles.btnEditar2} 
           />
          }

        {/* <button className={styles.btnEditar2} onClick={onItemClickImprimir} style={{ display: isMobile ? 'none' : '' }} id='imprimirFicha' title='Imprimir'>
          <span className="icon-siarl icon-imprimir"></span>
        </button> */}

          {!isMobile &&
          <DropDownButton
          items={optionsExportar}
          className={styles.btnImprimir}
          style={{marginTop: '-4px'}}
          iconClass="icon-siarl icon-exportar"
          onItemClick={onItemClickExportar}
          fillMode={'flat'}
          title='Exportar'
          itemRender={itemRender}
        ></DropDownButton>
          }

          {isMobile &&
            <DropDownButton
              items={optionsMais}
              className={styles.btnMais}
              iconClass="icon-siarl icon-mais"
              onItemClick={onItemClickMais}
              fillMode={'flat'}
              itemRender={itemRenderMais}
              title="Mais Opções"
            />
          }

          <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
            <span className="icon-siarl icon-ajuda"></span>
          </button>
          <button onClick={onCloseClick} className={styles.btnFechar} title="Minimizar">
            <span className="icon-siarl icon-minimizar"></span>
          </button>

          {!isMobile &&
          <MaximizeButton className={styles.btnMaximizar} />
          }
        </div>
      </div>
      <TabStrip selected={selected} onSelect={handleSelect} scrollable={isMobile}>
        <TabStripTab title="Desconformidades" key={'Desconformidades'}>
          <ExcelExport
            fileName='Resultado_Desconformidade'
            data={desconformidades.map((desconformidade: any) => ({
              ...desconformidade,
              data_registo: format(
                new Date(desconformidade.data_registo),
                'dd/MM/yyyHHmm'
              ),
            }))}
            ref={(exporter) => {
              _export = exporter
            }}
          >
            <Grid
              className={styles.form}
              sortable={true}
              onDataStateChange={dataStateChange}
              data={
                dataResult.data.length > 0 
                ? dataResult
                 :desconformidades
                }
              {...dataState}
              pageable={{
                buttonCount: 5,
                info: true,
                type: 'numeric',
                pageSizes: true,
                previousNext: true,
              }}
              total={desconformidades.length}
              //groupable={true}
            >
              <GridColumn cell={CommandCell} width={56} />

              {colums?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}

              
            </Grid>
          </ExcelExport>
          <GridPDFExport
            fileName='Resultado_Desconformidade'
            ref={(element) => {
              _pdfExport = element
            }}
            paperSize={['340mm', '297mm']}         

            margin="1cm"
          >
            <Grid
              className={styles.form}
              sortable={true}
              onDataStateChange={dataStateChange}
              data={dataResult.data.length > 0 ? dataResult : desconformidades}
              {...dataState}
              pageable={{
                buttonCount: 5,
                info: true,
                type: 'numeric',
                pageSizes: true,
                previousNext: true,
              }}
              //groupable={true}
            >
              <GridColumn cell={CommandCell} width={56} />
              {colums?.columns?.map((column, i) => {
                  return (
                    column.show && (
                      <GridColumn key={i} field={column.field} width={column.width} title={column.title} />
                    )
                  );
                })}
            </Grid>
          </GridPDFExport>
        </TabStripTab>

        <TabStripTab title="Documentos" key={'Documentos'}>
          <ExcelExport
            fileName='Resultado_Documento_Desconformidade'
            data={documentos}
            ref={(exporter) => {
              _export = exporter
            }}
          >
            <Grid
              className={styles.form}
              sortable={true}
              onDataStateChange={dataStateChangeDocumentos}
              data={
                dataResultDocumentos.data.length > 0
                  ? dataResultDocumentos
                  : documentos
              }
              {...dataState}
              pageable={{
                buttonCount: 5,
                info: true,
                type: 'numeric',
                pageSizes: true,
                previousNext: true,
              }}
              //groupable={true}
            >
              <GridColumn cell={documentsCustomCell} width={86} />
             {columsDocuments?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
            </Grid>
          </ExcelExport>

          <GridPDFExport
            fileName='Resultado_Documentos_Desconformidade'
            ref={(element) => {
              _pdfExport = element
            }}
            margin="1cm"
          >
            <Grid
              className={styles.form}
              sortable={true}
              onDataStateChange={dataStateChangeDocumentos}
              data={
                dataResultDocumentos.data.length > 0
                  ? dataResultDocumentos
                  : documentos
              }
              {...dataState}
              pageable={{
                buttonCount: 5,
                info: true,
                type: 'numeric',
                pageSizes: true,
                previousNext: true,
              }}
              //groupable={true}
            >
              <GridColumn cell={documentsCustomCell} width={86} />
              {columsDocuments?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
            </Grid>
          </GridPDFExport>
        </TabStripTab>
      </TabStrip>

      <div
        className={clsx(styles.footer, styles.justify_one_child, isCurrentViewMaximized && commonStyles.maximizedFooter)}
      >
        <Button className={styles.btnPesquisar} onClick={clearSearchResults} title='Nova Pesquisa'>
          <span className="icon-siarl icon-pesquisar btnIconPesquisar"></span>
          <span className={styles.rotulo}>
            {' '}&nbsp;{' '}
            Nova Pesquisa
          </span>
        </Button>
      </div>
    </div>
    {openForm && (
        <Dialog
        className="ModalDocumentos"
          title={`Documento: ${idDoDocumento}`}
          onClose={() => setOpenForm(false)}
          style={{ zIndex: 999 }}
        >
          
            <ImagePreviewV2
              documentUrl={urlDoDocumento}
              next={(e: any) => nextPhoto(e)}
              previous={(e: any) => previousPhoto(e)}
            />

        </Dialog>
      )}
  </>
)
}