import { Checkbox, CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import styles from '../../styles/ocorrencia/ConsultarOcorrenciaForm.module.scss'
import { APOIO_GESTAO_MODULE_NAME, CALCULO_PERFIL_MODULE_NAME, DOCUMENTOS_MODULE_NAME, DOMINIO_HIDRICO_MODULE_NAME, ENTIDADES_MODULE_NAME, ESTRATEGIA_ADAPATACAO_MODULE_NAME, GEOVISUALIZADORES_MODULE_NAME, INTERVENCAO_MODULE_NAME, MODELOS_MODULE_NAME, OCORRENCIAS_MODULE_NAME, USO_DO_SOLO_MODULE_NAME } from '../../utils/constants';

export const itemRenderMais = (props: { item: any; itemIndex: number }) => {
  const isTitleItem = props.item.key === 'titulo';

  const isButton = props.item.elementType === 'button';
  
  const isFilterButton = props.item.elementType === 'filterButton';

  if (isTitleItem) {
    // Item de título personalizado
    return (
      <div className={styles.DropDownButtonTitulo}>
        <span>{props.item.text}</span>
      </div>
    );
  }else if (isButton) {
    // Demais itens (botão)
    return (
      <div className={styles.DropDownButtonItemBtn}>
        <span className={props.item.className}></span>
        {' '}&nbsp;{' '}
        {props.item.text}
      </div>
    );
  }else if(isFilterButton){
    return (
      <Checkbox
        label={props.item.text}
        key={props.item.key}
        value={props.item.checked}
        style={{color:'black'}}
        defaultChecked={props.item.checked}
        onChange={(e)=> props.item.filterCallback(e, props.item)}
      />
    );
  }
  else {
    // Demais itens
    return (
      <div className={styles.DropDownButtonItem}>
        <span className={props.item.className}></span>
        {' '}&nbsp;{' '}
        <span>{props.item.text}</span>
      </div>
    );
  }
};

export const elementoClickMais = (event: any) => {

  const link: HTMLAnchorElement | null = document.querySelector('#' + event.item.key);

  if (link) {
    link.click();
  }

}

export const mostraMapa = () => {

  const iframe: any = document.getElementById('iframeGeoPortal');

  if (iframe) {
    iframe.style.display = 'block';
  }
  
}

export const ocultaMapa = () => {

  const iframe: any = document.getElementById('iframeGeoPortal');

  if (iframe) {
    iframe.style.display = 'none';
  }
  
}

export const ocultaFloatingMenu_e_Mapa = () => {

  postMessage({ type: "hideFloatingMenu", value: "true" }, "*");

  ocultaMapa();
}

export const ocultaFloatingMenu_e_Mapa_e_Mostra_Form = () => {

  ocultaFloatingMenu_e_Mapa();

  mostraFormulario();
}

export const mostraFloatingMenu_e_Mapa = () => {

  postMessage({ type: "showFloatingMenu", value: "true" }, "*");

  mostraMapa();
}

export const mostraFloatingMenu_e_Mapa_e_Oculta_Form = () => {

  mostraFloatingMenu_e_Mapa();

  ocultaFormulario();
}

const chavesDoForm = '[class*="ConsultarOcorrenciaForm_base"], [class*="Form_base__"], [class*="DominioHidrico_base__"], [class*="Intervencao_base__"], [class*="Geovisualizadores_base__"], [class*="ApoioGestao_base__"], [class*="ApoioGestao_base__"], [class*="EstrategiaAdaptacao_base__"], [class*="Documentos_base__"]';

export const mostraFormulario = () => {

  // if (sessionStorage.getItem("Modulo") == OCORRENCIAS_MODULE_NAME) {
  //   postMessage({type: "form", value: "consultaOcorrencias"});
  // }
  if (sessionStorage.getItem("Modulo") == DOMINIO_HIDRICO_MODULE_NAME) {
    postMessage({type: "form", value: "pesquisarDominioHidrico"});
  }
  if (sessionStorage.getItem("Modulo") == INTERVENCAO_MODULE_NAME) {
    postMessage({type: "form", value: "pesquisarIntervencao"});
  }

  if (sessionStorage.getItem("Modulo") == USO_DO_SOLO_MODULE_NAME) {
    postMessage({type: "form", value: "pesquisarUsoDoSolo"});
  }

  if (sessionStorage.getItem("Modulo") == GEOVISUALIZADORES_MODULE_NAME) {
    postMessage({type: "form", value: "listarGeovisualizadores"});
  }

  if (sessionStorage.getItem("Modulo") == GEOVISUALIZADORES_MODULE_NAME) {
    postMessage({type: "form", value: "listarGeovisualizadores"});
  }

  if (sessionStorage.getItem("Modulo") == APOIO_GESTAO_MODULE_NAME) {
    postMessage({type: "form", value: "pesquisarApoioGestaoPretensao"});
  }

  if (sessionStorage.getItem("Modulo") == ESTRATEGIA_ADAPATACAO_MODULE_NAME) {
    postMessage({type: "form", value: "pesquisarEstrategiaAdaptacao"});
  }

  if (sessionStorage.getItem("Modulo") == ENTIDADES_MODULE_NAME) {
    postMessage({type: "form", value: "diretorioEntidades"});
  }

  if (sessionStorage.getItem("Modulo") == MODELOS_MODULE_NAME) {
    postMessage({type: "form", value: "pesquisarModelos"});
  }

  if (sessionStorage.getItem("Modulo") == DOCUMENTOS_MODULE_NAME) {
    postMessage({type: "form", value: "pesquisarDocumentos"});
  }

  if (sessionStorage.getItem("Modulo") == DOCUMENTOS_MODULE_NAME) {
    postMessage({type: "form", value: "pesquisarDocumentos"});
  }

  if (sessionStorage.getItem("Modulo") == CALCULO_PERFIL_MODULE_NAME) {
    postMessage({type: "form", value : "calculoPerfisGeograficosDinamicos"}, "*");
  }

  
   const formulario: any = document.querySelector(chavesDoForm);

   if (formulario) {
     formulario.style.display = '';
   }

}

export const ocultaFormulario = () => {
  //postMessage({type: "form", value: ""});
   const formulario: any = document.querySelector(chavesDoForm);

   if (formulario) {
     formulario.style.display = 'none';
   }

}

export const telaMobile = 481;