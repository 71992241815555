import { Field, FormRenderProps } from "@progress/kendo-react-form"
import styles from '../../../styles/estrategiaAdaptacao/EstrategiaAdaptacao.module.scss';
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { coordenadasPoligono, entidades, get, post } from "../../../services/utils";
import { useEffect, useState } from "react";
import { filterBy } from "@progress/kendo-data-query";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import FieldsetCombosLocalizacao from "../../localizacao/FieldsetCombosLocalizacao";
import ValidatedIdentificacaoCheckbox from "./ValidationComponentes/ValidatedIdentificacaoCheckbox";
import { requiredFieldValidator } from "../../../validations/ocorrencia";
import useVisibleColumns, { Key } from "../../../hooks/useVIsibleColumns";
import SessionStorageService from "../../../services/SessionStorageService";
import { Grid, GridCellProps, GridColumn, GridPageChangeEvent, GridToolbar } from "@progress/kendo-react-grid";
import { PagerTargetEvent } from '@progress/kendo-react-data-tools'
import { ResultadoValorUsoDoSoloCommandGridCell } from "../../usosdosolo/ResultadoValorUsoDoSoloCommandGridCell";
import { useValorUsoDoSoloStore } from "../../../stores/valorUsoDoSolo";
import DetalheValorUsoDoSolo from "../../usosdosolo/DetalheValorUsoDoSolo";
import { Dialog } from '@progress/kendo-react-dialogs'
import VisualizadorColunas from "../../Generico/VisualizadorColunas ";
import { ResultadoAcaoCommandGridCell } from "../../intervencao/ResultadoAcaoCommandGridCell";
import { process } from '@progress/kendo-data-query';
import { CONSULTAR_ACAO, PESQUISAR_ACAO } from "../../../utils/constants";
import { Line } from "react-chartjs-2";
import { useCommonStore } from "../../../stores/common";
import html2canvas from "html2canvas";
import ReactGA from 'react-ga4';

type IProps = {
    formRenderProps?: FormRenderProps
    viewMode?: boolean
    colunasVisiveis?:any

}
type ValorTituloUsoDoSolo = {
    id: number;
    vus: string;
    tipo: string;
    valor: string;
}
type PageState = {
    skip: number
    take: number
}
const MILHAO_CONVERTER = 1_000_000;
const keyPainelPrincipal: Key = 'acaoColunasVisiveis';
export default function AcaoEstrategiaAdaptacao({
    viewMode, colunasVisiveis
}: IProps) {
    const getAcao = async (id: any) => await get<any, any>(`${PESQUISAR_ACAO}/` + id)
    const [acoes, setacoes] = useState([])
    const [labels, setLabels] = useState<number[]>([])
    const [labelsY, setLabelsY] = useState<number[]>([])
    const [dataState, setDataState] = useState({
        skip: 0,
        take: 10,
        sort: [],
        group: [],
    });
    const initialDataState: PageState = { skip: 0, take: 10 }
    const [page, setPage] = useState<PageState>(initialDataState)
    const [dataResult, setDataResult] = useState(
        process(acoes, dataState)
    );
    const dataStateChange = (event: any) => {
        setDataResult(process(acoes, event.dataState));
        setDataState(event.dataState);
    };
    const handleInfoClickById = (acaoLocal: Record<string, any>) => async () => {

        const id = acaoLocal.int_acao_id ?? acaoLocal.id;
        const acao = await getAcao(id);

        if (!acao) {
            postMessage({ type: "infoMsg", value: { message: "Registo desativado" } }, "*");
            return;
        }
    }
    const handleLocationClickById = (acaoLocal: Record<string, any>) => async () => {
        const id = acaoLocal.int_acao_id ?? acaoLocal.id;
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage({ type: "SiarlIntAcaoHighlight", value: [id] }, '*')
        restoreView();
    }

    const acaoCustomCell = (props: GridCellProps) => (
        <ResultadoAcaoCommandGridCell
            {...props}
            onLocationClick={handleLocationClickById}
        />
    )
    const getAcoes = async (data: any) => await post<any, any>(`${CONSULTAR_ACAO}`, data)


    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/AcaoEstrategiaAdaptacao" });
    }, []);
    const  [data, setData]= useState({
        labels,
        datasets:[{
            
                    label: "Investimento Realizado",
                    data: labelsY,
                    borderColor: "blue",
                    backgroundColor: "white"
        }]
    })

    const linearRegression = (data: { x: number; y: number }[]) => {
        const n = data.length;
      
        const sumX = data.reduce((acc, point) => acc + point.x, 0);
        const sumY = data.reduce((acc, point) => acc + point.y, 0);
        const sumXY = data.reduce((acc, point) => acc + point.x * point.y, 0);
        const sumXSquare = data.reduce((acc, point) => acc + point.x ** 2, 0);
      
        const slope = (n * sumXY - sumX * sumY) / (n * sumXSquare - sumX ** 2); // inclinacao 
        const intercept = (sumY - slope * sumX) / n;  
      
        return { slope, intercept };
      };

    useEffect(() => {
        const getAccoesNaAreaSelecionada = async () => {
            postMessage({ type: 'showLoader' }, '*')
            try {
                const results: any = await getAcoes({
                    flatCoordinates: coordenadasPoligono ?? undefined, 
                    isUsarIPC:true,
                })
                if (results) {
                    SessionStorageService.setItem('resultadoEaAcaoPesquisa', JSON.stringify(results))
                    setacoes(results)
                    const idsAcao = results?.map((o: { id: number }) => o.id);
                    const iframe: any = document.getElementById('iframeGeoPortal');
                    iframe.contentWindow?.postMessage({ type: "SiarlIntAcaoSearch", value: idsAcao }, '*');
                    const xy = results?.map((o: { inv_realizado: number, data_ini_e: string }) => {
                        const y = o.inv_realizado/MILHAO_CONVERTER;
                        const x = o.data_ini_e ? new Date(o.data_ini_e).getFullYear() : null;
                        if (y !== undefined && x !== null) {
                          return { y, x };
                        } else {
                          return null;
                        }
                      }).filter((point:any) => point !== null) as { y: number; x: number }[];
                    
                    
                      if (xy.length >= 2) {
                            const regressionResult = linearRegression(xy);
                            const ys = results.map((x:{data_ini_e:string}) => regressionResult.slope * new Date(x.data_ini_e).getFullYear() + regressionResult.intercept);
                            const xs: number[] = Array.from(new Set(
                            results.map((o:any) => new Date(o.data_ini_e).getFullYear())
                          )).filter((year:any): year is number => !isNaN(year));

                          const investimento_realizado= results?.map((o: { inv_realizado: number})=> (o.inv_realizado/MILHAO_CONVERTER)) 
                          const results_investimento:any[] =results;

                            try{
                                results_investimento.forEach((element, index)=>{
                                        if(index==0){
                                            element.inv_realizado_acumulado = element.inv_realizado;
                                            element.inv_realizado_acumulado_me = element.inv_realizado/MILHAO_CONVERTER;
                                        }
                                       else if(index==1){
                                             element.inv_realizado_acumulado = results_investimento[0].inv_realizado+ results_investimento[index].inv_realizado;
                                             element.inv_realizado_acumulado_me = element.inv_realizado_acumulado/MILHAO_CONVERTER;
                                         }
                                         else{
                                            if(element!=null){
                                                const acumuladoAnterior = results_investimento[index-1]?results_investimento[index-1]?.inv_realizado_acumulado:0;
                                                const posteriorInvRealizado =results_investimento[index+1]? results_investimento[index+1]?.inv_realizado:0;
                                                if(acumuladoAnterior && posteriorInvRealizado ){
                                                    const acumulado = acumuladoAnterior+ posteriorInvRealizado;
                                                    element.inv_realizado_acumulado =acumulado ;
                                                    element.inv_realizado_acumulado_me = acumulado/MILHAO_CONVERTER;
                                                }
                                            }
                                         }
                                })
                            }
                            catch(ex){
                                console.log({ex})
                            }
                             const xyAcumulados = results_investimento?.map((o: { inv_realizado_acumulado_me: number, data_ini_e: string }) => {
                                 const y = o.inv_realizado_acumulado_me;
                                 const x = o.data_ini_e ? new Date(o.data_ini_e).getFullYear() : null;
                                 if (y !== undefined && x !== null) {
                                   return { y, x };
                                 } else {
                                   return null;
                                 }
                               }).filter((point:any) => point !== null) as { y: number; x: number }[];
                               const regressionResultAcumulado = linearRegression(xyAcumulados);
                               const CD2050 = regressionResultAcumulado.slope * 2050 + regressionResultAcumulado.intercept;
                               const CD2100 = regressionResultAcumulado.slope * 2100 + regressionResultAcumulado.intercept;
                               const ANO_ACTUAL = regressionResultAcumulado.slope * new Date().getFullYear() + regressionResultAcumulado.intercept;
                               SessionStorageService.setItem("custoAdaptacao",JSON.stringify( {
                                   custoAdaptacao50:CD2050, 
                                   custoAdaptacao100:CD2100,
                                   anoAtual:ANO_ACTUAL
                               }))

                              const ysAcumulado = results_investimento.map((x:{data_ini_e:string}) => regressionResultAcumulado.slope * new Date(x.data_ini_e).getFullYear() + regressionResultAcumulado.intercept);
                              const investimento_realizado_acumulado= results_investimento?.map((o: { inv_realizado_acumulado_me: number,data_ini_e:string})=> (o.inv_realizado_acumulado_me));
                            setData({
                                labels:xs.sort((a, b) => a - b), 
                                datasets:[
                                    {
                                    label: "Linear (Investimento Realizado [M€])",
                                    data: ys,
                                    borderColor: "lightblue",
                                    backgroundColor: "white"
                                }, 
                                {
                                    label: "Investimento Realizado [M€]",
                                    data: investimento_realizado,
                                    borderColor: "blue",
                                    backgroundColor: "white"
                                },
                                {
                                    label: "Investimento Realizado Acumulado [M€]",
                                    data: investimento_realizado_acumulado,
                                    borderColor: "green",
                                    backgroundColor: "white"
                                },
                                {
                                    label: "Linear (Investimento Realizado Acumulado [M€]) ",
                                    data: ysAcumulado,
                                    borderColor: "lightgreen",
                                    backgroundColor: "white"
                                }
                            ]
                            })

                        } 
                      
                        saveScreenById("imgsAcao")
                }
            } catch (error) {
                postMessage({ type: 'errorMsg', value: { message: "Não foi possivel efetuar esta operação" } }, '*')
            } finally {
                postMessage({ type: 'hideLoader' }, '*')
            }

        }
        getAccoesNaAreaSelecionada();

    }, [])
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "top" as const,
            },
            title: {
                display: true,
                text: "Investimento em obras de defesa"},
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Tempo',
                    color: 'black'
                }
            },
            y: {
                title: {
                    display: true,
                    text: 'Investimento [M€]',
                    color: 'black'
                }
            }
        }
    };
    const restoreView = useCommonStore(state => state.restoreView)

 const saveScreenById = async (key:string)=>{
    let mapImage:any = undefined;
      const divGraficoArea= document.querySelector(
        '#graficoAcao'
      ) as HTMLCanvasElement

      const generatedImageGrafico = await html2canvas(divGraficoArea)
      const grafico = generatedImageGrafico.toDataURL()
 
    if (!window.location.href.includes('localhost')) {

    const mapIframe = document.querySelector(
        '#iframeGeoPortal'
      ) as HTMLIFrameElement

      const mapIframeInnerContent =
        mapIframe.contentWindow?.document.querySelector(
          'section.content'
        ) as HTMLElement

      const generatedImage = await html2canvas(mapIframeInnerContent)
      mapImage = generatedImage.toDataURL()
    
    }
    SessionStorageService.setItem(key, JSON.stringify({
        img_grafico_acao:grafico,
       img_mapa_acao:mapImage
      }))
 }
 
    return (
        <>   <div className={`${styles.form} ${styles.removeScrollDuplicado}`}>
                    <Line options={options} data={data} id="graficoAcao"/>

            <fieldset>
            <div>
            <Grid
                sortable={true}
                onDataStateChange={dataStateChange}
                data={dataResult.data.length > 0 ? dataResult : acoes.slice(page.skip, page.take + page.skip)}
                {...dataState}
                pageable={{
                    buttonCount: 5,
                    info: true,
                    type: 'numeric',
                    pageSizes: true,
                    previousNext: true,
                }}
                {...dataState}
                skip={page.skip}
                take={page.take}
                total={dataResult.data.length > 0 ? dataResult.total : acoes.length}
                //groupable={true}
            >

                <GridColumn cell={acaoCustomCell} width={50} />
                {colunasVisiveis?.columns?.map((column:any, i:number) => {
                    return (
                        column.show && (
                            <GridColumn key={i} field={column.field} width={column.width} title={column.title} />
                        )
                    );
                })}


            </Grid>
            </div>
            </fieldset>

            <div>
            </div>
        </div>

        </>);
}
