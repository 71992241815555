import {
  Dispatch,
  MouseEvent as ReactMouseEvent,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useState
} from 'react'

import {
  Grid,
  GridCellProps,
  GridColumn,
  GridPageChangeEvent,
} from '@progress/kendo-react-grid'

import { Button, DropDownButton } from '@progress/kendo-react-buttons'
import { PagerTargetEvent } from '@progress/kendo-react-data-tools'
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from '@progress/kendo-react-layout'
import { format } from 'date-fns'
import { CSVLink } from 'react-csv'
import SessionStorageService from '../../services/SessionStorageService'
import { GetUserNameTecnico, TiposUtilizacao, b64toBlob, estadosTitulo, exportPDFWithMethod, get, handleDownload, hasPermission, post, setusoSoloSelected, tipoDeProcesso, tipoTitulos } from '../../services/utils'
import { useUsoDoSoloStore } from '../../stores/usoDoSolo'
import styles from '../../styles/usodosolo/ResultadoUsoDoSoloForm.module.scss'
import commonStyles from '../../styles/common/Common.module.scss'
import { CONSULTAR_US_TITULO, CURRENT_TIMEZONE, GET_DOCUMENTO, GET_DOCUMENTO_BY_US_TITULO, GET_US_TITULO_BY_ID, GET_US_TITULO_SHAPEFILE, PERMISSION_USOSOLO_PERFIL_COMPLETO_EDICAO, PERMISSION_USOSOLO_PERFIL_COMPLETO_LEITURA, PERMISSION_USOSOLO_PERFIL_INTERMEDIO_EDICAO, PERMISSION_USOSOLO_PERFIL_INTERMEDIO_LEITURA, PERMISSION_USOSOLO_PERFIL_TOTAL, URL_AJUDA_USOS_SOLO_TITULO, clearLayers } from '../../utils/constants'
import { ResultadoTituloUsoDoSoloCommandGridCell } from './ResultadoTituloUsoDoSoloCommandGridCell'
import clsx from 'clsx'
import { useCommonStore } from '../../stores/common'
import MaximizeButton from '../common/MaximizeButton'
import useClearMap from '../../hooks/useClearMap'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { process } from '@progress/kendo-data-query'
import { GridPDFExport } from '@progress/kendo-react-pdf'
import { ResultadoDocumentoCommandGridCell } from '../documentos/ResultadoDocumentoCommandGridCell'
import { Dialog } from '@progress/kendo-react-dialogs'
import ImagePreviewV2 from '../viewer/ImagePreviewV2'
import VisualizadorColunas from '../Generico/VisualizadorColunas '
import useVisibleColumns, { Key } from '../../hooks/useVIsibleColumns'
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais'
import ReactGA from 'react-ga4';
import moment from 'moment'
import "moment-timezone"

type IProps = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
  setShowResults: Dispatch<SetStateAction<boolean>>
  setShowRegistarUsoDoSoloForm?: Dispatch<SetStateAction<boolean>>
}

type PageState = {
  skip: number
  take: number
}

const initialDataState: PageState = { skip: 0, take: 20 }

type TituloUsoDoSolo = {
  id: number;
  designacao: string;
  id_processo: string;
  utilizador_id: number;
  utilizador?: any;
  data_registo: string;
  data_entrada_vigor: string;
  data_expiracao: string;
  data_alteracao_licenciamento: string;
  freguesia_cod?: any;
  nuts3_cod?: any;
  geom_imported?: any;
  codigo_processo: string;
  codigo_utilizacao: string;
  categoria?: any;
  tipo_processo_id?: any;
  tipo_utilizacao_id?: any;
  observacoes: string;
  geom?: any;
  dataEmissao: string;
  urlTitulo: string;
}

const keyPainelPrincipal:Key = 'tituloUsoSoloColunasVisiveis';
const keyPainelDocumentos:Key = 'tituloUsoSoloDocumentosColunasVisiveis';
export default function ResultadoUsoDoSoloForm({
  onCloseClick,
  setShowRegistarUsoDoSoloForm,
  setShowResults
}: IProps) {
  const [isMobile, setIsMobile] = useState(false);
  const [selected, setSelected] = useState<number>(0)

  const [tituloUsoDoSolo, setTituloUsoDoSolo] = useState<any[]>([])
  const [documentosUsTitulo, setDocumentosUsTitulo] = useState([])

  const [page, setPage] = useState<PageState>(initialDataState)
  const [pageSizeValue, setPageSizeValue] = useState<
    number | string | undefined
  >()
  
   const getDocumento = async (data: any) =>
  await get<any, any>(`${GET_DOCUMENTO}/` + data)

  const isCurrentViewMaximized = useCommonStore(state => state.isCurrentViewMaximized)

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected)
  }
  const handlePageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent
    const take = event.page.take

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value)
    }

    setPage({
      ...event.page,
      take,
    })
  }
  
   const [dataStateDocumentos, setDataStateDocumentos] = useState({
    skip: 0,
    take: 20,
    sort: [],
    group: [],
  });
  
  const dataStateChangeDocumentos = (event: any) => {
    setDataResultDocumentos(process(documentosUsTitulo, event.dataState));
    setDataStateDocumentos(event.dataState);
  };

  const [dataResultDocumentos, setDataResultDocumentos] = useState(
    process(documentosUsTitulo, dataStateDocumentos)
  );
  
  const getUsoDoSolo = async (data: any) =>
    await get<any, any>(`${GET_US_TITULO_BY_ID}/` + data)

    const getDocumentosByUsTituloIDs = async (data: any) =>
  await post<any, any>(`${GET_DOCUMENTO_BY_US_TITULO}` , data)

  const getShapeFile = async (data: any) =>
  await post<any, any>(`${GET_US_TITULO_SHAPEFILE}`, data)

   const [urlDoDocumento, setUrlDoDocumento] = useState("");
  const [idDoDocumento, setIdDoDocumento] = useState("");
  const [openForm, setOpenForm] = useState<boolean>(false)
  
    const documentosToPreview = documentosUsTitulo;

    const {getVisibleColumns, removeColumnsKeys} = useVisibleColumns();

    const [clearMap] = useClearMap();
  const clearSearchResults = () => {
    removeColumnsKeys(keyPainelDocumentos)
    removeColumnsKeys(keyPainelPrincipal)
    clearMap(clearLayers["CLEAR_LAYERS_US_TITULO"]);
    SessionStorageService.removeItem('resultadoUsoDoSoloPesquisa')
    sessionStorage.removeItem('lastSearchTitulo')
    postMessage({ type: 'resultadoUsoDoSoloPesquisaChange' }, '*')

    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
        { type: clearLayers["CLEAR_LAYERS_US_TITULO"], value: 'true' },
        '*'
    )
  }
  const selectUsoDoSoloResultId = useUsoDoSoloStore(
    (state) => state.selectUsoDoSoloResultId
  )
  const selectUsoDoSoloResult = useUsoDoSoloStore(
    (state) => state.selectUsoDoSoloResult
  )

  const consultarTitulo = async (data: any) =>
  await post<any, any>(`${CONSULTAR_US_TITULO}`, data)


  useEffect(() => {
    if (sessionStorage.getItem("lastSearchTitulo") && !SessionStorageService.getItem("firstCallUsTitulo")) {
      postMessage({ type: "showLoader" }, "*");
      const objToSearch = sessionStorage.getItem("lastSearchTitulo") ?? '';
      consultarTitulo(objToSearch).then((data) => {
        SessionStorageService.setItem("resultadoUsoDoSoloPesquisa", JSON.stringify(data));
        setResultsTitulo();
        postMessage({ type: "hideLoader" }, "*");
      })
    } 
    else {
      setResultsTitulo();
  }
  
  }, []);

  const setResultsTitulo= () => {    
    
    getVisibleColumns(keyPainelPrincipal, setColums)
    getVisibleColumns(keyPainelDocumentos, setColumsDocuments)
    let titulosUsoDoSolo = JSON.parse(
      SessionStorageService.getItem('resultadoUsoDoSoloPesquisa') ?? ''
    );
    
    if (titulosUsoDoSolo) {
      // Ajuste das datas aqui
      titulosUsoDoSolo = titulosUsoDoSolo.map((tituloUsoDoSolo: any) => ({
        ...tituloUsoDoSolo,
          data_entrada_vigor: tituloUsoDoSolo.data_entrada_vigor ? moment.utc(tituloUsoDoSolo.data_entrada_vigor).tz(CURRENT_TIMEZONE).format("DD/MM/yyyy") : '',
          data_expiracao: tituloUsoDoSolo.data_expiracao ? moment.utc(tituloUsoDoSolo.data_expiracao).tz(CURRENT_TIMEZONE).format("DD/MM/yyyy") : '',
          data_registo: tituloUsoDoSolo.data_registo ? moment.utc(tituloUsoDoSolo.data_registo).tz(CURRENT_TIMEZONE).format("DD/MM/yyyy HH:mm:ss") : ''
      }));
    }


    if (tituloUsoDoSolo) {
      const iframe: any = document.getElementById('iframeGeoPortal')
      const ids: number[] = []
      const idsApoio: number[] = []
      tituloUsoDoSolo.forEach((element: any) => {
        ids.push(element.id)
      })
      iframe.contentWindow?.postMessage(
        { type: 'SiarlUsTituloSearch', value: ids },
        '*'
      )
    }

    const ids = titulosUsoDoSolo?.map((x: any) =>x.id);

    setTituloUsoDoSolo(titulosUsoDoSolo)
     getDocumentosByUsTituloIDs(ids).then(result => {
        setDocumentosUsTitulo(result);
        postMessage({ type: "hideLoader" }, "*");
      })
  }

  useEffect(() => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    const ids: number[] = []
    tituloUsoDoSolo.forEach((element: any) => {
      ids.push(element.id)
    })
    iframe.contentWindow?.postMessage(
      { type: 'SiarlUsTituloSearch', value: ids },
      '*'
    )
  })


   const documentCustomCell = (props: GridCellProps) => (
    <ResultadoDocumentoCommandGridCell
    onLocationMouseEnter={function (documentos: Record<string, unknown>): void {
      console.log("n/a");
    } } onLocationMouseLeave={function (documentos: Record<string, unknown>): void {
      console.log("n/a");
    } } {...props}
    onInfoClick={handleInfoClickById}
    onLocationClick={handleLocationClick}
    onDocumentPreviewClick={handleDocumentPreviewClickById}    />
  )

   const nextPhoto = async (e: any) => {

    try{

      postMessage({ type: "showLoader" }, "*");
      const nextIds = documentosToPreview
        .filter((doc: any) => doc.id < idDoDocumento && doc.url !== '')
        .map((doc: any) => doc.id);
  
      if (nextIds.length > 0) {
        const nextId = Math.max(...nextIds);
        console.log(`O próximo ID é ${nextId}`);
  
        const documento = await getDocumento(nextId);
  
        setIdDoDocumento(nextId.toString());
        setUrlDoDocumento(documento.url);

        const iframe: any = document.getElementById('iframeGeoPortal')
        const id = documento.entidade_id ?? documento.id;
        iframe.contentWindow?.postMessage(
          {
            type: 'SiarlEntidadesCenter',
            value: [id],
          },
          '*'
        )
  
      } else {
        console.log("Não há um próximo ID na lista.");
      }

    }catch(error){

      postMessage({ type: "errorMsg", value: "Não foi possivel abrir o documento!" }, "*");

    }finally{

      postMessage({ type: "hideLoader" }, "*");

      e.preventDefault();
    }
   
  }

  const previousPhoto = async (e: any) => {

    try{

      postMessage({ type: "showLoader" }, "*");
      const previousIds = documentosToPreview
        .filter((doc: any) => doc.id > idDoDocumento && doc.url !== '')
        .map((doc: any) => doc.id);
  
      if (previousIds.length > 0) {
        const previousId = Math.min(...previousIds);
        console.log(`O anterior ID é ${previousId}`);
  
        const documento = await getDocumento(previousId);
  
        setIdDoDocumento(previousId.toString());
        setUrlDoDocumento(documento.url);

        const iframe: any = document.getElementById('iframeGeoPortal')
        const id = documento.entidade_id ?? documento.id;
        iframe.contentWindow?.postMessage(
          {
            type: 'SiarlEntidadesCenter',
            value: [id],
          },
          '*'
        )
      } else {
        console.log("Não há um anterior ID na lista.");
      }

    }catch(error){

      postMessage({ type: "errorMsg", value: "Não foi possivel abrir o documento!" }, "*");

    }finally{

      postMessage({ type: "hideLoader" }, "*");

      e.preventDefault();
    }
       
  }

  const openPdfNewTab = () => {
    return new Promise<void>((resolve, reject) => {
        
        postMessage({ type: "showLoader" }, "*");

        setTimeout(() => {
           const columnsToShow = colums.columns.filter(x => x.show);
            const dataToExport = tituloUsoDoSolo.map((item: any) => {
                const filteredItem: any = {};
                columnsToShow.forEach((column: any) => {
                    filteredItem[column.field] = item[column.field];
                });
                return filteredItem;
            });
            
            exportPDFWithMethod(columnsToShow, dataToExport, 8)
                .then((pdfData: any) => {
                    postMessage({ type: "hideLoader" }, "*");

                        const base64Data = pdfData.split(";base64,")[1];
                        console.log(base64Data);
                        const blob = b64toBlob(base64Data, 'application/pdf');
                        const blobUrl = URL.createObjectURL(blob);

                      
                        window.open(blobUrl, '_blank');
                        resolve();
                  
                })
                .catch((error: any) => {
                    console.error('Error exporting PDF:', error);
                    reject(error);
                });
        }, 1000);
    });
};

   const handleDocumentPreviewClickById = (documentosLocal: Record<string, any>) => async () => {

    try {
      
      postMessage({ type: "showLoader" }, "*");
      const documentos = await getDocumento(documentosLocal.id);

      setIdDoDocumento(documentosLocal.id);
      setUrlDoDocumento(documentos.url);
      setOpenForm(true);

    } catch (error) {

      postMessage({ type: "errorMsg", value: "Não foi possivel abrir o documento!" }, "*");
      setOpenForm(false);

    } finally {

      postMessage({ type: "hideLoader" }, "*");

    }
  }

  const close = () => {
  setOpenForm(false);
}

useEffect(() => {
  ReactGA.send({ hitType: "pageview", page: "/ResultadoUsosDoSoloTitulo" });
}, []);

  const handleInfoClickById = (tituloUsoDoSoloLine: any) => async () => {

     const id = tituloUsoDoSoloLine.us_titulo_id ?? tituloUsoDoSoloLine.id;
     const tituloUsoDoSolo = await getUsoDoSolo(id);

     if (!tituloUsoDoSolo) {
      postMessage({type: "infoMsg", value: { message: "Registo desativado" }}, "*");
      return;
    }
    const tecnico = await GetUserNameTecnico(tituloUsoDoSolo.utilizador_id);
    
    selectUsoDoSoloResultId(tituloUsoDoSolo.id)
    const usosSoloObject = {
      ...tituloUsoDoSolo,
      titulo: tituloUsoDoSolo?.titulo ?? undefined,
      id: tituloUsoDoSolo?.id ?? undefined,
      idProcesso: tituloUsoDoSolo?.id_processo ?? undefined,
      data_entrada_vigor: tituloUsoDoSolo.data_entrada_vigor ? moment.utc(tituloUsoDoSolo.data_entrada_vigor).tz(CURRENT_TIMEZONE) : undefined,
      data_expiracao: tituloUsoDoSolo.data_expiracao ? moment.utc(tituloUsoDoSolo.data_expiracao).tz(CURRENT_TIMEZONE) : undefined,
      data_alteracao_licenciamento: tituloUsoDoSolo.data_alteracao_licenciamento ? moment.utc(tituloUsoDoSolo.data_alteracao_licenciamento).tz(CURRENT_TIMEZONE) : undefined,
      data_emissao: tituloUsoDoSolo.data_emissao ? moment.utc(tituloUsoDoSolo.data_emissao).tz(CURRENT_TIMEZONE) : undefined,
      designacao: tituloUsoDoSolo?.designacao ?? undefined,
      freguesia: tituloUsoDoSolo?.freguesia ?? undefined,
      massaagua : tituloUsoDoSolo?.massaagua ?? undefined,
      praia: tituloUsoDoSolo?.praia ?? undefined,
      tecnicoRegisto: tecnico,
      data_registo: tituloUsoDoSolo.data_registo ? moment.utc(tituloUsoDoSolo.data_registo).tz(CURRENT_TIMEZONE) : undefined,
      designacaoIdentificacao: tituloUsoDoSolo?.designacao ?? undefined,
      tipoDeProcesso: tituloUsoDoSolo?.tipo_processo_id && tipoDeProcesso ? tipoDeProcesso.find(x => x.id == tituloUsoDoSolo?.tipo_processo_id) : undefined,
      codProcesso: tituloUsoDoSolo?.codigo_processo ?? undefined,
      tipoUtilizacao: tituloUsoDoSolo?.tipo_utilizacao_id ? TiposUtilizacao.find(x => x.id == tituloUsoDoSolo?.tipo_utilizacao_id) : undefined,
      codUtilizacao: tituloUsoDoSolo?.codigo_utilizacao ?? undefined,
      categoria: tituloUsoDoSolo?.categoria ?? undefined,
      nifRequerente: tituloUsoDoSolo?.nif_requerente ?? undefined,
      nomeRequerente: tituloUsoDoSolo?.nome_requerente ?? undefined,
      obs: tituloUsoDoSolo?.observacoes ?? undefined,
      filedoc_cod: tituloUsoDoSolo?.filedoc_cod ?? undefined,
      estado_id: tituloUsoDoSolo?.estado ?? undefined,
      tipo_registo: tituloUsoDoSolo?.tipo_registo ?? undefined,
      urlTitulo: tituloUsoDoSolo?.url_titulo ?? undefined,
      tipoTitulo: tituloUsoDoSolo?.tipo_titulo_id ? tipoTitulos.find (x => x.id == tituloUsoDoSolo?.tipo_titulo_id) : undefined
    }
    sessionStorage.setItem('usGeomEdited', 'false')

    selectUsoDoSoloResult(usosSoloObject);
    setusoSoloSelected(usosSoloObject);
    setShowResults(false)
    SessionStorageService.setItem("novoUsTitulo", 'false');
    if (setShowRegistarUsoDoSoloForm) setShowRegistarUsoDoSoloForm(true)

    const iframe: any = document.getElementById('iframeGeoPortal')

    iframe.contentWindow?.postMessage({ type: "SiarlUsTituloSearch", value: [tituloUsoDoSolo?.id] }, '*')

    iframe.contentWindow?.postMessage({ type: "SiarlUsTituloCenter", value: [tituloUsoDoSolo?.id] }, '*')

  }

  const handleLocationClick = (usTitulo: Record<string, unknown>) => async () => {
    console.log(`#${usTitulo.id} - mostrar popover no mapa`)
    const iframe: any = document.getElementById('iframeGeoPortal')
    //iframe.contentWindow?.postMessage({ type: "SiarlOcorrenciasSearch", value: [ocorrencia.id] }, '*')
    if (usTitulo.id) {
      iframe.contentWindow?.postMessage(
        {
          type: 'SiarlUsTituloCenter',
          value: [usTitulo.id],
        },
        '*'
      )
    }
  }

  const conditionalProps = hasPermission(PERMISSION_USOSOLO_PERFIL_TOTAL) || hasPermission(PERMISSION_USOSOLO_PERFIL_COMPLETO_EDICAO) || hasPermission(PERMISSION_USOSOLO_PERFIL_COMPLETO_LEITURA) || hasPermission(PERMISSION_USOSOLO_PERFIL_INTERMEDIO_EDICAO) || hasPermission(PERMISSION_USOSOLO_PERFIL_INTERMEDIO_LEITURA) ?
  { 
    onInfoClick: handleInfoClickById,
    onLocationClick: handleLocationClick
  } 
  : {};

  const CommandCell = (props: GridCellProps) => (
    <ResultadoTituloUsoDoSoloCommandGridCell
      {...props}
      {...conditionalProps}
    />
  )

  const onItemClick = (event: any) => {

    const link: HTMLAnchorElement | null = document.querySelector('#print-results');

    if (link) {
      link.click();
    }

    // if (event.item.key == 'ExportarFichasResultados') {

      

    // } else if (event.item.key == 'ExportarListaResultados') {

    //   console.log(event.item.text);

    // }

  };

  const options = [
    {
      key: 'ExportarFichasResultados',
      text: 'Fichas',
    },
    {
      key: 'ExportarListaResultados',
      text: 'Resultados',
    },
  ]

  const itemRender = (props: { item: any; itemIndex: number }) => {
    const isTitleItem = props.item.key === 'titulo';
    if (isTitleItem) {
      // Item de título personalizado
      return (
        <div className={styles.DropDownButtonTitulo}>
          <span>{props.item.text}</span>
        </div>
      );
    } else {
      // Demais itens
      return (
        <div className={styles.DropDownButtonItem}>
          <span>{props.item.text}</span>
        </div>
      );
    }
  };

  const [dataState, setDataState] = useState({
    skip: 0,
    take: 20,
    sort: [],
    group: [],
  });
  const dataStateChange = (event: any) => {
    setDataResult(process(tituloUsoDoSolo, event.dataState));
    setDataState(event.dataState);
  };

  const [dataResult, setDataResult] = useState(
    process(tituloUsoDoSolo, dataState)
  );

  const optionsExportar = [
    {
      key: 'ExportarListaResultadosExcel',
      text: 'Excel',
    },
    {
      key: 'ExportarListaResultadosShapeFile',
      text: 'Shapefile',
    },
  ]
  
  let _pdfExport: any;
  const exportExcel = () => {
    _export.save();
  };

  const onItemClickExportar = async (event: any) => {
    if (event.item.key == 'ExportarListaResultadosExcel') {
      exportExcel();
    }
    else if (event.item.key == "ExportarListaResultadosShapeFile") {
      const results = JSON.parse(
        SessionStorageService.getItem('resultadoUsoDoSoloPesquisa') ?? ''
      )
  
      if (results) {
        const iframe: any = document.getElementById('iframeGeoPortal')
        const ids: number[] = []
        results.forEach((element: any) => {
          ids.push(element.id)
        })

        const file: any = await getShapeFile(ids);
        const filePath = 'shapeFileUsTitulos.zip';

        handleDownload(file, filePath);
    } 
  }
}

  let _export: any;
  const exportPDF = () => {
    _pdfExport.save();
  };

  const onItemClickImprimir = async (event: any) => {
    openPdfNewTab();
  }

  const [columsDocuments, setColumsDocuments] = useState<{ columns: { field: string, title: string, width: number | string, show: boolean }[] }>({
    columns: [
      { field: "id", width: 70, title: "ID", show: true },
      { field: "filedoc_cod", width: 190, title: "ID Documento Filedoc", show: true },
      { field: "data_publicacao", width: 150, title: "Data publicação", show: true },
      { field: "tipoRecurso.designacao", width: 150, title: "Tipo de recurso", show: true },
      { field: "titulo", width: 90, title: "Título", show: true },
      { field: "autores", width: 90, title: "Autor", show: true },
      { field: "entidade.nome", width: 90, title: "Entidade", show: true },
      { field: "id_estado", width: 'auto', title: "Estado", show: true },
    ]
  })

  const [colums, setColums]=useState<{columns:{field:string, title:string, width:number|string, show:boolean}[]}>({
    columns:[
      { field: "id", title: "ID", show: true, width: 70 },
      { field: "designacao", width: 90, title: "Nome", show: true },
      { field: "data_registo", width: 150, title: "Data registo", show: true },
      { field: "data_entrada_vigor", title: "Data entrada em vigor", show: true, width: 190 },
      { field: "data_expiracao", title: "Data Expiração", show: true, width: 150 },
      { field: "tipoUtilizacao.designacao", title: "Tipo Utilização", show: true, width: 150 },
      { field: "arh.designacao", title: "ARH", show: true, width: 150 },
      { field: "entidade.designacao", title: "Entidade", show: true, width: 150 },
      { field: "nif_requerente", title: "Nif Requerente", show: true, width: 150 },
      { field: "nome_requerente", title: "Nome Requerente", show: true, width: "auto" }, 
    ]
  })

  useEffect(() => {
    SessionStorageService.setItem(keyPainelDocumentos, JSON.stringify(columsDocuments));
  }, [columsDocuments]);
  
  useEffect(() => {
    SessionStorageService.setItem(keyPainelPrincipal, JSON.stringify(colums));
  }, [colums]);

  useLayoutEffect(() => {

    const handleResize = () => {

      if (window.innerWidth <= telaMobile) {
        
        setIsMobile(true);
        ocultaFloatingMenu_e_Mapa();

      }else{

        setIsMobile(false);
        mostraFloatingMenu_e_Mapa();

      }

    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);  


  const onItemClickMais = (event: any) => {

    elementoClickMais(event);
    
    onItemClickExportar(event);


  };

  const optionsMais = [
    {
      key: 'titulo',
      text: 'Imprimir',
    },
    {
      key: 'titulo',
      text: 'Exportar',
    },
    ...optionsExportar.map(option => ({
      ...option,
      className: 'icon-siarl icon-exportar btnIconRegistar',
    })),
  ]

  const registarNovoClick = () => {
    selectUsoDoSoloResultId(undefined);
    selectUsoDoSoloResult(undefined);
    sessionStorage.setItem('usGeomEdited', 'false')
    SessionStorageService.setItem("novoUsTitulo", 'true');
    postMessage({ type: "form", value: "registarUsoDoSolo" }, "*");
  }

  const openAjuda = () => {
    window.open(URL_AJUDA_USOS_SOLO_TITULO, "_blank")
  }

return (
  <div className={clsx(styles.base, isCurrentViewMaximized && commonStyles.maximizedBaseForm)}>
    <div className={styles.header}>
      <div>
        <div className={styles.nomeDoModulo}>Uso de solo</div>
        <div className={styles.tituloDaPagina}>Resultados de pesquisa</div>
      </div>

      <div>

        {hasPermission(PERMISSION_USOSOLO_PERFIL_TOTAL) || hasPermission(PERMISSION_USOSOLO_PERFIL_COMPLETO_EDICAO) || hasPermission(PERMISSION_USOSOLO_PERFIL_INTERMEDIO_EDICAO)?
          <Button
            onClick={registarNovoClick}
            className={styles.btnNovoRegisto}
            hidden={selected>0}
            style={{ display: isMobile ? 'none' : '' }}
            id='novoRegisto'
            title='Novo título'
          >
            <span className="icon-siarl icon-registar btnIconRegistar"></span>{' '}
            &nbsp;{' '}
            Novo título
          </Button>
        : ''}
        
        <CSVLink
          title="Imprimir resultados"
          id="print-results"
          data={[]}
          filename={`siarl-usoDoSolo-${format(new Date(), 'ddMMyyyHHmm')}`}
          hidden
        >
        </CSVLink>

        {!isMobile &&
        <VisualizadorColunas 
              columns={selected===0?colums:columsDocuments} 
              setColumns={selected===0?setColums:setColumsDocuments}
              styles={styles.btnEditar2} 
           />
        }
        
        <button className={styles.btnEditar2} onClick={onItemClickImprimir} style={{ display: isMobile ? 'none' : '' }} id='imprimirFicha' title='Imprimir'>
          <span className="icon-siarl icon-imprimir"></span>
        </button>

        {!isMobile &&
          <DropDownButton
          items={optionsExportar}
          className={styles.btnImprimir}
          style={{marginTop: '-4px'}}
          iconClass="icon-siarl icon-exportar"
          onItemClick={onItemClickExportar}
          fillMode={'flat'}
          title='Exportar'
          itemRender={itemRender}
        ></DropDownButton>
        }

        {isMobile &&
          <DropDownButton
            items={optionsMais}
            className={styles.btnMais}
            iconClass="icon-siarl icon-mais"
            onItemClick={onItemClickMais}
            fillMode={'flat'}
            itemRender={itemRenderMais}
            title="Mais Opções"
          />
        }

        <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
          <span className="icon-siarl icon-ajuda"></span>
        </button>

        <button className={styles.btnFechar} onClick={onCloseClick} title="Minimizar">
          <span className="icon-siarl icon-minimizar"></span>
        </button>

        {!isMobile &&
        <MaximizeButton className={styles.btnMaximizar} />
        }
      </div>
    </div>

    <TabStrip selected={selected} onSelect={handleSelect} scrollable={isMobile}>
      <TabStripTab title="Títulos" key={'titulos'}>

      <ExcelExport
        fileName='Resultado_Titulos'
              data={tituloUsoDoSolo.map((usTitulo: any) => ({
                ...usTitulo,
               
              }))}
              ref={(exporter) => {
                _export = exporter
              }}
            >
              <Grid
                className={styles.form}
                sortable={true}
                onDataStateChange={dataStateChange}
                data={
                  dataResult.data.length > 0
                    ? dataResult
                    : tituloUsoDoSolo.slice(page.skip, page.take + page.skip).map((pretensao: any) => ({
                      ...pretensao,
                    
                    }))
                }
                {...dataState}
                pageable={{
                  buttonCount: 5,
                  info: true,
                  type: 'numeric',
                  pageSizes: true,
                  previousNext: true,
                }}
                //groupable={true}
                total={tituloUsoDoSolo.length}
              >
                <GridColumn cell={CommandCell} width={100} />
                
          {colums?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
              </Grid>
            </ExcelExport>
            
            <GridPDFExport  
              fileName='Resultado_UsoDoSolo'
              ref={(element) => {
                _pdfExport = element
              }}
             paperSize={['390mm', '297mm']}         

              margin="1cm"
            >
              <Grid
                className={styles.form}
                sortable={true}
                onDataStateChange={dataStateChange}
                data={
                  dataResult.data.length > 0
                    ? dataResult
                    : tituloUsoDoSolo.map((usTitulo: any) => ({
                      ...usTitulo,
                    }))
                }
                {...dataState}
                pageable={{
                  buttonCount: 5,
                  info: true,
                  type: 'numeric',
                  pageSizes: true,
                  previousNext: true,
                }}
                //groupable={true}
              >
                   <GridColumn cell={CommandCell} width={100} />
                   {colums?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
              </Grid>
            </GridPDFExport>
      </TabStripTab>

    <TabStripTab title="Documentos">
        <ExcelExport
          fileName='Resultado_Documentos_Titulos'
          data={documentosUsTitulo}
          ref={(exporter) => {
            _export = exporter
          }}
        >
          <Grid
            className={styles.form}
            sortable={true}
            onDataStateChange={dataStateChangeDocumentos}
            data={
              dataResultDocumentos.data.length > 0
                ? dataResultDocumentos
                : documentosUsTitulo.slice(page.skip, page.take + page.skip)
            }
            {...dataState}
            pageable={{
              buttonCount: 5,
              info: true,
              type: 'numeric',
              pageSizes: true,
              previousNext: true,
            }}
            total={documentosUsTitulo.length}
            //groupable={true}
          >
            <GridColumn cell={documentCustomCell} width={86} />
            {columsDocuments?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
          </Grid>
        </ExcelExport>

        <GridPDFExport
          fileName='Resultado_Documentos_UsoDoSolo'
          ref={(element) => {
            _pdfExport = element
          }}
          margin="1cm"
        >
          <Grid
            className={styles.form}
            sortable={true}
            onDataStateChange={dataStateChangeDocumentos}
            data={
              dataResultDocumentos.data.length > 0
                ? dataResultDocumentos
                : documentosUsTitulo
            }
            {...dataState}
            pageable={{
              buttonCount: 5,
              info: true,
              type: 'numeric',
              pageSizes: true,
              previousNext: true,
            }}
            //groupable={true}
          >
            <GridColumn cell={documentCustomCell} width={86} />
            <GridColumn field="id" width={70} title="ID" />
            <GridColumn
              field="filedoc_cod"
              width={190}
              title="ID Documento Filedoc"
            />
            <GridColumn
              field="data_publicacao"
              width={150}
              title="Data publicação"
            />
            <GridColumn
              field="tipoRecurso.designacao"
              width={150}
              title="Tipo de recurso"
            />
            <GridColumn field="titulo" width={90} title="Título" />
            <GridColumn field="autores" width={90} title="Autor" />
            <GridColumn field="entidade.nome" width={300} title="Entidade" />
            <GridColumn field="estado_id" width={'auto'} title="Estado" />
          </Grid>
        </GridPDFExport>
      </TabStripTab>
    </TabStrip>
     {openForm && (
        <Dialog
        className="ModalDocumentos"
          title={`Documento: ${idDoDocumento}`}
          onClose={close}
          style={{ zIndex: 999 }}
        >
  
            <ImagePreviewV2
              documentUrl={urlDoDocumento}
              next={(e: any) => nextPhoto(e)}
              previous={(e: any) => previousPhoto(e)}
            />

        </Dialog>
      )}

    <div
      className={clsx(styles.footer, styles.justify_one_child, isCurrentViewMaximized && commonStyles.maximizedFooter)}
    >
      <Button className={styles.btnPesquisar} onClick={clearSearchResults} title='Nova Pesquisa'>
        <span className="icon-siarl icon-pesquisar"></span>
        <span className={styles.rotulo}>
          {' '}&nbsp;{' '}
          Nova Pesquisa
        </span>
      </Button>
    </div>
  </div>
)

}