import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import styles from '../../../styles/modelos/Modelos.module.scss'
import clsx from 'clsx'

type Props = {
  onInfoClick?: (modelo: Record<string, unknown>) => () => void
  onLocationClick?: (modelo: Record<string, unknown>) => () => void
  dataItem: Record<string, unknown>
}

export const ResultadoModeloCommandGridCell = ({
  onInfoClick,
  onLocationClick,
  dataItem,
}: Props) => {
  return (
    <td className="k-command-cell">  
      <div className={styles.gridCommand}>
      {dataItem.hasGeom && onLocationClick?
        <div
          role="button"
          onClick={onLocationClick(dataItem)}
          className={clsx(styles.gridCommandIconButton, 'espacoGridAction')}
        >
          <FontAwesomeIcon icon={faLocationDot} title="Mostrar no Mapa" />
        </div> : <div className='espacoGridAction'>&nbsp;</div>}
      {onInfoClick?
        <div
          role="button"
          className={styles.gridCommandIconButton}
          onClick={onInfoClick(dataItem)}
        >
          <FontAwesomeIcon icon={faInfoCircle} title="Detalhes de registo" />
        </div>:<></>}
      </div>
    </td>
  )
}
