import { useEffect, useRef, useState } from "react"
import styles from '../../styles/vooDrone/VooDrone.module.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBackward, faCameraAlt, faForward, faPauseCircle, faPlayCircle, faStopCircle } from "@fortawesome/free-solid-svg-icons"
import proj4 from "proj4"
import { EPSG_GLOBAL, EPSG_MAP, PROJECTION_MAP } from "../../utils/constants"
import { add, addMilliseconds, differenceInMinutes, differenceInSeconds, format, formatISO, parseISO } from "date-fns"
import { setkmlDetalheSelected } from "../../services/utils"
type Props = {
    path?: string
    srt?:string;
    kml?:any;
}
export default function VideoPlayer({ path, srt, kml }: Props) {
    const [isPlaying, setIsPlaying] = useState(false)
    const isFullScreen = useRef(false)
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const media = document.querySelector("video");
    const time_current = document.querySelector("#time_current");
    const time_total = document.querySelector("#time_total");
    const player = document.querySelector("#player");
    const progressFill: any = document.querySelector("#progressFill");
    const progressBar: any = document.querySelector("#progress");
    const [srtData, setSrtData]= useState<string[]>()
    const [kmlData, setKmlData]= useState<any>()
    const [currentPosition, setCurrentPosition]= useState<{ latitude:number|undefined, longitude:number|undefined, altitude:number|undefined}>()
    const latitudeRegex = /\[latitude\s*:\s*([\d.-]+)\]/;
    const longitudeRegex = /\[longtitude\s*:\s*([\d.-]+)\]/;
    const altitudeRegex = /\[altitude\s*:\s*([\d.-]+)\]/;
    const parser = new DOMParser();
    proj4.defs(EPSG_MAP, PROJECTION_MAP);
    const captureScreenshot = () => {
        if (!path) return;
        if (videoRef.current && canvasRef.current) {
            const video = videoRef.current;
            const canvas = canvasRef.current;
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const ctx = canvas.getContext("2d");
            if (ctx) {
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                const screenshotDataUrl = canvas.toDataURL("image/png");
                if (screenshotDataUrl) {
                    const link = document.createElement('a')
                    link.href = screenshotDataUrl
                    link.download = 'screenshot.png'
                    link.style.display = 'none'
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                }
            }
        }
    };
    const playPauseMedia = () => {
        if (!path) return;
        setIsPlaying((prevState:boolean)=>!prevState)
        if (media?.paused) {
            media?.play();
            media.addEventListener("timeupdate", setTime);
        } else {
            media?.pause();
            media?.removeEventListener("timeupdate", setTime);
        }
    }
    const stopMedia = () => {
        media?.removeEventListener("timeupdate", setTime);
        if (!path) return;
        media?.pause();
        if (media && media?.currentTime) {
            media.currentTime = 0;
        }
        setIsPlaying(false)
    }
    const windBackward = () => {
        if (media && media?.currentTime <= 3) {
            stopMedia();
        } else {
            if (media && media?.currentTime) {
                media.currentTime -= 3;
            }
        }
    }
    function windForward() {
        if (media && media.currentTime >= media.duration - 3) {
            stopMedia();
        } else {
            if (media && media?.currentTime) {
                media.currentTime += 3;
            }
        }
    }
    const formatVideoTime = (time: any) => {
        const minutes = Math.floor((time % 3600) / 60);
        let seconds: any = Math.floor(time % 60);
        seconds = seconds > 9 ? seconds : `0${seconds}`;
        return `${minutes}:${seconds}`;
    }
    const setTime = () => {
        if (media && media?.currentTime) {
            const minutes = Math.floor(media?.currentTime / 60);
            const seconds = Math.floor(media?.currentTime - minutes * 60);
            const minuteValue = minutes.toString().padStart(2, "0");
            const secondValue = seconds.toString().padStart(2, "0");
            const mediaTime = `${minuteValue}:${secondValue}`;
            if (time_current) {
                time_current.textContent = mediaTime;
            }
            if (time_total) {
                time_total.textContent = formatVideoTime(media?.duration);
            }
            if( progressFill){
                progressFill.style.width=`${(media?.currentTime / media.duration) * progressBar.clientWidth}px`;
            }
            if(kml){
                const location =  extractCurrentPositionKml();
                 if(location && location.latitude && location.longitude){
                     postLocationToMap(location)
                 }
               }
            if(srt){
                extractCurrentPosition()
            }
        }
    }
    const postLocationToMap=(location:{longitude:number,latitude:number,altitude:number})=>{
        const latLongMap = proj4(EPSG_GLOBAL, EPSG_MAP, [location.longitude?? 0,location.latitude?? 0]);
        const dataToSend = {
            longitude: latLongMap[0],
            latitude: latLongMap[1],
          }
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage(
            { type: 'SiarlVooDroneVideoPlay', value: dataToSend },
            '*'
        )
    }
    const extractCurrentPosition =()=>{
        const currentSrt = getCurrentSrt();
        const latitudeMatch = currentSrt.match(latitudeRegex);
        const longitudeMatch = currentSrt.match(longitudeRegex);
        const altitudeMatch = currentSrt.match(altitudeRegex);
        const latitude = latitudeMatch ? parseFloat(latitudeMatch[1]) : undefined;
        const longitude = longitudeMatch ? parseFloat(longitudeMatch[1]) : undefined;
        const altitude = altitudeMatch ? parseFloat(altitudeMatch[1]) : undefined;
        setCurrentPosition(()=>({
            latitude, longitude, altitude
        }));
        const latLongMap = proj4(EPSG_GLOBAL, EPSG_MAP, [longitude?? 0,latitude?? 0]);
        const dataToSend = {
            longitude: latLongMap[0],
            latitude: latLongMap[1],
          }
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage(
            { type: 'SiarlVooDroneVideoPlay', value: dataToSend },
            '*'
        )
    }

    
    
    
    const setProgress = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!progressBar) return;

        const newTime = e.nativeEvent.offsetX / progressBar.offsetWidth;
        progressFill.style.width = `${newTime * 100}%`;

        if (media) {
            media.currentTime = newTime * media.duration;
        }
        if (kml) {
            const location = extractCurrentPositionKml();
            if (location && location.latitude && location.longitude) {
                postLocationToMap(location)
            }
        }
        if(srt){
            extractCurrentPosition()
        }
    };
    media?.addEventListener("ended", stopMedia);
    const toggleFullScreen = () => {
        if (!isFullScreen.current) {
            if(player){
                player.requestFullscreen().catch(error=>{
                    console.log({error})
                })
            }
        }
        else {
             document.exitFullscreen().catch(error=>{
                console.log({error})
            })
        }
        isFullScreen.current = !isFullScreen.current;
    } 
      const parseTime = (timeString: string): number => {
        if(timeString){
        const [time, milliseconds] = timeString?.split(",") || [];
        // if(!time && !milliseconds) return 0;
        const [hours, minutes, seconds] = time?.split(":")?.map(Number) || [];
        // if(!hours && !minutes && !seconds) return 0; 
        return hours * 3600 + minutes * 60 + seconds + parseFloat("0." + milliseconds);
        } 
        return 0;
      };
      const getCurrentSrt = ()=>{
        let currentSrt="";
        const srts:string[] |undefined = srtData;
        const currentTime = media?.currentTime;
        if(!srts) return "";
        if(!currentTime) return "";
        for (let i = 0; i < srts.length; i++) {
            const subtitleBlock = srts?.[i]?.split("\n");
            const startTime = subtitleBlock?.[1]?.split(" --> ")?.[0];
            const endTime = subtitleBlock?.[1]?.split(" --> ")?.[1];
            if ( currentTime >= parseTime(startTime) && currentTime <= parseTime(endTime)
            ) {
                currentSrt = subtitleBlock?.slice(2)?.join("\n");
            }
          }
       return currentSrt;
      }

      const extractCurrentPositionKml = () => {
        const currentTime = media?.currentTime;
        if (!currentTime) return null;
        const voo_data = parseISO(kmlData?.timestamps[0]); 
        const currentTimeInMilliseconds = currentTime * 1000;
        const currentTimeISO: Date = addMilliseconds(voo_data, currentTimeInMilliseconds);
        let closestIndex = -1;
        let closestTimestampDiff = Number.MAX_SAFE_INTEGER;
        kmlData?.timestamps.forEach((t:any, index:number) => {
            const timestamp = parseISO(t);
            const timestampDiff = Math.abs(timestamp.getTime() - currentTimeISO.getTime());
            
            if (timestampDiff < closestTimestampDiff) {
                closestIndex = index;
                closestTimestampDiff = timestampDiff;
            }
        });
        if (closestIndex === -1) return null;
        const coords = kmlData?.coordinates[closestIndex].split(',').map(parseFloat);
        if (coords.length >= 3) {
            return {
                latitude: coords[1],
                longitude: coords[0],
                altitude: coords[2]
            };
        }
    
        return null;
    }
    useEffect(() => {
        if (path && kml) {
            const kmlDocument = parser.parseFromString(kml, "application/xml");
            if (!kmlDocument) return;
            const errors = kmlDocument.getElementsByTagName("parsererror");
            if (errors.length > 0) return;
            const placemark: any = kmlDocument.querySelector('Placemark');
            if (!placemark) return;
            const timestampsStr = placemark.querySelector('Data[name="timestamp"] value').textContent;
            const coordinatesStr = placemark.querySelector('LineString coordinates').textContent;
            const timestamps: string[] = timestampsStr.split(',');
            const coordinates: string[] = coordinatesStr.split(/\s+/);
            setKmlData(() => (
                { coordinates, timestamps }
            ))
            try {
                if (timestamps) {
                    const inicio = timestamps?.at(0);
                    const fim = timestamps?.at(-1);
                    const local = coordinates?.at(0);
                    const duracaoVideo = formatVideoTime(media?.duration);
                    let duracao;
                    if (fim && inicio) {
                        duracao = formatVideoTime(differenceInSeconds(parseISO(fim), parseISO(inicio)))
                        setkmlDetalheSelected({
                            inicio: parseISO(inicio),
                            fim: parseISO(fim),
                            local,
                            duracaoVideo,
                            duracao
                        })
                    }
                }
            } catch (error) { 
                console.log({error})
            }
        }
    }, [kml])
    useEffect(() => {
        if ( path && srt) {
          fetch(srt)
            .then((response) => response.text())
            .then((data) => {
                setSrtData(data.split("\n\n"));
            });
        }
      }, [srt]);
    return (
        <>
            <div className={styles.player_container}>
                <div className={styles.player} id="player">
                    <video
                        onClick={(e)=>{
                            e.preventDefault()
                            playPauseMedia()
                        }}
                        ref={videoRef}
                        height={'400px'}
                        preload="auto"
                        src={`${path?path:null}`}
                        className={styles.video}
                        id="video"
                    >
                    </video>
                    <div className={styles.controls}>
                        <div className={styles.time} style={{ color: "#fff" }} >
                            <span className={styles.time_current} id="time_current"></span>{` / `}<span className={styles.time_total} id="time_total"></span>
                        </div>
                        <div className={styles.progress} id="progress" onClick={setProgress}>
                            <div className={styles.progress_filled} id="progressFill"></div>
                        </div>
                        <div className={styles.controls_main} style={{ color: "#fff" }}>
                            <div className={styles.controls_left}>

                                <button
                                    className={styles.controlsIcon}
                                    data-icon="B"
                                    aria-label="rewind"
                                    onClick={(e)=>{
                                        e.preventDefault()
                                        windBackward()
                                    }}
                                    >
                                    <FontAwesomeIcon icon={faBackward} />
                                </button>
                                <button
                                    id="play"
                                    className={styles.controlsIcon}
                                    data-icon="P"
                                    aria-label="play pause toggle"
                                    onClick={(e)=>{
                                        e.preventDefault()
                                        playPauseMedia()
                                    }}
                                    
                                    >
                                    {isPlaying ? <FontAwesomeIcon icon={faPauseCircle} /> : <FontAwesomeIcon icon={faPlayCircle} />}
                                </button>
                                <button
                                    className={styles.controlsIcon}
                                    data-icon="F"
                                    aria-label="fastforward"
                                    onClick={(e)=>{
                                        e.preventDefault()
                                        windForward()
                                    }}
                                    >
                                    <FontAwesomeIcon icon={faForward} />
                                </button>
                                <button
                                    id="stop"
                                    className={styles.controlsIcon}
                                    data-icon="s"
                                    aria-label="stop"
                                    onClick={(e)=>{
                                        e.preventDefault()
                                        stopMedia()
                                    }}
                                    >
                                    <FontAwesomeIcon icon={faStopCircle} />
                                </button>

                            </div>

                            <div className={styles.controls_right}>
                                <button
                                    id="screenshotBtn"
                                    className={styles.controlsIcon}
                                    data-icon="screenshot"
                                    aria-label="screenshot"
                                    onClick={(e)=>{
                                        e.preventDefault()
                                        captureScreenshot()
                                    }}
                                    >
                                    <FontAwesomeIcon icon={faCameraAlt} />
                                </button>
                                <div className={styles.fullscreen} onClick={toggleFullScreen}> 
                                   <span className="icon-siarl icon-maximizarjanela"></span>
                                   
                                </div>
                            </div>

                        </div>



                    </div>

                </div>
            </div>
            <canvas ref={canvasRef} style={{ display: "none" }} />

        </>

    )
}
