
import {
  MouseEvent as ReactMouseEvent, useEffect, useState
} from 'react'

import { Button } from '@progress/kendo-react-buttons'
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form'
import { arhs, blobToBase64, capitania, concelhos, coordenadasPoligono, distritos, vzEstados, fillArhsAsync, fillCapitaniaAsync, fillConcelhosAsync, fillDistritosAsync, fillFreguesiasAsync, fillNuts1Async, fillNuts2Async, fillNuts3Async, freguesias, get, handleGenericError, massaagua, nuts1, nuts2, nuts3, post, setCoordenadasPoligono, setVooDronePathSelected, setVooDroneSelected, setkmlDetalheSelected, hasPermission, useCombos, fillVzEstadoAsync } from '../../services/utils'
import styles from '../../styles/vooDrone/VooDrone.module.scss'

import { DateTimePicker } from '@progress/kendo-react-dateinputs'
import { ComboBox } from '@progress/kendo-react-dropdowns'
import { Input, RadioGroup } from '@progress/kendo-react-inputs'
import useClearMap from '../../hooks/useClearMap'
import SessionStorageService from '../../services/SessionStorageService'
import { useVooDroneStore } from '../../stores/vooDrone'
import { CONSULTAR_REGISTOS_VOO_DRONE, GET_VOOS_DRONE, NENHUM, PERMISSION_INSERIR_VOO_DRONE, SAVE_VOO_DRONE, URL_AJUDA_VOO_DRONE } from '../../utils/constants'
import { formatsAcceptToSubmit } from './RegistarVooDroneForm'
import clsx from 'clsx'
import FieldsetCombosLocalizacao from '../localizacao/FieldsetCombosLocalizacao'
import ReactGA from 'react-ga4';
import { filterBy } from '@progress/kendo-data-query'

type Props = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
}
type FiltroVzRegistoVooDroneDTO = {
  id: number;
  id_estado: number;
  name: string;
  descricao: string;
  criterio_geografico: number;
  de_data_registo: string;
  ate_data_registo: string;
  de_data_voo: string;
  ate_data_voo: string;
  status: boolean;
  freguesia_cod: string;
  concelho_cod: string;
  distrito_cod: string;
  nuts1_cod: string;
  nuts2_cod: string;
  nuts3_cod: string;
  arh_cod: string;
  massa_agua_id: number;
  flatCoordinates: string;
}
export default function VooDroneFormTab({ onCloseClick }: Props) {

  const [identificacaoVisible, setIdentificacaoVisible] = useState(true)
  const toggleIdentificacao = () => setIdentificacaoVisible(!identificacaoVisible);

  const [CritGeograficosVisible, setCritGeograficosVisible] = useState(false)
  const toggleCriterioGeografico = () => setCritGeograficosVisible(!CritGeograficosVisible);

  const [localizacaoVisible, setLocalizacaoVisible] = useState(false)
  const toggleLocalizacao = () => setLocalizacaoVisible(!localizacaoVisible);

  const [dataVisible, setDataVisible] = useState(false)
  const toggleDataPesquisa = () => setDataVisible(!dataVisible);

  const [video, setVideo] = useState<any>();
  const [srt, setSrt] = useState<any>();

  const [freguesiasFiltered, setFreguesiasFiltered] = useState<any>([])
  const [concelhosFiltered, setConcelhosFiltered] = useState<any>([])
  const [nuts2Filtered, setNuts2Filtered] = useState<any>([])
  const [nuts3Filtered, setNuts3Filtered] = useState<any>([])
  const [dataDeRegisto, setDataDeRegisto] = useState<any>()
  const [dataAteRegisto, setDataAteRegisto] = useState<any>()

  type combosKeys= 'vzEstados';

  const [combos, setCombos] = useState<any>({
    vzEstados
  })


  const setDataOption: Record<combosKeys, { setFn: any, data: any[] }> = {
    vzEstados: {
      setFn: setCombos,
      data: vzEstados
    }
  };

const filterCombo_Multiselect = (e: any, tipoCombo: combosKeys) => {
    const { setFn, data } = setDataOption[tipoCombo];
    setFn({
        ...combos,
        [tipoCombo]: filterBy(data?.slice()??[], e.filter)
    }
  );
}

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/ConsultarVooDrone" });
  }, []);

useEffect(() => {
    
     if(!nuts1 ){
      fillNuts1Async();
      }
     if(!nuts2) {
      fillNuts2Async();
     }
     if(!nuts3){
      fillNuts3Async();
     }
     if(!distritos){
      fillDistritosAsync();
     }
     if(!concelhos){
      fillConcelhosAsync();
     }
     if(!freguesias){
      fillFreguesiasAsync();
     }
     if(!arhs){
      fillArhsAsync();
     }
     if(!capitania){
      fillCapitaniaAsync();
     }
     if(!vzEstados){
      fillVzEstadoAsync();
     }
    }, []);

  const getVoosDrone = async (data: any) => await get<any, any>(`${GET_VOOS_DRONE}/` + data);
  const AREA_VISIVEL = 1;
  const POLIGONO_SELECAO = 2;

  const selectVooDroneResult = useVooDroneStore(
    (state) => state.selectVooDroneResult
  )
  const selectVooDroneResultId = useVooDroneStore(
    (state) => state.selectVooDroneResultId
  )
  const selectVooDronePathResult = useVooDroneStore(
    (state) => state.selectVooDronePathResult
  )
  const selectVooDronePathResultId = useVooDroneStore(
    (state) => state.selectVooDroneResultId
  )
  const handleSubmit = async (e: any) => {

    if (dataAteRegisto && dataAteRegisto < dataDeRegisto ){
      postMessage({ type: 'errorMsg', value: { message: "A data de fím não pode ser inferior à data de início" } }, '*')
      return false;
    }

    try {
      postMessage({ type: 'showLoader' }, '*')
      const formValues = e.values;
      const voo_registos_drone_dto = {
        ...formValues, 
        flatCoordinates:coordenadasPoligono,
        criterio_geografico: formValues?.rbCriterio
      };
      sessionStorage.setItem("lastSearchVooDrone", JSON.stringify(voo_registos_drone_dto));

      const voosData = await post<FiltroVzRegistoVooDroneDTO, any>(`${CONSULTAR_REGISTOS_VOO_DRONE}`, voo_registos_drone_dto);
      SessionStorageService.setItem("resultadoVooDronePesquisa", JSON.stringify(voosData));
      setCoordenadasPoligono(undefined);
      postMessage({ type: 'resultadoVooDronePesquisaChange' }, '*')
      SessionStorageService.setItem("firstCallVooDrone", true);

    } catch (error) {
      console.log(error)
      postMessage({ type: 'errorMsg', value:{message:"Não foi possivel efetuar esta operação"} }, '*')
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }

  }
  const registarNovoClick = () => {
    selectVooDroneResult(undefined)
    selectVooDroneResultId(undefined)
    selectVooDronePathResult(undefined)
    selectVooDronePathResultId(undefined)
    setVooDroneSelected(undefined)
    setkmlDetalheSelected(undefined)
    setVooDronePathSelected(undefined)
    SessionStorageService.setItem("novoVooDrone", 'true');
    postMessage({ type: "form", value: "registarVooDrone" }, "*");
  }
  const filterConcelho = (e: any) => {
    setConcelhosFiltered([]);
    if (e?.value?.codigo) {
      const concelhosLocal = concelhos.filter((x: any) => x.distrito_cod == e.value.codigo);
      setConcelhosFiltered(concelhosLocal);
    }
  }
  const filterFreguesia = (e: any) => {
    setFreguesiasFiltered([]);
    if (e?.value?.codigo) {
      const freguesiaLocal = freguesias.filter((x: any) => x.concelho_cod == e.value.codigo);
      setFreguesiasFiltered(freguesiaLocal);
    }
  }
  const filterNuts2 = (e: any) => {
    setNuts2Filtered([])
    if (e?.value?.codigo) {
      setNuts2Filtered(nuts2?.filter((nts: any) => nts?.nuts_i_cod === e?.value?.codigo))
    }
  }
  const filterNuts3 = (e: any) => {
    setNuts3Filtered([])
    if (e?.value?.codigo) {
      setNuts3Filtered(nuts3?.filter((nts: any) => nts?.nuts_ii_cod === e?.value?.codigo))
    }
  }

  const clearMap = () => {
    try {

      setCoordenadasPoligono(undefined)
      const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.contentWindow?.postMessage({ type: "SiarlClearVooDroneLine", value: "true" }, '*')
      iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: "true" }, '*')
      iframe.contentWindow?.postMessage({ type: "SiarlDeselectMapArea", value: "true" }, '*')

    } catch (error) {
      console.log({ error })
    }
  }

  const changerbCriterio = (e: any) => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlDeselectMapArea", value: 'true' }, '*')
    clearMap();
    if (e.value == POLIGONO_SELECAO) {
      postMessage({ type: "informationMsg", value: "Selecione a área de pesquisa" }, "*");
      const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.contentWindow?.postMessage({ type: "SiarlGeoPortalAdicionarArea", value: 'true' }, '*')
    }
    if (e.value == AREA_VISIVEL) {
      postMessage({ type: "informationMsg", value: "Foi selecionada a área visível do mapa" }, "*");
      const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.contentWindow?.postMessage({ type: "SiarlGetMapArea", value: 'true' }, '*')
      iframe.contentWindow?.postMessage({ type: "SiarlSelectMapArea", value: 'true' }, '*')
    }
  }

  const cleanCombosLocalizacao = (fieldsToClean: string[], formRenderProps: FormRenderProps) => {
    fieldsToClean.forEach(field => {
      formRenderProps.onChange(field, { value: null })
    })
  }

  const showVideo = async (e: any) => {
    if (!e?.value) return;
    const uploadedFiles: any[] = e?.value;
    let srt: string | ArrayBuffer | null = null;
    let video: File | Blob | MediaSource | string | undefined | ArrayBuffer = undefined;
    uploadedFiles?.forEach(async file => {
      const { name, extension, getRawFile } = file;
      if (formatsAcceptToSubmit.includes(extension)) {
        srt = getRawFile();
        sessionStorage.setItem("nomeVooDrone", name.replace(extension, ""));
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage({ type: "SiarlVooDroneLine", value: [6] }, '*')

      }
      if (!formatsAcceptToSubmit.includes(extension)) {
        video = getRawFile();
      }

    })
    if (video) {
      try {
        postMessage({ type: 'showLoader' }, '*')
        video = await URL.createObjectURL(video);
        if (srt) {
          srt = await blobToBase64(srt);
          setSrt(() => (srt))
        }
        setVideo(() => (video));


      } catch (error) {
        console.log({ error })
        handleGenericError(error);
      } finally {
        postMessage({ type: 'hideLoader' }, '*')
      }
    }
  }

  const  openAjuda = () => {
    window.open(URL_AJUDA_VOO_DRONE, "_blank")
  }

  console.log({vzEstados})
  const _cleanFields = ["concelho", "freguesia", "distrito"];
  return (
    <div className={styles.base}>
      <div className={clsx(styles.header, styles.headerTabs)}>
        <div>
          <div className={styles.nomeDoModulo}>
            Video aéreo
          </div>
          <div className={styles.tituloDaPagina}>
            Critérios de pesquisa
          </div>
        </div>
        <div>
          {hasPermission(PERMISSION_INSERIR_VOO_DRONE) ? 
          <Button onClick={registarNovoClick} className={styles.btnNovoRegisto}>
            <span className="icon-siarl icon-registar btnIconRegistar"></span>{' '}
            &nbsp;{' '}
            Novo vídeo aéreo
          </Button>
          : ''}
          <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
            <span className="icon-siarl icon-ajuda"></span>
          </button>

          <button className={styles.btnFechar} onClick={onCloseClick}>
            <span className="icon-siarl icon-minimizar"></span>
          </button>

        </div>
      </div>

      <Form
        onSubmitClick={handleSubmit}
        initialValues={{
          rbCriterio:0
        }}
        render={(formRenderProps: FormRenderProps) => (
          <>
            <FormElement className={styles.form}>
              <fieldset>
                <legend>
                  <button type='button' className={styles.itemExpandBase} onClick={toggleIdentificacao}>
                    <div className={styles.btn_Expandir_Colapsar}>
                      <span className={identificacaoVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                    </div>
                    <span className={styles.fieldsetTitulo}>Identificação</span>
                  </button>
                </legend>

                {identificacaoVisible && (
                  <>
                    <div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}>
                      <div>
                        <Field
                          name="id"
                          label="ID"
                          component={Input}
                        />
                      </div>
                      <div>
                        <Field
                          name="descricao"
                          label="Descrição"
                          component={Input}
                        />
                      </div>
                      <div>
                        <Field
                          name="estado"
                          label="Estado"
                          component={ComboBox}
                          data={combos?.vzEstados}
                          filterable={true}
                          onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'vzEstados')}}
                          textField={'descricao'}
                        />
                      </div>
                    </div>
                  </>
                )}
              </fieldset>

              <fieldset>

                <legend>
                  <button type='button' className={styles.itemExpandBase} onClick={toggleCriterioGeografico}>
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span className={CritGeograficosVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                  </div>
                  <span className={styles.fieldsetTitulo}>Critério geográfico</span>
                  </button>
                </legend>

                {CritGeograficosVisible && (

                  <div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}>
                    <Field
                      id={'rbCriterio'}
                      name={'rbCriterio'}
                      layout={'horizontal'}
                      component={RadioGroup}
                      onChange={(e) => {
                        changerbCriterio(e)
                      }}
                      data={[
                        { label: 'Nenhum', value: NENHUM},
                        { label: 'Área visível', value: AREA_VISIVEL },
                        { label: 'Polígono de seleção', value: POLIGONO_SELECAO },
                      ]}
                      defaultValue={NENHUM} // Set the default selected value to 0 (Nenhum)

                    />
                  </div>

                )}
              </fieldset>

            <FieldsetCombosLocalizacao styles={styles} formRenderProps={formRenderProps}/>
              

              <fieldset>

                <legend>
                  <button type='button' className={styles.itemExpandBase} onClick={toggleDataPesquisa}>
                    <div className={styles.btn_Expandir_Colapsar}>
                      <span className={dataVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                    </div>
                    <span className={styles.fieldsetTitulo}>Intervalo Data</span>
                  </button>
                </legend>

                {dataVisible && (
                  <div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}>
                    <div>
                      <Field
                        name="de_data_registo"
                        label="De"
                        format="dd/MM/yyyy HH:mm"
                        component={DateTimePicker}
                        onChange={(e) =>{
                          setDataDeRegisto(e.value)
                        }}
                      />
                    </div>
                    <div>
                      <Field
                        name="ate_data_registo"
                        label="Até"
                        format="dd/MM/yyyy HH:mm"
                        component={DateTimePicker}
                        onChange={(e) =>{
                          setDataAteRegisto(e.value)
                        }}
                      />
                    </div>
                  </div>
                )}

              </fieldset>

            </FormElement>
            <div className={`${styles.footer} ${styles.justify_multiple_children}`}>
              <Button
                onClick={(e) => {
                  formRenderProps.onFormReset();
                  clearMap();
                }}
                className={styles.btnLimparPesquisa}
                title='Limpar pesquisa'
              >
                <span className="icon-siarl icon-limparcampos"></span>
                <span className={styles.rotulo}>
                  {' '}&nbsp;{' '}
                  Limpar pesquisa
                </span>            
              </Button>
              <Button
                onClick={(e) => formRenderProps.onSubmit(e)}
                className={styles.btnPesquisar}
                title='Pesquisar'
              >
                <span className="icon-siarl icon-pesquisar"></span>
                <span className={styles.rotulo}>
                  {' '}&nbsp;{' '}
                  Pesquisar
                </span>              
              </Button>
            </div>
          </>
        )}
      />

    </div>
  )
}
