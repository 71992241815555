import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import styles from '../../styles/intervencao/Intervencao.module.scss'
import clsx from 'clsx'

type Props = {
  onInfoClick: (inervencao: Record<string, unknown>) => () => void,
  onMapCenter: (inervencao: Record<string, unknown>) => () => void,
  dataItem: Record<string, unknown>
}

export const ResultadoIntervencaoCommandGridCell = ({
  onInfoClick,
  onMapCenter,
  dataItem,
}: Props) => {
  return (
    <td className="k-command-cell">
      <div className={styles.gridCommand}>
      {dataItem.hasGeom ? 
      <div
          role="button"
          className={clsx(styles.gridCommandIconButton, 'espacoGridAction')}
          onClick={onMapCenter(dataItem)}
        >
          <FontAwesomeIcon icon={faLocationDot} title="Mostrar no Mapa"/>
        </div> 
        : <div className='espacoGridAction'>
        &nbsp;
      </div>}
      <div 
          role="button"
          className={styles.gridCommandIconButton}
          onClick={onInfoClick(dataItem)}
        >
          <FontAwesomeIcon icon={faInfoCircle} title="Detalhes de registo" />
        </div>
      
      </div>
    </td>
  )
}
