import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import styles from '../../styles/viewer/ShareModal.module.scss'
import { Button } from '@progress/kendo-react-buttons'
import { useGeovisualizadoresStore } from '../../stores/geovisualizador'
import clsx from 'clsx'
import { del } from '../../services/utils'
import { DELETE_SHARED_MAP } from '../../utils/constants'

type Props = {
  toggleDialog: () => void
}

export default function DeleteSharedMapModal({ toggleDialog }: Props) {
  const selectedGVResultId = useGeovisualizadoresStore(
    (state) => state.selectedGVResultId
  )
  const setShouldRefetchList = useGeovisualizadoresStore(
    (state) => state.setShouldRefetchList
  )

  const deleteSharedMap = (id: number) => del(`${DELETE_SHARED_MAP}/${id}`, {})

  const handleConfirmDelete = async () => {
    try {
      postMessage({ type: 'showLoader' }, '*')
      if (selectedGVResultId) await deleteSharedMap(selectedGVResultId)
      setShouldRefetchList(true)
    } catch (error) {
      postMessage({ type: 'errorMsg', value:{message:"Não foi possivel efetuar esta operação"} }, '*')
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  return (
    <Dialog
      className="dialog-center"
      title="Mapa partilhado"
      onClose={toggleDialog}
    >
      <p className={styles.confirmationText}>
        Você tem certeza que quer apagar o registo?
      </p>
      <DialogActionsBar layout="end">
        <Button onClick={toggleDialog} className={styles.cancelButton}>
          Fechar
        </Button>
        <Button
          onClick={handleConfirmDelete}
          className={clsx(styles.leftSpacer, styles.buttonWithIcon)}
          themeColor="primary"
        >
          Apagar
        </Button>
      </DialogActionsBar>
    </Dialog>
  )
}
