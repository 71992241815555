import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form, Field, FormElement } from '@progress/kendo-react-form'
import { Button } from '@progress/kendo-react-buttons'

import ValidatedInput from '../forms/ValidatedInput'
import { changPasswordFormValidator, loginFormValidator } from '../../validations/auth'
import styles from '../../styles/auth/LoginForm.module.scss'
import { login } from '../../services/auth'
import { mapStore, post, setGeoPortalUrl, setGeoPortalUser } from '../../services/utils'
import { AUTH_LOCALSTORAGE, GEOPORTAL_MAPA_PUBLICO_URL, GEOPORTAL_MAPA_URL, RESET_PASSWORD, ROLE_MAPAS_PRIVADOS } from '../../utils/constants'
import clsx from 'clsx'
import { Input } from '@progress/kendo-react-inputs'
import SessionStorageService from '../../services/SessionStorageService'
import ReactGA from 'react-ga4';

export default function PasswordRecoverForm() {
  const navigate = useNavigate()
  const location = useLocation()
  const setGPUrl = mapStore((state: any) => state.setUrl);
  const from = location.state?.from?.pathname || '/'
  const queryParams = new URLSearchParams(window.location.search)
  const resetPassword = async (data: any) =>
  await post<any, any>(`${RESET_PASSWORD}`, data)

  const handleSubmit = async (dataItem: { [name: string]: string }) => {
    postMessage({ type: 'showLoader' }, '*')
    if (dataItem.newPassword != dataItem.confirmPassword) {
       postMessage({ type: 'hideLoader' }, '*')
      return;
    }
    const objctToSend = JSON.parse(SessionStorageService.getItem("ObjResetToken"));
    objctToSend["Password"] = dataItem.newPassword;
    resetPassword(objctToSend).then(result => {
      if(result) {
        postMessage({ type: 'hideLoader' }, '*')
        SessionStorageService.removeItem("ObjResetToken");
        navigate("/login");
      }
    });
    //navigate("/login");
  }

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/PasswordRecovery" });
  }, []);

  useEffect(() => {
    sessionStorage.removeItem('userLoginSended')

    const items: any[] = window.location.hash.replace('#/password-recover?resettoken=', '').split("&");
    if (items.length < 2 && !SessionStorageService.getItem("ObjResetToken")) {
      navigate("/login");
      return;
    }
    if (items.length == 2) {
    const token = items[0];
    const email = items[1].replace("email=", "");
    const dataToSend = {
      EmailAddress: email,
      token: token
    }
    console.log(dataToSend);

    SessionStorageService.setItem("ObjResetToken", JSON.stringify(dataToSend));
    window.location.href = window.location.origin + "#/password-recover";
  }
  }, [])

   return (
    <Form
      onSubmit={handleSubmit}
      validator={changPasswordFormValidator}
      render={() => (
        <FormElement className={styles.form}>
          <fieldset className={'k-form-fieldset'}>
            <div className={styles.bottomSpacer}>
              <Field name="newPassword" type="password" component={ValidatedInput} label="Nova palavra-passe" />
            </div>

            <div>
              <Field
                name="confirmPassword"
                type="password"
                component={ValidatedInput}
                label="Confirme a palavra-passe"
              />
            </div>
          </fieldset>
          <div className={`k-form-buttons ${styles.buttons}`}>
            <Button
              className={clsx(styles.submit, styles.iniciarCor)}
              type="submit"
            >
              Alterar
            </Button>
          </div>
        </FormElement>
      )}
    />
  )
}
