import { create } from 'zustand'

type UsoDoSoloState = {
    selectedUsoDoSoloResult?: unknown
    selectUsoDoSoloResult: (payload: unknown) => void
    selectedUsoDoSoloResultId?: number
    selectUsoDoSoloResultId: (payload?: number) => void
    identificacaoTabIsValid: boolean
    selectUsoDoSoloIdentificacaoTabIsValid: (payload: unknown) => void
    detalheTabIsValid: boolean
    selectUsoDoSoloDetalheTabIsValid: (payload: unknown) => void
    identificacaoTabValidationObject: Record<string, boolean>
    addIdentificacaoTabValidatedField: (payload: Record<string, boolean>) => void
    detalheTabValidationObject: Record<string, boolean>
    addDetalheTabValidatedField: (payload: Record<string, boolean>) => void
}

export const useUsoDoSoloStore = create<UsoDoSoloState>((set, get) => ({
    selectedUsoDoSoloResult: null,
    identificacaoTabIsValid: false,
    detalheTabIsValid: true,
    identificacaoTabValidationObject: {},
    detalheTabValidationObject: {},
    selectUsoDoSoloResult: (payload: unknown) =>
        set({ selectedUsoDoSoloResult: payload }),
    selectUsoDoSoloResultId: (payload?: number) =>
        set({ selectedUsoDoSoloResultId: payload }),
    selectUsoDoSoloIdentificacaoTabIsValid: (payload?: any) =>
        set({ identificacaoTabIsValid: payload }),
    selectUsoDoSoloDetalheTabIsValid: (payload?: any) =>
        set({ identificacaoTabIsValid: payload }),
    addIdentificacaoTabValidatedField: (payload) => {
        const validationObj = get().identificacaoTabValidationObject
        const newValidationObj = { ...validationObj, ...payload }
        set({ identificacaoTabValidationObject: newValidationObj })
        const validationValues = Object.values(newValidationObj)
        set({ identificacaoTabIsValid: validationValues.every(value => value === true) })
    },
    addDetalheTabValidatedField: (payload) => {
        const validationObj = get().detalheTabValidationObject
        const newValidationObj = { ...validationObj, ...payload }
        set({ detalheTabValidationObject: newValidationObj })
        const validationValues = Object.values(newValidationObj)
        set({ detalheTabIsValid: validationValues.every(value => value === true) })
    }
}))
