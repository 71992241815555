import { create } from 'zustand'

type CommonState = {
  isCurrentViewMaximized: boolean
  isMapLoaded: boolean
  maximizeView: () => void
  restoreView: () => void
  toggleMaximizeView: () => void
  setIsMapLoaded: (payload: boolean) => void
}

export const useCommonStore = create<CommonState>((set, get) => ({
  isCurrentViewMaximized: false,
  isMapLoaded: false,
  maximizeView: () => set({ isCurrentViewMaximized: true }),
  restoreView: () => set({ isCurrentViewMaximized: false }),
  toggleMaximizeView: () => {
    const isMaximized = get().isCurrentViewMaximized
    set({ isCurrentViewMaximized: !isMaximized })
  },
  setIsMapLoaded: (payload) => set({ isMapLoaded: payload }),
}))
