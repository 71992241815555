import { Button } from '@progress/kendo-react-buttons'
import { DateTimePicker } from '@progress/kendo-react-dateinputs'
import { Dialog } from '@progress/kendo-react-dialogs'
import { ComboBox, MultiSelect } from '@progress/kendo-react-dropdowns'
import { Field, Form, FormElement } from '@progress/kendo-react-form'
import { Input, RadioButton } from '@progress/kendo-react-inputs'
import { useEffect, useState } from 'react'
import {
  arhs,
  concelhos,
  distritos,
  fillConcelhosAsync,
  fillDistritosAsync,
  fillFreguesiasAsync,
  fillNuts1Async,
  fillNuts2Async,
  fillNuts3Async,
  fillStatusLocal,
  fillTipoAcessoAsync,
  fillTipoEntidades,
  freguesias,
  get,
  getEntidadeSelected,
  hasPermission,
  massaagua,
  nuts1,
  nuts2,
  nuts3,
  post,
  setCoordenadasSelected,
  setEntidadeSelected,
  statusLocal,
  tipoEntidades,
  tipoacesso,
  useCombos
} from '../../../services/utils'
import { useEntidadeStore } from '../../../stores/entidade'
import styles from '../../../styles/entidade/RegistarEntidade.module.scss'
import {
  GET_COORDINATES_BY_ADDRESS,
  GET_ENTIDADE,
  GET_ENTIDADE_CONTATOS,
  IMPORT_GEOM_ENTIDADE,
  PERMISSION_ENTIDADES_PERFIL_COMPLETO_EDICAO,
  PERMISSION_ENTIDADES_PERFIL_COMPLETO_LEITURA,
  PERMISSION_ENTIDADES_PERFIL_TOTAL,
  SAVE_NEW_ENTIDADE
} from '../../../utils/constants'
import { requiredFieldValidator } from '../../../validations/ocorrencia'
import EntidadeCodigoPostalInputField from '../fields/EntidadeCodigoPostalInputField'
import EntidadeConcelhoField from '../fields/EntidadeConcelhoField'
import EntidadeDistritoField from '../fields/EntidadeDistritoField'
import EntidadeFreguesiaField from '../fields/EntidadeFrguesiaField'
import EntidadeLocalidadeInputField from '../fields/EntidadeLocalidadeInputField'
import EntidadeMoradaInputField from '../fields/EntidadeMoradaInputField'
import EntidadeNuts1Field from '../fields/EntidadeNuts1Field'
import EntidadeNuts2Field from '../fields/EntidadeNuts2Field'
import EntidadeNuts3Field from '../fields/EntidadeNuts3Field'
import ValidatedIdentificacaoComboBox from '../fields/ValidatedIdentificacaoComboBox'
import ValidatedIdentificacaoInput from '../fields/ValidatedIdentificacaoInput'
import ImportarGeometriaComponent from '../../apoiogestao/registar/ImportarGeometriaForm'
import clsx from 'clsx'
import SessionStorageService from '../../../services/SessionStorageService'
import { Grid, GridCellProps, GridColumn, GridItemChangeEvent } from '@progress/kendo-react-grid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faRemove } from '@fortawesome/free-solid-svg-icons'
import { ResultadoContactoCommandGridCell } from '../ResultadoContactoCommandGridCell copy'
import CheckMoreItem from '../../documentos/registar/fields/CheckMoreItem'
import { filterBy } from '@progress/kendo-data-query'

type IProps = {
  clickSave?: any
  viewMode?: boolean

}
export function EntidadeIdentificacao({ clickSave , viewMode}: IProps) {
  const [freguesiasFiltred, setFreguesiasFiltred] = useState<any>()
  const [concelhosFiltred, setConcelhosFiltred] = useState<any>()
  const [concelhosDisabled, setConcelhosDisabled] = useState<any>(true)
  const [freguesiasDisabled, setFreguesiasDisabled] = useState<any>(true)
  const [nameUser, setNameUser] = useState<any>()
  const [freguesiaSelected, setFreguesiaSelected] = useState<any>()
  const [distritoSelected, setDistritoSelected] = useState<any>()
  const [concelhoSelected, setConcelhoSelected] = useState<any>()
  const [nuts1Selected, setNuts1Selected] = useState<any>()
  const [nuts2Selected, setNuts2Selected] = useState<any>()
  const [nuts3Selected, setNuts3Selected] = useState<any>()
  const [massaaguaSelected, setMassaaguaSelected] = useState<any>()
  const [arhSelectected, setArhSelected] = useState<any>()
  const [nuts2Filtred, setNuts2Filtered] = useState<any>()
  const [nuts3Filtred, setNuts3Filtered] = useState<any>()

  const [morada, setMorada] = useState<any>('')
  const [localidade, setLocalidade] = useState<any>('')
  const [codigoPostal, setCodigoPostal] = useState<any>('')
  const [openImportModal, setOpenImportModal] = useState<any>()

  const [showModalEditContactos, setShowModalEditContactos] = useState(false);
  const [openModalDescartar, setOpenModalDescartar] = useState<any>()

  const importGeomEntidade = async (data: any) =>
    await post<any, any>(`${IMPORT_GEOM_ENTIDADE}`, data)
  const createEntidade = async (data: any) =>
    await post<any, any>(`${SAVE_NEW_ENTIDADE}`, data)
  const getCoordinates = async (data: any) =>
    await post<any, any>(`${GET_COORDINATES_BY_ADDRESS}`, data)
  const getEntidade = async (data: any) =>
    await get<any, any>(`${GET_ENTIDADE}/` + data)
  const getContactos = async (data: any) => 
  await get<any,any>(GET_ENTIDADE_CONTATOS + "/" + data);

  const selectEntidadeResult = useEntidadeStore(
    (state) => state.selectEntidadeResult
  )
  const selectedEntidadeResult: any = useEntidadeStore(
    (state) => state.selectedEntidadeResult
  )

  const [dataContactos, setDataContactos] = useState<any[]>([]);
  const [idContato, setIdContato] = useState();
  const [nomeContato, setNomeContato] = useState();
  const [departamentoContato, setDepartamentoContato] = useState();
  const [funcaoContato, setFuncaoContato] = useState();
  const [telefoneContato, setTelefoneContato] = useState();
  const [emailContato, setEmailContato] = useState();
  const [itemToRemove, setItemToRemove] = useState<any>();
  
  type combosKeys= 'tipoEntidades'| 'tipoacesso';

    const [combos, setCombos] = useState<any>({
      tipoEntidades,
      tipoacesso
    })


    const setDataOption: Record<combosKeys, { setFn: any, data: any[] }> = {
      tipoEntidades: {
        setFn: setCombos,
        data: tipoEntidades
      },
      tipoacesso: {
        setFn: setCombos,
        data: tipoacesso
      }
    };

  const filterCombo_Multiselect = (e: any, tipoCombo: combosKeys) => {
      const { setFn, data } = setDataOption[tipoCombo];
      setFn({
          ...combos,
          [tipoCombo]: filterBy(data?.slice()??[], e.filter)
      }
      );
  }


useEffect(() => {
    
     if(!nuts1 ){
      fillNuts1Async();
      }
     if(!nuts2) {
      fillNuts2Async();
     }
     if(!nuts3){
      fillNuts3Async();
     }
     if(!distritos){
      fillDistritosAsync();
     }
     if(!concelhos){
      fillConcelhosAsync();
     }
     if(!freguesias){
      fillFreguesiasAsync();
     }
     if(!tipoEntidades){
      fillTipoEntidades();
     }
     if(!tipoacesso){
      fillTipoAcessoAsync();
     }

  }, []);


  useEffect(() => {
      console.log("viewMode")
      console.log(viewMode)
  }, []);

  useEffect(() => {
    setFreguesiasFiltred(freguesias)
    setConcelhosFiltred(concelhos)
    fillStatusLocal();
  },[]);

  const cancelImport = () => {
    setOpenImportModal(false)
  }

  const filterConcelho = (e: any) => {
    if (e?.value?.codigo) {
      const concelhosLocal = concelhos.filter(
        (x: any) => x.distrito_cod == e.value.codigo
      )
      setConcelhosFiltred(concelhosLocal)
      setConcelhosDisabled(false)
    } else {
      setConcelhosFiltred(concelhos)
      setConcelhosDisabled(true)
    }
  }

  const filterFreguesia = (e: any) => {
    if (e?.value?.codigo) {
      const freguesiaLocal = freguesias?.filter(
        (x: any) => x.concelho_cod == e.value.codigo
      )
      setFreguesiasFiltred(freguesiaLocal)
      setFreguesiasDisabled(false)
      const concelhoSelectedLocal = concelhos?.filter(
        (x) => x.codigo == e?.value?.codigo
      )
      deselectNuts()
      deselectMassaArh()
      setConcelhoSelected(concelhoSelectedLocal[0])
    } else {
      setFreguesiasDisabled(true)
      setFreguesiasFiltred(freguesias)
    }
  }

  const removeContacto: any = () => {
    const item = itemToRemove;
    const dataContatosLocal = dataContactos.filter(x => !(x.nome == item.nome && x.telefone == item.telefone)).slice();
    setDataContactos(dataContatosLocal);
    setOpenModalDescartar(false);
  }

  const confirmRemoveContato = (item: any) => {
    setItemToRemove(item);
    setOpenModalDescartar(true);
  }

  const onEditItemClick =(item: any) => {
    setShowModalEditContactos(true);
    setIdContato(item.id);
    setNomeContato(item.nome);
    setTelefoneContato(item.telefone);
    setEmailContato(item.email);
    setDepartamentoContato(item.departamento);
    setFuncaoContato(item.funcao);
  }

  const gravarContato = () => {
    const entidade_id = getEntidadeSelected()?.id;
    const contatoToAdd = {
      entidade_id: entidade_id ?? undefined,
      nome: nomeContato,
      departamento: departamentoContato,
      email: emailContato,
      telefone: telefoneContato
    }
    const dataContatosLocal:any[] = dataContactos.slice();
    dataContatosLocal.push(contatoToAdd);
    setDataContactos(dataContatosLocal);    
  }

  const geoCode = async (e: any) => {
    let moradaLocal = morada;
    let fullAddress = morada + (localidade ?  ', ' + localidade : '') + (codigoPostal ? ', ' + codigoPostal: '')
    if (!fullAddress) {
      fullAddress = selectedEntidadeResult.morada + ', ' + selectedEntidadeResult.localidade + ', ' + selectedEntidadeResult.codigoPostal;
      moradaLocal = selectedEntidadeResult.morada;
    }
    const flatCoordinatesLocal = await getCoordinates(fullAddress)
    if (flatCoordinatesLocal) {
      const coords = flatCoordinatesLocal.split(',')
      const dataToSend = {
        longitude: coords[0],
        latitude: coords[1],
      }
      // const iframe: any = document.getElementById('iframeGeoPortal')
      // iframe.contentWindow?.postMessage(
      //   { type: 'SiarlGeoCodeSearch', value: dataToSend },
      //   '*'
      // )
      setMorada(moradaLocal);
      setCoordenadasSelected(flatCoordinatesLocal)
      postMessage(
        {
          type: 'CoordenadasSelected',
          value: { x: dataToSend.longitude, y: dataToSend.latitude, morada: moradaLocal },
        },
        '*'
      )
    }
  }

  const selectArh = (e: any) => {
    if (e?.value?.codigo) {
      const arhSelectedLocal = arhs.filter((x) => x.codigo == e?.value?.codigo)
      deselectAll()
      setArhSelected(arhSelectedLocal[0])
    }
  }
  const deselectAll = () => {
    deselectDistConc()
    deselectNuts()
    deselectMassaArh()
  }
  const deselectMassaArh = () => {
    setMassaaguaSelected(undefined)
    setArhSelected(undefined)
  }

  const deselectNuts = () => {
    setNuts1Selected(undefined)
    setNuts2Selected(undefined)
    setNuts3Selected(undefined)
  }

  const deselectDistConc = () => {
    setFreguesiaSelected(undefined)
    setDistritoSelected(undefined)
    setConcelhoSelected(undefined)
  }

  const filterNuts2SelectNuts1 = (e: any) => {
    setNuts2Filtered([])
    if (e?.value?.codigo) {
      const nuts1SelectedLocal = nuts1?.filter(
        (x) => x.codigo == e?.value?.codigo
      )
      deselectDistConc()
      deselectMassaArh()
      setNuts1Selected(nuts1SelectedLocal[0])
      setNuts2Filtered(
        nuts2?.filter((nts: any) => nts?.nuts_i_cod === e?.value?.codigo)
      )
    }
  }

  const filterNuts3SelectNuts2 = (e: any) => {
    setNuts3Filtered([])
    if (e?.value?.codigo) {
      const nuts2SelectedLocal = nuts2?.filter(
        (x) => x.codigo == e?.value?.codigo
      )
      deselectDistConc()
      deselectMassaArh()
      setNuts2Selected(nuts2SelectedLocal[0])
      setNuts3Filtered(
        nuts3?.filter((nts: any) => nts?.nuts_ii_cod === e?.value?.codigo)
      )
    }
  }

  const selectNuts3 = (e: any) => {
    if (e?.value?.codigo) {
      const nuts3SelectedLocal = nuts3?.filter(
        (x) => x.codigo == e?.value?.codigo
      )
      deselectAll()
      setNuts3Selected(nuts3SelectedLocal[0])
    }
  }
  const freguesiaChange = (e: any) => {
    if (e?.value?.codigo) {
      const freguesiaSelectedLocal = freguesias?.filter(
        (x) => x.codigo == e?.value?.codigo
      )
      setFreguesiaSelected(freguesiaSelectedLocal[0])
      const entidadeSelected = getEntidadeSelected()
      setEntidadeSelected({
        ...entidadeSelected,
        geom_imported: 'Freguesia: ' + freguesiaSelectedLocal[0]?.nome,
      })
      selectEntidadeResult({
        ...selectedEntidadeResult,
        geom_imported: 'Freguesia: ' + freguesiaSelectedLocal[0]?.nome,
      })
    }
  }
  const filterConcelhoSelectDistrito = (e: any) => {
    if (e?.value?.codigo) {
      const concelhosLocal = concelhos?.filter(
        (x: any) => x.distrito_cod == e.value.codigo
      )
      setConcelhosFiltred(concelhosLocal)
      setConcelhosDisabled(false)
      const distritoSelectedLocal = distritos?.filter(
        (x) => x.codigo == e?.value?.codigo
      )
      deselectNuts()
      deselectMassaArh()
      setDistritoSelected(distritoSelectedLocal[0])
    } else {
      setConcelhosFiltred(concelhos)
      setConcelhosDisabled(true)
    }
  }

  const selectMassaAgua = (e: any) => {
    if (e?.value?.codigo) {
      const massaAguaLocal = massaagua?.filter(
        (x) => x.codigo == e?.value?.codigo
      )
      deselectAll()
      setMassaaguaSelected(massaAguaLocal[0])
    }
  }
  const entidadeSelected = getEntidadeSelected()

  useEffect(() => {
    if (entidadeSelected?.id) {
      getContactos(entidadeSelected?.id).then(result => {
        setDataContactos(result);
        SessionStorageService.setItem("dataContactos", dataContactos);
      });
    }
  }, []);

  useEffect(() => {
    SessionStorageService.setItem("dataContactos", dataContactos);
  });

  const canSeeEntidadesAllPermissions = hasPermission(PERMISSION_ENTIDADES_PERFIL_COMPLETO_EDICAO) || hasPermission(PERMISSION_ENTIDADES_PERFIL_TOTAL) || hasPermission(PERMISSION_ENTIDADES_PERFIL_COMPLETO_LEITURA);

  return (
    <>
      <div className={`${styles.form} ${styles.removeScrollDuplicado}`}>


       <span style={{ marginLeft: '08px' }}>Os campos com simbolo * (asterisco) são de preenchimento obrigatório</span>

        <fieldset>

          <legend>
            <span className={styles.fieldsetTitulo}>Registo</span>
          </legend>

          <div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}>

            <div>
              <Field
                name="id"
                label="ID"
                component={Input}
                data={entidadeSelected?.id ?? undefined}
                disabled
              />
            </div>

            <div>
              <Field
                name="tecnicoRegisto"
                label="Técnico Registo"
                disabled
                component={Input}
              />
            </div>

            <div>
              <Field
                name="dataHoraRegisto"
                label="Data/hora registo"
                format="dd/MM/yyyy HH:mm"
                component={DateTimePicker}
                disabled
              />
            </div>

          </div>

        </fieldset>
        <fieldset>
        <legend>
            <span className={styles.fieldsetTitulo}>Identificação</span>
          </legend>
          <div className={styles.espacoVerticalFieldSet}>
        <div className={styles.fields}>
            {canSeeEntidadesAllPermissions?
                          <div>
                            <Field
                              name="nome"
                              label="Designação *"
                              component={ValidatedIdentificacaoInput}
                              validator={requiredFieldValidator}
                              maxLength={250}
                              disabled={viewMode}
                            />
                          </div>
            :<></>}
            </div>
            <div className={styles.fields}>
              {canSeeEntidadesAllPermissions ?

                <div>
                  <Field name="sigla" label="Sigla" component={Input} maxLength={25} disabled={viewMode}
                  />
                </div> : <></>}
                {canSeeEntidadesAllPermissions ?
              <div>
                <Field name="url" label="URL" component={Input} disabled={viewMode} maxLength={250} />
              </div>
              : <></>}
            </div>
            
            <div className={styles.fields}>
              <div>
                <Field name="email" label="Email" component={Input}  disabled={viewMode} maxLength={250} />
              </div>

              <div>
                <Field
                  name="telefone"
                  label="Telefone"
                  type="number"
                  component={Input}
                  maxLength={25}
                  disabled={viewMode}

                />
              </div>
            </div>

            <div className={styles.fields}>
              {canSeeEntidadesAllPermissions ?
                <div>
                  <Field
                    name="tipoEntidade"
                    label="Tipo Entidade *"
                    component={ValidatedIdentificacaoComboBox}
                    data={combos?.tipoEntidades}
                    textField="designacao"
                    validator={requiredFieldValidator}
                    disabled={viewMode}
                    filterable={true}
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'tipoEntidades')}}
                  />
                </div> : <></>}

              <div>
                <Field
                  name="tipoAcesso"
                  label="Tipo Acesso"
                  data={combos?.tipoacesso}
                  component={ComboBox}
                  textField="designacao"
                  disabled={viewMode}
                  filterable={true}
                  onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'tipoacesso')}}
                />
              </div>

            </div>
            </div>
        </fieldset>
        <fieldset>

          <legend>
            <span className={styles.fieldsetTitulo}>Localização Sede</span>
          </legend>

          <div className={styles.espacoVerticalFieldSet}>

            <div className={styles.fields}>
              <div>
                <Field
                  name="nuts1"
                  label="NUTS I"
                  component={EntidadeNuts1Field}
                  textField={'nome'}
                  disabled
                  data={nuts1}
                />
              </div>
              <div>
                <Field
                  name="nuts2"
                  label="NUTS II"
                  component={EntidadeNuts2Field}
                  textField={'nome'}
                  disabled
                  data={nuts2}
                />
              </div>
              <div>
                <Field
                  name="nuts3"
                  label="NUTS III"
                  component={EntidadeNuts3Field}
                  textField={'nome'}
                  disabled
                  data={nuts3}
                />
              </div>
            </div>

            <div className={styles.fields}>
              <div>
                <Field
                  name="distrito"
                  label="Distrito"
                  component={EntidadeDistritoField}
                  textField={'nome'}
                  disabled
                  data={distritos}
                />
              </div>
              <div>
                <Field
                  name="concelho"
                  label="Concelho"
                  component={EntidadeConcelhoField}
                  disabled
                  textField={'nome'}
                  data={concelhos}
                />
              </div>
              <div>
                <Field
                  name="freguesia"
                  label="Freguesia"
                  component={EntidadeFreguesiaField}
                  textField={'nome'}
                  disabled
                  data={freguesias}
                />
              </div>
            </div>
            <div className={styles.MarcarMapa}>
              <div className={styles.fields}>
                {canSeeEntidadesAllPermissions?
                <div>
                  <Field
                    name="morada"
                    label="Morada"
                    component={EntidadeMoradaInputField}
                    onChange={(e: any) => {
                      setMorada(e.value)
                      selectEntidadeResult({
                        ...selectedEntidadeResult,
                        morada: e.value,
                      })
                    }}
                   disabled={viewMode}
                    maxLength={250}
                  />
                </div>
                :<></>}
              </div>


              <div className={styles.fields}>

                <div>
                  <Field
                    name="codigoPostal"
                    label="Código Postal"
                    component={EntidadeCodigoPostalInputField}
                    onChange={(e: any) => {
                      selectEntidadeResult({
                        ...selectedEntidadeResult,
                        codigoPostal: e.value,
                      })
                      setCodigoPostal(e.value);
                    }}
                    disabled={viewMode}
                    maxLength={8}

                  />
                </div>

                <div>
                  <Field
                    name="localidade"
                    label="Localidade"
                    disabled={viewMode}
                    component={EntidadeLocalidadeInputField}
                    onChange={(e: any) => {
                      selectEntidadeResult({
                        ...selectedEntidadeResult,
                        localidade: e.value,
                      })
                      setLocalidade(e.value);
                    }}
                    maxLength={25}
                  />
                </div>
              </div>

              <div className={styles.fields}>
                <div>
                  <Button
                    onClick={(e) => {
                      geoCode(e)
                      e.preventDefault()
                    }}
                    style={{ marginTop: 12 }}
                    className={styles.btnImport}
                  >
                    Localizar no mapa
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Localização Jurisdição</span>
          </legend>

          <div className={styles.espacoVerticalFieldSet}>
            <div className={styles.fields}>
              {getEntidadeSelected() ? (
                <div>
                  <CheckMoreItem
                    list={getEntidadeSelected()?.lstNuts1Jurisdicao}
                    property={'nome'}
                    label="NUTS I"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="lstNuts1Jurisdicao"
                    label="NUTS I"
                    component={MultiSelect}
                    textField={'nome'}
                    data={nuts1}
                    disabled
                  />
                </div>
              )}
              {getEntidadeSelected() ? (
                <div>
                  <CheckMoreItem
                    list={getEntidadeSelected()?.lstNuts2Jurisdicao}
                    property={'nome'}
                    label="NUTS II"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="lstNuts2Jurisdicao"
                    label="NUTS II"
                    component={MultiSelect}
                    textField={'nome'}
                    data={nuts2}
                    disabled
                  />
                </div>
              )}
              {getEntidadeSelected() ? (
                <div>
                  <CheckMoreItem
                    list={getEntidadeSelected()?.lstNuts3Jurisdicao}
                    property={'nome'}
                    label="NUTS III"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="lstNuts3Jurisdicao"
                    label="NUTS III"
                    component={MultiSelect}
                    textField={'nome'}
                    data={nuts3}
                    disabled
                  />
                </div>
              )}
            </div>

            <div className={styles.fields}>
              {getEntidadeSelected() ? (
                <div>
                  <CheckMoreItem
                    list={getEntidadeSelected()?.lstDistritosJurisdicao}
                    property={'nome'}
                    label="Distrito"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="lstDistritosJurisdicao"
                    label="Distrito"
                    component={MultiSelect}
                    textField={'nome'}
                    data={distritos}
                    disabled
                  />
                </div>
              )}
              {getEntidadeSelected() ? (
                <div>
                  <CheckMoreItem
                    list={getEntidadeSelected()?.lstConcelhosJurisdicao}
                    property={'nome'}
                    label="Concelho"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="lstConcelhosJurisdicao"
                    label="Concelho"
                    component={MultiSelect}
                    textField={'nome'}
                    data={concelhos}
                    disabled
                  />
                </div>
              )}
              {getEntidadeSelected() ? (
                <div>
                  <CheckMoreItem
                    list={getEntidadeSelected()?.lstFreguesiasJurisdicao}
                    property={'nome'}
                    label="Freguesia"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="lstFreguesiasJurisdicao"
                    label="Freguesia"
                    component={MultiSelect}
                    textField={'designacao'}
                    data={freguesiasFiltred}
                    disabled
                  />
                </div>
              )}
            </div>

            <div className={styles.fields}>
              {getEntidadeSelected() ? (
                <div>
                  <CheckMoreItem
                    list={getEntidadeSelected()?.lstArhsJurisdicao}
                    property={'nome'}
                    label="ARH"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="arh"
                    label="ARH"
                    component={MultiSelect}
                    textField={'nome'}
                    data={arhs}
                    disabled
                  />
                </div>
              )}
              {getEntidadeSelected() ? (
                <div>
                  <CheckMoreItem
                    list={getEntidadeSelected()?.lstCapitaniasJurisdicao}
                    property={'nome'}
                    label="Capitania"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="capitania"
                    label="Capitania"
                    component={MultiSelect}
                    textField={'nome'}
                    disabled
                  />
                </div>
              )}
              {getEntidadeSelected() ? (
                <div>
                  <CheckMoreItem
                    list={getEntidadeSelected()?.lstMassaAguaJurisdicao}
                    property={'nome'}
                    label="Massa de água"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="massaAgua"
                    label="Massa de água"
                    component={MultiSelect}
                    textField={'nome'}
                    data={massaagua}
                    disabled
                  />
                </div>
              )}
            </div>
          </div>
        </fieldset>
      </div>
    </>
  )
}
