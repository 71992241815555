import React, { useEffect, useRef, useState } from 'react';
import styles from '../../styles/fotoaerea/FotoAerea.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faCameraAlt, faFileDownload, faForward, faPlayCircle, faStopCircle } from '@fortawesome/free-solid-svg-icons';
import { saveAs } from '@progress/kendo-file-saver';
import { get } from '../../services/utils';
import FotografiaSlider from '../fotografia/FotografiaSlider';
interface ImagePreviewProps {
  documentUrl: string;
  setUrlDoDocumento: any;
  hideClose?: boolean;
  next: any;
  first: any;
  last: any;
  previous: any;
  play: any;
  stop: any;
  fullScreen: any;
  fotos: any[];
  setFotoIndex: any;
}

const ImagePreview: React.FC<ImagePreviewProps> = ({ documentUrl, setUrlDoDocumento, hideClose,
next, first, last, previous, play, stop, fullScreen, fotos, setFotoIndex }) => {
  const [showPreview, setShowPreview] = useState(true);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const isFullScreen = useRef(false);
  const slideShow = document.querySelector("#slideShow");
  const [refreshKey, setRefreshKey] = useState(0);
  const imgRef = useRef<HTMLImageElement>(null);
  const objectRef = useRef<HTMLObjectElement>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const controlsRef = useRef<HTMLDivElement>(null);
  const handleClosePreview = () => {
    setUrlDoDocumento('');
    setShowPreview(false);
  };

  const getExtensionFromPath = (path: string) => {
    if (!path) {
      return 'jpg';
    }
    const pathParts = path.split('.');
    return pathParts[pathParts.length - 1].toLowerCase();
  };
  
  const getImage = async () => await get(documentUrl);

  const captureScreenshot = (e: any) => {
   
    if (canvasRef.current) {
        const canvasLocal = drawImageToCanvas();
        const screenshotDataUrl = canvasLocal?.toDataURL("image/png");
        if (screenshotDataUrl) {
            const link = document.createElement('a')
            link.href = screenshotDataUrl
            link.download = 'screenshot.png'
             link.style.display = 'none'
            document.body.appendChild(link)
             link.click()
            document.body.removeChild(link)
        }
    }
    e.preventDefault();
};

const downloadPhoto = async (e: any) => {

  try {

    const fileExtension = getExtensionFromPath(documentUrl);
    const fileName = 'foto.' + fileExtension;

    const response = await fetch(documentUrl);
    const blob = await response.blob();

    const blobUrl = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = fileName;

    link.click();

    window.URL.revokeObjectURL(blobUrl);

    e.preventDefault();

  } catch (error) {
    console.error('Erro ao fazer o download da foto:', error);
  }

};

// const toggleFullScreen = () => {
//   if (!isFullScreen.current) {
//       if(slideShow){
//         slideShow.requestFullscreen().catch(error=>{
//               console.log({error})
//           })
//       }
//   }
//   else {
//        document.exitFullscreen().catch(error=>{
//           console.log({error})
//       })
//   }
//   isFullScreen.current = !isFullScreen.current;
// } 

const toggleFullScreen = () => {
  const element = slideShow;

  if (element) {
    try {
      document.addEventListener('fullscreenchange', handleFullScreenChange);

      if (!document.fullscreenElement) {
        element.requestFullscreen();
      } else {
        document.exitFullscreen();
      }
    } catch (error) {
      console.log('Erro ao manipular o modo de tela cheia:', error);
    }
  }
};

const handleFullScreenChange = () => {
  isFullScreen.current= !!document.fullscreenElement;
  console.log('Modo de tela cheia alterado:', isFullScreen.current);
  // if (isFullScreen.current) {
  //   stop();
  // }

  if (imgRef.current !== null) {

    imgRef.current.style.height = isFullScreen.current ? '100%' : 'auto';
    imgRef.current.style.maxHeight = isFullScreen.current ? '100%' : '500px';
    imgRef.current.style.width = isFullScreen.current ? '100%' : 'auto';
    
  }
  if (controlsRef.current != null) {
    controlsRef.current.style.display = isFullScreen.current ? 'none' : 'block';
  }

  if (iframeRef.current !== null) {

    iframeRef.current.style.height = isFullScreen.current ? '100%' : '';
    iframeRef.current.style.minHeight = isFullScreen.current ? '100%' : '74vh';
    iframeRef.current.style.width = isFullScreen.current ? '100%' : 'auto';
  }

  if (objectRef.current !== null) {

    objectRef.current.style.height = isFullScreen.current ? '100%' : 'auto';
    objectRef.current.style.maxHeight = isFullScreen.current ? '100%' : '74vh';
    objectRef.current.style.minHeight = isFullScreen.current ? '100%' : '74vh';
    objectRef.current.style.width = isFullScreen.current ? '100%' : 'auto';

  }

};


const drawImageToCanvas = () => {
  const img: any = imgRef.current;
  const canvas = canvasRef.current;
  const ctx = canvas?.getContext('2d');
  if (img && canvas) {
    // Clear the canvas

    ctx?.clearRect(0, 0, canvas.width, canvas.height);
    canvas.width = img.width;
    canvas.height = img.height;
    // Draw the image onto the canvas
    ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);
  }
  return canvas;
};

useEffect(() => {
  try {
  drawImageToCanvas();
}catch(e) {
  console.log(e);
}
}, [])

useEffect(() => {
  setRefreshKey(prevKey => prevKey + 1);
}, [documentUrl])

  return (
            <><div
            style={{
              position: 'relative',
              top: 0,
              left: 0,
              //width: '100%',
              //height: '100%',
              //backgroundColor: 'rgba(0, 0, 0, 0.8)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              //zIndex: 9999,
            }}
          >
      <div className={styles.image} id="slideShow">
        <img ref={imgRef}
          key={refreshKey}
          src={documentUrl}
          title="Image Preview"
          style={{ width: 'auto', border: 'none', height: '500px' }} />
        <div ref={controlsRef} className={styles.controls}>
          <div className={styles.time} style={{ color: "#fff" }}>
            <span className={styles.time_current} id="time_current"></span>{` / `}<span className={styles.time_total} id="time_total"></span>
          </div>
          <div className={styles.controls_main} style={{ color: "#fff" }}>
            <div className={styles.controls_left}>

              <button
                className={styles.controlsIcon}
                data-icon="B"
                aria-label="rewind"
                onClick={(e) => {
                  previous(e);
                } }
              >
                <FontAwesomeIcon icon={faBackward} />
              </button>
              <button
                id="play"
                className={styles.controlsIcon}
                data-icon="P"
                aria-label="play pause toggle"
                onClick={(e) => {
                  play(e);
                } }

              >
                <FontAwesomeIcon icon={faPlayCircle} />
              </button>
              <button
                className={styles.controlsIcon}
                data-icon="F"
                aria-label="fastforward"
                onClick={(e) => {
                  next(e);
                } }
              >
                <FontAwesomeIcon icon={faForward} />
              </button>
              <button
                id="stop"
                className={styles.controlsIcon}
                data-icon="s"
                aria-label="stop"
                onClick={(e) => {
                  stop(e);
                } }
              >
                <FontAwesomeIcon icon={faStopCircle} />
              </button>

            </div>
            <FotografiaSlider setFotoIndex={setFotoIndex} setDocumentoUrl={setUrlDoDocumento} fotos={fotos} />
            <div className={styles.controls_right}>
              <button
                id="screenshotBtn"
                className={styles.controlsIcon}
                data-icon="screenshot"
                aria-label="screenshot"
                onClick={(e) => {
                  captureScreenshot(e);
                } }
              >
                <FontAwesomeIcon icon={faCameraAlt} />
              </button>

              <button
                id="downloadBtn"
                className={styles.controlsIcon}
                data-icon="download"
                aria-label="download"
                onClick={(e) => {
                  downloadPhoto(e);
                } }
              >
                <FontAwesomeIcon icon={faFileDownload} />
              </button>

              <div className={styles.fullscreen} onClick={toggleFullScreen}>
                <span className="icon-siarl icon-maximizarjanela"></span>

              </div>
              
            </div>
            
          </div>
        </div>
      </div>
    </div><canvas ref={canvasRef} style={{ display: "none" }} /></>


    /*{ <>
      {showPreview && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
          }}
        >
          <div style={{ width: '80%', height: '80%', backgroundColor: '#fff' }}>
            {documentUrl ? 
            <img
              src={documentUrl}
              title="Image Preview"
              style={{ width: '100%', height: '100%', border: 'none' }}
            />: ''}
            { hideClose ? '' :
            <button
              onClick={handleClosePreview}
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                padding: '8px',
                backgroundColor: 'transparent',
                color: '#fff',
                border: 'none',
                cursor: 'pointer',
                fontSize: '16px',
              }}
            >
              Close
            </button>}
          </div>
        </div>
      )}
    </> }
  );*/
          )  }

export default ImagePreview;
