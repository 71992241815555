import { Input, Slider, SliderLabel } from "@progress/kendo-react-inputs";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { get } from "../../services/utils";
import clsx from 'clsx'
import styles from '../../styles/fotoaerea/FotografiaSlider.module.scss'
import { useCommonStore } from "../../stores/common";
import commonStyles from '../../styles/common/Common.module.scss'
import { format } from "date-fns";
import { DateTimePicker, DateTimePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { Button } from "@progress/kendo-react-buttons";
import { EPSG_GLOBAL, EPSG_MAP } from "../../utils/constants";
import proj4 from "proj4";
type Props = {
    isOverlayVisible?: boolean,
    setDocumentoUrl: any,
    fotos: any[],
    setFotoIndex: any
  }
  
export default function FotografiaSlider({
    isOverlayVisible,
    setDocumentoUrl,
    fotos,
    setFotoIndex
  }: Props) {
    const [selectedValue, setSelectedValue] = useState<any>();
    const [deData, setDeData] = useState<Date>(new Date);
    const [ateData, setAteData] = useState<Date>(new Date);
    const selectorChange = async(e: any) => {
        const index = Math.floor(e.value)
        const sismo = fotos?[index] : undefined;
        fotos?.forEach(async (item, currentIndex) => {
            if (index == currentIndex) {
                item['index'] = index;
                setSelectedValue(item);
                if (fotos[index] && fotos[index].latitude && fotos[index].longitude) {
                    try {
                      const response = await fetch(fotos[index].url_externa);
                    } catch {
                      console.log("erro no fetch")
                    }
                    setDocumentoUrl(fotos[index].url_externa);
                    setFotoIndex(index);
                    const latLongMap = proj4(EPSG_GLOBAL, EPSG_MAP, [fotos[index].longitude?? 0,fotos[index].latitude?? 0]);
                    const dataToSendLatLong = {
                        longitude: latLongMap[0],
                        latitude: latLongMap[1],
                        id: fotos[index].id
                      }
                      const iframe: any = document.getElementById('iframeGeoPortal')
              
                      iframe.contentWindow?.postMessage(
                          { type: "SiarlFotoObliquaPlay", value: dataToSendLatLong },
                          '*'
                      )
                    }
            }
        });
    }
    const isCurrentViewMaximized = useCommonStore(state => state.isCurrentViewMaximized)
    return(
      <div
       >
        <div
        className={clsx(
            styles.base,
          )}
        >
            <div style={{ 
                
                display:'flex',
                justifyContent:'center',
                alignContent:'center',
                flexDirection:'column',
                padding:'12px',
                gap:'8'
            }}>
                <div>
                    <Slider onChange={selectorChange}
                    buttons={false} step={1} min={0} max={fotos?.length?? 0}>
                    </Slider>
                </div>               
            </div>
        <div>
           
        </div>
        </div>
      </div>
    )
  }