import { ComboBox, ComboBoxProps } from '@progress/kendo-react-dropdowns'
import { Error } from '@progress/kendo-react-labels'
import { useEffect } from 'react'

import type { FieldRenderProps } from '@progress/kendo-react-form'

import { useEntidadeStore } from '../../../stores/entidade'

export default function ValidatedIdentificacaoComboBox(
  fieldRenderProps: FieldRenderProps
) {
  const setIsIdentificacaoTabValid = useEntidadeStore(
    (state) => state.setIsIdentificacaoTabValid
  )
  const addIdentificacaoTabValidatedField = useEntidadeStore(
    (state) => state.addIdentificacaoTabValidatedField
  )

  useEffect(() => {
    setIsIdentificacaoTabValid(!!fieldRenderProps.value)
    addIdentificacaoTabValidatedField({
      [fieldRenderProps.name]: !!fieldRenderProps.value,
    })
  }, [fieldRenderProps.value])

  return (
    <div>
      <ComboBox {...(fieldRenderProps as ComboBoxProps)} />
      {fieldRenderProps.visited && fieldRenderProps.validationMessage && (
        <Error>{fieldRenderProps.validationMessage}</Error>
      )}
    </div>
  )
}
