import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form, Field, FormElement } from '@progress/kendo-react-form'
import { Button } from '@progress/kendo-react-buttons'

import ValidatedInput from '../forms/ValidatedInput'
import { loginFormValidator } from '../../validations/auth'
import styles from '../../styles/auth/LoginForm.module.scss'
import { login } from '../../services/auth'
import { mapStore, setGeoPortalUrl, setGeoPortalUser } from '../../services/utils'
import { AUTH_LOCALSTORAGE, GEOPORTAL_MAPA_PUBLICO_URL, GEOPORTAL_MAPA_URL, GUEST_USER_NAME, PASS_GUEST, ROLE_MAPAS_PRIVADOS } from '../../utils/constants'
import clsx from 'clsx'
import SessionStorageService from '../../services/SessionStorageService'
import ReactGA from 'react-ga4';

export default function LoginForm() {
  const navigate = useNavigate()
  const location = useLocation()
  const setGPUrl = mapStore((state: any) => state.setUrl);
  const from = location.state?.from?.pathname || '/'

  const loginAction = async (username: any, password: any) => {
    const userLogin = { username: username, password: password }
    postMessage({ type: 'showLoader' }, '*')
    try {
      await login(userLogin);
      setGeoPortalUser(userLogin);
      if (from && from != '/login') {
        setTimeout(() => {
          postMessage({ type: 'userLoginSended' }, '*');
        }, 1000)
        navigate(from)
      } else {
        navigate('/ocorrencia/terreno/registar')
      }
    } catch (e) {
      postMessage({type: "errorMsg", value: {message: "Falha na autenticação."}}, "*")
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  const handleSubmit = async (dataItem: { [name: string]: string }) => {
    await loginAction(dataItem.userName, dataItem.password);
  }

  useEffect(() => {
    sessionStorage.removeItem('userLoginSended')
    SessionStorageService.clear();
  }, [])

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/Login" });
  }, []);

  const handleRegisterClick = () => navigate('/registar')

  const handleChangPasswordClick = () => navigate("/forgot-password");

  const handleLoginGuestClick = async () => {
    await loginAction(GUEST_USER_NAME, PASS_GUEST);
  }

  return (
    <Form
      onSubmit={handleSubmit}
      validator={loginFormValidator}
      render={() => (
        <FormElement className={styles.form}>
          <fieldset className={'k-form-fieldset'}>
            <div className={styles.bottomSpacer}>
              <Field name="userName" component={ValidatedInput} label="Utilizador" />
            </div>

            <div>
              <Field
                name="password"
                type="password"
                component={ValidatedInput}
                label="Palavra-passe"
              />
            </div>
          </fieldset>
          <div className={`k-form-buttons ${styles.buttons}`}>
            <Button
              className={clsx(styles.submit, styles.iniciarCor)}
              type="submit"
            >
              Iniciar sessão
            </Button>
            <Button
              className={clsx(styles.register, styles.registarCor)}
              fillMode="outline"
              onClick={handleLoginGuestClick}
            >
              Entrar como convidado
            </Button>
            <Button
              className={clsx(styles.register, styles.registarCor)}
              fillMode="outline"
              onClick={handleRegisterClick}
            >
              Registar
            </Button>
            <Button
              className={clsx(styles.register, styles.registarCor)}
              fillMode="outline"
              onClick={handleChangPasswordClick}
            >
              Esqueci-me da palavra-passe
            </Button>
          </div>
        </FormElement>
      )}
    />
  )
}
