import { create } from 'zustand'

type estrategiaAdaptacaoState = {
  selectedEstrategiaResult?: unknown
  selectEstrategiaResult: (payload: unknown) => void
  selectedEstrategiaResultId?: number
  selectEstrategiaResultId: (payload?: number) => void
  identificacaoTabIsValid: boolean
  selectEstrategiaIdentificacaoTabIsValid: (payload: unknown) => void
  parametroTabIsValid: boolean
  selectEstrategiaParametrosTabIsValid: (payload: unknown) => void
  identificacaoTabValidationObject: Record<string, boolean>
  addIdentificacaoTabValidatedField: (payload: Record<string, boolean>) => void
  parametroTabValidationObject: Record<string, boolean>
  addParametroTabValidatedField: (payload: Record<string, boolean>) => void
}

export const useEstrategiaAdaptacaoStore = create<estrategiaAdaptacaoState>((set, get) => ({
  selectedEstrategiaResult: null,
  identificacaoTabIsValid: false,
  parametroTabIsValid: true,
  identificacaoTabValidationObject: {},
  parametroTabValidationObject: {},
  selectEstrategiaResult: (payload: unknown) =>
    set({ selectedEstrategiaResult: payload }),
  selectEstrategiaResultId: (payload?: number) =>
    set({ selectedEstrategiaResultId: payload }),
    selectEstrategiaIdentificacaoTabIsValid: (payload?: any) =>
    set({ identificacaoTabIsValid: payload }),
    selectEstrategiaParametrosTabIsValid: (payload?: any) =>
    set({ identificacaoTabIsValid: payload }),
  addIdentificacaoTabValidatedField: (payload) => {
    const validationObj = get().identificacaoTabValidationObject
    const newValidationObj = { ...validationObj, ...payload }
    set({ identificacaoTabValidationObject: newValidationObj })
    const validationValues = Object.values(newValidationObj)
    set({identificacaoTabIsValid: validationValues.every(value => value === true)})
  },
  addParametroTabValidatedField: (payload) => {
    const validationObj = get().parametroTabValidationObject
    const newValidationObj = { ...validationObj, ...payload }
    set({ parametroTabValidationObject: newValidationObj })
    const validationValues = Object.values(newValidationObj)
    set({parametroTabIsValid: validationValues.every(value => value === true)})
  }
}))
