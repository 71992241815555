import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import styles from '../../styles/entidade/ConsultarEntidadeForm.module.scss'
import clsx from 'clsx'

type HoverState = 'idle' | 'enter' | 'leave'


type Props = {
  onLocationClick?: (entidade: Record<string, unknown>) => () => void
  onInfoClick?: (entidade: Record<string, unknown>) => () => void
  dataItem: Record<string, unknown>
  onLocationMouseEnter?: (entidade: Record<string, unknown>) => void
  onLocationMouseLeave?: (entidade: Record<string, unknown>) => void
}

export const ResultadoEntidadeCommandGridCell = ({
  onLocationClick,
  onInfoClick,
  dataItem,
  onLocationMouseEnter,
  onLocationMouseLeave
}: Props) => {
    
  const [locationHoverState, setLocationHoverState] =
    useState<HoverState>('idle')

  const handleMouseEnter = () => setLocationHoverState('enter')
  const handleMouseLeave = () => setLocationHoverState('leave')

  useEffect(() => {
    if (locationHoverState === 'enter') {
      onLocationMouseEnter &&  onLocationMouseEnter(dataItem)
    } else if (locationHoverState === 'leave') {
      onLocationMouseLeave && onLocationMouseLeave(dataItem)
    }
  }, [locationHoverState])

  return (
    <td className="k-command-cell">
      <div className={'gridCommand'}>
        {dataItem?.hasGeom &&  onLocationClick ? (
          <div
            role="button"
            onClick={onLocationClick(dataItem)}
            className={clsx(styles.gridCommandIconButton, 'espacoGridAction')}
          >
            <FontAwesomeIcon icon={faLocationDot} title="Mostrar no Mapa" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}/>
          </div>
        ) : (
          <div className='espacoGridAction'>&nbsp;</div>
        )}
        {onInfoClick?
        <div
          role="button"
          className={styles.gridCommandIconButton}
          onClick={onInfoClick(dataItem)}
        >
          <FontAwesomeIcon icon={faInfoCircle} title="Detalhes de registo" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}/>
        </div>
        :<></>}
      </div>
    </td>
  )
}
