import { faBackward, faCameraAlt, faForward } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import styles from '../../styles/vooDrone/VooDrone.module.scss';
interface ImagePreviewProps {
  documentUrl: string;
  next: (e: any) => void;
  previous: (e: any) => void;
}

const ImagePreviewV2: React.FC<ImagePreviewProps> = ({ documentUrl, next, previous }) => {

  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const isFullScreen = useRef(false);
  const slideShow = document.querySelector("#slideShow");
  const [isImage, setIsImage] = useState(false);
  const [iframeError, setIframeError] = useState(false);

  const imgRef = useRef<HTMLImageElement>(null);
  const objectRef = useRef<HTMLObjectElement>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const getExtensionFromPath = (path: string) => {
    if (!path) {
      return 'jpg';
    }
    const pathParts = path.split('.');
    return pathParts[pathParts.length - 1].toLowerCase();
  };

  useEffect(() => {

    const extensions = ['jpg', 'jpeg', 'png', 'gif'];

    const determineFileType = () => {
      if (
        extensions.includes(getExtensionFromPath(documentUrl)) || extensions.some(ext => documentUrl.includes('data:image/' + ext))
      ) {
        return true;
      } else {
        return false;
      }
    };

    setIsImage(determineFileType());
  }, [documentUrl]);

  const captureScreenshot = async () => {

    try {

      const fileExtension = getExtensionFromPath(documentUrl);
      const fileName = 'documento.' + fileExtension;

      const response = await fetch(documentUrl);
      const blob = await response.blob();

      const blobUrl = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = fileName;

      link.click();

      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Erro ao fazer o download do documento:', error);
    }

  };

  const toggleFullScreen = () => {
    const element = slideShow;

    if (element) {
      try {
        document.addEventListener('fullscreenchange', handleFullScreenChange);

        if (!document.fullscreenElement) {
          element.requestFullscreen();
        } else {
          document.exitFullscreen();
        }
      } catch (error) {
        console.log('Erro ao manipular o modo de tela cheia:', error);
      }
    }
  };

  const handleFullScreenChange = () => {
    isFullScreen.current = !!document.fullscreenElement;
    console.log('Modo de tela cheia alterado:', isFullScreen.current);

    if (imgRef.current !== null) {

      imgRef.current.style.height = isFullScreen.current ? '100%' : 'auto';
      // imgRef.current.style.maxHeight = isFullScreen.current ? '100%' : '74vh';
 
    }

    if (iframeRef.current !== null) {

      iframeRef.current.style.height = isFullScreen.current ? '100%' : '';
      // iframeRef.current.style.minHeight = isFullScreen.current ? '100%' : '74vh';

    }

    if (objectRef.current !== null) {

      objectRef.current.style.height = isFullScreen.current ? '100%' : 'auto';
      // objectRef.current.style.maxHeight = isFullScreen.current ? '100%' : '74vh';
    }
  };

  useEffect(() => {
    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);


  const drawImageToCanvas = () => {
    const img: any = imgRef.current;
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    if (img && canvas) {
      // Clear the canvas

      ctx?.clearRect(0, 0, canvas.width, canvas.height);
      canvas.width = img.width;
      canvas.height = img.height;
      // Draw the image onto the canvas
      ctx?.drawImage(img, 0, 0, canvas.width, canvas.height);
    }
    return canvas;
  };

  useEffect(() => {
    try {
      drawImageToCanvas();
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    const iframe = iframeRef.current;

    if (iframe) {
      const handleIframeError = () => {
        setIframeError(true);
      };

      iframe.addEventListener('load', () => {
        try {
          if (iframe.contentWindow?.location.href === 'about:blank') {
            handleIframeError();
          }
        } catch (e) {
          handleIframeError();
        }
      });

      return () => {
        iframe.removeEventListener('load', handleIframeError);
      };
    }
  }, [documentUrl]);

  return (
    <>
      <div className='form'
        style={{
          display: 'flex', justifyContent: 'center',
          flexDirection: 'column', flexWrap: 'wrap', alignContent: 'center',
          alignItems: 'center', height: "calc(100% - 60px)", paddingBottom: '20px'
        }}
        id="slideShow"
      >
        
        {isImage ? (
          <img
            ref={imgRef}
            src={documentUrl}
            title="Image Preview"
            className='fillMaxHeightAvailable'
          />
        ) : iframeError || (documentUrl && !documentUrl.includes(' ') && documentUrl.includes('.') && documentUrl.toLowerCase().endsWith('.pdf')) ? (
          <object
            data={documentUrl}
            type={`application/${documentUrl ? documentUrl.split('.').pop() : ''}`}
            ref={objectRef}
            className='fillHeightAvailable fillWidthAvailable'
            style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', flexWrap: 'wrap', alignContent: 'center', alignItems: 'center' }}
          >
            <div className='fillMaxHeightAvailable'>
              Não pode visualizar o documento.{' '}<a href={documentUrl} target='_blank' style={{ color: '#1BA49C' }}>Clique aqui para abrir link de documento num novo separador!</a>
            </div>
          </object>
        ) : (
          <iframe
            ref={iframeRef}
            src={documentUrl}
            title="Document Preview"
            className='fillHeightAvailable fillWidthAvailable'
          ></iframe>
        )}
      </div>

      <div className="footerModal justify_multiple_children" style={{ color: "#fff", background: "#001339" }}>
        <div className={styles.controls_left}>

          <button
            className={styles.controlsIcon}
            data-icon="B"
            aria-label="rewind"
            onClick={(e) => {
              previous(e);
            }}
          >
            <FontAwesomeIcon icon={faBackward} />
          </button>

          <button
            className={styles.controlsIcon}
            data-icon="F"
            aria-label="fastforward"
            onClick={(e) => {
              next(e);
            }}
          >
            <FontAwesomeIcon icon={faForward} />
          </button>

        </div>

        <div className={styles.controls_right}>
          <button
            id="screenshotBtn"
            className={styles.controlsIcon}
            data-icon="screenshot"
            aria-label="screenshot"
            onClick={(e) => {
              captureScreenshot();
            }}
          >
            <FontAwesomeIcon icon={faCameraAlt} />
          </button>
          <div className={styles.fullscreen} onClick={toggleFullScreen}>
            <span className="icon-siarl icon-maximizarjanela"></span>

          </div>
        </div>
      </div>
    </>
  );
}

export default ImagePreviewV2;