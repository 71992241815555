import { useCommonStore } from '../../stores/common'

type Props = {
  className: string
  
}

export default function MaximizeButton({ className = '' }: Props) {
  const isCurrentViewMaximized = useCommonStore(
    (state) => state.isCurrentViewMaximized
  )
  const toggleMaximizeView = useCommonStore((state) => state.toggleMaximizeView)

  return (
    <button className={className} onClick={toggleMaximizeView} title={isCurrentViewMaximized ? "Minimizar" : "Maximizar"}>
      {isCurrentViewMaximized ? (
        <span className="icon-siarl icon-minimizarjanela" />
      ) : (
        <span className="icon-siarl icon-maximizarjanela" />
      )}
    </button>
  )
}
