import { create } from 'zustand'

type OcorrenciaState = {
  selectedOcorrenciaResult?: unknown
  selectOcorrenciaResult: (payload: unknown) => void
  selectedOcorrenciaResultId?: number
  selectOcorrenciaResultId: (payload?: number) => void
  identificacaoTabIsValid: boolean
  selectOcorrenciaIdentificacaoTabIsValid: (payload: unknown) => void
  detalheTabIsValid: boolean
  selectOcorrenciaDetalheTabIsValid: (payload: unknown) => void
  identificacaoTabValidationObject: Record<string, boolean>
  addIdentificacaoTabValidatedField: (payload: Record<string, boolean>) => void
  detalheTabValidationObject: Record<string, boolean>
  addDetalheTabValidatedField: (payload: Record<string, boolean>) => void
}

export const useOcorrenciaStore = create<OcorrenciaState>((set, get) => ({
  selectedOcorrenciaResult: null,
  identificacaoTabIsValid: false,
  detalheTabIsValid: true,
  identificacaoTabValidationObject: {},
  detalheTabValidationObject: {},
  selectOcorrenciaResult: (payload: unknown) =>
    set({ selectedOcorrenciaResult: payload }),
  selectOcorrenciaResultId: (payload?: number) =>
    set({ selectedOcorrenciaResultId: payload }),
    selectOcorrenciaIdentificacaoTabIsValid: (payload?: any) =>
    set({ identificacaoTabIsValid: payload }),
    selectOcorrenciaDetalheTabIsValid: (payload?: any) =>
    set({ identificacaoTabIsValid: payload }),
  addIdentificacaoTabValidatedField: (payload) => {
    const validationObj = get().identificacaoTabValidationObject
    const newValidationObj = { ...validationObj, ...payload }
    set({ identificacaoTabValidationObject: newValidationObj })
    const validationValues = Object.values(newValidationObj)
    set({identificacaoTabIsValid: validationValues.every(value => value === true)})
  },
  addDetalheTabValidatedField: (payload) => {
    const validationObj = get().detalheTabValidationObject
    const newValidationObj = { ...validationObj, ...payload }
    set({ detalheTabValidationObject: newValidationObj })
    const validationValues = Object.values(newValidationObj)
    set({detalheTabIsValid: validationValues.every(value => value === true)})
  }
}))
