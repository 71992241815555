import { Button } from '@progress/kendo-react-buttons'
import {
    Field,
    Form,
    FormElement,
    FormRenderProps
} from '@progress/kendo-react-form'

import { MouseEvent as ReactMouseEvent, useEffect, useState } from 'react'

import { DateTimePicker } from '@progress/kendo-react-dateinputs'
import { ComboBox, ComboBoxFilterChangeEvent } from '@progress/kendo-react-dropdowns'
import { Input, RadioGroup, TextArea } from '@progress/kendo-react-inputs'
import SessionStorageService from '../../services/SessionStorageService'
import {
    condicionantesProcessos,
    coordenadasPoligono,
    estadosProcesso,
    fasesProcedimentoDelimitacao,
    fillCombosAuxiliares,
    fillCondicionantesProcessosAsync,
    fillEstadosProcessoAsync,
    fillFasesProcedimentoDelimitacaoAsync,
    fillLocalizacoesProcessosAsync,
    fillTiposAcaoJudicialAsync,
    fillTiposAquisicaoAsync,
    fillTiposDecisaoFinalAsync,
    fillTiposDesafetacaoAsync,
    fillTiposIniciativaAsync,
    fillTiposNotificacaoAsync,
    fillTiposParcelaProvaAsync,
    fillTiposPendencia,
    fillTiposPendenciaAsync,
    fillTiposProcessoAsync,
    fillTiposSentencaAsync,
    fillValidacoesProvaAsync,
    fillTecnicosAsync,
    freguesias,
    localizacoesProcesso,
    post, setCoordenadasPoligono,
    tiposAcaoJudicial,
    tiposAquisicao,
    tiposDecisaoFinal,
    tiposDesafetacao,
    tiposIniciativa,
    tiposNotificacao,
    tiposParcela,
    tiposPendencia,
    tiposSentenca,
    tiposSituacaoNotificacao,
    tipossProcesso,
    useCombos,
    validacoesProva,
    tecnicos,
    hasPermission,
    dhTipoDominio,
    dhRecurso,
    dhRppAntes,
    dhRppDepois,
    fillDhRecursoAsync,
    fillDhRppAntesAsync,
    fillDhRppDepoisAsync,
    fillDhTipoDominioAsync,
    dhDeferimento,
    fillDhDeferimentoAsync,
    entidades
} from '../../services/utils'
import styles from '../../styles/dominiohidrico/DominioHidrico.module.scss'
import {
    AREA_VISIVEL,
    CONSULTAR_DH_PROCESSO, NENHUM, PERMISSION_DOMINIO_HIDRICO_PERFIL_BASICO, PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_EDICAO, PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_LEITURA, PERMISSION_DOMINIO_HIDRICO_PERFIL_TOTAL, POLIGONO_SELECAO, PROCESSO_MODULE_NAME, SEM_GEOMETRIA, clearLayers
} from '../../utils/constants'
import { FilterDescriptor, filterBy } from '@progress/kendo-data-query'
import FieldsetCombosLocalizacao from '../localizacao/FieldsetCombosLocalizacao'
import ReactGA from 'react-ga4';

type Props = {
    onCloseClick: (
        e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
    ) => void | undefined;
}




export default function PesquisarProcessoForm({
    onCloseClick,
}: Props) {

    const [registoVisible, setRegistoVisible] = useState(true)
    const toggleRegisto = () => setRegistoVisible(!registoVisible);

    const [identificacaoVisible, setIdentificacaoVisible] = useState(false)
    const toggleIdentificacaoVisible = () => setIdentificacaoVisible(!identificacaoVisible);

    const [estadoDoProcessoVisible, setEstadoDoProcessoVisible] = useState(false)
    const toggleEstadoDoProcesso = () => setEstadoDoProcessoVisible(!estadoDoProcessoVisible);

    const [requerenteVisible, setRequerenteVisible] = useState(false)
    const toggleRequerente = () => setRequerenteVisible(!requerenteVisible);

    const [notificacaoVisible, setNotificacaoVisible] = useState(false)
    const toggleNotificacao = () => setNotificacaoVisible(!notificacaoVisible);

    const [faseProcessoVisible, setFaseProcessoVisible] = useState(false)
    const toggleFaseProcesso = () => setFaseProcessoVisible(!faseProcessoVisible);

    const [sentencaVisible, setSentencaVisible] = useState(false)
    const toggleSentenca = () => setSentencaVisible(!sentencaVisible);

    const [rppVisible, setRppVisible] = useState(false)
    const toggleRppVisible = () => setRppVisible(!rppVisible);

    const [decisaoVisible, setDecisaoVisible] = useState(false)
    const toggleDecisaoVisible = () => setDecisaoVisible(!decisaoVisible);

    const [autoDelimitacaoVisible, setAutoDelimitacaoVisible] = useState(false)
    const toggleAutoDelimitacaoVisible = () => setAutoDelimitacaoVisible(!autoDelimitacaoVisible);

    const [criterioGeograficoVisible, setCriterioGeograficoVisible] = useState(false)
    const toggleCriterioGeografico = () => setCriterioGeograficoVisible(!criterioGeograficoVisible)

    const [diplomaPublicadoVisible, setDiplomaPublicadoVisible] = useState(false)
    const toggleDiplomaPublicadoVisible = () => setDiplomaPublicadoVisible(!diplomaPublicadoVisible)

    const [LocalizacaoVisible, setLocalizacaoVisible] = useState(false)
    const toggleLocalizacao = () => setLocalizacaoVisible(!LocalizacaoVisible)

    const [TipologiaVisible, setTipologiaVisible] = useState(false)
    const toggleTipologia = () => setTipologiaVisible(!TipologiaVisible)

    const [outrosCriteriosVisible, setOutrosCriteriosVisible] = useState(false)
    const toggleOutrosCriterios = () => setOutrosCriteriosVisible(!outrosCriteriosVisible)
    
    
    const [minData, setMinDate] = useState<any>();

    const [freguesiasData, setFreguesiasData] = useState(freguesias ? freguesias.slice() : []);
    const [tecnicosData, setTecnicosData] = useState(tecnicos ? tecnicos.slice() : []);

    const [entidadesData, setEntidadesData] = useState(entidades ? entidades.slice() : []);

    type combosKeys= 'tipossProcesso'| 'localizacoesProcesso' | 'tiposSentenca'| 'estadosProcesso' | 'fasesProcedimentoDelimitacao' | 'tiposPendencia' |
    'tiposDecisaoFinal' | 'validacoesProva' | 'tiposParcela' | "condicionantesProcessos"  | 'tiposAcaoJudicial' | 'tiposDesafetacao' | 'tiposAquisicao' |
    'tiposIniciativa' | 'dhTipoDominio' | 'dhRppAntes' | 'dhRppDepois' | 'dhRecurso' | 'dhDeferimento';

    const [combos, setCombos] = useState<any>({
        tipossProcesso,
        localizacoesProcesso,
        estadosProcesso,
        tiposSentenca,
        fasesProcedimentoDelimitacao,
        validacoesProva,
        tiposParcela,
        condicionantesProcessos,
        tiposAcaoJudicial,
        tiposDesafetacao,
        tiposIniciativa,
        tiposAquisicao,
        tiposDecisaoFinal,
        dhTipoDominio,
        dhRppAntes,
        dhRppDepois,
        dhRecurso,
        dhDeferimento
    })

    const setDataOption: Record<combosKeys, { setFn: any, data: any[] }> = {
        tipossProcesso: {
            setFn: setCombos,
            data: tipossProcesso
        },
        localizacoesProcesso: {
            setFn: setCombos,
            data: localizacoesProcesso
        },
        estadosProcesso: {
            setFn: setCombos,
            data: estadosProcesso
        },
        fasesProcedimentoDelimitacao: {
            setFn: setCombos,
            data:fasesProcedimentoDelimitacao
        },
        tiposSentenca: {
            setFn: setCombos,
            data: tiposSentenca
        },
        tiposDecisaoFinal: {
            setFn: setCombos,
            data: tiposDecisaoFinal
        },
        validacoesProva: {
            setFn: setCombos,
            data:validacoesProva
        },
        tiposParcela: {
            setFn: setCombos,
            data: tiposParcela
        },
        tiposPendencia: {
            setFn: setCombos,
            data: tiposPendencia
        },
        condicionantesProcessos: {
            setFn: setCombos,
            data: condicionantesProcessos
        },
        tiposAcaoJudicial: {
            setFn: setCombos,
            data: tiposAcaoJudicial
        },
        tiposDesafetacao: {
          setFn: setCombos,
            data: tiposDesafetacao
        },
        tiposAquisicao: {
            setFn: setCombos,
            data: tiposAquisicao
        },
        tiposIniciativa: {
            setFn: setCombos,
            data: tiposIniciativa
        },
        dhTipoDominio: {
            setFn: setCombos,
            data: dhTipoDominio
        },
        dhRppAntes: {
            setFn: setCombos,
            data: dhRppAntes
        },
        dhRppDepois: {
            setFn: setCombos,
            data: dhRppDepois
        },
        dhRecurso: {
            setFn: setCombos,
            data: dhRecurso
        },
        dhDeferimento: {
            setFn: setCombos,
            data: dhDeferimento
        },
      };

    const filterCombo_Multiselect = (e: any, tipoCombo: combosKeys) => {
        const { setFn, data } = setDataOption[tipoCombo];
        setFn({
            ...combos,
            [tipoCombo]: filterBy(data?.slice()??[], e.filter)
        }
        );
    }

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/PesquisarProcesso" });
    // ReactGA.pageview(window.location.pathname + "PesquisarProcesso");
    }, []);
    
    useEffect(() => {
        if(!tipossProcesso){
         fillTiposProcessoAsync();
        }
        if(!localizacoesProcesso){
         fillLocalizacoesProcessosAsync();
        }
        if(!estadosProcesso){
         fillEstadosProcessoAsync();
        }
        if(!fasesProcedimentoDelimitacao){
         fillFasesProcedimentoDelimitacaoAsync();
        }
        if(!tiposSentenca){
         fillTiposSentencaAsync();
        }
         if(!tiposDecisaoFinal){
         fillTiposDecisaoFinalAsync();
        }
         if(!validacoesProva){
         fillValidacoesProvaAsync();
        }
        if(!tiposParcela){
         fillTiposParcelaProvaAsync();
        }
         if(!tiposPendencia){
         fillTiposPendenciaAsync();
        }
         if(!condicionantesProcessos){
         fillCondicionantesProcessosAsync();
        }
        if(!tiposAcaoJudicial){
         fillTiposAcaoJudicialAsync();
        }
        if(!tiposDesafetacao){
         fillTiposDesafetacaoAsync();
        }
        if(!tiposAquisicao){
         fillTiposAquisicaoAsync();
        }
         if(!tiposIniciativa){
         fillTiposIniciativaAsync();
        }
        if(!estadosProcesso){
           fillEstadosProcessoAsync();
        }
        if(!dhTipoDominio){
           fillDhTipoDominioAsync();
        }
        if(!dhRppAntes){
           fillDhRppAntesAsync();
        }
        if(!dhRppDepois){
           fillDhRppDepoisAsync();
        }
        if(!dhRecurso){
           fillDhRecursoAsync();
        }
        if(!dhDeferimento){
            fillDhDeferimentoAsync();
        }
     }, []);

   const canSeeDominioHidrico = 
                            hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_LEITURA) ||
                            hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_EDICAO) ||
                            hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_TOTAL) 

    const [estadoVisible, setEstadoVisible] = useState(false)
    const toggleEstado = () => setEstadoVisible(!estadoVisible);

    const getProcessos = async (data: any) => await post<any, any>(`${CONSULTAR_DH_PROCESSO}`, data)


    const changerbCriterio = (e: any) => {
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage({ type: "SiarlDeselectMapArea", value: 'true' }, '*')
        if (e.value == POLIGONO_SELECAO) {
            postMessage(
                { type: 'informationMsg', value: 'Selecione a área de pesquisa' },
                '*'
            )
            const iframe: any = document.getElementById('iframeGeoPortal')
            iframe.contentWindow?.postMessage(
                { type: 'SiarlGeoPortalAdicionarArea', value: 'true' },
                '*'
            )
        }
        if (e.value == AREA_VISIVEL) {
            iframe.contentWindow?.postMessage({ type: "SiarlSelectMapArea", value: 'true' }, '*')
            postMessage(
                {
                    type: 'informationMsg',
                    value: 'Foi selecionada a área visível do mapa',
                },
                '*'
            )
           
            iframe.contentWindow?.postMessage(
                { type: 'SiarlGetMapArea', value: 'true' },
                '*'
            )
        }
        if (e.value == NENHUM) {
            setCoordenadasPoligono(undefined)
            clearMap()
        }
    }

    useEffect(() => {
        clearMap();
    }, [])

    const [dataInicio, setDataInicio] = useState('');
    const [dataFim, setDataFim] = useState('');

  
    const clearMap = () => {
        try {
    
          setCoordenadasPoligono(undefined)
          const iframe: any = document.getElementById('iframeGeoPortal')
          iframe.contentWindow?.postMessage({ type: "SiarlProcessoClearLayers", value: "true" }, '*')
          iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: "true" }, '*')
          iframe.contentWindow?.postMessage({ type: "SiarlDeselectMapArea", value: "true" }, '*')
    
        } catch (error) {
          console.log({ error })
        }
    }

    const filterDataTecnicos = (filter: FilterDescriptor) => {
        const data = tecnicos.slice();
        return filterBy(data, filter);
      };
    
    const filterChangeTecnicos = (event: ComboBoxFilterChangeEvent) => {
        setTecnicosData(filterDataTecnicos(event.filter));
    };

    const filterDataEntidades = (filter: FilterDescriptor) => {
        const data = entidades.slice();
        return filterBy(data, filter);
      };
    
    const filterChangeEntidades = (event: ComboBoxFilterChangeEvent) => {
        setEntidadesData(filterDataEntidades(event.filter));
    };

    const handleSubmit = async (e: any) => {

      
        postMessage({ type: 'showLoader' }, '*')

        const objToSearch = {
            //  REGISTO
            id: e.values?.id,
            np_filedoc: e.values?.np_filedoc ?? undefined,
            id_tipo_es: e.values?.id_tipo_es?.id ?? undefined,
            user_tecnico_id: e.values?.tecnico ? e.values?.tecnico.id : undefined,
            entidade_id: e.values?.entidade?.id ?? null,
            data_inicio: e.values?.data_inicio ?? undefined,
            data_fim: e.values?.data_fim ?? undefined,


            //  TIPOLOGIA
            id_tipo_processo: e.values?.id_tipo_processo?.id ?? undefined,
            id_tipo_aj: e.values?.id_tipo_aj?.id ?? undefined,
            id_tipo_in: e.values?.id_tipo_in?.id ?? undefined,
            id_tipo_aq: e.values?.id_tipo_aq?.id ?? undefined,


            //  IDENTIFICAÇÃO PROCESSO
            requerente: e.values?.requerente ?? undefined,
            assunto: e.values?.assunto ?? undefined,
            id_localizacao_processo: e.values?.id_localizacao_processo?.id ?? undefined,
            np_legado: e.values?.np_legado ?? undefined,
            n_cdpm: e.values?.n_cdpm ?? undefined,
            np_mp: e.values?.np_mp ?? undefined,
            num_doc: e.values?.num_doc ?? undefined,
            np_ent_por: e.values?.np_ent_por ?? undefined,
            np_comum: e.values?.np_comum ?? undefined,


            //  CRITÉRIO GEOGRÁFICO
            criterio_geografico: e?.values?.rbCriterio ?? undefined,


            //  LOCALIZAÇÃO
            nuts1_cod: e.values?.nuts1?.codigo ?? undefined,
            nuts2_cod: e.values?.nuts2?.codigo ?? undefined,
            nuts3_cod: e.values?.nuts3?.codigo ?? undefined,
            distrito_cod: e.values?.distrito?.codigo ?? undefined,
            concelho_cod: e.values?.concelho?.codigo ?? undefined,
            freguesia_cod: e.values?.freguesia?.codigo ?? undefined,
            arh_cod: e.values?.arh?.codigo ?? undefined,
            capitania_cod: e.values?.capitania?.codigo ?? undefined,
            massa_agua_id: e.values?.massaagua?.id ?? undefined,
            lugar: e.values?.local ?? undefined,


            //  FASE PROCEDIMENTO
            id_fase_pr: e.values?.id_fase_pr?.id ?? undefined,
            representante: e.values?.representante ?? undefined,


            // OUTROS CRITÉRIOS
            def_licenciamento: e.values?.comDeferimento?.id ?? undefined,


            //  RPP
            dh_rpp_antes_dl_id: e.values?.rpp_antes_DL ?? undefined,
            dh_tipo_acao_reconhecimento_id: e.values?.rpp_depois_DL ?? undefined,
      

            //  AUTO DELIMITAÇÃO
            id_tipo_ad: e.values?.tipo_DominioAuto?.id ?? undefined,

            //  DIPLOMA PUBLICADO
            id_cond_pr: e.values?.id_cond_pr?.id ?? undefined,
            dr: e.values?.diarioRepublica ?? undefined,


            //  DECISÃO
            dec_final: e.values?.dec_final ?? undefined,
            id_tipo_se: e.values?.id_tipo_se?.id ?? undefined,
            id_tipo_ac: e.values?.id_tipo_ac?.id ?? undefined,


            geom: undefined,
            flatCoordinates: coordenadasPoligono,
            tipo_geom_indefinida: false,
            status: e.values?.status?.valor ?? undefined,
        }

        sessionStorage.setItem("lastSearchProcessos", JSON.stringify(objToSearch));
         const result: any = await getProcessos(objToSearch);

        if (result) {

            SessionStorageService.setItem('resultadoProcessosPesquisa', JSON.stringify(result))
            postMessage({ type: 'resultadoProcessosPesquisaChange', value: result }, '*')

        }
        SessionStorageService.setItem("firstCallProcesso", true);

    }

    useEffect(() => {
        sessionStorage.setItem("SubModulo", PROCESSO_MODULE_NAME);
        SessionStorageService.setItem("DominioHidricoTipoPesquisa", PROCESSO_MODULE_NAME);
    }, []);

    
    {/*  VARIAR COMPOS CONFORME OPÇÃO TIPO DE PROCESSO  */}
    const [showDefault, setDefault] = useState<boolean>(true);
    const [showOpcao0, setOpcao0] = useState<boolean>(false);
    const [showOpcao1, setOpcao1] = useState<boolean>(false);
    const [showOpcao2, setOpcao2] = useState<boolean>(false);
    const [showOpcao3, setOpcao3] = useState<boolean>(false);
    const [showOpcao4, setOpcao4] = useState<boolean>(false);
    const [showOpcao5, setOpcao5] = useState<boolean>(false);
    const [showOpcao6, setOpcao6] = useState<boolean>(false);
    const [showOpcao7, setOpcao7] = useState<boolean>(false);
    
    const verificarID = (idTipoProcesso: any) => {
        const ValueCombo = idTipoProcesso?.id

        console.log("Foi escolhido o ID: " + ValueCombo)
        
        if (ValueCombo === 0) {
            setOpcao0(true);
            setOpcao1(false);
            setOpcao2(false);
            setOpcao3(false);
            setOpcao4(false);
            setOpcao5(false);
            setOpcao6(false);
            setOpcao7(false);
            setDefault(false);
        }else if (ValueCombo === 1) {
            setOpcao0(false);
            setOpcao1(true);
            setOpcao2(false);
            setOpcao3(false);
            setOpcao4(false);
            setOpcao5(false);
            setOpcao6(false);
            setOpcao7(false);
            setDefault(false);
        }else if (ValueCombo === 2) {
            setOpcao0(false);
            setOpcao1(false);
            setOpcao2(true);
            setOpcao3(false);
            setOpcao4(false);
            setOpcao5(false);
            setOpcao6(false);
            setOpcao7(false);
            setDefault(false);
        }else if (ValueCombo === 3) {
            setOpcao0(false);
            setOpcao1(false);
            setOpcao2(false);
            setOpcao3(true);
            setOpcao4(false);
            setOpcao5(false);
            setOpcao6(false);
            setOpcao7(false);
            setDefault(false);
        }else if (ValueCombo === 4) {
            setOpcao0(false);
            setOpcao1(false);
            setOpcao2(false);
            setOpcao3(false);
            setOpcao4(true);
            setOpcao5(false);
            setOpcao6(false);
            setOpcao7(false);
            setDefault(false);
        }else if (ValueCombo === 5) {
            setOpcao0(false);
            setOpcao1(false);
            setOpcao2(false);
            setOpcao3(false);
            setOpcao4(false);
            setOpcao5(true);
            setOpcao6(false);
            setOpcao7(false);
            setDefault(false);
        }else if (ValueCombo === 6) {
            setOpcao0(false);
            setOpcao1(false);
            setOpcao2(false);
            setOpcao3(false);
            setOpcao4(false);
            setOpcao5(false);
            setOpcao6(true);
            setOpcao7(false);
            setDefault(false);
        }else if (ValueCombo === 7) {
            setOpcao0(false);
            setOpcao1(false);
            setOpcao2(false);
            setOpcao3(false);
            setOpcao4(false);
            setOpcao5(false);
            setOpcao6(false);
            setOpcao7(true);
            setDefault(false);
        }
    };

    return (
        <Form
            onSubmitClick={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
                <>
                    <FormElement className={styles.form}>

                        {/* FIELDSET REGISTO */}
                        {canSeeDominioHidrico && (showDefault || showOpcao0 || showOpcao1 || showOpcao2 || showOpcao3 || showOpcao4 || showOpcao5 || showOpcao6 || showOpcao7) ? (
                            <fieldset>
                                <legend>
                                    <button
                                    type="button"
                                    onClick={toggleRegisto}
                                    className={styles.itemExpandBase}
                                    >
                                    <div className={styles.btn_Expandir_Colapsar}>
                                        <span
                                        className={
                                            registoVisible
                                            ? 'icon-siarl icon-colapsar'
                                            : 'icon-siarl icon-expandir'
                                        }
                                        ></span>
                                    </div>
                                    <span className={styles.fieldsetTitulo}>Registo</span>
                                    </button>
                                </legend>

                                <div className={styles.espacoVerticalFieldSet}>
                                {registoVisible && (hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_BASICO) || canSeeDominioHidrico) ? (
                                    <>
                                        <div className={styles.fields}>
                                            <div>
                                                <Field name="id" label="ID" component={Input} />
                                            </div>

                                            <div>
                                                <Field name="np_filedoc" label="Nº Processo APA" component={Input} />
                                            </div>

                                            <div>
                                                <Field
                                                    name="np_legado"
                                                    label="Nº Proc Legado"
                                                    component={Input}
                                                />
                                            </div>
                                        </div>

                                        {registoVisible && canSeeDominioHidrico ? (
                                            <div className={styles.fields}>
                                                <div>
                                                    <Field
                                                        name="tecnico"
                                                        label="Técnico"
                                                        component={ComboBox}
                                                        textField={'name'}
                                                        filterable={true}
                                                        onFilterChange={filterChangeTecnicos}
                                                        data={tecnicosData}
                                                    />
                                                </div>

                                                <div>
                                                    <Field
                                                        name="entidade"
                                                        label="Entidade"
                                                        component={ComboBox}
                                                        textField={'nome'}
                                                        filterable={true}
                                                        onFilterChange={filterChangeEntidades}
                                                        data={entidadesData}
                                                    />
                                                </div>
                                            </div>
                                        ) : ( '' )}
                                        
                                        <div className={styles.fields}>
                                                <div>
                                                    <Field
                                                        name="data_inicio"
                                                        label="Registos de"
                                                        component={DateTimePicker}
                                                        format="dd/MM/yyyy HH:mm"
                                                        onChange={(e) => {
                                                            setDataInicio(e.value)
                                                            setMinDate(e.value)
                                                        } }
                                                    />
                                                </div>

                                                <div>
                                                    <Field
                                                        name="data_fim"
                                                        label="Registos até"
                                                        component={DateTimePicker}
                                                        min={minData}
                                                        format="dd/MM/yyyy HH:mm"
                                                    />
                                                </div>
                                            </div>
                                        </>
                                ) : ( '' )}
                                </div>
                            </fieldset>
                        ) : ''}

                        {/* FIELDSET TIPOLOGIA */}
                        {canSeeDominioHidrico && (showDefault || showOpcao0 || showOpcao1 || showOpcao2 || showOpcao3 || showOpcao4 || showOpcao5 || showOpcao6 || showOpcao7) ? (
                            <fieldset>
                                <legend>
                                        <button type='button' onClick={toggleTipologia} className={styles.itemExpandBase}>
                                            <div className={styles.btn_Expandir_Colapsar}>
                                                <span className={TipologiaVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                                            </div>
                                            <span className={styles.fieldsetTitulo}>Tipologia</span>
                                        </button>
                                </legend>

                                <div className={styles.espacoVerticalFieldSet}>
                                    {TipologiaVisible && (
                                        <>
                                            <div className={styles.fields}>
                                                <div>
                                                    <Field
                                                        name="id_tipo_processo"
                                                        label="Tipo de processo"
                                                        component={ComboBox}
                                                        textField={'descricao'}
                                                        filterable={true}
                                                        onFilterChange={(e: any) => { filterCombo_Multiselect(e, 'tipossProcesso') }}
                                                        onChange={(event) => verificarID(event.target.value)}
                                                        data={combos?.tipossProcesso}
                                                    />
                                                </div>
                                            </div>
                                        
                                            <div className={styles.fields}>
                                                {canSeeDominioHidrico && showOpcao3 ? (
                                                    <div>
                                                        <Field
                                                            name="id_tipo_aj"
                                                            label="Tipo de ação judicial"
                                                            component={ComboBox}
                                                            textField={'descricao'}
                                                            filterable={true}
                                                            onFilterChange={(e: any) => {
                                                                filterCombo_Multiselect(e, 'tiposAcaoJudicial')
                                                            } }
                                                            data={combos?.tiposAcaoJudicial}
                                                        />
                                                    </div>
                                                ) : ''}

                                                {canSeeDominioHidrico && (showOpcao1 || showOpcao2) ? (
                                                    <div>
                                                        <Field
                                                            name="id_tipo_in"
                                                            label="Tipo de iniciativa"
                                                            component={ComboBox}
                                                            textField={'descricao'}
                                                            filterable={true}
                                                            onFilterChange={(e: any) => {
                                                                filterCombo_Multiselect(e, 'tiposIniciativa')
                                                            } }
                                                            data={combos?.tiposIniciativa}
                                                        />
                                                    </div>
                                                ) : ''}

                                                {(canSeeDominioHidrico || hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_BASICO)) && showOpcao5 ? (
                                                    <div>
                                                        <Field
                                                            name="id_tipo_aq"
                                                            label="Tipo de aquisição"
                                                            component={ComboBox}
                                                            textField={'descricao'}
                                                            filterable={true}
                                                            onFilterChange={(e: any) => { filterCombo_Multiselect(e, 'tiposAquisicao') }}
                                                            data={combos?.tiposAquisicao}
                                                        />
                                                    </div>
                                                ) : ''}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </fieldset>
                        ) : ''}


                        {/* FIELDSET IDENTIFICAÇÃO */}
                        <fieldset>
                            <legend>
                                <button type='button' onClick={toggleIdentificacaoVisible} className={styles.itemExpandBase}>
                                    <div className={styles.btn_Expandir_Colapsar}>
                                        <span className={identificacaoVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                                    </div>
                                    <span className={styles.fieldsetTitulo}>Identificação do processo</span>
                                </button>
                            </legend>

                            {identificacaoVisible && ( hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_BASICO) || canSeeDominioHidrico)  ? (
                                <div className={styles.espacoVerticalFieldSet}>
                                    <>
                                        <div className={styles.fields}>
                                            <div>
                                                <Field
                                                    name="requerente"
                                                    label="Requerente"
                                                    component={Input}
                                                />
                                            </div>
                                        </div>

                                        <div className={styles.fields}>
                                            <div>
                                                <Field
                                                    name="assunto"
                                                    label="Assunto"
                                                    component={Input}
                                                />
                                            </div>
                                        </div>

                                        {canSeeDominioHidrico && (showOpcao1 || showOpcao2 || showOpcao4 || showOpcao5 || showOpcao6) ? (
                                            <div className={styles.fields}>
                                                <div>
                                                    <Field
                                                        name="id_localizacao_processo"
                                                        label="Localização do processo"
                                                        component={ComboBox}
                                                        textField={'descricao'}
                                                        filterable={true}
                                                        onFilterChange={(e: any) => { filterCombo_Multiselect(e, 'localizacoesProcesso') } }
                                                        data={combos?.localizacoesProcesso}
                                                    />
                                                </div>
                                            </div>
                                        ) : ''}
                                        
                                        <div className={styles.fields}>
                                            <div>
                                                <Field
                                                    name="id_tipo_es"
                                                    label="Estado do processo"
                                                    component={ComboBox}
                                                    textField={'descricao'}
                                                    filterable={true}
                                                    onFilterChange={(e: any) => { filterCombo_Multiselect(e, 'estadosProcesso') } }
                                                    data={combos?.estadosProcesso}
                                                />
                                            </div>

                                            {canSeeDominioHidrico && (showOpcao1 || showOpcao2 || showOpcao6) ? (
                                                <div>
                                                    <Field
                                                        name="n_cdpm"
                                                        label="Nº Proc CDPM"
                                                        component={Input}
                                                    />
                                                </div>
                                            ) : ''}
                                        </div>
                                        
                                        {canSeeDominioHidrico && showOpcao3 ? (
                                            <div className={styles.fields}>
                                                <div>
                                                    <Field
                                                        name="np_mp"
                                                        label="Nº Proc Administrativo (MP)"
                                                        component={Input}
                                                    />
                                                </div>
                                                <div>
                                                    <Field
                                                        name="np_comum"
                                                        label="Nº Proc Tribunal"
                                                        component={Input}
                                                    />
                                                </div>
                                            </div>
                                        ) : ''}
                                        
                                        <div className={styles.fields}>
                                            {canSeeDominioHidrico && showOpcao1 ? (
                                                <div>
                                                    <Field
                                                        name="np_ent_por"
                                                        label="Nº Proc Ent Portuária"
                                                        component={Input}
                                                    />
                                                </div>
                                            ) : ''}

                                            {canSeeDominioHidrico && showOpcao7 ? (
                                                <div>
                                                    <Field
                                                        name="num_doc"
                                                        label="Nº de Registo SGD"
                                                        component={Input}
                                                    />
                                                </div>
                                            ) : ''}
                                        </div>
                                    </>
                                
                                </div>
                            ) : ''}
                        </fieldset>


                        {/* FIELDSET CRITERIO GEOGRÁFICO */}
                        {canSeeDominioHidrico || hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_BASICO)?
                            <fieldset>

                                <legend>
                                    <button type='button' className={styles.itemExpandBase} onClick={toggleCriterioGeografico}>
                                        <div className={styles.btn_Expandir_Colapsar}>
                                            <span className={criterioGeograficoVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                                        </div>
                                        <span className={styles.fieldsetTitulo}>Critério geográfico</span>
                                    </button>
                                </legend>

                                {criterioGeograficoVisible && (

                                    <div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}>
                                        <Field
                                            id={'rbCriterio'}
                                            name={'rbCriterio'}
                                            layout={'horizontal'}
                                            component={RadioGroup}
                                            onChange={(e) => {
                                                changerbCriterio(e)
                                            }}
                                            data={[
                                                { label: 'Nenhum', value: NENHUM},
                                                { label: 'Área visível', value: AREA_VISIVEL },
                                                { label: 'Polígono de seleção', value: POLIGONO_SELECAO },
                                            ]}
                                            defaultValue={NENHUM} // Set the default selected value to 0 (Nenhum)

                                        />
                                    </div>
                                )}
                            </fieldset>
                        : ''}


                        {/* FIELDSET LOCALIZAÇÃO */}
                        <FieldsetCombosLocalizacao isPesquisaAvancada={true} haveLocal={true} NomeLocal={"Lugar Toponimico"} styles={styles} formRenderProps={formRenderProps}/>


                        {/* FIELDSET FASE PROCEDIMENTO */}
                        {canSeeDominioHidrico && (showOpcao1 || showOpcao2) ? (
                            <fieldset>
                                <legend>
                                    <button type='button' onClick={toggleFaseProcesso} className={styles.itemExpandBase}>
                                        <div className={styles.btn_Expandir_Colapsar}>
                                            <span className={faseProcessoVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                                        </div>
                                        <span className={styles.fieldsetTitulo}>Fase do Procedimento</span>
                                    </button>
                                </legend>

                                {faseProcessoVisible && (
                                    <div className={styles.espacoVerticalFieldSet}>
                                        <div className={styles.fields}>
                                            <div>
                                                <Field
                                                    name="id_fase_pr"
                                                    label="Fase de procedimento"
                                                    component={ComboBox}
                                                    textField={'descricao'}
                                                    filterable={true}
                                                    onFilterChange={(e: any) => { filterCombo_Multiselect(e, 'fasesProcedimentoDelimitacao') } }
                                                    data={combos?.fasesProcedimentoDelimitacao}
                                                />
                                            </div>

                                            <div>
                                                <Field
                                                    name="representante"
                                                    label="Representante na comissão delimitação"
                                                    component={Input}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </fieldset>
                        ) : ''}
                        

                        {/* FIELDSET RECONHECIMENTO PROPRIEDADE PRIVADA */}
                        {canSeeDominioHidrico && (showOpcao1 || showOpcao2 || showOpcao6) ? (
                            <fieldset>
                                <legend>
                                    <button type='button' onClick={toggleRppVisible} className={styles.itemExpandBase}>
                                        <div className={styles.btn_Expandir_Colapsar}>
                                            <span className={rppVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                                        </div>
                                        <span className={styles.fieldsetTitulo}>Reconhecimento Propriedade Privada</span>
                                    </button>
                                </legend>

                                {rppVisible && (
                                    <div className={styles.espacoVerticalFieldSet}>
                                        <div className={styles.fields}>
                                            {canSeeDominioHidrico && (showOpcao1 || showOpcao6) ? (
                                                <div>
                                                    <Field
                                                        name="rpp_antes_DL" // TD9 - dh_reconhecimento_propriedade_privada
                                                        label="RPP antes DL 353/2007"
                                                        component={ComboBox}
                                                        textField={'descricao'}
                                                        filterable={true}
                                                        onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'dhRppAntes')}}
                                                        data={combos?.dhRppAntes}
                                                    />
                                                </div>
                                            ) : ''}

                                            {canSeeDominioHidrico && showOpcao2 ? (
                                                <div>
                                                    <Field
                                                        name="rpp_depois_DL" // TD9 - dh_tipo_acao_reconhecimento
                                                        label="RPP após DL 353/2007"
                                                        component={ComboBox}
                                                        textField={'descricao'}
                                                        filterable={true}
                                                        onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'dhRppDepois')}}
                                                        data={combos?.dhRppDepois}
                                                    />
                                                </div>
                                            ) : ''}
                                        </div>
                                    </div>
                                )}
                            </fieldset>
                        ) : ''}


                        {/* Fieldset Outros critérios */}
                        {canSeeDominioHidrico && showOpcao6 ? (
                            <fieldset>
                                <legend>
                                    <button type='button' onClick={toggleOutrosCriterios} className={styles.itemExpandBase}>
                                        <div className={styles.btn_Expandir_Colapsar}>
                                            <span className={outrosCriteriosVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                                        </div>
                                        <span className={styles.fieldsetTitulo}>Outros critérios</span>
                                    </button>
                                </legend>

                                {outrosCriteriosVisible && (
                                    <div className={styles.espacoVerticalFieldSet}>
                                        <div className={styles.fields}>
                                            <div>
                                                <Field
                                                    name="comDeferimento"
                                                    label="Deferimento de licenciamento"
                                                    component={ComboBox}
                                                    textField={'descricao'}
                                                    filterable={true}
                                                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'dhDeferimento')}}
                                                    data={combos?.dhDeferimento}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </fieldset>
                        ) : ''}


                        {/* FIELDSET AUTO DELIMITAÇÃO */}
                        {canSeeDominioHidrico && (showOpcao1 || showOpcao2 || showOpcao3 || showOpcao4 || showOpcao5) ? (
                            <fieldset>
                                <legend>
                                    <button type='button' onClick={toggleAutoDelimitacaoVisible} className={styles.itemExpandBase}>
                                        <div className={styles.btn_Expandir_Colapsar}>
                                            <span className={autoDelimitacaoVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                                        </div>
                                        <span className={styles.fieldsetTitulo}>Auto Delimitação</span>
                                    </button>
                                </legend>

                                {autoDelimitacaoVisible && (
                                    <div className={styles.espacoVerticalFieldSet}>
                                        <div className={styles.fields}>
                                            {canSeeDominioHidrico && showOpcao3 ? (
                                                <div>
                                                    <Field
                                                        name="tipo_DominioAuto"
                                                        label="Tipo Dominio"
                                                        component={ComboBox}
                                                        textField={'descricao'}
                                                        filterable={true}
                                                        onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'dhTipoDominio')}}
                                                        data={combos?.dhTipoDominio}
                                                    />
                                                </div>
                                            ) : ''}

                                            {canSeeDominioHidrico && (showOpcao1 || showOpcao2 || showOpcao4 || showOpcao5) ? (
                                                <div>
                                                    <Field
                                                        name="diarioRepublica"
                                                        label="Diário da República"
                                                        component={Input}
                                                    />
                                                </div>
                                            ) : ''}
                                        </div>
                                    </div>
                                )}
                            </fieldset>
                        ) : ''}


                        {/* FIELDSET CONDICIONANTES */}
                        {canSeeDominioHidrico && showOpcao1 ? (
                            <fieldset>
                                <legend>
                                    <button type='button' onClick={toggleDiplomaPublicadoVisible} className={styles.itemExpandBase}>
                                        <div className={styles.btn_Expandir_Colapsar}>
                                            <span className={diplomaPublicadoVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                                        </div>
                                        <span className={styles.fieldsetTitulo}>Condicionantes</span>
                                    </button>
                                </legend>

                                {diplomaPublicadoVisible && (
                                    <div className={styles.espacoVerticalFieldSet}>
                                        <div className={styles.fields}>
                                            <div>
                                                <Field
                                                    name="id_cond_pr"
                                                    label="Condicionantes ao procedimento de delimitação"
                                                    component={ComboBox}
                                                    textField={'descricao'}
                                                    filterable={true}
                                                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'condicionantesProcessos')}}
                                                    data={combos?.condicionantesProcessos}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </fieldset>
                        ) : ''}


                        {/* FIELDSET DECISÂO */}
                        {canSeeDominioHidrico && showOpcao3 ? (
                            <fieldset>
                                <legend>
                                    <button type='button' onClick={toggleDecisaoVisible} className={styles.itemExpandBase}>
                                        <div className={styles.btn_Expandir_Colapsar}>
                                            <span className={decisaoVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                                        </div>
                                        <span className={styles.fieldsetTitulo}>Decisão</span>
                                    </button>
                                </legend>

                                {decisaoVisible && (
                                    <div className={styles.espacoVerticalFieldSet}>
                                        <div className={styles.fields}>
                                            <div>
                                                <Field
                                                    name="dec_final"
                                                    label="Decisão final"
                                                    component={ComboBox}
                                                    textField={'descricao'}
                                                    filterable={true}
                                                    onFilterChange={(e: any) => { filterCombo_Multiselect(e, 'tiposDecisaoFinal') } }
                                                    data={combos?.tiposDecisaoFinal}
                                                />
                                            </div>

                                            <div>
                                                <Field
                                                    name="id_tipo_se"
                                                    label="Sentença"
                                                    component={ComboBox}
                                                    textField={'descricao'}
                                                    filterable={true}
                                                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'tiposSentenca')}}
                                                    data={combos?.tiposSentenca}
                                                />
                                            </div>
                                        </div>

                                        <div className={styles.fields}>
                                            <div>
                                                <Field
                                                    name="id_tipo_ac"
                                                    label="Recurso"
                                                    component={ComboBox}
                                                    textField={'descricao'}
                                                    filterable={true}
                                                    onFilterChange={(e: any) => { filterCombo_Multiselect(e, 'dhRecurso') } }
                                                    data={combos?.dhRecurso}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </fieldset>
                        ) : ''}
                    </FormElement>

                    <div className={`${styles.footer} ${styles.justify_multiple_children}`}>

                        <Button
                            onClick={() => { formRenderProps.onFormReset(); clearMap()}}
                            className={styles.btnLimparPesquisa}
                            title='Limpar pesquisa'
                        >
                            <span className="icon-siarl icon-limparcampos"></span>
                            <span className={styles.rotulo}>
                                {' '}&nbsp;{' '}
                                Limpar pesquisa
                            </span>                            
                        </Button>

                        <Button
                            onClick={formRenderProps.onSubmit}
                            className={styles.btnPesquisar}
                            title='Pesquisar'
                        >
                            <span className="icon-siarl icon-pesquisar"></span>
                            <span className={styles.rotulo}>
                                {' '}&nbsp;{' '}
                                Pesquisar
                            </span>                            
                        </Button>

                    </div>
                </>
            )}
        />
    )
}