import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import styles from '../../styles/usodosolo/UsoDoSolo.module.scss'
import stylesAG from '../../../styles/apoiogestao/ApoioGestao.module.scss'
import clsx from 'clsx'

type HoverState = 'idle' | 'enter' | 'leave'

type Props = {
  onDetailsClick: (documentos: Record<string, unknown>) => () => void
  onMapCenter: (documentos: Record<string, unknown>) => () => void
  dataItem: Record<string, unknown>
}

export const ResultadoApoioGestaoPretensaoCommandGridCell = ({
  onDetailsClick,
  onMapCenter,
  dataItem,
}: Props) => {
  return (
    <td className="k-command-cell">
      
      <div className={'gridCommand'}>
      {dataItem.hasGeom ?
        <div
          role="button"
          onClick={onMapCenter(dataItem)}
          className={clsx(styles.gridCommandIconButton, 'espacoGridAction')}
        >
          <FontAwesomeIcon icon={faLocationDot} title="Mostrar no Mapa" />
        </div>
        : <div className='espacoGridAction'>&nbsp;</div>
        }
        <div
          role="button"
          className={styles.gridCommandIconButton}
          onClick={onDetailsClick(dataItem)}
        >
          <FontAwesomeIcon icon={faInfoCircle} title="Detalhes de registo" />
        </div>
      </div>
    </td>
  )
}
