import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from '@progress/kendo-react-form'

import {
  MouseEvent as ReactMouseEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'

import {
  Grid,
  GridCellProps,
  GridColumn,
  GridPageChangeEvent,
} from '@progress/kendo-react-grid'

import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from '@progress/kendo-react-layout'

import { Button, DropDownButton } from '@progress/kendo-react-buttons'

import { PagerTargetEvent } from '@progress/kendo-react-data-tools'


import styles from '../../styles/apoiogestao/ApoioGestao.module.scss'

import { AG_DESCONFORMIDADE_POINT_NAME, AUTH_LOCALSTORAGE, GET_AG_DESCONFORMIDADE, GET_AG_DESCONFORMIDADE_BY_ID, GET_URL_FROM_FILE_DOC, IMPORT_GEOM_DESCONFORMIDADE, PERMISSION_APOIO_GESTAOPERFIL_INTERMEDIO_EDICAO, PERMISSION_APOIO_GESTAO_PERFIL_COMPLETO_EDICAO, PERMISSION_APOIO_GESTAO_PERFIL_TOTAL, PERMISSION_ATULIZAR_DESCONFORMIDADE, PERMISSION_INSERIR_DESCONFORMIDADE, RELATORIO_DESCONFORMIDADE, REMOVER_AREA_AG_DESCONFORMIDADE, SAVE_NEW_AG_DESCONFORMIDADE, UPDATE_AG_DESCONFORMIDADE, URL_AJUDA_APOIO_GESTAO_DESCONFORMIDADE, clearLayers} from '../../utils/constants'
import { GetUserNameTecnico, agEstados, b64toBlob, coordenadasPoligono, entidadeUser, get, getDesconformidadeSelected, handleGenericError, hasPermission, post, put, setCoordenadasPoligono, setDesconformidadeSelected } from '../../services/utils'
import DesconformidadeRegistar from './registar/DesconformidadeRegistar'
import DocumentosForm from './registar/Documentos'
import AssociacaoForm from './registar/Associacao'
import { useAgDesconformidadeStore } from '../../stores/agDesconformidade'
import { DocumentoListagem } from '../documentos/registar/documento/documento'
import { AssociacaoListagem } from '../associacao/Associacao'
import ImportarGeometriaComponent from './registar/ImportarGeometriaForm'
import { Dialog } from '@progress/kendo-react-dialogs'
import SessionStorageService from '../../services/SessionStorageService'
import useClearMap from '../../hooks/useClearMap'
import html2canvas from 'html2canvas'
import ReactGA from 'react-ga4';
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais'

type IProps = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
}

type PageState = {
  skip: number
  take: number
}


let _pdfExport: any;
  const exportExcel = () => {
    _export.save();
  };
  let _export: any;
  const exportPDF = () => {
    _pdfExport.save();
  };
const TOTAL_TABS = 3

const initialDataState: PageState = { skip: 0, take: 10 }
 type AgDesconformidadeObject ={
  id: number;
  utilizador_id: number;
  utilizador: unknown;
  data_registo: string;
  ag_tema_id: number;
  ag_tema: unknown;
  descricao: string;
  anexos_path: string;
  medida_corretiva_a_aplicar: string;
  medida_corretiva_aplicada: string;
  ag_estado_id: number;
  ag_estado: unknown;
  flatCoordinates: string;
  status: boolean;
}
export default function RegistarApoioGestaoDesconformidadeForm({ onCloseClick }: IProps) {
  const [isMobile, setIsMobile] = useState(false);
  const editar = [
    {
      key: 'SiarlGeoPortalAdicionarPonto',
      text: 'Editar Registo',
      icon: <span className={`icon-siarl icon-editar borderIconArea topIcon`}></span>,
    },
  ];

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/RegistarApoioGestaoDesconformidade" });
  }, []);

  const area = [
    {
      key: 'SiarlGeoPortalAdicionarArea',
      text: 'Desenhar Área de abrangência',
      icon: <span className={`icon-siarl icon-area borderIconArea`}></span>,
    },
  ];

  const ponto = [
    {
      key: '',
    text: 'Adicionar ponto de abrangência',
    icon: <span className={`icon-siarl icon-pin borderIconArea`}></span>,
    }
  ];


  const imprimir = [
    {
      key: '',
      text: 'Imprimir Ficha',
      icon: <span className={`icon-siarl icon-imprimir borderIconArea`}></span>,
    },
  ];
    
    const exportar = [
      {
        key: '',
      text: 'Exportar Ficha',
      icon: <span className={`icon-siarl icon-exportar borderIconArea`}></span>,
      }
    ];


  const [selected, setSelected] = useState<number>(0)
  const [page, setPage] = useState<PageState>(initialDataState)
  const selectedAgDesconformidadeResult: any = useAgDesconformidadeStore(
    (state) => state.selectedAgDesconformidadeResult
  ) 
  const getUrlFileDoc = async (data: any) => await get<any>(`${GET_URL_FROM_FILE_DOC}/` + encodeURIComponent(data));

  
  const selectedAgDesconformidadeResultId: any = useAgDesconformidadeStore(
    (state) => state.selectedAgDesconformidadeResultId
  )

    const [openImportModal, setOpenImportModal] = useState<any>()


  const selectAgDesconformidadeResultId = useAgDesconformidadeStore(
    (state) => state.selectAgDesconformidadeResultId
  )
  const selectAgDesconformidadeResult = useAgDesconformidadeStore(
    (state) => state.selectAgDesconformidadeResult
  )

  
  const removerAreaDesconformidade = async (data: any) =>
    await post<any, any>(`${REMOVER_AREA_AG_DESCONFORMIDADE}`, data)
  const [openRemoveAreaModal, setOpenRemoveAreaModal] = useState<any>()


  const getDesconformidadeById = async (id: any) =>
    await get<any, any>(`${GET_AG_DESCONFORMIDADE_BY_ID}/` + id)
  const importGeomDesconformidade = async (data: any) =>
    await post<any, any>(`${IMPORT_GEOM_DESCONFORMIDADE}`, data)

  const isIdentificacaoTabValid = useAgDesconformidadeStore(
    (state) => state.identificacaoTabIsValid
  )
  const [pageSizeValue, setPageSizeValue] = useState<
    number | string | undefined
  >()
    const getDesconformidade = async (data: any) =>
  await get<any, any>(`${GET_AG_DESCONFORMIDADE_BY_ID}/` + data)
  const handleSelect = (e: TabStripSelectEventArguments) => {
    if (e.selected > 0 && !selectedAgDesconformidadeResultId) {
      postMessage({type:"infoMsg", value: {message: "É necessário gravar primeiro."}})
      return;
    }
    if(e.selected==0 && selectedAgDesconformidadeResultId){
        const setValues = async () =>{
          try {
           postMessage({type: "showLoader"}, "*");
            
            const desconformidadeWithRelationshipsLists = await getDesconformidade(selectedAgDesconformidadeResultId);
               selectAgDesconformidadeResultId(desconformidadeWithRelationshipsLists.id)
              selectAgDesconformidadeResult({
             ...desconformidadeWithRelationshipsLists
      })
            setDesconformidadeSelected(desconformidadeWithRelationshipsLists);

          } catch (error) {
            handleGenericError(error);

          }finally{
               postMessage({type: "hideLoader"}, "*");
          }
        }
        setValues();
        
    }
    setSelected(e.selected)
  }
  const saveAgDesconformidade = async (data: any) =>
  await post<any, any>(`${SAVE_NEW_AG_DESCONFORMIDADE}`, data)

  const relatorioDesconformidade = async (data: any) =>
  await post<any, any>(`${RELATORIO_DESCONFORMIDADE}`, data)

const onItemClickExportar = async (event: any) => {
  let mapImage = ''

  if (!window.location.href.includes('localhost')) {
    const mapIframe = document.querySelector(
      '#iframeGeoPortal'
    ) as HTMLIFrameElement

    const mapIframeInnerContent =
      mapIframe.contentWindow?.document.querySelector(
        'div.ol-viewport'
      ) as HTMLElement;

    const mapBussula =  mapIframe.contentWindow?.document.querySelector(
      'button.fixedNorthArrowButton'
    ) as HTMLElement;

    mapIframeInnerContent.appendChild(mapBussula);
    
    const generatedImage = await html2canvas(mapIframeInnerContent);
    mapImage = generatedImage.toDataURL()
  }

  console.log(selectedAgDesconformidadeResult);

  try {
    postMessage({ type: 'showLoader' }, '*')

    const documentoBase64 = await relatorioDesconformidade({
      id: selectedAgDesconformidadeResult?.id,
      dados: {
        //REGISTO
        'REGISTO' : '',
        'Nº Processo APA: ': selectedAgDesconformidadeResult?.num_processo_file_doc ?? '',
        'Data do registo:': selectedAgDesconformidadeResult?.data_registo ? new Date(selectedAgDesconformidadeResult?.data_registo).toLocaleDateString() : '',
        'Técnico': selectedAgDesconformidadeResult?.utilizador?.name ?? '',
        'Descrição': selectedAgDesconformidadeResult?.descricao ?? '',

        //IDENTIFICAÇÃO
        'IDENTIFICAÇÃO:' : '',
        'Tema:': selectedAgDesconformidadeResult?.ag_tema?.descricao ?? '',
        'Estado:': selectedAgDesconformidadeResult?.ag_estado?.descricao ?? '',
        'Medida corretiva a aplicar:': selectedAgDesconformidadeResult?.medida_corretiva_a_aplicar ?? '',
        'Decisão:': selectedAgDesconformidadeResult?.ag_decisao?.descricao ?? '',


        //LOCALIZAÇÃO
        'LOCALIZAÇÃO' : '',
        'Nuts1:': selectedAgDesconformidadeResult?.nuts1[0]?.nome ?? '',
        'Nuts2:': selectedAgDesconformidadeResult?.nuts2[0]?.nome ?? '',
        'Nuts3:': selectedAgDesconformidadeResult?.nuts3[0]?.nome ?? '',
        'Distritos:': selectedAgDesconformidadeResult?.distritos[0]?.nome ?? '',
        'Concelhos:': selectedAgDesconformidadeResult?.concelhos[0]?.nome ?? '',
        'Freguesias:': selectedAgDesconformidadeResult?.freguesias[0]?.nome ?? '',


      },
      img_mapa: mapImage
    });

   
    if (documentoBase64) {
      const link = document.createElement('a')
      link.href = 'data:application/octet-stream;base64,' + documentoBase64
      link.download = 'reporte_desconformidade.pdf'
      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      postMessage({ type: 'hideLoader' }, '*')
    }
  } catch (error) {
    postMessage({ type: 'errorMsg', value: { message: "Não foi possivel efetuar esta operação" } }, '*')
  } finally {
    postMessage({ type: 'hideLoader' }, '*')
  }};

  const selectMapAction =  (item: any) => {
    const iframe: any = document.getElementById('iframeGeoPortal')

    if (item.key == "removerArea") {
      setOpenRemoveAreaModal(true)
    } else {
      iframe.contentWindow?.postMessage({ type: item.key, value: getDesconformidadeSelected()?.id, editGpLayer: AG_DESCONFORMIDADE_POINT_NAME }, '*')
    }
  }

const removeArea = async () => {
  const iframe: any = document.getElementById('iframeGeoPortal')
  iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: 'true' }, '*')

  setCoordenadasPoligono(undefined);
  if (!selectedAgDesconformidadeResult?.id) {
    setOpenRemoveAreaModal(false)
    return;
  }
  await removerAreaDesconformidade(selectedAgDesconformidadeResult?.id);
  
  iframe.contentWindow?.postMessage(
    { type: 'SiarlDesconformidadeSearch', value: [selectedAgDesconformidadeResult?.id] },
    '*'
  )
  refreshForm(selectedAgDesconformidadeResult?.id)
  setOpenRemoveAreaModal(false)
}

  const [openModalDescartar, setOpenModalDescartar] = useState<any>()

  const cancelModalDescartar = () => {
    setOpenModalDescartar(false)
  }

  const handleOpenModalDescartar = () => {
    if (
      SessionStorageService.getItem('novaDesconformidade') &&
      SessionStorageService.getItem('novaDesconformidade') == 'true'
    ) {
      setOpenModalDescartar(true);
    } else {
      onEditItemClick("SiarlVisualizar");
      handleCancel();   
   }
  }

  const [estadoFalse, setEstado] = useState(false)
  const [canUpdate, setCanUpdate] = useState(false);
  const [canInsert, setCanInsert] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [deleteClicked, setDeleteClicked] = useState(false);

  const handleDesativar = async (e: any) => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
       {
         type: 'SiarlCoordenadaBoxClose',
         value: 'true',
       },
       '*'
     )
     iframe.contentWindow?.postMessage(
      {
        type: 'SiarlRemoveSelection',
        value: 'true',
      },
      '*'
    )
  setOpenModalDescartar(false)
   const desconformidadeSelected = getDesconformidadeSelected()

    const dataToSend : any = {
      id: desconformidadeSelected?.id ?? undefined,
      status: estadoFalse
    }

      let DesconformidadeSaved: any
    
    if (desconformidadeSelected?.id) {
      postMessage({ type: 'showLoader' }, '*')
      DesconformidadeSaved = await updateAgDesconformidade(dataToSend)
      selectAgDesconformidadeResult({
        ...selectedAgDesconformidadeResult,
        ...DesconformidadeSaved,})
        postMessage({ type: 'hideLoader' }, '*')
      }

      SessionStorageService.removeItem("firstCallAgDesconformidade");
    postMessage({ type: 'form', value: 'pesquisarApoioGestaoPretensao' }, '*')


}

useEffect(() => {
  setCanInsert(
    hasPermission(PERMISSION_INSERIR_DESCONFORMIDADE) ||
    hasPermission(PERMISSION_APOIO_GESTAOPERFIL_INTERMEDIO_EDICAO) ||
    hasPermission(PERMISSION_APOIO_GESTAO_PERFIL_COMPLETO_EDICAO) ||
    hasPermission(PERMISSION_APOIO_GESTAO_PERFIL_TOTAL));
  setCanUpdate(hasPermission(PERMISSION_ATULIZAR_DESCONFORMIDADE) ||
  hasPermission(PERMISSION_APOIO_GESTAOPERFIL_INTERMEDIO_EDICAO) ||
  hasPermission(PERMISSION_APOIO_GESTAO_PERFIL_COMPLETO_EDICAO) ||
  hasPermission(PERMISSION_APOIO_GESTAO_PERFIL_TOTAL));
}, []);

const onEditItemClick= (event:any) => {
  if (event == "SiarlEditar" || event?.item?.key == "SiarlEditar") {
    setEditMode(true);
    setViewMode(false);
    return;
  }
  if (event == "SiarlVisualizar") {
    setEditMode(false);
    setViewMode(true);
  }
  if (event?.item?.key == "SiarlApagar") {
    setDeleteClicked(true);
    setOpenModalDescartar(true);
  }
}

const handleCancel = async()  => {
  refreshForm();
  postMessage({ type: "form", value: "" }, "*");
  setTimeout(() => {
    postMessage({ type: "form", value: "registarApoioGestaoDesconformidade" }, "*");
  }, 10);
}

const refreshForm = async(id: any = undefined) => {
  let idDesconformidade = id;
  if (!idDesconformidade) {
    if (getDesconformidadeSelected().id) {
      idDesconformidade = getDesconformidadeSelected().id;
    } else {
      return;
    }
  }
  const desconformidade = await getDesconformidade(idDesconformidade);
  const tecnico = await GetUserNameTecnico(desconformidade.utilizador_id);

  if (!desconformidade) {
    postMessage({type: "infoMsg", value: { message: "Registo desativado" }}, "*");
    return;
  }
  
  const dataToSend : any ={
    ...desconformidade,
    tecnicoRegisto : tecnico

  }

  const iframe: any = document.getElementById('iframeGeoPortal')

  selectAgDesconformidadeResult(dataToSend)
  selectAgDesconformidadeResultId(desconformidade?.id)
  setDesconformidadeSelected(desconformidade);
}

const importGeo = async (objToSend: any) => {
    postMessage({ type: "showLoader" }, "*");
    if (!objToSend) {
      postMessage({ type: "errorMsg", value: "Não foi possivel salvar" }, "*");
      postMessage({ type: "hideLoader" }, "*");
      return;

    }
    let desconformidade;
    let desconformidade_id;
    const desconformidadeSelected = selectedAgDesconformidadeResult ?? getDesconformidadeSelected();
    if (desconformidadeSelected && desconformidadeSelected?.id) {
      desconformidade = desconformidadeSelected;
      desconformidade_id = desconformidadeSelected?.id;
    } else {
      desconformidade = await saveAgDesconformidade({});
      desconformidade_id = desconformidade?.id;
      selectAgDesconformidadeResult(desconformidade);
      selectAgDesconformidadeResultId(desconformidade);

    }
    try {
      objToSend = { ...objToSend, desconformidade_id }
      const result = await importGeomDesconformidade(objToSend)
      if (!result) {
        postMessage({ type: "errorMsg", value: "Não foi possivel salvar" }, "*");
        return;
      }

      try {
        desconformidade = await getDesconformidadeById(desconformidade_id);
      } catch (error) {
        handleGenericError(error, "Registo salvo mas, Não é possivel mostrar os dados neste momento");

      } finally {
        desconformidade = { ...desconformidade, ...objToSend };
        selectAgDesconformidadeResultId(desconformidade_id);
        selectAgDesconformidadeResult(desconformidade);
        setDesconformidadeSelected(desconformidade)

        refreshForm(desconformidade_id);
      }
      try {
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage(
          { type: 'SiarlDesconformidadeSearch', value: [desconformidade_id] },
          '*'
        )
        
      } catch (error) {
        handleGenericError(error, "Registo salvo mas, Não é possivel mostrar os dados neste momento");
      }

      setOpenImportModal(false)

    } catch (err) {
      handleGenericError(err, "Não foi possivel salvar");
    } finally {
      postMessage({ type: "hideLoader" }, "*");
    }

  }


const updateAgDesconformidade = async (data: any) =>
  await put<any, any>(`${UPDATE_AG_DESCONFORMIDADE}`, data)
  const isSubmit = () => {
      return false
  }
  const userLocal = JSON.parse(
    localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}')



  const handleSubmit = async (e: any, isSubmitAction?: boolean) => {
    if (!e.values.descricao || !e.values.descricao.trim()) {
      postMessage({ type: "errorMsg", value: { message: "Preencha os campos obrigatórios" } }, "*");
      return false;
    }
    const userLocal = JSON.parse(
      localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}')

    const agDesconformidadeRequest = {
      ...e?.values,
      id: selectedAgDesconformidadeResultId,
      utilizador_id: userLocal?.user?.id,
      ag_tema_id: e?.values?.ag_tema?.id,
      ag_estado_id: e?.values?.ag_estado?.id,
      ag_decisao_id: e?.values?.ag_decisao_id?.id,
      anexos_path: "",
      local: e?.values?.local,
      flatCoordinates: coordenadasPoligono,
      geom_imported: selectedAgDesconformidadeResult?.geom_imported ?? undefined,
      ag_estado:undefined, 
      ag_tema:undefined, 
    }
    try {
      let AgDesconformidade: any;
      if (selectedAgDesconformidadeResultId) {
        AgDesconformidade = await updateAgDesconformidade(agDesconformidadeRequest)
      } else {
        AgDesconformidade = await saveAgDesconformidade(agDesconformidadeRequest)
      }
      if (AgDesconformidade) {
        setTimeout(() => {
          postMessage({ type: 'sucessMsg' }, '*')
        }, 500)
      }
      selectAgDesconformidadeResultId(AgDesconformidade?.id as number);


      if(AgDesconformidade) {
        await refreshForm(AgDesconformidade?.id);
        postMessage({ type: 'sucessMsg' }, '*')
      }

    } catch (error) {
      handleGenericError(error);
    }
  }
  const innerStyleTabContent = {
    scrollBehavior: 'smooth',
    top: 55,
    height: '83vh',
  }
  const voltar = () => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
       {
         type: 'SiarlCoordenadaBoxClose',
         value: 'true',
       },
       '*'
     )
      selectAgDesconformidadeResultId(undefined);
      clearDesconformidadeMap();
      postMessage({ type: 'form', value: 'pesquisarApoioGestaoPretensao' }, '*')
  }

  const [clearMap] = useClearMap();
  const clearDesconformidadeMap = () => {
    clearMap(clearLayers["CLEAR_LAYERS_DESCONFORMIDADE"]);
    setDesconformidadeSelected(undefined)
    selectAgDesconformidadeResult(undefined)
  }


 const onItemClick = (event: any) => {
  if(event?.item?.key == "ImportGeom"){
    ImportGeom(event.item)
   } else {
    selectMapAction(event.item)
   }   
  }
  const optionsEditar = [
    {
      key: 'SiarlEditar',
      text: 'Editar',
    },
    {
      key: 'SiarlApagar',
      text: 'Apagar',
    },
    
  ]

  const optionsArea = [
    {
      key: 'SiarlGeoPortalAdicionarArea',
      text: 'Desenhar Área',
    },
    {
      key: 'SiarlEditarGeometria',
      text: 'Editar Área',
    },
    {
      key: 'removerArea',
      text: 'Remover Área',
    },
    {
      key: 'ImportGeom',
      text: 'Importar Geom',
    }, 
  ]

  const onItemClickImprimir = async (event: any) => {

     
    let mapImage = ''

    if (!window.location.href.includes('localhost')) {
      const mapIframe = document.querySelector(
        '#iframeGeoPortal'
      ) as HTMLIFrameElement

      const mapIframeInnerContent =
        mapIframe.contentWindow?.document.querySelector(
          'div.ol-viewport'
        ) as HTMLElement;

      const mapBussula =  mapIframe.contentWindow?.document.querySelector(
        'button.fixedNorthArrowButton'
      ) as HTMLElement;

      mapIframeInnerContent.appendChild(mapBussula);
      
      const generatedImage = await html2canvas(mapIframeInnerContent);
      mapImage = generatedImage.toDataURL()
    }

    console.log(selectedAgDesconformidadeResult);

    try {
      postMessage({ type: 'showLoader' }, '*')

      const documentoBase64 = await relatorioDesconformidade({
        id: selectedAgDesconformidadeResult?.id,
        dados: {
          //REGISTO
          'REGISTO' : '',
          'Nº Processo APA: ': selectedAgDesconformidadeResult?.num_processo_file_doc ?? '',
          'Data do registo:': selectedAgDesconformidadeResult?.data_registo ? new Date(selectedAgDesconformidadeResult?.data_registo).toLocaleDateString() : '',
          'Técnico': selectedAgDesconformidadeResult?.utilizador?.name ?? '',
          'Descrição': selectedAgDesconformidadeResult?.descricao ?? '',

          //IDENTIFICAÇÃO
          'IDENTIFICAÇÃO:' : '',
          'Tema:': selectedAgDesconformidadeResult?.ag_tema?.descricao ?? '',
          'Estado:': selectedAgDesconformidadeResult?.ag_estado?.descricao ?? '',
          'Medida corretiva a aplicar:': selectedAgDesconformidadeResult?.medida_corretiva_a_aplicar ?? '',
          'Decisão:': selectedAgDesconformidadeResult?.ag_decisao?.descricao ?? '',


          //LOCALIZAÇÃO
          'LOCALIZAÇÃO' : '',
          'Nuts1:': selectedAgDesconformidadeResult?.nuts1[0]?.nome ?? '',
          'Nuts2:': selectedAgDesconformidadeResult?.nuts2[0]?.nome ?? '',
          'Nuts3:': selectedAgDesconformidadeResult?.nuts3[0]?.nome ?? '',
          'Distritos:': selectedAgDesconformidadeResult?.distritos[0]?.nome ?? '',
          'Concelhos:': selectedAgDesconformidadeResult?.concelhos[0]?.nome ?? '',
          'Freguesias:': selectedAgDesconformidadeResult?.freguesias[0]?.nome ?? '',


        },
        img_mapa: mapImage
      });

      if (documentoBase64) {
        const blob = b64toBlob(documentoBase64, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
        postMessage({ type: 'hideLoader' }, '*')
      }
    } catch (error) {
      postMessage({ type: 'errorMsg', value: { message: "Não foi possivel efetuar esta operação" } }, '*')
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }

  };
  const itemRender = (props: { item: any; itemIndex: number }) => {
    const isTitleItem = props.item.key === 'titulo';
    if (isTitleItem) {
      return (
        <div className={styles.DropDownButtonTitulo}>
          <span>{props.item.text}</span>
        </div>
      );
    } else {
      return (
        <div className={styles.DropDownButtonItem}>
          <span>{props.item.text}</span>
        </div>
      );
    }
  };

  const optionsImprimir = [
    {
      key: 'imprimirFichas',
      text: 'Fichas',
    },
    {
      key: 'imprimirResultados',
      text: 'Resultados',
    },
  ]

  const optionsExportar = [
    {
      key: 'exportarFichas',
      text: 'Fichas',
    },
    {
      key: 'exportarResultados',
      text: 'Resultados',
    },
  ]
 
  const optionsPonto = [
    {
      key: 'SiarlGeoPortalAdicionarPonto',
      text: 'Adicionar Ponto',
    },
    { 
      key: 'SiarlEditarGeometria',
      text: 'Editar Ponto'
    },
    {
      key: 'removerPonto',
      text: 'Remover Ponto',
    },
  ]
 

  const cancelImport = () => {
    setOpenImportModal(false)
  }
  
  const ImportGeom = async (item: any) => {
    if (item.key == 'ImportGeom') {
      setOpenImportModal(true)
    }
  }

   const onImportGeomClick = (event: any) => {
    ImportGeom(event.item)
  }

  useEffect(() => {
    setViewMode(!editMode && SessionStorageService.getItem("novaDesconformidade") != 'true');
  }, [])

  useEffect(() => {
    if (viewMode) {
      SessionStorageService.removeItem("novaDesconformidade");
    }
  }, [viewMode])

  const onClickOpenFileDoc = async(e: any) => {
    const desconformidadeSelected = getDesconformidadeSelected();
    if (!desconformidadeSelected?.num_processo_file_doc) {
      postMessage({ type: "infoMsg", value: {message: "É necessário preencher o nº processo APA (filedoc)."} }, "*");
      return;
    }
    postMessage({ type: 'showLoader' }, '*');
    const urlFileDoc = await getUrlFileDoc(desconformidadeSelected?.num_processo_file_doc);
    postMessage({ type: 'hideLoader' }, '*')
    window.open(urlFileDoc, '_blank');
    return;
  }

  useLayoutEffect(() => {

    const handleResize = () => {

      if (window.innerWidth <= telaMobile) {
        
        setIsMobile(true);
        ocultaFloatingMenu_e_Mapa();

      }else{

        setIsMobile(false);
        mostraFloatingMenu_e_Mapa();

      }

    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []); 

  const onItemClickMais = (event: any) => {

    elementoClickMais(event);

    onEditItemClick(event);
    onImportGeomClick(event);
    onItemClick(event);
    
    if(optionsExportar.some(option => option.key === event.item.key)){
      onItemClickExportar(event);
    }

  };

  const optionsMais = [
    {
      key: 'titulo',
      text: 'Registo',
    },
    ...optionsEditar.map(option => ({
      ...option,
      className: 'icon-siarl icon-editar btnIconRegistar',
    })),
    {
      key: 'titulo',
      text: 'Opções',
    },
    {
      key: 'openFileDoc',
      text: 'Diretório de documentos',
      className: 'icon-siarl icon-filedoc btnIconRegistar'
    },   
    {
      key: 'titulo',
      text: 'Geometria',
    },
    {
      key: 'titulo',
      text: 'Área',
    },
    ...optionsArea.map(option => ({
      ...option,
      className: 'icon-siarl icon-area btnIconRegistar',
    })),
    {
      key: 'titulo',
      text: 'Exportar',
    },
    ...optionsExportar.map(option => ({
      ...option,
      className: 'icon-siarl icon-exportar btnIconRegistar',
    })),
  ]

  const openAjuda = () => {
    window.open(URL_AJUDA_APOIO_GESTAO_DESCONFORMIDADE, "_blank");
  }

  return (
      <div className={styles.base}>
          <div className={styles.header}>
            <div>
                <div className={styles.nomeDoModulo}>
                  Apoio à Gestão
                </div>
                <div className={styles.tituloDaPagina}>
                {!selectedAgDesconformidadeResult || !selectedAgDesconformidadeResult?.id ? 'Nova Desconformidade' : (editMode ? 'Editar Desconformidade' : 'Desconformidade')}
                </div>
            </div>
            <div>
          {!isMobile && canUpdate && (viewMode || editMode) && (SessionStorageService.getItem("novaDesconformidade") != 'true') ? 
          <DropDownButton
            items={optionsEditar}
            className={styles.btnEditar2}
            iconClass="icon-siarl icon-editar"
            onItemClick={onEditItemClick}
            fillMode={'flat'}
            itemRender={itemRender}
            title="Editar"
          ></DropDownButton>
          : ''}

          <button className={styles.btnEditar2}  title='Filedoc' onClick={onClickOpenFileDoc}
          style={{ display: isMobile ? 'none' : '' }} id='openFileDoc'>
            <span className="icon-siarl icon-filedoc"></span>
          </button>

          {!isMobile &&
          <DropDownButton items={optionsArea} className={styles.btnEditar2}
            iconClass='icon-siarl icon-area'
            onItemClick={onItemClick}
            fillMode={"flat"}
            disabled={viewMode}
            title='Adicionar geometria'
            itemRender={itemRender}
          >
          </DropDownButton>
          }

          {/* <button className={styles.btnEditar2} onClick={onItemClickImprimir}
          style={{ display: isMobile ? 'none' : '' }} id='imprimirFicha' title='Imprimir'>
            <span className="icon-siarl icon-imprimir"></span>
          </button> */}

       
          <button className={styles.btnImprimir} title='Exportar' onClick={onItemClickExportar}
           style={{ display: isMobile ? 'none' : '' }} id='exportarFicha'>
            <span className="icon-siarl icon-exportar"></span>
          </button>

          {isMobile &&
            <DropDownButton
              items={optionsMais}
              className={styles.btnMais}
              iconClass="icon-siarl icon-mais"
              onItemClick={onItemClickMais}
              fillMode={'flat'}
              itemRender={itemRenderMais}
              title="Mais Opções"
            />
          }

          <button onClick={openAjuda} className={styles.btnInfo}>
            <span className="icon-siarl icon-ajuda"></span>
          </button>

          <button className={styles.btnFechar} onClick={voltar}>
            <span className="icon-siarl icon-fechar"></span>
          </button>

        </div>


          </div>

           {openImportModal ?
        <ImportarGeometriaComponent onSubmit={importGeo} cancelImport={cancelImport} /> : <></>
      }

{openRemoveAreaModal ? (
               <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={() => setOpenRemoveAreaModal(false)}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                    <span className="textDescartar">Remover área </span>
                    <br></br>
                    <span>{'Tem a certeza que pretende remover a área selecionada?'}</span>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={() => setOpenRemoveAreaModal(false)}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={removeArea}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}


      {openModalDescartar ? (
          <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={cancelModalDescartar}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                  <fieldset>
                    <span className="textDescartar">{deleteClicked ? 'Apagar' : 'Cancelar'} registo  </span>
                    <br></br>
                    <span>{deleteClicked ? 'Ao apagar este registo deixará de ter acesso, sendo necessária uma intervenção rigorosa para realizar a sua recuperação.' : 'Ao cancelar este registo irá perder os dados inseridos até ao momento.'}</span>
                      <br></br>
                    <span>{deleteClicked ? 'Tem a certeza que pretende apagar?' : 'Tem a certeza que pretende cancelar?'}</span>
                  </fieldset>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={cancelModalDescartar}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={handleDesativar}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}
          
      <Form
        onSubmitClick={handleSubmit}
        initialValues={{
          ...selectedAgDesconformidadeResult,
          data_registo: selectedAgDesconformidadeResult?.data_registo
          ? new Date(selectedAgDesconformidadeResult?.data_registo)
          : new Date(), 
          ag_estado : agEstados ? agEstados[1] : undefined,
          entidade_registo: selectedAgDesconformidadeResult?.id && selectedAgDesconformidadeResult?.entidade ? selectedAgDesconformidadeResult?.entidade?.nome : entidadeUser,

          tecnicoRegisto: userLocal?.user?.name,
           status: selectedAgDesconformidadeResult?selectedAgDesconformidadeResult?.status: true, 
           id: selectedAgDesconformidadeResult?.id ?? getDesconformidadeSelected()?.id
        } ?? undefined}
        render={(formRenderProps) => (
          <FormElement>
            <TabStrip
              selected={selected}
              onSelect={handleSelect} 
              className={`${styles.innerStyleTabContent} ${viewMode? 'tabsVisualizar' : ''}`}
              scrollable={isMobile}
            >
              <TabStripTab title="Desconformidade" key={"Desconformidade"}>
                <DesconformidadeRegistar viewMode={viewMode} formRenderProps={formRenderProps}/>
              </TabStripTab>
              <TabStripTab title="Documentos" key={"Documentos"}>
                <DocumentoListagem viewMode={viewMode} pageName='desconformidade'/>
              </TabStripTab>

              <TabStripTab title="Associação" key={"Associação"}>
                <AssociacaoListagem viewMode={viewMode} pageName='desconformidade'/>
              </TabStripTab>
            </TabStrip>
            {(editMode || SessionStorageService.getItem("novaDesconformidade") == 'true')
                  && !viewMode ?
            <div className={`${styles.footer} ${styles.justify_multiple_children}`}>

                <Button type="button" className={styles.btnSeguinte} onClick={handleOpenModalDescartar} title='Cancelar'>
                  <span className="icon-siarl icon-fechar"></span>
                  <span className={styles.rotulo}>
                    {' '}&nbsp;{' '}
                    Cancelar
                  </span>        
                 </Button>
              <Button
                className={styles.btnSubmeter}
                  onClick={(e) => {
                    formRenderProps.onSubmit(e);
                  }}
                  title='Gravar'
              >
                <span className="icon-siarl icon-submeter btnIconRegistar"></span>
                <span className={styles.rotulo}>
                  {' '}&nbsp;{' '}
                  Gravar
                </span>          
              </Button>
            </div>
            : '' }
            </FormElement>
              )}
              />
      </div>
      
 
  )
}
