//  PROPRIEDADES KENDO / REACT
import { Button } from '@progress/kendo-react-buttons'
import { DateTimePicker } from '@progress/kendo-react-dateinputs'
import { ComboBox } from '@progress/kendo-react-dropdowns'
import { Checkbox, Input, TextArea } from '@progress/kendo-react-inputs'

import {
  Field,
  FormRenderProps
} from '@progress/kendo-react-form'

import {
  useEffect,
  useState
} from 'react'



//  ICONS USADOS


//  FIELDS

//import { useEntidadeStore } from '@stores/entidade'

import styles from '../../../styles/apoiogestao/ApoioGestao.module.scss'

//  IMPORTAR DADOS
import {
  agDecisoes,
  agEstados,
  agTema,
  arhs,
  capitania,
  concelhos,
  coordenadasSelected,
  distritos,
  fillAgEstadosAsync,
  fillAgTemaAsync,
  fillArhsAsync,
  fillCapitaniaAsync,
  fillConcelhosAsync,
  fillDistritosAsync,
  fillFreguesiasAsync,
  fillMassaAguaAsync,
  fillNuts1Async,
  fillNuts2Async,
  fillNuts3Async,
  freguesias,
  get,
  getDesconformidadeSelected,
  handleGenericError,
  hasPermission,
  massaagua,
  nuts1,
  nuts2,
  nuts3,
  post,
  setDesconformidadeSelected,
  useCombos
} from '../../../services/utils'

import { useAgDesconformidadeStore } from '../../../stores/agDesconformidade'
import { GET_AG_DESCONFORMIDADE_BY_ID, GET_AG_ESTADOS, GET_AG_TEMAS, IMPORT_GEOM_DESCONFORMIDADE, PERMISSION_APOIO_GESTAOPERFIL_INTERMEDIO_LEITURA, PERMISSION_APOIO_GESTAO_PERFIL_COMPLETO_EDICAO, PERMISSION_APOIO_GESTAO_PERFIL_COMPLETO_LEITURA, PERMISSION_APOIO_GESTAO_PERFIL_TOTAL, SAVE_NEW_AG_DESCONFORMIDADE } from '../../../utils/constants'
import { requiredFieldValidator } from '../../../validations/agDesconformidade'
import CheckMoreItem from '../../documentos/registar/fields/CheckMoreItem'
import ValidatedIdentificacaoInput from '../fields/desconformidade/ValidatedIdentificacaoInput'
import ImportarGeometriaComponent from './ImportarGeometriaForm'
import { filterBy } from '@progress/kendo-data-query'
import SessionStorageService from '../../../services/SessionStorageService'
import ValidatedTextArea from '../../forms/ValidatedTextArea'

type Props = {
  formRenderProps: FormRenderProps,
  viewMode: boolean
}

export default function DesconformidadeRegistar({ formRenderProps, viewMode }: Props) {
  const [concelhosFiltred, setConcelhosFiltred] = useState<any>()
  const [concelhosDisabled, setConcelhosDisabled] = useState<any>(true)
  const [freguesiasDisabled, setFreguesiasDisabled] = useState<any>(true)
  const [nuts2Filtered, setNuts2Filtered] = useState<any>()
  const [nuts3Filtered, setNuts3Filtered] = useState<any>()
  const [nuts2Disabled, setNuts2Disabled] = useState<any>(true)
  const [nuts3Disabled, setNuts3Disabled] = useState<any>(true)
  const [freguesiasFiltred, setFreguesiasFiltred] = useState<any>()
  const [openImportModal, setOpenImportModal] = useState<any>()

  const getAgEstados = async (data: any) =>
    await get<any, any>(`${GET_AG_ESTADOS}`)

  const getAgTemas = async () =>
    await get<any, any>(`${GET_AG_TEMAS}`)

  const selectedAgDesconformidadeResult: any = useAgDesconformidadeStore(
    (state) => state.selectedAgDesconformidadeResult
  )

  const selectedAgDesconformidadeResultId: any = useAgDesconformidadeStore(
    (state) => state.selectedAgDesconformidadeResultId
  )
  const selectAgDesconformidadeResultId = useAgDesconformidadeStore(
    (state) => state.selectAgDesconformidadeResultId
  )
  const selectAgDesconformidadeResult = useAgDesconformidadeStore(
    (state) => state.selectAgDesconformidadeResult
  )

    useEffect(() => {
    if(!nuts1 ){
      fillNuts1Async();
      }
     if(!nuts2) {
      fillNuts2Async();
     }
     if(!nuts3){
      fillNuts3Async();
     }
     if(!distritos){
      fillDistritosAsync();
     }
     if(!concelhos){
      fillConcelhosAsync();
     }
     if(!freguesias){
      fillFreguesiasAsync();
     }
     if(!arhs){
      fillArhsAsync();
     }
     if(!capitania){
      fillCapitaniaAsync();
     }
     if(!massaagua){
      fillMassaAguaAsync();
     }
     if(!agTema){
      fillAgTemaAsync();
     }
     if(!agEstados){
      fillAgEstadosAsync();
     }
       }, []);

  const filterConcelho = (e: any) => {
    if (e?.value?.codigo) {
      const concelhosLocal = concelhos.filter(
        (x: any) => x.distrito_cod == e.value.codigo
      )
      setConcelhosFiltred(concelhosLocal)
      setConcelhosDisabled(false)
    } else {
      setConcelhosFiltred(concelhos)
      setConcelhosDisabled(true)
    }
  }

  const filterFreguesia = (e: any) => {
    if (e?.value?.codigo) {
      const freguesiaLocal = freguesias.filter(
        (x: any) => x.concelho_cod == e.value.codigo
      )
      setFreguesiasFiltred(freguesiaLocal)
      setFreguesiasDisabled(false)
    } else {
      setFreguesiasDisabled(true)
      setFreguesiasFiltred(freguesias)
    }
  }
  const filterNuts2 = (e: any) => {
    if (e?.value?.codigo) {
      const nutsLocal = nuts2.filter((x: any) => x.nuts_i_cod == e.value.codigo)
      setNuts2Filtered(nutsLocal)
      setNuts2Disabled(false)
    } else {
      setNuts2Disabled(true)
      setNuts2Filtered(nuts2)
    }
  }
  const filterNuts3 = (e: any) => {
    if (e?.value?.codigo) {
      const nutsLocal = nuts3.filter(
        (x: any) => x.nuts_ii_cod == e.value.codigo
      )
      setNuts3Filtered(nutsLocal)
      setNuts3Disabled(false)
    } else {
      setNuts3Disabled(true)
      setNuts3Filtered(nuts3)
    }
  }

  type combosKeys= 'nuts1' | 'nuts2' | 'nuts3' | 
  'distritos'| 'concelhos'|'freguesias' |
   'capitania' | 'massaagua' | 'arhs'
   |'agTema' | 'agEstados' | 'agDecisoes' ;
   const [combos, setCombos] = useState<any>({
     nuts1,
     nuts2,
     nuts3,
     distritos,
     concelhos,
     freguesias,
     massaagua, 
     capitania,
     arhs,
     agTema,
     agEstados,
     agDecisoes
   })
   const setDataOption: Record<combosKeys, { setFn: any, data: any[] }> = {
     agEstados: { setFn: setCombos, data: agEstados },
     agTema: { setFn: setCombos, data: agTema },
     nuts1: { setFn: setCombos, data: nuts1 },
     nuts2: { setFn: setCombos, data: nuts2Filtered },
     nuts3: { setFn: setCombos, data: nuts3Filtered },
     distritos: { setFn: setCombos, data: distritos },
     concelhos: { setFn: setCombos, data: concelhosFiltred },
     arhs: { setFn: setCombos, data: arhs },
     capitania: { setFn: setCombos, data: capitania },
     freguesias: { setFn: setCombos, data: freguesiasFiltred },
     massaagua: { setFn: setCombos, data: massaagua },
     agDecisoes: {setFn: setCombos, data: agDecisoes}
   };
   const filterCombo_Multiselect = (e: any, tipoCombo: combosKeys) => {
    const { setFn, data } = setDataOption[tipoCombo];
      setFn({
          ...combos,
          [tipoCombo]: filterBy(data?.slice()??[], e.filter)
      }
      );
}


  const saveAgDesconformidade = async (data: any) =>
    await post<any, any>(`${SAVE_NEW_AG_DESCONFORMIDADE}`, data)

  const importGeomDesconformidade = async (data: any) =>
    await post<any, any>(`${IMPORT_GEOM_DESCONFORMIDADE}`, data)

  const getDesconformidade = async (id: any) =>
    await get<any, any>(`${GET_AG_DESCONFORMIDADE_BY_ID}/` + id)
  const cancelImport = () => {
    setOpenImportModal(false)
  }

  const importGeo = async (objToSend: any) => {
    if (!objToSend) {
      postMessage({ type: "errorMsg", value: "Não foi possivel salvar" }, "*");
      return;
    }

    let desconformidade_id;
    let desconformidade;
    const desconformidadeSelected = selectedAgDesconformidadeResult ?? getDesconformidadeSelected();
    if (desconformidadeSelected && desconformidadeSelected?.id) {
      desconformidade_id = desconformidadeSelected?.id;
      desconformidade = desconformidadeSelected;
    } else {
      desconformidade = await saveAgDesconformidade(desconformidade)
      desconformidade_id = desconformidade?.id;
      setDesconformidadeSelected(desconformidade);
      selectAgDesconformidadeResult(desconformidade)
      selectAgDesconformidadeResultId(desconformidade_id)

    }
    objToSend = { ...objToSend, desconformidade_id }
    try {
      postMessage({ type: "showLoader" }, "*");
      const result = await importGeomDesconformidade(objToSend)
      if (!result) {
        postMessage({ type: "errorMsg", value: "Não foi possivel salvar" }, "*");
        return;
      }
      try {
        desconformidade = await getDesconformidade(desconformidade_id);
      } catch (e) {
        handleGenericError(e);
      } finally {
        setDesconformidadeSelected({ ...desconformidade, ...objToSend });
        selectAgDesconformidadeResult({ ...desconformidade, ...objToSend })
        selectAgDesconformidadeResultId(desconformidade_id)
      }
      try {
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage(
          { type: 'SiarlDesconformidadeSearch', value: [desconformidade_id] },
          '*'
        )
      } catch (error) {
        handleGenericError(error);
      }
      setOpenImportModal(false)
    } catch (error) {
      handleGenericError(error);
    } finally {
      postMessage({ type: "hideLoader" }, "*");
    }
  }
  const changeFileDoc = (e: any) => {
    setDesconformidadeSelected({...getDesconformidadeSelected(), num_processo_file_doc: e?.value});
  }

  return (
    <>
      <div className={`${styles.form} ${styles.removeScrollDuplicado}`}>

       <span style={{ marginLeft: '08px' }}>Os campos com simbolo * (asterisco) são de preenchimento obrigatório</span>

        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Registo</span>
          </legend>
          <div className={styles.espacoVerticalFieldSet}>
            <div className={styles.fields}>
              <div>
                <Field
                  name="id"
                  label="ID"
                  component={Input}
                  disabled
                />
              </div>
              {hasPermission(PERMISSION_APOIO_GESTAO_PERFIL_TOTAL) || hasPermission(PERMISSION_APOIO_GESTAO_PERFIL_COMPLETO_EDICAO) || hasPermission(PERMISSION_APOIO_GESTAO_PERFIL_COMPLETO_LEITURA) ?

              <div>
              <Field
                name="num_processo_file_doc"
                label="Nº Processo APA"
                component={Input}
                maxLength={500}
                onChange={changeFileDoc}
                disabled={viewMode}
              />
              </div>
: ''}
              {
                hasPermission(PERMISSION_APOIO_GESTAO_PERFIL_TOTAL) ||
                hasPermission(PERMISSION_APOIO_GESTAO_PERFIL_COMPLETO_EDICAO) ||
                hasPermission(PERMISSION_APOIO_GESTAO_PERFIL_COMPLETO_LEITURA) ||
                hasPermission(PERMISSION_APOIO_GESTAOPERFIL_INTERMEDIO_LEITURA)
                ?
              <div>
                <Field
                  name="ag_estado"
                  label="Estado"
                  textField={'descricao'}
                  component={ComboBox}
                  filterable={true}
                  disabled={viewMode}
                  onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'agEstados')}}
                  data={combos?.agEstados}
                />
              </div> : ''}
              
             
            </div>
            <div className={styles.fields} >
            <div>
                <Field
                  name="data_registo"
                  label="Data/hora registo"
                  format="dd/MM/yyyy HH:mm"
                  component={DateTimePicker}
                  disabled={true}
                />
              </div>
              <div>
                <Field
                  name="tecnicoRegisto"
                  label="Técnico de registo"
                  component={Input}
                  disabled={true}
                />
              </div>
              <div>
                <Field
                  name="entidade_registo"
                  label="Entidade"
                  disabled
                  component={Input}
                />
              </div>
            </div>
          </div>
        </fieldset>

           <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Identificação</span>
          </legend>
          <div className={styles.espacoVerticalFieldSet}>
          <div className={styles.fields}>

              <div>
                <Field
                  name="ag_tema"
                  label="Tema"
                  textField={'descricao'}
                  component={ComboBox}
                  filterable={true}
                  onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'agTema')}}
                  data={combos?.agTema}
                  disabled={viewMode}
                />
              </div>
              </div>
              <div className={styles.fields}>
              <div>
                <Field
                  name="medida_corretiva_a_aplicar"
                  label="Medida corretiva a aplicar"
                  component={Input}
                  disabled={viewMode}
                />
              </div>
            </div>
            <div className={styles.fields}>

            {(
            (hasPermission(PERMISSION_APOIO_GESTAO_PERFIL_TOTAL) ||
            hasPermission(PERMISSION_APOIO_GESTAO_PERFIL_COMPLETO_EDICAO) ||
            hasPermission(PERMISSION_APOIO_GESTAO_PERFIL_COMPLETO_LEITURA) ||
            hasPermission(PERMISSION_APOIO_GESTAOPERFIL_INTERMEDIO_LEITURA)) &&
            (SessionStorageService.getItem("NovoRegistoDesconformidade") == 'false')
          ) ? (
                
              <div>
                <Field
                  name="medida_corretiva_aplicada"
                  label="Medida corretiva aplicada"
                  component={Input}
                  disabled={viewMode}
                />
              </div> ) : " "}
              </div>
            <div className={styles.fields}>
              <Field
                name="ag_decisao_id"
                label="Decisão"
                component={ComboBox}
                textField={'descricao'}
                disabled={viewMode}
                data={agDecisoes}
              />
            </div>
          

            <div className={styles.fields} style={{marginTop: 15}}>
              <Field
                name="descricao"
                label="Descrição da desconformidade *"
                placeholder="Descrição da desconformidade *"
                rows={2}
                size={"medium"}
                fillMode={"outline"}
                autoSize={true}
                component={ValidatedTextArea}
                maxLength={500}
                validator={requiredFieldValidator}
                disabled={viewMode}
              />
            </div>
          </div>
        </fieldset>

       
        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Localização</span>
          </legend>

          <div className={styles.espacoVerticalFieldSet}>
            <div className={styles.fieldsetSubTitulo}>
              {coordenadasSelected ?? ""}
            </div>
            <div className={styles.fields}>
              <div>
                {getDesconformidadeSelected() ?
                  <CheckMoreItem
                    list={getDesconformidadeSelected()?.nuts1}
                    property={'nome'}
                    label="NUTS I"
                  /> :
                  <Field
                    name="nuts1"
                    label="NUTS I"
                    component={ComboBox}
                    textField={'nome'}
                    onChange={filterNuts2}
                    filterable={true}
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'nuts1')}}
                    data={combos?.nuts1}
                    disabled

                  />
                }
              </div>
              <div>
                {getDesconformidadeSelected() ?
                  <CheckMoreItem
                    list={getDesconformidadeSelected()?.nuts2}
                    property={'nome'}
                    label="NUTS II"
                  /> :
                  <Field
                    name="nuts2"
                    label="NUTS II"
                    component={ComboBox}
                    textField={'nome'}
                    onChange={filterNuts3}
                    disabled
                    filterable={true}
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'nuts2')}}
                    data={combos?.nuts2}

                  />}
              </div>
              <div>
                {getDesconformidadeSelected() ?
                  <CheckMoreItem
                    list={getDesconformidadeSelected()?.nuts3}
                    property={'nome'}
                    label="NUTS III"
                  /> :
                  <Field
                    name="nuts3"
                    label="NUTS III"
                    component={ComboBox}
                    textField={'nome'}
                    disabled
                    filterable={true}
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'nuts3')}}
                    data={combos?.nuts3}

                  />}
              </div>
            </div>
            <div className={styles.fields}>
              <div>
                {getDesconformidadeSelected() ?
                  <CheckMoreItem
                    list={getDesconformidadeSelected()?.distritos}
                    property={'nome'}
                    label="Distrito"
                  /> :
                  <Field
                    name="distrito"
                    label="Distrito"
                    component={ComboBox}
                    onChange={filterConcelho}
                    textField={'nome'}
                    disabled
                    filterable={true}
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'distritos')}}
                    data={combos?.distritos}

                  />}
              </div>
              <div>
                {getDesconformidadeSelected() ?
                  <CheckMoreItem
                    list={getDesconformidadeSelected()?.concelhos}
                    property={'nome'}
                    label="Concelho"
                  /> :
                  <Field
                    name="concelho"
                    label="Concelho"
                    component={ComboBox}
                    onChange={filterFreguesia}
                    disabled
                    textField={'nome'}
                    filterable={true}
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'concelhos')}}
                    data={combos?.concelhos}
                  />}
              </div>
              <div>
                {getDesconformidadeSelected() ?
                  <CheckMoreItem
                    list={getDesconformidadeSelected()?.freguesias}
                    property={'nome'}
                    label="Freguesia"
                  /> :
                  <Field
                    name="freguesia"
                    label="Freguesia"
                    component={ComboBox}
                    disabled
                    textField={'nome'}
                    filterable={true}
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'freguesias')}}
                    data={combos?.freguesias}
                  />}
              </div>
            </div>
            <div className={styles.fields}>
              <div>
                {getDesconformidadeSelected() ?
                  <CheckMoreItem
                    list={getDesconformidadeSelected()?.arhs}
                    property={'nome'}
                    label="ARH"
                  /> :
                  <Field
                    name="arh"
                    label="ARH"
                    component={ComboBox}
                    textField={'nome'}
                    filterable={true}
                    disabled
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'arhs')}}
                    data={combos?.arhs}
                  />}
              </div>
              <div>
                {getDesconformidadeSelected() ?
                  <CheckMoreItem
                    list={getDesconformidadeSelected()?.capitanias}
                    property={'nome'}
                    label="Capitania"
                  /> :
                  <Field
                    name="capitania"
                    label="Capitania"
                    component={ComboBox}
                    textField={'nome'}
                    filterable={true}
                    disabled
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'capitania')}}
                    data={combos?.capitania}
                  />}
              </div>
              <div>
                {getDesconformidadeSelected() ?
                  <CheckMoreItem
                    list={getDesconformidadeSelected()?.massasAgua}
                    property={'nome'}
                    label="Massa de água"
                  /> :
                  <Field
                    name="massaagua"
                    label="Massa de água"
                    component={ComboBox}
                    textField={'nome'}
                    filterable={true}
                    disabled
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'massaagua')}}
                    data={combos?.massaagua}
                  />}
              </div>
            </div>
          </div>
        </fieldset>
     

      </div>

      {openImportModal ?
        <ImportarGeometriaComponent onSubmit={importGeo} cancelImport={cancelImport} /> : <></>
      }
    </>
  )
}
