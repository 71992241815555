import {
  MouseEvent as ReactMouseEvent,
  useEffect,
  useLayoutEffect,
  useState
} from 'react'

import {
  Grid,
  GridCellProps,
  GridColumn,
  GridPageChangeEvent,
} from '@progress/kendo-react-grid'

import { Button, DropDownButton } from '@progress/kendo-react-buttons'
import { PagerTargetEvent } from '@progress/kendo-react-data-tools'
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from '@progress/kendo-react-layout'
import { format } from 'date-fns'
import { CSVLink } from 'react-csv'
import SessionStorageService from '../../services/SessionStorageService'
import styles from '../../styles/usodosolo/UsoDoSolo.module.scss'
import commonStyles from '../../styles/common/Common.module.scss'
import { ResultadoValorUsoDoSoloCommandGridCell } from './ResultadoValorUsoDoSoloCommandGridCell'

import { Dialog } from '@progress/kendo-react-dialogs'
import { post, setEaRegistoAnalise } from '../../services/utils'
import { useValorUsoDoSoloStore } from '../../stores/valorUsoDoSolo'
import { GET_RELATORIO_DE_USO_DE_SOLO, URL_AJUDA_USOS_SOLO_VALOR, USO_DO_SOLO_MODULE_NAME, clearLayers } from '../../utils/constants'
import DetalheValorUsoDoSolo from './DetalheValorUsoDoSolo'
import clsx from 'clsx'
import { useCommonStore } from '../../stores/common'
import MaximizeButton from '../common/MaximizeButton'
import useClearMap from '../../hooks/useClearMap'
import VisualizadorColunas from '../Generico/VisualizadorColunas '
import useVisibleColumns, { Key } from '../../hooks/useVIsibleColumns'
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais'
import { process } from '@progress/kendo-data-query';
import ReactGA from 'react-ga4';
import { ExcelExport } from '@progress/kendo-react-excel-export'

type IProps = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
}

type PageState = {
  skip: number
  take: number
}

const initialDataState: PageState = { skip: 0, take: 20 }

type ValorTituloUsoDoSolo = {
  id: number;
  vus: string;
  tipo: string;
  valor: string;
}
const keyPainelPrincipal:Key = 'valorUsoSoloColunasVisiveis';
const keyPainelDocumentos:Key = 'valorUsoSoloDocumentosColunasVisiveis';
export default function ResultadoValorUsoDoSolo({ onCloseClick }: IProps) {
  const [isMobile, setIsMobile] = useState(false);
  const [selected, setSelected] = useState<number>(0)
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false)
  const [valoresUsoDoSolo, setValoresUsoDoSolo] = useState<ValorTituloUsoDoSolo[]>([])
  const [page, setPage] = useState<PageState>(initialDataState)
  const [pageSizeValue, setPageSizeValue] = useState<
    number | string | undefined
  >()
  const [Analise_Resultado, setAnalise_Resultado] = useState<any>()

  const isCurrentViewMaximized = useCommonStore(state => state.isCurrentViewMaximized)

  const getRelatorioDeValorUsoDoSolo = async (data: any) => await post<any, any>(GET_RELATORIO_DE_USO_DE_SOLO, data)

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected)
  }
  const selectValorUsoDoSolo = useValorUsoDoSoloStore((state) => state.selectValorUsoDoSoloResult)

  const handlePageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent
    const take = event.page.take

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value)
    }

    setPage({
      ...event.page,
      take,
    })
  }

  const [clearMap] = useClearMap();
  const {getVisibleColumns, removeColumnsKeys} = useVisibleColumns();

  const clearSearchResults = () => {
    SessionStorageService.removeItem('resultadoValorUsoDoSolo')
    setEaRegistoAnalise(undefined);
    if (sessionStorage.getItem("Modulo") == USO_DO_SOLO_MODULE_NAME) {
      clearMap(clearLayers["CLEAR_LAYERS_US_TITULO"])
    }
    postMessage({ type: 'resultadoUsoDoSoloPesquisaChange' }, '*')
    removeColumnsKeys(keyPainelDocumentos)
    removeColumnsKeys(keyPainelPrincipal)

  }

  useEffect(() => {
    let valoresUsoDoSoloStored = SessionStorageService.getItem('resultadoValorUsoDoSoloPesquisa');
    if (valoresUsoDoSoloStored) {
      valoresUsoDoSoloStored = JSON.parse(valoresUsoDoSoloStored);
      setValoresUsoDoSolo(valoresUsoDoSoloStored.resultadosPesquisa);
      setAnalise_Resultado(valoresUsoDoSoloStored);
    }
    getVisibleColumns(keyPainelPrincipal, setColums)
    getVisibleColumns(keyPainelDocumentos, setColumsDocuments)
  
  
  }, [])



  const handleInfoClickById = (valorUsoDoSolo: Record<string, any>) => async () => {
    selectValorUsoDoSolo(valorUsoDoSolo)
    closeModal();
  }

  const handleLocationClick = (usVus: Record<string, unknown>) => async () => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    if (usVus.id) {
      iframe.contentWindow?.postMessage(
        {
          type: 'SiarlUsVusPoligonoCenter',
          value: [usVus.id],
        },
        '*'
      )
    }
  }

  const CommandCell = (props: GridCellProps) => (
    <ResultadoValorUsoDoSoloCommandGridCell
      {...props}
      onDetailsClick={handleInfoClickById}
      onMapCenter={handleLocationClick}
    />
  )
  const closeModal = () => {
    setShowDetailModal(!showDetailModal)
  }


  const itemRender = (props: { item: any; itemIndex: number }) => {
    const isTitleItem = props.item.key === 'titulo';
    if (isTitleItem) {
      // Item de título personalizado
      return (
        <div className={styles.DropDownButtonTitulo}>
          <span>{props.item.text}</span>
        </div>
      );
    } else {
      // Demais itens
      return (
        <div className={styles.DropDownButtonItem}>
          <span>{props.item.text}</span>
        </div>
      );
    }
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/ResultadoValorUsosDoSolo" });
  }, []);

  const optionsExportar = [
    {
      key: 'ExportarListaResultadosExcel',
      text: 'Excel',
    },
  ]

  let _export: any;
  const exportExcel = () => {
    _export.save();
  };

  const onItemClickExportar = async (event: any) => {
    if (event.item.key == 'ExportarListaResultadosExcel') {
      exportExcel();
    }
  }

  const onItemClickDownloadPdf = async (event: any) => {
    postMessage({ type: 'showLoader' }, '*')
    let valoresUsoDoSoloStored = SessionStorageService.getItem('resultadoValorUsoDoSoloPesquisa');

    if (valoresUsoDoSoloStored) {
      valoresUsoDoSoloStored = JSON.parse(valoresUsoDoSoloStored);

      const documentoBase64 = await getRelatorioDeValorUsoDoSolo(valoresUsoDoSoloStored);

      if (documentoBase64) {
        const link = document.createElement('a')
        link.href = 'data:application/octet-stream;base64,' + documentoBase64
        link.download = 'ValorUsoDoSolo.pdf'
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        postMessage({ type: 'hideLoader' }, '*')
      }
    }
  }

  const faixasSalvaGuarda = [
    { id: 1, descricao: "Faixa Nível 1 2050" },
    { id: 2, descricao: "Faixa Nível 2 2100" },
    { id: 0, descricao: "Nenhuma" }
  ]

  const { ano, faixaSalvaguarda } = Analise_Resultado?.pesquisa ?? {};

  const ACell = (props: any) => {

    return (
      <td colSpan={props.colSpan} style={props.style}>
        Taxa atualizacão: {''}
      </td>
    );

  };

  const BCell = (props: any) => {

    return (
      <td colSpan={props.colSpan} style={props.style}>
        Ano de análise: {ano}
      </td>
    );

  };

  const CCell = (props: any) => {

    return (
      <td colSpan={props.colSpan} style={props.style}>
        Faixa 1: {faixaSalvaguarda}
      </td>
    );

  };

  const DCell = (props: any) => {

    return (
      <td colSpan={props.colSpan} style={props.style}>
        Faixa 2: {faixaSalvaguarda}
      </td>
    );

  };

  const getFormatedEurNumber=()=>{
    let valoresUsoDoSoloStored = SessionStorageService.getItem('resultadoValorUsoDoSoloPesquisa');
    if (valoresUsoDoSoloStored) {
      valoresUsoDoSoloStored = JSON.parse(valoresUsoDoSoloStored);
      console.log({valoresUsoDoSoloStored})
      const v =valoresUsoDoSoloStored?.valorTotal??0
      return new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(v);
    }

} 
  const ECell = (props: any) => {

    return (
      <td colSpan={props.colSpan} style={props.style}>
        Total: <span style={{ textShadow: '0 0 black' }}>{getFormatedEurNumber()}</span>
      </td>
    );

  };


  const [columsDocuments, setColumsDocuments] = useState<{ columns: { field: string, title: string, width: number | string, show: boolean }[] }>({
    columns: [
      { field: "id", width: 70, title: "ID", show: true },
      { field: "filedoc_cod", width: 190, title: "ID Documento Filedoc", show: true },
      { field: "data_publicacao", width: 150, title: "Data publicação", show: true },
      { field: "tipoRecurso.designacao", width: 150, title: "Tipo de recurso", show: true },
      { field: "titulo", width: 90, title: "Título", show: true },
      { field: "autores", width: 90, title: "Autor", show: true },
      { field: "entidade.nome", width: 90, title: "Entidade", show: true },
      { field: "id_estado", width: 'auto', title: "Estado", show: true },
    ]
  })

  const [colums, setColums]=useState<{columns:{field:string, title:string, width:number|string, show:boolean, footerCell:any}[]}>({
    columns:[
  { field: "id", title: "ID", show: true, width: 170, footerCell: BCell },
  { field: "usVus.descricao", title: "Vus", show: true, width: 190, footerCell: CCell },
  { field: "usVus.usTipoVus.descricao", title: "Tipo", show: true, width: 190, footerCell: DCell },
  { field: "valor", title: "valor", show: true, width: "auto", footerCell: ECell },  
    ]
  })

  useEffect(() => {
    SessionStorageService.setItem(keyPainelDocumentos, JSON.stringify(columsDocuments));
  }, [columsDocuments]);
  
  useEffect(() => {
    SessionStorageService.setItem(keyPainelPrincipal, JSON.stringify(colums));
  }, [colums]);

  const combinedData = valoresUsoDoSolo?.slice(page.skip, page.take + page.skip).map((vus: any) => (
    {
      ...vus,
      valor: new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(vus.valor)
    }
  ));


  useLayoutEffect(() => {

    const handleResize = () => {

      if (window.innerWidth <= telaMobile) {
        
        setIsMobile(true);
        ocultaFloatingMenu_e_Mapa();

      }else{

        setIsMobile(false);
        mostraFloatingMenu_e_Mapa();

      }

    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);  


  const onItemClickMais = (event: any) => {

    elementoClickMais(event);

    onItemClickExportar(event);

    onItemClickDownloadPdf(event);

  };

  const optionsMais = [
    {
      key: 'titulo',
      text: 'Imprimir',
    },
    {
      key: 'imprimirFicha',
      text: 'Resultados',
      className: 'icon-siarl icon-imprimir btnIconRegistar'
    },
    {
      key: 'titulo',
      text: 'Exportar',
    },
    ...optionsExportar.map(option => ({
      ...option,
      className: 'icon-siarl icon-exportar btnIconRegistar',
    })),
  ]
  const [dataState, setDataState] = useState({
    skip: 0,
    take: 20,
    sort: [],
    group: [],
  });
  const dataStateChange = (event: any) => {
    setDataResult(process(valoresUsoDoSolo, event.dataState));
    setDataState(event.dataState);
  };
  const [dataResult, setDataResult] = useState(
    process(valoresUsoDoSolo, dataState)
  );

  const openAjuda = () => {
    window.open(URL_AJUDA_USOS_SOLO_VALOR, "_blank")
  }

  return (
    <>
      <div className={clsx(styles.base, isCurrentViewMaximized && commonStyles.maximizedBaseForm)}>
        <div className={styles.header}>
          <div>
            <div className={styles.nomeDoModulo}>Uso de solo</div>
            <div className={styles.tituloDaPagina}>Resultados de pesquisa</div>
          </div>

          <div>

          {!isMobile &&
          <VisualizadorColunas 
              columns={selected===0?colums:columsDocuments} 
              setColumns={selected===0?setColums:setColumsDocuments}
              styles={styles.btnEditar2} 
           />
          }
            <CSVLink
              title="Imprimir resultados"
              id="print-results"
              data={[]}
              filename={`siarl-valorUsoDoSolo-${format(
                new Date(),
                'ddMMyyyHHmm'
              )}`}
              hidden
            ></CSVLink>

            <button className={styles.btnEditar2} onClick={onItemClickDownloadPdf} title='Imprimir'>
              <span className="icon-siarl icon-imprimir"></span>
            </button>

            {!isMobile &&
            <DropDownButton
              items={optionsExportar}
              className={styles.btnImprimir}
              style={{marginTop: '-4px'}}
              iconClass="icon-siarl icon-exportar"
              onItemClick={onItemClickExportar}
              fillMode={'flat'}
              itemRender={itemRender}
              title='Exportar'
            ></DropDownButton>
            }

            {isMobile &&
              <DropDownButton
                items={optionsMais}
                className={styles.btnMais}
                iconClass="icon-siarl icon-mais"
                onItemClick={onItemClickMais}
                fillMode={'flat'}
                itemRender={itemRenderMais}
                title="Mais Opções"
              />
            }

            <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
              <span className="icon-siarl icon-ajuda"></span>
            </button>
            <button onClick={onCloseClick} className={styles.btnFechar} title="Minimizar">
              <span className="icon-siarl icon-minimizar"></span>
            </button>

            {!isMobile &&
            <MaximizeButton className={styles.btnMaximizar} />
            }
          </div>
        </div>

        <TabStrip selected={selected} onSelect={handleSelect} scrollable={isMobile}>
          <TabStripTab title="Valores de uso do solo" key={'titulos'}>
          <ExcelExport
            fileName='Resultado_ValoresUsoSolo'
            data={valoresUsoDoSolo.slice(page.skip, page.take + page.skip)}


            ref={(exporter: any) => {
              _export = exporter;
            }}
          >
            <Grid
             sortable={true}
             onDataStateChange={dataStateChange}
              data={dataResult.data.length > 0 ? dataResult : valoresUsoDoSolo.slice(page.skip, page.take + page.skip)}
             {...dataState}
            pageable={{
              buttonCount: 5,
              info: true,
              type: 'numeric',
              pageSizes: true,
              previousNext: true,
            }}
            total={valoresUsoDoSolo.length}
            //groupable={true}
              className={styles.form}
              // data={combinedData}
              // pageable={{
              //   buttonCount: 5,
              //   info: true,
              //   type: 'numeric',
              //   pageSizes: true,
              //   previousNext: true,
              // }}
              // total={valoresUsoDoSolo?.length}
            >
              <GridColumn cell={CommandCell} width={156} footerCell={ACell} />
                {colums?.columns?.map((column, i) => {
              return (
                column.show && (
                  <GridColumn key={i}  field={column.field} width={column.width} title={column.title} footerCell={column.footerCell} />
                )
              );
            })}
              {/* <GridColumn field="id" width={170} title="ID" footerCell={BCell} />
              <GridColumn field="usVus.descricao" width={190} title="Vus" footerCell={CCell} />
              <GridColumn field="usVus.usTipoVus.descricao" width={190} title="Tipo" footerCell={DCell} />
              <GridColumn field="valor" width={'auto'} title="valor" footerCell={ECell} /> */}
            </Grid>
          </ExcelExport>
          </TabStripTab>
        </TabStrip>

        <div
          className={clsx(styles.footer, styles.justify_one_child, isCurrentViewMaximized && commonStyles.maximizedFooter)}
        >
          <Button className={styles.btnPesquisar} onClick={clearSearchResults} title='Nova Análise'>
            <span className="icon-siarl icon-pesquisar btnIconPesquisar"></span>
            <span className={styles.rotulo}>
              {' '}&nbsp;{' '}
              Nova Análise
            </span>
          </Button>
        </div>
      </div >
      {
        showDetailModal ?
          <Dialog className="ModalDocumentos" onClose={closeModal} title={"Detalhes"} style={{ zIndex: 999 }}>
            < DetalheValorUsoDoSolo />
          </Dialog >
          : <></>
      }
    </>
);
}