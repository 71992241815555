import { Button } from '@progress/kendo-react-buttons'
import { DateTimePicker } from '@progress/kendo-react-dateinputs'
import { ComboBox, ComboBoxFilterChangeEvent } from '@progress/kendo-react-dropdowns'
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from '@progress/kendo-react-form'
import { Input, RadioGroup } from '@progress/kendo-react-inputs'
import { MouseEvent as ReactMouseEvent, useEffect, useState } from 'react'

import SessionStorageService from '../../services/SessionStorageService'
import {
  arhs,
  capitania,
  concelhos,
  coordenadasPoligono,
  coordenadasSelected,
  distritos,
  entidades,
  estadoIntervencao,
  fillArhsAsync,
  fillCapitaniaAsync,
  fillCombosAuxiliares,
  fillConcelhosAsync,
  fillDistritosAsync,
  fillEntidadesAsync,
  fillFreguesiasAsync,
  fillInstrumentoFinanciamentoAsync,
  fillInstrumentoPlaneamentoAsync,
  fillMassaAguaAsync,
  fillNuts1Async,
  fillNuts2Async,
  fillNuts3Async,
  fillPlaneamento1Async,
  fillPlaneamento2Async,
  fillPlaneamento3Async,
  fillPrioridadeAsync,
  fillTip1Async,
  fillTip2Async,
  fillTip3Async,
  freguesias,
  hasPermission,
  instrumentoFinanciamento,
  instrumentoPlaneamento,
  massaagua,
  nuts1,
  nuts2,
  nuts3,
  planeamento1,
  planeamento2,
  planeamento3,
  post,
  prioridade,
  setCoordenadasPoligono,
  tecnicos,
  tip1,
  tip2,
  tip3,
} from '../../services/utils'
import styles from '../../styles/intervencao/Intervencao.module.scss'
import { AREA_VISIVEL, CONSULTAR_INTERVENCAO, NENHUM, PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO, PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA, PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO, PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_LEITURA, PERMISSION_INTERVENCAO_PERFIL_TOTAL, POLIGONO_SELECAO, clearLayers } from '../../utils/constants'
import FieldsetCombosLocalizacao from '../localizacao/FieldsetCombosLocalizacao'
import { filterBy, FilterDescriptor } from '@progress/kendo-data-query'
import ReactGA from 'react-ga4';

type Props = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
}



export default function PesquisarIntervencao({ onCloseClick }: Props) {
  const [identificacaoVisible, setIdentificacaoVisible] = useState(true)
  const toggleIdentificacao = () =>
    setIdentificacaoVisible((previousState) => !previousState)

  const [registoVisible, setRegistoVisible] = useState(true)
  const toggleRegisto = () => {
    setRegistoVisible(!registoVisible)
  }
  const [criterioGeograficoVisible, setCriterioGeograficoVisible] =
    useState(false)
  const toggleCriterioGeografico = () => {
    setCriterioGeograficoVisible((previousState) => !previousState)
  }

  const [localizacaoVisible, setLocalizacaoVisible] = useState(false)
  const toggleLocalizacao = () => {
    setLocalizacaoVisible((previousState) => !previousState)
  }

  const [dataVisible, setDataVisible] = useState(false)
  const toggleData = () => {
    setDataVisible((previousState) => !previousState)
  }

  const [fonteVisible, setFonteVisible] = useState(false)
  const toggleFonte = () => {
    setFonteVisible((previousState) => !previousState)
  }

  const [caracterizacaoVisible, setCaracterizacaoVisible] = useState(false)
  const toggleCaracterizacao = () => {
    setCaracterizacaoVisible((previousState) => !previousState)
  }

  const [instrumentoVisible, setInstrumentoVisible] = useState(false)
  const toggleInstrumento = () => {
    setInstrumentoVisible((previousState) => !previousState)
  }

  const [entidadeVisible, setEntidadeVisible] = useState(false)
  const toggleEntidade = () => {
    setEntidadeVisible((previousState) => !previousState)
  }

  const getIntervencao = async (data: any) => await post<any, any>(`${CONSULTAR_INTERVENCAO}`, data)

  const [concelhosFiltred, setConcelhosFiltred] = useState<any>(undefined)
  const [concelhosDisabled, setConcelhosDisabled] = useState<any>(true)
  const [freguesiasFiltred, setFreguesiasFiltred] = useState<any>(undefined)
  const [freguesiasDisabled, setFreguesiasDisabled] = useState<any>(true)
  const [nuts2Filtered, setNuts2Filtered] = useState<any>(undefined)
  const [nuts2Disabled, setNuts2Disabled] = useState<any>(true)
  const [nuts3Filtered, setNuts3Filtered] = useState<any>(undefined)
  const [nuts3Disabled, setNuts3Disabled] = useState<any>(true)
  const [minData, setMinDate] = useState<any>();
  const [tecnicosData, setTecnicosData] = useState(tecnicos ? tecnicos.slice() : []);
  const [dataRegistoInicio, setDataRegistoInicio] = useState<any>()
  const [dataRegistoFim, setDataRegistoFim] = useState<any>()
  const [tip1Data, setTip1Data] = useState(tip1 ? tip1.slice() : []);
  const [tip2Data, setTip2Data] = useState(tip2 ? tip2.slice() : []);
  const [tip3Data, setTip3Data] = useState(tip3 ? tip3.slice() : []);


  const filterDataTecnicos = (filter: FilterDescriptor) => {
    const data = tecnicos.slice();
    return filterBy(data, filter);
  };

  const filterChangeTecnicos = (event: ComboBoxFilterChangeEvent) => {
    setTecnicosData(filterDataTecnicos(event.filter));
  };

  type combosKeys = 'estadoIntervencao'|'instrumentoFinanciamento'
  |'tip1'| 'tip2' | 'tip3'|'instrumentoPlaneamento'
  |'prioridade' | 'planeamento1' |'planeamento2' | 'planeamento3'
  |'entidades';

  
  const [combos, setCombos] = useState<any>({
    estadoIntervencao,
    instrumentoPlaneamento,
    instrumentoFinanciamento,
    tip1,
    tip2,
    tip3,
    prioridade,
    planeamento1,
    planeamento2,
    planeamento3,
    entidades,
  })

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/PesquisarIntervencao" });
  }, []);

  const setDataOption: Record<combosKeys, { setFn: any, data: any[] }> = {
    estadoIntervencao: { setFn: setCombos, data: estadoIntervencao },
    instrumentoFinanciamento: { setFn: setCombos, data: instrumentoFinanciamento },
    tip1: { setFn: setCombos, data: tip1 },
    tip2: { setFn: setCombos, data: tip2 },
    tip3: { setFn: setCombos, data: tip3 },
    prioridade: { setFn: setCombos, data: prioridade },
    planeamento1: { setFn: setCombos, data: planeamento1 },
    planeamento2: { setFn: setCombos, data: planeamento2 },
    planeamento3: { setFn: setCombos, data: planeamento3 },
    entidades: { setFn: setCombos, data: entidades },
    instrumentoPlaneamento: { setFn: setCombos, data: instrumentoPlaneamento },

  };

  const filterCombo_Multiselect = (e: any, tipoCombo: combosKeys) => {
    const { setFn, data } = setDataOption[tipoCombo];
      setFn({
          ...combos,
          [tipoCombo]: filterBy(data?.slice()??[], e.filter)
      }
      );
}

const filterDataTip1 = (filter: FilterDescriptor) => {
  const data = tip1.slice();
  return filterBy(data, filter);
};

const filterChangeTip1 = (event: ComboBoxFilterChangeEvent) => {
  setTip1Data(filterDataTip1(event.filter));
};

const filterDataTip2 = (filter: FilterDescriptor) => {
  const data = tip2.slice();
  return filterBy(data, filter);
};

const filterChangeTip2 = (event: ComboBoxFilterChangeEvent) => {
  setTip2Data(filterDataTip2(event.filter));
};

const filterDataTip3 = (filter: FilterDescriptor) => {
  const data = tip3.slice();
  return filterBy(data, filter);
};

const filterChangeTip3 = (event: ComboBoxFilterChangeEvent) => {
  setTip3Data(filterDataTip3(event.filter));
};

 


useEffect(() => {
    
     if(!nuts1 ){
      fillNuts1Async();
      }
     if(!nuts2) {
      fillNuts2Async();
     }
     if(!nuts3){
      fillNuts3Async();
     }
     if(!distritos){
      fillDistritosAsync();
     }
     if(!concelhos){
      fillConcelhosAsync();
     }
     if(!freguesias){
      fillFreguesiasAsync();
     }
     if(!arhs){
      fillArhsAsync();
     }
     if(!capitania){
      fillCapitaniaAsync();
     }
     if(!massaagua){
      fillMassaAguaAsync();
     }
     if(!instrumentoFinanciamento){
      fillInstrumentoFinanciamentoAsync();
     }
     if(!tip1){
      fillTip1Async();
     }
     if(!tip2){
      fillTip2Async();
     }
     if(!tip2){
      fillTip3Async();
     }
     if(!prioridade){
      fillPrioridadeAsync();
     }
     if(!instrumentoPlaneamento){
      fillInstrumentoPlaneamentoAsync();
     }
     if(!planeamento1){
      fillPlaneamento1Async();
     }
      if(!planeamento2){
      fillPlaneamento2Async();
     }
      if(!planeamento3){
      fillPlaneamento3Async();
     }
     if(!prioridade){
      fillPrioridadeAsync();
     }
     if(!entidades){
      fillEntidadesAsync();
     }

  }, []);


  const filterConcelho = (e: any) => {
    if (e?.value?.codigo) {
      const concelhosLocal = concelhos.filter(
        (x: any) => x.distrito_cod == e.value.codigo
      )
      setConcelhosFiltred(concelhosLocal)
      setConcelhosDisabled(false)
    } else {
      setConcelhosFiltred(concelhos)
      setConcelhosDisabled(true)
    }
  }

   const handleSubmit = async (e: any) => {

    try {
      postMessage({ type: 'showLoader' }, '*')

      const objToSearch = {
              id: e.values?.id,
              designacao: e.values?.designacao ?? undefined,
              obs: e.values?.observacoes ?? undefined,
              objetivo : e.values?.objetivo ?? undefined,
              localizacao: e.values?.local ?? undefined,
              lugar: e.values?.lugar ?? undefined,
              id_tipo_n1: e.values?.id_tipo_n1 ?  e.values?.id_tipo_n1.id : undefined,
              id_tipo_n2: e.values?.id_tipo_n2 ?  e.values?.id_tipo_n2.id : undefined,
              id_tipo_n3: e.values?.id_tipo_n3 ?  e.values?.id_tipo_n3.id : undefined,
              id_int_pal_xxi_tip_nivel_1: e.values?.id_int_pal_xxi_tip_nivel_1 ?  e.values?.id_int_pal_xxi_tip_nivel_1.id : undefined,
              id_int_pal_xxi_tip_nivel_2: e.values?.id_int_pal_xxi_tip_nivel_2 ?  e.values?.id_int_pal_xxi_tip_nivel_2.id : undefined,
              id_int_pal_xxi_tip_nivel_3: e.values?.id_int_pal_xxi_tip_nivel_3 ?  e.values?.id_int_pal_xxi_tip_nivel_3.id : undefined,
              id_int_instrumento_planeamento: e.values?.int_instrumento_planeamento ? e.values?.id_int_instrumento_planeamento.id : undefined,
              id_prioridade: e.values?.id_prioridade ? e.values?.id_prioridade.id : undefined,
              de_inicio: e.values?.de_inicio ?? undefined,
              ate_inicio: e.values?.ate_inicio ?? undefined,
              de_fim: e.values?.de_fim ?? undefined,
              ate_fim: e.values?.ate_fim ?? undefined,
              status: e.values?.status?.valor ?? undefined,
              freguesia_cod: e.values?.freguesia?.codigo ?? undefined,
              concelho_cod: e.values?.concelho?.codigo ?? undefined,
              distrito_cod: e.values?.distrito?.codigo ?? undefined,
              nuts1_cod: e.values?.nuts1?.codigo ?? undefined,
              nuts2_cod: e.values?.nuts2?.codigo ?? undefined,
              nuts3_cod: e.values?.nuts3?.codigo ?? undefined,
              arh_cod: e.values?.arh?.codigo ?? undefined,
              capitania_cod : e.values?.capitania?.codigo ?? undefined,
              massa_agua_id: e.values?.massaAgua?.id ?? undefined,
              entidade : e.values?.entidade ? e.values?.entidade.id : undefined,
              criterio_geografico: e?.values?.rbCriterio ?? undefined,
              flatCoordinates: coordenadasPoligono,
              id_estado : e.values?.estado?.id ?? undefined,
              filedoc_cod: e.values?.filedoc_cod ?? undefined,
              tecnico_responsavel: e.values?.tecnico_responsavel?.id ?? undefined,
              estado_id: e.values?.estado?.id ?? undefined,
              ate_registo: e.values?.ateRegisto ?? undefined,
              de_registo: e.values?.deRegisto  ?? undefined,
              codigo_intervencao: e.values?.codigo_intervencao ?? undefined,
              fonte_financiamento_id: e.values?.fonte_financiamento?.id ?? undefined,
              entidade_id : e.values?.entidade?.id ?? undefined
      }

      SessionStorageService.setItem("lastSearchIntervencao", objToSearch);
      let result: any[] = await getIntervencao(objToSearch);

      if (typeof result === "string") {
        result = JSON.parse(result);
      }

      if (result) {

        SessionStorageService.setItem('resultadoIntervencaoPesquisa', result)
        postMessage({ type: 'resultadoIntervencaoPesquisaChange', value: result }, '*')

      }
      SessionStorageService.setItem("firstCallIntervencao", true);

    } catch (error) {
      postMessage({ type: 'errorMsg', value:{message:"Não foi possivel efetuar esta operação"} }, '*')
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  const filterFreguesia = (e: any) => {
    if (e?.value?.codigo) {
      const freguesiaLocal = freguesias.filter(
        (x: any) => x.concelho_cod == e.value.codigo
      )
      setFreguesiasFiltred(freguesiaLocal)
      setFreguesiasDisabled(false)
    } else {
      setFreguesiasDisabled(true)
      setFreguesiasFiltred(freguesias)
    }
  }
  const filterNuts2 = (e: any) => {
    if (e?.value?.codigo) {
      const nutsLocal = nuts2.filter((x: any) => x.nuts_i_cod == e.value.codigo)
      setNuts2Filtered(nutsLocal)
      setNuts2Disabled(false)
    } else {
      setNuts2Disabled(true)
      setNuts2Filtered(nuts2)
    }
  }
  const filterNuts3 = (e: any) => {
    if (e?.value?.codigo) {
      const nutsLocal = nuts3.filter(
        (x: any) => x.nuts_ii_cod == e.value.codigo
      )
      setNuts3Filtered(nutsLocal)
      setNuts3Disabled(false)
    } else {
      setNuts3Disabled(true)
      setNuts3Filtered(nuts3)
    }
  }

  const [dataInicioDe, setDataInicioDe] = useState('');
  const [dataInicioAte, setDataInicioAte] = useState('');

  const [dataConclusaoDe, setDataConclusaoDe] = useState('');
  const [dataConclusaoAte, setDataConclusaoAte] = useState('');

 
  
  const clearMap = () => {
    try {

      setCoordenadasPoligono(undefined)
      const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.contentWindow?.postMessage({ type: "SiarlIntIntervencaoClearLayers", value: "true" }, '*')
      iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: "true" }, '*')
      iframe.contentWindow?.postMessage({ type: "SiarlDeselectMapArea", value: "true" }, '*')

    } catch (error) {
      console.log({ error })
    }
  }

  const changerbCriterio = (e: any) => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlDeselectMapArea", value: 'true' }, '*')
    if (e.value == POLIGONO_SELECAO) {
        postMessage(
            { type: 'informationMsg', value: 'Selecione a área de pesquisa' },
            '*'
        )
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage(
            { type: 'SiarlGeoPortalAdicionarArea', value: 'true' },
            '*'
        )
    }
    if (e.value == AREA_VISIVEL) {
        iframe.contentWindow?.postMessage({ type: "SiarlSelectMapArea", value: 'true' }, '*')
        postMessage(
            {
                type: 'informationMsg',
                value: 'Foi selecionada a área visível do mapa',
            },
            '*'
        )
       
        iframe.contentWindow?.postMessage(
            { type: 'SiarlGetMapArea', value: 'true' },
            '*'
        )
    }
    if (e.value == NENHUM) {
        //setCoordenadasPoligono(undefined)
        clearMap()
    }
  }

  return (
    <Form
      onSubmitClick={handleSubmit}
      render={(formRenderProps: FormRenderProps) => (
        <>
          <FormElement className={styles.form}>

          <fieldset>
              <legend>
                <button type='button' className={styles.itemExpandBase} onClick={toggleRegisto}>
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span className={registoVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                  </div>
                  <span className={styles.fieldsetTitulo}>Registo</span>
                </button>
              </legend>
              {registoVisible && (
                <div className={styles.espacoVerticalFieldSet}>
                  <div className={styles.fields} >
                    <div>
                      <Field name="id" label="ID" component={Input} type="number" />
                    </div>
                    <div>
                      <Field name="filedoc_cod" label="Nº Processo APA" component={Input} type="number" />
                    </div>
                    
                  </div>
                  
                  <div className={styles.fields}>
                    
                    <div>
                      <Field
                        name="tecnico_responsavel"
                        label="Técnico Responsável pelo registo"
                        component={ComboBox}
                        textField={'name'}
                        filterable={true}
                        onFilterChange={filterChangeTecnicos}
                        data={tecnicosData}
                      />
                    </div>
                    <div>
                      <Field
                        name="estado"
                        label="Estado"
                        component={ComboBox}
                        textField={'descricao'}
                        filterable={true}
                        data={combos?.estadoIntervencao}
                      />
                    </div>
                  </div>
                  
                  <div style={{ marginTop: 15 }}>

                <div className={styles.fieldsetSubTitulo}>
                    Data de Registo
                </div>
                <div className={styles.fields}>
                    <div>
                      <Field
                        name="deRegisto"
                        label="De"
                        value={dataRegistoInicio}
                        format="dd/MM/yyyy HH:mm"
                        component={DateTimePicker}
                        onChange={(e) => {
                          setDataRegistoInicio(e.value)
                          setMinDate(e.value);
                        }}     
                      />
                    </div>
                    <div>
                      <Field
                        name="ateRegisto"
                        label="Até"
                        value={dataRegistoFim}
                        format="dd/MM/yyyy HH:mm"
                        component={DateTimePicker}
                        onChange={(e) => {
                          setDataRegistoFim(e.value)
                        }}
                        min={minData}
                  />
                </div>
              </div>
            </div>
          </div>
              )}
            </fieldset>

            <fieldset>
              <legend>
                <button
                  type="button"
                  className={styles.itemExpandBase}
                  onClick={toggleIdentificacao}
                >
                  <div
                    className={styles.btn_Expandir_Colapsar}
                  >
                    <span
                      className={
                        identificacaoVisible
                          ? 'icon-siarl icon-colapsar'
                          : 'icon-siarl icon-expandir'
                      }
                    />
                  </div>
                  <span className={styles.fieldsetTitulo}>Identificação</span>
                </button>
              </legend>

              {identificacaoVisible && (
                <><><><><><div
                  className={`${styles.fields} `}
                >
                  <div>
                    <Field name="designacao" label="Designação" component={Input} />
                  </div>
                </div><div
                  className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}
                >
                    <div>
                      <Field
                        name="codigo_intervencao"
                        label="Código da intervenção"
                        component={Input} />
                    </div>
                    <div>
                      <Field
                        name="id_prioridade"
                        label="Prioridade"
                        component={ComboBox}
                        textField={'descricao'}
                        filterable={true}
                        onFilterChange={(e: any) => { filterCombo_Multiselect(e, 'prioridade') } }
                        data={prioridade} />
                    </div>
                  </div></>
                  <div className={styles.fieldsetSubTitulo}>
                    Data de início
                  </div>
                  <div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}
                  >
                    <div>
                      <Field
                        name="de_inicio"
                        label="De"
                        component={DateTimePicker}
                        format="dd/MM/yyyy HH:mm"
                        onChange={(e) => {
                          setDataInicioDe(e.value)
                          setMinDate(e.value)
                        } } />
                    </div>
                    <div>
                      <Field
                        name="ate_inicio"
                        label="Até"
                        component={DateTimePicker}
                        format="dd/MM/yyyy HH:mm"
                        min={minData} />
                    </div>
                  </div>
                </><div className={styles.fieldsetSubTitulo}>
                    Data de fim
                  </div><div className={`${styles.fields} `}
                  >
                    <div>
                      <Field
                        name="de_fim"
                        label="De"
                        component={DateTimePicker}
                        format="dd/MM/yyyy HH:mm"
                        onChange={(e) => {
                          setDataInicioAte(e.value)
                          setMinDate(e.value)
                        } } />
                    </div>
                    <div>
                      <Field
                        name="ate_fim"
                        label="Até"
                        component={DateTimePicker}
                        format="dd/MM/yyyy HH:mm"
                        min={minData} />
                    </div>
                  </div></>
            {hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO) ||
              hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA) ||
              hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO) ||
              hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_LEITURA) ||
              hasPermission(PERMISSION_INTERVENCAO_PERFIL_TOTAL) ?
                  <div className={`${styles.fields}`}>
                    <div>
                      <Field
                        name="fonte_financiamento"
                        label="Fonte de financiamento"
                        component={ComboBox}
                        textField={'designacao'}
                        filterable={true}
                        onFilterChange={(e: any) => { filterCombo_Multiselect(e, 'instrumentoFinanciamento') } }
                        data={combos?.instrumentoFinanciamento} />
                    </div>
                  </div>: '' } </> 
                  <div
                    className={`${styles.fields}`}
                  >
                    <div>
                      <Field
                        name="entidade"
                        label="Entidade"
                        component={ComboBox}
                        textField={'nome'}
                        filterable={true}
                        onFilterChange={(e: any) => { filterCombo_Multiselect(e, 'entidades') } }
                        data={combos?.entidades} />
                    </div>
                  </div>
                  <div
                    className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}
                  >
                  <div>
                      <Field
                        name="objetivo"
                        label="Objetivo"
                        component={Input} />
                    </div>
                  </div>
                  </>
                 
              )}
            </fieldset>
            <fieldset>
              <legend>
                <button
                  type="button"
                  className={styles.itemExpandBase}
                  onClick={toggleCriterioGeografico}
                >
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span
                      className={
                        criterioGeograficoVisible
                          ? 'icon-siarl icon-colapsar'
                          : 'icon-siarl icon-expandir'
                      }
                    ></span>
                  </div>
                  <span className={styles.fieldsetTitulo}>
                    Critério geográfico
                  </span>
                </button>
              </legend>
              {criterioGeograficoVisible ? (
                <div className={styles.fields}>
                  <Field
                    id={'rbCriterio'}
                    name={'rbCriterio'}
                    layout={'horizontal'}
                    component={RadioGroup}
                    onChange={(e) => {
                      changerbCriterio(e)
                  }}                    
                  data={[
                      { label: 'Nenhum', value: 0},
                      { label: 'Área visível', value: 1 },
                      { label: 'Polígono de seleção', value: 2 },
                    ]}
                    defaultValue={0} // Set the default selected value to 0 (Nenhum)

                  />
                </div>
              ) : (
                <></>
              )}
            </fieldset>
            <FieldsetCombosLocalizacao styles={styles} isPesquisaAvancada={true} formRenderProps={formRenderProps}/>

            <fieldset>
              <legend>
                <button
                  type="button"
                  className={styles.itemExpandBase}
                  onClick={toggleCaracterizacao}
                >
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span
                      className={
                        caracterizacaoVisible
                          ? 'icon-siarl icon-colapsar'
                          : 'icon-siarl icon-expandir'
                      }
                    />
                  </div>
                  <span className={styles.fieldsetTitulo}>
                    Tipologia PAL XXI
                  </span>
                </button>
              </legend>
              {caracterizacaoVisible && (
                <div className={styles.espacoVerticalFieldSet}>
                  <div className={styles.fields}>
                    <div>
                      <Field
                        name="id_int_pal_xxi_tip_nivel_1"
                        label="Tipologia Nivel 1"
                        component={ComboBox}
                        textField={'descricao'}
                        filterable={true}
                        onFilterChange={filterChangeTip1}
                        data={tip1Data}
                      />
                    </div>
                    <div>
                      <Field
                        name="id_int_pal_xxi_tip_nivel_2"
                        label="Tipologia Nivel 2"
                        component={ComboBox}
                        textField={'descricao'}
                        filterable={true}
                        onFilterChange={filterChangeTip2}
                        data={tip2Data}
                      />
                    </div>
                    <div>
                      <Field
                        name="id_int_pal_xxi_tip_nivel_3"
                        label="Tipologia Nivel 3"
                        component={ComboBox}
                        textField={'descricao'}
                        filterable={true}
                        onFilterChange={filterChangeTip3}
                        data={tip3Data}

                      />
                    </div>
                  </div>
                 
                </div>
              )}
            </fieldset>
        
          </FormElement>

          <div
            className={`${styles.footer} ${styles.justify_multiple_children}`}
          >
            <Button
              onClick={() => {formRenderProps.onFormReset(); clearMap()}}
              className={styles.btnLimparPesquisa}
              title='Limpar pesquisa'
            >
              <span className="icon-siarl icon-limparcampos"></span>
              <span className={styles.rotulo}>
                {' '}&nbsp;{' '}
                Limpar pesquisa
              </span>         
            </Button>

            <Button
              onClick={formRenderProps.onSubmit}
              className={styles.btnPesquisar}
              title='Pesquisar'
            >
              <span className="icon-siarl icon-pesquisar"></span>
              <span className={styles.rotulo}>
                {' '}&nbsp;{' '}
                Pesquisar
              </span>          
            </Button>
          </div>
        </>
      )}
    />
  )
}
