import { create } from 'zustand'

type MobilizacaoState = {
  selectedMobilizacaoResult?: unknown
  selectMobilizacaoResult: (payload: unknown) => void
  selectedMobilizacaoResultId?: number
  selectMobilizacaoResultId: (payload?: number) => void
  identificacaoTabIsValid: boolean
  selectMobilizacaoIdentificacaoTabIsValid: (payload: unknown) => void
  identificacaoTabValidationObject: Record<string, boolean>
  addIdentificacaoTabValidatedField: (payload: Record<string, boolean>) => void
}

export const useMobilizacaoStore = create<MobilizacaoState>((set, get) => ({
  selectedMobilizacaoResult: null,
  identificacaoTabIsValid: false,
  identificacaoTabValidationObject: {},
  selectMobilizacaoResult: (payload: unknown) =>
    set({ selectedMobilizacaoResult: payload }),
  selectMobilizacaoResultId: (payload?: number) =>
    set({ selectedMobilizacaoResultId: payload }),
  selectMobilizacaoIdentificacaoTabIsValid: (payload?: any) =>
    set({ identificacaoTabIsValid: payload }),
  addIdentificacaoTabValidatedField: (payload) => {
    const validationObj = get().identificacaoTabValidationObject
    const newValidationObj = { ...validationObj, ...payload }
    set({ identificacaoTabValidationObject: newValidationObj })
    const validationValues = Object.values(newValidationObj)
    set({
      identificacaoTabIsValid: validationValues.every(
        (value) => value === true
      ),
    })
  },
}))
