import { Button, DropDownButton } from '@progress/kendo-react-buttons'
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout'
import {
  MouseEvent as ReactMouseEvent,
  useEffect,
  useLayoutEffect,
  useState
} from 'react'
import { fillAgEstadosAsync, fillDecisoesAsync, fillTipoPretensoesAsync, hasPermission, setDesconformidadeSelected, setPretensaoSelected, useCombos } from '../../services/utils'
import { useAgPretensaoStore } from '../../stores/agPretensao'
import styles from '../../styles/apoiogestao/ApoioGestao.module.scss'
import DesconformidadeApoioGestao from './DesconformidadeApoioGestao'
import PretensaoForm from './PretensaoForm'
import { PERMISSION_APOIO_GESTAOPERFIL_INTERMEDIO_EDICAO, PERMISSION_APOIO_GESTAOPERFIL_INTERMEDIO_LEITURA, PERMISSION_APOIO_GESTAO_PERFIL_COMPLETO_EDICAO, PERMISSION_APOIO_GESTAO_PERFIL_COMPLETO_LEITURA, PERMISSION_APOIO_GESTAO_PERFIL_TOTAL, PERMISSION_INSERIR_DESCONFORMIDADE, PERMISSION_INSERIR_PRETENSAO, PERMISSION_LISTAR_AG_DESCONFORMIDADE, PERMISSION_LISTAR_AG_PRETENSAO, URL_AJUDA_APOIO_GESTAO_PRETENSAO } from '../../utils/constants'
import SessionStorageService from '../../services/SessionStorageService'
import { useAgDesconformidadeStore } from '../../stores/agDesconformidade'
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais'
import ReactGA from 'react-ga4';

type IProps = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined,
  formDesconformidade?: number
}

const TAB_PRETENSAO = 0;
const TAB_DESCONFORMIDADE = 1;
export default function ConsultarApoioGestaoTabsForm({ onCloseClick, formDesconformidade }: IProps) {
  const [isMobile, setIsMobile] = useState(false);
  const [selected, setSelected] = useState<number>(formDesconformidade && formDesconformidade == 1 ? TAB_DESCONFORMIDADE : TAB_PRETENSAO)
  const [isPretensao, setIsPretensao] = useState<boolean>(true);
  const [seePretensao, setSeePretensao] = useState<boolean>(false);
  const [seeDesconformidade, setSeeDesconformidade] = useState(false);

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected)
    if (e.selected == TAB_PRETENSAO) {
      setIsPretensao(true)
    }
    if (e.selected == TAB_DESCONFORMIDADE) {
      setIsPretensao(false)
    }
  }
  const selectAgPretensaoResultId: any = useAgPretensaoStore(
    (state) => state.selectAgPretensaoResultId
  )
  const selectAgPretensaoResult = useAgPretensaoStore(
    (state) => state.selectAgPretensaoResult
  )

  const selectAgDesconformidadeResult: any = useAgDesconformidadeStore(
    (state) => state.selectAgDesconformidadeResult
  ) 
  
  const selectAgDesconformidadeResultId: any = useAgDesconformidadeStore(
    (state) => state.selectAgDesconformidadeResultId
  )

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/ConsultarApoioGestaoTabsForm" });
  }, [])

  const registarNovoClick = async () => {
    if (!isPretensao) {
      postMessage({ type: "form", value: "registarApoioGestaoDesconformidade" }, "*");
      SessionStorageService.setItem("novaDesconformidade", 'true');
      SessionStorageService.setItem("NovoRegistoDesconformidade", "true");
      setDesconformidadeSelected(undefined);
      selectAgDesconformidadeResult(undefined);
      selectAgDesconformidadeResultId(undefined);
      console.log("desconformidade");
      return;
    }
    selectAgPretensaoResultId(undefined);
    setPretensaoSelected(undefined);
    selectAgPretensaoResult(undefined);
    SessionStorageService.setItem("novaPretensao", 'true');
    postMessage({ type: "form", value: "registarApoioGestaoPretensao" }, "*");

  }

  useEffect(() => {
    const seePretensaoLocal = hasPermission(PERMISSION_LISTAR_AG_PRETENSAO) || 
    hasPermission(PERMISSION_APOIO_GESTAO_PERFIL_TOTAL) ||
    hasPermission(PERMISSION_APOIO_GESTAO_PERFIL_COMPLETO_EDICAO) ||
    hasPermission(PERMISSION_APOIO_GESTAO_PERFIL_COMPLETO_LEITURA);
    setSeePretensao(seePretensaoLocal);
    const seeDesconformidadeLocal = hasPermission(PERMISSION_LISTAR_AG_DESCONFORMIDADE) ||
    hasPermission(PERMISSION_APOIO_GESTAO_PERFIL_TOTAL) ||
    hasPermission(PERMISSION_APOIO_GESTAO_PERFIL_COMPLETO_EDICAO) ||
    hasPermission(PERMISSION_APOIO_GESTAO_PERFIL_COMPLETO_LEITURA) ||
    hasPermission(PERMISSION_APOIO_GESTAOPERFIL_INTERMEDIO_LEITURA) ||
    hasPermission(PERMISSION_APOIO_GESTAOPERFIL_INTERMEDIO_EDICAO);
    setSeeDesconformidade(seeDesconformidadeLocal);

    if (!seePretensaoLocal) {
      setIsPretensao(false);
    } else {
        setIsPretensao(true); 
      }
     
  }, [])

  const options = [
    {
    
      key: 'SiarlGeoPortalAdicionarPonto',
      text: 'Selecionar Ponto',
      icon: <span className="icon-siarl icon-s"></span>

    },
    {
      key: 'SiarlGeoPortalRemoverPonto',
      text: 'Remover Ponto',
      icon: <span className="icon-siarl icon-ajuda"></span>

    },
    {
      key: 'SiarlGeoPortalAdicionarArea',
      text: 'Desenhar Área',
      icon: <span className="icon-siarl icon-ajuda"></span>

    },
  ]

  useLayoutEffect(() => {

    const handleResize = () => {

      if (window.innerWidth <= telaMobile) {
        
        setIsMobile(true);
        ocultaFloatingMenu_e_Mapa();

      }else{

        setIsMobile(false);
        mostraFloatingMenu_e_Mapa();

      }

    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  useEffect(() => {
   console.log(selected);
  }, [])

  const onItemClickMais = (event: any) => {

    elementoClickMais(event);

  };

  const optionsMais = [
    {
      key: 'novoRegisto',
      text: isPretensao ? "Nova pretensão" : "Nova desconformidade",
      elementType: 'button',
      className: 'icon-siarl icon-registar btnIconRegistar'
    }
  ]

const openAjuda = () => {
  window.open(URL_AJUDA_APOIO_GESTAO_PRETENSAO, "_blank")
}
  return (
    <div className={styles.base}>

      <div className={styles.header}>

        <div>
          <div className={styles.nomeDoModulo}>
            Apoio à gestão
          </div>
          <div className={styles.tituloDaPagina}>
            Critérios de pesquisa
          </div>
        </div>

        <div>
          {
            (isPretensao && hasPermission(PERMISSION_INSERIR_PRETENSAO)) ||
            (!isPretensao && hasPermission(PERMISSION_INSERIR_DESCONFORMIDADE)) ||
            (hasPermission(PERMISSION_APOIO_GESTAOPERFIL_INTERMEDIO_EDICAO)) ||
            (hasPermission(PERMISSION_APOIO_GESTAO_PERFIL_TOTAL)) ||
            (hasPermission(PERMISSION_APOIO_GESTAO_PERFIL_COMPLETO_EDICAO))
          ?
          <Button
            onClick={registarNovoClick}
            className={styles.btnNovoRegisto}
            style={{ display: isMobile ? 'none' : '' }}
            id='novoRegisto'
            title={isPretensao ? "Nova pretensão" : "Nova desconformidade"}
          >
            <span className="icon-siarl icon-registar btnIconRegistar"></span>{' '}
            &nbsp;{' '}
            {isPretensao ? "Nova pretensão" : "Nova desconformidade"}
          </Button> : ''}

          {isMobile &&
            <DropDownButton
              items={optionsMais}
              className={styles.btnMais}
              iconClass="icon-siarl icon-mais"
              onItemClick={onItemClickMais}
              fillMode={'flat'}
              itemRender={itemRenderMais}
              title="Mais Opções"
            />
          }

          <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
            <span className="icon-siarl icon-ajuda"></span>
          </button>
          <button onClick={onCloseClick} className={styles.btnFechar} title="Minimizar">
            <span className="icon-siarl icon-minimizar"></span>
          </button>
        </div>

      </div>
      <TabStrip selected={selected} className={styles.backgroundWhite} onSelect={handleSelect}
      scrollable={isMobile}>
        {seePretensao ? 
          <TabStripTab title="Pretensão" key={"Pretensao"} >
            <PretensaoForm onCloseClick={onCloseClick} />
          </TabStripTab>
        : ''}
        {seeDesconformidade ? 
          <TabStripTab title="Desconformidade" key={"Desconformidade"}>
            <DesconformidadeApoioGestao onCloseClick={onCloseClick} />
          </TabStripTab>
        : ''}
      </TabStrip>

    </div>
  );
}

