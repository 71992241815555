import { ComboBox, ComboBoxFilterChangeEvent } from '@progress/kendo-react-dropdowns'
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form'
import { Input } from '@progress/kendo-react-inputs'
import { MouseEvent as ReactMouseEvent, useEffect, useState } from 'react'

import {
  estadoCandidatura,
  fillEstadoCandidaturaAsync,
  get,
  getCandidaturaSelected,
  post,
  useCombos
} from '../../services/utils'
import styles from '../../styles/intervencao/Intervencao.module.scss'
import { filterBy, FilterDescriptor } from '@progress/kendo-data-query'
import { Grid, GridColumn } from '@progress/kendo-react-grid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faRemove } from '@fortawesome/free-solid-svg-icons'
import { Dialog } from '@progress/kendo-react-dialogs'
import { requiredFieldValidator } from '../../validations/ocorrencia'
import { CONSULTAR_ACAO, CURRENT_TIMEZONE, GET_CANDIDATURA_ACAO_BY_CANDIDATURA } from '../../utils/constants'
import { v4 as uuidv4 } from 'uuid';
import SessionStorageService from '../../services/SessionStorageService'
import moment from 'moment'
import "moment-timezone"
import { process } from '@progress/kendo-data-query'

type IProps = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
  formRenderProps: FormRenderProps
  viewMode?: boolean
}

export default function IdetificacaoAcao({ onCloseClick, formRenderProps, viewMode }: IProps) {

  type combosKeys= 'estadoCandidatura';

    const [combos, setCombos] = useState<any>({
      estadoCandidatura
    })

    const [gridDataAcaoCandidatura, setGridDataAcaoCandidatura] = useState<any[]>([]);
    const [page2, setPage2] = useState({ skip: 0, take: 10 });
    const [isModalCandidaturaOpened, setIsModalCandidaturaOpened] = useState<any>();
    const [formDataModal, setFormDataModal] = useState<any>();
    const [idAcao, setIdAcao] = useState<any>();
    const [designacao, setDesignacao] = useState<any>();
    const [invElegivel, setInvElegivel] = useState<any>();
    const [invPrevisto, setInvPrevisto] = useState<any>();
    const [invComunitario, setInvComunitario] = useState<any>();
    const [invNaoElegivel, setInvNaoElegivel] = useState<any>();
    const [invNacional, setInvNacional] = useState<any>();
    const [despesaElegivel, setDespesaElegivel] = useState<any>();
    const [despesaNaoElegivel, setDespesaNaoElegivel] = useState<any>();
    const [acoes, setAcoes] =useState<any>();
    const [acoesData, setAcoesData] =useState<any>();
    const [isEditing, setIsEditing] = useState(false);
    const [acaoSelected, setAcaoSelected] = useState<any>();
    const [percentagemComparticipacao, setPercentagemComparticipacao] = useState<any>();
    const [errors, setErrors] = useState<any>({});
    const [validated, setValidated] = useState(false);
    const valorDefaultIVA = 23;
    const [iva, setIva] = useState<any>(valorDefaultIVA);
    const [unicID, setUnicID] = useState<any>();
    const getByIdCandidatura = async (data: any) => await get<any>(`${GET_CANDIDATURA_ACAO_BY_CANDIDATURA}/` + data);
    
    const consultarIntAcao = async (data: any) =>
      await post<any, any>(CONSULTAR_ACAO, data)


    const setDataOption: Record<combosKeys, { setFn: any, data: any[] }> = {
      estadoCandidatura: {
        setFn: setCombos,
        data: estadoCandidatura
      }
    };

  const filterCombo_Multiselect = (e: any, tipoCombo: combosKeys) => {
      const { setFn, data } = setDataOption[tipoCombo];
      setFn({
          ...combos,
          [tipoCombo]: filterBy(data?.slice()??[], e.filter)
      }
      );
  }

    useEffect(() => {
      if (getCandidaturaSelected()?.id) {
        getByIdCandidatura(getCandidaturaSelected()?.id).then((result : any[]) => {
          const candidaturasLst: any[] = gridDataAcaoCandidatura.slice();
          result.forEach(item => {
            const dataNewRow = {
              id: uuidv4(),
              id_acao: item.acao?.id,
              designacao: item.acao?.designacao,
              data_ini_p: moment.utc(item.acao?.data_ini_p).tz(CURRENT_TIMEZONE).format("DD/MM/YYYY"),
              data_con_p:moment.utc(item.acao?.data_con_p).tz(CURRENT_TIMEZONE).format("DD/MM/YYYY"),
              investimento: (Number.parseFloat(item.acao?.inv_previs) / (1 + (item.iva / 100))).toFixed(2),
              proc_contratacao: item.acao?.proc_contratacao,
              iva: item.iva,
              total_adjudicacao: item.acao?.inv_previs,
              despesa_elegivel: item.despesa_elegivel,
              despesa_nao_elegivel : item.despesa_nao_elegivel,
              despesa_total: (Number.parseFloat(item.despesa_elegivel) + Number.parseFloat(item.despesa_nao_elegivel)),
              percentagem_comparticipacao: item.percentagem_coparticipacao,
              inv_comunitario: item.inv_comunitario,
              inv_nacional: Number.parseFloat(item.inv_nacional),
              inv_nao_elegivel : Number.parseFloat(item.inv_previsto) - Number.parseFloat(item.inv_elegivel),
              inv_elegivel: Number.parseFloat(item.inv_elegivel),
              inv_comparticipado: Number.parseFloat(item.percentagem_coparticipacao) * Number.parseFloat(item.inv_elegivel),
              inv_previsto: Number.parseFloat(item.acao?.inv_previs)
            }
            candidaturasLst.push(dataNewRow);
          })
          setGridDataAcaoCandidatura(candidaturasLst);
          SessionStorageService.setItem("gridDataAcaoCandidatura", candidaturasLst);
        })
      }
      
    }, [])

  
  useEffect(() => {

    if(!estadoCandidatura)
    {
      fillEstadoCandidaturaAsync();
    }
    setIva(valorDefaultIVA);
    postMessage({type: "showLoader"}, "*");
    consultarIntAcao({}).then(result => {
      postMessage({type: "hideLoader"}, "*");
      const resultFiltred = result.filter((x: any) => x.designacao != null);
      setAcoes(resultFiltred);
      setAcoesData(resultFiltred);
    })

  }, []);

  const openModalCandidatura = (e: any) => {
    setIsModalCandidaturaOpened(true);
    e.preventDefault();
  }

  const handleEditModalCandidatura = (e: any) => {
    const ivaFinal = iva ?? valorDefaultIVA;
    const dataItem = e?.dataItem;
    setUnicID(dataItem.id);
    setIsEditing(true);
    console.log(dataItem);
    setIdAcao(dataItem.id_acao);
    setInvPrevisto(dataItem.inv_previsto);
    setInvElegivel(dataItem.inv_elegivel);
    setInvNaoElegivel(dataItem.inv_nao_elegivel);
    setDespesaElegivel(dataItem.despesa_elegivel);
    setDespesaNaoElegivel(dataItem.despesa_nao_elegivel);
    setIva(dataItem.iva);
    setPercentagemComparticipacao(dataItem.percentagem_comparticipacao);
    const designacaoObj = acoes.find((x: any) => x.id == dataItem.id_acao);
    setDesignacao(designacaoObj);
    setIsModalCandidaturaOpened(true);
  }

  const handleDeleteModalAcoCandidatura = (e:any) => {
    const list = gridDataAcaoCandidatura.filter(x => x.id != e.dataItem?.id);
    setGridDataAcaoCandidatura(list);
  }

  const validate = () => {
    if (!invElegivel || invElegivel?.length == 0 ||
      !despesaElegivel || despesaElegivel.length == 0 ||
      !despesaNaoElegivel || despesaNaoElegivel.length == 0 ||
      !percentagemComparticipacao || percentagemComparticipacao.length == 0 ||
      !acaoSelected 
    ) {
      setValidated(true);
      return false;
    } else {
      setValidated(false);
    }
    return true;
  }

  useEffect(() => {
    SessionStorageService.setItem("gridDataAcaoCandidatura", gridDataAcaoCandidatura);
  }, [gridDataAcaoCandidatura])

  const handleModalSubmit = (e: any) => {
    if(!validate()) {
      return;
    }
    const ivaFinal = iva ?? valorDefaultIVA;
    const dataNewRow = {
      id: isEditing ? unicID : uuidv4(),
      id_acao: acaoSelected.id,
      designacao: acaoSelected.designacao,
      data_ini_p: acaoSelected.data_ini_p ? moment.utc(acaoSelected.data_ini_p).tz(CURRENT_TIMEZONE).format("DD/MM/YYYY") : undefined,
      data_con_p: acaoSelected.data_con_p ? moment.utc(acaoSelected.data_con_p).tz(CURRENT_TIMEZONE).format("DD/MM/YYYY") : undefined,
      investimento: (Number.parseFloat(invPrevisto) / (1 + (ivaFinal / 100))).toFixed(2),
      proc_contratacao: acaoSelected.proc_contratacao,
      iva: ivaFinal,
      total_adjudicacao: invPrevisto,
      despesa_elegivel: despesaElegivel,
      despesa_nao_elegivel : despesaNaoElegivel,
      despesa_total: (Number.parseFloat(despesaElegivel) + Number.parseFloat(despesaNaoElegivel)),
      percentagem_comparticipacao: percentagemComparticipacao,
      inv_comunitario: (Number.parseFloat(percentagemComparticipacao) * Number.parseFloat(despesaElegivel)),
      inv_nacional: (Number.parseFloat(despesaNaoElegivel) + (100 - Number.parseFloat(percentagemComparticipacao)) * Number.parseFloat(despesaElegivel)).toFixed(2),
      inv_nao_elegivel : Number.parseFloat(invPrevisto) - Number.parseFloat(invElegivel),
      inv_elegivel: Number.parseFloat(invElegivel),
      inv_comparticipado: Number.parseFloat(percentagemComparticipacao) * Number.parseFloat(invElegivel),
      inv_previsto: Number.parseFloat(invPrevisto)
    }
    let candidaturasLst: any[] = [];
    if (!isEditing) {
      candidaturasLst = gridDataAcaoCandidatura.slice();
      candidaturasLst.push(dataNewRow);
      setGridDataAcaoCandidatura(candidaturasLst);

  } else {
    const candidaturasLst = gridDataAcaoCandidatura.filter(x => x.id != unicID);
    candidaturasLst.push(dataNewRow);
    setGridDataAcaoCandidatura(candidaturasLst);
  }
  setIsModalCandidaturaOpened(false);
  SessionStorageService.setItem("gridDataAcaoCandidatura", candidaturasLst);

  }

  const handlePesquisar = async (e: any) => {
    if ((!idAcao || idAcao == "") && !designacao) {
      postMessage({type: "infoMsg", value: {message: "Preencha o id ou a designação"}}, "*");
      return;
    }
    
    const objToSend = {
      id: idAcao && idAcao != "" ? idAcao : undefined,
      designacao : designacao?.designacao ?? undefined
    }
    const result = await consultarIntAcao(objToSend);
    console.log(result);
    if (result.length > 0) {
      setAcaoSelected(result[0]);
      setInvPrevisto(result[0].inv_previs);
      const designacaoObj = acoes.find((x: any) => x.id == result[0].id);
      setDesignacao(designacaoObj);
      setIdAcao(result[0].id)
    }
    
  }

  const filterDataAcoes = (filter: FilterDescriptor) => {
    const data = acoes.slice();
    return filterBy(data, filter);
  };

  const filterChangeAcoes = (event: ComboBoxFilterChangeEvent) => {
    setAcoesData(filterDataAcoes(event.filter));
  };

  const clearFieldsModalCandidatura = () => {
    setIdAcao(undefined);
    setDesignacao(undefined);
    setInvComunitario(undefined);
    setInvElegivel(undefined);
    setDespesaElegivel(undefined);
    setDespesaNaoElegivel(undefined);
    setPercentagemComparticipacao(undefined);
    setIva(undefined);
  }

  const [dataState, setDataState] = useState({
    skip: 0,
    take: 20,
    sort: [],
    group: [],
  });


  const [dataResult, setDataResult] = useState(
    process(gridDataAcaoCandidatura, dataState)
  );
  const dataStateChange = (event: any) => {
    setDataResult(process(gridDataAcaoCandidatura, event.dataState));
    setDataState(event.dataState);

    const skipPag: any = { skip: event.dataState.skip, take: event.dataState.take}
    setPage2({
  ...skipPag
})
  };

  return (
    <>
    <div className={`${styles.form} ${styles.removeScrollDuplicado}`}>
      <fieldset>
        <legend>
          <span className={styles.fieldsetTitulo}>Identificação</span>
        </legend>

        <div className={styles.espacoVerticalFieldSet}>
          <div className={styles.fields}>
            <div>
              <Field name="id" label="ID (Código)" component={Input} disabled />
            </div>
            <div>
              <Field
                name="cod_candid"
                label="N.º de candidatura"
                component={Input}
                disabled={viewMode} />
            </div>
            <div>
              <Field name="designacao" label="Designação" component={Input} disabled={viewMode} />
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset>
        <legend>
          <span className={styles.fieldsetTitulo}>Candidatura - Ação</span>
        </legend>
        <div className={styles.fields}>
          <button
            title="Adicionar Ação"
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary buttonAdicionarNovo btnPrimaryColor"
            onClick={(e) => {
              clearFieldsModalCandidatura();
              setIsEditing(false);
              openModalCandidatura(e);
            } }
            disabled={viewMode}
          >
            <span className="icon-siarl icon-registar k-button-icon"></span>{' '}
            &nbsp;{' '}
            Inserir
          </button>
        </div>
        <div className={styles.fields}>
          <div>
            <Grid
              className={'customGrid'}
              onDataStateChange={dataStateChange}
              data={dataResult.data.length > 0 ? dataResult : gridDataAcaoCandidatura?.slice(page2.skip, page2.take + page2.skip)}
              pageable={{
                buttonCount: 5,
                info: false,
                type: 'numeric',
                pageSizes: true,
                previousNext: true,
              }}
              total={dataResult.data.length > 0 ? dataResult.total : gridDataAcaoCandidatura.length}
              skip={page2.skip}
              take={page2.take}
            >
              <GridColumn width={56} title='Ações'
                cell={(props: any) => (
                  <td className="k-command-cell">
                    <div style={{ cursor: 'pointer' }} className={'gridCommand'}>
                      <div
                        role="button"
                        className={styles.gridCommandIconButton}
                        onClick={() => handleEditModalCandidatura(props)}
                      >
                        <FontAwesomeIcon icon={faInfoCircle} title="Editar contacto" />
                      </div>
                      <div style={{ cursor: 'pointer' }}
                        role="button"
                        onClick={() => {
                          handleDeleteModalAcoCandidatura(props)
                        } }
                        className={styles.gridCommandIconButton}
                      >
                        <FontAwesomeIcon icon={faRemove} title="Remover" />
                      </div>
                    </div>
                  </td>
                )} />
              <GridColumn width={150} className={styles.centerAlign} field='id_acao' title='Id ação' />
              <GridColumn width={150} className={styles.centerAlign} field='designacao' title='Designação' />
              <GridColumn width={150} className={styles.centerAlign} field='data_ini_p' title='Data ini. prevista' />
              <GridColumn width={150} className={styles.centerAlign} field='data_con_p' title='Data con. prevista' />
              <GridColumn width={150} className={styles.centerAlign} field='investimento' title='Investimento' />
              <GridColumn width={250} className={styles.centerAlign} field='proc_contratacao.descricao' title='Procedimento de contratacao' />
              <GridColumn width={150} className={styles.centerAlign} field='doc_suporte_investimento' title='Doc. suporte invest.' />
              <GridColumn width={150} className={styles.centerAlign} field='iva' title='IVA' />
              <GridColumn width={150} className={styles.centerAlign} field='total_adjudicacao' title='Total adjudicação' />
              <GridColumn width={150} className={styles.centerAlign} field='despesa_elegivel' title='Despesa elegivel' />
              <GridColumn width={150} className={styles.centerAlign} field='despesa_nao_elegivel' title='Despesa não elegivel' />
              <GridColumn width={150} className={styles.centerAlign} field='despesa_total' title='Despesa total' />
              <GridColumn width={150} className={styles.centerAlign} field='percentagem_comparticipacao' title='Percent. comparticip.' />
              <GridColumn width={150} className={styles.centerAlign} field='inv_comunitario' title='Inv. comunitario' />
              <GridColumn width={150} className={styles.centerAlign} field='inv_nacional' title='Inv. nacional' />
              <GridColumn width={150} className={styles.centerAlign} field='inv_nao_elegivel' title='Inv. não elegível' />
              <GridColumn width={150} className={styles.centerAlign} field='inv_elegivel' title='Inv. elegível' />
              <GridColumn width={150} className={styles.centerAlign} field='inv_previsto' title='Inv. previsto' />
            </Grid>
          </div>
        </div>

        <div className={styles.espacoVerticalFieldSet}>
          <div className={styles.fields}>
          </div>
        </div>
      </fieldset>
     </div>

      {isModalCandidaturaOpened ?
        <Dialog
        title="Candidatura - Ação"
        className="ModalInstumentoPlaneamento"
        onClose={() => setIsModalCandidaturaOpened(false)}
      >
        <Form
          render={(formRenderProps) => (
            <FormElement style={{ maxWidth: 620 }}>
              <div className="form removeScrollDuplicado">
                <fieldset>
                  <div className="espacoVerticalFieldSet">
                    <div className="fields">
                      <Input
                        name="id_acao"
                        label="id ação"
                        value={idAcao}
                        onChange={(e) => {
                          setIdAcao(e.value);
                        }}
                        required={(validated && (!acaoSelected || !acaoSelected?.id))}
                        />
                        {(!acaoSelected || !acaoSelected.id) && validated ? <span style={{color:'red'}}>{'Selecione a ação'}</span> : ''}
                      </div>
                    <div className="fields">
                    <ComboBox
                          name="designacao"
                          label="Designação"
                          filterable={true}
                          value={designacao}
                          onChange={(e) => {
                            setDesignacao(e.value)
                          }}
                          onFilterChange={filterChangeAcoes}
                          textField={'designacao'}
                          data={acoesData}
                          disabled={viewMode}
                        />
                    </div>
                    <div  className="fields">
                    
                      <Input
                          name="inv_previs"
                          value={invPrevisto}
                          label="Inv. Previsto"
                          disabled
                        />
                    </div>
                    <div className="fields">
                      <Input
                        name="inv_elegivel"
                        value={invElegivel}
                        label="Inv. Elegível"
                        onChange={(e) => {
                          setInvElegivel(e.value)
                        }}
                        required={(validated && (!invElegivel || invElegivel?.length == 0))}
                      />
                      {(!invElegivel || invElegivel?.length == 0) && validated ? <span style={{color:'red'}}>{'Campo obrigatório'}</span> : ''}
                    </div>
                    <div className="fields">
                      <Input
                        name="iva"
                        value={iva ?? valorDefaultIVA}
                        label="IVA"
                        type={'number'}
                        onChange={(e) => {
                          setIva(e.value)
                        }}
                        required={(validated && (!iva || iva?.length == 0))}
                      />
                    </div>

                    <div className="fields">
                      <Input
                        name="despesa_elegivel"
                        label="Despesa Elegivel"
                        value={despesaElegivel}
                        type={'number'}
                        onChange={(e) => {
                          setDespesaElegivel(e.value)
                        }}
                        required={(validated && (!despesaElegivel || despesaElegivel?.length == 0))}
                      />
                      {(!despesaElegivel || despesaElegivel?.length == 0) && validated ? <span style={{color:'red'}}>{'Campo obrigatório'}</span> : ''}
                    </div>
                    <div className="fields">
                      <Input
                        name="despesa_nao_elegivel"
                        label="Despesa Não Elegivel"
                        type={'number'}
                        value={despesaNaoElegivel}
                        onChange={(e) => {
                          setDespesaNaoElegivel(e.value)
                        }}
                        required={(validated && (!despesaNaoElegivel || despesaNaoElegivel?.length == 0))}
                      />
                      {(!despesaNaoElegivel || despesaNaoElegivel?.length == 0) && validated ? <span style={{color:'red'}}>{'Campo obrigatório'}</span> : ''}
                    </div>
                    <div className="fields">
                      <Input
                        name="percentagem_comparticipacao"
                        value={percentagemComparticipacao}
                        onChange={(e) => {
                          setPercentagemComparticipacao(e.value);
                        }}
                        label="Percentagem Comparticipação"
                      required={(validated && (!percentagemComparticipacao || percentagemComparticipacao?.length == 0))}
                        />
                        {(!percentagemComparticipacao || percentagemComparticipacao?.length == 0) && validated ? <span style={{color:'red'}}>{'Campo obrigatório'}</span> : ''}
                      </div>

                  </div>
                </fieldset>
              </div>
              <div className="k-form-buttons btnsModal">
              <button
                  type="button"
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                  onClick={() => setIsModalCandidaturaOpened(false)}
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                  onClick={
                    handlePesquisar
                  }
                >
                  Pesquisar
                </button>
                
                <button
                  type="button"
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                  onClick={handleModalSubmit}
                >
                  Submeter
                </button>
              </div>
            </FormElement>
          )}
        />
      </Dialog> : <></>
}
  </>
  )
}
