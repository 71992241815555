import { Button, DropDownButton } from '@progress/kendo-react-buttons'
import { Form, FormElement } from '@progress/kendo-react-form'
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from '@progress/kendo-react-layout'
import { useEffect, useLayoutEffect, useState } from 'react'

import { AssociacaoListagem } from '../../../components/associacao/Associacao'
import { DocumentoListagem } from '../../../components/documentos/registar/documento/documento'
import { OcorrenciaTerrenoConclusao } from '../../../components/ocorrencia/terreno/Conclusao'
import { OcorrenciaTerrenoDetalhe } from '../../../components/ocorrencia/terreno/Detalhes'
import { OcorrenciaTerrenoIdentificacao } from '../../../components/ocorrencia/terreno/Identificacao'
import {
  GetUserNameTecnico,
  b64toBlob,
  coordenadasPoligono,
  coordenadasSelected,
  del,
  entidadeUser,
  get,
  getIsEditingOcorrencia,
  getOcorrenciaSelected,
  handleGenericError,
  hasPermission,
  isTabSelection,
  ocEstados,
  post,
  put,
  refreshOcorrencesSearch,
  setCoordenadasPoligono,
  setCoordenadasSelected,
  setModeloSelected,
  setOcorrenciaSelected,
  setTabSelection,
  useCombos,
} from '../../../services/utils'
import { useOcorrenciaStore } from '../../../stores/ocorrencias'
import styles from '../../../styles/ocorrencia/ConsultarOcorrenciaForm.module.scss'
import {
  AUTH_LOCALSTORAGE,
  AVISO_CHAMADA,
  CURRENT_TIMEZONE,
  DELETE_OCORRENCIA,
  DELETE_OCORRENCIA_APOIO_GEOM,
  GET_EMAIL_BY_USER_ID,
  GET_OCORRENCIA,
  GET_URL_FROM_FILE_DOC,
  GET_USER_BY_ID,
  IMAGE_BASE_64_DOCS,
  LAYER_INDEX_OCORRENCIAS,
  LAYER_INDEX_OCORRENCIA_APOIO,
  OCORRENCIA_APOIO_POINT_NAME,
  OCORRENCIA_POINT_NAME,
  OPERATIONAL_LAYER,
  PERMISSION_ATUALIZAR_OCORRENCIA,
  PERMISSION_INSERIR_OCORRENCIA,
  PESQUISAR_ENTIDADEBYUSER,
  RELATORIO_OCORRENCIA,
  RELATORIO_OCORRENCIA_RDLC,
  SAVE_OCORRENCIA,
  UPDATE_OCORRENCIA,
  UPDATE_OCORRENCIA_APOIO,
  URL_AJUDA_OCORRENCIA_PESQUISA_AVANCADA,
  clearLayers,
} from '../../../utils/constants'
import { isOcorrenciaDocumentosValid } from '../../../validations/ocorrencia'
import { Dialog } from '@progress/kendo-react-dialogs'
import SessionStorageService from '../../../services/SessionStorageService'
import useClearMap from '../../../hooks/useClearMap'
import html2canvas from 'html2canvas'
import clsx from 'clsx'
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, mostraFloatingMenu_e_Mapa_e_Oculta_Form, ocultaFloatingMenu_e_Mapa, telaMobile } from '../../../components/common/ItemRenderMais'
import moment from 'moment';
import "moment-timezone"
import { format } from 'date-fns'
import { underlineIcon } from '@progress/kendo-svg-icons'

const TOTAL_TABS = 4

type Props = {
  onCloseClick: () => void | undefined
}

export default function RegistarOcorrenciaTerrenoTabs({onCloseClick}: Props) {
  const [isMobile, setIsMobile] = useState(false);
  const [selected, setSelected] = useState<number>(0)
  const [currentSelection, setCurrentSelection] = useState<any>(0);
  const [isSubmitAction, setIsSubmitAction] = useState(false)
  const [submitClicked, setSubmitClicked] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canInsert, setCanInsert] = useState(false);
  const [checkedInstabilidade, setCheckedInstabilidade] = useState(false);
  const [checkedErosao, setCheckedErosao] = useState(false);
  const [checkedGalgamento,setCheckedGalmento] = useState(false);
  const [checkedProtecao, setCheckedProtecao] = useState(false);
  const [ocorrenciaResumo, setOcorrenciaResumo] = useState<any>();

     const [percentLeftSucessError, setpercentLeftSucessError] = useState("20%");
     const [percentLeftMapa, setPercentLeft] = useState("16%");

     const updateOcorrenciaApoio = async (data: any) =>
      await put<any, any>(`${UPDATE_OCORRENCIA_APOIO}`, data)



  const saveOcorrencia = async (data: any) =>
    await post<any, any>(`${SAVE_OCORRENCIA}`, data)
  const deleteGeomApoioOcorrencia = async (data: any) =>
    await post<any, any>(`${DELETE_OCORRENCIA_APOIO_GEOM}`, data)

  const deleteOcorrencia = (id: number) => del(`${DELETE_OCORRENCIA}/${id}`, {})
  const getUrlFileDoc = async (data: any) => await get<any>(`${GET_URL_FROM_FILE_DOC}/` + encodeURIComponent(data));
  const getOcorrencia = async (data: any) =>
    await get<any, any>(`${GET_OCORRENCIA}/` + data)

    const imgBase64Docs = async (data: any) =>
    await get<any, any>(`${IMAGE_BASE_64_DOCS}/` + data)

  const getUserById = async (data: any) =>
  await get<any, any>(`${GET_USER_BY_ID}/` + data)

  const updateOcorrencia = async (data: any) =>
    await put<any, any>(`${UPDATE_OCORRENCIA}`, data)

    const relatorioOcorrencia = async (data: any) =>
    await post<any, any>(`${RELATORIO_OCORRENCIA_RDLC}`, data)

    const getEmailByUserId = async (data: any) =>
      await get<any, any>(`${GET_EMAIL_BY_USER_ID}/` + data)

  const selectedOcorrenciaResult: any = useOcorrenciaStore(
    (state) => state.selectedOcorrenciaResult
  )
  const selectedOcorrenciaResultId = useOcorrenciaStore(
    (state) => state.selectedOcorrenciaResultId
  )

  const selectOcorrenciaResultId = useOcorrenciaStore(
    (state) => state.selectOcorrenciaResultId
  )

  const selectOcorrenciaResult = useOcorrenciaStore(
    (state) => state.selectOcorrenciaResult
  )

  const identificacaoTabIsValid = useOcorrenciaStore(
    (state) => state.identificacaoTabIsValid
  )

  const detalheTabIsValid = useOcorrenciaStore(
    (state) => state.detalheTabIsValid
  )

  const [editMode, setEditMode] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [tipologiaValidator, setTipologiaValidator] = useState(false);

  const [openRemoveAreaModal, setOpenRemoveAreaModal] = useState<any>()


  useEffect(() => {
    setCanUpdate(hasPermission(PERMISSION_ATUALIZAR_OCORRENCIA));
    setCanInsert(hasPermission(PERMISSION_INSERIR_OCORRENCIA))
  }, [])

  useEffect(() => {
    if (selectedOcorrenciaResult?.id) {
      setCheckedInstabilidade(selectedOcorrenciaResult?.instabiladeArribas);
      setCheckedErosao(selectedOcorrenciaResult?.erosaoRecuo);
      setCheckedGalmento(selectedOcorrenciaResult?.galgamento);
      setCheckedProtecao(selectedOcorrenciaResult?.protecaoDefesa);
    }
  }, [])

  const handleCheckboxChangeInstabilidade = (e: any) => {
    setCheckedInstabilidade(e.value);
  };

  const handleCheckboxChangeErosao = (e: any) => {
    setCheckedErosao(e.value);
  };

  const handleCheckboxChangeGalmento = (e: any) => {
    setCheckedGalmento(e.value);
  };

  const handleCheckboxChangeProtecao = (e: any) => {
    setCheckedProtecao(e.value);
  };

  
  const handleSelect = (e: TabStripSelectEventArguments, formRenderProps:any) => {
    let validationError = false;
    const isDetalheTabValid = detalheTabIsValid && (checkedInstabilidade || checkedErosao || checkedGalgamento || checkedProtecao);

    SessionStorageService.setItem("isSubmitActionOcorrencia", "false");
    if (selected === 0 && !identificacaoTabIsValid) {
      validationError = true;
    }
  
    if (selected == 1 && e.selected != 0 && !isDetalheTabValid) {
      setTipologiaValidator(true)
      validationError = true;
    }
  
    if (validationError && !viewMode) {
      postMessage({ type: 'errorMsg', value: { left: (isMobile? (Number(percentLeftSucessError.split('%')[0])-5)+'%' : percentLeftSucessError) , message: "Verifique os campos obrigatórios." } }, '*');
      formRenderProps.onSubmit();
      setEditMode(true)
      setViewMode(false)
      return false;
    }
    setTabSelection(true);
    if (!viewMode) {
      formRenderProps.onSubmit();
    }
    setSelected(e.selected);
    const tabContent = document.getElementsByClassName(`k-active`)[1];
    if (tabContent) {
      tabContent.scrollTop = 0;
    }
  }

  const selectMapAction = async (item: any) => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    if (item.key == "removerArea"){
      setOpenRemoveAreaModal(true)
    }
    if(item.key == "SiarlEditarGeometria"){
      refreshOcorrencesSearch();
      postMessage(
        { type: 'infoEditGeom',  value: 
        { left: (isMobile? (Number(percentLeftMapa.split('%')[0])-5)+'%' : percentLeftMapa) ,
        message: "O ponto encontra-se em edição", geom : 'ponto'
          
        } },
        '*'
      )

      iframe.contentWindow?.postMessage(
        {
          type: 'SiarlOcorrenciasHighlightRemove',
          value: [getOcorrenciaSelected()?.id],
        },
        '*'
      )

      if (isMobile) {
        mostraFloatingMenu_e_Mapa_e_Oculta_Form();
      }

     iframe.contentWindow?.postMessage({ type: item.key, value: getOcorrenciaSelected()?.id, editGpLayer: OCORRENCIA_POINT_NAME }, '*')
     return;
    } else if (item.key == "SiarlEditarGeometriaArea") {
      iframe.contentWindow?.postMessage(
        {
          type: 'SiarlOcorrenciasHighlightRemove',
          value: [getOcorrenciaSelected()?.id],
        },
        '*'
      )
      postMessage(
        { type: 'infoEditGeom',  value: 
        { left: (isMobile? (Number(percentLeftMapa.split('%')[0])-5)+'%' : percentLeftMapa),
          message: "A área encontra-se em edição" ,geom : 'area'
        } },
        '*'
      )
      
      iframe.contentWindow?.postMessage({ type: "SiarlEditarGeometria", value: getOcorrenciaSelected()?.oc_ocorrencia_apoio_id, editGpLayer: OCORRENCIA_APOIO_POINT_NAME }, '*')
    } else {
      iframe.contentWindow?.postMessage({ type: item.key, value: "true" }, '*')
    }

  }

  const removeArea = async () => {
    const iframe: any = document.getElementById('iframeGeoPortal')

    const ocorrenciaSelected = getOcorrenciaSelected();
    iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: 'true' }, '*')
    setCoordenadasPoligono(undefined);

    if (!ocorrenciaSelected?.id) {
      setOpenRemoveAreaModal(false)
      return;
    }
    await deleteGeomApoioOcorrencia(ocorrenciaSelected?.id);
    refreshForm(ocorrenciaSelected?.id);
  //  postMessage({ type: 'sucessMsg' , value :{left : (isMobile? (Number(percentLeftSucessError.split('%')[0])-40)+'%' : percentLeftSucessError)
  //     } },'*')
    
    
    if (ocorrenciaSelected.oc_ocorrencia_apoio_id) {
      iframe.contentWindow?.postMessage(
        {
          type: 'SiarlOcorrenciasApoioSearch',
          value: [ocorrenciaSelected.oc_ocorrencia_apoio_id],
        },
        '*'
      )
    }
    setCoordenadasPoligono(undefined);
  setOpenRemoveAreaModal(false)
    
  }

  const onClickOpenFileDoc = async() => {
    const ocorrenciaSelected = getOcorrenciaSelected();
      if (!ocorrenciaSelected?.filedoc_cod) {
        postMessage({ type: "infoMsg", value: {message: "É necessário preencher o nº processo APA (filedoc)."} }, "*");
        return;
      }
      postMessage({ type: 'showLoader' }, '*');
      const urlFileDoc = await getUrlFileDoc(ocorrenciaSelected?.filedoc_cod);
      postMessage({ type: 'hideLoader' }, '*')
      window.open(urlFileDoc, '_blank');
      return;
  }

  useEffect(() => {
    setViewMode(!editMode && SessionStorageService.getItem("novaOcorrencia") != 'true');
  }, [])


  useEffect(() => {
    if(sessionStorage.getItem("ocGeomEdited") == 'true'){
      setEditMode(true)
      setViewMode(false)
    }
  }, [])
  
  const [estadoFalse, setEstado] = useState(false)
  const [estadoTrue, setEstadoTrue] = useState(true)

  const [openModalDescartar, setOpenModalDescartar] = useState<any>()
  const [deleteClicked, setDeleteClicked] = useState(false);

  const cancelModalDescartar = () => {
    setOpenModalDescartar(false)
  }

   const handleCancel = ()  => {  
        refreshForm(selectedOcorrenciaResultId);
        postMessage({ type: "form", value: "" }, "*");
        setTimeout(() => {
            postMessage({ type: "form", value: "registarOcorrencia" }, "*");
          }, 10)
    }
  
   const handleOpenModalDescartar = () => {
    if (
      SessionStorageService.getItem('novaOcorrencia') &&
      SessionStorageService.getItem('novaOcorrencia') == 'true'
    ) {
      setOpenModalDescartar(true);
    } else {
      onEditItemClick("SiarlVisualizar");
      handleCancel();
    }
  }


 const handleDescartar = async (e: any) => {
  const iframe: any = document.getElementById('iframeGeoPortal')
  iframe.contentWindow?.postMessage(
    {
      type: 'SiarlCoordenadaBoxClose',
      value: 'true',
    },
    '*'
  )
  iframe.contentWindow?.postMessage(
    {
      type: 'SiarlRemoveSelection',
      value: 'true',
    },
    '*'
  )
    clearOcorrenciaMap();
     setOpenModalDescartar(false)
     setDeleteClicked(false);
    postMessage({ type: 'form', value: 'consultaOcorrencias' }, '*')
   const ocorrenciaSelected = getOcorrenciaSelected()
   const userId = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '')
      ?.user?.id

      if (selectedOcorrenciaResultId) {
          SessionStorageService.removeItem("firstCallOcorrencias");
          const result = await deleteOcorrencia(selectedOcorrenciaResultId);
          setCoordenadasPoligono(undefined);
          setCoordenadasSelected(undefined);
          selectOcorrenciaResultId(undefined)
          selectOcorrenciaResult(undefined)
          setOcorrenciaSelected(undefined);
          SessionStorageService.removeItem("ocorrenciaIndeterminado");
          e.preventDefault();
      }
      SessionStorageService.setItem("isSubmitActionOcorrencia", "false");
  }
 
  const voltar = async() => {
      const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
      {
        type: 'SiarlOcorrenciasApoioHighlightRemove',
        value: 'true',
      },
      '*'
    )
    
    if (SessionStorageService.getItem("ocorrenciaSubmetida") == "true" || SessionStorageService.getItem("ocorrenciaAntiga") == "true" ) {
        postMessage({ type: 'form', value: 'consultaOcorrencias' }, '*')
        SessionStorageService.removeItem("ocorrenciaIndeterminado");
        clearOcorrenciaMap();
    }
    else{
        setOpenModalDescartar(true);
    }
    sessionStorage.setItem('ocGeomEdited', 'false')

  }


  const [clearMap] = useClearMap();
  const clearOcorrenciaMap = () => {
    clearMap(clearLayers["CLEAR_LAYERS_OCORRENCIAS"]);
    setOcorrenciaSelected(undefined)
    setCoordenadasSelected(undefined)
    selectOcorrenciaResult(undefined)
    selectOcorrenciaResultId(undefined)
  }


  const getOcorrenciaSelectedLocal = async(ocorrencia: any) => {
    
    let tecnico = "";
    const userLocal = JSON.parse(
      localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}')
    if(selectedOcorrenciaResult?.utilizador_id) {
      const user = await getUserById(selectedOcorrenciaResult?.utilizador_id);
      tecnico = user?.Name;
      if (!tecnico) {
        tecnico = userLocal?.user?.name ?? undefined
      }
    } else {
      tecnico = userLocal?.user?.name ?? undefined
    }
    
    return {
      id: ocorrencia.id,
      // Idetificacao
      distrito: ocorrencia.distrito ?? undefined,
      concelho: ocorrencia.concelho ?? undefined,
      freguesia: ocorrencia.freguesia ?? undefined,
      capitania: ocorrencia.capitania ?? undefined,
      capitania_id: ocorrencia.capitania_id ?? undefined,
      praia: ocorrencia.praia ?? undefined,
      massaAgua: ocorrencia.massa_agua ?? undefined,
      arh: ocorrencia.arh ?? undefined,
      nuts1: ocorrencia.nuts1 ?? undefined,
      nuts2: ocorrencia.nuts2 ?? undefined,
      nuts3: ocorrencia.nuts3 ?? undefined,
      dataHoraRegistro: ocorrencia.data_registo
        ? new Date(ocorrencia.data_registo)
        : undefined,
      dataHoraOcorrencia: ocorrencia.data_ocorrencia
        ? new Date(ocorrencia.data_ocorrencia)
        : undefined,
      entidade: ocorrencia.entidade ?? undefined,
      observacao: ocorrencia.observacoes ?? undefined,
      tecnico: tecnico,
      detecaoOcorrencia: ocorrencia.tipo_detecao_id ?? undefined,
      // Detalhes
      instabiladeArribas: ocorrencia.tip_inst_arribas ?? undefined,
      movimentoMassa: ocorrencia.tip_mmv ?? undefined,
      tip_mmv_alcance_deposito: ocorrencia.tip_mmv_alcance_deposito ?? undefined,
      fendaTracao: ocorrencia.tip_fenda_visivel ?? undefined,
      erosaoRecuo: ocorrencia.tip_erosao_arribas ?? undefined,
      escarpamento: ocorrencia.tip_escarpamento_duna ?? undefined,
      tip_altura_maxima_escarpa: ocorrencia.tip_altura_maxima_escarpa ?? undefined,
      rebaixamento: ocorrencia.tip_rebaixamento_praia ?? undefined,
      tip_reb_praia_altura_max: ocorrencia.tip_reb_praia_altura_max ?? undefined,
      galgamento: ocorrencia.galgamento_costeiro ?? undefined,
      tip_galg_cost_alcance_max: ocorrencia.tip_galg_cost_alcance_max ?? undefined,
      protecaoDefesa:  ocorrencia.tip_dano_obra ?? undefined,
      estruturaAderente: ocorrencia.tip_dano_estrutura_aderente ?? undefined,
      esporao: ocorrencia.tip_dano_esporao ?? undefined,
      protecaoEstabilizacao:
        ocorrencia.tip_dano_estrutura_protecao ?? undefined,
      ref_localizacao: ocorrencia.ref_localizacao ?? undefined,
      pessoaEmRisco: ocorrencia.tipo_pessoas_risco_id ?? undefined,
      fruicaoUsoPublico: ocorrencia.conseq_danos_areas_construidas ?? undefined,
      danosEdificacoes: ocorrencia.conseq_danos_edificacoes ?? undefined,
      outrosDanos: ocorrencia.conseq_danos_outras ?? undefined,
      outrosDanosDescricao: ocorrencia.conseq_danos_outras_obs ?? undefined,
      sistemasNaturais: ocorrencia.tipo_danos_sistemas_naturais_id ?? undefined,
      descricaoDanosSistemasNaturais:
        ocorrencia.danos_sistemas_naturais_obs ?? undefined,
      naoSabe: ocorrencia.causas_nao_sabe ?? undefined,
      tempestade: ocorrencia.causas_tempestade ?? undefined,
      descricaoTempestade: ocorrencia.causas_tempestade_obs ?? undefined,
      sismos: ocorrencia.causas_sistemos ?? undefined,
      outras: ocorrencia.causas_outras ?? undefined,
      descricaoOutras: ocorrencia.causas_ouras_obs ?? undefined,
      medidasMetigacao: ocorrencia.tipo_medida_mitigacao_id ?? undefined,
      sinalizacao: ocorrencia.mitig_sinalizacao ?? undefined,
      delimitacao: ocorrencia.mitig_delimitacao ?? undefined,
      saneamento: ocorrencia.mitig_saneamento ?? undefined,
      reabilitacao: ocorrencia.mitig_reabilitacao ?? undefined,
      reforco: ocorrencia.mitig_reforco ?? undefined,
      // Anexos
      uploadFotografia: ocorrencia.docs_fotos ?? undefined,
      uploadTopografia: '',
      uploadOutro: ocorrencia.docs_outros ?? undefined,
      utilizador_id: ocorrencia.utilizador_id,
      oc_estado: ocEstados.find(x => x.id == ocorrencia.estado_id),
      indeterminado: ocorrencia?.data_indeterminada ?? undefined,
      filedoc_cod: ocorrencia?.filedoc_cod
    }
  };


  const handleSubmit = async (e: any) => {

    const isSubmitAction: boolean = JSON.parse(SessionStorageService.getItem("isSubmitActionOcorrencia") ?? false);
    const isDetalheTabValid = detalheTabIsValid && (checkedInstabilidade || checkedErosao || checkedGalgamento || checkedProtecao);

    const userId = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '')
    ?.user?.id;

    if (getIsEditingOcorrencia()) {
      if (selectedOcorrenciaResult?.utilizador_id != userId) {
        if (!hasPermission(PERMISSION_ATUALIZAR_OCORRENCIA)) {
          postMessage({ type: 'errorMsg', value: { left: (isMobile? (Number(percentLeftSucessError.split('%')[0])-5)+'%' : percentLeftSucessError) , message: "Verifique os campos obrigatórios." } }, '*');
          return;
        }
      }
    }
    let validationError = false;
    setTipologiaValidator(false)

    // if(!getCheckedInstabilidade() && !getCheckedErosao() && !getCheckedGalmento() && !getCheckedProtecao()){
    //   setTipologiaValidator(true)
    //   validationError = true
    // }

    if (selected == 0 && !identificacaoTabIsValid) {
      validationError = true;
    }
    if (selected == 1 && !isDetalheTabValid) {
      setTipologiaValidator(true)
      validationError = true;
    }
    if (validationError) {
      postMessage({ type: 'errorMsg', value: { left: (isMobile? (Number(percentLeftSucessError.split('%')[0])-5)+'%' : percentLeftSucessError) , message: "Verifique os campos obrigatórios." } }, '*');
      setEditMode(true)
      setViewMode(false)
      return false;
    }

    console.log(e);

    const dataToSend = {
      id: selectedOcorrenciaResultId ? selectedOcorrenciaResultId : undefined,
      utilizador_id: userId,

      //Campos estáticos
      entidade_id: e?.values?.entidade?.id ?? undefined,
      entidade: e?.values?.entidade ?? undefined,
      distrito_cod: e?.values?.distrito?.codigo ?? undefined,
      concelho_cod: e?.values?.concelho?.codigo ?? undefined,
      freguesia_cod: e?.values?.freguesia?.codigo ?? undefined,
      nuts1_cod: e?.values?.nuts1?.codigo ?? undefined,
      nuts2_cod: e?.values?.nuts2?.codigo ?? undefined,
      nuts3_cod: e?.values?.nuts3?.codigo ?? undefined,
      arh_id: e?.values?.arh?.codigo ?? undefined,
      //  TabStripTab title="Identificação"
      capitania_cod: e?.values?.capitania?.codigo ?? undefined,
      praia_id: e?.values?.praia?.id,
      ref_localizacao: e?.values?.ref_localizacao,
      data_ocorrencia: e?.values?.dataHoraOcorrencia,
      data_registo: e?.values?.dataHoraRegistro,
      tipo_detecao_id: e?.values?.detecaoOcorrencia ?? 0,
      data_indeterminada: e?.values?.indeterminado,
      massa_agua_id: e.values?.massaAgua ? e.values?.massaAgua.id : undefined,

      //  TabStripTab title="Detalhes"
      tip_inst_arribas: e?.values?.instabiladeArribas,
      tip_mmv: e?.values?.movimentoMassa,
      tip_mmv_alcance_deposito: e?.values?.tip_mmv_alcance_deposito,
      tip_fenda_visivel: e?.values?.fendaTracao,
      tip_erosao_arribas: e?.values?.erosaoRecuo,
      tip_escarpamento_duna: e?.values?.escarpamento,
      tip_altura_maxima_escarpa: e?.values?.tip_altura_maxima_escarpa,
      tip_rebaixamento_praia: e?.values?.rebaixamento,
      tip_reb_praia_altura_max: e?.values?.tip_reb_praia_altura_max,
      galgamento_costeiro: e?.values?.galgamento,
      tip_galg_cost_alcance_max: e?.values?.tip_galg_cost_alcance_max,
      tip_dano_obra: e?.values?.protecaoDefesa ?? undefined,
      tip_dano_estrutura_aderente: e?.values?.estruturaAderente,
      tip_dano_esporao: e?.values?.esporao,
      tip_dano_estrutura_protecao: e?.values?.protecaoEstabilizacao,
      tipo_pessoas_risco_id: e?.values?.pessoaEmRisco,
      conseq_danos_areas_construidas: e?.values?.fruicaoUsoPublico,
      conseq_danos_edificacoes: e?.values?.danosEdificacoes,
      conseq_danos_outras: e?.values?.outrosDanos,
      conseq_danos_outras_obs: e?.values?.outrosDanosDescricao,
      tipo_danos_sistemas_naturais_id: e?.values?.sistemasNaturais,
      danos_sistemas_naturais_obs: e?.values?.descricaoDanosSistemasNaturais,
      causas_nao_sabe: e?.values?.naoSabe,
      causas_tempestade: e?.values?.tempestade,
      causas_tempestade_obs: e?.values?.descricaoTempestade,
      causas_sistemos: e?.values?.sismos,
      causas_outras: e?.values?.outras,
      causas_ouras_obs: e?.values?.descricaoOutras,
      tipo_medida_mitigacao_id: e?.values?.medidasMetigacao,
      mitig_sinalizacao: e?.values?.sinalizacao,
      mitig_delimitacao: e?.values?.delimitacao,
      mitig_saneamento: e?.values?.saneamento,
      mitig_reabilitacao: e?.values?.reabilitacao,
      mitig_reforco: e?.values?.reforco,
      observacoes: e?.values?.observacao ?? '', // CAMPO OBRIGATÓRIO
      estado_id: e?.values?.oc_estado?.id,
      //  TabStripTab title="Anexos"
      docs_fotos: e?.values?.fotografia,
      docs_outros: e?.values?.outro,
      //Coordenadas
      flatCoordinatesApoio: coordenadasPoligono,
      flat_coordinates: coordenadasSelected,
      status: true,
      filedoc_cod: e?.values?.filedoc_cod
    }
    try {
      let ocorrenciaSaved: any
      if (isSubmitAction) {
        if (selectedOcorrenciaResultId) {
          ocorrenciaSaved = await updateOcorrencia(dataToSend)
        } else {
          ocorrenciaSaved = await saveOcorrencia(dataToSend)
        }
        
        if (ocorrenciaSaved?.oc_ocorrencia_apoio_id && coordenadasPoligono) {
          const dataToSendApoio = {
            id: ocorrenciaSaved.oc_ocorrencia_apoio_id,
            flatCoordinatesGeomApoio: coordenadasPoligono
          }
          updateOcorrenciaApoio(dataToSendApoio);
        }
      }

      

      setSubmitClicked(true);
      // selectOcorrenciaResultId(ocorrenciaSaved?.id as number);
      SessionStorageService.setItem("ocorrenciaIndeterminado", dataToSend.data_indeterminada);
      // selectOcorrenciaResult({ ...getOcorrenciaSelectedLocal(ocorrenciaSaved), ...selectedOcorrenciaResult });
      // setOcorrenciaSelected(ocorrenciaSaved);
      if (isSubmitAction) {
        refreshForm(ocorrenciaSaved?.id); 
      } else {
        refreshForm(selectedOcorrenciaResultId);
      }
      if (selected < TOTAL_TABS && !isSubmitAction && !isTabSelection) {
        setSelected(selected + 1)
      }
      const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.contentWindow?.postMessage(
        {
          type: 'SiarlCoordenadaBoxClose',
          value: 'true',
        },
        '*'
      )
      if (isSubmitAction) {
        refreshOcorrencesSearch();
        postMessage({type: "removeInfoPonto", value: "true"}, "*");
        iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: "false"}, '*');
      }

      if (isSubmitAction && !viewMode) {
        postMessage({ type: 'sucessMsg' , value :{left : (isMobile? (Number(percentLeftSucessError?.split('%')[0])-40)+'%' : percentLeftSucessError)
        } },'*')
      }
    } catch(error) {
      handleGenericError(error)
    }
  }

  const refreshForm = async(id: any) => {
    const ocorrencia = await getOcorrencia(id);
    const userLocal = JSON.parse(
      localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}'
    )
    const tecnico = await GetUserNameTecnico(ocorrencia.utilizador_id);
    if (isSubmitAction) {
      postMessage({type: "removeInfoPonto", value: "true"}, "*");
    }
    const seletedOcorrenciaReulst = {
      id: ocorrencia.id,
      // Idetificacao
      distrito: ocorrencia.distrito ?? undefined,
      concelho: ocorrencia.concelho ?? undefined,
      freguesia: ocorrencia.freguesia ?? undefined,
      capitania: ocorrencia.capitania_id ?? undefined,
      praia: ocorrencia.praia ?? undefined,
      arh: ocorrencia.arh ?? undefined,
      nuts1: ocorrencia.nuts1 ?? undefined,
      nuts2: ocorrencia.nuts2 ?? undefined,
      nuts3: ocorrencia.nuts3 ?? undefined,
      dataHoraRegistro: ocorrencia.data_registo ? moment.utc(ocorrencia.data_registo).tz(CURRENT_TIMEZONE) :undefined ,

      dataHoraOcorrencia: ocorrencia.data_ocorrencia ? moment.utc(ocorrencia.data_ocorrencia).tz(CURRENT_TIMEZONE) : undefined,

      entidade: ocorrencia.entidade ?? undefined,
      observacao: ocorrencia.observacoes ?? undefined,
      tecnico: tecnico,
      detecaoOcorrencia: ocorrencia.tipo_detecao_id ?? undefined,
      // Detalhes
      instabiladeArribas: ocorrencia.tip_inst_arribas ?? undefined,
      movimentoMassa: ocorrencia.tip_mmv ?? undefined,
      tip_mmv_alcance_deposito: ocorrencia.tip_mmv_alcance_deposito ?? undefined,
      fendaTracao: ocorrencia.tip_fenda_visivel ?? undefined,
      erosaoRecuo: ocorrencia.tip_erosao_arribas ?? undefined,
      escarpamento: ocorrencia.tip_escarpamento_duna ?? undefined,
      tip_altura_maxima_escarpa: ocorrencia.tip_altura_maxima_escarpa ?? undefined,
      rebaixamento: ocorrencia.tip_rebaixamento_praia ?? undefined,
      tip_reb_praia_altura_max: ocorrencia.tip_reb_praia_altura_max ?? undefined,
      galgamento: ocorrencia.galgamento_costeiro ?? undefined,
      tip_galg_cost_alcance_max: ocorrencia.tip_galg_cost_alcance_max ?? undefined,
      protecaoDefesa:  ocorrencia.tip_dano_obra ?? undefined,
      estruturaAderente: ocorrencia.tip_dano_estrutura_aderente ?? undefined,
      esporao: ocorrencia.tip_dano_esporao ?? undefined,
      protecaoEstabilizacao:
        ocorrencia.tip_dano_estrutura_protecao ?? undefined,
      ref_localizacao: ocorrencia.ref_localizacao ?? undefined,
      pessoaEmRisco: ocorrencia.tipo_pessoas_risco_id ?? undefined,
      fruicaoUsoPublico: ocorrencia.conseq_danos_areas_construidas ?? undefined,
      danosEdificacoes: ocorrencia.conseq_danos_edificacoes ?? undefined,
      outrosDanos: ocorrencia.conseq_danos_outras ?? undefined,
      outrosDanosDescricao: ocorrencia.conseq_danos_outras_obs ?? undefined,
      sistemasNaturais: ocorrencia.tipo_danos_sistemas_naturais_id ?? undefined,
      descricaoDanosSistemasNaturais:
        ocorrencia.danos_sistemas_naturais_obs ?? undefined,
      naoSabe: ocorrencia.causas_nao_sabe ?? undefined,
      tempestade: ocorrencia.causas_tempestade ?? undefined,
      descricaoTempestade: ocorrencia.causas_tempestade_obs ?? undefined,
      sismos: ocorrencia.causas_sistemos ?? undefined,
      outras: ocorrencia.causas_outras ?? undefined,
      descricaoOutras: ocorrencia.causas_ouras_obs ?? undefined,
      medidasMetigacao: ocorrencia.tipo_medida_mitigacao_id ?? undefined,
      sinalizacao: ocorrencia.mitig_sinalizacao ?? undefined,
      delimitacao: ocorrencia.mitig_delimitacao ?? undefined,
      saneamento: ocorrencia.mitig_saneamento ?? undefined,
      reabilitacao: ocorrencia.mitig_reabilitacao ?? undefined,
      reforco: ocorrencia.mitig_reforco ?? undefined,
      // Anexos
      uploadFotografia: ocorrencia.docs_fotos ?? undefined,
      uploadTopografia: '',
      uploadOutro: ocorrencia.docs_outros ?? undefined,
      utilizador_id: ocorrencia.utilizador_id,
      oc_estado: ocorrencia.estado ?? undefined,
      indeterminado: ocorrencia?.data_indeterminada ?? undefined,
      filedoc_cod: ocorrencia?.filedoc_cod,
      oc_ocorrencia_apoio_id: ocorrencia?.oc_ocorrencia_apoio_id
    }
    const iframe: any = document.getElementById('iframeGeoPortal')

    if (ocorrencia.oc_ocorrencia_apoio_id) {
      iframe.contentWindow?.postMessage(
        {
          type: 'SiarlOcorrenciasApoioSearch',
          value: [ocorrencia.oc_ocorrencia_apoio_id],
        },
        '*'
      )
    }
    if (isSubmitAction) {
      refreshOcorrencesSearch();
    }
    iframe.contentWindow?.postMessage(
      {
        type: 'SiarlOcorrenciasHighlightRemove',
        value: [ocorrencia.id],
      },
      '*'
    )
    console.log(selectedOcorrenciaResult)

    selectOcorrenciaResultId(ocorrencia.id as number)
    selectOcorrenciaResult(seletedOcorrenciaReulst)
    setOcorrenciaSelected(seletedOcorrenciaReulst);
  }

  //  EFETUAR VALIDAÇÃO DAS TABS
  const validateTabs = (selected: number, identificacaoTabIsValid: boolean, detalheTabIsValid: boolean, isSubmitAction: boolean) : boolean => {
    let validationError = false;
  
    if (selected === 0 && !identificacaoTabIsValid) {
      validationError = true;
    }
  
    if (selected === 1 && (!detalheTabIsValid && !isSubmitAction)) { 
      validationError = true;
    }
  
    if (validationError) {
      postMessage({ type: 'errorMsg', value: { left: (isMobile? (Number(percentLeftSucessError.split('%')[0])-5)+'%' : percentLeftSucessError) , message: "Verifique os campos obrigatórios." } }, '*');
      setEditMode(true)
      setViewMode(false)
      return false;
    }
  
    return true;
  }
  

  const isSubmit = () => {
    if (selected === 0 && !identificacaoTabIsValid) {
      return false
    }
    if (selected === 1 && !detalheTabIsValid) {
      return false
    }
    if (selected === 2 && !isOcorrenciaDocumentosValid) {
      return false
    }
    if (selected === TOTAL_TABS) {
      return true
    } else {
      return false
    }
  }
  const innerStyleTabContent = {
    scrollBehavior: 'smooth',
    top: 55,
    height: '80vh',
  }

  const itemRender = (props: { item: any; itemIndex: number }) => {
    const isTitleItem = props.item.key === 'titulo';
    if (isTitleItem) {
      // Item de título personalizado
      return (
        <div className={styles.DropDownButtonTitulo}>
          <span>{props.item.text}</span>
        </div>
      );
    } else {
      // Demais itens
      return (
        <div className={styles.DropDownButtonItem}>
          <span>{props.item.text}</span>
        </div>
      );
    }
  };

  const onItemClick = (event: any) => {
    selectMapAction(event.item)
  }

  const onEditItemClick= (event:any) => {
    if (event == "SiarlEditar" || event?.item?.key == "SiarlEditar") {
      setEditMode(true);
      setViewMode(false);
      SessionStorageService.setItem('novaOcorrencia', 'false')
      return;
    }
    if (event == "SiarlVisualizar") {
      setEditMode(false);
      setViewMode(true);
      SessionStorageService.setItem('novaOcorrencia', 'false')
    }
    if (event?.item?.key == "SiarlApagar") {
      setDeleteClicked(true);
      setOpenModalDescartar(true);
    }
  }
    useEffect(() => {
      if (viewMode) {
        SessionStorageService.removeItem("novaOcorrencia");
      }    })  

  const optionsEditar = [

    {
      key: 'SiarlEditar',
      text: 'Editar',
    },
    {
      key: 'SiarlApagar',
      text: 'Apagar',
    },
    
  ]
  const optionsPonto = [

    {
      key: 'SiarlEditarGeometria',
      text: 'Editar Ponto',
    },
    
  ]

  const optionsFileDoc = [

    {
      key: 'AbrirFileDoc',
      text: 'Abrir Filedoc',
    },
    
  ]

  const optionsArea = [

    {
      key: 'SiarlGeoPortalAdicionarArea',
      text: 'Desenhar Área',
    },
    {
      key: 'SiarlEditarGeometriaArea',
      text: 'Editar Área',
    },
    {
      key: 'removerArea',
      text: 'Remover Área',
    },
  ]




  const onItemClickImprimir = async (event: any) => {
      //let mapImage = ''
  
      const ocorrencia = await getOcorrencia(selectedOcorrenciaResultId);
  
      const email = await getEmailByUserId(selectedOcorrenciaResult?.utilizador_id)
  
      if (!window.location.href.includes('localhost')) {
        const mapIframe = document.querySelector(
          '#iframeGeoPortal'
        ) as HTMLIFrameElement
  
        // const mapIframeInnerContent =
        //   mapIframe.contentWindow?.document.querySelector(
        //     'div.ol-viewport'
        //   ) as HTMLElement;
  
        // const mapBussula =  mapIframe.contentWindow?.document.querySelector(
        //   'button.fixedNorthArrowButton'
        // ) as HTMLElement;
  
        // mapIframeInnerContent.appendChild(mapBussula);
        
        // const generatedImage = await html2canvas(mapIframeInnerContent);
        // mapImage = generatedImage.toDataURL()
      }
          
       console.log(selectedOcorrenciaResult)
    
      let instabilidadeArribaText = ""
      let movimentoMassaText = ""
      const alcanceMaximoDepositotext = " : Alcance máximo do depósito/detritos: " + (selectedOcorrenciaResult?.tip_mmv_alcance_deposito ? selectedOcorrenciaResult?.tip_mmv_alcance_deposito.toString() : '') + "m\n"
      let fendaTracaoText = ""
      let erosaoRecuoText = ""
      let escarpamentoText = ""
      const alturaMaxEscarpamentoText = " : Altura máxima da escarpa: " + (selectedOcorrenciaResult?.tip_altura_maxima_escarpa ? selectedOcorrenciaResult?.tip_altura_maxima_escarpa.toString() : ' ')  + "m\n"
      let rebaixamentoText = "" 
      const alturaMaxPraiaText = " :  Altura máxima da praia: " + (selectedOcorrenciaResult?.tip_reb_praia_altura_max ? selectedOcorrenciaResult?.tip_reb_praia_altura_max.toString() : ' ') + 'm\n';
      let galgamentoCosteiroText = ""
      const alcanceMaximoAguaText = " : Alcance máximo de água /detritos: " + (selectedOcorrenciaResult?.tip_galg_cost_alcance_max ? selectedOcorrenciaResult?.tip_galg_cost_alcance_max.toString() : '') + 'm\n'
      let protecaoDefesaText = ""
      let estruturaAderenteText = ""
      let esporaoText = ""
      let estruturaProtecaoText = ""
      let pessoasEmRiscoText = ""
      let danosEdificaoesText = ""
      let danosUsoPublico = ""
      let outrosDanosText = ""
      const descricaoOutrosDanosText = selectedOcorrenciaResult?.outrosDanosDescricao ? selectedOcorrenciaResult?.outrosDanosDescricao.toString() : ''
      let sistemasNaturaisText = ""
      const descricaoSistemasNaturaisText = selectedOcorrenciaResult?.descricaoDanosSistemasNaturais ? selectedOcorrenciaResult?.descricaoDanosSistemasNaturais.toString() : ''
      let naoSabeText = ""
      let tempestadeText = ""
      const descricaoTempestadeText = (selectedOcorrenciaResult?.descricaoTempestade ? selectedOcorrenciaResult?.descricaoTempestade.toString() : '') + '\n'
      let sismosText = ""
      let outrasText = ""
      const descricaoOutrasText = selectedOcorrenciaResult?.descricaoOutras ? selectedOcorrenciaResult?.descricaoOutras.toString() : '';
      let medidasMetigacaoText = ""
  
      if(selectedOcorrenciaResult?.instabiladeArribas == true){
        instabilidadeArribaText = "Instabilidades em arribas\n";
     }
     if(selectedOcorrenciaResult?.movimentoMassa == true){
        movimentoMassaText = "    Movimento massa de vertente" + alcanceMaximoDepositotext 
     }
     if(selectedOcorrenciaResult?.fendaTracao == true){
        fendaTracaoText = "    Fenda tração com cobertura visível\n"
     }
     if(selectedOcorrenciaResult?.erosaoRecuo == true){
       erosaoRecuoText = "Erosão/Recuo da linha da costa\n"
     }
     if(selectedOcorrenciaResult?.escarpamento == true){
       escarpamentoText = "    Escarpamento da duna" + alturaMaxEscarpamentoText
     }
     if(selectedOcorrenciaResult?.rebaixamento == true){
       rebaixamentoText = "    Rebaixamento da praia" + alturaMaxPraiaText
     }
     if(selectedOcorrenciaResult?.galgamento == true) {
       galgamentoCosteiroText = "Galgamento costeiro " + alcanceMaximoAguaText
     }
     if(selectedOcorrenciaResult?.protecaoDefesa == true){
       protecaoDefesaText = "Em estruturas de proteção/defesa costeira\n"
     }
     if(selectedOcorrenciaResult?.estruturaAderente == true){
       estruturaAderenteText = "Estrutura aderente\n"
     }
     if(selectedOcorrenciaResult?.esporao == true){
       esporaoText = "Esporão/Quebra-Mar"
     }
     if(selectedOcorrenciaResult?.protecaoEstabilizacao == true){
      estruturaProtecaoText = "\nEstrutura de proteção/estabilização de arriba"
     }
     if(selectedOcorrenciaResult?.pessoaEmRisco == 1){
       pessoasEmRiscoText = "Sim"
     }
     else if(selectedOcorrenciaResult?.pessoaEmRisco == 2){
       pessoasEmRiscoText = "Não  sabe/Não Aplicável"
     }
     else if(selectedOcorrenciaResult?.pessoaEmRisco == 0 || selectedOcorrenciaResult.pessoaEmRisco == undefined){
       pessoasEmRiscoText = "Não"
     }
     if(selectedOcorrenciaResult?.fruicaoUsoPublico == true){
      danosUsoPublico = "Danos em áreas construidas de fruição/uso público\n"
    }
     if(selectedOcorrenciaResult?.danosEdificacoes == true){
       danosEdificaoesText = "Danos em edificações\n"
     }
     if(selectedOcorrenciaResult?.outrosDanos == true){
       outrosDanosText = "Outros danos: " + descricaoOutrosDanosText
     }
     if(selectedOcorrenciaResult?.sistemasNaturais == 1){
       sistemasNaturaisText = "Sim: " + descricaoSistemasNaturaisText
     }
     if(selectedOcorrenciaResult?.sistemasNaturais == 2){
       sistemasNaturaisText = "Não sabe/Não Aplicável"
     }
     if(selectedOcorrenciaResult.sistemasNaturais == 0 || selectedOcorrenciaResult.sistemasNaturais == undefined){
       sistemasNaturaisText = "Não"
     }
     if(selectedOcorrenciaResult.naoSabe == true){
       naoSabeText = "Não sabe/Não Aplicável\n"
     }
     if(selectedOcorrenciaResult.tempestade == true){
       tempestadeText = "Tempestade: " + descricaoTempestadeText
     }
     if(selectedOcorrenciaResult?.sismos == true){
       sismosText = "Sismos\n"
     }
     if(selectedOcorrenciaResult?.outras == true){
       outrasText = "Outras: " + descricaoOutrasText
     }
     if(selectedOcorrenciaResult?.medidasMetigacao == 2){
       medidasMetigacaoText = "Não sabe/Não Aplicável"
     }
     if(selectedOcorrenciaResult?.medidasMetigacao == 1){
       medidasMetigacaoText ="Sim"
     }
     if(selectedOcorrenciaResult?.medidasMetigacao == 0 || selectedOcorrenciaResult.medidasMetigacao == undefined){
       medidasMetigacaoText = "Não"
     }
  
      const entidade = selectedOcorrenciaResult?.entidade?.nome ?? '';
  
  
  
      let tipologiaOcorrenciaText = instabilidadeArribaText + movimentoMassaText + fendaTracaoText + erosaoRecuoText
       + escarpamentoText + rebaixamentoText + galgamentoCosteiroText + protecaoDefesaText + estruturaAderenteText + esporaoText + estruturaProtecaoText
  
       if (tipologiaOcorrenciaText == "") {
        tipologiaOcorrenciaText = "-";
        }
  
      let danosEdificacoesText = danosUsoPublico + danosEdificaoesText + outrosDanosText
      
      if(danosEdificacoesText == ""){
          danosEdificacoesText = "-";
        }
  
      let causasOcorrenciaText = naoSabeText + tempestadeText + sismosText + outrasText
  
      if (causasOcorrenciaText == "") {
        causasOcorrenciaText = "-";
        }
        let obs = ""
        if(selectedOcorrenciaResult?.observacao == ""){
           obs = "-"
        }
        else{
          obs = selectedOcorrenciaResult?.observacao 
        }
  
        let longitudeLatitude = '';
        if (ocorrencia.coord_x && ocorrencia.coord_y) {
          longitudeLatitude = ocorrencia.coord_x.toFixed(6) + " , " + ocorrencia.coord_y.toFixed(6)
        }

        let detecaoOcorrencia = '';

        if(ocorrencia. selectedOcorrenciaResult?.detecaoOcorrencia == 1){
          detecaoOcorrencia = "Aviso/Chamada"
        }
        else{
          detecaoOcorrencia = "Visita Regular"
          
        }

      try {
        postMessage({ type: 'showLoader' }, '*')
  
        const objToSendImgMapa : any = {
          CodPrintTemplate: "RELATORIO_OCORRENCIAS2",
          extendInfo: {
            spatialReference: {
              wkid: 0
            },
            xmin: 0,
            ymin: 0,
            xmax: 0,
            ymax: 0
          },
          ListOperationalLayerInfo: [
            {
              IdOperationLayer: OPERATIONAL_LAYER,
              UseLayerExtent: false,
              DefinitionExpression: "",
              ListServiceLayer: [
                {
                    LayerId: LAYER_INDEX_OCORRENCIAS,
                    DefinitionExpression: "id=" + selectedOcorrenciaResult?.id,
                    UseLayerExtent: ocorrencia.oc_ocorrencia_apoio_id ? false : true
                },
                {
                    LayerId: LAYER_INDEX_OCORRENCIA_APOIO,
                    DefinitionExpression: "ocorrencia_id=" + selectedOcorrenciaResult?.id,
                    UseLayerExtent: ocorrencia.oc_ocorrencia_apoio_id ? true : false
                }
              ]
            }
          ]
        }

        const documentoBase64 = await relatorioOcorrencia({
          id: selectedOcorrenciaResult?.id,
          dados: {
           'REGISTO' : '',
           'Data do registo:': selectedOcorrenciaResult?.dataHoraRegistro ? new Date(selectedOcorrenciaResult?.dataHoraRegistro).toLocaleDateString() : '',
           'Técnico:': selectedOcorrenciaResult?.tecnico ?? '',
           'Email:' : email ?? '',
           'Entidade:': entidade ?? '',
  
          'IDENTIFICAÇÃO' : '',
           'Data da ocorrência:': selectedOcorrenciaResult?.dataHoraOcorrencia ? new Date(selectedOcorrenciaResult?.dataHoraOcorrencia).toLocaleDateString() : 'Data indeterminada',
           'Deteção da ocorrência:': detecaoOcorrencia ?? '',
  
          'LOCALIZAÇÃO' : '',
           'Concelho:': selectedOcorrenciaResult?.concelho?.nome ?? '',
           'ARH :': selectedOcorrenciaResult?.arh?.nome ?? '',
           'Capitania:': selectedOcorrenciaResult?.capitania?.nome ?? '',
           'Praia:': selectedOcorrenciaResult?.praia?.nome ?? '',
           'Referência de local:': selectedOcorrenciaResult?.ref_localizacao ?? '',
           'Longetitude, latitude:':  longitudeLatitude ?? '',
          
          'TIPOLOGIA DA OCORRÊNCIA' : '',
           'Tipologia da Ocorrência:': tipologiaOcorrenciaText ?? '',
      
          'CONSEQUÊNCIAS DA OCORRÊNCIA' : '',
           'Pessoas em risco:': pessoasEmRiscoText ?? '',
           'Danos em edificações:': danosEdificacoesText ?? '',
           
           'Danos em sistemas:': sistemasNaturaisText ?? '',
  
            'CAUSAS DA OCORRÊNCIA' : '',
           'Causas da ocorrência:': causasOcorrenciaText ?? '',
           'MEDIDAS DE MITIGAÇÃO DE RISCO' : '',
           'Medidas de mitigação do risco:' : medidasMetigacaoText ?? '',
           'OBSERVAÇÕES' : '',
           'Observação:': obs ?? '',
            
          },  
          img_mapa: "",//mapImage.replace("data:image/png;base64,", ""),
          objJsonToCreateImg: objToSendImgMapa
        });
       
      if (documentoBase64) {
        const blob = b64toBlob(documentoBase64, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
        postMessage({ type: 'hideLoader' }, '*')
      }
    } catch (error) {
      console.log(error);
      postMessage({ type: 'errorMsg', value: { left: (isMobile? (Number(percentLeftSucessError.split('%')[0])-5)+'%' : percentLeftSucessError) , message: "Verifique os campos obrigatórios." } }, '*');
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }

  };

  const optionsImprimir = [
    {
      key: 'titulo',
      text: 'Imprimir',
    },
    {
      key: 'imprimirFichas',
      text: 'Fichas',
    },
    {
      key: 'imprimirResultados',
      text: 'Resultados',
    },
  ]

  const onItemClickExportar = async (event: any) => {
    let mapImage = ''
  
      const ocorrencia = await getOcorrencia(selectedOcorrenciaResultId);
  
      const email = await getEmailByUserId(selectedOcorrenciaResult?.utilizador_id)
  
      if (!window.location.href.includes('localhost')) {
        const mapIframe = document.querySelector(
          '#iframeGeoPortal'
        ) as HTMLIFrameElement
  
        const mapIframeInnerContent =
          mapIframe.contentWindow?.document.querySelector(
            'div.ol-viewport'
          ) as HTMLElement;
  
        const mapBussula =  mapIframe.contentWindow?.document.querySelector(
          'button.fixedNorthArrowButton'
        ) as HTMLElement;
  
        mapIframeInnerContent.appendChild(mapBussula);
        
        const generatedImage = await html2canvas(mapIframeInnerContent);
        mapImage = generatedImage.toDataURL()
      }
          
       console.log(selectedOcorrenciaResult)
    
      let instabilidadeArribaText = ""
      let movimentoMassaText = ""
      const alcanceMaximoDepositotext = " ; Alcance máximo do depósito/detritos: " + (selectedOcorrenciaResult?.tip_mmv_alcance_deposito ? selectedOcorrenciaResult?.tip_mmv_alcance_deposito.toString() : '') + "m\n"
      let fendaTracaoText = ""
      let erosaoRecuoText = ""
      let escarpamentoText = ""
      const alturaMaxEscarpamentoText = " : Altura máxima da escarpa: " + (selectedOcorrenciaResult?.tip_altura_maxima_escarpa ? selectedOcorrenciaResult?.tip_altura_maxima_escarpa.toString() : ' ')  + "m\n"
      let rebaixamentoText = "" 
      const alturaMaxPraiaText = " :  Altura máxima da praia: " + (selectedOcorrenciaResult?.tip_reb_praia_altura_max ? selectedOcorrenciaResult?.tip_reb_praia_altura_max.toString() : ' ') + 'm\n';
      let galgamentoCosteiroText = ""
      const alcanceMaximoAguaText = " : Alcance máximo de água /detritos: " + (selectedOcorrenciaResult?.tip_galg_cost_alcance_max ? selectedOcorrenciaResult?.tip_galg_cost_alcance_max.toString() : '') + 'm\n'
      let protecaoDefesaText = ""
      let estruturaAderenteText = ""
      let esporaoText = ""
      let estruturaProtecaoText = ""
      let pessoasEmRiscoText = ""
      let danosEdificaoesText = ""
      let danosUsoPublico = ""
      let outrosDanosText = ""
      const descricaoOutrosDanosText = selectedOcorrenciaResult?.outrosDanosDescricao ? selectedOcorrenciaResult?.outrosDanosDescricao.toString() : ''
      let sistemasNaturaisText = ""
      const descricaoSistemasNaturaisText = selectedOcorrenciaResult?.descricaoDanosSistemasNaturais ? selectedOcorrenciaResult?.descricaoDanosSistemasNaturais.toString() : ''
      let naoSabeText = ""
      let tempestadeText = ""
      const descricaoTempestadeText = selectedOcorrenciaResult?.descricaoTempestade ? selectedOcorrenciaResult?.descricaoTempestade.toString() : ''
      let sismosText = ""
      let outrasText = ""
      const descricaoOutrasText = selectedOcorrenciaResult?.descricaoOutras ? selectedOcorrenciaResult?.descricaoOutras.toString() : '';
      let medidasMetigacaoText = ""
  
      if(selectedOcorrenciaResult?.instabiladeArribas == true){
        instabilidadeArribaText = "Instabilidades em arribas\n";
     }
     if(selectedOcorrenciaResult?.movimentoMassa == true){
        movimentoMassaText = "    Movimento massa de vertente" + alcanceMaximoDepositotext 
     }
     if(selectedOcorrenciaResult?.fendaTracao == true){
        fendaTracaoText = "    Fenda tração com cobertura visível\n"
     }
     if(selectedOcorrenciaResult?.erosaoRecuo == true){
       erosaoRecuoText = "Erosão/Recuo da linha da costa\n"
     }
     if(selectedOcorrenciaResult?.escarpamento == true){
       escarpamentoText = "    Escarpamento da duna" + alturaMaxEscarpamentoText
     }
     if(selectedOcorrenciaResult?.rebaixamento == true){
       rebaixamentoText = "    Rebaixamento da praia" + alturaMaxPraiaText
     }
     if(selectedOcorrenciaResult?.galgamento == true) {
       galgamentoCosteiroText = "Galgamento costeiro " + alcanceMaximoAguaText
     }
     if(selectedOcorrenciaResult?.protecaoDefesa == true){
       protecaoDefesaText = "Em estruturas de proteção/defesa costeira\n"
     }
     if(selectedOcorrenciaResult?.estruturaAderente == true){
       estruturaAderenteText = "Estrutura aderente\n"
     }
     if(selectedOcorrenciaResult?.esporao == true){
       esporaoText = "Esporão/Quebra-Mar"
     }
     if(selectedOcorrenciaResult?.protecaoEstabilizacao == true){
      estruturaProtecaoText = "\nEstrutura de proteção/estabilização de arriba"
     }
     if(selectedOcorrenciaResult?.pessoaEmRisco == 1){
       pessoasEmRiscoText = "Sim"
     }
     else if(selectedOcorrenciaResult?.pessoaEmRisco == 2){
       pessoasEmRiscoText = "Não  sabe/Não Aplicável"
     }
     else if(selectedOcorrenciaResult?.pessoaEmRisco == 0 || selectedOcorrenciaResult.pessoaEmRisco == undefined){
       pessoasEmRiscoText = "Não"
     }
     if(selectedOcorrenciaResult?.fruicaoUsoPublico == true){
      danosUsoPublico = "Danos em áreas construidas de fruição/uso público\n"
    }
     if(selectedOcorrenciaResult?.danosEdificacoes == true){
       danosEdificaoesText = "Danos em edificações\n"
     }
     if(selectedOcorrenciaResult?.outrosDanos == true){
       outrosDanosText = "Outros danos: " + descricaoOutrosDanosText
     }
     if(selectedOcorrenciaResult?.sistemasNaturais == 1){
       sistemasNaturaisText = "Sim: " + descricaoSistemasNaturaisText
     }
     if(selectedOcorrenciaResult?.sistemasNaturais == 2){
       sistemasNaturaisText = "Não sabe/Não Aplicável"
     }
     if(selectedOcorrenciaResult.sistemasNaturais == 0 || selectedOcorrenciaResult.sistemasNaturais == undefined){
       sistemasNaturaisText = "Não"
     }
     if(selectedOcorrenciaResult.naoSabe == true){
       naoSabeText = "Não sabe/Não Aplicável\n"
     }
     if(selectedOcorrenciaResult.tempestade == true){
       tempestadeText = "Tempestade: " + descricaoTempestadeText
     }
     if(selectedOcorrenciaResult?.sismos == true){
       sismosText = "Sismos\n"
     }
     if(selectedOcorrenciaResult?.outras == true){
       outrasText = "Outras: " + descricaoOutrasText
     }
     if(selectedOcorrenciaResult?.medidasMetigacao == 2){
       medidasMetigacaoText = "Não sabe/Não Aplicável"
     }
     if(selectedOcorrenciaResult?.medidasMetigacao == 1){
       medidasMetigacaoText ="Sim"
     }
     if(selectedOcorrenciaResult?.medidasMetigacao == 0 || selectedOcorrenciaResult.medidasMetigacao == undefined){
       medidasMetigacaoText = "Não"
     }
  
      const entidade = selectedOcorrenciaResult?.entidade_registo ?? '';
  
  
  
      let tipologiaOcorrenciaText = instabilidadeArribaText + movimentoMassaText + fendaTracaoText + erosaoRecuoText
       + escarpamentoText + rebaixamentoText + galgamentoCosteiroText + protecaoDefesaText + estruturaAderenteText + esporaoText + estruturaProtecaoText
  
       if (tipologiaOcorrenciaText == "") {
        tipologiaOcorrenciaText = "-";
        }
  
      let danosEdificacoesText = danosUsoPublico + danosEdificaoesText + outrosDanosText
      
      if(danosEdificacoesText == ""){
          danosEdificacoesText = "-";
        }
  
      let causasOcorrenciaText = naoSabeText + tempestadeText + sismosText + outrasText
  
      if (causasOcorrenciaText == "") {
        causasOcorrenciaText = "-";
        }
        let obs = ""
        if(selectedOcorrenciaResult?.observacao == ""){
           obs = "-"
        }
        else{
          obs = selectedOcorrenciaResult?.observacao 
        }
  
        let longitudeLatitude = '';
        if (ocorrencia.coord_x && ocorrencia.coord_y) {
          longitudeLatitude = ocorrencia.coord_x.toFixed(6) + " , " + ocorrencia.coord_y.toFixed(6)
        }

        let detecaoOcorrencia = '';

        if(ocorrencia. selectedOcorrenciaResult?.detecaoOcorrencia == 1){
          detecaoOcorrencia = "Aviso/Chamada"
        }
        else{
          detecaoOcorrencia = "Visita Regular"
          
        }

      try {
        postMessage({ type: 'showLoader' }, '*')
  
        const documentoBase64 = await relatorioOcorrencia({
          id: selectedOcorrenciaResult?.id,
          dados: {
           'REGISTO' : '',
           'Data do registo:': selectedOcorrenciaResult?.dataHoraRegistro ? new Date(selectedOcorrenciaResult?.dataHoraRegistro).toLocaleDateString() : '',
           'Técnico:': selectedOcorrenciaResult?.tecnico ?? '',
           'Email:' : email ?? '',
           'Entidade:': entidade ?? '',
  
          'IDENTIFICAÇÃO' : '',
           'Data da ocorrência:': selectedOcorrenciaResult?.dataHoraOcorrencia ? new Date(selectedOcorrenciaResult?.dataHoraOcorrencia).toLocaleDateString() : '',
           'Deteção da ocorrência:': detecaoOcorrencia ?? '',
  
          'LOCALIZAÇÃO' : '',
           'Concelho:': selectedOcorrenciaResult?.concelho?.nome ?? '',
           'ARH :': selectedOcorrenciaResult?.arh?.nome ?? '',
           'Capitania:': selectedOcorrenciaResult?.capitania?.nome ?? '',
           'Praia:': selectedOcorrenciaResult?.praia?.nome ?? '',
           'Referência de local:': selectedOcorrenciaResult?.ref_localizacao ?? '',
           'Longetitude, latitude:':  longitudeLatitude ?? '',
          
          'TIPOLOGIA DA OCORRÊNCIA' : '',
           'Tipologia da Ocorrência:': tipologiaOcorrenciaText ?? '',
      
          'CONSEQUÊNCIAS DA OCORRÊNCIA' : '',
           'Pessoas em risco:': pessoasEmRiscoText ?? '',
           'Danos em edificações:': danosEdificacoesText ?? '',
           
           'Danos em sistemas:': sistemasNaturaisText ?? '',
  
            'CAUSAS DA OCORRÊNCIA' : '',
           'Causas da ocorrência:': causasOcorrenciaText ?? '',
           'MEDIDAS DE MITIGAÇÃO DE RISCO' : '',
           'Medidas de mitigação do risco:' : medidasMetigacaoText ?? '',
           'OBSERVAÇÕES' : '',
           'Observação:': obs ?? '',
            
          },  
          img_mapa: mapImage.replace("data:image/png;base64,", ""),
        });
     
      if (documentoBase64) {
        if (documentoBase64) {
          const link = document.createElement('a')
          link.href = 'data:application/octet-stream;base64,' + documentoBase64
          link.download = `siarl-lista-ocorrencias-${format(new Date(), 'ddMMyyyHHmm')}.pdf`
          link.style.display = 'none'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          postMessage({ type: 'hideLoader' }, '*')
        }
      }
    } catch (error) {
      console.log(error);
      postMessage({ type: 'errorMsg', value: { left: (isMobile? (Number(percentLeftSucessError.split('%')[0])-5)+'%' : percentLeftSucessError) , message: "Verifique os campos obrigatórios." } }, '*');
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }

  };

  const optionsExportar = [
    {
      key: 'exportarFichas',
      text: 'Fichas',
    },
    {
      key: 'exportarResultados',
      text: 'Resultados',
    },
  ]

  useEffect(() => {
    console.log(selectedOcorrenciaResult);
  }, [])

  useLayoutEffect(() => {

    const handleResize = () => {

      if (window.innerWidth <= telaMobile) {
        
        setIsMobile(true);
        ocultaFloatingMenu_e_Mapa();

      }else{

        setIsMobile(false);
        mostraFloatingMenu_e_Mapa();

      }

    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []); 

  const onItemClickMais = (event: any) => {

    elementoClickMais(event);

    onEditItemClick(event);
    onItemClick(event);

    if(event.item.key == "exportarFichas"){
      onItemClickExportar(event);
    }
    

  };

  const optionsMais = [
    {
      key: 'titulo',
      text: 'Registo',
    },
    ...optionsEditar.map(option => ({
      ...option,
      className: 'icon-siarl icon-editar btnIconRegistar',
    })),
    /* {
      key: 'titulo',
      text: 'Opções',
    },
    {
      key: 'openFileDoc',
      text: 'Diretório de documentos',
      className: 'icon-siarl icon-filedoc btnIconRegistar'
    }, */    
    {
      key: 'titulo',
      text: 'Ponto',
    },
    ...optionsPonto.map(option => ({
      ...option,
      className: 'icon-siarl icon-pin btnIconRegistar',
    })),
    {
      key: 'titulo',
      text: 'Área',
    },
    ...optionsArea.map(option => ({
      ...option,
      className: 'icon-siarl icon-area btnIconRegistar',
    })),
    {
      key: 'titulo',
      text: 'Imprimir',
    },
    {
      key: 'imprimirFicha',
      text: 'Fichas',
      className: 'icon-siarl icon-imprimir btnIconRegistar'
    },
    {
      key: 'titulo',
      text: 'Exportar',
    },
    ...optionsExportar.map(option => ({
      ...option,
      className: 'icon-siarl icon-exportar btnIconRegistar',
    })),
  ]

  const openAjuda = () => {
    window.open(URL_AJUDA_OCORRENCIA_PESQUISA_AVANCADA, "_blank");
  }



  return (
    <div className={styles.base}>

      <div className={styles.header}>

        <div>
          <div className={styles.nomeDoModulo}>
            Ocorrências
          </div>
          <div className={styles.tituloDaPagina}>
            {!(SessionStorageService.getItem("novaOcorrencia") != 'true') ? 'Nova ocorrência' : (editMode ? 'Editar Ocorrência' : 'Ocorrência')}
          </div>
        </div>

        <div>
          {!isMobile && canUpdate && (viewMode || editMode) && (SessionStorageService.getItem("novaOcorrencia") != 'true') ? 
        <DropDownButton
            items={optionsEditar}
            className={styles.btnEditar2}
            iconClass="icon-siarl icon-editar"
            onItemClick={onEditItemClick}
            fillMode={'flat'}
            itemRender={itemRender}
            title="Editar"
          ></DropDownButton>
          : ''}

          <button title="Filedoc" className={styles.btnEditar2} onClick={onClickOpenFileDoc} 
          style={{ display: isMobile ? 'none' : '' }} id='openFileDoc'>
            <span className="icon-siarl icon-filedoc"></span>
          </button>

          {!isMobile &&
            <DropDownButton
            items={optionsPonto}
            className={styles.btnEditar2}
            iconClass="icon-siarl icon-pin"
            onItemClick={onItemClick}
            fillMode={'flat'}
            itemRender={itemRender}
            disabled={SessionStorageService.getItem("novaOcorrencia") != 'true' && !editMode }
            title="Gemoetria - Ponto"
          ></DropDownButton>
          }
          {!isMobile &&
          <DropDownButton
            items={optionsArea}
            className={styles.btnEditar2}
            iconClass="icon-siarl icon-area"
            onItemClick={onItemClick}
            fillMode={'flat'}
            itemRender={itemRender}
            disabled={SessionStorageService.getItem("novaOcorrencia") != 'true' && !editMode }

            title="Geometria - Área"
          ></DropDownButton>
          }
          <button className={styles.btnEditar2} onClick={onItemClickImprimir} 
          style={{ display: isMobile ? 'none' : '' }} id='imprimirFicha' title='Imprimir'>
            <span className="icon-siarl icon-imprimir"></span>
          </button>

          <button className={styles.btnImprimir} title='Exportar' onClick={onItemClickExportar}
           style={{ display: isMobile ? 'none' : '' }} id='exportarFicha'>
            <span className="icon-siarl icon-exportar"></span>
          </button>
          
          {isMobile && (
            <>
            <div>
              <div>
                <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
                  <span className="icon-siarl icon-ajuda"></span>
                </button>
                <button onClick={() => {
                    SessionStorageService.setItem("isSubmitActionOcorrencia", "false");
                    mostraFloatingMenu_e_Mapa_e_Oculta_Form()
                    }} className={styles.btnFechar} title="Minimizar">
                    <span className="icon-siarl icon-minimizar"></span>
                </button>     
              </div>
              <div style={{marginTop: "8px" , marginBottom: "8px"}}>
                <DropDownButton
                  items={optionsMais}
                  className={styles.btnMais}
                  iconClass="icon-siarl icon-mais"
                  onItemClick={onItemClickMais}
                  fillMode={'flat'}
                  itemRender={itemRenderMais}
                  title="Mais Opções"
                  style={{marginTop: "0px" , height: "38px" , width: "38px" , verticalAlign: "top"}}
                  
                />
                <button className={styles.btnInfo} onClick={voltar} title="Fechar">
                  <span className="icon-siarl icon-fechar"></span>
                </button>
              </div>
            </div>
            
            </>
          )
          
          }
          {!isMobile && (
            <>
            <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
            <span className="icon-siarl icon-ajuda"></span>
          </button>

          <button className={styles.btnInfo} onClick={voltar} title="Fechar">
            <span className="icon-siarl icon-fechar"></span>
          </button>

          <button onClick={() => {
             SessionStorageService.setItem("isSubmitActionOcorrencia", "false");
             mostraFloatingMenu_e_Mapa_e_Oculta_Form()
             }} className={styles.btnFechar} title="Minimizar">
            <span className="icon-siarl icon-minimizar"></span>
          </button>

            </>
          )

          }
          
          
        </div>

      </div>
      {openModalDescartar ? (
          <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={cancelModalDescartar}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                  <fieldset>
                    <span className="textDescartar">{deleteClicked ? 'Apagar' : 'Cancelar'} registo  </span>
                    <br></br>
                    <span>{deleteClicked ? 'Ao apagar este registo deixará de ter acesso, sendo necessária uma intervenção rigorosa para realizar a sua recuperação.' : 'Ao cancelar este registo irá perder os dados inseridos até ao momento.'}</span>
                      <br></br>
                    <span>{deleteClicked ? 'Tem a certeza que pretende apagar?' : 'Tem a certeza que pretende cancelar?'}</span>
                  </fieldset>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={cancelModalDescartar}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={handleDescartar}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}

      {openRemoveAreaModal ? (
               <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={() => setOpenRemoveAreaModal(false)}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                    <span className="textDescartar">Remover área </span>
                    <br></br>
                    <span>{'Tem a certeza que pretende remover a área selecionada?'}</span>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={() => setOpenRemoveAreaModal(false)}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={removeArea}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}


        
      <Form 
      onSubmitClick={handleSubmit}
        initialValues={{
          ...selectedOcorrenciaResult, indeterminado: selectedOcorrenciaResult?.indeterminado, statusField:selectedOcorrenciaResult?.status,
          detecaoOcorrencia: (!selectedOcorrenciaResult?.detecaoOcorrencia || selectedOcorrenciaResult?.detecaoOcorrencia == 0 ?
            AVISO_CHAMADA : selectedOcorrenciaResult?.detecaoOcorrencia),
            entidade_registo: selectedOcorrenciaResult?.id && selectedOcorrenciaResult?.entidade ? selectedOcorrenciaResult?.entidade?.nome : entidadeUser,
            dataHoraRegistro: selectedOcorrenciaResult?.dataHoraRegistro
            ? new Date(selectedOcorrenciaResult?.dataHoraRegistro)
            : new Date(),
            dataHoraOcorrencia: selectedOcorrenciaResult?.dataHoraOcorrencia
            ? new Date(selectedOcorrenciaResult?.dataHoraOcorrencia)
            : undefined,
            oc_estado :  selectedOcorrenciaResult?.oc_estado ?? ocEstados.find(estado => estado.codigo === 'SUBM') ?? undefined
        } ?? undefined}

        render={(formRenderProps) => (
          <FormElement>
            <TabStrip selected={selected} onSelect={(e) => {handleSelect(e, formRenderProps)}}
            className={`${styles.innerStyleTabContent} ${styles.backgroundWhite} ${viewMode? 'tabsVisualizar' : 'modoEdicao' }`}
            scrollable={isMobile}>
              <TabStripTab title="Identificação" key={"Identificação"}>
                <OcorrenciaTerrenoIdentificacao editMode={editMode} formRenderProps={formRenderProps}/>
              </TabStripTab>
              <TabStripTab title="Detalhes" key={"Detalhes"} >
                <OcorrenciaTerrenoDetalhe viewMode={viewMode} tipologiaValidator={tipologiaValidator} onCheckboxChangeInstabilidade={handleCheckboxChangeInstabilidade} onCheckboxChangeErosao={handleCheckboxChangeErosao} onCheckboxChangeGalmento={handleCheckboxChangeGalmento} onCheckboxChangeProtecao={handleCheckboxChangeProtecao} formRenderProps={formRenderProps}/>
              </TabStripTab>
              <TabStripTab title="Documentos" key={"Documentos"}>
                <DocumentoListagem pageName='ocorrencias' viewMode={viewMode} formRenderProps={formRenderProps} />
              </TabStripTab>
              <TabStripTab title="Resumo" key={"Resumo"}>
                <OcorrenciaTerrenoConclusao selectedOcorrenciaResult={selectedOcorrenciaResult} />
              </TabStripTab>
              {!isMobile &&
              <TabStripTab title="Associação" key={"Associação"} >
                <AssociacaoListagem viewMode={viewMode} pageName='ocorrencias' />
              </TabStripTab>
               }
            </TabStrip>

            {!viewMode ?
            <div className={`${styles.footer} ${styles.justify_multiple_children}`}>
              <div className={styles.botoesBrancosFooter}>
                <Button
                  type="button"
                  className={styles.btnSeguinte}
                  onClick={handleOpenModalDescartar}
                  title='Cancelar'
                >
                  <span className="icon-siarl icon-fechar"></span>
                  <span className={styles.rotulo}>
                    {' '}&nbsp;{' '}
                    Cancelar
                  </span>
                </Button>
                <Button
                  className={styles.btnAnterior}
                  disabled={selected === 0}
                  onClick={(e) => {
                    if (selected !== 0) {
                      SessionStorageService.setItem("isSubmitActionOcorrencia", "false");
                      setSelected(selected - 1);
                      e.preventDefault();
                    }
                  }}
                  title='Anterior'
                >
                  <span className="icon-siarl icon-anterior"></span>
                  <span className={styles.rotulo}>
                    {' '}&nbsp;{' '}
                    Anterior
                  </span>
                </Button>
                <Button
                  className={styles.btnSeguinte}
                  disabled={selected === TOTAL_TABS}
                  onClick={(e) => {
                    SessionStorageService.setItem("isSubmitActionOcorrencia", "false");
                    setTabSelection(false);
                    formRenderProps.onSubmit(e);
                  }}
                  title='Seguinte'
                >
                  <span className={styles.rotulo}>
                    Seguinte
                    {' '}&nbsp;{' '}
                  </span>
                  <span className="icon-siarl icon-seguinte"></span>
                </Button>
              </div>
             {selected === 0 && !editMode ? (
              <Button
                className={styles.btnDisabled}
                disabled={selected === 0 && !editMode}
                onClick={(e) => {
                  setEditMode(false);
                  setViewMode(true);
                  SessionStorageService.setItem("isSubmitActionOcorrencia", "true");
                  SessionStorageService.setItem('ocorrenciaSubmetida', 'true')
                  formRenderProps.onSubmit(e);
                }}
                title={editMode ? 'Gravar' : 'Submeter'}
              >
                <span className="icon-siarl icon-submeter btnIconRegistar btnDisabled"></span>
                <span className={styles.rotulo}>
                  {' '}&nbsp;{' '}
                  {editMode ? 'Gravar' : 'Submeter'}
                </span>
              </Button>
            ) : (

               <Button
                className={styles.btnSubmeter}
                onClick={(e) => {
                  setEditMode(false);
                  setViewMode(true);
                  SessionStorageService.setItem("isSubmitActionOcorrencia", "true");
                  SessionStorageService.setItem('ocorrenciaSubmetida', 'true')
                  formRenderProps.onSubmit(e);
                }}
                title={editMode ? 'Gravar' : 'Submeter'}
              >
                <span className="icon-siarl icon-submeter btnIconRegistar"></span>
                <span className={styles.rotulo}>
                  {' '}&nbsp;{' '}
                  {editMode ? 'Gravar' : 'Submeter'}
                </span>
              </Button>
            )}
            </div>
            : ''}
          </FormElement>
        )}
      />
    </div>
  )
}