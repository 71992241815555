import { useEffect, useState } from 'react'

import { Dialog } from '@progress/kendo-react-dialogs'
import { ComboBox, MultiSelect } from '@progress/kendo-react-dropdowns'
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from '@progress/kendo-react-form'
import { Button } from '@progress/kendo-react-buttons'
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridPageChangeEvent,
} from '@progress/kendo-react-grid'
import {
  entidades,
  fillEntidadesAsync,
  getIntervencaoSelected,
} from '../../services/utils'
import styles from '../../styles/dominiohidrico/DominioHidrico.module.scss'
import { PagerTargetEvent } from '@progress/kendo-react-data-tools'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileEdit } from '@fortawesome/free-solid-svg-icons'
import { filterBy } from "@progress/kendo-data-query";

type Props = {
  dataCombo: any
  textField : any
  title1 : any 
  title2 : any 
  label : any
  dataGrid?: any
  setDataGrid?: any
  initialValues?: any
  viewMode?: any

}

type Name = {
  name: string
}

type PageState = {
  skip: number
  take: number
}


export default function AssociacaoEntidadeIntervencao({dataCombo , viewMode, textField, title1, title2, label, dataGrid, setDataGrid, initialValues} : Props) {

  const handleSubmitAddAssociacao = (event: any) => {
      const gridData = multiData.map((data: any) => ({
      Entidade: data,
      Intervencao: getIntervencaoSelected()?.designacao?? '',
    }))


    setDataGrid(gridData)

    setData(gridData)
  }
  const [lstEntidades, setLstEntidades] = useState<any>('');

  useEffect(() => {
    if (initialValues) {
      setLstEntidades(initialValues);
    }
  }, [initialValues]);

  const initialDataState: PageState = { skip: 0, take: 10 }

  const [page, setPage] = useState<PageState>(initialDataState)

  const [pageSizeValue, setPageSizeValue] = useState<
    number | string | undefined
  >()

  const [entidadeData, setEntidadeData] = useState(entidades ? entidades.slice() : []);

  const handlePageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent
    const take = event.page.take

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value)
    }

    setPage({
      ...event.page,
      take,
    })
  }

  interface EditCommandCellProps extends GridCellProps {
    enterEdit: (item: any) => void
    deleteItem: (item: any) => void
    pointOnMap: (item: any) => void
  }

  const EditCommandCell = (props: EditCommandCellProps) => {
    return (
      <td className="k-command-cell">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            margin: '0 0 0 8px',
          }}
        >
          {' '}
          <span></span>
          <div role="button" onClick={() => props.enterEdit(props.dataItem)}>
            <FontAwesomeIcon icon={faFileEdit} />
          </div>
        </div>
      </td>
    )
  }

  const editField = 'inEdit'

  const [data, setData] = useState<any>()

  const [multiData, setMultiData] = useState([])

  async function HandleOnChangeMultiData(e: any) {
    if (e?.value) {
      setMultiData(e?.value) 
    }
  }

  const [openForm, setOpenForm] = useState<boolean>(false)
  const [editItem, setEditItem] = useState<any>({ id: undefined })

  const enterEdit = (item: any) => {
    setOpenForm(true)
    setEditItem(item)
  }
  const pointOnMap = (item: any) => {
    console.log(item)
    return
  }
  const deleteItem = (item: any) => {
    console.log(item)
    return
  }

  const MyEditCommandCell = (props: GridCellProps) => (
    <EditCommandCell
      {...props}
      enterEdit={enterEdit}
      pointOnMap={pointOnMap}
      deleteItem={deleteItem}
    />
  )

  const filterChange = (event: any) => {
    setEntidadeData(filterBy(entidades.slice(), event.filter));
  };

  return (
    <>
      <div className={styles.fields}>
        <div style={{ flex: 5 }}>
          <div>
            <Field
              name="lstEntidades"
              label="Entidades"
              component={MultiSelect}
              textField={textField}
              data={entidadeData}
              onChange={HandleOnChangeMultiData}
              filterable={true}
              onFilterChange={filterChange}
              disabled={viewMode}
            />
          </div>
        </div>
      </div>
    </>
  )
}
