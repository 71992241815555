import { useEffect, useState } from "react"
import { v4 as uuidv4 } from 'uuid';
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faRemove } from "@fortawesome/free-solid-svg-icons";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Dialog } from '@progress/kendo-react-dialogs'
import { DatePicker, DateTimePicker, MultiViewCalendar, MultiViewCalendarProps } from "@progress/kendo-react-dateinputs";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import styles from '../../styles/intervencao/Intervencao.module.scss';
import SessionStorageService from "../../services/SessionStorageService";
import { useAcaoStore } from "../../stores/acao";
import { post } from "../../services/utils";
import { GET_EXECUCAO_ACAO_BY_ID_ACAO } from "../../utils/constants";
import { format, parse, parseISO } from "date-fns";


  type IProps = {
    formRenderProps?: any,
    viewMode: boolean
  }

  interface FormData {
    id?: any;
    data_fatura?: Date;
    numero_fatura?: any;
    descricao?: any;
    valor_total?: any;
  }


export function IdentificacaoAcaoExecucao({ formRenderProps, viewMode }: IProps) {
  const [openModalAcaoExecucao, setOpenModalAcaoExecucao] = useState<any>()
  const [openModalDescartar, setOpenModalDescartar] = useState<any>()
  const [selectedGridItemId, setSelectedGridItemId] = useState<string | null>(null);
  const [formData, setFormData] = useState<FormData>({});
  const [gridData, setGridData] = useState<FormData[]>([]);
  const [itemToRemove, setItemToRemove] = useState<any>();
  const [page, setPage] = useState({ skip: 0, take: 10 });

  
  const getExecucaoAcao = async (data: any) =>
    await post<any, any>(`${GET_EXECUCAO_ACAO_BY_ID_ACAO}`, data)

  const getResumoExecucao = async (data: any) =>
    await post<any, any>(`${GET_EXECUCAO_ACAO_BY_ID_ACAO}`, data)

  const selectedAcaoResult: any = useAcaoStore(
    (state) => state.selectedAcaoResult
  )

  useEffect(() => {
    SessionStorageService.setItem("dataAcaoExecucao", gridData);
    SessionStorageService.setItem("dataResumoExecucao", resumoData);
  });
  
  
  useEffect(() => {
    resumoExecucaoPorAno();
  }, [gridData]);

  useEffect(() => {
    if (selectedAcaoResult?.id) {
      const fetchData = async () => {
          const data = await getExecucaoAcao(selectedAcaoResult?.id);
           resumoData = await getResumoExecucao(selectedAcaoResult?.id);
           
           const formattedData = data.map((item: any) => ({
            ...item,
            data_fatura: item.data_fatura ? format(parseISO(item.data_fatura), 'dd/MM/yyyy') : undefined
          }));
    
          setGridData(formattedData);

      };
      fetchData();
  }
    
  }, []);

  const openModal = (e : any) => {
      setFormData({
        id: "",
        data_fatura: undefined,
        numero_fatura: "",
        descricao: "",
        valor_total: "",
        });
        setSelectedGridItemId(null)
      e.preventDefault();
      setOpenModalAcaoExecucao(true)
  }
  
  const cancelModal = () => {
    setOpenModalAcaoExecucao(false)
  }
  
  const handleAddItem = (item: any) => {
    const newItem = { 
        ...item, 
        id: uuidv4(),
        data_fatura: item?.data_fatura ? format(item.data_fatura, 'dd/MM/yyyy') : undefined, 
    };
    setGridData([...gridData, newItem]);
};

  const handleUpdateItem = (id : any, updatedItem : any) => {
      setGridData(prevData => prevData.map(item => item.id === id ? { ...updatedItem, id } : item));
  };
  
  const removeExecucao: any = () => {
    const item = itemToRemove;
    const dataExecucaoAcao = gridData.filter(x => !(x.id == item.id)).slice();
    setGridData(dataExecucaoAcao);
    setOpenModalDescartar(false);
  }
  
  const confirmRemoveExecucao = (item: any) => {
    setItemToRemove(item);
    setOpenModalDescartar(true);
  }

  const isUUID = (str: string) => {
    const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidPattern.test(str);
  };

  
const handleSubmitModal = (e : any) => {

    if (selectedGridItemId) {
        handleUpdateItem(selectedGridItemId, formData);
    } else {
        const newId = uuidv4();
        handleAddItem({ ...formData, id: newId });
        setSelectedGridItemId(newId);
    }
  
    setOpenModalAcaoExecucao(false);
  };

  const handleRowClick = (dataItem : any) => {
  
    setSelectedGridItemId(dataItem?.id); 

    const parsedDate = dataItem.data_fatura ? parse(dataItem.data_fatura, 'dd/MM/yyyy', new Date()) : undefined;

   
      setFormData({
          data_fatura: parsedDate ?? undefined,
          numero_fatura: dataItem.numero_fatura ?? undefined,
          descricao: dataItem.descricao ?? undefined,
          valor_total: dataItem.valor_total ?? undefined,
      });
  
   setOpenModalAcaoExecucao(true);
  };
  
  const handlePageChange2 = (event : any) => {
    setPage({ skip: event.page.skip, take: event.page.take });
  };
  
  const handleFormChange = (event : any) => {
    
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const CustomCalendar = (props: MultiViewCalendarProps) => {
    return <MultiViewCalendar {...props} views={1} />
  }

  const resumoExecucaoPorAno = () => {
    const resumo = gridData.reduce((acc : any, item : any) => {
      const ano = item.data_fatura ? new Date(item.data_fatura.split('/').reverse().join('-')).getFullYear() : null;
      if (ano) {
        if (!acc[ano]) {
          acc[ano] = { ano, numero_registos: 0, valor: 0 };
        }
        acc[ano].numero_registos += 1;
        acc[ano].valor += parseFloat(item.valor_total) || 0;
      }
      return acc;
    }, {});

    return Object.values(resumo);
  };

  let resumoData = resumoExecucaoPorAno();

  
  return (
    <>
      <div className={`${styles.form} ${styles.removeScrollDuplicado}`}>

        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>
            Execução da ação
            </span>
          </legend>
          {!viewMode  ? (

          <div className={styles.espacoVerticalFieldSet}>
          <button
              title="Adicionar novo"
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary buttonAdicionarNovo btnPrimaryColor"
              onClick={(e) => {
                openModal(e);
              }}
              // disabled={viewMode || !selectedIntervencaoResult?.id}
              >
              <span className="icon-siarl icon-registar k-button-icon"></span>{' '}
              &nbsp;{' '}
              Inserir
            </button>
         </div>
        ) : ""}

        <div className={styles.espacoVerticalFieldSet}>
        <div className={styles.scrollableArea}>
          <Grid
            className={'customGrid'}
            data={gridData.slice(page.skip, page.take + page.skip)}
            pageable={{
              buttonCount: 5,
              info: false,  
              type: 'numeric',
              pageSizes: false,  
              previousNext: true,
            }}
            rowHeight={50}
            total={gridData.length}
            skip={page.skip}
            take={page.take}
            onPageChange={handlePageChange2}

          >
            {!viewMode ?

            <GridColumn width={56}
                        cell={(props) => (
                          <td className="k-command-cell">
                            <div style={{cursor:'pointer'}} className={'gridCommand'}>
                              <div
                                role="button"
                                className={styles.gridCommandIconButton}
                                onClick={() => handleRowClick(props.dataItem)}
                              >
                                <FontAwesomeIcon icon={faInfoCircle} title="Editar associação" />
                              </div>
                              <div style={{cursor:'pointer'}}
                                role="button"
                                onClick={() => confirmRemoveExecucao(props.dataItem)}
                                className={styles.gridCommandIconButton}
                              >
                                <FontAwesomeIcon icon={faRemove} title="Remover associação" />
                              </div>
                            </div>
                          </td>
                        )}
                      />
              : ''}
            <GridColumn field="data_fatura" className={styles.centerAlign} width="110px" title="Data da fatura" />
            <GridColumn field="numero_fatura" className={styles.centerAlign} width="125px" title="Referência do documento" />
            <GridColumn field="descricao" className={styles.centerAlign} width="80px" title="Descrição" />
            <GridColumn field="valor_total"className={styles.centerAlign}  width="120px" title="Valor" />
           
          </Grid>
        </div>
        </div>


        {openModalAcaoExecucao ? (
          <Dialog
            title="Associar execução à ação"
            className="ModalAcaoExecucao"
            onClose={cancelModal}
          >
            
            <Form
              onSubmit={handleSubmitModal}
              initialValues={formData}
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 400 }}>
                  <div className={`${styles.form} ${styles.removeScrollDuplicado}`}>
                    
                     
                      <div className={styles.fields}>
                        <div>
                        <Field
                          name="data_fatura" 
                          onChange={handleFormChange}  
                          label="Data da fatura" 
                          component={DatePicker}  
                          format="dd/MM/yyyy"
                          calendar={CustomCalendar}
                          disabled={viewMode}  
                        />
                        </div>
                      </div>
                      <div className={styles.fields}>
                        <div>
                        <Field
                          name="numero_fatura" 
                          onChange={handleFormChange}  
                          label="Referência do documento" 
                          component={Input}  
                          disabled={viewMode}  
                        />
                      </div>
                    </div>
                  <div className={`${styles.fields}`}>
                  <div>
                      <Field
                          name="descricao"
                          label="Descrição"
                          component={Input}                     
                          disabled={viewMode}  
                          onChange={handleFormChange}
                          />
                  </div>
               </div>      
                <div className={`${styles.fields}`}>
                  <div>
                      <Field
                          name="valor_total"
                          label="Valor"
                          component={Input}
                          type="number"
                          onChange={handleFormChange}
                          />
                  </div>
               </div>  
               
                  <div className="k-form-buttons btnsModal">
                  <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                      onClick={cancelModal}
                    >
                      Cancelar
                    </button>
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                      onClick={formRenderProps.onSubmit}
                    >
                      Submeter
                    </button>
                  </div>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}

<fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>
              Resumo da execução
            </span>
          </legend>
          <div className={styles.espacoVerticalFieldSet}>
            <div className={styles.scrollableArea}>
              <Grid
                className={'customGrid'}
                data={resumoData}
                rowHeight={50}
              >
                <GridColumn field="ano" className={styles.centerAlign} title="Ano" width="100px" />
                <GridColumn field="numero_registos" className={styles.centerAlign} title="Número de Registos" width="150px" />
                <GridColumn field="valor" className={styles.centerAlign} title="Valor Total" width="150px" />
              </Grid>
            </div>
          </div>
        </fieldset>

  {openModalDescartar ? (
          <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={() => setOpenModalDescartar(false)}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                  <fieldset>
                    <span className="textDescartar">{'Apagar'} associação  </span>
                    <br></br>
                    <span>{'Tem a certeza que pretende apagar o associação?'}</span>
                  </fieldset>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={() => setOpenModalDescartar(false)}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={removeExecucao }
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}
        </fieldset>
        </div>
    </>
  )

}


