import { DateTimePicker } from '@progress/kendo-react-dateinputs'
import { ComboBox } from '@progress/kendo-react-dropdowns'
import { Field, FormRenderProps } from '@progress/kendo-react-form'
import { Checkbox, Input, RadioGroup } from '@progress/kendo-react-inputs'
import { useEffect, useState } from 'react'
import {
  GetUserNameTecnico,
  arhs,
  capitania,
  concelhos,
  coordenadasSelected,
  distritos,
  entidades,
  fillArhsAsync,
  fillCapitaniaAsync,
  fillConcelhosAsync,
  fillDistritosAsync,
  fillFreguesiasAsync,
  fillNuts1Async,
  fillNuts2Async,
  fillNuts3Async,
  fillPraiasAsync,
  freguesias,
  get,
  getOcorrenciaSelected,
  massaagua,
  nuts1,
  nuts2,
  nuts3,
  ocEstados,
  praias,
  setOcorrenciaSelected,
  tecnico,
  useCombos,
  userLogged,
} from '../../../services/utils'
import { useOcorrenciaStore } from '../../../stores/ocorrencias'
import styles from '../../../styles/ocorrencia/ConsultarOcorrenciaForm.module.scss'
import { AUTH_LOCALSTORAGE, AVISO_CHAMADA, CURRENT_TIMEZONE, GET_OCORRENCIA, VISITA } from '../../../utils/constants'
import {  requiredFieldValidator } from '../../../validations/ocorrencia'
import ValidatedIdentificacaoCheckbox from '../fields/ValidatedIdentificacaoCheckBox'
import ValidatedIdentificacaoDateTimePicker from '../fields/ValidatedIdentificacaoDateTimePicker'
import ValidatedIdentificacaoInput from '../fields/ValidatedIdentificacaoInput'
import SessionStorageService from '../../../services/SessionStorageService'
import { filterBy } from '@progress/kendo-data-query'
import moment from 'moment'
import { click } from '@testing-library/user-event/dist/click'
type IProps = {
  formRenderProps?: FormRenderProps
  editMode?: boolean
}
export function OcorrenciaTerrenoIdentificacao({formRenderProps, editMode}:IProps) {
  const [freguesiasFiltred, setFreguesiasFiltred] = useState<any>()

  const selectedOcorrenciaResult: any = useOcorrenciaStore(
    (state) => state.selectedOcorrenciaResult
  )
  const selectedOcorrenciaResultId = useOcorrenciaStore(
    (state) => state.selectedOcorrenciaResultId
  )

  const selectOcorrenciaResult = useOcorrenciaStore(
    (state) => state.selectOcorrenciaResult
  )

  const addIdentificacaoTabValidatedField = useOcorrenciaStore(
    (state) => state.addIdentificacaoTabValidatedField
  )
  const [disableDateTimePicker, setDisableDateTimePicker] = useState(false)
  const [dateTimeValue, setDateTimeValue] = useState(undefined)
  const [isChecked, setIsChecked] = useState(false)

  type combosKeys= 'massaagua' | 'praias';

  const [combos, setCombos] = useState<any>({
    massaagua,
    praias
  })


  const setDataOption: Record<combosKeys, { setFn: any, data: any[] }> = {
    massaagua: {
      setFn: setCombos,
      data: massaagua
    },
    praias: {
      setFn: setCombos,
      data: praias
    }
  };

const filterCombo_Multiselect = (e: any, tipoCombo: combosKeys) => {
    const { setFn, data } = setDataOption[tipoCombo];
    setFn({
        ...combos,
        [tipoCombo]: filterBy(data?.slice()??[], e.filter)
    }
    );
}

 useEffect(() => {
    
     if(!nuts1 ){
      fillNuts1Async();
      }
     if(!nuts2) {
      fillNuts2Async();
     }
     if(!nuts3){
      fillNuts3Async();
     }
     if(!distritos){
      fillDistritosAsync();
     }
     if(!concelhos){
      fillConcelhosAsync();
     }
     if(!freguesias){
      fillFreguesiasAsync();
     }
      if(!arhs){
      fillArhsAsync();
     }
      if(!capitania){
      fillCapitaniaAsync();
     }
     if(!praias){
      fillPraiasAsync();
     }
       }, []);

  const handleCheckboxChange = () => {    
      
    setDisableDateTimePicker(!disableDateTimePicker)

    if (disableDateTimePicker) {
      setDateTimeValue(undefined)
    }
    setIsChecked(!isChecked)
    addIdentificacaoTabValidatedField({ dataHoraOcorrencia: !isChecked })
  }

  const handleClickCheckBox = (formRenderProps: FormRenderProps) => {
    if(!isChecked){
    formRenderProps.onChange("dataHoraOcorrencia", { value: undefined })

      }
  }

  useEffect(() => {
    if (selectedOcorrenciaResult?.indeterminado || SessionStorageService.getItem("ocorrenciaIndeterminado")) {
      handleCheckboxChange()
      setIsChecked(true);
    }
  }, [])

  useEffect(() => {
    if (!tecnico) {
      userLogged()
    }
  }, [tecnico])

  const concelhoSelectChange = (item: any) => {
    if (!item) {
      setFreguesiasFiltred(freguesias)
      return
    }
    setFreguesiasFiltred(
      freguesias.filter((x) => x.concelho_cod == item.codigo)
    )
  }

  const changeProcessoFiledoc = (e: any) => {
    const ocorrenciaSelectedLocal = {...getOcorrenciaSelected(), filedoc_cod: e?.value}
    setOcorrenciaSelected(ocorrenciaSelectedLocal);
  }

    let coordenadas_formatadas;
    const dataAtual = new Date();
    if (coordenadasSelected) {
      const coordenadas = coordenadasSelected.split(', ');
      const coordenada_x = parseFloat(coordenadas[0]).toFixed(6);
      const coordenada_y = parseFloat(coordenadas[1]).toFixed(6);
      coordenadas_formatadas  = coordenada_x + ', ' + coordenada_y;
    }

  return (
    <div className={`${styles.form} ${styles.removeScrollDuplicado}`}>

       <span style={{ marginLeft: '08px' }}>Os campos com simbolo * (asterisco) são de preenchimento obrigatório</span>

      <fieldset>
        <legend>
          <span className={styles.fieldsetTitulo}>Registo</span>
        </legend>

        <div className={styles.espacoVerticalFieldSet}>
          <div className={styles.fields}>
            <div>
              <Field
                name="id"
                label="ID"
                component={ValidatedIdentificacaoInput}
                data={selectedOcorrenciaResultId ?? undefined}
                disabled
              />
            </div>
            <div>
              <Field
                name="filedoc_cod"
                label="Nº Processo APA"
                component={Input}
                onChange={changeProcessoFiledoc}
                data={selectedOcorrenciaResultId ?? undefined}
                disabled={SessionStorageService.getItem("novaOcorrencia") != 'true' && !editMode }
              />
            </div>
            <div>
            <Field
              name="oc_estado"
              label="Estado"
              disabled={SessionStorageService.getItem("novaOcorrencia") != 'true' && !editMode }
              component={ComboBox}
              textField={'designacao'}
              data={ocEstados}
            />
          </div>
          </div>
          <div className={styles.fields}>
          <div>
              <Field
                name="dataHoraRegistro"
                label="Data/hora do Registo"
                format={'dd/MM/yyyy HH:mm'}
                disabled
                placeholder=""
                component={ValidatedIdentificacaoDateTimePicker}
              />
            </div>
            <div>
              <Field
                name="tecnico"
                label="Técnico"
                component={ValidatedIdentificacaoInput}
                data={tecnico}
                disabled
              />
            </div>
            <div>
            <Field
              name="entidade_registo"
              label="Entidade"
              disabled
              component={Input}
            />
          </div>
          </div>
        
        </div>
      </fieldset>

      <fieldset>
        <legend>
          <span className={styles.fieldsetTitulo}>Identificação</span>
        </legend>

        <div className={styles.espacoVerticalFieldSet}>
          <div className={styles.fields}>
            {isChecked ? (
              <div>
                <Field
                  name="dataHoraOcorrencia"
                  label="Data/hora da Ocorrência"
                  format={'dd/MM/yyyy HH:mm'}
                  disabled
                  value={dateTimeValue}
                  key={'dataHoraOcorrencia'}
                  component={DateTimePicker}
                />
              </div>
            ) : (
              <div>
                <Field
                  name="dataHoraOcorrencia"
                  label="Data/hora da Ocorrência *"
                  format={'dd/MM/yyyy HH:mm'}
                  max={dataAtual}
                  value={dateTimeValue}
                  key={'dataHoraOcorrenciaValidacao'}
                  validator={requiredFieldValidator}
                  component={ValidatedIdentificacaoDateTimePicker}
                  disabled={SessionStorageService.getItem("novaOcorrencia") != 'true' && !editMode }
                />
              </div>
            )}
            <div>
              <Field
                name="indeterminado"
                checked={isChecked}
                onChange={handleCheckboxChange}
                onClick={() => {
                  if (formRenderProps) {
                    handleClickCheckBox(formRenderProps);
                  }
                }}                label="Data indeterminada"
                title="Marque esta caixa se não souber a data exata da ocorrência"
                component={Checkbox}
                className="checkbox-indeterminado"
                disabled={SessionStorageService.getItem("novaOcorrencia") != 'true' && !editMode }

              />
            </div>
          </div>

          <div style={{ marginTop: 24 }}>
            <div
              className={styles.fieldsetSubTitulo}
              style={{ marginLeft: '12px' }}
            >
              Deteção da ocorrência
            </div>
            <div className={styles.fields} style={{ marginLeft: '12px' }}>
              <div>
                <Field
                  id={'detecaoOcorrencia'}
                  name={'detecaoOcorrencia'}
                  layout={'vertical'}
                  component={RadioGroup}
                  data={[
                    { label: 'Aviso/Chamada', value: AVISO_CHAMADA },
                    { label: 'Visita Regular', value: VISITA },
                  ]}
                  disabled={SessionStorageService.getItem("novaOcorrencia") != 'true' && !editMode }
                />
              </div>
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset>
        <legend>
          <span className={styles.fieldsetTitulo}>Localização</span>
        </legend>

        <div className={styles.espacoVerticalFieldSet}>
          <div className={styles.fieldsetSubTitulo}>
            {coordenadas_formatadas ?? ''}
          </div>

          <div className={styles.fields}>
            <div>
              <Field
                name="nuts1"
                label="NUTS I"
                disabled
                component={ComboBox}
                textField={'nome'}
                data={nuts1}
              />
            </div>
            <div>
              <Field
                name="nuts2"
                label="NUTS II"
                disabled
                component={ComboBox}
                textField={'nome'}
                data={nuts2}
              />
            </div>
            <div>
              <Field
                name="nuts3"
                label="NUTS III"
                disabled
                component={ComboBox}
                textField={'nome'}
                data={nuts3}
              />
            </div>
          </div>

          <div className={styles.fields}>
            <div>
              <Field
                name="distrito"
                label="Distrito"
                disabled
                component={ComboBox}
                textField={'nome'}
                data={distritos}
              />
            </div>
            <div>
              <Field
                name="concelho"
                label="Concelho"
                disabled
                component={ComboBox}
                onChange={(e) => concelhoSelectChange(e.value)}
                textField={'nome'}
                data={concelhos}
              />
            </div>
            <div>
              <Field
                name="freguesia"
                label="Freguesia"
                textField={'nome'}
                data={freguesias}
                disabled
                component={ComboBox}
              />
            </div>
          </div>

          <div className={styles.fields}>
            <div>
              <Field
                name="arh"
                label="ARH"
                component={ComboBox}
                textField={'nome'}
                data={arhs}
                disabled
              />
            </div>
            <div>
              <Field
                name="capitania"
                label="Capitania"
                component={ComboBox}
                textField={'nome'}
                data={capitania}
                disabled
              />
            </div>
            <div>
              <Field
                name="massaAgua"
                label="Massa de água"
                component={ComboBox}
                textField={'nome'}
                data={combos?.massaagua}
                filterable={true}
                onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'massaagua')}}
                disabled

              />
            </div>
          </div>
          <div className={styles.fields}>
            <div>
              <Field
                name="praia"
                label="Praia (se aplicável)"
                component={ComboBox}
                textField={'nome'}
                data={combos?.praias}
                filterable={true}
                onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'praias')}}
                disabled={SessionStorageService.getItem("novaOcorrencia") != 'true' && !editMode }

              />
            </div>
            <div>
              <Field name="ref_localizacao" label="Local" component={Input}  disabled={SessionStorageService.getItem("novaOcorrencia") != 'true' && !editMode }


 />
            </div>
          </div>
        </div>
      </fieldset>

     
    </div>
  )
}
