import { Button, DropDownButton } from '@progress/kendo-react-buttons'
import { PagerTargetEvent } from '@progress/kendo-react-data-tools'
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form'
import { GridPageChangeEvent } from '@progress/kendo-react-grid'
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from '@progress/kendo-react-layout'
import { MouseEvent as ReactMouseEvent, useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { GetUserNameTecnico, b64toBlob, coordenadasSelected, entidades, estadosTitulo, get, getUsoSoloSelected, handleGenericError, hasPermission, massaagua, nuts1, post, put, refreshPesquisaSearch, setCoordenadasSelected, setIntervencaoSelected, setusoSoloSelected, tipoEntidades, tipossProcesso, useCombos } from '../../services/utils'
import { useUsoDoSoloStore } from '../../stores/usoDoSolo'
import styles from '../../styles/usodosolo/RegistarUsoDoSoloForm.module.scss'
import {
  AUTH_LOCALSTORAGE,
  GET_URL_FROM_FILE_DOC,
  GET_US_TITULO,
  GET_US_TITULO_BY_ID,
  IMPORT_GEOM_USOSOLO,
  LAYER_INDEX_US_TITULO,
  OPERATIONAL_LAYER,
  PERMISSION_ATUALIZAR_US_TITULO,
  PERMISSION_USOSOLO_PERFIL_COMPLETO_EDICAO,
  PERMISSION_USOSOLO_PERFIL_COMPLETO_LEITURA,
  PERMISSION_USOSOLO_PERFIL_INTERMEDIO_EDICAO,
  PERMISSION_USOSOLO_PERFIL_TOTAL,
  RELATORIO_TITULO,
  REMOVER_PONTO_US_TITULO,
  SAVE_NEW_US_TITULO,
  TIPO_USOS_SOLO_SILIAMB,
  UPDATE_US_TITULO,
  URL_AJUDA_USOS_SOLO_TITULO,
  US_TITULOS_POINT_NAME,
  clearLayers,
} from '../../utils/constants'
import { AssociacaoListagem } from '../associacao/Associacao'
import { DocumentoListagem } from '../documentos/registar/documento/documento'
import IdentificacaoSoloForm from './registar/Identificacao'
import ImportarGeometriaComponent from '../apoiogestao/registar/ImportarGeometriaForm'
import { Dialog } from '@progress/kendo-react-dialogs'
import { FilterDescriptor, filterBy } from '@progress/kendo-data-query';
import SessionStorageService from '../../services/SessionStorageService'
import useClearMap from '../../hooks/useClearMap'
import html2canvas from 'html2canvas'
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais'
import { ComboBox } from '@progress/kendo-react-dropdowns'
import ReactGA from 'react-ga4';
type IProps = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
}

type PageState = {
  skip: number
  take: number
}

let _pdfExport: any
const exportExcel = () => {
  _export.save()
}
let _export: any
const exportPDF = () => {
  _pdfExport.save()
}
const TOTAL_TABS = 3

const initialDataState: PageState = { skip: 0, take: 10 }
type UsoDoSoloForm = {
  id: number
  designacao: string
  id_processo: string
  utilizador_id: number
  estado_titulo_id?: number
  utilizador?: unknown
  data_registo: string
  data_entrada_vigor: string
  data_expiracao: string
  data_alteracao_licenciamento: string
  distrito_cod: string
  concelho_cod: string
  freguesia_cod: string
  nuts3_cod: string
  nuts2_cod: string
  nuts1_cod: string
  arh_cod: string
  capitania_cod: string
  praia_cod: string
  geom_imported: unknown
  codigo_processo: string
  codigo_utilizacao: string
  categoria: string
  tipo_processo_id: number
  tipo_utilizacao_id: number
  observacoes: string
  //local: string
  nif_requerente: string
  nome_requerente: string
  geom?: unknown
  isTitulo: boolean
  status: boolean,
  estado_id: number
  flatCoordinates: string,
  filedoc_cod: string,
  data_emissao: string,
  url_titulo: string
}
export default function RegistarUsoDoSoloForm({ onCloseClick }: IProps) {
  const [isMobile, setIsMobile] = useState(false);
  const [selected, setSelected] = useState<number>(0)
  const [page, setPage] = useState<PageState>(initialDataState)
  const [canUpdate, setCanUpdate] = useState(false);
  const [canInsert, setCanInsert] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [percentLeftSucess, setPercentLeftSucess] = useState("22%");
  const [percentLeftMapa, setPercentLeft] = useState("16%");

  
  
  const getUsoDoSolo = async (data: any) =>
  await get<any, any>(`${GET_US_TITULO_BY_ID}/` + data)
  
  const selectedUsoDoSoloResult: any = useUsoDoSoloStore(
    (state) => state.selectedUsoDoSoloResult
    )
    const [formState, setFormState] = useState("formUsoSolo");

    const [openImportModal, setOpenImportModal] = useState<any>()

  const selectedUsoDoSoloResultId: any = useUsoDoSoloStore(
    (state) => state.selectedUsoDoSoloResultId
  )
  const identificacaoTabIsValid = useUsoDoSoloStore(
    (state) => state.identificacaoTabIsValid
  )
  const selectUsoDoSoloResultId = useUsoDoSoloStore(
    (state) => state.selectUsoDoSoloResultId
  )
  const selectUsoDoSoloResult = useUsoDoSoloStore(
    (state) => state.selectUsoDoSoloResult
  )

   const getusoSoloById = async (id: any) =>
    await get<any, any>(`${GET_US_TITULO_BY_ID}/` + id)
  const importGeomUsoSolo = async (data: any) =>
    await post<any, any>(`${IMPORT_GEOM_USOSOLO}`, data)
    const getUrlFileDoc = async (data: any) => await get<any>(`${GET_URL_FROM_FILE_DOC}/` + encodeURIComponent(data));

    const removerPontoTitulo = async (data: any) =>
      await post<any, any>(`${REMOVER_PONTO_US_TITULO}`, data)

  const [pageSizeValue, setPageSizeValue] = useState<
    number | string | undefined
  >()
  const handleSelect = (e: TabStripSelectEventArguments) => {
    if (e.selected > 0 && !selectedUsoDoSoloResult) {
      postMessage({type:"infoMsg", value: {message: "É necessário gravar primeiro."}})
      return;
    }
    setSelected(e.selected)
  }
  const saveUsoDoSolo = async (data: any) =>
    await post<any, any>(`${SAVE_NEW_US_TITULO}`, data)

  const selectMapAction = async(item: any) => {
    if(item.key == "removerPonto"){
      setOpenRemovePontoModal(true)
    }
    if(item.key == "SiarlEditarGeometria"){
      const idUsosSoloTitulo = getUsoSoloSelected()?.id;
      if (!idUsosSoloTitulo) {
        postMessage({type: "infoMsg", value: {message: "É necessário gravar primeiro." } }, "*");
        return;
      }
      const tituloUsoDoSolo = await getUsoDoSolo(idUsosSoloTitulo);

      if (!tituloUsoDoSolo.hasGeom) {
        postMessage({type: "infoMsg", value: {message: "O ponto ainda não foi gravado." } }, "*");
        return;
      }

      //refreshPesquisaSearch("resultadoUsoDoSoloPesquisa", "SiarlUsTituloSearch")
      postMessage(
        { type: 'infoEditGeom',  value: 
        { left: (isMobile? (Number(percentLeftMapa.split('%')[0])-5)+'%' : percentLeftMapa) , 
        message: "Selecione o novo local do ponto", geom : 'ponto'          
        } },
        '*'
      )
      const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.contentWindow?.postMessage({ type: 'SiarlGeoPortalAdicionarPonto', value: 'false' }, '*')
    } else {
    
      const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.contentWindow?.postMessage({ type: item.key, value: getUsoSoloSelected()?.id, editGpLayer: US_TITULOS_POINT_NAME }, '*')
    }
    setpontoAtivo(true)
  }

  const [pontoAtivo, setpontoAtivo] = useState(false)
  const [openRemovePontoModal, setOpenRemovePontoModal] = useState<any>()


  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/RegistarUsosSolo" });
  }, []);
 
  const DesativarSelecaoPonto = () => {
    setpontoAtivo(false)
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: 'SiarlGeoPortalAdicionarPonto', value: 'false' }, '*')
  }

  const updateUsoDoSolo = async (data: any) =>
    await put<any, any>(`${UPDATE_US_TITULO}`, data)

    const relatorioTitulo = async (data: any) =>
    await post<any, any>(`${RELATORIO_TITULO}`, data)

  const handlePageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent
    const take = event.page.take

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value)
    }

    setPage({
      ...event.page,
      take,
    })
  }
  const isSubmit = () => {
    if (selected === 0 && !identificacaoTabIsValid) {
      return false
    }

    if (selected === TOTAL_TABS) {
      return true
    } else {
      return false
    }
  }
/*
  const importGeo = async (objToSend: any) => {
    postMessage({ type: "showLoader" }, "*");
    if (!objToSend) {
      postMessage({ type: "errorMsg", value: "Não foi possivel salvar" }, "*");
      postMessage({ type: "hideLoader" }, "*");
      return;

    }
    let usoSolo;
    let usoSolo_id;
    const usoSoloSelected = selectedUsoDoSoloResult ?? getUsoSoloSelected();
    if (usoSoloSelected && usoSoloSelected?.id) {
      usoSolo = usoSoloSelected;
      usoSolo_id = usoSolo?.id;
    } else {
      usoSolo = await saveUsoDoSolo({});
      usoSolo_id = usoSolo?.id;
      selectUsoDoSoloResult(usoSolo);
      selectUsoDoSoloResultId(usoSolo_id);

    }
    try {
      objToSend = { ...objToSend, usoSolo_id }
      const result = await importGeomUsoSolo(objToSend)
      if (!result) {
        postMessage({ type: "errorMsg", value: "Não foi possivel salvar" }, "*");
        return;
      }

      try {
        usoSolo = await getusoSoloById(usoSolo_id);
      } catch (error) {
        postMessage({ type: "errorMsg", value: "Registo salvo mas, Não é possivel mostrar os dados neste momento" }, "*");

      } finally {
        usoSolo = { ...usoSolo, ...objToSend };
        selectUsoDoSoloResultId(usoSolo_id);
        selectUsoDoSoloResult(usoSolo);
        setusoSoloSelected(usoSolo)
      }
      try {
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage(
          { type: 'SiarlUsUsoSoloSearch', value: [usoSolo] },
          '*'
        )
      } catch (error) {
        postMessage({ type: "errorMsg", value: "Registo salvo mas, Não é possivel mostrar os dados neste momento" }, "*");
      }

      setOpenImportModal(false)
    } catch (err) {
      postMessage({ type: "errorMsg", value: "Não foi possivel salvar" }, "*");

    } finally {
      postMessage({ type: "hideLoader" }, "*");
    }

  }
*/

  const [openModalDescartar, setOpenModalDescartar] = useState<any>()

  const cancelModalDescartar = () => {
    setOpenModalDescartar(false)
  }

  const handleOpenModalDescartar = () => {
    if (
      SessionStorageService.getItem('novoUsTitulo') &&
      SessionStorageService.getItem('novoUsTitulo') == 'true'
    ) {
      setOpenModalDescartar(true);

      if(pontoAtivo){
        DesativarSelecaoPonto()
      }

    } else {
      onEditItemClick("SiarlVisualizar");
      handleCancel();

      if(pontoAtivo){
        DesativarSelecaoPonto()
      }  
   }
  }

  useEffect(() => {
    setViewMode(!editMode && SessionStorageService.getItem("novoUsTitulo") != 'true');
  }, [])

  useEffect(() => {
    if(sessionStorage.getItem("usGeomEdited") == 'true'){
      setEditMode(true)
      setViewMode(false)
    }
  }, [])
  
  useEffect(() => {
    if (viewMode) {
      SessionStorageService.removeItem("novoUsTitulo");
    }
  }, [viewMode])

  useEffect(() => {
    setCanUpdate(hasPermission(PERMISSION_ATUALIZAR_US_TITULO));
  }, [])


  const onEditItemClick= (event:any) => {
    if (event == "SiarlEditar" || event?.item?.key == "SiarlEditar") {
      setEditMode(true);
      setViewMode(false);
      handleAlteraTitulo();
      return;
    }
    if (event == "SiarlVisualizar") {
      setEditMode(false);
      setViewMode(true);
    }
    if (event?.item?.key == "SiarlApagar") {
      setDeleteClicked(true);
      setOpenModalDescartar(true);
    }
  }

  const handleCancel = ()  => {
    refreshForm();
  }

  const refreshForm = async(id: any = undefined) => {

    let idUsosSoloTitulo = id;
    if (!idUsosSoloTitulo) {
      if (selectedUsoDoSoloResult?.id) {
        idUsosSoloTitulo = selectedUsoDoSoloResult?.id;
      } else {
        return;
      }
    }
    const tituloUsoDoSolo = await getUsoDoSolo(idUsosSoloTitulo);

    const tecnico = await GetUserNameTecnico(tituloUsoDoSolo.utilizador_id);


    const usosSoloObject = {
      titulo: tituloUsoDoSolo?.titulo,
      id: tituloUsoDoSolo?.id,
      idProcesso: tituloUsoDoSolo?.id_processo,
      estadoTitulo: tituloUsoDoSolo?.estadoDoTitulo,
      dataVigor: tituloUsoDoSolo?.data_entrada_vigor ? new Date(tituloUsoDoSolo?.data_entrada_vigor) : undefined,
      dataExpiracao: tituloUsoDoSolo?.data_expiracao ? new Date(tituloUsoDoSolo?.data_expiracao) : undefined,
      dataAlteracao: tituloUsoDoSolo?.data_alteracao_licenciamento ? new Date(tituloUsoDoSolo?.data_alteracao_licenciamento) : undefined,
      designacao: tituloUsoDoSolo?.designacao,
      nuts1: tituloUsoDoSolo?.nuts1??undefined,
      nuts2: tituloUsoDoSolo?.nuts2??undefined,
      nuts3: tituloUsoDoSolo?.nuts3??undefined,
      distrito: tituloUsoDoSolo?.distrito??undefined,
      concelho: tituloUsoDoSolo?.concelho??undefined,
      freguesia: tituloUsoDoSolo?.freguesia??undefined,
      arh: tituloUsoDoSolo?.arh??undefined,
      capitania: tituloUsoDoSolo?.capitania??undefined,
      praia: tituloUsoDoSolo?.praia?? undefined,
      massaagua: tituloUsoDoSolo?.massaAgua ?? undefined,
      //local: undefined,
      tecnicoRegisto: tecnico,
      dataHoraRegisto: tituloUsoDoSolo?.data_registo ? new Date(tituloUsoDoSolo?.data_registo) : undefined,
      tipoDeProcesso: tituloUsoDoSolo?.tipo_processo,
      codProcesso: tituloUsoDoSolo?.codigo_processo,
      tipoUtilizacao: tituloUsoDoSolo?.tipo_utilizacao,
      codUtilizacao: tituloUsoDoSolo?.codigo_utilizacao,
      categoria: tituloUsoDoSolo?.categoria,
      nifRequerente: tituloUsoDoSolo?.nif_requerente,
      nomeRequerente: tituloUsoDoSolo?.nome_requerente,
      obs: tituloUsoDoSolo?.observacoes,
      filedoc_cod: tituloUsoDoSolo?.filedoc_cod,
      dataEmissao: tituloUsoDoSolo?.data_emissao,
      estado_id: tituloUsoDoSolo?.estado_id,
      urlTitulo: tituloUsoDoSolo?.url_titulo
    }
    
    selectUsoDoSoloResult(usosSoloObject);
    setusoSoloSelected(usosSoloObject);
    
    postMessage({ type: "form", value: "" }, "*");
    setTimeout(() => {
      postMessage({ type: "form", value: "registarUsoDoSolo" }, "*");
    }, 10);
    if(tituloUsoDoSolo?.id){
      refreshPesquisaSearch("resultadoUsoDoSoloPesquisa", "SiarlUsTituloSearch")
    }

  }
  const [estadoFalse, setEstado] = useState(false)
    const [estadoTrue, setEstadoTrue] = useState(true)


  const handleDesativar = async (e: any) => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
       {
         type: 'SiarlCoordenadaBoxClose',
         value: 'true',
       },
       '*'
     )
     iframe.contentWindow?.postMessage(
      {
        type: 'SiarlRemoveSelection',
        value: 'true',
      },
      '*'
    )
  setDeleteClicked(true)
  setOpenModalDescartar(false)
  


  if(selectedUsoDoSoloResultId){
   const usoSoloSelected = getUsoSoloSelected()

    const dataToSend : any = {
      id: usoSoloSelected?.id ?? undefined,
      status: estadoFalse
    }

      let UsoSoloSaved: any
   postMessage({ type: 'showLoader' }, '*')
    if (usoSoloSelected?.id) {
          UsoSoloSaved = await updateUsoDoSolo(dataToSend)
          selectUsoDoSoloResult({
            ...selectedUsoDoSoloResult,
            ...UsoSoloSaved,})
          }
    }

    SessionStorageService.removeItem("firstCallUsTitulo");
    postMessage({ type: 'form', value: 'pesquisarUsoDoSolo' }, '*')

}

  const userLocal = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}')

  const handleSubmit = async (e: any, isSubmitAction?: boolean) => {
    const userLocal = JSON.parse(
      localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}'
    )
    if (selected == 0 && !identificacaoTabIsValid) {
      return false
    }
    const usoDoSoloRequest: UsoDoSoloForm = {
      isTitulo: e?.values?.titulo,
      id: getUsoSoloSelected()?.id ?? (selectedUsoDoSoloResult?.id ?? undefined),
      estado_titulo_id: e?.values?.estadoTitulo?.id,
      id_processo: e?.values?.idProcesso,
      data_entrada_vigor: e?.values?.dataVigor,
      data_expiracao: e?.values?.dataExpiracao,
      data_alteracao_licenciamento: e?.values?.dataAlteracao,
      designacao: e?.values?.designacao,
      arh_cod: e?.values?.arh?.codigo,
      freguesia_cod: e?.values?.freguesia?.codigo,
      distrito_cod: e?.values?.distrito?.codigo,
      concelho_cod: e?.values?.concelho?.codigo,
      nuts3_cod: e?.values?.nuts3?.codigo,
      nuts2_cod: e?.values?.nuts2?.codigo,
      nuts1_cod: e?.values?.nuts1?.codigo,
      praia_cod: e?.values?.praia?.codigo,
      capitania_cod: e?.values?.capitania?.codigo,
      //local: e?.values?.local,
      geom_imported: undefined,
      utilizador_id: userLocal?.user?.id,
      data_registo: e?.values?.dataHoraRegisto,
      tipo_processo_id: e?.values?.tipoDeProcesso?.id,
      codigo_processo: e?.values?.codProcesso,
      tipo_utilizacao_id: e?.values?.tipoUtilizacao?.id,
      codigo_utilizacao: e?.values?.codUtilizacao,
      categoria: e?.values?.categoria,
      nif_requerente: e?.values?.nifRequerente,
      nome_requerente: e?.values?.nomeRequerente,
      observacoes: e?.values?.obs,
      status: true,
      flatCoordinates: coordenadasSelected,
      filedoc_cod: e?.values?.filedoc_cod,
      data_emissao: e?.values?.dataEmissao,
      estado_id: e?.values?.estado_id?.id,
      url_titulo: e?.values?.urlTitulo
    }
    SessionStorageService.setItem("savedUsoSolo", JSON.stringify(usoDoSoloRequest))
    try {
      let usoDoSolo: any
      if (getUsoSoloSelected()?.id || selectedUsoDoSoloResult?.id) {
        usoDoSolo = await updateUsoDoSolo(usoDoSoloRequest)
      } else {
        usoDoSolo = await saveUsoDoSolo(usoDoSoloRequest)
      }
      await refreshForm(usoDoSolo?.id);
      selectUsoDoSoloResult(usoDoSoloRequest)

      selectUsoDoSoloResultId(usoDoSoloRequest?.id)
      setFormState((prev:string)=>`${prev}_${coordenadasSelected}`)
      if(pontoAtivo){
        DesativarSelecaoPonto()
      }
      if(usoDoSolo){
        if (SessionStorageService.getItem("resultadoUsoDoSoloPesquisa")) {
          const usTitulosSearch: any[] = JSON.parse(SessionStorageService.getItem("resultadoUsoDoSoloPesquisa"));
          if (usTitulosSearch) {
            const usTitulosSearchFiltred = usTitulosSearch.filter(x => x.id != usoDoSolo.id)?.slice() ?? [];
            usTitulosSearchFiltred.push(usoDoSolo);
            SessionStorageService.setItem("resultadoUsoDoSoloPesquisa", JSON.stringify(usTitulosSearchFiltred));
          }
          refreshPesquisaSearch("resultadoUsoDoSoloPesquisa", "SiarlUsTituloSearch")
        } else {
          const iframe: any = document.getElementById('iframeGeoPortal')
          iframe.contentWindow?.postMessage(
            { type: 'SiarlUsTituloSearch', value: [usoDoSolo?.id] },
            '*'
          )
        }
      }
      postMessage({type: "removeInfoPonto", value: "true"}, "*");
      const iframe: any = document.getElementById('iframeGeoPortal')  
      iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: "false"}, '*');

      setTimeout(() => {
         postMessage({ type: 'sucessMsg' , value :{left : (isMobile? (Number(percentLeftSucess.split('%')[0])-40)+'%' : percentLeftSucess)
        } },'*')
      }, 500)
      selectUsoDoSoloResultId(usoDoSolo?.id as number)
    } catch (error) {
      handleGenericError(error);
    }
  }
  const innerStyleTabContent = {
    scrollBehavior: 'smooth',
    top: 55,
    height: '80vh',
  }
  
  const voltar = () => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
       {
         type: 'SiarlCoordenadaBoxClose',
         value: 'true',
       },
       '*'
    )

    if(pontoAtivo){
      DesativarSelecaoPonto()
    }

    clearUsoSoloMap();
    postMessage({ type: 'form', value: 'pesquisarUsoDoSolo' }, '*')
    sessionStorage.setItem('usGeomEdited', 'false')

  }

  
  const [clearMap] = useClearMap();
  const clearUsoSoloMap = () => {
    clearMap(clearLayers["CLEAR_LAYERS_US_TITULO"]);
    setusoSoloSelected(undefined)
    setCoordenadasSelected(undefined)
    selectUsoDoSoloResult(undefined)
  }

  const itemRender = (props: { item: any; itemIndex: number }) => {
    const isTitleItem = props.item.key === 'titulo'
    if (isTitleItem) {
      // Item de título personalizado
      return (
        <div className={styles.DropDownButtonTitulo}>
          <span>{props.item.text}</span>
        </div>
      )
    } else {
      // Demais itens
      return (
        <div className={styles.DropDownButtonItem}>
          <span>{props.item.text}</span>
        </div>
      )
    }
  }

  const onItemClick = (event: any) => {
    selectMapAction(event.item)
  };

  const optionsEditar = [
    {
      key: 'SiarlEditar',
      text: 'Editar',
    },
    {
      key: 'SiarlApagar',
      text: 'Apagar',
    },
    
  ]

  const optionsFileDoc = [

    {
      key: 'AbrirFileDoc',
      text: 'Abrir Filedoc',
    },
    
  ]

  const optionsArea = [
    {
      key: 'SiarlGeoPortalAdicionarArea',
      text: 'Desenhar Área',
    },
    {
      key: 'removerArea',
      text: 'Remover Área',
    },
  ]

  const onItemClickImprimir = async (event: any) => {
    
    let mapImage = ''

    if (!window.location.href.includes('localhost')) {
      const mapIframe = document.querySelector(
        '#iframeGeoPortal'
      ) as HTMLIFrameElement

      const mapIframeInnerContent =
        mapIframe.contentWindow?.document.querySelector(
          'div.ol-viewport'
        ) as HTMLElement;

      const mapBussula =  mapIframe.contentWindow?.document.querySelector(
        'button.fixedNorthArrowButton'
      ) as HTMLElement;

      mapIframeInnerContent.appendChild(mapBussula);
      
      const generatedImage = await html2canvas(mapIframeInnerContent);
      mapImage = generatedImage.toDataURL()
    }
    

    console.log(selectedUsoDoSoloResult);

    try {
      postMessage({ type: 'showLoader' }, '*')

      const objToSendImgMapa : any = {
        CodPrintTemplate: "RELATORIO_US_TITULO",
        extendInfo: {
          spatialReference: {
            wkid: 0
          },
          xmin: 0,
          ymin: 0,
          xmax: 0,
          ymax: 0
        },
        ListOperationalLayerInfo: [
          {
            IdOperationLayer: OPERATIONAL_LAYER,
            UseLayerExtent: false,
            DefinitionExpression: "",
            ListServiceLayer: [
              {
                  LayerId: LAYER_INDEX_US_TITULO,
                  DefinitionExpression: "id=" + selectedUsoDoSoloResult?.id,
                  UseLayerExtent: true
              }
            ]
          }
        ]
      }

      const documentoBase64 = await relatorioTitulo({
        id: selectedUsoDoSoloResult?.id,
        dados: {
          'REGISTO' : '',
          'Nº processo APA:': selectedUsoDoSoloResult?.filedoc_cod ?? '',
          'ID do processo:': selectedUsoDoSoloResult?.id_processo ?? '',
          'Data entrada em vigor:': selectedUsoDoSoloResult?.data_entrada_vigor ? new Date(selectedUsoDoSoloResult?.data_entrada_vigor).toLocaleDateString() : '',
          'Data de expiração:': selectedUsoDoSoloResult?.data_expiracao ? new Date(selectedUsoDoSoloResult?.data_expiracao).toLocaleDateString() : '',
          'Data de alteração:': selectedUsoDoSoloResult?.data_alteracao_licenciamento? new Date(selectedUsoDoSoloResult?.data_alteracao_licenciamento).toLocaleDateString() : '',

          'IDENTIFICAÇÃO' : '',
          'Data/hora registo:' : selectedUsoDoSoloResult?.data_registo ? new Date(selectedUsoDoSoloResult?.data_registo).toLocaleDateString() : '',
          'Designação:' : selectedUsoDoSoloResult?.designacao,

           //Localização
           'LOCALIZAÇÃO' : '',
           'Distritos:': selectedUsoDoSoloResult?.distrito?.nome ?? '',
           'Concelhos:': selectedUsoDoSoloResult?.concelho?.nome ?? '',
           'Freguesias:': selectedUsoDoSoloResult?.freguesia?.nome ?? '',
           'Nuts1:': selectedUsoDoSoloResult?.nuts1?.nome ?? '',
           'Nuts2:': selectedUsoDoSoloResult?.nuts2?.nome ?? '',
           'Nuts3:': selectedUsoDoSoloResult?.nuts3?.nome ?? '',

           'TIPOLOGIA' : '',
           'Tipo de processo:' : selectedUsoDoSoloResult?.tipoProcesso?.nome ?? '',
           'Código do processo:': selectedUsoDoSoloResult?.codigo_processo ?? '',
           'Tipo de utilização:' : selectedUsoDoSoloResult?.tipoUtilizacao?.nome ?? '',
           'Código utilização:' : selectedUsoDoSoloResult?.codigo_utilizacao ?? '',
           'Tipo de título:' : selectedUsoDoSoloResult?.tipoTitulo?.nome ?? '',
           'Categoria:' : selectedUsoDoSoloResult?.categoria ?? '',

          'REQUERENTE' : '',
          'Nif requerente:': selectedUsoDoSoloResult?.nif_requerente ?? '',
          'Nome do requerente:' : selectedUsoDoSoloResult?.nome_requerente ?? '',
          'Data de emissão:': selectedUsoDoSoloResult?.data_emissao? new Date(selectedUsoDoSoloResult?.data_emissao).toLocaleDateString() : '',
          'Estado:': selectedUsoDoSoloResult?.estado_id?.descricao ?? '',
          'Url título:': selectedUsoDoSoloResult?.url_titulo ?? '',

          'OBSERVAÇÕES' : '',
          'Observação:': selectedUsoDoSoloResult?.obs ?? '',
        },      
        img_mapa: "",
        objJsonToCreateImg: objToSendImgMapa
      });

      if (documentoBase64) {
        const blob = b64toBlob(documentoBase64, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
        postMessage({ type: 'hideLoader' }, '*')
      }
    } catch (error) {
      postMessage({ type: 'errorMsg', value: { message: "Não foi possivel efetuar esta operação" } }, '*')
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }

  }

  const optionsImprimir = [
    {
      key: 'imprimirFichas',
      text: 'Fichas',
    },
    {
      key: 'imprimirResultados',
      text: 'Resultados',
    },
  ]

  const cancelImport = () => {
    setOpenImportModal(false)
  }
  
  const ImportGeom = async (item: any) => {
    if (item.key == 'ImportGeom') {
      setOpenImportModal(true)
    }
  }

  const onClickOpenFileDoc = async() => {
    const usoSoloSelected = getUsoSoloSelected();
      if (!usoSoloSelected?.filedoc_cod) {
        postMessage({ type: "infoMsg", value: {message: "É necessário preencher o nº processo APA (filedoc)."} }, "*");
        return;
      }
      postMessage({ type: 'showLoader' }, '*');
      const urlFileDoc = await getUrlFileDoc(usoSoloSelected?.filedoc_cod);
      postMessage({ type: 'hideLoader' }, '*')
      window.open(urlFileDoc, '_blank');
      return;
  }

  const onImportGeomClick = (event: any) => {
    ImportGeom(event.item)
  }

  const onItemClickExportar = async (event: any) => {
    let mapImage = ''

    if (!window.location.href.includes('localhost')) {
      const mapIframe = document.querySelector(
        '#iframeGeoPortal'
      ) as HTMLIFrameElement

      const mapIframeInnerContent =
        mapIframe.contentWindow?.document.querySelector(
          'div.ol-viewport'
        ) as HTMLElement;

      const mapBussula =  mapIframe.contentWindow?.document.querySelector(
        'button.fixedNorthArrowButton'
      ) as HTMLElement;

      mapIframeInnerContent.appendChild(mapBussula);
      
      const generatedImage = await html2canvas(mapIframeInnerContent);
      mapImage = generatedImage.toDataURL()
    }
    

    console.log(selectedUsoDoSoloResult);

    try {
      postMessage({ type: 'showLoader' }, '*')

      const documentoBase64 = await relatorioTitulo({
        id: selectedUsoDoSoloResult?.id,
        dados: {
          'REGISTO' : '',
          'Nº processo APA:': getUsoSoloSelected()?.filedoc_cod ?? '',
          'ID do processo:': selectedUsoDoSoloResult?.id_processo ?? '',
          'Data entrada em vigor:': selectedUsoDoSoloResult?.data_entrada_vigor ? new Date(selectedUsoDoSoloResult?.data_entrada_vigor).toLocaleDateString() : '',
          'Data de expiração:': selectedUsoDoSoloResult?.data_expiracao ? new Date(selectedUsoDoSoloResult?.data_expiracao).toLocaleDateString() : '',
          'Data de alteração:': selectedUsoDoSoloResult?.data_alteracao_licenciamento? new Date(selectedUsoDoSoloResult?.data_alteracao_licenciamento).toLocaleDateString() : '',

          'IDENTIFICAÇÃO' : '',
          'Data/hora registo:' : selectedUsoDoSoloResult?.data_registo ? new Date(selectedUsoDoSoloResult?.data_registo).toLocaleDateString() : '',
          'Designação:' : selectedUsoDoSoloResult?.designacao,

           //Localização
           'LOCALIZAÇÃO' : '',
           'Distritos:': selectedUsoDoSoloResult?.distrito?.nome ?? '',
           'Concelhos:': selectedUsoDoSoloResult?.concelho?.nome ?? '',
           'Freguesias:': selectedUsoDoSoloResult?.freguesia?.nome ?? '',
           'Nuts1:': selectedUsoDoSoloResult?.nuts1?.nome ?? '',
           'Nuts2:': selectedUsoDoSoloResult?.nuts2?.nome ?? '',
           'Nuts3:': selectedUsoDoSoloResult?.nuts3?.nome ?? '',

           'TIPOLOGIA' : '',
           'Tipo de processo:' : selectedUsoDoSoloResult?.tipoProcesso?.nome ?? '',
           'Código do processo:': selectedUsoDoSoloResult?.codigo_processo ?? '',
           'Tipo de utilização:' : selectedUsoDoSoloResult?.tipoUtilizacao?.nome ?? '',
           'Código utilização:' : selectedUsoDoSoloResult?.codigo_utilizacao ?? '',
           'Tipo de título:' : selectedUsoDoSoloResult?.tipoTitulo?.nome ?? '',
           'Categoria:' : selectedUsoDoSoloResult?.categoria ?? '',

          'REQUERENTE' : '',
          'Nif requerente:': selectedUsoDoSoloResult?.nif_requerente ?? '',
          'Nome do requerente:' : selectedUsoDoSoloResult?.nome_requerente ?? '',
          'Data de emissão:': selectedUsoDoSoloResult?.dataEmissao ?? '',
          'Estado:': selectedUsoDoSoloResult?.estado_id?.descricao ?? '',
          'Url título:': selectedUsoDoSoloResult?.url_titulo ?? '',

          'OBSERVAÇÕES' : '',
          'Observação:': selectedUsoDoSoloResult?.obs ?? '',
        },      
        img_mapa: mapImage
      });
      if (documentoBase64) {
        const link = document.createElement('a')
        link.href = 'data:application/octet-stream;base64,' + documentoBase64
        link.download = 'reporte_titulos.pdf'
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        postMessage({ type: 'hideLoader' }, '*')
      }
    } catch (error) {
      postMessage({ type: 'errorMsg', value: { message: "Não foi possivel efetuar esta operação" } }, '*')
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

   const importarGeometria = [
    {
      key: 'ImportGeom',
      text: 'Importar Geom',
    },
  ]


  const optionsExportar = [
    {
      key: 'exportarFichas',
      text: 'Fichas',
    },
    {
      key: 'exportarResultados',
      text: 'Resultados',
    },
  ]
  const optionsPonto = [
    {
      key: 'SiarlGeoPortalAdicionarPonto',
      text: 'Adicionar Ponto',
    },
    {
      key: 'SiarlEditarGeometria',
      text: 'Editar Ponto',
    },
    {
      key: 'removerPonto',
      text: 'Remover Ponto',
    },
  ]

  useEffect(() => {
    SessionStorageService.removeItem("savedUsoSolo")
    console.log(selectedUsoDoSoloResult);
  }, [])


  useLayoutEffect(() => {

    const handleResize = () => {

      if (window.innerWidth <= telaMobile) {
        
        setIsMobile(true);
        ocultaFloatingMenu_e_Mapa();

      }else{

        setIsMobile(false);
        mostraFloatingMenu_e_Mapa();

      }

    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []); 

  const onItemClickMais = (event: any) => {

    elementoClickMais(event);

    onEditItemClick(event);
    onItemClick(event);

  };

  const optionsMais = [
    {
      key: 'titulo',
      text: 'Registo',
    },
    ...optionsEditar.map(option => ({
      ...option,
      className: 'icon-siarl icon-editar btnIconRegistar',
    })),
    {
      key: 'titulo',
      text: 'Opções',
    },
    {
      key: 'openFileDoc',
      text: 'Diretório de documentos',
      className: 'icon-siarl icon-filedoc btnIconRegistar'
    },    
    {
      key: 'titulo',
      text: 'Ponto',
    },
    ...optionsPonto.map(option => ({
      ...option,
      className: 'icon-siarl icon-pin btnIconRegistar',
    })),
    {
      key: 'titulo',
      text: 'Imprimir',
    },
    {
      key: 'imprimirFicha',
      text: 'Fichas',
      className: 'icon-siarl icon-imprimir btnIconRegistar'
    },
    {
      key: 'titulo',
      text: 'Exportar',
    },
    ...optionsExportar.map(option => ({
      ...option,
      className: 'icon-siarl icon-exportar btnIconRegistar',
    })),
  ]

  const [tituloUsoSolos, setTituloUsoSolos] = useState(false);

  const handleAlteraTitulo = () => {
    setTituloUsoSolos(true);
  };

  const openAjuda = () => {
    window.open(URL_AJUDA_USOS_SOLO_TITULO, "_blank")
  }

  const removePonto = async () => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: 'true' }, '*')
    setCoordenadasSelected(undefined)
    if (!selectedUsoDoSoloResultId) {
      setOpenRemovePontoModal(false)
      return;
    }
    await removerPontoTitulo(selectedUsoDoSoloResultId);
    
    refreshPesquisaSearch("resultadoUsoDoSoloPesquisa", "SiarlUsTituloSearch")
    await refreshForm(selectedUsoDoSoloResultId)
    setOpenRemovePontoModal(false)
  }

 
  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <div>
          <div className={styles.nomeDoModulo}>Uso do Solo</div>
          <div className={styles.tituloDaPagina}>
            {!selectedUsoDoSoloResultId || !selectedUsoDoSoloResultId ? 'Novo título' : (tituloUsoSolos ? 'Editar título' : 'Título')}
          </div>
        </div>

        <div>

          { (hasPermission(PERMISSION_USOSOLO_PERFIL_TOTAL) || hasPermission(PERMISSION_USOSOLO_PERFIL_COMPLETO_EDICAO) || hasPermission(PERMISSION_USOSOLO_PERFIL_INTERMEDIO_EDICAO)) && !isMobile && canUpdate && (viewMode || editMode) && (SessionStorageService.getItem("novoUsTitulo") != 'true') && 
          getUsoSoloSelected()?.tipo_registo != TIPO_USOS_SOLO_SILIAMB ?  
          <DropDownButton
            items={optionsEditar}
            className={styles.btnEditar2}
            iconClass="icon-siarl icon-editar"
            onItemClick={onEditItemClick}
            fillMode={'flat'}
            itemRender={itemRender}
            title="Editar"
          ></DropDownButton>
          : ''}
          
          <button className={styles.btnEditar2} title='Filedoc' onClick={onClickOpenFileDoc}
          style={{ display: isMobile ? 'none' : '' }} id='openFileDoc'>
            <span className="icon-siarl icon-filedoc"></span>
          </button>

          {!isMobile &&
          <DropDownButton
            items={optionsPonto}
            className={styles.btnEditar2}
            iconClass="icon-siarl icon-pin"
            onItemClick={onItemClick}
            fillMode={'flat'}
            disabled={viewMode}
            title='Geometria'
            itemRender={itemRender}
          ></DropDownButton>
          }

          <button className={styles.btnEditar2} onClick={onItemClickImprimir}
          style={{ display: isMobile ? 'none' : '' }} id='imprimirFicha' title='Imprimir'>
            <span className="icon-siarl icon-imprimir"></span>
          </button>

      
          <button className={styles.btnImprimir} title='Exportar' onClick={onItemClickExportar}
           style={{ display: isMobile ? 'none' : '' }} id='exportarFicha'>
            <span className="icon-siarl icon-exportar"></span>
          </button>

          {isMobile &&
            <DropDownButton
              items={optionsMais}
              className={styles.btnMais}
              iconClass="icon-siarl icon-mais"
              onItemClick={onItemClickMais}
              fillMode={'flat'}
              itemRender={itemRenderMais}
              title="Mais Opções"
            />
          }

          <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
            <span className="icon-siarl icon-ajuda"></span>
          </button>

          <button className={styles.btnFechar} onClick={voltar} title="Fechar">
            <span className="icon-siarl icon-fechar"></span>
          </button>
        </div>
      </div>

{openModalDescartar ? (
          <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={cancelModalDescartar}
          >
            
            <Form
                    key={"modal"}

              initialValues={
                {
                } 
              }
              render={() => (
                <FormElement style={{ maxWidth: 500 }}>
                  <fieldset>
                    <span className="textDescartar">{deleteClicked ? 'Apagar' : 'Cancelar'} registo  </span>
                    <br></br>
                    <span>{deleteClicked ? 'Ao apagar este registo deixará de ter acesso, sendo necessária uma intervenção rigorosa para realizar a sua recuperação.' : 'Ao cancelar este registo irá perder os dados inseridos até ao momento.'}</span>
                      <br></br>
                    <span>{deleteClicked ? 'Tem a certeza que pretende apagar?' : 'Tem a certeza que pretende cancelar?'}</span>
                  </fieldset>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={cancelModalDescartar}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={handleDesativar}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}

{openRemovePontoModal ? (
               <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={() => setOpenRemovePontoModal(false)}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                    <span className="textDescartar">Remover ponto </span>
                    <br></br>
                    <span>{'Tem a certeza que pretende remover o ponto selecionado?'}</span>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={() => setOpenRemovePontoModal(false)}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={removePonto}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}

      <Form
        key={formState}
        onSubmitClick={handleSubmit}
        initialValues={
          {
            ...getUsoSoloSelected(),
            dataHoraRegisto: selectedUsoDoSoloResult?.data_registo
            ? new Date(selectedUsoDoSoloResult?.data_registo)
            : new Date(),           
            dataVigor: selectedUsoDoSoloResult?.data_entrada_vigor ? new Date(selectedUsoDoSoloResult?.data_entrada_vigor) : undefined,
            dataExpiracao: selectedUsoDoSoloResult?.data_expiracao ? new Date(selectedUsoDoSoloResult?.data_expiracao) : undefined,
            dataAlteracao: selectedUsoDoSoloResult?.data_alteracao_licenciamento ? new Date(selectedUsoDoSoloResult?.data_alteracao_licenciamento) : undefined,
            dataEmissao: selectedUsoDoSoloResult?.data_emissao ? new Date(selectedUsoDoSoloResult?.data_emissao) : undefined,
             tecnicoRegisto: selectedUsoDoSoloResult?.tecnicoRegisto ?? userLocal?.user?.name
          } ?? undefined
        }
        render={(formRenderProps) => (
          <FormElement>
            <TabStrip
              selected={selected}
              onSelect={handleSelect}

              className={`${styles.innerStyleTabContent} ${styles.backgroundWhite} ${viewMode? 'tabsVisualizar' : ''}`}
              scrollable={isMobile}
            >
              <TabStripTab title="Identificação">
                <IdentificacaoSoloForm viewMode={viewMode} onCloseClick={onCloseClick} />
              </TabStripTab>

              <TabStripTab
                title="Documentos"
              >
                <DocumentoListagem viewMode={viewMode} pageName="usoDoSolo" />
              </TabStripTab>

              <TabStripTab
                title="Associação"
              >
                <AssociacaoListagem viewMode={viewMode} pageName="usoDoSolo" />
              </TabStripTab>
            </TabStrip>
            {(editMode || SessionStorageService.getItem("novoUsTitulo") == 'true')
                  && !viewMode ?
            <div
              className={`${styles.footer} ${styles.justify_multiple_children}`}
            >

                 <Button type="button" className={styles.btnSeguinte} onClick={handleOpenModalDescartar} title='Cancelar'>
                  <span className="icon-siarl icon-fechar"></span>
                  <span className={styles.rotulo}>
                    {' '}&nbsp;{' '}
                    Cancelar
                  </span>
                 </Button>
             
              <Button
                className={styles.btnSubmeter}
                onClick={formRenderProps.onSubmit}
                title='Gravar'
              >
                <span className="icon-siarl icon-submeter btnIconRegistar"></span>
                <span className={styles.rotulo}>
                  {' '}&nbsp;{' '}
                  Gravar
                </span>
              </Button>
            </div>
        : ''}
          </FormElement>
  )}
      />
    </div>
  )
}
