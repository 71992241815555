import { GetUserNameTecnico, coordenadasPoligono, coordenadasSelected, del, entidadeUser, get, getCheckedAdaptacao, getCheckedDefesa, getCheckedDefesaAdaptacao, getCheckedRetirada, getEaRegistoAnalise, getFlatCoordinatesApoioEA, getFlatCoordinatesEA, getValidateGeomEA, handleGenericError, hasPermission, isTabSelection, ocEstados, post, preventPropsNullValues, put, setCheckedAdaptacao, setCheckedDefesa, setCheckedDefesaAdaptacao, setCheckedRetirada, setCoordenadasPoligono, setCoordenadasSelected, setEaRegistoAnalise, setEaResultado, setFlatCordinatesApoioEA, setFlatCordinatesEA, setOcorrenciaSelected, setTabSelection, setValidateGeomEA } from '../../services/utils';
import styles from '../../styles/estrategiaAdaptacao/EstrategiaAdaptacao.module.scss'
import {
  MouseEvent as ReactMouseEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState
} from 'react'
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from '@progress/kendo-react-form'
import { AUTH_LOCALSTORAGE, DELETE_ANALISE, EA_POINT_NAME, GET_ANALISE, GET_USER_BY_ID, IMPORT_GEOM_EA_REGISTO_ANALISE, PERMISSION_ATUALIZAR_ESTRATEGIA_ADAPTACAO, PERMISSION_INSERIR_ESTRATEGIA_ADAPTACAO, RELATORIO_ESTRATEGIA_ADAPTACAO, REMOVER_AREA_APOIO_ESTRATEGIA_ADAPTACAO,  REMOVER_AREA_PRINCIPAL_ESTRATEGIA_ADAPTACAO, SAVE_ANALISE, UPDATE_ANALISE, URL_AJUDA_ESTRATEGIA_ADAPTACAO } from '../../utils/constants';
import { Button, DropDownButton } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs'
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import UsoDeSoloForm from '../usosdosolo/ValorUsoDoSoloForm'
import SessionStorageService from '../../services/SessionStorageService';
import IdentificacaoEstrategiaAdaptacao from './componentes/IdentificacaoEstrategiaAdaptacao';
import VusEstrategiaAdaptacao from './componentes/VusEstrategiaAdaptacao';
import AcaoEstrategiaAdaptacao from './componentes/AcaoEstrategiaAdaptacao';
import AnaliseEstrategiaAdaptacao from './componentes/AnaliseEstrategiaAdaptacao';
import { EstrategiaAdaptacaoParametros } from './Parametros';
import { useEstrategiaAdaptacaoStore } from '../../stores/estrategiaAdaptacao';
import { DocumentoListagem } from '../documentos/registar/documento/documento';
import ImportarGeometriaComponent from '../apoiogestao/registar/ImportarGeometriaForm';
import VisualizadorColunas from '../Generico/VisualizadorColunas ';
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais';
import html2canvas from 'html2canvas';
import ReactGA from 'react-ga4';

type Props = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
}
export default function RegistarEstrategiaAdaptacaoTabs({ onCloseClick }: Props) {
  const [isMobile, setIsMobile] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [openModalDescartar, setOpenModalDescartar] = useState<any>()
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [selected, setSelected] = useState<number>(0)
  const [canUpdate, setCanUpdate] = useState(false);
  const [canInsert, setCanInsert] = useState(false);
  const [realizeNovaAnalise, setRealizeNovaAnalise] = useState(false);

  const relatorioEA = async (data: any) =>
  await post<any, any>(`${RELATORIO_ESTRATEGIA_ADAPTACAO}`, data)
  const [isCheckboxPolignoChecked, setCheckboxPolignoChecked] = useState(false);
  const [disabledPolignoApoio, setDisabledPolignoApoio] = useState(false);
  const getAnalise = async (data: any) =>
  await get<any, any>(`${GET_ANALISE}/` + data)

  const getUserById = async (data: any) =>
  await get<any, any>(`${GET_USER_BY_ID}/` + data)

  const saveAnalise = async (data: any) =>
  await post<any, any>(`${SAVE_ANALISE}`, data)

  const updateAnalise = async (data: any) =>
  await put<any, any>(`${UPDATE_ANALISE}`, data)

  const deleteAnalise = (id: number) => del(`${DELETE_ANALISE}/${id}`, {})


  const removerAreaPrincipal = async (data: any) =>
    await post<any, any>(`${REMOVER_AREA_PRINCIPAL_ESTRATEGIA_ADAPTACAO}`, data)

  const removerAreaApoio = async (data: any) =>
    await post<any, any>(`${REMOVER_AREA_APOIO_ESTRATEGIA_ADAPTACAO}`, data)

  const [openRemoveAreaModal, setOpenRemoveAreaModal] = useState<any>()


  const TOTAL_TABS = 5

  useEffect(() => {
    if (viewMode) {
      sessionStorage.removeItem("novaAnaliseEstrategiaAdaptacao");
    }
  })

   useEffect(() => {
      const registoAnalise = getEaRegistoAnalise();
      handleCheckboxChangeRetirada({value : registoAnalise?.retirada})
      handleCheckboxChangeDefesa({value : registoAnalise?.defesa})
      handleCheckboxChangeAdaptacao({value : registoAnalise?.adaptacao})
      handleCheckboxChangeDA({value : registoAnalise?.defesa_adaptacao})
  }, [])

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/RegistarEstrategiaAdaptacaoTabs" });
  }, []);

    useEffect(() => {
    setViewMode(!editMode && sessionStorage.getItem("novaAnaliseEstrategiaAdaptacao") != 'true');
  }, [])

   const [openImportModal, setOpenImportModal] = useState<any>()
 
   const importGeomEaRegistoAnalise = async (data: any) =>
    await post<any, any>(`${IMPORT_GEOM_EA_REGISTO_ANALISE}`, data)

  const handleMessageEvent = useCallback( async(event: any) => {
      if ((event.data.type == "CoordenadasFlatSelected" ) && getFlatCoordinatesEA() && !getValidateGeomEA() ) {
            const polygonString = getFlatCoordinatesFromObj(event.data.value)
            setCoordenadasPoligono(polygonString)
            setDisabledPolignoApoio(true)
            let eaRegistoAnaliseSaved; 
            postMessage({type: "showLoader"}, "*");
            const eaRegistoAnaliseSelected: any = getEaRegistoAnalise();

            if(eaRegistoAnaliseSelected?.id){
              eaRegistoAnaliseSaved = await updateAnalise({id: eaRegistoAnaliseSelected?.id, flatCoordinates: coordenadasPoligono });  
            }
            else{
              eaRegistoAnaliseSaved = await saveAnalise({flatCoordinates: coordenadasPoligono});  
              selectEstrategiaResult(eaRegistoAnaliseSaved)
              setEaRegistoAnalise(eaRegistoAnaliseSaved);
            }
            postMessage({type: "hideLoader"}, "*");
            const iframe: any = document.getElementById('iframeGeoPortal')
            iframe.contentWindow?.postMessage(
              {
                type: 'SiarlEaRegistoAnaliseSearch',
                value: [eaRegistoAnaliseSaved?.id],
              },
              '*'
            )
            
            setValidateGeomEA(true)      
            saveScreenById("poligonoAnalise")
          }
          if ((event.data.type == "CoordenadasFlatSelected" ) && (getFlatCoordinatesEA() && getFlatCoordinatesApoioEA() ) ){
              const polygonString = getFlatCoordinatesFromObj(event.data.value)
              setCoordenadasPoligono(polygonString)
              const eaRegistoAnaliseSelected: any = getEaRegistoAnalise();
              postMessage({type: "showLoader"}, "*");
              const eaRegistoAnaliseSaved = await updateAnalise({id: eaRegistoAnaliseSelected?.id,  flatCoordinatesApoio: coordenadasPoligono, });  
              if (eaRegistoAnaliseSaved?.eaAbrangencia?.id) {
              const iframe: any = document.getElementById('iframeGeoPortal')
              iframe.contentWindow?.postMessage(
                {
                  type: 'SiarlEaAnaliseAbrangenciaSearch',
                  value: [eaRegistoAnaliseSaved?.eaAbrangencia?.id],
                },
                '*'
              )
              saveScreenById("poligonoAbrangencia")
              postMessage({type: "hideLoader"}, "*");
              
          }
        }



  }, [])

  const saveScreenById = async (key:"poligonoAbrangencia"|"poligonoAnalise")=>{
    let mapImage = ''

    if (!window.location.href.includes('localhost')) {
      const mapIframe = document.querySelector(
        '#iframeGeoPortal'
      ) as HTMLIFrameElement

      const mapIframeInnerContent =
        mapIframe.contentWindow?.document.querySelector(
          'section.content'
        ) as HTMLElement

      const generatedImage = await html2canvas(mapIframeInnerContent)
      mapImage = generatedImage.toDataURL()
      if(key==="poligonoAbrangencia"){
        SessionStorageService.setItem(key, JSON.stringify({
          img_mapa_area_abrangencia:mapImage
        }))
      }if(key==="poligonoAnalise"){
        SessionStorageService.setItem(key, JSON.stringify({
          img_mapa:mapImage
        }))
      }


    }
 }
 

  useEffect(() => {
    setCanUpdate(hasPermission(PERMISSION_ATUALIZAR_ESTRATEGIA_ADAPTACAO));
    setCanInsert(hasPermission(PERMISSION_INSERIR_ESTRATEGIA_ADAPTACAO))
  }, [])

    useEffect(() => {
        window.addEventListener(
            'message',
            handleMessageEvent,
            false
        )

        return () => {
            window.removeEventListener(
                'message',
                handleMessageEvent,
                false
            )
        }
    }, [])

  useEffect(() => {
    if (getFlatCoordinatesEA() || getFlatCoordinatesApoioEA()){

      const iframe: any = document.getElementById('iframeGeoPortal')
         iframe.contentWindow?.postMessage(
           { type: 'SiarlGeoPortalAdicionarArea', value: 'true' },
           '*'
         )
   }
  })

  useEffect(() => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    const idToSend = [selectedEstrategiaResult?.id];
    iframe.contentWindow?.postMessage(
      { type: 'SiarlEaRegistoAnaliseSearch', value: idToSend },
      '*'
    )

    const idAbrangencia = selectedEstrategiaResult?.eaAbrangencia?.id ?? undefined;
      if (idAbrangencia) {
         iframe.contentWindow?.postMessage(
          { type: 'SiarlEaAnaliseAbrangenciaSearch', value: [idAbrangencia] },
          '*'
         )
       }
  }, [])
  
  const identificacaoTabIsValid = useEstrategiaAdaptacaoStore(
    (state) => state.identificacaoTabIsValid
  )

  const parametroTabIsValid = useEstrategiaAdaptacaoStore(
    (state) => state.parametroTabIsValid
  )

   const selectedEstrategiaResult: any = useEstrategiaAdaptacaoStore(
    (state) => state.selectedEstrategiaResult
  )
  const selectedEstrategiaResultId = useEstrategiaAdaptacaoStore(
    (state) => state.selectedEstrategiaResultId
  )

  const selectEstrategiaResultId = useEstrategiaAdaptacaoStore(
    (state) => state.selectEstrategiaResultId
  )

  const selectEstrategiaResult = useEstrategiaAdaptacaoStore(
    (state) => state.selectEstrategiaResult
  )

  const optionsEditar = [
    {
      key: 'SiarlEditar',
      text: 'Editar',
    },
    {
      key: 'SiarlApagar',
      text: 'Apagar',
    },

  ]
  const optionsPonto = [

    {
      key: 'SiarlEditarGeometria',
      text: 'Editar Ponto',
    },

  ]

  const importarGeometria = [
    {
      key: 'ImportGeom',
      text: 'Importar Geom',
    },
  ]

  const optionsArea = [

    {
      key: 'SiarlEditarGeometria',
      text: 'Editar Área',
    },
    {
      key: 'removerArea',
      text: 'Remover Área',
    },
    {
      key: 'ImportGeom',
      text: 'Importar Geom',
    },
  ]

  const onEditItemClick = (event: any) => {
    if (event == "SiarlEditar" || event?.item?.key == "SiarlEditar") {
      setEditMode(true);
      setViewMode(false);
      handleAlteraTitulo();
      sessionStorage.setItem('novaAnaliseEstrategiaAdaptacao', 'false')
      return;
    }
    if (event == "SiarlVisualizar") {
      setEditMode(false);
      setViewMode(true);
      sessionStorage.setItem('novaAnaliseEstrategiaAdaptacao', 'false')
    }
    if (event?.item?.key == "SiarlApagar") {
      setDeleteClicked(true);
      setOpenModalDescartar(true);
    }
  }
  const itemRender = (props: { item: any; itemIndex: number }) => {
    const isTitleItem = props.item.key === 'titulo';
    if (isTitleItem) {
      // Item de título personalizado
      return (
        <div className={styles.DropDownButtonTitulo}>
          <span>{props.item.text}</span>
        </div>
      );
    } else {
      // Demais itens
      return (
        <div className={styles.DropDownButtonItem}>
          <span>{props.item.text}</span>
        </div>
      );
    }
  };

  const onClickOpenFileDoc = async () => {
    // TO DO OpenFileDoc

  }
  
  const onItemClick = (event: any) => {
    if(event?.item?.key == "ImportGeom"){
      ImportGeom(event.item)
     } else {
      selectMapAction(event.item)
     }    
  }

  const selectMapAction =  (item: any) => {
    const iframe: any = document.getElementById('iframeGeoPortal')

    if (item.key == "removerArea") {
      setOpenRemoveAreaModal(true)
    } else {
      iframe.contentWindow?.postMessage({ type: item.key, value: getEaRegistoAnalise()?.id, editGpLayer: EA_POINT_NAME }, '*')
    }
  }

  const removeArea = async () => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: 'true' }, '*')
    setCoordenadasPoligono(undefined);
    if (!selectedEstrategiaResult?.id) {
      return;
    }
    await removerAreaPrincipal(selectedEstrategiaResult?.id);
    await removerAreaApoio(selectedEstrategiaResult?.eaAbrangencia?.id  );
    
    iframe.contentWindow?.postMessage(
      { type: 'SiarlEaRegistoAnaliseSearch', value: [selectedEstrategiaResult?.id] },
      '*'
    )
    iframe.contentWindow?.postMessage(
      { type: 'SiarlEaAnaliseAbrangenciaSearch', value: [selectedEstrategiaResult?.eaAbrangencia?.id] },
      '*'
    )
    refreshForm(selectedEstrategiaResult?.id)
    setOpenRemoveAreaModal(false)
  }

  const onItemClickImprimir = async (event: any) => {
    
    try {
      let dataToSend = {
        ...getEaRegistoAnalise(),
        entidade:getEaRegistoAnalise()?.entidade_id?.nome, 
        utilizador: String(getEaRegistoAnalise()?.utilizador_id),
        utilizador_id: String(getEaRegistoAnalise()?.utilizador_id),
        data_hora_relatorio:getEaRegistoAnalise()?.data_hora_relatorio??new Date()
      }

      const  vus = SessionStorageService.getItem("resultadoVusEstrategiaAdaptacaoPesquisa")
      const acoes = SessionStorageService.getItem("resultadoEaAcaoPesquisa")
      if(vus){
        const vus = JSON.parse(SessionStorageService.getItem("resultadoVusEstrategiaAdaptacaoPesquisa"));
        dataToSend = {...dataToSend,vus  }
      }
      if(acoes){
        const acoes = JSON.parse(SessionStorageService.getItem("resultadoEaAcaoPesquisa"));
        dataToSend = {...dataToSend,acoes  }
      }
      
   
      const poligonoAbrangencia = SessionStorageService.getItem("poligonoAbrangencia");
      if(poligonoAbrangencia){
        const poligonoAbrangencia = JSON.parse(SessionStorageService.getItem("poligonoAbrangencia"));
        dataToSend = {...dataToSend,...poligonoAbrangencia  }
      } 


      const poligonoAnalise = SessionStorageService.getItem("poligonoAnalise");
      if(poligonoAnalise){
        const poligonoAnalise = JSON.parse(SessionStorageService.getItem("poligonoAnalise"));
        dataToSend = {...dataToSend,...poligonoAnalise  }
      } 

      
      const imgsAcao = SessionStorageService.getItem("imgsAcao");
      if(imgsAcao){
        const imgs = JSON.parse(SessionStorageService.getItem("imgsAcao"));
        dataToSend = {...dataToSend,...imgs  }
      } 

      const imgsVus = SessionStorageService.getItem("imgsVus");
      if(imgsVus){
        const imgs = JSON.parse(SessionStorageService.getItem("imgsVus"));
        dataToSend = {...dataToSend,...imgs  }
      }

      const analise = SessionStorageService.getItem("analise");
      if(analise){
        const imgs = JSON.parse(SessionStorageService.getItem("analise"));
        dataToSend = {...dataToSend,...imgs  }
      }


      console.log({dataToSend})
     const documentoBase64 = await relatorioEA(dataToSend)
      if (documentoBase64) {
        const link = document.createElement('a')
        link.href = 'data:application/octet-stream;base64,' + documentoBase64
        link.download = 'relatorio_analise.pdf'
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    } catch (error) {
      console.log({error})
      postMessage({ type: 'errorMsg', value: { message: "Não foi possivel efetuar esta operação" } }, '*')

    }finally{
      postMessage({ type: 'hideLoader' }, '*')

    }
  }

  const optionsExportar = [
    {
      key: 'titulo',
      text: 'Exportar',
    },
    {
      key: 'exportarFichas',
      text: 'Fichas',
    },
    {
      key: 'exportarResultados',
      text: 'Resultados',
    },
  ]
  const onItemClickExportar = (event: any) => {

    if (event.item.key == 'ExportarFichasResultados') {

      const link: HTMLAnchorElement | null = document.querySelector('#print-results');

      if (link) {
        link.click();
      }

    } else if (event.item.key == 'ExportarListaResultados') {

      console.log(event.item.text);

    }

  };
  const voltar = async () => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
       {
         type: 'SiarlCoordenadaBoxClose',
         value: 'true',
       },
       '*'
     )

     if(SessionStorageService.getItem("isSubmitActionEstrategiaAdaptacao") == "true" || sessionStorage.getItem("analiseAntiga") == "true"){
      postMessage({ type: 'form', value: 'pesquisarEstrategiaAdaptacao' }, '*')
     }
     else if (!SessionStorageService.getItem("isSubmitActionEstrategiaAdaptacao")){
      if(selectedEstrategiaResult?.id){
       const result = await deleteAnalise(selectedEstrategiaResult?.id)
        postMessage({ type: 'form', value: 'pesquisarEstrategiaAdaptacao' }, '*')
      }
      else{
        postMessage({ type: 'form', value: 'pesquisarEstrategiaAdaptacao' }, '*')
       }
     } else {
      postMessage({ type: 'form', value: 'pesquisarEstrategiaAdaptacao' }, '*')
     }
     
     

    setCoordenadasPoligono(undefined);
    setCoordenadasSelected(undefined);
    selectEstrategiaResultId(undefined)
    selectEstrategiaResult(undefined)
    iframe.contentWindow?.postMessage(
      { type: 'SiarlEaRegistoAnaliseClearLayers', value: "true" },
      '*'
    )
    iframe.contentWindow?.postMessage(
      { type: 'SiarlRemoveSelection', value: "true" },
      '*'
    )
  }

  const cancelModalDescartar = () => {
    setOpenModalDescartar(false)
  }

  const [estadoFalse, setEstado] = useState(false)


  const handleDescartar = async (e: any) => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
       {
         type: 'SiarlCoordenadaBoxClose',
         value: 'true',
       },
       '*'
     )
     iframe.contentWindow?.postMessage(
      {
        type: 'SiarlRemoveSelection',
        value: 'true',
      },
      '*'
    )
     setOpenModalDescartar(false)
     setDeleteClicked(false);

    const eaRegistoAnaliseSelected: any = getEaRegistoAnalise();

    const dataToSend : any = {
      id: eaRegistoAnaliseSelected?.id ?? undefined,
      status: estadoFalse
    }

      let AnaliseSaved: any
    if (eaRegistoAnaliseSelected?.id) {
         postMessage({ type: 'showLoader' }, '*')
          AnaliseSaved = await updateAnalise(dataToSend)
          selectEstrategiaResult({
            ...selectedEstrategiaResult,
            ...AnaliseSaved,})
            setEaRegistoAnalise(AnaliseSaved)
          postMessage({ type: 'hideLoader' }, '*')

          }
    SessionStorageService.removeItem("firstCallAnalise")
    postMessage({ type: 'form', value: 'pesquisarEstrategiaAdaptacao' }, '*')


    }

    const handleCheckboxChangeRetirada = (e: any) => {
      setCheckedRetirada(e.value);
  };

    const handleCheckboxChangeDefesa = (e: any) => {
      setCheckedDefesa(e.value);
  };

    const handleCheckboxChangeAdaptacao = (e: any) => {
      setCheckedAdaptacao(e.value);
  };
  
    const handleCheckboxChangeDA = (e: any) => {
      setCheckedDefesaAdaptacao(e.value);
  };

  const handleCheckboxChangeFlatCordinates = (e: any) => {
    if (getEaRegistoAnalise()?.id) {
      setRealizeNovaAnalise(true);
    }
    if (e.value == true) {
      postMessage({ type: 'infoMsg', value: { message: "Selecione a área no mapa" } }, '*')
    }
    
    setFlatCordinatesEA(e.value)
    setValidateGeomEA(false)
    
    setCheckboxPolignoChecked((prevChecked) => !prevChecked);
  };

  const handleCheckboxChangeFlatCordinatesApoio = (e: any) => {
    if (e.value == true) {
       postMessage({ type: 'infoMsg', value: { message: "Selecione a área no mapa" } }, '*')
    }
    setFlatCordinatesApoioEA(e.value);
  };

  

  const handleSelect = (e: TabStripSelectEventArguments, formRenderProps:   any) => {
    let validationError = false;
  
    // if (selected === 0 && !identificacaoTabIsValid) {
    //   validationError = true;
    // }
  
    // if (selected === 1 && (!parametroTabIsValid)) {
    //   validationError = true;
    // }

    setRealizeNovaAnalise(false);

    if(selected ===1 &&  (!getCheckedRetirada() && !getCheckedDefesa() && !getCheckedAdaptacao() && !getCheckedDefesaAdaptacao())){
      validationError = true
    }
    
    if (selected == 0 && (!getEaRegistoAnalise()?.hasGeom && !coordenadasPoligono)) {
      postMessage({ type: 'errorMsg', value: { message: "A geometria é obrigatória." } }, '*')
      formRenderProps.onSubmit();
      return false;
    }

     if (validationError) {
      postMessage({ type: 'errorMsg', value: { message: "Verifique os campos obrigatórios." } }, '*');
       formRenderProps.onSubmit();
       return false;
     }
    
    setTabSelection(true);
    formRenderProps.onSubmit();
    setSelected(e.selected);
  }

  const getFlatCoordinatesFromObj = (value: any) => {
    let polygonString = ''
    const values: any[] = value
    values?.forEach((value, index) => {
      if (index % 2 == 0) {
        polygonString += value + ' '
      } else {
        polygonString += value + ','
      }
    })
    polygonString = polygonString.substring(0, polygonString.length - 1)
    return polygonString
  }

  const refreshForm = async(id: any) => {
    
    const analise = await getAnalise(id);
    const userLocal = JSON.parse(
      localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}'
    )
    const tecnico = await GetUserNameTecnico(analise.utilizador_id);


  const objToShow = {
      ...analise,
      id: analise.id,
      nuts1: analise.nuts1 ?? undefined,
      nuts2: analise.nuts2 ?? undefined,
      nuts3: analise.nuts3 ?? undefined,
      distrito : analise.distrito ?? undefined,
      concelho: analise.mobilizacao ?? undefined,
      freguesia: analise.freguesia ?? undefined,
      arh: analise.arh ?? undefined,
      capitania : analise.capitania ?? undefined,
      massaagua: analise.massaagua ?? undefined,
      data_registo: analise?.data_registo? new Date(analise?.data_registo):null,
      data_hora_relatorio :analise?.data_hora_relatorio? new Date(analise?.data_hora_relatorio):null,
      filedoc_cod: analise?.filedoc_cod ?? undefined,
      estado_id: analise?.estado_id ?? undefined,
      utilizador_id: tecnico,
      entidade_id: analise?.entidade_id ?? undefined,
      titulo: analise?.titulo ?? undefined,
      poligono_area_analise: analise?.poligono_area_analise ?? undefined,
      poligono_area_abrangencia_obras_execucao: analise?.poligono_area_abrangencia_obras_execucao ?? undefined,
      retirada: analise?.retirada ?? undefined,
      defesa: analise?.defesa,
      adaptacao: analise?.adaptacao ?? undefined,
      defesa_adaptacao: analise?.defesa_adaptacao ?? undefined,
      salvaguarda_faixa_i: analise?.salvaguarda_faixa_i ?? undefined,
      salvaguarda_faixa_ii: analise?.salvaguarda_faixa_ii ?? undefined,
      markrisk_edificios: analise?.markrisk_edificios ?? undefined,
      universidade_nova: analise?.universidade_nova ?? undefined,
      servicos_ecosistema: analise?.servicos_ecosistema ?? undefined,
      lstTipologiasObra: preventPropsNullValues(analise?.lstTipologiasObra, "nome") ?? undefined,
      custo_adaptacao_edificio: analise?.custo_adaptacao_edificio ?? undefined,
      custo_demolicao: analise?.custo_demolicao ?? undefined,
      custo_renaturalizacao: analise?.custo_renaturalizacao ?? undefined,
   }
    selectEstrategiaResultId(analise.id as number)
    selectEstrategiaResult(objToShow)
    setEaRegistoAnalise(objToShow);
   }

   const ImportGeom = async (item: any) => {
    if (item.key == 'ImportGeom') {
      setOpenImportModal(true)
      
    }
  }

  const cancelImport = () => {
    setOpenImportModal(false)
  }

  const onImportGeomClick = (event: any) => {
    ImportGeom(event.item)
  }

   const importGeo = async (objToSend: any) => {
    postMessage({ type: "showLoader" }, "*");
    if (!objToSend) {
      postMessage({ type: "errorMsg", value: "Não foi possivel salvar" }, "*");
      postMessage({ type: "hideLoader" }, "*");
      return;

    }
    let ea_analise;
    let ea_registo_analise_id;
    const eaRegistoAnalise = getEaRegistoAnalise();
    if (eaRegistoAnalise && eaRegistoAnalise?.id) {
      ea_analise = eaRegistoAnalise;
      ea_registo_analise_id = eaRegistoAnalise?.id;
    } else {
      ea_analise = await saveAnalise({});
      ea_registo_analise_id = ea_analise?.id;
      selectEstrategiaResult(ea_analise);
      setEaRegistoAnalise(ea_analise)
      selectEstrategiaResultId(ea_registo_analise_id);

    }
    try {
      objToSend = { ...objToSend, ea_registo_analise_id }
      const result = await importGeomEaRegistoAnalise(objToSend)
      if (!result) {
        postMessage({ type: "errorMsg", value: "Não foi possivel salvar" }, "*");
        return;
      }

      try {
        ea_analise = await getAnalise(ea_registo_analise_id);
      } catch (error) {
        postMessage({ type: "errorMsg", value: "Registo salvo mas, Não é possivel mostrar os dados neste momento" }, "*");

      } finally {
        ea_analise = { ...ea_registo_analise_id, ...objToSend };
        selectEstrategiaResultId(ea_registo_analise_id);
        selectEstrategiaResult(ea_analise);
        setEaRegistoAnalise(ea_analise)
        refreshForm(ea_registo_analise_id);

      }
      try {
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage(
          { type: 'SiarlEaRegistoAnaliseSearch', value: [ea_registo_analise_id] },
          '*'
        )
      } catch (error) {
        postMessage({ type: "errorMsg", value: "Registo salvo mas, Não é possivel mostrar os dados neste momento" }, "*");
      }

      setEaResultado(true);

      setOpenImportModal(false)
    } catch (err) {
      handleGenericError(err);

    } finally {
      postMessage({ type: "hideLoader" }, "*");
      setCheckboxPolignoChecked(true);

    }

  }

   const handleSubmit = async (e: any) => {

    const eaRegistoAnaliseSelected: any = getEaRegistoAnalise();


    const isSubmitAction: boolean = JSON.parse(SessionStorageService.getItem("isSubmitActionEstrategiaAdaptacao") ?? false);
    const userId = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '')
    ?.user?.id;
    
  
     let validationError = false;

    const ea = await getAnalise(eaRegistoAnaliseSelected?.id);
    
    if(selected ===1 &&  (!getCheckedRetirada() && !getCheckedDefesa() && !getCheckedAdaptacao() && !getCheckedDefesaAdaptacao())){
      validationError = true
    }
    
    if (selected == 0 && (!ea.hasGeom && !coordenadasPoligono)) {
      postMessage({ type: 'errorMsg', value: { message: "A geometria é obrigatória." } }, '*')
      return false;
    } 
    if (validationError) {
      postMessage({ type: 'errorMsg', value: { message: "Verifique os campos obrigatórios." } }, '*')
      return false;
    }

    console.log(e);



    const dataToSend = {
      //TAB IDENTIFICACAO
      id: eaRegistoAnaliseSelected?.id ?? undefined,
      filedoc_cod: e?.values?.filedoc_cod ?? undefined,
      estado_id: e?.values?.estado_id?.id ?? undefined,
      data_registo: e?.values?.data_registo ?? undefined,
      utilizador_id: userId,
      entidade_id: e?.values?.entidade?.id ?? undefined,
      data_hora_relatorio: e?.values?.data_hora_relatorio ?? undefined,
      titulo: e?.values?.titulo ?? undefined,
      poligono_area_analise: e?.values?.poligono_area_analise ?? undefined,
      poligono_area_abrangencia_obras_execucao: e?.values?.poligono_area_abrangencia_obras_execucao ?? undefined,
      
      //Campos localização
      distrito_cod: e?.values?.distrito?.codigo ?? undefined,
      concelho_cod: e?.values?.concelho?.codigo ?? undefined,
      freguesia_cod: e?.values?.freguesia?.codigo ?? undefined,
      nuts1_cod: e?.values?.nuts1?.codigo ?? undefined,
      nuts2_cod: e?.values?.nuts2?.codigo ?? undefined,
      nuts3_cod: e?.values?.nuts3?.codigo ?? undefined,
      arh_id: e?.values?.arh?.codigo ?? undefined,

      //TAB PARAMETROS
      retirada: e?.values?.retirada ?? undefined,
      defesa: e?.values?.defesa ?? undefined,
      adaptacao: e?.values?.adaptacao ?? undefined,
      defesa_adaptacao: e?.values?.defesa_adaptacao ?? undefined,
      salvaguarda_faixa_i: e?.values?.salvaguarda_faixa_i ?? undefined,
      salvaguarda_faixa_ii: e?.values?.salvaguarda_faixa_ii ?? undefined,
      markrisk_edificios: e?.values?.markrisk_edificios ?? undefined,
      universidade_nova: e?.values?.universidade_nova ?? undefined,
      carta_ocupacao_solo: e?.values?.carta_ocupacao_solo ?? undefined,
      servicos_ecosistema: e?.values?.servicos_ecosistema ?? undefined,
      lstFinanciamento: e?.values?.lstFinanciamento ?? undefined,
      custo_adaptacao_edificio: e?.values?.custo_adaptacao_edificio ?? undefined,
      custo_demolicao: e?.values?.custo_demolicao ?? undefined,
      custo_renaturalizacao: e?.values?.custo_renaturalizacao ?? undefined,
      dt_periodo_analise_de: e?.values?.dt_periodo_analise_de ? new Date(e?.values?.dt_periodo_analise_de) : undefined,
      dt_periodo_analise_para: e?.values?.dt_periodo_analise_para ? new Date(e?.values?.dt_periodo_analise_para) : undefined,
      status: true,
    }
    try {
      let analiseSaved: any
      if (eaRegistoAnaliseSelected?.id) {
        analiseSaved = await updateAnalise(dataToSend)
      } else {
        analiseSaved = await saveAnalise(dataToSend)
      }
    console.log(selectedEstrategiaResult)
      refreshForm(analiseSaved?.id);
      if (selected < TOTAL_TABS && !isSubmitAction && !isTabSelection) {
        setSelected(selected + 1)
      }
  

      if (isSubmitAction && !viewMode) {
        postMessage({ type: 'sucessMsg' }, '*')
      }
    } catch(error) {
      handleGenericError(error)
    }
  }
  const innerStyleTabContent = {
    scrollBehavior: 'smooth',
    top: 55,
    height: '80vh',
  }

  const userName = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '')
    ?.user?.name;

  const handleCancel = ()  => {
        refreshForm(selectedEstrategiaResultId);
        postMessage({ type: "form", value: "" }, "*");
        setTimeout(() => {
            postMessage({ type: "form", value: "novaAnaliseEstrategiaAdaptacao" }, "*");
          }, 10)
    }

  const handleOpenModalDescartar = () => {
    if (
      sessionStorage.getItem('novaAnaliseEstrategiaAdaptacao') &&
      sessionStorage.getItem('novaAnaliseEstrategiaAdaptacao') == 'true'
    ) {
      setOpenModalDescartar(true);
    } else {
      onEditItemClick("SiarlVisualizar");
      handleCancel();
    }
  }
  
  const [columnsAcoes, setColumnsAcoes] = useState<{ columns: { field: string, title: string, width: number | string, show: boolean }[] }>({
    columns: [
        { field: "id", title: "ID", show: true, width: 70 },
        { field: "acaoEstado.descricao", title: "Estado", show: true, width: 170 },
        { field: "designacao", title: "Designação", show: true, width: 150 },
        { field: "tipo_n3.descricao", title: "Tipologia nivel 3", show: true, width: 150 },
        { field: "tipo_n4.descricao", title: "Tipologia nivel 4", show: true, width: 90 },
        { field: "data_ini_p", title: "Data de inicio", show: true, width: 'auto' },
        { field: "data_con_p", title: "Data de conclusão", show: true, width: 'auto' },
        { field: "val_contrat", title: "Valor executado", show: true, width: 'auto' },
        { field: "inv_realizado", title: "Tipologia nivel 2", show: true, width: 'auto' },
    ]
})  

const [columnsVus, setColumnsVus] = useState<{ columns: { field: string, title: string, width: number | string, show: boolean,footerCell:any }[] }>({
  columns: [
      { field: "id", title: "ID", show: true, width: 170, footerCell: "B" },
      { field: "usVus.descricao", title: "Vus", show: true, width: 190, footerCell: "C" },
      { field: "usVus.usTipoVus.descricao", title: "Tipo", show: true, width: 190, footerCell: "D"},
      { field: "valor", title: "valor", show: true, width: "auto", footerCell: "E" },
  ]
})

  useLayoutEffect(() => {

    const handleResize = () => {

      if (window.innerWidth <= telaMobile) {
        
        setIsMobile(true);
        ocultaFloatingMenu_e_Mapa();

      }else{

        setIsMobile(false);
        mostraFloatingMenu_e_Mapa();

      }

    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);  

  const onItemClickMais = (event: any) => {

    elementoClickMais(event);

    onEditItemClick(event);
    onImportGeomClick(event);
    onItemClick(event);
    
    if(optionsExportar.some(option => option.key === event.item.key)){
      onItemClickExportar(event);
    }

  };

  const optionsMais = [
    {
      key: 'titulo',
      text: 'Registo',
    },
    ...optionsEditar.map(option => ({
      ...option,
      className: 'icon-siarl icon-editar btnIconRegistar',
    })),
    {
      key: 'titulo',
      text: 'Geometria',
    },
    {
      key: 'titulo',
      text: 'Opções',
    },
    {
      key: 'openFileDoc',
      text: 'Diretório de documentos',
      className: 'icon-siarl icon-filedoc btnIconRegistar'
    },    
    {
      key: 'titulo',
      text: 'Ponto',
    },
    ...optionsPonto.map(option => ({
      ...option,
      className: 'icon-siarl icon-pin btnIconRegistar',
    })),
    {
      key: 'titulo',
      text: 'Área',
    },
    ...optionsArea.map(option => ({
      ...option,
      className: 'icon-siarl icon-area btnIconRegistar',
    })),
    {
      key: 'titulo',
      text: 'Imprimir',
    },
    {
      key: 'imprimirFicha',
      text: 'Fichas',
      className: 'icon-siarl icon-imprimir btnIconRegistar'
    },
    {
      key: 'titulo',
      text: 'Exportar',
    },
    ...optionsExportar.map(option => ({
      ...option,
      className: 'icon-siarl icon-exportar btnIconRegistar',
    })),
  ]

  const [tituloAnalise, setTituloAnalise] = useState(false);

  const handleAlteraTitulo = () => {
    setTituloAnalise(true);
  };

  const openAjuda = () => {
    window.open(URL_AJUDA_ESTRATEGIA_ADAPTACAO, "_blank")
  }

  return (
    <div className={styles.base}>
      <div className={styles.header}>

        <div>
          <div className={styles.nomeDoModulo}>
            Estratégia de adaptação
          </div>
          <div className={styles.tituloDaPagina}>
            {!selectedEstrategiaResult || !selectedEstrategiaResult?.id ? 'Nova Análise' : (tituloAnalise ? 'Editar Análise' : 'Análise')}
          </div>
        </div>
        <div>
           {!isMobile && canUpdate && (viewMode || editMode) && (sessionStorage.getItem("novaAnaliseEstrategiaAdaptacao") != 'true') ? 
        <DropDownButton
            items={optionsEditar}
            className={styles.btnEditar2}
            iconClass="icon-siarl icon-editar"
            onItemClick={onEditItemClick}
            fillMode={'flat'}
            itemRender={itemRender}
            title="Editar"
          ></DropDownButton>
          : ''}

        {!isMobile && (selected == 2 || selected == 3) &&
          <VisualizadorColunas
            setColumns={selected==3?setColumnsAcoes:selected==2?setColumnsVus:undefined}
            columns={selected==3?columnsAcoes:selected==2?columnsVus:undefined}
            styles={styles.btnEditar2}
          />
          }
          <button className={styles.btnEditar2} onClick={onClickOpenFileDoc}
          style={{ display: isMobile ? 'none' : '' }} id='openFileDoc'>
            <span className="icon-siarl icon-filedoc"></span>
          </button>

          {!isMobile &&
          <DropDownButton
            items={optionsArea}
            className={styles.btnEditar2}
            iconClass="icon-siarl icon-area"
            onItemClick={onItemClick}
            fillMode={'flat'}
            itemRender={itemRender}
            disabled={sessionStorage.getItem("novaAnaliseEstrategiaAdaptacao") != 'true' && !editMode}

            title="Geometria - Área"
          ></DropDownButton>
          }

          <button className={styles.btnEditar2} onClick={onItemClickImprimir}
          style={{ display: isMobile ? 'none' : '' }} id='imprimirFicha' title='Imprimir'>
            <span className="icon-siarl icon-imprimir"></span>
          </button>

          {!isMobile &&
          <DropDownButton
            items={optionsExportar}
            className={styles.btnImprimir}
            style={{marginTop: '-4px'}}
            iconClass="icon-siarl icon-exportar"
            onItemClick={onItemClickExportar}
            fillMode={'flat'}
            itemRender={itemRender}
            title="Exportar"
          ></DropDownButton>
          }

          {isMobile &&
            <DropDownButton
              items={optionsMais}
              className={styles.btnMais}
              iconClass="icon-siarl icon-mais"
              onItemClick={onItemClickMais}
              fillMode={'flat'}
              itemRender={itemRenderMais}
              title="Mais Opções"
            />
          }

          <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
            <span className="icon-siarl icon-ajuda"></span>
          </button>

          <button className={styles.btnFechar} onClick={voltar} title="Fechar">
            <span className="icon-siarl icon-fechar"></span>
          </button>

        </div>

        {openImportModal ?
         <ImportarGeometriaComponent onSubmit={importGeo} cancelImport={cancelImport} /> : <></>
        }

{openRemoveAreaModal ? (
               <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={() => setOpenRemoveAreaModal(false)}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                    <span className="textDescartar">Remover área </span>
                    <br></br>
                    <span>{'Tem a certeza que pretende remover a área selecionada?'}</span>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={() => setOpenRemoveAreaModal(false)}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={removeArea}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}

        {openModalDescartar ? (
          <Dialog

            className={styles.dialogDesconformidades}
            onClose={cancelModalDescartar}
          >

            <Form

              initialValues={
                {
                }
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                  <fieldset>
                    <span className="textDescartar">{deleteClicked ? 'Apagar' : 'Cancelar'} registo  </span>
                    <br></br>
                    <span>{deleteClicked ? 'Ao apagar este registo deixará de ter acesso, sendo necessária uma intervenção rigorosa para realizar a sua recuperação.' : 'Ao cancelar este registo irá perder os dados inseridos até ao momento.'}</span>
                    <br></br>
                    <span>{deleteClicked ? 'Tem a certeza que pretende apagar?' : 'Tem a certeza que pretende cancelar?'}</span>
                  </fieldset>
                  <div className="k-form-buttons btnModal">
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={cancelModalDescartar}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={handleDescartar}
                    >
                      Sim
                    </button>
                  </div>
                 
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}

      </div>
      <Form
        onSubmitClick={handleSubmit}
        initialValues={
          {
            ...getEaRegistoAnalise(),
            data_registo: getEaRegistoAnalise()?.data_registo
            ? new Date(getEaRegistoAnalise()?.data_registo)
            : new Date(),
            data_hora_relatorio: getEaRegistoAnalise()?.data_hora_relatorio
            ? new Date(getEaRegistoAnalise()?.data_hora_relatorio)
            : undefined, 
            dt_periodo_analise_de : getEaRegistoAnalise()?.dt_periodo_analise_de ? new Date(getEaRegistoAnalise()?.dt_periodo_analise_de) : undefined,
            dt_periodo_analise_para: getEaRegistoAnalise()?.dt_periodo_analise_para ? new Date(getEaRegistoAnalise()?.dt_periodo_analise_para) : undefined,          
            entidade_registo: entidadeUser,
         
          } ?? undefined
        }
        render={(formRenderProps: FormRenderProps) => (
          <>
            <FormElement>
              <TabStrip selected={selected} onSelect={(e) => { handleSelect(e, formRenderProps) }} 
              className={`${styles.innerStyleTabContent} ${viewMode ? 'tabsVisualizar' : ''}`}
              scrollable={isMobile}>
                <TabStripTab title="Identificação" key={"Identificação"}>
                <IdentificacaoEstrategiaAdaptacao onCheckboxChangeFlatCordinates={handleCheckboxChangeFlatCordinates} onCheckboxChangeFlatCordinatesApoio={handleCheckboxChangeFlatCordinatesApoio} disabled={disabledPolignoApoio} isChecked={isCheckboxPolignoChecked} viewMode={viewMode} formRenderProps={formRenderProps} realizeNovaAnalise={realizeNovaAnalise}/>
                </TabStripTab>
                <TabStripTab title="Parâmetros" key={"Parâmetros"}>
                  <EstrategiaAdaptacaoParametros viewMode={viewMode} onCheckboxChangeDefesa={handleCheckboxChangeDefesa} onCheckboxChangeRetirada={handleCheckboxChangeRetirada} onCheckboxChangeAdaptacao={handleCheckboxChangeAdaptacao} onCheckboxChangeDA={handleCheckboxChangeDA}  formRenderProps={formRenderProps}/>
                </TabStripTab>
                <TabStripTab title="VUS" key={"VUS"}>
                  <VusEstrategiaAdaptacao  viewMode={viewMode} formRenderProps={formRenderProps} colunasVisiveis={columnsVus}/>
                </TabStripTab>
                <TabStripTab title="Ações" key={"Ações"}>
                <AcaoEstrategiaAdaptacao  viewMode={viewMode} colunasVisiveis={columnsAcoes}/>
                </TabStripTab>
                <TabStripTab title="Análise" key={"Análise"}>
                <AnaliseEstrategiaAdaptacao  viewMode={viewMode}/>
                </TabStripTab>
                <TabStripTab title="Documentos" key={"Documentos"}>
                  <DocumentoListagem pageName='estrategiaAdaptacao' viewMode={viewMode} formRenderProps={formRenderProps} />
                </TabStripTab>
              </TabStrip>
            </FormElement>

            {(editMode || sessionStorage.getItem("novaAnaliseEstrategiaAdaptacao") == 'true')
            && !viewMode ?
            <div className={`${styles.footer} ${styles.justify_multiple_children}`}>
              <div className={styles.botoesBrancosFooter}>
                <Button
                  type="button"
                  className={styles.btnSeguinte}
                  onClick={handleOpenModalDescartar}
                  title='Cancelar'
                >
                    <span className="icon-siarl icon-fechar"></span>
                    <span className={styles.rotulo}>
                      {' '}&nbsp;{' '}
                      Cancelar
                    </span>
                </Button>
                <Button
                  className={styles.btnAnterior}
                  disabled={selected === 0}
                  onClick={(e) => {
                    if (selected !== 0) {
                      SessionStorageService.setItem("isSubmitActionEstrategiaAdaptacao", "false");
                      setSelected(selected - 1);
                      e.preventDefault();
                    }
                  }}
                  title='Anterior'
                >
                  <span className="icon-siarl icon-anterior"></span>
                  <span className={styles.rotulo}>
                    {' '}&nbsp;{' '}
                    Anterior
                  </span>
                </Button>
                <Button
                  className={styles.btnSeguinte}
                  disabled={selected === TOTAL_TABS}
                  onClick={(e) => {
                    SessionStorageService.setItem("isSubmitActionEstrategiaAdaptacao", "false");
                    setTabSelection(false);
                    formRenderProps.onSubmit(e);
                  }}
                  title='Seguinte'
                >
                    <span className={styles.rotulo}>
                      Seguinte
                      {' '}&nbsp;{' '}
                    </span>
                    <span className="icon-siarl icon-seguinte"></span>
                </Button>
              </div>

             {selected === 0 && !editMode ? (
              <Button
                type="button"
                className={styles.btnDisabled}
                disabled={selected === 0 && !editMode}
                onClick={(e) => {
                  // setIsSubmitAction(true)
                  SessionStorageService.setItem("isSubmitActionEstrategiaAdaptacao", "true");
                  formRenderProps.onSubmit(e);
                }}
                title={editMode ? 'Gravar' : 'Submeter'}
              >
                <span className="icon-siarl icon-submeter btnIconRegistar btnDisabled"></span>
                <span className={styles.rotulo}>
                  {' '}&nbsp;{' '}
                  {editMode ? 'Gravar' : 'Submeter'}
                </span>
              </Button>
            ) : (

               <Button
                className={styles.btnSubmeter}
                type="button"
                onClick={(e) => {
                  // setIsSubmitAction(true)
                  SessionStorageService.setItem("isSubmitActionEstrategiaAdaptacao", "true");
                  formRenderProps.onSubmit(e);   
                             }}
                             title={editMode ? 'Gravar' : 'Submeter'}
              >
                <span className="icon-siarl icon-submeter btnIconRegistar"></span>
                <span className={styles.rotulo}>
                  {' '}&nbsp;{' '}
                  {editMode ? 'Gravar' : 'Submeter'}
                </span>
              </Button>
            )}
            </div>
            : ''}

          </>
        )}
      />

    </div>

  );
}
