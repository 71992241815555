import { Button } from '@progress/kendo-react-buttons'

import {
  MouseEvent as ReactMouseEvent,
  useEffect,
  useLayoutEffect,
  useState
} from 'react'

import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from '@progress/kendo-react-layout'
import SessionStorageService from '../../services/SessionStorageService'
import {
  get,
  getOcorrenciaSelected,
  hasPermission,
  post,
  setIsEditingOcorrencia,
  useCombos
} from '../../services/utils'
import styles from '../../styles/ocorrencia/ConsultarOcorrenciaForm.module.scss'
import {
  MD_WINDOW_WIDTH,
  PERMISSION_CONSULTAR_OCORRENCIA,
  PERMISSION_INSERIR_OCORRENCIA,
  PERMISSION_OCORRENCIA_PERFIL_COMPLETO_EDICAO,
  PERMISSION_OCORRENCIA_PERFIL_COMPLETO_LEITURA,
  PERMISSION_OCORRENCIA_PERFIL_INTERMEDIO_EDICAO,
  PERMISSION_OCORRENCIA_PERFIL_INTERMEDIO_LEITURA,
  PERMISSION_OCORRENCIA_PERFIL_TOTAL,
  PESQUISAR_ENTIDADEBYUSER,
  PESQUISAR_OCORRENCIAS_SIMPLES,
  SM_WINDOW_WIDTH,
  URL_AJUDA_OCORRENCIA_PESQUISA_AVANCADA,
  URL_AJUDA_OCORRENCIA_PESQUISA_SIMPLES,
  XS_WINDOW_WIDTH
} from '../../utils/constants'
import ConsultarOcorrenciaAvancadaForm from './ConsultarOcorrenciaAvancadaForm'
import ConsultarOcorrenciaSimplesForm from './ConsultarOcorrenciaSimplesForm'
import { FormElement } from '@progress/kendo-react-form'
import { Form } from 'react-router-dom'
import ReactGA from 'react-ga4';
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, mostraFloatingMenu_e_Mapa_e_Oculta_Form, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais'

type Props = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
}

export default function ConsultarOcorrenciaTabsForm({ onCloseClick }: Props) {
  const [isMobile, setIsMobile] = useState(false);
  const [isXs, setIsXs] = useState(false);
  const [isSm, setIsSm] = useState(false);
  const [isMd, setIsMd] = useState(false);
  const [dataDe, setDataDe] = useState<any>()
  const [freguesiasFiltred, setFreguesiasFiltred] = useState<any>()
  const [selected, setSelected] = useState<number>(0)
  const getOcorrencias = async (data: any) =>
    await post<any, any>(`${PESQUISAR_OCORRENCIAS_SIMPLES}`, data)
  const getEntidadeByUser = async (data: any) =>
    await get<any, any>(`${PESQUISAR_ENTIDADEBYUSER}/` + data)
  const [percentLeftMsg, setPercentLeftMsg] = useState("19%");

  const showAdvancedFilters = () => {
    return hasPermission(PERMISSION_CONSULTAR_OCORRENCIA) ||
    hasPermission(PERMISSION_OCORRENCIA_PERFIL_COMPLETO_EDICAO) ||
    hasPermission(PERMISSION_OCORRENCIA_PERFIL_INTERMEDIO_EDICAO) ||
    hasPermission(PERMISSION_OCORRENCIA_PERFIL_COMPLETO_LEITURA) ||
    hasPermission(PERMISSION_OCORRENCIA_PERFIL_TOTAL) ||
    hasPermission(PERMISSION_OCORRENCIA_PERFIL_INTERMEDIO_LEITURA)
  }

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/ConsultarOcorrenciasTabsForm" });
  }, [])

  const handleSubmit = async (e: any) => {
    console.log(JSON.stringify(e.values))
    try {
      postMessage({ type: 'showLoader' }, '*')
      const result = await getOcorrencias({
        data_inicio: e.values.De,
        data_fim: e.values.Ate,
        freguesia_cod: e.values?.freguesia
          ? e.values?.freguesia.dicofre
          : undefined,
        praia: e.values?.praia?.id,
      })
      if (result) {
        SessionStorageService.setItem(
          'resultadoOcorrenciasPesquisa',
          JSON.stringify(result)
        )
        postMessage({ type: 'resultadoOcorrenciasPesquisaChange' }, '*')
      }
    } catch (error) {
      postMessage({ type: 'errorMsg', value:{message:"Não foi possivel efetuar esta operação"} }, '*')
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  const registarNovoClick = async () => {
    setIsEditingOcorrencia(false);
    SessionStorageService.setItem('ocorrenciaSubmetida', 'false')
    sessionStorage.setItem('novoPontoOcorrencia', 'true')
    SessionStorageService.setItem('novaOcorrencia', 'true')
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
      { type: 'SiarlGeoPortalAdicionarPonto', value: 'true' },
      '*'
    )
   postMessage(
      { type: 'infoMsgMapa',  value: { message: 'Selecione o local no mapa' , left: (isMobile? (Number(percentLeftMsg.split('%')[0])-5)+'%' : '42%') } },
      '*'
    )
    postMessage({type: "closeForms", value: ""}, "*");
   
  }
  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected)
  }

  useLayoutEffect(() => {

    const handleResize = () => {
      const currentWindowWidth = window.innerWidth
      if (currentWindowWidth <= telaMobile) {
        // Mobile Configs
        setIsMobile(true)
        setIsXs(false)
        setIsSm(false)
        setIsMd(false)
        ocultaFloatingMenu_e_Mapa()
      }else if (currentWindowWidth <= XS_WINDOW_WIDTH) {
        // Extrasmall Screen Configs
        setIsMobile(false)
        setIsXs(true)
        setIsSm(true)
        setIsMd(false)
      } else if (currentWindowWidth <= SM_WINDOW_WIDTH) {
        // Small Screen Configs
        setIsMobile(false)
        setIsXs(false)
        setIsSm(true)
        setIsMd(false)
      } else if (currentWindowWidth <= MD_WINDOW_WIDTH) {
        // Medium Screen Configs
        setIsMobile(false)
        setIsXs(false)
        setIsSm(false)
        setIsMd(true)
      } else {
        // Desktop Configs
        setIsMobile(false)
        setIsXs(false)
        setIsSm(false)
        setIsMd(false)
        mostraFloatingMenu_e_Mapa()
      }
    }
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []); 

  const onItemClickMais = (event: any) => {
 
    if (event.item.key == 'novoRegisto') {

      elementoClickMais(event);

      mostraFloatingMenu_e_Mapa();

    }

  };

  const optionsMais = [
    {
      key: 'novoRegisto',
      text: 'Nova ocorrência',
      elementType: 'button',
      className: 'icon-siarl icon-registar btnIconRegistar'
    }
  ]

  const openAjuda = () => {
    if (SessionStorageService.getItem("ocorrenciasPesquisaTipo")) {
      const ocorrenciasPesquisaTipo = SessionStorageService.getItem("ocorrenciasPesquisaTipo");
      if (ocorrenciasPesquisaTipo == "Simples") {
        window.open(URL_AJUDA_OCORRENCIA_PESQUISA_SIMPLES, "_blank");
      } else if (ocorrenciasPesquisaTipo == "Avancada") {
        window.open(URL_AJUDA_OCORRENCIA_PESQUISA_AVANCADA, "_blank");
      }
    }
  }

  return (
    <div className={styles.base}>

      <div
        className={styles.header}
        style={{ flexDirection: isSm ? 'column' : 'row', gap: '8px' }}
      >

        <div style={isXs ? {display: "flex", alignItems:"center", flexDirection: "column"} : {}}>
          <div className={styles.nomeDoModulo}>
            Ocorrências
          </div>
          <div className={styles.tituloDaPagina}>
            Critérios de pesquisa
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: isMobile ? 'flex-end' : 'space-between',
            alignItems:  isMobile ? 'flex-end': 'center',
            gap: '8px',
            flexDirection: isMobile ? 'column-reverse' : isXs ? "column" : isMd ? 'column' : 'row',
            marginBottom: isMobile ? '8px' : '0px'
          }}
        >

      {hasPermission(PERMISSION_INSERIR_OCORRENCIA) ||
        hasPermission(PERMISSION_OCORRENCIA_PERFIL_TOTAL) ||
        hasPermission(PERMISSION_OCORRENCIA_PERFIL_INTERMEDIO_EDICAO) ||
        hasPermission(PERMISSION_OCORRENCIA_PERFIL_COMPLETO_EDICAO)
         ?             <Button
              onClick={registarNovoClick}
              className={styles.btnNovoRegisto}
              //style={{ height: '38px', margin: 0 }}
              id="novoRegisto"
              title="Nova ocorrência"
            >
                <span className="icon-siarl icon-registar btnIconRegistar"/>
                {!(isMobile) && <span> &nbsp;{' '}Nova ocorrência</span>}
            </Button>
        : ''}              
          <div style={{display: "flex", gap: "8px"}}>
            <button
              onClick={openAjuda}
              className={styles.btnInfo}
              style={{margin: 0}}
              title="Obter Ajuda"
            >
              <span className="icon-siarl icon-ajuda"></span>
            </button>

            <button
              className={styles.btnFechar}
              onClick={() => {
                mostraFloatingMenu_e_Mapa_e_Oculta_Form();
              }}
              title="Minimizar"
            >
              <span className="icon-siarl icon-minimizar"></span>
            </button>
          </div>

        </div>

      </div>

          {hasPermission(PERMISSION_OCORRENCIA_PERFIL_TOTAL) || hasPermission(PERMISSION_OCORRENCIA_PERFIL_COMPLETO_EDICAO) || hasPermission(PERMISSION_OCORRENCIA_PERFIL_COMPLETO_LEITURA) || hasPermission(PERMISSION_OCORRENCIA_PERFIL_INTERMEDIO_EDICAO) || hasPermission(PERMISSION_OCORRENCIA_PERFIL_INTERMEDIO_LEITURA) ?
            <ConsultarOcorrenciaAvancadaForm onCloseClick={onCloseClick} />
          :
            <ConsultarOcorrenciaSimplesForm onCloseClick={onCloseClick} />
        }
    </div>
  )
}