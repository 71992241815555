import { Button, DropDownButton } from '@progress/kendo-react-buttons'
import { process } from '@progress/kendo-data-query';
import { PagerTargetEvent } from '@progress/kendo-react-data-tools'
import { format } from 'date-fns'
import { CSVLink } from 'react-csv'
import { get, post, setCoordenadasSelected, setIsEditingOcorrencia, setIntervencaoSelected, setOcorrenciaSelected, handleGenericError, hasPermission, ocEstados, handleDownload, exportPDFWithMethod, b64toBlob, GetUserNameTecnico } from '../../services/utils'
import { useOcorrenciaStore } from '../../stores/ocorrencias'
import {
  AUTH_LOCALSTORAGE,
  CURRENT_TIMEZONE,
  GET_DOCUMENTO,
  GET_EMAIL_BY_USER_ID,
  GET_OCORRENCIA,
  GET_OCORRENCIAS_SHAPEFILE,
  GET_USER_BY_ID,
  LAYER_INDEX_OCORRENCIAS,
  LAYER_INDEX_OCORRENCIA_APOIO,
  OPERATIONAL_LAYER,
  PERMISSION_CONSULTAR_OCORRENCIA,
  PERMISSION_DOCUMENTOS_CONSULTA_AVANCADA,
  PERMISSION_INSERIR_OCORRENCIA,
  PERMISSION_OCORRENCIA_PERFIL_COMPLETO_EDICAO,
  PERMISSION_OCORRENCIA_PERFIL_COMPLETO_LEITURA,
  PERMISSION_OCORRENCIA_PERFIL_INTERMEDIO_EDICAO,
  PERMISSION_OCORRENCIA_PERFIL_INTERMEDIO_LEITURA,
  PERMISSION_OCORRENCIA_PERFIL_TOTAL,
  PESQUISAR_DOCUMENTOS_BY_OCORRENCIAS,
  PESQUISAR_OCORRENCIAS_AVANCADA,
  PESQUISAR_OCORRENCIAS_LIST_RESULTS,
  PESQUISAR_OCORRENCIAS_SIMPLES,
  RECORDS_LIMIT,
  RELATORIO_MERGE,
  URL_AJUDA_OCORRENCIA_PESQUISA_AVANCADA,
  URL_AJUDA_OCORRENCIA_PESQUISA_SIMPLES,
} from '../../utils/constants'
import { ResultadoOcorrenciaCommandGridCell } from './ResultadoOcorrenciaCommandGridCell'


import styles from '../../styles/ocorrencia/OcorrenciasStylesv2.module.scss'
import commonStyles from '../../styles/common/Common.module.scss'

import {
  Dispatch,
  MouseEvent as ReactMouseEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
  useRef
} from 'react'

import {
  Grid,
  GridCellProps,
  GridColumn,
  GridColumnMenuCheckboxFilter,
  GridColumnMenuFilter,
  GridColumnMenuGroup,
  GridColumnMenuSort,
  GridPageChangeEvent,
  GridToolbar,
} from '@progress/kendo-react-grid'

import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from '@progress/kendo-react-layout'
import SessionStorageService from '../../services/SessionStorageService'
import CustomGridCell from './CustomGridCell'
import { useCommonStore } from '../../stores/common'
import clsx from 'clsx'
import MaximizeButton from '../common/MaximizeButton'
import { ResultadoDocumentoCommandGridCell } from '../documentos/ResultadoDocumentoCommandGridCell'
import { Dialog } from '@progress/kendo-react-dialogs'
import ImagePreviewV2 from '../viewer/ImagePreviewV2'
import VisualizadorColunas from '../Generico/VisualizadorColunas '
import useVisibleColumns, { Key } from '../../hooks/useVIsibleColumns'
import React from 'react';
import ReactGA from 'react-ga4';
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais'
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import moment from 'moment';
import "moment-timezone"
let ocorrenciasDataFilter : any = [];
let selectTab : any = 0;
let documentosOcorrenciasFilter : any = [];
type Props = {
  onCloseClick: () => void | undefined
  setShowResults: Dispatch<SetStateAction<boolean>>
  setShowOcorrenciaForm?: Dispatch<SetStateAction<boolean>>
}

type PageState = {
  skip: number
  take: number
}
type PageState2 = {
  skip: number
  take: number
}
type SortState = {
  sort: []
}
type SortStateDocumentos = {
  sort: []
}
const initialDataState: PageState = { skip: 0, take: 20 }
const initialDataState2: PageState = { skip: 0, take: 20 }
const keyPainelPrincipal:Key = 'ocorrenciaColunasVisiveis';
const inicialSortState : SortState = {sort : []}
const inicialSortStateDocumentos : SortStateDocumentos = {sort : []}
const keyPainelDocumentos:Key = 'ocorrenciaDocumentosColunasVisiveis';
export default function ResultadoOcorrenciaForm({
  onCloseClick,
  setShowResults,
  setShowOcorrenciaForm,
}: Props) {
  const [isMobile, setIsMobile] = useState(false);
  const [page, setPage] = useState<PageState>(initialDataState)
  const [page2, setPage2] = useState<PageState>(initialDataState2)
  const [sort, setsort] = useState<SortState>(inicialSortState)
  const [sortDocumentos, setsortDocumentos] = useState<SortStateDocumentos>(inicialSortStateDocumentos)
  const [pageSizeValue, setPageSizeValue] = useState<
    number | string | undefined
  >()
  const [ocorrencias, setOcorrencias] = useState([])
  const [documentosOcorrencias, setDocumentosOcorrencias] = useState([])
  const selectOcorrenciaResult = useOcorrenciaStore(
    (state) => state.selectOcorrenciaResult
  )
  const selectOcorrenciaResultId = useOcorrenciaStore(
    (state) => state.selectOcorrenciaResultId
  )

  const [openFormViewDocumento, setOpenFormViewDocumento] = useState<boolean>(false)
  const [documentos, setDocumentos] = useState([])
  const [urlDoDocumento, setUrlDoDocumento] = useState("");
  const [idDoDocumento, setIdDoDocumento] = useState("");
  const [ocorrenciasFinal, setOcorrenciasFinal] = useState<any>([]);
  const [isSmallerThan1350, setIsSmallerThan1350] = useState(false);
  const [isSmallerThan1120, setIsSmallerThan1120] = useState(false);
  const [isSmallerThan850, setIsSmallerThan850] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [pageable, setPageable] = useState<any>({
    buttonCount: 5,
    info: true,
    type: 'numeric',
    pageSizes: true,
    previousNext: true,
  })

  
  const getEmailByUserId = async (data: any) =>
    await get<any, any>(`${GET_EMAIL_BY_USER_ID}/` + data)

  const getOcorrencias = async (data: any) =>
  await post<any, any>(`${PESQUISAR_OCORRENCIAS_AVANCADA}`, data)
  const getOcorrenciasSimples = async (data: any) =>
  await post<any, any>(`${PESQUISAR_OCORRENCIAS_SIMPLES}`, data)

  const getShapeFile = async (data: any) =>
  await post<any, any>(`${GET_OCORRENCIAS_SHAPEFILE}`, data)
  
  const isCurrentViewMaximized = useCommonStore(state => state.isCurrentViewMaximized)

  const restoreView = useCommonStore(state => state.restoreView)
  const {getVisibleColumns, removeColumnsKeys} = useVisibleColumns();

  
  const getOcorrenciasPorIds = async (data: any) =>
    await post<any, any>(`${PESQUISAR_OCORRENCIAS_LIST_RESULTS}`, data) 

  const relatorioOcorrenciasMerge = async (data: any) =>
    await post<any, any>(`${RELATORIO_MERGE}`, data)

  const getDocumentosByOcorrencias = async (data: any) =>
    await post<any, any>(`${PESQUISAR_DOCUMENTOS_BY_OCORRENCIAS}`, data)
  const [openForm, setOpenForm] = useState<boolean>(false)

  const getOcorrencia = async (data: any) =>
    await get<any, any>(`${GET_OCORRENCIA}/` + data)

  const getDocumento = async (data: any) =>
    await get<any, any>(`${GET_DOCUMENTO}/` + data)
  
  const getUserById = async (data: any) =>
  await get<any, any>(`${GET_USER_BY_ID}/` + data)

  const handleMessageEvent = useCallback((event: MessageEvent) => {
    if (event.data.type == "resultadoOcorrenciasPesquisaChange") {
      SessionStorageService.setItem("resultadoOcorrenciasPesquisa", event.data.value);
    }
  }, [])

    const [dataState, setDataState] = React.useState({
    skip: 0,
    take: 20,
    sort: [],
    group: [],
  });

  const [dataStateDocumentos, setDataStateDocumentos] = React.useState({
    skip: 0,
    take: 20,
    sort: [],
    group: [],
  });

  const selectedOcorrenciaResult: any = useOcorrenciaStore(
    (state) => state.selectedOcorrenciaResult
  )
  
    const dataStateChangeDocumentos = (event: any) => {
    setDataResultDocumentos(process(documentosOcorrencias, event.dataState));
    const skip2: PageState = { skip: event.dataState.skip, take: event.dataState.take}
  
    setPage({
      ...skip2
    })

    const sortable: SortStateDocumentos = { sort: event.dataState.sort}
    
    setsortDocumentos({...sortable})

    
    //setDataStateDocumentos(event.dataState);
  };

  const [dataResultDocumentos, setDataResultDocumentos] = React.useState(
    process(documentos, dataStateDocumentos)
  );

  const dataStateChange = (event: any) => {
    setDataResult(process(ocorrencias, event.dataState));
    const skip2: PageState2 = { skip: event.dataState.skip, take: event.dataState.take}
    setPage2({
      ...skip2
    })
    const sortable: SortState = { sort: event.dataState.sort}
    
    setsort({...sortable})
    //setDataState(event.dataState);
    
  };

   const [dataResult, setDataResult] = React.useState(
    process(ocorrencias, dataState)
  );

  const [percentLeftMsg, setPercentMsg] = useState("19%");

  useEffect(() => {
    ocorrenciasDataFilter = dataResult.data;
  }, [dataResult])

  useEffect(() => {
    documentosOcorrenciasFilter = dataResultDocumentos.data;
  }, [dataResultDocumentos])

  useEffect(() => {
    window.addEventListener(
      'message',
      handleMessageEvent,
      false
    )

    return () => {
      window.removeEventListener(
        'message',
        handleMessageEvent,
        false
      )
    }
  }, [handleMessageEvent])

  const initialized = useRef(false)
  useEffect(() => {
      if (!initialized.current) {
        initialized.current = true;
      
      if (SessionStorageService.getItem("lastSearchOcorrenciasAvancada") && !SessionStorageService.getItem("firstCallOcorrencias")) {
        
        setTimeout(() => {
          const objToSearch = SessionStorageService.getItem("lastSearchOcorrenciasAvancada") ?? '';
          getOcorrencias(objToSearch).then((data) => {
            SessionStorageService.setItem("resultadoOcorrenciasPesquisa", JSON.stringify(data));
            setResultsOcorrencia();
          })},
          1000);
        } else if (SessionStorageService.getItem("lastSearchOcorrencias") && !SessionStorageService.getItem("firstCallOcorrencias")) {
          setTimeout(() => {
          const objToSearch = SessionStorageService.getItem("lastSearchOcorrencias") ?? '';
          getOcorrenciasSimples(objToSearch).then((data) => {
            SessionStorageService.setItem("resultadoOcorrenciasPesquisa", JSON.stringify(data));
            setResultsOcorrencia();
          });},
          1000);
      }
      else {
      setResultsOcorrencia();
    }
  }
  }, [])

  const setResultsOcorrencia = () => {
    const results = JSON.parse(
      SessionStorageService.getItem('resultadoOcorrenciasPesquisa') ?? ''
    )

    if (results) {
      const iframe: any = document.getElementById('iframeGeoPortal')
      const ids: number[] = []
      const idsApoio: number[] = []
      results.forEach((element: any) => {
        ids.push(element.id)
      })
      iframe.contentWindow?.postMessage(
        { type: 'SiarlOcorrenciasSearch', value: ids },
        '*'
      )
    }

    const formattedResults = results.map((ocorrencia: any) => {
      const dataOcorrencia = ocorrencia.data_ocorrencia ? new Date(ocorrencia.data_ocorrencia + 'Z').toLocaleString(undefined, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }) : 'Data indeterminada';
      const dataRegisto = new Date(ocorrencia.data_registo + 'Z').toLocaleString(undefined, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      });
      let localizacao_concat = '';
      if (ocorrencia.praia) {
        localizacao_concat = ocorrencia.praia + ', ' + ocorrencia.concelho
      } else if (ocorrencia.ref_localizacao) 
      {
        localizacao_concat = ocorrencia.ref_localizacao + ' - ' + ocorrencia.concelho
      } else {
        localizacao_concat = ocorrencia?.concelho;
      }
      return {
        ...ocorrencia,
        localizacao_concat: localizacao_concat,
        data_ocorrencia: dataOcorrencia,
        data_registo: dataRegisto,
      };
    });
    
    sessionStorage.removeItem('sistemasNaturais')
    sessionStorage.removeItem('pessoaEmRisco')
    sessionStorage.removeItem('medidasMetigacao')
    sessionStorage.removeItem('detecaoOcorrencia')
    sessionStorage.removeItem('highlightClicked')
    ocorrenciasDataFilter = formattedResults;
    setOcorrencias(formattedResults);
    setOcorrenciasFinal(formattedResults.slice(page2.skip, page2.take + page2.skip))
    getVisibleColumns(keyPainelPrincipal, setColums)
    getVisibleColumns(keyPainelDocumentos, setColumsDocuments)
  
  
    postMessage({ type: 'hideLoader' }, '*');

    try {
      const idsOcorrencias = results.map((o: any) => o.id)
      getDocumentosByOcorrencias(idsOcorrencias).then(response => {
        setDocumentosOcorrencias(response.map((item: any) => ({
          ...item,
          hasGeom: true,
          data_registo: moment.utc(item.data_registo).tz(CURRENT_TIMEZONE).format("DD/MM/yyyy HH:mm")
        })))
        documentosOcorrenciasFilter = (response.map((item: any) => ({
          ...item,
          data_registo: moment.utc(item.data_registo).tz(CURRENT_TIMEZONE).format("DD/MM/yyyy HH:mm")
        })))
        console.log(response)
      })

    } catch (error) {
      console.error('Erro ao buscar os dados:', error)
    }
  }

  useEffect(() => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    const ids: number[] = []
    ocorrencias.forEach((element: any) => {
      ids.push(element.id)
    })
    iframe.contentWindow?.postMessage(
      { type: 'SiarlOcorrenciasSearch', value: ids },
      '*'
    )
  }, [])

  const openPdfNewTab = () => {
    return new Promise<void>((resolve, reject) => {

        setPageable(false);
        postMessage({ type: "showLoader" }, "*");

        setTimeout(() => {
            const columnsToShow = colums.columns.filter(x => x.show);
            const { skip, take } = page2;

            const dataToExport = ocorrencias.slice(skip, skip + take).map((item: any) => {
                const filteredItem: any = {};
                columnsToShow.forEach((column: any) => {
                    filteredItem[column.field] = item[column.field];
                });
                return filteredItem;
            });

            exportPDFWithMethod(columnsToShow, dataToExport, 5)
                .then((pdfData: any) => {
                    postMessage({ type: "hideLoader" }, "*");

                    setPageable({
                        buttonCount: 5,
                        info: true,
                        type: 'numeric',
                        pageSizes: true,
                        previousNext: true,
                    });

                    const base64Data = pdfData.split(";base64,")[1];
                    const blob = b64toBlob(base64Data, 'application/pdf');
                    const blobUrl = URL.createObjectURL(blob);

                    window.open(blobUrl, '_blank');
                    resolve();

                })
                .catch(error => {
                    console.error('Error exporting PDF:', error);
                    reject(error);
                });
        }, 1000);
    });
};
    
    const downloadPdf =() => {
      return new Promise<void>((resolve, reject) => {
            
        setPageable(false);
        postMessage({ type: "showLoader" }, "*");

        setTimeout(() => {
          const columnsToShow = colums.columns.filter(x => x.show);
          const dataToExport = ocorrencias.map((item: any) => {
              const filteredItem: any = {};
              columnsToShow.forEach((column: any) => {
                  filteredItem[column.field] = item[column.field];
              });
              return filteredItem;
          });
          
          exportPDFWithMethod(columnsToShow, dataToExport, 5)
              .then((pdfData: any) => {
                  postMessage({ type: "hideLoader" }, "*");

                      setPageable({
                          buttonCount: 5,
                          info: true,
                          type: 'numeric',
                          pageSizes: true,
                          previousNext: true,
                      });

                        const base64Data = pdfData.split(";base64,")[1];
                        console.log(base64Data);
                        const blob = b64toBlob(base64Data, 'application/pdf');
                        const blobUrl = URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = blobUrl;
                        a.download = `siarl-lista-ocorrencias-${format(new Date(), 'ddMMyyyHHmm')}.pdf`;
                        a.click();
                  
                })
                .catch(error => {
                    console.error('Error exporting PDF:', error);
                    reject(error);
                });
        }, 1000);
    });
    }


  const clearSearchResults = () => {
    SessionStorageService.removeItem('resultadoOcorrenciasPesquisa')
    postMessage({ type: 'resultadoOcorrenciasPesquisaChange' }, '*')
    SessionStorageService.removeItem("lastSearchOcorrenciasAvancada");
    SessionStorageService.removeItem("lastSearchOcorrencias");
    selectOcorrenciaResult(null)
    removeColumnsKeys(keyPainelDocumentos)
    removeColumnsKeys(keyPainelPrincipal)
    const iframe: any = document.getElementById('iframeGeoPortal')
    setTimeout(() => {
      iframe.contentWindow?.postMessage(
        { type: 'SiarlOcorrenciasClearLayers', value: 'true' },
        '*'
      )
    }, 2000);
  }

  const handleRowMouseEnterById = (item: Record<string, unknown>) => {
    const id = item.ocorrencia_id ?? item.id;
    const iframe: any = document.getElementById('iframeGeoPortal')
    //iframe.contentWindow?.postMessage({ type: "SiarlOcorrenciasSearch", value: [ocorrencia.id] }, '*')
    if (id) {
        iframe.contentWindow?.postMessage(
          {
            type: 'SiarlOcorrenciasHighlight',
            value: [id],
          },
          '*'
        )

    }
  }
  
  const handleRowMouseLeaveById = (item: Record<string, unknown>) => {
    console.log(`#${item.id} - remover popover no mapa`)
    const id = item.ocorrencia_id ?? item.id;
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
      {
        type: 'SiarlOcorrenciasHighlightRemove',
        value: [id],
      },
      '*'
    )
  }

  const handleLocationClickById = (item: Record<string, any>) => () => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    const id = item.ocorrencia_id ?? item.id;
    iframe.contentWindow?.postMessage(
      {
        type: 'SiarlOcorrenciasCenter',
        value: [id],
      },
      '*'
    )
    restoreView();
    
    if(isMobile) onCloseClick()
  }

   const registarNovoClick = async () => {
    setIsEditingOcorrencia(false);
    sessionStorage.setItem('novoPontoOcorrencia', 'true')
    SessionStorageService.setItem("novaOcorrencia", 'true')
    SessionStorageService.setItem('ocorrenciaAntiga', 'false')
    sessionStorage.setItem('ocGeomEdited', 'false')
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
      { type: 'SiarlGeoPortalAdicionarPonto', value: 'true' },
      '*'
    )
    postMessage(
      { type: 'infoMsgMapa',  value: { message: 'Selecione o local no mapa' , left: (isMobile? (Number(percentLeftMsg.split('%')[0])-5)+'%' : '42%') } },
      '*'
    )
    postMessage({type: "closeForms", value: ""}, "*");
    
    restoreView();
  }
  
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/ResultadoOcorrencia" });
  }, []);



  const handleInfoClickById = (item: Record<string, any>) => async () => {
    
    setIsEditingOcorrencia(true);
    const userLocal = JSON.parse(
      localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}'
    )
    // Se veio da listagem de documentos terá ocorrencia_id
    const id = item.ocorrencia_id ?? item.id;
    const ocorrencia = await getOcorrencia(id);

    const entidadeByUser = ocorrencia?.utilizador?.entidade?.nome;

    if (!ocorrencia) {
      postMessage({type: "infoMsg", value: { message: "Registo desativado" }}, "*");
      return;
    }
    const tecnico = await GetUserNameTecnico(ocorrencia.utilizador_id); 
      
    const seletedOcorrenciaReulst = {
      id: ocorrencia.id,
      // Idetificacao
      distrito: ocorrencia.distrito ?? undefined,
      concelho: ocorrencia.concelho ?? undefined,
      freguesia: ocorrencia.freguesia ?? undefined,
      capitania: ocorrencia.capitania ?? undefined,
      praia: ocorrencia.praia ?? undefined,
      arh: ocorrencia.arh ?? undefined,
      nuts1: ocorrencia.nuts1 ?? undefined,
      nuts2: ocorrencia.nuts2 ?? undefined,
      nuts3: ocorrencia.nuts3 ?? undefined,
      massaAgua: ocorrencia.massa_agua ?? undefined,
      dataHoraRegistro: ocorrencia.data_registo ? moment.utc(ocorrencia.data_registo).tz(CURRENT_TIMEZONE) :undefined ,

      dataHoraOcorrencia: ocorrencia.data_ocorrencia ? moment.utc(ocorrencia.data_ocorrencia).tz(CURRENT_TIMEZONE) : undefined,
      entidade_registo: ocorrencia.entidade?.nome ?? undefined,
      entidade: ocorrencia.entidade ?? undefined,
      observacao: ocorrencia.observacoes ?? undefined,
      tecnico: tecnico,
      detecaoOcorrencia: ocorrencia.tipo_detecao_id ?? undefined,
      // Detalhes
      instabiladeArribas: ocorrencia.tip_inst_arribas ?? undefined,
      movimentoMassa: ocorrencia.tip_mmv ?? undefined,
      tip_mmv_alcance_deposito: ocorrencia.tip_mmv_alcance_deposito ?? undefined,
      fendaTracao: ocorrencia.tip_fenda_visivel ?? undefined,
      erosaoRecuo: ocorrencia.tip_erosao_arribas ?? undefined,
      escarpamento: ocorrencia.tip_escarpamento_duna ?? undefined,
      tip_altura_maxima_escarpa: ocorrencia.tip_altura_maxima_escarpa ?? undefined,
      rebaixamento: ocorrencia.tip_rebaixamento_praia ?? undefined,
      tip_reb_praia_altura_max: ocorrencia.tip_reb_praia_altura_max ?? undefined,
      galgamento: ocorrencia.galgamento_costeiro ?? undefined,
      tip_galg_cost_alcance_max: ocorrencia.tip_galg_cost_alcance_max ?? undefined,
      protecaoDefesa:  ocorrencia.tip_dano_obra ?? undefined,
      estruturaAderente: ocorrencia.tip_dano_estrutura_aderente ?? undefined,
      esporao: ocorrencia.tip_dano_esporao ?? undefined,
      protecaoEstabilizacao: ocorrencia.tip_dano_estrutura_protecao ?? undefined,
      ref_localizacao: ocorrencia.ref_localizacao ?? undefined,
      pessoaEmRisco: ocorrencia.tipo_pessoas_risco_id ?? undefined,
      fruicaoUsoPublico: ocorrencia.conseq_danos_areas_construidas ?? undefined,
      danosEdificacoes: ocorrencia.conseq_danos_edificacoes ?? undefined,
      outrosDanos: ocorrencia.conseq_danos_outras ?? undefined,
      outrosDanosDescricao: ocorrencia.conseq_danos_outras_obs ?? undefined,
      sistemasNaturais: ocorrencia.tipo_danos_sistemas_naturais_id ?? undefined,
      descricaoDanosSistemasNaturais: ocorrencia.danos_sistemas_naturais_obs ?? undefined,
      naoSabe: ocorrencia.causas_nao_sabe ?? undefined,
      tempestade: ocorrencia.causas_tempestade ?? undefined,
      descricaoTempestade: ocorrencia.causas_tempestade_obs ?? undefined,
      sismos: ocorrencia.causas_sistemos ?? undefined,
      outras: ocorrencia.causas_outras ?? undefined,
      descricaoOutras: ocorrencia.causas_ouras_obs ?? undefined,
      medidasMetigacao: ocorrencia.tipo_medida_mitigacao_id ?? undefined,
      sinalizacao: ocorrencia.mitig_sinalizacao ?? undefined,
      delimitacao: ocorrencia.mitig_delimitacao ?? undefined,
      saneamento: ocorrencia.mitig_saneamento ?? undefined,
      reabilitacao: ocorrencia.mitig_reabilitacao ?? undefined,
      reforco: ocorrencia.mitig_reforco ?? undefined,
      // Anexos
      uploadFotografia: ocorrencia.docs_fotos ?? undefined,
      uploadTopografia: '',
      uploadOutro: ocorrencia.docs_outros ?? undefined,
      utilizador_id: ocorrencia.utilizador_id ?? undefined,
      oc_estado: ocorrencia.estado ?? undefined,
      indeterminado: ocorrencia?.data_indeterminada ?? undefined,
      filedoc_cod: ocorrencia?.filedoc_cod ?? undefined,
      oc_ocorrencia_apoio_id: ocorrencia?.oc_ocorrencia_apoio_id
    }
    const iframe: any = document.getElementById('iframeGeoPortal')

    if (ocorrencia.oc_ocorrencia_apoio_id) {
      iframe.contentWindow?.postMessage(
        {
          type: 'SiarlOcorrenciasApoioSearch',
          value: [ocorrencia.oc_ocorrencia_apoio_id],
        },
        '*'
      )
    }

    iframe.contentWindow?.postMessage(
      { type: 'SiarlOcorrenciasHighlightRemove', value: [ocorrencia.id] },
      '*'
    )

    iframe.contentWindow?.postMessage(
      { type: 'SiarlOcorrenciasCenter', value: [ocorrencia.id] },
      '*'
    )


    SessionStorageService.setItem("novaOcorrencia", 'false')
    SessionStorageService.setItem('ocorrenciaAntiga', 'true')
    sessionStorage.setItem('ocGeomEdited', 'false')


    selectOcorrenciaResultId(ocorrencia.id as number)
    selectOcorrenciaResult(seletedOcorrenciaReulst)
    setOcorrenciaSelected(seletedOcorrenciaReulst);

    setShowResults(false)
    if (setShowOcorrenciaForm) setShowOcorrenciaForm(true)

    if (ocorrencia.coord_x && ocorrencia.coord_y) {
      setCoordenadasSelected(ocorrencia.coord_x + ', ' + ocorrencia.coord_y)
    } else {
      setCoordenadasSelected(undefined)
    }

    restoreView();

  }

  const handlePageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent
    const take = event.page.take

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value)
    }

    setPage2({
      ...event.page,
      take,
    })
    
  }

  const CommandCell = (props: GridCellProps) => (
    <ResultadoOcorrenciaCommandGridCell
      {...props}
      onLocationClick={handleLocationClickById}
      onInfoClick={handleInfoClickById}
      onLocationMouseEnter={handleRowMouseEnterById}
      onLocationMouseLeave={handleRowMouseLeaveById}
    />
  )

  const DocumentCommandCell = (props: GridCellProps) => (
    <ResultadoDocumentoCommandGridCell
      {...props}
      onLocationClick={handleLocationClickById}
      onInfoClick={handleInfoClickById}
      onDocumentPreviewClick={handleDocumentPreviewClickById}
      onLocationMouseEnter={handleRowMouseEnterById}
      onLocationMouseLeave={handleRowMouseLeaveById}
    />
  )

  const documentosToPreview = documentosOcorrencias

  const handleDocumentPreviewClickById = (documentosLocal: Record<string, any>) => async () => {

    try {

      postMessage({ type: "showLoader" }, "*");
      const documentos = await getDocumento(documentosLocal.id);

      setIdDoDocumento(documentosLocal.id);
      setUrlDoDocumento(documentos.url);
      setOpenFormViewDocumento(true);

    } catch (error) {
      handleGenericError(error, "Não foi possivel abrir o documento!")
      setOpenFormViewDocumento(false);

    } finally {

      postMessage({ type: "hideLoader" }, "*");

    }
  }

  const nextPhoto = async (e: any) => {

    try{

      postMessage({ type: "showLoader" }, "*");
      const nextIds = documentosToPreview
        .filter((doc: any) => doc.id < idDoDocumento && doc.url !== '')
        .map((doc: any) => doc.id);
  
      if (nextIds.length > 0) {
        const nextId = Math.max(...nextIds);
        console.log(`O próximo ID é ${nextId}`);
  
        const documento = await getDocumento(nextId);
  
        setIdDoDocumento(nextId.toString());
        setUrlDoDocumento(documento.url);

        const iframe: any = document.getElementById('iframeGeoPortal')
        const idOcorrencia = documento.ocorrencia_id;
        iframe.contentWindow?.postMessage(
          {
            type: 'SiarlOcorrenciasCenter',
            value: [idOcorrencia],
          },
          '*'
        )
  
      } else {
        console.log("Não há um próximo ID na lista.");
      }

    }catch(error){

      handleGenericError(error, "Não foi possivel abrir o documento!")

    }finally{

      postMessage({ type: "hideLoader" }, "*");

      e.preventDefault();
    }
   
  }

  const previousPhoto = async (e: any) => {

    try{

      postMessage({ type: "showLoader" }, "*");
      const previousIds = documentosToPreview
        .filter((doc: any) => doc.id > idDoDocumento && doc.url !== '')
        .map((doc: any) => doc.id);
  
      if (previousIds.length > 0) {
        const previousId = Math.min(...previousIds);
        console.log(`O anterior ID é ${previousId}`);
  
        const documento = await getDocumento(previousId);
  
        setIdDoDocumento(previousId.toString());
        setUrlDoDocumento(documento.url);

        const iframe: any = document.getElementById('iframeGeoPortal')
        const idOcorrencia = documento.ocorrencia_id;
        iframe.contentWindow?.postMessage(
          {
            type: 'SiarlOcorrenciasCenter',
            value: [idOcorrencia],
          },
          '*'
        )
        restoreView();

      } else {
        console.log("Não há um anterior ID na lista.");
      }

    }catch(error){

      handleGenericError(error, "Não foi possivel abrir o documento!")

    }finally{

      postMessage({ type: "hideLoader" }, "*");

      e.preventDefault();
    }
       
  }

  useEffect(() => {

    const elemento: any = document.querySelector(".k-window-content.k-dialog-content");

    if (elemento) {
      elemento.style.padding = "0px";
    }

  }, [openForm]);

  const previewDocument: any = (e: any) => {
    console.log(e);
  }

  const CustomCell = (props: GridCellProps) => (
    <CustomGridCell
      {...props}
      onMouseEnter={handleRowMouseEnterById}
      onMouseLeave={handleRowMouseLeaveById}
    />
  )

  const [selected, setSelected] = useState<number>(0)

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected)
    selectTab = e.selected
  }

  const enterEdit = (item: any) => {
    console.log(item)
    return
  }

  const pointOnMap = (item: any) => {
    console.log(item)
    return
  }
  const openFileDoc = (item: any) => {
    console.log(item)
    return
  }
  const openDocument = (item: any) => {
    console.log(item)
    return
  }

  useEffect(() => {
    if (ocorrencias) console.log({ ocorrencias })
  }, [ocorrencias])

  const onItemClick = (event: any) => {
    if (hasPermission(PERMISSION_CONSULTAR_OCORRENCIA) || hasPermission(PERMISSION_OCORRENCIA_PERFIL_COMPLETO_EDICAO) ||
  hasPermission(PERMISSION_OCORRENCIA_PERFIL_INTERMEDIO_EDICAO)) {
    const link: HTMLAnchorElement | null = document.querySelector('#print-results');

    if (link) {
      link.click();
    }

    } else {
      const link: HTMLAnchorElement | null = document.querySelector('#print-results-simples');

    if (link) {
      link.click();
    }
  }

    // if (event.item.key == 'ExportarFichasResultados') {

      

    // } else if (event.item.key == 'ExportarListaResultados') {

    //   console.log(event.item.text);

    // }

  };

  const ColumnMenu = (dataState: any) => {
    let datafilter;
    if(selectTab == 1){
      datafilter = documentosOcorrenciasFilter;
    }else{
      datafilter = ocorrenciasDataFilter;
    }
    return (
      <div>
      {/* <GridColumnMenuSort {...dataState} /> */}
      {/* <GridColumnMenuFilter {...dataState} /> */}
      {/* <GridColumnMenuGroup {...dataState} /> */}
      <GridColumnMenuCheckboxFilter {...dataState} data={datafilter} expanded={true} />
    </div>)

  }

  const options = [
    {
      key: 'ExportarFichasResultados',
      text: 'Fichas',
    },
    {
      key: 'ExportarListaResultados',
      text: 'Resultados',
    },
  ]

  const itemRender = (props: { item: any; itemIndex: number }) => {
    const isTitleItem = props.item.key === 'titulo';
    if (isTitleItem) {
      // Item de título personalizado
      return (
        <div className={styles.DropDownButtonTitulo}>
          <span>{props.item.text}</span>
        </div>
      );
    } else {
      // Demais itens
      return (
        <div className={styles.DropDownButtonItem}>
          <span>{props.item.text}</span>
        </div>
      );
    }
  };

  const closePreview = () => {
    setOpenFormViewDocumento(false);
  }

  const [columsDocuments, setColumsDocuments] = useState<{ columns: {field?:string, title?:string, width?:number|string, show:boolean, columnMenu?:any, filter?:any}[] }>({
    columns: [
      { field: "id", title: "Id", filter: 'numeric', columnMenu: ColumnMenu, show: true ,width: 90},
    { field: "data_registo", title: "Data", columnMenu: ColumnMenu, show: true ,  width: 170},
    { field: "tipo_recurso", title: "Tipo de Recurso", columnMenu: ColumnMenu, show: true ,  width: 170},
    { field: "titulo", title: "Titulo", columnMenu: ColumnMenu, show: true ,  width: 170},
    { field: "autores", title: "Autor", columnMenu: ColumnMenu, show: true ,  width: 170},
    { field: "entidade.nome", title: "Entidade", columnMenu: ColumnMenu, show: true ,  width: 200},
    { field: "estado.designacao", title: "Estado", columnMenu: ColumnMenu, show: true ,  width: 170},
    ]
  })

  const [columsAvancada, setColumsAvancada]=useState<{columns:{field?:string, title?:string, width?:number|string, show:boolean, columnMenu?:any, filter?:any}[]}>({
    columns:[
      { field: "id",filter:'text', title: "Id", show: true,  columnMenu: ColumnMenu, width: 90},
      { field: "data_ocorrencia",filter:'text', title: "Data Ocorrência",  columnMenu: ColumnMenu,show: true,  width: 170 },
      { field: "data_registo", filter:'text',title: "Data Registo",  columnMenu: ColumnMenu, show: false, width: 170},
      { field: "localizacao_concat",filter:'text', title: "Localização", columnMenu: ColumnMenu,show: true, width: 170 },
      { field: "tipologia",filter:'text', title: "Tipologia", columnMenu: ColumnMenu, show: true , width: 170},
      { field: "consequencias",filter:'text', title: "Consequencias", columnMenu: ColumnMenu, show: false , width: 170},
      { field: "entidade",filter:'text', title: "Entidade", columnMenu: ColumnMenu, show: false , width: 200},
      { field: "estado_ocorrencia",filter:'text', title: "Estado", columnMenu: ColumnMenu, show: false, width: 170 },  
    ]
  })

    const [colums, setColums]=useState<{columns:{field?:string, title?:string, width?:number|string, show:boolean, columnMenu?:any, filter?:any}[]}>({
    columns:[
      { field: "id", title: "Id",filter:'text', show: true,  columnMenu: ColumnMenu},
      { field: "data_ocorrencia",filter:'text', title: "Data Ocorrência",  columnMenu: ColumnMenu,show: true },
      { field: "localizacao_concat",filter:'text', title: "Localização", columnMenu: ColumnMenu,show: true },
      { field: "tipologia",filter:'text', title: "Tipologia", columnMenu: ColumnMenu, show: true },
    ]
  })

  useEffect(() => {
    SessionStorageService.setItem(keyPainelDocumentos, JSON.stringify(columsDocuments));
  }, [columsDocuments]);
  
  useEffect(() => {
    SessionStorageService.setItem(keyPainelPrincipal, JSON.stringify(colums));
  }, [colums]);

   useEffect(() => {
    if (hasPermission(PERMISSION_CONSULTAR_OCORRENCIA) ||
      hasPermission(PERMISSION_OCORRENCIA_PERFIL_COMPLETO_EDICAO) ||
      hasPermission(PERMISSION_OCORRENCIA_PERFIL_COMPLETO_LEITURA) ||
      hasPermission(PERMISSION_OCORRENCIA_PERFIL_TOTAL)) {
      setColums(columsAvancada);
    }
  }, [])




  useLayoutEffect(() => {

    const handleResize = () => {

      if (window.innerWidth <= telaMobile) {
        
        setIsMobile(true);
        ocultaFloatingMenu_e_Mapa();

      }else{

        setIsMobile(false);
        mostraFloatingMenu_e_Mapa();

      }

      if (window.innerWidth <= 1350) {
        setIsSmallerThan1350(true);
      }else{
        setIsSmallerThan1350(false);
      }

      if (window.innerWidth <= 1120) {
        setIsSmallerThan1120(true);
      }else{
        setIsSmallerThan1120(false);
      }

      if (window.innerWidth <= 850) {
        setIsSmallerThan850(true);
      }else{
        setIsSmallerThan850(false);
      }

    };
    
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);  


  const onItemClickMais = (event: any) => {

    elementoClickMais(event);

    if (event.item.key == 'novoRegisto') {

      mostraFloatingMenu_e_Mapa();

    }

  };
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  function handleButtonClick() {
    setIsOpen(!isOpen);
  }
  const optionsMais = () => {
    function filterCallback(value: any, item: any, setColumnsFunction: any, currentColumns: any[]) {
      const columnsUpdate = currentColumns.map((column: any) => {
        // to avoid the bad behaviour of the key title, must be reimplemented the itemRenderMais function
        const itemKey = item.key === "title" ? "titulo" : item.key

        if (column.field === itemKey) {
          return { ...column, show: value.value };
        } else {
          return column;
        }
      });
    
      const checkedColumns = columnsUpdate.filter((column: any) => column.show);
      if (checkedColumns.length === 0 && !value.value) {
        // Si es la única columna marcada y está siendo desmarcada, no hacer nada
        return;
      }
    
      setColumnsFunction({ columns: columnsUpdate });      
    }

    let columnsItems
    
    if (selected === 0) {
      columnsItems = colums?.columns?.map((c: any) => ({
        key: c.field,
        text: c.title,
        checked: c.show,
        elementType: "filterButton",
        filterCallback: (value: any, item: any) => filterCallback(value, item, setColums, colums.columns)
      }));
    } else {
      columnsItems = columsDocuments?.columns?.map((c: any) => ({
        // to avoid the bad behaviour of the key title, must be reimplemented the itemRenderMais function
        key: c.field === "titulo" ? "title" : c.field,
        text: c.title,
        checked: c.show,
        elementType: "filterButton",
        filterCallback: (value: any, item: any) => filterCallback(value, item, setColumsDocuments, columsDocuments.columns)
      }));
    }



    return [
      // {
      //   key: 'novoRegisto',
      //   text: 'Nova ocorrência',
      //   elementType: 'button',
      //   className: 'icon-siarl icon-registar btnIconRegistar'
      // },
      {
        key: 'titulo',
        text: 'Imprimir',
      },
      {
        key: 'imprimirFicha',
        text: 'Resultados',
        className: 'icon-siarl icon-imprimir btnIconRegistar'
      },
      {
        key: 'titulo',
        text: 'Exportar',
      },
      {
        key: 'ExportarListaResultadosExcel',
        text: 'excel',
      },
      {
        key: 'ExportarListaResultadosCsv',
        text: 'CSV',
      },
      {
        key: 'ExportarListaResultadosPdf',
        text: 'PDF',
      },
      {
        key: 'ExportarTodasFichas',
        text: 'Fichas',
      },
      {
        key: 'ExportarListaResultadosShapeFile',
        text: 'Shapefile',
      },
      {
        key: 'titulo',
        text: 'Colunas da tabela',
      },
      
      ...columnsItems,
    ]
  }
   
  const optionsExportar = [
    {
      key: 'ExportarListaResultadosExcel',
      text: 'excel',
    },
    {
      key: 'ExportarListaResultadosCsv',
      text: 'CSV',
    },
    {
      key: 'ExportarListaResultadosPdf',
      text: 'PDF',
    },
    {
      key: 'ExportarListaResultadosShapeFile',
      text: 'Shapefile',
    },
    {
      key: 'ExportarTodasFichas',
      text: 'Fichas',
    },
  ]

let _export: any;
const exportExcel = () => {
  _export.save();
};


const onItemClickExportarFichas = async () => {
  try {

  
    
    const relatorios = [];

    const results = JSON.parse(
      SessionStorageService.getItem('resultadoOcorrenciasPesquisa') ?? ''
    );
  
    const idsOcorrencias = results.map((o: any) => o.id);

    if(idsOcorrencias.length  > RECORDS_LIMIT){
      postMessage({ type: 'errorMsg', value: { message: "Não é possivel exportar mais de 100 fíchas" } }, '*')
      return false
      } 

      const resultOcorrencias = await getOcorrenciasPorIds(idsOcorrencias);
      postMessage({ type: 'showLoader' }, '*');
    
      for (const ocorrencia of resultOcorrencias) {
        
      let email = '';
      if (ocorrencia?.utilizador_id) {
        email = await getEmailByUserId(ocorrencia?.utilizador_id)
      }
      let instabilidadeArribaText = ""
      let movimentoMassaText = ""
      const alcanceMaximoDepositotext = " : Alcance máximo do depósito/detritos: " + (ocorrencia?.tip_mmv_alcance_deposito ? ocorrencia?.tip_mmv_alcance_deposito.toString() : '') + "m\n"
      let fendaTracaoText = ""
      let erosaoRecuoText = ""
      let escarpamentoText = ""
      const alturaMaxEscarpamentoText = " : Altura máxima da escarpa: " + (ocorrencia?.tip_altura_maxima_escarpa ? ocorrencia?.tip_altura_maxima_escarpa.toString() : ' ')  + "m\n"
      let rebaixamentoText = "" 
      const alturaMaxPraiaText = " :  Altura máxima da praia: " + (ocorrencia?.tip_reb_praia_altura_max ? ocorrencia?.tip_reb_praia_altura_max.toString() : ' ') + 'm\n';
      let galgamentoCosteiroText = ""
      const alcanceMaximoAguaText = " : Alcance máximo de água /detritos: " + (ocorrencia?.tip_galg_cost_alcance_max ? ocorrencia?.tip_galg_cost_alcance_max.toString() : '') + 'm\n'
      let protecaoDefesaText = ""
      let estruturaAderenteText = ""
      let esporaoText = ""
      let estruturaProtecaoText = ""
      let pessoasEmRiscoText = ""
      let danosEdificaoesText = ""
      let danosUsoPublico = ""
      let outrosDanosText = ""
      const descricaoOutrosDanosText = ocorrencia?.conseq_danos_outras_obs ? ocorrencia?.conseq_danos_outras_obs.toString() : ''
      let sistemasNaturaisText = ""
      const descricaoSistemasNaturaisText = ocorrencia?.danos_sistemas_naturais_obs ? ocorrencia?.danos_sistemas_naturais_obs.toString() : ''
      let naoSabeText = ""
      let tempestadeText = ""
      const descricaoTempestadeText = (ocorrencia?.causas_tempestade_obs ? ocorrencia?.causas_tempestade_obs.toString() : '') + '\n'
      let sismosText = ""
      let outrasText = ""
      const descricaoOutrasText = ocorrencia?.causas_ouras_obs ? ocorrencia?.causas_ouras_obs.toString() : '';
      let medidasMetigacaoText = ""
  
      if(ocorrencia?.tip_inst_arribas == true){
        instabilidadeArribaText = "Instabilidades em arribas\n";
     }
     if(ocorrencia?.tip_mmv == true){
        movimentoMassaText = "    Movimento massa de vertente" + alcanceMaximoDepositotext 
     }
     if(ocorrencia?.tip_fenda_visivel == true){
        fendaTracaoText = "    Fenda tração com cobertura visível\n"
     }
     if(ocorrencia?.tip_erosao_arribas == true){
       erosaoRecuoText = "Erosão/Recuo da linha da costa\n"
     }
     if(ocorrencia?.tip_escarpamento_duna == true){
       escarpamentoText = "    Escarpamento da duna" + alturaMaxEscarpamentoText
     }
     if(ocorrencia?.tip_rebaixamento_praia == true){
       rebaixamentoText = "    Rebaixamento da praia" + alturaMaxPraiaText
     }
     if(ocorrencia?.galgamento_costeiro == true) {
       galgamentoCosteiroText = "Galgamento costeiro " + alcanceMaximoAguaText
     }
     if(ocorrencia?.tip_dano_obra == true){
       protecaoDefesaText = "Em estruturas de proteção/defesa costeira\n"
     }
     if(ocorrencia?.tip_dano_estrutura_aderente == true){
       estruturaAderenteText = "Estrutura aderente\n"
     }
     if(ocorrencia?.tip_dano_esporao == true){
       esporaoText = "Esporão/Quebra-Mar"
     }
     if(ocorrencia?.tip_dano_estrutura_protecao == true){
      estruturaProtecaoText = "\nEstrutura de proteção/estabilização de arriba"
     }
     if(ocorrencia?.tipo_pessoas_risco_id == 1){
       pessoasEmRiscoText = "Sim"
     }
     else if(ocorrencia?.tipo_pessoas_risco_id == 2){
       pessoasEmRiscoText = "Não  sabe/Não Aplicável"
     }
     else if(ocorrencia?.tipo_pessoas_risco_id == 0 || ocorrencia?.tipo_pessoas_risco_id == undefined){
       pessoasEmRiscoText = "Não"
     }


     
     if(ocorrencia?.conseq_danos_areas_construidas == true){
      danosUsoPublico = "Danos em áreas construidas de fruição/uso público\n"
    }
     if(ocorrencia?.conseq_danos_edificacoes == true){
       danosEdificaoesText = "Danos em edificações\n"
     }
     if(ocorrencia?.conseq_danos_outras == true){
       outrosDanosText = "Outros danos: " + descricaoOutrosDanosText
     }
     if(ocorrencia?.tipo_danos_sistemas_naturais_id == 1){
       sistemasNaturaisText = "Sim: " + descricaoSistemasNaturaisText
     }
     if(ocorrencia?.tipo_danos_sistemas_naturais_id == 2){
       sistemasNaturaisText = "Não sabe/Não Aplicável"
     }
     if(ocorrencia?.tipo_danos_sistemas_naturais_id == 0 || ocorrencia?.tipo_danos_sistemas_naturais_id == undefined){
       sistemasNaturaisText = "Não"
     }

     if(ocorrencia.causas_nao_sabe == true){
       naoSabeText = "Não sabe/Não Aplicável\n"
     }
     if(ocorrencia.causas_tempestade == true){
       tempestadeText = "Tempestade: " + descricaoTempestadeText
     }
     if(ocorrencia?.causas_sistemos == true){
       sismosText = "Sismos\n"
     }
     if(ocorrencia?.causas_outras == true){
       outrasText = "Outras: " + descricaoOutrasText
     }

     if(ocorrencia?.tipo_medida_mitigacao_id == 2){
       medidasMetigacaoText = "Não sabe/Não Aplicável"
     }
     if(ocorrencia?.tipo_medida_mitigacao_id == 1){
       medidasMetigacaoText ="Sim"
     }
     if(ocorrencia?.tipo_medida_mitigacao_id == 0 || ocorrencia.medidasMetigacao == undefined){
       medidasMetigacaoText = "Não"
     }
  
     const entidade = ocorrencia?.entidade ?? '';
  
      let tipologiaOcorrenciaText = instabilidadeArribaText + movimentoMassaText + fendaTracaoText + erosaoRecuoText
       + escarpamentoText + rebaixamentoText + galgamentoCosteiroText + protecaoDefesaText + estruturaAderenteText + esporaoText + estruturaProtecaoText
  
       if (tipologiaOcorrenciaText == "") {
        tipologiaOcorrenciaText = "-";
        }
  
      let danosEdificacoesText = danosUsoPublico + danosEdificaoesText + outrosDanosText
      
      if(danosEdificacoesText == ""){
          danosEdificacoesText = "-";
        }
  
      let causasOcorrenciaText = naoSabeText + tempestadeText + sismosText + outrasText
  
      if (causasOcorrenciaText == "") {
        causasOcorrenciaText = "-";
        }
        let obs = ""
        if(ocorrencia?.observacoes == ""){
           obs = "-"
        }
        else{
          obs = ocorrencia?.observacoes 
        }

        let longitudeLatitude = '';
        if (ocorrencia.coord_x && ocorrencia.coord_y) {
          longitudeLatitude = ocorrencia.coord_x.toFixed(6) + " , " + ocorrencia.coord_y.toFixed(6)
        }

        let detecaoOcorrencia = '';

        if(ocorrencia.tipo_detecao_id == 1){
          detecaoOcorrencia = "Aviso/Chamada"
        }
        else{
          detecaoOcorrencia = "Visita Regular"
          
        }
        const objToSendImgMapa : any = {
          CodPrintTemplate: "RELATORIO_OCORRENCIAS2",
          extendInfo: {
            spatialReference: {
              wkid: 0
            },
            xmin: 0,
            ymin: 0,
            xmax: 0,
            ymax: 0
          },
          ListOperationalLayerInfo: [
            {
              IdOperationLayer: OPERATIONAL_LAYER,
              UseLayerExtent: false,
              DefinitionExpression: "",
              ListServiceLayer: [
                {
                    LayerId: LAYER_INDEX_OCORRENCIAS,
                    DefinitionExpression: "id=" + ocorrencia.id,
                    UseLayerExtent: ocorrencia.oc_ocorrencia_apoio_id ? false : true
                },
                {
                    LayerId: LAYER_INDEX_OCORRENCIA_APOIO,
                    DefinitionExpression: "ocorrencia_id=" + ocorrencia.id,
                    UseLayerExtent: ocorrencia.oc_ocorrencia_apoio_id ? true : false
                }
              ]
            }
          ]
        }


 
      const relatorio = {
        Id: ocorrencia.id,
        dados: {
          'REGISTO' : '',
          'Data do registo:': ocorrencia?.data_registo ? new Date(ocorrencia?.data_registo).toLocaleDateString() : '',
          'Técnico:': ocorrencia?.tecnico ?? '',
          'Email:' :  email ?? '',
          'Entidade:': entidade ?? '',
 
         'IDENTIFICAÇÃO' : '',
          'Data da ocorrência:': ocorrencia?.data_ocorrencia ? new Date(ocorrencia?.data_ocorrencia).toLocaleDateString() : 'Data indeterminada',
          'Deteção da ocorrência:': detecaoOcorrencia,
 
         'LOCALIZAÇÃO' : '',
          'Concelho:': ocorrencia?.concelho ?? '',
          'ARH :': ocorrencia?.arh ?? '',
          'Capitania:': ocorrencia?.capitania ?? '',
          'Praia:': ocorrencia?.praia ?? '',
          'Referência de local:': ocorrencia?.ref_localizacao ?? '',
          'Longetitude, latitude:':  longitudeLatitude ?? '',
         
          'TIPOLOGIA DA OCORRÊNCIA' : '',
          'Tipologia da Ocorrência:': tipologiaOcorrenciaText ?? '',
      
          'CONSEQUÊNCIAS DA OCORRÊNCIA' : '',
          'Pessoas em risco:': pessoasEmRiscoText ?? '',
          'Danos em edificações:': danosEdificacoesText ?? '',
            
          'Danos em sistemas:': sistemasNaturaisText ?? '',
 
           'CAUSAS DA OCORRÊNCIA' : '',
          'Causas da ocorrência:': causasOcorrenciaText ?? '',

          'MEDIDAS DE MITIGAÇÃO DE RISCO' : '',
          'Medidas de mitigação do risco:' : medidasMetigacaoText ?? '',

          'OBSERVAÇÕES' : '',
          'Observação:': obs ?? '',

        },
        img_mapa: " ",
        objJsonToCreateImg: objToSendImgMapa

      };
      
      relatorios.push(relatorio);
  }

    const mergedPdfBase64 = await relatorioOcorrenciasMerge(relatorios);
    if (mergedPdfBase64) {
      const blob = b64toBlob(mergedPdfBase64, 'application/pdf');
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    }
  } catch (error) {
    console.log(error);
    postMessage({ type: 'errorMsg', value: { left: '50%', message: "Verifique os campos obrigatórios." } }, '*');
  } finally {
    postMessage({ type: 'hideLoader' }, '*');
  }
};



  const onItemClickExportar = async (event: any) => {

    if(event.item.key == "ExportarTodasFichas"){
      onItemClickExportarFichas();
    }

    if (event.item.key == 'ExportarListaResultadosExcel') {

      exportExcel();

    }
    else if (event.item.key == 'ExportarListaResultadosCsv'){
      const link: HTMLAnchorElement | null = document.querySelector('#print-results');

      if (link) {
        link.click();
      }
    }
    else if (event.item.key == "ExportarListaResultadosPdf") {
      downloadPdf();
    }
    else if (event.item.key == "ExportarListaResultadosShapeFile") {
      const results = JSON.parse(
        SessionStorageService.getItem('resultadoOcorrenciasPesquisa') ?? ''
      )
  
      if (results) {
        const iframe: any = document.getElementById('iframeGeoPortal')
        const ids: number[] = []
        results.forEach((element: any) => {
          ids.push(element.id)
        })

        const columnsToExport = colums.columns.filter(x => x.show).map(x => x.field);
        const ocorrenciasFiltred: any[] = [];
        ocorrencias?.forEach((item: any) => {
          const filteredItem: any[] = [];
          colums.columns.filter(x => x.show).forEach((column: any) => {
            filteredItem.push({
              Key: column.field,
              Value: item[column.field]?.toString()
            });
          });
          ocorrenciasFiltred.push(filteredItem);
      })

        const objToSend = {
          ids: ids,
          fields: columnsToExport,
          values: ocorrenciasFiltred
        }

        const file: any = await getShapeFile(objToSend);
        const filePath = `siarl-lista-ocorrencias-${format(new Date(), 'ddMMyyyHHmm')}.zip`;

       handleDownload(file, filePath);
    } 
  
  }
};

let _pdfExport: any;
const exportPDF = () => {
  _pdfExport.save();
};

const onItemClickImprimir = async (event: any) => {
  //exportPDF();
   openPdfNewTab();
}

const openAjuda = () => {
  if (SessionStorageService.getItem("ocorrenciasPesquisaTipo")) {
    const ocorrenciasPesquisaTipo = SessionStorageService.getItem("ocorrenciasPesquisaTipo");
    if (ocorrenciasPesquisaTipo == "Simples") {
      window.open(URL_AJUDA_OCORRENCIA_PESQUISA_SIMPLES, "_blank");
    } else if (ocorrenciasPesquisaTipo == "Avancada") {
      window.open(URL_AJUDA_OCORRENCIA_PESQUISA_AVANCADA, "_blank");
    }
  }
}
const availableOcorrencias = ocorrencias.slice();
const initialOcorrencias = availableOcorrencias.splice(0, 20);

const [gridData, setGridData] = React.useState(initialOcorrencias);

const scrollHandler = (event: { nativeEvent: any; }) => {
  const ScroolLength = 10;
  const e = event.nativeEvent;
  if (
    e.target.scrollTop + ScroolLength >=
    e.target.scrollHeight - e.target.clientHeight
  ) {
    const moreData = availableOcorrencias.splice(0, ScroolLength);
    if (moreData.length > 0) {
      setGridData((oldData) => oldData.concat(moreData));
    }
  }
};

const canCreateNewOcurrence = hasPermission(PERMISSION_INSERIR_OCORRENCIA) ||
hasPermission(PERMISSION_OCORRENCIA_PERFIL_TOTAL) ||
hasPermission(PERMISSION_OCORRENCIA_PERFIL_INTERMEDIO_EDICAO) ||
hasPermission(PERMISSION_OCORRENCIA_PERFIL_COMPLETO_EDICAO)

return (
  <div className={clsx(styles.base, isCurrentViewMaximized && commonStyles.maximizedBaseForm)}>
    <div className={styles.header}>
      <div>
        <div className={styles.nomeDoModulo}>Ocorrências</div>
        <div className={styles.tituloDaPagina}>Resultados de pesquisa</div>
      </div>

      <div style={{display: "flex", height: "fit-content", flexDirection: isSmallerThan850 ? "column-reverse" : "row"}}>
        <CSVLink
          title="Imprimir resultados"
          id="print-results"
          data={ocorrencias?.map((item: any) => {
            const filteredItem: any = {};
            colums.columns.filter(x => x.show).forEach((column: any) => {
                filteredItem[column.field] = item[column.field];
            });
            return filteredItem;
        })}
          separator=','
          filename={`siarl-lista-ocorrencias-${format(new Date(), 'ddMMyyyHHmm')}`}
          hidden
        ></CSVLink>

        <CSVLink
          title="Imprimir resultados"
          id="print-results-simples"
          data={ocorrencias?.map((ocorrencia: any) => ({
            id: ocorrencia?.id,
            data_ocorrencia: ocorrencia?.data_ocorrencia,
            ref_localizacao: ocorrencia?.ref_localizacao,
            tipologia: ocorrencia?.tipologia
          }))}
          separator=','
          filename={`siarl-lista-ocorrencias-${format(new Date(), 'ddMMyyyHHmm')}`}
          hidden
        ></CSVLink>
        <>
          <div className={styles.espacamentoBotoesResponsive}style={{marginBottom: "8px"}}>
            {isSmallerThan1120 ? 
            <div ref={dropdownRef}>
              <DropDownButton
                items={optionsMais()}
                className={styles.btnMais}
                iconClass="icon-siarl icon-mais"
                onItemClick={onItemClickMais}
                fillMode={'flat'}
                itemRender={itemRenderMais}
                title="Mais Opções"
                opened={isOpen}
                onOpen={handleButtonClick}
              />
            </div>
             
            :
              <>
                {canCreateNewOcurrence && (
                  <button onClick={registarNovoClick} className={styles.btnNovoRegisto} style={isSmallerThan1350 ? {width: "38px", height: "38px"} : {}} id='novoRegisto' title='Nova ocorrência'>
                    <span className="icon-siarl icon-registar btnIconRegistar"/>
                      {!isSmallerThan1350 && <span>&nbsp;{' '}Nova ocorrência</span> }
                  </button>
                )}

                <VisualizadorColunas
                  columns={selected === 0 ? colums : columsDocuments}
                  setColumns={selected === 0 ? setColums : setColumsDocuments}
                  styles={styles.btnEditar}
                />

                <button className={styles.btnEditar} onClick={onItemClickImprimir} style={{ display: isMobile ? 'none' : '' }} id='imprimirFicha' title='Imprimir'>
                  <span className="icon-siarl icon-imprimir"></span>
                </button>
              </>
            }
            {!isSmallerThan1120 ?(
              <div ref={dropdownRef}>
                <DropDownButton
                  items={optionsExportar}
                  className={styles.btnImprimir}
                  iconClass="icon-siarl icon-exportar"
                  onItemClick={onItemClickExportar}
                  fillMode={'flat'}
                  itemRender={itemRender}
                  title='Exportar'
                  opened={isOpen}
                  onOpen={handleButtonClick}
                ></DropDownButton>
              </div>
            )
            : '' } 
            {canCreateNewOcurrence && isSmallerThan1120 && (
                  <button onClick={registarNovoClick} className={styles.btnNovoRegisto} style={isSmallerThan1350 ? {width: "38px", height: "38px"} : {}} id='novoRegisto' title='Nova ocorrência'>
                    <span className="icon-siarl icon-registar btnIconRegistar"/>
                      {!isSmallerThan1120 && <span>&nbsp;{' '}Nova ocorrência</span> }
                  </button>
            )}
            
          </div>

          <div className={styles.espacamentoBotoesResponsive}> 
          <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
              <span className="icon-siarl icon-ajuda"></span>
            </button>           
            <button className={styles.btnFechar} onClick={onCloseClick} title="Minimizar">
              <span className="icon-siarl icon-minimizar"></span>
            </button>
            
            {!isMobile ? 
              <MaximizeButton className={styles.btnMaximizar} />
            : ''}
          </div>
        </>
      </div>
    </div>
{hasPermission(PERMISSION_CONSULTAR_OCORRENCIA) ||
      hasPermission(PERMISSION_OCORRENCIA_PERFIL_COMPLETO_EDICAO) ||
      hasPermission(PERMISSION_OCORRENCIA_PERFIL_COMPLETO_LEITURA) ||
      hasPermission(PERMISSION_OCORRENCIA_PERFIL_INTERMEDIO_EDICAO) ||
      hasPermission(PERMISSION_OCORRENCIA_PERFIL_INTERMEDIO_LEITURA) ||
      hasPermission(PERMISSION_OCORRENCIA_PERFIL_TOTAL) ?
    <TabStrip selected={selected} onSelect={handleSelect} scrollable={isMobile}>
      <TabStripTab title="Ocorrências">
        <ExcelExport
            fileName={`siarl-lista-ocorrencias-${format(new Date(), 'ddMMyyyHHmm')}`}
            data={ocorrencias}
            ref={(exporter) => {
              _export = exporter
            }}
          >
             {/* <GridToolbar>
          <VisualizadorColunas
            columns={selected === 0 ? colums : columsDocuments}
            setColumns={selected === 0 ? setColums : setColumsDocuments}
            styles={styles}
          />
          </GridToolbar> */}
            <Grid
              className={styles.form}
              sortable={true}
              filterable={false}
              onDataStateChange={dataStateChange}
              data={
                dataResult.data.length > 0 
                  ? dataResult
                  : ocorrencias.map((documentosocorrencias: any) => ({
                      ...documentosocorrencias,  
              })).slice(page2.skip, page2.take + page2.skip)}
                
              {...dataState}
              pageable={{
                buttonCount: 5,
                info: true,
                type: 'numeric',
                pageSizes: true,
                previousNext: true,
              }}
              skip={page2.skip}
              take={page2.take}
              sort={sort.sort}
              total={dataResult.total != 0 ? dataResult.total : ocorrencias.length}
              cells={{ data: CustomCell }}
              onScroll={scrollHandler}
              fixedScroll={true}
            >
              <GridColumn cell={CommandCell} width={56} />

              {
              colums?.columns?.map((column, i) => {
                return (
                  column.show && (
                    <GridColumn key={i} field={column.field} columnMenu={column.columnMenu} width={column.width} title={column.title}  />
                  )
                );
              })}
            </Grid>
        </ExcelExport>

        <GridPDFExport
          fileName={`siarl-lista-ocorrencias-${format(new Date(), 'ddMMyyyHHmm')}`}
          ref={(element) => {
            _pdfExport = element
          }}
          paperSize={['340mm', '297mm']}         

          margin="1cm"
        >
                      <Grid
              className={styles.form}
              sortable={true}
              filterable={false}
              onDataStateChange={dataStateChange}
                data={dataResult.data.length > 0 ? dataResult : ocorrencias}
              {...dataState}
              pageable={{
                buttonCount: 5,
                info: true,
                type: 'numeric',
                pageSizes: true,
                previousNext: true,
              }}
              onScroll={scrollHandler}
              fixedScroll={true}
              rowHeight={50}
              total={dataResult.total != 0 ? dataResult.total : ocorrencias.length}
              ////groupable={true}
              cells={{ data: CustomCell }}
            >
              <GridColumn cell={CommandCell} width={56} />

              {
              colums?.columns?.map((column, i) => {
                return (
                  column.show && (
                    <GridColumn key={i}  field={column.field} columnMenu={column.columnMenu} width={column.width} title={column.title} />
                  )
                );
              })}
            </Grid>
        </GridPDFExport>
      </TabStripTab>

      <TabStripTab title="Documentos">
        <ExcelExport
        fileName={`siarl-lista-documentos-ocorrencias-${format(new Date(), 'ddMMyyyHHmm')}`}
          data={documentosOcorrencias}
          ref={(exporter) => {
            _export = exporter
          }}
        >
            
            <Grid
              className={styles.form}
              sortable={true}
              filterable={false}
              onDataStateChange={dataStateChangeDocumentos}
              data={
                dataResultDocumentos.data.length > 0 
                  ? dataResultDocumentos
                  : documentosOcorrencias.map((documentos: any) => ({
                      ...documentos,
                      data_publicacao: documentos?.data_publicacao ? format(new Date(documentos.data_publicacao), 'dd/MM/yyyy HH:mm') : undefined,
              })).slice(page.skip, page.take + page.skip)
            }       
            {...dataStateDocumentos} 
                pageable={{
                buttonCount: 5,
                info: true,
                type: 'numeric',
                pageSizes: true,
                previousNext: true,
              }}
              skip={page.skip}
              take={page.take}
              sort={sortDocumentos.sort}
              total={dataResultDocumentos.total != 0 ? dataResultDocumentos.total : documentosOcorrencias.length }
              //onPageChange={handlePageChange}
              ////groupable={true}
              cells={{ data: CustomCell }}
            >
            
              <GridColumn cell={DocumentCommandCell} width={86} />
              {columsDocuments?.columns?.map((column, i) => {
                return (
                  column.show && (
                    <GridColumn key={i}  field={column.field} columnMenu={column.columnMenu}   width={column.width} title={column.title} />
                  )
                );
              })}
            </Grid>
        </ExcelExport>
      </TabStripTab>
    </TabStrip>
     :         <><ExcelExport
        fileName={`siarl-lista-ocorrencias-${format(new Date(), 'ddMMyyyHHmm')}`}
        data={ocorrencias}
        ref={(exporter) => {
          _export = exporter;
        } }
      >
        <Grid
          className={styles.form}
          sortable={true}
          filterable={false}
          onDataStateChange={dataStateChange}
          data={dataResult.data.length > 0 ? dataResult : ocorrenciasFinal}
          {...dataState}
          pageable={pageable}
          total={dataResult.total != 0 ? dataResult.total : ocorrencias.length}
          // //groupable={true}
          cells={{ data: CustomCell }}
        >
          <GridColumn cell={CommandCell} width={56} />

          {colums?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i} field={column.field} columnMenu={column.columnMenu} width={column.width} title={column.title} />
              )
            );
          })}
        </Grid>
      </ExcelExport>
      <GridPDFExport
        fileName={`siarl-lista-ocorrencias-${format(new Date(), 'ddMMyyyHHmm')}`}
        ref={(element) => {
          _pdfExport = element;
        } }
        paperSize={['340mm', '297mm']}

        margin="1cm"
      >
          <Grid
            className={styles.form}
            sortable={true}
            filterable={false}
            onDataStateChange={dataStateChange}
            data={dataResult.data.length > 0 ? dataResult : ocorrencias}
            {...dataState}
            pageable={{
              buttonCount: 5,
              info: true,
              type: 'numeric',
              pageSizes: true,
              previousNext: true,
            }}
            total={dataResult.total != 0 ? dataResult.total : ocorrencias.length}
            // //groupable={true}
            cells={{ data: CustomCell }}
          >
            <GridColumn cell={CommandCell} width={56} />

            {colums?.columns?.map((column, i) => {
              return (
                column.show && (
                  <GridColumn key={i} field={column.field} columnMenu={column.columnMenu} width={column.width} title={column.title} />
                )
              );
            })}
          </Grid>
        </GridPDFExport></>
 }
    <div
      className={clsx(styles.footer, styles.justify_one_child, isCurrentViewMaximized && commonStyles.maximizedFooter)}
    >
      <Button className={styles.btnPesquisar} onClick={clearSearchResults} title='Nova Pesquisa'>
        <span className="icon-siarl icon-pesquisar"></span>
        <span className={styles.rotulo}>
          {' '}&nbsp;{' '}
          Nova pesquisa
        </span>
      </Button>
    </div>
    {openFormViewDocumento && (
        <Dialog
        className="ModalDocumentos"
          title={`Documento: ${idDoDocumento}`}
          onClose={closePreview}
          style={{ zIndex: 999 }}
        >
          
            <ImagePreviewV2
              documentUrl={urlDoDocumento}
              next={(e: any) => nextPhoto(e)}
              previous={(e: any) => previousPhoto(e)}
            />

        </Dialog>
      )}
  </div>
  
)
}
