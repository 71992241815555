import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import styles from '../../styles/ocorrencia/ConsultarOcorrenciaForm.module.scss'
import { useEffect, useState } from 'react'

type HoverState = 'idle' | 'enter' | 'leave'


type Props = {
  onLocationClick: (ocorrencia: Record<string, unknown>) => () => void
  onInfoClick: (ocorrencia: Record<string, unknown>) => () => void
  dataItem: Record<string, unknown>
  onLocationMouseEnter: (ocorrencia: Record<string, unknown>) => void
  onLocationMouseLeave: (ocorrencia: Record<string, unknown>) => void
}

export const ResultadoEstrategiaCommandGridCell = ({
  onLocationClick,
  onInfoClick,
  dataItem,
  onLocationMouseEnter,
  onLocationMouseLeave
}: Props) => {
  
  const [locationHoverState, setLocationHoverState] =
    useState<HoverState>('idle')

  const handleMouseEnter = () => setLocationHoverState('enter')
  const handleMouseLeave = () => setLocationHoverState('leave')

  useEffect(() => {
    if (locationHoverState === 'enter') {
      onLocationMouseEnter(dataItem)
    } else if (locationHoverState === 'leave') {
      onLocationMouseLeave(dataItem)
    }
  }, [locationHoverState])

  return (
    <td className="k-command-cell">
      <div className={'gridCommand'}>
        <div
          role="button"
          onClick={onLocationClick(dataItem)}
          className={styles.gridCommandIconButton}
        > 
          <FontAwesomeIcon
            icon={faLocationDot}
            title="Mostrar no Mapa"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        </div>
        <div
          role="button"
          className={styles.gridCommandIconButton}
          onClick={onInfoClick(dataItem)}
        >
          <FontAwesomeIcon
            icon={faInfoCircle}
            title="Detalhes de registo"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        </div>
      </div>
    </td>

  )
}
