import React, { MouseEvent, useEffect, useState } from 'react';
import clsx from 'clsx';
import commonStyles from '../../styles/common/Common.module.scss';
import styles from '../../styles/calculoPerfilGeografico/CalculoPerfilGeografico.module.scss';
import MaximizeButton from '../common/MaximizeButton';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { Grid, GridCellProps, GridColumn, GridPageChangeEvent } from '@progress/kendo-react-grid';
import { Button, DropDownButton } from '@progress/kendo-react-buttons';
import { PagerTargetEvent } from '@progress/kendo-react-data-tools';
import { useCommonStore } from '../../stores/common';
import { coordenadasLinhaSelected, get, post } from '../../services/utils';
import { CALCULO_PERFIL_MODULE_NAME, GET_MDTS, GET_MDTS_FROM_GEOM, URL_AJUDA_CALCULO_PERFIL } from '../../utils/constants';
import VisualizadorColunas from '../Generico/VisualizadorColunas ';
import useVisibleColumns, { Key } from '../../hooks/useVIsibleColumns'
import SessionStorageService from '../../services/SessionStorageService';
import ReactGA from 'react-ga4';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { format } from 'date-fns';
import { CSVLink } from 'react-csv';
import { Form, FormRenderProps, FormElement } from '@progress/kendo-react-form';

type Props = {
    onCloseClick: (e: MouseEvent<HTMLButtonElement>) => void;
};

type PageState = {
    skip: number;
    take: number;
};

type CamadaTopografica = {
    id: number;
    data: string;
    tipo: string;
    densidade: string;
    descricao: string;
    data_fim_processamento: string;
};

const initialDataState: PageState = { skip: 0, take: 10 };
const keyPainelPrincipal:Key = 'mdtsColunasVisiveis';
export default function CalculoPerfisGeograficosDinamicosForm({ onCloseClick }: Props) {
    const [page, setPage] = useState<PageState>(initialDataState);
    const isCurrentViewMaximized = useCommonStore((state) => state.isCurrentViewMaximized);
    const [selected, setSelected] = useState<number>(0);
    const [pageSizeValue, setPageSizeValue] = useState<number | string | undefined>();
    const [camadaTopograficas, setCamadaTopograficas] = useState<CamadaTopografica[]>([]);
    const [selectedRows, setSelectedRows] = useState<number[]>([]);

    const getMdts = async () => await get<any, any>(GET_MDTS)
    const getMdtsFromGeom = async (data: any) => await post<any, any>(`${GET_MDTS_FROM_GEOM}`, data);
    const {getVisibleColumns, removeColumnsKeys} = useVisibleColumns();
    const [percentLeftLinha, setPercentLeftLinha] = useState("34%");

    const handleSelect = (e: TabStripSelectEventArguments) => {
        setSelected(e.selected);
    };

    const handlePageChange = (event: GridPageChangeEvent) => {
        const targetEvent = event.targetEvent as PagerTargetEvent;
        const take = event.page.take;

        if (targetEvent.value) {
            setPageSizeValue(targetEvent.value);
        }

        setPage({
            ...event.page,
            take,
        });

        setSelectedRows([]);
    };

    const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        const allRows = camadaTopograficas.filter(x => !!x.data_fim_processamento).map((camadaTopografica) => camadaTopografica.id);
        setSelectedRows(event.target.checked ? allRows : []);
    };

    const handleRowSelect = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
        if (event.target.checked) {
            setSelectedRows((prevSelected) => [...prevSelected, id]);
        } else {
            setSelectedRows((prevSelected) => prevSelected.filter((selectedId) => selectedId !== id));
        }
    };

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/CalculoPerfilsGeograficosDinamicos" });
      }, []);

    const handleOpenGraficoCPGDForm = () => {
        removeColumnsKeys(keyPainelPrincipal)
        sessionStorage.setItem("idsMDT", JSON.stringify(selectedRows));
        postMessage({ type: "form", value: "graficoCPGDForm" }, "*");
    };

    const perfilGeograficoClick = (item: any) => {
        postMessage(
          { type: 'infoMsgMapa', value: { message: 'Desenhe a linha para cálculo de perfis geográficos dinâmicos', left: percentLeftLinha  } },
          '*')
        postMessage({type: "form", value: ""}, "*");
        sessionStorage.setItem("perfilGeograficoClicked" , '');
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage({ type: item.key, value: 'true' }, '*')
        SessionStorageService.setItem("perfilGeograficoInProgress", true);
        sessionStorage.setItem("Modulo", CALCULO_PERFIL_MODULE_NAME);
      } 
    
    const handleNewPerfil = () => {
        SessionStorageService.removeItem("perfilGeograficoInProgress");
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: 'true' }, '*')
        iframe.contentWindow?.postMessage(
           {
             type: 'SiarlCoordenadaBoxClose',
             value: 'true',
           },
           '*'
         )
        postMessage({ type: 'form', value: 'minimizarCalculoPerfil' }, '*');
        iframe.contentWindow?.postMessage(
            {
              type: 'SiarlHideInfoMap',
              value: 'true',
            },
            '*'
          )
          perfilGeograficoClick({
            key: 'SiarlGeoPortalAdicionarLinhaCalculoPerfil'
          })
        
    };

    const Minimizar = () => {
        postMessage({ type: 'form', value: 'minimizarCalculoPerfil' }, '*');
      }

      const fechar = () => {
        SessionStorageService.removeItem("perfilGeograficoInProgress");
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage(
           {
             type: 'SiarlCoordenadaBoxClose',
             value: 'true',
           },
           '*'
         )
        postMessage({ type: 'form', value: 'minimizarCalculoPerfil' }, '*');
      }

    useEffect(() => {

        const fetchData = async () => {
            postMessage({ type: 'showLoader' }, '*');
            getVisibleColumns(keyPainelPrincipal, setColums)

            try {
                const results: CamadaTopografica[] = await getMdtsFromGeom(coordenadasLinhaSelected);
                setCamadaTopograficas(results);
            } catch (error) {
                console.error("Erro ao buscar dados:", error);
            }

            postMessage({ type: 'hideLoader' }, '*');
        };

        fetchData();

    }, []);

    const openAjuda = () => {
        window.open(URL_AJUDA_CALCULO_PERFIL, "_blank")
    }

    const [colums, setColums]=useState<{columns:{field:string, title:string, width:number|string, show:boolean}[]}>({
        columns:[
            { field: "id", title: "Id", show: true, width: 70 },
            { field: "data", title: "Data", show: true, width: 150 },
            { field: "tipo", title: "Tipo", show: true, width: 150 },
            { field: "designacao", title: "Designação", show: true, width: 250 },
            { field: "descricao", title: "Descrição", show: true, width: 150 },
            {field: "data_fim_processamento", title: "Data Fim Proc.", show: true, width:150 }
        ]
      })
      useEffect(() => {
        SessionStorageService.setItem(keyPainelPrincipal, JSON.stringify(colums));
      }, [colums]);

      let _export: any;
    const exportExcel = () => {
    _export.save();
    };

    const optionsExportar = [
        {
          key: 'ExportarListaResultadosExcel',
          text: 'excel',
        },
        {
          key: 'ExportarListaResultadosCsv',
          text: 'CSV',
        },
       
      ]

      const onItemClickExportar = async (event: any) => {

        if (event.item.key == 'ExportarListaResultadosExcel') {
          exportExcel();
        }
        else if (event.item.key == 'ExportarListaResultadosCsv'){
            const link: HTMLAnchorElement | null = document.querySelector('#print-results');
      
            if (link) {
              link.click();
            }
          }
    };

    const itemRender = (props: { item: any; itemIndex: number }) => {
        const isTitleItem = props.item.key === 'titulo';
        if (isTitleItem) {
          // Item de título personalizado
          return (
            <div className={styles.DropDownButtonTitulo}>
              <span>{props.item.text}</span>
            </div>
          );
        } else {
          // Demais itens
          return (
            <div className={styles.DropDownButtonItem}>
              <span>{props.item.text}</span>
            </div>
          );
        }
      };


    return (
        <div className={clsx(styles.base, isCurrentViewMaximized && commonStyles.maximizedBaseForm)}>

            <CSVLink
            title="Imprimir resultados"
            id="print-results"
            data={camadaTopograficas?.map((item: any) => {
                const filteredItem: any = {};
                colums.columns.filter(x => x.show).forEach((column: any) => {
                    filteredItem[column.field] = item[column.field];
                });
                return filteredItem;
            })}
            separator=','
            filename={`siarl-lista-mdts-${format(new Date(), 'ddMMyyyHHmm')}`}
            hidden
            ></CSVLink>

          

            <div className={styles.header}>
                <div>
                    <div className={styles.nomeDoModulo}>
                        Cálculo de perfis geográficos dinâmicos
                    </div>
                    <div className={styles.tituloDaPagina}>
                        Modelos digitais de elevação disponíveis
                    </div>
                </div>
                <div>
                    <VisualizadorColunas
                        columns={colums}
                        setColumns={setColums}
                        styles={styles.btnEditar}
                    />

                <DropDownButton
                  items={optionsExportar}
                  className={styles.btnImprimir}
                  iconClass="icon-siarl icon-exportar"
                  onItemClick={onItemClickExportar}
                  fillMode={'flat'}
                  itemRender={itemRender}
                  title='Exportar'
                 
                ></DropDownButton>
                    <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
                        <span className="icon-siarl icon-ajuda"></span>
                    </button>
                    <button style={{marginRight: "8px"}} className={styles.btnFechar} onClick={fechar} title="Minimizar">
                        <span className="icon-siarl icon-fechar"></span>
                    </button>
                    <button className={styles.btnFechar} onClick={Minimizar} title="Minimizar">
                        <span className="icon-siarl icon-minimizar"></span>
                    </button>
                    <MaximizeButton className={styles.btnMaximizar} />
                </div>
            </div>
            
            <ExcelExport
              fileName={`pdf-lista-camada-topograficas-${format(new Date(), 'ddMMyyyHHmm')}`}
              data={camadaTopograficas}
              ref={(exporter) => {
                _export = exporter;
              }}
            >
              <Grid
                className={styles.form}
                data={camadaTopograficas?.slice(page.skip, page.take + page.skip)}
                pageable={{
                  buttonCount: 5,
                  info: true,
                  type: 'numeric',
                  pageSizes: true,
                  previousNext: true,
                }}
                skip={page.skip}
                take={page.take}
                total={camadaTopograficas.length}
                onPageChange={handlePageChange}
              >
                <GridColumn
                  title="Ações"
                  field="id"
                  width={70}
                  cell={(props: any) => (
                    <td>
                      <input
                        aria-label='Ações'
                        type="checkbox"
                        disabled={!props.dataItem.data_fim_processamento}
                        checked={selectedRows.includes(props.dataItem.id)}
                        onChange={(e) => handleRowSelect(e, props.dataItem.id)}
                      />
                    </td>
                  )}
                  headerCell={() => (
                    <input
                      type="checkbox"
                      checked={selectedRows.length === camadaTopograficas.length}
                      onChange={handleSelectAll}
                    />
                  )}
                />
                {colums?.columns?.map((column, i) => {
                  return (
                    column.show && (
                      <GridColumn key={i} field={column.field} width={column.width} title={column.title} />
                    )
                  );
                })}
              </Grid>
            </ExcelExport>

            <div className={clsx(styles.footer, styles.justify_multiple_children, isCurrentViewMaximized && commonStyles.maximizedFooter)}>
                 <Button className={styles.btnSeguinte} onClick={handleNewPerfil}>
                    Novo perfil
                </Button>
                <Button className={styles.btnPesquisar} onClick={handleOpenGraficoCPGDForm}>
                    Desenhar perfil
                </Button>
            </div>
        </div>
    );
}
