import React from 'react'
import ReactDOM from 'react-dom/client'

import reportWebVitals from './reportWebVitals'
import ReactGA from 'react-ga4';
import App from './App'
import { PROPRIEDADE_GA } from './utils/constants';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

ReactGA.initialize(PROPRIEDADE_GA);

root.render(
  <App />
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
