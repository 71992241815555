import { create } from 'zustand'

type DemarcacaoState = {
    selectedDemarcacaoResult?: unknown
    selectDemarcacaoResult: (payload: unknown) => void
    selectedDemarcacaoResultId?: number
    selectDemarcacaoResultId: (payload?: number) => void
    identificacaoTabIsValid: boolean
    selectDemarcacaoIdentificacaoTabIsValid: (payload: unknown) => void
     identificacaoTabValidationObject: Record<string, boolean>
    addIdentificacaoTabValidatedField: (payload: Record<string, boolean>) => void
   
}

export const useDemarcacaoStore = create<DemarcacaoState>((set, get) => ({
    selectedDemarcacaoResult: null,
    identificacaoTabIsValid: false,
    identificacaoTabValidationObject: {},
    selectDemarcacaoResult: (payload: unknown) =>
        set({ selectedDemarcacaoResult: payload }),
    selectDemarcacaoResultId: (payload?: number) =>
        set({ selectedDemarcacaoResultId: payload }),
    selectDemarcacaoIdentificacaoTabIsValid: (payload?: any) =>
        set({ identificacaoTabIsValid: payload }),
    addIdentificacaoTabValidatedField: (payload) => {
        const validationObj = get().identificacaoTabValidationObject
        const newValidationObj = { ...validationObj, ...payload }
        set({ identificacaoTabValidationObject: newValidationObj })
        const validationValues = Object.values(newValidationObj)
        set({ identificacaoTabIsValid: validationValues.every(value => value === true) })
    }
}))
