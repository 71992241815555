import { Input } from '@progress/kendo-react-inputs'
import { Error } from '@progress/kendo-react-labels'

import type { FieldRenderProps } from '@progress/kendo-react-form'

export default function ValidatedInput(fieldRenderProps: FieldRenderProps) {
  const { validationMessage, visited, ...rest } = fieldRenderProps

  return (
    <div>
      <Input {...rest} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  )
}
