import { Button } from '@progress/kendo-react-buttons'
import { ComboBox, ComboBoxFilterChangeEvent } from '@progress/kendo-react-dropdowns'
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from '@progress/kendo-react-form'
import { useEffect, useState } from 'react'

import { DateTimePicker } from '@progress/kendo-react-dateinputs'
import { Input, RadioGroup } from '@progress/kendo-react-inputs'
import { useNavigate } from 'react-router'
import SessionStorageService from '../../services/SessionStorageService'
import {
  concelhos,
  coordenadasPoligono,
  designacaoLinha,
  distritos,
  fillCombosAuxiliares,
  fillConcelhosAsync,
  fillDistritosAsync,
  fillFreguesiasAsync,
  fillNuts1Async,
  fillNuts2Async,
  fillNuts3Async,
  freguesias,
  handleGenericError,
  lugarToponimico,
  massaagua,
  nuts1,
  nuts2,
  nuts3,
  post,
  regiaoHidrografica,
  setCoordenadasPoligono,
  setDemarcacaoSelected,
  tipoLinha,
  tecnicos,
  fillTecnicosAsync,
  hasPermission,
} from '../../services/utils'
import styles from '../../styles/dominiohidrico/DominioHidrico.module.scss'
import {
  AREA_VISIVEL,
  AUTH_LOCALSTORAGE,
  DEMARCACAO_MODULE_NAME,
  NENHUM,
  PERMISSION_DOMINIO_HIDRICO_PERFIL_BASICO,
  PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_EDICAO,
  PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_LEITURA,
  PERMISSION_DOMINIO_HIDRICO_PERFIL_TOTAL,
  PESQUISAR_DEMARCACAO,
  POLIGONO_SELECAO,
  SEM_GEOMETRIA,
  clearLayers,
} from '../../utils/constants'
import { FilterDescriptor, filterBy } from '@progress/kendo-data-query'
import FieldsetCombosLocalizacao from '../localizacao/FieldsetCombosLocalizacao'
import { MouseEvent as ReactMouseEvent} from 'react'
import ReactGA from 'react-ga4';

type Props = {
  onClick?: (
      e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined;
}



export default function PesquisarDemarcacaoForm({onClick} : Props) {
  const [dataRegistoInicio, setDataRegistoInicio] = useState<any>()
  const [dataRegistoFim, setDataRegistoFim] = useState<any>()
  const [dataAtualizacao, setDataAtualizacao] = useState<any>()
  const [dataDe, setDataDe] = useState<any>()
  const [freguesiasFiltred, setFreguesiasFiltred] = useState<any>()
  const [concelhosFiltred, setConcelhosFiltred] = useState<any>()
  const [concelhosDisabled, setConcelhosDisabled] = useState<any>(true)
  const [freguesiasDisabled, setFreguesiasDisabled] = useState<any>(true)

  const [registoVisible, setRegistoVisible] = useState(true)
  const [identificacaoVisible, setIdentificacaoVisible] = useState(false)
  const [localizacaoVisible, setLocalizacaoVisible] = useState(false)
  const [nuts2Filtered, setNuts2Filtered] = useState<any>()
  const [nuts3Filtered, setNuts3Filtered] = useState<any>()
  const [nuts2Disabled, setNuts2Disabled] = useState<any>(true)
  const [nuts3Disabled, setNuts3Disabled] = useState<any>(true)
  const [criterioGeograficoVisible, setCriterioGeograficoVisible] =
    useState(false)
  const togglecriterioGeografico = () => {
    setCriterioGeograficoVisible(!criterioGeograficoVisible)
  }
  const [minData, setMinDate] = useState<any>();

  const [tecnicosData, setTecnicosData] = useState(tecnicos ? tecnicos.slice() : []);

  type combosKeys= 'tipoLinha'| 'nuts1' | 'nuts2' | 'nuts3' | 'distritos'| 'concelhos'|'freguesias' | 'regiaoHidrografica' | 'massaagua';

  const [combos, setCombos] = useState<any>({
       tipoLinha,
        nuts1,
        nuts2,
        nuts3,
        distritos,
        concelhos,
        freguesias,
        massaagua, 
        regiaoHidrografica,
      })
      const setDataOption: Record<combosKeys, { setFn: any, data: any[] }> = {
        tipoLinha: { setFn: setCombos, data: tipoLinha },
        nuts1: { setFn: setCombos, data: nuts1 },
        nuts2: { setFn: setCombos, data: nuts2Filtered },
        nuts3: { setFn: setCombos, data: nuts3Filtered },
        distritos: { setFn: setCombos, data: distritos },
        concelhos: { setFn: setCombos, data: concelhosFiltred },
        freguesias: { setFn: setCombos, data: freguesiasFiltred },
        massaagua: { setFn: setCombos, data: massaagua },
        regiaoHidrografica: { setFn: setCombos, data: regiaoHidrografica },
      };
      
      const filterCombo_Multiselect = (e: any, tipoCombo: combosKeys) => {
        const { setFn, data } = setDataOption[tipoCombo];
        setFn({
            ...combos,
            [tipoCombo]: filterBy(data?.slice()??[], e.filter)
        }
        );
    }
  
      const canSeeDominioHidrico = 
                            hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_LEITURA) ||
                            hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_EDICAO) ||
                            hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_TOTAL) 
                            

    useEffect(() => {
      ReactGA.send({ hitType: "pageview", page: "/PesquisarDemarcacao" });
    }, []);

   useEffect(() => {
    
     if(!nuts1 ){
      fillNuts1Async();
      }
     if(!nuts2) {
      fillNuts2Async();
     }
     if(!nuts3){
      fillNuts3Async();
     }
     if(!distritos){
      fillDistritosAsync();
     }
     if(!concelhos){
      fillConcelhosAsync();
     }
     if(!freguesias){
      fillFreguesiasAsync();
    }
    if(!tecnicos){
      fillTecnicosAsync();
     }

  }, []);

  


  const getDemarcacao = async (data: any) =>
    await post<any, any>(`${PESQUISAR_DEMARCACAO}`, data)


  const toggleRegisto = () => {
    setRegistoVisible(!registoVisible)
  }
  const toggleDataIdentificacao = () => {
    setIdentificacaoVisible(!identificacaoVisible)
  }

  const toggleLocalizacao = () => {
    setLocalizacaoVisible(!localizacaoVisible)
  }
  const filterConcelho = (e: any) => {
    if (e?.value?.codigo) {
      const concelhosLocal = concelhos.filter(
        (x: any) => x.distrito_cod == e.value.codigo
      )
      setConcelhosFiltred(concelhosLocal)
      setConcelhosDisabled(false)
    } else {
      setConcelhosFiltred(concelhos)
      setConcelhosDisabled(true)
    }
  }

  const filterFreguesia = (e: any) => {
    if (e?.value?.codigo) {
      const freguesiaLocal = freguesias.filter(
        (x: any) => x.concelho_cod == e.value.codigo
      )
      setFreguesiasFiltred(freguesiaLocal)
      setFreguesiasDisabled(false)
    } else {
      setFreguesiasDisabled(true)
      setFreguesiasFiltred(freguesias)
    }
  }

  const filterNuts2 = (e: any) => {
    if (e?.value?.codigo) {
      const nutsLocal = nuts2.filter((x: any) => x.nuts_i_cod == e.value.codigo)
      setNuts2Filtered(nutsLocal)
      setNuts2Disabled(false)
    } else {
      setNuts2Disabled(true)
      setNuts2Filtered(nuts2)
    }
  }
  const filterNuts3 = (e: any) => {
    if (e?.value?.codigo) {
      const nutsLocal = nuts3.filter(
        (x: any) => x.nuts_ii_cod == e.value.codigo
      )
      setNuts3Filtered(nutsLocal)
      setNuts3Disabled(false)
    } else {
      setNuts3Disabled(true)
      setNuts3Filtered(nuts3)
    }
  }

  const filterDataTecnicos = (filter: FilterDescriptor) => {
    const data = tecnicos.slice();
    return filterBy(data, filter);
  };

  const filterChangeTecnicos = (event: ComboBoxFilterChangeEvent) => {
    setTecnicosData(filterDataTecnicos(event.filter));
  };

  // const selectPatrimonioResult = useEntidadeStore(
  // (state) => state.selectEntidadeResult
  // )
  const registarNovoClick = () => {
    const loggedUser = JSON.parse(
      localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}'
    )
    setDemarcacaoSelected({ tecnicoRegisto: loggedUser?.user?.name })
    postMessage({ type: 'form', value: 'registarEntidade' }, '*')
  }


  const navigate = useNavigate();

  const navigateToContacts = () => {
    // 👇️ navigate to /contacts
    navigate('src/components/dominiohidrico/RegistarDemarcacao.tsx');
  };

  const handleSubmit = async (e: any) => {

    try {
      postMessage({ type: 'showLoader' }, '*')

      const objToSearch = {
        id: e.values?.id ?? null,
        user_tecnico_id: e.values?.tecnico ? e.values?.tecnico.id : undefined,
        ahr_id: e.values?.ahr?.id,
        concelho_cod: e.values?.concelho?.codigo ?? undefined,
        nuts1_cod: e.values?.nuts1?.codigo ?? undefined,
        nuts2_cod: e.values?.nuts2?.codigo ?? undefined,
        nuts3_cod: e.values?.nuts3?.codigo ?? undefined,
        arh_cod: e.values?.arh?.codigo ?? undefined,
        capitania_cod : e.values?.capitania?.codigo ?? undefined,
        massagua_id : e.values?.massaAgua?.id ?? undefined,
        local_id: e.values?.local ?? null,
        distrito_cod: e.values?.distrito?.codigo ?? undefined,
        criterio_geografico : e?.values?.rbCriterio ?? undefined,
        freguesia_cod: e.values?.freguesia?.codigo ?? undefined,
        flatCoordinates: coordenadasPoligono ?? undefined,
        data_inicio: e.values?.data_registo_inicio ?? undefined,
        data_fim: e.values?.data_registo_fim ?? undefined
      }

      sessionStorage.setItem("lastSearchDemarcacao", JSON.stringify(objToSearch));
      const result: any = await getDemarcacao(objToSearch);

      if (result) {

        SessionStorageService.setItem('resultadoDemarcacoesPesquisa', JSON.stringify(result))
        postMessage({ type: 'resultadoDemarcacoesPesquisaChange', value: result }, '*')

      }
      SessionStorageService.setItem("firstCallDemarcacao", true);

    } catch (error) {
      handleGenericError(error);
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  useEffect(() => {
    sessionStorage.setItem("SubModulo", DEMARCACAO_MODULE_NAME);
    SessionStorageService.setItem("DominioHidricoTipoPesquisa", DEMARCACAO_MODULE_NAME);
  }, []);

  const changerbCriterio = (e: any) => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlDeselectMapArea", value: 'true' }, '*')
    if (e.value == POLIGONO_SELECAO) {
      postMessage(
        { type: 'informationMsg', value: 'Selecione a área de pesquisa' },
        '*'
      )
      
      iframe.contentWindow?.postMessage(
        { type: 'SiarlGeoPortalAdicionarArea', value: 'true' },
        '*'
      )
    }
    if (e.value == AREA_VISIVEL) {
      iframe.contentWindow?.postMessage({ type: "SiarlSelectMapArea", value: 'true' }, '*')
      postMessage(
        {
          type: 'informationMsg',
          value: 'Foi selecionada a área visível do mapa',
        },
        '*'
      )
      iframe.contentWindow?.postMessage(
        { type: 'SiarlGetMapArea', value: 'true' },
        '*'
      )
    }
    if (e.value == NENHUM) {
      setCoordenadasPoligono(undefined)
      clearMap()
    }
  }


  const clearMap = () => {
    try {

      setCoordenadasPoligono(undefined)
      const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.contentWindow?.postMessage({ type: "SiarlDemarcacaoClearLayers", value: "true" }, '*')
      iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: "true" }, '*')
      iframe.contentWindow?.postMessage({ type: "SiarlDeselectMapArea", value: "true" }, '*')

    } catch (error) {
      console.log({ error })
    }
  }

  return (
    <Form
      onSubmitClick={handleSubmit}
      render={(formRenderProps: FormRenderProps) => (
        <>
          <FormElement className={styles.form}>
            <fieldset>
              <legend>
                <button
                  type="button"
                  className={styles.itemExpandBase}
                  onClick={toggleRegisto}
                >
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span
                      className={
                        registoVisible
                          ? 'icon-siarl icon-colapsar'
                          : 'icon-siarl icon-expandir'
                      }
                    ></span>
                  </div>
                  <span className={styles.fieldsetTitulo}>Registo</span>
                </button>
              </legend>
                <div className={styles.espacoVerticalFieldSet}>
                  {/* <div className={styles.fields}>
                   {registoVisible && (hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_BASICO) || canSeeDominioHidrico) && 
                    <div>
                      <Field name="Id" label="ID Registo" component={Input} />
                    </div>
                   }

                    {registoVisible && canSeeDominioHidrico? (
                    <div>
                      <Field
                        name="tecnico"
                        label="Técnico"
                        component={ComboBox}
                        textField={'name'}
                        filterable={true}
                        onFilterChange={filterChangeTecnicos}
                        data={tecnicosData}
                      />
                    </div>
                   ) : ''}
                  </div> */}


                  {registoVisible && (hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_BASICO) || canSeeDominioHidrico) && 

                  <><div className={styles.fields}>
                    <div>
                      <Field
                        name="data_registo_inicio"
                        label="De"
                        value={dataRegistoInicio}
                        onChange={(e) => {
                          setDataRegistoInicio(e.value)
                           setMinDate(e.value);
                        } }
                        format="dd/MM/yyyy HH:mm"
                        component={DateTimePicker} />
                    </div>
                    <div>
                      <Field
                        name="data_registo_fim"
                        label="Até"
                        value={dataRegistoFim}
                        min={minData}
                        onChange={(e) => {
                          setDataRegistoFim(e.value)
                        } }
                        format="dd/MM/yyyy HH:mm"
                        component={DateTimePicker} />
                    </div>
                  </div>
                  {/* <div className={styles.espacoVerticalFieldSet}>
                      <div className={styles.fields}>
                        <Field
                          name="data_atualizacao"
                          label="Data de atualização"
                          value={dataAtualizacao}
                          onChange={(e) => {
                            setDataAtualizacao(e.value)
                          } }
                          format="dd/MM/yyyy HH:mm"
                          component={DateTimePicker} />
                      </div>
                    </div> */}
                    </>
                }

                </div>
              
            </fieldset>

            <fieldset>

              <legend>
                <button type='button' className={styles.itemExpandBase} onClick={togglecriterioGeografico}>
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span className={criterioGeograficoVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                  </div>
                  <span className={styles.fieldsetTitulo}>Critério geográfico</span>
                </button>
              </legend>
              {criterioGeograficoVisible && (canSeeDominioHidrico || hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_BASICO)) && 

                <div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}>
                  <Field
                    id={'rbCriterio'}
                    name={'rbCriterio'}
                    layout={'horizontal'}
                    component={RadioGroup}
                    onChange={(e) => {
                      changerbCriterio(e)
                    }}
                    data={[
                      { label: 'Nenhum', value: NENHUM},
                      { label: 'Área visível', value: AREA_VISIVEL },
                      { label: 'Polígono de seleção', value: POLIGONO_SELECAO },
                    ]}
                    defaultValue={NENHUM} // Set the default selected value to 0 (Nenhum)

                  />
                </div>
              }
              
            </fieldset>
            
            {(canSeeDominioHidrico || hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_BASICO)) ? 
          <FieldsetCombosLocalizacao isPesquisaAvancada={true} styles={styles} formRenderProps={formRenderProps}/>
          :  <></> }

          </FormElement>
          <div
            className={`${styles.footer} ${styles.justify_multiple_children}`}
          >
            <Button
              onClick={() => { formRenderProps.onFormReset(); clearMap()}}
              className={styles.btnLimparPesquisa}
              title='Limpar pesquisa'
            >
              <span className="icon-siarl icon-limparcampos"></span>
              <span className={styles.rotulo}>
                {' '}&nbsp;{' '}
                Limpar pesquisa
              </span>
            </Button>

            <Button
              onClick={formRenderProps.onSubmit}
              className={styles.btnPesquisar}
              title='Pesquisar'
            >
              <span className="icon-siarl icon-pesquisar"></span>
              <span className={styles.rotulo}>
                {' '}&nbsp;{' '}
                Pesquisar
              </span>              
            </Button>
          </div>
        </>
      )}
    />
  )
}
