import {
  faEye,
  faInfoCircle,
  faLocationDot,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import styles from '../../styles/documentos/Documentos.module.scss'
import SessionStorageService from '../../services/SessionStorageService'
import { DOCUMENTOS_MODULE_NAME, OCORRENCIAS_MODULE_NAME, PERMISSION_CONSULTAR_OCORRENCIA } from '../../utils/constants'
import { hasPermission } from '../../services/utils'
import clsx from 'clsx'

type HoverState = 'idle' | 'enter' | 'leave'

type Props = {
  onLocationMouseEnter: (documentos: Record<string, unknown>) => void
  onLocationMouseLeave: (documentos: Record<string, unknown>) => void
  onLocationClick?: (documentos: Record<string, unknown>) => () => void
  onInfoClick?: (documentos: Record<string, unknown>) => () => void
  onDocumentPreviewClick: (documentos: Record<string, unknown>) => () => void
  dataItem: Record<string, unknown>
}

export const ResultadoDocumentoCommandGridCell = ({
  onLocationMouseEnter,
  onLocationMouseLeave,
  onLocationClick,
  onInfoClick,
  onDocumentPreviewClick,
  dataItem,
}: Props) => {
  const [locationHoverState, setLocationHoverState] =
    useState<HoverState>('idle')

  const handleMouseEnter = () => setLocationHoverState('enter')
  const handleMouseLeave = () => setLocationHoverState('leave')

  const url = dataItem.url as string | undefined;

  const isValidUrl = (url: string | undefined): boolean => {
    if (!url) return false;
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };
  

  useEffect(() => {
    if (locationHoverState === 'enter') {
      onLocationMouseEnter(dataItem)
    } else if (locationHoverState === 'leave') {
      onLocationMouseLeave(dataItem)
    }
  }, [locationHoverState])

  return (
    <td className="k-command-cell">
      <div className={'gridCommand'}>
        {dataItem.hasGeom && onLocationClick? 
          <div
            role="button"
            onClick={onLocationClick(dataItem)}
            className={clsx(styles.gridCommandIconButton, 'espacoDocumentoGridAction')}
          >
            <FontAwesomeIcon
              icon={faLocationDot}
              title="Mostrar no Mapa"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
          </div>
        :<div className='espacoDocumentoGridAction'>&nbsp;</div>}
        {(sessionStorage.getItem("Modulo") == DOCUMENTOS_MODULE_NAME ||
        (sessionStorage.getItem("Modulo") == OCORRENCIAS_MODULE_NAME && hasPermission(PERMISSION_CONSULTAR_OCORRENCIA)) ) && onInfoClick? 
        <div
          role="button"
          className={styles.gridCommandIconButton}
          onClick={onInfoClick(dataItem)}
        >
          <FontAwesomeIcon
            icon={faInfoCircle}
            title="Detalhes de registo"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        </div> : ''}
        {dataItem?.url != undefined && url?.trim() !== "" && isValidUrl(url) && (
          <div
            role="button"
            className={styles.gridCommandIconButton}
            onClick={onDocumentPreviewClick(dataItem)}
          >
            <FontAwesomeIcon icon={faEye} title="Visualizar" />
          </div>
        )}
      </div>
    </td>

  )
}
