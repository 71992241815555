import { process } from '@progress/kendo-data-query';
import {
  Dispatch,
  MouseEvent as ReactMouseEvent,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { Button, DropDownButton } from '@progress/kendo-react-buttons';
import { PagerTargetEvent } from '@progress/kendo-react-data-tools';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Grid, GridCellProps, GridColumn, GridPageChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { format } from 'date-fns';
import React from 'react';
import { CSVLink } from 'react-csv';
import SessionStorageService from '../../services/SessionStorageService';
import { GetUserNameTecnico, b64toBlob, entidadeUser, exportPDFWithMethod, get, handleDownload, hasPermission, post, refreshPesquisaSearch, setModeloSelected } from '../../services/utils';
import { useModeloStore } from '../../stores/modelo';
import styles from '../../styles/modelos/ResultadoModeloForm.module.scss';
import commonStyles from '../../styles/common/Common.module.scss'
import { CURRENT_TIMEZONE, GET_DOCUMENTO, GET_MODELO, GET_MODELOS_SHAPEFILE, PERMISSION_MODELO_PERFIL_COMPLETO_EDICAO, PERMISSION_MODELO_PERFIL_COMPLETO_LEITURA, PERMISSION_MODELO_PERFIL_TOTAL, PESQUISAR_DOCUMENTOS_BY_MODELOS, PESQUISAR_MODELOS, PESQUISAR_MODELO_LIST_RESULTS, RECORDS_LIMIT, RELATORIO_MERGE_MODELO, URL_AJUDA_MODELOS, clearLayers } from '../../utils/constants';
import { ResultadoModeloCommandGridCell } from './registar/ResultadoModeloCommandGridCell';
import clsx from 'clsx';
import { useCommonStore } from '../../stores/common';
import MaximizeButton from '../common/MaximizeButton';
import { ResultadoDocumentoCommandGridCell } from '../documentos/ResultadoDocumentoCommandGridCell';
import { Dialog } from '@progress/kendo-react-dialogs';
import ImagePreviewV2 from '../viewer/ImagePreviewV2';
import VisualizadorColunas from '../Generico/VisualizadorColunas ';
import useVisibleColumns, { Key } from '../../hooks/useVIsibleColumns';
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais';
import ReactGA from 'react-ga4';
import moment from 'moment';
import "moment-timezone"

type Props = {
  onCloseClick?: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
  setShowResultadosModelo: Dispatch<SetStateAction<boolean>>
  setShowRegistarModeloForm: Dispatch<SetStateAction<boolean>>
}

const options = [
  {
    key: 'ExportarFichasResultados',
    text: 'Exportar fichas resultados',
    icon: <span className={`icon-siarl icon-exportar ${styles.btnImprimir}`}></span>,
  }
]
type PageState = {
  skip: number
  take: number
}
const initialDataState: PageState = { skip: 0, take: 20 }
const keyPainelPrincipal:Key = 'modeloColunasVisiveis';
const keyPainelDocumentos:Key = 'modeloDocumentosColunasVisiveis';
export default function ResultadoModeloForm({
  onCloseClick,
  setShowResultadosModelo,
  setShowRegistarModeloForm,
}: Props) {
  const [isMobile, setIsMobile] = useState(false);
  const [modelos, setModelos] = useState([])
  const [documentosModelos, setDocumentosModelos] = useState([])
  const getDocumento = async (data: any) =>
  await get<any, any>(`${GET_DOCUMENTO}/` + data)
  const [urlDoDocumento, setUrlDoDocumento] = useState("");
  const [idDoDocumento, setIdDoDocumento] = useState("");
  const [openForm, setOpenForm] = useState<boolean>(false)

  const [selected, setSelected] = useState<number>(0)
  const [page, setPage] = useState<PageState>(initialDataState)
  const selectModeloResult = useModeloStore(
    (state) => state.selectModeloResult
  )
  const selectModeloResultId = useModeloStore(
    (state) => state.selectModeloResultId
  )
  const [pageSizeValue, setPageSizeValue] = useState<
    number | string | undefined
  >()
  const documentosToPreview = documentosModelos;

  const isCurrentViewMaximized = useCommonStore(state => state.isCurrentViewMaximized)
  const restoreView = useCommonStore(state => state.restoreView)
  const {getVisibleColumns, removeColumnsKeys} = useVisibleColumns();

  const getModelo = async (data: any) =>
    await get<any, any>(`${GET_MODELO}/` + data)
  const getDocumentosByModelos = async (data: any) =>
    await post<any, any>(`${PESQUISAR_DOCUMENTOS_BY_MODELOS}`, data)

  const getShapeFile = async (data: any) =>
  await post<any, any>(`${GET_MODELOS_SHAPEFILE}`, data)

  const getModelosPorIds = async (data: any) =>
    await post<any, any>(`${PESQUISAR_MODELO_LIST_RESULTS}`, data)

  const relatorioModeloMerge = async (data: any) =>
    await post<any, any>(`${RELATORIO_MERGE_MODELO}`, data)


  const clearSearchResults = () => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: clearLayers["CLEAR_LAYERS_MODELOS"], value: "true" }, '*')
    SessionStorageService.removeItem('resultadoModelosPesquisa')
    SessionStorageService.removeItem("lastSearchModelos");
    postMessage({ type: "form", value: "pesquisarModelos" }, "*");
    setModelos([]);
    removeColumnsKeys(keyPainelDocumentos)
    removeColumnsKeys(keyPainelPrincipal)
  }

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected)
  }
  const handlePageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent
    const take = event.page.take

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value)
    }

    setPage({
      ...event.page,
      take,
    })
  }

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/ResultadoModelos" });

    // ReactGA.pageview(window.location.pathname + "ResultadoModelos");
  }, []);

  const consultarModelos = async (data: any) => await post<any, any>(`${PESQUISAR_MODELOS}`, data)

  const initialized = useRef(false)
    useEffect(() => {
        if (!initialized.current) {
          initialized.current = true;    
          if (SessionStorageService.getItem("lastSearchModelos") && !SessionStorageService.getItem("firstCallModelos"))  {
      postMessage({ type: "showLoader" }, "*");
      const objToSearch = SessionStorageService.getItem("lastSearchModelos") ?? '';
      consultarModelos(objToSearch).then((data) => {
        SessionStorageService.setItem("resultadoModelosPesquisa", JSON.stringify(data));
        setResultsModelos();
        postMessage({ type: "hideLoader" }, "*");
      })
    } 
    else {
      setResultsModelos();
  }
}
  }, [])

  const setResultsModelos = () => {

       const results = JSON.parse(
         SessionStorageService.getItem('resultadoModelosPesquisa') ?? ''
      )

      // if (results) {
      //   const iframe: any = document.getElementById('iframeGeoPortal')
      //   const ids: number[] = []
      //   const idsApoio: number[] = []
      //   results.forEach((element: any) => {
      //     ids.push(element.id)
      //   })
      //   iframe.contentWindow?.postMessage(
      //     { type: 'SiarlModelosSearch', value: ids },
      //     '*'
      //   )
      // }

      refreshPesquisaSearch("resultadoModelosPesquisa", "SiarlModelosSearch")

     const formattedResults = results.map((modelos: any) => ({
      ...modelos,
      data_publicacao: modelos.data_publicacao
      ? moment.utc(modelos.data_publicacao).tz(CURRENT_TIMEZONE).format('DD/MM/yyyy HH:mm')
      : '', 
    }));

      
      getVisibleColumns(keyPainelPrincipal, setColums)
      getVisibleColumns(keyPainelDocumentos, setColumsDocuments)
      setModelos(formattedResults);

        const idsModelo = results.map((o: any) => o.id)
        getDocumentosByModelos(idsModelo).then(response => {
          setDocumentosModelos(response)
          console.log(response)
        })

  }


  useEffect(() => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    const ids: number[] = []
    modelos.forEach((element: any) => {
      ids.push(element.id)
    })
    iframe.contentWindow?.postMessage(
      { type: 'SiarlModelosSearch', value: ids },
      '*'
    )
  }, [])

  const openPdfNewTab = () => {
    return new Promise<void>((resolve, reject) => {
        
        postMessage({ type: "showLoader" }, "*");

        setTimeout(() => {
           const columnsToShow = colums.columns.filter(x => x.show);
            const dataToExport = modelos.map((item: any) => {
                const filteredItem: any = {};
                columnsToShow.forEach((column: any) => {
                    filteredItem[column.field] = item[column.field];
                });
                return filteredItem;
            });
            
            exportPDFWithMethod(columnsToShow, dataToExport, 8)
                .then((pdfData: any) => {
                    postMessage({ type: "hideLoader" }, "*");

                        const base64Data = pdfData.split(";base64,")[1];
                        console.log(base64Data);
                        const blob = b64toBlob(base64Data, 'application/pdf');
                        const blobUrl = URL.createObjectURL(blob);

                      
                        window.open(blobUrl, '_blank');
                        resolve();
                  
                })
                .catch(error => {
                    console.error('Error exporting PDF:', error);
                    reject(error);
                });
        }, 1000);
    });
};

  const handleInfoClickById = (modeloLocal: Record<string, any>) => async () => {

    const id = modeloLocal.modelo_id ?? modeloLocal.id;
    const modelo = await getModelo(id);
    const tecnico = await GetUserNameTecnico(modelo.utilizador_id);

    const entidadeByUser = modelo?.utilizador?.entidade?.nome;

     if (!modelo) {
      postMessage({type: "infoMsg", value: { message: "Registo desativado" }}, "*");
      return;
    }

    const iframe: any = document.getElementById('iframeGeoPortal')

    console.log(modelo);

    const modelosObject = {
      ...modelo,
      dataHoraRegisto: modelo.data_registo ? moment.utc(modelo.data_registo).tz(CURRENT_TIMEZONE) : undefined,
      data_publicacao: modelo.data_publicacao ? moment.utc(modelo.data_publicacao).tz(CURRENT_TIMEZONE) : undefined,
      autores: modelo?.autores ?? undefined,
      local: modelo?.local ?? undefined,
      tecnicoRegisto : tecnico,
    }
    
    selectModeloResult(modelosObject)
    selectModeloResultId(modelo?.id)
    setModeloSelected(modelosObject);
    setShowResultadosModelo(false)
    setShowRegistarModeloForm(true)
    SessionStorageService.setItem("novoModelo", 'false');
    const idToSend = [modelo?.id];

    iframe.contentWindow?.postMessage({ type: "SiarlModelosSearch", value: idToSend }, '*')

    iframe.contentWindow?.postMessage({ type: "SiarlModelosCenter", value: idToSend }, '*')

    restoreView();
  }
  const handleLocationClick = (modeloLocal: Record<string, any>) => async () => { 
    
    const id = modeloLocal.modelo_id ?? modeloLocal.id;
    const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.contentWindow?.postMessage({ type: "SiarlModelosCenter", value: [id] }, '*')
      restoreView();
  }
const conditionalProps = hasPermission(PERMISSION_MODELO_PERFIL_TOTAL) || hasPermission(PERMISSION_MODELO_PERFIL_COMPLETO_LEITURA)
    ? { 
      onInfoClick: handleInfoClickById,
      onLocationClick: handleLocationClick
     } 
    : {};
  const modeloCustomCell = (props: GridCellProps) => (
    <ResultadoModeloCommandGridCell
      {...props}
      {...conditionalProps}
    />
  )

  const [columsDocuments, setColumsDocuments] = useState<{ columns: { field: string, title: string, width: number | string, show: boolean }[] }>({
    columns: [
      { field: "id", width: 70, title: "ID", show: true },
      { field: "filedoc_cod", width: 190, title: "ID Documento Filedoc", show: true },
      { field: "dataHoraPublicacao", width: 180, title: "Data publicação", show: true },
      { field: "tipoRecurso.designacao", width: 150, title: "Tipo de recurso", show: true },
      { field: "titulo", width: 170, title: "Título", show: true },
      { field: "autores", width: 170, title: "Autor", show: true },
      { field: "entidade.nome", width: 180, title: "Entidade", show: true },
      { field: "id_estado", width: 190, title: "Estado", show: true },
    ]
  })
  
  const colunasAdicionais = [
  { field: "modeloTipo.designacao", title: "Tipo", show: true, width: 150 },
  { field: "modeloSubtipo.designacao", title: "Subtipo", show: true, width: 150 },
  { field: "entidade.name", title: "Entidade", show: true, width: 190},
  { field: "designacao", title: "Titulo", show: true, width: 190 },
  ]
  
  const colunasPadrao =[
    { field: "id", title: "ID", show: true, width: 90},
    {
      field: "data_publicacao",
      title: "Data Publicação",
      show: true,
      width: 170,
      cell: (props: { dataItem: { data_publicacao: string | number | Date; }; }) => (
        <td>
          {props.dataItem.data_publicacao
            ? new Date(props.dataItem.data_publicacao).toLocaleString()
            : ''}
        </td>
      ),
    },]
  const [colums, setColums]=useState<{columns:{field:string, title:string,cell?:any, width:number|string, show:boolean}[]}>({
    columns:colunasPadrao
  })
  

  const documentCustomCell = (props: GridCellProps) => (
    <ResultadoDocumentoCommandGridCell
    onLocationMouseEnter={function (documentos: Record<string, unknown>): void {
      console.log("n/a");
    } } onLocationMouseLeave={function (documentos: Record<string, unknown>): void {
      console.log("n/a");
    } } {...props}
    onInfoClick={handleInfoClickById}
    onLocationClick={handleLocationClick}
    onDocumentPreviewClick={handleDocumentPreviewClickById}    />
  )

  const handleDocumentPreviewClickById = (documentosLocal: Record<string, any>) => async () => {

    try {

      postMessage({ type: "showLoader" }, "*");
      const documentos = await getDocumento(documentosLocal.id);

      setIdDoDocumento(documentosLocal.id);
      setUrlDoDocumento(documentos.url);
      setOpenForm(true);

    } catch (error) {

      postMessage({ type: "errorMsg", value: "Não foi possivel abrir o documento!" }, "*");
      setOpenForm(false);

    } finally {

      postMessage({ type: "hideLoader" }, "*");

    }
  }

  const nextPhoto = async (e: any) => {

    try{

      postMessage({ type: "showLoader" }, "*");
      const nextIds = documentosToPreview
        .filter((doc: any) => doc.id < idDoDocumento && doc.url !== '')
        .map((doc: any) => doc.id);
  
      if (nextIds.length > 0) {
        const nextId = Math.max(...nextIds);
        console.log(`O próximo ID é ${nextId}`);
  
        const documento = await getDocumento(nextId);
  
        setIdDoDocumento(nextId.toString());
        setUrlDoDocumento(documento.url);

        const iframe: any = document.getElementById('iframeGeoPortal')
        const id = documento.entidade_id ?? documento.id;
        iframe.contentWindow?.postMessage(
          {
            type: 'SiarlEntidadesCenter',
            value: [id],
          },
          '*'
        )
  
      } else {
        console.log("Não há um próximo ID na lista.");
      }

    }catch(error){

      postMessage({ type: "errorMsg", value: "Não foi possivel abrir o documento!" }, "*");

    }finally{

      postMessage({ type: "hideLoader" }, "*");

      e.preventDefault();
    }
   
  }

  const previousPhoto = async (e: any) => {

    try{

      postMessage({ type: "showLoader" }, "*");
      const previousIds = documentosToPreview
        .filter((doc: any) => doc.id > idDoDocumento && doc.url !== '')
        .map((doc: any) => doc.id);
  
      if (previousIds.length > 0) {
        const previousId = Math.min(...previousIds);
        console.log(`O anterior ID é ${previousId}`);
  
        const documento = await getDocumento(previousId);
  
        setIdDoDocumento(previousId.toString());
        setUrlDoDocumento(documento.url);

        const iframe: any = document.getElementById('iframeGeoPortal')
        const id = documento.entidade_id ?? documento.id;
        iframe.contentWindow?.postMessage(
          {
            type: 'SiarlEntidadesCenter',
            value: [id],
          },
          '*'
        )
      } else {
        console.log("Não há um anterior ID na lista.");
      }

    }catch(error){

      postMessage({ type: "errorMsg", value: "Não foi possivel abrir o documento!" }, "*");

    }finally{

      postMessage({ type: "hideLoader" }, "*");

      e.preventDefault();
    }
       
  }

  useEffect(() => {

    const elemento: any = document.querySelector(".k-window-content.k-dialog-content");

    if (elemento) {
      elemento.style.padding = "0px";
    }

  }, [openForm]);


  const [dataState, setDataState] = React.useState({
    skip: 0,
    take: 20,
    sort: [],
    group: [],
  });

  const [dataStateDocumentos, setDataStateDocumentos] = React.useState({
    skip: 0,
    take: 20,
    sort: [],
    group: [],
  });


  const [dataResult, setDataResult] = React.useState(
    process(modelos, dataState)
  );

  const dataStateChange = (event: any) => {
    setDataResult(process(modelos, event.dataState));
    setDataState(event.dataState);

    
    const skipPag: PageState = { skip: event.dataState.skip, take: event.dataState.take}
setPage({
  ...skipPag
})
  };



  const dataStateChangeDocumentos = (event: any) => {
    setDataResultDocumentos(process(documentosModelos, event.dataState));
    setDataStateDocumentos(event.dataState);
  };

  const [dataResultDocumentos, setDataResultDocumentos] = React.useState(
    process(documentosModelos, dataStateDocumentos)
  );

  const optionsExportar = [
    {
      key: 'ExportarListaResultadosExcel',
      text: 'excel',
    },
    {
      key: 'ExportarListaResultadosShapeFile',
      text: 'Shapefile',
    },
    {
      key: 'ExportarTodasFichas',
      text: 'Fichas',
    },
  ]

  const itemRender = (props: { item: any; itemIndex: number }) => {
    const isTitleItem = props.item.key === 'titulo';
    if (isTitleItem) {
      // Item de título personalizado
      return (
        <div className={styles.DropDownButtonTitulo}>
          <span>{props.item.text}</span>
        </div>
      );
    } else {
      // Demais itens
      return (
        <div className={styles.DropDownButtonItem}>
          <span>{props.item.text}</span>
        </div>
      );
    }
  };

  let _pdfExport: any;
  const exportExcel = () => {
    _export.save();
  };

  const onItemClickExportarFichas = async () => {
    try {
  
      const relatorios = [];
  
      const results = JSON.parse(
        SessionStorageService.getItem('resultadoModelosPesquisa') ?? ''
      );
    
   
      
    const idsModelo = results.map((o: any) => o.id);

    if(idsModelo.length  > RECORDS_LIMIT){
      postMessage({ type: 'errorMsg', value: { message: "Não é possivel exportar mais de 100 fíchas" } }, '*')
      return false
      }

    const modelos = await getModelosPorIds(idsModelo);

        postMessage({ type: 'showLoader' }, '*');

        for (const modeloId of idsModelo) {
            const modelo = modelos.find((modelo: any) => modelo.id === modeloId);


            if (!modelo) {
              continue; // If no model is found, skip to the next iteration
            }
      
     
        const relatorio = {
          id: modelo?.id,
          dados: {

            //Registo
            'REGISTO': '',
            'Data do registo:': modelo?.data_registo ? new Date(modelo?.data_registo).toLocaleDateString() : '',
            'Estado do registo:': modelo?.moEstado?.designacao ?? '',
            'Técnico:': modelo?.utilizador?.name ?? '',
            'Entidade do registo:': entidadeUser ?? '',
  
            //Identificacao
            'IDENTIFICAÇÃO': '',
            'Titulo do modelo:' : modelo?.designacao ?? '',
            'Data da publicação:': modelo?.data_publicacao ? new Date(modelo?.data_publicacao).toLocaleDateString() : '',
            'Autores:' : modelo?.autores ?? '',
            'Entidade:': modelo?.entidade?.nome ?? '',
  
            //Localizacao
            'LOCALIZAÇÃO' : '',
            'Distrito:': modelo?.distritos?.nome ?? '',
            'Concelho:': modelo?.concelhos?.nome ?? '',
            'Freguesia:': modelo?.lstFreguesias?.nome ?? '',
            'Nuts1:': modelo?.nuts1?.nome ?? '',
            'Nuts2:': modelo?.nuts2?.nome ?? '',
            'Nuts3:': modelo?.nuts3?.nome ?? '',
  
            //Tipologia
            'TIPOLOGIA' : '',
            'Tipo de modelo:': modelo?.modeloTipo?.designacao ?? '',
            'Subtipo de modelo:': modelo?.modeloSubtipo?.designacao ?? '',
            
            //Detalhes
            'DETALHES' : '',
            'Descrição:' : modelo?.descricao ?? '',
            'Tipo de acesso:' : modelo?.tipoAcesso?.designacao ?? '',
            'URL:' : modelo?.url ?? '',
            'Fonte:' : modelo?.fonte ?? '',
            'Observações:' : modelo?.observacoes ?? ''
  
  
          },
          img_mapa: "",
        };

        
        relatorios.push(relatorio);
    }
  
      const mergedPdfBase64 = await relatorioModeloMerge(relatorios);
      if (mergedPdfBase64) {
        const blob = b64toBlob(mergedPdfBase64, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
      }
    } catch (error) {
      console.log(error);
      postMessage({ type: 'errorMsg', value: { left: '50%', message: "Verifique os campos obrigatórios." } }, '*');
    } finally {
      postMessage({ type: 'hideLoader' }, '*');
    }
  };

  const onItemClickExportar = async(event: any) => {

    if(event.item.key == "ExportarTodasFichas"){
      onItemClickExportarFichas();
    }

    if (event.item.key == 'ExportarListaResultadosExcel') {

      exportExcel();

    }
    else if (event.item.key == "ExportarListaResultadosShapeFile") {
        const results = JSON.parse(
          SessionStorageService.getItem('resultadoModelosPesquisa') ?? ''
        )
          if (results) {
            const iframe: any = document.getElementById('iframeGeoPortal')
            const ids: number[] = []
            results.forEach((element: any) => {
              ids.push(element.id)
            })
    
            const columnsToExport = colums.columns.filter(x => x.show).map(x => x.field);
            const modelosFiltred: any[] = [];
            modelos?.forEach((item: any) => {
              const filteredItem: any[] = [];
              colums.columns.filter(x => x.show).forEach((column: any) => {
                filteredItem.push({
                  Key: column.field,
                  Value: item[column.field]?.toString()
                });
              });
              modelosFiltred.push(filteredItem);
          })
    
            const objToSend = {
              ids: ids,
              fields: columnsToExport,
              values: modelosFiltred
            }
    
            const file: any = await getShapeFile(objToSend);
            const filePath = `siarl-lista-modelos-${format(new Date(), 'ddMMyyyHHmm')}.zip`;
    
           handleDownload(file, filePath);

      } 
    }
  };

  let _export: any;
  const exportPDF = () => {
    _pdfExport.save();
  };

  const onItemClickImprimir = async (event: any) => {
    openPdfNewTab();
  }

const close = () => {
  setOpenForm(false);
}
  

useEffect(()=>{
  if(hasPermission(PERMISSION_MODELO_PERFIL_TOTAL) || hasPermission(PERMISSION_MODELO_PERFIL_COMPLETO_LEITURA)){
    setColums({ columns: [...colunasPadrao, ...colunasAdicionais] });
  }
},[])

useEffect(() => {
  SessionStorageService.setItem(keyPainelDocumentos, JSON.stringify(columsDocuments));
}, [columsDocuments]);

useEffect(() => {
  SessionStorageService.setItem(keyPainelPrincipal, JSON.stringify(colums));
}, [colums]);

useLayoutEffect(() => {

  const handleResize = () => {

    if (window.innerWidth <= telaMobile) {
      
      setIsMobile(true);
      ocultaFloatingMenu_e_Mapa();

    }else{

      setIsMobile(false);
      mostraFloatingMenu_e_Mapa();

    }

  };

  window.addEventListener('resize', handleResize);

  handleResize();

  return () => {
    window.removeEventListener('resize', handleResize);
  };

}, []); 


const onItemClickMais = (event: any) => {

  elementoClickMais(event);

  if(optionsExportar.some(option => option.key === event.item.key)){
    onItemClickExportar(event);
  }

};

const optionsMais = [
  {
    key: 'titulo',
    text: 'Imprimir',
  },
  {
    key: 'imprimirFicha',
    text: 'Resultados',
    className: 'icon-siarl icon-imprimir btnIconRegistar'
  },
  {
    key: 'titulo',
    text: 'Exportar',
  },
  ...optionsExportar.map(option => ({
    ...option,
    className: 'icon-siarl icon-exportar btnIconRegistar',
  })),
]

const registarNovoClick = () => {
    selectModeloResult(undefined);
    selectModeloResultId(undefined);
    setModeloSelected(undefined);
    SessionStorageService.setItem("novoModelo", 'true');
    postMessage({ type: "form", value: "registarModelos" }, "*");
}

const openAjuda = () => {
  window.open(URL_AJUDA_MODELOS, "_blank")
}

return (
  <div className={clsx(styles.base, isCurrentViewMaximized && commonStyles.maximizedBaseForm)}>
    <div className={styles.header}>
      <div>
        <div className={styles.nomeDoModulo}>Modelos</div>
        <div className={styles.tituloDaPagina}>Resultados de pesquisa</div>
      </div>

      <div>

        {hasPermission(PERMISSION_MODELO_PERFIL_TOTAL) ?
          <Button onClick={registarNovoClick} className={styles.btnNovoRegisto}
          style={{ display: isMobile ? 'none' : '' }}
          id='novoRegisto'
          title='Novo Modelo'
          >
            <span className="icon-siarl icon-registar btnIconRegistar"></span>{' '}
            &nbsp;{' '}
            Novo Modelo
          </Button>
        :<></>}

        <CSVLink
          title="Imprimir resultados"
          id="print-results-modelos"
          separator={';'}
          data={modelos.map((modelo: any) => ({
            ...modelo,
          }))}
          filename={`siarl-modelos-${format(new Date(), 'ddMMyyyHHmm')}`}
          hidden
        ></CSVLink>

      {!isMobile &&
        <VisualizadorColunas 
          columns={selected===0?colums:columsDocuments} 
          setColumns={selected===0?setColums:setColumsDocuments}
          styles={styles.btnEditar2} 
        />
      }

        <button className={styles.btnEditar2} onClick={onItemClickImprimir} style={{ display: isMobile ? 'none' : '' }} id='imprimirFicha' title='Imprimir'>
          <span className="icon-siarl icon-imprimir"></span>
        </button>

        {!isMobile &&
        <DropDownButton
          items={optionsExportar}
          className={styles.btnImprimir}
          style={{marginTop: '-4px'}}
          iconClass="icon-siarl icon-exportar"
          onItemClick={onItemClickExportar}
          fillMode={'flat'}
          itemRender={itemRender}
          title='Exportar'
        ></DropDownButton>
        }

        {isMobile &&
          <DropDownButton
            items={optionsMais}
            className={styles.btnMais}
            iconClass="icon-siarl icon-mais"
            onItemClick={onItemClickMais}
            fillMode={'flat'}
            itemRender={itemRenderMais}
            title="Mais Opções"
          />
        }

        <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
          <span className="icon-siarl icon-ajuda"></span>
        </button>
        <button className={styles.btnFechar} onClick={onCloseClick} title="Minimizar">
          <span className="icon-siarl icon-minimizar"></span>
        </button>

        {!isMobile &&
        <MaximizeButton className={styles.btnMaximizar} />
        }
      </div>
    </div>

    <TabStrip selected={selected} onSelect={handleSelect} scrollable={isMobile}>
      <TabStripTab title="Modelos">
        
        <ExcelExport
          fileName='Resultado_Modelos'
          data={modelos}
          ref={(exporter) => {
            _export = exporter
          }}
        >
          <Grid
            className={styles.form}
            sortable={true}
            onDataStateChange={dataStateChange}
            data={dataResult.data.length > 0 ? dataResult : modelos.slice(page.skip, page.take + page.skip)}
            {...dataState}
            skip={page.skip}
            take={page.take}
            pageable={{
              buttonCount: 5,
              info: true,
              type: 'numeric',
              pageSizes: true,
              previousNext: true,
            }}
            total={modelos.length}
            //groupable={true}
          >
            <GridColumn cell={modeloCustomCell} width={60} />

            {colums?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
          </Grid>
        </ExcelExport>

        <GridPDFExport 
          fileName='Resultado_Modelos'
          ref={(element) => {
            _pdfExport = element
          }}
          paperSize={['340mm', '297mm']}         

          margin="1cm"
        >
          <Grid
            className={styles.form}
            sortable={true}
            onDataStateChange={dataStateChange}
            data={dataResult.data.length > 0 ? dataResult : modelos}
            {...dataState}
            pageable={{
              buttonCount: 5,
              info: true,
              type: 'numeric',
              pageSizes: true,
              previousNext: true,
            }}
            //groupable={true}
          >
            <GridColumn cell={modeloCustomCell} width={60} />
            {colums?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
          </Grid>
        </GridPDFExport>
      </TabStripTab>

      <TabStripTab title="Documentos">
        <ExcelExport
          fileName='Resultado_DocumentosModelos'
          data={documentosModelos}
          ref={(exporter) => {
            _export = exporter
          }}
        >
          <Grid
            className={styles.form}
            sortable={true}
            onDataStateChange={dataStateChangeDocumentos}
            data={
              dataResultDocumentos.data.length > 0
                ? dataResultDocumentos
                : documentosModelos
            }
            {...dataState}
            pageable={{
              buttonCount: 5,
              info: true,
              type: 'numeric',
              pageSizes: true,
              previousNext: true,
            }}
            //groupable={true}
          >
            <GridColumn cell={documentCustomCell} width={86} />
            {columsDocuments?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
          </Grid>
        </ExcelExport>

        <GridPDFExport
          fileName='Resultado_Documentos_Modelos'
          ref={(element) => {
            _pdfExport = element
          }}
          margin="1cm"
        >
          <Grid
            className={styles.form}
            sortable={true}
            onDataStateChange={dataStateChangeDocumentos}
            data={
              dataResultDocumentos.data.length > 0
                ? dataResultDocumentos
                : documentosModelos
            }
            {...dataState}
            pageable={{
              buttonCount: 5,
              info: true,
              type: 'numeric',
              pageSizes: true,
              previousNext: true,
            }}
            //groupable={true}
          >
            <GridColumn cell={documentCustomCell} width={86} />
            {columsDocuments?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
          </Grid>
        </GridPDFExport>
      </TabStripTab>
    </TabStrip>
    {openForm && (
        <Dialog
        className="ModalDocumentos"
          title={`Documento: ${idDoDocumento}`}
          onClose={close}
          style={{ zIndex: 999 }}
        >
            <ImagePreviewV2
              documentUrl={urlDoDocumento}
              next={(e: any) => nextPhoto(e)}
              previous={(e: any) => previousPhoto(e)}
            />

        </Dialog>
      )}
    <div
      className={clsx(styles.footer, styles.justify_one_child, isCurrentViewMaximized && commonStyles.maximizedFooter)}
    >
      <Button className={styles.btnPesquisar} onClick={clearSearchResults} title='Nova Pesquisa'>
        <span className="icon-siarl icon-pesquisar"></span>
        <span className={styles.rotulo}>
          {' '}&nbsp;{' '}
          Nova pesquisa
        </span>
      </Button>
    </div>
  </div>
)
}