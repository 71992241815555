import { process } from '@progress/kendo-data-query';
import { Button, DropDownButton } from '@progress/kendo-react-buttons';
import { PagerTargetEvent } from '@progress/kendo-react-data-tools';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridColumnMenuCheckboxFilter,
  GridColumnMenuFilter,
  GridColumnMenuGroup,
  GridColumnMenuSort,
  GridPageChangeEvent,
  GridToolbar,
} from '@progress/kendo-react-grid';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { GridPDFExport } from "@progress/kendo-react-pdf";
import clsx from 'clsx';
import { format } from 'date-fns';
import React, {
  Dispatch,
  MouseEvent as ReactMouseEvent,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { CSVLink } from 'react-csv';
import SessionStorageService from '../../services/SessionStorageService';
import { GetUserNameTecnico, b64toBlob, clearEntidade, exportPDFWithMethod, fillLocation, get, handleDownload, handleGenericError, hasPermission, post, setEntidadeSelected, useCombos } from '../../services/utils';
import { useCommonStore } from '../../stores/common';
import { useEntidadeStore } from '../../stores/entidade';
import commonStyles from '../../styles/common/Common.module.scss';
import styles from '../../styles/entidade/ResultadoEntidadeForm.module.scss';
import { AUTH_LOCALSTORAGE, CONSULTAR_ENTIDADES, CRITERIO_JURISDICAO, CRITERIO_SEDE, CURRENT_TIMEZONE, GET_DOCUMENTO, GET_ENTIDADE, GET_ENTIDADE_JURISDICAO_BY_ENTIDADE, GET_ENTIDADE_SHAPEFILE, GET_LIST_RESULTS_DI_ENTIDADE, GET_USER_BY_ID, PERMISSION_ENTIDADES_PERFIL_COMPLETO_EDICAO, PERMISSION_ENTIDADES_PERFIL_COMPLETO_LEITURA, PERMISSION_ENTIDADES_PERFIL_TOTAL, PESQUISAR_DOCUMENTOS_BY_ENTIDADES, RELATORIO_MERGE_ENTIDADE, URL_AJUDA_ENTIDADES, URL_FONT_GRID_PDF, clearLayers } from '../../utils/constants';
import MaximizeButton from '../common/MaximizeButton';
import CustomGridCell from '../ocorrencia/CustomGridCell';
import { ResultadoEntidadeCommandGridCell } from './ResultadoEntidadeCommandGridCell';
import useClearMap from '../../hooks/useClearMap';
import { ResultadoDocumentoCommandGridCell } from '../documentos/ResultadoDocumentoCommandGridCell';
import { Dialog } from '@progress/kendo-react-dialogs';
import ImagePreviewV2 from '../viewer/ImagePreviewV2';
import VisualizadorColunas from '../Generico/VisualizadorColunas ';
import useVisibleColumns, { Key } from '../../hooks/useVIsibleColumns';
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais';
import ReactGA from 'react-ga4';
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import { defineFont } from '@progress/kendo-drawing/pdf';
import moment from 'moment'
import "moment-timezone"

type Props = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
  setShowResults: Dispatch<SetStateAction<boolean>>
  setShowEntidadeForm?: Dispatch<SetStateAction<boolean>>
}

type PageState = {
  skip: number
  take: number
}


const initialDataState: PageState = { skip: 0, take: 20 }
const keyPainelPrincipal:Key = 'entidadeColunasVisiveis';
const keyPainelDocumentos:Key = 'entidadeDocumentosColunasVisiveis';

export default function ResultadoEntidadeForm({
  onCloseClick,
  setShowResults,
  setShowEntidadeForm,
}: Props) {
  const [isMobile, setIsMobile] = useState(false);
  const [page, setPage] = useState<PageState>(initialDataState)
  const [paginacao, setPaginacao] = useState<PageState>(initialDataState)
  const [pageSizeValue, setPageSizeValue] = useState<
    number | string | undefined
  >()
  const [entidades, setEntidades] = useState([])
  const selectEntidadeResult = useEntidadeStore(
    (state) => state.selectEntidadeResult
  )

  const [urlDoDocumento, setUrlDoDocumento] = useState("");
  const [idDoDocumento, setIdDoDocumento] = useState("");
  const [openForm, setOpenForm] = useState<boolean>(false)
  const isCurrentViewMaximized = useCommonStore(state => state.isCurrentViewMaximized)
  const restoreView = useCommonStore(state => state.restoreView)
  const {getVisibleColumns, removeColumnsKeys} = useVisibleColumns();
  const[entidadesFinal, setEntidadesFinal] = useState<any[]>([]);
  const [pageble, setPageble] = useState<any>({
    buttonCount: 5,
    info: true,
    type: 'numeric',
    pageSizes: true,
    previousNext: true,
  })

  const relatorioEntidadeMerge = async (data: any) =>
    await post<any, any>(`${RELATORIO_MERGE_ENTIDADE}`, data)

  const getEntidadesPorIds = async (data: any) =>
    await post<any, any>(`${GET_LIST_RESULTS_DI_ENTIDADE}`, data)

  const getEntidade = async (data: any) =>
    await get<any, any>(`${GET_ENTIDADE}/` + data)

    const consultarEntidades = async (data: any) =>
    await post<any, any>(`${CONSULTAR_ENTIDADES}`, data)

    const getEntidadeJuridicao = async (data: any) => 
    await get<any,any>(GET_ENTIDADE_JURISDICAO_BY_ENTIDADE + "/" + data);

   const getShapeFile = async (data: any) => await post<any, any>(GET_ENTIDADE_SHAPEFILE, data);

  const getDocumento = async (data: any) =>
    await get<any, any>(`${GET_DOCUMENTO}/` + data)
  
  const getUserById = async (data: any) =>
  await get<any, any>(`${GET_USER_BY_ID}/` + data)

  const getDocumentosByEntidades = async (data: any) =>
    await post<any, any>(`${PESQUISAR_DOCUMENTOS_BY_ENTIDADES}`, data)

  const initialized = useRef(false)
  useEffect(() => {
      if (!initialized.current) {
        initialized.current = true;      
        if (SessionStorageService.getItem("lastSearchEntidades") && !SessionStorageService.getItem("firstCallEntidade")) {
        const objToSearch = SessionStorageService.getItem("lastSearchEntidades") ?? '';
        consultarEntidades(objToSearch).then((data) => {
          SessionStorageService.setItem("resultadoEntidadePesquisa", JSON.stringify(data));
          setResultsEntidades();
        })
      } 
      else {
        setResultsEntidades();
    }
  }
    }, [])

    useEffect(() => {
      ReactGA.send({ hitType: "pageview", page: "/ResultadoEndidades" });
    }, []);


    const setResultsEntidades = () => {
      const results = JSON.parse(
      SessionStorageService.getItem('resultadoEntidadePesquisa') ?? ''
    )

    const idsJurisdicao: number[] = [];
    const ids: number[] = [];
    results.forEach((element: any) => {
      ids.push(element.id);
      if (element?.diEntidadeJurisdicoes && element?.diEntidadeJurisdicoes.length > 0) {
        idsJurisdicao.push(element?.diEntidadeJurisdicoes[0].id);
      }
    });
    const iframe: any = document.getElementById('iframeGeoPortal')
    const criterioEntidades = JSON.parse(SessionStorageService.getItem("criterioEntidades"));
    if (criterioEntidades == CRITERIO_SEDE) {
      SessionStorageService.setItem("criterioEntidades", JSON.stringify(CRITERIO_SEDE));
      iframe.contentWindow?.postMessage(
        { type: 'SiarlEntidadesSearch', value: ids },
        '*'
      )
    }

    const formattedResults = results.map((entidade: any) => ({
      ...entidade,
    }))
    setEntidades(formattedResults);
    setEntidadesFinal(formattedResults.slice(paginacao.skip, paginacao.take + paginacao.skip))
    getVisibleColumns(keyPainelPrincipal, setColums)
    getVisibleColumns(keyPainelDocumentos, setColumsDocuments)
    getDocumentosByEntidades(ids).then(resultDocs => {
      setDocumentos(resultDocs);
    });

  }

  useEffect(() => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    const ids: number[] = []
    entidades.forEach((element: any) => {
      ids.push(element.id)
    })
    iframe.contentWindow?.postMessage(
      { type: 'SiarlEntidadesSearch', value: ids },
      '*'
    )
  }, [])
  
  const [clearMap] = useClearMap();
  const clearSearchResults = () => {
    clearMap(clearLayers["CLEAR_LAYERS_ENTIDADES"]);
    SessionStorageService.removeItem("entidade");
    SessionStorageService.removeItem('resultadoEntidadePesquisa')
    SessionStorageService.removeItem("lastSearchEntidades");
    postMessage({ type: 'resultadoEntidadePesquisaChange' }, '*')
    selectEntidadeResult(null)
    removeColumnsKeys(keyPainelDocumentos)
    removeColumnsKeys(keyPainelPrincipal)
  }

  const EntidadeCustomCell = (props: GridCellProps) => (
    <CustomGridCell
      {...props}
      onMouseEnter={handleRowMouseEnterById}
      onMouseLeave={handleRowMouseLeaveById}
    />
  )

  const handleRowMouseEnterById = (entidade: Record<string, any>) => {
    console.log(`#${entidade.id} - mostrar popover no mapa`)
    const iframe: any = document.getElementById('iframeGeoPortal')
    //iframe.contentWindow?.postMessage({ type: "SiarlOcorrenciasSearch", value: [ocorrencia.id] }, '*')
    if (entidade.id) {
      if (JSON.parse(SessionStorageService.getItem("criterioEntidades")) == CRITERIO_SEDE) {
        iframe.contentWindow?.postMessage(
          {
            type: 'SiarlEntidadesHighlight',
            value: [entidade.id],
          },
          '*'
        )
      }
    } else if (JSON.parse(SessionStorageService.getItem("criterioEntidades")) == CRITERIO_JURISDICAO) {
      if (entidade?.diEntidadeJurisdicoes && entidade?.diEntidadeJurisdicoes.length > 0) {
        iframe.contentWindow?.postMessage(
          {
            type: 'SiarlEntidadesApoioHighlight',
            value: [entidade?.diEntidadeJurisdicoes[0].id],
          },
          '*'
        )
      }
    }
  }

  const handleRowMouseLeaveById = (entidade: Record<string, unknown>) => {
    console.log(`#${entidade.id} - remover popover no mapa`)
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
      {
        type: 'SiarlEntidadesHighlightRemove',
        value: [entidade.id],
      },
      '*'
    )
  }

  const handleLocationMouseEnterById = (
    entidade: Record<string, unknown>
  ) => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
      {
        type: 'SiarlEntidadesHighlight',
        value: [entidade.id],
      },
      '*'
    )
  }

  const handleLocationMouseLeaveById = (
    entidade: Record<string, unknown>
  ) => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
      {
        type: 'SiarlEntidadesHighlightRemove',
        value: [entidade.id],
      },
      '*'
    )
  }

  const handleInfoClickById = (entidadeLocal: Record<string, any>) => async () => {
    
    const id = entidadeLocal.entidade_id ?? entidadeLocal.id;
    const entidade = await getEntidade(id);

     if (!entidade) {
      postMessage({type: "infoMsg", value: { message: "Registo desativado" }}, "*");
      return;
    }

    const tecnico = await GetUserNameTecnico(entidade.utilizador_id);

    const locationObj: any = await fillLocation(entidade);

    const entidadeResult = {
      id: entidade.id,
      nome: entidade.nome ?? undefined,
      tecnicoRegisto: tecnico,
      sigla: entidade.codigo ?? undefined,
      morada: entidade.morada ?? undefined,
      localidade: entidade.localidade ?? undefined,
      tipo_entidade_id: entidade.tipo_entidade_id,
      tipoEntidade: entidade.tipoEntidade,
      email: entidade.oc_focal_point_email ?? undefined,
      url: entidade.url ?? undefined,
      jurisdicao: entidade.oc_notify_email_new_issue,
      telefone: entidade.telefone ?? undefined,
      codigoPostal: entidade.codigo_postal ?? undefined,
      tipoAcesso: entidade.tipoAcesso,
      tipo_acesso_id: entidade.tipo_acesso_id,
      dataHoraRegisto: entidade.data_registo ? moment.utc(entidade.data_registo).tz(CURRENT_TIMEZONE) : undefined,
      freguesia: locationObj?.freguesia ?? undefined,
      concelho: locationObj?.concelho ?? undefined,
      distrito: locationObj?.distrito ?? undefined,
      nuts1: locationObj?.nuts1 ?? undefined,
      nuts2: locationObj?.nuts2 ?? undefined,
      nuts3: locationObj?.nuts3 ?? undefined,
      diEntidadeJurisdicoes: entidade.diEntidadeJurisdicoes ?? undefined,
      lstFreguesiasJurisdicao: entidade.lstFreguesiasJurisdicao ?? undefined,
      lstConcelhosJurisdicao: entidade.lstConcelhosJurisdicao ?? undefined,
      lstDistritosJurisdicao: entidade.lstDistritosJurisdicao ?? undefined,
      lstNuts3Jurisdicao: entidade.lstNuts3Jurisdicao ?? undefined,
      lstNuts2Jurisdicao: entidade.lstNuts2Jurisdicao ?? undefined,
      lstNuts1Jurisdicao: entidade.lstNuts1Jurisdicao ?? undefined,
      lstMassaAguaJurisdicao : entidade?.lstMassaAguaJurisdicao ?? undefined,
      lstArhsJurisdicao: entidade?.lstArhsJurisdicao ?? undefined,
      lstCapitaniasJurisdicao: entidade?.lstCapitaniasJurisdicao ?? undefined,
      geom_imported: entidade?.geom_imported,
    }
    SessionStorageService.setItem('novaEntidade', 'false')
    sessionStorage.setItem('diGeomEdited', 'false')

    sessionStorage.setItem("jurisdicaoEntidade", entidade.oc_notify_email_new_issue ?? "")
    selectEntidadeResult(entidadeResult)
    setEntidadeSelected(entidadeResult);
    // sessionStorage.setItem("entidadeId", entidade.id);
    const iframe: any = document.getElementById('iframeGeoPortal')


    //iframe.contentWindow?.postMessage({ type: "SiarlEntidadesSearch", value: [entidade.id] }, '*')

    iframe.contentWindow?.postMessage({ type: "SiarlEntidadesCenter", value: [entidade.id] }, '*')

    const entidadeJuridicao: any = await getEntidadeJuridicao(entidade?.id);

    if (entidadeJuridicao) {
      const idToSend = [entidadeJuridicao.id];
      const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.contentWindow?.postMessage({ type: "SiarlEntidadesApoioSearch", value: idToSend }, '*')
    }
    setShowResults(false)
    if (setShowEntidadeForm) setShowEntidadeForm(true)
  }

  const handlePageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent
    const take = event.page.take

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value)
    }

    restoreView();

    setPage({
      ...event.page,
      take,
    })
  }

  const handleLocationClickById = (entidade: Record<string, any>) => () => {
    restoreView();
    const iframe: any = document.getElementById('iframeGeoPortal')
    // if (JSON.parse(SessionStorageService.getItem("criterioEntidades")) == CRITERIO_JURISDICAO) {
    //   if (entidade?.diEntidadeJurisdicoes && entidade?.diEntidadeJurisdicoes.length > 0) {
    //     const idToSend = [entidade?.diEntidadeJurisdicoes[0].id];
    //     const iframe: any = document.getElementById('iframeGeoPortal')
    //     iframe.contentWindow?.postMessage({ type: "SiarlEntidadesApoioSearch", value: idToSend }, '*')
    //   }
    // } else {
      const id = entidade.entidade_id ?? entidade.id;
      iframe.contentWindow?.postMessage(
        {
          type: 'SiarlEntidadesCenter',
          value: [id],
        },
        '*'
      )
    //}
  }
  const canSeeEntidadesEdit = hasPermission(PERMISSION_ENTIDADES_PERFIL_COMPLETO_EDICAO) || hasPermission(PERMISSION_ENTIDADES_PERFIL_TOTAL)
  const completoLeitura = hasPermission(PERMISSION_ENTIDADES_PERFIL_COMPLETO_LEITURA);

  const conditionalProps = canSeeEntidadesEdit || completoLeitura
  ? { 
    onInfoClick: handleInfoClickById
   } 
  : {};
  const CommandCell = (props: GridCellProps) => (
    <ResultadoEntidadeCommandGridCell
      {...props}
      onLocationClick={handleLocationClickById}
      onLocationMouseEnter={handleRowMouseEnterById}
      onLocationMouseLeave={handleRowMouseLeaveById}
     {...conditionalProps}
    />
  )

  const DocumentCommandCell = (props: GridCellProps) => (
    <ResultadoDocumentoCommandGridCell
    onLocationMouseEnter={function (documentos: Record<string, unknown>): void {
      console.log("n/a");
    } } onLocationMouseLeave={function (documentos: Record<string, unknown>): void {
      console.log("n/a");
    } } {...props}
    onLocationClick={handleLocationClickById}
    onInfoClick={handleInfoClickById}
    onDocumentPreviewClick={handleDocumentPreviewClickById}    />
  )

  const ColumnMenu = (dataState: any) => {
    return (
      <div>
      <GridColumnMenuSort {...dataState} />
      <GridColumnMenuFilter {...dataState} />
      <GridColumnMenuGroup {...dataState} />
    </div>)

  }

  const [documentos, setDocumentos] = useState<any>([]);
  const [selected, setSelected] = useState<number>(0);
  

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected)
  }

  const createDataState = (dataState: any) => {
    return {
      result: process(entidades.slice(0), dataState),
      dataState: dataState
    };
  };
  const initialState = createDataState({
    take: 8,
    skip: 0
  });
  const [result, setResult] = React.useState(initialState.result);
  const [dataState, setDataState] = React.useState({
    skip: 0,
    take: 20,
    sort: [],
    group: [],
  });

  const [dataStateDocumentos, setDataStateDocumentos] = React.useState({
    skip: 0,
    take: 20,
    sort: [],
    group: [],
  });

  const [dataResult, setDataResult] = React.useState(
    process(entidades, dataState)
  );

  const dataStateChange = (event: any) => {
    setDataResult(process(entidades, event.dataState));
    setDataState(event.dataState);

    const skipPag: PageState = { skip: event.dataState.skip, take: event.dataState.take}
setPaginacao({
  ...skipPag
})

  };


  const dataStateChangeDocumentos = (event: any) => {
    setDataResultDocumentos(process(documentos, event.dataState));
    setDataStateDocumentos(event.dataState);
  };

  const [dataResultDocumentos, setDataResultDocumentos] = React.useState(
    process(documentos, dataStateDocumentos)
  );

  const optionsImprimir = [
    {
      key: 'ExportarListaResultados',
      text: 'Resultados',
    },
  ]

  const optionsExportar = [
    {
      key: 'ExportarListaResultadosExcel',
      text: 'excel',
    },
    {
      key: 'ExportarListaResultadosShapeFile',
      text: 'Shapefile'
    },
    {
      key: 'ExportarTodasFichas',
      text: 'Fichas',
    },
  ]

  const itemRender = (props: { item: any; itemIndex: number }) => {
    const isTitleItem = props.item.key === 'titulo';
    if (isTitleItem) {
      // Item de título personalizado
      return (
        <div className={styles.DropDownButtonTitulo}>
          <span>{props.item.text}</span>
        </div>
      );
    } else {
      // Demais itens
      return (
        <div className={styles.DropDownButtonItem}>
          <span>{props.item.text}</span>
        </div>
      );
    }
  };

  const [columsDocuments, setColumsDocuments] = useState<{ columns: {field?:string, title?:string, width?:number|string, show:boolean, columnMenu?:any, filter?:any}[] }>({
    columns: [
      { field: "id", title: "Id", filter: 'numeric', columnMenu: ColumnMenu,show:true },
      { field: "nome", title: "Nome", columnMenu: ColumnMenu,show:true },
      { field: "tipo", title: "Tipo", show: true, width: "auto", columnMenu: ColumnMenu},
    ]
  })

  const [colums, setColums]=useState<{columns:{field?:string, title?:string, width?:number|string, show:boolean, columnMenu?:any, filter?:any}[]}>({
    columns:[
      {field: "id", title: "Id", filter: 'numeric', columnMenu: ColumnMenu, show:true , width: 90},
      {field: "nome", title: "Nome", columnMenu: ColumnMenu ,show:true},
      {field: "tipo", title: "Tipo", show: true, width: 180, columnMenu: ColumnMenu },
      {field: "codigoPostal", title: "Codigo Postal", show: false, width: 180, columnMenu: ColumnMenu },
      {field: "localidade", title: "Localidade", show: true, width: 190, columnMenu: ColumnMenu },
      {field: "telefone", title: "Telefone", show: true, width: 170, columnMenu: ColumnMenu },
      {field: "morada", title: "Morada", show: false, width: 190, columnMenu: ColumnMenu },
    ]
  })

  const onItemClickExportarFichas = async () => {
    try {
  
    
      const relatorios = [];
  
      const results = JSON.parse(
        SessionStorageService.getItem('resultadoEntidadePesquisa') ?? ''
      );
    

      const idsEntidade = results.map((o: any) => o.id);
      if(idsEntidade.length  > 100){
      postMessage({ type: 'errorMsg', value: { message: "Não é possivel exportar mais de 100 fíchas" } }, '*')
      return false
      }  
  
      const entidades = await getEntidadesPorIds(idsEntidade);
      
        postMessage({ type: 'showLoader' }, '*');
      
        for (const entidadeId of idsEntidade) {
  
        const entidade = entidades.find((entidade: any) => entidade.id === entidadeId);
  
     
        const relatorio = {
          id: entidade?.id,
        dados: {
          //Registo
          'REGISTO' : '',
          'Técnico:': entidade?.utilizador?.name ?? '',

          //Identificacao
          'IDENTIFICAÇÃO' : '',
          'Nome:': entidade?.nome ?? '',
          'Sigla:': entidade?.codigo ?? '',
          'Url:': entidade?.url ?? '',
          'Email:': entidade?.on_focal_point_email ?? '',
          'Telefone:': entidade?.telefone ?? '',

          //Localizacao
          'LOCALIZAÇÃO' : '',
          'Nuts1:' : entidade?.apNuts3?.nuts2?.nuts1?.nome ?? '',
          'Nuts2:': entidade?.apNuts3?.nuts2?.nome ?? '',
          'Nuts3:' : entidade?.apNuts3?.nome  ?? '',
          'Concelho:' : entidade?.apFreguesia?.concelho?.nome ?? '',
          'Distrito:' : entidade?.apFreguesia?.concelho?.distrito?.nome ?? '',
          'Freguesia:' : entidade?.apFreguesia?.nome ?? '',
          'Morada:' : entidade?.morada ?? '',
          'Código postal:': entidade?.codigo_postal ?? '',
          'Localidade:': entidade?.localidade ?? '',
         
          //Entidade
          'ENTIDADE': '',
          'Tipo de entidade:' : entidade?.tipoEntidade?.designacao ?? '',
          'Tipo de acesso:' : entidade?.tipoAcesso?.designacao ?? ''
          
        },       
        img_mapa: "",
      };

        
        relatorios.push(relatorio);
    }
  
      const mergedPdfBase64 = await relatorioEntidadeMerge(relatorios);
      if (mergedPdfBase64) {
        const blob = b64toBlob(mergedPdfBase64, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
      }
    } catch (error) {
      console.log(error);
      postMessage({ type: 'errorMsg', value: { left: '50%', message: "Verifique os campos obrigatórios." } }, '*');
    } finally {
      postMessage({ type: 'hideLoader' }, '*');
    }
  };


  const onItemClickExportar = async(event: any) => {
    if(event.item.key == "ExportarTodasFichas"){
      onItemClickExportarFichas();
    }

    if (event.item.key == 'ExportarListaResultadosExcel') {

      exportExcel();

    } else if (event.item.key == 'ExportarListaResultadosPdf') {

      exportPDFDownload();

    }
    else if (event.item.key == "ExportarListaResultadosShapeFile") {
      const results = JSON.parse(
        SessionStorageService.getItem('resultadoEntidadePesquisa') ?? ''
      )
  
        if (results) {
          const iframe: any = document.getElementById('iframeGeoPortal')
          const ids: number[] = []
          results.forEach((element: any) => {
            ids.push(element.id)
          })
  
          const columnsToExport = colums.columns.filter(x => x.show).map(x => x.field);
          const entidadesFiltred: any[] = [];
          entidades?.forEach((item: any) => {
            const filteredItem: any[] = [];
            colums.columns.filter(x => x.show).forEach((column: any) => {
              filteredItem.push({
                Key: column.field,
                Value: item[column.field]?.toString()
              });
            });
            entidadesFiltred.push(filteredItem);
        })
  
          const objToSend = {
            ids: ids,
            fields: columnsToExport,
            values: entidadesFiltred
          }
  
          const file: any = await getShapeFile(objToSend);
          const filePath = `siarl-lista-entidades-${format(new Date(), 'ddMMyyyHHmm')}.zip`;
  
         handleDownload(file, filePath);
    } 
  }

  };

  const documentosToPreview = documentos;
  
  let _pdfExport: any;
  const exportExcel = () => {
    _export.save();
  };

  let _export: any;
  const exportPDFDownload = () => {
     _pdfExport.save();
   };




   
   const openPdfNewTab = () => {
    return new Promise<void>((resolve, reject) => {
        
        postMessage({ type: "showLoader" }, "*");

        setTimeout(() => {
           const columnsToShow = colums.columns.filter(x => x.show);
            const dataToExport = entidades.map((item: any) => {
                const filteredItem: any = {};
                columnsToShow.forEach((column: any) => {
                    filteredItem[column.field] = item[column.field];
                });
                return filteredItem;
            });
            
            exportPDFWithMethod(columnsToShow, dataToExport, 8)
                .then((pdfData: any) => {
                    postMessage({ type: "hideLoader" }, "*");

                        const base64Data = pdfData.split(";base64,")[1];
                        console.log(base64Data);
                        const blob = b64toBlob(base64Data, 'application/pdf');
                        const blobUrl = URL.createObjectURL(blob);

                      
                        window.open(blobUrl, '_blank');
                        resolve();
                  
                })
                .catch(error => {
                    console.error('Error exporting PDF:', error);
                    reject(error);
                });
        }, 1000);
    });
};


  

  const onItemClickImprimir = async (event: any) => {
    openPdfNewTab();
  }


  const handleDocumentPreviewClickById = (documentosLocal: Record<string, any>) => async () => {

    try {

      postMessage({ type: "showLoader" }, "*");
      const documentos = await getDocumento(documentosLocal.id);

      setIdDoDocumento(documentosLocal.id);
      setUrlDoDocumento(documentos.url);
      setOpenForm(true);

    } catch (error) {

      handleGenericError(error, "Não foi possivel abrir o documento!")
      setOpenForm(false);

    } finally {

      postMessage({ type: "hideLoader" }, "*");

    }
  }

  const nextPhoto = async (e: any) => {

    try{

      postMessage({ type: "showLoader" }, "*");
      const nextIds = documentosToPreview
        .filter((doc: any) => doc.id < idDoDocumento && doc.url !== '')
        .map((doc: any) => doc.id);
  
      if (nextIds.length > 0) {
        const nextId = Math.max(...nextIds);
        console.log(`O próximo ID é ${nextId}`);
  
        const documento = await getDocumento(nextId);
  
        setIdDoDocumento(nextId.toString());
        setUrlDoDocumento(documento.url);

        const iframe: any = document.getElementById('iframeGeoPortal')
        const id = documento.entidade_id ?? documento.id;
        iframe.contentWindow?.postMessage(
          {
            type: 'SiarlEntidadesCenter',
            value: [id],
          },
          '*'
        )
  
      } else {
        console.log("Não há um próximo ID na lista.");
      }

    }catch(error){
      handleGenericError(error, "Não foi possivel abrir o documento!")

    }finally{

      postMessage({ type: "hideLoader" }, "*");

      e.preventDefault();
    }
   
  }

  const previousPhoto = async (e: any) => {

    try{

      postMessage({ type: "showLoader" }, "*");
      const previousIds = documentosToPreview
        .filter((doc: any) => doc.id > idDoDocumento && doc.url !== '')
        .map((doc: any) => doc.id);
  
      if (previousIds.length > 0) {
        const previousId = Math.min(...previousIds);
        console.log(`O anterior ID é ${previousId}`);
  
        const documento = await getDocumento(previousId);
  
        setIdDoDocumento(previousId.toString());
        setUrlDoDocumento(documento.url);

        const iframe: any = document.getElementById('iframeGeoPortal')
        const id = documento.entidade_id ?? documento.id;
        iframe.contentWindow?.postMessage(
          {
            type: 'SiarlEntidadesCenter',
            value: [id],
          },
          '*'
        )
      } else {
        console.log("Não há um anterior ID na lista.");
      }

    }catch(error){
      handleGenericError(error, "Não foi possivel abrir o documento!");

    }finally{

      postMessage({ type: "hideLoader" }, "*");

      e.preventDefault();
    }
       
  }

  useEffect(() => {

    const elemento: any = document.querySelector(".k-window-content.k-dialog-content");

    if (elemento) {
      elemento.style.padding = "0px";
    }

  }, [openForm]);

  const close = () => {
    setOpenForm(false);
  }

  useEffect(() => {
    SessionStorageService.setItem(keyPainelDocumentos, JSON.stringify(columsDocuments));
  }, [columsDocuments]);
  
  useEffect(() => {
    SessionStorageService.setItem(keyPainelPrincipal, JSON.stringify(colums));
  }, [colums]);
  
  useLayoutEffect(() => {

    const handleResize = () => {

      if (window.innerWidth <= telaMobile) {
        
        setIsMobile(true);
        ocultaFloatingMenu_e_Mapa();

      }else{

        setIsMobile(false);
        mostraFloatingMenu_e_Mapa();

      }

    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []); 


  const onItemClickMais = (event: any) => {

    elementoClickMais(event);

    onItemClickExportar(event);

    onItemClickImprimir(event);

  };

  const optionsMais = [
    {
      key: 'titulo',
      text: 'Imprimir',
    },
    {
      key: 'imprimirFicha',
      text: 'Fichas',
      className: 'icon-siarl icon-imprimir btnIconRegistar'
    },
    {
      key: 'titulo',
      text: 'Exportar',
    },
    ...optionsExportar.map(option => ({
      ...option,
      className: 'icon-siarl icon-exportar btnIconRegistar',
    })),
  ]

  const registarNovoClick = () => {

    sessionStorage.setItem('diGeomEdited', 'false')
    SessionStorageService.setItem('novaEntidade', 'true')
    const loggedUser = JSON.parse(
      localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}'
    )
    selectEntidadeResult({ tecnicoRegisto: loggedUser?.user?.name })
    setEntidadeSelected({ tecnicoRegisto: loggedUser?.user?.name })
    postMessage({ type: 'form', value: 'registarEntidade' }, '*')
  }

  const openAjuda = () => {
    window.open(URL_AJUDA_ENTIDADES, "_blank");
  }
  

  return (
    <div className={clsx(styles.base, isCurrentViewMaximized && commonStyles.maximizedBaseForm)}>
      <div className={styles.header}>
        <div>
          <div className={styles.nomeDoModulo}>Entidades</div>
          <div className={styles.tituloDaPagina}>Resultados de pesquisa</div>
        </div>

        <div>

        {canSeeEntidadesEdit? 
          <Button onClick={registarNovoClick} className={styles.btnNovoRegisto}
          style={{ display: isMobile ? 'none' : '' }}
          id='novoRegisto' title='Nova entidade'>
            <span className="icon-siarl icon-registar btnIconRegistar"></span>{' '}
            &nbsp;{' '}
            Nova entidade
          </Button>
        :  ''}

          <CSVLink
            title="Imprimir resultados"
            id="print-results-entidades"
            separator={';'}
            data={entidades.map((entidade: any) => ({
              ...entidade,
            }))}
            filename={`siarl-entidades-${format(
              new Date(),
              'ddMMyyyHHmm'
            )}`}
            hidden
          ></CSVLink>

          {!isMobile &&
               <VisualizadorColunas 
              columns={selected===0?colums:columsDocuments} 
              setColumns={selected===0?setColums:setColumsDocuments}
              styles={styles.btnEditar2} 
           />
          }

          <button className={styles.btnEditar2} onClick={onItemClickImprimir} style={{ display: isMobile ? 'none' : '' }} id='imprimirFicha' title='Imprimir'>
            <span className="icon-siarl icon-imprimir"></span>
          </button>

          {!isMobile &&
            <DropDownButton
              items={optionsExportar}
              className={styles.btnImprimir}
              style={{marginTop: '-4px'}}
              iconClass="icon-siarl icon-exportar"
              onItemClick={onItemClickExportar}
              fillMode={'flat'}
              title='Exportar'
              itemRender={itemRender}
            />
          }

          {isMobile &&
            <DropDownButton
              items={optionsMais}
              className={styles.btnMais}
              iconClass="icon-siarl icon-mais"
              onItemClick={onItemClickMais}
              fillMode={'flat'}
              itemRender={itemRenderMais}
              title="Mais Opções"
            />
          }
                    
          <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
            <span className="icon-siarl icon-ajuda"></span>
          </button>

          <button className={styles.btnFechar} onClick={onCloseClick} title="Minimizar">
            <span className="icon-siarl icon-minimizar"></span>
          </button>

          {!isMobile &&
          <MaximizeButton className={styles.btnMaximizar} ></MaximizeButton>
          }
        </div>
      </div>

      <TabStrip selected={selected} onSelect={handleSelect} scrollable={isMobile}>
        <TabStripTab title="Entidades">
          <ExcelExport
            fileName='Resultado_Entidades'
            data={entidades}
            ref={(exporter) => {
              _export = exporter
            }}
          >
            <Grid
              className={styles.form}
              sortable={true}
              onDataStateChange={dataStateChange}
              data={dataResult.data.length > 0 ? dataResult : entidadesFinal.map((entidades: any) => ({
                ...entidades,  
        })).slice(paginacao.skip, paginacao.take + paginacao.skip)}
              {...dataState}
              pageable={pageble}
              skip={paginacao.skip}
              take={paginacao.take}
              total={entidades.length}
              //groupable={true}
              cells={{ data: EntidadeCustomCell }}
            >
              <GridColumn cell={CommandCell} width={56} />              
              {colums?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} filter={column.filter} columnMenu={column.columnMenu}   width={column.width} title={column.title} />
              )
            );
          })}
              
            </Grid>
          </ExcelExport>

          <GridPDFExport
            fileName='Resultado_Entidades'
            ref={(element) => {
              _pdfExport = element
            }}
            
            margin="1cm"
           paperSize={['340mm', '297mm']}         

          >
            {
              <Grid
                className={styles.form}
                sortable={true}
                onDataStateChange={dataStateChange}
                data={dataResult.data.length > 0 ? dataResult : entidades.map((entidades: any) => ({
                  ...entidades,  
          })).slice(paginacao.skip, paginacao.take + paginacao.skip)}                {...dataState}
                pageable={{
                  buttonCount: 5,
                  info: true,
                  type: 'numeric',
                  pageSizes: true,
                  previousNext: true,
                }}
                skip={paginacao.skip}
                take={paginacao.take}
                //groupable={true}
              >
                <GridColumn cell={DocumentCommandCell} width={86} />
                {columsDocuments?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} filter={column.filter} width={column.width} title={column.title} columnMenu={column.columnMenu} />
              )
            );
          })}
              </Grid>
            }
          </GridPDFExport>
        </TabStripTab>

        <TabStripTab title="Documentos">
          <ExcelExport
            fileName='Resultado_Documentos_Entidade'
            data={documentos}
            ref={(exporter) => {
              _export = exporter
            }}
          >
            <Grid
              className={styles.form}
              sortable={true}
              onDataStateChange={dataStateChangeDocumentos}
              data={
                dataResultDocumentos.data.length > 0
                  ? dataResultDocumentos
                  : documentos
              }
              {...dataState}
              pageable={{
                buttonCount: 5,
                info: true,
                type: 'numeric',
                pageSizes: true,
                previousNext: true,
              }}
              //groupable={true}
            >
              <GridColumn cell={DocumentCommandCell} width={86}  />
              {columsDocuments?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} filter={column.filter} width={column.width} title={column.title} columnMenu={column.columnMenu} />
              )
            );
          })}
            </Grid>
          </ExcelExport>

          <GridPDFExport
            fileName='Resultado_Documentos_Entidades'
            ref={(element) => {
              _pdfExport = element
            }}
            margin="1cm"
          >
            {
              <Grid
                className={styles.form}
                sortable={true}
                onDataStateChange={dataStateChangeDocumentos}
                data={
                  dataResultDocumentos.data.length > 0
                    ? dataResultDocumentos
                    : documentos
                }
                {...dataState}
                pageable={{
                  buttonCount: 5,
                  info: true,
                  type: 'numeric',
                  pageSizes: true,
                  previousNext: true,
                }}
                //groupable={true}
              >
                <GridColumn cell={DocumentCommandCell} width={86}  />
                <GridColumn
                  field="id"
                  title="Id"
                  filter={'numeric'}
                  columnMenu={ColumnMenu}
                />
                <GridColumn
                  field="data_registo"
                  title="Data"
                  columnMenu={ColumnMenu}
                />
                <GridColumn
                  field="titulo"
                  title="Titulo"
                  columnMenu={ColumnMenu}
                />
                <GridColumn
                  field="entidade"
                  width={'auto'}
                  title="Entidade"
                  columnMenu={ColumnMenu}
                />
              </Grid>
            }
          </GridPDFExport>
        </TabStripTab>
      </TabStrip>

      <div
        className={clsx(styles.footer, styles.justify_one_child, isCurrentViewMaximized && commonStyles.maximizedFooter)}
      >
        <Button className={styles.btnPesquisar} onClick={clearSearchResults} title='Nova Pesquisa'>
          <span className="icon-siarl icon-pesquisar"></span>
          <span className={styles.rotulo}>
            {' '}&nbsp;{' '}
            Nova pesquisa
          </span>
        </Button>
      </div>
      {openForm && (
        <Dialog
        className="ModalDocumentos"
          title={`Documento: ${idDoDocumento}`}
          onClose={close}
          style={{ zIndex: 999 }}
        >

            <ImagePreviewV2
              documentUrl={urlDoDocumento}
              next={(e: any) => nextPhoto(e)}
              previous={(e: any) => previousPhoto(e)}
            />

        </Dialog>
      )}
    </div>
  )
}

