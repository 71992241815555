import { getter } from '@progress/kendo-react-common'
import { isStrongPassword } from '../services/utils'

export const loginFormValidator = (values: Record<string, unknown>) => {
  const userNameGetter = getter('userName')
  const passwordGetter = getter('password')

  const userNameValue = userNameGetter(values)
  const passwordValue = passwordGetter(values)

  const isFormValid = userNameValue && passwordValue

  if (isFormValid) {
    return
  }

  return {
    VALIDATION_SUMMARY: 'Por favor, preencha os campos correntamento.',
    ['userName']: !userNameValue ? 'Campo obrigatório.' : '',
    ['password']: !passwordValue ? 'Campo obrigatório.' : '',
  }
}

export const changPasswordFormValidator = (values: Record<string, unknown>) => {
  const newPassword = getter('newPassword')
  const confirmPassword = getter('confirmPassword')

  const newPasswordValue = newPassword(values)
  const confirmPasswordValue = confirmPassword(values)

  const isPasswordStrong = isStrongPassword(newPasswordValue);
  const isFormValid = newPasswordValue && confirmPasswordValue && (newPasswordValue == confirmPasswordValue) && isPasswordStrong

  if (isFormValid) {
    return
  }

  return {
    VALIDATION_SUMMARY: 'Por favor, preencha os campos correntamento.',
    ['newPassword']: !newPasswordValue ? 'Campo obrigatório.' : (!isPasswordStrong ? "A palavra-passe deve conter 8 caracteres entre, letras maiúsculas e minúsculas, números e caractéres especiais." : ''),
    ['confirmPassword']: !isFormValid ? 'As palavras-passe não conferem.' : '',
  }
}

export const forgotPasswordFormValidator = (values: Record<string, unknown>) => {
  const email = getter('email')

  const emailValue = email(values)

  const isFormValid = emailValue

  if (isFormValid) {
    return
  }

  return {
    VALIDATION_SUMMARY: 'Por favor, preencha os campos correntamento.',
    ['email']: !emailValue ? 'Campo obrigatório.' : ''
  }
}

export const registerFormValidator = (values: Record<string, unknown>) => {
  const userNameGetter = getter('username')
  const emailGetter = getter('email')
  const passwordGetter = getter('password')

  const email = emailGetter(values)
  const usernameValue = userNameGetter(values)
  const passwordValue = passwordGetter(values)

  const isFormValid = email && usernameValue && passwordValue

  if (isFormValid) {
    return
  }

  return {
    VALIDATION_SUMMARY: 'Por favor, preencha os campos correntamento.',
    ['email']: !email ? 'Campo obrigatório.' : '',
    ['username']: !usernameValue ? 'Campo obrigatório.' : '',
    ['password']: !passwordValue ? 'Campo obrigatório.' : '',
  }
}
