export const requiredFieldValidator = (value: string) =>
  !value ? 'campo obrigatorio' : ( typeof value == 'string' && !value.trim() ? 'Campo obrigatório.' : '');

export let isDocumentoIdentificacaoValid: boolean

export const setIsDocumentoIdentificacaoValid = (value: boolean) => {
  isDocumentoIdentificacaoValid = value
}

export let isDocumentoDetalhesValid: boolean

export const setIsDocumentoDetalhesValid = (value: boolean) => {
  isDocumentoDetalhesValid = value
}

export let isDocumentoAssociaoValid: boolean

export const setIsDocumentoAssociaoValid = (value: boolean) => {
  isDocumentoAssociaoValid = value
}

export const validateUrl = (value: string): string | undefined => {
  if (!value) return 'Campo obrigatório';
  if (typeof value === 'string' && !value.trim()) return 'Campo obrigatório.';

  // Verifica se o valor é um URL válido
  const urlPattern = new RegExp(
    '^(https?://)?' + // protocolo
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // nome de domínio
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OU endereço IP (v4)
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // porta e caminho
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // string de consulta
    '(\\#[-a-z\\d_]*)?$',
    'i'
  );

  return urlPattern.test(value) ? undefined : 'URL inválido';
};
