import { create } from 'zustand'

type DhProcessoState = {
    selectedDhProcessoResult?: unknown
    selectDhProcessoResult: (payload: unknown) => void
    selectedDhProcessoResultId?: number
    selectDhProcessoResultId: (payload?: number) => void
}

export const useDhProcessoStore = create<DhProcessoState>((set, get) => ({
    selectedDhProcessoResult: null,
    selectDhProcessoResult: (payload: unknown) => set({ selectedDhProcessoResult: payload }),
    selectDhProcessoResultId: (payload?: number) => set({ selectedDhProcessoResultId: payload }),
}))
