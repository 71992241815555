import { Button, DropDownButton } from '@progress/kendo-react-buttons'
import { Form, FormElement } from '@progress/kendo-react-form'
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from '@progress/kendo-react-layout'
import { useEffect, useLayoutEffect, useState } from 'react'

import { AssociacaoListagem } from '../../components/associacao/Associacao'
import { DocumentoListagem } from '../../components/documentos/registar/documento/documento'
import { ModeloDetalhe } from '../../components/modelos/registar/Detalhes'
import { ModeloIdentificacao } from '../../components/modelos/registar/Identificacao'
import {
  GetUserNameTecnico,
  b64toBlob,
  blobToBase64,
  coordenadasPoligono,
  distritos,
  entidadeUser,
  fillCombosAuxiliares,
  freguesias,
  get,
  getModeloSelected,
  handleGenericError,
  hasPermission,
  modelosTipos,
  nuts1,
  post,
  put,
  refreshPesquisaSearch,
  setCoordenadasPoligono,
  setCoordenadasSelected,
  setIntervencaoSelected,
  setModeloSelected,
  tecnico,
  useCombos,
  userLogged,
} from '../../services/utils'
import { useModeloStore } from '../../stores/modelo'
import styles from '../../styles/modelos/RegistarModeloForm.module.scss'
import ReactGA from 'react-ga4';
import {
  AUTH_LOCALSTORAGE,
  GET_ALL_MODELOS,
  GET_MODELO,
  GET_MODELO_BY_ID,
  IMPORT_GEOM_MODELO,
  MODELO_POINT_NAME,
  PERMISSION_ATUALIZAR_MODELO,
  PERMISSION_INSERIR_MODELO,
  RELATORIO_MODELO,
  RELATORIO_MODELO_RDLC,
  REMOVER_AREA_MODELO,
  SAVE_NEW_MODELO,
  SOFT_DELETE_MODELO,
  UPDATE_MODELO,
  URL_AJUDA_MODELOS,
  clearLayers,
} from '../../utils/constants'
import {
  isDocumentoDetalhesValid,
  isDocumentoIdentificacaoValid,
} from '../../validations/documento'
import ImportarGeometriaComponent from '../../components/apoiogestao/registar/ImportarGeometriaForm'
import { Dialog } from '@progress/kendo-react-dialogs'
import SessionStorageService from '../../services/SessionStorageService'
import useClearMap from '../../hooks/useClearMap'
import html2canvas from 'html2canvas'
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../../components/common/ItemRenderMais'

type ModeloType = {
  id?: number
  designacao?: string
  descricao?: string
  estado?: string
  entidade_id: number | string
  entidade?: Entidade
  utilizador_id: number
  data_registo: string
  tipo_acesso_id: number
  tipoAcesso?: TipoAcesso
  fonte?: string
  url?: string
  observacao?: string
  foto?: unknown
  freguesia_cod?: string
  autores?: string
  nuts1_ids?: any[]
  nuts2_ids?: any[]
  nuts3_ids?: any[]
  arch_ids?: any[]
  capitania_ids?: any[]
  massaAgua_ids?: any[]
  freguesia_ids?: any[]
  concelho_ids?: any[]
  distrito_ids?: any[]
  tecnicoRegisto?: string
}

type Entidade = {
  id: number
  nome: string
  codigo: string
  morada: string
  codigo_postal: string
  localidade: string
  tipo_entidade_id: number
  tipoEntidade: TipoEntidade
  oc_focal_point_email: string
  oc_notify_email_new_issue: boolean
  estado_id: number
  url: string
  telefone: string
  utilizador_id: number
  data_registo: string
  tipo_acesso_id: number
  tipoAcesso: TipoAcesso
  flatCoordinates: string
  flatCoordinatesJurisdicao: string
  nuts1_id: number
  nuts2_id: number
  nuts3_id: number
  distrito_id: number
  concelho_id: number
  freguesia_id: number
  freguesia_cod: string
}

type TipoAcesso = {
  id: number
  designacao: string
  descricao: string
}

type TipoEntidade = {
  id: number
  designacao: string
}

let _pdfExport: any
const exportExcel = () => {
  _export.save()
}
let _export: any
const exportPDF = () => {
  _pdfExport.save()
}

const TOTAL_TABS = 3

export default function RegistarModelosTabs() {
  const [isMobile, setIsMobile] = useState(false);
  const [selected, setSelected] = useState<number>(0)
  const [formState, setFormState] = useState({})
  const [canUpdate, setCanUpdate] = useState(false);
  const [canInsert, setCanInsert] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [deleteClicked, setDeleteClicked] = useState(false);
  const getModelo = async (data: any) =>
    await get<any, any>(`${GET_MODELO}/` + data)

  const softDeleteModelo = async (data: any) =>
    await post<any, any>(`${SOFT_DELETE_MODELO}`, data)
useEffect(() => {

          if(!nuts1 || !distritos || !modelosTipos)
        {
          fillCombosAuxiliares();

        }
    
  }, []);

  const [percentLeftSucess, setPercentLeftSucess] = useState("22%");


  const saveModelo = async (data: any) =>
    await post<any, any>(`${SAVE_NEW_MODELO}`, data)

   const [openImportModal, setOpenImportModal] = useState<any>()


  const updateModelo = async (data: any) =>
    await put<any, any>(`${UPDATE_MODELO}`, data)

  const selectedModeloResult: any = useModeloStore(
    (state) => state.selectedModeloResult
  )
  
  const identificacaoTabIsValid: any = useModeloStore(
    (state) => state.identificacaoTabIsValid
  )

  const detalheTabIsValid: any = useModeloStore(
    (state) => state.detalheTabIsValid
  )
  
  const selectedModeloResultId = useModeloStore(
    (state) => state.selectedModeloResultId
  )

   const getModeloById = async (id: any) =>
    await get<any, any>(`${GET_MODELO_BY_ID}/` + id)

  const selectModeloResultId = useModeloStore(
    (state) => state.selectModeloResultId
  )

   const importGeomModelo = async (data: any) =>
    await post<any, any>(`${IMPORT_GEOM_MODELO}`, data)

  const relatorioModelo = async (data: any) =>
    await post<any, any>(`${RELATORIO_MODELO_RDLC}`, data)
  
  const removerAreaModelo = async (data: any) =>
    await post<any, any>(`${REMOVER_AREA_MODELO}`, data)
  

  const selectModeloResult = useModeloStore((state) => state.selectModeloResult)

  useEffect(() => {
    addEventListener('message', (event: MessageEvent) => {
      if (event.data.type == 'CoordenadasSelected') {
        console.log(event.data.value)
        if (event.data.value) {
          const flatPointCoordinates =
            event.data.value.x + ', ' + event.data.value.y
          setCoordenadasSelected(flatPointCoordinates)
        }
      } else if (event.data.type == 'CoordenadasFlatSelected') {
        let polygonString = ''
        const values: any[] = event.data.value
        values?.forEach((value, index) => {
          if (index % 2 == 0) {
            polygonString += value + ' '
          } else {
            polygonString += value + ','
          }
        })
        polygonString = polygonString.substring(0, polygonString.length - 1)
        setCoordenadasPoligono(polygonString)
        console.log(polygonString)
      }
    })
  }, [])

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/RegistarModelo" });
  }, []);

  useEffect(() => {
    setCanInsert(hasPermission(PERMISSION_INSERIR_MODELO));
    setCanUpdate(hasPermission(PERMISSION_ATUALIZAR_MODELO));
  }, [])

  useEffect(() => {
    if(viewMode) {
      SessionStorageService.removeItem("novoModelo");
    }
  }, [viewMode])

  useEffect(() => {
    setViewMode(!editMode && SessionStorageService.getItem("novoModelo") != 'true');
  }, [])

  const [openModalDescartar, setOpenModalDescartar] = useState<any>()

  const cancelModalDescartar = () => {
    setOpenModalDescartar(false)
  }

  const handleOpenModalDescartar = () => {
    if (
      SessionStorageService.getItem('novoModelo') &&
      SessionStorageService.getItem('novoModelo') == 'true'
    ) {
      setOpenModalDescartar(true);
    } else {
      onEditItemClick("SiarlVisualizar");
      handleCancel();   
   }
  }

  const [estadoFalse, setEstado] = useState(false)
    const [estadoTrue, setEstadoTrue] = useState(true)


  const handleDesativar = async (e: any) => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
       {
         type: 'SiarlCoordenadaBoxClose',
         value: 'true',
       },
       '*'
     )
     iframe.contentWindow?.postMessage(
      {
        type: 'SiarlRemoveSelection',
        value: 'true',
      },
      '*'
    )
  setOpenModalDescartar(false)
   const modeloSelected = getModeloSelected()

   await softDeleteModelo(modeloSelected?.id);


    SessionStorageService.removeItem("firstCallModelos");
    postMessage({ type: 'form', value: 'pesquisarModelos' }, '*')

}



const onEditItemClick= (event:any) => {
  if (event == "SiarlEditar" || event?.item?.key == "SiarlEditar") {
    setEditMode(true);
    setViewMode(false);
    handleAlteraTitulo();
    return;
  }
  if (event == "SiarlVisualizar") {
    setEditMode(false);
    setViewMode(true);
  }
  if (event?.item?.key == "SiarlApagar") {
    setDeleteClicked(true);
    setOpenModalDescartar(true);
  }
}

const refreshForm = async(id: any = undefined) => {
  let idModelo = id;
  if (!idModelo) {
    if (selectedModeloResult?.id) {
      idModelo = selectedModeloResult?.id;
    } else {
      return;
    }
  }

  refreshPesquisaSearch("resultadoModelosPesquisa", "SiarlModelosSearch")

  const modelo = await getModelo(idModelo);
  const tecnico = await GetUserNameTecnico(modelo.utilizador_id);

  if (!modelo) {
    postMessage({type: "infoMsg", value: { message: "Registo desativado" }}, "*");
    return;
  }
  
  const dataToSend : any = {
    ...modelo,
    tecnicoRegisto: tecnico
  }

  const iframe: any = document.getElementById('iframeGeoPortal')

  selectModeloResult(dataToSend)
  selectModeloResultId(modelo?.id)
  setModeloSelected(dataToSend);
}

const handleCancel = ()  => {
  refreshForm();
  postMessage({ type: "form", value: "" }, "*");
  setTimeout(() => {
    postMessage({ type: "form", value: "registarModelos" }, "*");
  }, 10);
}

  const handleSelect = (e: TabStripSelectEventArguments) => {
    if (e.selected > 1 && !selectedModeloResult) {
      postMessage({type:"infoMsg", value: {message: "É necessário gravar primeiro."}})
      return;
    }
    setSelected(e.selected)
  }

  const selectMapAction = async (item: any) => {
    const iframe: any = document.getElementById('iframeGeoPortal')

    if (item.key == "removerArea") {
      await removerAreaModelo(selectedModeloResult?.id);
      iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: 'true' }, '*')
      iframe.contentWindow?.postMessage(
        { type: 'SiarlModelosSearch', value: [selectedModeloResult.id] },
        '*'
      )
    } else if (item.key == "SiarlEditarGeometria") {
      if (selectedModeloResult.geom_imported) {
        postMessage({type: "infoMsg", value :{message: "Não é possivel editar geometrias importadas"}});
        return;
      }
      iframe.contentWindow?.postMessage({ type: item.key, value: getModeloSelected()?.id, editGpLayer: MODELO_POINT_NAME }, '*');
    }
    else {
      iframe.contentWindow?.postMessage({ type: item.key, value: getModeloSelected()?.id, editGpLayer: MODELO_POINT_NAME }, '*')
    }
  }

  const voltar = () => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
       {
         type: 'SiarlCoordenadaBoxClose',
         value: 'true',
       },
       '*'
     )
    clearModelosMap()
    postMessage({ type: 'form', value: 'pesquisarModelos' }, '*')
  }

  const [clearMap] = useClearMap();
  const clearModelosMap = () => {
    clearMap(clearLayers["CLEAR_LAYERS_MODELOS"]);
    setModeloSelected(undefined)
    selectModeloResult(undefined)
  }
  const handleSubmit = async (e: any) => {

    if (!e.values.titulo || !e.values.titulo.trim()) {
      postMessage({ type: "errorMsg", value: { message: "Preencha os campos obrigatórios" } }, "*");
      return; 
    }

    if (selected == 0 && !identificacaoTabIsValid) {
      return false
    }
    if (selected == 1 && !detalheTabIsValid) {
      return false
    }
    
    const userId = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '')
      ?.user?.id
    const dataToSend: any = {
      //Identificaco registo
      id: selectedModeloResultId ?? undefined,
      data_registo: e?.values?.dataHoraRegisto,
      mo_estado_id: e?.values?.estadoDoRegisto?.id ?? undefined,
      utilizador_id: userId,
      entidade_registo_id: e?.values?.entidadeDoRegisto?.id,
      //Identificaco identificacao
      designacao: e?.values?.titulo ?? '',
      data_publicacao: e?.values?.dataHoraPublicacao,
      autores: e?.values?.autores,
      entidade_id: e?.values?.entidade?.id,
      //Identificaco localizacao
      flatCoordinates: coordenadasPoligono ?? undefined,
      nuts_i_cod: e?.values?.nuts1?.codigo,
      nuts_2_cod: e.values?.nuts2?.codigo,
      nuts_3_cod: e?.values?.nuts3?.codigo,
      distrito_cod: e?.values?.distrito?.codigo,
      concelho_cod: e?.values?.concelho?.codigo,
      freguesia_cod: e?.values?.freguesia ? e?.values?.freguesia?.codigo : '',
      arh_cod: e?.values?.arh?.codigo,
      capitania_cod: e?.values?.capitania?.codigo,
      massaAgua_cod: e?.values?.massaAgua?.codigo,
      // Identificaco Tipologia
      mo_modelo_subtipo_id: e?.values?.subtipoModelo?.id ?? undefined,
      mo_modelo_tipo_id: e?.values?.tipoModelo?.id ?? undefined,
      // detalhes
      descricao: e?.values?.descricao ?? '',
      tipo_acesso_id: e?.values?.tipoAcesso?.id,
      url: e?.values?.url ?? '',
      fonte: e?.values?.fonte ?? '',
      observacoes: e?.values?.obs ?? '',
      local: e.values?.local ?? undefined
    }
    if (e?.values?.arquivo || e?.values?.arquivo?.length > 0) {
      const arquivo = e?.values?.arquivo?.[0]?.getRawFile()
      try {
        const base64File = await blobToBase64(arquivo)
        dataToSend['foto'] = base64File
      } catch (error) {
        console.log({ error })
      }
    }
    try {
      postMessage({ type: 'showLoader' }, '*')
      let modeloSaved: any
      if (selectedModeloResultId) {
        modeloSaved = await updateModelo(dataToSend)
      } else {
        modeloSaved = await saveModelo(dataToSend)
      }
      refreshForm(modeloSaved.id);
      const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.contentWindow?.postMessage(
        { type: 'SiarlModelosSearch', value: [modeloSaved.id] },
        '*'
      )
      if (modeloSaved) {
        setTimeout(() => {
           postMessage({ type: 'sucessMsg' , value :{left : (isMobile? (Number(percentLeftSucess.split('%')[0])-40)+'%' : percentLeftSucess)
        } },'*')
      }, 500)
      }
    } catch(error) {
      handleGenericError(error)
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  const mapActionsSelectItemChange = (item: any) => {
    selectMapAction(item.value)
  }

  const isSubmit = () => {
    if (selected === 0 && !isDocumentoIdentificacaoValid) {
      return false
    }
    if (selected === 1 && !isDocumentoDetalhesValid) {
      return false
    }
    if (selected === TOTAL_TABS) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    if (!tecnico) {
      userLogged()
    }
  }, [tecnico])

  const innerStyleTabContent = {
    scrollBehavior: 'smooth',
    top: 55,
    height: '80vh',
  }
  console.log({ selectedModeloResult })

  const itemRender = (props: { item: any; itemIndex: number }) => {
    const isTitleItem = props.item.key === 'titulo'
    if (isTitleItem) {
      // Item de título personalizado
      return (
        <div className={styles.DropDownButtonTitulo}>
          <span>{props.item.text}</span>
        </div>
      )
    } else {
      // Demais itens
      return (
        <div className={styles.DropDownButtonItem}>
          <span>{props.item.text}</span>
        </div>
      )
    }
  }

   const onItemClick = (event: any) => {
    if(event?.item?.key == "ImportGeom"){
      ImportGeom(event.item)
     } else {
      selectMapAction(event.item)
     }
    
  }

  const optionsEditar = [
    {
      key: 'SiarlEditar',
      text: 'Editar',
    },
    {
      key: 'SiarlApagar',
      text: 'Apagar',
    },
    
  ]

  const optionsArea = [
    {
      key: 'SiarlGeoPortalAdicionarArea',
      text: 'Desenhar Área',
    },
    {
      key: 'SiarlEditarGeometria',
      text: 'Editar Área',
    },
    {
      key: 'removerArea',
      text: 'Remover Área',
    },
    {
      key: 'ImportGeom',
      text: 'Importar Geom',
    },
  ]

  const onItemClickImprimir = async (event: any) => {
    
    let mapImage = ''

    if (!window.location.href.includes('localhost')) {
      const mapIframe = document.querySelector(
        '#iframeGeoPortal'
      ) as HTMLIFrameElement

      const mapIframeInnerContent =
        mapIframe.contentWindow?.document.querySelector(
          'div.ol-viewport'
        ) as HTMLElement;

      const mapBussula =  mapIframe.contentWindow?.document.querySelector(
        'button.fixedNorthArrowButton'
      ) as HTMLElement;

      mapIframeInnerContent.appendChild(mapBussula);
      
      const generatedImage = await html2canvas(mapIframeInnerContent);
      mapImage = generatedImage.toDataURL()
    }
    console.log(selectedModeloResult);

    try {
      postMessage({ type: 'showLoader' }, '*')

      const documentoBase64 = await relatorioModelo({
        id: selectedModeloResult?.id,
        dados: {

          //Registo
          'REGISTO': '',
          'Data do registo:': selectedModeloResult?.data_registo ? new Date(selectedModeloResult?.data_registo).toLocaleDateString() : '',
          'Estado do registo:': selectedModeloResult?.moEstado?.designacao ?? '',
          'Técnico:': selectedModeloResult?.utilizador?.name ?? '',
          'Entidade do registo:': entidadeUser ?? '',

          //Identificacao
          'IDENTIFICAÇÃO': '',
          'Titulo do modelo:' : selectedModeloResult?.designacao ?? '',
          'Data da publicação:': selectedModeloResult?.data_publicacao ? new Date(selectedModeloResult?.data_publicacao).toLocaleDateString() : '',
          'Autores:' : selectedModeloResult?.autores ?? '',
          'Entidade:': selectedModeloResult?.entidade?.nome ?? '',

          //Localizacao
          'LOCALIZAÇÃO' : '',
          'Distrito:': selectedModeloResult?.distritos[0]?.nome ?? '',
          'Concelho:': selectedModeloResult?.concelhos[0]?.nome ?? '',
          'Freguesia:': selectedModeloResult?.lstFreguesias[0]?.nome ?? '',
          'Nuts1:': selectedModeloResult?.nuts1[0]?.nome ?? '',
          'Nuts2:': selectedModeloResult?.nuts2[0]?.nome ?? '',
          'Nuts3:': selectedModeloResult?.nuts3[0]?.nome ?? '',

          //Tipologia
          'TIPOLOGIA' : '',
          'Tipo de modelo:': selectedModeloResult?.modeloTipo?.designacao ?? '',
          'Subtipo de modelo:': selectedModeloResult?.modeloSubtipo?.designacao ?? '',
          
          //Detalhes
          'DETALHES' : '',
          'Descrição:' : selectedModeloResult?.descricao ?? '',
          'Tipo de acesso:' : selectedModeloResult?.tipoAcesso?.designacao ?? '',
          'URL:' : selectedModeloResult?.url ?? '',
          'Fonte:' : selectedModeloResult?.fonte ?? '',
          'Observações:' : selectedModeloResult?.observacoes ?? ''


        },
        img_mapa: mapImage.replace("data:image/png;base64,", ""),
      });

      if (documentoBase64) {
        const blob = b64toBlob(documentoBase64, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
        postMessage({ type: 'hideLoader' }, '*')
      }
    } catch (error) {
      postMessage({ type: 'errorMsg', value: { message: "Não foi possivel efetuar esta operação" } }, '*')
      console.log("error")
      console.log(error)
      
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }

  }

  const optionsImprimir = [
    {
      key: 'imprimirFichas',
      text: 'Fichas',
    },
    {
      key: 'imprimirResultados',
      text: 'Resultados',
    },
  ]

  const onItemClickExportar = async (event: any) => {
    let mapImage = ''

    if (!window.location.href.includes('localhost')) {
      const mapIframe = document.querySelector(
        '#iframeGeoPortal'
      ) as HTMLIFrameElement

      const mapIframeInnerContent =
        mapIframe.contentWindow?.document.querySelector(
          'div.ol-viewport'
        ) as HTMLElement;

      const mapBussula =  mapIframe.contentWindow?.document.querySelector(
        'button.fixedNorthArrowButton'
      ) as HTMLElement;

      mapIframeInnerContent.appendChild(mapBussula);
      
      const generatedImage = await html2canvas(mapIframeInnerContent);
      mapImage = generatedImage.toDataURL()
    }
    console.log(selectedModeloResult);

    try {
      postMessage({ type: 'showLoader' }, '*')

      const documentoBase64 = await relatorioModelo({
        id: selectedModeloResult?.id,
        dados: {

          //Registo
          'REGISTO': '',
          'Data do registo:': selectedModeloResult?.data_registo ? new Date(selectedModeloResult?.data_registo).toLocaleDateString() : '',
          'Estado do registo:': selectedModeloResult?.moEstado?.designacao ?? '',
          'Técnico:': selectedModeloResult?.utilizador?.name ?? '',
          'Entidade do registo:': entidadeUser ?? '',

          //Identificacao
          'IDENTIFICAÇÃO': '',
          'Titulo do modelo:' : selectedModeloResult?.designacao,
          'Data da publicação:': selectedModeloResult?.data_publicacao ? new Date(selectedModeloResult?.data_publicacao).toLocaleDateString() : '',
          'Autores:' : selectedModeloResult?.autores,
          'Entidade:': selectedModeloResult?.entidade?.nome ?? '',

          //Localizacao
          'LOCALIZAÇÃO' : '',
          'Distrito:': selectedModeloResult?.distritos[0]?.nome ?? '',
          'Concelho:': selectedModeloResult?.concelhos[0]?.nome ?? '',
          'Freguesia:': selectedModeloResult?.lstFreguesias[0]?.nome ?? '',
          'Nuts1:': selectedModeloResult?.nuts1[0]?.nome ?? '',
          'Nuts2:': selectedModeloResult?.nuts2[0]?.nome ?? '',
          'Nuts3:': selectedModeloResult?.nuts3[0]?.nome ?? '',

          //Tipologia
          'TIPOLOGIA' : '',
          'Tipo de modelo:': selectedModeloResult?.modeloTipo?.designacao ?? '',
          'Subtipo de modelo:': selectedModeloResult?.modeloSubtipo?.designacao ?? '',
          
          //Detalhes
          'DETALHES' : '',
          'Descrição:' : selectedModeloResult?.descricao ?? '',
          'Tipo de acesso:' : selectedModeloResult?.tipoAcesso?.designacao ?? '',
          'URL:' : selectedModeloResult?.url ?? '',
          'Fonte:' : selectedModeloResult?.fonte ?? '',
          'Observações:' : selectedModeloResult?.observacoes ?? ''


        },
        img_mapa: mapImage.replace("data:image/png;base64,", ""),
      });

      if (documentoBase64) {
        if (documentoBase64) {
          const link = document.createElement('a')
          link.href = 'data:application/octet-stream;base64,' + documentoBase64
          link.download = 'reporte_modelo.pdf'
          link.style.display = 'none'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          postMessage({ type: 'hideLoader' }, '*')
        }
      }
    } catch (error) {
      postMessage({ type: 'errorMsg', value: { message: "Não foi possivel efetuar esta operação" } }, '*')
      console.log("error")
      console.log(error)
      
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  const optionsExportar = [
    {
      key: 'exportarFichas',
      text: 'Fichas',
    },
    {
      key: 'exportarResultados',
      text: 'Resultados',
    },
  ]

  const importarGeometria = [
    {
      key: 'ImportGeom',
      text: 'Importar Geom',
    },
  ]

const importGeo = async (objToSend: any) => {
    postMessage({ type: "showLoader" }, "*");
    if (!objToSend) {
      postMessage({ type: "errorMsg", value: "Não foi possivel salvar" }, "*");
      postMessage({ type: "hideLoader" }, "*");
      return;

    }
    let modelo;
    let modelo_id;
    const modeloSelected = selectedModeloResult ?? getModeloSelected();
    if (modeloSelected && modeloSelected?.id) {
      modelo = modeloSelected;
      modelo_id = modeloSelected?.id;
    } else {
      modelo = await saveModelo({});
      modelo_id = modelo?.id;
      selectModeloResult(modelo);
      selectModeloResultId(modelo_id);

    }
    try {
      objToSend = { ...objToSend, modelo_id }
      const result = await importGeomModelo(objToSend)
      if (!result) {
        postMessage({ type: "errorMsg", value: "Não foi possivel salvar" }, "*");
        return;
      }

      try {
        modelo = await getModeloById(modelo_id);
      } catch (error) {
        postMessage({ type: "errorMsg", value: "Registo salvo mas, Não é possivel mostrar os dados neste momento" }, "*");

      } finally {
        modelo = { ...modelo, ...objToSend };
        selectModeloResultId(modelo_id);
        selectModeloResult(modelo);
        setModeloSelected(modelo)

        refreshForm(modelo_id);
      }
      try {
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage(
          { type: 'SiarlModelosSearch', value: [modelo_id] },
          '*'
        )
      } catch (error) {
        postMessage({ type: "errorMsg", value: "Registo salvo mas, Não é possivel mostrar os dados neste momento" }, "*");
      }

      setOpenImportModal(false)
    } catch (err) {
      handleGenericError(err);

    } finally {
      postMessage({ type: "hideLoader" }, "*");
    }

  }


const cancelImport = () => {
    setOpenImportModal(false)
  }
  
  const ImportGeom = async (item: any) => {
    if (item.key == 'ImportGeom') {
      setOpenImportModal(true)
    }
  }

  const onImportGeomClick = (event: any) => {
    ImportGeom(event.item)
  }

  useLayoutEffect(() => {

    const handleResize = () => {

      if (window.innerWidth <= telaMobile) {
        
        setIsMobile(true);
        ocultaFloatingMenu_e_Mapa();

      }else{

        setIsMobile(false);
        mostraFloatingMenu_e_Mapa();

      }

    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []); 

  const onItemClickMais = (event: any) => {

    elementoClickMais(event);

    onEditItemClick(event);
    onImportGeomClick(event);
    onItemClick(event);

  };

  const optionsMais = [
    {
      key: 'titulo',
      text: 'Registo',
    },
    ...optionsEditar.map(option => ({
      ...option,
      className: 'icon-siarl icon-editar btnIconRegistar',
    })),
    {
      key: 'titulo',
      text: 'Geometria',
    },
    ...importarGeometria.map(option => ({
      ...option,
      className: 'icon-siarl icon-registar btnIconRegistar',
    })),   
    {
      key: 'titulo',
      text: 'Área',
    },
    ...optionsArea.map(option => ({
      ...option,
      className: 'icon-siarl icon-area btnIconRegistar',
    })),
    {
      key: 'titulo',
      text: 'Imprimir',
    },
    {
      key: 'imprimirFicha',
      text: 'Fichas',
      className: 'icon-siarl icon-imprimir btnIconRegistar'
    },
    {
      key: 'titulo',
      text: 'Exportar',
    },
    ...optionsExportar.map(option => ({
      ...option,
      className: 'icon-siarl icon-exportar btnIconRegistar',
    })),
  ]

  const [tituloModelo, setTituloModelo] = useState(false);

  const handleAlteraTitulo = () => {
    setTituloModelo(true);
  };

  const openAjuda = () => {
    window.open(URL_AJUDA_MODELOS, "_blank");
  }

  return (
    
    <div className={styles.base}>
      <div className={styles.header}>
        <div>
          <div className={styles.nomeDoModulo}>Modelos</div>
          <div className={styles.tituloDaPagina}>
            {!selectedModeloResult || !selectedModeloResult?.id ? 'Novo Modelo' : (tituloModelo ? 'Editar Modelo' : 'Modelo')}
          </div>
        </div>

        <div>
        {!isMobile && canUpdate && (viewMode || editMode) && (SessionStorageService.getItem("novoModelo") != 'true') ? 
          <DropDownButton
            items={optionsEditar}
            className={styles.btnEditar2}
            iconClass="icon-siarl icon-editar"
            onItemClick={onEditItemClick}
            fillMode={'flat'}
            itemRender={itemRender}
            title="Editar"
          ></DropDownButton>
          : ''}

        {!isMobile &&
          <DropDownButton
            items={optionsArea}
            className={styles.btnEditar2}
            iconClass="icon-siarl icon-area"
            onItemClick={onItemClick}
            fillMode={'flat'}
            title='Geometria - Área'
            disabled={viewMode}
            itemRender={itemRender}
          ></DropDownButton>
        }
          <button className={styles.btnEditar2} onClick={onItemClickImprimir}
          style={{ display: isMobile ? 'none' : '' }} id='imprimirFicha' title='Imprimir'>
            <span className="icon-siarl icon-imprimir"></span>
          </button>

          <button className={styles.btnImprimir} title='Exportar' onClick={onItemClickExportar}
           style={{ display: isMobile ? 'none' : '' }} id='exportarFicha'>
            <span className="icon-siarl icon-exportar"></span>
          </button>

        {isMobile &&
            <DropDownButton
              items={optionsMais}
              className={styles.btnMais}
              iconClass="icon-siarl icon-mais"
              onItemClick={onItemClickMais}
              fillMode={'flat'}
              itemRender={itemRenderMais}
              title="Mais Opções"
            />
          }

          <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
            <span className="icon-siarl icon-ajuda"></span>
          </button>

          <button className={styles.btnFechar} onClick={voltar} title="Fechar">
            <span className="icon-siarl icon-fechar"></span>
          </button>
        </div>
      </div>

        {openImportModal ?
        <ImportarGeometriaComponent onSubmit={importGeo} cancelImport={cancelImport} /> : <></>
      }

      {openModalDescartar ? (
          <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={cancelModalDescartar}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                  <fieldset>
                    <span className="textDescartar">{deleteClicked ? 'Apagar' : 'Cancelar'} registo  </span>
                    <br></br>
                    <span>{deleteClicked ? 'Ao apagar este registo deixará de ter acesso, sendo necessária uma intervenção rigorosa para realizar a sua recuperação.' : 'Ao cancelar este registo irá perder os dados inseridos até ao momento.'}</span>
                      <br></br>
                    <span>{deleteClicked ? 'Tem a certeza que pretende apagar?' : 'Tem a certeza que pretende cancelar?'}</span>
                  </fieldset>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={cancelModalDescartar}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={handleDesativar}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}


      <Form
        onSubmitClick={handleSubmit}
        initialValues={
          {
            ...selectedModeloResult,
            tecnicoRegisto: tecnico,
            entidade_registo: entidadeUser,
            Iddoc: selectedModeloResult?.id,
            obs: selectedModeloResult?.observacoes,
            tipoAcesso: selectedModeloResult?.tipoAcesso
              ? selectedModeloResult?.tipoAcesso
              : null,
              dataHoraRegisto: selectedModeloResult?.dataHoraRegisto
              ? new Date(selectedModeloResult?.dataHoraRegisto)
              : new Date(),
            titulo: selectedModeloResult?.designacao,
            status: status[0],
            dataHoraPublicacao: selectedModeloResult?.data_publicacao
              ? new Date(selectedModeloResult?.data_publicacao)
              : undefined,
            tipoModelo: selectedModeloResult?.modeloTipo,
            subtipoModelo: selectedModeloResult?.modeloSubtipo,
            estadoDoRegisto: selectedModeloResult?.moEstado
          } ?? undefined
        }
        render={(formRenderProps) => (
          <FormElement>
            <TabStrip
              selected={selected}
              onSelect={handleSelect}
              className={`${styles.innerStyleTabContent} ${styles.backgroundWhite} ${viewMode? 'tabsVisualizar' : ''}`}
              scrollable={isMobile}
            >
              <TabStripTab title="Identificação">
                <ModeloIdentificacao viewMode={viewMode} formRenderProps={formRenderProps} />
              </TabStripTab>
              <TabStripTab title="Detalhes">
                <ModeloDetalhe viewMode={viewMode} formRenderProps={formRenderProps} />
              </TabStripTab>
              <TabStripTab title="Documentos">
                <DocumentoListagem viewMode={viewMode} pageName="modelos" />
              </TabStripTab>
            </TabStrip>
            {(editMode || SessionStorageService.getItem("novoModelo") == 'true')
                  && !viewMode ?
            <div
              className={`${styles.footer} ${styles.justify_multiple_children}`}
            >

                <Button type="button" className={styles.btnSeguinte} onClick={handleOpenModalDescartar} title='Cancelar'>
                  <span className="icon-siarl icon-fechar"></span>
                  <span className={styles.rotulo}>
                    {' '}&nbsp;{' '}
                    Cancelar
                  </span>
                 </Button>
        
              <Button
                className={styles.btnSubmeter}
                onClick={formRenderProps.onSubmit}
                title='Gravar'
              >
                <span className="icon-siarl icon-submeter btnIconRegistar"></span>
                <span className={styles.rotulo}>
                  {' '}&nbsp;{' '}
                  Gravar
                </span>
              </Button>
            </div> : ''}
          </FormElement>
        )}
      />
    </div>
  )
}
