import * as React from 'react'

import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form'
import { Label, Error, Hint } from '@progress/kendo-react-labels'
import { ComboBox } from '@progress/kendo-react-dropdowns'
import { useEntidadeStore } from '../../../stores/entidade'
import { Input } from '@progress/kendo-react-inputs'

export default function EntidadeCodigoPostalInputField(
  fieldRenderProps: FieldRenderProps
) {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    value,
    ...others
  } = fieldRenderProps
  const editorRef = React.useRef<any>(null)
  const selectedEntidadeResult: any = useEntidadeStore(state => state.selectedEntidadeResult);

  const showValidationMessage: string | false | null =
    touched && validationMessage
  const showHint: boolean = !showValidationMessage && hint
  const hintId: string = showHint ? `${id}_hint` : ''
  const errorId: string = showValidationMessage ? `${id}_error` : ''
  const labelId: string = label ? `${id}_label` : ''

  return (
    <FieldWrapper>
       <div> 
      <Input label={label} placeholder={''}  id={id} value={selectedEntidadeResult && selectedEntidadeResult.codigoPostal} disabled={disabled} {...others}>
      </Input>
      </div>
      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  )
}
