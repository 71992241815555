import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLocationDot,
  faInfoCircle,
  faEye,
} from '@fortawesome/free-solid-svg-icons'
import styles from '../../styles/intervencao/Intervencao.module.scss'
import clsx from 'clsx'

type Props = {
  onInfoClick?: (acao: Record<string, unknown>) => () => void
  onLocationClick: (acao: Record<string, unknown>) => () => void
  onDocumentPreviewClick?: (acao: Record<string, unknown>) => () => void
  dataItem: Record<string, unknown>
}
type PropsWithoutDocumentPreview =Exclude<Props, "onDocumentPreviewClick">
export const ResultadoAcaoCommandGridCell = ({
  onInfoClick,
  onLocationClick,
  onDocumentPreviewClick,
  dataItem,
}: Props | PropsWithoutDocumentPreview ) => {
  return (
    <td className="k-command-cell">
      <div className={styles.gridCommand}>
      {dataItem.hasGeom ? 
        <div
          role="button"
          onClick={onLocationClick(dataItem)}
          className={clsx(styles.gridCommandIconButton, 'espacoGridAction')}
        >
          <FontAwesomeIcon icon={faLocationDot} title="Mostrar no Mapa" />
        </div>
        : <div className='espacoGridAction'>
            &nbsp;
          </div>}

      {onInfoClick?
            <div
            role="button"
            className={styles.gridCommandIconButton}
            onClick={onInfoClick(dataItem)}
          >
            <FontAwesomeIcon icon={faInfoCircle} title="Detalhes de registo" />
          </div>
        :<></>}
        
        {onDocumentPreviewClick && dataItem?.url != undefined && dataItem.url !== '' && (
          <div
            role="button"
            className={styles.gridCommandIconButton}
            onClick={onDocumentPreviewClick(dataItem)}
          >
            <FontAwesomeIcon icon={faEye} title="Visualizar" />
          </div>
        )}
      </div>
    </td>
  )
}
