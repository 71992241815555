import { DateTimePicker } from "@progress/kendo-react-dateinputs"
import { ComboBox } from "@progress/kendo-react-dropdowns"
import { Field, FormRenderProps } from "@progress/kendo-react-form"
import { Input, TextArea } from "@progress/kendo-react-inputs"
import { useEffect, useState } from "react"
import { blobToBase64, vzEstados, fillEstadoAsync, fillVzEstadoAsync, handleGenericError, useCombos } from "../../../services/utils"
import { useVooDroneStore } from "../../../stores/vooDrone"
import styles from '../../../styles/vooDrone/VooDrone.module.scss'
import { requiredFieldValidator } from "../../../validations/documento"
import { formatsAcceptToSubmit } from "../RegistarVooDroneForm"
import VideoPlayer from "../VideoPlayer"
import ValidatedVideoUpload from "../ValidatedVideoUpload"
import CheckMoreItem from "../../documentos/registar/fields/CheckMoreItem"
import { filterBy } from "@progress/kendo-data-query"
import VideoPlayerV2 from "../VideoPlayerV2"

type Props = {
    formRenderProps: FormRenderProps,
    viewMode: boolean
}
const SRT_FORMAT = "SRT";
const KML_FORMAT = "KML";
const TYPE_BASE64_FILE_KML = "data:application/octet-stream;base64,"
export default function IdentificacaoVooDrone({ formRenderProps, viewMode }: Props) {
  
    type combosKeys= 'vzEstados';

    const [combos, setCombos] = useState<any>({
        vzEstados
    })
  
  
    const setDataOption: Record<combosKeys, { setFn: any, data: any[] }> = {
        vzEstados: {
        setFn: setCombos,
        data: vzEstados
      }
    };
  
  const filterCombo_Multiselect = (e: any, tipoCombo: combosKeys) => {
      const { setFn, data } = setDataOption[tipoCombo];
      setFn({
          ...combos,
          [tipoCombo]: filterBy(data?.slice()??[], e.filter)
      }
    );
  }

    useEffect(() => {

     if(!vzEstados){
      fillVzEstadoAsync();
     }
    }, []);

    const _cleanFields = ["concelho", "freguesia", "distrito"];

    const selectedVooDronePathResult: any = useVooDroneStore(
        (state) => state.selectedVooDronePathResult
    )
  
    const selectVooDronePathResult = useVooDroneStore(
        (state) => state.selectVooDronePathResult
    )
    const showVideo = async (e: any) => {
        if (e?.value?.length==0) {
            selectVooDronePathResult(undefined)
            return;
        }
        const uploadedFiles: any[] = e?.value;
        let srt: string | ArrayBuffer | null = null;
        let kml: any = null;
        let video: File | Blob | MediaSource | string | undefined | ArrayBuffer = undefined;
        uploadedFiles?.forEach(async file => {
            const { name, extension, getRawFile } = file;
            if (extension.toUpperCase().includes(SRT_FORMAT)) {
                srt = getRawFile();
            }
            if (extension.toUpperCase().includes(KML_FORMAT)) {
                kml = getRawFile();
            }
            if (!formatsAcceptToSubmit.includes(extension)) {
                video = getRawFile();
            }
        })
        if (video) {
            try {
                postMessage({ type: 'showLoader' }, '*')
                video = await URL.createObjectURL(video);
                if (srt) {
                    srt = await blobToBase64(srt);
                }
                if (kml) {
                    kml = await blobToBase64(kml);
                    kml = kml.replace(TYPE_BASE64_FILE_KML, "");
                }
                selectVooDronePathResult({
                    ...selectedVooDronePathResult,
                    video,
                    srt,
                    kml: kml ? atob(kml) : undefined
                })

            } catch (error) {
                console.log({ error })
                handleGenericError(error);
            } finally {
                postMessage({ type: 'hideLoader' }, '*')
            }
        }else{
            selectVooDronePathResult({
                ...selectedVooDronePathResult, video:undefined
            })
        }
    }
    const selectedVooDroneResult: any = useVooDroneStore(
        (state) => state.selectedVooDroneResult
    )
    return (

        <div className={`${styles.form} ${styles.removeScrollDuplicado}`}>
            <fieldset>
                <legend>
                    <span className={styles.fieldsetTitulo}>Registo</span>
                </legend>
                <div className={styles.espacoVerticalFieldSet}>
                    {!selectedVooDronePathResult?.id?
                        <div className={styles.fields}>
                            <div>
                            <br></br>                                                            
                                <span>Apenas são permitidos ficheiros do tipo .KML e .MP4</span>
                                <Field
                                    name="arquivo"
                                    component={ValidatedVideoUpload}
                                    onChange={showVideo}
                                    disabled={viewMode}
                                />

                            </div>
                         
                        </div>
                    :<></>
                    }
                    <div className={styles.fields}>
                        {selectedVooDronePathResult?.isResultado ?
                            <VideoPlayerV2 path={selectedVooDronePathResult?.video} />

                            :
                            <VideoPlayer path={selectedVooDronePathResult?.video} srt={selectedVooDronePathResult?.srt} kml={selectedVooDronePathResult?.kml} />
                        }
                    </div>
                 
                </div>
            </fieldset>

            <fieldset>
                <legend>
                    <span className={styles.fieldsetTitulo}>Identificação</span>
                </legend>
                <div className={styles.espacoVerticalFieldSet}>

                    <div className={`${styles.fields}`}>
                        <div hidden>
                            <Field
                                name="id_utilizador"
                                label="id_utilizador"
                                component={Input}
                                disabled
                            />
                        </div>
                        <div>

                            <Field
                                name="id"
                                label="ID"
                                component={Input}
                                disabled
                            />
                        </div>
                        <div>
                            <Field
                                name="data_registo"
                                label="Data de registo"
                                format="dd/MM/yyyy HH:mm"
                                component={DateTimePicker}
                                disabled
                            />

                        </div>

                    </div>
                    <div className={`${styles.fields}`}>
                        <div>
                            <Field
                                name="tecnico_registo"
                                label="Técnico de registo"
                                component={Input}
                                disabled
                            />

                        </div>
                        <div>
                            <Field
                                name="vz_estado"
                                label="Estado"
                                component={ComboBox}
                                data={combos?.vzEstados}
                                filterable={true}
                                onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'vzEstados')}}
                                textField={'descricao'}
                                disabled={viewMode}
                            />
                        </div>
                    </div>

                    <div className={`${styles.fields}`}>
                        <div>
                            <Field
                                name="descricao"
                                label="Descrição"
                                component={Input}
                              //  validator={requiredFieldValidator}
                                disabled={viewMode}
                            />
                        </div>
                    </div>
                </div>
            </fieldset>

            <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Localização</span>
          </legend>

          <div className={styles.espacoVerticalFieldSet}>
            <div className={styles.fields}>
                <div>
                  <CheckMoreItem
                    list={selectedVooDronePathResult?.nuts1}
                    property={'nome'}
                    label="NUTS I"
                  />
                </div>
                <div>
                  <CheckMoreItem
                    list={selectedVooDronePathResult?.nuts2}
                    property={'nome'}
                    label="NUTS II"
                  />
                </div>
                  <CheckMoreItem
                    list={selectedVooDronePathResult?.nuts3}
                    property={'nome'}
                    label="NUTS III"
                  />
                </div>
            

            <div className={styles.fields}>
       
                <div>
                  <CheckMoreItem
                    list={selectedVooDronePathResult?.distritos}
                    property={'nome'}
                    label="Distrito"
                  />
                </div>
           
                <div>
                  <CheckMoreItem
                    list={selectedVooDronePathResult?.concelhos}
                    property={'nome'}
                    label="Concelho"
                  />
                </div>
           
      
                <div>
                  <CheckMoreItem
                    list={selectedVooDronePathResult?.freguesias}
                    property={'nome'}
                    label="Freguesia"
                  />
                </div>
        
            </div>

            <div className={styles.fields}>
                <div>
                  <CheckMoreItem
                    list={selectedVooDronePathResult?.arhs}
                    property={'nome'}
                    label="ARH"
                  />
                </div>
        
                <div>
                  <CheckMoreItem
                    list={selectedVooDronePathResult?.capitanias}
                    property={'nome'}
                    label="Capitania"
                  />
                </div>
                <div>
                  <CheckMoreItem
                    list={selectedVooDronePathResult?.massasAgua}
                    property={'nome'}
                    label="Massa de água"
                  />
                </div>
            </div>
          </div>
          <div>
          </div>
        </fieldset>
        <fieldset>

                <legend>
                    <span className={styles.fieldsetTitulo}>Detalhes</span>
                </legend>
                <div className={styles.espacoVerticalFieldSet}>

                    <div className={styles.fields}>
                        <div>
                            <Field
                                disabled
                                name="duracao"
                                label="Duração"
                                component={Input}
                            />
                        </div>
                        <div>
                            <Field
                                disabled
                                name="distancia"
                                label="Distância"
                                component={Input}
                            />
                        </div>
                        <div>
                            <Field
                                disabled
                                name="data_voo"
                                label="Data de voo"
                                component={Input}

                            />
                        </div>
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <legend>
                    <span className={styles.fieldsetTitulo}>Comentário</span>
                </legend>
                <div className={styles.espacoVerticalFieldSet}>
                    <div className={styles.fields}>
                        <div>
                            <Field
                                name="comentario"
                                label="Comentário"
                                component={TextArea}
                                disabled={viewMode}
                            />
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>
    );
}