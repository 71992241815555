import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form, Field, FormElement } from '@progress/kendo-react-form'
import { Button } from '@progress/kendo-react-buttons'

import ValidatedInput from '../forms/ValidatedInput'
import { changPasswordFormValidator, forgotPasswordFormValidator, loginFormValidator } from '../../validations/auth'
import styles from '../../styles/auth/LoginForm.module.scss'
import { login } from '../../services/auth'
import { mapStore, post, setGeoPortalUrl, setGeoPortalUser } from '../../services/utils'
import { AUTH_LOCALSTORAGE, GEOPORTAL_MAPA_PUBLICO_URL, GEOPORTAL_MAPA_URL, RESET_PASSWORD, ROLE_MAPAS_PRIVADOS, SEND_MAIL_TOKEN_PASSWORD_RESET } from '../../utils/constants'
import clsx from 'clsx'
import { Input } from '@progress/kendo-react-inputs'
import SessionStorageService from '../../services/SessionStorageService'
import ReactGA from 'react-ga4';

export default function ForgotPasswordForm() {
  const navigate = useNavigate()
  const location = useLocation()
  const setGPUrl = mapStore((state: any) => state.setUrl);
  const from = location.state?.from?.pathname || '/'
  const queryParams = new URLSearchParams(window.location.search)
  const sendMailTokenPasswordReset = async (data: any) =>
  await post<any, any>(`${SEND_MAIL_TOKEN_PASSWORD_RESET}`, data)

  const handleSubmit = async (dataItem: { [name: string]: string }) => {
    postMessage({ type: 'showLoader' }, '*')
    const dataToSend = {
      email: dataItem.email
    }
    sendMailTokenPasswordReset(dataToSend).then(result => {
      if (result) {
        postMessage({ type: 'hideLoader' }, '*')
        postMessage({ type: 'sucessMsg', value: {message: "E-mail enviado com sucesso."} }, '*')
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      }
    });
  }

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/ForgotPassword" });
  }, []);

   return (
    <Form
      onSubmit={handleSubmit}
      validator={forgotPasswordFormValidator}
      render={() => (
        <FormElement className={styles.form}>
          <fieldset className={'k-form-fieldset'}>
            <div className={styles.bottomSpacer}>
              <Field name="email" component={ValidatedInput} label="E-mail" />
            </div>
          </fieldset>
          <div className={`k-form-buttons ${styles.buttons}`}>
            <Button
              className={clsx(styles.submit, styles.iniciarCor)}
              type="submit"
            >
              Enviar e-mail de recuperação
            </Button>
          </div>
        </FormElement>
      )}
    />
  )
}
