import { faCircleXmark } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'

import { Button, DropDownButton } from '@progress/kendo-react-buttons'

import { faPrint } from '@fortawesome/free-solid-svg-icons'
import { Dialog } from '@progress/kendo-react-dialogs'
import { Tooltip } from '@progress/kendo-react-tooltip'
import { CSVLink } from 'react-csv'
import { useDocumentosStore } from '../../stores/documentos'
import commonStyles from '../../styles/common/Common.module.scss'
import styles from '../../styles/documentos/Documentos.module.scss'

import {
  Dispatch,
  MouseEvent as ReactMouseEvent,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { ResultadoDocumentoCommandGridCell } from './ResultadoDocumentoCommandGridCell'

import {
  Grid,
  GridCellProps,
  GridColumn,
  GridColumnMenuCheckboxFilter,
  GridColumnMenuFilter,
  GridColumnMenuGroup,
  GridColumnMenuSort,
  GridPageChangeEvent,
  GridToolbar
} from '@progress/kendo-react-grid'

import { process } from '@progress/kendo-data-query'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout"
import { GridPDFExport } from '@progress/kendo-react-pdf'
import clsx from 'clsx'
import SessionStorageService from '../../services/SessionStorageService'
import { GetUserNameTecnico, b64toBlob, clearDocumento, entidadeUser, estados, exportPDFWithMethod, fillEstadoAsync, get, handleDownload, handleGenericError, hasPermission, post, setDocumentoSelected, useCombos } from '../../services/utils'
import { useCommonStore } from '../../stores/common'
import { CURRENT_TIMEZONE, GET_DOCUMENTO, GET_DOCUMENTOS_SHAPEFILE, GET_ENTIDADE_SHAPEFILE, PERMISSION_DOCUMENTOS_PERFIL_COMPLETO_EDICAO, PERMISSION_DOCUMENTOS_PERFIL_COMPLETO_LEITURA, PERMISSION_DOCUMENTOS_PERFIL_INTERMEDIO_EDICAO, PERMISSION_DOCUMENTOS_PERFIL_INTERMEDIO_LEITURA, PERMISSION_DOCUMENTOS_PERFIL_TOTAL, PESQUISAR_DOCUMENTOS_AVANCADA, PESQUISAR_DOCUMENTOS_SIMPLES, PESQUISAR_DOCUMENTO_LIST_RESULTS, RECORDS_LIMIT, RELATORIO_MERGE_DOCUMENTO, URL_AJUDA_DOCUMENTOS_AVANCADA, URL_AJUDA_DOCUMENTOS_SIMPLES } from '../../utils/constants'
import MaximizeButton from '../common/MaximizeButton'
import ImagePreviewV2 from '../viewer/ImagePreviewV2'
import VisualizadorColunas from '../Generico/VisualizadorColunas '
import useVisibleColumns, { Key } from '../../hooks/useVIsibleColumns'
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais'
import { PagerTargetEvent } from '@progress/kendo-react-data-tools'
import ReactGA from 'react-ga4';
import moment from 'moment';
import "moment-timezone"
import React from 'react'
type Props = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
  setShowResults: Dispatch<SetStateAction<boolean>>
  setShowDocumentosForm?: Dispatch<SetStateAction<boolean>>
}

type PageState = {
  skip: number
  take: number
}

const initialDataState: PageState = { skip: 0, take: 10 }
const keyPainelPrincipal:Key = 'documentoColunasVisiveis';
export default function ResultadoDocumentosForm({
  onCloseClick,
  setShowResults,
  setShowDocumentosForm,
}: Props) {
  const canCreateEditDocumentos = hasPermission(PERMISSION_DOCUMENTOS_PERFIL_COMPLETO_EDICAO) || hasPermission(PERMISSION_DOCUMENTOS_PERFIL_TOTAL) || hasPermission(PERMISSION_DOCUMENTOS_PERFIL_INTERMEDIO_EDICAO); 
  const canSeeDocumentos =  hasPermission(PERMISSION_DOCUMENTOS_PERFIL_COMPLETO_EDICAO) || 
                            hasPermission(PERMISSION_DOCUMENTOS_PERFIL_COMPLETO_LEITURA) || 
                            hasPermission(PERMISSION_DOCUMENTOS_PERFIL_TOTAL) ||
                            hasPermission(PERMISSION_DOCUMENTOS_PERFIL_INTERMEDIO_EDICAO)
                            hasPermission(PERMISSION_DOCUMENTOS_PERFIL_INTERMEDIO_LEITURA); 
                            
  const [isMobile, setIsMobile] = useState(false);
  const [page, setPage] = useState<PageState>(initialDataState)
  const [pageSizeValue, setPageSizeValue] = useState<
    number | string | undefined
  >()

  const [openForm, setOpenForm] = useState<boolean>(false)
  const [documentos, setDocumentos] = useState([])
  const selectDocumentoResult = useDocumentosStore(
    (state) => state.selectDocumentosResult
  )
  const selectDocumentosResultId = useDocumentosStore(
    (state) => state.selectDocumentosResultId
  )

  const [selected, setSelected] = useState<number>(0)
  const [urlDoDocumento, setUrlDoDocumento] = useState("");
  const [idDoDocumento, setIdDoDocumento] = useState("");
  const isCurrentViewMaximized = useCommonStore(state => state.isCurrentViewMaximized)
  const restoreView = useCommonStore(state => state.restoreView)
  const {getVisibleColumns, removeColumnsKeys} = useVisibleColumns();
  const getShapeFile = async (data: any) =>
  await post<any, any>(`${GET_DOCUMENTOS_SHAPEFILE}`, data)

  const handlePageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent
    const take = event.page.take

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value)
    }

    setPage({
      ...event.page,
      take,
    })
  }

  useEffect(() => {

    if (!estados) {
      fillEstadoAsync();
    }

  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/ResultadoDocumentosForm" });
    // ReactGA.pageview(window.location.pathname + "ResultadoDocumentosForm");
  }, []);

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected)
  }

  const relatorioDocumentoMerge = async (data: any) =>
    await post<any, any>(`${RELATORIO_MERGE_DOCUMENTO}`, data)

  const getDocumentoPorIds = async (data: any) =>
    await post<any, any>(`${PESQUISAR_DOCUMENTO_LIST_RESULTS}`, data)

  const getDocumento = async (data: any) =>
    await get<any, any>(`${GET_DOCUMENTO}/` + data)

    const getDocumentosSimples = async (data: any) =>
    await post<any, any>(`${PESQUISAR_DOCUMENTOS_SIMPLES}`, data)

    const getDocumentosAvancada = async (data: any) =>
    await post<any, any>(`${PESQUISAR_DOCUMENTOS_AVANCADA}`, data)

    const initialized = useRef(false)
    useEffect(() => {
        if (!initialized.current) {
          initialized.current = true;           
          if (SessionStorageService.getItem("lastSearchDocumentosAvançada") && !SessionStorageService.getItem("firstCallDocumentoSimples")) {
        const objToSearch = SessionStorageService.getItem("lastSearchDocumentosAvançada") ?? '';
        getDocumentosAvancada(objToSearch).then((data) => {
          SessionStorageService.setItem("resultadoDocumentosPesquisa", JSON.stringify(data));
          setResultsDocumentos();
        })
      } else if (SessionStorageService.getItem("lastSearchDocumentosSimples") && !SessionStorageService.getItem("firstCallDocumentoAvancada")) {
        const objToSearch = sessionStorage.getItem("lastSearchDocumentosSimples") ?? '';
        getDocumentosSimples(objToSearch).then((data) => {
          SessionStorageService.setItem("resultadoDocumentosPesquisa", JSON.stringify(data));
          setResultsDocumentos();
        });
      }
      else {
        setResultsDocumentos();
    }
  }
    }, [])

    const setResultsDocumentos = () => {
      const results = JSON.parse(
      SessionStorageService.getItem('resultadoDocumentosPesquisa') ?? ''
    )

    if (results) {
      const iframe: any = document.getElementById('iframeGeoPortal')
      const ids: number[] = []
      results.forEach((element: any) => {
        if(element){
          ids.push(element?.id)
        }
      })

    }

    const formattedResults = results.map((documento: any) => ({
      ...documento,
      data_registo:  documento.data_registo ? format(
        new Date(documento.data_registo),
        'dd/MM/yyy'
      ) : '',
    }))

    setDocumentos(formattedResults)
    getVisibleColumns(keyPainelPrincipal, setColums)
  }

  useEffect(() => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    const ids: number[] = []
    documentos.forEach((element: any) => {
      ids.push(element.id)
    })
    iframe.contentWindow?.postMessage(
      { type: 'SiarlDocumentoSearch', value: ids },
      '*'
    )
  }, [])

  const clearSearchResults = () => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlDocumentosClearLayers", value: "true" }, '*')
    SessionStorageService.removeItem('resultadoDocumentosPesquisa')
    postMessage({ type: 'resultadoDocumentosPesquisaChange' }, '*')
    SessionStorageService.removeItem("lastSearchDocumentosAvançada");
    SessionStorageService.removeItem("lastSearchDocumentosSimples");
    selectDocumentoResult(null)
    clearDocumento();
    removeColumnsKeys(keyPainelPrincipal)

  }

  const handleLocationMouseEnterById = (
    documentos: Record<string, unknown>
  ) => {
    console.log(`#${documentos.id} - mostrar popover no mapa`)
  }

  const handleLocationMouseLeaveById = (
    documentos: Record<string, unknown>
  ) => {
    console.log(`#${documentos.id} - mostrar popover no mapa`)
  }

  const handleInfoClickById = (documentosLocal: Record<string, any>) => async () => {

    const documentos = await getDocumento(documentosLocal.id);
    
    const tecnico = await GetUserNameTecnico(documentos.utilizador_id);

    const entidadeByUser = documentos?.utilizador?.entidade?.nome;

    if (!documentos || documentos==="") {
      postMessage({ type: "infoMsg", value: { message: "Registo desativado" } }, "*");
      return;
    }

    const url = documentos.url as string | undefined
  const hasValidExtension = (url: string | undefined): boolean => {
    return url ? /\.(\w{3,4})$/i.test(url) : false;
  }

    postMessage({ type: "showLoader" }, "*");

    postMessage({ type: "hideLoader" }, "*");
    const selectedDocumentosResults = {
      ...documentos,
      // Idetificacao
      id: documentos.id,
      Iddoc: documentos.id || undefined,
      filedoc_cod: documentos.filedoc_cod || undefined,
      Idfiledoc : documentos.filedoc_cod || undefined,
      idioma: documentos.idioma || undefined,
      entidade_id: documentos.entidade || undefined,
      tipoAcesso: documentos.tipoAcesso || undefined,
      recurso: documentos.tipoRecurso || undefined,
      tipo_recurso_id: documentos.tipoRecurso || undefined,
      dataPublicacao: documentos.data_publicacao ? moment.utc(documentos.data_publicacao).tz(CURRENT_TIMEZONE) : undefined,
      titulo: documentos.titulo || undefined,
      url: documentos.url || undefined,
      path: documentos.path || undefined,
      obs: documentos.observacoes || undefined,
      estado_id: estados.find(estado => estado.id === documentos.estado_id) || undefined,
      geom_imported: documentos.geom_imported ?? undefined,
      dataHoraRegisto: documentos.data_registo ? moment.utc(documentos.data_registo).tz(CURRENT_TIMEZONE) : undefined,
      palavraChave: documentos.lstPalavraChave || undefined,
      resumo: documentos.resumo || undefined,
      autores: documentos.autores || undefined,
      categoriaTematica: documentos.lstCategoria || undefined,
      flatCoordinates: documentos.flatCoordinates || undefined,
      nomearquivo: documentos.nomearquivo || undefined,
      entidade_registo: documentos?.utilizador?.entidade?.nome,
      tecnicoRegisto: tecnico
    }
    if(url != undefined && url?.trim() !== "" && hasValidExtension(url)) {
      SessionStorageService.setItem('documentoAnexado', 'true')
    }
    else{
      SessionStorageService.setItem('documentoAnexado', 'false')
      
    }
    SessionStorageService.setItem('novoDocumento', 'false')
    sessionStorage.setItem('documentoAntigo', 'true')
    SessionStorageService.setItem("isSubmitActionDocumentos", "false");

    selectDocumentosResultId(documentos.id as number)
    selectDocumentoResult(selectedDocumentosResults)
    setDocumentoSelected(selectedDocumentosResults);
    setShowResults(false)
    const idToSend = [documentos.id];
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlDocumentoSearch", value: idToSend }, '*')

    iframe.contentWindow?.postMessage({ type: "SiarlDocumentoCenter", value: idToSend }, '*')
    if (setShowDocumentosForm) setShowDocumentosForm(true)

    restoreView();
  }

  const [dataState, setDataState] = useState({
    skip: 0,
    take: 20,
    sort: [],
    group: [],
  });


  const [dataResult, setDataResult] = useState(
    process(documentos, dataState)
  );

  const dataStateChange = (event: any) => {
    setDataResult(process(documentos, event.dataState));
    setDataState(event.dataState);

    const skipPag: PageState = { skip: event.dataState.skip, take: event.dataState.take}
setPage({
  ...skipPag
})
  };

 

  const ColumnMenu = (dataState: any) => {
    return (
      <div>
      <GridColumnMenuSort {...dataState} />
      <GridColumnMenuFilter {...dataState} />
      <GridColumnMenuGroup {...dataState} />
    </div>)

  }
  
  const openAjuda = () => {
    if (SessionStorageService.getItem("DocumentosPesquisaTipo")) {
      const documentoPesquisaTipo = SessionStorageService.getItem("DocumentosPesquisaTipo");
      if (documentoPesquisaTipo == "Simples") {
        window.open(URL_AJUDA_DOCUMENTOS_SIMPLES, "_blank");
      } else if (documentoPesquisaTipo == "Avancada") {
        window.open(URL_AJUDA_DOCUMENTOS_AVANCADA, "_blank");
      }
    }
  }


  const openPdfNewTab = () => {
    return new Promise<void>((resolve, reject) => {
        
        postMessage({ type: "showLoader" }, "*");

        setTimeout(() => {
           const columnsToShow = colums.columns.filter(x => x.show);
            const dataToExport = documentos.map((item: any) => {
                const filteredItem: any = {};
                columnsToShow.forEach((column: any) => {
                    filteredItem[column.field] = item[column.field];
                });
                return filteredItem;
            });
            
            exportPDFWithMethod(columnsToShow, dataToExport, 8)
                .then((pdfData: any) => {
                    postMessage({ type: "hideLoader" }, "*");

                        const base64Data = pdfData.split(";base64,")[1];
                        console.log(base64Data);
                        const blob = b64toBlob(base64Data, 'application/pdf');
                        const blobUrl = URL.createObjectURL(blob);

                      
                        window.open(blobUrl, '_blank');
                        resolve();
                  
                })
                .catch(error => {
                    console.error('Error exporting PDF:', error);
                    reject(error);
                });
        }, 1000);
    });
};


  const handleLocationClickById = (documento: Record<string, any>) => () => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlDocumentoSearch", value: [documento.id] }, '*')
    iframe.contentWindow?.postMessage({ type: "SiarlDocumentoCenter", value: [documento.id] }, '*')
  }
  const conditionalProps = canSeeDocumentos
  ? { 
    onInfoClick: handleInfoClickById
   } 
  : {};

  const CommandCell = (props: GridCellProps) => (
    <ResultadoDocumentoCommandGridCell
      {...props}
      onLocationMouseEnter={handleLocationMouseEnterById}
      onLocationMouseLeave={handleLocationMouseLeaveById}
      onDocumentPreviewClick={handleDocumentPreviewClickById}
      onLocationClick={handleLocationClickById}
      {...conditionalProps}
    />
  )
  const close = () => {
    setOpenForm(false)
  }

  const documentosToPreview = JSON.parse(
    SessionStorageService.getItem('resultadoDocumentosPesquisa') ?? ''
  )

  const handleDocumentPreviewClickById = (documentosLocal: Record<string, any>) => async () => {

    try {

      postMessage({ type: "showLoader" }, "*");
      //const documentos = await getDocumento(documentosLocal.id);

      setIdDoDocumento(documentosLocal.id);
      setUrlDoDocumento(documentosLocal.url);
      setOpenForm(true);

    } catch (error) {
      handleGenericError(error, "Não foi possivel abrir o documento!");
      setOpenForm(false);

    } finally {

      postMessage({ type: "hideLoader" }, "*");

    }
  }

  const nextPhoto = async (e: any) => {

    try {

      postMessage({ type: "showLoader" }, "*");
      const nextIds = documentosToPreview
        .filter((doc: any) => doc.id < idDoDocumento && doc.url !== '')
        .map((doc: any) => doc.id);

      if (nextIds.length > 0) {
        const nextId = Math.max(...nextIds);
        console.log(`O próximo ID é ${nextId}`);

        const documentos = await getDocumento(nextId);

        setIdDoDocumento(nextId.toString());

        const url = documentos.url.replace(/ /g, '%20');

        setUrlDoDocumento(url);

      } else {
        console.log("Não há um próximo ID na lista.");
      }

    } catch (error) {
      handleGenericError(error, "Não foi possivel abrir o documento!");

    } finally {

      postMessage({ type: "hideLoader" }, "*");

      e.preventDefault();
    }

  }

  const previousPhoto = async (e: any) => {

    try {

      postMessage({ type: "showLoader" }, "*");
      const previousIds = documentosToPreview
        .filter((doc: any) => doc.id > idDoDocumento && doc.url !== '')
        .map((doc: any) => doc.id);

      if (previousIds.length > 0) {
        const previousId = Math.min(...previousIds);
        console.log(`O anterior ID é ${previousId}`);

        const documentos = await getDocumento(previousId);

        setIdDoDocumento(previousId.toString());

        const url = documentos.url.replace(/ /g, '%20');

        setUrlDoDocumento(url);

      } else {
        console.log("Não há um anterior ID na lista.");
      }

    } catch (error) {
      handleGenericError(error, "Não foi possivel abrir o documento!")

    } finally {

      postMessage({ type: "hideLoader" }, "*");

      e.preventDefault();
    }

  }

  useEffect(() => {

    const elemento: any = document.querySelector(".k-window-content.k-dialog-content");

    if (elemento) {
      elemento.style.padding = "0px";
    }

  }, [openForm]);

  const onItemClick = (event: any) => {

    const link: HTMLAnchorElement | null = document.querySelector('#print-results');

    if (link) {
      link.click();
    }

    // if (event.item.key == 'ExportarFichasResultados') {



    // } else if (event.item.key == 'ExportarListaResultados') {

    //     console.log(event.item.text);

    // }

  };

let _export: any;
const exportExcel = () => {
  _export.save();
};


const onItemClickExportarFichas = async () => {
  try {

    const relatorios = [];

    const results = JSON.parse(
      SessionStorageService.getItem('resultadoDocumentosPesquisa') ?? ''
    );

  
    const idsDocumento = results.map((o: any) => o.id);

    if(idsDocumento.length  > RECORDS_LIMIT){
      postMessage({ type: 'errorMsg', value: { message: "Não é possivel exportar mais de 100 fíchas" } }, '*')
      return false
      } 

    const documentos = await getDocumentoPorIds(idsDocumento);

    postMessage({ type: 'showLoader' }, '*');

    for (const documentoId of idsDocumento) {
        const documento = documentos.find((documento: any) => documento.id === documentoId);

   
      const relatorio = {
        id: documento?.id,
        dados: {
          //Registo
          'REGISTO' : '',
          'Filedoc:': documento?.filedoc_cod ?? '',
          'Estado do Registo:' : documento?.estado?.designacao ?? '',
          'Data do registo:': documento?.data_registo ? new Date(documento?.data_registo).toLocaleDateString() : '',
          'Técnico:' : documento?.utilizador?.name ?? '',
          'Entidade do Registo:' : entidadeUser ?? '',

          //Indetificacao
          'IDENTIFICAÇÃO' : '',
          'Título:': documento?.titulo ?? '',
          'Autores:': documento?.autores ?? '',
          'Data da publicação:': documento?.data_publicacao ? new Date(documento?.data_publicacao).toLocaleDateString() : '',

          //Localização
          'LOCALIZAÇÃO' : '',
          'Distrito:': documento?.distritos ? documento?.distritos?.nome : '',
          'Concelho:': documento?.concelhos ?documento?.concelhos?.nome : '',
          'Freguesia:': documento?.lstFreguesias ? documento?.lstFreguesias?.nome : '',
          'Nuts1:': documento?.nuts1 ? documento?.nuts1?.nome : '',
          'Nuts2:': documento?.nuts2 ? documento?.nuts2?.nome : '',
          'Nuts3:': documento?.nuts3 ? documento?.nuts3?.nome : '',

          //Tipologia
          'TIPOLOGIA' : '',
          'Recurso:': documento?.tipoRecurso?.designacao ?? '',
          'Tipo de acesso:': documento?.tipoAcesso?.designacao ?? '',


          //Resumo
          'RESUMO' : '',
          'Resumo:': documento?.resumo ?? '',


          //Detalhe
          'DETALHES' : '',
          'Idioma:': documento?.idioma?.designacao ?? '',
          'Categoria temática:': documento?.lstCategoria ?? '',
          'Palavras chave:': documento?.lstPalavraChave  ?? '',
          'Observações:': documento?.observacoes ?? '',

          // 'Url': documento?.url ?? '',
          // 'categorias': documento?.categorias.map((c: any) => c.designacao) ?? '',
     
        },      
        img_mapa: "",
      };

      
      relatorios.push(relatorio);
  }

    const mergedPdfBase64 = await relatorioDocumentoMerge(relatorios);
    if (mergedPdfBase64) {
      const blob = b64toBlob(mergedPdfBase64, 'application/pdf');
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    }
  } catch (error) {
    console.log(error);
    postMessage({ type: 'errorMsg', value: { left: '50%', message: "Verifique os campos obrigatórios." } }, '*');
  } finally {
    postMessage({ type: 'hideLoader' }, '*');
  }
};

  const onItemClickExportar = async (event: any) => {
      if(event.item.key == "ExportarTodasFichas"){
        onItemClickExportarFichas();
      }

      if (event.item.key == 'ExportarListaResultadosExcel') {
  
        exportExcel();
  
      }else if (event.item.key == "ExportarListaResultadosShapeFile") {
        const results = JSON.parse(
          SessionStorageService.getItem('resultadoDocumentosPesquisa') ?? ''
        )
      
      if (results) {
        const iframe: any = document.getElementById('iframeGeoPortal')
        const ids: number[] = []
        results.forEach((element: any) => {
          ids.push(element.id)
        })

        const columnsToExport = colums.columns.filter(x => x.show).map(x => x.field);
        const documentosFiltred: any[] = [];
        documentos?.forEach((item: any) => {
          const filteredItem: any[] = [];
          colums.columns.filter(x => x.show).forEach((column: any) => {
            filteredItem.push({
              Key: column.field,
              Value: item[column.field]?.toString()
            });
          });
          documentosFiltred.push(filteredItem);
      })

        const objToSend = {
          ids: ids,
          fields: columnsToExport,
          values: documentosFiltred
        }

        const file: any = await getShapeFile(objToSend);
        const filePath = `siarl-lista-documentos-${format(new Date(), 'ddMMyyyHHmm')}.zip`;

       handleDownload(file, filePath);
  } 
    }
  };

let _pdfExport: any;
const exportPDF = () => {
  _pdfExport.save();
};

const onItemClickImprimir = async (event: any) => {
  openPdfNewTab();
}

  const colunasPadrao =
  [
    { field: "id", title: "Id", filter: 'numeric', columnMenu: ColumnMenu, show: true , width: 90},
    { field: "data_registo", title: "Data Registo", columnMenu: ColumnMenu, show: true, width: 170 },
    { field: "tipo_recurso", title: "Tipo de Recurso", columnMenu: ColumnMenu, show: true, width: 200 },
    { field: "titulo", title: "Titulo", columnMenu: ColumnMenu, show: true, width: 150 },
    { field: "autores", title: "Autor", columnMenu: ColumnMenu, show: true, width: 150 },
    { field: "entidade", title: "Entidade", columnMenu: ColumnMenu, show: true, width: 210 },
    { field: "estado", title: "Estado", columnMenu: ColumnMenu, show: true, width: 190},
  ]
  const colunasAdicionais: any[] =
  [
    // { field: "titulo", title: "Titulo", columnMenu: ColumnMenu, show: true },
    // { field: "entidade", title: "Entidade", columnMenu: ColumnMenu, show: true },
    // { field: "autores", title: "Autor", columnMenu: ColumnMenu, show: true },
  ]  

  const [colums, setColums]=useState<{columns:{field?:string, title?:string, width?:number|string, show:boolean, columnMenu?:any, filter?:any}[]}>({
    columns:colunasPadrao
  })
  useEffect(() => {
    SessionStorageService.setItem(keyPainelPrincipal, JSON.stringify(colums));
  }, [colums]);

  useEffect(()=>{
    if(canSeeDocumentos){
      setColums({ columns: [...colunasPadrao, ...colunasAdicionais] });
    }
  },[])
  const itemRender = (props: { item: any; itemIndex: number }) => {
    const isTitleItem = props.item.key === 'titulo';
    if (isTitleItem) {
      // Item de título personalizado
      return (
        <div className={styles.DropDownButtonTitulo}>
          <span>{props.item.text}</span>
        </div>
      );
    } else {
      // Demais itens
      return (
        <div className={styles.DropDownButtonItem}>
          <span>{props.item.text}</span>
        </div>
      );
    }
  };

  const optionsExportar = [
    {
      key: 'ExportarListaResultadosExcel',
      text: 'excel',
    },
    // {
    //   key: 'ExportarListaResultadosPdf',
    //   text: 'pdf',
    // },
    {
      key: 'ExportarListaResultadosShapeFile',
      text: 'Shapefile',
    },
    {
      key: 'ExportarTodasFichas',
      text: 'Fichas',
    },
  ]
  
  useLayoutEffect(() => {

    const handleResize = () => {

      if (window.innerWidth <= telaMobile) {
        
        setIsMobile(true);
        ocultaFloatingMenu_e_Mapa();

      }else{

        setIsMobile(false);
        mostraFloatingMenu_e_Mapa();

      }

    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);  


  const onItemClickMais = (event: any) => {

      elementoClickMais(event);

  };

  const optionsMais = [
    {
      key: 'titulo',
      text: 'Imprimir',
    },
    {
      key: 'imprimirFicha',
      text: 'Resultados',
      className: 'icon-siarl icon-imprimir btnIconRegistar'
    },
  ]

  const registarNovoClick = () => {
    SessionStorageService.setItem('novoDocumento', 'true')
    sessionStorage.setItem('documentoAntigo', 'false')
    SessionStorageService.setItem("isSubmitActionDocumentos", "false");
    SessionStorageService.setItem("documentoAnexado", "false");
    selectDocumentoResult(undefined)
    setDocumentoSelected(undefined);
    selectDocumentosResultId(undefined);
    clearDocumento();
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlDocumentosClearLayers", value: "true" }, '*')
    postMessage({ type: "form", value: "registarDocumentos" }, "*");
  }

  return (
    <>
      <div className={clsx(styles.base, isCurrentViewMaximized && commonStyles.maximizedBaseForm)}>
        <div className={styles.header}>
          <div>
            <div className={styles.nomeDoModulo}>Documentos</div>
            <div className={styles.tituloDaPagina}>Resultados de pesquisa</div>
          </div>

          <div>

            {canCreateEditDocumentos ? 
              <Button
                onClick={registarNovoClick}
                className={styles.btnNovoRegisto}
                style={{ display: isMobile ? 'none' : '' }}
                id='novoRegisto'
                title='Novo Documento'
              >
                <span className="icon-siarl icon-registar btnIconRegistar"></span>{' '}
                &nbsp;{' '}
                Novo Documento
              </Button>
            :  ''}

            <CSVLink
              title="Imprimir resultados"
              id="print-results"
              separator={';'}
              data={documentos.map((documento: any) => ({
                ...documento,
              }))}
              filename={`siarl-documentos-${format(new Date(), 'ddMMyyyHHmm')}`}
              hidden
            >
            </CSVLink>

           

            {!isMobile &&
            <VisualizadorColunas 
              columns={colums} 
              setColumns={setColums}
              styles={styles.btnEditar2} 
           />
            }

          <button className={styles.btnEditar2} onClick={onItemClickImprimir} style={{ display: isMobile ? 'none' : '' }} id='imprimirFicha' title='Imprimir'>
            <span className="icon-siarl icon-imprimir"></span>
          </button>

            {!isMobile &&
            <DropDownButton
              items={optionsExportar}
              className={styles.btnImprimir}
              style={{marginTop: '-4px'}}
              iconClass="icon-siarl icon-exportar"
              onItemClick={onItemClickExportar}
              fillMode={'flat'}
              itemRender={itemRender}
              title='Exportar'
            ></DropDownButton>
            }

            {isMobile &&
              <DropDownButton
                items={optionsMais}
                className={styles.btnMais}
                iconClass="icon-siarl icon-mais"
                onItemClick={onItemClickMais}
                fillMode={'flat'}
                itemRender={itemRenderMais}
                title="Mais Opções"
              />
            }

            <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
              <span className="icon-siarl icon-ajuda"></span>
            </button>

            <button className={styles.btnFechar} onClick={onCloseClick} title="Minimizar">
              <span className="icon-siarl icon-minimizar"></span>
            </button>

            {!isMobile &&
            <MaximizeButton className={styles.btnMaximizar} />
}
          </div>
        </div>

        <TabStrip selected={selected} onSelect={handleSelect} scrollable={isMobile}>
          <TabStripTab title="Documentos">
            <ExcelExport
              fileName='Resultado_Documentos'
              data={documentos}
              ref={(exporter) => {
                _export = exporter
              }}
            >
              <Grid
                sortable={true}
                onDataStateChange={dataStateChange}
                style={{
                  height: '73vh',
                }}
                data={dataResult.data.length > 0 ? dataResult : documentos.slice(page.skip, page.take + page.skip)}
                {...dataState}
                skip={page.skip}
                take={page.take}
                pageable={{
                  buttonCount: 5,
                  info: true,
                  type: 'numeric',
                  pageSizes: true,
                  previousNext: true,
                }}
               
               total={documentos.length}
                //groupable={true}
              >
                <GridColumn cell={CommandCell} width={80} />
                    {colums?.columns?.map((column, i) => {
                return (
                  column.show && (
                    <GridColumn key={i}  field={column.field} filter={column.filter} columnMenu={column.columnMenu}   width={column.width} title={column.title} />
                  )
                );
              })}
              </Grid>
            </ExcelExport>

            <GridPDFExport
              fileName='Resultado_Documentos'
              ref={(element) => {
                _pdfExport = element
              }}
              margin="1cm"
              paperSize={['340mm', '297mm']}         

            >
              {
                <Grid
                  sortable={true}
                  onDataStateChange={dataStateChange}
                  style={{
                    height: '73vh',
                  }}
                  data={dataResult.data.length > 0 ? dataResult : documentos}
                  {...dataState}
                  pageable={{
                    buttonCount: 5,
                    info: true,
                    type: 'numeric',
                    pageSizes: true,
                    previousNext: true,
                  }}
                  
                  //groupable={true}
                >
                  <GridColumn cell={CommandCell} width={56} />
                  <GridColumn
                    field="id"
                    title="Id"
                    filter={'numeric'}
                    columnMenu={ColumnMenu}
                  />
                  <GridColumn
                    field="data_registo"
                    title="Data"
                    columnMenu={ColumnMenu}
                  />
                  <GridColumn
                    field="titulo"
                    title="Titulo"
                    columnMenu={ColumnMenu}
                  />
                  <GridColumn
                    field="entidade"
                    title="Entidade"
                    columnMenu={ColumnMenu}
                  />
                </Grid>
              }
            </GridPDFExport>
          </TabStripTab>
        </TabStrip>

        <div
          className={clsx(styles.footer, styles.justify_one_child, isCurrentViewMaximized && commonStyles.maximizedFooter)}
        >
          <Button className={styles.btnPesquisar} onClick={clearSearchResults} title='Nova Pesquisa'>
            <span className="icon-siarl icon-pesquisar"></span>
            <span className={styles.rotulo}>
              {' '}&nbsp;{' '}
              Nova pesquisa
            </span>
          </Button>
        </div>
      </div>
      {openForm && (
        <Dialog
          className="ModalDocumentos"
          title={`Documento: ${idDoDocumento}`}
          onClose={close}
          style={{ zIndex: 999 }}
        >
          <ImagePreviewV2
            documentUrl={urlDoDocumento}
            next={(e: any) => nextPhoto(e)}
            previous={(e: any) => previousPhoto(e)}
          />

        </Dialog>
      )}
    </>
  )
}