import { DateTimePicker } from '@progress/kendo-react-dateinputs'
import { ComboBox } from '@progress/kendo-react-dropdowns'
import { Field, FormRenderProps } from '@progress/kendo-react-form'
import { Input, TextArea } from '@progress/kendo-react-inputs'
import { MouseEvent as ReactMouseEvent, useEffect, useState } from 'react'

import {
  arhs,
  capitania,
  concelhos,
  designacaoLinha,
  distritos,
  fillConcelhosAsync,
  fillDistritosAsync,
  fillFreguesiasAsync,
  fillNuts1Async,
  fillNuts2Async,
  fillNuts3Async,
  freguesias,
  get,
  getDemarcacaoSelected,
  hasPermission,
  massaagua,
  nuts1,
  nuts2,
  nuts3,
  post,
  put,
  regiaoHidrografica,
  setDemarcacaoSelected,
  tipoLinha,
  useCombos
} from '../../services/utils'
import styles from '../../styles/dominiohidrico/DominioHidrico.module.scss'

import { useDemarcacaoStore } from '../../stores/demarcacao'
import { GET_DEMARCACAO, PERMISSION_DOMINIO_HIDRICO_PERFIL_BASICO, PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_EDICAO, PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_LEITURA, PERMISSION_DOMINIO_HIDRICO_PERFIL_TOTAL, SAVE_NEW_DEAMARCACAO, UPDATE_DEMARCACAO } from '../../utils/constants'
import CheckMoreItem from '../documentos/registar/fields/CheckMoreItem'
import { filterBy } from '@progress/kendo-data-query'

type IProps = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
  formRenderProps: FormRenderProps
  viewMode?: boolean
}

export default function IdentificacaoDemarcacao({ onCloseClick, formRenderProps, viewMode }: IProps) {
  const [nuts2Filtered, setNuts2Filtered] = useState<any>()
  const [nuts3Filtered, setNuts3Filtered] = useState<any>()
  const [freguesiasFiltred, setFreguesiasFiltred] = useState<any>()
  const [concelhosFiltred, setConcelhosFiltred] = useState<any>()
  const [openImportModal, setOpenImportModal] = useState<any>()

  const selectedDemarcacaoResult: any = useDemarcacaoStore((state) => state.selectedDemarcacaoResult)
  const selectedDemarcacaoResultId: any = useDemarcacaoStore((state) => state.selectedDemarcacaoResultId)
  const selectDemarcacaoResult = useDemarcacaoStore((state) => state.selectDemarcacaoResult)
  const selectDemarcacaoResultId = useDemarcacaoStore((state) => state.selectDemarcacaoResultId)

  const saveDemarcacao = async (data: any) => await post<any, any>(`${SAVE_NEW_DEAMARCACAO}`, data)
  const updateDemarcacao = async (data: any) => await put<any, any>(`${UPDATE_DEMARCACAO}`, data)

  const getDhDesmarcacaoById = async (id: any) =>
    await get<any, any>(`${GET_DEMARCACAO}/` + id)

    type combosKeys='capitania'| 'arhs'| 'tipoLinha'| 'nuts1' | 'nuts2' | 'nuts3' | 'distritos'| 'concelhos'|'freguesias' | 'regiaoHidrografica' | 'massaagua';

    const [combos, setCombos] = useState<any>({
         tipoLinha,
          nuts1,
          nuts2,
          nuts3,
          distritos,
          concelhos,
          freguesias,
          massaagua, 
          regiaoHidrografica,
          arhs,
          capitania
        })
        const setDataOption: Record<combosKeys, { setFn: any, data: any[] }> = {
          tipoLinha: { setFn: setCombos, data: tipoLinha },
          nuts1: { setFn: setCombos, data: nuts1 },
          nuts2: { setFn: setCombos, data: nuts2Filtered },
          nuts3: { setFn: setCombos, data: nuts3Filtered },
          distritos: { setFn: setCombos, data: distritos },
          concelhos: { setFn: setCombos, data: concelhosFiltred },
          arhs: { setFn: setCombos, data: arhs },
          capitania: { setFn: setCombos, data: capitania },
          freguesias: { setFn: setCombos, data: freguesiasFiltred },
          massaagua: { setFn: setCombos, data: massaagua },
          regiaoHidrografica: { setFn: setCombos, data: regiaoHidrografica },
        };
        
        const filterCombo_Multiselect = (e: any, tipoCombo: combosKeys) => {
          const { setFn, data } = setDataOption[tipoCombo];
          setFn({
              ...combos,
              [tipoCombo]: filterBy(data?.slice()??[], e.filter)
          }
          );
      }


      const canSeeDominioHidrico = 
                            hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_LEITURA) ||
                            hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_EDICAO) ||
                            hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_TOTAL) 

    const allPermissionDominioHidrico = 
                            hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_BASICO) ||
                            hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_LEITURA) ||
                            hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_EDICAO) ||
                            hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_TOTAL) 
  
      
   
   useEffect(() => {
    
     if(!nuts1 ){
      fillNuts1Async();
      }
     if(!nuts2) {
      fillNuts2Async();
     }
     if(!nuts3){
      fillNuts3Async();
     }
     if(!distritos){
      fillDistritosAsync();
     }
     if(!concelhos){
      fillConcelhosAsync();
     }
     if(!freguesias){
      fillFreguesiasAsync();
     }


  }, []);


  const filterConcelho = (e: any) => {
    if (e?.value?.codigo) {
      const concelhosLocal = concelhos.filter(
        (x: any) => x.distrito_cod == e.value.codigo
      )
      setConcelhosFiltred(concelhosLocal)
    } else {
      setConcelhosFiltred(concelhos)
    }
  }

  const filterFreguesia = (e: any) => {
    if (e?.value?.codigo) {
      const freguesiaLocal = freguesias.filter(
        (x: any) => x.concelho_cod == e.value.codigo
      )
      setFreguesiasFiltred(freguesiaLocal)
    } else {
      setFreguesiasFiltred(freguesias)
    }
  }
  const filterNuts2 = (e: any) => {
    if (e?.value?.codigo) {
      const nutsLocal = nuts2.filter((x: any) => x.nuts_i_cod == e.value.codigo)
      setNuts2Filtered(nutsLocal)
    } else {
      setNuts2Filtered(nuts2)
    }
  }
  const filterNuts3 = (e: any) => {
    if (e?.value?.codigo) {
      const nutsLocal = nuts3.filter(
        (x: any) => x.nuts_ii_cod == e.value.codigo
      )
      setNuts3Filtered(nutsLocal)
    } else {
      setNuts3Filtered(nuts3)
    }
  }

  const changeFileDoc = async(e: any) => {
    setDemarcacaoSelected({...getDemarcacaoSelected(), filedoc_cod: e?.value});
  }

  return (
    <>
      <div className={`${styles.form} ${styles.removeScrollDuplicado}`}>
        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Registo</span>
          </legend>

        {allPermissionDominioHidrico &&
          <div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}>
            
            <div>
              <Field
                name="id"
                label="ID"
                component={Input}
                disabled
              />
            </div>

            <div>
              <Field
                name="filedoc_cod"
                label="Nº Processo APA"
                onChange={changeFileDoc}
                component={Input}
                disabled={viewMode}
              />
            </div>

            <div>
              <Field
                name="dataHoraRegisto"
                label="Data/hora registo"
                format="dd/MM/yyyy HH:mm"
                component={DateTimePicker}
                disabled
              />
            </div>
          </div>
          }

          <div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}>
         {canSeeDominioHidrico &&
            <div>
              <Field
                name="tecnicoRegisto"
                label="Técnico"
                disabled
                component={Input}
              />
            </div>
          }
          {allPermissionDominioHidrico &&
            <div>
              <Field
                name="designacao"
                label="Designação da Linha"
                component={Input}
                textField={'nome'}
                data={designacaoLinha}
                disabled={viewMode}
              />
            </div>
            }

          </div>
        </fieldset>

        {allPermissionDominioHidrico &&
        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Localização</span>
          </legend>

          <div className={styles.espacoVerticalFieldSet}>
            <div className={styles.fields}>
              <div>
                {getDemarcacaoSelected() ?
                  <CheckMoreItem
                    list={getDemarcacaoSelected()?.nuts1}
                    property={'nome'}
                    label="NUTS I"
                  /> :
                  <Field
                    name="nuts1"
                    label="NUTS I"
                    component={ComboBox}
                    textField={'nome'}
                    onChange={filterNuts2}
                    filterable={true}
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'nuts1')}}
                    data={combos?.nuts1}
                    disabled

                  />
                }
              </div>
              <div>
                {getDemarcacaoSelected() ?
                  <CheckMoreItem
                    list={getDemarcacaoSelected()?.nuts2}
                    property={'nome'}
                    label="NUTS II"
                  /> :
                  <Field
                    name="nuts2"
                    label="NUTS II"
                    component={ComboBox}
                    textField={'nome'}
                    onChange={filterNuts3}
                    filterable={true}
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'nuts2')}}
                    data={combos?.nuts2}
                    disabled

                  />}
              </div>
              <div>
                {getDemarcacaoSelected() ?
                  <CheckMoreItem
                    list={getDemarcacaoSelected()?.nuts3}
                    property={'nome'}
                    label="NUTS III"
                  /> :
                  <Field
                    name="nuts3"
                    label="NUTS III"
                    component={ComboBox}
                    textField={'nome'}
                    filterable={true}
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'nuts3')}}
                    data={combos?.nuts3}
                    disabled
                  />}
              </div>
            </div>

            <div className={styles.fields}>
              <div>
                {getDemarcacaoSelected() ?
                  <CheckMoreItem
                    list={getDemarcacaoSelected()?.distritos}
                    property={'nome'}
                    label="Distrito"
                  /> :
                  <Field
                    name="distrito"
                    label="Distrito"
                    component={ComboBox}
                    onChange={filterConcelho}
                    textField={'nome'}
                    disabled
                    filterable={true}
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'distritos')}}
                    data={combos?.distritos}

                  />}
              </div>
              <div>
                {getDemarcacaoSelected() ?
                  <CheckMoreItem
                    list={getDemarcacaoSelected()?.concelhos}
                    property={'nome'}
                    label="Concelho"
                  /> :
                  <Field
                    name="concelho"
                    label="Concelho"
                    component={ComboBox}
                    onChange={filterFreguesia}
                    disabled
                    textField={'nome'}
                    filterable={true}
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'concelhos')}}
                    data={combos?.concelhos}
                  />}
              </div>
              <div>
                {getDemarcacaoSelected() ?
                  <CheckMoreItem
                    list={getDemarcacaoSelected()?.freguesias}
                    property={'nome'}
                    label="Freguesia"
                  /> :
                  <Field
                    name="freguesia"
                    label="Freguesia"
                    component={ComboBox}
                    disabled
                    textField={'nome'}
                    filterable={true}
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'freguesias')}}
                    data={combos?.freguesias}
                  />}
              </div>
            </div>
            <div className={styles.fields}>
              <div>
                {getDemarcacaoSelected() ?
                  <CheckMoreItem
                    list={getDemarcacaoSelected()?.arhs}
                    property={'nome'}
                    label="ARH"
                  /> :
                  <Field
                    name="arh"
                    label="ARH"
                    component={ComboBox}
                    textField={'nome'}
                    disabled
                    filterable={true}
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'arhs')}}
                    data={combos?.arhs}
                  />}
              </div>
              <div>
                {getDemarcacaoSelected() ?
                  <CheckMoreItem
                    list={getDemarcacaoSelected()?.capitanias}
                    property={'nome'}
                    label="Capitania"
                  /> :
                  <Field
                    name="capitania"
                    label="Capitania"
                    component={ComboBox}
                    textField={'nome'}
                    filterable={true}
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'capitania')}}
                    data={combos?.capitania}
                    disabled

                  />}
              </div>
              <div>
                {getDemarcacaoSelected() ?
                  <CheckMoreItem
                    list={getDemarcacaoSelected()?.massasAgua}
                    property={'nome'}
                    label="Massa de água"
                  /> :
                  <Field
                    name="massaagua"
                    label="Massa de água"
                    component={ComboBox}
                    textField={'nome'}
                    filterable={true}
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'massaagua')}}
                    data={combos?.massaagua}
                    disabled
                  />}
              </div>
            </div>

            <div className={styles.fields}>
              <div>
                <Field
                  name="tipoLinha"
                  label="Tipo de linha"
                  component={ComboBox}
                  //onChange={}
                  filterable={true}
                  onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'tipoLinha')}}
                  data={combos?.tipoLinha}
                  disabled
                />
              </div>
              <div>
                <Field
                  name="regiaoHidrografica"
                  label="ARH"
                  disabled
                  component={ComboBox}
                  //onChange={}
                  textField={'nome'}
                  filterable={true}
                  onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'regiaoHidrografica')}}
                  data={combos?.regiaoHidrografica}
                />
              </div>
            </div>
          </div>
        </fieldset>
        }

        {canSeeDominioHidrico &&
        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Observações</span>
          </legend>

          <div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}>
            <Field
              name="obs"
              label="Observações"
              component={TextArea}
              textField={'nome'}
              disabled={viewMode}

            />
          </div>
        </fieldset>
      }

      </div>
    </>
  )
}
