import { DateTimePicker } from '@progress/kendo-react-dateinputs'
import { ComboBox, MultiSelect } from '@progress/kendo-react-dropdowns'
import { Field } from '@progress/kendo-react-form'
import { Input, RadioGroup, TextArea } from '@progress/kendo-react-inputs'
import { useEffect, useState } from 'react'
import {
  arhs,
  concelhos,
  distritos,
  fillArhsAsync,
  fillCapitaniaAsync,
  fillConcelhosAsync,
  fillDistritosAsync,
  fillFreguesiasAsync,
  fillNuts1Async,
  fillNuts2Async,
  fillNuts3Async,
  fotoIndex,
  freguesias,
  get,
  handleGenericError,
  massaagua,
  nuts1,
  nuts2,
  nuts3,
  post,
  setFotoIndex,
  tecnico,
  useCombos,
  userLogged
} from '../../services/utils'
import styles from '../../styles/entidade/RegistarEntidade.module.scss'
import {
  EPSG_GLOBAL,
  EPSG_MAP,
  GET_COORDINATES_BY_ADDRESS,
  GET_ENTIDADE,
  GET_FOTOS_REGISTO_VOO,
  GET_FO_IMAGE_BASE64,
  IMPORT_GEOM_ENTIDADE,
  PROJECTION_MAP,
  SAVE_NEW_ENTIDADE,
} from '../../utils/constants'

import { Button } from '@progress/kendo-react-buttons'
import {
  capitania, getEntidadeSelected, setCoordenadasSelected,
  setEntidadeSelected
} from '../../services/utils'
import { useEntidadeStore } from '../../stores/entidade'
import { useFotografiaStore } from '../../stores/fotoObliqua'
import MultipleUpload from '../viewer/fields/MultipleUpload'
import ValidatedDocumentosUpload from '../ocorrencia/fields/ValidatedDocumentosUpload'
import proj4 from 'proj4'
import ImagePreview from '../viewer/ImagePreview'
import CheckMoreItem from '../documentos/registar/fields/CheckMoreItem'
import FotografiaSlider from './FotografiaSlider'

type IProps = {
  clickSave?: any,
  fotos?: any[]
  viewMode?: boolean
}
export function FotografiaIdentificacao({ clickSave, viewMode }: IProps) {
  const [freguesiasFiltred, setFreguesiasFiltred] = useState<any>()
  const [concelhosFiltred, setConcelhosFiltred] = useState<any>()
  const [concelhosDisabled, setConcelhosDisabled] = useState<any>(true)
  const [freguesiasDisabled, setFreguesiasDisabled] = useState<any>(true)
  const [nameUser, setNameUser] = useState<any>()
  const [freguesiaSelected, setFreguesiaSelected] = useState<any>()
  const [distritoSelected, setDistritoSelected] = useState<any>()
  const [concelhoSelected, setConcelhoSelected] = useState<any>()
  const [nuts1Selected, setNuts1Selected] = useState<any>()
  const [nuts2Selected, setNuts2Selected] = useState<any>()
  const [nuts3Selected, setNuts3Selected] = useState<any>()
  const [massaaguaSelected, setMassaaguaSelected] = useState<any>()
  const [arhSelectected, setArhSelected] = useState<any>()
  const [nuts2Filtred, setNuts2Filtered] = useState<any>()
  const [nuts3Filtred, setNuts3Filtered] = useState<any>()
  //const [fotoIndex, setFotoIndex] = useState<number>(0);
  const [intervalSlides, setIntervarSlides] = useState<any>();
  const [fotos, setFotos] = useState<any>();
  const [isNew, setIsNew] = useState(false);
  const [playDisabled, setPlayDisabled] = useState(false);

  const [morada, setMorada] = useState<any>('')
  const [openImportModal, setOpenImportModal] = useState<any>()
  const importGeomEntidade = async (data: any) =>
    await post<any, any>(`${IMPORT_GEOM_ENTIDADE}`, data)
  const createEntidade = async (data: any) =>
    await post<any, any>(`${SAVE_NEW_ENTIDADE}`, data)
  const getCoordinates = async (data: any) =>
    await post<any, any>(`${GET_COORDINATES_BY_ADDRESS}`, data)
  const getEntidade = async (data: any) =>
    await get<any, any>(`${GET_ENTIDADE}/` + data)
  const getImagemBase64 = async (data: any) =>
    await get<any, any>(`${GET_FO_IMAGE_BASE64}/` + data)

  const getFotosRegistoVoo = async (data: any) =>
    await get<any, any>(`${GET_FOTOS_REGISTO_VOO}/` + data)

  const selectedFotografiaResult: any = useFotografiaStore(
    (state) => state.selectedFotoResult
  )
  const selectEntidadeResult = useEntidadeStore(
    (state) => state.selectEntidadeResult
  )
  const selectedEntidadeResult: any = useEntidadeStore(
    (state) => state.selectedEntidadeResult
  )
  const [urlDoDocumento, setUrlDoDocumento] = useState("");
  proj4.defs(EPSG_MAP, PROJECTION_MAP);

useEffect(() => {
    
     if(!nuts1 ){
      fillNuts1Async();
      }
     if(!nuts2) {
      fillNuts2Async();
     }
     if(!nuts3){
      fillNuts3Async();
     }
     if(!distritos){
      fillDistritosAsync();
     }
     if(!concelhos){
      fillConcelhosAsync();
     }
     if(!freguesias){
      fillFreguesiasAsync();
     }
     if(!arhs){
      fillArhsAsync();
     }
     if(!capitania){
      fillCapitaniaAsync();
     }
  }, []);

  addEventListener(
    'message',
    (event: MessageEvent) => {
      if (event.data.type == "stopSlideShowPhoto") {
        clearInterval(intervalSlides);
      }

     });

  const cancelImport = () => {
    setOpenImportModal(false)
  }
  const importGeom = async (obj: any) => {
    let entidadeId
    if (entidadeSelected?.id) {
      entidadeId = entidadeSelected?.id
    } else {
      const entidadeInserted = await createEntidade({})
      entidadeId = entidadeInserted.id
      selectEntidadeResult(entidadeInserted)
      setEntidadeSelected(entidadeInserted)
    }
    let dataToSend
    if (freguesiaSelected) {
      dataToSend = {
        freguesia_cod: obj?.freguesiaImport?.codigo,
        geom_imported: 'Freguesia: ' + freguesiaSelected?.nome,
      }
    } else if (concelhoSelected) {
      dataToSend = {
        concelho_cod: concelhoSelected?.codigo,
        geom_imported: 'Concelho: ' + concelhoSelected?.nome,
      }
    } else if (distritoSelected) {
      dataToSend = {
        distrito_cod: distritoSelected?.codigo,
        geom_imported: 'Distrito: ' + distritoSelected?.nome,
      }
    } else if (nuts3Selected) {
      dataToSend = {
        nuts3_cod: nuts3Selected?.codigo,
        geom_imported: 'Nuts 3: ' + nuts3Selected?.nome,
      }
    } else if (nuts2Selected) {
      dataToSend = {
        nuts2_cod: nuts2Selected?.codigo,
        geom_imported: 'Nuts 2: ' + nuts2Selected?.nome,
      }
    } else if (nuts1Selected) {
      dataToSend = {
        nuts1_cod: nuts1Selected?.codigo,
        geom_imported: 'Nuts 1: ' + nuts1Selected?.nome,
      }
    } else if (massaaguaSelected) {
      dataToSend = {
        massa_agua_cod: massaaguaSelected?.codigo,
        geom_imported: 'Massa Agua: ' + massaaguaSelected?.nome,
      }
    } else if (arhSelectected) {
      dataToSend = {
        arh_cod: arhSelectected?.codigo,
        geom_imported: 'ARH: ' + arhSelectected?.nome,
      }
    }

    try {
      postMessage({ type: 'showLoader' }, '*')
      const result = await importGeomEntidade({
        ...dataToSend,
        entidade_id: entidadeId,
      })
      if (result) {
        postMessage(
          { type: 'informationMsg', value: 'Importado com sucesso.' },
          '*'
        )
        setEntidadeSelected({
          ...entidadeSelected,
          geom_imported: dataToSend?.geom_imported,
        })
        selectEntidadeResult({
          ...selectedEntidadeResult,
          geom_imported: dataToSend?.geom_imported,
        })
        setOpenImportModal(false)

        const entidadeUpdated = await getEntidade(entidadeId)

        if (
          entidadeUpdated?.diEntidadeJurisdicoes &&
          entidadeUpdated?.diEntidadeJurisdicoes.length > 0
        ) {
          const idToSend = [entidadeUpdated?.diEntidadeJurisdicoes[0].id]
          const iframe: any = document.getElementById('iframeGeoPortal')
          iframe.contentWindow?.postMessage(
            { type: 'SiarlEntidadesApoioSearch', value: idToSend },
            '*'
          )
        }
      }
    } catch (error) {
      handleGenericError(error, 'importação sem sucesso.')
      console.log({ error })
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  const filterConcelho = (e: any) => {
    if (e?.value?.codigo) {
      const concelhosLocal = concelhos.filter(
        (x: any) => x.distrito_cod == e.value.codigo
      )
      setConcelhosFiltred(concelhosLocal)
      setConcelhosDisabled(false)
    } else {
      setConcelhosFiltred(concelhos)
      setConcelhosDisabled(true)
    }
  }

  const geoCode = async (e: any) => {
    const flatCoordinatesLocal = await getCoordinates(morada)
    if (flatCoordinatesLocal) {
      const coords = flatCoordinatesLocal.split(',')
      const dataToSend = {
        longitude: coords[0],
        latitude: coords[1],
      }
      const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.contentWindow?.postMessage(
        { type: 'SiarlGeoCodeSearch', value: dataToSend },
        '*'
      )

      setCoordenadasSelected(flatCoordinatesLocal)
      postMessage(
        {
          type: 'CoordenadasSelected',
          value: { x: dataToSend.longitude, y: dataToSend.latitude },
        },
        '*'
      )
    }
  }

  const deselectAll = () => {
    deselectDistConc()
    deselectNuts()
    deselectMassaArh()
  }
  const deselectMassaArh = () => {
    setMassaaguaSelected(undefined)
    setArhSelected(undefined)
  }

  const deselectNuts = () => {
    setNuts1Selected(undefined)
    setNuts2Selected(undefined)
    setNuts3Selected(undefined)
  }

  const deselectDistConc = () => {
    setFreguesiaSelected(undefined)
    setDistritoSelected(undefined)
    setConcelhoSelected(undefined)
  }


  const fillImagePreviewer = async (index: any, play = false) => {
    if (fotos.length <= index) {
      return;
    }
    if (!play) {
      postMessage({type: "showLoader"}, "*");
      setUrlDoDocumento(fotos[index].url_externa);
      postMessage({type: "hideLoader"}, "*");
    } else {
          //so funciona publicado por problemas de cors na url
          try {
          const response = await fetch(fotos[index].url_externa);
        } catch {
          console.log("erro no fetch")
        }
          // const blob = await response.blob();

          // const blobUrl = window.URL.createObjectURL(blob);

          //const base64File = await getImagemBase64(fotos[index].id);
          setUrlDoDocumento(fotos[index].url_externa);
    }
    if (fotos[fotoIndex] && fotos[fotoIndex].latitude && fotos[fotoIndex].longitude) {
      const latLongMap = proj4(EPSG_GLOBAL, EPSG_MAP, [fotos[fotoIndex].longitude?? 0,fotos[fotoIndex].latitude?? 0]);
      const dataToSendLatLong = {
          longitude: latLongMap[0],
          latitude: latLongMap[1],
          id: fotos[fotoIndex]?.id
        }
        const iframe: any = document.getElementById('iframeGeoPortal')

        iframe.contentWindow?.postMessage(
            { type: "SiarlFotoObliquaPlay", value: dataToSendLatLong },
            '*'
        )
      }
  }

  const nextPhoto = (e: any, play = false) => {
    if (fotoIndex >= fotos.length) {
      setFotoIndex(0);
    } else {
      setFotoIndex(fotoIndex + 1);
    }
    fillImagePreviewer(fotoIndex, play);
    e.preventDefault();
  }

  const lastPhoto = (e:any) => {
    //setFotoIndex(fotos.length - 1);
    setFotoIndex(fotos.length - 1);
    fillImagePreviewer(fotos.length - 1);
    e.preventDefault();
  }

  const firstPhoto = (e: any) => {
    setFotoIndex(0);
    fillImagePreviewer(fotos.length - 1);
    e.preventDefault();
  }

  const play = (e: any) => {
    if (playDisabled) {
      return;
    }
    setPlayDisabled(true);
    const intervalId = setInterval(() => {
      if (fotoIndex >= fotos.length) {
        setFotoIndex(0);
      }
      nextPhoto(e, true);
    }, 1000);
    setIntervarSlides(intervalId);
    e.preventDefault();
  }

  const stop = (e:any) => {
    clearInterval(intervalSlides);
    setPlayDisabled(false);
    e.preventDefault();
  }

  const previousPhoto =(e: any) => {
    if (fotoIndex < 0) {
      setFotoIndex(fotos.length);
    } else {
      setFotoIndex(fotoIndex - 1);
    }
    fillImagePreviewer(fotoIndex);
    e.preventDefault();
  }

  useEffect(() => {
    setIsNew(!selectedFotografiaResult?.id)
    if (!selectedFotografiaResult?.id) {
      return;
    }
    const iframe: any = document.getElementById('iframeGeoPortal')
    
    iframe.contentWindow?.postMessage(
      { type: "SiarlFotoAereaLine", value: [selectedFotografiaResult?.id] },
      '*'
  )
    getFotosRegistoVoo(selectedFotografiaResult?.id).then(items => {
      if (items && items.length > 0) {
        setFotos(items);
        if (items[fotoIndex] && items[fotoIndex].latitude && items[fotoIndex].longitude) {
          const latLongMap = proj4(EPSG_GLOBAL, EPSG_MAP, [items[fotoIndex].longitude?? 0,items[fotoIndex].latitude?? 0]);
          const dataToSendLatLong = {
              longitude: latLongMap[0],
              latitude: latLongMap[1],
              id: items[fotoIndex].id
            }
            iframe.contentWindow?.postMessage(
                { type: "SiarlFotoObliquaPlay", value: dataToSendLatLong },
                '*'
            )
          }
          if (items[fotoIndex] && items[fotoIndex].url_externa) {
            setUrlDoDocumento(items[fotoIndex].url_externa);
          }
      }
    });

  }, [selectedFotografiaResult]);

  const entidadeSelected = getEntidadeSelected()

  return (
    <>
      <div className={`${styles.form} ${styles.removeScrollDuplicado}`}>
      {urlDoDocumento ? 
        <fieldset style={{paddingBottom: '20%'}}>
          <legend>
            <span className={styles.fieldsetTitulo}>{selectedFotografiaResult?.descricao ?? 'Fotografia'}</span>
          </legend>
          <div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}>
            <div style={{ height: 500 }}>
              
              <ImagePreview
              documentUrl={urlDoDocumento}
              setFotoIndex={setFotoIndex}
              setUrlDoDocumento={setUrlDoDocumento}
              hideClose={true}
              stop={(e: any) => {stop(e)} }
              play={(e: any) => {play(e)} }
              first={(e: any) => firstPhoto(e)}
              last={(e:any) => lastPhoto(e)}
              fullScreen={''}
              next={(e: any) => nextPhoto(e)}
              previous={(e:any) => previousPhoto(e)}
              fotos={fotos}
              />
              
            </div>
            
          </div>

        </fieldset>
        : '' }
        {!selectedFotografiaResult?.id ? 
        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Upload</span>
          </legend>

          <div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}>
            <div>
              <Field name="arquivo" component={MultipleUpload} disabled={viewMode}/>
            </div>
          </div>
        </fieldset>
        : ''}
        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Registo</span>
          </legend>

          <div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}>
            <div>
              <Field name="id" label="ID" component={Input} disabled />
            </div>
            <div>
              <Field
                name="data_registo"
                label="Data registo"
                component={DateTimePicker}
                format="dd/MM/yyyy HH:mm"
                disabled
              />
            </div>
            <div>
              <Field
                name="tecnicoRegisto"
                label="Técnico"
                component={Input}
                disabled
              />
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend ><span className={styles.fieldsetTitulo}>Identificação</span></legend>
            <div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}>
              <Field name="descricao" label="Descrição" component={Input} disabled={viewMode}/>
            </div>
            <div className={styles.espacoVerticalFieldSet}>
            <div className={styles.fields}>
            <div>
              <Field
                name="data_voo"
                label="Data voo"
                component={DateTimePicker}
                format="dd/MM/yyyy HH:mm"
                disabled={viewMode}
              />
            </div>
              <div><Field name="duracao" label="Duração (minutos)" type="number" component={Input} disabled={viewMode} /></div> 
              <div><Field name="distancia" label="Distancia" type="number" component={Input} disabled={viewMode} /></div> 
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Localização</span>
          </legend>

          <div className={styles.espacoVerticalFieldSet}>
            <div className={styles.fields}>
              {selectedFotografiaResult ? (
                <div>
                  <CheckMoreItem
                    list={selectedFotografiaResult?.nuts1}
                    property={'nome'}
                    label="NUTS I"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="nuts1"
                    label="NUTS I"
                    component={MultiSelect}
                    textField={'nome'}
                    data={nuts1}
                    disabled
                  />
                </div>
              )}
              {selectedFotografiaResult ? (
                <div>
                  <CheckMoreItem
                    list={selectedFotografiaResult?.nuts2}
                    property={'nome'}
                    label="NUTS II"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="nuts2"
                    label="NUTS II"
                    component={MultiSelect}
                    textField={'nome'}
                    data={nuts2}
                    disabled
                  />
                </div>
              )}
              {selectedFotografiaResult ? (
                <div>
                  <CheckMoreItem
                    list={selectedFotografiaResult?.nuts3}
                    property={'nome'}
                    label="NUTS III"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="nuts3"
                    label="NUTS III"
                    component={MultiSelect}
                    textField={'nome'}
                    data={nuts3}
                    disabled
                  />
                </div>
              )}
            </div>

            <div className={styles.fields}>
              {selectedFotografiaResult ? (
                <div>
                  <CheckMoreItem
                    list={selectedFotografiaResult?.distritos}
                    property={'nome'}
                    label="Distrito"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="distrito"
                    label="Distrito"
                    component={MultiSelect}
                    textField={'nome'}
                    data={distritos}
                    disabled
                  />
                </div>
              )}
              {selectedFotografiaResult ? (
                <div>
                  <CheckMoreItem
                    list={selectedFotografiaResult?.concelhos}
                    property={'nome'}
                    label="Concelho"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="concelho"
                    label="Concelho"
                    component={MultiSelect}
                    textField={'nome'}
                    disabled
                    data={concelhos}
                  />
                </div>
              )}
              {selectedFotografiaResult ? (
                <div>
                  <CheckMoreItem
                    list={selectedFotografiaResult?.freguesias}
                    property={'nome'}
                    label="Freguesia"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="freguesia"
                    label="Freguesia"
                    component={MultiSelect}
                    textField={'designacao'}
                    data={freguesiasFiltred}
                    disabled
                  />
                </div>
              )}
            </div>

            <div className={styles.fields}>
              {selectedFotografiaResult ? (
                <div>
                  <CheckMoreItem
                    list={selectedFotografiaResult?.arhs}
                    property={'nome'}
                    label="ARH"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="arh"
                    label="ARH"
                    component={MultiSelect}
                    textField={'nome'}
                    data={arhs}
                    disabled
                  />
                </div>
              )}
              {selectedFotografiaResult ? (
                <div>
                  <CheckMoreItem
                    list={selectedFotografiaResult?.capitanias}
                    property={'nome'}
                    label="Capitania"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="capitania"
                    label="Capitania"
                    component={MultiSelect}
                    textField={'nome'}
                    data={capitania}
                    disabled
                  />
                </div>
              )}
              {selectedFotografiaResult ? (
                <div>
                  <CheckMoreItem
                    list={selectedFotografiaResult?.massasAgua}
                    property={'nome'}
                    label="Massa de água"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="massaAgua"
                    label="Massa de água"
                    component={MultiSelect}
                    textField={'nome'}
                    data={massaagua}
                    disabled
                  />
                </div>
              )}
            </div>
          </div>
          <div>
          </div>
        </fieldset>
        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Comentário</span>
          </legend>

          <div className={styles.espacoVerticalFieldSet}>
            <div className={styles.fields}>
              <Field name="comentario" label="Observações" component={TextArea} disabled={viewMode} />

            </div>
          </div>
        </fieldset>
      </div>
    </>
  )
}
