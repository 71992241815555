import { createBrowserRouter, createHashRouter, RouterProvider, useNavigate } from 'react-router-dom'

import ErrorPage from './ErrorPage'
import Root from './routes/Root'
import Login from './routes/Login'
import Registar from './routes/Registar'
import RegistarOcorrenciaTerreno from './routes/ocorrencia/terreno/Registar'

import './styles/globals.scss'
import './styles/icons/style.scss'
import "@progress/kendo-theme-material/dist/all.scss"
import AuthProvider from './components/auth/AuthProvider'
import RecoverPassword from './routes/RecoverPassword'
import ForgotPassword from './routes/ForgotPassword'

import { IntlProvider, load, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';

import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';

import ptNumbers from 'cldr-numbers-full/main/pt/numbers.json';
import ptLocalCurrency from 'cldr-numbers-full/main/pt/currencies.json';
import ptCaGregorian from 'cldr-dates-full/main/pt/ca-gregorian.json';
import ptDateFields from 'cldr-dates-full/main/pt/dateFields.json';
import TraducaoPT from "./utils/pt.json";

load(
  likelySubtags,
  currencyData,
  weekData,
  ptNumbers,
  ptLocalCurrency,
  ptCaGregorian,
  ptDateFields
);

loadMessages(TraducaoPT, "pt-PT");

const router = createHashRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/login',
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/registar',
    element: <Registar />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/password-recover',
    element: <RecoverPassword/>,
    errorElement: <ErrorPage />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword/>,
    errorElement: <ErrorPage />,
  },
  {
    path: '/ocorrencia/terreno/registar',
    element: <AuthProvider><RegistarOcorrenciaTerreno /></AuthProvider>,
    errorElement: <ErrorPage />,
  },
])

function App() {
  return <LocalizationProvider language="pt-PT"> <IntlProvider locale="pt"> <RouterProvider router={router} /> </IntlProvider> </LocalizationProvider>
}

export default App
