import styles from '../../../styles/ocorrencia/ConsultarOcorrenciaForm.module.scss'
import { Field, FormRenderProps } from '@progress/kendo-react-form'
import { Checkbox, TextArea, RadioButton, RadioGroup, Input } from '@progress/kendo-react-inputs'
import { useState } from 'react'
import { ADOTADA, NAO, NAO_SABE, POR_ADOTAR, SIM } from '../../../utils/constants'
import clsx from 'clsx'
import { opacity } from 'html2canvas/dist/types/css/property-descriptors/opacity'

type IProps = {
  formRenderProps?: FormRenderProps
  viewMode?: boolean
  tipologiaValidator?: boolean

  onCheckboxChangeInstabilidade?: any
  onCheckboxChangeErosao?: any
  onCheckboxChangeGalmento?: any
  onCheckboxChangeProtecao?: any
}
export function OcorrenciaTerrenoDetalhe({ formRenderProps, viewMode, tipologiaValidator, onCheckboxChangeInstabilidade, onCheckboxChangeErosao, onCheckboxChangeGalmento, onCheckboxChangeProtecao }: IProps) {
  // const [instabiladeArribas, setInstabilidadeArribas] = useState(false)
  // const [erosaoRecuo, setErosaoRecuo] = useState(false)
  // const [protecaoDefesa, setProtecaoDefesa] = useState(false)
  // const [outrosDanos, setOutrosDanos] = useState(false)
  // const [tempestade, setTempestade] = useState(false)
  // const [outras, setOutras] = useState(false)
  // const [sistemasNaturais, setSistemasNaturais] = useState<any>()
  // const [pessoaEmRisco, setPessoaEmRisco] = useState<any>()
  // const [medidasMetigacao, setMedidasMetigacao] = useState<any>()

  const keyPressTextArea = (e: any) => {
    if (viewMode) {
      e.preventDefault();
      return false;
    }
  }
  return (
    <div className={`${styles.form} ${styles.removeScrollDuplicado}`}>

      <fieldset>
        <legend>
          <span className={styles.fieldsetTitulo}>Tipologia da ocorrência*</span>
        </legend>

        <div className={`${styles.espacoVerticalFieldSet} ${tipologiaValidator && !viewMode ? 'ValidatorMultiplaCheck' : ''}`}>

          <div className={styles.firstPontosDetalhes}>
            <Field
              name="instabiladeArribas"
              label="Instabilidade em arribas"
              component={Checkbox}
              onChange={onCheckboxChangeInstabilidade}
              disabled={viewMode}
            />
            {formRenderProps?.valueGetter("instabiladeArribas") ? (

              <div className={clsx(styles.subitem, styles.subPontosDetalhes)}>
                <div className={styles.spacingPontos}>
                  <Field
                    name="movimentoMassa"
                    label="Movimento de massa de vertente (mmv)"
                    component={Checkbox}
                    disabled={viewMode}
                  />
                </div>

                {formRenderProps?.valueGetter("movimentoMassa") ? (
                  <div className={styles.spacingPontos}>
                    <Field
                      name="tip_mmv_alcance_deposito"
                      label="Alcance máximo do depósito/detritos (m)"
                      component={Input}
                      type={'number'}
                      onKeyDown={(evt: any) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                      disabled={viewMode}
                      />
                  </div>
                ) : null}

                <div className={styles.spacingPontos}>
                  <Field
                    name="fendaTracao"
                    label="Fenda de tração com abertura visível"
                    component={Checkbox}
                    disabled={viewMode}
                  />
                </div>
              </div>
            ) : null}
          </div>

          <div className={styles.outrosPontosDetalhes}>
            <Field
              name="erosaoRecuo"
              label="Erosão/recuo da linha de costa"
              component={Checkbox}
              onChange={onCheckboxChangeErosao}
              disabled={viewMode}
            />
            {formRenderProps?.valueGetter("erosaoRecuo") ? (
              <div className={clsx(styles.subitem, styles.subPontosDetalhes)}>
                <div className={styles.spacingPontos}>
                  <Field
                    name="escarpamento"
                    label="Escarpamento da duna"
                    component={Checkbox}
                    disabled={viewMode}
                  />
                </div>
                  {formRenderProps?.valueGetter("escarpamento") ? (
                    <div>
                      <Field name="tip_altura_maxima_escarpa" label="Altura máxima de escarpa (m)"
                        component={Input} disabled={viewMode} 
                        type={'number'}
                        onKeyDown={(evt: any) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                        />
                    </div>
                  ) : null}

                  <div className={styles.spacingPontos}>
                    <Field
                      name="rebaixamento"
                      label="Rebaixamento da praia"
                      component={Checkbox}
                      disabled={viewMode}
                    />
                  </div>
                  {formRenderProps?.valueGetter("rebaixamento") ? (
                    <div>
                      <Field name="tip_reb_praia_altura_max" label="Altura máxima (m)"
                        component={Input}  disabled={viewMode} type={'number'}
                        onKeyDown={(evt: any) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                      />
                    </div>
                  ) : null}
              </div>
            ) : null}
          </div>

          <div className={styles.outrosPontosDetalhes}>
            <Field
              name="galgamento"
              label="Galgamento costeiro"
              component={Checkbox}
              onChange={onCheckboxChangeGalmento}
              disabled={viewMode}
            />
            {formRenderProps?.valueGetter("galgamento") ? (
              <div className={clsx(styles.subitem, styles.subPontosDetalhes)}>
                <div className={styles.spacingPontos}>
                  <Field name="tip_galg_cost_alcance_max" label="Alcance máximo de água/detritos (m)"
                    component={Input} type={'number'} 
                    onKeyDown={(evt: any) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                    disabled={viewMode}
                  />
                </div>
              </div>
            ) : null}
          </div>

          <div className={styles.outrosPontosDetalhes}>
            <Field
              name="protecaoDefesa"
              label="Em estruturas de proteção/defesa costeira"
              component={Checkbox}
              onChange={onCheckboxChangeProtecao}
              disabled={viewMode}
              
            />
            {formRenderProps?.valueGetter("protecaoDefesa") ? (
              <div className={clsx(styles.subitem, styles.subPontosDetalhes)}>
                <div className={styles.spacingPontos}>
                  <Field
                    name="estruturaAderente"
                    label="Estrutura aderente"
                    component={Checkbox}
                    disabled={viewMode}
                  />
                </div>

                <div className={styles.spacingPontos}>
                  <Field
                    name="esporao"
                    label="Esporão/Quebra-Mar"
                    component={Checkbox}
                    disabled={viewMode}
                  />
                </div>

                <div className={styles.spacingPontos}>
                  <Field
                    name="protecaoEstabilizacao"
                    label="Estrutura de proteção/estabilização de arriba"
                    component={Checkbox}
                    disabled={viewMode}
                  />
                </div>
              </div>
            ) : null}
          </div>

          {tipologiaValidator && !viewMode ? (
            <div className={styles.outrosPontosDetalhes}>
              <span className='customErrorText'>
                É obrigatório selecionar pelos menos uma das caixas de seleção
              </span>
            </div>
          ) : ''}
        </div>
      </fieldset>

      <fieldset>
        <legend>
          <span className={styles.fieldsetTitulo}>Consequência(s) da ocorrência</span>
        </legend>

        <div className={styles.espacoVerticalFieldSet}>
          <div style={{ marginTop: 24 }}>
            <div className={styles.fieldsetSubTitulo}>
              Pessoas em risco
            </div>

            <div className={styles.firstPontosDetalhes}>
              <Field
                id={'pessoaEmRisco'}
                name={'pessoaEmRisco'}
                layout={'horizontal'}
                component={RadioGroup}
                disabled={viewMode}

                data={[
                  { label: 'Não sabe / Não aplicável', value: NAO_SABE },
                  { label: 'Não', value: NAO },
                  { label: 'Sim', value: SIM },
                ]}
                defaultValue={NAO} // Set the default
              />
            </div>
          </div>

          <div style={{ marginTop: 24 }}>
            <div className={styles.fieldsetSubTitulo}>
              Danos em edificações
            </div>

            <div>
              <div className={styles.firstPontosDetalhes} title="Danos em áreas construídas de fruição/uso público (e.g. passeio pedonal/passadiços/miradouro/zonas de estadia)">
              <Field
                name="fruicaoUsoPublico"
                label="Danos em áreas construídas de fruição/uso público"
                component={Checkbox}
                disabled={viewMode}
              />
              </div>

              <div className={styles.outrosPontosDetalhes} title="Danos em Edificações (e.g. habitações/comerciais/apoios de praia)">
              <Field
                name="danosEdificacoes"
                label="Danos em Edificações"
                component={Checkbox}
                disabled={viewMode}
              />
              </div>

              <div className={styles.outrosPontosDetalhes}>
              <Field
                name="outrosDanos"
                label="Outros Danos"
                component={Checkbox}
                disabled={viewMode}
              />
                {formRenderProps?.valueGetter("outrosDanos") ? (
                  <div className={styles.subPontosDetalhes}>
                    <div className={styles.spacingPontos}>
                      <Field
                        name="outrosDanosDescricao"
                        label="Descrição"
                        component={TextArea} 
                        maxLength={528}  
                        style={{opacity: viewMode ? '0.7' : '1'}}
                        onKeyPress={keyPressTextArea}
                        placeholder="Descrição"
                        className={styles.defaultTextArea}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div style={{ marginTop: 24 }}>
            <div className={styles.fieldsetSubTitulo}>
              Danos em Sistemas Naturais
            </div>

            <div style={{ marginLeft: '12px' }}>
              <Field
                id={'sistemasNaturais'}
                name={'sistemasNaturais'}
                layout={'horizontal'}
                component={RadioGroup}

                data={[
                  { label: 'Não', value: NAO },
                  { label: 'Não sabe / Não aplicável', value: NAO_SABE },
                  { label: 'Sim', value: SIM },
                ]}
                defaultValue={NAO} // Set the default
                disabled={viewMode}
              />
              {formRenderProps?.valueGetter("sistemasNaturais") == SIM ? (
                <div className={styles.spacingPontos}>
                  <Field
                    name="descricaoDanosSistemasNaturais"
                    label="Descrição"
                    component={TextArea}
                    maxLength={528}
                    style={{opacity: viewMode ? '0.7' : '1'}}
                    onKeyPress={keyPressTextArea}
                    placeholder="Descrição"
                    className={styles.defaultTextArea}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset>
        <legend>
          <span className={styles.fieldsetTitulo}>Causa(s) da Ocorrência</span>
        </legend>

        <div className={styles.espacoVerticalFieldSet}>
            <div className={styles.firstPontosDetalhes}>
              <Field
                name="naoSabe"
                label="Não sabe / Não aplicável"
                component={Checkbox}
                disabled={viewMode}
              />
            </div>

            <div className={styles.outrosPontosDetalhes}>
              <Field
                name="tempestade"
                label="Tempestade"
                disabled={viewMode}
                component={Checkbox}
              />
              {formRenderProps?.valueGetter("tempestade") ? (
                <div className={styles.subPontosDetalhes}>
                  <div className={styles.spacingPontos}>
                    <Field
                      name="descricaoTempestade"
                      label="Descrição"
                      component={TextArea}
                      style={{opacity: viewMode ? '0.7' : '1'}}
                      maxLength={528}
                      onKeyPress={keyPressTextArea}
                      className={styles.defaultTextArea}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          
            <div className={styles.outrosPontosDetalhes}>
              <Field
                name="sismos"
                label="Sismos"
                component={Checkbox}
                disabled={viewMode}      
              />
            </div>

            <div className={styles.outrosPontosDetalhes}>
              <Field
                name="outras"
                label="Outras"
                component={Checkbox}
                disabled={viewMode}      
              />
              {formRenderProps?.valueGetter("outras") ? (
                <div className={styles.subPontosDetalhes}>
                  <div className={styles.spacingPontos}>
                    <Field
                      name="descricaoOutras"
                      label="Descrição"
                      component={TextArea}
                      maxLength={528}
                      style={{opacity: viewMode ? '0.7' : '1'}}
                      onKeyPress={keyPressTextArea}
                      placeholder="Descrição"
                      className={styles.defaultTextArea}
                    />
                  </div>
                </div>
              ) : null}
            </div>
        </div>
      </fieldset>

      <fieldset>
        <legend>
          <span className={styles.fieldsetTitulo}>Medidas de Mitigação do Risco</span>
        </legend>

        <div className={styles.espacoVerticalFieldSet}>
          <div style={{ marginLeft: '12px' }}>
            <Field
              id={'medidasMetigacao'}
              name={'medidasMetigacao'}
              layout={'horizontal'}
              component={RadioGroup}

              data={[
                { label: 'Não', value: NAO },
                { label: 'Não sabe / Não aplicável', value: NAO_SABE },
                { label: 'Sim', value: SIM },
              ]}
              defaultValue={NAO} // Set the default
               disabled={viewMode}
            />
            {formRenderProps?.valueGetter("medidasMetigacao") == SIM ? (
              <table className={styles.medidasMetigacao} cellPadding="10">
                <tr>
                  <th></th>
                  <th>Imediata</th>
                  <th>A adotar</th>
                </tr>

                <tr className={styles.item}>
                  <td>Sinalização (aviso/interdição)</td>
                  <td className={styles.alignRadio}>
                    <Field
                      id={'sinalizacao'}
                      name="sinalizacao"
                      layout={'horizontal'}
                      component={RadioGroup}
                      data={[
                        { value: ADOTADA }
                      ]}
                      disabled={viewMode}
                    />
                  </td>

                  <td className={styles.alignRadio}>
                    <Field
                      id={'sinalizacao'}
                      name="sinalizacao"
                      layout={'horizontal'}
                      component={RadioGroup}
                      data={[
                        { value: POR_ADOTAR }
                      ]}
                      disabled={viewMode}
                    />
                  </td>
                </tr>

                <tr className={styles.item}>
                  <td>Delimitação / Interdição da área de risco</td>
                  <td className={styles.alignRadio}>
                    <Field
                      name="delimitacao"
                      id={'delimitacao'}
                      layout={'horizontal'}
                      component={RadioGroup}
                      data={[
                        { value: ADOTADA }
                      ]}
                       disabled={viewMode}
                    />
                  </td>

                  <td className={styles.alignRadio}>
                    <Field
                      name="delimitacao"
                      id={'delimitacao'}
                      layout={'horizontal'}
                      component={RadioGroup}
                      data={[
                        { value: POR_ADOTAR}
                      ]}
                      disabled={viewMode}
                    />
                  </td>
                </tr>

                <tr className={styles.item}>
                  <td>Saneamento de blocos / Reperfilamento</td>
                  <td className={styles.alignRadio}>
                    <Field
                      name="saneamento"
                      id={'saneamento'}
                      layout={'horizontal'}
                      component={RadioGroup}
                      data={[
                        { value: ADOTADA }
                      ]}
                      disabled={viewMode}
                    />
                  </td>

                  <td className={styles.alignRadio}>
                    <Field
                      name="saneamento"
                      id={'saneamento'}
                      layout={'horizontal'}
                      component={RadioGroup}
                      data={[
                        { value: POR_ADOTAR }
                      ]}
                      disabled={viewMode}
                    />
                  </td>
                </tr>

                <tr className={styles.item}>
                  <td>Reabilitação de obra de proteção/estabilização</td>
                  <td className={styles.alignRadio}>
                    <Field
                      name="reabilitacao"
                      id={'reabilitacao'}
                      layout={'horizontal'}
                      component={RadioGroup}
                      data={[
                        { value: ADOTADA }
                      ]}
                      disabled={viewMode}
                    />
                  </td>

                  <td className={styles.alignRadio}>
                    <Field
                      name="reabilitacao"
                      id={'reabilitacao'}
                      layout={'horizontal'}
                      component={RadioGroup}
                      data={[
                        { value: POR_ADOTAR }
                      ]}
                      disabled={viewMode}
                    />
                  </td>
                </tr>

                <tr className={styles.item}>
                  <td>Reforço/Alimentação artificial de emergência</td>
                  <td className={styles.alignRadio}>
                    <Field
                      name="reforco"
                      id={'reforco'}
                      layout={'horizontal'}
                      component={RadioGroup}
                      data={[
                        { value: ADOTADA }
                      ]}
                      disabled={viewMode}
                    />
                  </td>

                  <td className={styles.alignRadio}>
                    <Field
                      name="reforco"
                      id={'reforco'}
                      layout={'horizontal'}
                      component={RadioGroup}
                      data={[
                        { value: POR_ADOTAR }
                      ]}
                      disabled={viewMode}
                    />
                  </td>
                </tr>
              </table>
            ) : null}
          </div>
        </div>
      </fieldset>

      <fieldset>
        <legend>
          <span className={styles.fieldsetTitulo}>Observações</span>
        </legend>

        <div className={styles.espacoVerticalFieldSet}>
          <div style={{ marginLeft: '12px' }}>
            <Field
              name="observacao"
              label="Observação"
              component={TextArea}
              maxLength={528}
              style={{opacity: viewMode ? '0.7' : '1'}}
              onKeyPress={keyPressTextArea}
              placeholder="Observações gerais"
              className={styles.defaultTextArea}
            />
          </div>
        </div>
      </fieldset>
    </div>
  )
}
