import { DateTimePicker } from '@progress/kendo-react-dateinputs'
import { ComboBox, ComboBoxFilterChangeEvent } from '@progress/kendo-react-dropdowns'
import {
  Field
} from '@progress/kendo-react-form'
import { Checkbox, Input, TextArea } from '@progress/kendo-react-inputs'
import {
  MouseEvent as ReactMouseEvent,
  useEffect,
  useState
} from 'react'

import {
  arhs,
  capitania,
  concelhos,
  coordenadasSelected,
  distritos,
  entidades,
  fillArhsAsync,
  fillCapitaniaAsync,
  fillCombosAuxiliares,
  fillConcelhosAsync,
  fillDistritosAsync,
  fillFreguesiasAsync,
  fillNuts1Async,
  fillNuts2Async,
  fillNuts3Async,
  fillPraiasAsync,
  fillTipoEntidades,
  fillTiposProcessoAsync,
  freguesias,
  getUsoSoloSelected,
  nuts1,
  nuts2,
  nuts3,
  praias,
  setusoSoloSelected,
  tipoEntidades,
  useCombos,
  estadosTitulo,
  fillEstadosTituloAsync,
  fillUsTiposProcessoAsync,
  tipoDeProcesso,
  massaagua,
  tipoTitulos,
  TiposUtilizacao
} from '../../../services/utils'
import styles from '../../../styles/usodosolo/RegistarUsoDoSoloForm.module.scss'

import { requiredFieldValidator } from '../../../validations/usoDoSolo'
import ValidatedIdentificacaoInput from '../fields/ValidatedIdentificacaoInput'
import { filterBy, FilterDescriptor } from '@progress/kendo-data-query'
import FieldsetCombosLocalizacao from '../../localizacao/FieldsetCombosLocalizacao'
import { TIPO_USOS_SOLO_SILIAMB } from '../../../utils/constants'


type IProps = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined,
  viewMode: boolean
}

export default function Identificacao({ onCloseClick, viewMode }: IProps) {
  const [freguesiasFiltered, setFreguesiasFiltered] = useState<any>([])
  const [concelhosFiltered, setConcelhosFiltered] = useState<any>([])
  const [nuts2Filtered, setNuts2Filtered] = useState<any>([])
  const [nuts3Filtered, setNuts3Filtered] = useState<any>([])
  const [tipoProcessoData, setTipoProcessoData] = useState<any>();
  const [tipoUtilizacaoData, setTipoUtilizacaoData] = useState<any>();
  const [tipoTituloData, setTipoTituloData] = useState<any>();

     useEffect(() => {
        
    if(!nuts1 ){
      fillNuts1Async();
      }
     if(!nuts2) {
      fillNuts2Async();
     }
     if(!nuts3){
      fillNuts3Async();
     }
     if(!distritos){
      fillDistritosAsync();
     }
     if(!concelhos){
      fillConcelhosAsync();
     }
     if(!freguesias){
      fillFreguesiasAsync();
     }
     if(!arhs){
      fillArhsAsync();
     }
     if(!capitania){
      fillCapitaniaAsync();
     }
     if(!praias){
      fillPraiasAsync();
     }
     if(!tipoEntidades){
      fillTipoEntidades();
     }
     if(!tipoDeProcesso){
      fillUsTiposProcessoAsync();
     }
     if(!estadosTitulo){
      fillEstadosTituloAsync();
     }

     setTipoProcessoData(tipoDeProcesso.slice());
     setTipoUtilizacaoData(TiposUtilizacao.slice());
     setTipoTituloData(tipoTitulos.slice())
    
  }, []);

  const changeFileDocCod = (e: any) => {
    setusoSoloSelected({...getUsoSoloSelected(), filedoc_cod: e?.value})
  }

  const changeDesignacao = (e: any) => {
    setusoSoloSelected({...getUsoSoloSelected(), designacao: e?.value})
  }
  
  const changeDataVigor = (e: any) => {
    setusoSoloSelected({...getUsoSoloSelected(), dataVigor: e?.value});
  }

  const changeDataExpiracao = (e: any) => {
    setusoSoloSelected({...getUsoSoloSelected(), dataExpiracao: e?.value});
  }

  const changeDataAlteracao = (e: any) => {
    setusoSoloSelected({...getUsoSoloSelected(), dataAlteracao: e?.value});
  }

  const changeTiposProcesso = (e: any) => {
    setusoSoloSelected({...getUsoSoloSelected(), tipoDeProcesso: e?.value})
  }

  const changCodProcesso = (e: any) => {
    setusoSoloSelected({...getUsoSoloSelected(), codProcesso: e?.value})
  }

  const changeCodUtilizacao = (e: any) => {
    setusoSoloSelected({...getUsoSoloSelected(), codUtilizacao: e?.value});
  }

  const changeCategoria = (e: any) => {
    setusoSoloSelected({...getUsoSoloSelected(), categoria: e?.value});
  }

  const changeEstadoId = (e:any) => {
    setusoSoloSelected({...getUsoSoloSelected(), estado_id: e?.value});
  }

  const PermitirNumeros = (e: React.ChangeEvent<HTMLInputElement>) => {
    const ApenasNumeros = e.target.value.replace(/\D/g, '');
    e.target.value = ApenasNumeros;
  };

  const changeNifRequerente = (e: any) => {
    setusoSoloSelected({...getUsoSoloSelected(), nifRequerente: e?.value});
  }

  const changeNomeRequerente = (e :any) => {
    setusoSoloSelected({...getUsoSoloSelected(), nomeRequerente: e?.value});
  }

  const changeDataEmissao = (e:any) => {
    setusoSoloSelected({...getUsoSoloSelected(), dataEmissao: e?.value});
  }

  const changeUrlTitulo = (e:any) => {
    setusoSoloSelected({...getUsoSoloSelected(), urlTitulo : e?.value});
  }

  const changeObs = (e: any) => {
    setusoSoloSelected({...getUsoSoloSelected(), obs: e?.value});
  }

  const filterChangeUtilizacao = (event: ComboBoxFilterChangeEvent) => {
    const data = TiposUtilizacao.slice();
    setTipoUtilizacaoData(filterBy(data, event.filter));
  };

  const filterChangeProcesso = (event: ComboBoxFilterChangeEvent) => {
    const data = tipoDeProcesso.slice();
    setTipoProcessoData(filterBy(data, event.filter));
  };

  const filterChangeTipoTitulo = (event: ComboBoxFilterChangeEvent) => {
    const data = tipoTitulos.slice();
    setTipoTituloData(filterBy(data, event.filter));
  };

  return (
    <div className={`${styles.form} ${styles.removeScrollDuplicado}`}>


      <span style={{ marginLeft: '08px' }}>Os campos com simbolo * (asterisco) são de preenchimento obrigatório</span>

      <fieldset>
        <legend>
          <span className={styles.fieldsetTitulo}>Registo</span>
        </legend>

        <div className={styles.espacoVerticalFieldSet}>

          <div className={styles.fields} style={{marginBottom:20}}>

            <div>
              <Field name="titulo" checked={getUsoSoloSelected()?.tipo_registo != TIPO_USOS_SOLO_SILIAMB} disabled label="Registo Manual" component={Checkbox} />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Field name="SILIAMB" checked={getUsoSoloSelected()?.tipo_registo == TIPO_USOS_SOLO_SILIAMB} disabled label="SILIAMB" component={Checkbox} />
            </div>

          </div>
          <div className={styles.fields}>
            <Field
              name="titulo"
              label="Titulo"
              component={Checkbox}
              disabled
            />
          </div>
          <div className={styles.fields}>

            <div>
              <Field name="id" disabled={true} label="ID" component={Input} />
            </div>
            <div>
              <Field
                name="filedoc_cod"
                label="Nº Processo APA"
                component={Input}
                disabled={viewMode}
                onChange={changeFileDocCod}
              />
            </div>
            <div>
              <Field
                name="idProcesso"
                label="ID Processo"
                component={Input}
                disabled={viewMode}
              />
            </div>
          </div>

          <div className={styles.fields}>

            <div>
              <Field
                name="dataVigor"
                label="Data entrada em Vigor"
                format="dd/MM/yyyy HH:mm"
                component={DateTimePicker}
                onChange={changeDataVigor}
                disabled={viewMode}
              />
            </div>

            <div>
              <Field
                name="dataExpiracao"
                label="Data de Expiração"
                format="dd/MM/yyyy HH:mm"
                component={DateTimePicker}
                onChange={changeDataExpiracao}
                disabled={viewMode}
              />
            </div>

            <div>
              <Field
                name="dataAlteracao"
                label="Data alteração licenciamento"
                format="dd/MM/yyyy HH:mm"
                component={DateTimePicker}
                onChange={changeDataAlteracao}
                disabled={viewMode}
              />
            </div>

          </div>

        </div>
      </fieldset>


       <fieldset>

        <legend>
          <span className={styles.fieldsetTitulo}>Identificação</span>
        </legend>

        <div className={styles.espacoVerticalFieldSet}>

          <div className={styles.fields}>
            <div>
              <Field
                name="tecnicoRegisto"
                label="Técnico de registo"
                component={Input}
                disabled={true}
              />
            </div>
            <div>
              <Field
                name="dataHoraRegisto"
                label="Data/hora registo"
                format="dd/MM/yyyy HH:mm"
                component={DateTimePicker}
                disabled={true}
              />
            </div>
          </div>

          <div className={styles.fields}>
            <Field
              name="designacao"
              label="Designação *"
              component={ValidatedIdentificacaoInput}
              validator={requiredFieldValidator}
              onChange={changeDesignacao}
              disabled={viewMode}
            />
          </div>

        </div>
      </fieldset>
      
      <fieldset>
        <legend>
          <span className={styles.fieldsetTitulo}>Localização</span>
        </legend>

        <div className={styles.espacoVerticalFieldSet}>
          <div className={styles.fieldsetSubTitulo}>
          </div>

          <div className={styles.fields}>
            <div>
              <Field
                name="nuts1"
                label="NUTS I"
                disabled
                component={ComboBox}
                textField={'nome'}
                data={nuts1}
              />
            </div>
            <div>
              <Field
                name="nuts2"
                label="NUTS II"
                disabled
                component={ComboBox}
                textField={'nome'}
                data={nuts2}
              />
            </div>
            <div>
              <Field
                name="nuts3"
                label="NUTS III"
                disabled
                component={ComboBox}
                textField={'nome'}
                data={nuts3}
              />
            </div>
          </div>

          <div className={styles.fields}>
            <div>
              <Field
                name="distrito"
                label="Distrito"
                disabled
                component={ComboBox}
                textField={'nome'}
                data={distritos}
              />
            </div>
            <div>
              <Field
                name="concelho"
                label="Concelho"
                disabled
                component={ComboBox}
                textField={'nome'}
                data={concelhos}
              />
            </div>
            <div>
              <Field
                name="freguesia"
                label="Freguesia"
                textField={'nome'}
                data={freguesias}
                disabled
                component={ComboBox}
              />
            </div>
          </div>

          <div className={styles.fields}>
            <div>
              <Field
                name="arh"
                label="ARH"
                component={ComboBox}
                textField={'nome'}
                data={arhs}
                disabled
              />
            </div>
            <div>
              <Field
                name="capitania"
                label="Capitania"
                component={ComboBox}
                textField={'nome'}
                data={capitania}
                disabled
              />
            </div>
            <div>
              <Field
                name="massaagua"
                label="Massa de água"
                component={ComboBox}
                textField={'nome'}
                data={massaagua}
                filterable={true}
                disabled

              />
            </div>
          </div>
          <div className={styles.fields}>
            <div>
              <Field
                name="praia"
                label="Praia (se aplicável)"
                component={ComboBox}
                textField={'nome'}
                data={praias}
                filterable={true}
                disabled={viewMode}

              />
            </div>
            {/* <div>
              <Field name="ref_localizacao" label="Local" component={Input}  disabled={viewMode }/>
            </div> */}
          </div>
        </div>
      </fieldset>
     

      <fieldset>

        <legend>
          <span className={styles.fieldsetTitulo}>Tipologia</span>
        </legend>

        <div className={styles.espacoVerticalFieldSet}>

          <div className={styles.fields}>
            <div>
              <Field
                name="tipoDeProcesso"
                label="Tipo de processo"
                component={ComboBox}
                filterable={true}
                textField={'nome'}
                onFilterChange={filterChangeProcesso}
                data={tipoProcessoData}
                disabled={viewMode}
              />
            </div>
            <div>
              <Field
                name="codProcesso"
                label="Cód. Processo"
                component={Input}
                onChange={changCodProcesso}
                disabled={viewMode}
              />
            </div>
            
          </div>

          <div className={styles.fields}>
            <div>
              <Field
                name="tipoUtilizacao"
                label="Tipo de utilização"
                component={ComboBox}
                data={tipoUtilizacaoData}
                onFilterChange={filterChangeUtilizacao}
                textField={'nome'}
                filterable={true}
                disabled={viewMode}
              />
            </div>
            <div>
              <Field
                name="codUtilizacao"
                label="Cód. Utilização"
                onFilterChange={changeCodUtilizacao}
                component={Input}
                disabled={viewMode}
              />
            </div>
          </div>

          <div className={styles.fields}>
          <div>
              <Field
                name="tipoTitulo"
                label="Tipo de Título"
                component={ComboBox}
                data={tipoTituloData}
                filterable={true}
                onFilterChange={filterChangeTipoTitulo}
                textField={'descricao'}
                disabled={viewMode}
              />
            </div>
            <div>
              <Field
                name="categoria"
                label="Categoria"
                component={Input}
                onChange={changeCategoria}
                disabled={viewMode}
              />
            </div>
          </div>

        </div>
      </fieldset>

      <fieldset>
        <legend>
          <span className={styles.fieldsetTitulo}>Requerente</span>
        </legend>

        <div className={`${styles.espacoVerticalFieldSet}`}>
          <div className={`${styles.fields} `}>
            <div>
              <Field
                name="nifRequerente"
                label="NIF do requerente"
                component={Input}
                disabled={viewMode}
                onInput={PermitirNumeros}
                onChange={changeNifRequerente}
                maxLength={9}
              />
            </div>

            <div>
              <Field
                name="nomeRequerente"
                label="Nome do requerente"
                component={Input}
                onChange={changeNomeRequerente}
                disabled={viewMode}
              />
            </div>
          </div>

          <div className={`${styles.fields} `}>
            <div>
              <Field
                name="dataEmissao"
                label="Data/hora Emissão"
                format="dd/MM/yyyy HH:mm"
                component={DateTimePicker}
                onChange={changeDataEmissao}
                disabled={viewMode}
              />
            </div>

            <div>
            <Field
              name="estado_id"
              label="Estado"
              disabled={viewMode}
              component={ComboBox}
              onChange={changeEstadoId}
              textField={'descricao'}
              filterable={true}
              data={estadosTitulo}
            />
            </div>
          </div>
          
          <div className={styles.fields}>
            <Field
              name="urlTitulo"
              label="URL do Titulo"
              component={Input}
              onChange={changeUrlTitulo}
              disabled={viewMode}
            />
          </div>
        </div>
      </fieldset>

      <fieldset>

        <legend>
          <span className={styles.fieldsetTitulo}>Observações</span>
        </legend>

        <div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}>
          <Field
            name="obs"
            label="Observações"
            component={TextArea}
            onChange={changeObs}
            textField={'nome'}
            disabled={viewMode}
          />
        </div>

      </fieldset>


    </div>
  )
}
