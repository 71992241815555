import { create } from 'zustand'

type EntidadeState = {

  selectedEntidadeResultId?: number
  selectEntidadeResultId: (payload?: number) => void

  selectedEntidadeResult?: unknown
  selectEntidadeResult: (payload: unknown) => void
  isIdentificacaoTabValid?: boolean
  isDocumentosTabValid?: boolean
  isAssociacaoTabValid?: boolean
  setIsIdentificacaoTabValid: (payload: boolean) => void
  setIsDocumentosTabValid: (payload: boolean) => void
  setIsAssociacaoTabValid: (payload: boolean) => void
  identificacaoTabValidationObject: Record<string, boolean>
  addIdentificacaoTabValidatedField: (payload: Record<string, boolean>) => void
  documentoTabValidationObject: Record<string, boolean>
  addDocumentoTabValidatedField: (payload: Record<string, boolean>) => void
  associacaoTabValidationObject: Record<string, boolean>
  addAssociacaoTabValidatedField: (payload: Record<string, boolean>) => void
}

export const useEntidadeStore = create<EntidadeState>((set, get) => ({
  selectedEntidadeResult: null,
  selectEntidadeResult: (payload: unknown) =>
    set({ selectedEntidadeResult: payload }),

    selectEntidadeResultId: (payload?: number) =>
    set({ selectedEntidadeResultId: payload }),
    
  isIdentificacaoTabValid: false,
  isDocumentosTabValid: true,
  isAssociacaoTabValid: true,
  identificacaoTabValidationObject: {},
  documentoTabValidationObject: {},
  associacaoTabValidationObject: {},
  setIsIdentificacaoTabValid: (payload: boolean) =>
    set({ isIdentificacaoTabValid: payload }),
  setIsDocumentosTabValid: (payload: boolean) =>
    set({ isDocumentosTabValid: payload }),
  setIsAssociacaoTabValid: (payload: boolean) =>
    set({ isAssociacaoTabValid: payload }),
  addIdentificacaoTabValidatedField: (payload) => {
    const validationObj = get().identificacaoTabValidationObject
    const newValidationObj = { ...validationObj, ...payload }
    set({ identificacaoTabValidationObject: newValidationObj })
    const validationValues = Object.values(newValidationObj)
    set({isIdentificacaoTabValid: validationValues.every(value => value === true)})
  },
  addDocumentoTabValidatedField: (payload) => {
    const validationObj = get().documentoTabValidationObject
    const newValidationObj = { ...validationObj, ...payload }
    set({ documentoTabValidationObject: newValidationObj })
    const validationValues = Object.values(newValidationObj)
    set({isDocumentosTabValid: validationValues.every(value => value === true)})
  },
  addAssociacaoTabValidatedField: (payload) => {
    const validationObj = get().associacaoTabValidationObject
    const newValidationObj = { ...validationObj, ...payload }
    set({ associacaoTabValidationObject: newValidationObj })
    const validationValues = Object.values(newValidationObj)
    set({isAssociacaoTabValid: validationValues.every(value => value === true)})
  },
}))
