import { create } from 'zustand'

type PatrimonioState = {
    selectedPatrimonioResult?: unknown
    selectPatrimonioResult: (payload: unknown) => void
    selectedPatrimonioResultId?: number
    selectPatrimonioResultId: (payload?: number) => void
    identificacaoTabIsValid: boolean
    selectPatrimonioIdentificacaoTabIsValid: (payload: unknown) => void
     identificacaoTabValidationObject: Record<string, boolean>
    addIdentificacaoTabValidatedField: (payload: Record<string, boolean>) => void
   
}

export const usePatrimonioStore = create<PatrimonioState>((set, get) => ({
    selectedPatrimonioResult: null,
    identificacaoTabIsValid: false,
    identificacaoTabValidationObject: {},
    selectPatrimonioResult: (payload: unknown) =>
        set({ selectedPatrimonioResult: payload }),
    selectPatrimonioResultId: (payload?: number) =>
        set({ selectedPatrimonioResultId: payload }),
    selectPatrimonioIdentificacaoTabIsValid: (payload?: any) =>
        set({ identificacaoTabIsValid: payload }),
    addIdentificacaoTabValidatedField: (payload) => {
        const validationObj = get().identificacaoTabValidationObject
        const newValidationObj = { ...validationObj, ...payload }
        set({ identificacaoTabValidationObject: newValidationObj })
        const validationValues = Object.values(newValidationObj)
        set({ identificacaoTabIsValid: validationValues.every(value => value === true) })
    }
}))
