import {
  useEffect,
  useState
} from 'react'


import { DateTimePicker } from '@progress/kendo-react-dateinputs'
import { ComboBox } from '@progress/kendo-react-dropdowns'
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form'
import { Input } from '@progress/kendo-react-inputs'
import { fillFontesDadosVusAsync, fillTiposVusAsync, fillVusAsync, fontesDadosVus, tiposVus, useCombos, vus } from '../../services/utils'
import { useValorUsoDoSoloStore } from '../../stores/valorUsoDoSolo'
import styles from '../../styles/usodosolo/UsoDoSolo.module.scss'


type PageState = {
  skip: number
  take: number
}

const initialDataState: PageState = { skip: 0, take: 10 }


export default function DetalheValorUsoDoSolo() {

  const [selected, setSelected] = useState<number>(0)
  const [page, setPage] = useState<PageState>(initialDataState)
  const [pageSizeValue, setPageSizeValue] = useState<
    number | string | undefined
  >()
  const [concelhosFiltred, setConcelhosFiltred] = useState<any>()
  const [concelhosDisabled, setConcelhosDisabled] = useState<any>(true)
  const [freguesiasDisabled, setFreguesiasDisabled] = useState<any>(true)
  const [nuts2Filtered, setNuts2Filtered] = useState<any>()
  const [nuts3Filtered, setNuts3Filtered] = useState<any>()
  const [nuts2Disabled, setNuts2Disabled] = useState<any>(true)
  const [nuts3Disabled, setNuts3Disabled] = useState<any>(true)
  const [freguesiasFiltred, setFreguesiasFiltred] = useState<any>()

useEffect(() => {
    
     if(!vus){
      fillVusAsync();
      }
     if(!tiposVus) {
      fillTiposVusAsync();
     }
     if(!fontesDadosVus){
      fillFontesDadosVusAsync();
     }
    
  }, []);

  const selectedValorUsoDoSolo: any = useValorUsoDoSoloStore((state) => state.selectedValorUsoDoSoloResult)

  const fieldsRenderByType: Record<string, JSX.Element> =
  {
    "COS": <> <div>
      <Field name="classes" label="Classes" disabled component={Input} />
    </div></>,
    "EcoSistema": <>
      <div>
        <Field name="estadoConservacao" label="Estado de conservação" component={Input} />
      </div>
      <div>
        <Field name="usTipoVus" label="Tipo de uso" textfield={"descricao"} component={ComboBox} data={tiposVus} />
      </div>
      <div>
        <Field name="atividade" label="Atividade" component={Input} />
      </div>
    </>,
    "Urbanas": <>
      <div>
        <Field name="estadoConservacao" label="Estado de conservação" component={Input} />
      </div>
      <div>
        <Field name="tipoOcupacao" label="Tipo de ocupação" component={Input} />
      </div>
      <div>
        <Field name="servicoPrestado" label="Serviço prestado" component={Input} />
      </div></>,
  }

  return (<>
    <Form initialValues={selectedValorUsoDoSolo ?? undefined}
      render={(formRenderProps: FormRenderProps) => (
        <>
          <FormElement className={styles.form}>
            <fieldset>
              <legend
              >
                <span className={styles.fieldsetTitulo}>Registo</span>{' '}
              </legend>
              <div className={styles.fields}
              >
                <Field
                  name="id"
                  label="ID Documento"
                  component={Input}
                  disabled
                />
              </div>
            </fieldset>
            <fieldset>
              <div className={styles.fields}>
                <div>
                  <Field
                    name="usVus"
                    label="VUS"
                    component={ComboBox}
                    textField={'descricao'}
                    data={vus}
                    disabled
                  />
                </div>
                <div>
                  <Field
                    name="usTipoVus"
                    label="Tipo"
                    component={ComboBox}
                    textField={'descricao'}
                    disabled
                    data={tiposVus}
                  />
                </div>
              </div>
              <div className={styles.fields}>
                <div>
                  <Field name="valor" label="Valor" disabled component={Input} />
                </div>
                <div>
                  <Field name="vus.data_registo" label="Data/Hora valor" disabled component={DateTimePicker} />
                </div>
              </div>
              <div className={styles.fields}>
                {fieldsRenderByType[selectedValorUsoDoSolo?.usTipoVus?.id ?? "COS"]}
              </div>
              <div className={styles.fields}>
                <div>
                  <Field name="presencaRisco" label="Presença de Risco" disabled component={Input} />
                </div>
              </div>
              <div className={styles.fields}>
                <Field name="usFonteDadosVus" label="Fonte dos dados de base" textField={"descricao"} disabled component={ComboBox} data={fontesDadosVus} />
              </div>
            </fieldset>
          </FormElement>
        </>
      )} />


  </>);
}