import { DatePicker, DateTimePicker, MultiViewCalendar, MultiViewCalendarProps } from '@progress/kendo-react-dateinputs'
import { ComboBox,MultiSelect } from '@progress/kendo-react-dropdowns'
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form'
import {
  Checkbox,
  Input,
  RadioGroup,
  TextArea
} from '@progress/kendo-react-inputs'
import { MouseEvent as ReactMouseEvent, useEffect, useState } from 'react'

import {
  acaoEstado,
  acaoPlaneamento1,
  acaoPlaneamento2,
  acaoPlaneamento3,
  acaoPlaneamento4,
  arhs,
  capitania,
  codigoCandidatura,
  codigoIntervencao,
  componenteAcao,
  concelhos,
  designacaoIntervencao,
  distritos,
  entidades,
  entidadeUser,
  fillAcaoPlaneamento1Async,
  fillAcaoPlaneamento2Async,
  fillAcaoPlaneamento3Async,
  fillAcaoPlaneamento4Async,
  fillArhsAsync,
  fillCapitaniaAsync,
  fillCodigoCandidaturaAsync,
  fillCodigoIntervencaoAsync,
  fillCombosAuxiliares,
  fillComponenteAcaoAsync,
  fillConcelhosAsync,
  fillDesignacaoIntervencaoAsync,
  fillDistritosAsync,
  fillEntidadesAsync,
  fillFreguesiasAsync,
  fillMassaAguaAsync,
  fillNaturezaAcaoAsync,
  fillNuts1Async,
  fillNuts2Async,
  fillNuts3Async,
  fillPrioridadeAsync,
  fillProcContratacaoAsync,
  fillTipoEngenhariaAsync,
  fillTipoPlanemanetoAsync,
  freguesias,
  get,
  getAcaoSelected,
  hasPermission,
  intIndicador,
  massaagua,
  naturezaAcao,
  nuts1,
  nuts2,
  nuts3,
  post,
  praias,
  prioridade,
  procContratacao,
  put,
  setAcaoSelected,
  tipoEngenharia,
  tipoPlaneamento,
  useCombos
} from '../../services/utils'
import styles from '../../styles/intervencao/Intervencao.module.scss'
import { Dialog } from '@progress/kendo-react-dialogs'

import { Button } from '@progress/kendo-react-buttons'
import { useAcaoStore } from '../../stores/acao'
import { CONSULTAR_CANDIDATURA, CONSULTAR_CANDIDATURA_ACOES, GET_INT_ACAO_INDICADORES, GET_INTERVENCAO_ACAO_BY_ID_ACAO, IMPORT_GEOM_ACAO, PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO, PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA, PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO, PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_LEITURA, PERMISSION_INTERVENCAO_PERFIL_TOTAL, PESQUISAR_ACAO, PESQUISAR_ENTIDADEBYUSER, PESQUISAR_INTERVENCOES_ACAO, SAVE_NEW_ACAO, UPDATE_ACAO } from '../../utils/constants'
import ImportarGeometriaComponent from '../apoiogestao/registar/ImportarGeometriaForm'
import CheckMoreItem from '../documentos/registar/fields/CheckMoreItem'
import AssociacaoEntidadeIntervencao from './AssociacaoEntidade'
import { filterBy } from '@progress/kendo-data-query'
import { faInfoCircle, faRemove } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, GridColumn } from '@progress/kendo-react-grid'
import { v4 as uuidv4 } from 'uuid';
import SessionStorageService from '../../services/SessionStorageService'
import PesquisarAcao from './PesquisarAcao'


type IProps = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
  formRenderProps: FormRenderProps
  viewMode?: boolean
}

interface FormData {
  id?: any;
  id_interv?: any;
  designacao?: any;
  codigo?: any;
  intEstado?: any;
  id_int_instrumento_planeamento?: string;
  id_int_palXXI_tip_nivel1?: string;
  id_int_palXXI_tip_nivel2?: string;
  id_int_palXXI_tip_nivel3?: string;
  numero_intervencao?: string;
  id_prioridade?: string;
  percentagem?: any;
}

interface FormDataAssociacaoCandidatura {
  id?: any;
  id_candidatura?: any;
  intInstrumentoFinanciamento?: any;
  codigo_comunitario?: any;
  intCandidaturaEstado?: any;
  valor_comunitario?: any;
  valor_nacional?: any;
  inv_previsto?: any;
}




export default function IdentificacaoAcao({ onCloseClick, formRenderProps, viewMode }: IProps) {
  const [openImportModal, setOpenImportModal] = useState<any>()
  const [concelhosFiltred, setConcelhosFiltred] = useState<any>(undefined)
  const [concelhosDisabled, setConcelhosDisabled] = useState<any>(true)
  const [freguesiasFiltred, setFreguesiasFiltred] = useState<any>(undefined)
  const [freguesiasDisabled, setFreguesiasDisabled] = useState<any>(true)
  const [nuts2Filtered, setNuts2Filtered] = useState<any>(undefined)
  const [nuts2Disabled, setNuts2Disabled] = useState<any>(true)
  const [nuts3Filtered, setNuts3Filtered] = useState<any>(undefined)
  const [nuts3Disabled, setNuts3Disabled] = useState<any>(true)
  const [findIntervencao, setFindIntervencao] = useState<any>(false)
  const [findCandidatura, setFindCandidatura] = useState<any>(false)
  
  const [formData, setFormData] = useState<FormData>({});
  const [gridData, setGridData] = useState<FormData[]>([]);
  const [gridDataCandidaturas, setGridDataCandidatura] = useState<FormDataAssociacaoCandidatura[]>([]);
  const [formDataCandidaturas, setFormDataCandidaturas] = useState<FormDataAssociacaoCandidatura>({});

  const [tipoN1, setTipoN1] = useState<any>('');
  const [tipoN2, setTipoN2] = useState<any>('');
  const [tipoN3, setTipoN3] = useState<any>('');
  const [valContrat, setValContrat] = useState(0);
  const [revisaoPrecos, setRevisaoPrecos] = useState(0);

  const [openModalDescartarIndicador, setOpenModalDescartarIndicador] = useState<any>()
  const [openModalDescartarAssociacaoCandidatura, setOpenModalDescartarAssociacaoCandidatura] = useState<any>()
  const [idInterv, setIdInterv] = useState<any>();
  const [designacao, setDesignacao] = useState<any>();
  const [codigo, setCodigo] = useState<any>();
  
  const [gridDataIndicadores, setGridDataIndicadores] = useState<FormData[]>([]);

  const getIntervencaoAcao = async (data: any) =>
    await post<any, any>(`${GET_INTERVENCAO_ACAO_BY_ID_ACAO}`, data)

  const getIndicadores = async (data: any) =>
    await post<any, any>(`${GET_INT_ACAO_INDICADORES}`, data)

  const handleValContratChange = (event : any) => {
    const value = parseFloat(event.target.value) || 0;
    SessionStorageService.setItem("valor_contratado" , value)
    setValContrat(value);
  };

  const handleValRevisaoPrecosChange = (event : any) => {
    const value = parseFloat(event.target.value) || 0;
    SessionStorageService.setItem("revisao_precos" , value)
    setRevisaoPrecos(value);
  };

  
  const selectedAcaoResult: any = useAcaoStore(
    (state) => state.selectedAcaoResult
  )
  const selectedAcaoResultId = useAcaoStore(
    (state) => state.selectedAcaoResultId
  )

  const getEntidadeByUser = async (data: any) =>
    await get<any, any>(`${PESQUISAR_ENTIDADEBYUSER}/` + data)
  
    useEffect(() => {
    
     if(!nuts1 ){
      fillNuts1Async();
      }
     if(!nuts2) {
      fillNuts2Async();
     }
     if(!nuts3){
      fillNuts3Async();
     }
     if(!distritos){
      fillDistritosAsync();
     }
     if(!concelhos){
      fillConcelhosAsync();
     }
     if(!freguesias){
      fillFreguesiasAsync();
     }
     if(!arhs){
      fillArhsAsync();
     }
     if(!capitania){
      fillCapitaniaAsync();
     }
     if(!massaagua){
      fillMassaAguaAsync();
     }
     if(!acaoPlaneamento1){
      fillAcaoPlaneamento1Async();
     }
     if(!acaoPlaneamento2){
      fillAcaoPlaneamento2Async();
     }
     if(!acaoPlaneamento3){
      fillAcaoPlaneamento3Async();
     }
     if(!acaoPlaneamento4){
      fillAcaoPlaneamento4Async();
     }
     if(!tipoEngenharia){
      fillTipoEngenhariaAsync();
     }
     if(!naturezaAcao){
      fillNaturezaAcaoAsync();
     }

      if(!componenteAcao){
      fillComponenteAcaoAsync();
     }
      if(!tipoPlaneamento){
      fillTipoPlanemanetoAsync();
     }
     if(!prioridade){
      fillPrioridadeAsync();
     }
     if(!entidades){
      fillEntidadesAsync();
     }
     if(!designacaoIntervencao){
      fillDesignacaoIntervencaoAsync();
     }
     if(!codigoIntervencao){
      fillCodigoIntervencaoAsync();
     }
     if(!codigoCandidatura){
      fillCodigoCandidaturaAsync();
     }
     if(!procContratacao){
      fillProcContratacaoAsync();
     }

     if(getAcaoSelected()?.utilizador) {
      getEntidadeByUser(getAcaoSelected()?.utilizador?.id).then(resultEntidade => {
        formRenderProps.onChange('entidade_registo', { value: resultEntidade?.nome });
      });
    }else{
      formRenderProps.onChange('entidade_registo', { value: entidadeUser });

    }
     

  }, []);
  
  useEffect(() => {
    SessionStorageService.setItem("dataIntervencaoAcao", gridData);
    SessionStorageService.setItem("dataIndicadores", gridDataIndicadores);

  });

  useEffect(() => {
    if (getAcaoSelected()?.id && getAcaoSelected()?.praia) {
      formRenderProps.onChange('praia', { value: getAcaoSelected()?.praia }) 
    }
  }, [getAcaoSelected()?.praia]);

  useEffect(() => {
    if(selectedAcaoResult?.id){
      const fetchData = async () => {
        const objToSearch = {
          id_acao: selectedAcaoResult?.id,
        };
      
        const dataCandidaturaAcao = await pesquisarCandidaturaAcoes(objToSearch);

        const objtToSearch2 = {
          id : dataCandidaturaAcao[0]?.id_candida
        }

        if(objtToSearch2?.id != undefined){
        const dataCandidatura = await pesquisarCandidatura(objtToSearch2)
        setGridDataCandidatura(dataCandidatura);
      }
      }
      fetchData();

    }
  },[selectedAcaoResult?.id]);

  let data : any;
  useEffect(() => {
    if(selectedAcaoResult?.id){
    const fetchData = async () => {
       data = await getIntervencaoAcao(selectedAcaoResult?.id);
      setGridData(data);
      
      if (data.length > 0) {
        const lastIndex = data.length - 1;
        const lastItem = data[lastIndex];

        formRenderProps.onChange('id_tipo_n1', { value: lastItem?.id_int_palXXI_tip_nivel1 });
        formRenderProps.onChange('id_tipo_n2', { value: lastItem.id_int_palXXI_tip_nivel2 });
        formRenderProps.onChange('id_tipo_n3', { value: lastItem.id_int_palXXI_tip_nivel3 });
      }
      const dataIndicadores = await getIndicadores(selectedAcaoResult?.id);
      setGridDataIndicadores(dataIndicadores);

    };
    fetchData();
    
  }
  
  },[data]);

  
  let lstAcaoExecucao : any;
  let val_contratado : any
  useEffect(() => {
    lstAcaoExecucao = SessionStorageService.getItem("dataAcaoExecucao");
    const revisaoPrecos = parseFloat(SessionStorageService.getItem("revisao_precos")) || 0;
  
    if (lstAcaoExecucao?.length > 0 && selectedAcaoResult?.id) {
      const inv_realizado = lstAcaoExecucao?.reduce((sum : any, item : any) => sum + (parseFloat(item?.valor_total) || 0), 0) + revisaoPrecos;
      formRenderProps.onChange('inv_realizado', { value: inv_realizado });
  
       val_contratado = parseFloat(SessionStorageService.getItem("valor_contratado")) || 0;
      
      const valor_por_executar = (val_contratado - inv_realizado) > 0 ? (val_contratado - inv_realizado) : 0;
      const taxa_execucao = (val_contratado - inv_realizado) > 0 ? (inv_realizado / val_contratado) * 100 : 100;
  
      formRenderProps.onChange('valor_por_executar', { value: valor_por_executar });
      formRenderProps.onChange('taxa_execucao', { value: taxa_execucao });
    }
  },[revisaoPrecos, val_contratado]);



  const [EntidadeData, setEntidadeData] = useState(entidades ? entidades.slice() : []);
  const saveAcao = async (data: any) => await post<any, any>(`${SAVE_NEW_ACAO}`, data)
  const updateAcao = async (data: any) => await put<any, any>(`${UPDATE_ACAO}`, data)
  const getAcaoById = async (id: any) =>
    await get<any, any>(`${PESQUISAR_ACAO}/` + id)
  const importGeomIntAcao = async (data: any) =>
    await post<any, any>(`${IMPORT_GEOM_ACAO}`, data)

  const pesquisarIntervencao = async (data: any) => await post<any, any>(`${PESQUISAR_INTERVENCOES_ACAO}`, data)

  const pesquisarCandidatura = async (data: any) => await post<any, any>(`${CONSULTAR_CANDIDATURA}`, data)
  
  const pesquisarCandidaturaAcoes = async (data: any) => await post<any, any>(`${CONSULTAR_CANDIDATURA_ACOES}`, data)


  const [itemToRemove, setItemToRemove] = useState<any>();

  const [openModalIntervencaoAcao, setOpenModalIntervencaoAcao] = useState<any>()
  const [openModalAssociacaoCandidatura, setOpenModalAssociacaoCandidatura] = useState<any>()
  const [openModalDescartar, setOpenModalDescartar] = useState<any>()
  const [selectedGridItemId, setSelectedGridItemId] = useState<string | null>(null);
  const [selectedGridItemIdCandidatura, setSelectedGridItemIdCandidatura] = useState<string | null>(null);

  const [selectedIndicador, setSelectedIndicador] = useState(null);
  const [valorIndicador, setValorIndicador] = useState(null);


  const [page2, setPage2] = useState({ skip: 0, take: 10 });

  const selectAcaoResult = useAcaoStore(
    (state) => state.selectAcaoResult
  )
  const selectAcaoResultId = useAcaoStore(
    (state) => state.selectAcaoResultId
  )

 
  const filterConcelho = (e: any) => {
    if (e?.value?.codigo) {
      const concelhosLocal = concelhos.filter(
        (x: any) => x.distrito_cod == e.value.codigo
      )
      setConcelhosFiltred(concelhosLocal)
      setConcelhosDisabled(false)
    } else {
      setConcelhosFiltred(concelhos)
      setConcelhosDisabled(true)
    }
  }

  const filterFreguesia = (e: any) => {
    if (e?.value?.codigo) {
      const freguesiaLocal = freguesias.filter(
        (x: any) => x.concelho_cod == e.value.codigo
      )
      setFreguesiasFiltred(freguesiaLocal)
      setFreguesiasDisabled(false)
    } else {
      setFreguesiasDisabled(true)
      setFreguesiasFiltred(freguesias)
    }
  }
  const filterNuts2 = (e: any) => {
    if (e?.value?.codigo) {
      const nutsLocal = nuts2.filter((x: any) => x.nuts_i_cod == e.value.codigo)
      setNuts2Filtered(nutsLocal)
      setNuts2Disabled(false)
    } else {
      setNuts2Disabled(true)
      setNuts2Filtered(nuts2)
    }
  }
  const filterNuts3 = (e: any) => {
    if (e?.value?.codigo) {
      const nutsLocal = nuts3.filter(
        (x: any) => x.nuts_ii_cod == e.value.codigo
      )
      setNuts3Filtered(nutsLocal)
      setNuts3Disabled(false)
    } else {
      setNuts3Disabled(true)
      setNuts3Filtered(nuts3)
    }
  }
  type combosKeys = 'acaoEstado'
  |'acaoPlaneamento1'| 'acaoPlaneamento2' | 'acaoPlaneamento3'|'acaoPlaneamento4'
  |'tipoEngenharia' | 'naturezaAcao' |'componenteAcao' | 'tipoPlaneamento'
  |'entidades' | 'praias' | 'designacaoIntervencao' | 'codigoIntervencao' | 'codigoCandidatura';

  const [combos, setCombos] = useState<any>({
    acaoEstado, 
    entidades, 
    acaoPlaneamento1, 
    acaoPlaneamento2,
    acaoPlaneamento3,
    acaoPlaneamento4,
    tipoEngenharia,
    naturezaAcao,
    componenteAcao,
    tipoPlaneamento,
    praias,
    designacaoIntervencao,
    codigoIntervencao,
    codigoCandidatura
  })
  const setDataOption: Record<combosKeys, { setFn: any, data: any[] }> = {
    acaoEstado: { setFn: setCombos, data: acaoEstado },
    entidades: { setFn: setCombos, data: entidades },
    acaoPlaneamento1: { setFn: setCombos, data: acaoPlaneamento1 },
    acaoPlaneamento2: { setFn: setCombos, data: acaoPlaneamento2 },
    acaoPlaneamento3: { setFn: setCombos, data: acaoPlaneamento3 },
    acaoPlaneamento4: { setFn: setCombos, data: acaoPlaneamento4 },
    tipoEngenharia: { setFn: setCombos, data: tipoEngenharia },
    naturezaAcao: { setFn: setCombos, data: naturezaAcao },
    componenteAcao: { setFn: setCombos, data: componenteAcao },
    tipoPlaneamento: { setFn: setCombos, data: tipoPlaneamento },
    praias: { setFn: setCombos, data: praias },
    designacaoIntervencao: { setFn: setCombos, data: designacaoIntervencao },
    codigoIntervencao: { setFn: setCombos, data: codigoIntervencao },
    codigoCandidatura: { setFn: setCombos, data: codigoCandidatura }
  };


  const filterCombo_Multiselect = (e: any, tipoCombo: combosKeys) => {
    const { setFn, data } = setDataOption[tipoCombo];
      setFn({
          ...combos,
          [tipoCombo]: filterBy(data?.slice()??[], e.filter)
      }
      );
  }
  const filterChange = (event: any) => {
    setEntidadeData(filterBy(entidades.slice(), event.filter));
  };

  const [multiData, setMultiData] = useState([])

  async function HandleOnChangeMultiData(e: any) {
    if (e?.value) {
      setMultiData(e?.value) 
    }
  }

const changeFileDoc = (e: any) => {
  setAcaoSelected({...getAcaoSelected(), n_filedoc: e?.value})
}

const CustomCalendar = (props: MultiViewCalendarProps) => {
  return <MultiViewCalendar {...props} views={1} />
}

const openModal = (e : any) => {
  setFormData({
    id_interv: "",
    designacao: "",
    codigo: "",
    intEstado: "",
    id_int_palXXI_tip_nivel1: "",
    id_int_palXXI_tip_nivel2: "",
    id_int_palXXI_tip_nivel3: "",
    id_prioridade: "",
    });
    setIdInterv(undefined);
    setDesignacao(undefined);
    setCodigo(undefined);
    setSelectedGridItemId(null)
  e.preventDefault();
  setOpenModalIntervencaoAcao(true)
  setFindIntervencao(false)
}




const cancelModal = () => {
  setOpenModalIntervencaoAcao(false)
}


const handleAddItem = (item : any) => {
  const newItem = { ...item, id: uuidv4()};
  setGridData([...gridData, newItem]);

};

const handleUpdateItem = (id : any, updatedItem : any) => {
    setGridData(prevData => prevData.map(item => item.id === id ? { ...updatedItem, id } : item));
};



const removeAssociacaoIntervencao: any = () => {
  const item = itemToRemove;
  const dataIntervencaoAcao = gridData.filter(x => !(x.id == item.id)).slice();
  setGridData(dataIntervencaoAcao);
  setOpenModalDescartar(false);

  if (dataIntervencaoAcao?.length > 0) {
    const lastIntervencao = dataIntervencaoAcao[dataIntervencaoAcao?.length - 1];

    formRenderProps.onChange('id_tipo_n1', { value: lastIntervencao?.id_int_palXXI_tip_nivel1 });
    formRenderProps.onChange('id_tipo_n2', { value: lastIntervencao?.id_int_palXXI_tip_nivel2 });
    formRenderProps.onChange('id_tipo_n3', { value: lastIntervencao?.id_int_palXXI_tip_nivel3 });
  } else {
    formRenderProps.onChange('id_tipo_n1', { value: "" });
    formRenderProps.onChange('id_tipo_n2', { value: "" });
    formRenderProps.onChange('id_tipo_n3', { value: "" });
  }
 
}

const confirmRemoveAssociacaoIntervencao = (item: any) => {
  setItemToRemove(item);
  setOpenModalDescartar(true);
}


const removeIndicador: any = () => {
  const item = itemToRemove;
  const dataIndicadorLocal = gridData.filter(x => !(x.id == item.id)).slice();
  setGridDataIndicadores(dataIndicadorLocal);
  setOpenModalDescartarIndicador(false);
}


const confirmRemoveIndicador = (item: any) => {
  setItemToRemove(item);
  setOpenModalDescartarIndicador(true);
}





const isUUID = (str: string) => {
  const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidPattern.test(str);
};

const handlePesquisarIntervencao = async (e : any, formRenderProps : FormRenderProps) => {
  e.preventDefault();

  if ((!idInterv || idInterv == "") && !designacao && !codigo) {
    postMessage({type: "infoMsg", value: {message: "Preencha o id, designação ou o código"}}, "*");
    return;
  }

 

  const intervencaoRequest = {
    id_interv: idInterv ?? undefined,
    designacao: designacao?.designacao ?? undefined,
    codigo: codigo?.codigo ?? undefined,
  };

  try {
    const data = await pesquisarIntervencao(intervencaoRequest);
    if (data) {
     setFindIntervencao(true)
     const dataObject = {
      designacao: data?.designacao,
      codigo: data?.codigo,
    };
     formRenderProps.onChange('id_interv', { value: data?.id_interv}) 
     formRenderProps.onChange('designacao', { value: dataObject}) 
     formRenderProps.onChange('codigo', { value: dataObject}) 
     formRenderProps.onChange('id_int_palXXI_tip_nivel1', { value: data?.int_pal_xxi_tip_nivel_1?.descricao}) 
     formRenderProps.onChange('id_int_palXXI_tip_nivel2', { value: data?.int_pal_xxi_tip_nivel_2?.descricao}) 
     formRenderProps.onChange('id_int_palXXI_tip_nivel3', { value: data?.int_pal_xxi_tip_nivel_3?.descricao}) 
     formRenderProps.onChange('intEstado', { value: data?.intEstado?.descricao}) 
     setFormData({
      id_interv: data?.id_interv,
      designacao: data?.designacao,
      codigo: data?.codigo,
      intEstado: data?.intEstado,
      id_int_palXXI_tip_nivel1: data?.int_pal_xxi_tip_nivel_1,
      id_int_palXXI_tip_nivel2: data?.int_pal_xxi_tip_nivel_2,
      id_int_palXXI_tip_nivel3: data?.int_pal_xxi_tip_nivel_3,
      });
    
    } else {
      postMessage({ type: 'errorMsg', value: { left: "15%" , message: "Não foi encontrada nenhuma intervenção." } }, '*');
    }
  } catch (error) {
    console.error("Erro ao pesquisar intervenção:", error);
  }
};

const handleSubmitModal = (e : any) => {

  if (selectedGridItemId) {
      handleUpdateItem(selectedGridItemId, formData);
  } else {
      const newId = uuidv4();
      handleAddItem({ ...formData, id: newId });
      setSelectedGridItemId(newId);
  }

  formRenderProps.onChange('id_tipo_n1', { value: formData?.id_int_palXXI_tip_nivel1 });
  formRenderProps.onChange('id_tipo_n2', { value: formData.id_int_palXXI_tip_nivel2 });
  formRenderProps.onChange('id_tipo_n3', { value: formData.id_int_palXXI_tip_nivel3 });

  setOpenModalIntervencaoAcao(false);
};


const handleRowClick = (dataItem : any) => {

  const id = dataItem?.id; 

  setSelectedGridItemId(id); 

  const dataItemObject = {
    designacao: dataItem?.designacao,
    codigo: dataItem?.codigo,
  };

  setFormData({
    id_interv: dataItem?.id_interv,
    designacao : dataItemObject,
    codigo : dataItemObject,
    intEstado: dataItem?.IntEstado?.descricao,
    id_int_palXXI_tip_nivel1: dataItem?.id_int_palXXI_tip_nivel1?.descricao,
    id_int_palXXI_tip_nivel2: dataItem?.id_int_palXXI_tip_nivel2?.descricao,
    id_int_palXXI_tip_nivel3: dataItem?.id_int_palXXI_tip_nivel3?.descricao,
    id_prioridade: dataItem?.id_prioridade,
  });
  setOpenModalIntervencaoAcao(true);
};

const handlePageChange2 = (event : any) => {
  setPage2({ skip: event.page2.skip, take: event.page2.take });
};

const handleFormChange = (event : any) => {
  
  setFormData({
    ...formData,
    [event.target.name]: event.target.value,
  });

  if (event.target.name === 'id_interv') {
    setIdInterv(event.target.value);
  }
};


const handleKeyPress = (e : any) => {
  
  if(viewMode){
    e.preventDefault();
    return false
  }
};

const handleInsertIndicador = () => {
  if (selectedIndicador) {
    const newItem = {
      id: uuidv4(),
      id_indicador: selectedIndicador,
      valor: valorIndicador
    };
    setGridDataIndicadores([...gridDataIndicadores, newItem]);
  }
  formRenderProps.onChange('id_indicador', { value: "" }) 
  formRenderProps.onChange('valor', { value: "" }) 
  setSelectedIndicador(null);
  setValorIndicador(null)

};

  return (
    <>
      <div className={`${styles.form} ${styles.removeScrollDuplicado}`}>
        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Registo</span>
          </legend>

          <div className={` ${styles.espacoVerticalFieldSet}`}>
            <div className={styles.fields}>
              <div>
                <Field name="id" label="ID (Código)" component={Input} disabled />
              </div>
              {
                      hasPermission(PERMISSION_INTERVENCAO_PERFIL_TOTAL) ||
                      hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO) ||
                      hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA)?<>
             
              <div>
                <Field
                  name="n_filedoc"
                  label="Nº Processo APA"
                  component={Input}
                  onChange={changeFileDoc}
                  disabled={viewMode}

                />
              </div>
              </>:<></>}
              <div>
                <Field
                  name="acaoEstado"
                  label="Estado"
                  component={ComboBox}
                  textField={'descricao'}
                  filterable={true}
                  onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'acaoEstado')}}
                  data={combos?.acaoEstado}
                  disabled={viewMode}

                />
              </div>
            </div>
            <div className={styles.fields}>
            <div>
                <Field
                  name="data_registo"
                  label="Data/hora registo"
                  component={DatePicker}  
                  format="dd/MM/yyyy"
                  calendar={CustomCalendar}
                  disabled={true}
                />
              </div>
              <div>
              <Field
                name="utilizador_id"
                label="Técnico Registo"
                disabled
                component={Input}
              />
            </div>
            <div>
              <Field
                name="entidade_registo"
                label="Entidade"
                component={Input}
                disabled
              />
            </div>
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Identificação</span>
          </legend>
          <div className={`${styles.fields}`}>
            <div>
                <Field name="designacao" label="Designação" component={Input} disabled={viewMode}/>
            </div>
            </div>
            <div className={`${styles.fields}`}>
            {
                      hasPermission(PERMISSION_INTERVENCAO_PERFIL_TOTAL) ||
                      hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO) ||
                      hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA)?<>
              <div>
                <Field
                  name="n_processo_aquisitivo"
                  label="N.º de processo aquisitivo"
                  component={Input}
                  disabled={viewMode}

                />
              </div>
               </>:<></>}
            <div>
                <Field
                  name="id_proc_contratacao"
                  label="Procedimento de contratação"
                  component={ComboBox} 
                  textField={'descricao'}
                  data={procContratacao}
                  disabled={viewMode}       />
              </div>
             
            
            </div>

            <div className={`${styles.fields} `}>
            <div>
              <Field
                name="data_tutela"
                label="Data da tutela"
                component={DatePicker}
                calendar={CustomCalendar}
                format="dd/MM/yyyy"
                disabled={viewMode}
              />
            </div>
            <div>
            <Field
                name="data_tc"
                label="Data do tribunal de contas"
                component={DatePicker}
                calendar={CustomCalendar}
                format="dd/MM/yyyy"
                disabled={viewMode}
              />
            </div>
            <div>
            <Field
                name="data_artigo"
                label="Data do artigo"
                component={DatePicker}
                calendar={CustomCalendar}
                format="dd/MM/yyyy"
                disabled={viewMode}
              />
            </div>
           
          </div>

            <div className={`${styles.fields} `}>
            {hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_LEITURA) ||hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO) ||hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA)  ||hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO)  ||hasPermission(PERMISSION_INTERVENCAO_PERFIL_TOTAL) ? <>

                <div>
                <Field
                  name="data_ini_p"
                  label="Data de início prevista"
                  component={DatePicker}
                  calendar={CustomCalendar}
                  format="dd/MM/yyyy"
                  disabled={viewMode}
              />
              </div>
              <div>
              <Field
                name="data_con_p"
                label="Data de conclusão prevista"
                component={DatePicker}
                calendar={CustomCalendar}
                format="dd/MM/yyyy"
                disabled={viewMode}
              />
              </div>
              </>:<></>}

              {hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_LEITURA) ||
              hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO) ||
              hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA)  ||
              hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO)  ||
              hasPermission(PERMISSION_INTERVENCAO_PERFIL_TOTAL)? <>
         
            <div className={styles.fields}>
              <div>
                <Field
                  name="inv_previs"
                  label="Valor Previsto"
                  component={Input}
                  disabled={viewMode}
                />
              </div>
            </div>
            </>:<></>}


            </div>
            <div className={`${styles.fields} `}>
                <div>


                </div>
                <div>
                <Field
                  name="data_adjudicacao"
                  label="Data de adjudicação"
                  component={DatePicker}
                  calendar={CustomCalendar}
                  format="dd/MM/yyyy"
                  disabled={viewMode}
              />
              </div>

              <div>
                <Field
                  name="val_contrat"
                  label="Preço contratual"
                  component={Input}
                  disabled={viewMode}
                  onChange={handleValContratChange}

                />
              </div>
          

            </div>

            <div className={`${styles.fields} `}>

            <div>
                <Field
                  name="taxa_execucao"
                  label="Taxa execução"
                  component={Input}
                  disabled
                />
              </div>

              <div>
                <Field
                  name="valor_por_executar"
                  label="Valor por executar"
                  component={Input}
                  disabled
                />
              </div>

              <div>
                <Field
                  name="inv_realizado"
                  label="Valor realizado"
                  component={Input}
                  disabled
                />
              </div>
            </div>
            <div className={`${styles.fields} `}>

              <div>
              <Field
                name="data_ini_e"
                label="Data de inicio de execução"
                component={DatePicker}
                calendar={CustomCalendar}
                format="dd/MM/yyyy"
                disabled={viewMode}
              />
            </div>
             <div>
                <Field
                  name="data_con_e"
                  label="Data de conclusão de execução"
                  component={DatePicker}
                  calendar={CustomCalendar}
                  format="dd/MM/yyyy"
                  disabled={viewMode}

                />
              </div>
              <div>
                <Field
                  name="revisao_precos"
                  label="Revisão preços"
                  component={Input}
                  disabled={viewMode}
                  onChange={handleValRevisaoPrecosChange}
                  type="number"


                />
              </div>
            </div>

            <div className={styles.espacoVerticalFieldSet}>

            <div className={`${styles.fields} `}>

              <div>
              <Field
                name="entidade"
                label="Entidade"
                component={ComboBox}
                textField={'nome'}
                filterable={true}
                onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'entidades')}}
                data={combos?.entidades}
                disabled={viewMode}
              />
            </div>
            </div>
            </div>

            <div className={`${styles.fields} `}>
            <div className={styles.espacoVerticalFieldSet}>

              <div>
              <Field
                  name="descricao"
                  label="Descrição"
                  component={TextArea}
                  style={{opacity: viewMode ? '0.7' : '1'}}
                  onKeyPress={handleKeyPress}
                  />
              </div>
            </div>
            </div>
           
         
        </fieldset>
  
        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Localização</span>
          </legend>

          <div className={styles.fields}>
          <div>
              {getAcaoSelected() ?
                <CheckMoreItem
                  list={getAcaoSelected()?.nuts1}
                  property={'nome'}
                  label="NUTS I"
                /> :
                <Field
                  name="nuts1"
                  label="NUTS I"
                  component={ComboBox}
                  textField={'nome'}
                  data={nuts1}
                  onChange={filterNuts2}
                  disabled

                />
              }
            </div>
            <div>
              {getAcaoSelected() ?
                <CheckMoreItem
                  list={getAcaoSelected()?.nuts2}
                  property={'nome'}
                  label="NUTS II"

                /> :
                <Field
                  name="nuts2"
                  label="NUTS II"
                  component={ComboBox}
                  textField={'nome'}
                  data={nuts2Filtered}
                  onChange={filterNuts3}
                  disabled
                />}
            </div>
            <div>
              {getAcaoSelected() ?
                <CheckMoreItem
                  list={getAcaoSelected()?.nuts3}
                  property={'nome'}
                  label="NUTS III"

                /> :
                <Field
                  name="nuts3"
                  label="NUTS III"
                  component={ComboBox}
                  textField={'nome'}
                  data={nuts3Filtered}
                  disabled


                />}
            </div>
          </div>
          <div className={styles.fields}>
          <div>
              {getAcaoSelected() ?
                <CheckMoreItem
                  list={getAcaoSelected()?.distritos}
                  property={'nome'}
                  label="Distrito"
                /> :
                <Field
                  name="distrito"
                  label="Distrito"
                  component={ComboBox}
                  onChange={filterConcelho}
                  textField={'nome'}
                  data={distritos}
                  disabled

                />}
            </div>
            <div>
              {getAcaoSelected() ?
                <CheckMoreItem
                  list={getAcaoSelected()?.concelhos}
                  property={'nome'}
                  label="Concelho"
                /> :
                <Field
                  name="concelho"
                  label="Concelho"
                  component={ComboBox}
                  onChange={filterFreguesia}
                  disabled
                  textField={'nome'}
                  data={concelhosFiltred}
                />}
            </div>
            <div>
              {getAcaoSelected() ?
                <CheckMoreItem
                  list={getAcaoSelected()?.freguesias}
                  property={'nome'}
                  label="Freguesia"
                /> :
                <Field
                  name="freguesia"
                  label="Freguesia"
                  component={ComboBox}
                  disabled
                  textField={'nome'}
                  data={freguesiasFiltred}
                />}
            </div>
          </div>
          <div className={styles.fields}>
          <div>
            {getAcaoSelected() ?
              <CheckMoreItem
                list={getAcaoSelected()?.arhs}
                property={'nome'}
                label="ARH"
              /> :
              <Field
                name="arh"
                label="ARH"
                component={ComboBox}
                textField={'nome'}
                data={arhs}
                disabled

              />}
            </div>
            <div>
            {getAcaoSelected() ?
              <CheckMoreItem
                list={getAcaoSelected()?.capitanias}
                property={'nome'}
                label="Capitania"
              /> :
              <Field
                name="capitania"
                label="Capitania"
                component={ComboBox}
                textField={'nome'}
                data={capitania}
                disabled

              />}
              </div>
              <div>
            {getAcaoSelected() ?
              <CheckMoreItem
                list={getAcaoSelected()?.massasAgua}
                property={'nome'}
                label="Massa de água"
              /> :
              <Field
                name="massaagua"
                label="Massa de água"
                component={ComboBox}
                textField={'nome'}
                data={massaagua}
                disabled

              />}
              </div>
          </div>
          <div className={styles.fields}>
            <div>
              <Field
                name="praia"
                label="Praia (se aplicável)"
                component={ComboBox}
                textField={'nome'}
                filterable={true}
                onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'praias')}}
                data={combos?.praias}
                disabled={viewMode}

              />
            </div>
            <div>
              <Field name="local" label="Local" component={Input} disabled={viewMode}
  />
            </div>
          </div>
          &nbsp;
        </fieldset>
    
        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>
            Intervenções associadas
            </span>
          </legend>
          {!viewMode  ? (

          <div className={styles.espacoVerticalFieldSet}>
          <button
              title="Adicionar novo"
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary buttonAdicionarNovo btnPrimaryColor"
              onClick={(e) => {
                openModal(e);
              }}
              // disabled={viewMode || !selectedIntervencaoResult?.id}
              >
              <span className="icon-siarl icon-registar k-button-icon"></span>{' '}
              &nbsp;{' '}
              Inserir
            </button>
         </div>
        ) : ""}

        <div className={styles.espacoVerticalFieldSet}>
        <div className={styles.scrollableArea}>
          <Grid
            className={'customGrid'}
            data={gridData.slice(page2.skip, page2.take + page2.skip)}
            pageable={{
              buttonCount: 5,
              info: false,  
              type: 'numeric',
              pageSizes: false,  
              previousNext: true,
            }}
            rowHeight={50}
            total={gridData.length}
            skip={page2.skip}
            take={page2.take}
            onPageChange={handlePageChange2}
            // onRowClick={handleRowClick}

          >
            {!viewMode ?
            
            <GridColumn width={56}
                        cell={(props) => (
                          <td className="k-command-cell">
                            <div style={{cursor:'pointer'}} className={'gridCommand'}>
                              <div
                                role="button"
                                className={styles.gridCommandIconButton}
                                onClick={() => handleRowClick(props.dataItem)}
                              >
                                <FontAwesomeIcon icon={faInfoCircle} title="Editar associação" />
                              </div>
                              <div style={{cursor:'pointer'}}
                                role="button"
                                onClick={() => confirmRemoveAssociacaoIntervencao(props.dataItem)}
                                className={styles.gridCommandIconButton}
                              >
                                <FontAwesomeIcon icon={faRemove} title="Remover associação" />
                              </div>
                            </div>
                          </td>
                        )}
                      />
              : '' }     
            <GridColumn field="id_interv" className={styles.centerAlign}  width="100px" title="ID intervenção" />
            <GridColumn field="designacao" className={styles.centerAlign} width="110px" title="Designação" />
            <GridColumn field="codigo" className={styles.centerAlign} width="85px" title="Código" />
            <GridColumn field="intEstado.descricao" className={styles.centerAlign} width="110px" title="Estado" />
            <GridColumn field="id_int_palXXI_tip_nivel3.descricao" className={styles.centerAlign} width="110px" title="Tipo n3" />
            <GridColumn field="percentagem" className={styles.centerAlign} width="120px" title="Percentagem" />
          
          </Grid>
        </div>
        </div>
        </fieldset>


      
        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>
            Candidaturas associadas
            </span>
          </legend>
         
        <div className={styles.espacoVerticalFieldSet}>
        <div className={styles.scrollableArea}>
          <Grid
            className={'customGrid'}
            data={gridDataCandidaturas.slice(page2.skip, page2.take + page2.skip)}
            pageable={{
              buttonCount: 5,
              info: false,  
              type: 'numeric',
              pageSizes: false,  
              previousNext: true,
            }}
            rowHeight={50}
            total={gridData.length}
            skip={page2.skip}
            take={page2.take}
            onPageChange={handlePageChange2}

          >
            
           
                   
            <GridColumn field="id" className={styles.centerAlign} width="100px" title="ID candidatura" />
            <GridColumn field="intInstrumentoFinanciamento.designacao" className={styles.centerAlign} width="145px" title="Entidade comunitária" />
            <GridColumn field="cod_candidatura" className={styles.centerAlign} width="100px" title="Código " />
            <GridColumn field="intCandidaturaEstado.descricao" className={styles.centerAlign} width="120px" title="Estado" />
            <GridColumn field="inv_comp_total" className={styles.centerAlign} width="135px" title="Valor comunitário" />
            <GridColumn field="inv_nacional_total" className={styles.centerAlign} width="135px" title="Valor nacional" />
            <GridColumn field="inv_previsto_total" className={styles.centerAlign} width="135px" title="Investimento previsto" />
          
          </Grid>
        </div>
        </div>
        </fieldset>




        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Tipologia</span>
          </legend>
          <div className={styles.espacoVerticalFieldSet}>
              <div>
                <Field
                  name="id_tipo_n1"
                  label="Tipologia Nivel 1 (PAL XXI)"
                  component={ComboBox}
                  textField={'descricao'}
                  filterable={true}
                  onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'acaoPlaneamento1')}}
                  disabled
                  />
              </div>
              <div>
                <Field
                  name="id_tipo_n2"
                  label="Tipologia Nivel 2 (PAL XXI)"
                  component={ComboBox}
                  textField={'descricao'}
                  filterable={true}
                  onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'acaoPlaneamento2')}}
                  disabled
                />
              </div>
              <div>
                <Field
                  name="id_tipo_n3"
                  label="Tipologia Nivel 3 (PAL XXI)"
                  component={ComboBox}
                  textField={'descricao'}
                  filterable={true}
                  onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'acaoPlaneamento3')}}
                  disabled
                />
              </div>
              <div>
                <Field
                  name="id_tipo_n4"
                  label="Tipologia Nivel 4 (Execução)"
                  data={acaoPlaneamento4}
                  component={ComboBox}
                  disabled={viewMode}
                  textField={'descricao'}
                  filterable={true}
                  onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'acaoPlaneamento4')}}
                />
              </div>
          
            <div className={styles.fields}>
                
                <div>
                  <Field
                    name="id_natureza_acao"
                    label="Natureza da ação"
                    component={ComboBox}
                    textField={'descricao'}
                    filterable={true}
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'naturezaAcao')}}
                    data={combos?.naturezaAcao}
                    disabled={viewMode}
                  />
                </div>
            </div>
            <div className={styles.fields}>

                <div>
                  <Field
                    name="id_int_acao_componente"
                    label="Componentes da ação"
                    component={MultiSelect}
                    textField={'descricao'}
                    filterable={true}
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'componenteAcao')}}
                    data={combos?.componenteAcao}
                    disabled={viewMode}
                  />
                </div>
              </div>
          </div>
              
        </fieldset>

        
        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>
              Indicadores de concretização
            </span>
          </legend>
          {!viewMode  ? (

          <div className={styles.espacoVerticalFieldSet}>
             <div className={styles.fields}>     
                    
               <div>
              <Field
                name="id_indicador"
                label="Indicadores"
                component={ComboBox}
                data={intIndicador}
                onChange={(e) => setSelectedIndicador(e.target.value)}
                textField={'descricao'}
                filterable={true}
                value={selectedIndicador}
                onFilterChange={filterChange}
                disabled={viewMode} 
              />
              </div>
               <div>
                <Field
                  name="valor"
                  label="Valor"
                  component={Input}
                  onChange={(e) => setValorIndicador(e.target.value)}
                  disabled={viewMode}
                  value={valorIndicador}
                  type="number"
                />
            </div>  

          <button
              title="Inserir indicador"
              className={styles.btnInserirIndicador}
              onClick={handleInsertIndicador}
              type="button"
              // disabled={viewMode || !selectedIntervencaoResult?.id}
              >
              <span className="icon-siarl icon-registar k-button-icon"></span>{' '}
              &nbsp;{' '}
            </button>
         </div>
         </div>

        ) : ""}

         
        <div className={styles.espacoVerticalFieldSet}>
          <div className={styles.scrollableArea}>
          <Grid
            className={'customGrid'}
            data={gridDataIndicadores.slice(page2.skip, page2.take + page2.skip)}
            pageable={{
              buttonCount: 5,
              info: false,  
              type: 'numeric',
              pageSizes: false,  
              previousNext: true,
            }}
            rowHeight={50}
            total={gridDataIndicadores.length}
            skip={page2.skip}
            take={page2.take}
            onPageChange={handlePageChange2}

          >
            {!viewMode? (
            <GridColumn
              width="50px"
              cell={(props) => (
                <td>
                  <button
                    onClick={() => confirmRemoveIndicador(props.dataItem)}
                    className={styles.gridCommandIconButton}>
                  <FontAwesomeIcon icon={faRemove} />
                  </button>
                
                </td>
              )}
            />
          ) : ""}
           <GridColumn field="id" width="35px" title="ID" cell={(props) => (
           <td>
               {isUUID(props.dataItem.id) ? '' : props.dataItem.id}
           </td>
            )}
            />            
            <GridColumn field="id_indicador.descricao" width="170px" title="Indicador de concretização" />
            <GridColumn field="valor" width="170px" title="Valor" />
            
          </Grid>
          </div>
        </div>
        </fieldset>
  
        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Observações</span>
          </legend>

          <div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}>
            <Field
              name="obs"
              label="Observações"
              component={TextArea}
              textField={'nome'}
              onKeyPress={handleKeyPress}
              style={{opacity: viewMode ? '0.7' : '1'}}


            />
          </div>
        </fieldset>

      </div>


   


      {openModalDescartar ? (
          <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={() => setOpenModalDescartar(false)}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                  <fieldset>
                    <span className="textDescartar">{'Apagar'} registo  </span>
                    <br></br>
                    <span>{'Tem a certeza que pretende apagar o associação?'}</span>
                  </fieldset>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={() => setOpenModalDescartar(false)}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={removeAssociacaoIntervencao}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}

{openModalIntervencaoAcao ? (
          <Dialog
            title="Associação de intervenção à ação"
            className="ModalInstumentoPlaneamento"
            onClose={cancelModal}
          >
            
            <Form
              onSubmit={handleSubmitModal}
              initialValues={formData}
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 620 }}>
                  <div className={`${styles.form} ${styles.removeScrollDuplicado}`}>
                    
                      <div className={styles.fields}>
                        <Field
                          name="id_interv" 
                          onChange={handleFormChange}  
                          label="ID Intervenção" 
                          component={Input}  
                          disabled={viewMode}  
                          type="number"
                          value={idInterv}
                        />
                      </div>
                      <div className={styles.fields}>
                        <div>
                        <Field
                          name="designacao"
                          label="Designação"
                          component={ComboBox}
                          filterable={true}
                          onFilterChange={(e: any) => { filterCombo_Multiselect(e, 'designacaoIntervencao') }}
                          textField={'designacao'}
                          data={combos?.designacaoIntervencao}
                          value={designacao}
                          disabled={viewMode}
                          onChange={(e) => {
                            setDesignacao(e.value)
                          }}
                        />
                      </div>
                  
                      </div>
                      <div className={styles.fields}>
                        <div>
                        <Field
                            name="codigo"
                            label="Código"
                            component={ComboBox}
                            filterable={true}
                            onFilterChange={(e: any) => { filterCombo_Multiselect(e, 'codigoIntervencao') }}
                            textField={'codigo'}
                            data={combos?.codigoIntervencao}
                            disabled={viewMode}
                            value={codigo}
                            onChange={(e) => {
                              setDesignacao(e.value)
                            }}
                          />
                      </div>
                    </div>

                    <div className={`${styles.fields}`}>
                  <div>
                      <Field
                          name="intEstado"
                          label="Estado"
                          component={Input}                     
                          disabled
                          onChange={handleFormChange}
                          />
                  </div>
               </div>      
                  <div className={`${styles.fields}`}>
                  <div>
                      <Field
                          name="id_int_palXXI_tip_nivel3"
                          label="Tipologia Nivel 3"
                          component={Input}                     
                          disabled
                          onChange={handleFormChange}
                          />
                  </div>
               </div>      
               <div className={`${styles.fields}`}>
                  <div>
                      <Field
                          type="number"
                          name="percentagem"
                          label="Percentagem"
                          component={Input}                     
                          onChange={handleFormChange}
                          />
                  </div>
               </div>      
                {/* <div className={`${styles.fields}`}>
                  <div>
                      <Field
                          name="id_int_palXXI_tip_nivel2"
                          label="Tipologia Nivel 2"
                          component={Input}
                          disabled
                          onChange={handleFormChange}
                          />
                  </div>
               </div>  
               <div className={`${styles.fields}`}>
                  <div>
                      <Field
                          name="id_int_palXXI_tip_nivel3"
                          label="Tipologia Nivel 3"
                          component={Input}
                          disabled
                          onChange={handleFormChange}
                          />
                  </div>
               </div>    */}
               <div className="k-form-buttons btnsModal">
               <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                      onClick={cancelModal}
                    >
                      Cancelar
                    </button>
                    <button
                      type={'button'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                      onClick={(e) => handlePesquisarIntervencao(e, formRenderProps)}                    
                       >
                    
                      Pesquisar
                    </button>
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                      onClick={formRenderProps.onSubmit}
                      disabled={!findIntervencao}
                    >
                      Submeter
                    </button>
                    </div>              
                    </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}




{openModalDescartarIndicador ? (
          <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={() => setOpenModalDescartarIndicador(false)}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                  <fieldset>
                    <span className="textDescartar">{'Apagar'} indicador  </span>
                    <br></br>
                    <span>{'Tem a certeza que pretende apagar o indicador?'}</span>
                  </fieldset>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={() => setOpenModalDescartarIndicador(false)}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={removeIndicador}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}




    </>
  )
}

