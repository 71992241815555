import {
  faCloudDownload,
  faFileDownload,
  faTruckField
} from '@fortawesome/free-solid-svg-icons'
import { Button, DropDownButton } from '@progress/kendo-react-buttons'
import { Form, FormElement } from '@progress/kendo-react-form'
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from '@progress/kendo-react-layout'
import { useEffect, useLayoutEffect, useState } from 'react'

import clsx from 'clsx'
import { AssociacaoListagem } from '../../components/associacao/Associacao'
import { DocumentoDetalhe } from '../../components/documentos/registar/Detalhes'
import { DocumentoIdentificacao } from '../../components/documentos/registar/Identificacao'
import DocumentPreview from '../../components/viewer/DocumentPreview'
import {
  GetUserNameTecnico,
  b64toBlob,
  blobToBase64,
  coordenadasPoligono,
  del,
  entidadeUser,
  entidades,
  estados,
  fillEntidadesAsync,
  fillEstadoAsync,
  get,
  getDocumentoSelected,
  getExtensionFromPath,
  handleGenericError,
  hasPermission,
  post,
  put,
  setCoordenadasPoligono,
  setCoordenadasSelected,
  setDocumentoSelected,
  useCombos,
} from '../../services/utils'
import { useDocumentosStore } from '../../stores/documentos'
import styles from '../../styles/documentos/Documentos.module.scss'
import {
  AUTH_LOCALSTORAGE,
  BAIXAR_DOCUMENTO,
  GET_DOCUMENTO,
  IMPORT_GEOM_DOCUMENTO,
  PESQUISAR_DOCUMENTOS_ALL,
  GET_URL_FROM_FILE_DOC,
  SAVE_NEW_DOCUMENTO,
  UPDATE_DOCUMENTO,
  PESQUISAR_DOCUMENTOS_ALL_BY_ID,
  PERMISSION_ERROR_STATUS_CODE,
  NOT_AUTHORIZED_ERROR_STATUS_CODE,
  PERMISSION_ATUALIZAR_DOCUMENTO,
  PERMISSION_INSERIR_DOCUMENTO,
  DELETE_DOCUMENTO,
  clearLayers,
  RELATORIO_DOCUMENTO,
  GET_USER_BY_ID,
  DELETE_DOCUMENTO_FISICO,
  UPLOAD_DOCUMENTO,
  URL_AJUDA_DOCUMENTOS_AVANCADA,
  REMOVER_AREA_DOCUMENTO,
  RELATORIO_DOCUMENTO_RDLC,
  DOCUMENTO_POINT_NAME,
  SOFT_REMOVE_DOCUMENT,
} from '../../utils/constants'
import { isDocumentoAssociaoValid, validateUrl } from '../../validations/documento'
import ImportarGeometriaComponent from '../../components/apoiogestao/registar/ImportarGeometriaForm'
import { Dialog } from '@progress/kendo-react-dialogs'
import SessionStorageService from '../../services/SessionStorageService'
import useClearMap from '../../hooks/useClearMap'
import html2canvas from 'html2canvas'
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../../components/common/ItemRenderMais'
import ReactGA from 'react-ga4';
import ImagePreviewV2 from '../../components/viewer/ImagePreviewV2'
import EditDocumentoFormTabsSimplified from '../../components/documentos/registar/documento/EditDocumentoFormTabsSimplified'
import { set } from 'date-fns'

const TOTAL_TABS = 3
type Documento = {
  id?: number
  utilizador_id: number
  entidade_id: number | string
  estado_id?: number
  entidade?: Entidade
  filedoc_cod?: string
  data_registo: string
  tipo_acesso_id: number
  tipoAcesso?: TipoAcesso
  modulo_id?: number
  ocorrencia_id?: number
  intervencao_id?: number
  acao_id?: number
  candidatura_id?: number
  mobilizacao_id?: number
  titulo: string
  resumo?: string
  url?: string
  tipo_recurso_id: number
  tipoRecurso?: TipoAcesso
  autores?: string
  data_publicacao?: string
  idioma_id: number
  idioma?: TipoAcesso
  observacoes?: string
  path?: string
  base64arquivo?: any
  nomearquivo?: string
}
type Entidade = {
  id: number
  nome: string
  codigo: string
  morada: string
  codigo_postal: string
  localidade: string
  tipo_entidade_id: number
  tipoEntidade: TipoEntidade
  oc_focal_point_email: string
  oc_notify_email_new_issue: boolean
  estado_id: number
  url: string
  telefone: string
  utilizador_id: number
  data_registo: string
  tipo_acesso_id: number
  tipoAcesso: TipoAcesso
  flatCoordinates: string
  flatCoordinatesJurisdicao: string
  nuts1_id: number
  nuts2_id: number
  nuts3_id: number
  distrito_id: number
  concelho_id: number
  freguesia_id: number
  freguesia_cod: string
}
type TipoAcesso = {
  id: number
  designacao: string
  descricao: string
}
type TipoEntidade = {
  id: number
  designacao: string
}

let _pdfExport: any
const exportExcel = () => {
  _export.save()
}
let _export: any
const exportPDF = () => {
  _pdfExport.save()
}
type Props = {
  editMode?: boolean
}

export default function RegistarDocumentosTabs()  {

  const [isMobile, setIsMobile] = useState(false);
  const deleteDocumento = (id: number) => del(`${DELETE_DOCUMENTO}/${id}`, {})

  const [selected, setSelected] = useState<number>(0)
  const [formState, setFormState] = useState({})
  const [openImportModal, setOpenImportModal] = useState<any>()
  const [newDocumento, setNewDocumento] = useState();
  const [openForm, setOpenForm] = useState(false);
  const getDocumentoById = async (id: any) =>
    await get<any, any>(`${GET_DOCUMENTO}/` + id)
  const importGeomDocumento = async (data: any) =>
    await post<any, any>(`${IMPORT_GEOM_DOCUMENTO}`, data)
  const ASSOCIACAO_TAB = 2;

  const [showNewDocumentoDialog, setShowNewDocumentoDialog] = useState<boolean>(false);
  const [openModalApagarDocumento, setShowModalApagarDocumento] = useState<any>()

  const cancelModalApagarDocumento = () => {
    setShowModalApagarDocumento(false)
  }


  const getUrlFileDoc = async (data: any) => await get<any>(`${GET_URL_FROM_FILE_DOC}/` + encodeURIComponent(data));

      useEffect(() => {

    if(!estados ){
      fillEstadoAsync();
      }
     
      }, []);

  const saveDocumento = async (data: any) =>
    await post<any, any>(`${SAVE_NEW_DOCUMENTO}`, data)


  const deleteDocumentoFisico = async(id: any) => 
  await del<any, any>(`${DELETE_DOCUMENTO_FISICO}/${id}`, {})
    
  const updateDocumento = async (data: any) =>
    await put<any, any>(`${UPDATE_DOCUMENTO}`, data)

  const softRemoveDocument = async (data: any) =>
    await post<any, any>(`${SOFT_REMOVE_DOCUMENT}`, data)

  const baixarDocumento = async (id: any) =>
    await get<any, any>(`${BAIXAR_DOCUMENTO}/${id}`)

  const removerArea = async (data: any) =>
    await post<any, any>(`${REMOVER_AREA_DOCUMENTO}`, data)

    
  const GetAbpUsers = async (id: any) =>
  await get<any, any>(`${GET_USER_BY_ID}/${id}`)


  const selectedDocumentosResult: any = useDocumentosStore(
    (state) => state.selectedDocumentosResult
  )

  const getDocumento = async (data: any) =>
    await get<any, any>(`${GET_DOCUMENTO}/` + data)
  const [menuOpenEditar, setMenuOpenEditar] = useState(false)

  const relatorioDocumento = async (data: any) =>
    await post<any, any>(`${RELATORIO_DOCUMENTO_RDLC}`, data)

  const handleMouseEnterEditar = () => {
    setMenuOpenEditar(true)
  }

  const handleMouseLeaveEditar = () => {
    setMenuOpenEditar(false)
  }

  const [menuOpenArea, setMenuOpenArea] = useState(false)

  const handleMouseEnterArea = () => {
    setMenuOpenArea(true)
  }

  const handleMouseLeaveArea = () => {
    setMenuOpenArea(false)
  }

  const [menuOpenImprimir, setMenuOpenImprimir] = useState(false)

  const handleMouseEnterImprimir = () => {
    setMenuOpenImprimir(true)
  }

  const handleMouseLeaveImprimir = () => {
    setMenuOpenImprimir(false)
  }

  const [menuOpenExportar, setMenuOpenExportar] = useState(false)

  const handleMouseEnterExportar = () => {
    setMenuOpenExportar(true)
  }

  const handleMouseLeaveExportar = () => {
    setMenuOpenExportar(false)
  }

  const [menuOpenPonto, setMenuOpenPonto] = useState(false)

  const [openModalDescartar, setOpenModalDescartar] = useState<any>()
    const [deleteClicked, setDeleteClicked] = useState(false);


  const cancelModalDescartar = () => {
    setOpenModalDescartar(false)
  }


  const handleMouseEnterPonto = () => {
    setMenuOpenPonto(true)
  }

  const handleMouseLeavePonto = () => {
    setMenuOpenPonto(false)
  }
const handleOpenModalDescartar = () => {
    if (
      SessionStorageService.getItem('novoDocumento') &&
      SessionStorageService.getItem('novoDocumento') == 'true'
    ) {
      setOpenModalDescartar(true);
    } else {
      onEditItemClick("SiarlVisualizar");
     handleCancel();   
   }
  }

  const [estadoFalse, setEstado] = useState(false)
  const [estadoTrue, setEstadoTrue] = useState(true)

  const [canUpdate, setCanUpdate] = useState(false);
  const [canInsert, setCanInsert] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [viewMode, setViewMode] = useState(false);

    useEffect(() => {
    setViewMode(!editMode && SessionStorageService.getItem("novoDocumento") != 'true');
  }, [])

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/RegistarDocumento" });
  }, []);

  useEffect(() => {
    setCanUpdate(hasPermission(PERMISSION_ATUALIZAR_DOCUMENTO));
    setCanInsert(hasPermission(PERMISSION_INSERIR_DOCUMENTO))
  }, [])

  const userId = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '')
   ?.user?.id

const refreshForm = async(id: any = undefined) => {
  let iDocumento = id;
  if (!iDocumento) {
    if (selectedDocumentosResult?.id) {
      iDocumento = selectedDocumentosResult?.id;
    } else {
      return;
    }
  }
  const documentos = await getDocumento(iDocumento);

  const tecnico = await GetUserNameTecnico(documentos.utilizador_id);


 const selectedDocumentosResults = {
      ...documentos,
      // Idetificacao+ detalhes
      id: documentos.id,
      Iddoc: documentos.id || undefined,
      filedoc_cod: documentos.filedoc_cod || undefined,
      idioma: documentos.idioma || undefined,
      entidade_id: documentos.entidade || undefined,
      tipoAcesso: documentos.tipoAcesso || undefined,
      recurso: documentos.tipoRecurso || undefined,
      tipo_recurso_id: documentos.tipoRecurso || undefined,
      dataPublicacao: documentos.data_publicacao || undefined,
      titulo: documentos.titulo || undefined,
      url: documentos.url || undefined,
      path: documentos.path || undefined,
      obs: documentos.observacoes || undefined,
      estado_id: estados.find(estado => estado.id === documentos.estado_id) || undefined,
      geom_imported: documentos.geom_imported ?? undefined,
      dataHoraRegisto: documentos.data_registo || undefined,
      palavraChave: documentos.lstPalavraChave || undefined,
      resumo: documentos.resumo || undefined,
      autores: documentos.autores || undefined,
      categoriaTematica: documentos.lstCategoria || undefined,
      flatCoordinates: documentos.flatCoordinates || undefined,
      nomearquivo: documentos.nomearquivo,
      tecnicoRegisto: tecnico
    }

 
    selectDocumentosResult(selectedDocumentosResults);
    setDocumentoSelected(selectedDocumentosResults);

     const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlDocumentoSearch", value: documentos?.id }, '*')

    iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: "true" }, '*')

  }

   const handleCancel = ()  => {
        refreshForm();
        postMessage({ type: "form", value: "" }, "*");
        setTimeout(() => {
            postMessage({ type: "form", value: "registarDocumentos" }, "*");
          }, 10)
    }


    const handleApagarDocumento = async (e: any) => {

      const userId = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '')
      ?.user?.id

      await softRemoveDocument(getDocumentoSelected()?.id)
      setShowModalApagarDocumento(false)    


      SessionStorageService.setItem("documentoAnexado", "false");

    }

  const handleDescartar = async (e: any) => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
       {
         type: 'SiarlCoordenadaBoxClose',
         value: 'true',
       },
       '*'
     )
     iframe.contentWindow?.postMessage(
      {
        type: 'SiarlRemoveSelection',
        value: 'true',
      },
      '*'
    )
    setOpenModalDescartar(false)
    setDeleteClicked(false);
   const documentoSelected = getDocumentoSelected()
   const userId = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '')
   ?.user?.id
   
   let documentoSaved : any;

   if(getDocumentoSelected()?.id){
    documentoSaved = await getDocumento(getDocumentoSelected()?.id);
   }
   
   const dataToSend: any = {
    id: selectedDocumentosResult?.id ?? getDocumentoSelected()?.id ?? undefined,
    utilizador_id: userId,
    entidade_id: getDocumentoSelected()?.entidade_id?.id ?? undefined,
    estado_id: getDocumentoSelected()?.estado_id?.id ?? undefined,
    filedoc_cod: getDocumentoSelected()?.filedoc_cod ?? undefined,
    data_registo: getDocumentoSelected()?.dataHoraRegisto ?? undefined,
    tipo_acesso_id: getDocumentoSelected()?.tipoAcesso?.id ?? undefined,
    palavra_chave_id: getDocumentoSelected()?.palavraChave?.id ?? undefined,
    titulo: getDocumentoSelected()?.titulo ?? undefined,
    resumo: getDocumentoSelected()?.Resumo ?? undefined,
    url: getDocumentoSelected()?.url ?? undefined,
    path: documentoSaved?.path ?? undefined,
    tipo_recurso_id: getDocumentoSelected()?.tipo_recurso_id?.id ?? undefined,
    autores: getDocumentoSelected()?.autores ?? undefined,
    data_publicacao: getDocumentoSelected()?.dataPublicacao ?? undefined,
    idioma_id: getDocumentoSelected()?.idioma?.id ?? undefined,
    lstCategoria: getDocumentoSelected()?.categoriaTematica ?? undefined,
    lstPalavraChave: getDocumentoSelected()?.palavraChave ?? undefined,
    observacoes: getDocumentoSelected()?.obs ?? undefined,
    geom_imported: getDocumentoSelected()?.geom_imported ?? undefined,
    flatCoordinates: coordenadasPoligono ?? undefined,
    status: false,
  }
  if (documentoSelected?.id) {
      postMessage({ type: 'showLoader' }, '*')
     const document =  await updateDocumento(dataToSend)
     const resultDelete = await deleteDocumentoFisico(getDocumentoSelected()?.id);
      selectDocumentosResult(document)
      let results = JSON.parse(
        SessionStorageService.getItem('resultadoDocumentosPesquisa') ?? ''
      )
      results = results.filter((r:any) => r.id !== dataToSend?.id);
      SessionStorageService.setItem(
        'resultadoDocumentosPesquisa',
        JSON.stringify(results)
      )
    }
          postMessage({ type: 'hideLoader' }, '*')
          SessionStorageService.removeItem("firstCallDocumentoSimples");
          SessionStorageService.removeItem("firstCallDocumentoAvancada");

          postMessage({ type: 'form', value: 'pesquisarDocumentos' }, '*')
  }

  const editar = [
    {
      key: 'SiarlGeoPortalAdicionarPonto',
      text: 'Editar Registo',
      icon: (
        <span
          className={`icon-siarl icon-editar borderIconArea topIcon`}
        ></span>
      ),
    },
  ]

  const area = [
    {
      key: 'SiarlGeoPortalAdicionarArea',
      text: 'Desenhar Área de abrangência',
      icon: <span className={`icon-siarl icon-area borderIconArea`}></span>,
    },
  ]

  const ponto = [
    {
      key: '',
      text: 'Adicionar ponto de abrangência',
      icon: <span className={`icon-siarl icon-pin borderIconArea`}></span>,
    },
  ]

  const imprimir = [
    {
      key: '',
      text: 'Imprimir Ficha',
      icon: <span className={`icon-siarl icon-imprimir borderIconArea`}></span>,
    },
  ]

  const exportar = [
    {
      key: '',
      text: 'Exportar Ficha',
      icon: <span className={`icon-siarl icon-exportar borderIconArea`}></span>,
    },
  ]

  const selectDocumentosResultId = useDocumentosStore(
    (state) => state.selectDocumentosResultId
  )

  const selectDocumentosResult = useDocumentosStore(
    (state) => state.selectDocumentosResult
  )

  const identificacaoTabIsValid = useDocumentosStore(
    (state) => state.identificacaoTabIsValid
  )

  const detalheTabIsValid = useDocumentosStore(
    (state) => state.detalheTabIsValid
  )

  useEffect(() => {
    addEventListener('message', (event: MessageEvent) => {
      if (event.data.type == 'CoordenadasSelected') {
        console.log(event.data.value)
        if (event.data.value) {
          const flatPointCoordinates =
            event.data.value.x + ', ' + event.data.value.y
          setCoordenadasSelected(flatPointCoordinates)
        }
      } else if (event.data.type == 'CoordenadasFlatSelected') {
        let polygonString = ''
        const values: any[] = event.data.value
        values?.forEach((value, index) => {
          if (index % 2 == 0) {
            polygonString += value + ' '
          } else {
            polygonString += value + ','
          }
        })
        polygonString = polygonString.substring(0, polygonString.length - 1)
        setCoordenadasPoligono(polygonString)
        console.log(polygonString)
      }
    })

    setNewDocumento(selectedDocumentosResult?.nomearquivo);

  }, [])

  const handleSelect = (e: TabStripSelectEventArguments) => {
    if (e.selected > 0 && !selectedDocumentosResult) {
      postMessage({type:"infoMsg", value: {message: "É necessário gravar primeiro."}})
      return;
    }
    setSelected(e.selected)  }

  const [urlDoDocumento, setUrlDoDocumento] = useState('')
  const [openRemoveAreaModal, setOpenRemoveAreaModal] = useState<any>()

  const selectMapAction = async (item: any) => {
    
   const iframe: any = document.getElementById('iframeGeoPortal')
   if (item.key == "AbrirFileDoc") {
    postMessage({ type: 'showLoader' }, '*');
    const urlFileDoc = await getUrlFileDoc(selectedDocumentosResult?.Idfiledoc);
    postMessage({ type: 'hideLoader' }, '*')
    window.open(urlFileDoc, '_blank');
  }
 else if (item.key == 'BaixarDoc') {
    if (selectedDocumentosResult && selectedDocumentosResult?.url) {
      const url = selectedDocumentosResult?.url
      setOpenForm(true);
    }
  }
  else if (item.key == "removerArea") {
   setOpenRemoveAreaModal(true)
  }
  else {
      iframe.contentWindow?.postMessage({ type: item.key, value: getDocumentoSelected()?.id, editGpLayer: DOCUMENTO_POINT_NAME }, '*')
    }
  }

  const removeArea = async () => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: 'true' }, '*')
    setCoordenadasPoligono(undefined);
    if (!selectedDocumentosResult?.id) {
      setOpenRemoveAreaModal(false)
      return;
    }
    await removerArea(selectedDocumentosResult?.id);
    
    iframe.contentWindow?.postMessage(
      { type: 'SiarlEaRegistoAnaliseSearch', value: [selectedDocumentosResult?.id] },
      '*'
    )
    iframe.contentWindow?.postMessage(
      { type: 'SiarlEaRegistoAnaliseSearch', value: [selectedDocumentosResult?.id] },
      '*'
    )
    refreshForm(selectedDocumentosResult?.id)
    setOpenRemoveAreaModal(false)
  }

  const voltar = async () => {

     const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
      {
        type: 'SiarlCoordenadaBoxClose',
        value: 'true',
      },
      '*'
    )

    const userId = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '')
    ?.user?.id

      if ((SessionStorageService.getItem("isSubmitActionDocumentos") == "true" || sessionStorage.getItem("documentoAntigo") == "true")) {
        postMessage({ type: 'form', value: 'pesquisarDocumentos' }, '*')
        clearDocumentosMap();
      }
      else if(getDocumentoSelected()?.id){
          const dataToSend : any = {
            Id : getDocumentoSelected()?.id,
            utilizador_id: userId,
            status: false
          }
          const resultDelete = await deleteDocumentoFisico(getDocumentoSelected()?.id);
          const resultUpdate = await updateDocumento(dataToSend);
          postMessage({ type: 'form', value: 'pesquisarDocumentos' }, '*')
          clearDocumentosMap();
      }
      else{
        postMessage({ type: 'form', value: 'pesquisarDocumentos' }, '*')
        clearDocumentosMap();
      }

  }

  const [clearMap] = useClearMap();
  const clearDocumentosMap = () => {
    clearMap(clearLayers["CLEAR_LAYERS_DOCUMENTOS"]);
    setDocumentoSelected(undefined)
    selectDocumentosResult(undefined)
  }

 

  const username = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '')
    ?.user?.name



  const handleSubmit = async (e: any ,isSubmitAction?: boolean) => {
    console.log(e.values)
    if (selected == 0 && !identificacaoTabIsValid) {
      return false
    }
    if (selected == 1 && !detalheTabIsValid) {
     
      return false
    }

    let documentoSaved;
    if(getDocumentoSelected()?.id)
    {
      documentoSaved = await getDocumento(getDocumentoSelected()?.id);

    }
      const userId = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '')
      ?.user?.id


      let base64File: any = ''

      const dataToSend: any = {
      id: selectedDocumentosResult?.id ?? getDocumentoSelected()?.id ?? undefined,
      utilizador_id: userId,
      entidade_id: e?.values?.entidade_id?.id ?? undefined,
      estado_id: e?.values?.estado_id?.id ?? undefined,
      filedoc_cod: e.values?.filedoc_cod ?? undefined,
      data_registo: e?.values?.dataHoraRegisto ?? undefined,
      tipo_acesso_id: e.values?.tipoAcesso?.id ?? undefined,
      palavra_chave_id: e.values?.palavraChave?.id ?? undefined,
      titulo: e.values?.titulo ?? undefined,
      resumo: e.values?.Resumo ?? undefined,
      path: documentoSaved?.path ?? undefined,
      url: documentoSaved?.url ?? undefined,
      tipo_recurso_id: e.values?.tipo_recurso_id?.id ?? undefined,
      autores: e.values?.autores ?? undefined,
      data_publicacao: e.values?.dataPublicacao ?? undefined,
      idioma_id: e.values?.idioma?.id ?? undefined,
      lstCategoria: e.values?.categoriaTematica ?? undefined,
      lstPalavraChave: e.values?.palavraChave ?? undefined,
      observacoes: e.values?.obs ?? undefined,
      geom_imported: getDocumentoSelected()?.geom_imported ?? undefined,
      flatCoordinates: coordenadasPoligono ?? undefined,
      status: true,
    }
  
    if (e?.values?.arquivo || e?.values?.arquivo?.length > 0) {
      const arquivo = e?.values?.arquivo?.[0]?.getRawFile()
      const nomeArquivo = arquivo?.name
      try {
        base64File = await blobToBase64(arquivo)
        dataToSend['base64arquivo'] = base64File
        dataToSend['nomearquivo'] = nomeArquivo
      } catch (error) {
        console.log({ error })
      }
    }
    try {
      let documentoSaved: any
      if (selectedDocumentosResult?.id || getDocumentoSelected()?.id) {
        documentoSaved = await updateDocumento(dataToSend)
        setDocumentoSelected(documentoSaved)

      } else {
        documentoSaved = await saveDocumento(dataToSend);
         setDocumentoSelected(documentoSaved)
      }
      // to bring the combos too
      documentoSaved = await getDocumento(documentoSaved.id);

      const selectedDocumentosResults = {
        ...documentoSaved,
        // Idetificacao
        id: documentoSaved.id,
        Iddoc: documentoSaved.id || undefined,
        filedoc_cod: documentoSaved.filedoc_cod || undefined,
        idioma: documentoSaved.idioma || undefined,
        entidade_id: documentoSaved.entidade || undefined,
        tipoAcesso: documentoSaved.tipoAcesso || undefined,
        recurso: documentoSaved.tipoRecurso || undefined,
        tipo_recurso_id: documentoSaved.tipoRecurso || undefined,
        dataPublicacao : documentoSaved.data_publicacao || undefined,
        titulo: documentoSaved.titulo || undefined,
        url: documentoSaved.url || undefined,
        path: documentoSaved.path || undefined,
        obs: documentoSaved.observacoes || undefined,
        estado_id: estados.find(estado => estado.id === documentoSaved.estado_id) || undefined,
        geom_imported: documentoSaved.geom_imported ?? undefined,
        dataHoraRegisto: documentoSaved.data_registo || undefined,
        palavraChave: documentoSaved.lstPalavraChave || undefined,
        resumo: documentoSaved.resumo || undefined,
        autores: documentoSaved.autores || undefined,
        categoriaTematica: documentoSaved.lstCategoria || undefined,
        flatCoordinates: documentoSaved.flatCoordinates || undefined,
        nomearquivo: documentoSaved.nomearquivo
      }

      selectDocumentosResultId(documentoSaved?.id as number)
      selectDocumentosResult(selectedDocumentosResults);
      setDocumentoSelected(selectedDocumentosResults);
     

     refreshForm(documentoSaved.id);
      if (documentoSaved) {
        
        setTimeout(() => {
          postMessage({ type: 'sucessMsg' }, '*')
        }, 500)
      }
      
    } catch (error: any) {
     handleGenericError(error);
  }
}

    const importGeo = async (objToSend: any, e: any) => {
      
    postMessage({ type: "showLoader" }, "*");
    if (!objToSend) {
      postMessage({ type: "errorMsg", value: "Não foi possivel salvar" }, "*");
      postMessage({ type: "hideLoader" }, "*");
      return;

    }


    let documento: any;
    let documento_id;
  

 const userId = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '')
      ?.user?.id

    const dataRegisto = selectedDocumentosResult?.dataHoraRegisto ? new Date(selectedDocumentosResult?.dataHoraRegisto) : new Date()

    const documentoSelected = selectedDocumentosResult ?? getDocumentoSelected();
    if (documentoSelected && documentoSelected?.id) {
      documento = documentoSelected;
      documento_id = documentoSelected?.id;
    } else {
      documento = await saveDocumento({utilizador_id: userId, data_registo: dataRegisto });
      documento_id = documento?.id;
      const selectedDocumentosResults = {
        ...documento,
        // Idetificacao+ detalhes
        id: documento.id,
        Iddoc: documento.id || undefined,
        filedoc_cod: documento.filedoc_cod || undefined,
        idioma: documento.idioma || undefined,
        entidade_id: documento.entidade || undefined,
        tipoAcesso: documento.tipoAcesso || undefined,
        recurso: documento.tipoRecurso || undefined,
        tipo_recurso_id: documento.tipoRecurso || undefined,
        dataPublicacao: documento.data_publicacao || undefined,
        titulo: documento.titulo || undefined,
        url: documento.url || undefined,
        path: documento.path || undefined,
        obs: documento.observacoes || undefined,
        estado_id: estados.find(estado => estado.id === documento.estado_id) || undefined,
        geom_imported: documento.geom_imported ?? undefined,
        dataHoraRegisto: documento.data_registo || undefined,
        palavraChave: documento.lstPalavraChave || undefined,
        resumo: documento.resumo || undefined,
        autores: documento.autores || undefined,
        categoriaTematica: documento.lstCategoria || undefined,
        flatCoordinates: documento.flatCoordinates || undefined,
        nomearquivo: documento.nomearquivo,
        tecnicoRegisto: userId
      }
      selectDocumentosResult(selectedDocumentosResults);
      setDocumentoSelected(selectedDocumentosResults);
      selectDocumentosResultId(documento_id);

    }
    try {
      objToSend = { ...objToSend, ...documento, documento_id: documento_id, data_registo: dataRegisto }
      const result = await importGeomDocumento(objToSend)
      if (!result) {
        postMessage({ type: "errorMsg", value: "Não foi possivel salvar" }, "*");
        return;
      }

      try {
        documento = await getDocumentoById(documento_id);
      } catch (error) {
        postMessage({ type: "errorMsg", value: "Registo salvo mas, Não é possivel mostrar os dados neste momento" }, "*");

      } finally {
        refreshForm(documento_id);
      }
      try {
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage(
          { type: 'SiarlDocumentoSearch', value: [documento_id] },
          '*'
        )
      } catch (error) {
        postMessage({ type: "errorMsg", value: "Registo salvo mas, Não é possivel mostrar os dados neste momento" }, "*");
      }
      setOpenImportModal(false)
      refreshForm(documento_id);

    } catch (err) {
      handleGenericError(err);

    } finally {
      postMessage({ type: "hideLoader" }, "*");
    }

  }

 
  const options = [
    {
      key: 'BaixarDoc',
      text: 'Abrir documento',
      icon:
        selectedDocumentosResult && selectedDocumentosResult?.url
          ? faCloudDownload
          : faFileDownload,
    }
  ]

  useEffect(() => {
    if (selectedDocumentosResult?.Idfiledoc) {
      options.push(
      {
        key: 'AbrirFileDoc',
        text: 'Abrir File Doc',
        icon: faFileDownload,
      });
    }
  }, [])

  const onItemClickImprimirEntidades = (event: any) => {
    const link: HTMLAnchorElement | null = document.querySelector(
      '#print-results-entidades'
    )

    if (link) {
      link.click()
    }
  }

  const mapActionsSelectItemChange = (item: any) => {
    selectMapAction(item.value)
  }

  const isSubmit = () => {
    if (selected === 0 && !identificacaoTabIsValid) {
      return false
    }
    if (selected === 1 && !detalheTabIsValid) {
      return false
    }
    if (selected === TOTAL_TABS) {
      return true
    } else {
      return false
    }
  }
  const innerStyleTabContent = {
    scrollBehavior: 'smooth',
    top: 55,
  }


 const onItemClick = (event: any) => {
  if(event?.item?.key == "ImportGeom"){
    ImportGeom(event.item)
   } else {
    selectMapAction(event.item)
   }
    
  }

  const optionsEditar = [
    {
      key: 'SiarlEditar',
      text: 'Editar',
    },
    {
      key: 'SiarlApagar',
      text: 'Apagar',
    },
    
  ]

  const optionsArea = [
    {
      key: 'SiarlGeoPortalAdicionarArea',
      text: 'Desenhar Área',
    },
    {
      key: 'SiarlEditarGeometria',
      text: 'Editar Área',
    },
    {
      key: 'removerArea',
      text: 'Remover Área',
    },
    {
      key: 'ImportGeom',
      text: 'Importar Geom',
    },    
  ]

  const optionsImprimir = [
    {
      key: 'imprimirFichas',
      text: 'Fichas',
    },
    {
      key: 'imprimirResultados',
      text: 'Resultados',
    },
  ]

  const optionsExportar = [
    {
      key: 'exportarFichas',
      text: 'Fichas',
    },
    {
      key: 'exportarResultados',
      text: 'Resultados',
    },
  ]

  const itemRender = (props: { item: any; itemIndex: number }) => {
    const isTitleItem = props.item.key === 'titulo'
    if (isTitleItem) {
      // Item de título personalizado
      return (
        <div className={styles.DropDownButtonTitulo}>
          <span>{props.item.text}</span>
        </div>
      )
    } else {
      // Demais itens
      return (
        <div className={styles.DropDownButtonItem}>
          <span>{props.item.text}</span>
        </div>
      )
    }
  }

     const onEditItemClick= (event:any) => {
    if (event == "SiarlEditar" || event?.item?.key == "SiarlEditar") {
      setEditMode(true);
      setViewMode(false);
      handleAlteraTitulo();
      return;
    }
    if (event == "SiarlVisualizar") {
      setEditMode(false);
      setViewMode(true);
    }
    if (event?.item?.key == "SiarlApagar") {
      setDeleteClicked(true);
      setOpenModalDescartar(true);
    }
  }
  useEffect(() => {
    if (viewMode) {
      SessionStorageService.removeItem("novoDocumento");
    }
  })

   const ImportGeom = async (item: any) => {
    if (item.key == 'ImportGeom') {
      setOpenImportModal(true)
    }
  }

  const onImportGeomClick = (event: any) => {
    ImportGeom(event.item)
  }

  const onItemClickExportar = async (event: any) => {
    let mapImage = ''

    if (!window.location.href.includes('localhost')) {
      const mapIframe = document.querySelector(
        '#iframeGeoPortal'
      ) as HTMLIFrameElement

      const mapIframeInnerContent =
        mapIframe.contentWindow?.document.querySelector(
          'div.ol-viewport'
        ) as HTMLElement;

      const mapBussula =  mapIframe.contentWindow?.document.querySelector(
        'button.fixedNorthArrowButton'
      ) as HTMLElement;

      mapIframeInnerContent.appendChild(mapBussula);
      
      const generatedImage = await html2canvas(mapIframeInnerContent);
      mapImage = generatedImage.toDataURL()
    }

    
    const entidade = selectedDocumentosResult?.entidade?.nome ?? '';


    console.log(selectedDocumentosResult);

    try {
      postMessage({ type: 'showLoader' }, '*')

      const documentoBase64 = await relatorioDocumento({
        id: selectedDocumentosResult?.id,
        dados: {
          //Registo
          'REGISTO' : '',
          'Filedoc:': selectedDocumentosResult?.filedoc_cod ?? '',
          'Estado do Registo:' : selectedDocumentosResult?.estado_id?.designacao ?? '',
          'Data do registo:': selectedDocumentosResult?.dataHoraRegisto ? new Date(selectedDocumentosResult?.dataHoraRegisto).toLocaleDateString() : '',
          'Técnico:' : selectedDocumentosResult?.tecnicoRegisto ?? '',
          'Entidade do Registo:' : selectedDocumentosResult?.entidade_id?.nome ?? '',

          //Indetificacao
          'IDENTIFICAÇÃO' : '',
          'Título:': selectedDocumentosResult?.titulo ?? '',
          'Entidade:': entidade ?? '',
          'Autores:': selectedDocumentosResult?.autores ?? '',
          'Data da publicação:': selectedDocumentosResult?.dataPublicacao ? new Date(selectedDocumentosResult?.dataPublicacao).toLocaleDateString() : '',

          //Localização
          'LOCALIZAÇÃO' : '',
          'Distrito:': selectedDocumentosResult?.distritos[0]?.nome ?? '',
          'Concelho:': selectedDocumentosResult?.concelhos[0]?.nome ?? '',
          'Freguesia:': selectedDocumentosResult?.freguesiasAll[0]?.nome ?? '',
          'Nuts1:': selectedDocumentosResult?.nuts1[0]?.nome ?? '',
          'Nuts2:': selectedDocumentosResult?.nuts2[0]?.nome ?? '',
          'Nuts3:': selectedDocumentosResult?.nuts3[0]?.nome ?? '',

          //Tipologia
          'TIPOLOGIA' : '',
          'Recurso:': selectedDocumentosResult?.recurso?.designacao ?? '',
          'Tipo de acesso:': selectedDocumentosResult?.tipoAcesso?.designacao ?? '',


          //Resumo
          'RESUMO' : '',
          'Resumo:': selectedDocumentosResult?.resumo ?? '',


          //Detalhe
          'DETALHES' : '',
          'Idioma:': selectedDocumentosResult?.idioma?.designacao ?? '',
          'Categoria temática:': selectedDocumentosResult?.categoriaTematica.map((ct: any) => ct.designacao).join(', ') ?? '',
          'Palavras chave:': selectedDocumentosResult?.palavraChave.map((pc: any) => pc.designacao).join(', ') ?? '',
          'Observações:': selectedDocumentosResult?.obs ?? '',

          // 'Url': selectedDocumentosResult?.url ?? '',
          // 'categorias': selectedDocumentosResult?.categorias.map((c: any) => c.designacao) ?? '',
     
        },      
        img_mapa: mapImage.replace("data:image/png;base64,", ""),
      });
  
      if (documentoBase64) {
        const link = document.createElement('a')
        link.href = 'data:application/octet-stream;base64,' + documentoBase64
        link.download = 'reporte_documento.pdf'
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        postMessage({ type: 'hideLoader' }, '*')
      }
    } catch (error) {
      postMessage({ type: 'errorMsg', value: { message: "Não foi possivel efetuar esta operação" } }, '*')
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

   const cancelImport = () => {
    setOpenImportModal(false)
  }

  const onItemClickImprimir = async (event: any) => {


    let mapImage = ''

    if (!window.location.href.includes('localhost')) {
      const mapIframe = document.querySelector(
        '#iframeGeoPortal'
      ) as HTMLIFrameElement

      const mapIframeInnerContent =
        mapIframe.contentWindow?.document.querySelector(
          'div.ol-viewport'
        ) as HTMLElement;

      const mapBussula =  mapIframe.contentWindow?.document.querySelector(
        'button.fixedNorthArrowButton'
      ) as HTMLElement;

      mapIframeInnerContent.appendChild(mapBussula);
      
      const generatedImage = await html2canvas(mapIframeInnerContent);
      mapImage = generatedImage.toDataURL()
    }

    
    const entidade = selectedDocumentosResult?.entidade?.nome ?? '';


    console.log(selectedDocumentosResult);

    try {
      postMessage({ type: 'showLoader' }, '*')

      const documentoBase64 = await relatorioDocumento({
        id: selectedDocumentosResult?.id,
        dados: {
          //Registo
          'REGISTO' : '',
          'Filedoc:': selectedDocumentosResult?.filedoc_cod ?? '',
          'Estado do Registo:' : selectedDocumentosResult?.estado_id?.designacao ?? '',
          'Data do registo:': selectedDocumentosResult?.dataHoraRegisto ? new Date(selectedDocumentosResult?.dataHoraRegisto).toLocaleDateString() : '',
          'Técnico:' : selectedDocumentosResult?.tecnicoRegisto ?? '',
          'Entidade do Registo:' : selectedDocumentosResult?.entidade_id?.nome ?? '',

          //Indetificacao
          'IDENTIFICAÇÃO' : '',
          'Título:': selectedDocumentosResult?.titulo ?? '',
          'Entidade:': entidade ?? '',
          'Autores:': selectedDocumentosResult?.autores ?? '',
          'Data da publicação:': selectedDocumentosResult?.dataPublicacao ? new Date(selectedDocumentosResult?.dataPublicacao).toLocaleDateString() : '',

          //Localização
          'LOCALIZAÇÃO' : '',
          'Distrito:': selectedDocumentosResult?.distritos[0]?.nome ?? '',
          'Concelho:': selectedDocumentosResult?.concelhos[0]?.nome ?? '',
          'Freguesia:': selectedDocumentosResult?.freguesiasAll[0]?.nome ?? '',
          'Nuts1:': selectedDocumentosResult?.nuts1[0]?.nome ?? '',
          'Nuts2:': selectedDocumentosResult?.nuts2[0]?.nome ?? '',
          'Nuts3:': selectedDocumentosResult?.nuts3[0]?.nome ?? '',

          //Tipologia
          'TIPOLOGIA' : '',
          'Recurso:': selectedDocumentosResult?.recurso?.designacao ?? '',
          'Tipo de acesso:': selectedDocumentosResult?.tipoAcesso?.designacao ?? '',


          //Resumo
          'RESUMO' : '',
          'Resumo:': selectedDocumentosResult?.resumo ?? '',


          //Detalhe
          'DETALHES' : '',
          'Idioma:': selectedDocumentosResult?.idioma?.designacao ?? '',
          'Categoria temática:': selectedDocumentosResult?.categoriaTematica.map((ct: any) => ct.designacao).join(', ') ?? '',
          'Palavras chave:': selectedDocumentosResult?.palavraChave.map((pc: any) => pc.designacao).join(', ') ?? '',
          'Observações:': selectedDocumentosResult?.obs ?? '',

          // 'Url': selectedDocumentosResult?.url ?? '',
          // 'categorias': selectedDocumentosResult?.categorias.map((c: any) => c.designacao) ?? '',
     
        },      
        img_mapa: mapImage.replace("data:image/png;base64,", ""),
      });

      if (documentoBase64) {
        const blob = b64toBlob(documentoBase64, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
        postMessage({ type: 'hideLoader' }, '*')
      }
    } catch (error) {
      postMessage({ type: 'errorMsg', value: { message: "Não foi possivel efetuar esta operação" } }, '*')
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
    
  }

  const close = () => {
    setOpenForm(false);
  }

  useLayoutEffect(() => {

    const handleResize = () => {

      if (window.innerWidth <= telaMobile) {
        
        setIsMobile(true);
        ocultaFloatingMenu_e_Mapa();

      }else{

        setIsMobile(false);
        mostraFloatingMenu_e_Mapa();

      }

    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []); 

  const onItemClickMais = (event: any) => {

    elementoClickMais(event);

    onEditItemClick(event);
    onImportGeomClick(event);
    onItemClick(event);
    
    if(optionsExportar.some(option => option.key === event.item.key)){
      onItemClickExportar(event);
    }

  };

  const uploadDocumento = async (data: any) => post<any, any>(UPLOAD_DOCUMENTO, data)
  
  const handleSubmitNewDocumento = async (values: Record<string, any>) => {
    try {
      postMessage({ type: 'showLoader' }, '*')

      const arquivo = await blobToBase64(values?.arquivo[0].getRawFile())
      const nomeArquivo = values?.arquivo[0].name
      const userId = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '') ?.user?.id
      const dataRegisto = selectedDocumentosResult?.dataHoraRegisto ? new Date(selectedDocumentosResult?.dataHoraRegisto) : new Date()

      const selectdDocumento = {
        id: getDocumentoSelected()?.id,
        entidade_id: getDocumentoSelected()?.entidade_id?.id,
        estado_id: getDocumentoSelected()?.estado_id?.id,
        filedoc_cod: getDocumentoSelected()?.filedoc_cod,
        data_registo: dataRegisto,
        tipo_acesso_id: getDocumentoSelected()?.tipo_acesso_id?.id,
        tipoAcesso: getDocumentoSelected()?.tipoAcesso,
        modulo_id: getDocumentoSelected()?.modulo_id,
        ocorrencia_id: getDocumentoSelected()?.ocorrencia_id,
        intervencao_id: getDocumentoSelected()?.intervencao_id,
        acao_id: getDocumentoSelected()?.acao_id,
        candidatura_id: getDocumentoSelected()?.candidatura_id,
        mobilizacao_id: getDocumentoSelected()?.mobilizacao_id,
        titulo: getDocumentoSelected()?.titulo,
        resumo: getDocumentoSelected()?.resumo,
        url: getDocumentoSelected()?.url,
        tipo_recurso_id: getDocumentoSelected()?.tipo_recurso_id?.id,
        tipoRecurso: getDocumentoSelected()?.tipoRecurso,
        autores: getDocumentoSelected()?.autores,
        data_publicacao: getDocumentoSelected()?.data_publicacao,
        idioma_id: getDocumentoSelected()?.idioma_id?.id,
        idioma: getDocumentoSelected()?.idioma,
        observacoes: getDocumentoSelected()?.observacoes,
        path: getDocumentoSelected()?.path,
        geom_imported: getDocumentoSelected()?.geom_imported,
        flatCoordinates: getDocumentoSelected()?.flatCoordinates,
      }
      console.log("getDocumentoSelected()?.url")
      console.log(getDocumentoSelected()?.url)
      let documentoUpload: any
      if ( selectdDocumento?.id) {
     const documentoUpdated : any = await updateDocumento({
        ...selectdDocumento,
        utilizador_id: userId,
        base64arquivo: arquivo,
        nomearquivo: nomeArquivo,
      })
      setDocumentoSelected(documentoUpdated);
      selectDocumentosResult(documentoUpdated);

       documentoUpload = await uploadDocumento({
        arquivo: arquivo,
        nome_arquivo: nomeArquivo,
        id: selectdDocumento?.id
      });

    } else {
      const documentSaved : any = await saveDocumento({
        ...selectdDocumento,
        utilizador_id: userId,
        base64arquivo: arquivo,
        nomearquivo: nomeArquivo});

        setDocumentoSelected(documentSaved);
        selectDocumentosResult(documentSaved);
        selectDocumentosResultId(documentSaved.id);

        await uploadDocumento({
          arquivo: arquivo,
          nome_arquivo: nomeArquivo,
          id: documentSaved?.id
        });
      }
  
      setNewDocumento(nomeArquivo)
      SessionStorageService.setItem("documentoAnexado", "true");
      postMessage({ type: 'sucessMsg' }, '*')


    } catch (error: any) {
      if (error?.response?.status == PERMISSION_ERROR_STATUS_CODE || 
        error?.response?.status == NOT_AUTHORIZED_ERROR_STATUS_CODE) {
        postMessage({ type: "errorMsg", value: { message: "Não tem permissões para essa funcionalidade" } }, "*");
      } else {
        postMessage(
          {
            type: 'errorMsg',
            value: { message: 'Não foi possivel efetuar esta operação' },
          },
          '*'
        )
      }
    } finally {

      postMessage({ type: 'hideLoader' }, '*')
      closeNewDocumentoDialog()

    }
  }

  const handleSubmitNewExternalDocumento = async (values: Record<string, any>) => {
    try {
      postMessage({ type: 'showLoader' }, '*')

      const arquivo = values?.arquivo
      const nomeArquivo = arquivo.substring(arquivo.lastIndexOf('/') + 1)
      const userId = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '') ?.user?.id
      const dataRegisto = selectedDocumentosResult?.dataHoraRegisto ? new Date(selectedDocumentosResult?.dataHoraRegisto) : new Date()

      const selectdDocumento = {
        id: getDocumentoSelected()?.id,
        entidade_id: getDocumentoSelected()?.entidade_id?.id,
        estado_id: getDocumentoSelected()?.estado_id?.id,
        filedoc_cod: getDocumentoSelected()?.filedoc_cod,
        data_registo: dataRegisto,
        tipo_acesso_id: getDocumentoSelected()?.tipo_acesso_id?.id,
        tipoAcesso: getDocumentoSelected()?.tipoAcesso,
        modulo_id: getDocumentoSelected()?.modulo_id,
        ocorrencia_id: getDocumentoSelected()?.ocorrencia_id,
        intervencao_id: getDocumentoSelected()?.intervencao_id,
        acao_id: getDocumentoSelected()?.acao_id,
        candidatura_id: getDocumentoSelected()?.candidatura_id,
        mobilizacao_id: getDocumentoSelected()?.mobilizacao_id,
        titulo: getDocumentoSelected()?.titulo,
        resumo: getDocumentoSelected()?.resumo,
        url: getDocumentoSelected()?.url,
        tipo_recurso_id: getDocumentoSelected()?.tipo_recurso_id?.id,
        tipoRecurso: getDocumentoSelected()?.tipoRecurso,
        autores: getDocumentoSelected()?.autores,
        data_publicacao: getDocumentoSelected()?.data_publicacao,
        idioma_id: getDocumentoSelected()?.idioma_id?.id,
        idioma: getDocumentoSelected()?.idioma,
        observacoes: getDocumentoSelected()?.observacoes,
        path: getDocumentoSelected()?.path,
        geom_imported: getDocumentoSelected()?.geom_imported,
        flatCoordinates: getDocumentoSelected()?.flatCoordinates,
      }
      if (getDocumentoSelected()?.id) {
      const DocumentoUpdated : any = await updateDocumento({
        ...selectdDocumento,
        utilizador_id: userId,
        url: arquivo,
        nomearquivo: nomeArquivo,
      })
      setDocumentoSelected(DocumentoUpdated);
      selectDocumentosResult(DocumentoUpdated);

    } else {
      const DocumentoSaved : any = await saveDocumento({
        ...selectdDocumento,
        utilizador_id: userId,
        url: arquivo,
        nomearquivo: nomeArquivo,
        data_registo: new Date()
      })
      setDocumentoSelected(DocumentoSaved);
      selectDocumentosResult(DocumentoSaved);
      refreshForm(getDocumentoSelected()?.id);
    }

      setNewDocumento(nomeArquivo)
      SessionStorageService.setItem("documentoAnexado", "true");
      postMessage({ type: 'sucessMsg' }, '*')
      

    } catch (error: any) {
      if (error?.response?.status == PERMISSION_ERROR_STATUS_CODE || 
        error?.response?.status == NOT_AUTHORIZED_ERROR_STATUS_CODE) {
        postMessage({ type: "errorMsg", value: { message: "Não tem permissões para essa funcionalidade" } }, "*");
      } else {
        postMessage(
          {
            type: 'errorMsg',
            value: { message: 'Não foi possivel efetuar esta operação' },
          },
          '*'
        )
      }
    } finally {

      postMessage({ type: 'hideLoader' }, '*')
      closeNewDocumentoDialog()

    }
  }

  const optionsMais = [
    {
      key: 'titulo',
      text: 'Registo',
    },
    ...optionsEditar.map(option => ({
      ...option,
      className: 'icon-siarl icon-editar btnIconRegistar',
    })),
    {
      key: 'titulo',
      text: 'Geometria',
    },
    {
      key: 'titulo',
      text: 'Documentos',
    },
    ...options.map(option => ({
      ...option,
      className: 'icon-siarl icon-documentos btnIconRegistar',
    })),    
    {
      key: 'titulo',
      text: 'Área',
    },
    ...optionsArea.map(option => ({
      ...option,
      className: 'icon-siarl icon-area btnIconRegistar',
    })),
    {
      key: 'titulo',
      text: 'Imprimir',
    },
    {
      key: 'imprimirFicha',
      text: 'Fichas',
      className: 'icon-siarl icon-imprimir btnIconRegistar'
    },
    {
      key: 'titulo',
      text: 'Exportar',
    },
    ...optionsExportar.map(option => ({
      ...option,
      className: 'icon-siarl icon-exportar btnIconRegistar',
    })),
  ]

  const [tituloDocumento, setTituloDocumento] = useState(false);

  const handleAlteraTitulo = () => {
    setTituloDocumento(true);
  };

  const onClickOpenFileDoc = async() => {
      if (!selectedDocumentosResult?.Idfiledoc) {
        postMessage({ type: "infoMsg", value: {message: "É necessário preencher o nº processo APA (filedoc)."} }, "*");
        return;
      }
      postMessage({ type: 'showLoader' }, '*');
      const urlFileDoc = await getUrlFileDoc(selectedDocumentosResult?.Idfiledoc);
      postMessage({ type: 'hideLoader' }, '*')
      window.open(urlFileDoc, '_blank');
      return;
  }

  const addNewOrDeleteDocument =  () => {

    if(SessionStorageService.getItem("documentoAnexado") == 'false'){
     setShowNewDocumentoDialog(true)
    }
    else if(SessionStorageService.getItem("documentoAnexado") == 'true'){
      setShowModalApagarDocumento(true)
    }
  }

  
  const closeNewDocumentoDialog = () => {
    setShowNewDocumentoDialog(false)
  }

  const openAjuda = () => {
    window.open(URL_AJUDA_DOCUMENTOS_AVANCADA, "_blank");
  }

  const isDocumentoAnexado = SessionStorageService.getItem("documentoAnexado") == 'false';

  const title = isDocumentoAnexado ? "Adicionar Documento" : "Remover Documento";

  const hasValidExtension = (url: string | undefined): boolean => {
    return url ? /\.(\w{3,4})$/i.test(url) : false;
  }

  return (
    <>
      {urlDoDocumento && (
        <DocumentPreview
          documentUrl={urlDoDocumento}
          setUrlDoDocumento={setUrlDoDocumento}
        />
      )}

      {!urlDoDocumento && (
        <div className={styles.base}>
          <div className={styles.header}>
            <div>
              <div className={styles.nomeDoModulo}>Documentos</div>
              <div className={styles.tituloDaPagina}>
              {!selectedDocumentosResult || !selectedDocumentosResult?.id ? 'Novo Documento' : (tituloDocumento ? 'Editar Documento' : 'Documento')}

              </div>
            </div>
            <div>

            {!viewMode ? (
            <Button
              onClick={addNewOrDeleteDocument}
              className={styles.btnNovoRegisto}
              style={{ display: isMobile ? 'none' : '' }}
              id='adicionarFicheiro'
              title={title}          >
              <span className={title === "Adicionar Documento" ? "icon-siarl icon-registar btnIconRegistar" : "icon-siarl icon-descartar btnIconRegistar"}></span>{' '}
              &nbsp;{' '}
              {title == "Adicionar Documento" ? "Adicionar Documento" : "Remover Documento"}
            </Button>
          ) : (
            <></>
          )}

          {!isMobile && canUpdate && (viewMode || editMode) && (SessionStorageService.getItem("novoDocumento") != 'true') ? 
          <DropDownButton
            items={optionsEditar}
            className={styles.btnEditar2}
            iconClass="icon-siarl icon-editar"
            onItemClick={onEditItemClick}
            fillMode={'flat'}
            itemRender={itemRender}
            title="Editar"
          ></DropDownButton>
          : ''}
            
            {!isMobile && (selectedDocumentosResult?.path != undefined && selectedDocumentosResult?.path.trim() !== "" || 
           (selectedDocumentosResult?.url !== undefined && selectedDocumentosResult?.url.trim() !== "" && hasValidExtension(selectedDocumentosResult?.url))) &&
              <DropDownButton
                items={options}
                className={styles.btnEditar2}
                iconClass="icon-siarl icon-documentos"
                title='Documento'
                onItemClick={onItemClick}
                fillMode={'flat'}
                itemRender={itemRender}
              ></DropDownButton>
            }

          <button title="Filedoc" className={styles.btnEditar2} onClick={onClickOpenFileDoc} 
          style={{ display: isMobile ? 'none' : '' }} id='openFileDoc'>
            <span className="icon-siarl icon-filedoc"></span>
          </button>

            {!isMobile &&
              <DropDownButton
                items={optionsArea}
                className={styles.btnEditar2}
                title='Geometria - Área'
                iconClass="icon-siarl icon-area"
                onItemClick={onItemClick}
                fillMode={'flat'}
                itemRender={itemRender}
                disabled={viewMode}

              ></DropDownButton>
              }

              <button className={styles.btnEditar2} onClick={onItemClickImprimir}
              style={{ display: isMobile ? 'none' : '' }} id='imprimirFicha' title='Imprimir'>
                <span className="icon-siarl icon-imprimir"></span>
              </button>

              <button className={styles.btnImprimir} title='Exportar' onClick={onItemClickExportar}
           style={{ display: isMobile ? 'none' : '' }} id='exportarFicha'>
            <span className="icon-siarl icon-exportar"></span>
          </button>

          {isMobile &&
            <DropDownButton
              items={optionsMais}
              className={styles.btnMais}
              iconClass="icon-siarl icon-mais"
              onItemClick={onItemClickMais}
              fillMode={'flat'}
              itemRender={itemRenderMais}
              title="Mais Opções"
            />
          }

              <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
                <span className="icon-siarl icon-ajuda"></span>
              </button>

              <button className={styles.btnFechar} onClick={voltar} title="Fechar">
                <span className="icon-siarl icon-fechar"></span>
              </button>
            </div>
          </div>

           {openImportModal ?
        <ImportarGeometriaComponent onSubmit={importGeo} cancelImport={cancelImport} /> : <></>
      }

{openRemoveAreaModal ? (
               <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={() => setOpenRemoveAreaModal(false)}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                    <span className="textDescartar">Remover área </span>
                    <br></br>
                    <span>{'Tem a certeza que pretende remover a área selecionada?'}</span>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={() => setOpenRemoveAreaModal(false)}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={removeArea}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}


      {openModalDescartar ? (
          <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={cancelModalDescartar}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                  <fieldset>
                    <span className="textDescartar">{deleteClicked ? 'Apagar' : 'Cancelar'} registo  </span>
                    <br></br>
                    <span>{deleteClicked ? 'Ao apagar este registo deixará de ter acesso, sendo necessária uma intervenção rigorosa para realizar a sua recuperação.' : 'Ao cancelar este registo irá perder os dados inseridos até ao momento.'}</span>
                      <br></br>
                    <span>{deleteClicked ? 'Tem a certeza que pretende apagar?' : 'Tem a certeza que pretende cancelar?'}</span>
                  </fieldset>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={cancelModalDescartar}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={handleDescartar}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}

        {openModalApagarDocumento ? (
            <Dialog
              
              className={styles.dialogDesconformidades}
              onClose={cancelModalApagarDocumento}
            >
              
              <Form
                initialValues={
                  {
                  } 
                }
                render={(formRenderProps) => (
                  <FormElement style={{ maxWidth: 500 }}>
                    <fieldset>
                      <span className="textDescartar">Remover Ficheiro  </span>
                      <br></br>
                      <span>Tem a certeza que deseja remover o ficheiro?</span>
                    </fieldset>
                    <div className="k-form-buttons btnModal">         
                      <button
                        type={'submit'}
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                        onClick={cancelModalApagarDocumento}
                      >
                        Não
                      </button>

                      <button
                        type={'submit'}
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                        onClick={handleApagarDocumento}
                      >
                        Sim
                      </button>
                    </div>
                  </FormElement>
                )}
              />
            </Dialog>
          ) : (
            <></>
          )}

          <Form
            onSubmitClick={handleSubmit}
            initialValues={
              {
                ...selectedDocumentosResult,
                tecnicoRegisto: username,
                entidade_registo: entidadeUser,
                Iddoc: getDocumentoSelected()?.id,
                //entidadeByUser: entidadeByUser,
                dataHoraRegisto: selectedDocumentosResult?.id && selectedDocumentosResult?.dataHoraRegisto ? new Date(selectedDocumentosResult?.dataHoraRegisto) : new Date(),
                dataPublicacao: selectedDocumentosResult?.id && selectedDocumentosResult?.dataPublicacao ? new Date(selectedDocumentosResult?.dataPublicacao) : undefined,
                Resumo: selectedDocumentosResult?.id && selectedDocumentosResult?.resumo ? selectedDocumentosResult?.resumo : undefined,
                estado_id : estados ? estados[0] : undefined
              } ?? undefined
            }
            
            render={(formRenderProps) => (
              <>
                <FormElement
                  className={clsx(
                    selected === 1 && !identificacaoTabIsValid && 'tab-error',
                    selected === 1 && !detalheTabIsValid && 'tab-error'
                  )}
                >
                  <TabStrip
                    selected={selected}
                    onSelect={handleSelect}
                    className={`${styles.innerStyleTabContent} ${styles.backgroundWhite} ${viewMode? 'tabsVisualizar' : ''}`}
                    scrollable={isMobile}
                  >
                    <TabStripTab title="Identificação">
                      <DocumentoIdentificacao
                        formRenderProps={formRenderProps} setNewDocumento={setNewDocumento} newDocumento={newDocumento} viewMode={viewMode}
                      />
                    </TabStripTab>
                    <TabStripTab title="Detalhes">
                      <DocumentoDetalhe viewMode={viewMode} />
                    </TabStripTab> 
                    <TabStripTab title="Associação">
                      <AssociacaoListagem pageName='documentos' viewMode={viewMode}/>
                    </TabStripTab>
                  </TabStrip>

                 {(editMode || SessionStorageService.getItem("novoDocumento") == 'true')
                  && !viewMode ?
                  <div
                    className={`${styles.footer} ${styles.justify_multiple_children}`}
                  >

                    <Button type="button" className={styles.btnSeguinte} onClick={handleOpenModalDescartar} title='Cancelar'>
                        <span className="icon-siarl icon-fechar"></span>
                        <span className={styles.rotulo}>
                          {' '}&nbsp;{' '}
                          Cancelar
                        </span>
                    </Button>

                    <Button
                      className={styles.btnSubmeter}
                       onClick={(e) => {
                    formRenderProps.onSubmit(e);
                    SessionStorageService.setItem("isSubmitActionDocumentos", "true");
                    e.preventDefault();
                  }}
                  title='Submeter'
                    >
                      <span className="icon-siarl icon-submeter btnIconRegistar"></span>
                      <span className={styles.rotulo}>
                        {' '}&nbsp;{' '}
                        Submeter
                      </span>
                    </Button>
                  </div>
                  : ''}
                </FormElement>
              </>
            )}
          />
        </div>
      )}
      {openForm && (
        <Dialog
          className="ModalDocumentos"
          title={`Documento: ${selectedDocumentosResult?.id}`}
          onClose={close}
          style={{ zIndex: 999 }}
        >
          <ImagePreviewV2
            documentUrl={selectedDocumentosResult?.url} next={function (e: any): void {
              console.log(e)
            } } previous={function (e: any): void {
              console.log(e)
            } }          />

        </Dialog>
      )}

    {showNewDocumentoDialog && (
        <Dialog title={`Adicionar documento`} onClose={closeNewDocumentoDialog} style={{ zIndex: 999 }}>
          <EditDocumentoFormTabsSimplified
            handleSubmitDocumento={handleSubmitNewDocumento}
            handleSubmitExternalDocumento={handleSubmitNewExternalDocumento}
          />
        </Dialog>
      )}
    </>
  )
}

