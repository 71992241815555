import { coordenadasPoligono, eaEstado, hasPermission, post, setCoordenadasPoligono, setEaRegistoAnalise, setEaResultado, tecnico, tecnicos } from '../../services/utils';
import styles from '../../styles/estrategiaAdaptacao/EstrategiaAdaptacao.module.scss'
import {
    MouseEvent as ReactMouseEvent,
    useCallback,
    useEffect,
    useLayoutEffect,
    useState
} from 'react'
import {
    Field,
    Form,
    FormElement,
    FormRenderProps,
} from '@progress/kendo-react-form'
import { AREA_VISIVEL, CONSULTAR_ANALISE, NENHUM, PERMISSION_INSERIR_ESTRATEGIA_ADAPTACAO, POLIGONO_SELECAO, URL_AJUDA_ESTRATEGIA_ADAPTACAO } from '../../utils/constants';
import { Button, DropDownButton } from '@progress/kendo-react-buttons';
import SessionStorageService from '../../services/SessionStorageService';
import FieldsetCombosLocalizacao from '../localizacao/FieldsetCombosLocalizacao';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { Input, RadioButton, RadioButtonChangeEvent, RadioGroup } from '@progress/kendo-react-inputs';
import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import { useEstrategiaAdaptacaoStore } from '../../stores/estrategiaAdaptacao';
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais';
import ReactGA from 'react-ga4';
import clsx from 'clsx';
import { filterBy } from '@progress/kendo-data-query';

type Props = {
    onCloseClick: (
        e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
    ) => void | undefined
}
export default function ConsultarEstrategiaAdaptacaoForm({ onCloseClick }: Props) {

  type combosKeys= 'eaEstado' | 'tecnicos';

    const [combos, setCombos] = useState<any>({
      eaEstado,
      tecnicos
    })


    const setDataOption: Record<combosKeys, { setFn: any, data: any[] }> = {
      eaEstado: {
        setFn: setCombos,
        data: eaEstado
      },
      tecnicos: {
        setFn: setCombos,
        data: tecnicos
      }
    };

  const filterCombo_Multiselect = (e: any, tipoCombo: combosKeys) => {
      const { setFn, data } = setDataOption[tipoCombo];
      setFn({
          ...combos,
          [tipoCombo]: filterBy(data?.slice()??[], e.filter)
      }
      );
  }


  const [isMobile, setIsMobile] = useState(false);
    const selectEstrategiaResultId = useEstrategiaAdaptacaoStore(
      (state) => state.selectEstrategiaResultId
    )

    const selectEstrategiaResult = useEstrategiaAdaptacaoStore(
      (state) => state.selectEstrategiaResult
    )
    const registarNovoClick = () => {
      sessionStorage.setItem('novaAnaliseEstrategiaAdaptacao', 'true')
      sessionStorage.setItem('analiseAntiga', 'false')
      setEaRegistoAnalise(undefined);
      selectEstrategiaResult(undefined);
      selectEstrategiaResultId(undefined);
      setEaResultado(false);
      postMessage({ type: 'form', value: 'novaAnaliseEstrategiaAdaptacao' }, '*')
    }

    const [registoVisible, setRegistoVisible] = useState(true)
    const [identificacaoVisible, setIdentificacaoVisible] = useState(false)
    const [CritGeograficosVisible, setCritGeograficosVisible] = useState(false)
    const [rbCriteriosSelected, setRBCriteriosSelected] = useState<any>()
    const [dataDeRegisto, setDataDeRegisto] = useState<any>()
    const [dataAteRegisto, setDataAteRegisto] = useState<any>()
    const [dataDeRelatorio, setDataDeRelatorio] = useState<any>()
    const [dataAteRelatorio, setDataAteRelatorio] = useState<any>()
    const [minData, setMinDate] = useState<any>();
    const [minDataRelatorio, setMinDataRelatorio] = useState<any>();

    const getAnalise = async (data: any) => await post<any, any>(`${CONSULTAR_ANALISE}`, data)

    const clearMap = () => {
      try {
  
        setCoordenadasPoligono(undefined)
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: "true" }, '*')
        iframe.contentWindow?.postMessage({ type: "SiarlDeselectMapArea", value: "true" }, '*')
  
      } catch (error) {
        console.log({ error })
      }
    }

     const changerbCriterio = useCallback(
    (e: RadioButtonChangeEvent) => {
      const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlDeselectMapArea", value: 'true' }, '*')
      setRBCriteriosSelected(e.value)
      if (e.value == POLIGONO_SELECAO) {
        postMessage({ type: "informationMsg", value: "Selecione a área de pesquisa" }, "*");
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage({ type: "SiarlGeoPortalAdicionarArea", value: 'true' }, '*')
      }
      if (e.value == AREA_VISIVEL) {
        postMessage({ type: "informationMsg", value: "Foi selecionada a área visível do mapa" }, "*");
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage({ type: "SiarlGetMapArea", value: 'true' }, '*')
        iframe.contentWindow?.postMessage({ type: "SiarlSelectMapArea", value: 'true' }, '*')
      }
    },
    [setRBCriteriosSelected]
  )
    useEffect(() => {
      ReactGA.send({ hitType: "pageview", page: "/ConsultarEstrategiaAdaptacao" });
    }, [])

    const handleSubmit = async (e: any) => {

     try {
      postMessage({ type: 'showLoader' }, '*')

      const objToSearch = {
        id: e.values?.id,
              utilizador_id: e.values?.utilizador_id?.id ?? undefined,
              data_hora_relatorio_de: e.values?.data_hora_relatorio_de ?? undefined,
              filedoc_cod: e.values?.filedoc_cod ?? undefined,
              titulo: e.values?.titulo ?? undefined,
              estado_id: e.values?.estado_id?.id ?? undefined,
              data_hora_relatorio_ate: e.values?.data_hora_relatorio_ate ?? undefined,
              data_registo_de: e.values?.data_registo_de ?? undefined,
              data_registo_ate: e.values?.data_registo_ate ?? undefined,
              status: e.values?.status?.valor ?? undefined,
              freguesia_cod: e.values?.freguesia?.codigo ?? undefined,
              concelho_cod: e.values?.concelho?.codigo ?? undefined,
              distrito_cod: e.values?.distrito?.codigo ?? undefined,
              nuts1_cod: e.values?.nuts1?.codigo ?? undefined,
              nuts2_cod: e.values?.nuts2?.codigo ?? undefined,
              nuts3_cod: e.values?.nuts3?.codigo ?? undefined,
              capitania_cod: e.values?.capitania?.codigo ?? undefined,
              arh_cod: e.values?.arh?.codigo ?? undefined,
              massa_agua_cod: e.values?.massaAgua?.codigo ?? undefined,
              criterio_geografico: rbCriteriosSelected ?? null,
              flatCoordinates: coordenadasPoligono
      }

      SessionStorageService.setItem("lastSearchEstrategiaAdaptacao", objToSearch);
      SessionStorageService.setItem("firstCallAnalise", true);

      const result: any = await getAnalise(objToSearch);

     if (result) {
        const ids: number[] = [];
        result.forEach((element: any) => {
          ids.push(element.id);
        });
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage({ type: "SiarlEaRegistoAnaliseSearch", value: ids }, '*')

        SessionStorageService.setItem(
          'resultadoEstrategiaAdaptacaoPesquisa',
          result
        )

        postMessage({ type: 'resultadoEstrategiaAdaptacaoPesquisaChange' }, '*');
      }

    } catch (error) {
      postMessage({ type: 'errorMsg', value:{message:"Não foi possivel efetuar esta operação"} }, '*')
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  useLayoutEffect(() => {

    const handleResize = () => {

      if (window.innerWidth <= telaMobile) {
        
        setIsMobile(true);
        ocultaFloatingMenu_e_Mapa();

      }else{

        setIsMobile(false);
        mostraFloatingMenu_e_Mapa();

      }

    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  const onItemClickMais = (event: any) => {

    elementoClickMais(event);

  };

  const optionsMais = [
    {
      key: 'novoRegisto',
      text: 'Nova análise',
      elementType: 'button',
      className: 'icon-siarl icon-registar btnIconRegistar'
    }
  ]

  const openAjuda = () => {
    window.open(URL_AJUDA_ESTRATEGIA_ADAPTACAO, "_blank")
  }
    
    return (
        <div className={styles.base}>
            <div className={clsx(styles.header, styles.backgroundWhite)}>

                <div>
                    <div className={styles.nomeDoModulo}>
                        Estratégia de adaptação
                    </div>
                    <div className={styles.tituloDaPagina}>
                        Critérios de pesquisa
                    </div>
                </div>

                <div>
                    {hasPermission(PERMISSION_INSERIR_ESTRATEGIA_ADAPTACAO) ? 
                        <Button onClick={registarNovoClick} className={styles.btnNovoRegisto}
                        style={{ display: isMobile ? 'none' : '' }}
                        id='novoRegisto' title='Nova Análise'>
                            <span className="icon-siarl icon-registar btnIconRegistar"></span>{' '}
                            &nbsp;{' '}
                            Nova Análise
                        </Button>
                        : '' }
                         
            {isMobile &&
              <DropDownButton
                items={optionsMais}
                className={styles.btnMais}
                iconClass="icon-siarl icon-mais"
                onItemClick={onItemClickMais}
                fillMode={'flat'}
                itemRender={itemRenderMais}
                title="Mais Opções"
              />
            }
                    <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
                        <span className="icon-siarl icon-ajuda"></span>
                    </button>
                    <button className={styles.btnFechar} onClick={onCloseClick} title="Minimizar">
                        <span className="icon-siarl icon-minimizar"></span>
                    </button>
                </div>
            </div>

            <Form
            onSubmitClick={handleSubmit}
                initialValues={
                    undefined
                }
                render={(formRenderProps: FormRenderProps) => (
                    <>
                        <FormElement className={styles.form}>
                              <fieldset>

                <legend>
                  <button type='button' className={styles.itemExpandBase} onClick={() => { setRegistoVisible(!registoVisible) }}>
                    <div className={styles.btn_Expandir_Colapsar}>
                      <span className={registoVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                    </div>
                    <span className={styles.fieldsetTitulo}>Registo</span>
                  </button>
                </legend>

                {registoVisible && (
                    <div className={styles.espacoVerticalFieldSet}>
                  <div className={styles.fields}>
                    <div>
                      <Field name="id" label="ID Documento" component={Input} />
                    </div>
                    <div>
                      <Field
                        name="filedoc_cod"
                        label="N.º Processo APA"
                        component={Input}
                      />
                    </div>
                    <div>
                      <Field
                        name="estado_id"
                        label="Estado"
                        component={ComboBox}
                        textField={'designacao'}
                        data={combos?.eaEstado}
                        filterable={true}
                        onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'eaEstado')}}
                      />
                    </div>
                  </div>
                  <div className={styles.fields}>
                    <div>
                        <Field
                        name="utilizador_id"
                        label="Técnico"
                        component={ComboBox}
                        textField={'name'}
                        data={combos?.tecnicos}
                        filterable={true}
                        onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'tecnicos')}}
                        />
                </div>
                  </div>
                  <div className={styles.fields}>
                   <div>
                    <Field
                      name="data_registo_de"
                      label="De"
                      format="dd/MM/yyyy HH:mm"
                      component={DateTimePicker}
                      onChange={(e) => {
                        setDataDeRegisto(e.value)
                         setMinDate(e.value);
                      }}     
                    />
                  </div>
                  <div>
                    <Field
                      name="data_registo_ate"
                      label="Até"
                      format="dd/MM/yyyy HH:mm"
                      component={DateTimePicker}
                      onChange={(e) => {
                        setDataAteRegisto(e.value)
                      }}
                      min={minData}
                    />
                  </div>
                  </div>
                </div>
                )}
              </fieldset>

              <fieldset>

                <legend>
                  <button type='button' className={styles.itemExpandBase} onClick={() => { setCritGeograficosVisible(!CritGeograficosVisible) }}>
                    <div className={styles.btn_Expandir_Colapsar}>
                      <span className={CritGeograficosVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                    </div>
                    <span className={styles.fieldsetTitulo}>Critério geográfico</span>
                  </button>
                </legend>

                {CritGeograficosVisible && (

                  <div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}>
                    <Field
                      id={'rbCriterio'}
                      name={'rbCriterio'}
                      layout={'horizontal'}
                      component={RadioGroup}
                      onChange={(e) => {
                        changerbCriterio(e)
                      }}
                      data={[
                        { label: 'Nenhum', value: NENHUM},
                        { label: 'Área visível', value: AREA_VISIVEL },
                        { label: 'Polígono de seleção', value: POLIGONO_SELECAO },
                      ]}
                      defaultValue={NENHUM} // Set the default selected value to 0 (Nenhum)
                    />
                  </div>

                )}

                </fieldset>

              <FieldsetCombosLocalizacao isPesquisaAvancada={true} styles={styles} formRenderProps={formRenderProps}/>


                 <fieldset>

                <legend>
                  <button type='button' className={styles.itemExpandBase} onClick={() => { setIdentificacaoVisible(!identificacaoVisible) }}>
                    <div className={styles.btn_Expandir_Colapsar}>
                      <span className={identificacaoVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                    </div>
                    <span className={styles.fieldsetTitulo}>Identificação</span>
                  </button>
                </legend>

                {identificacaoVisible && (

                  <div className={styles.espacoVerticalFieldSet}>
                    <div>
                      <Field
                        name="titulo"
                        label="Titulo"
                        component={Input}
                      />
                    </div>

                <div style={{ marginTop: 15 }}>

                <div className={styles.fieldsetSubTitulo}>
                    Data de relatório
                </div>

                    <div className={styles.fields}>
                   <div>
                    <Field
                      name="data_hora_relatorio_de"
                      label="De"
                      component={DateTimePicker}
                      value={dataDeRelatorio}
                      format="dd/MM/yyyy HH:mm"
                      onChange={(e) => {
                        setDataDeRelatorio(e.value)
                         setMinDataRelatorio(e.value);
                      }}                         />
                  </div>
                  <div>
                    <Field
                      name="data_hora_relatorio_ate"
                      label="Até"
                      component={DateTimePicker}
                      value={dataAteRelatorio}
                      format="dd/MM/yyyy HH:mm"
                      onChange={(e) => {
                        setDataAteRelatorio(e.value)
                      }}            
                      min={minDataRelatorio}
                       />
                  </div>
                  </div>
                  </div>
                  </div>

                )}

              </fieldset>

                        </FormElement>
                        <div className={`${styles.footer} ${styles.justify_multiple_children}`}>
                            <Button
                                onClick={(e: any) => {
                                    formRenderProps.onFormReset();
                                    clearMap()
                                }}
                                className={styles.btnLimparPesquisa}
                                title='Limpar pesquisa'
                            >
                                <span className="icon-siarl icon-limparcampos"></span>
                                <span className={styles.rotulo}>
                                  {' '}&nbsp;{' '}
                                  Limpar pesquisa
                                </span>
                            </Button>
                            <Button
                                onClick={formRenderProps.onSubmit}
                                className={styles.btnPesquisar}
                                title='Pesquisar'
                            >
                                <span className="icon-siarl icon-pesquisar"></span>
                                <span className={styles.rotulo}>
                                  {' '}&nbsp;{' '}
                                  Pesquisar
                                </span>
                            </Button>
                        </div>

                    </>
                )}
            />
        </div>

    );
    
}
