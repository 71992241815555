import {
  faEye,
  faRemove,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PagerTargetEvent } from '@progress/kendo-react-data-tools'
import { Dialog } from '@progress/kendo-react-dialogs'
import { Form, FormElement, FormRenderProps } from '@progress/kendo-react-form'
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridPageChangeEvent,
  GridToolbar,
  GridNoRecords
} from '@progress/kendo-react-grid'
import { useEffect, useLayoutEffect, useState } from 'react'
import {
  blobToBase64,
  estados,
  fillEstadoAsync,
  fillIdiomasAsync,
  fillTipoAcessoAsync,
  fillTipoRecursoAsync,
  fillTiposProcessoAsync,
  get,
  del,
  getAcaoSelected,
  getCandidaturaSelected,
  getDemarcacaoSelected,
  getDesconformidadeSelected,
  getEaRegistoAnalise,
  getIntervencaoSelected,
  getMobilizacaoSelected,
  getOcorrenciaSelected,
  getPatrimonioSelected,
  getPretensaoSelected,
  getProcessoSelected,
  getUsoSoloSelected,
  handleGenericError,
  idioma,
  post,
  tipoacesso,
  tiporecurso,
  useCombos
} from '../../../../services/utils'
import { useEntidadeStore } from '../../../../stores/entidade'
import { useModeloStore } from '../../../../stores/modelo'
import { useOcorrenciaStore } from '../../../../stores/ocorrencias'
import { usePatrimonioStore } from '../../../../stores/patrimonio'

import stylesOcorrencias from '../../../../styles/ocorrencia/OcorrenciasStylesv2.module.scss'
import stylesEntidades from '../../../../styles/entidade/RegistarEntidade.module.scss'
import stylesModelos from '../../../../styles/modelos/RegistarModeloForm.module.scss'
import stylesUsoDoSolo from '../../../../styles/usodosolo/RegistarUsoDoSoloForm.module.scss'
import stylesEstrategiaAdaptacao from '../../../../styles/estrategiaAdaptacao/EstrategiaAdaptacao.module.scss'
import stylesDominioHidrico from '../../../../styles/dominiohidrico/DominioHidrico.module.scss'
import stylesIntervencao from '../../../../styles/intervencao/Intervencao.module.scss'
import stylesApoioGestao from '../../../../styles/apoiogestao/ApoioGestao.module.scss'
import { default as styles } from '../../../../styles/documentos/GridDocumentos.module.scss'

import moment from 'moment'
import "moment-timezone"
import {
  APOIO_GESTAO_MODULE_NAME,
  AUTH_LOCALSTORAGE,
  CURRENT_TIMEZONE,
  DELETE_ASSOCIACAO,
  DELETE_DOCUMENTO,
  DOMINIO_HIDRICO_MODULE_NAME,
  ENTIDADES_MODULE_NAME,
  ESTRATEGIA_ADAPATACAO_MODULE_NAME,
  GET_DOCUMENTO,
  INTERVENCAO_MODULE_NAME,
  MODELOS_MODULE_NAME,
  NOT_AUTHORIZED_ERROR_STATUS_CODE,
  OCORRENCIAS_MODULE_NAME,
  PATRIMONIO_MODULE_NAME,
  PERMISSION_ERROR_STATUS_CODE,
  PESQUISAR_DOCUMENTOS_ACAO,
  PESQUISAR_DOCUMENTOS_ALL,
  PESQUISAR_DOCUMENTOS_ALL_BY_ID,
  PESQUISAR_DOCUMENTOS_BY_USO_SOLO,
  PESQUISAR_DOCUMENTOS_CANDIDATURA,
  PESQUISAR_DOCUMENTOS_DEMARCACAO,
  PESQUISAR_DOCUMENTOS_DESCONFORMIDADE,
  PESQUISAR_DOCUMENTOS_EA_REGISTO_ANALISE,
  PESQUISAR_DOCUMENTOS_ENTIDADE,
  PESQUISAR_DOCUMENTOS_INTERVENCAO,
  PESQUISAR_DOCUMENTOS_MOBILIZACAO,
  PESQUISAR_DOCUMENTOS_MODELO,
  PESQUISAR_DOCUMENTOS_OCORRENCIA,
  PESQUISAR_DOCUMENTOS_PATRIMONIO,
  PESQUISAR_DOCUMENTOS_PRETENSAO,
  PESQUISAR_DOCUMENTOS_PROCESSO,
  PESQUISAR_DOCUMENTOS_REGISTO_VOO_DRONE,
  PESQUISAR_ENTIDADEBYUSER,
  SAVE_NEW_DOCUMENTO,
  UPLOAD_DOCUMENTO_ACAO,
  UPLOAD_DOCUMENTO_CANDIDATURA,
  UPLOAD_DOCUMENTO_DEMARCACAO,
  UPLOAD_DOCUMENTO_DESCONFORMIDADE,
  UPLOAD_DOCUMENTO_EA_REGISTO_ANALISE,
  UPLOAD_DOCUMENTO_ENTIDADE,
  UPLOAD_DOCUMENTO_INTERVENCAO,
  UPLOAD_DOCUMENTO_MOBILIZACAO,
  UPLOAD_DOCUMENTO_MODELO,
  UPLOAD_DOCUMENTO_OCORRENCIA,
  UPLOAD_DOCUMENTO_PATRIMONIO,
  UPLOAD_DOCUMENTO_PRETENSAO,
  UPLOAD_DOCUMENTO_PROCESSO,
  UPLOAD_DOCUMENTO_REGISTO_VOO_DRONE,
  UPLOAD_DOCUMENTO_US_TITULO,
  USO_DO_SOLO_MODULE_NAME,
  VOO_DRONE_MODULE_NAME,
} from '../../../../utils/constants'
import { CarregarDocumentoTabs } from './EditDocumentoFormTabs'
import { useVooDroneStore } from '../../../../stores/vooDrone'
import ImagePreviewV2 from '../../../viewer/ImagePreviewV2'
import SessionStorageService from '../../../../services/SessionStorageService'
import { useUsoDoSoloStore } from '../../../../stores/usoDoSolo'
import { mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../../../common/ItemRenderMais'
import React from 'react'
import { sliderDragTitle } from '@progress/kendo-react-inputs/dist/npm/messages'

type PageState = {
  skip: number
  take: number
}

type Props = {
  formRenderProps?: FormRenderProps
  pageName?: string
  viewMode: boolean
}

const initialDataState: PageState = { skip: 0, take: 10 }
export interface MultiAction_CommandCellProps extends GridCellProps {
  onDocumentPreviewClick: (documentos: Record<string, unknown>) => () => void,
  deleteItem: (item: any) => void,
  dataItem: Record<string, unknown>
}

export const MultiActionCommandCell =  ({
  onDocumentPreviewClick,
  deleteItem,
  dataItem,
  viewMode
}: MultiAction_CommandCellProps & { viewMode: boolean }) => {
  return (
    
 <td className="k-command-cell">
      <div className={'gridCommand'}>
        {dataItem.url !== '' && (
        <div
          role="button"
          className={styles.gridCommandIconButton}
          onClick={onDocumentPreviewClick(dataItem)}
        >
          <FontAwesomeIcon icon={faEye} title="Visualizar" />
        </div>
         )}
          {!viewMode && dataItem?.primario ?
        <div
          role="button"
          onClick={() => deleteItem(dataItem)}
          
        >
          <FontAwesomeIcon icon={faRemove} />
        </div> : <></>
      }
      </div>
    </td>
  )
}

export function DocumentoListagem({ pageName, formRenderProps, viewMode }: Props) {
  let selectedStyles

  switch (pageName) {
    //  MODULO DOMINIO HIDRICO
    case 'processos':
      selectedStyles = stylesDominioHidrico;
      break;
    case 'patrimonio':
      selectedStyles = stylesDominioHidrico;
      break
    case 'demarcacao':
      selectedStyles = stylesDominioHidrico;
      break
    
    //  MODULO INTERVENÇÕES
    case 'intervencoes':
      selectedStyles = stylesIntervencao;
      break;
    case 'acao':
      selectedStyles = stylesIntervencao;
      break
    case 'candidatura':
      selectedStyles = stylesIntervencao;
      break
    case 'mobilizacao':
      selectedStyles = stylesIntervencao;
      break
    
    //  MODULO APOIO GESTÃO
    case 'pretensao':
      selectedStyles = stylesApoioGestao
      break
    case 'desconformidade':
      selectedStyles = stylesApoioGestao
      break
    
    case 'entidade':
      selectedStyles = stylesEntidades
      break

    case 'modelos':
      selectedStyles = stylesModelos
      break

    case 'usoDoSolo':
      selectedStyles = stylesUsoDoSolo
      break
    case 'estrategiaAdaptacao':
      selectedStyles = stylesEstrategiaAdaptacao
      break
    default:
      selectedStyles = styles
  }

  const [page, setPage] = useState<PageState>(initialDataState)
  const [urlDoDocumento, setUrlDoDocumento] = useState("");
  const [idDoDocumento, setIdDoDocumento] = useState("");
  const [openModalDescartar, setOpenModalDescartar] = useState(false);
  const [idToRemove, setIdToRemove] = useState(0);
  const [isPrimario, setIsPrimario] = useState(false);

  
  const getDocumentosByUsoSolo = async (data: any) =>
    await get<any, any>(`${PESQUISAR_DOCUMENTOS_BY_USO_SOLO}/` + data)

  const getDocumentosByProcesso = async (data: any) =>
    await get<any, any>(`${PESQUISAR_DOCUMENTOS_PROCESSO}/` + data)
  const getDocumentosByEntidade = async (data: any) =>
    await get<any, any>(`${PESQUISAR_DOCUMENTOS_ENTIDADE}/` + data)
  const getDocumentosByOcorrencia = async (data: any) =>
    await get<any, any>(`${PESQUISAR_DOCUMENTOS_OCORRENCIA}/` + data)
  const getDocumentosByModelo = async (data: any) =>
    await get<any, any>(`${PESQUISAR_DOCUMENTOS_MODELO}/` + data)
  const getDocumentosByPretensao = async (data: any) =>
    await get<any, any>(`${PESQUISAR_DOCUMENTOS_PRETENSAO}/` + data)
  const getDocumentosByPatrimonio = async (data: any) =>
    await get<any, any>(`${PESQUISAR_DOCUMENTOS_PATRIMONIO}/` + data)
  const getDocumentosByIntervencao = async (data: any) =>
    await get<any, any>(`${PESQUISAR_DOCUMENTOS_INTERVENCAO}/` + data)
  const getDocumentosByAcao = async (data: any) =>
    await get<any, any>(`${PESQUISAR_DOCUMENTOS_ACAO}/` + data)
  const getDocumentosByCandidatura = async (data: any) =>
    await get<any, any>(`${PESQUISAR_DOCUMENTOS_CANDIDATURA}/` + data)
  const getDocumentosByMobilizacao = async (data: any) =>
    await get<any, any>(`${PESQUISAR_DOCUMENTOS_MOBILIZACAO}/` + data)
  const getDocumentosByDemarcacao = async (data: any) =>
    await get<any, any>(`${PESQUISAR_DOCUMENTOS_DEMARCACAO}/` + data)
  const getAllDocumentos = async () =>
    await get<any, any>(`${PESQUISAR_DOCUMENTOS_ALL}`)
  const getDocumentosByDesconformidade = async (data: any) =>
    await get<any, any>(`${PESQUISAR_DOCUMENTOS_DESCONFORMIDADE}/` + data)

    const getDocumentosByEaRegistoAnalise = async (data: any) =>
    await get<any, any>(`${PESQUISAR_DOCUMENTOS_EA_REGISTO_ANALISE}/` + data)
    
    const getDocumentosByRegistoVooDroneId = async (id: any) =>
    await get<any, any>(`${PESQUISAR_DOCUMENTOS_REGISTO_VOO_DRONE}/${id}`)

  const getAllDocumentosById = async (data: any) =>
    await post<any, any>(`${PESQUISAR_DOCUMENTOS_ALL_BY_ID}`, data)

  const [pageSizeValue, setPageSizeValue] = useState<
    number | string | undefined
  >()

  const getEntidadeByUser = async (data: any) =>
    await get<any, any>(`${PESQUISAR_ENTIDADEBYUSER}/` + data)

  const selectedEntidadeResult: any = useEntidadeStore(
    (state: any) => state.selectedEntidadeResult
  )

  const selectedOcorrenciaResult: any = useOcorrenciaStore(
    (state) => state.selectedOcorrenciaResult
  )
  const selectedModeloResult: any = useModeloStore(
    (state) => state.selectedModeloResult
  )

   const selectedUsoDoSoloResult: any = useUsoDoSoloStore(
    (state) => state.selectedUsoDoSoloResult
  )

  const selectedPatrimonioResult: any = usePatrimonioStore(
    (state) => state.selectedPatrimonioResult
  )

    const selectedVooDronePathResult: any = useVooDroneStore(
    (state) => state.selectedVooDronePathResult
  )

  const [entidades, setEntidades] = useState([])
  const [isMobile, setIsMobile] = useState(false);
  const [percentLeftSucess, setPercentLeftSucess] = useState("19%");
  useLayoutEffect(() => {

    const handleResize = () => {

      if (window.innerWidth <= telaMobile) {
        
        setIsMobile(true);
        ocultaFloatingMenu_e_Mapa();

      }else{

        setIsMobile(false);
        mostraFloatingMenu_e_Mapa();

      }

    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []); 

   
   const saveDocumentoUsTitulo = async (data: any) =>
    await post<any, any>(UPLOAD_DOCUMENTO_US_TITULO, data)
  const saveDocumentoProcesso = async (data: any) =>
    await post<any, any>(UPLOAD_DOCUMENTO_PROCESSO, data)
  const saveDocumentoEntidade = async (data: any) =>
    await post<any, any>(UPLOAD_DOCUMENTO_ENTIDADE, data)
  const saveDocumentoOcorrencia = async (data: any) =>
    await post<any, any>(UPLOAD_DOCUMENTO_OCORRENCIA, data)
  const saveDocumentoModelo = async (data: any) =>
    await post<any, any>(UPLOAD_DOCUMENTO_MODELO, data)
  const saveDocumentoPretensao = async (data: any) =>
    await post<any, any>(UPLOAD_DOCUMENTO_PRETENSAO, data)
  const saveDocumentoDesconformidade = async (data: any) =>
    await post<any, any>(UPLOAD_DOCUMENTO_DESCONFORMIDADE, data)
  const [data, setData] = useState<any[]>([])
  const [openForm, setOpenForm] = useState<boolean>(false)
  const [openDocumentModal, setOpenDocumentModal] = useState<boolean>(false)
  const saveDocumentoPatrimonio = async (data: any) =>
    await post<any, any>(UPLOAD_DOCUMENTO_PATRIMONIO, data)
  const saveDocumentoDemarcacao = async (data: any) =>
    await post<any, any>(UPLOAD_DOCUMENTO_DEMARCACAO, data)
  const saveDocumentoIntervencao = async (data: any) =>
    await post<any, any>(UPLOAD_DOCUMENTO_INTERVENCAO, data)
  const saveDocumentoAcao = async (data: any) =>
    await post<any, any>(UPLOAD_DOCUMENTO_ACAO, data)
  const saveDocumentoCandidatura = async (data: any) =>
    await post<any, any>(UPLOAD_DOCUMENTO_CANDIDATURA, data)
  const saveDocumentoMobilizacao = async (data: any) =>
    await post<any, any>(UPLOAD_DOCUMENTO_MOBILIZACAO, data)
    const saveDocumentoRegistoVooDrone = async (data: any) =>
    await post<any, any>(UPLOAD_DOCUMENTO_REGISTO_VOO_DRONE, data)
    const saveDocumentoEaRegistoAnalise = async (data: any) =>
    await post<any, any>(UPLOAD_DOCUMENTO_EA_REGISTO_ANALISE, data)
    const getDocumento = async (data: any) =>
    await get<any, any>(`${GET_DOCUMENTO}/` + data)
    const deleteDocumento = (id: number) => del(`${DELETE_DOCUMENTO}/${id}`, {})
    const deleteAssociacao = async(id: any) => await del<any, any>(`${DELETE_ASSOCIACAO}/${id}`, {})

  const insertDocumento = async (data: any) =>
    await post<any, any>(SAVE_NEW_DOCUMENTO, data)

  const handlePageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent
    const take = event.page.take

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value)
    }

    setPage({
      ...event.page,
      take,
    })
  }

  const handleDocumentPreviewClickById = (documentosLocal: Record<string, any>) => async () => {

    try {

      postMessage({ type: "showLoader" }, "*");
      const documentos = await getDocumento(documentosLocal.id);

      setIdDoDocumento(documentosLocal.id);
      setUrlDoDocumento(documentos.url);
      setOpenDocumentModal(true);

    } catch (error) {
      handleGenericError(error, "Não foi possivel abrir o documento!");
      setOpenDocumentModal(false);

    } finally {

      postMessage({ type: "hideLoader" }, "*");

    }
  }

  const documentosToPreview = SessionStorageService.getItem('resultadoDocumentosPesquisaModulo');

  const nextPhoto = async (e: any) => {

    try{

      postMessage({ type: "showLoader" }, "*");
      const nextIds = documentosToPreview
        .filter((doc: any) => doc.id < idDoDocumento && doc.url !== '')
        .map((doc: any) => doc.id);
  
      if (nextIds.length > 0) {
        const nextId = Math.max(...nextIds);
        console.log(`O próximo ID é ${nextId}`);
  
        const documentos = await getDocumento(nextId);
  
        setIdDoDocumento(nextId.toString());
        setUrlDoDocumento(documentos.url);
  
      } else {
        console.log("Não há um próximo ID na lista.");
      }

    }catch(error){

      handleGenericError(error, "Não foi possivel abrir o documento!");

    }finally{

      postMessage({ type: "hideLoader" }, "*");

      e.preventDefault();
    }
   
  }

  const previousPhoto = async (e: any) => {

    try{

      postMessage({ type: "showLoader" }, "*");
      const previousIds = documentosToPreview
        .filter((doc: any) => doc.id > idDoDocumento && doc.url !== '')
        .map((doc: any) => doc.id);
  
      if (previousIds.length > 0) {
        const previousId = Math.min(...previousIds);
        console.log(`O anterior ID é ${previousId}`);
  
        const documentos = await getDocumento(previousId);
  
        setIdDoDocumento(previousId.toString());
        setUrlDoDocumento(documentos.url);
      } else {
        console.log("Não há um anterior ID na lista.");
      }

    }catch(error){

      handleGenericError(error, "Não foi possivel abrir o documento!")

    }finally{

      postMessage({ type: "hideLoader" }, "*");

      e.preventDefault();
    }
       
  }

  useEffect(() => {

    const elemento: any = document.querySelector(".k-window-content.k-dialog-content");

    if (elemento) {
      elemento.style.padding = "0px";
    }

  }, [openDocumentModal]);


  const handleSubmitDocumento = async (documento: any) => {
    
    postMessage({ type: 'showLoader' }, '*')

    try {

      const interno = "Interno"
      const submetido = "Submetido"
      
      const tipo = documento?.tipo
      const tipoAcesso = tipoacesso.find((item) => item.designacao.toUpperCase() === interno.toUpperCase());
      const estadoDocumento = estados.find((item) => item.designacao.toUpperCase() === submetido.toUpperCase());

      const recursoId = documento?.tipo_recurso_id
      const titulo = documento?.titulo
      const data_registo = documento?.data_registo
      const data_publicacao = documento?.data_publicacao
      const descricao = documento?.descricao
      const arquivo = await blobToBase64(documento?.arquivo)
      const nomeArquivo = documento?.nomeArquivo
      const userId = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '')
        ?.user?.id
      const entidade = documento.entidade ?? await getEntidadeByUser(userId)

      const modulo = sessionStorage.getItem('Modulo')

      switch (modulo) {

        case DOMINIO_HIDRICO_MODULE_NAME: {
          const patrimonioSelected = getPatrimonioSelected()
          const demarcacaoSelected = getDemarcacaoSelected()
          const processoSelected = getProcessoSelected()

          if (patrimonioSelected && patrimonioSelected?.id) {
         await saveDocumentoPatrimonio({
              id: documento?.id,
              tipo_acesso_id: tipoAcesso.id,
              idioma_id: documento?.idioma_id,
              estado_id: estadoDocumento.id,
              tipo_recurso_id: recursoId,
              utilizador_id: userId,
              patrimonio_id: patrimonioSelected?.id,
              entidade_id: entidade?.id,
              arquivo: arquivo,
              nome_arquivo: nomeArquivo,
              data_registo: data_registo,
              data_publicacao: data_publicacao,
              observacoes: descricao,
              titulo: titulo,
              autores: documento?.autores,
              filedoc_cod: documento?.filedoc_cod
            })  
            if (patrimonioSelected?.id) {
              await _getDocumentosByPatrimonio(patrimonioSelected?.id)
            }
          } else if (demarcacaoSelected && demarcacaoSelected?.id) {
            await saveDocumentoDemarcacao({
              id: documento?.id,
              tipo_acesso_id: tipoAcesso.id,
              idioma_id: documento?.idioma_id,
              estado_id: estadoDocumento.id,
              tipo_recurso_id: recursoId,
              utilizador_id: userId,
              demarcacao_id: demarcacaoSelected?.id,
              entidade_id: entidade?.id,
              arquivo: arquivo,
              nome_arquivo: nomeArquivo,
              data_registo: data_registo,
              data_publicacao: data_publicacao,
              observacoes: descricao,
              titulo: titulo,
              autores: documento?.autores,
              filedoc_cod: documento?.filedoc_cod
            })
            if (demarcacaoSelected?.id) {
              await _getDocumentosByDemarcacao(demarcacaoSelected?.id)
            }
          } else if (processoSelected && processoSelected?.id) {
            await saveDocumentoProcesso({
              id: documento?.id,
              tipo_acesso_id: tipoAcesso.id,
              idioma_id: documento?.idioma_id,
              estado_id: estadoDocumento.id,
              tipo_recurso_id: recursoId,
              utilizador_id: userId,
              processo_id: processoSelected?.id,
              entidade_id: entidade?.id,
              arquivo: arquivo,
              nome_arquivo: nomeArquivo,
              data_registo: data_registo,
              data_publicacao: data_publicacao,
              observacoes: descricao,
              titulo: titulo,
              autores: documento?.autores,
              filedoc_cod: documento?.filedoc_cod
            })
            if (processoSelected?.id) {
              await _getDocumentosByProcesso(processoSelected?.id)
            }
          }

          break
        }

        case INTERVENCAO_MODULE_NAME: {
          const intervencaoSelected = getIntervencaoSelected()
          const acaoSelected = getAcaoSelected()
          const mobilizacaoSelected = getMobilizacaoSelected()
          const candidaturaSelected = getCandidaturaSelected()


          if (intervencaoSelected && intervencaoSelected?.id) {
            await saveDocumentoIntervencao({
              id: documento?.id,
              tipo_acesso_id: tipoAcesso.id,
              idioma_id: documento?.idioma_id,
              estado_id: estadoDocumento.id,
              tipo_recurso_id: recursoId,
              utilizador_id: userId,
              int_intervencao_id: intervencaoSelected?.id,
              entidade_id: entidade?.id,
              arquivo: arquivo,
              nome_arquivo: nomeArquivo,
              data_registo: data_registo,
              data_publicacao: data_publicacao,
              observacoes: descricao,
              titulo: titulo,
              autores: documento?.autores,
              filedoc_cod: documento?.filedoc_cod
            })
            if (intervencaoSelected?.id) {
              await _getDocumentosByIntervencao(intervencaoSelected?.id)
            }
          } else if (acaoSelected && acaoSelected?.id) {
            await saveDocumentoAcao({
              id: documento?.id,
              tipo_acesso_id: tipoAcesso.id,
              idioma_id: documento?.idioma_id,
              estado_id: estadoDocumento.id,
              tipo_recurso_id: recursoId,
              utilizador_id: userId,
              int_acao_id: acaoSelected?.id,
              entidade_id: entidade?.id,
              arquivo: arquivo,
              nome_arquivo: nomeArquivo,
              data_registo: data_registo,
              data_publicacao: data_publicacao,
              observacoes: descricao,
              titulo: titulo,
              autores: documento?.autores,
              filedoc_cod: documento?.filedoc_cod
            })
            if (acaoSelected?.id) {
              await _getDocumentosByAcao(acaoSelected?.id)
            }
          } else if (mobilizacaoSelected && mobilizacaoSelected?.id) {
            await saveDocumentoMobilizacao({
              id: documento?.id,
              tipo_acesso_id: tipoAcesso.id,
              idioma_id: documento?.idioma_id,
              estado_id: estadoDocumento.id,
              tipo_recurso_id: recursoId,
              utilizador_id: userId,
              int_mobilizacao_id: mobilizacaoSelected?.id,
              entidade_id: entidade?.id,
              arquivo: arquivo,
              nome_arquivo: nomeArquivo,
              data_registo: data_registo,
             data_publicacao: data_publicacao,
              observacoes: descricao,
              titulo: titulo,
              autores: documento?.autores,
              filedoc_cod: documento?.filedoc_cod
            })
            if (mobilizacaoSelected?.id) {
              await _getDocumentosByMobilizacao(mobilizacaoSelected?.id)
            }
          } else if (candidaturaSelected && candidaturaSelected?.id) {
            await saveDocumentoCandidatura({
              id: documento?.id,
              tipo_acesso_id: tipoAcesso.id,
              idioma_id: documento?.idioma_id,
              estado_id: estadoDocumento.id,
              tipo_recurso_id: recursoId,
              utilizador_id: userId,
              int_candidatura_id: candidaturaSelected?.id,
              entidade_id: entidade?.id,
              arquivo: arquivo,
              nome_arquivo: nomeArquivo,
              data_registo: data_registo,
               data_publicacao: data_publicacao,
              observacoes: descricao,
              titulo: titulo,
              autores: documento?.autores,
              filedoc_cod: documento?.filedoc_cod
            })
            if (candidaturaSelected?.id) {
              await _getDocumentosByCandidatura(candidaturaSelected?.id)
            }
          }
          break
        }

        case ENTIDADES_MODULE_NAME: {
          if (selectedEntidadeResult && selectedEntidadeResult?.id) {
            await saveDocumentoEntidade({
              id: documento?.id,
              tipo_acesso_id: tipoAcesso.id,
              idioma_id: documento?.idioma_id,
              estado_id: estadoDocumento.id,
              tipo_recurso_id: recursoId,
              utilizador_id: userId,
              entidade_id: selectedEntidadeResult?.id,
              arquivo: arquivo,
              nome_arquivo: nomeArquivo,
              data_registo: data_registo,
              observacoes: descricao,
              data_publicacao: data_publicacao,
              titulo: titulo,
              autores: documento?.autores,
              filedoc_cod: documento?.filedoc_cod
            })

            if (selectedEntidadeResult?.id) {
              await _getDocumentosByEntidade(selectedEntidadeResult?.id)
            }
          }

          break
        }

        case OCORRENCIAS_MODULE_NAME: {
          if (selectedOcorrenciaResult?.id || getOcorrenciaSelected()?.id) {
            
            const result = await saveDocumentoOcorrencia({
              id: documento?.id,
              tipo_acesso_id: tipoAcesso.id,
              idioma_id: documento?.idioma_id,
              estado_id: estadoDocumento.id,
              tipo_recurso_id: recursoId,
              utilizador_id: userId,
              ocorrencia_id: selectedOcorrenciaResult?.id ?? getOcorrenciaSelected().id,
              entidade_id: entidade?.id,
              arquivo: arquivo,
              nome_arquivo: nomeArquivo,
              data_registo: data_registo,
             data_publicacao: data_publicacao,
              observacoes: descricao,
              titulo: titulo,
              autores: documento?.autores,
              filedoc_cod: documento?.filedoc_cod
            })

            if (selectedOcorrenciaResult?.id || getOcorrenciaSelected()?.id) {
              await _getDocumentosByOcorrencia(selectedOcorrenciaResult?.id ?? getOcorrenciaSelected().id)
            }
          }

          break
        }

        case MODELOS_MODULE_NAME: {
          if (selectedModeloResult && selectedModeloResult?.id) {
            await saveDocumentoModelo({
              id: documento?.id,
              tipo_acesso_id: tipoAcesso.id,
              idioma_id: documento?.idioma_id,
              estado_id: estadoDocumento.id,
              tipo_recurso_id: recursoId,
              utilizador_id: userId,
              modelo_id: selectedModeloResult?.id,
              entidade_id: entidade?.id,
              arquivo: arquivo,
              nome_arquivo: nomeArquivo,
              data_registo: data_registo,
              data_publicacao: data_publicacao,
              observacoes: descricao,
              titulo: titulo,
              autores: documento?.autores,
              filedoc_cod: documento?.filedoc_cod
            })

            if (selectedModeloResult?.id) {
              await _getDocumentosByModelo(selectedModeloResult?.id)
            }
          }
          break
        }

         case USO_DO_SOLO_MODULE_NAME: {
          const usoSoloSelected = getUsoSoloSelected();

          if (usoSoloSelected && usoSoloSelected?.id) {
            await saveDocumentoUsTitulo({
              id: documento?.id,
              tipo_acesso_id: tipoAcesso.id,
              idioma_id: documento?.idioma_id,
              estado_id: estadoDocumento.id,
              tipo_recurso_id: recursoId,
              utilizador_id: userId,
              us_titulo_id: usoSoloSelected?.id,
              entidade_id: entidade?.id,
              arquivo: arquivo,
              nome_arquivo: nomeArquivo,
              data_registo: data_registo,
              data_publicacao: data_publicacao,
              observacoes: descricao,
              titulo: titulo,
              autores: documento?.autores,
              filedoc_cod: documento?.filedoc_cod
            })

            if (usoSoloSelected?.id) {
              await _getDocumentosByUsoSolo(usoSoloSelected?.id)
            }
          }

          break
        }

        case APOIO_GESTAO_MODULE_NAME: {
          const pretensaoSelected = getPretensaoSelected()
          const desconformidadeSelected = getDesconformidadeSelected()

          if (pretensaoSelected && pretensaoSelected?.id) {
            await saveDocumentoPretensao({
              id: documento?.id,
              tipo_acesso_id: tipoAcesso.id,
              idioma_id: documento?.idioma_id,
              estado_id: estadoDocumento.id,
              tipo_recurso_id: recursoId,
              utilizador_id: userId,
              pretensao_id: pretensaoSelected?.id,
              entidade_id: entidade?.id,
              arquivo: arquivo,
              nome_arquivo: nomeArquivo,
              data_registo: data_registo,
              data_publicacao: data_publicacao,
              observacoes: descricao,
              titulo: titulo,
              autores: documento?.autores,
              filedoc_cod: documento?.filedoc_cod
            })
            if (pretensaoSelected?.id) {
              await _getDocumentosByPretensao(pretensaoSelected?.id)
            }
          } else if (desconformidadeSelected && desconformidadeSelected?.id) {
            await saveDocumentoDesconformidade({
              id: documento?.id,
              tipo_acesso_id: tipoAcesso.id,
              idioma_id: documento?.idioma_id,
              estado_id: estadoDocumento.id,
              tipo_recurso_id: recursoId,
              utilizador_id: userId,
              desconformidade_id: desconformidadeSelected?.id,
              entidade_id: entidade?.id,
              arquivo: arquivo,
              nome_arquivo: nomeArquivo,
              data_registo: data_registo,
              data_publicacao: data_publicacao,
              observacoes: descricao,
              titulo: titulo,
              autores: documento?.autores,
              filedoc_cod: documento?.filedoc_cod
            })
            if (desconformidadeSelected?.id) {
              await _getDocumentosByDesconformidade(desconformidadeSelected?.id)
            }
          }

          break
        }

        case ESTRATEGIA_ADAPATACAO_MODULE_NAME: {
          const eaRegistoAnalise = getEaRegistoAnalise();
          await saveDocumentoEaRegistoAnalise({
            id: documento?.id,
            tipo_acesso_id: tipoAcesso.id,
            idioma_id: documento?.idioma_id,
            estado_id: estadoDocumento.id,
            tipo_recurso_id: recursoId,
            utilizador_id: userId,
            ea_registo_analise_id: eaRegistoAnalise?.id,
            entidade_id: entidade?.id,
            arquivo: arquivo,
            nome_arquivo: nomeArquivo,
            data_registo: data_registo,
            data_publicacao: data_publicacao,
            observacoes: descricao,
            titulo: titulo,
            autores: documento?.autores,
            filedoc_cod: documento?.filedoc_cod
          })
          if (eaRegistoAnalise?.id) {
            await _getDocumentosByEaRegistoAnalise(eaRegistoAnalise?.id)
          }
          break
        }

        case VOO_DRONE_MODULE_NAME:{
            if(selectedVooDronePathResult && selectedVooDronePathResult?.id_registo_voo_drone){
              const id_registo_voo_drone = selectedVooDronePathResult?.id_registo_voo_drone;
              await saveDocumentoRegistoVooDrone({
                id: documento?.id,
                tipo_acesso_id: tipoAcesso.id,
                idioma_id: documento?.idioma_id,
                estado_id: estadoDocumento.id,
                tipo_recurso_id: recursoId,
                utilizador_id: userId,
                vz_registo_voo_drone_id: id_registo_voo_drone,
                entidade_id: entidade?.id,
                arquivo: arquivo,
                nome_arquivo: nomeArquivo,
                data_registo: data_registo,
                data_publicacao: data_publicacao,
                observacoes: descricao,
                titulo: titulo,
                autores: documento?.autores,
                filedoc_cod: documento?.filedoc_cod
              })
              if (id_registo_voo_drone) {
                await _getDocumentosByRegistoVooDroneId(id_registo_voo_drone)
              }
            }
          break;
        }

      }
      postMessage({ type: 'sucessMsg' , value :{left : (isMobile? (Number(percentLeftSucess.split('%')[0])-40)+'%' : percentLeftSucess)
    } },'*')      
    } catch (error: any) {
      if (error?.response?.status == PERMISSION_ERROR_STATUS_CODE || 
        error?.response?.status == NOT_AUTHORIZED_ERROR_STATUS_CODE) {
        postMessage({type: "errorMsg", value: {message: "Não tem permissões para essa funcionalidade"}}, "*");
      } else {
        postMessage(
          {
            type: 'errorMsg',
            value: { message: 'Não foi possivel efetuar esta operação' },
          },
          '*'
        )
      }
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
      close()
    }
  }

  const handleSubmitExternalDocumento = async (documento: any) => {
    try {
      postMessage({ type: 'showLoader' }, '*')


      const interno = "Interno"
      const submetido = "Submetido"

      const tipoAcesso = tipoacesso.find((item) => item.designacao.toUpperCase() === interno.toUpperCase());
      const estadoDocumento = estados.find((item) => item.designacao.toUpperCase() === submetido.toUpperCase());

      const tipo = documento?.tipo

      const recursoId = documento?.tipo_recurso_id
      const data_registo = documento?.data_registo
      const data_publicacao = documento?.data_publicacao
      const titulo = documento?.titulo
      const descricao = documento?.descricao
      const url = documento?.url
      const userId = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '')
        ?.user?.id

      const entidade = documento.entidade ?? await getEntidadeByUser(userId)

      const modulo = sessionStorage.getItem('Modulo')

      switch (modulo) {
        
        case DOMINIO_HIDRICO_MODULE_NAME: {
          const patrimonioSelected = getPatrimonioSelected()
          const demarcacaoSelected = getDemarcacaoSelected()
          const processoSelected = getProcessoSelected()

          if (patrimonioSelected && patrimonioSelected?.id) {
            await insertDocumento({
              id: documento?.id,
              tipo_acesso_id: tipoAcesso.id,
              idioma_id: documento?.idioma_id,
              estado_id: estadoDocumento.id,
              tipo_recurso_id: recursoId,
              utilizador_id: userId,
              patrimonio_id: patrimonioSelected?.id,
              entidade_id: entidade?.id,
              url: url,
              data_registo: data_registo,
              data_publicacao: data_publicacao,
              observacoes: descricao,
              titulo: titulo,
              autores: documento?.autores,
              filedoc_cod: documento?.filedoc_cod
            })
            if (patrimonioSelected?.id) {
              await _getDocumentosByPatrimonioExternal(patrimonioSelected?.id)
            }
          } else if (demarcacaoSelected && demarcacaoSelected?.id) {
            await insertDocumento({
              id: documento?.id,
              tipo_acesso_id: tipoAcesso.id,
              idioma_id: documento?.idioma_id,
              estado_id: estadoDocumento.id,
              tipo_recurso_id: recursoId,
              utilizador_id: userId,
              demarcacao_id: demarcacaoSelected?.id,
              entidade_id: entidade?.id,
              url: url,
              data_registo: data_registo,
              data_publicacao: data_publicacao,
              observacoes: descricao,
              titulo: titulo,
              autores: documento?.autores,
              filedoc_cod: documento?.filedoc_cod
            })
            if (demarcacaoSelected?.id) {
              await _getDocumentosByDemarcacaoExternal(demarcacaoSelected?.id)
            }
          } else if (processoSelected && processoSelected?.id) {
            await insertDocumento({
              id: documento?.id,
              tipo_acesso_id: tipoAcesso.id,
              idioma_id: documento?.idioma_id,
              estado_id: estadoDocumento.id,
              tipo_recurso_id: recursoId,
              utilizador_id: userId,
              processo_id: processoSelected?.id,
              entidade_id: entidade?.id,
              url: url,
              data_registo: data_registo,
              data_publicacao: data_publicacao,
              observacoes: descricao,
              titulo: titulo,
              autores: documento?.autores,
              filedoc_cod: documento?.filedoc_cod
            })
            if (processoSelected?.id) {
              await _getDocumentosByProcessoExternal(processoSelected?.id)
            }
          }

          break
        }

        case INTERVENCAO_MODULE_NAME: {

          const intervencaoSelected = getIntervencaoSelected()
          const acaoSelected = getAcaoSelected()
          const mobilizacaoSelected = getMobilizacaoSelected()
          const candidaturaSelected = getCandidaturaSelected()

          if (intervencaoSelected && intervencaoSelected?.id) {
            await insertDocumento({
              id: documento?.id,
              tipo_acesso_id: tipoAcesso.id,
              idioma_id: documento?.idioma_id,
              estado_id: estadoDocumento.id,
              tipo_recurso_id: recursoId,
              utilizador_id: userId,
              intervencao_id: intervencaoSelected?.id,
              entidade_id: entidade?.id,
              url: url,
              data_registo: data_registo,
              data_publicacao: data_publicacao,
              observacoes: descricao,
              titulo: titulo,
              autores: documento?.autores,
              filedoc_cod: documento?.filedoc_cod
            })
            if (intervencaoSelected?.id) {
              await _getDocumentosByIntervencaoExternal(intervencaoSelected?.id)
            }
          } else if (acaoSelected && acaoSelected?.id) {
            await insertDocumento({
              id: documento?.id,
              tipo_acesso_id: tipoAcesso.id,
              idioma_id: documento?.idioma_id,
              estado_id: estadoDocumento.id,
              tipo_recurso_id: recursoId,
              utilizador_id: userId,
              acao_id: acaoSelected?.id,
              entidade_id: entidade?.id,
              url: url,
              data_registo: data_registo,
              data_publicacao: data_publicacao,
              observacoes: descricao,
              titulo: titulo,
              autores: documento?.autores,
              filedoc_cod: documento?.filedoc_cod
            })
            if (acaoSelected?.id) {
              await _getDocumentosByAcaoExternal(acaoSelected?.id)
            }
          } else if (mobilizacaoSelected && mobilizacaoSelected?.id) {
            await insertDocumento({
              id: documento?.id,
              tipo_acesso_id: tipoAcesso.id,
              idioma_id: documento?.idioma_id,
              estado_id: estadoDocumento.id,
              tipo_recurso_id: recursoId,
              utilizador_id: userId,
              mobilizacao_id: mobilizacaoSelected?.id,
              entidade_id: entidade?.id,
              url: url,
              data_registo: data_registo,
              data_publicacao: data_publicacao,    
              observacoes: descricao,
              titulo: titulo,
              autores: documento?.autores,
              filedoc_cod: documento?.filedoc_cod
            })
            if (mobilizacaoSelected?.id) {
              await _getDocumentosByMobilizacaoExternal(acaoSelected?.id)
            }
          } else if (candidaturaSelected && candidaturaSelected?.id) {
            await insertDocumento({
              id: documento?.id,
              tipo_acesso_id: tipoAcesso.id,
              idioma_id: documento?.idioma_id,
              estado_id: estadoDocumento.id,
              tipo_recurso_id: recursoId,
              utilizador_id: userId,
              candidatura_id: candidaturaSelected?.id,
              entidade_id: entidade?.id,
              url: url,
              data_registo: data_registo,
              data_publicacao: data_publicacao,
              observacoes: descricao,
              titulo: titulo,
              autores: documento?.autores,
              filedoc_cod: documento?.filedoc_cod
            })
            if (candidaturaSelected?.id) {
              await _getDocumentosByCandidaturaExternal(candidaturaSelected?.id)
            }
          }

          break
        }

        case ENTIDADES_MODULE_NAME: {
          if (selectedEntidadeResult && selectedEntidadeResult?.id) {
            await insertDocumento({
              id: documento?.id,
              tipo_acesso_id: tipoAcesso.id,
              idioma_id: documento?.idioma_id,
              estado_id: estadoDocumento.id,
              tipo_recurso_id: recursoId,
              utilizador_id: userId,
              entidade_id: selectedEntidadeResult?.id,
              url: url,
              data_registo: data_registo,
              observacoes: descricao,
               data_publicacao: data_publicacao,
              titulo: titulo,
              autores: documento?.autores,
              filedoc_cod: documento?.filedoc_cod
            })

            if (selectedEntidadeResult?.id) {
              await _getDocumentosByEntidadeExternal(selectedEntidadeResult?.id)
            }
          }

          break
        }

        case OCORRENCIAS_MODULE_NAME: {

          if (selectedOcorrenciaResult?.id || getOcorrenciaSelected()?.id) {
            await insertDocumento({
              id: documento?.id,
              tipo_acesso_id: tipoAcesso.id,
              idioma_id: documento?.idioma_id,
              estado_id: estadoDocumento.id,
              tipo_recurso_id: recursoId,
              utilizador_id: userId,
              ocorrencia_id: selectedOcorrenciaResult?.id ?? getOcorrenciaSelected().id,
              entidade_id: entidade?.id,
              url: url,
              data_registo: data_registo,
               data_publicacao: data_publicacao,
              observacoes: descricao,
              titulo: titulo,
              autores: documento?.autores,
              filedoc_cod: documento?.filedoc_cod
            })

            if (selectedOcorrenciaResult?.id || getOcorrenciaSelected()?.id) {
              await _getDocumentosByOcorrenciaExternal(selectedOcorrenciaResult?.id ?? getOcorrenciaSelected().id)
            }
          }

          break
        }

        case MODELOS_MODULE_NAME: {
          if (selectedModeloResult && selectedModeloResult?.id) {
            await insertDocumento({
              id: documento?.id,
              tipo_acesso_id: tipoAcesso.id,
              idioma_id: documento?.idioma_id,
              estado_id: estadoDocumento.id,
              tipo_recurso_id: recursoId,
              utilizador_id: userId,
              modelo_id: selectedModeloResult?.id,
              entidade_id: entidade?.id,
              url: url,
              data_registo: data_registo,
              data_publicacao: data_publicacao,
              observacoes: descricao,
              titulo: titulo,
              autores: documento?.autores,
              filedoc_cod: documento?.filedoc_cod
            })

            if (selectedModeloResult?.id) {
              await _getDocumentosByModeloExternal(selectedModeloResult?.id)
            }
          }

          break
        }

        case ESTRATEGIA_ADAPATACAO_MODULE_NAME: {
          const eaRegistoAnalise = getEaRegistoAnalise();
          await insertDocumento({
            id: documento?.id,
            tipo_acesso_id: tipoAcesso.id,
            idioma_id: documento?.idioma_id,
            estado_id: estadoDocumento.id,
            tipo_recurso_id: recursoId,
            utilizador_id: userId,
            ea_registo_analise_id: eaRegistoAnalise?.id,
            entidade_id: entidade?.id,
            url: url,
            data_registo: data_registo,
            data_publicacao: data_publicacao,
            observacoes: descricao,
            titulo: titulo,
            autores: documento?.autores,
            filedoc_cod: documento?.filedoc_cod
          })
          if (eaRegistoAnalise?.id) {
            await _getDocumentosByEaRegistoAnalise(eaRegistoAnalise?.id)
          }
          break
        }

           case USO_DO_SOLO_MODULE_NAME: {
          if (getUsoSoloSelected() && getUsoSoloSelected()?.id) {
            await insertDocumento({
              id: documento?.id,
              tipo_acesso_id: tipoAcesso.id,
              idioma_id: documento?.idioma_id,
              estado_id: estadoDocumento.id,
              tipo_recurso_id: recursoId,
              utilizador_id: userId,
              entidade_id: entidade?.id,
              url: url,
              data_registo: data_registo,
              data_publicacao: data_publicacao,
              observacoes: descricao,
              titulo: titulo,
              us_titulo_id: getUsoSoloSelected()?.id,
              autores: documento?.autores,
              filedoc_cod: documento?.filedoc_cod
            })

            if (getUsoSoloSelected()?.id) {
              await _getDocumentosByUsoSoloExternal(getUsoSoloSelected()?.id)
            }
          }

          break
        }

        case APOIO_GESTAO_MODULE_NAME: {
          const pretensaoSelected = getPretensaoSelected()
          const desconformidadeSelected = getDesconformidadeSelected()
          if (pretensaoSelected && pretensaoSelected?.id) {
            await insertDocumento({
              id: documento?.id,
              tipo_acesso_id: tipoAcesso.id,
              idioma_id: documento?.idioma_id,
              estado_id: estadoDocumento.id,
              tipo_recurso_id: recursoId,
              utilizador_id: userId,
              pretensao_id: pretensaoSelected?.id,
              entidade_id: entidade?.id,
              url: url,
              data_registo: data_registo,
              data_publicacao: data_publicacao,
              observacoes: descricao,
              titulo: titulo,
              autores: documento?.autores,
              filedoc_cod: documento?.filedoc_cod
            })

            if (pretensaoSelected?.id) {
              await _getDocumentosByPretensaoExternal(pretensaoSelected?.id)
            }
          } else if (desconformidadeSelected && desconformidadeSelected.id) {
            await insertDocumento({
              id: documento?.id,
              tipo_acesso_id: tipoAcesso.id,
              idioma_id: documento?.idioma_id,
              estado_id: estadoDocumento.id,
              tipo_recurso_id: recursoId,
              utilizador_id: userId,
              desconformidade_id: desconformidadeSelected?.id,
              entidade_id: entidade?.id,
              url: url,
              data_registo: data_registo,
              data_publicacao: data_publicacao,
              observacoes: descricao,
              titulo: titulo,
              autores: documento?.autores,
              filedoc_cod: documento?.filedoc_cod
            })

            if (desconformidadeSelected?.id) {
              await _getDocumentosByDesconformidadeExternal(
                desconformidadeSelected?.id
              )
            }
          }

          break
        }

      }
    } catch (error) {
      postMessage(
        {
          type: 'errorMsg',
          value: { message: 'Não foi possivel efetuar esta operação' },
        },
        '*'
      )
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
      close()
      postMessage({ type: 'sucessMsg' , value :{left : (isMobile? (Number(percentLeftSucess.split('%')[0])-40)+'%' : percentLeftSucess)
    } },'*')  
    fetchData();  
    } 
  }

  const _getDocumentosByProcesso = async (id?: any) => {
    try {
      postMessage({ type: 'showLoader' }, '*')
      const result = await getDocumentosByProcesso(id)
      SessionStorageService.setItem("resultadoDocumentosPesquisaModulo", result);

      if (Array.isArray(result)) {
        setData(result)
      }
    } catch (error) {
      setData([])
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  const _getDocumentosByProcessoExternal = async (id?: any) => {
    try {
      postMessage({ type: 'showLoader' }, '*')
      const result = await getAllDocumentosById({ processo_id: id })
      SessionStorageService.setItem("resultadoDocumentosPesquisaModulo", result);

      if (Array.isArray(result)) {
        setData(result)
      }
    } catch (error) {
      setData([])
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  const _getDocumentosByEntidade = async (id?: any) => {
    try {
      postMessage({ type: 'showLoader' }, '*')
      const result = await getDocumentosByEntidade(id)
      SessionStorageService.setItem("resultadoDocumentosPesquisaModulo", result);

      if (Array.isArray(result)) {
        setData(result)
      }
    } catch (error) {
      setData([])
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  const _getDocumentosByEntidadeExternal = async (id?: any) => {
    try {
      postMessage({ type: 'showLoader' }, '*')
      const result = await getAllDocumentosById({ entidade_id: id })
      SessionStorageService.setItem("resultadoDocumentosPesquisaModulo", result);

      if (Array.isArray(result)) {
        setData(result)
      }
    } catch (error) {
      setData([])
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  const _getDocumentosByOcorrencia = async (id?: any) => {
    try {
      postMessage({ type: 'showLoader' }, '*')

      const result = await getDocumentosByOcorrencia(id)
      SessionStorageService.setItem("resultadoDocumentosPesquisaModulo", result);

      if (Array.isArray(result)) {
        setData(result)
      }
    } catch (error) {
      setData([])
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  const _getDocumentosByOcorrenciaExternal = async (id?: any) => {
    try {
      postMessage({ type: 'showLoader' }, '*')

      const result = await getAllDocumentosById({ ocorrencia_id: id })
      SessionStorageService.setItem("resultadoDocumentosPesquisaModulo", result);

      if (Array.isArray(result)) {
        setData(result)
      }
    } catch (error) {
      setData([])
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  const _getDocumentosByUsoSolo = async (id?: any) => {
    try {
      postMessage({ type: 'showLoader' }, '*')
      const result = await getAllDocumentosById({titulo_id: id})
      SessionStorageService.setItem("resultadoDocumentosPesquisaModulo", result);

      if (Array.isArray(result)) {
        setData(result)
      }
    } catch (error) {
      setData([])
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  const _getDocumentosByModelo = async (id?: any) => {
    try {
      postMessage({ type: 'showLoader' }, '*')
      const result = await getDocumentosByModelo(id)
      SessionStorageService.setItem("resultadoDocumentosPesquisaModulo", result);

      if (Array.isArray(result)) {
        setData(result)
      }
    } catch (error) {
      setData([])
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  const _getDocumentosByModeloExternal = async (id?: any) => {
    try {
      postMessage({ type: 'showLoader' }, '*')
      const result = await getAllDocumentosById({ modelo_id: id })
      SessionStorageService.setItem("resultadoDocumentosPesquisaModulo", result);

      if (Array.isArray(result)) {
        setData(result)
      }
    } catch (error) {
      setData([])
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  const _getDocumentosByPatrimonio = async (id?: any) => {
    try {
      postMessage({ type: 'showLoader' }, '*')
      const result = await getDocumentosByPatrimonio(id)
      SessionStorageService.setItem("resultadoDocumentosPesquisaModulo", result);

      if (Array.isArray(result)) {
        setData(result)
      }
    } catch (error) {
      setData([])
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  const _getDocumentosByPatrimonioExternal = async (id?: any) => {
    try {
      postMessage({ type: 'showLoader' }, '*')
      const result = await getAllDocumentosById({ patrimonio_id: id })
      SessionStorageService.setItem("resultadoDocumentosPesquisaModulo", result);

      if (Array.isArray(result)) {
        setData(result)
      }
    } catch (error) {
      setData([])
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  const _getDocumentosByIntervencao = async (id?: any) => {
    try {
      postMessage({ type: 'showLoader' }, '*')
      const result = await getDocumentosByIntervencao(id)
      SessionStorageService.setItem("resultadoDocumentosPesquisaModulo", result);

      if (Array.isArray(result)) {
        setData(result)
      }
    } catch (error) {
      setData([])
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  const _getDocumentosByIntervencaoExternal = async (id?: any) => {
    try {
      postMessage({ type: 'showLoader' }, '*')
      const result = await getAllDocumentosById({ int_intervencao_id: id })
      SessionStorageService.setItem("resultadoDocumentosPesquisaModulo", result);

      if (Array.isArray(result)) {
        setData(result)
      }
    } catch (error) {
      setData([])
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  const _getDocumentosByAcao = async (id?: any) => {
    try {
      postMessage({ type: 'showLoader' }, '*')
      const result = await getDocumentosByAcao(id)
      SessionStorageService.setItem("resultadoDocumentosPesquisaModulo", result);

      if (Array.isArray(result)) {
        setData(result)
      }
    } catch (error) {
      setData([])
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }
  const _getDocumentosByCandidatura = async (id?: any) => {
    try {
      postMessage({ type: 'showLoader' }, '*')
      const result = await getDocumentosByCandidatura(id)
      SessionStorageService.setItem("resultadoDocumentosPesquisaModulo", result);

      if (Array.isArray(result)) {
        setData(result)
      }
    } catch (error) {
      setData([])
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }
  const _getDocumentosByCandidaturaExternal = async (id?: any) => {
    try {
      postMessage({ type: 'showLoader' }, '*')
      const result = await getAllDocumentosById({ int_candidatura_id: id })
      SessionStorageService.setItem("resultadoDocumentosPesquisaModulo", result);
      if (Array.isArray(result)) {
        setData(result)
      }
    } catch (error) {
      setData([])
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  const _getDocumentosByMobilizacao = async (id?: any) => {
    try {
      postMessage({ type: 'showLoader' }, '*')
      const result = await getDocumentosByMobilizacao(id)
      SessionStorageService.setItem("resultadoDocumentosPesquisaModulo", result);

      if (Array.isArray(result)) {
        setData(result)
      }
    } catch (error) {
      setData([])
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  const _getDocumentosByMobilizacaoExternal = async (id?: any) => {
    try {
      postMessage({ type: 'showLoader' }, '*')
      const result = await getAllDocumentosById({ int_mobilizacao_id: id })
      SessionStorageService.setItem("resultadoDocumentosPesquisaModulo", result);
      if (Array.isArray(result)) {
        setData(result)
      }
    } catch (error) {
      setData([])
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  const _getDocumentosByAcaoExternal = async (id?: any) => {
    try {
      postMessage({ type: 'showLoader' }, '*')
      const result = await getAllDocumentosById({ int_acao_id: id })
      SessionStorageService.setItem("resultadoDocumentosPesquisaModulo", result);

      if (Array.isArray(result)) {
        setData(result)
      }
    } catch (error) {
      setData([])
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  const _getDocumentosByRegistoVooDroneId = async (id?: any) => {
    try {
      postMessage({ type: 'showLoader' }, '*')
      const result = await getDocumentosByRegistoVooDroneId(id)
      SessionStorageService.setItem("resultadoDocumentosPesquisaModulo", result);

      if (Array.isArray(result)) {
        setData(result)
      }
    } catch (error) {
      setData([])
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }


  const _getDocumentosByDemarcacao = async (id?: any) => {
    try {
      postMessage({ type: 'showLoader' }, '*')
      const result = await getDocumentosByDemarcacao(id)
      SessionStorageService.setItem("resultadoDocumentosPesquisaModulo", result);

      if (Array.isArray(result)) {
        setData(result)
      }
    } catch (error) {
      setData([])
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  const _getDocumentosByDemarcacaoExternal = async (id?: any) => {
    try {
      postMessage({ type: 'showLoader' }, '*')
      const result = await getAllDocumentosById({ demarcacao_id: id })
      SessionStorageService.setItem("resultadoDocumentosPesquisaModulo", result);

      if (Array.isArray(result)) {
        setData(result)
      }
    } catch (error) {
      setData([])
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  const _getDocumentosByPretensao = async (id?: any) => {
    try {
      postMessage({ type: 'showLoader' }, '*')
      const result = await getDocumentosByPretensao(id)
      SessionStorageService.setItem("resultadoDocumentosPesquisaModulo", result);

      if (Array.isArray(result)) {
        setData(result)
      }
    } catch (error) {
      setData([])
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

   const _getDocumentosByUsoSoloExternal = async (id?: any) => {
    try {
      postMessage({ type: 'showLoader' }, '*')
      const result = await getAllDocumentosById({ titulo_id: id })
      SessionStorageService.setItem("resultadoDocumentosPesquisaModulo", result);

      if (Array.isArray(result)) {
        setData(result)
      }
    } catch (error) {
      setData([])
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  const _getDocumentosByPretensaoExternal = async (id?: any) => {
    try {
      postMessage({ type: 'showLoader' }, '*')
      const result = await getAllDocumentosById({ pretensao_id: id })
      SessionStorageService.setItem("resultadoDocumentosPesquisaModulo", result);

      if (Array.isArray(result)) {
        setData(result)
      }
    } catch (error) {
      setData([])
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  const _getDocumentosByDesconformidade = async (id?: any) => {
    try {
      postMessage({ type: 'showLoader' }, '*')
      const result = await getDocumentosByDesconformidade(id)
      SessionStorageService.setItem("resultadoDocumentosPesquisaModulo", result);

      if (Array.isArray(result)) {
        setData(result)
      }
    } catch (error) {
      setData([])
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  const _getDocumentosByEaRegistoAnalise = async (id?: any) => {
    try {
      postMessage({ type: 'showLoader' }, '*')
      const result = await getDocumentosByEaRegistoAnalise(id)
      SessionStorageService.setItem("resultadoDocumentosPesquisaModulo", result);

      if (Array.isArray(result)) {
        setData(result)
      }
    } catch (error) {
      setData([])
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  const _getDocumentosByDesconformidadeExternal = async (id?: any) => {
    try {
      postMessage({ type: 'showLoader' }, '*')
      const result = await getAllDocumentosById({ desconformidade_id: id })
      SessionStorageService.setItem("resultadoDocumentosPesquisaModulo", result);

      if (Array.isArray(result)) {
        setData(result)
      }
    } catch (error) {
      setData([])
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  const fetchData = async () => {
    const modulo = sessionStorage.getItem('Modulo')

    switch (modulo) {
      case DOMINIO_HIDRICO_MODULE_NAME: {
        const patrimonioSelected = getPatrimonioSelected()
        const demarcacaoSelected = getDemarcacaoSelected()
        const processoSelected = getProcessoSelected()

        if (patrimonioSelected && patrimonioSelected?.id) {
          _getDocumentosByPatrimonio(patrimonioSelected?.id)
        } else if (demarcacaoSelected && demarcacaoSelected?.id) {
          _getDocumentosByDemarcacao(demarcacaoSelected?.id)
        } else if (processoSelected && processoSelected?.id) {
          _getDocumentosByProcesso(processoSelected?.id)
        }

        break
      }

      case ENTIDADES_MODULE_NAME: {
        if (selectedEntidadeResult && selectedEntidadeResult?.id) {
          _getDocumentosByEntidade(selectedEntidadeResult?.id)
        }

        break
      }

      case OCORRENCIAS_MODULE_NAME: {
        if (selectedOcorrenciaResult?.id || getOcorrenciaSelected()?.id) {
          _getDocumentosByOcorrencia(selectedOcorrenciaResult?.id ?? getOcorrenciaSelected().id)
        }

        break
      }

      case MODELOS_MODULE_NAME: {
        if (selectedModeloResult && selectedModeloResult?.id) {
          _getDocumentosByModelo(selectedModeloResult?.id)
        }

        break
      }

       case USO_DO_SOLO_MODULE_NAME: {
        const usoSoloSelected = getUsoSoloSelected();
        if (usoSoloSelected && usoSoloSelected?.id) {
          _getDocumentosByUsoSolo(usoSoloSelected?.id)
        }

        break
      }

      case PATRIMONIO_MODULE_NAME: {
        if (selectedPatrimonioResult && selectedPatrimonioResult?.id) {
          _getDocumentosByPatrimonio(selectedPatrimonioResult?.id)
        }

        break
      }

      case APOIO_GESTAO_MODULE_NAME: {
        const pretensaoSelected = getPretensaoSelected()
        const desconformidadeSelected = getDesconformidadeSelected()
        if (pretensaoSelected && pretensaoSelected?.id) {
          _getDocumentosByPretensao(pretensaoSelected?.id)
        } else if (desconformidadeSelected && desconformidadeSelected?.id) {
          _getDocumentosByDesconformidade(desconformidadeSelected?.id)
        }

        break
      }

      case ESTRATEGIA_ADAPATACAO_MODULE_NAME: {
        const estrategiaAdaptacaoSelected = getEaRegistoAnalise();
        if (estrategiaAdaptacaoSelected && estrategiaAdaptacaoSelected?.id) {
          _getDocumentosByEaRegistoAnalise(estrategiaAdaptacaoSelected?.id);
        }
        break
      }

      case INTERVENCAO_MODULE_NAME: {
        const intervencaoSelected = getIntervencaoSelected()
        const acaoSelected = getAcaoSelected()
        const candidaturaSelected = getCandidaturaSelected()
        const mobilizacaoSelected = getMobilizacaoSelected()
        if (intervencaoSelected && intervencaoSelected?.id) {
          _getDocumentosByIntervencao(intervencaoSelected?.id)
        } else if (acaoSelected && acaoSelected?.id) {
          _getDocumentosByAcao(acaoSelected?.id)
        } else if (candidaturaSelected && candidaturaSelected?.id) {
          _getDocumentosByCandidatura(candidaturaSelected?.id)
        } else if (mobilizacaoSelected && mobilizacaoSelected?.id) {
          _getDocumentosByMobilizacao(mobilizacaoSelected?.id)
        }

        break
      }
      case VOO_DRONE_MODULE_NAME:{
        if(selectedVooDronePathResult && selectedVooDronePathResult?.id_registo_voo_drone){
          const id_registo_voo_drone = selectedVooDronePathResult?.id_registo_voo_drone;
          await _getDocumentosByRegistoVooDroneId(id_registo_voo_drone)
        }
        break;
      }
    }
  }

  useEffect(() => {

    if (!estados) {
      fillEstadoAsync();
    }
    if (!idioma) {
      fillIdiomasAsync();
    }
    if (!tipoacesso) {
      fillTipoAcessoAsync();
    }
    if (!tiporecurso) {
      fillTiposProcessoAsync();
    }

    console.log("viewMode")
    console.log(viewMode)

    fetchData()
  }, [])

  const addNew = (e: any) => {
    setOpenForm(true)
    e.preventDefault()
    return
  }

  const close = () => {
    setOpenForm(false)
  }

  const closePreview = () => {
    setOpenDocumentModal(false);
  }

  const enterEdit = (item: any) => {
    console.log(item)
    return
  }

  const pointOnMap = (item: any) => {
    console.log(item)
    return
  }
  const openFileDoc = (item: any) => {
    console.log(item)
    return
  }
  const openDocument = (item: any) => {
    console.log(item)
    return
  }

  const MyMultiActionCommandCellEntidades = (props: GridCellProps) => (
    <MultiActionCommandCell
      {...props}
      viewMode = {viewMode}
      deleteItem={handleOpenDescartar}
      onDocumentPreviewClick={handleDocumentPreviewClickById}
    />
  )

  const handleOpenDescartar = (item: any) => {
    console.log(item);
    setIsPrimario(item.primario);
    if (item.primario) {
      setIdToRemove(item.id);
    } else {
      setIdToRemove(item.idAssociacao);
    }
    setOpenModalDescartar(true);
  }

  const cancelModalDescartar = () => {
    setOpenModalDescartar(false)
  }

  const handleDescartar = async (e: any) => { 
    if (isPrimario) {
      const result = await deleteDocumento(idToRemove);
    } else {
      const result = await deleteAssociacao(idToRemove);
    }
    
    setOpenModalDescartar(false);
    fetchData();
  }
  const availableData = data.slice();
  const initialData = availableData.splice(0, 20);
  
  const [gridData, setGridData] = React.useState(initialData);
  
  const scrollHandler = (event: { nativeEvent: any; }) => {
    const ScroolLength = 10;
    const e = event.nativeEvent;
    if (
      e.target.scrollTop + ScroolLength >=
      e.target.scrollHeight - e.target.clientHeight
    ) {
      const moreData = availableData.splice(0, ScroolLength);
      if (moreData.length > 0) {
        setGridData((oldData) => oldData.concat(moreData));
      }
    }
  };

  return (
    <>
      <Grid
        //className={selectedStyles.form}
        className={` ${selectedStyles.form} ${viewMode? 'customGridVisualizar' : 'k-grid.k-grid-md.GridDocumentos_form__Kg7bw.customGrid k-grid.k-grid-md.GridDocumentos_form__qz-Iu.customGrid'} `}  
        data={data.map((data: any) => ({
          ...data,
          primarioDescription: data.primario ? "Sim" : "Não"
        }))?.slice(page.skip, page.take + page.skip)}
        pageable={{
          buttonCount: 5,
          info: true,
          type: 'numeric',
          pageSizes: true,
          previousNext: true,
        }}
        sortable={true}
        onScroll={scrollHandler}
        fixedScroll={true}
        rowHeight={50}
        skip={page.skip}
        take={page.take}
        total={data.length}
        onPageChange={handlePageChange}
      >

      {!viewMode  ? (
        <GridToolbar>
            <button
              title="Adicionar novo"
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary buttonAdicionarNovo btnPrimaryColor"
              onClick={(e) => {
                addNew(e);
              }}
            >
              <span className="icon-siarl icon-registar k-button-icon"></span>{' '}
              &nbsp;{' '}
              Adicionar Novo
            </button>
        </GridToolbar>
      ) : ""}
      
        <GridNoRecords>
          Nenhum registo disponível
        </GridNoRecords>
        <GridColumn width={56} cell={MyMultiActionCommandCellEntidades}/>
        <GridColumn field="id" width="90px" title="ID"/>
        <GridColumn
          width="170px"
          field="data_registo"
          title="Data registo"
          cell={(props) => (
            <td>{moment.utc(props.dataItem.data_registo).tz(CURRENT_TIMEZONE).format("DD/MM/yyyy HH:mm")}</td>
          )}
        />
        <GridColumn field="titulo" title="Título" width="170px"/>
        <GridColumn
          width="170px"
          field="tipoRecurso.designacao"
          title="Tipo de recurso"
        />
        {/* <GridColumn field="autores" title="Autor" width="170px"/>
        <GridColumn field="entidade.nome" title="Entidade" width="170px"/>
        <GridColumn field="estado.designacao" title="Estado" width="170px"/> */}
        <GridColumn field="primarioDescription" title="Primario" width="170px"/>
      </Grid>

      {openForm && (
        <Dialog
          className="ModalDocumentos"
          title={`Adicionar documento`}
          onClose={close}
          style={{ zIndex: 999 }}
        >
          <CarregarDocumentoTabs
            close={close}
            handleSubmitDocumento={handleSubmitDocumento}
            handleSubmitExternalDocumento={handleSubmitExternalDocumento}
            formRenderProps={formRenderProps}
          />
        </Dialog>
      )}

      {openDocumentModal && (
        <Dialog
          className="ModalDocumentos"
          title={`Documento: ${idDoDocumento}`}
          onClose={closePreview}
          style={{ zIndex: 999 }}
        >
          <ImagePreviewV2
            documentUrl={urlDoDocumento}
            next={(e: any) => nextPhoto(e)}
            previous={(e: any) => previousPhoto(e)}
          />
        </Dialog>
      )}
      {openModalDescartar ? (
          <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={cancelModalDescartar}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                  <fieldset>
                    <span className="textDescartar">Tem a certeza que deseja apagar {isPrimario ? 'este documento' : 'esta associação'} ?  </span>
                    <br></br>
                  </fieldset>
                  <br></br>
                  <div className="k-form-buttons btnModal">                    
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                      onClick={cancelModalDescartar}
                    >
                      Não
                    </button>
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar"
                      onClick={handleDescartar}

                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}
    </>
    
  )
}