import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import styles from '../../styles/viewer/ShareModal.module.scss'
import { Button } from '@progress/kendo-react-buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { useGeovisualizadoresStore } from '../../stores/geovisualizador'

type Props = {
  toggleDialog: () => void
}

export default function SharedMapModal({ toggleDialog }: Props) {
  const selectedGVResult = useGeovisualizadoresStore(
    (state) => state.selectedGVResult
  )

  const copyToClipboard = async (textToCopy: any) => {
    // Navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
      await navigator.clipboard.writeText(textToCopy)
    } else {
      //hack to avoid http issue
      // Use the 'out of viewport hidden text area' trick
      const textArea = document.createElement('textarea')
      textArea.value = textToCopy

      // Move textarea out of the viewport so it's not visible
      textArea.style.position = 'absolute'
      textArea.style.left = '-999999px'

      document.body.prepend(textArea)
      textArea.select()

      try {
        document.execCommand('copy')
      } catch (error) {
        console.error(error)
      } finally {
        textArea.remove()
      }
    }
  }

  return (
    <Dialog
      className="dialog-center"
      title="Mapa partilhado"
      onClose={toggleDialog}
    >
      <div className={styles.generateMapSection}>
        <div className={styles.generateMapSectionHeader}>
          <span className={styles.generateMapSectionHeaderText}>
            Link gerado
          </span>
          <Button
            fillMode="flat"
            className={styles.generateMapSectionHeaderIconButton}
            onClick={async () =>
              await copyToClipboard(selectedGVResult?.link_gerado)
            }
            title='Copiar Link'
          >
            <FontAwesomeIcon
              icon={faCopy}
              className={styles.generateMapSectionHeaderIcon}
            />
          </Button>
        </div>
        <div className={styles.generateMapSectionBody}>
          <span className={styles.generateMapSectionLink}>
            {selectedGVResult?.link_gerado}
          </span>
        </div>
      </div>
      <DialogActionsBar layout="end">
        <Button onClick={toggleDialog} className={styles.cancelButton}>
          Fechar
        </Button>
      </DialogActionsBar>
    </Dialog>
  )
}
