import React, {
    MouseEvent as ReactMouseEvent, useEffect, useState
} from 'react'

import { Button } from '@progress/kendo-react-buttons'
import { DatePicker, DateTimePicker, MultiViewCalendar } from '@progress/kendo-react-dateinputs'
import { ComboBox } from '@progress/kendo-react-dropdowns'
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form'
import { Checkbox, Input, TextArea } from '@progress/kendo-react-inputs'
import { Dialog } from '@progress/kendo-react-dialogs'
import { filterBy } from '@progress/kendo-data-query'
import { coordenadasSelected, condicionantesProcessos, fillCondicionantesProcessosAsync, estadosProcesso, fillEstadosProcessoAsync, fasesProcedimentoDelimitacao, fillFasesProcedimentoDelimitacaoAsync, localizacoesProcesso, fillLocalizacoesProcessosAsync, tiposAcaoJudicial, fillTiposAcaoJudicialAsync, tiposAquisicao, fillTiposAquisicaoAsync, tiposDecisaoFinal, fillTiposDecisaoFinalAsync, tiposDesafetacao, fillTiposDesafetacaoAsync, tiposIniciativa, fillTiposIniciativaAsync, tiposParcela, fillTiposParcelaProvaAsync, tiposPendencia, fillTiposPendenciaAsync, tipossProcesso, fillTiposProcessoAsync, tiposSentenca, fillTiposSentencaAsync, validacoesProva, fillValidacoesProvaAsync, dhTipoDominio, fillDhTipoDominioAsync, dhRppAntes, fillDhRppAntesAsync, dhRppDepois, fillDhRppDepoisAsync, dhRecurso, fillDhRecursoAsync, get, getProcessoSelected, hasPermission, setProcessoSelected, dhDeferimento, fillDhDeferimentoAsync } from '../../../services/utils'
import { useDhProcessoStore } from '../../../stores/dhProcesso'
import styles from '../../../styles/dominiohidrico/RegistarProcessoForm.module.scss'
import { DH_AUTOS_GET_BY_PROC, DH_AUTOS_VERTICES_GET_BY_PROC, DH_PARECERES_BY_PROC, PERMISSION_DOMINIO_HIDRICO_PERFIL_BASICO, PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_EDICAO, PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_LEITURA, PERMISSION_DOMINIO_HIDRICO_PERFIL_TOTAL, SAVE_NEW_DH_PROCESSO, UPDATE_DH_PROCESSO } from '../../../utils/constants'
import CheckMoreItem from '../../documentos/registar/fields/CheckMoreItem'
import { v4 as uuidv4 } from 'uuid';
import {
  arhs,
  capitania,
  concelhos,
  distritos,
  freguesias,
  
  massaagua,
  nuts1,
  nuts2,
  nuts3,
  tecnico,
  userLogged
} from '../../../services/utils'
import { Grid, GridColumn } from '@progress/kendo-react-grid'
import { requiredFieldValidator } from '../../../validations/ocorrencia'
import SessionStorageService from '../../../services/SessionStorageService'
import { faInfoCircle, faRemove } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import { overflow } from 'html2canvas/dist/types/css/property-descriptors/overflow'

type IProps = {
    onCloseClick: (
        e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
    ) => void | undefined
    formRenderProps: FormRenderProps
    viewMode?: boolean
    onCheckboxValueChange: (isChecked: boolean) => void;
}

type PageState = {
    skip: number
    take: number
  }

interface FormData {
    id?: any;
    tipo_dominio: any;
    Latitude?: string;
    Longitude?: string;
    Latitude_Corrigida?: string;
    Longitude_Corrigida?: string;
    indice?: string;
}

export default function Identificacao({ onCloseClick, formRenderProps, viewMode, onCheckboxValueChange }: IProps) {

    const selectedProcessoResultId: any = useDhProcessoStore((state) => state.selectedDhProcessoResultId)
    const selectedProcessoResult: any = useDhProcessoStore((state) => state.selectedDhProcessoResult);
    const selectProcessoResult = useDhProcessoStore((state) => state.selectDhProcessoResult)
    const selectProcessoResultId = useDhProcessoStore((state) => state.selectDhProcessoResultId)
    const [isChecked, setIsChecked] = useState(false)

    const [formData, setFormData] = useState<any>();
    const [formDataParecer, setFormDataParecer] = useState<any>();
    const [gridData, setGridData] = useState<any[]>([]);
    const [gridDataParecer, setGridDataParecer] = useState<any[]>([]);
    const [page2, setPage2] = useState({ skip: 0, take: 10 });
  
    const [openModalAutoDelimitacao, setopenModalAutoDelimitacao] = useState<any>()
    const [openModalEdicaoAutoDelimitacao, setopenModalEdicaoAutoDelimitacao] = useState<any>()
    const [openModalDhParecer, setOpenModalDhParecer] = useState<any>();
    const [isAutoDelimitacaoFilled, setAutoDelimitacaoFilled] = useState(false);
    const [selectedGridItemId, setSelectedGridItemId] = useState<string | null>(null);
    const [isEditingModalParecer, setIsEditingModalParecer] = useState(false);
    const [isEditingModal, setIsEditingModal] = useState(false);
    const [idModalEditing, setIdModalEditing] = useState<any>();
    const getDhAutosVerticesFromProcId = async (data: any) => await get<any>(`${DH_AUTOS_VERTICES_GET_BY_PROC}/` + data); 
    const getDhParecerFromProcId = async (data: any) => await get<any>(`${DH_PARECERES_BY_PROC}/` + data); 
    const getDhAutosFromProcId = async (data: any) => await get<any>(`${DH_AUTOS_GET_BY_PROC}/` + data);
    

    const [nuts2Filtered, setNuts2Filtered] = useState<any>()
    const [nuts3Filtered, setNuts3Filtered] = useState<any>()
    const [freguesiasFiltred, setFreguesiasFiltred] = useState<any>()
    const [concelhosFiltred, setConcelhosFiltred] = useState<any>()
    const [dataItemToRemove, setDataItemToRemove] = useState<any>();
    const [dataItemToRemoveParecer, setDataItemToRemoveParecer] = useState<any>();
    const ID_DOMINIO_PUBLICO_HIDRICO_DEFAULT = 1;

    const [openModalDescartar, setOpenModalDescartar] = useState<any>();
    const [openModalDescartarParecer, setOpenModalDescartarParecer] = useState<any>();

    type combosKeys= 'tipossProcesso'| 'localizacoesProcesso' | 'tiposSentenca'| 'estadosProcesso' | 'fasesProcedimentoDelimitacao' | 'tiposPendencia' |
    'tiposDecisaoFinal' | 'validacoesProva' | 'tiposParcela' | "condicionantesProcessos"  | 'tiposAcaoJudicial' | 'tiposDesafetacao' | 'tiposAquisicao' |
    'tiposIniciativa' | 'dhTipoDominio' | 'dhRppAntes' | 'dhRppDepois' | 'dhRecurso' | 'dhDeferimento';

    const [combos, setCombos] = useState<any>({
        tipossProcesso,
        localizacoesProcesso,
        estadosProcesso,
        tiposSentenca,
        fasesProcedimentoDelimitacao,
        validacoesProva,
        tiposParcela,
        condicionantesProcessos,
        tiposAcaoJudicial,
        tiposDesafetacao,
        tiposIniciativa,
        tiposAquisicao,
        tiposDecisaoFinal,
        dhTipoDominio,
        dhRppAntes,
        dhRppDepois,
        dhRecurso,
        dhDeferimento
    })


    const setDataOption: Record<combosKeys, { setFn: any, data: any[] }> = {
        tipossProcesso: {
            setFn: setCombos,
            data: tipossProcesso
        },
        localizacoesProcesso: {
            setFn: setCombos,
            data: localizacoesProcesso
        },
        estadosProcesso: {
            setFn: setCombos,
            data: estadosProcesso
        },
        fasesProcedimentoDelimitacao: {
            setFn: setCombos,
            data:fasesProcedimentoDelimitacao
        },
        tiposSentenca: {
            setFn: setCombos,
            data: tiposSentenca
        },
        tiposDecisaoFinal: {
            setFn: setCombos,
            data: tiposDecisaoFinal
        },
        validacoesProva: {
            setFn: setCombos,
            data:validacoesProva
        },
        tiposParcela: {
            setFn: setCombos,
            data: tiposParcela
        },
        tiposPendencia: {
            setFn: setCombos,
            data: tiposPendencia
        },
        condicionantesProcessos: {
            setFn: setCombos,
            data: condicionantesProcessos
        },
        tiposAcaoJudicial: {
            setFn: setCombos,
            data: tiposAcaoJudicial
        },
        tiposDesafetacao: {
          setFn: setCombos,
            data: tiposDesafetacao
        },
        tiposAquisicao: {
            setFn: setCombos,
            data: tiposAquisicao
        },
        tiposIniciativa: {
            setFn: setCombos,
            data: tiposIniciativa
        },
        dhTipoDominio: {
            setFn: setCombos,
            data: dhTipoDominio
        },
        dhRppAntes: {
            setFn: setCombos,
            data: dhRppAntes
        },
        dhRppDepois: {
            setFn: setCombos,
            data: dhRppDepois
        },

        dhRecurso: {
            setFn: setCombos,
            data: dhRecurso
        },
        dhDeferimento: {
            setFn: setCombos,
            data: dhDeferimento
        },
      };

    const filterCombo_Multiselect = (e: any, tipoCombo: combosKeys) => {
        const { setFn, data } = setDataOption[tipoCombo];
        setFn({
            ...combos,
            [tipoCombo]: filterBy(data?.slice()??[], e.filter)
        }
        );
    }

    useEffect(() => {
        const fetchData = async(procId: any)=> {

           const autos: any[] = await getDhAutosFromProcId(procId);
           const dtoArray : any[] = [];
           let objToAdd = {};
           
           autos.forEach((item1) => {
            const fieldsToadd: any[] = [];
              const vertices: any[] = item1.dhAutosVertices;
              vertices.forEach((item, index) => {
                fieldsToadd.push({[`indice${index}`]: item['indice']});
                objToAdd = {...objToAdd,
                    ['tipo_DominioAuto'] : item1['dhTipoDominio'],
                    [`latitude${index}`] : item['lat_y_auto'],
                    [`longitude${index}`] : item['lon_x_auto'],
                    [`latitudeCorrigida${index}`]: item['lat_y_corrigida'],
                    [`longitudeCorrigida${index}`]: item['lon_x_corrigida'],
                    [`indice${index}`]: item['indice']
                }
              })
              const objWithId = {
                id : uuidv4(),
                e: objToAdd
              }
              dtoArray.push(objWithId);
              setFields(fieldsToadd);
              SessionStorageService.setItem("fieldsDHAuto", fields);
           })
           
            
            setGridData(dtoArray);

            const pareceres : any[] = await getDhParecerFromProcId(procId);

            const dtoArrayPareceres: any[] = [];
            pareceres.forEach(item => {
                const objToAdd = {
                    id : uuidv4(),
                    id_dh_proc: procId,
                    npar_cdpm : item['npar_cdpm'],
                    data_par_cdpm : item['data_par_cdpm'],
                    data_hom_par_cdpm: item['data_hom_par_cdpm'],
                    data_par_cdpm_formated : item['data_par_cdpm'] ? format(new Date(item['data_par_cdpm'] ), 'dd/MM/yyyy') :  undefined,
                    data_hom_par_cdpm_formated: item['data_hom_par_cdpm'] ? format(new Date(item['data_hom_par_cdpm'] ), 'dd/MM/yyyy') :  undefined
                }
                dtoArrayPareceres.push(objToAdd);
            });
            setGridDataParecer(dtoArrayPareceres);
        }
        
        if (getProcessoSelected()?.id) {
            fetchData(getProcessoSelected()?.id);
            verificarID();
        }
        
    }, [])

    useEffect(() => {
        if(!tipossProcesso){
         fillTiposProcessoAsync();
        }
        if(!localizacoesProcesso){
         fillLocalizacoesProcessosAsync();
        }
        if(!estadosProcesso){
         fillEstadosProcessoAsync();
        }
        if(!fasesProcedimentoDelimitacao){
         fillFasesProcedimentoDelimitacaoAsync();
        }
        if(!tiposSentenca){
         fillTiposSentencaAsync();
        }
         if(!tiposDecisaoFinal){
         fillTiposDecisaoFinalAsync();
        }
         if(!validacoesProva){
         fillValidacoesProvaAsync();
        }
        if(!tiposParcela){
         fillTiposParcelaProvaAsync();
        }
         if(!tiposPendencia){
         fillTiposPendenciaAsync();
        }
         if(!condicionantesProcessos){
         fillCondicionantesProcessosAsync();
        }
        if(!tiposAcaoJudicial){
         fillTiposAcaoJudicialAsync();
        }
        if(!tiposDesafetacao){
         fillTiposDesafetacaoAsync();
        }
        if(!tiposAquisicao){
         fillTiposAquisicaoAsync();
        }
         if(!tiposIniciativa){
         fillTiposIniciativaAsync();
        }
        if(!estadosProcesso){
           fillEstadosProcessoAsync();
        }
        if(!dhTipoDominio){
           fillDhTipoDominioAsync();
        }
        if(!dhRppAntes){
           fillDhRppAntesAsync();
        }
        if(!dhRppDepois){
           fillDhRppDepoisAsync();
        }
        if(!dhRecurso){
           fillDhRecursoAsync();
        }
        if(!dhDeferimento){
            fillDhDeferimentoAsync();
        }
    }, []);

  const handleCheckboxChange = () => {
    const newCheckedValue = !isChecked;
    setIsChecked(newCheckedValue);
    onCheckboxValueChange(newCheckedValue); 
  };



const TipoProcessoComboLogs = () => {
    //const selectedValue = combos?.tipossProcesso
    const ValueCombo = formRenderProps.valueGetter("id_tipo_processo")

    console.log('ID Tipo Processo : ' + ValueCombo?.id)
}

const [idInputValue, setIdInputValue] = useState<string>('');

const [showDefault, setDefault] = useState<boolean>(true);
const [showOpcao0, setOpcao0] = useState<boolean>(false);
const [showOpcao1, setOpcao1] = useState<boolean>(false);
const [showOpcao2, setOpcao2] = useState<boolean>(false);
const [showOpcao3, setOpcao3] = useState<boolean>(false);
const [showOpcao4, setOpcao4] = useState<boolean>(false);
const [showOpcao5, setOpcao5] = useState<boolean>(false);
const [showOpcao6, setOpcao6] = useState<boolean>(false);
const [showOpcao7, setOpcao7] = useState<boolean>(false);


const verificarID = () => {
    const selectedValue = getProcessoSelected()?.dhTipoProcesso?.id
    const ValueCombo = formRenderProps.valueGetter("id_tipo_processo")
    
    if (ValueCombo?.id === 0 || selectedValue === 0) {
        setOpcao0(true);
        setOpcao1(false);
        setOpcao2(false);
        setOpcao3(false);
        setOpcao4(false);
        setOpcao5(false);
        setOpcao6(false);
        setOpcao7(false);
        setDefault(false);
    }else if (ValueCombo?.id === 1 || selectedValue === 1) {
      setOpcao0(false);
      setOpcao1(true);
      setOpcao2(false);
      setOpcao3(false);
      setOpcao4(false);
      setOpcao5(false);
      setOpcao6(false);
      setOpcao7(false);
      setDefault(false);
    }else if (ValueCombo?.id === 2 || selectedValue === 2) {
      setOpcao0(false);
      setOpcao1(false);
      setOpcao2(true);
      setOpcao3(false);
      setOpcao4(false);
      setOpcao5(false);
      setOpcao6(false);
      setOpcao7(false);
      setDefault(false);
    }else if (ValueCombo?.id === 3 || selectedValue === 3) {
        setOpcao0(false);
      setOpcao1(false);
      setOpcao2(false);
      setOpcao3(true);
      setOpcao4(false);
      setOpcao5(false);
      setOpcao6(false);
      setOpcao7(false);
      setDefault(false);
    }else if (ValueCombo?.id === 4 || selectedValue === 4) {
        setOpcao0(false);
      setOpcao1(false);
      setOpcao2(false);
      setOpcao3(false);
      setOpcao4(true);
      setOpcao5(false);
      setOpcao6(false);
      setOpcao7(false);
      setDefault(false);
    }else if (ValueCombo?.id === 5 || selectedValue === 5) {
        setOpcao0(false);
      setOpcao1(false);
      setOpcao2(false);
      setOpcao3(false);
      setOpcao4(false);
      setOpcao5(true);
      setOpcao6(false);
      setOpcao7(false);
      setDefault(false);
    }else if (ValueCombo?.id === 6 || selectedValue === 6) {
        setOpcao0(false);
      setOpcao1(false);
      setOpcao2(false);
      setOpcao3(false);
      setOpcao4(false);
      setOpcao5(false);
      setOpcao6(true);
      setOpcao7(false);
      setDefault(false);
    }else if (ValueCombo?.id === 7 || selectedValue === 7) {
        setOpcao0(false);
      setOpcao1(false);
      setOpcao2(false);
      setOpcao3(false);
      setOpcao4(false);
      setOpcao5(false);
      setOpcao6(false);
      setOpcao7(true);
      setDefault(false);
    }
};
    
useEffect(() => {
    verificarID();
}, []);

    const canSeeDominioHidrico = 
                            hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_LEITURA) ||
                            hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_EDICAO) ||
                            hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_TOTAL) 

    

    const filterConcelho = (e: any) => {
        if (e?.value?.codigo) {
            const concelhosLocal = concelhos.filter(
                (x: any) => x.distrito_cod == e.value.codigo
            )
            setConcelhosFiltred(concelhosLocal)
        } else {
            setConcelhosFiltred(concelhos)
        }
    }

    const filterFreguesia = (e: any) => {
        if (e?.value?.codigo) {
            const freguesiaLocal = freguesias.filter(
                (x: any) => x.concelho_cod == e.value.codigo
            )
            setFreguesiasFiltred(freguesiaLocal)
        } else {
            setFreguesiasFiltred(freguesias)
        }
    }
    const filterNuts2 = (e: any) => {
        if (e?.value?.codigo) {
            const nutsLocal = nuts2.filter((x: any) => x.nuts_i_cod == e.value.codigo)
            setNuts2Filtered(nutsLocal)
        } else {
            setNuts2Filtered(nuts2)
        }
    }
    const filterNuts3 = (e: any) => {
        if (e?.value?.codigo) {
            const nutsLocal = nuts3.filter(
                (x: any) => x.nuts_ii_cod == e.value.codigo
            )
            setNuts3Filtered(nutsLocal)
        } else {
            setNuts3Filtered(nuts3)
        }
    }

    const changeFileDoc = (e: any) => {
        setProcessoSelected({...getProcessoSelected(), np_filedoc: e?.value});
    }


    const [comAuto, setComAuto] = useState<boolean>(false);

const handleComAutoCheckbox = (event: any) => {
    const checkComAuto = !comAuto;
    setComAuto(checkComAuto);
    console.log('Checkbox value:', checkComAuto);
};

const handleAddItem = (item : any) => {
    const newItem = { ...item, id: uuidv4() };
    setGridData([...gridData, newItem]);
};
    
const handleUpdateItem = (id : any, updatedItem : any) => {
    setGridData(prevData => prevData.map(item => item.id === id ? { ...updatedItem, id } : item));
};

const handleSubmitModal = (e : any) => {

    let lstLatLong: any[] = [];

    let id = uuidv4()
    if (isEditingModal) {
        id = idModalEditing;
    }

    const objToAdd = {
        id : id,
        e
    }

    lstLatLong = gridData.filter(x => x.id != id);

    lstLatLong.push(objToAdd);
    const filedsToAdd : any[] = [];
    const itemsToSend = [];
    Object.keys(objToAdd.e).forEach((key) => {
        const value = objToAdd.e[key];
        // Atribui a quantidade de campos indice dinamicos que foram criados.
        if (key != "indice" && key.indexOf("indice") != -1) {
            filedsToAdd.push({key: value});
        }
    });
    const coordinatesToSend : any[] = [];

    if (filedsToAdd.length < 2) {
        postMessage({type: "infoMsg", value: {message: "A linha tem que ter ao menos 2 pontos."}}, "*");
        return false;
    }

    filedsToAdd.forEach((item, index) => {
        const objToSend = {
            id: id,
            tipo_DominioAuto: e['tipo_DominioAuto'],
            Latitude : e['latitude' + index] ? Number.parseFloat(e['latitude' + index]) : undefined,
            Longitude : e['longitude' + index] ? Number.parseFloat(e['longitude' + index]) : undefined,
            Latitude_Corrigida: e['latitudeCorrigida' + index] ? Number.parseFloat(e['latitudeCorrigida' + index]) : undefined,
            Longitude_Corrigida: e['longitudeCorrigida' + index] ? Number.parseFloat(e['longitudeCorrigida' + index]) : undefined,
            indice: Number.parseInt(e['indice' + index])
        }
        coordinatesToSend.push(objToSend);
    })

    SessionStorageService.setItem(id, coordinatesToSend);
    
    console.log(coordinatesToSend);

    setFields(filedsToAdd)
    SessionStorageService.setItem("fieldsDHAuto", filedsToAdd);

    setGridData(lstLatLong);

    console.log(e.values);
    SessionStorageService.setItem("gridDataAutos", lstLatLong);
    setIsEditingModal(false);
    setIdModalEditing(undefined);
    setopenModalAutoDelimitacao(false);
};


    const handleSubmitModalEdicao = (e: any) => {
        const obj = gridData.find(x => x.id == e.id);
        const objToAdd = {
            id: e['id'],
            Latitude : e['latitude'],
            Longitude : e['longitude'],
            Latitude_Corrigida: e['latitudeCorrigida'],
            Longitude_Corrigida: e['longitudeCorrigida'],
            indice: e['indice']
        }
        const gridToAdd = gridData.filter(x => x.id != e.id).slice();
        gridToAdd.push(objToAdd);
        setGridData(gridToAdd);
        SessionStorageService.setItem("gridDataAutos", gridToAdd);
        setopenModalEdicaoAutoDelimitacao(false);
    }

    const handleEditarModalParecer = (e: any) => {
        const obj = gridData.find(x => x.id == e.id);
        const objToAdd = {
            id: e['id'],
            id_dh_proc: getProcessoSelected()?.id ?? undefined,
            npar_cdpm : e['npar_cdpm'],
            data_par_cdpm : e['data_par_cdpm'],
            data_hom_par_cdpm: e['data_hom_par_cdpm'],
            data_par_cdpm_formated : e['data_par_cdpm'] ? format(new Date(e['data_par_cdpm'] ), 'dd/MM/yyyy') :  undefined,
            data_hom_par_cdpm_formated: e['data_hom_par_cdpm'] ? format(new Date(e['data_hom_par_cdpm'] ), 'dd/MM/yyyy') :  undefined
        }
        const gridToAdd = gridDataParecer.filter(x => x.id != e.id).slice();
        gridToAdd.push(objToAdd);
        setGridDataParecer(gridToAdd);
        const gridDTO = gridToAdd.map((item: any) => ({
            npar_cdpm: item.npar_cdpm,
            data_par_cdpm: item.data_par_cdpm,
            data_hom_par_cdpm: item.data_hom_par_cdpm,
            id_dh_proc: getProcessoSelected()?.id
        }));
        SessionStorageService.setItem("gridDataPareceres", gridDTO);
        setIsEditingModalParecer(false);
        setOpenModalDhParecer(false);
    }

    const handleRowClick = (event : any) => { 
    
        const dataItem = event.dataItem;
        setIsEditingModal(true);
        setIdModalEditing(dataItem.id);
        setFieldsModal([]);
        const filedsToAdd : any= [];
        Object.keys(dataItem.e).forEach((key) => {
            const value = dataItem.e[key];
            // Atribui a quantidade de campos indice dinamicos que foram criados.
            if (key != "indice" && key.indexOf("indice") != -1) {
                filedsToAdd.push({key: value});
            }
        });

        console.log(filedsToAdd);
        setFieldsModal(filedsToAdd);
        setFormData(dataItem.e);
        setopenModalAutoDelimitacao(true);
      };

      const handleEditParecerItem = (event: any) => {
        setIsEditingModalParecer(true);
        const dataItem = event.dataItem;

        setFormDataParecer({
            id: dataItem.id,
            id_dh_proc: getProcessoSelected()?.id ?? undefined,
            npar_cdpm: dataItem.npar_cdpm,
            data_par_cdpm: dataItem.data_par_cdpm ? new Date(dataItem.data_par_cdpm) : undefined,
            data_hom_par_cdpm: dataItem.data_hom_par_cdpm ? new Date(dataItem.data_hom_par_cdpm) : undefined,
        });
        setOpenModalDhParecer(true);
      }

      const handleSubmitModalParecer = (e : any) => {
        if (isEditingModalParecer) {
            handleEditarModalParecer(e);
            return;
        }
        const pareceres: any[] = [];
        const objToAdd = {
            id : uuidv4(),
            id_dh_proc: getProcessoSelected()?.id ?? undefined,
            npar_cdpm : e['npar_cdpm'],
            data_par_cdpm : e['data_par_cdpm'],
            data_hom_par_cdpm: e['data_hom_par_cdpm'],
            data_par_cdpm_formated : e['data_par_cdpm'] ? format(new Date(e['data_par_cdpm'] ), 'dd/MM/yyyy') :  undefined,
            data_hom_par_cdpm_formated: e['data_hom_par_cdpm'] ? format(new Date(e['data_hom_par_cdpm'] ), 'dd/MM/yyyy') :  undefined
        }
        pareceres.push(objToAdd);
    
        const currentGridData = gridDataParecer.slice();
        
        currentGridData.push(objToAdd);
    
        setGridDataParecer(currentGridData);
    
        console.log(e.values);

        const gridDTO = currentGridData.map((item: any) => ({
            npar_cdpm: item.npar_cdpm,
            data_par_cdpm: item.data_par_cdpm,
            data_hom_par_cdpm: item.data_hom_par_cdpm,
            id_dh_proc: getProcessoSelected()?.id
        }));
        SessionStorageService.setItem("gridDataPareceres", gridDTO);
        setOpenModalDhParecer(false);
    };


    const openModal = (e : any) => {
        if (!isEditingModal) {
            setFormData({
                Latitude: "",
                Longitude: "",
                Latitude_Corrigida: "",
                Longitude_Corrigida: "",
                indice: "",
            });
        }
        setAutoDelimitacaoFilled(false)
        e.preventDefault();
        setopenModalAutoDelimitacao(true)
    }

    const openModalParecer = (e : any) => {
        setFormDataParecer({
            npar_cdpm: '',
            id_dh_proc: getProcessoSelected()?.id ?? undefined,
            data_par_cdpm: undefined,
            data_hom_par_cdpm: undefined,
        });
        e.preventDefault();
        setOpenModalDhParecer(true)
    }

    const cancelModal = () => {
        setIsEditingModal(false);
        setFieldsModal([{
            indice: ""
        }])
        setopenModalAutoDelimitacao(false)
    }

    const cancelModalEdicao = () => {
        setopenModalEdicaoAutoDelimitacao(false);
    }
    const cancelModalParecer = () => {
        setOpenModalDhParecer(false);
    }

    const CustomCalendar = (props: any) => {
        return <MultiViewCalendar {...props} views={1} />
      }

    const [fields, setFields] = useState<Array<any>>
         ([{ id: uuidv4(),latitude: '', longitude: '', latitudeCorrigida: '', longitudeCorrigida: '', indice: '' }]);

    const [fieldsModal, setFieldsModal] = useState<Array<any>>
         ([{ id: uuidv4(),latitude: '', longitude: '', latitudeCorrigida: '', longitudeCorrigida: '' }]);

    const [fieldsParecer, setFieldsParecer] = useState<Array<{
        id: any,
        n_par_cdpm: string;
        data_par_cdpm: any;
        data_hom_par_cdpm: any;
    }>> ([{ id: uuidv4(),n_par_cdpm: '', data_par_cdpm: undefined, data_hom_par_cdpm: undefined }]);

    const handleAddField = (e: any) => {
        setFieldsModal([...fieldsModal, { id: uuidv4(),latitude: '', longitude: '', longitudeCorrigida: '', latitudeCorrigida: '' }]);
        e.preventDefault();
    };

    const handleAddFieldParecer = (e: any) => {
        setFieldsParecer([...fieldsParecer, { id: uuidv4(),n_par_cdpm: '', data_par_cdpm: undefined, data_hom_par_cdpm: undefined }]);
        e.preventDefault();
    }

    const handleDeleteField = (e: any) => {
        const fieldsLocal = fieldsModal.slice();
        if (fieldsLocal.length == 1) {
            return;
        }
        fieldsLocal.pop();
        setFieldsModal(fieldsLocal);
        e.preventDefault();
    }

    const handleRemoveItem = () => {
        const item = dataItemToRemove?.dataItem;
        const currentGrid = gridData.filter(x => x.id != item.id).slice();
        setGridData(currentGrid);
        setOpenModalDescartar(false);
    }

    const handleRemoveItemParecer = () => {
        const item = dataItemToRemoveParecer?.dataItem;
        const currentGrid = gridDataParecer.filter(x => x.id != item.id).slice();
        setGridDataParecer(currentGrid);
        setOpenModalDescartarParecer(false);
    }

    useEffect(() => {
        if (!tecnico) {
          userLogged()
        }
    }, [tecnico])


    const keyPressTextArea = (e: any) => {
        if (viewMode) {
          e.preventDefault();
          return false;
        }
    }

    return (
        <>
            <div className={`${styles.form} ${styles.removeScrollDuplicado}`}>

                {/* Fieldset Registo */}
                {canSeeDominioHidrico && (showDefault || showOpcao0 || showOpcao1 || showOpcao2 || showOpcao3 || showOpcao4 || showOpcao5 || showOpcao6 || showOpcao7) ? (
                    <fieldset>

                        <legend>
                            <span className={styles.fieldsetTitulo}>Registo</span>
                        </legend>

                        <div className={styles.espacoVerticalFieldSet}>
                            <div className={styles.fields}>
                                {( hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_BASICO) || canSeeDominioHidrico) ?
                                    <>
                                        <div>
                                            <Field
                                                name="id"
                                                label="ID"
                                                component={Input}
                                                data={selectedProcessoResultId ?? undefined}
                                                disabled
                                            />
                                        </div>

                                        <div>
                                            <Field
                                                name="np_filedoc"
                                                label="Nº Processo APA"
                                                component={Input}
                                                onChange={changeFileDoc}
                                                maxLength={25}
                                                disabled={viewMode}
                                            />
                                        </div>

                                        <div>
                                            <Field
                                                name="np_legado"
                                                label="Nº Proc Legado"
                                                component={Input}
                                                maxLength={25}
                                                disabled={viewMode}
                                            />
                                        </div>
                                    </>
                                : ''}
                            </div>

                            <div className={styles.fields}>
                                {( hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_BASICO) || canSeeDominioHidrico) ?
                                        <div>
                                            <Field
                                                name="data_registo"
                                                label="Data/hora do registo"
                                                component={DateTimePicker}
                                                format="dd/MM/yyyy HH:mm"
                                                disabled />
                                        </div>
                                : ''}

                                {canSeeDominioHidrico ? (
                                    <div>
                                        <Field
                                            name="tecnico_id"
                                            label="Técnico"
                                            component={Input}
                                            data={tecnico}
                                            disabled
                                        />
                                    </div>
                                ) : ''}
                            </div>    
                        </div>

                    </fieldset>
                ) : ''}


                {/* Fieldset Tipologia */}
                {canSeeDominioHidrico && (showDefault || showOpcao0 || showOpcao1 || showOpcao2 || showOpcao3 || showOpcao4 || showOpcao5 || showOpcao6 || showOpcao7) ? (
                    <fieldset>
                        <legend>
                            <span className={styles.fieldsetTitulo}>Tipologia</span>
                        </legend>

                        <div className={styles.espacoVerticalFieldSet}>
                            <div className={styles.fields}>
                                {( hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_BASICO) || canSeeDominioHidrico && (showDefault || showOpcao0) ) ? (
                                    <div>
                                        <Field
                                            name="id_tipo_processo"
                                            label="Tipo de processo"
                                            component={ComboBox}
                                            textField={'descricao'}
                                            filterable={true}
                                            onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'tipossProcesso')}}
                                            onChange={verificarID}
                                            data={combos?.tipossProcesso}
                                            disabled={viewMode}
                                        />
                                    </div>
                                ) : '' }
                            </div>

                            <div className={styles.fields}>
                                {canSeeDominioHidrico && showOpcao3 ? (
                                    <div>
                                        <Field
                                            name="id_tipo_aj"
                                            label="Tipo de ação judicial"
                                            component={ComboBox}
                                            textField={'descricao'}
                                            filterable={true}
                                            onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'tiposAcaoJudicial')}}
                                            data={combos?.tiposAcaoJudicial}
                                            disabled={viewMode}
                                        />
                                    </div>
                                ) : ''}

                                {canSeeDominioHidrico && (showOpcao1 || showOpcao2) ? (
                                    <div>
                                        <Field
                                            name="id_tipo_in"
                                            label="Tipo de iniciativa"
                                            component={ComboBox}
                                            textField={'descricao'}
                                            filterable={true}
                                            onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'tiposIniciativa')}}
                                            data={combos?.tiposIniciativa}
                                            disabled={viewMode}
                                        />
                                    </div>
                                ) : ''}

                                {(canSeeDominioHidrico || hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_BASICO)) && showOpcao5 ? (
                                    <div>
                                        <Field
                                            name="id_tipo_aq"
                                            label="Tipo de aquisição"
                                            component={ComboBox}
                                            textField={'descricao'}
                                            filterable={true}
                                            onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'tiposAquisicao')}}
                                            data={combos?.tiposAquisicao}
                                            disabled={viewMode}
                                        />
                                    </div>
                                ) : ''}
                            </div>
                        </div>
                    </fieldset>
                ) : ''}


                {/* Fieldset Identificação do processo */}
                {canSeeDominioHidrico && (showOpcao1 || showOpcao2 || showOpcao3 || showOpcao4 || showOpcao5 || showOpcao6 || showOpcao7) ? (
                    <fieldset>
                        <legend>
                            <span className={styles.fieldsetTitulo}>Identificação do processo</span>
                        </legend>

                        <div className={styles.espacoVerticalFieldSet}>
                            <div className={styles.fields}>
                                <div>
                                    <Field
                                        name="requerente"
                                        label="Requerente"
                                        component={Input}
                                        maxLength={50}
                                        disabled={viewMode}
                                    />
                                </div>
                            </div>

                            <div className={styles.fields}>
                                <div>
                                    <Field
                                        name="assunto"
                                        label="Assunto"
                                        component={Input}
                                        maxLength={100}
                                        disabled={viewMode}
                                    />
                                </div>
                            </div>

                            <div className={styles.fields}>
                                <div>
                                    <Field
                                        name="id_tipo_es"
                                        label="Estado do processo"
                                        component={ComboBox}
                                        textField={'descricao'}
                                        filterable={true}
                                        onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'estadosProcesso')}}
                                        data={combos?.estadosProcesso}
                                        disabled={viewMode}
                                    />
                                </div>

                                {canSeeDominioHidrico && (showOpcao1 || showOpcao2 || showOpcao6) ? (
                                    <div>
                                        <Field
                                            name="n_cdpm"
                                            label="Nº Proc CDPM"
                                            component={Input}
                                            maxLength={25}
                                            disabled={viewMode}
                                        />
                                    </div>
                                ) : ''}

                                {canSeeDominioHidrico && showOpcao3 ? (
                                    <>
                                        <div>
                                            <Field
                                                name="np_mp"
                                                label="Nº Proc Administrativo (MP)"
                                                component={Input}
                                                maxLength={25}
                                                disabled={viewMode}
                                            />
                                        </div>
                                        <div>
                                            <Field
                                                name="np_comum"
                                                label="Nº Proc Tribunal"
                                                component={Input}
                                                maxLength={25}
                                                disabled={viewMode}
                                            />
                                        </div>
                                    </>
                                ) : ''}

                                {canSeeDominioHidrico && showOpcao1 ? (
                                    <div>
                                        <Field
                                            name="np_ent_por"
                                            label="Nº Proc Ent. Portuária"
                                            component={Input}
                                            maxLength={25}
                                            disabled={viewMode}
                                        />
                                    </div>
                                ) : ''}

                                {canSeeDominioHidrico && showOpcao7 ? (
                                    <div>
                                        <Field
                                            name="num_doc"
                                            label="Nº de Registo SGD"
                                            component={Input}
                                            maxLength={25}
                                            disabled={viewMode}
                                        />
                                    </div>
                                ) : ''}
                            </div>

                            {canSeeDominioHidrico && (showOpcao1 || showOpcao2 || showOpcao4 || showOpcao5 || showOpcao6) ? (
                                <div className={styles.fields}>
                                    <div>
                                        <Field
                                            name="id_localizacao_processo"
                                            label="Localização do processo"
                                            component={ComboBox}
                                            textField={'descricao'}
                                            filterable={true}
                                            onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'localizacoesProcesso')}}
                                            data={combos?.localizacoesProcesso}
                                            disabled={viewMode}
                                        />
                                    </div>
                                </div>
                            ) : ''}

                            {canSeeDominioHidrico && (showOpcao1 || showOpcao2 || showOpcao4 || showOpcao5) ? (
                                <div className={styles.fields}>
                                    <div>
                                        <Field
                                            name="diarioRepublica"
                                            label="Diário da República"
                                            component={Input}
                                            maxLength={255}
                                            disabled={viewMode || comAuto}
                                        />
                                    </div>
                                </div>
                            ) : ''}

                            <div className={styles.fields}>
                                <div style={{ marginTop: 24 }}>
                                    <div className={styles.fieldsetSubTitulo}>Observações do estado do processo</div>
                                    <div className={styles.textAreaCustomizado}>
                                        <Field
                                            name="obs_tipo_e"
                                            label="Observações do estado do processo"
                                            component={TextArea}
                                            maxLength={528}
                                            style={{opacity: viewMode ? '0.7' : '1'}}
                                            onKeyPress={keyPressTextArea}
                                            className={styles.defaultTextArea}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                ) : ''}


                {/* Fieldset Localização */}
                {canSeeDominioHidrico && (showOpcao1 || showOpcao2 || showOpcao3 || showOpcao4 || showOpcao5 || showOpcao6 || showOpcao7) ? (
                    <fieldset>
                        <legend>
                            <span className={styles.fieldsetTitulo}>Localização</span>
                        </legend>

                        <div className={styles.espacoVerticalFieldSet}>
                            <div className={styles.fieldsetSubTitulo}>
                                {coordenadasSelected ?? ""}
                            </div>
                            <div className={styles.fields}>
                                <div>
                                    {getProcessoSelected() ?
                                        <CheckMoreItem
                                            list={getProcessoSelected()?.nuts1}
                                            property={'nome'}
                                            label="NUTS I"
                                        /> :
                                        <Field
                                            name="nuts1"
                                            label="NUTS I"
                                            component={ComboBox}
                                            textField={'nome'}
                                            data={nuts1}
                                            onChange={filterNuts2}
                                            disabled
                                        />
                                    }
                                </div>
                                <div>
                                    {getProcessoSelected() ?
                                        <CheckMoreItem
                                            list={getProcessoSelected()?.nuts2}
                                            property={'nome'}
                                            label="NUTS II"
                                        /> :
                                        <Field
                                            name="nuts2"
                                            label="NUTS II"
                                            component={ComboBox}
                                            textField={'nome'}
                                            data={nuts2Filtered}
                                            onChange={filterNuts3}
                                            disabled
                                        />}
                                </div>
                                <div>
                                    {getProcessoSelected() ?
                                        <CheckMoreItem
                                            list={getProcessoSelected()?.nuts3}
                                            property={'nome'}
                                            label="NUTS III"
                                        /> :
                                        <Field
                                            name="nuts3"
                                            label="NUTS III"
                                            component={ComboBox}
                                            textField={'nome'}
                                            data={nuts3Filtered}
                                            disabled
                                        />}
                                </div>
                            </div>
                            <div className={styles.fields}>

                                {showOpcao1 || showOpcao2 || showOpcao3 || showOpcao4 || showOpcao5 || showOpcao6 || showOpcao7 ? (
                                <div>
                                    {getProcessoSelected() ?
                                        <CheckMoreItem
                                            list={getProcessoSelected()?.distritos}
                                            property={'nome'}
                                            label="Distrito"
                                        /> :
                                        <Field
                                            name="distrito"
                                            label="Distrito"
                                            component={ComboBox}
                                            onChange={filterConcelho}
                                            textField={'nome'}
                                            data={distritos}
                                            disabled
                                        />}
                                </div>
                                ) : ''}

                                {showOpcao1 || showOpcao2 || showOpcao3 || showOpcao4 || showOpcao5 || showOpcao6 || showOpcao7 ? (
                                <div>
                                    {getProcessoSelected() ?
                                        <CheckMoreItem
                                            list={getProcessoSelected()?.concelhos}
                                            property={'nome'}
                                            label="Concelho"
                                        /> :
                                        <Field
                                            name="concelho"
                                            label="Concelho"
                                            component={ComboBox}
                                            onChange={filterFreguesia}
                                            disabled
                                            textField={'nome'}
                                            data={concelhosFiltred}
                                        />}
                                </div>
                                ) : ''}

                                {showOpcao1 || showOpcao2 || showOpcao3 || showOpcao4 || showOpcao5 || showOpcao6 || showOpcao7 ? (
                                <div>
                                    {getProcessoSelected() ?
                                        <CheckMoreItem
                                            list={getProcessoSelected()?.freguesias}
                                            property={'nome'}
                                            label="Freguesia"
                                        /> :
                                        <Field
                                            name="freguesia"
                                            label="Freguesia"
                                            component={ComboBox}
                                            disabled
                                            textField={'nome'}
                                            data={freguesiasFiltred}
                                        />}
                                </div>
                                ) : ''}

                            </div>
                            <div className={styles.fields}>
                                <div>
                                    {getProcessoSelected() ?
                                        <CheckMoreItem
                                            list={getProcessoSelected()?.arhs}
                                            property={'nome'}
                                            label="ARH"
                                        /> :
                                        <Field
                                            name="arh"
                                            label="ARH"
                                            component={ComboBox}
                                            textField={'nome'}
                                            data={arhs}
                                            disabled
                                        />}
                                </div>
                                <div>
                                    {getProcessoSelected() ?
                                        <CheckMoreItem
                                            list={getProcessoSelected()?.capitanias}
                                            property={'nome'}
                                            label="Capitania"
                                        /> :
                                        <Field
                                            name="capitania"
                                            label="Capitania"
                                            component={ComboBox}
                                            textField={'nome'}
                                            data={capitania}
                                            disabled
                                        />}
                                </div>

                                {showOpcao1 || showOpcao2 || showOpcao3 || showOpcao4 || showOpcao5 || showOpcao6 || showOpcao7 ? (
                                <div>
                                    {getProcessoSelected() ?
                                        <CheckMoreItem
                                            list={getProcessoSelected()?.massasAgua}
                                            property={'nome'}
                                            label="Massa de água"
                                        /> :
                                        <Field
                                            name="massaagua"
                                            label="Massa de água"
                                            component={ComboBox}
                                            textField={'nome'}
                                            data={massaagua}
                                            disabled
                                        />}
                                </div>
                                ) : ''}

                            </div>
                            <div className={styles.fields}>

                                {showOpcao1 || showOpcao2 || showOpcao3 || showOpcao4 || showOpcao5 || showOpcao6 || showOpcao7 ? (
                                <div>
                                    <Field
                                        name="lugar"
                                        label="Lugar toponímico"
                                        component={Input}
                                        maxLength={100}
                                        disabled = {viewMode}
                                        />
                                </div>
                                ) : ''}
                                
                            </div>
                        </div>
                    </fieldset>
                ) : ''}


                {/* Fieldset Fase do Procedimento */}
                {canSeeDominioHidrico && (showOpcao1 || showOpcao2) ? (
                    <fieldset>
                        <legend>
                            <span className={styles.fieldsetTitulo}>Fase do Procedimento</span>
                        </legend>

                        <div className={styles.espacoVerticalFieldSet}>
                            <div className={styles.fields}>
                                <div>
                                    <Field
                                        name="id_fase_pr"
                                        label="Fase de procedimento"
                                        component={ComboBox}
                                        textField={'descricao'}
                                        filterable={true}
                                        onFilterChange={(e: any) => { filterCombo_Multiselect(e, 'fasesProcedimentoDelimitacao') } }
                                        data={combos?.fasesProcedimentoDelimitacao}
                                        disabled={viewMode} />
                                </div>
                                <div>
                                    <Field
                                        name="representante"
                                        label="Representante na comissão delimitação"
                                        component={Input}
                                        maxLength={50}
                                        disabled={viewMode} />
                                </div>
                            </div>

                            <div style={{ marginTop: 24 }}>
                                <div className={styles.fieldsetSubTitulo}>Observações fase procedimento</div>
                                <div className={styles.textAreaCustomizado}>
                                    <Field
                                        name="obs_fase_p"
                                        label="Observações fase procedimento"
                                        component={TextArea}
                                        maxLength={528}
                                        style={{opacity: viewMode ? '0.7' : '1'}}
                                        onKeyPress={keyPressTextArea}
                                        className={styles.defaultTextArea}
                                    />
                                </div>
                            </div>
                        </div>
                    </fieldset>
                ) : ''}


                {/* Fieldset Reconhecimento de Propriedade Privada */}
                {canSeeDominioHidrico && (showOpcao1 || showOpcao2 || showOpcao6) ? (
                    <fieldset>
                        <legend>
                            <span className={styles.fieldsetTitulo}>Reconhecimento de propriedade privada</span>
                        </legend>

                        <div className={styles.espacoVerticalFieldSet}>
                            <div className={styles.fields}>
                                {canSeeDominioHidrico && (showOpcao1 || showOpcao6) ? (
                                    <div>
                                        <Field
                                            name="rpp_antes_DL" // TD9 - dh_reconhecimento_propriedade_privada
                                            label="Reconhecimento de propriedade privada"
                                            component={ComboBox}
                                            textField={'descricao'}
                                            filterable={true}
                                            onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'dhRppAntes')}}
                                            data={combos?.dhRppAntes}
                                            disabled={viewMode}
                                        />
                                    </div>
                                ) : ''}

                                {canSeeDominioHidrico && showOpcao2 ? (
                                    <div>
                                        <Field
                                            name="rpp_depois_DL" // TD21 - dh_tipo_acao_reconhecimento
                                            label="Reconhecimento de propriedade privada"
                                            component={ComboBox}
                                            textField={'descricao'}
                                            filterable={true}
                                            onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'dhRppDepois')}}
                                            data={combos?.dhRppDepois}
                                            disabled={viewMode}
                                        />
                                    </div>
                                ) : ''}
                            </div>

                            <div className={styles.fields}>
                                <div style={{ marginTop: 24 }}>
                                    <div className={styles.fieldsetSubTitulo}>Observações reconhecimento propriedade privada</div>
                                    <div className={styles.textAreaCustomizado}>
                                        <Field
                                            name="obs_rpp"
                                            label="Observações reconhecimento propriedade privada"
                                            component={TextArea}
                                            maxLength={528}
                                            style={{opacity: viewMode ? '0.7' : '1'}}
                                            onKeyPress={keyPressTextArea}
                                            className={styles.defaultTextArea}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                ) : ''}
       

                {/* Fieldset Outros critérios */}
                {canSeeDominioHidrico && showOpcao6 ? (
                    <fieldset>
                        <legend>
                            <span className={styles.fieldsetTitulo}>Outros critérios</span>
                        </legend>

                        <div className={styles.espacoVerticalFieldSet}>
                            <div className={styles.fields}>
                                <div>
                                    <Field
                                        name="comDeferimento"
                                        label="Deferimento de licenciamento"
                                        component={ComboBox}
                                        textField={'descricao'}
                                        filterable={true}
                                        onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'dhDeferimento')}}
                                        data={combos?.dhDeferimento}
                                        disabled={viewMode}
                                    />
                                </div>
                            </div>
                        </div>
                    </fieldset>
                ) : ''}


                {/* Fieldset Pareceres CDPM */}
                {canSeeDominioHidrico && (showOpcao1 || showOpcao6) ? (
                    <fieldset>
                        <legend>
                            <span className={styles.fieldsetTitulo}>Pareceres CDPM</span>
                        </legend>
                        <div className={styles.fields}>
                                        <button
                                            title="Adicionar parecer"
                                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary buttonAdicionarNovo btnPrimaryColor"
                                            onClick={(e) => {
                                                openModalParecer(e);
                                            }}
                                            disabled={viewMode}
                                            >
                                            <span className="icon-siarl icon-registar k-button-icon"></span>{' '}
                                            &nbsp;{' '}
                                            Inserir
                                        </button>
                                        </div>
                        <div className={styles.fields}>
                                <div className={styles.scrollableArea}>
                                 <Grid
                                        className={'customGrid'}
                                        data={gridDataParecer.slice(page2.skip, page2.take + page2.skip)}
                                        pageable={{
                                        buttonCount: 5,
                                        info: false,  
                                        type: 'numeric',
                                        pageSizes: false,  
                                        previousNext: true,
                                        }}
                                        rowHeight={50}
                                        total={gridDataParecer.length}
                                        skip={page2.skip}
                                        take={page2.take}
                                        //onPageChange={handlePageChange2}


                                    >
                                        <GridColumn width={56} title='Ações'
                                            cell={(props: any) => (
                                            <td className="k-command-cell">
                                                <div style={{cursor:'pointer'}} className={'gridCommand'}>
                                                <div
                                                    role="button"
                                                    className={styles.gridCommandIconButton}
                                                    onClick={() => handleEditParecerItem(props)}
                                                >
                                                    <FontAwesomeIcon icon={faInfoCircle} title="Editar contacto" />
                                                </div>
                                                <div style={{cursor:'pointer'}}
                                                    role="button"
                                                    onClick={() => {
                                                        setDataItemToRemoveParecer(props);
                                                        setOpenModalDescartarParecer(true);
                                                    } }
                                                    className={styles.gridCommandIconButton}
                                                >
                                                    <FontAwesomeIcon icon={faRemove} title="Remover contacto" />
                                                </div>
                                                </div>
                                            </td>
                                            )}
                                            />
                                    <GridColumn field='npar_cdpm' className={styles.centerAlign}title='Numero de Parecer CDPM' />
                                    <GridColumn field='data_par_cdpm_formated' className={styles.centerAlign} title='Data de Parecer CDPM' />
                                    <GridColumn field='data_hom_par_cdpm_formated' className={styles.centerAlign} title='Data de Hom. Parecer CDPM' />                                    
                                    </Grid>
                                </div>
                            </div>

                        <div className={styles.espacoVerticalFieldSet}>
                            <div className={styles.fields}>
                            </div>
                        </div>
                    </fieldset>
                ) : ''}


                {/* Fieldset Auto de delimitação */}
                {canSeeDominioHidrico && (showOpcao1 || showOpcao2) ? (
                    <fieldset>
                        {canSeeDominioHidrico && (showOpcao1 || showOpcao2) ? (
                            <>
                                <legend>
                                    <span className={styles.fieldsetTitulo}>Auto de delimitação</span>
                                </legend>
                                <div className={styles.espacoVerticalFieldSet}>
                                    <div className={styles.fields}>
                                        <div>
                                            <Field
                                                name="comAuto"
                                                label="Com auto de delimitação"
                                                component={Checkbox}
                                                checked={comAuto}
                                                onChange={handleComAutoCheckbox}
                                                disabled={viewMode}
                                            />
                                        </div>

                                       
                                        <div className={styles.fields} style={{justifyContent: 'center'}}>
                                          <button
                                              title="Adicionar nova poligonal"
                                              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary buttonAdicionarNovo btnPrimaryColor"
                                              onClick={(e) => {
                                                 setIsEditingModal(false);
                                                  openModal(e);
                                              }}
                                              disabled={viewMode}
                                              >
                                              <span className="icon-siarl icon-registar k-button-icon"></span>{' '}
                                              &nbsp;{' '}
                                              Inserir
                                          </button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : ''}

                        {canSeeDominioHidrico && (showOpcao4 || showOpcao5) ? (
                            <legend>
                                <span className={styles.fieldsetTitulo}>Diploma publicado</span>
                            </legend>
                        ) : ''}

                        
                        <div className={styles.espacoVerticalFieldSet}>
                            <div className={styles.fields}>
                                <div className={styles.scrollableArea}>
                                 <Grid
                                        className={'customGrid'}
                                        data={gridData.slice(page2.skip, page2.take + page2.skip)}
                                        pageable={{
                                        buttonCount: 5,
                                        info: false,  
                                        type: 'numeric',
                                        pageSizes: false,  
                                        previousNext: true,
                                        }}
                                        rowHeight={50}
                                        total={gridData.length}
                                        skip={page2.skip}
                                        take={page2.take}
                                        //onPageChange={handlePageChange2}
                                        onRowClick={handleRowClick}

                                    >
                                        <GridColumn width={56} title='Ações'
                                            cell={(props: any) => (
                                            <td className="k-command-cell">
                                                <div style={{cursor:'pointer'}} className={'gridCommand'}>
                                                {!viewMode ? 
                                                <div 
                                                    role="button"
                                                    className={styles.gridCommandIconButton}
                                                    onClick={() => handleRowClick(props)}
                                                >
                                                    <FontAwesomeIcon icon={faInfoCircle} title="Editar contacto" />
                                                </div>: ''}
                                                {!viewMode ?
                                                <div style={{cursor:'pointer'}}
                                                    role="button"
                                                    onClick={() => 
                                                        {
                                                            setDataItemToRemove(props);
                                                            setOpenModalDescartar(true);
                                                        }
                                                    }
                                                    className={styles.gridCommandIconButton}
                                                >
                                                    <FontAwesomeIcon icon={faRemove} title="Remover contacto" />
                                                </div> : ''}
                                                </div>
                                            </td>
                                            )}
                                            />
                                            <GridColumn width={150}  field={'e.tipo_DominioAuto.descricao'} title={'Tp. Dominio'} />,
                                            {
                                            fields.map((column, i) => {
                                                return [
                                                    <GridColumn width={150} className={styles.centerAlign} key={`indice-${i}`} field={`e.indice${i}`} title={`Indice ${(i + 1)}`} />,
                                                    <GridColumn width={150} className={styles.centerAlign} key={`longitude-${i}`} field={`e.longitude${i}`} title={`X ${(i + 1)}`} />,
                                                    <GridColumn width={150} className={styles.centerAlign} key={`latitude-${i}`} field={`e.latitude${i}`} title={`Y ${(i + 1)}`} />,
                                                    <GridColumn width={150} className={styles.centerAlign} key={`longitude_corrigida-${i}`} field={`e.longitudeCorrigida${i}`} title={`X Corrigido ${(i + 1)}`} />,
                                                    <GridColumn width={150} className={styles.centerAlign} key={`latitude_corrigida-${i}`} field={`e.latitudeCorrigida${i}`} title={`Y Corrigido ${(i + 1)}`} />      
                                                ];
                                            })}
                                    {/* <GridColumn field='Latitude' title='Latitude' />
                                    <GridColumn field='Longitude' title='Longitude' />
                                    <GridColumn field='Latitude_Corrigida' title='Latitude Corrigida' />
                                    <GridColumn field='Longitude_Corrigida' title='Longitude Corrigida' />
                                    <GridColumn field='indice' title='Indice' /> */}
                                    
                                    </Grid>
                                </div>
                            </div>
                        </div>
                        

                        <div className={styles.espacoVerticalFieldSet}>
                            {canSeeDominioHidrico && (showOpcao1 || showOpcao2) ? (
                                <>
                                    <div className={styles.fields}>
                                        <div style={{ marginTop: 24 }}>
                                            <div className={styles.fieldsetSubTitulo}>Observações ao auto de delimitação</div>
                                            <div className={styles.textAreaCustomizado}>
                                                <Field
                                                    name="obs_ad"
                                                    label="Observações ao auto de delimitação"
                                                    component={TextArea}
                                                    maxLength={528}
                                                    style={{opacity: viewMode ? '0.7' : '1'}}
                                                    onKeyPress={keyPressTextArea}
                                                    className={styles.defaultTextArea}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {canSeeDominioHidrico && showOpcao1 ? (
                                        <>
                                            <legend>
                                                <span className={styles.fieldsetTitulo}>Condicionamentos</span>
                                            </legend>

                                            <div className={styles.fields}>
                                                <Field
                                                    name="id_cond_pr"
                                                    label="Condicionantes ao procedimento de delimitação"
                                                    component={ComboBox}
                                                    textField={'descricao'}
                                                    filterable={true}
                                                    onFilterChange={(e: any) => { filterCombo_Multiselect(e, 'condicionantesProcessos') } }
                                                    data={combos?.condicionantesProcessos}
                                                    disabled={viewMode || comAuto} />
                                            </div>

                                            <div className={styles.fields}>
                                                <div style={{ marginTop: 24 }}>
                                                    <div className={styles.fieldsetSubTitulo}>Observações às condicionantes ao procedimento de delimitação</div>
                                                    <div className={styles.textAreaCustomizado}>
                                                        <Field
                                                            name="obs_condicionantes"
                                                            label="Observações às condicionantes ao procedimento de delimitação"
                                                            component={TextArea}
                                                            maxLength={528}
                                                            style={{ opacity: viewMode ? '0.7' : '1' }}
                                                            onKeyPress={keyPressTextArea}
                                                            className={styles.defaultTextArea} />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : ''}
                                </>
                            ) : ''}
                        </div>
                    </fieldset>
                ) : ''}
                

                {/* Fieldset Decisão */}
                {canSeeDominioHidrico && showOpcao3 ? (
                    <fieldset>
                        <legend>
                            <span className={styles.fieldsetTitulo}>Decisão</span>
                        </legend>
                            
                        <div className={styles.espacoVerticalFieldSet}>
                            <div className={styles.fields}>
                                <div>
                                    <Field
                                        name="dec_final"
                                        label="Decisão final"
                                        component={ComboBox}
                                        textField={'descricao'}
                                        filterable={true}
                                        onFilterChange={(e: any) => { filterCombo_Multiselect(e, 'tiposDecisaoFinal') } }
                                        data={combos?.tiposDecisaoFinal}
                                        disabled={viewMode} />
                                </div>
                                <div>
                                    <Field
                                        name="id_tipo_se"
                                        label="Sentença"
                                        component={ComboBox}
                                        textField={'descricao'}
                                        filterable={true}
                                        onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'tiposSentenca')}}
                                        data={combos?.tiposSentenca}
                                        disabled={viewMode}
                                    />
                                </div>
                            </div>

                            <div className={styles.fields}>
                                <div style={{ marginTop: 24 }}>
                                    <div className={styles.fieldsetSubTitulo}>Observações da sentença</div>
                                    <div className={styles.textAreaCustomizado}>
                                        <Field
                                            name="obs_senten"
                                            label="Observações da sentença"
                                            component={TextArea}
                                            maxLength={528}
                                            style={{opacity: viewMode ? '0.7' : '1'}}
                                            onKeyPress={keyPressTextArea}
                                            className={styles.defaultTextArea}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className={styles.fields}>
                                <div>
                                    <Field
                                      name="id_tipo_ac"
                                      label="Recurso"
                                      component={ComboBox}
                                      textField={'descricao'}
                                      filterable={true}
                                      onFilterChange={(e: any) => { filterCombo_Multiselect(e, 'dhRecurso') } }
                                      data={combos?.dhRecurso}
                                      disabled={viewMode}
                                    />
                                </div>
                            </div>

                            <div className={styles.fields}>
                                <div style={{ marginTop: 24 }}>
                                    <div className={styles.fieldsetSubTitulo}>Observações recurso</div>
                                    <div className={styles.textAreaCustomizado}>
                                        <Field
                                            name="obs_acorda"
                                            label="Observações recurso"
                                            component={TextArea}
                                            maxLength={528}
                                            style={{opacity: viewMode ? '0.7' : '1'}}
                                            onKeyPress={keyPressTextArea}
                                            className={styles.defaultTextArea}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </fieldset>
                ) : ''}
            </div>

            {openModalAutoDelimitacao ? (
                <Dialog
                  title="Auto Delimitação - Referência Poligonal"
                  className="ModalInstumentoPlaneamento"
                  onClose={cancelModal}
                >
                  
                  <Form
                    onSubmit={handleSubmitModal}
                    initialValues={{...formData,
                        tipo_DominioAuto: dhTipoDominio.find(x => x.id == ID_DOMINIO_PUBLICO_HIDRICO_DEFAULT),
                    }}
                    render={(formRenderProps) => (
                        <FormElement style={{ maxWidth: 620 }}>
                            <div className='form removeScrollDuplicado'>
                            <div>
                                            <Field
                                                name="tipo_DominioAuto"
                                                label="Tipo Dominio"
                                                component={ComboBox}
                                                textField={'descricao'}
                                                filterable={true}
                                                onFilterChange={(e: any) => { filterCombo_Multiselect(e, 'dhTipoDominio') } }
                                                data={combos?.dhTipoDominio}
                                                disabled={viewMode || comAuto} />
                                        </div>
                                {fieldsModal.map((field, index) => (
                                    <>
                                        
                                    <fieldset>

                                            <legend>
                                                <span className='fieldsetTitulo'>Auto delimitação </span>
                                            </legend>
                                            <div style={{marginTop: '-10px', marginLeft:'5px'}}><span>ETRS89 / Portugal TM06 (EPSG:3763)</span></div>
                                            <div className='espacoVerticalFieldSet'>
                                                <div>
                                                    <div className='fields'>
                                                        <div>
                                                            <Field
                                                                component={Input}
                                                                name={"id" + index}
                                                                label="id"
                                                                style={{ display: 'none' }} />
                                                            <Field
                                                                component={Input}
                                                                name={"longitude" + index}
                                                                label="X"
                                                                value={field.longitude}
                                                                type={'number'} />
                                                        </div>
                                                        <div>
                                                            
                                                                <Field
                                                                component={Input}
                                                                name={"latitude" + index}
                                                                label="Y"
                                                                type={'number'}
                                                                value={field.latitude} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='fields'>
                                                        
                                                        <div>
                                                            <Field
                                                                component={Input}
                                                                key={index}
                                                                name={"longitudeCorrigida" + index}
                                                                label="X Corrigido"
                                                                value={field.longitudeCorrigida}
                                                                type={'number'} />
                                                        </div>
                                                        <div>
                                                            <Field
                                                                component={Input}
                                                                key={index}
                                                                name={"latitudeCorrigida" + index}
                                                                label="Y Corrigido"
                                                                value={field.latitudeCorrigida}
                                                                type={'number'} />
                                                        </div>
                                                    </div>
                                                    <div className='fields'>
                                                        <Field
                                                            component={Input}
                                                            name={"indice" + index}
                                                            label="Indice"
                                                            type={'number'}
                                                            value={field.latitude}
                                                            validator={requiredFieldValidator} />
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset></>
                                    
                                ))}  
                            </div>       
                            <div className="k-form-buttons">
                            <button
                                type={'submit'}
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                onClick={formRenderProps.onSubmit}
                            >
                                Submeter
                            </button>
                            <button
                                type={'submit'}
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                onClick={cancelModal}
                            >
                                Cancelar
                            </button>
                            <button
                            type={'submit'}
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={handleDeleteField}
                            >
                                Remover
                            </button>
                            <button
                            type={'submit'}
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={handleAddField}
                            >
                                Adicionar
                            </button>
                            </div>
                        </FormElement>
                    )}
                  />
                </Dialog>
            ) : ( "" )}



            {openModalEdicaoAutoDelimitacao ? (
                <Dialog
                  title="Auto Delimitação - Referência Poligonal"
                  className="ModalInstumentoPlaneamento"
                  onClose={cancelModalEdicao}
                >
                  
                  <Form
                    onSubmit={handleSubmitModalEdicao}
                    initialValues={formData}
                    render={(formRenderProps) => (
                        <FormElement style={{ maxWidth: 620 }}>
                            <div className='form removeScrollDuplicado'>
                                <fieldset>
                                    <legend>
                                        <span className='fieldsetTitulo'>Auto delimitação</span>
                                    </legend>
                                    <div className='espacoVerticalFieldSet'>
                                        <div className='fields'>
                                            <div>
                                                <Field
                                                    component={Input}
                                                    name={"id"}
                                                    label="id"
                                                    style={{display: 'none'}}
                                                />
                                                <Field
                                                    component={Input}
                                                    name={"latitude"}
                                                    label="Latitude"
                                                    type={'number'}
                                                />
                                            </div>
                                            <div>
                                                <Field
                                                    component={Input}
                                                    name={"longitude"}
                                                    label="Longitude"
                                                    type={'number'}
                                                />
                                            </div>
                                        </div>
                                        <div className='fields'>
                                            <div>
                                                <Field
                                                    component={Input}
                                                    name={"latitudeCorrigida"}
                                                    label="Latitude Corrigida"
                                                    type={'number'}
                                                />
                                            </div>
                                            <div>
                                                <Field
                                                    component={Input}
                                                    name={"longitudeCorrigida"}
                                                    label="Longitude Corrigida"
                                                    type={'number'}
                                                />
                                            </div>
                                        </div>
                                        <div className='fields'>
                                            <Field
                                                component={Input}
                                                name={"indice"}
                                                label="Indice"
                                                type={'number'}
                                                validator={requiredFieldValidator}
                                            />
                                        </div>
                                    </div>
                                </fieldset>
                                
                                
                                
                            </div>       
                            <div className="k-form-buttons">
                            <button
                                type={'submit'}
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                onClick={formRenderProps.onSubmit}
                            >
                                Submeter
                            </button>
                            <button
                                type={'submit'}
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                onClick={cancelModalEdicao}
                            >
                                Cancelar
                            </button>
                            <button
                            type={'submit'}
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={handleAddField}
                            >
                                Adicionar
                            </button>
                            </div>
                        </FormElement>
                    )}
                  />
                </Dialog>
            ) : ( "" )} 


{openModalDhParecer ? (
                <Dialog
                  title="Auto Delimitação - Dh Parecer"
                  className="ModalDhParecer"
                  onClose={cancelModalParecer}
                >
                  
                  <Form
                    onSubmit={handleSubmitModalParecer}
                    initialValues={formDataParecer}
                    render={(formRenderProps) => (
                        <FormElement style={{ maxWidth: 620 }}>
                            <div className='form removeScrollDuplicado'>
                                <fieldset>
                                    <legend>
                                        <span className='fieldsetTitulo'>Parecer</span>
                                    </legend>
                                    <div className='espacoVerticalFieldSet'>
                                    
                                    <div className='fields'>
                                            <div>
                                                <Field
                                                    component={Input}
                                                    name={"id_dh_proc"}
                                                    style={{display:'none'}}
                                                />
                                            </div>
                                        </div>
                                        <div className='fields'>
                                            <div>
                                                <Field
                                                    component={Input}
                                                    name={"npar_cdpm"}
                                                    label="Numero Parecer CDPM"
                                                />
                                            </div>
                                        </div>
                                        <div className='fields'>
                                            <div>
                                                <Field
                                                    component={DatePicker}
                                                    name={"data_par_cdpm"}
                                                    label="Data do Parecer CDPM"
                                                    format="dd/MM/yyyy"
                                                    calendar={CustomCalendar}
                                                />
                                            </div>
                                            <div>
                                                <Field
                                                    component={DatePicker}
                                                    name={"data_hom_par_cdpm"}
                                                    label="Data de homologação do parecer CDPM"
                                                    format="dd/MM/yyyy"
                                                    calendar={CustomCalendar}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                
                            </div>       
                            <div className="k-form-buttons">
                            <button
                                type={'submit'}
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                onClick={formRenderProps.onSubmit}
                            >
                                Adicionar
                            </button>
                            <button
                                type={'submit'}
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                onClick={cancelModalParecer}
                            >
                                Cancelar
                            </button>
                            </div>
                        </FormElement>
                    )}
                  />
                </Dialog>
            ) : ( "" )} 
{openModalDescartar ? (
    <Dialog
      
      className={styles.dialogDesconformidades}
      onClose={() => setOpenModalDescartar(false)}
    >
      
      <Form
        initialValues={
          {
          } 
        }
        render={(formRenderProps) => (
          <FormElement style={{ maxWidth: 500 }}>
            <fieldset>
              <span className="textDescartar">{'Apagar'} registo  </span>
              <br></br>
              <span>{'Tem a certeza que pretende apagar o a associação?'}</span>
            </fieldset>
            <div className="k-form-buttons btnModal">         
              <button
                type={'submit'}
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                onClick={() => setOpenModalDescartar(false)}
              >
                Não
              </button>

              <button
                type={'submit'}
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                onClick={handleRemoveItem}
              >
                Sim
              </button>
            </div>
          </FormElement>
        )}
      />
    </Dialog>
  ) : (
    <></>
  )}

{openModalDescartarParecer ? (
    <Dialog
      
      className={styles.dialogDesconformidades}
      onClose={() => setOpenModalDescartarParecer(false)}
    >
      
      <Form
        initialValues={
          {
          } 
        }
        render={(formRenderProps) => (
          <FormElement style={{ maxWidth: 500 }}>
            <fieldset>
              <span className="textDescartar">{'Apagar'} registo  </span>
              <br></br>
              <span>{'Tem a certeza que pretende apagar o a associação?'}</span>
            </fieldset>
            <div className="k-form-buttons btnModal">         
              <button
                type={'submit'}
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                onClick={() => setOpenModalDescartarParecer(false)}
              >
                Não
              </button>

              <button
                type={'submit'}
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                onClick={handleRemoveItemParecer}
              >
                Sim
              </button>
            </div>
          </FormElement>
        )}
      />
    </Dialog>
  ) : (
    <></>
  )}

</>
    )
}

