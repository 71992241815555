import { Button, DropDownButton } from '@progress/kendo-react-buttons'
import { Form, FormElement } from '@progress/kendo-react-form'
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from '@progress/kendo-react-layout'
import { useEffect, useLayoutEffect, useState } from 'react'

import { MouseEvent as ReactMouseEvent } from 'react'
import {
  b64toBlob,
  clearPatrimonio,
  coordenadasPoligono,
  del,
  get,
  getPatrimonioSelected,
  getProcessoSelected,
  handleGenericError,
  hasPermission,
  post,
  put,
  setCoordenadasPoligono,
  setCoordenadasSelected,
  setDemarcacaoSelected,
  setPatrimonioSelected,
  setProcessoSelected,
  tecnico,
  useCombos,
  userLogged
} from '../../services/utils'
import { usePatrimonioStore } from '../../stores/patrimonio'
import styles from '../../styles/dominiohidrico/DominioHidrico.module.scss'
import {
  AUTH_LOCALSTORAGE,
  DELETE_DH_PATRIMONIO,
  DH_PATRIMONIO_POINT_NAME,
  GET_PATRIMONIO,
  GET_URL_FROM_FILE_DOC,
  IMPORT_GEOM_DH_PATRIMONIO,
  PATRIMONIO_MODULE_NAME,
  PERMISSION_ATUALIZAR_PATRIMONIO,
  PERMISSION_INSERIR_PATRIMONIO,
  RELATORIO_PATRIMONIO,
  REMOVER_AREA_DH_PATRIMONIO,
  SAVE_NEW_PATRIMONIO,
  UPDATE_PATRIMONIO,
  URL_AJUDA_DOMINIO_HIDRICO_PATRIMONIO,
  clearLayers,
} from '../../utils/constants'
import { AssociacaoListagem } from '../associacao/Associacao'
import { DocumentoListagem } from '../documentos/registar/documento/documento'
import IdentificacaoPatrimonio from './IdentificacaoPatrimonio'
import ImportarGeometriaComponent from '../apoiogestao/registar/ImportarGeometriaForm'
import { Dialog } from '@progress/kendo-react-dialogs'
import SessionStorageService from '../../services/SessionStorageService'
import useClearMap from '../../hooks/useClearMap'
import html2canvas from 'html2canvas'
import ReactGA from 'react-ga4';
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais'
type Props = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
}

type PatrimonioForm = {
  id: number
  designacao: string
  lugar: string
  diploma: string
  id_tipo_pr: number
  obs: string
  tecnico_id: number,
  data_registo: unknown
  geom: unknown
  flatCoordinates: string
  geom_imported?: string
  filedoc_cod: string
}

const TOTAL_TABS = 3

export default function RegistarPatrimonioForm({ onCloseClick }: Props) {
  const [isMobile, setIsMobile] = useState(false);
  const [selected, setSelected] = useState<number>(0)
  const [openImportModal, setOpenImportModal] = useState<any>()

  const selectedPatrimonioResult: any = usePatrimonioStore((state) => state.selectedPatrimonioResult);
  const selectPatrimonioResultId: any = usePatrimonioStore((state) => state.selectPatrimonioResultId);
  const selectPatrimonioResult = usePatrimonioStore((state) => state.selectPatrimonioResult)
  const [openRemoveAreaModal, setOpenRemoveAreaModal] = useState<any>()


  const savePatrimonio = async (data: any) => await post<any, any>(`${SAVE_NEW_PATRIMONIO}`, data)
  const updatePatrimonio = async (data: any) => await put<any, any>(`${UPDATE_PATRIMONIO}`, data)
  const [viewMode, setViewMode] = useState(false);

  const [entityId, setPatrimonioId] = useState<any>()

  const getPatrimonio = async (data: any) => await get<any, any>(`${GET_PATRIMONIO}/` + data)
  const getUrlFileDoc = async (data: any) => await get<any>(`${GET_URL_FROM_FILE_DOC}/` + encodeURIComponent(data));

  const removerAreaPatrimonio = async (data: any) =>
    await post<any, any>(`${REMOVER_AREA_DH_PATRIMONIO}`, data)

  const relatorioPatrimonio = async (data: any) =>
    await post<any, any>(`${RELATORIO_PATRIMONIO}`, data)

  useEffect(() => {
    const patrimonioSelected = getPatrimonioSelected()

    console.log('ID PATRIMONIO : ' + patrimonioSelected?.id)
  }, [])

    useEffect(() => {
    setViewMode(!editMode && sessionStorage.getItem("novoPatrimonio") != 'true');
  }, [])


  useEffect(() => {
    if (sessionStorage.getItem('patrimonioId')) {
      setPatrimonioId(JSON.parse(sessionStorage.getItem('patrimonioId') ?? ''))
    }
  }, [])

  const handleSelect = (e: TabStripSelectEventArguments) => {
    if (e.selected > 0 && !selectedPatrimonioResult && !getPatrimonioSelected()) {
      postMessage({type:"infoMsg", value: {message: "É necessário gravar primeiro."}})
      return;
    }
    setSelected(e.selected)  }

    const selectMapAction =  (item: any) => {
      const iframe: any = document.getElementById('iframeGeoPortal')
  
      if (item.key == "removerArea") {
        setOpenRemoveAreaModal(true)
      } else {
        iframe.contentWindow?.postMessage({ type: item.key, value: getPatrimonioSelected()?.id, editGpLayer: DH_PATRIMONIO_POINT_NAME }, '*')
      }
    }

  
  const removeArea = async () => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    const patrimonioSelected = getPatrimonioSelected();
    iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: 'true' }, '*')
    setCoordenadasPoligono(undefined);
    if (!patrimonioSelected?.id) {
      setOpenRemoveAreaModal(false)
      return;
    }
    await removerAreaPatrimonio(patrimonioSelected?.id);
    
    iframe.contentWindow?.postMessage(
      { type: 'SiarlPatrimonioSearch', value: [patrimonioSelected?.id] },
      '*'
    )
    refreshForm(patrimonioSelected?.id)
    setOpenRemoveAreaModal(false)

  }

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/RegistarPatrimonio" });
    // ReactGA.pageview(window.location.pathname + "RegistarPatrimonio");
  }, []);

  const voltar = () => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
      {
        type: 'SiarlCoordenadaBoxClose',
        value: 'true',
      },
      '*'
    )
    clearPatrimonioMap();
    postMessage({ type: 'form', value: 'pesquisarPatrimonio' }, '*');
   
  }

  const [clearMap] = useClearMap();
  const clearPatrimonioMap = () => {
    clearMap(clearLayers["CLEAR_LAYERS_PATRIMONIO"]);
    setPatrimonioSelected(undefined)
    selectPatrimonioResult(undefined)
  }

  const [openModalDescartar, setOpenModalDescartar] = useState<any>()

  const cancelModalDescartar = () => {
    setOpenModalDescartar(false)
  }

  const refreshForm = async(id: any = undefined) => {
  let iPatrimonio = id;
  if (!iPatrimonio) {
    if (selectedPatrimonioResult?.id) {
      iPatrimonio = selectedPatrimonioResult?.id;
    } else {
      return;
    }
  }
  const patrimonio = await getPatrimonio(iPatrimonio);

  if (!patrimonio) {
    postMessage({type: "infoMsg", value: { message: "Registo desativado" }}, "*");
    return;
  }

  const iframe: any = document.getElementById('iframeGeoPortal')

  selectPatrimonioResult(patrimonio)
  selectPatrimonioResultId(patrimonio?.id)
  setPatrimonioSelected(patrimonio);
}


  const handleCancel = ()  => {

        refreshForm();
        postMessage({ type: "form", value: "" }, "*");
        setTimeout(() => {
            postMessage({ type: "form", value: "registarPatrimonio" }, "*");
          }, 10)
    }

     const handleOpenModalDescartar = () => {
 if (
      sessionStorage.getItem('novoPatrimonio') &&
      sessionStorage.getItem('novoPatrimonio') == 'true'
    ) {
      setOpenModalDescartar(true);
    } else {
      onEditItemClick("SiarlVisualizar");
      handleCancel();
    }  }

  const [estadoFalse, setEstado] = useState(false)
  const [estadoTrue, setEstadoTrue] = useState(true)

  const deletePatrimonio = (id: number) => del(`${DELETE_DH_PATRIMONIO}/${id}`, {})

  const [deleteClicked, setDeleteClicked] = useState(false);

  const [canUpdate, setCanUpdate] = useState(false);
  const [canInsert, setCanInsert] = useState(false);
  const [editMode, setEditMode] = useState(false);
  useEffect(() => {
    setCanUpdate(hasPermission(PERMISSION_ATUALIZAR_PATRIMONIO));
    setCanInsert(hasPermission(PERMISSION_INSERIR_PATRIMONIO))
  }, [])

   const handleDescartar = async (e: any) => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
      {
        type: 'SiarlCoordenadaBoxClose',
        value: 'true',
      },
      '*'
    )
    iframe.contentWindow?.postMessage(
      {
        type: 'SiarlRemoveSelection',
        value: 'true',
      },
      '*'
    )
     setOpenModalDescartar(false)
     setDeleteClicked(false);
   const patrimonioSelected = getPatrimonioSelected()

    const dataToSend : any = {
      id: patrimonioSelected?.id ?? undefined,
      status: estadoFalse
    }

      let PatrimonioSaved: any
    if (patrimonioSelected?.id) {
         postMessage({ type: 'showLoader' }, '*')
          PatrimonioSaved = await updatePatrimonio(dataToSend)
          selectPatrimonioResult({
            ...selectedPatrimonioResult,
            ...PatrimonioSaved,})
          postMessage({ type: 'hideLoader' }, '*')

          }

    SessionStorageService.removeItem("firstCallPatrimonio");
    postMessage({ type: 'form', value: 'pesquisarPatrimonio' }, '*')

}

  const patrimonioSelected = getPatrimonioSelected();

  const handleSubmit = async (e: any) => {

    const isSubmitAction: boolean = JSON.parse(SessionStorageService.getItem("isSubmitActionPatrimonio") ?? false);

    const userId = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '')?.user?.id

    const patrimonioRequest: PatrimonioForm = {
      id: patrimonioSelected?.id ?? undefined,
      designacao: e.values?.designacao ?? undefined,
      lugar: e.values?.lugar ?? undefined,
      diploma: e.values?.diploma ?? undefined,
      id_tipo_pr: e.values?.id_tipo_pr?.id ?? undefined,
      obs: e.values?.obs ?? undefined,
      tecnico_id: userId,
      data_registo: e.values?.dataHoraRegisto ?? undefined,
      geom: undefined,
      flatCoordinates: coordenadasPoligono ?? undefined,
      geom_imported: selectedPatrimonioResult?.geom_imported,
      filedoc_cod: e.values?.filedoc_cod ?? undefined

    }

    postMessage({ type: 'showLoader' }, '*');

    try {
      let PatrimonioSaved: any;
      const patrimonioSelected = getPatrimonioSelected();

      if (selected != 1 && selected != 2) {
        if (patrimonioSelected?.id) {

          PatrimonioSaved = await updatePatrimonio(patrimonioRequest);
          selectPatrimonioResult(PatrimonioSaved);
          selectPatrimonioResult({
            ...selectedPatrimonioResult,
            ...PatrimonioSaved
          });
          setPatrimonioSelected(PatrimonioSaved);

        } else {

          PatrimonioSaved = await savePatrimonio(patrimonioRequest);

          setPatrimonioSelected(PatrimonioSaved);
          selectPatrimonioResult({
            ...selectedPatrimonioResult,
            ...PatrimonioSaved
          });

        }
      }

      setCoordenadasSelected(undefined)

      refreshForm(PatrimonioSaved?.id);

       setTimeout(() => {
          postMessage({ type: 'sucessMsg' }, '*')
        }, 500)
      
    } catch (error) {
      handleGenericError(error)
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  const itemRender = (props: { item: any; itemIndex: number }) => {
    const isTitleItem = props.item.key === 'titulo'
    if (isTitleItem) {
      // Item de título personalizado
      return (
        <div className={styles.DropDownButtonTitulo}>
          <span>{props.item.text}</span>
        </div>
      )
    } else {
      // Demais itens
      return (
        <div className={styles.DropDownButtonItem}>
          <span>{props.item.text}</span>
        </div>
      )
    }
  }

  const onItemClick = (event: any) => {
    if(event?.item?.key == "ImportGeom"){
      ImportGeom(event.item)
     } else {
      selectMapAction(event.item)
     } 
  }

  useEffect(() => {
    sessionStorage.setItem("SubModulo", PATRIMONIO_MODULE_NAME);
    setProcessoSelected(undefined);
    setDemarcacaoSelected(undefined);
  }, []);

  const [hasGeom, setHasGeom] = useState(false);
  
  useEffect(() => {
    if(getPatrimonioSelected()?.id){
    getPatrimonio(getPatrimonioSelected()?.id).then((result) => {
      if (result && result.hasGeom !== undefined) {
        setHasGeom(result.hasGeom);
      }
    });
  }
  });

  const optionsArea = [];

  if (!hasGeom) {
    optionsArea.push({
      key: 'SiarlGeoPortalAdicionarArea',
      text: 'Adicionar Área',
    });
  } else {
    optionsArea.push(
      {
        key: 'SiarlEditarGeometria',
        text: 'Editar Área',
      },
      {
        key: 'removerArea',
        text: 'Remover Área',
      }
    );
  }

  optionsArea.push({
    key: 'ImportGeom',
    text: 'Importar Geom',
  });

  const onItemClickImprimir = async (event: any) => {

    let mapImage = ''

    if (!window.location.href.includes('localhost')) {
      const mapIframe = document.querySelector(
        '#iframeGeoPortal'
      ) as HTMLIFrameElement

      const mapIframeInnerContent =
        mapIframe.contentWindow?.document.querySelector(
          'div.ol-viewport'
        ) as HTMLElement;

      const mapBussula =  mapIframe.contentWindow?.document.querySelector(
        'button.fixedNorthArrowButton'
      ) as HTMLElement;

      mapIframeInnerContent.appendChild(mapBussula);
      
      const generatedImage = await html2canvas(mapIframeInnerContent);
      mapImage = generatedImage.toDataURL()
    }

    console.log(getPatrimonioSelected());

    try {
      postMessage({ type: 'showLoader' }, '*')

      const documentoBase64 = await relatorioPatrimonio({
        id: getPatrimonioSelected()?.id,
        dados: {
          'REGISTO' : '',
          'Técnico: ': getPatrimonioSelected()?.utilizador?.name ?? '',
          'Nº Processo APA: ': getPatrimonioSelected()?.filedoc_cod ?? '',
          'Data Registo: ' : getPatrimonioSelected()?.data_registo ? new Date(getPatrimonioSelected()?.data_registo).toLocaleDateString() : '',
          
          'IDENTIFICAÇÃO' : '',
          'Diploma legal: ': getPatrimonioSelected()?.diploma ?? '',
          'Designação: ': getPatrimonioSelected()?.designacao ?? '',

          'LOCALIZAÇÃO' : '',
          'Nuts1: ': getPatrimonioSelected()?.nuts1[0]?.nome ?? '',
          'Nuts2: ': getPatrimonioSelected()?.nuts2[0]?.nome ?? '',
          'Nuts3: ': getPatrimonioSelected()?.nuts3[0]?.nome ?? '',
          'Distritos: ': getPatrimonioSelected()?.distritos[0]?.nome ?? '',
          'Concelhos: ': getPatrimonioSelected()?.concelhos[0]?.nome ?? '',
          'Freguesias: ': getPatrimonioSelected()?.freguesias[0]?.nome ?? '',

          'OBSERVAÇÔES' : '',
          'Observação: ': getPatrimonioSelected()?.obs ?? '',
          
          
        },
        img_mapa: mapImage
      });

      if (documentoBase64) {
        const blob = b64toBlob(documentoBase64, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
        postMessage({ type: 'hideLoader' }, '*')
      }
    } catch (error) {
      postMessage({ type: 'errorMsg', value: { message: "Não foi possivel efetuar esta operação" } }, '*')
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
    
  }

  const optionsImprimir = [
    {
      key: 'imprimirFichas',
      text: 'Fichas',
    },
    {
      key: 'imprimirResultados',
      text: 'Resultados',
    },
  ]

  const onItemClickExportar = async (event: any) => {
    
    let mapImage = ''

    if (!window.location.href.includes('localhost')) {
      const mapIframe = document.querySelector(
        '#iframeGeoPortal'
      ) as HTMLIFrameElement

      const mapIframeInnerContent =
        mapIframe.contentWindow?.document.querySelector(
          'div.ol-viewport'
        ) as HTMLElement;

      const mapBussula =  mapIframe.contentWindow?.document.querySelector(
        'button.fixedNorthArrowButton'
      ) as HTMLElement;

      mapIframeInnerContent.appendChild(mapBussula);
      
      const generatedImage = await html2canvas(mapIframeInnerContent);
      mapImage = generatedImage.toDataURL()
    }

    console.log(getPatrimonioSelected());

    try {
      postMessage({ type: 'showLoader' }, '*')

      const documentoBase64 = await relatorioPatrimonio({
        id: getPatrimonioSelected()?.id,
        dados: {
          'REGISTO' : '',
          'Técnico: ': getPatrimonioSelected()?.utilizador?.name ?? '',
          'Nº Processo APA: ': getPatrimonioSelected()?.filedoc_cod ?? '',
          'Data Registo: ' : getPatrimonioSelected()?.data_registo ? new Date(getPatrimonioSelected()?.data_registo).toLocaleDateString() : '',
          
          'IDENTIFICAÇÃO' : '',
          'Diploma legal: ': getPatrimonioSelected()?.diploma ?? '',
          'Designação: ': getPatrimonioSelected()?.designacao ?? '',

          'LOCALIZAÇÃO' : '',
          'Nuts1: ': getPatrimonioSelected()?.nuts1[0]?.nome ?? '',
          'Nuts2: ': getPatrimonioSelected()?.nuts2[0]?.nome ?? '',
          'Nuts3: ': getPatrimonioSelected()?.nuts3[0]?.nome ?? '',
          'Distritos: ': getPatrimonioSelected()?.distritos[0]?.nome ?? '',
          'Concelhos: ': getPatrimonioSelected()?.concelhos[0]?.nome ?? '',
          'Freguesias: ': getPatrimonioSelected()?.freguesias[0]?.nome ?? '',

          'OBSERVAÇÔES' : '',
          'Observação: ': getPatrimonioSelected()?.obs ?? '',
          
          
        },
        img_mapa: mapImage
      });

      if (documentoBase64) {
        const blob = b64toBlob(documentoBase64, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
    
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = 'reporte_património.pdf'; 
        link.click();
        
        postMessage({ type: 'hideLoader' }, '*')
      }
    } catch (error) {
      postMessage({ type: 'errorMsg', value: { message: "Não foi possivel efetuar esta operação" } }, '*')
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
    
  }

  const optionsExportar = [
    {
      key: 'exportarFichas',
      text: 'Ficha',
    },
  ]

   const optionsEditar = [
    {
      key: 'SiarlEditar',
      text: 'Editar',
    },
    {
      key: 'SiarlApagar',
      text: 'Apagar',
    },
    
  ]

 
  const onEditItemClick= (event:any) => {
    if (event == "SiarlEditar" || event?.item?.key == "SiarlEditar") {
      setEditMode(true);
      setViewMode(false);
      return;
    }
    if (event == "SiarlVisualizar") {
      setEditMode(false);
      setViewMode(true);
    }
    if (event?.item?.key == "SiarlApagar") {
      setDeleteClicked(true);
      setOpenModalDescartar(true);
    }
  }

  useEffect(() => {
    if (viewMode) {
      sessionStorage.removeItem("novoPatrimonio");
    }
  })

 const importGeomDhProcesso = async (data: any) =>
    await post<any, any>(`${IMPORT_GEOM_DH_PATRIMONIO}`, data)
  const getDhPatrimonioById = async (id: any) =>
    await get<any, any>(`${GET_PATRIMONIO}/` + id)
  const saveDhPatrimonio = async (data: any) => await post<any, any>(`${SAVE_NEW_PATRIMONIO}`, data);

   const cancelImport = () => {
    setOpenImportModal(false)
  }
  const importGeo = async (objToSend: any) => {
    postMessage({ type: "showLoader" }, "*");
    if (!objToSend) {
      postMessage({ type: "errorMsg", value: "Não foi possivel salvar" }, "*");
      postMessage({ type: "hideLoader" }, "*");
      return;

    }
    let dh_patrimonio;
    let dh_patrimonio_id;
    const patrimonioSelected = selectedPatrimonioResult ?? getPatrimonioSelected();
    if (patrimonioSelected && patrimonioSelected?.id) {
      dh_patrimonio = patrimonioSelected;
      dh_patrimonio_id = patrimonioSelected?.id;
    } else {
      dh_patrimonio = await saveDhPatrimonio({});
      dh_patrimonio_id = dh_patrimonio?.id;
      selectPatrimonioResult(dh_patrimonio);
      selectPatrimonioResultId(dh_patrimonio_id);

    }
    try {
      objToSend = { ...objToSend, dh_patrimonio_id }
      const result = await importGeomDhProcesso(objToSend)
      if (!result) {
        postMessage({ type: "errorMsg", value: "Não foi possivel salvar" }, "*");
        return;
      }

      try {
        dh_patrimonio = await getDhPatrimonioById(dh_patrimonio_id);
      } catch (error) {
        handleGenericError(error)

      } finally {
        dh_patrimonio = { ...dh_patrimonio, ...objToSend };
        selectPatrimonioResultId(dh_patrimonio_id);
        selectPatrimonioResult(dh_patrimonio);
        setPatrimonioSelected(dh_patrimonio)
        refreshForm(dh_patrimonio_id)

      }
      try {
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage(
          { type: 'SiarlPatrimonioSearch', value: [dh_patrimonio_id] },
          '*'
        )
      } catch (error) {
        handleGenericError(error)
      }

      setOpenImportModal(false)
    } catch (err) {
      handleGenericError(err)

    } finally {
      postMessage({ type: "hideLoader" }, "*");
    }

  }

 const ImportGeom = async (item: any) => {
    if (item.key == 'ImportGeom') {
      setOpenImportModal(true)
    }
  }
  
   const onImportGeomClick = (event: any) => {
    ImportGeom(event.item)
  }

  const onClickOpenFileDoc = async(e: any) => {
    const patrimonioSelected = getPatrimonioSelected();
    if (!patrimonioSelected?.filedoc_cod) {
      postMessage({ type: "infoMsg", value: {message: "É necessário preencher o nº processo APA (filedoc)."} }, "*");
      return;
    }
    postMessage({ type: 'showLoader' }, '*');
    const urlFileDoc = await getUrlFileDoc(patrimonioSelected?.filedoc_cod);
    postMessage({ type: 'hideLoader' }, '*')
    window.open(urlFileDoc, '_blank');
    return;
  }

  useLayoutEffect(() => {

    const handleResize = () => {

      if (window.innerWidth <= telaMobile) {
        
        setIsMobile(true);
        ocultaFloatingMenu_e_Mapa();

      }else{

        setIsMobile(false);
        mostraFloatingMenu_e_Mapa();

      }

    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);  

  const onItemClickMais = (event: any) => {

    elementoClickMais(event);
    
    onEditItemClick(event);
    onImportGeomClick(event);
    onItemClick(event);   
    onItemClickExportar(event);
  };

  const optionsMais = [
    {
      key: 'titulo',
      text: 'Registo',
    },
    ...optionsEditar.map(option => ({
      ...option,
      className: 'icon-siarl icon-editar btnIconRegistar',
    })),
    {
      key: 'titulo',
      text: 'Opções',
    },
    {
      key: 'openFileDoc',
      text: 'Diretório de documentos',
      className: 'icon-siarl icon-filedoc btnIconRegistar'
    },    
    {
      key: 'titulo',
      text: 'Geometria',
    },
    {
      key: 'titulo',
      text: 'Área',
    },
    ...optionsArea.map(option => ({
      ...option,
      className: 'icon-siarl icon-area btnIconRegistar',
    })),
    {
      key: 'titulo',
      text: 'Imprimir',
    },
    {
      key: 'imprimirFicha',
      text: 'Fichas',
      className: 'icon-siarl icon-imprimir btnIconRegistar'
    },
    {
      key: 'titulo',
      text: 'Exportar',
    },
    ...optionsExportar.map(option => ({
      ...option,
      className: 'icon-siarl icon-exportar btnIconRegistar',
    })),
  ]

  const openAjuda = () => {
    window.open(URL_AJUDA_DOMINIO_HIDRICO_PATRIMONIO, "_blank");
  }

  useEffect(() => {
    if (!tecnico) {
      userLogged();
    }
  }, [tecnico])

  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <div>
          <div className={styles.nomeDoModulo}>Domínio Hidríco</div>
            <div className={styles.tituloDaPagina}>
              {patrimonioSelected ? (editMode ? 'Editar património' : 'Património') : 'Novo património'}
            {/* {patrimonioSelected?.id || !patrimonioSelected ? (editMode ? 'Editar património' : 'Património') : 'Novo património'} */}
            </div>
          </div>
        <div>
          {!isMobile && canUpdate && (viewMode || editMode) && (sessionStorage.getItem("novoPatrimonio") != 'true') ? 
          <DropDownButton
            items={optionsEditar}
            className={styles.btnEditar2}
            iconClass="icon-siarl icon-editar"
            onItemClick={onEditItemClick}
            fillMode={'flat'}
            itemRender={itemRender}
            title="Editar"
          ></DropDownButton>
          : ''}

          <button className={styles.btnEditar2}  title='Filedoc' onClick={onClickOpenFileDoc}
          style={{ display: isMobile ? 'none' : '' }} id='openFileDoc'>
            <span className="icon-siarl icon-filedoc"></span>
          </button>


          {!isMobile &&
          <DropDownButton
            items={optionsArea}
            className={styles.btnEditar2}
            iconClass="icon-siarl icon-area"
            onItemClick={onItemClick}
            fillMode={'flat'}
            title='Geometria - Área'
            itemRender={itemRender}
            disabled={viewMode}
          ></DropDownButton>
          }

          <button id='imprimirFicha' className={styles.btnEditar2} onClick={onItemClickImprimir}
          style={{ display: isMobile ? 'none' : '' }} title='Imprimir'>
            <span className="icon-siarl icon-imprimir"></span>
          </button>

          <button id='exportarFicha' className={styles.btnImprimir} onClick={onItemClickExportar}
          style={{ display: isMobile ? 'none' : '' }} title='Exportar'>
            <span className="icon-siarl icon-exportar"></span>
          </button>

          {isMobile &&
            <DropDownButton
              items={optionsMais}
              className={styles.btnMais}
              iconClass="icon-siarl icon-mais"
              onItemClick={onItemClickMais}
              fillMode={'flat'}
              itemRender={itemRenderMais}
              title="Mais Opções"
            />
          }

          <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
            <span className="icon-siarl icon-ajuda"></span>
          </button>

          <button className={styles.btnFechar} onClick={voltar} title="Fechar">
            <span className="icon-siarl icon-fechar"></span>
          </button>
        </div>
      </div>

{openImportModal ?
        <ImportarGeometriaComponent onSubmit={importGeo} cancelImport={cancelImport} /> : <></>
      }

{openRemoveAreaModal ? (
               <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={() => setOpenRemoveAreaModal(false)}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                    <span className="textDescartar">Remover área </span>
                    <br></br>
                    <span>{'Tem a certeza que pretende remover a área selecionada?'}</span>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={() => setOpenRemoveAreaModal(false)}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={removeArea}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}

         {openModalDescartar ? (
          <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={cancelModalDescartar}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                  <fieldset>
                    <span className="textDescartar">{deleteClicked ? 'Apagar' : 'Cancelar'} registo  </span>
                    <br></br>
                    <span>{deleteClicked ? 'Ao apagar este registo deixará de ter acesso, sendo necessária uma intervenção rigorosa para realizar a sua recuperação.' : 'Ao cancelar este registo irá perder os dados inseridos até ao momento.'}</span>
                      <br></br>
                    <span>{deleteClicked ? 'Tem a certeza que pretende apagar?' : 'Tem a certeza que pretende cancelar?'}</span>
                  </fieldset>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={cancelModalDescartar}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={handleDescartar}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}
      
      <Form
        initialValues={
          {
            ...getPatrimonioSelected(),
            tecnicoRegisto: getPatrimonioSelected()?.tecnicoRegisto ?? tecnico,
            //dataHoraRegisto: getPatrimonioSelected()?.data_registo ?? new Date(),
            dataHoraRegisto: getPatrimonioSelected()?.data_registo ? new Date(getPatrimonioSelected()?.data_registo) : new Date(),
          } ?? undefined
        }
        onSubmitClick={handleSubmit}
        render={(formRenderProps) => (
          <FormElement>
            <TabStrip
              selected={selected}
              onSelect={handleSelect}
              className={`${styles.innerStyleTabContent} ${styles.backgroundWhite} ${viewMode? 'tabsVisualizar' : ''}`}
              scrollable={isMobile}
            >
              <TabStripTab title="Identificação" key={'Identificação'}>
                <IdentificacaoPatrimonio onCloseClick={onCloseClick} viewMode={viewMode} formRenderProps={formRenderProps} />
              </TabStripTab>
              <TabStripTab title="Documentos"  key={'Documentos'}>
                <DocumentoListagem pageName="patrimonio" viewMode={viewMode} />
              </TabStripTab>
              <TabStripTab title="Associação"  key={"Associação"}>
                <AssociacaoListagem pageName='patrimonio' viewMode={viewMode}/>
              </TabStripTab>
              
            </TabStrip>

             {(editMode || sessionStorage.getItem("novoPatrimonio") == 'true')
            && !viewMode ?
                  <div
                    className={`${styles.footer} ${styles.justify_multiple_children}`}
                  >

                  <Button type="button" className={styles.btnSeguinte} onClick={handleOpenModalDescartar} title='Cancelar'>
                  <span className="icon-siarl icon-fechar"></span>
                  <span className={styles.rotulo}>
                    {' '}&nbsp;{' '}
                    Cancelar
                  </span>
                 </Button>    

                   <Button
                      className={styles.btnSubmeter}
                       onClick={(e) => {
                      formRenderProps.onSubmit(e);
                      e.preventDefault();
                  }}
                  title='Gravar'
                    >
                      <span className="icon-siarl icon-submeter btnIconRegistar"></span>
                      <span className={styles.rotulo}>
                        {' '}&nbsp;{' '}
                        Gravar
                      </span>
                    </Button>
                  </div>
            : ''}
          </FormElement>
        )}
      />
    </div>
  )
}