import { create } from 'zustand'

type CandidaturaState = {
  selectedCandidaturaResult?: unknown
  selectCandidaturaResult: (payload: unknown) => void
  selectedCandidaturaResultId?: number
  selectCandidaturaResultId: (payload?: number) => void
  identificacaoTabIsValid: boolean
  selectCandidaturaIdentificacaoTabIsValid: (payload: unknown) => void
  identificacaoTabValidationObject: Record<string, boolean>
  addIdentificacaoTabValidatedField: (payload: Record<string, boolean>) => void
}

export const useCandidaturaStore = create<CandidaturaState>((set, get) => ({
  selectedCandidaturaResult: null,
  identificacaoTabIsValid: false,
  identificacaoTabValidationObject: {},
  selectCandidaturaResult: (payload: unknown) =>
    set({ selectedCandidaturaResult: payload }),
  selectCandidaturaResultId: (payload?: number) =>
    set({ selectedCandidaturaResultId: payload }),
  selectCandidaturaIdentificacaoTabIsValid: (payload?: any) =>
    set({ identificacaoTabIsValid: payload }),
  addIdentificacaoTabValidatedField: (payload) => {
    const validationObj = get().identificacaoTabValidationObject
    const newValidationObj = { ...validationObj, ...payload }
    set({ identificacaoTabValidationObject: newValidationObj })
    const validationValues = Object.values(newValidationObj)
    set({
      identificacaoTabIsValid: validationValues.every(
        (value) => value === true
      ),
    })
  },
}))
