import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import styles from '../../styles/usodosolo/UsoDoSolo.module.scss'

type HoverState = 'idle' | 'enter' | 'leave'

type Props = {
  onInfoClick?: (usTitulo: Record<string, unknown>) => () => void
  onLocationClick?: (usTitulo: Record<string, unknown>) => () => void
  dataItem: Record<string, unknown>
}

export const ResultadoTituloUsoDoSoloCommandGridCell = ({
  onInfoClick,
  onLocationClick,
  dataItem,
}: Props) => {
  return (
    <td className="k-command-cell">
      <div className={'gridCommand'}>
      {onLocationClick?
        <div
          role="button"
          onClick={onLocationClick(dataItem)}
          className={styles.gridCommandIconButton}
        >
          <FontAwesomeIcon icon={faLocationDot} title="Mostrar no Mapa" />
        </div>
        :<></>}
        
        {onInfoClick?
        <div
          role="button"
          className={styles.gridCommandIconButton}
          onClick={onInfoClick(dataItem)}
        >
          <FontAwesomeIcon icon={faInfoCircle} title="Detalhes de registo" />
        </div>
        :<></>}
      </div>
    </td>
  )
}
