import { Error } from '@progress/kendo-react-labels'
import { useEffect } from 'react'

import { useModeloStore } from '../../../../stores/modelo'

import type { FieldRenderProps } from '@progress/kendo-react-form'
import { ComboBox, ComboBoxProps } from '@progress/kendo-react-dropdowns'

export default function ValidatedDetalheComboBox(fieldRenderProps: FieldRenderProps) {
  const addDetalheTabValidatedField = useModeloStore((state) => state.addDetalheTabValidatedField)

  useEffect(() => {
    addDetalheTabValidatedField({ [fieldRenderProps.name]: (!!fieldRenderProps.value || fieldRenderProps.value == 0)})
  }, [fieldRenderProps.value])

  return (
    <div>
      <ComboBox {...fieldRenderProps as ComboBoxProps} />
      {fieldRenderProps.visited && fieldRenderProps.validationMessage && <Error>{fieldRenderProps.validationMessage}</Error>}
    </div>
  )
}
