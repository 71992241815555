import { create } from 'zustand'

type AgPretensaoState = {
  selectedAgPretensaoResult?: unknown
  selectAgPretensaoResult: (payload: unknown) => void
  selectedAgPretensaoResultId?: number
  selectAgPretensaoResultId: (payload?: number) => void
}

export const useAgPretensaoStore = create<AgPretensaoState>((set, get) => ({
  selectedAgPretensaoResult: null,
  selectAgPretensaoResult: (payload: unknown) =>
    set({ selectedAgPretensaoResult: payload }),
  selectAgPretensaoResultId: (payload?: number) =>
    set({ selectedAgPretensaoResultId: payload })
}))
