import { Button } from '@progress/kendo-react-buttons'
import { Dialog } from '@progress/kendo-react-dialogs'
import { MultiSelect } from '@progress/kendo-react-dropdowns'
import { Field } from '@progress/kendo-react-form'
import { TextArea } from '@progress/kendo-react-inputs'
import { useEffect, useState } from 'react'
import { arhs, blobToBase64, capitania, categorias, concelhos, distritos, entidades, fillArhsAsync, fillCapitaniaAsync, fillCategoriaAsync, fillConcelhosAsync, fillDistritosAsync, fillEntidadesAsync, fillFreguesiasAsync, fillIdiomasAsync, fillNuts1Async, fillNuts2Async, fillNuts3Async, fillPalavrasChaveAsync, fillPraiasAsync, fillTipoAcessoAsync, fillTipoRecursoAsync, freguesias, getDocumentoSelected, idioma, nuts1, nuts2, nuts3, palavraschave, post, praias, put, setDocumentoSelected, tipoacesso, tiporecurso, useCombos } from '../../../services/utils'
import { useDocumentosStore } from '../../../stores/documentos'
import styles from '../../../styles/documentos/Documentos.module.scss'
import { AUTH_LOCALSTORAGE, IMPORT_GEOM_DOCUMENTO, NOT_AUTHORIZED_ERROR_STATUS_CODE, PERMISSION_ERROR_STATUS_CODE, SAVE_NEW_DOCUMENTO, UPDATE_DOCUMENTO, UPLOAD_DOCUMENTO } from '../../../utils/constants'

//import { requiredFieldValidator } from '../../../validations/documento'
import { requiredFieldValidator } from '../../../validations/ocorrencia'

import EditDocumentoFormTabsSimplified from './documento/EditDocumentoFormTabsSimplified'
import ValidatedDetalheComboBox from './fields/ValidatedDetalheComboBox'
import ValidatedDetalheMultiSelect from './fields/ValidatedDetalheMultiSelect'

type Props = {
  viewMode?: boolean
}

export function DocumentoDetalhe({ viewMode }: Props) {

  const [freguesiasFiltred, setFreguesiasFiltred] = useState<any>()
  const [concelhosFiltred, setConcelhosFiltred] = useState<any>()
  const [concelhosDisabled, setConcelhosDisabled] = useState<any>(true)
  const [freguesiasDisabled, setFreguesiasDisabled] = useState<any>(true)
  const [openImportModal, setOpenImportModal] = useState<any>();
  const [freguesiaSelected, setFreguesiaSelected] = useState<any>()
  const [distritoSelected, setDistritoSelected] = useState<any>()
  const [concelhoSelected, setConcelhoSelected] = useState<any>()
  const [nuts1Selected, setNuts1Selected] = useState<any>();
  const [nuts2Selected, setNuts2Selected] = useState<any>();
  const [nuts3Selected, setNuts3Selected] = useState<any>();
  const [massaaguaSelected, setMassaaguaSelected] = useState<any>();
  const [arhSelectected, setArhSelected] = useState<any>()
  const [nuts2Filtred, setNuts2Filtered] = useState<any>()
  const [nuts3Filtred, setNuts3Filtered] = useState<any>()
  const [geomImported, setGeomImported] = useState<any>();
  const [showNewDocumentoDialog, setShowNewDocumentoDialog] = useState<boolean>(false);

  const importGeomDocumento = async (data: any) =>
    await post<any, any>(`${IMPORT_GEOM_DOCUMENTO}`, data)

  
  const selectedDocumentosResult: any = useDocumentosStore(
    (state) => state.selectedDocumentosResult
  )
  const selectDocumentosResult = useDocumentosStore(
    (state) => state.selectDocumentosResult
  )
  const selectDocumentosResultId = useDocumentosStore(
    (state) => state.selectDocumentosResultId
  )

  
   useEffect(() => {
    
     if(!nuts1 ){
      fillNuts1Async();
      }
     if(!nuts2) {
      fillNuts2Async();
     }
     if(!nuts3){
      fillNuts3Async();
     }
     if(!distritos){
      fillDistritosAsync();
     }
     if(!concelhos){
      fillConcelhosAsync();
     }
     if(!freguesias){
      fillFreguesiasAsync();
     }
     if(!arhs){
      fillArhsAsync();
     }
     if(!capitania){
      fillCapitaniaAsync();
     }
     if(!praias){
      fillPraiasAsync();
     }
     if(!entidades){
      fillEntidadesAsync();
     }
     if(!categorias){
      fillCategoriaAsync();
     }
     if(!idioma){
      fillIdiomasAsync();
     }
     if(!palavraschave){
      fillPalavrasChaveAsync();
     }
     if(!tiporecurso){
      fillTipoRecursoAsync();
     }
     if(!tipoacesso){
      fillTipoAcessoAsync();
     }

  }, []);




  return (
    <>
      <div className={`${styles.form} ${styles.removeScrollDuplicado}`}>
        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Idioma</span>
          </legend>
          <div className={styles.espacoVerticalFieldSet}>
            <div className={styles.fields}>
              <Field
                name="idioma"
                label="Idioma *"
                component={ValidatedDetalheComboBox}
                validator={requiredFieldValidator}
                textField={'designacao'}
                data={idioma}
                disabled={viewMode}

              />
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Categoria temática</span>
          </legend>
          <div className={styles.espacoVerticalFieldSet}>
            <div className={styles.fields}>
              <Field
                name="categoriaTematica"
                label="Categoria temática *"
                component={ValidatedDetalheMultiSelect}
                validator={requiredFieldValidator}
                textField={'designacao'}
                data={categorias}
                disabled={viewMode}
              />
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Palavras-chave</span>
          </legend>
          <div className={styles.espacoVerticalFieldSet}>
            <div className={styles.fields}>
              <Field
                name="palavraChave"
                label="Palavras-chave"
                component={MultiSelect}
                textField={'designacao'}
                data={palavraschave}
                disabled={viewMode}             
                 />
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Observações</span>
          </legend>
          <div className={styles.espacoVerticalFieldSet}>
            <div className={styles.fields}>
              <Field
                name="obs"
                label="Observações"
                component={TextArea}
                placeholder="Observações"
                rows={5}
                maxLength={528}
                disabled={viewMode}              />
            </div>
          </div>
        </fieldset>
      </div>

    </>
  )
}
