import { useEffect, useLayoutEffect, useState } from 'react';
import { redirect, useNavigate } from 'react-router-dom'
import Login from './Login';
import { AG_DESCONFORMIDADE_POINT_NAME, AG_PRETENSAO_POINT_NAME, AUTH_LOCALSTORAGE, DEMARCACAO_TABLE_NAME, DESCONFORMIDADE_TABLE_NAME, DH_DEMARCACAO_POINT_NAME, DH_PATRIMONIO_POINT_NAME, DH_PROCESSO_POINT_NAME, DOCUMENTO_POINT_NAME, DOCUMENTO_TABLE_NAME, ENTIDADES_MODULE_NAME, ENTIDADE_JURISDICAO_POINT_NAME, ENTIDADE_JURISDICAO_TABLE_NAME, ENTIDADE_POINT_NAME, ENTIDADE_TABLE_NAME, GEOPORTAL_MAPA_PUBLICO_URL, GEOPORTAL_MAPA_URL, INT_INTERVENCAO_POINT_NAME, INT_INTERVENCAO_TABLE_NAME, MODELOS_MODULE_NAME, MODELO_POINT_NAME, MODELO_TABLE_NAME, OCORRENCIAS_MODULE_NAME, OCORRENCIA_APOIO_POINT_NAME, OCORRENCIA_APOIO_TABLE_NAME, OCORRENCIA_POINT_NAME, OCORRENCIA_TABLE_NAME, PATRIMONIO_TABLE_NAME, PRETENSAO_TABLE_NAME, PROCESSO_TABLE_NAME, UPDATE_AG_DESCONFORMIDADE, UPDATE_DEMARCACAO, UPDATE_DH_PROCESSO, UPDATE_DOCUMENTO, UPDATE_DOCUMENTO_GEOM, UPDATE_ENTIDADE, UPDATE_ENTIDADE_JURISDICAO, UPDATE_INTERVENCAO, UPDATE_MODELO, UPDATE_OCORRENCIA_APOIO, UPDATE_OCORRENCIA_GEOMETRY, UPDATE_PATRIMONIO, UPDATE_PRETENSAO, UPDATE_US_TITULO, US_TITULO_POINT_NAME } from '../utils/constants';
import AuthProvider from '../components/auth/AuthProvider';
import Cookies, { CookieGetOptions } from 'universal-cookie';
import { Button } from '@progress/kendo-react-buttons';
import ViewerLayout from '../components/viewer/ViewerLayout'

import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import ReactLoading from 'react-loading';
import { geoPortalUser, getGeoPortalUser, put, refreshEntidadesSearch, refreshOcorrencesSearch, setCoordenadasSelected, setGeoPortalUrl, setIsEditingOcorrencia } from '../services/utils';
import styles from '../styles/dominiohidrico/DominioHidrico.module.scss'

import { Form, FormElement } from '@progress/kendo-react-form';
import { Dialog } from '@progress/kendo-react-dialogs';
import { ocultaFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa_e_Mostra_Form, telaMobile } from '../components/common/ItemRenderMais';
import SessionStorageService from '../services/SessionStorageService';

export default function Root() {
  const [isMobile, setIsMobile] = useState(false);
  const [success, setSuccess] = useState(false);
  const [msg, setMsg] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Ocorreu um erro ao gravar.");
   const [info, setInfo] = useState(false);
  const [infoMsg, setInfoMsg] = useState("");
  

  const [infoMapa, setInfoMapa] = useState(false);
  const [editGeom, setEditGeom] = useState(false);
  const [infoMsgMapa, setInfoMsgMapa] = useState("Selecione o local no mapa");
  const [infoEditGeom, setInfoMsgEditGeom] = useState("O ponto encontra-se em edição");
  const [editPonto, setEditPonto] = useState(false);

  const [infoMapaAberto, setInfoMapaAberto] = useState(false);
  const [infoMsgMapaAberto, setInfoMsgMapaAberto] = useState("");
  
  const [msgValue, setMsgValue] = useState("Info");
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState<any>();
  const [showLoaderCenter, setShowLoaderCenter] = useState<any>();
  const [showLoaderMapa, setShowLoaderMapa] = useState<any>();


  const [percentLeftTeste, setPercentLeft] = useState("19%");
  const [percentTop, setPercentTop] = useState("6%");
  const [top, setTop] = useState(false);

  const [openModalConfirmacaoCancelInfo, setOpenModalConfirmacaoCancelInfo] = useState<any>()
  const [openModalEditGeom, setOpenModalEditGeom] = useState<any>()

  const cancelModalConfirmacaoInfo = () => {
    setOpenModalConfirmacaoCancelInfo(false)
        sessionStorage.setItem('novoPontoOcorrencia', 'false')
        SessionStorageService.setItem('novaOcorrencia', 'false')
  }

  const openModal = () =>{
    setOpenModalConfirmacaoCancelInfo(true)
  }

  const cancelModalEditGeom = () => {
    setOpenModalEditGeom(false)
  }

  const openModalEditPonto = () =>{
    setOpenModalEditGeom(true)
  }

  const handleNotificationClose = () => {
   setOpenModalConfirmacaoCancelInfo(false)
   setOpenModalEditGeom(false)
};


const cancelEdicaoPonto = () => {
  setEditGeom(false)
  setOpenModalEditGeom(false)
     
  const iframe: any = document.getElementById('iframeGeoPortal')
  iframe.contentWindow?.postMessage(
    { type: 'SiarlRemoveSelection', value: 'true' },
    '*'
  )
   if (isMobile) {
    ocultaFloatingMenu_e_Mapa_e_Mostra_Form();
   }

   if (sessionStorage.getItem("Modulo") == OCORRENCIAS_MODULE_NAME) {
    refreshOcorrencesSearch();
   } else if (sessionStorage.getItem("Modulo") == ENTIDADES_MODULE_NAME) {
    refreshEntidadesSearch();
   }
  }

   const cancelarInfoMapa = () => {
    setInfoMapa(false)
    setOpenModalConfirmacaoCancelInfo(false)
        sessionStorage.setItem('novoPontoOcorrencia', 'false')
        SessionStorageService.setItem('novaOcorrencia', 'false')
        const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
      { type: 'SiarlGeoPortalAdicionarPonto', value: 'false' },
      '*'
    )
     if (isMobile) {
      ocultaFloatingMenu_e_Mapa_e_Mostra_Form();
     }
    }


  useEffect(() => {
        
    const iframe: any = document.getElementById('iframeGeoPortal');

    iframe.addEventListener('load', function () {
      const iframeWindow = iframe.contentWindow;

      try {
        if (location.href.indexOf('localhost') != -1) {
          setTimeout(() => {
            iframe.contentWindow?.postMessage({
              type: "DoSiarlGeoPortalLogin",
              user: getGeoPortalUser()
            }, "*");
          }, 1000);
        } else {
        iframeWindow.fetch('../view-resources/Views/Account/Login.js')
          .then((response: any) => {
            if (response.ok) {

              console.log('O arquivo Login.js está disponível dentro do iframe');

              setTimeout(() => {
                iframe.contentWindow?.postMessage({
                  type: "DoSiarlGeoPortalLogin",
                  user: getGeoPortalUser()
                }, "*");
              }, 1000);

              //postMessage({ type: 'hideLoader' }, '*')

            } else {

              console.log('O arquivo Login.js não foi encontrado dentro do iframe');
              //postMessage({ type: 'hideLoader' }, '*')

            }
          })
          .catch((error: any) => {

            console.error('Erro ao fazer a requisição do arquivo Login.js dentro do iframe:', error);
            //postMessage({ type: 'hideLoader' }, '*')

          });
        }
      } catch (e) {
        console.log(e);
        //postMessage({ type: 'hideLoader' }, '*')
      }
      try {
        if(iframe){
          // JS console iframe.contentWindow.document.getElementsByClassName("content")[0].style.margin=0;
          const contentWindowDocument = iframe?.contentWindow?.document;
           const mapGeo = contentWindowDocument?.getElementById("map");
           if(mapGeo){
             const classNameContent = contentWindowDocument?.getElementsByClassName("content")[0];
             if(classNameContent){
              classNameContent.style.margin = 0;
             }
           }
        }
      } catch (error) {
        console.log({error});
      }

    });

    addEventListener(
      'message',
      (event: MessageEvent) => {
        if (event.data.type == "showLoader") {
          setShowLoader(true);

        }
        else if (event.data.type == "hideLoader") {
          setShowLoader(false);
        }
        else if (event.data.type == "showLoaderCenter") {
          setShowLoaderCenter(true);

        }
        else if (event.data.type == "hideLoaderCenter") {
          setShowLoaderCenter(false);
        }
        else if (event.data.type == "showLoaderMapa") {
          setShowLoaderMapa(true);
        } else if (event.data.type == "hideLoaderMapa") {
          setShowLoaderMapa(false);
        }
        else if (event.data.type == "geoPortalSiarlLoginDone") {
          //location.reload();
          if (localStorage.getItem(AUTH_LOCALSTORAGE)) {
            setGeoPortalUrl(GEOPORTAL_MAPA_URL)
          } else {
            setGeoPortalUrl(GEOPORTAL_MAPA_PUBLICO_URL);
          }
        }
        
        else if (event.data.type == "sucessMsg") {
           if (event?.data?.value?.left) {
            setPercentLeft(event?.data?.value?.left)
          }else if(isMobile){
            setPercentLeft('-18%')
          }
          setSuccess(true);
          setError(false);
          setTimeout(() => {
            setSuccess(false);
          }, 5000);
        }
        else if (event.data.type == "hideInformationMsg") {
          setMsg(false);
        }
        else if (event.data.type == "errorMsg") {
          if (event?.data?.value?.message) {
            setErrorMsg(event?.data?.value?.message);
          }
            if(event?.data?.value?.left){
             setPercentLeft(event?.data?.value?.left)
            }
              if(event?.data?.value?.top){
                setPercentTop(event?.data?.value?.top)
                setTop(true)
              }
              else{
                setTop(false)
              }
          setError(true);
          setSuccess(false);
          setTimeout(() => {
            setError(false);
          }, 5000); 
        }
        else if (event.data.type == "infoMsg" || event.data.type == "informationMsg") {
          if (event?.data?.value) {
            if (typeof event.data.value === 'string') {
              setInfoMsg(event.data.value);
            } else if (event.data.value.message) {
              setInfoMsg(event.data.value.message);
            }
          }
          if(event?.data?.value?.left){
            setPercentLeft(event?.data?.value?.left)

          }
          setInfo(true);
          setSuccess(false);
          setTimeout(() => {
            setInfo(false);
          }, 5000); 
        }else if (event.data.type == "infoMsgMapa") {
          setInfoMapa(true);
          if (event?.data?.value?.message) {
            setInfoMsgMapa(event?.data?.value?.message);
            if (event?.data?.value?.left) {
              setPercentLeft(event?.data?.value?.left)
            }
          }
          setInfoMapa(true);
          setError(false);
          setSuccess(false);
        } 
        else if (event.data.type == "infoEditGeom") {
          setEditGeom(true);
          if (event?.data?.value?.message) {
            setInfoMsgEditGeom(event?.data?.value?.message);
            if (event?.data?.value?.left) {
              setPercentLeft(event?.data?.value?.left)
            }
             if(event?.data?.value?.geom == 'ponto'){
              setEditPonto(true)
            }else{
              setEditPonto(false)
            }
          }
          setInfoMapa(false)
          setEditGeom(true);
          setError(false);
          setSuccess(false);
        }   else if (event.data.type ==  "CoordenadasSelected" || event.data.type == "CoordenadasLineSelected" || event.data.type == "CoordenadasFlatSelected" || event.data.type == "SiarlPoligonoEdited"){
             setInfoMapa(false)       
        } 
        else if (event.data.type == "removeInfoPonto") {
          setEditGeom(false) 
        }

        else if (event.data.type == "SiarlGeoportalMapShare") {
          setGeoPortalUrl(GEOPORTAL_MAPA_URL + '&mapid=' + event.data.value);
        }
    
      },
      false
    );


   
  }, [])

  useLayoutEffect(() => {

    const handleResize = () => {

      if (window.innerWidth <= telaMobile) {
        
        setIsMobile(true);

      }else{

        setIsMobile(false);

      }

    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);  

  return (
    <><AuthProvider >
      <ViewerLayout />
    </AuthProvider><NotificationGroup
      style={{ top: !top ? '16%' : percentTop , left: (isMobile? (Number(percentLeftTeste.split('%')[0])-5)+'%' : percentLeftTeste)}}
    >
        <Fade>
          {success && (
            <Notification
              style={{borderColor: "#1BA49C", background:" #1BA49C 0% 0% no-repeat padding-box", color: "#ffffff"  }}
              className='leftSucessMsg'
              type={{ style: "success", icon: true }}
              closable={true}
              onClose={() => setSuccess(false)}
            >
              <span > Salvo com sucesso.</span>
            </Notification>
          )}
          {msg && (
            <Notification
              style={{borderColor: "#1BA49C", background:" #1BA49C 0% 0% no-repeat padding-box", color: "#ffffff"}}
              className='leftSucessMsg'
              type={{ style: "success", icon: true }}
              closable={true}
              onClose={() => setMsg(false)}
            >
              <span>{msgValue}</span>
            </Notification>
          )}
          {error && (
            <Notification
              style={{color: "#fff"}}
              className='leftErrorMsg'
              type={{ style: "error", icon: true }}
              closable={true}
              onClose={() => setError(false)}
            >
              <span>{errorMsg}</span>
            </Notification>
          )} {infoMapa && (
            <Notification
              style={{borderColor: "#1BA49C", background:" #001339 0% 0% no-repeat padding-box" }}
               type={{ style: "info", icon: true }}
              closable={true}
              onClose={() => openModal()}
            >
              <span>{infoMsgMapa}</span>
            </Notification>
          )}
          {editGeom && (
            <Notification
              style={{borderColor: "#1BA49C", background:" #001339 0% 0% no-repeat padding-box" }}
               type={{ style: "info", icon: true }}
              closable={true}
              onClose={() => openModalEditPonto()}
            >
              <span>{infoEditGeom}</span>
            </Notification>
          )}
           {info && (
            <Notification
              style={{borderColor: "#1BA49C", background:" #001339 0% 0% no-repeat padding-box" }}
               type={{ style: "info", icon: true }}
               className='leftSucessMsg'
              closable={true}
              onClose={() => setInfo(false)}
            >
              <span>{infoMsg}</span>
            </Notification>
          )}
         
        </Fade>
      </NotificationGroup>

       {openModalConfirmacaoCancelInfo ? (
          <Dialog
            
            className={styles.dialogConfirmacaoCancelInfo}
            onClose={cancelModalConfirmacaoInfo}
          >
            
            <Form
               initialValues={
                {
                  
                } 
              }
              render={(formRenderProps : any) => (
                <FormElement style={{ maxWidth: 500 }}>
                  <fieldset>
                    <span className="textDescartar">Cancelar seleção </span>
                    <br></br>
                    <span>Ao cancelar a seleção no mapa irá retornar á pesquisa.</span>
<br></br>
                    <span>Tem a certeza que pretende cancelar?</span>

                    <br></br>
                  </fieldset>
                  <br></br>
                  <div className="k-form-buttons btnModal">                    
                     <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={handleNotificationClose}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={cancelarInfoMapa}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
          )}

{openModalEditGeom ? (
          <Dialog
            
            className={styles.dialogModalsMapa}
            onClose={cancelModalEditGeom}
          >
            
            <Form
               initialValues={
                {
                  
                } 
              }
              render={(formRenderProps : any) => (
                <FormElement style={{ maxWidth: 500 }}>
                    <span className="textDescartar">Cancelar edição </span>
                    <br></br>
                    <span>Tem a certeza que pretende cancelar a edição  {editPonto ? 'do ponto' : 'da área' }?</span>
                  <br></br>
                  <div className="k-form-buttons btnModal">                    
                     <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={handleNotificationClose}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={cancelEdicaoPonto}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
          )}

      
      {showLoader ? <div className="overlay"><ReactLoading className={'spinner'} type={'spin'} color={'#1ba49c'} height={'10%'} width={'10%'} /></div> : <></>}
      {showLoaderCenter ? <div className="overlay"><ReactLoading className={'spinner-center'} type={'spin'} color={'#1ba49c'} height={'10%'} width={'10%'} /></div> : <></>}
      {showLoaderMapa ? <div className="overlay"><ReactLoading className={'spinner-left'} type={'spin'} color={'#1ba49c'} height={'10%'} width={'10%'} /></div> : <></>}

    </>
  )
}
