import { useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBars,
  faUser,
  faLocationDot,
  faStar,
  faShareNodes,
  faFilter,
  faMap,
} from '@fortawesome/free-solid-svg-icons'

import UserMenu from './UserMenu'
import ShareModal from './ShareModal'

import styles from '../../styles/viewer/FloatingMenu.module.scss'
import commonStyles from '../../styles/common/Common.module.scss'

import type { Dispatch, SetStateAction } from 'react'
import {
  APOIO_GESTAO_MODULE_NAME,
  AUTH_LOCALSTORAGE,
  BASE_GEOPORTAL_URL,
  CALCULO_PERFIL_MODULE_NAME,
  CREATE_PRETENSAO,
  DELETE_SESSION_STATE,
  FOTO_AEREA_MODULE_NAME,
  GET_AG_ESTADOS,
  GET_AG_TEMAS,
  GUEST_USER_NAME,
  PERMISSION_CALCULO_PERFIL,
  PERMISSION_GEOVISUALIZADOR_PERFIL_BASICO,
  PERMISSION_GEOVISUALIZADOR_PERFIL_COMPLETO_EDICAO,
  PERMISSION_GEOVISUALIZADOR_PERFIL_COMPLETO_LEITURA,
  PERMISSION_GEOVISUALIZADOR_PERFIL_INTERMEDIO_EDICAO,
  PERMISSION_GEOVISUALIZADOR_PERFIL_INTERMEDIO_LEITURA,
  PERMISSION_GEOVISUALIZADOR_PERFIL_TOTAL,
  PERMISSION_LISTAR_AG_DESCONFORMIDADE,
  PERMISSION_LISTAR_FOTO_AEREA,
  PERMISSION_LISTAR_VOO_DRONE,
  PERMISSION_PARTILHAR_MAPA,
  SAVE_NEW_AG_DESCONFORMIDADE,
  UPDATE_AG_DESCONFORMIDADE,
  UPDATE_PRETENSAO,
  VOO_DRONE_MODULE_NAME,
  clearLayers,
  production,
} from '../../utils/constants'
import {
  Field,
  Form,
  FormElement,
  FormSubmitClickEvent,
} from '@progress/kendo-react-form'
import { Input, TextArea } from '@progress/kendo-react-inputs'
import { ComboBox } from '@progress/kendo-react-dropdowns'
import {
  agDecisoes,
  agEstados,
  agTipoPretensoes,
  clearSession,
  coordenadasPoligono,
  get,
  getCurrentMapId,
  handleGenericError,
  hasPermission,
  post,
  put,
} from '../../services/utils'
import { DateTimePicker } from '@progress/kendo-react-dateinputs'
import { Dialog } from '@progress/kendo-react-dialogs'
import { FormRenderProps } from '@progress/kendo-react-form'
import ValidatedIdentificacaoInput from '../ocorrencia/fields/ValidatedIdentificacaoInput'
import { requiredFieldValidator } from '../../validations/documento'
import { useAgPretensaoStore } from '../../stores/agPretensao'
import { useAgDesconformidadeStore } from '../../stores/agDesconformidade'
import SessionStorageService from '../../services/SessionStorageService'
import { useCommonStore } from '../../stores/common'
import { useNavigate } from 'react-router'
import useClearMap from '../../hooks/useClearMap'
import ValidatedTextArea from '../forms/ValidatedTextArea'

type Props = {
  collapseSidebarMenu: boolean
  setCollapseSidebarMenu: Dispatch<SetStateAction<boolean>>
  isOverlayVisible: boolean
}

export default function FloatingMenu({
  collapseSidebarMenu,
  setCollapseSidebarMenu,
  isOverlayVisible,
}: Props) {
  const [visibleDialog, setVisibleDialog] = useState(false)
  const [showUserMenu, setShowUserMenu] = useState(false)
  const [isFloatingMenuVisible, setIsFloatingMenuVisible] = useState(false)
  const [agEstados, setAgEstados] = useState<any>([])
  const [agTemas, setAgTemas] = useState<any>([])
  const deleteSessionState = async (data: any) =>
   await post<any, any>(`${DELETE_SESSION_STATE}`, data)
   const navigate = useNavigate();

     const [percentLeftÁrea, setPercentLeftÁrea] = useState("40%");
     const [percentLeftLinha, setPercentLeftLinha] = useState("34%");
     const [percentLeftSucess, setPercentLeftSucess] = useState("-3%");

   


  const isCurrentViewMaximized = useCommonStore(state => state.isCurrentViewMaximized)

  const toggleDialog = () => {
    setVisibleDialog(!visibleDialog)
  }

  const openUserMenu = () => {
    const loggedUser = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) || '{}')
    if (loggedUser?.user?.username == GUEST_USER_NAME) 
    {
      loginButtonClick(loggedUser);
      return;
    }
    setShowUserMenu(true)
  }

  const loginButtonClick = (loggedUser: any) => {
    if (loggedUser?.user) {
      clearSession();
      const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.src = BASE_GEOPORTAL_URL + "Account/Logout"
    }
    setTimeout(() => {
      navigate("/login");
    }, 1000)
    
  }

  const toggleSideMenu = () => {
    setCollapseSidebarMenu(!collapseSidebarMenu)
  }

  const locationDotClick = () => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
      { type: 'SiarlGeoPortalSearch', value: 'true' },
      '*'
    )
  }

  const locationGoogleClick = () => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
      { type: 'SiarlSarchToponimiaGoogle', value: 'true' },
      '*'
    )
  }

  const favoritesClick = () => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
      { type: 'SiarlGeoPortalFavorites', value: 'true' },
      '*'
    )
  }
  const getAgEstados = async (data: any) =>
    await get<any, any>(`${GET_AG_ESTADOS}`)

  const getAgTemas = async () => await get<any, any>(`${GET_AG_TEMAS}`)

  useEffect(() => {
    //postMessage({ type: 'showLoader' }, '*')
    getAgEstados({})
      .then((result) => {
        setAgEstados(result)
        getAgTemas()
          .then((temas) => {
            setAgTemas(temas)
          })
          .catch((err) => {
            postMessage({ type: 'errorMsg', value:{message:"Não foi possivel efetuar esta operação"} }, '*')
          })
      })
      .finally(() => {
        //postMessage({ type: 'hideLoader' }, '*')
      })
  }, [])

  useEffect(() => {
    const handleMouseEnter = () => {
      setIsFloatingMenuVisible(true)
    }

    const handleMouseLeave = () => {
      setIsFloatingMenuVisible(false)
    }

    const iframe: any = document.getElementById('iframeGeoPortal')

    // iframe.addEventListener('mouseenter', handleMouseEnter)
    // iframe.addEventListener('mouseleave', handleMouseLeave)

    // return () => {  
    //   iframe.removeEventListener('mouseenter', handleMouseEnter)
    //   iframe.removeEventListener('mouseleave', handleMouseLeave)
    // }
  }, [])

 

  useEffect(() => {
    const handleMouseEnter = () => {
      setShowUserMenu(false)
    }

    const handleMouseLeave = () => {
      setShowUserMenu(false)
    }

    const iframe: any = document.getElementById('iframeGeoPortal')

    iframe.addEventListener('mouseenter', handleMouseEnter)
    iframe.addEventListener('mouseleave', handleMouseLeave)

    return () => {
      iframe.removeEventListener('mouseenter', handleMouseEnter)
      iframe.removeEventListener('mouseleave', handleMouseLeave)
    }
  }, [])

   

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const [openModalPretensoes, setOpenModalPretensoes] = useState<any>()

  const [openModalDesconformidades, setOpenModalDesconformidades] =
    useState<any>()
  const [seeDesconformidade, setSeeDesconformidade] = useState(false);
  const [seeCalculoPerfil, setSeeCalculoPerfil] = useState(false);
  const [seeVooDrone, setSeeVooDrone] = useState(false);
  const [seeFotoAerea, setSeeFotoAerea] = useState(false);
  const [seePartilhaMapa, setSeePartilhaMapa] = useState(false);

  const modulo = sessionStorage.getItem('Modulo')
  

  const cancelModalPretensoes = () => {
    setOpenModalPretensoes(false)
  }

  useEffect(() => {
    setSeeDesconformidade(hasPermission(PERMISSION_LISTAR_AG_DESCONFORMIDADE))
    setSeeCalculoPerfil(hasPermission(PERMISSION_CALCULO_PERFIL))
    setSeeVooDrone(hasPermission(PERMISSION_LISTAR_VOO_DRONE))
    setSeeFotoAerea(hasPermission(PERMISSION_LISTAR_FOTO_AEREA))
    setSeePartilhaMapa(
      hasPermission(PERMISSION_PARTILHAR_MAPA) ||
      hasPermission(PERMISSION_GEOVISUALIZADOR_PERFIL_BASICO) ||
      hasPermission(PERMISSION_GEOVISUALIZADOR_PERFIL_COMPLETO_EDICAO) ||
      hasPermission(PERMISSION_GEOVISUALIZADOR_PERFIL_COMPLETO_LEITURA) ||
      hasPermission(PERMISSION_GEOVISUALIZADOR_PERFIL_INTERMEDIO_EDICAO) ||
      hasPermission(PERMISSION_GEOVISUALIZADOR_PERFIL_INTERMEDIO_LEITURA) ||
      hasPermission(PERMISSION_GEOVISUALIZADOR_PERFIL_TOTAL)
    )
  }, [])

  const cancelModalDesconformidades = () => {
    sessionStorage.removeItem("desconformidadeClicked");
    setOpenModalDesconformidades(false)
  }

  const [dataRegistoInicio, setDataRegistoInicio] = useState<any>()
  const [dataRegistoFim, setDataRegistoFim] = useState<any>()

  const initialValues = {
    // Your initial field values here
  }

  const selectedAgDesconformidadeResultId: any = useAgDesconformidadeStore(
    (state) => state.selectedAgDesconformidadeResultId
  )

  const selectAgDesconformidadeResultId = useAgDesconformidadeStore(
    (state) => state.selectAgDesconformidadeResultId
  )
  const selectAgDesconformidadeResult = useAgDesconformidadeStore(
    (state) => state.selectAgDesconformidadeResult
  )

  const updateAgDesconformidade = async (data: any) =>
    await put<any, any>(`${UPDATE_AG_DESCONFORMIDADE}`, data)

  const saveAgDesconformidade = async (data: any) =>
    await post<any, any>(`${SAVE_NEW_AG_DESCONFORMIDADE}`, data)

  const selectAgPretensaoResultId: any = useAgPretensaoStore(
    (state) => state.selectAgPretensaoResultId
  )
  const selectAgPretensaoResult = useAgPretensaoStore(
    (state) => state.selectAgPretensaoResult
  )
  const [clearMap] = useClearMap();

const userLocal = JSON.parse(
      localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}'
    )
  const handleSubmit = async (formValues: any, isSubmitAction?: boolean) => {
    
    postMessage({type: "showLoaderCenter"}, "*");
    const desconformidadeRequest: any = {
      id: selectedAgDesconformidadeResultId ?? undefined,
      utilizador_id: userLocal.id,
      ag_tema_id: formValues?.values?.ag_tema?.id ?? undefined,
      ag_estado_id: formValues?.values?.ag_estado?.id ?? undefined,
      medida_corretiva_a_aplicar: formValues?.values?.medida_corretiva_a_aplicar ?? undefined ,
      medida_corretiva_aplicada : formValues?.values?.medida_corretiva_aplicada ?? undefined,
      descricao: formValues?.values?.descricao ?? undefined,
      flatCoordinates: coordenadasPoligono,
    }
    try {
      let desconformidade: any
      if (selectedAgDesconformidadeResultId) {
        desconformidade = await updateAgDesconformidade(desconformidadeRequest)
        cancelModalDesconformidades()
      } else {
        desconformidade = await saveAgDesconformidade(desconformidadeRequest)
        cancelModalDesconformidades()

      }
      postMessage({type: "hideLoaderCenter"}, "*");
      setTimeout(() => {
        postMessage({ type: 'sucessMsg' , value :{left : percentLeftSucess
        } },'*')
      }, 500)
     
      selectAgDesconformidadeResultId(desconformidade?.id as number)
      selectAgDesconformidadeResult(desconformidade as number)
      if (isSubmitAction) {
        postMessage(
          { type: 'form', value: 'pesquisarApoioGestaoPretensao' },
          '*'
        )
        selectAgDesconformidadeResultId(undefined)
        selectAgDesconformidadeResult(undefined)
      }
    } catch (error) {
      handleGenericError(error);
    }
  }

  const moduloFotoClick = (item: any) => {
    sessionStorage.setItem("fotoObliquaClicked" , "true")
    sessionStorage.setItem("Modulo", FOTO_AEREA_MODULE_NAME);
    if (production) {
      postMessage({type: "infoMsg", value: {message: "Em carregamento de dados"}}, "*");
    }
    postMessage({type: "form", value:""}, "*");
  }


 const perfilGeograficoClick = (item: any) => {
    postMessage(
      { type: 'infoMsgMapa', value: { message: 'Desenhe a linha para cálculo de perfis geográficos dinâmicos', left: percentLeftLinha  } },
      '*')
    postMessage({type: "form", value: ""}, "*");
    sessionStorage.setItem("perfilGeograficoClicked" , '');
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: item.key, value: 'true' }, '*')
    SessionStorageService.setItem("perfilGeograficoInProgress", true);
    sessionStorage.setItem("Modulo", CALCULO_PERFIL_MODULE_NAME);
  } 

  const desconformidadeClick = (item: any) => {
    postMessage(
      { type: 'infoMsgMapa', value: { message: 'Selecione a área no mapa', left: percentLeftÁrea } },
      '*'
    )
    sessionStorage.setItem("desconformidadeClicked" , 'true');
    postMessage({type: "form", value: ""}, "*");
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: item.key, value: 'true' }, '*')
  } 
  
  const handleClickVooDrone = () => {
    sessionStorage.setItem("Modulo", VOO_DRONE_MODULE_NAME);
    sessionStorage.setItem("vooDroneClicked" , 'true');
    if (production) {
      postMessage({type: "infoMsg", value: {message: "Em carregamento de dados"}}, "*");
    }
    postMessage({type: "form", value: "vooDrone"}, "*");
  }

  const limparFiltrosPesquisa = async () => {
    SessionStorageService.clearExceptKeyOf("menuItems");
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlClearLayers", value: 'true' }, '*')
    iframe.contentWindow?.postMessage({ type: "SiarlUsTituloClearLayers", value: "true" }, '*')

    clearMap(clearLayers["CLEAR_LAYERS_PATRIMONIO"]);
    try{
      const mapid = getCurrentMapId();
      await deleteSessionState(mapid);
    }catch(ex){
      console.log({ex})
    }

    postMessage({type: "hideFormsShowMenu", value: ""}, "*");
  }


      

  const area = [
    {
      key: 'SiarlGeoPortalAdicionarArea',
      text: 'Desenhar Área de abrangência',
      icon: <span className={`icon-siarl icon-area borderIconArea`}></span>,
    },
  ]




   window.addEventListener("message", event =>   {

  const receivedMessage = event.data;

  if (receivedMessage.type === 'desconformidadeModal') {
    setOpenModalDesconformidades(true); 
  }
});


  //window.addEventListener("message", event =>   {

 // const receivedMessage = event.data;

 // if (receivedMessage.type === 'openPerfilGeografico') {
  //postMessage({type: "form", value: "calculoPerfisGeograficosDinamicos"}, "*");  }
//});

  return (
    <div className={clsx(isCurrentViewMaximized && commonStyles.maximizedHidden)}>
      <div
        className={clsx(
          styles.base,
          collapseSidebarMenu && styles.collapsed,
          isOverlayVisible && styles.overlayVisible,
          !isFloatingMenuVisible && styles.floatingMenuVisible
        )}
      >
        <ul className={styles.menu}>
          <li className={clsx(styles.item, styles.firstItem)}>
            <button
              className={styles.button}
              onClick={toggleSideMenu}
              onMouseEnter={() => setIsFloatingMenuVisible(true)}
              onMouseLeave={() => setIsFloatingMenuVisible(false)}
              title={collapseSidebarMenu ? 'Abrir menu' : 'Fechar menu'}
            >
              {collapseSidebarMenu ? (
                <>
                  <span className="icon-siarl icon-abrirmenu"></span>
                </>
              ) : (
                <>
                  <span className="icon-siarl icon-fecharmenu"></span>
                </>
              )}
            </button>
          </li>
          <li className={styles.item}>
            <button
              className={styles.button}
              onClick={openUserMenu}
              title="Utilizador"
              onMouseLeave={() => setIsFloatingMenuVisible(false)}

              
            >
              <span className="icon-siarl icon-utilizador"></span>
            </button>
          </li>
          <li className={styles.item}>
            <button
              onClick={locationDotClick}
              className={styles.button}
              title="Pesquisa Camadas"
            >
              <span className="icon-siarl icon-pin"></span>
            </button>
          </li>
          <li className={styles.item}>
            <button
              onClick={locationGoogleClick}
              className={styles.button}
              title="Pesquisa geográfica"
            >
              <span className="icon-siarl icon-pesquisar"></span>
            </button>
          </li>
          <li className={styles.item}>
            <button
              onClick={favoritesClick}
              className={styles.button}
              title="Locais guardados"
            >
              <span className="icon-siarl icon-favorito"></span>
            </button>
          </li>
          {seePartilhaMapa ? 
          <li className={styles.item}>
            <button
              className={styles.button}
              onClick={toggleDialog}
              title="Partilhar mapa"
            >
              <span className="icon-siarl icon-partilhar"></span>
            </button>
          </li>
          : ''}
          <li className={styles.item}>
            <button className={styles.button} onClick={limparFiltrosPesquisa} title="Reiniciar mapa">
              <span className="icon-siarl icon-filtrosreset"></span>
            </button>
          </li>
          {seeDesconformidade? 
            <li className={styles.item}>
              <button
                className={styles.button}
                onClick={(e) => {
                  desconformidadeClick(area[0])
                }}
                title="Registar desconformidade"
              >
                <span className="icon-siarl icon-mapadesconformidade"></span>
              </button>
            </li> 
            : ''}
            {seeVooDrone ? 
             <li className={styles.item}>
              <button
                className={styles.button}
                onClick={(e) => {
                  handleClickVooDrone()
                }}
                title="Video Aéreo"
              >
                <span className="icon-siarl icon-videoaereo">
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                  <span className='path4'></span>
                </span>
              </button>
            </li>
            : ''}
            {seeFotoAerea ? 
              <li className={styles.item}>
            <button className={styles.button} onClick={moduloFotoClick} title="Foto aérea">
              <span className="icon-siarl icon-fotografiaaerea">
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                  <span className='path4'></span>
              </span>
            </button>
          </li>
          : ''}
          {seeCalculoPerfil ? 
          <li className={styles.item}>
            <button className={styles.button}   onClick={(e) => {
                  perfilGeograficoClick({
                  key: 'SiarlGeoPortalAdicionarLinhaCalculoPerfil'
                })
                }} title="Cálculo de perfis geográficos dinâmicos">
              <span className="icon-siarl icon-calcperfgeodinamico"></span>
            </button>
          </li>
          : ''}
        </ul>
        {openModalDesconformidades ? (
          <Dialog
            title="Desconformidades"
            className={styles.dialogDesconformidades}
            onClose={cancelModalDesconformidades}
          >
            
            <Form
              onSubmitClick={handleSubmit}
              initialValues={
                {
                  tecnicoRegisto: userLocal?.user?.name,
                } ?? undefined
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 620 }}>
                  <div className={`${styles.form} ${styles.removeScrollDuplicado}`}>
                  <fieldset>
                    <legend>
                      <span className={styles.fieldsetTitulo}>Registo</span>
                    </legend>
                    <div className={styles.espacoVerticalFieldSet}>
                      <div className={styles.fields}>
                        <div>
                          <Field name="id" label="ID" component={Input} disabled />
                        </div>
                        <div>
                          <Field
                            name="data_registo"
                            label="Data/hora registo"
                            format="dd/MM/yyyy HH:mm"
                            component={DateTimePicker}
                          />
                        </div>
                      </div>
                      <div className={styles.fields}>
                          <Field
                            name="tecnicoRegisto"
                            label="Técnico de registo"
                            component={Input}
                            disabled
                          />
                      </div>
                      <div className={styles.fields} style={{marginTop: 15}}>
                          <Field
                            name="descricao"
                            label="Descrição da desconformidade *"
                            placeholder="Descrição da desconformidade *"
                            rows={2}
                            size={"medium"}
                            fillMode={"outline"}
                            autoSize={true}
                            className={styles.textAreaCustomizado}
                            component={ValidatedTextArea}
                            maxLength={500}
                            validator={requiredFieldValidator}
                          />
                      </div>
                    </div>
                  </fieldset>
                  <fieldset>
                    <legend>
                      <span className={styles.fieldsetTitulo} style={{marginTop: 10}}>Identificação</span>
                    </legend>
                    <div className={styles.espacoVerticalFieldSet}>
                      <div className={styles.fields}>
                        <div>
                          <Field
                            name="ag_tema"
                            label="Tema"
                            data={agTemas}
                            textField={'descricao'}
                            component={ComboBox}
                          />
                        </div>
                        <div>
                          <Field
                            name="ag_estado"
                            label="Estado"
                            data={agEstados}
                            textField={'descricao'}
                            component={ComboBox}
                          />
                        </div>
                      </div>
                      <div className={styles.fields}>
                          <Field
                            name="medida_corretiva_a_aplicar"
                            label="Medida corretiva a aplicar"
                            component={Input}
                          />
                      </div>
                      <div className={styles.fields}>
                          <Field
                            name="medida_corretiva_aplicada"
                            label="Medida corretiva aplicada"
                            component={Input}
                          />
                      </div>
                    </div>
                  </fieldset>
                  <div className="k-form-buttons">
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                      onClick={formRenderProps.onSubmit}
                    >
                      Submeter
                    </button>
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                      onClick={cancelModalDesconformidades}
                    >
                      Cancelar
                    </button>
                  </div>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}

        {visibleDialog && <ShareModal toggleDialog={toggleDialog} />}
        {showUserMenu && <UserMenu onClose={() => setShowUserMenu(false)} />}
      </div>
    </div>
  )
}
