import { process } from '@progress/kendo-data-query';
import {
  Dispatch,
  MouseEvent as ReactMouseEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { Button, DropDownButton } from '@progress/kendo-react-buttons';
import { PagerTargetEvent } from '@progress/kendo-react-data-tools';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Grid, GridCellProps, GridColumn, GridPageChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { format } from 'date-fns';
import React from 'react';
import { CSVLink } from 'react-csv';
import SessionStorageService from '../../services/SessionStorageService';
import { GetUserNameTecnico, b64toBlob, entidadeUser, exportPDFWithMethod, get, handleDownload, handleGenericError, hasPermission, post, preventPropsNullValues, setCoordenadasPoligono, setIntervencaoSelected, setModeloSelected } from '../../services/utils';
import styles from '../../styles/intervencao/Intervencao.module.scss'
import commonStyles from '../../styles/common/Common.module.scss'
import { AUTH_LOCALSTORAGE, CONSULTAR_INTERVENCAO, CURRENT_TIMEZONE, GET_DOCUMENTO, GET_DOCUMENTO_BY_INTERVENCAO, GET_INT_ACAO_SHAPEFILE, GET_INT_INTERVENCOES_SHAPEFILE, GET_LIST_RESULTS_INT_INTERVENCAO, LAYER_INDEX_INT_INTERVENCOES, OPERATIONAL_LAYER, PERMISSION_INSERIR_INTERVENCAO, PERMISSION_INTERVENCAO_PERFIL_BASICO, PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO, PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA, PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO, PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_LEITURA, PERMISSION_INTERVENCAO_PERFIL_TOTAL, PESQUISAR_DOCUMENTOS_INTERVENCAO, PESQUISAR_INTERVENCAO, RECORDS_LIMIT, RELATORIO_MERGE_INTERVENCAO, URL_AJUDA_INTERVENCOES_INTERVENCAO, clearLayers } from '../../utils/constants';
import { ResultadoIntervencaoCommandGridCell } from './ResultadoIntervencaoCommandGridCell';
import { useIntervencaoStore } from '../../stores/intervencao';
import clsx from 'clsx';
import { useCommonStore } from '../../stores/common';
import MaximizeButton from '../common/MaximizeButton';
import { Dialog } from '@progress/kendo-react-dialogs';
import ImagePreviewV2 from '../viewer/ImagePreviewV2'
import { ResultadoDocumentoCommandGridCell } from '../documentos/ResultadoDocumentoCommandGridCell';
import { faBackward, faTable } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field } from '@progress/kendo-react-form';
import { Checkbox, CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import VisualizadorColunas from '../Generico/VisualizadorColunas ';
import useVisibleColumns, { Key } from '../../hooks/useVIsibleColumns';
import ReactGA from 'react-ga4';
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais';
import useClearMap from '../../hooks/useClearMap';
import { Session } from 'inspector';
import moment from 'moment'
import "moment-timezone"
// import useVisibleColumns from '../../hooks/useVisibleColumns';
type Props = {
  onCloseClick?: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
  setShowResultadoIntervencao: Dispatch<SetStateAction<boolean>>
  setShowRegistarIntervencao: Dispatch<SetStateAction<boolean>>
}

const options = [
  {
    key: 'ExportarFichasResultados',
    text: 'Exportar fichas resultados',
    icon: <span className={`icon-siarl icon-exportar ${styles.btnImprimir}`}></span>,
  }
]
type PageState = {
  skip: number
  take: number
}
const initialDataState: PageState = { skip: 0, take: 20 }
const keyPainelPrincipal:Key = 'intervencoesColunasVisiveis';
const keyPainelDocumentos:Key = 'intervencoesDocumentosColunasVisiveis';

export default function ResultadoIntervencaoForm({
  onCloseClick,
  setShowResultadoIntervencao,
  setShowRegistarIntervencao,
}: Props) {
  const [isMobile, setIsMobile] = useState(false);
  const [intervencoes, setintervencoes] = useState<any[]>([])
  const [documentosIntervencoes, setDocumentosIntervencoes] = useState([])

  const [selected, setSelected] = useState<number>(0)
  const [page, setPage] = useState<PageState>(initialDataState)
  const selectIntervencaoResult = useIntervencaoStore(
    (state) => state.selectIntervencaoResult
  )
  const selectIntervencaoResultId = useIntervencaoStore(
    (state) => state.selectIntervencaoResultId
  )
  const [pageSizeValue, setPageSizeValue] = useState<
    number | string | undefined
  >()

  const isCurrentViewMaximized = useCommonStore(state => state.isCurrentViewMaximized)
  const [clearMap] = useClearMap();

  const getIntervencao = async (data: any) =>
    await get<any, any>(`${PESQUISAR_INTERVENCAO}/` + data)

const consultarIntervencao = async (data: any) => await post<any, any>(`${CONSULTAR_INTERVENCAO}`, data)
const getShapeFile = async (data: any) =>
await post<any, any>(`${GET_INT_INTERVENCOES_SHAPEFILE}`, data)

      const getDocumentosByIntervencaoIDs = async (data: any) =>
  await post<any, any>(`${GET_DOCUMENTO_BY_INTERVENCAO}` , data)

  const getDocumento = async (data: any) =>
  await get<any, any>(`${GET_DOCUMENTO}/` + data)

  const getIntervencaoPorIds = async (data: any) =>
    await post<any, any>(`${GET_LIST_RESULTS_INT_INTERVENCAO}`, data)

  const relatorioIntervencaoMerge = async (data: any) =>
    await post<any, any>(`${RELATORIO_MERGE_INTERVENCAO}`, data)

  const [urlDoDocumento, setUrlDoDocumento] = useState("");
  const [idDoDocumento, setIdDoDocumento] = useState("");
  const [openForm, setOpenForm] = useState<boolean>(false)
  const {getVisibleColumns, removeColumnsKeys} = useVisibleColumns();

     const handleMessageEvent = useCallback((event: MessageEvent) => {
        if (event.data.type == "resultadoIntervencaoPesquisaChange") {
            SessionStorageService.setItem("resultadoIntervencaoPesquisa", event.data.value);
        }
    }, [])

     useEffect(() => {
        window.addEventListener(
            'message',
            handleMessageEvent,
            false
        )

        return () => {
            window.removeEventListener(
                'message',
                handleMessageEvent,
                false
            )
        }
    }, [handleMessageEvent])

    const documentosToPreview = documentosIntervencoes

    useEffect(() => {
      ReactGA.send({ hitType: "pageview", page: "/ResultadoIntervencao" });
    }, []);


    const initialized = useRef(false)
    useEffect(() => {
        if (!initialized.current) {
          initialized.current = true;      
        if (SessionStorageService.getItem("lastSearchIntervencao") && !SessionStorageService.getItem("firstCallIntervencao")) {
        postMessage({ type: "showLoader" }, "*");
        const objToSearch = SessionStorageService.getItem("lastSearchIntervencao") ?? '';
        consultarIntervencao(objToSearch).then((data) => {
          SessionStorageService.setItem("resultadoIntervencaoPesquisa", data);
          setResultsIntervencao();
          postMessage({ type: "hideLoader" }, "*");
        })
      } 
      else {
        setResultsIntervencao();
    }
  }
    }, [])

    const setResultsIntervencao = () => {
      try {
  
        const results = SessionStorageService.getItem('resultadoIntervencaoPesquisa')
  
        setintervencoes(results);
        try {
          const idsIntervencao = results.map((o: any) => o.id);
          const iframe: any = document.getElementById('iframeGeoPortal')
          
          getDocumentosByIntervencaoIDs(idsIntervencao).then(response => {
            setDocumentosIntervencoes(response.map((item: any) => ({
              ...item,
              data_publicacao: item.data_publicacao ? moment.utc(new Date(item.data_publicacao)).tz(CURRENT_TIMEZONE).format("DD/MM/yyyy") : undefined,
            })))
            console.log(response)
          })
          getVisibleColumns(keyPainelPrincipal, setColums)
          getVisibleColumns(keyPainelDocumentos, setColumsDocuments)

          const ids: any[] = []
          results.forEach((element: any) => {
            ids.push(element.id)
            })
            iframe.contentWindow?.postMessage(
              { type: 'SiarlIntIntervencaoSearch', value: ids },
              '*'
            )
        } catch (error) {
          console.error('Erro ao buscar os dados:', error)
        }
  
      } catch (e) {
        setintervencoes([]);
      }
    }

    useEffect(() => {
      const iframe: any = document.getElementById('iframeGeoPortal')
      const ids: number[] = []
      intervencoes.forEach((element: any) => {
        ids.push(element.id)
      })
      iframe.contentWindow?.postMessage(
        { type: 'SiarlIntIntervencaoSearch', value: ids },
        '*'
      )
    }, [])

  const clearSearchResults = () => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlIntIntervencaoClearLayers", value: "true" }, '*')
    SessionStorageService.removeItem('resultadoIntervencaoPesquisa')
    SessionStorageService.removeItem("lastSearchIntervencao");
    SessionStorageService.removeItem("total_valor_execucao");
    removeColumnsKeys(keyPainelDocumentos)
    removeColumnsKeys(keyPainelPrincipal)
    setCoordenadasPoligono(undefined);
    clearMap(clearLayers["CLEAR_LAYERS_INTERVENCAO"])

    postMessage({ type: "form", value: "pesquisarIntervencao" }, "*");
    setintervencoes([]);
  }

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected)
  }
  const handlePageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent
    const take = event.page.take

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value)
    }

    setPage({
      ...event.page,
      take,
    })
  }

  const handleDocumentPreviewClickById = (documentosLocal: Record<string, any>) => async () => {

    try {

      postMessage({ type: "showLoader" }, "*");
      const documentos = await getDocumento(documentosLocal.id);

      setIdDoDocumento(documentosLocal.id);
      setUrlDoDocumento(documentos.url);
      setOpenForm(true);

    } catch (error) {

      handleGenericError(error, "Não foi possivel abrir o documento!")
      setOpenForm(false);

    } finally {

      postMessage({ type: "hideLoader" }, "*");

    }
  }

  const nextPhoto = async (e: any) => {

    try{

      postMessage({ type: "showLoader" }, "*");
      const nextIds = documentosToPreview
        .filter((doc: any) => doc.id < idDoDocumento && doc.url !== '')
        .map((doc: any) => doc.id);
  
      if (nextIds.length > 0) {
        const nextId = Math.max(...nextIds);
        console.log(`O próximo ID é ${nextId}`);
  
        const documento = await getDocumento(nextId);
  
        setIdDoDocumento(nextId.toString());
        setUrlDoDocumento(documento.url);

        const iframe: any = document.getElementById('iframeGeoPortal')
        const intervencao_id = e.intervencao_id;
        iframe.contentWindow?.postMessage(
          {
            type: 'SiarlIntIntervencaoHighlight',
            value: [intervencao_id],
          },
          '*'
        )
  
      } else {
        console.log("Não há um próximo ID na lista.");
      }

    }catch(error){

      postMessage({ type: "errorMsg", value: "Não foi possivel abrir o documento!" }, "*");

    }finally{

      postMessage({ type: "hideLoader" }, "*");

      e.preventDefault();
    }
   
  }

  const previousPhoto = async (e: any) => {

    try{

      postMessage({ type: "showLoader" }, "*");
      const previousIds = documentosToPreview
        .filter((doc: any) => doc.id > idDoDocumento && doc.url !== '')
        .map((doc: any) => doc.id);
  
      if (previousIds.length > 0) {
        const previousId = Math.min(...previousIds);
        console.log(`O anterior ID é ${previousId}`);
  
        const documento = await getDocumento(previousId);
  
        setIdDoDocumento(previousId.toString());
        setUrlDoDocumento(documento.url);

        const iframe: any = document.getElementById('iframeGeoPortal')
        const intervencao_id = e.intervencao_id;
        iframe.contentWindow?.postMessage(
          {
            type: 'SiarlIntIntervencaoHighlight',
            value: [intervencao_id],
          },
          '*'
        )
      } else {
        console.log("Não há um anterior ID na lista.");
      }

    } catch(error){

      handleGenericError(error, "Não foi possivel abrir o documento!")

    }finally{

      postMessage({ type: "hideLoader" }, "*");

      e.preventDefault();
    }
       
  }

  useEffect(() => {

    const elemento: any = document.querySelector(".k-window-content.k-dialog-content");

    if (elemento) {
      elemento.style.padding = "0px";
    }

  }, [openForm]);

  const handleMapCenter = (intervencao: Record<string, any>) => async () => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    //iframe.contentWindow?.postMessage({ type: "SiarlOcorrenciasSearch", value: [ocorrencia.id] }, '*')
    if (intervencao.id) {
      iframe.contentWindow?.postMessage(
        {
          type: 'SiarlIntIntervencaoCenter',
          value: [intervencao.id],
        },
        '*'
      )
    }
  }

  const handleInfoClickById = (intervencaoLocal: Record<string, any>) => async () => {
    postMessage({ type: "showLoader" }, "*");
    const id = intervencaoLocal.intervencao_id ?? intervencaoLocal.id;
    const intervencao = await getIntervencao(id);
    const tecnico = await GetUserNameTecnico(intervencao.utilizador_id);
    

     if (!intervencao) {
      postMessage({type: "infoMsg", value: { message: "Registo desativado" }}, "*");
      postMessage({ type: "hideLoader" }, "*");
      return;
    }
    postMessage({ type: "hideLoader" }, "*");

    
    const iframe: any = document.getElementById('iframeGeoPortal')
    
    const objToShow = {
      ...intervencao,
      id: intervencao.id,
      localizacao: intervencao?.localizacao ?? undefined,
       nuts1: intervencao.nuts1 ?? undefined,
      nuts2: intervencao.nuts2 ?? undefined,
      nuts3: intervencao.nuts3 ?? undefined,
      distrito : intervencao.distrito ?? undefined,
      concelho: intervencao.mobilizacao ?? undefined,
      freguesia: intervencao.freguesia ?? undefined,
      arh: intervencao.arh ?? undefined,
      capitania : intervencao.capitania ?? undefined,
      massaagua: intervencao.massaagua ?? undefined,
      inicio: intervencao?.inicio? new Date(intervencao?.inicio):null,
      fim :intervencao?.inicio? new Date(intervencao?.fim):null,
      id_tipo_n1: intervencao?.tipo_n1 ?? undefined,
      id_tipo_n2: intervencao?.tipo_n2 ?? undefined,
      id_tipo_n3: intervencao?.tipo_n3 ?? undefined,
      estadoIntervencao: intervencao?.estado ?? undefined,
      id_prioridade: intervencao?.int_prioridade ?? undefined,
      id_int_pal_xxi_tip_nivel_1: intervencao?.int_pal_xxi_tip_nivel_1 ?? undefined,
      id_int_pal_xxi_tip_nivel_2: intervencao?.int_pal_xxi_tip_nivel_2 ?? undefined,
      id_int_pal_xxi_tip_nivel_3: intervencao?.int_pal_xxi_tip_nivel_3 ?? undefined,
      id_int_instrumento_planeamento: intervencao?.int_instrumento_planeamento ?? undefined,
      entidade: intervencao?.entidade ?? undefined,
      designacao: intervencao?.designacao ?? undefined,
      numero_intervencao: intervencao?.numero_intervencao ?? undefined,
      lstEntidades: preventPropsNullValues(intervencao.lstEntidades, "nome") ?? undefined,
      filedoc_cod: intervencao?.filedoc_cod ?? undefined,
      utilizador_id : tecnico,
      praia: intervencao?.praia ?? undefined,
      inv_global: intervencao?.inv_global ?? undefined,
      fin_comunitario: intervencao?.fin_comunitario ?? undefined,
      val_executado: intervencao?.val_executado ?? undefined,
      id_tipo_in : intervencao?.intComponente ?? undefined,
      objetivo : intervencao?.objetivo ?? undefined,
      data_registo: intervencao.data_registo ? moment.utc(new Date(intervencao.data_registo)).tz(CURRENT_TIMEZONE) : undefined,
      entidade_registo: entidadeUser ?? undefined,
      observacoes : intervencao.obs ?? undefined
      
    }
  

    sessionStorage.setItem('novaIntervencao', 'false')
    SessionStorageService.setItem('total_valor_execucao',intervencao?.val_executado  )
    selectIntervencaoResult(objToShow)
    selectIntervencaoResultId(intervencao?.id)
    setIntervencaoSelected(objToShow);
    setShowResultadoIntervencao(false)
    setShowRegistarIntervencao(true)
    const idToSend = [intervencao?.id];

    iframe.contentWindow?.postMessage(
      { type: 'SiarlIntIntervencaoSearch', value: idToSend },
      '*'
    )
    iframe.contentWindow?.postMessage(
      { type: 'SiarlIntIntervencaoCenter', value: idToSend },
      '*'
    )
  }
  const intervencaoCustomCell = (props: GridCellProps) => (
    <ResultadoIntervencaoCommandGridCell
    {...props}
    onInfoClick={handleInfoClickById}
    onMapCenter={handleMapCenter}
    {...conditionalProps}
    />
  )

  const documentsCustomCell = (props: GridCellProps) => (
    <ResultadoDocumentoCommandGridCell
    onLocationMouseEnter={function (documentos: Record<string, unknown>): void {
      console.log("n/a");
    } } onLocationMouseLeave={function (documentos: Record<string, unknown>): void {
      console.log("n/a");
    } } onLocationClick={handleLocationClick} onDocumentPreviewClick={handleDocumentPreviewClickById} {...props}
    onInfoClick={handleInfoClickById}    />
  )


  const [dataState, setDataState] = React.useState({
    skip: 0,
    take: 20,
    sort: [],
    group: [],
  });

  const [dataStateDocumentos, setDataStateDocumentos] = React.useState({
    skip: 0,
    take: 20,
    sort: [],
    group: [],
  });

  const dataStateChange = (event: any) => {
    setDataResult(process(intervencoes, event.dataState));
    setDataState(event.dataState);
  };

  const [dataResult, setDataResult] = React.useState(
    process(intervencoes, dataState)
  );

  const dataStateChangeDocumentos = (event: any) => {
    setDataResultDocumentos(process(documentosIntervencoes, event.dataState));
    setDataStateDocumentos(event.dataState);
  };

  const [dataResultDocumentos, setDataResultDocumentos] = React.useState(
    process(documentosIntervencoes, dataStateDocumentos)
  );

  const optionsImprimir = [
    {
      key: 'ExportarListaResultados',
      text: 'Resultados',
    },
  ]

  const optionsExportar = [
    {
      key: 'ExportarListaResultadosExcel',
      text: 'excel',
    },
    {
      key: 'ExportarListaResultadosPdf',
      text: 'PDF',
    },
    {
      key: 'ExportarListaResultadosShapeFile',
      text: 'Shapefile',
    },
    {
      key: 'ExportarTodasFichas',
      text: 'Fichas',
    },
  ]

  const [columsDocuments, setColumsDocuments] = useState<{ columns: { field: string, title: string, width: number | string, show: boolean }[] }>({
    columns: [
      { field: "id", width: 70, title: "ID", show: true },
      { field: "filedoc_cod", width: 190, title: "ID Documento Filedoc", show: true },
      { field: "data_publicacao", width: 150, title: "Data publicação", show: true },
      { field: "tipoRecurso.designacao", width: 150, title: "Tipo de recurso", show: true },
      { field: "titulo", width: 90, title: "Título", show: true },
      { field: "autores", width: 90, title: "Autor", show: true },
      { field: "entidade.nome", width: 90, title: "Entidade", show: true },
      { field: "id_estado", width: 'auto', title: "Estado", show: true },
    ]
  })
  const canSeeIntervencao = hasPermission(PERMISSION_INTERVENCAO_PERFIL_BASICO) ||
  hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO) ||
  hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA) ||
  hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO) ||
  hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_LEITURA) ||
  hasPermission(PERMISSION_INTERVENCAO_PERFIL_TOTAL);

  
  const conditionalProps =  canSeeIntervencao
  ? { 
      onInfoClick: handleInfoClickById
  } 
  : {};
  
  const colunasPadrao =[
    { field: "id", title: "ID", show: true,width:70 },  

  ]
  const colunasAdicionais = [
    { field: "localizacao", title: "Localização", show: true,width:140 },  
    // { field: "instrumento", title: "Instrumento", show: true, width:130 }, 
    { field: "int_pal_xxi_tip_nivel_3.descricao", title: "Tipologia 3 PAL XXI", show: true,width:238  },
    { field: "designacao", title: "Designação", show: true,width:130 },
    { field: "int_prioridade.descricao", title: "Prioridade", show: true,width:120 },  
    { field: "objetivo", title: "Objetivo", show: true,width:238  },
    { field: "inv_global", title: "Investimento global", show: true,width:238  },
    { field: "fin_nacional", title: "Financiamento nacional", show: true,width:238  },
    { field: "fin_comunitario", title: "Financiamento comunitário", show: true,width:238  },
    { field: "inicio", title: "Data Início", show: true,width: 120 },  
    { field: "fim", title: "Data Fim", show: true,width: 120  },  
    { field: "tipo_n1.descricao", title: "Tipologia nivel 1", show: false,width: 180 },  
    { field: "tipo_n2.descricao", title: "Tipologia nivel 2", show: false,width:180 },  
    { field: "tipo_n3.descricao", title: "Tipologia nivel 3", show: false,width:180  },
    { field: "estado.descricao", title: "Estado", show: false,width: 140  },  
    { field: "entidade", title: "Entidade Responsável", show: true,width:238  },
    { field: "obs", title: "Observações", show: true,width:238  },
    // { field: "lstEntidades.nome", title: "teste", show: true,width:238  },
  ]
  const [colums, setColums]=useState<{columns:{field:string, title:string, width:number|string, show:boolean}[]}>({
    columns:colunasPadrao
  })
  useEffect(() => {
    if(canSeeIntervencao){
      setColums({ columns: [...colunasPadrao, ...colunasAdicionais] });
    }
  }, []);
  useEffect(() => {
    SessionStorageService.setItem(keyPainelDocumentos, JSON.stringify(columsDocuments));
  }, [columsDocuments]);
  
  useEffect(() => {
    SessionStorageService.setItem(keyPainelPrincipal, JSON.stringify(colums));
  }, [colums]);
  


  const itemRender = (props: { item: any; itemIndex: number }) => {
    const isTitleItem = props.item.key === 'titulo';
    if (isTitleItem) {
      // Item de título personalizado
      return (
        <div className={styles.DropDownButtonTitulo}>
          <span>{props.item.text}</span>
        </div>
      );
    } else {
      // Demais itens
      return (
        <div className={styles.DropDownButtonItem}>
          <span>{props.item.text}</span>
        </div>
      );
    }
  };
  const onItemClickExportar = async (event: any) => {
    if(event.item.key == "ExportarTodasFichas"){
      onItemClickExportarFichas();
    }
    if (event.item.key == 'ExportarListaResultadosExcel') {
      exportExcel();
    } else if (event.item.key == 'ExportarListaResultadosPdf') {
      exportPDF();
    }
    else if (event.item.key == "ExportarListaResultadosShapeFile") {
      const results = SessionStorageService.getItem('resultadoIntervencaoPesquisa')
  
      if (results) {
        const ids: number[] = []
        results.forEach((element: any) => {
          ids.push(element.id)
        })

        const file: any = await getShapeFile(ids);
        const filePath = 'shapeFileIntIntervencoes.zip';

        handleDownload(file, filePath);
    }
  } 
}
  
  let _pdfExport: any;
  const exportExcel = () => {
    _export.save();
  };

  let _export: any;
  const exportPDF = () => {
    _pdfExport.save();
  };

  const openPdfNewTab = () => {
    return new Promise<void>((resolve, reject) => {
        
        postMessage({ type: "showLoader" }, "*");

        setTimeout(() => {
           const columnsToShow = colums.columns.filter(x => x.show);
            const dataToExport = intervencoes?.map((item: any) => {
                const filteredItem: any = {};
                columnsToShow.forEach((column: any) => {
                    filteredItem[column.field] = item[column.field];
                });
                return filteredItem;
            });
            
            exportPDFWithMethod(columnsToShow, dataToExport, 8)
                .then((pdfData: any) => {
                    postMessage({ type: "hideLoader" }, "*");

                        const base64Data = pdfData.split(";base64,")[1];
                        console.log(base64Data);
                        const blob = b64toBlob(base64Data, 'application/pdf');
                        const blobUrl = URL.createObjectURL(blob);

                      
                        window.open(blobUrl, '_blank');
                        resolve();
                  
                })
                .catch(error => {
                    console.error('Error exporting PDF:', error);
                    reject(error);
                });
        }, 1000);
    });
};

const onItemClickExportarFichas = async () => {
  try {

  
    const relatorios = [];

    const results = SessionStorageService.getItem('resultadoIntervencaoPesquisa');
  
 

    const idsIntervencao = results.map((o: any) => o.id);

    if(idsIntervencao.length  > RECORDS_LIMIT){
      postMessage({ type: 'errorMsg', value: { message: "Não é possivel exportar mais de 100 fíchas" } }, '*')
      return false
      }
  

    const intervencoes = await getIntervencaoPorIds(idsIntervencao);
    
      postMessage({ type: 'showLoader' }, '*');
    
      for (const intervencaoId of idsIntervencao) {

      const intervencao = intervencoes.find((intervencao: any) => intervencao.id === intervencaoId);

      const objToSendImgMapa : any = {
        CodPrintTemplate: "RELATORIO_INT_INTERVENCAO",
        extendInfo: {
          spatialReference: {
            wkid: 0
          },
          xmin: 0,
          ymin: 0,
          xmax: 0,
          ymax: 0
        },
        ListOperationalLayerInfo: [
          {
            IdOperationLayer: OPERATIONAL_LAYER,
            UseLayerExtent: false,
            DefinitionExpression: "",
            ListServiceLayer: [
              {
                  LayerId: LAYER_INDEX_INT_INTERVENCOES,
                  DefinitionExpression: "id=" + intervencao?.id,
                  UseLayerExtent: true
              }
            ]
          }
        ]
      }
   
      const relatorio = {
        id: intervencao?.id,
        dados: {
          'Nº Processo APA:': intervencao?.filedoc_cod ?? '',
          'Designação:': intervencao?.designacao ?? '',
          'Número da intervenção:' : intervencao?.numero_intervencao ?? '',
          'Técnico:' : intervencao?.tecnico ?? '',

          'Nuts1:': intervencao?.nuts1?.nome ?? '',
          'Nuts3:': intervencao?.nuts3?.nome ?? '',
          'Nuts2:': intervencao?.nuts2?.nome ?? '',
          'Distritos:': intervencao?.distritos?.nome ?? '',
          'Concelhos:': intervencao?.concelhos?.nome ?? '',
          'Freguesias:': intervencao?.freguesias?.nome ?? '',

          'Data de início:': intervencao.inicio ? new Date(intervencao.inicio).toLocaleDateString() : '',
          'Data de fim:': intervencao.fim ? new Date(intervencao.fim).toLocaleDateString() : '',

          'Fonte financiamento previsto:' : intervencao?.lstFinanciamento?.designacao ?? '-',
          'Tipologia Nível 1:': intervencao?.int_pal_xxi_tip_nivel_1?.descricao ?? '',
          'Tipologia Nível 2:': intervencao?.int_pal_xxi_tip_nivel_2?.descricao ?? '',
          'Tipologia Nível 3:': intervencao?.int_pal_xxi_tip_nivel_3?.descricao ?? '',
          'Prioridade:': intervencao?.int_prioridade?.descricao ?? '',
          'Estado:': intervencao?.estado?.descricao ?? '',

          'Instrumento de planeamento:': intervencao?.int_instrumento_planeamento?.designacao ?? '',
          'Tipologia Nível 1': intervencao?.tipo_n1?.descricao ?? '',
          'Tipologia Nível 2': intervencao?.tipo_n2?.descricao ?? '',
          'Tipologia Nível 3': intervencao?.tipo_n3?.descricao ?? '',
        },  
        img_mapa: "",
        objJsonToCreateImg: objToSendImgMapa
      };

      
      relatorios.push(relatorio);
  }

    const mergedPdfBase64 = await relatorioIntervencaoMerge(relatorios);
    if (mergedPdfBase64) {
      const blob = b64toBlob(mergedPdfBase64, 'application/pdf');
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, '_blank');
    }
  } catch (error) {
    console.log(error);
    postMessage({ type: 'errorMsg', value: { left: '50%', message: "Verifique os campos obrigatórios." } }, '*');
  } finally {
    postMessage({ type: 'hideLoader' }, '*');
  }
};

  const onItemClickImprimir = async (event: any) => {
    openPdfNewTab();
  }

  const handleLocationClick = (item: Record<string, any>) => async () => {
    const id = item.intervencao_id ?? item.id;
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
      { type: 'SiarlIntIntervencaoHighlight', value: [id] },
      '*'
    )
    
  } 

  const onItemClickImprimirDocumentos = (event: any) => {

    const link: HTMLAnchorElement | null = document.querySelector('#print-results-documentos');

    if (link) {
      link.click();
    }

    // if (event.item.key == 'ExportarListaResultados') {

      

    // }

  };

  const onItemClick = (event: any) => {

    if (event.item.key == 'ExportarFichasResultados') {

      const link: HTMLAnchorElement | null = document.querySelector('#print-results');

      if (link) {
        link.click();
      }

    } else if (event.item.key == 'ExportarListaResultados') {

      console.log(event.item.text);

    }

  };

  const options = [
    {
      key: 'ExportarFichasResultados',
      text: 'Fichas',
    },
    {
      key: 'ExportarListaResultados',
      text: 'Resultados',
    },
  ]
  const close = () => {
    setOpenForm(false);
  }  

  useLayoutEffect(() => {

    const handleResize = () => {

      if (window.innerWidth <= telaMobile) {
        
        setIsMobile(true);
        ocultaFloatingMenu_e_Mapa();

      }else{

        setIsMobile(false);
        mostraFloatingMenu_e_Mapa();

      }

    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);  


  const onItemClickMais = (event: any) => {

    elementoClickMais(event);

      onItemClickExportar(event);

      onItemClickImprimir(event);
  };

  const optionsMais = [
    {
      key: 'titulo',
      text: 'Exportar',
    },
    ...optionsExportar.map(option => ({
      ...option,
      className: 'icon-siarl icon-exportar btnIconRegistar',
    })),
  ]

  const handleNewRegistoClick = () => {
    setIntervencaoSelected(undefined);
      selectIntervencaoResult(undefined);
      setShowResultadoIntervencao(false)
      sessionStorage.setItem('novaIntervencao', 'true')
      const loggedUser = JSON.parse(
        localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}'
      )
      setIntervencaoSelected({ utilizador_id: loggedUser?.user?.name })

      if (setShowRegistarIntervencao) setShowRegistarIntervencao(true)
  }

const openAjuda = () => {
  window.open(URL_AJUDA_INTERVENCOES_INTERVENCAO, "_blank")
}

return (
  <div className={clsx(styles.base, isCurrentViewMaximized && commonStyles.maximizedBaseForm)}>
    <div className={styles.header}>
      <div>
        <div className={styles.nomeDoModulo}>Intervenções</div>
        <div className={styles.tituloDaPagina}>Resultados de pesquisa
        </div>
      </div>

      <div>

        {hasPermission(PERMISSION_INSERIR_INTERVENCAO) && (  
              hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO) ||
              hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO)
              )? 
          <Button
            onClick={handleNewRegistoClick}
            className={styles.btnNovoRegisto}
            style={{ display: isMobile ? 'none' : '' }}
            id='novoRegisto'
            title='Nova intervenção'
          >
            <span className="icon-siarl icon-registar btnIconRegistar" /> &nbsp;{' '}
            Nova intervenção
          </Button>
        :  ''}

        <CSVLink
          title="Imprimir resultados"
          id="print-results-intervencao"
          separator={';'}
          data={intervencoes?.map((intervencao: any) => ({
            ...intervencao,
          }))}
          filename={`siarl-intervencao-${format(
            new Date(),
            'ddMMyyyHHmm'
          )}`}
          hidden
        ></CSVLink>

          {!isMobile &&
        <VisualizadorColunas 
              columns={selected===0?colums:columsDocuments} 
              setColumns={selected===0?setColums:setColumsDocuments}
              styles={styles.btnEditar2}  
           />
          }

        <button className={styles.btnEditar2} onClick={onItemClickImprimir} style={{ display: isMobile ? 'none' : '' }} id='imprimirFicha' title='Imprimir'>
          <span className="icon-siarl icon-imprimir"></span>
        </button>

        {!isMobile &&
          <DropDownButton
            items={optionsExportar}
            className={styles.btnImprimir}
            style={{marginTop: '-4px'}}
            iconClass="icon-siarl icon-exportar"
            onItemClick={onItemClickExportar}
            fillMode={'flat'}
            title='Exportar'
            itemRender={itemRender}
          ></DropDownButton>
        }

          {isMobile &&
            <DropDownButton
              items={optionsMais}
              className={styles.btnMais}
              iconClass="icon-siarl icon-mais"
              onItemClick={onItemClickMais}
              fillMode={'flat'}
              itemRender={itemRenderMais}
              title="Mais Opções"
            />
          }

          {isMobile &&
            <DropDownButton
              items={optionsMais}
              className={styles.btnMais}
              iconClass="icon-siarl icon-mais"
              onItemClick={onItemClickMais}
              fillMode={'flat'}
              itemRender={itemRenderMais}
              title="Mais Opções"
            />
          }

        <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
          <span className="icon-siarl icon-ajuda"></span>
        </button>
        <button className={styles.btnFechar} onClick={onCloseClick} title="Minimizar">
          <span className="icon-siarl icon-minimizar"></span>
        </button>

        {!isMobile &&
        <MaximizeButton className={styles.btnMaximizar} />
        }
      </div>
    </div>

    <TabStrip selected={selected} onSelect={handleSelect} scrollable={isMobile}>
      <TabStripTab title="Intervenções">
      <ExcelExport
        fileName='Resultado_Intervencoes'
            
            data={
               intervencoes?.map((intervencao: any) => ({
                    ...intervencao,
                    inicio: intervencao?.inicio ? format(new Date(intervencao.inicio), 'dd/MM/yyyy') : undefined,
                    fim: intervencao?.fim ? format(new Date(intervencao.fim), 'dd/MM/yyyy') : undefined,
              }))}


            ref={(exporter) => {
              _export = exporter;
            }}
          >
          <Grid
            className={styles.form}
            sortable={true}
            onDataStateChange={dataStateChange}
            data={
              dataResult.data.length > 0
                ? dataResult.data.map((intervencao: any) => ({
                  ...intervencao,
                  inicio: intervencao?.inicio ? format(new Date(intervencao.inicio), 'dd/MM/yyyy') : undefined,
                  fim: intervencao?.fim ? format(new Date(intervencao.fim), 'dd/MM/yyyy') : undefined}))
                : intervencoes?.map((intervencao: any) => ({
                    ...intervencao,
                    inicio: intervencao?.inicio ? format(new Date(intervencao.inicio), 'dd/MM/yyyy') : undefined,
                    fim: intervencao?.fim ? format(new Date(intervencao.fim), 'dd/MM/yyyy') : undefined,
            })).slice(page.skip, page.take + page.skip)
          }
          total={intervencoes.length}
          
            {...dataState}
            pageable={{
              buttonCount: 5,
              info: true,
              type: 'numeric',
              pageSizes: true,
              previousNext: true,
            }}
            //groupable={true}
          >
            <GridColumn cell={intervencaoCustomCell} width={60} />
            {colums?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
          </Grid>
          </ExcelExport>
        
        <GridPDFExport  
          fileName='Resultado_Intervencao'
          ref={(element) => {
            _pdfExport = element
          }}
          paperSize={['350mm', '297mm']}         
          margin="1cm"
        >
          <Grid
            className={styles.form}
            sortable={true}
            onDataStateChange={dataStateChange}
            data={
              dataResult.data.length > 0
                ? dataResult
                : intervencoes?.map((intervencao: any) => ({
                    ...intervencao,
                    inicio: intervencao?.inicio ? format(new Date(intervencao.inicio), 'dd/MM/yyyy') : undefined,
                    fim: intervencao?.fim ? format(new Date(intervencao.fim), 'dd/MM/yyyy') : undefined,
            }))
          }
          total={intervencoes.length}
          {...dataState}
            pageable={{
              buttonCount: 5,
              info: true,
              type: 'numeric',
              pageSizes: true,
              previousNext: true,
            }}
            //groupable={true}
          >
              <GridColumn cell={intervencaoCustomCell} width={30} />
              {colums?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
          </Grid>
        </GridPDFExport>

        
      </TabStripTab>

      <TabStripTab title="Documentos">
        <ExcelExport
          fileName='Resultado_DocumentosIntervencoes'
          data={documentosIntervencoes}
          ref={(exporter) => {
            _export = exporter
          }}
        >
          
          <Grid
            className={styles.form}
            sortable={true}
            onDataStateChange={dataStateChangeDocumentos}
            data={dataResultDocumentos.data.length > 0 ? dataResultDocumentos : documentosIntervencoes.slice(page.skip, page.take + page.skip)}
            {...dataState}
            pageable={{
              buttonCount: 5,
              info: true,
              type: 'numeric',
              pageSizes: true,
              previousNext: true,
            }}
            //groupable={true}
            total={documentosIntervencoes.length}

          >
            <GridColumn cell={documentsCustomCell} width={86} />
            {columsDocuments?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
          </Grid>
        </ExcelExport>

        <GridPDFExport
          fileName='Resultado_Documentos_Intervencao'
          ref={(element) => {
            _pdfExport = element
          }}
          margin="1cm"
        >
          <Grid
            className={styles.form}
            sortable={true}
            onDataStateChange={dataStateChangeDocumentos}
            data={
              dataResultDocumentos.data.length > 0
                ? dataResultDocumentos
                : documentosIntervencoes
            }
            {...dataState}
            pageable={{
              buttonCount: 5,
              info: true,
              type: 'numeric',
              pageSizes: true,
              previousNext: true,
            }}
            //groupable={true}
          >
           {columsDocuments?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
          </Grid>
        </GridPDFExport>
      </TabStripTab>
    </TabStrip>
    {openForm && (
        <Dialog
        className="ModalDocumentos"
          title={`Documento: ${idDoDocumento}`}
          onClose={close}
          style={{ zIndex: 999 }}
        >
          
            <ImagePreviewV2
              documentUrl={urlDoDocumento}
              next={(e: any) => nextPhoto(e)}
              previous={(e: any) => previousPhoto(e)}
            />

        </Dialog>
      )}
    <div
      className={clsx(styles.footer, styles.justify_one_child, isCurrentViewMaximized && commonStyles.maximizedFooter)}
    >
      <Button className={styles.btnPesquisar} onClick={clearSearchResults} title='Nova Pesquisa'>
        <span className="icon-siarl icon-pesquisar"></span>
        <span className={styles.rotulo}>
          {' '}&nbsp;{' '}
          Nova pesquisa
        </span>
      </Button>
    </div>
  </div>
)
}