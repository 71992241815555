
import { Button, DropDownButton } from '@progress/kendo-react-buttons'
import { ComboBox } from '@progress/kendo-react-dropdowns'
import { Field, Form, FormElement, FormRenderProps, FormSubmitClickEvent } from '@progress/kendo-react-form'
import { Input, RadioButton, RadioButtonChangeEvent } from '@progress/kendo-react-inputs'
import { MouseEvent as ReactMouseEvent, useCallback, useEffect, useLayoutEffect, useState } from 'react'
import SessionStorageService from '../../services/SessionStorageService'
import { arhs, capitania, concelhos, coordenadasPoligono, distritos, entidades, estados, fillArhsAsync, fillCapitaniaAsync, fillCombosAuxiliares, fillConcelhosAsync, fillDistritosAsync, fillEntidadesAsync, fillEstadoAsync, fillFreguesiasAsync, fillModelosSubTiposAsync, fillModelosTiposAsync, fillNuts1Async, fillNuts2Async, fillNuts3Async, freguesias, handleGenericError, hasPermission, modelosSubtipos, modelosTipos, nuts1, nuts2, nuts3, post, setCoordenadasPoligono, setModeloSelected, useCombos } from '../../services/utils'
import { useModeloStore } from '../../stores/modelo'
import styles from '../../styles/modelos/ConsultaModeloSimplesForm.module.scss'
import { PERMISSION_MODELO_PERFIL_COMPLETO_EDICAO, PERMISSION_MODELO_PERFIL_COMPLETO_LEITURA, PERMISSION_MODELO_PERFIL_TOTAL, PESQUISAR_MODELOS, URL_AJUDA_MODELOS } from '../../utils/constants'
import FieldsetCombosLocalizacao from '../localizacao/FieldsetCombosLocalizacao'
import { filterBy } from '@progress/kendo-data-query'
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais'
import ReactGA from 'react-ga4';

type Props = {
  onCloseClick?: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
}

export default function ConsultaModeloSimplesForm({
  onCloseClick,
}: Props) {
  const [isMobile, setIsMobile] = useState(false);
  const [registoVisible, setRegistoVisible] = useState(true)
  const [identificacaoVisible, setIdentificacaoVisible] = useState(false)
  const [localizacaoVisible, setLocalizacaoVisible] = useState(false)
  const [CritGeograficosVisible, setCritGeograficosVisible] = useState(false)
  const [tipologiaVisible, setTipologiaVisible] = useState(false)
  const [rbCriteriosSelected, setRBCriteriosSelected] = useState<any>()

  const [subtipoModelosFiltered, setSubtipoModelosFiltered] = useState<any>([])
  const [freguesiasFiltered, setFreguesiasFiltered] = useState<any>([])
  const [concelhosFiltered, setConcelhosFiltered] = useState<any>([])
  const [nuts2Filtered, setNuts2Filtered] = useState<any>([])
  const [nuts3Filtered, setNuts3Filtered] = useState<any>([])

  type combosKeys = 'entidades'|'estados'|'modelosTipos' |'modelosSubtipos';

  const [combos, setCombos] = useState<any>({
    entidades,
    estados,
    modelosTipos,
    modelosSubtipos
  })
  const setDataOption: Record<combosKeys, { setFn: any, data: any[] }> = {
    entidades: { setFn: setCombos, data: entidades },
    estados: { setFn: setCombos, data: estados },
    modelosTipos: { setFn: setCombos, data: modelosTipos },
    modelosSubtipos: { setFn: setCombos, data: subtipoModelosFiltered }
  };

  const filterCombo_Multiselect = (e: any, tipoCombo: combosKeys) => {
    const { setFn, data } = setDataOption[tipoCombo];
      setFn({
          ...combos,
          [tipoCombo]: filterBy(data?.slice()??[], e.filter)
      }
      );
}

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/ConsultarModelos" });
  }, []);

   useEffect(() => {
    
     if(!nuts1 ){
      fillNuts1Async();
      }
     if(!nuts2) {
      fillNuts2Async();
     }
     if(!nuts3){
      fillNuts3Async();
     }
     if(!distritos){
      fillDistritosAsync();
     }
     if(!concelhos){
      fillConcelhosAsync();
     }
     if(!freguesias){
      fillFreguesiasAsync();
     }
     if(!arhs){
      fillArhsAsync();
     }
     if(!capitania){
      fillCapitaniaAsync();
     }
     if(!estados){
      fillEstadoAsync();
     }
     if(!entidades){
      fillEntidadesAsync();
     }
     if(!modelosTipos){
      fillModelosTiposAsync();
     }
     if(!modelosSubtipos){
      fillModelosSubTiposAsync();
     }

  }, []);

  const getModelos = async (data: any) => await post<any, any>(`${PESQUISAR_MODELOS}`, data)
  const selectModeloResultId = useModeloStore(
    (state) => state.selectModeloResultId
  )


  const AREA_VISIVEL = 1;
  const POLIGONO_SELECAO = 2;
  const selectModeloResult = useModeloStore(
    (state) => state.selectModeloResult
  )
  const [designacoes, setDesignacoes] = useState([
    { name: "D1", id: 1 },
    { name: "D2", id: 2 },
    { name: "D3", id: 3 }
  ]);

  const clearMap = () => {
    try {

      setCoordenadasPoligono(undefined)
      const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.contentWindow?.postMessage({ type: "SiarlModelosClearLayers", value: "true" }, '*')
      iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: "true" }, '*')
      iframe.contentWindow?.postMessage({ type: "SiarlDeselectMapArea", value: "true" }, '*')

    } catch (error) {
      console.log({ error })
    }
  }

  const changerbCriterio = useCallback(
    (e: RadioButtonChangeEvent) => {
      const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlDeselectMapArea", value: 'true' }, '*')
      setRBCriteriosSelected(e.value)
      if (e.value == POLIGONO_SELECAO) {
        postMessage({ type: "informationMsg", value: "Selecione a área de pesquisa" }, "*");
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage({ type: "SiarlGeoPortalAdicionarArea", value: 'true' }, '*')
      }
      if (e.value == AREA_VISIVEL) {
        postMessage({ type: "informationMsg", value: "Foi selecionada a área visível do mapa" }, "*");
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage({ type: "SiarlGetMapArea", value: 'true' }, '*')
        iframe.contentWindow?.postMessage({ type: "SiarlSelectMapArea", value: 'true' }, '*')
      }
    },
    [setRBCriteriosSelected]
  )

  const handleSubmit = async (e: FormSubmitClickEvent) => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlModelosClearLayers", value: "true" }, '*')
    console.log(JSON.stringify(e.values));

    try {

      postMessage({ type: 'showLoader' }, '*')
      const objToSearch = {
        id: e.values?.id,
        estado: e.values?.estadoDoRegisto?.designacao,
        descricao: e.values?.descricao,
        designacao: e.values?.descricao?.name,
        entidade_id: e.values?.entidade?.id,
        nuts1_cod: e.values?.nuts1?.codigo,
        nuts2_cod: e.values?.nuts2?.codigo,
        nuts3_cod: e.values?.nuts3?.codigo,
        distrito_cod: e.values?.distrito?.codigo,
        concelho_cod: e.values?.concelho?.codigo,
        arh_cod: e.values?.arh?.codigo,
        capitania_id: e.values?.capitania?.id,
        massa_agua_id: e.values?.massaAgua?.id ?? undefined,
        // tipoModelo: e.values?.tipoModelo.id,
        mo_modelo_subtipo_id: e.values?.subtipoModelo?.id,
        mo_modelo_tipo_id: e.values?.modeloTipo?.id,
        criterio_geografico: rbCriteriosSelected ?? null,
        flatCoordinates: coordenadasPoligono
      }
      
    SessionStorageService.setItem("lastSearchModelos", objToSearch);
    SessionStorageService.setItem("firstCallModelos", true);

    const pesquisarModelosResult: any = await getModelos(objToSearch);


      setCoordenadasPoligono(undefined);
      if (pesquisarModelosResult) {
        const ids: number[] = [];
        pesquisarModelosResult.forEach((element: any) => {
          ids.push(element.id);
        });
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage({ type: "SiarlModelosSearch", value: ids }, '*')

        postMessage({ type: 'resultadoDocumentosPesquisaChange' }, '*')
        SessionStorageService.setItem(
          'resultadoModelosPesquisa',
          JSON.stringify(pesquisarModelosResult)
        )

        postMessage({ type: 'resultadoModelosPesquisaChange' }, '*');
      }
    } catch (error) {
      console.log(error)
      handleGenericError(error);
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }

  }
  const registarNovoClick = () => {
    selectModeloResult(undefined);
    selectModeloResultId(undefined);
    setModeloSelected(undefined);
    SessionStorageService.setItem("novoModelo", 'true');
    postMessage({ type: "form", value: "registarModelos" }, "*");

  }
  const onTipoModeloChange = (e: any) => {
    setSubtipoModelosFiltered([]);
    const tipoModelo = e?.value;
    if (tipoModelo) {
      setSubtipoModelosFiltered(modelosSubtipos.filter((sbm: { mo_modelo_tipo_id: any }) => sbm.mo_modelo_tipo_id === tipoModelo.id))
    }
  }
  const filterConcelho = (e: any) => {
    setConcelhosFiltered([]);
    if (e?.value?.codigo) {
      const concelhosLocal = concelhos.filter((x: any) => x.distrito_cod == e.value.codigo);
      setConcelhosFiltered(concelhosLocal);
    }
  }
  const filterFreguesia = (e: any) => {
    setFreguesiasFiltered([]);
    if (e?.value?.codigo) {
      const freguesiaLocal = freguesias.filter((x: any) => x.concelho_cod == e.value.codigo);
      setFreguesiasFiltered(freguesiaLocal);
    }
  }
  const filterNuts2 = (e: any) => {
    setNuts2Filtered([])
    if (e?.value?.codigo) {
      setNuts2Filtered(nuts2?.filter((nts: any) => nts?.nuts_i_cod === e?.value?.codigo))
    }
  }
  const filterNuts3 = (e: any) => {
    setNuts3Filtered([])
    if (e?.value?.codigo) {
      setNuts3Filtered(nuts3?.filter((nts: any) => nts?.nuts_ii_cod === e?.value?.codigo))
    }
  }

  useLayoutEffect(() => {

    const handleResize = () => {

      if (window.innerWidth <= telaMobile) {
        
        setIsMobile(true);
        ocultaFloatingMenu_e_Mapa();

      }else{

        setIsMobile(false);
        mostraFloatingMenu_e_Mapa();

      }

    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  const onItemClickMais = (event: any) => {

    elementoClickMais(event);

  };

  const optionsMais = [
    {
      key: 'novoRegisto',
      text: 'Novo Modelo',
      elementType: 'button',
      className: 'icon-siarl icon-registar btnIconRegistar'
    }
  ]

  const openAjuda = () => {
    window.open(URL_AJUDA_MODELOS, "_blank")
  }

  return (
    <div className={styles.base}>

      <div className={styles.header}>

        <div>
          <div className={styles.nomeDoModulo}>
            Modelos
          </div>
          <div className={styles.tituloDaPagina}>
            Critérios de pesquisa
          </div>
        </div>

        <div>
        {hasPermission(PERMISSION_MODELO_PERFIL_TOTAL)
        ?
          <Button onClick={registarNovoClick} className={styles.btnNovoRegisto}
          style={{ display: isMobile ? 'none' : '' }}
          id='novoRegisto'
          title='Novo Modelo'
          >
            <span className="icon-siarl icon-registar btnIconRegistar"></span>{' '}
            &nbsp;{' '}
            Novo Modelo
          </Button>:<></>}

          {isMobile &&
            <DropDownButton
              items={optionsMais}
              className={styles.btnMais}
              iconClass="icon-siarl icon-mais"
              onItemClick={onItemClickMais}
              fillMode={'flat'}
              itemRender={itemRenderMais}
              title="Mais Opções"
            />
          }

          <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
            <span className="icon-siarl icon-ajuda"></span>
          </button>

          <button className={styles.btnFechar} onClick={onCloseClick} title="Minimizar">
            <span className="icon-siarl icon-minimizar"></span>
          </button>

        </div>
      </div>

      <Form
        onSubmitClick={handleSubmit}
        render={(formRenderProps: FormRenderProps) => (
          <>
            <FormElement className={styles.form}>

              <fieldset>

                <legend>
                  <button type='button' className={styles.itemExpandBase} onClick={() => { setRegistoVisible(!registoVisible) }}>
                    <div className={styles.btn_Expandir_Colapsar}>
                      <span className={registoVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                    </div>
                    <span className={styles.fieldsetTitulo}>Registo</span>
                  </button>
                </legend>

                {registoVisible && (
                  <div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}>
                  <div>
                   <Field name="id" label="ID" component={Input} />
                 </div>
                    <div>
                      <Field
                        name="estadoDoRegisto"
                        label="Estado do Registo"
                        component={ComboBox}
                        textField={'designacao'}
                        filterable={true}
                        onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'estados')}}
                        data={combos?.estados}
                      />
                    </div>

                  </div>
                )}
              </fieldset>

              {hasPermission(PERMISSION_MODELO_PERFIL_TOTAL) || hasPermission(PERMISSION_MODELO_PERFIL_COMPLETO_LEITURA)?
              <fieldset>

                <legend>
                  <button type='button' className={styles.itemExpandBase} onClick={() => { setIdentificacaoVisible(!identificacaoVisible) }}>
                    <div className={styles.btn_Expandir_Colapsar}>
                      <span className={identificacaoVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                    </div>
                    <span className={styles.fieldsetTitulo}>Identificação</span>
                  </button>
                </legend>
                {identificacaoVisible && (

                  <div className={styles.espacoVerticalFieldSet}>
                    <div>
                      <Field
                        name="designacao"
                        label="Designação"
                        component={Input}
                      />
                    </div>
                    <div>
                      <Field
                        name="entidade"
                        label="Entidade responsável"
                        component={ComboBox}
                        textField={'nome'}
                        filterable={true}
                        onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'entidades')}}
                        data={combos?.entidades}
                      />
                    </div>
                  </div>
                )}
              </fieldset>:<></>}

              <fieldset>

                <legend>
                  <button type='button' className={styles.itemExpandBase} onClick={() => { setCritGeograficosVisible(!CritGeograficosVisible) }}>
                    <div className={styles.btn_Expandir_Colapsar}>
                      <span className={CritGeograficosVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                    </div>
                    <span className={styles.fieldsetTitulo}>Critério geográfico</span>
                  </button>
                </legend>

                {CritGeograficosVisible && (

                  <div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}>

                    <div>
                      <RadioButton
                        name="rbCriterio"
                        label="Nenhum"
                        value={0}
                        defaultChecked
                        onChange={changerbCriterio}
                      />
                    </div>

                    <div>
                      <RadioButton
                        name="rbCriterio"
                        label="Área visível"
                        value={1}
                        onChange={changerbCriterio}
                      />
                    </div>

                    <div>
                      <RadioButton
                        name="rbCriterio"
                        label="Polígono de seleção"
                        value={2}
                        onChange={changerbCriterio}
                      />
                    </div>

                  </div>

                )}
              </fieldset>
              <FieldsetCombosLocalizacao isPesquisaAvancada={true} styles={styles} formRenderProps={formRenderProps}/>
              {hasPermission(PERMISSION_MODELO_PERFIL_TOTAL) || hasPermission(PERMISSION_MODELO_PERFIL_COMPLETO_LEITURA)?
              <fieldset>

                <legend>
                  <button type='button' className={styles.itemExpandBase} onClick={() => { setTipologiaVisible(!tipologiaVisible) }}>
                    <div className={styles.btn_Expandir_Colapsar}>
                      <span className={tipologiaVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                    </div>
                    <span className={styles.fieldsetTitulo}>Tipologia</span>
                  </button>
                </legend>

                {tipologiaVisible && (
                  <div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}>

                    <div>
                      <Field
                        name="tipoModelo"
                        label="Tipo de modelo"
                        component={ComboBox}
                        textField={'designacao'}
                        onChange={(e) => onTipoModeloChange(e)}
                        filterable={true}
                        onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'modelosTipos')}}
                        data={combos?.modelosTipos}
                      />
                    </div>

                    <div>
                      <Field
                        name="subtipoModelo"
                        label="Subtipo de Modelo"
                        component={ComboBox}
                        textField={'designacao'}
                        filterable={true}
                        onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'modelosSubtipos')}}
                        data={combos?.modelosSubtipos}                      />
                    </div>

                  </div>
                )}
              </fieldset>:<></>}

            </FormElement>
            <div className={`${styles.footer} ${styles.justify_multiple_children}`}>

              <Button
                onClick={() => { formRenderProps.onFormReset(); clearMap()}}
                className={styles.btnLimparPesquisa}
                title='Limpar pesquisa'
              >
                <span className="icon-siarl icon-limparcampos"></span>
                <span className={styles.rotulo}>
                  {' '}&nbsp;{' '}
                  Limpar pesquisa
                </span>
              </Button>
              <Button
                onClick={formRenderProps.onSubmit}
                className={styles.btnPesquisar}
                title='Pesquisar'
              >
                <span className="icon-siarl icon-pesquisar"></span>
                <span className={styles.rotulo}>
                  {' '}&nbsp;{' '}
                  Pesquisar
                </span>
              </Button>
            </div>
          </>
        )}
      />

    </div>
  )
}
