import { Button, DropDownButton } from '@progress/kendo-react-buttons'

import {
  Dispatch,
  MouseEvent as ReactMouseEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState
} from 'react'

import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from '@progress/kendo-react-layout'
import { useDhProcessoStore } from '../../stores/dhProcesso'
import styles from '../../styles/dominiohidrico/DominioHidrico.module.scss'
import PesquisarProcessoForm from './PesquisarProcessoForm'
import PesquisarPatrimonioForm from './PesquisarPatrimonioForm'
import PesquisarDemarcacaoForm from './PesquisarDemarcacaoForm'
import { useDhPatrimonioStore } from '../../stores/dhPatrimonio'
import { useDhDemarcacaoStore } from '../../stores/dhDemarcacao'
import { hasPermission, setDemarcacaoSelected, setPatrimonioSelected, setProcessoSelected } from '../../services/utils'
import { useDemarcacaoStore } from '../../stores/demarcacao'
import { usePatrimonioStore } from '../../stores/patrimonio'
import { AUTH_LOCALSTORAGE, DEMARCACAO_MODULE_NAME, PATRIMONIO_MODULE_NAME, PERMISSION_DOMINIO_HIDRICO_PERFIL_BASICO, PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_EDICAO, PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_LEITURA, PERMISSION_DOMINIO_HIDRICO_PERFIL_INTERMEDIO_LEITURA, PERMISSION_DOMINIO_HIDRICO_PERFIL_TOTAL, PERMISSION_LISTAR_DH_DEMARCACAO, PERMISSION_LISTAR_DH_PATRIMONIO, PERMISSION_LISTAR_DH_PROCESSO, PROCESSO_MODULE_NAME, URL_AJUDA_DOMINIO_HIDRICO_DEMARCACAO, URL_AJUDA_DOMINIO_HIDRICO_PATRIMONIO, URL_AJUDA_DOMINIO_HIDRICO_PROCESSO } from '../../utils/constants'
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais'
import SessionStorageService from '../../services/SessionStorageService'

type RegistoButtonLabel =
  | 'Novo processo'
  | 'Novo património'
  | 'Nova demarcação'

type Props = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined,
  setShowRegistoPatrimonio?: Dispatch<SetStateAction<boolean>>
  setShowRegistarDemarcacao?: Dispatch<SetStateAction<boolean>>
  setShowResults: Dispatch<SetStateAction<boolean>>
  setShowRegistarProcessoForm?: Dispatch<SetStateAction<boolean>>


}

export default function PesquisarDominioHidricoTabs({ onCloseClick, setShowRegistoPatrimonio, setShowRegistarDemarcacao, setShowRegistarProcessoForm, setShowResults }: Props) {
  const [isMobile, setIsMobile] = useState(false);
  const [selected, setSelected] = useState<number>(0)
  const [newRegistoLabel, setNewRegistoLabel] = useState<string>('Novo processo')
  const [isMaximized, setIsMaximized] = useState(false)
  const selectProcessoResult = useDhProcessoStore((state) => state.selectDhProcessoResult)
  const selectPatrimonioResult = useDhPatrimonioStore((state) => state.selectDhPatrimonioResult)
  const selectDemarcacaoResult = useDhDemarcacaoStore((state) => state.selectDhDemarcacaoResult)
  const PROCESSO = 0;
  const PATRIMONIO = 1;
  const DEMARCACAO = 2;
  const handleSelect = (e: TabStripSelectEventArguments) => {
    if (e.selected == PROCESSO) {
      setNewRegistoLabel("Novo processo");
    }
    else if (e.selected == PATRIMONIO ) {
      setNewRegistoLabel("Novo património");
    }
    else if (e.selected == DEMARCACAO  ) {
      setNewRegistoLabel("Nova demarcação");
    }
    setSelected(e.selected)
  }

  const selectProcessoResultId = useDhProcessoStore((state) => state.selectDhProcessoResultId)
  const selectDemarcacaoResultId = useDemarcacaoStore((state) => state.selectDemarcacaoResultId)
  const selectPatrimonioResultId = usePatrimonioStore((state) => state.selectPatrimonioResultId)
  const [seePatrimonio, setSeePatrimonio] = useState(false);
  const [seeProcesso, setSeeProcesso] = useState(false);
  const [seeDemarcacao, setSeeDemarcacao] = useState(false);

  const canSeeDominioHidrico = hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_BASICO) ||
                            hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_LEITURA) ||
                            hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_EDICAO) ||
                            hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_TOTAL) 

  const canSeeDominioHidricoEdit = hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_EDICAO) || hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_TOTAL);



  const handleNewRegistoClick = useCallback(() => {
    if (selected === DEMARCACAO) {
      setDemarcacaoSelected(undefined);
      selectDemarcacaoResult(undefined);
      setShowResults(false)
      sessionStorage.setItem('novaDemarcacao', 'true')
      const loggedUser = JSON.parse(
        localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}'
      )
      setDemarcacaoSelected({ tecnicoRegisto: loggedUser?.user?.name })
      if (setShowRegistarDemarcacao)
        setShowRegistarDemarcacao(true)
    }

    if (selected === PATRIMONIO) {
      setPatrimonioSelected(undefined);
      selectPatrimonioResult(undefined);
      setShowResults(false)
      sessionStorage.setItem('novoPatrimonio', 'true')

      if (setShowRegistoPatrimonio)
        setShowRegistoPatrimonio(true)

    }

    if (selected === PROCESSO) {
      selectProcessoResult(undefined);
      setProcessoSelected(undefined);
      setShowResults(false);
      selectProcessoResultId(undefined)
      sessionStorage.setItem('novoProcesso', 'true')


      if (setShowRegistarProcessoForm) 
      setShowRegistarProcessoForm(true);

    }
  }, [newRegistoLabel])

  useEffect(() => {
    setSeeDemarcacao(hasPermission(PERMISSION_LISTAR_DH_DEMARCACAO))
    setSeePatrimonio(hasPermission(PERMISSION_LISTAR_DH_PATRIMONIO))
    setSeeProcesso(hasPermission(PERMISSION_LISTAR_DH_PROCESSO))
  }, []);

  const openAjuda = () => {
    if (SessionStorageService.getItem("DominioHidricoTipoPesquisa")) {
      const tipoPesquisaDH = SessionStorageService.getItem("DominioHidricoTipoPesquisa")
      if (tipoPesquisaDH == PROCESSO_MODULE_NAME) {
        window.open(URL_AJUDA_DOMINIO_HIDRICO_PROCESSO, "_blank")
      } else if (tipoPesquisaDH == PATRIMONIO_MODULE_NAME) {
        window.open(URL_AJUDA_DOMINIO_HIDRICO_PATRIMONIO, "_blank")
      } else if (tipoPesquisaDH == DEMARCACAO_MODULE_NAME) {
        window.open(URL_AJUDA_DOMINIO_HIDRICO_DEMARCACAO, "_blank")
      }

    }
  }

  useLayoutEffect(() => {

    const handleResize = () => {

      if (window.innerWidth <= telaMobile) {
        
        setIsMobile(true);
        ocultaFloatingMenu_e_Mapa();

      }else{

        setIsMobile(false);
        mostraFloatingMenu_e_Mapa();

      }

    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  const onItemClickMais = (event: any) => {

    elementoClickMais(event);

  };

  
  const removeSessionPatrimonio = () => {

    SessionStorageService.removeItem("lastSearchPatrimonio")
  };

  const removeSessionDemarcacao = () => {

    sessionStorage.removeItem("lastSearchDemarcacao")
  };
  const optionsMais = [
    {
      key: 'novoRegisto',
      text: newRegistoLabel,
      elementType: 'button',
      className: 'icon-siarl icon-registar btnIconRegistar'
    }
  ]

  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <div>
          <div className={styles.nomeDoModulo}>Domínio Hídrico</div>
          <div className={styles.tituloDaPagina}>Pesquisa</div>
        </div>

        <div>
       { canSeeDominioHidricoEdit &&
          <Button
            onClick={handleNewRegistoClick}
            className={styles.btnNovoRegisto}
            style={{ display: isMobile ? 'none' : '' }}
            id='novoRegisto'
            title={newRegistoLabel}
          >
            <span className="icon-siarl icon-registar btnIconRegistar" /> &nbsp;{' '}
            {newRegistoLabel}
          </Button>
      }
          {isMobile &&
            <DropDownButton
              items={optionsMais}
              className={styles.btnMais}
              iconClass="icon-siarl icon-mais"
              onItemClick={onItemClickMais}
              fillMode={'flat'}
              itemRender={itemRenderMais}
              title="Mais Opções"
            />
          }

          <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
            <span className="icon-siarl icon-ajuda" />
          </button>

          <button className={styles.btnFechar} onClick={onCloseClick} title="Minimizar">
            <span className="icon-siarl icon-minimizar" />
          </button>
        </div>
      </div>
      <TabStrip selected={selected} className="backgroundWhite" onSelect={handleSelect}
      scrollable={isMobile}>
        {seeProcesso || canSeeDominioHidrico ? 
        <TabStripTab title="Processos" key="Processos">
          <PesquisarProcessoForm onCloseClick={onCloseClick} />
        </TabStripTab>
        : ''}
        {seePatrimonio || canSeeDominioHidrico ? 
        <TabStripTab title="Património"  key="Património">
          <PesquisarPatrimonioForm onClick={removeSessionPatrimonio} />
        </TabStripTab>
        : ''}
        {seeDemarcacao || canSeeDominioHidrico ? 
        <TabStripTab title="Demarcação"  key="Demarcação">
          <PesquisarDemarcacaoForm  onClick={removeSessionDemarcacao}/>
        </TabStripTab>
        : ''}
      </TabStrip>
    </div>
  )
}
