import { DatePicker, DateTimePicker, MultiViewCalendar, MultiViewCalendarProps } from '@progress/kendo-react-dateinputs'
import { ComboBox } from '@progress/kendo-react-dropdowns'
import { Field } from '@progress/kendo-react-form'
import { Input, TextArea } from '@progress/kendo-react-inputs'
import { MouseEvent as ReactMouseEvent, useEffect, useState } from 'react'

import {
  distritos,
  entidadeUser,
  estadoCandidatura,
  fillCombosAuxiliares,
  fillEstadoCandidaturaAsync,
  fillInstrumentoFinanciamentoAsync,
  fillTipoIncidenciaAsync,
  freguesias,
  get,
  getCandidaturaSelected,
  instrumentoFinanciamento,
  instrumentoPlaneamento,
  nuts1,
  setCandidaturaSelected,
  tipoIncidencia,
  useCombos
} from '../../services/utils'
import styles from '../../styles/intervencao/Intervencao.module.scss'
import { filterBy } from '@progress/kendo-data-query'
import { PESQUISAR_ENTIDADEBYUSER } from '../../utils/constants'

type IProps = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined,
  formRenderProps?: any,
  viewMode?: boolean
}



export default function IdetificacaCandidatura({ onCloseClick, formRenderProps, viewMode }: IProps) {
 
 const getEntidadeByUser = async (data: any) =>
    await get<any, any>(`${PESQUISAR_ENTIDADEBYUSER}/` + data)

useEffect(() => {

     if(!estadoCandidatura)
     {
      fillEstadoCandidaturaAsync();
     }
     if(!tipoIncidencia )
      {
      fillTipoIncidenciaAsync();
      }

      if (getCandidaturaSelected()?.id) {
        formRenderProps.onChange('inv_previsto_total', { value: getCandidaturaSelected()?.inv_previsto_total });
        formRenderProps.onChange('inv_comp_total', { value: getCandidaturaSelected().inv_comp_total });
        formRenderProps.onChange('inv_nacional_total', { value: getCandidaturaSelected().inv_nacional_total });
        formRenderProps.onChange('data_hora_registo', { value: getCandidaturaSelected().data_hora_registo ? new Date(getCandidaturaSelected().data_hora_registo) : new Date() });
        
        if(getCandidaturaSelected()?.utilizador) {
          getEntidadeByUser(getCandidaturaSelected()?.utilizador?.id).then(resultEntidade => {
            console.log(resultEntidade);
            formRenderProps.onChange('entidade_registo', { value: resultEntidade?.nome });
          });
        } 
      }
      else {
        formRenderProps.onChange('entidade_registo', { value: entidadeUser });
      }

      

  }, []);


  type combosKeys = 'estadoCandidatura'
  | 'tipoIncidencia' | 'instrumentoFinanciamento';

  const [combos, setCombos] = useState<any>({
    estadoCandidatura, 
    tipoIncidencia,
    instrumentoFinanciamento
  })
  
  const setDataOption: Record<combosKeys, { setFn: any, data: any[] }> = {
    estadoCandidatura: {
      setFn: setCombos,
      data: estadoCandidatura
    },
    tipoIncidencia: {
      setFn: setCombos,
      data: tipoIncidencia
    },
    instrumentoFinanciamento: {
      setFn: setCombos,
      data: instrumentoFinanciamento
    }
  };

  const filterCombo_Multiselect = (e: any, tipoCombo: combosKeys) => {
    const { setFn, data } = setDataOption[tipoCombo];
      setFn({
          ...combos,
          [tipoCombo]: filterBy(data?.slice()??[], e.filter)
      }
      );
}

const changeFileDoc = (e: any) => {
  setCandidaturaSelected({...getCandidaturaSelected(), filedoc_cod: e?.value})
}

const CustomCalendar = (props: MultiViewCalendarProps) => {
  return <MultiViewCalendar {...props} views={1} />
}
const keyPressTextArea = (e: any) => {
  if (viewMode) {
    e.preventDefault();
    return false;
  }
}

  return (
    <div className={`${styles.form} ${styles.removeScrollDuplicado}`}>
      <fieldset style={{paddingBottom: '10px'}}>
        <legend>
          <span className={styles.fieldsetTitulo}>Registo</span>
        </legend>
        <div className={styles.espacoVerticalFieldSet}>
        <div className={styles.fields}>
            <div>
              <Field name="id" label="ID (Código)" component={Input} disabled />
            </div>
            <div>
              <Field name="filedoc_cod" onChange={changeFileDoc} label="Nº Processo APA" component={Input} disabled={viewMode} />
            </div>
            <div>
            <Field
              name="estado_candidatura_id"
              label="Estado"
              component={ComboBox}
              textField={'descricao'}
              filterable={true}
              onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'estadoCandidatura')}}
              data={combos?.estadoCandidatura}
              disabled={viewMode}
  />
          </div>
          </div>
          </div>
          <div className={styles.fields}>
          <div>
              <Field
                name="data_hora_registo"
                label="Data registo"
                component={DateTimePicker}
                disabled
              />
            </div>
            <div>
            <Field
                name="utilizador_id"
                label="Técnico Registo"
                disabled
                component={Input}
              />
            </div>
            <div>
            <Field
              name="entidade_registo"
              label="Entidade"
              disabled
              component={Input}
            />
            </div>
          </div>
        </fieldset>
      <fieldset >
        <legend>
          <span className={styles.fieldsetTitulo}>Identificação</span>
        </legend>

        <div className={styles.espacoVerticalFieldSet}>
          
          <div className={styles.fields}>
          <div>
              <Field
                name="cod_candid"
                label="N.º de candidatura"
                component={Input}
                disabled={viewMode}
              />
              </div>
              <div>
              <Field
                name="inv_previsto_total"
                label="Investimento Previsto"
                component={Input}
                disabled
              />
              </div>
            </div>
          <div className={styles.fields}>
          <div>
              <Field
                name="data_registo"
                label="Data registo"
                format="dd/MM/yyyy"
                component={DatePicker}
                calendar={CustomCalendar}
                
              />
            </div>
            <div>
              <Field
                name="inv_comp_total"
                label="Investimento Comunitario"
                component={Input}
                disabled
              />
              </div>
          </div>
       <div className={styles.fields}>
       <div>
              <Field
                name="data_submissao"
                label="Data submissão"
                format="dd/MM/yyyy"
                component={DatePicker}
                disabled={viewMode}
                calendar={CustomCalendar}
                />
            </div>
            <div>
              <Field
                name="inv_nacional_total"
                label="Investimento Nacional"
                component={Input}
                disabled
              />
              </div>
       </div>
        <div className={styles.espacoVerticalFieldSet}>
              <Field name="designacao" label="Designação" component={Input} disabled={viewMode}/>
           </div>
           </div>

      </fieldset>
      <fieldset>
        <legend>
          <span className={styles.fieldsetTitulo}>Tipologia</span>
        </legend>

        <div className={styles.espacoVerticalFieldSet}>
            <div>
              <Field
                name="id_tipo_in"
                label="Tipo de Inicidencia"
                component={ComboBox}
                textField={'descricao'}
                filterable={true}
                onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'tipoIncidencia')}}
                data={combos?.tipoIncidencia}            
                disabled={viewMode}
              />
            </div>
          </div>
      </fieldset>
      <fieldset>
        <legend>
          <span className={styles.fieldsetTitulo}>
            Instrumento de financiamento
          </span>
        </legend>
        <div className={styles.espacoVerticalFieldSet}>
          <div className={styles.fields}>
            <div>
              <Field
                name="instr_financiamento_id"
                label="Instrumento de financiamento"
                component={ComboBox}
                textField={'designacao'}
                filterable={true}
                onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'instrumentoFinanciamento')}}
                data={combos?.instrumentoFinanciamento}  
                disabled={viewMode}
              />
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset>
      <legend>
          <span className={styles.fieldsetTitulo}>
            Observações
          </span>
        </legend>
        <div>
          <Field
          name="obs"
          label="Observações"
          placeHolder='Observações gerais'
          style={{opacity: viewMode ? '0.7' : '1'}}
          onKeyPress={keyPressTextArea}
          component={TextArea}
          />
        </div>
      </fieldset>
    </div>
  )
}
