import { create } from 'zustand'

type ValorUsoDoSoloState = {
    selectedValorUsoDoSoloResult?: unknown
    selectValorUsoDoSoloResult: (payload: unknown) => void
    selectedValorUsoDoSoloResultId?: number
    selectValorUsoDoSoloResultId: (payload?: number) => void
    identificacaoTabIsValid: boolean
    selectValorUsoDoSoloIdentificacaoTabIsValid: (payload: unknown) => void
    detalheTabIsValid: boolean
    selectValorUsoDoSoloDetalheTabIsValid: (payload: unknown) => void
    identificacaoTabValidationObject: Record<string, boolean>
    addIdentificacaoTabValidatedField: (payload: Record<string, boolean>) => void
    detalheTabValidationObject: Record<string, boolean>
    addDetalheTabValidatedField: (payload: Record<string, boolean>) => void
}

export const useValorUsoDoSoloStore = create<ValorUsoDoSoloState>((set, get) => ({
    selectedValorUsoDoSoloResult: null,
    identificacaoTabIsValid: false,
    detalheTabIsValid: true,
    identificacaoTabValidationObject: {},
    detalheTabValidationObject: {},
    selectValorUsoDoSoloResult: (payload: unknown) =>
        set({ selectedValorUsoDoSoloResult: payload }),
    selectValorUsoDoSoloResultId: (payload?: number) =>
        set({ selectedValorUsoDoSoloResultId: payload }),
    selectValorUsoDoSoloIdentificacaoTabIsValid: (payload?: any) =>
        set({ identificacaoTabIsValid: payload }),
    selectValorUsoDoSoloDetalheTabIsValid: (payload?: any) =>
        set({ identificacaoTabIsValid: payload }),
    addIdentificacaoTabValidatedField: (payload) => {
        const validationObj = get().identificacaoTabValidationObject
        const newValidationObj = { ...validationObj, ...payload }
        set({ identificacaoTabValidationObject: newValidationObj })
        const validationValues = Object.values(newValidationObj)
        set({ identificacaoTabIsValid: validationValues.every(value => value === true) })
    },
    addDetalheTabValidatedField: (payload) => {
        const validationObj = get().detalheTabValidationObject
        const newValidationObj = { ...validationObj, ...payload }
        set({ detalheTabValidationObject: newValidationObj })
        const validationValues = Object.values(newValidationObj)
        set({ detalheTabIsValid: validationValues.every(value => value === true) })
    }
}))
