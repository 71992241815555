import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form'
import { useEffect, useState } from 'react'
import {
  arhs,
  blobToBase64,
  capitania,
  concelhos,
  distritos,
  entidades,
  estados,
  fillArhsAsync,
  fillCapitaniaAsync,
  fillConcelhosAsync,
  fillDistritosAsync,
  fillEntidadesAsync,
  fillEstadoAsync,
  fillFreguesiasAsync,
  fillNuts1Async,
  fillNuts2Async,
  fillNuts3Async,
  freguesias,
  get,
  getDocumentoSelected,
  hasPermission,
  massaagua,
  nuts1,
  nuts2,
  nuts3,
  post,
  put,
  setDocumentoSelected,
  tipoacesso,
  tiporecurso,
  useCombos
} from '../../../services/utils'
import styles from '../../../styles/documentos/Documentos.module.scss'
import {  requiredFieldValidator } from '../../../validations/ocorrencia'
import ValidatedIdentificacaoComboBox from './fields/ValidatedIdentificacaoComboBox'
import ValidatedIdentificacaoInput from './fields/ValidatedIdentificacaoInput'

import { Button } from '@progress/kendo-react-buttons'
import { DatePicker, DateTimePicker, MultiViewCalendar, MultiViewCalendarProps } from '@progress/kendo-react-dateinputs'
import { Dialog } from '@progress/kendo-react-dialogs'
import { ComboBox, MultiSelect } from '@progress/kendo-react-dropdowns'
import { Input, TextArea } from '@progress/kendo-react-inputs'
import { useDocumentosStore } from '../../../stores/documentos'
import {
  AUTH_LOCALSTORAGE,
  GET_DO_DOCUMENTO_ARH,
  GET_DO_DOCUMENTO_CAPITANIA,
  GET_DO_DOCUMENTO_CATEGORIA,
  GET_DO_DOCUMENTO_CONCELHO,
  GET_DO_DOCUMENTO_DISTRITO,
  GET_DO_DOCUMENTO_FREGUESIAS,
  GET_DO_DOCUMENTO_MASSA_AGUA,
  GET_DO_DOCUMENTO_NUTS1,
  GET_DO_DOCUMENTO_NUTS2,
  GET_DO_DOCUMENTO_NUTS3,
  IMPORT_GEOM_DOCUMENTO,
  NOT_AUTHORIZED_ERROR_STATUS_CODE,
  PERMISSION_ATUALIZAR_DOCUMENTO,
  PERMISSION_ERROR_STATUS_CODE,
  PERMISSION_INSERIR_DOCUMENTO,
  SAVE_NEW_DOCUMENTO,
  UPDATE_DOCUMENTO,
  UPLOAD_DOCUMENTO
} from '../../../utils/constants'
import CheckMoreItem from './fields/CheckMoreItem'
import { filterBy } from '@progress/kendo-data-query'
import SessionStorageService from '../../../services/SessionStorageService'
import EditDocumentoFormTabsSimplified from './documento/EditDocumentoFormTabsSimplified'

type IProps = {
  formRenderProps: FormRenderProps
  viewMode: boolean
  setNewDocumento: any
  newDocumento: any
}
export function DocumentoIdentificacao({ formRenderProps, viewMode, newDocumento, setNewDocumento}: IProps) {
  const [openImportModal, setOpenImportModal] = useState<any>()
  const selectedDocumentosResultId = useDocumentosStore(
    (state) => state.selectedDocumentosResultId
  )
  const selectedDocumentosResult: any = useDocumentosStore(
    (state) => state.selectedDocumentosResult
  )

  const importGeomDocumento = async (data: any) =>
    await post<any, any>(`${IMPORT_GEOM_DOCUMENTO}`, data)
  const [freguesiasFiltred, setFreguesiasFiltred] = useState<any>()
  const [concelhosFiltred, setConcelhosFiltred] = useState<any>()
  const [concelhosDisabled, setConcelhosDisabled] = useState<any>(true)
  const [freguesiasDisabled, setFreguesiasDisabled] = useState<any>(true)
  const [freguesiaSelected, setFreguesiaSelected] = useState<any>()
  const [distritoSelected, setDistritoSelected] = useState<any>()
  const [concelhoSelected, setConcelhoSelected] = useState<any>()
  const [nuts1Selected, setNuts1Selected] = useState<any>();
  const [nuts2Selected, setNuts2Selected] = useState<any>();
  const [nuts3Selected, setNuts3Selected] = useState<any>();
  const [massaaguaSelected, setMassaaguaSelected] = useState<any>();
  const [arhSelectected, setArhSelected] = useState<any>()
  const [nuts2Filtred, setNuts2Filtered] = useState<any>()
  const [nuts3Filtred, setNuts3Filtered] = useState<any>()
  const [geomImported, setGeomImported] = useState<any>();
  
  const [canUpdate, setCanUpdate] = useState(false);
  useEffect(() => {
    setCanUpdate(hasPermission(PERMISSION_ATUALIZAR_DOCUMENTO));
  }, [])



   const saveDocumento = async (data: any) =>
    await post<any, any>(`${SAVE_NEW_DOCUMENTO}`, data)
  const pesquisarFreguesiasPorDocumento = async (id?: number) =>
    await get<any, any>(`${GET_DO_DOCUMENTO_FREGUESIAS}/${id}`)

  const pesquisarArhsPorDocumento = async (id?: number) =>
    await get<any, any>(`${GET_DO_DOCUMENTO_ARH}/${id}`)
  const pesquisarCapitaniasPorDocumentoId = async (id?: number) =>
    await get<any, any>(`${GET_DO_DOCUMENTO_CAPITANIA}/${id}`)
  const pesquisarCategoriasPorDocumentoId = async (id?: number) =>
    await get<any, any>(`${GET_DO_DOCUMENTO_CATEGORIA}/${id}`)
  const pesquisarConcelhosPorDocumentoId = async (id?: number) =>
    await get<any, any>(`${GET_DO_DOCUMENTO_CONCELHO}/${id}`)
  const pesquisarDistritosPorDocumentoId = async (id?: number) =>
    await get<any, any>(`${GET_DO_DOCUMENTO_DISTRITO}/${id}`)
  const pesquisarMassasAguaPorDocumentoId = async (id?: number) =>
    await get<any, any>(`${GET_DO_DOCUMENTO_MASSA_AGUA}/${id}`)
  const pesquisarNuts1PorDocumentoId = async (id?: number) =>
    await get<any, any>(`${GET_DO_DOCUMENTO_NUTS1}/${id}`)
  const pesquisarNuts2PorDocumentoId = async (id?: number) =>
    await get<any, any>(`${GET_DO_DOCUMENTO_NUTS2}/${id}`)
  const pesquisarNuts3PorDocumentoId = async (id?: number) =>
    await get<any, any>(`${GET_DO_DOCUMENTO_NUTS3}/${id}`)

  const selectDocumentosResult = useDocumentosStore(
    (state) => state.selectDocumentosResult
  )
  const [freguesiasData, setFreguesiasData] = useState(freguesias ? freguesias.slice() : []);
  const [concelhoData, setConcelhoData] = useState(concelhos ? concelhos.slice() : []);
  const [distritoData, setDistritoData] = useState(distritos ? distritos.slice() : []);
  
  const [nuts1Data, setNuts1Data] = useState(nuts1 ? nuts1.slice() : []);
  const [nuts2Data, setNuts2Data] = useState(nuts2 ? nuts2.slice() : []);
  const [nuts3Data, setNuts3Data] = useState(nuts3 ? nuts3.slice() : []);

  const [arhData, setArhData] = useState(arhs ? arhs.slice() : []);
  const [capitaniaData, setCapitaniaData] = useState(capitania ? capitania.slice() : []);
  const [massaaguaData, setMassaaguaData] = useState(massaagua ? massaagua.slice() : []);
 
  const [entidadesData, setEntidadesData] = useState(entidades ? entidades.slice() : []);
  const [tipoRecursoData, setTipoRecursoData] = useState(tiporecurso ? tiporecurso.slice() : []);
  
  const CustomCalendar = (props: MultiViewCalendarProps) => {
    return <MultiViewCalendar {...props} views={1} />
  }

  
  type combosKeys = 'nuts1' | 'nuts2' | "nuts3" | 'distrito' | 'concelho' | 'freguesia' | 'arh' | 'capitania' | 'massaagua' | 'tipoRecurso' | 'entidade';
const [combos, setCombos] = useState<any>({
    
      nuts1,
      nuts2:nuts2Filtred,
      nuts3:nuts3Filtred,
      distritos,
      concelhos:concelhosFiltred,
      freguesias:freguesiasFiltred,
      arhs,
      capitania,
      massaagua, 
      tiporecurso,
      entidades
     
    })
  const setDataOption: Record<combosKeys, { setFn: any, data: any[] }> = {
    nuts1: { setFn: setCombos, data: nuts1 },
    nuts2: { setFn: setCombos, data: nuts2 },
    nuts3: { setFn: setCombos, data: nuts3 },
    distrito: { setFn: setCombos, data: distritos },
    concelho: { setFn: setCombos, data: concelhos },
    freguesia: { setFn: setCombos, data: freguesias },
    arh: { setFn: setCombos, data: arhs },
    capitania: { setFn: setCombos, data: capitania },
    massaagua: { setFn: setCombos, data: massaagua },
    tipoRecurso: { setFn: setCombos, data: tiporecurso },
    entidade: { setFn: setCombos, data: entidades },
  };

   const filterCombo_Multiselect = (e: any, tipoCombo: combosKeys) => {
      const { setFn, data } = setDataOption[tipoCombo];
        setFn({
            ...combos,
            [tipoCombo]: filterBy(data?.slice()??[], e.filter)
        }
        );
  }
  

  useEffect(() => {
    
     if(!nuts1 ){
      fillNuts1Async();
      }
     if(!nuts2) {
      fillNuts2Async();
     }
     if(!nuts3){
      fillNuts3Async();
     }
     if(!distritos){
      fillDistritosAsync();
     }
     if(!concelhos){
      fillConcelhosAsync();
     }
     if(!freguesias){
      fillFreguesiasAsync();
     }
     if(!arhs){
      fillArhsAsync();
     }
     if(!capitania){
      fillCapitaniaAsync();
     }
     if(!estados){
      fillEstadoAsync();
     }
     if(!entidades){
      fillEntidadesAsync();
     }

  }, []);

  const concelhoSelectChange = (item: any) => {
    if (!item) {
      setFreguesiasFiltred(freguesias)
      return
    }
    setFreguesiasFiltred(
      freguesias.filter((x) => x.concelho_cod == item.codigo)
    )
  }

  const filterConcelhoSelectDistrito = (e: any) => {
    if (e?.value?.codigo) {
      const concelhosLocal = concelhos.filter((x: any) => x.distrito_cod == e.value.codigo);
      setConcelhosFiltred(concelhosLocal);
      setConcelhosDisabled(false);
      const distritoSelectedLocal = distritos.filter(x => x.codigo == e?.value?.codigo);
      deselectNuts();
      deselectMassaArh();
      setConcelhoSelected(distritoSelectedLocal[0]);
    } else {
      setConcelhosFiltred(concelhos);
      setConcelhosDisabled(true);
    }
  }

  const filterFreguesia = (e: any) => {
    if (e?.value?.codigo) {
      const freguesiaLocal = freguesias.filter((x: any) => x.concelho_cod == e.value.codigo);
      setFreguesiasFiltred(freguesiaLocal);
      setFreguesiasDisabled(false);
      const concelhoSelectedLocal = concelhos.filter(x => x.codigo == e?.value?.codigo);
      deselectNuts();
      deselectMassaArh();
      setConcelhoSelected(concelhoSelectedLocal[0]);
    } else {
      setFreguesiasDisabled(true);
      setFreguesiasFiltred(freguesias);
    }
  }

  const freguesiaChange = (e: any) => {
    if (e?.value?.codigo) {
      const freguesiaSelectedLocal = freguesias.filter(x => x.codigo == e?.value?.codigo);
      setFreguesiaSelected(freguesiaSelectedLocal[0]);
      const documentoSelected = getDocumentoSelected();
      setDocumentoSelected({
        ...documentoSelected,
        geom_imported: "Freguesia: " + freguesiaSelectedLocal[0]?.nome
      })
    }
  }

  const filterNuts2SelectNuts1 = (e: any) => {
    setNuts2Filtered([])
    if (e?.value?.codigo) {
      const nuts1SelectedLocal = nuts1.filter(x => x.codigo == e?.value?.codigo);
      deselectDistConc();
      deselectMassaArh();
      setNuts1Selected(nuts1SelectedLocal[0]);
      setNuts2Filtered(nuts2?.filter((nts: any) => nts?.nuts_i_cod === e?.value?.codigo))
    }
  }

  const filterNuts3SelectNuts2 = (e: any) => {
    setNuts3Filtered([])
    if (e?.value?.codigo) {
      const nuts2SelectedLocal = nuts2.filter(x => x.codigo == e?.value?.codigo);
      deselectDistConc();
      deselectMassaArh();
      setNuts2Selected(nuts2SelectedLocal[0]);
      setNuts3Filtered(nuts3?.filter((nts: any) => nts?.nuts_ii_cod === e?.value?.codigo))
    }
  }

  const cancelImport = () => {
    setOpenImportModal(false);
  }

  const selectArh = (e: any) => {
    if (e?.value?.codigo) {
      const arhSelectedLocal = arhs.filter(x => x.codigo == e?.value?.codigo);
      deselectAll();
      setArhSelected(arhSelectedLocal[0]);
    }
  }

  const selectNuts3 = (e: any) => {
    if (e?.value?.codigo) {
      const nuts3SelectedLocal = nuts3.filter(x => x.codigo == e?.value?.codigo);
      deselectAll();
      setNuts3Selected(nuts3SelectedLocal[0]);
    }
  }

  const deselectAll = () => {
    deselectDistConc();
    deselectNuts();
    deselectMassaArh();
  }

  const deselectMassaArh = () => {
    setMassaaguaSelected(undefined);
    setArhSelected(undefined);
  }

  const deselectNuts = () => {
    setNuts1Selected(undefined);
    setNuts2Selected(undefined);
    setNuts3Selected(undefined);
  }

  const deselectDistConc = () => {
    setFreguesiaSelected(undefined);
    setDistritoSelected(undefined);
    setConcelhoSelected(undefined);
  }

  useEffect(() => {
    console.log(entidades);
  }, []);

  const importGeom = async (obj: any) => {
    const documentoSelected: any = getDocumentoSelected();
    let documentoId;
    if (documentoSelected?.id) {
      documentoId = documentoSelected?.id;
    } else {
      const documentoInserted = await saveDocumento({});
      documentoId = documentoInserted.id;
      selectedDocumentosResult(documentoInserted);
      setDocumentoSelected(documentoInserted);
    }

    let dataToSend;
    if (freguesiaSelected) {
      dataToSend = {
        documento_id: documentoId,
        freguesia_cod: obj?.freguesiaImport?.codigo,
        geom_imported: "Freguesia: " + freguesiaSelected?.nome
      }
    } else if (concelhoSelected) {
      dataToSend = {
        documento_id: documentoId,
        concelho_cod: concelhoSelected?.codigo,
        geom_imported: "Concelho: " + concelhoSelected?.nome
      }
    }
    else if (distritoSelected) {
      dataToSend = {
        documento_id: documentoId,
        distrito_cod: distritoSelected?.codigo,
        geom_imported: "Distrito: " + distritoSelected?.nome
      }
    }
    else if (nuts3Selected) {
      dataToSend = {
        documento_id: documentoId,
        nuts3_cod: nuts3Selected?.codigo,
        geom_imported: "Nuts 3: " + nuts3Selected?.nome
      }
    }
    else if (nuts2Selected) {
      dataToSend = {
        documento_id: documentoId,
        nuts2_cod: nuts2Selected?.codigo,
        geom_imported: "Nuts 2: " + nuts2Selected?.nome
      }
    }
    else if (nuts1Selected) {
      dataToSend = {
        documento_id: documentoId,
        nuts1_cod: nuts1Selected?.codigo,
        geom_imported: "Nuts 1: " + nuts1Selected?.nome
      }
    }
    else if (massaaguaSelected) {
      dataToSend = {
        documento_id: documentoId,
        massa_agua_cod: massaaguaSelected?.codigo,
        geom_imported: "Massa Agua: " + massaaguaSelected?.nome
      }
    }
    else if (arhSelectected) {
      dataToSend = {
        documento_id: documentoId,
        arh_cod: arhSelectected?.codigo,
        geom_imported: "ARH: " + arhSelectected?.nome
      }
    }
    setDocumentoSelected({
      ...documentoSelected,
      geom_imported: dataToSend?.geom_imported
    })
    console.log(dataToSend);
    const result = await importGeomDocumento(dataToSend);
    if (result) {
      postMessage({ type: "informationMsg", value: "Importado com sucesso." }, "*");
      setOpenImportModal(false);
      setGeomImported(true);
      setValues();
      const idToSend = [documentoSelected?.id];
      const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.contentWindow?.postMessage({ type: "SiarlDocumentoSearch", value: idToSend }, '*')
    }
  }

  const getFreguesiasPorDocumentoId = async (id?: any) => {
    try {
      const freguesias = await pesquisarFreguesiasPorDocumento(id)
      return freguesias
    } catch (error) {
      return
    }
  }
  const getArhsPorDocumentoId = async (id?: any) => {
    try {
      const arhs = await pesquisarArhsPorDocumento(id)
      return arhs
    } catch (error) {
      return
    }
  }

  const getCapitaniasPorDocumentoId = async (id?: any) => {
    try {
      const capitanias = await pesquisarCapitaniasPorDocumentoId(id)
      return capitanias
    } catch (error) {
      return
    }
  }
  const getCategoriasPorDocumentoId = async (id?: any) => {
    try {
      const categorias = await pesquisarCategoriasPorDocumentoId(id)
      return categorias
    } catch (error) {
      return
    }
  }
  const getConcelhosPorDocumentoId = async (id?: any) => {
    try {
      const concelhos = await pesquisarConcelhosPorDocumentoId(id)
      return concelhos
    } catch (error) {
      return
    }
  }
  const getDistritosPorDocumentoId = async (id?: any) => {
    try {
      const distritos = await pesquisarDistritosPorDocumentoId(id)
      return distritos
    } catch (error) {
      return
    }
  }
  const getMassasAguaPorDocumentoId = async (id?: any) => {
    try {
      const massasAgua = await pesquisarMassasAguaPorDocumentoId(id)
      return massasAgua
    } catch (error) {
      return
    }
  }
  const getNuts1PorDocumentoId = async (id?: any) => {
    try {
      const nuts1 = await pesquisarNuts1PorDocumentoId(id)
      return nuts1
    } catch (error) {
      return
    }
  }
  const getNuts2PorDocumentoId = async (id?: any) => {
    try {
      const nuts2 = await pesquisarNuts2PorDocumentoId(id)
      return nuts2
    } catch (error) {
      return
    }
  }
  const getNuts3PorDocumentoId = async (id?: any) => {
    try {
      const nuts3 = await pesquisarNuts3PorDocumentoId(id)
      return nuts3
    } catch (error) {
      return
    }
  }

  const setValues = async () => {
    try {
      postMessage({ type: 'showLoader' }, '*')
      const freguesiasAll = await getFreguesiasPorDocumentoId(
        selectedDocumentosResultId
      )
      const arhs = await getArhsPorDocumentoId(selectedDocumentosResultId)
      const capitanias = await getCapitaniasPorDocumentoId(
        selectedDocumentosResultId
      )
      const categorias = await getCategoriasPorDocumentoId(
        selectedDocumentosResultId
      )
      const concelhos = await getConcelhosPorDocumentoId(
        selectedDocumentosResultId
      )
      const distritos = await getDistritosPorDocumentoId(
        selectedDocumentosResultId
      )
      const massasAgua = await getMassasAguaPorDocumentoId(
        selectedDocumentosResultId
      )
      const nuts1 = await getNuts1PorDocumentoId(selectedDocumentosResultId)
      const nuts2 = await getNuts2PorDocumentoId(selectedDocumentosResultId)
      const nuts3 = await getNuts3PorDocumentoId(selectedDocumentosResultId)
      // const palavrasChaves = await getPalavrasChavesPorDocumentoId(selectedDocumentosResultId)
      selectDocumentosResult({
        ...selectedDocumentosResult,
        freguesiasAll,
        arhs,
        capitanias,
        categorias,
        concelhos,
        distritos,
        massasAgua,
        nuts1,
        nuts2,
        nuts3,
        // ,
        // palavrasChaves
      })
      const documentoSelected: any = getDocumentoSelected()
      setDocumentoSelected({
        ...documentoSelected,
        freguesiasAll,
        arhs,
        capitanias,
        categorias,
        concelhos,
        distritos,
        massasAgua,
        nuts1,
        nuts2,
        nuts3,
        // ,
        // palavrasChaves
      })
    } catch (error) {
      postMessage({ type: 'errorMsg', value:{message:"Não foi possivel efetuar esta operação"} }, '*')
      console.log(error)
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }
  
  useEffect(() => {
    console.log(selectedDocumentosResult, 'selectedDocumentosResult')

    if (selectedDocumentosResult && selectedDocumentosResult?.id) {
      setValues()
    }
  }, [])

  

  return (
    <>
      <div className={`${styles.form} ${styles.removeScrollDuplicado}`}>

       <span style={{ marginLeft: '08px' }}>Os campos com simbolo * (asterisco) são de preenchimento obrigatório</span>
        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Registo</span>
          </legend>

          <div className={styles.espacoVerticalFieldSet}>
            <div className={styles.fields}>
              <div>
                <Field
                  name="Iddoc"
                  label="ID Documento"
                  disabled
                  component={Input}
                />
              </div>
              <div>
                <Field name="filedoc_cod" label="Nº Processo APA" component={Input} disabled={viewMode} maxLength={25} />
              </div>
              <div>
                <Field
                  name="estado_id"
                  label="Estado do Registo *"
                  component={ValidatedIdentificacaoComboBox}
                  validator={requiredFieldValidator}
                  textField={'designacao'}
                  disabled={viewMode}
                  data={estados}
                />
              </div>
            </div>
            <div className={styles.fields}>
               <div>
                <Field
                  name="dataHoraRegisto"
                  label="Data registo"
                  format="dd/MM/yyyy"
                  component={DatePicker}
                  disabled
                  calendar={CustomCalendar}

                />
              </div>

              <div>
                <Field
                  name="tecnicoRegisto"
                  label="Técnico de registo"
                  component={Input}
                  disabled
                />
              </div>
             
              <div>
              <Field
                name="entidade_registo"
                label="Entidade"
                disabled
                component={Input}
             />
             </div>
            </div>
          </div>
        </fieldset>

    
      <div style={{ marginTop: 24 }}></div>

        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Identificação</span>
          </legend>

          <div className={styles.espacoVerticalFieldSet}>


            <div className={styles.fields}>

             <div>
                <Field
                  name="titulo"
                  label="Título *"
                  component={ValidatedIdentificacaoInput}
                  validator={requiredFieldValidator}
                  textField={'nome'}
                  maxLength={250}
                  disabled={viewMode}
                />
              </div>
              </div>
            <div className={styles.fields}>
              <div>
                <Field
                  name="entidade_id"
                  label="Entidade *"
                  component={ValidatedIdentificacaoComboBox}
                  textField={'nome'}
                  validator={requiredFieldValidator}
                  filterable={true}
                  onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'entidade')}}
                  data={entidadesData}   
                  disabled={viewMode}
                />
              </div>
              <div>
                <Field
                  name="autores"
                  label="Autores"
                  component={Input}
                  textField={'nome'}
                  maxLength={50}
                  disabled={viewMode}
                />
              </div>
             
            </div>
            <div className={styles.fields}>
              <div>
                <Field
                  name="dataPublicacao"
                  label="Data publicação"
                  format="dd/MM/yyyy"
                  component={DatePicker}
                  disabled={viewMode}               
                  calendar={CustomCalendar} />
              </div>
             
            </div>
          </div>
          </fieldset>
        
        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Localização</span>
          </legend>

          <div className={styles.espacoVerticalFieldSet}>
            <div className={styles.fields}>
              {selectedDocumentosResultId ? (
                <div>
                  <CheckMoreItem
                    list={selectedDocumentosResult?.nuts1}
                    property={'nome'}
                    label="NUTS I"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="nuts1"
                    label="NUTS I"
                    component={MultiSelect}
                    textField={'nome'}
                    filterable={true}
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'nuts1')}}
                    data={nuts1Data}
                    disabled
                    />
                </div>
              )}
              {selectedDocumentosResultId ? (
                <div>
                  <CheckMoreItem
                    list={selectedDocumentosResult?.nuts2}
                    property={'nome'}
                    label="NUTS II"
                    
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="nuts2"
                    label="NUTS II"
                    component={MultiSelect}
                    textField={'nome'}
                    filterable={true}
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'nuts2')}}
                    data={nuts2Data}
                    disabled
                  />
                </div>
              )}
              {selectedDocumentosResultId ? (
                <div>
                  <CheckMoreItem
                    list={selectedDocumentosResult?.nuts3}
                    property={'nome'}
                    label="NUTS III"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="nuts3"
                    label="NUTS III"
                    component={MultiSelect}
                    textField={'nome'}
                    filterable={true}
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'nuts3')}}
                    data={nuts3Data}
                    disabled
                    />
                </div>
              )}
            </div>

            <div className={styles.fields}>
              {selectedDocumentosResultId ? (
                <div>
                  <CheckMoreItem
                    list={selectedDocumentosResult?.distritos}
                    property={'nome'}
                    label="Distrito"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="distrito"
                    label="Distrito"
                    component={MultiSelect}
                    textField={'nome'}
                    filterable={true}
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'distrito')}}
                    data={distritoData}
                    disabled

                  />
                </div>
              )}
              {selectedDocumentosResultId ? (
                <div>
                  <CheckMoreItem
                    list={selectedDocumentosResult?.concelhos}
                    property={'nome'}
                    label="Concelho"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="concelho"
                    label="Concelho"
                    component={MultiSelect}
                    onChange={(e) => concelhoSelectChange(e.value)}
                    textField={'nome'}
                    filterable={true}
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'concelho')}}
                    data={concelhoData}
                    disabled

                  />
                </div>
              )}
              {selectedDocumentosResultId ? (
                <div>
                  <CheckMoreItem
                    list={selectedDocumentosResult?.lstFreguesias}
                    property={'nome'}
                    label="Freguesia"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="freguesia"
                    label="Freguesia"
                    component={MultiSelect}
                    textField={'designacao'}
                    filterable={true}
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'freguesia')}}
                    data={freguesiasData}
                    disabled
                  />
                </div>
              )}
            </div>

            <div className={styles.fields}>
              {selectedDocumentosResultId ? (
                <div>
                  <CheckMoreItem
                    list={selectedDocumentosResult?.arhs}
                    property={'nome'}
                    label="ARH"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="arh"
                    label="ARH"
                    component={MultiSelect}
                    textField={'nome'}
                    filterable={true}
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'arh')}}
                    data={arhData}
                    disabled
                  />
                </div>
              )}
              {selectedDocumentosResultId ? (
                <div>
                  <CheckMoreItem
                    list={selectedDocumentosResult?.capitanias}
                    property={'nome'}
                    label="Capitania"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="capitania"
                    label="Capitania"
                    component={MultiSelect}
                    textField={'nome'}
                    filterable={true}
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'capitania')}}
                    data={capitaniaData}
                    disabled
                  />
                </div>
              )}
              {selectedDocumentosResultId ? (
                <div>
                  <CheckMoreItem
                    list={selectedDocumentosResult?.massasAgua}
                    property={'nome'}
                    label="Massa de água"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="massaAgua"
                    label="Massa de água"
                    component={MultiSelect}
                    textField={'nome'}
                    filterable={true}
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'massaagua')}}
                    data={massaaguaData}
                    disabled
                  />
                </div>
              )}
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Tipologia</span>
          </legend>
          <div className={styles.espacoVerticalFieldSet}>
            <div className={styles.fields}>
              <div>
                <Field
                  name="tipo_recurso_id"
                  label="Tipo de recurso *"
                  component={ValidatedIdentificacaoComboBox}
                  validator={requiredFieldValidator}
                  textField={'designacao'}
                  filterable={true}
                  onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'tipoRecurso')}}
                  data={tipoRecursoData}   
                  disabled={viewMode}
                />

                <div>
                <Field
                  name="tipoAcesso"
                  label="Tipo de acesso *"
                  component={ValidatedIdentificacaoComboBox}
                  validator={requiredFieldValidator}
                  textField={'designacao'}
                  disabled={viewMode}
                  data={tipoacesso}
                />
              </div>
              </div>
            </div>
          </div>
        </fieldset>
      

          <fieldset>
           <legend>
            <span className={styles.fieldsetTitulo}>Resumo</span>
          </legend>
           <div className={styles.espacoVerticalFieldSet}>
            <div className={styles.fields}>
              <Field
                name="Resumo"
                label="Resumo"
                component={TextArea}
                textField={'nome'}
                maxLength={500}
                disabled={viewMode}              />
            </div>
          </div>
        </fieldset>
      </div>  
     </>
  )
}
