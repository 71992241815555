import { process } from '@progress/kendo-data-query';
import {
  Dispatch,
  MouseEvent as ReactMouseEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { Button, DropDownButton } from '@progress/kendo-react-buttons';
import { PagerTargetEvent } from '@progress/kendo-react-data-tools';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import {Grid, GridCellProps, GridColumn, GridPageChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { format, formatISO } from 'date-fns';
import React from 'react';
import { CSVLink } from 'react-csv';
import SessionStorageService from '../../services/SessionStorageService';
import { b64toBlob, exportPDFWithMethod, get, handleGenericError, hasPermission, post,setVooDronePathSelected,setVooDroneSelected, setkmlDetalheSelected } from '../../services/utils';
import styles from '../../styles/vooDrone/VooDrone.module.scss'
import commonStyles from '../../styles/common/Common.module.scss'
import { useVooDroneStore } from '../../stores/vooDrone';
import { ResultadoVooDroneCommandGridCell } from './ResultadoVooDroneCommandGridCell';
import { PESQUISAR_VOO_DRONE ,GET_DOCUMENTO_BY_VOOS_DRONE, PESQUISAR_VOO_DRONE_PATH_BY_UUID, DOWNLOAD_RESOURCE_VOO_DRONE_PATH_BY_ID, DOWNLOAD_VIDEO_BY_ID, AUTH_LOCALSTORAGE, API_URL, GET_VOO_DRONE_BY_VOO_DRONE_PATH_ID, GET_REGISTO_VOO_DRONE, GET_REGISTO_VOO_DRONE_BY_ID, clearLayers, CONSULTAR_REGISTOS_VOO_DRONE, GET_VIDEO_BY_VOO_DRONE_PATH_ID, PERMISSION_INSERIR_VOO_DRONE, GET_VOO_DRONES_BY_VOO_DRONE_PATH_ID} from '../../utils/constants';
import axios, { AxiosProgressEvent } from 'axios';
import MaximizeButton from '../common/MaximizeButton';
import { useCommonStore } from '../../stores/common';
import clsx from 'clsx';
import useClearMap from '../../hooks/useClearMap';
import VisualizadorColunas from '../Generico/VisualizadorColunas ';
import useVisibleColumns, { Key } from '../../hooks/useVIsibleColumns';

type Props = {
  onCloseClick?: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
  setShowResultadoVooDrone: Dispatch<SetStateAction<boolean>>
  setShowRegistarVooDrone: Dispatch<SetStateAction<boolean>>
}

const options = [
  {
    key: 'ExportarFichasResultados',
    text: 'Exportar fichas resultados',
    icon: <span className={`icon-siarl icon-exportar ${styles.btnImprimir}`}></span>,
  }
]
type PageState = {
  skip: number
  take: number
}
const initialDataState: PageState = { skip: 0, take: 20 }
const SRT_FORMAT= "SRT";
const KML_FORMAT= "KML";

const keyPainelPrincipal:Key = 'vooDroneColunasVisiveis';
const keyPainelDocumentos:Key = 'vooDroneDocumentosColunasVisiveis';
export default function ResultadoVooDroneForm({
  onCloseClick,
  setShowResultadoVooDrone,
  setShowRegistarVooDrone,
}: Props) {
  const [voosDrone, setvoosDrone] = useState([])
  const [documentosvoosDrone, setDocumentosvoosDrone] = useState([])

  const [selected, setSelected] = useState<number>(0)
  const [page, setPage] = useState<PageState>(initialDataState)

  const [totalFileSize, setTotalFileSize] = useState<number>(0) // equivale a 100% do width
  const [downloadedFileSize, setUploadedFileSize] = useState<number>(0)
  const downloadedFileSizeWidthToFill = ((downloadedFileSize * 100)/ totalFileSize).toFixed(2);
  const [errorDownload, setErrorDownload] = useState(false)
  const [clearMap] = useClearMap();
  const isCurrentViewMaximized = useCommonStore(state => state.isCurrentViewMaximized)
  const restoreView = useCommonStore(state => state.restoreView)
  const getRegistoVooDroneById = async(id: any) => await get<any, any>(GET_REGISTO_VOO_DRONE_BY_ID + '/' + id);
  const {getVisibleColumns, removeColumnsKeys} = useVisibleColumns();

  const selectVooDroneResult = useVooDroneStore(
    (state) => state.selectVooDroneResult
  )
  const selectVooDroneResultId = useVooDroneStore(
    (state) => state.selectVooDroneResultId
  )
  const selectVooDronePathResult = useVooDroneStore(
    (state) => state.selectVooDronePathResult
  )
  const selectedVooDronePathResult = useVooDroneStore(
    (state) => state.selectedVooDronePathResult
  )
  const selectVooDronePathResultId = useVooDroneStore(
    (state) => state.selectVooDroneResultId
  )
  const [pageSizeValue, setPageSizeValue] = useState<
    number | string | undefined
  >()

  const getVooDrone = async (data: any) =>
    await get<any, any>(`${PESQUISAR_VOO_DRONE}/` + data)

      const getDocumentosByvoosDroneIDs = async (data: any) =>
  await post<any, any>(`${GET_DOCUMENTO_BY_VOOS_DRONE}` , data)

     const handleMessageEvent = useCallback((event: MessageEvent) => {
        if (event.data.type == "resultadoVooDronePesquisaChange") {
            SessionStorageService.setItem("resultadoVooDronePesquisa", event.data.value);
        }
    }, [])

     useEffect(() => {
        window.addEventListener(
            'message',
            handleMessageEvent,
            false
        )

        return () => {
            window.removeEventListener(
                'message',
                handleMessageEvent,
                false
            )
        }
    }, [handleMessageEvent])

    const consultarVooDrone = async (data: any) => await post<any, any>(`${CONSULTAR_REGISTOS_VOO_DRONE}`, data)

    useEffect(() => {
      if (SessionStorageService.getItem("lastSearchVooDrone") && !SessionStorageService.getItem("firstCallVooDrone")) {
        const objToSearch = SessionStorageService.getItem("lastSearchVooDrone") ?? '';
        consultarVooDrone(objToSearch).then((data) => {
          SessionStorageService.setItem("resultadoVooDronePesquisa", JSON.stringify(data));
          setResultsVooDrone();
        })
      } 
      else {
        setResultsVooDrone();
    }
    }, [])

    const setResultsVooDrone = () => {
        let results = JSON.parse(SessionStorageService.getItem('resultadoVooDronePesquisa') ?? '')
        if(results && results.length>=0){
          results=  results.map((vooDrone: any) => ({
            ...vooDrone,
            data_voo: formatISO(
              new Date(vooDrone.data_voo),
              {
                format:'extended',
                representation:"complete"
              }
            ),
          }))
        }
        setvoosDrone(results)
        const iframe: any = document.getElementById('iframeGeoPortal')
        const ids: any[] = []
        results.forEach((element: any) => {
            ids.push(element.id)
          })
          iframe.contentWindow?.postMessage(
            { type: 'SiarlVooDroneSearch', value: ids },
            '*'
          )
          getVisibleColumns(keyPainelPrincipal, setColums)
          getVisibleColumns(keyPainelDocumentos, setColumsDocuments)
        postMessage({ type: 'hideLoader' }, '*');

   }

  const clearSearchResults = () => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlIntVooDroneClearLayers", value: "true" }, '*')
    SessionStorageService.removeItem('resultadoVooDronePesquisa')
    SessionStorageService.removeItem('lastSearchVooDrone')
    selectVooDroneResult(undefined)
    selectVooDroneResultId(undefined)
    setVooDroneSelected(undefined);
    postMessage({ type: "form", value: "vooDrone" }, "*");
    setvoosDrone([]);
    removeColumnsKeys(keyPainelDocumentos)
    removeColumnsKeys(keyPainelPrincipal)
    clearMap(clearLayers["CLEAR_LAYERS_VOO_DRONE"]);
  }

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected)
  }
  const handlePageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent
    const take = event.page.take

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value)
    }

    setPage({
      ...event.page,
      take,
    })
  }
  function base64StringToBlob(base64String:any, type?:any) {
    const byteCharacters = atob(base64String);
    const byteNumbers = Array.from(byteCharacters, char => char.charCodeAt(0));
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], {type});
}

  useEffect(() => {

    try {
      const results = JSON.parse(
        SessionStorageService.getItem('resultadoVooDronePesquisa') ?? ''
      )
      setvoosDrone(results);
        const idsVooDrone = results.map((o: any) => o.id)
        getDocumentosByvoosDroneIDs(idsVooDrone).then(response => {
          setDocumentosvoosDrone(response)
        }).catch(error=>{
          console.log(error)
        })
      } catch (error) {
        console.error('Erro ao buscar os dados:', error)
      }
  }, [])
  const isBase64String = (base46StringToTest:string)=> {
    if (!base46StringToTest) {
        return false;
    }
    const base64Regex = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})?$/;
    return base64Regex.test(base46StringToTest);
}

  const handleInfoClickById = (registoVooDroneLocal: Record<string, any>) => async () => {
    try {


      postMessage({type: "showLoader"}, "*");
      const iframe: any = document.getElementById('iframeGeoPortal')
      const idVooDroneLineString = registoVooDroneLocal.id; // TO DO COLOCAR NO MAPA
      iframe.contentWindow?.postMessage({ type: "SiarlIntVooDroneSearch", value: [idVooDroneLineString] }, '*')
      const voosDronePath =  await get<any, any>(`${PESQUISAR_VOO_DRONE_PATH_BY_UUID}/` + registoVooDroneLocal?.voo_unique_guid)
      const vzPath =voosDronePath?.find((vdp:{id:number, path:string})=> vdp?.path.toUpperCase().includes(KML_FORMAT));
      if(vzPath?.estado_processamento){
        postMessage({ type: 'infoMsg', value:{message:"Aguarde o processamento para este registo"} }, '*')
        return;
      }
      const {id} =  vzPath;
      const vooDroneCoords = await get<any, any>(`${GET_VOO_DRONES_BY_VOO_DRONE_PATH_ID}` +vzPath?.id )
      let video = "";
    
         const registo_video= await get<any, any>(`${GET_VIDEO_BY_VOO_DRONE_PATH_ID}` +vzPath?.id);
        if(registo_video === undefined || registo_video ===null || registo_video ==="" ){
          postMessage({ type: 'infoMsg', value:{message:"Aguarde o processamento para este registo"} }, '*')
          return;
        }
        if(registo_video?.estado_processamento){
          postMessage({ type: 'infoMsg', value:{message:"Aguarde o processamento para este registo"} }, '*')
          return;
        }
         video = registo_video?.url;
        let registoVooDrone;
        try {
           registoVooDrone = await getRegistoVooDroneById(registoVooDroneLocal?.id);
        } catch (error) {
          console.log({expected:error})
        }
        const {concelhos, distritos, freguesias, nuts1, nuts2, nuts3, arhs} = registoVooDrone || {};
        selectVooDronePathResult({
          ...vzPath,
          id,
          video,
          kml:null,
          concelhos,
          distritos,
          freguesias,
          nuts1,
          nuts2,
          nuts3,
          arhs,
          duracao:registoVooDroneLocal?.duracao,
          distancia:registoVooDroneLocal?.distancia,
          descricao:registoVooDroneLocal?.descricao,
          id_registo_voo_drone:registoVooDroneLocal?.id,
          id_vz_estado: registoVooDroneLocal?.id_vz_estado,
          vz_estado:registoVooDroneLocal?.vz_estado,
          isResultado:true,
          comentario:registoVooDroneLocal?.comentario
        })
      SessionStorageService.setItem("vooDroneCoords", JSON.stringify(vooDroneCoords));
      SessionStorageService.setItem("novoVooDrone", 'false');
      selectVooDroneResultId(registoVooDrone?.id)
      setkmlDetalheSelected(registoVooDroneLocal)
      setShowResultadoVooDrone(false)
      setShowRegistarVooDrone(true)
      iframe.contentWindow?.postMessage(
          { type: 'SiarlVooDroneLine', value: [registoVooDroneLocal?.id] },
          '*'
        )
    } catch (error) {
      handleGenericError(error);
    }finally{
      postMessage({type: "hideLoader"}, "*");
    }
    restoreView();
  }
  const VooDroneCustomCell = (props: GridCellProps) => (
    <ResultadoVooDroneCommandGridCell
      {...props}
      onInfoClick={handleInfoClickById}
    />
  )
  const [dataState, setDataState] = React.useState({
    skip: 0,
    take: 20,
    sort: [],
    group: [],
  });
  const [dataStateDocumentos, setDataStateDocumentos] = React.useState({
    skip: 0,
    take: 20,
    sort: [],
    group: [],
  });

  const dataStateChange = (event: any) => {
    setDataResult(process(voosDrone, event.dataState));
    setDataState(event.dataState);
  };
  const [dataResult, setDataResult] = React.useState(
    process(voosDrone, dataState)
  );
  const dataStateChangeDocumentos = (event: any) => {
    setDataResultDocumentos(process(documentosvoosDrone, event.dataState));
    setDataStateDocumentos(event.dataState);
  };

  const [dataResultDocumentos, setDataResultDocumentos] = React.useState(
    process(documentosvoosDrone, dataStateDocumentos)
  );

  const itemRender = (props: { item: any; itemIndex: number }) => {
    const isTitleItem = props.item.key === 'titulo';
    if (isTitleItem) {
      return (
        <div className={styles.DropDownButtonTitulo}>
          <span>{props.item.text}</span>
        </div>
      );
    } else {
      return (
        <div className={styles.DropDownButtonItem}>
          <span>{props.item.text}</span>
        </div>
      );
    }
  };

  const optionsExportar = [
    {
      key: 'ExportarListaResultadosExcel',
      text: 'excel',
    },
  ]

  let _export: any;
const exportExcel = () => {
  _export.save();
};

  const onItemClickExportar = (event: any) => {
    exportExcel();
  };

  let _pdfExport: any;
  const exportPDF = () => {
    _pdfExport.save();
  };

  const openPdfNewTab = () => {
    return new Promise<void>((resolve, reject) => {
        
        postMessage({ type: "showLoader" }, "*");

        setTimeout(() => {
           const columnsToShow = colums.columns.filter(x => x.show);
            const dataToExport = voosDrone.map((item: any) => {
                const filteredItem: any = {};
                columnsToShow.forEach((column: any) => {
                    filteredItem[column.field] = item[column.field];
                });
                return filteredItem;
            });
            
            exportPDFWithMethod(columnsToShow, dataToExport, 8)
                .then((pdfData: any) => {
                    postMessage({ type: "hideLoader" }, "*");

                        const base64Data = pdfData.split(";base64,")[1];
                        console.log(base64Data);
                        const blob = b64toBlob(base64Data, 'application/pdf');
                        const blobUrl = URL.createObjectURL(blob);

                      
                        window.open(blobUrl, '_blank');
                        resolve();
                  
                })
                .catch(error => {
                    console.error('Error exporting PDF:', error);
                    reject(error);
                });
        }, 1000);
    });
};

  const onItemClickImprimir = async (event: any) => {
    openPdfNewTab();
  }

  const registarNovoClick = () => {
    selectVooDroneResult(undefined)
    selectVooDroneResultId(undefined)
    selectVooDronePathResult(undefined)
    selectVooDronePathResultId(undefined)
    setVooDroneSelected(undefined)
    setkmlDetalheSelected(undefined)
    setVooDronePathSelected(undefined)
    SessionStorageService.setItem("novoVooDrone", 'true');
    postMessage({ type: "form", value: "registarVooDrone" }, "*");
  }

  const [columsDocuments, setColumsDocuments] = useState<{ columns: { field: string, title: string, width: number | string, show: boolean }[] }>({
    columns: [
      { field: "id", width: 70, title: "ID", show: true },
      { field: "filedoc_cod", width: 190, title: "ID Documento Filedoc", show: true },
      { field: "data_publicacao", width: 150, title: "Data publicação", show: true },
      { field: "tipoRecurso.designacao", width: 150, title: "Tipo de recurso", show: true },
      { field: "titulo", width: 90, title: "Título", show: true },
      { field: "autores", width: 90, title: "Autor", show: true },
      { field: "entidade.nome", width: 90, title: "Entidade", show: true },
      { field: "id_estado", width: 'auto', title: "Estado", show: true },
    ]
  })

  const [colums, setColums]=useState<{columns:{field:string, title:string, width:number|string, show:boolean}[]}>({
    columns:[
      { field: "id", title: "ID", show: true, width: 70 },
      { field: "name", title: "Nome", show: true, width: 160 },
      { field: "data_voo", title: "Data de voo", show: true, width: 170 },
      { field: "duracao", title: "Duração", show: true, width: 150 },
      { field: "distancia", title: "Distância", show: true, width: 170 },
      { field: "descricao", title: "Descrição", show: true, width: 170 }, 
    ]
  })

  useEffect(() => {
    SessionStorageService.setItem(keyPainelDocumentos, JSON.stringify(columsDocuments));
  }, [columsDocuments]);
  
  useEffect(() => {
    SessionStorageService.setItem(keyPainelPrincipal, JSON.stringify(colums));
  }, [colums]);

  const combinedData = dataResult.data.length > 0
  ? dataResult
  : voosDrone?.slice(page.skip, page.take + page.skip)
    .map((VooDrone: any) => ({
      ...VooDrone,
      data_voo: format(
        new Date(VooDrone.data_voo),
        'dd/MM/yyy HH:mm'
      )
    }));

return (
  <div className={clsx(styles.base, isCurrentViewMaximized && commonStyles.maximizedBaseForm)}>
    <div className={styles.header}>
      <div>
        <div className={styles.nomeDoModulo}>Video Aéreo</div>
        <div className={styles.tituloDaPagina}>Resultados de pesquisa</div>
      </div>

      <div>
        {hasPermission(PERMISSION_INSERIR_VOO_DRONE) ? 
          <Button onClick={registarNovoClick} className={styles.btnNovoRegisto}>
            <span className="icon-siarl icon-registar btnIconRegistar"></span>{' '}
            &nbsp;{' '}
            Novo vídeo aéreo
          </Button>
        : ''}
        <CSVLink
          title="Imprimir resultados"
          id="print-results"
          separator={';'}
          data={voosDrone?.map((VooDrone: any) => ({
            ...VooDrone,
            entidade: VooDrone?.entidade?.nome,
            tipoEntidade: VooDrone?.entidade?.tipoEntidade?.designacao,
            tipoAcesso: VooDrone?.tipoAcesso?.designacao,
            foto: '',
          }))}
          filename={`siarl-documentos-${format(new Date(), 'ddMMyyyHHmm')}`}
          hidden
        ></CSVLink>

        <VisualizadorColunas 
              columns={selected===0?colums:columsDocuments} 
              setColumns={selected===0?setColums:setColumsDocuments}
              styles={styles.btnEditar2} 
           />

        <button className={styles.btnEditar2} onClick={onItemClickImprimir} id='imprimirFicha' title='Imprimir'>
          <span className="icon-siarl icon-imprimir"></span>
        </button>

        <DropDownButton
              items={optionsExportar}
              className={styles.btnImprimir}
              style={{marginTop: '-4px'}}
              iconClass="icon-siarl icon-exportar"
              onItemClick={onItemClickExportar}
              fillMode={'flat'}
              itemRender={itemRender}
              title='Exportar'
        ></DropDownButton>


        <button className={styles.btnFechar} onClick={onCloseClick} title="Minimizar">
          <span className="icon-siarl icon-minimizar"></span>
        </button>

        <MaximizeButton className={styles.btnMaximizar} />
      </div>
    </div>

    <TabStrip selected={selected} onSelect={handleSelect}>
      <TabStripTab title="Video Aéreo">
        <ExcelExport
          fileName='Resultado_VideoAereo'
          data={voosDrone}
          ref={(exporter) => {
            _export = exporter
          }}
        >
          <Grid
            className={styles.form}
            sortable={true}
            onDataStateChange={dataStateChange}
            data={combinedData}
            {...dataState}
            pageable={{
              buttonCount: 5,
              info: true,
              type: 'numeric',
              pageSizes: true,
              previousNext: true,
            }}
            total={voosDrone.length}
            //groupable={true}
          >
            <GridColumn cell={VooDroneCustomCell} width={30}/>
            {colums?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
          </Grid>
        </ExcelExport>

        <GridPDFExport
          fileName='Resultado_VideoAereo'
          ref={(element) => {
            _pdfExport = element
          }}
          margin="1cm"
          paperSize={['340mm', '297mm']}         

        >
          <Grid
            className={styles.form}
            sortable={true}
            onDataStateChange={dataStateChange}
            data={dataResult.data.length > 0 ? dataResult : voosDrone}
            {...dataState}
            pageable={{
              buttonCount: 5,
              info: true,
              type: 'numeric',
              pageSizes: true,
              previousNext: true,
            }}
            //groupable={true}
          >
             <GridColumn cell={VooDroneCustomCell} width={30} />
             {colums?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
          </Grid>
        </GridPDFExport>
      </TabStripTab>

      <TabStripTab title="Documentos">
        <ExcelExport
          fileName='Resultado_Documentos_VideoAereo'
          data={documentosvoosDrone}
          ref={(exporter) => {
            _export = exporter
          }}
        >
          <Grid
            className={styles.form}
            sortable={true}
            onDataStateChange={dataStateChangeDocumentos}
            data={
              dataResultDocumentos.data.length > 0
                ? dataResultDocumentos
                : documentosvoosDrone
            }
            {...dataState}
            pageable={{
              buttonCount: 5,
              info: true,
              type: 'numeric',
              pageSizes: true,
              previousNext: true,
            }}
            //groupable={true}
          >
            {columsDocuments?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
          </Grid>
        </ExcelExport>

        <GridPDFExport
          fileName='Resultado_Documentos_VideoAereo'
          ref={(element) => {
            _pdfExport = element
          }}
          margin="1cm"
        >
          <Grid
            className={styles.form}
            sortable={true}
            onDataStateChange={dataStateChangeDocumentos}
            data={
              dataResultDocumentos.data.length > 0
                ? dataResultDocumentos
                : documentosvoosDrone
            }
            {...dataState}
            pageable={{
              buttonCount: 5,
              info: true,
              type: 'numeric',
              pageSizes: true,
              previousNext: true,
            }}
            //groupable={true}
          >
          {columsDocuments?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
          </Grid>
        </GridPDFExport>
      </TabStripTab>
    </TabStrip>

    <div
      className={clsx(styles.footer_custom, styles.justify_multiple_children_child, isCurrentViewMaximized && commonStyles.maximizedFooter)}
    >
      {totalFileSize > 0 ?
        <>
          <div className={styles.progress_bar} id="progress" title={`${downloadedFileSizeWidthToFill}% (${downloadedFileSize}mb / ${totalFileSize}mb)`}>
            <div
              className={errorDownload ? styles.progress_filled_error : styles.progress_filled}
              id="progressFill"
              style={{ width: `${downloadedFileSizeWidthToFill}%` }}
            >
            </div>
            <span className={styles.progress_label}>A descarregar video {`${downloadedFileSizeWidthToFill}% (${downloadedFileSize}mb / ${totalFileSize}mb)`} </span>
          </div>
        </>
        : <></>
      }
      <Button className={styles.btn_nova_pesquisa_custom} onClick={clearSearchResults}>
        <span className="icon-siarl icon-pesquisar"></span> &nbsp; Nova pesquisa
      </Button>
    </div>
  </div>
)

} 