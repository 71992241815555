
const uploadVideoWorker = () => {
  onmessage = (e) => {
    const formData = new FormData();
    const formDataArray = e.data.objForm;
    formData?.append("video", formDataArray.video);
    formData?.append("id_voo_drone_path", formDataArray.id_voo_drone_path);
    const xhr = new XMLHttpRequest();
    xhr.open('POST', e.data.url, true);
    if (e.data.token) {
      xhr.setRequestHeader('Authorization', `Bearer ${e.data.token}`);
    }
    xhr.upload.addEventListener('progress', function(event) {
      if (event.lengthComputable) {
        const totalMB = (event.total / (1024 * 1024)).toFixed(2);
        const loadedMB = (event.loaded / (1024 * 1024)).toFixed(2);
        self.postMessage({totalMB,loadedMB });
      }
    });
    xhr.onload = function() {
      if (xhr.status === 200) {
        self.postMessage({ success: JSON.parse(xhr.responseText) });
      } else {
        self.postMessage({ error: xhr.statusText });
      }
    };
    xhr.onerror = function() {
      self.postMessage({ error: xhr.statusText || 'Upload failed' });
    };
    xhr.onabort = function() {
      self.postMessage({ error: 'Upload aborted' });
    };
    xhr.ontimeout = function() {
      self.postMessage({ error: 'Upload timed out' });
    };
    xhr.timeout =12_000_000_000;
    xhr.send(formData);
  };
};
let code = uploadVideoWorker.toString()
code = code.substring(code.indexOf("{") + 1, code.lastIndexOf("}"))
const blob = new Blob([code], { type: 'application/javascript' })
const workerScript = URL.createObjectURL(blob)
export default workerScript;