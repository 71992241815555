import { Button } from '@progress/kendo-react-buttons'
import { DateTimePicker } from '@progress/kendo-react-dateinputs'
import { ComboBox, ComboBoxFilterChangeEvent } from '@progress/kendo-react-dropdowns'
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from '@progress/kendo-react-form'

import { MouseEvent as ReactMouseEvent, useEffect, useState } from 'react'

import { Checkbox } from '@progress/kendo-react-inputs'
import SessionStorageService from '../../services/SessionStorageService'
import { filterBy, FilterDescriptor } from "@progress/kendo-data-query";
import {
  concelhos,
  distritos,
  fillCombosAuxiliares,
  fillConcelhosAsync,
  fillDistritosAsync,
  fillFreguesiasAsync,
  fillNuts1Async,
  fillNuts2Async,
  fillNuts3Async,
  fillPraiasAsync,
  freguesias,
  get,
  nuts1,
  nuts2,
  nuts3,
  post,
  praias,
  put,
  useCombos
} from '../../services/utils'
import { useOcorrenciaStore } from '../../stores/ocorrencias'
import styles from '../../styles/ocorrencia/ConsultarOcorrenciaForm.module.scss'
import {
  AUTH_LOCALSTORAGE,
  GET_OCORRENCIA,
  PESQUISAR_ENTIDADEBYUSER,
  PESQUISAR_OCORRENCIAS_SIMPLES,
  SAVE_OCORRENCIA,
  UPDATE_OCORRENCIA
} from '../../utils/constants'
import FieldsetCombosLocalizacao from '../localizacao/FieldsetCombosLocalizacao'

type Props = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
}

export default function ConsultarOcorrenciaSimplesForm({
  onCloseClick,
}: Props) {
  const [dataDe, setDataDe] = useState<any>()
  const [dataAte, setDataAte] = useState<any>()
  const [freguesiasFiltred, setFreguesiasFiltred] = useState<any>()
  const getEntidadeByUser = async (data: any) =>
    await get<any, any>(`${PESQUISAR_ENTIDADEBYUSER}/` + data)
  const [concelhosFiltred, setConcelhosFiltred] = useState<any>()
  const [concelhosDisabled, setConcelhosDisabled] = useState<any>(true)
  const [freguesiasDisabled, setFreguesiasDisabled] = useState<any>(true)
  const [nuts2Filtered, setNuts2Filtered] = useState<any>()
  const [nuts3Filtered, setNuts3Filtered] = useState<any>()
  const [nuts2Disabled, setNuts2Disabled] = useState<any>(true)
  const [nuts3Disabled, setNuts3Disabled] = useState<any>(true)
  const [distritosDisabled, setDistritosDisabled] = useState<any>(false);
  const [freguesiasData, setFreguesiasData] = useState(freguesias ? freguesias.slice() : []);
  const [concelhoData, setConcelhoData] = useState(concelhos ? concelhos.slice() : []);
  const [distritoData, setDistritoData] = useState(distritos ? distritos.slice() : []);
  const [nuts1Data, setNuts1Data] = useState(nuts1 ? nuts1.slice() : []);
  const [nuts2Data, setNuts2Data] = useState(nuts2 ? nuts2.slice() : []);
  const [nuts3Data, setNuts3Data] = useState(nuts3 ? nuts3.slice() : []);
  const [minData, setMinDate] = useState<any>();

  const getOcorrencia = async (data: any) =>
    await get<any, any>(`${GET_OCORRENCIA}/` + data)
  const [dataOcorrenciaVisible, setDataOcorrenciaVisible] = useState(true)
  const [tipologiaVisible, setTipologiaVisible] = useState(false)
  const [localizacaoVisible, setLocalizacaoVisible] = useState(false)
  const getOcorrencias = async (data: any) =>
    await post<any, any>(`${PESQUISAR_OCORRENCIAS_SIMPLES}`, data)
  const saveOcorrencia = async (data: any) =>
    await post<any, any>(`${SAVE_OCORRENCIA}`, data)

  const updateOcorrencia = async (data: any) =>
    await put<any, any>(`${UPDATE_OCORRENCIA}`, data)
  const selectedOcorrenciaResultId = useOcorrenciaStore(
    (state) => state.selectedOcorrenciaResultId
  )

  const selectOcorrenciaResult = useOcorrenciaStore(
    (state) => state.selectOcorrenciaResult
  )

  const selectOcorrenciaResultId = useOcorrenciaStore(
    (state) => state.selectOcorrenciaResultId
  )
  useEffect(() => {
    
     if(!nuts1 ){
      fillNuts1Async();
      }
     if(!nuts2) {
      fillNuts2Async();
     }
     if(!nuts3){
      fillNuts3Async();
     }
     if(!distritos){
      fillDistritosAsync();
     }
     if(!concelhos){
      fillConcelhosAsync();
     }
     if(!freguesias){
      fillFreguesiasAsync();
     }
     if(!praias){
      fillPraiasAsync();
     }

  }, []);

  useEffect(() => {
     sessionStorage.removeItem('sistemasNaturais')
      sessionStorage.removeItem('pessoaEmRisco')
      sessionStorage.removeItem('medidasMetigacao')
      sessionStorage.removeItem('detecaoOcorrencia')
      setFreguesiasFiltred(freguesias)
      setConcelhosFiltred(concelhos)
      setNuts2Filtered(nuts2)
      setNuts3Filtered(nuts3)
      SessionStorageService.setItem("ocorrenciasPesquisaTipo", "Simples");

  }, []);

  const toggleDataOcorrencia = () => {
    setDataOcorrenciaVisible(!dataOcorrenciaVisible)
  }
  const toggleTipologia = () => {
    setTipologiaVisible(!tipologiaVisible)
  }

  const toggleLocalizacao = () => {
    setLocalizacaoVisible(!localizacaoVisible)
  }
  const handleEnableDistrito = () => {
  setDistritosDisabled(false);
};
  const handleSubmit = async (e: any) => {

 

    console.log(JSON.stringify(e.values))
    postMessage({ type: 'showLoader' }, '*')
    const userLocal = JSON.parse(
      localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}'
    )
    const objToSearch = {
      data_ocorrencia_inicio: e.values?.De ?? null,
      data_ocorrencia_fim: e.values?.Ate ?? null,
      instabilidade_em_arribas: e.values?.instabiladeArribas,
      data_indeterminada : e.values?.indeterminado,
      erosao_recuo: e.values?.erosaoRecuo,
      galgamento_costeiro: e.values?.galgamento,
      estrutura_de_protecao: e.values?.protecaoDefesa,
      ahr_id: e.values?.ahr?.id,
      concelho_cod: e.values?.concelho ? e.values?.concelho.codigo : undefined,
      nuts1_cod: e.values?.nuts1 ? e.values?.nuts1.codigo : undefined,
      nuts2_cod: e.values?.nuts2 ? e.values?.nuts2.codigo : undefined,
      nuts3_cod: e.values?.nuts3 ? e.values?.nuts3.codigo : undefined,
      local_id: e.values?.local ?? null,
      distrito_cod: e.values?.distrito ? e.values?.distrito.codigo : undefined,
      freguesia_cod: e.values?.freguesia
        ? e.values?.freguesia.codigo
        : undefined,
      praia_id: e.values?.praia?.id,
      user_id: userLocal?.user?.id
    }

    sessionStorage.setItem("lastSearchOcorrencias", JSON.stringify(objToSearch));
    SessionStorageService.setItem("firstCallOcorrencias", true);
    const result: any = await getOcorrencias(objToSearch);
    if (result) {
      const iframe: any = document.getElementById('iframeGeoPortal')
      const ids: number[] = []
      const idsApoio: number[] = []
      for (const element of result) {
        ids.push(element.id)
        if (element.oc_ocorrencia_apoio_id) {
          idsApoio.push(element.oc_ocorrencia_apoio_id)
        }
      }
      //const ids = [14, 15];
      iframe.contentWindow?.postMessage(
        { type: 'SiarlOcorrenciasSearch', value: ids },
        '*'
      )
      //iframe.contentWindow?.postMessage({ type: "SiarlOcorrenciasApoioSearch", value: idsApoio }, '*')
      SessionStorageService.setItem(
        'resultadoOcorrenciasPesquisa',
        JSON.stringify(result)
      )

      postMessage({ type: 'resultadoOcorrenciasPesquisaChange', value: result }, '*')
    }
  }

  return (
    <Form
      onSubmitClick={handleSubmit}
      render={(formRenderProps: FormRenderProps) => (
        <>
          <FormElement className={styles.form}>

            <fieldset>
              <legend>
                <button type='button' className={styles.itemExpandBase} onClick={toggleDataOcorrencia}>
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span className={dataOcorrenciaVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                  </div>
                  <span className={styles.fieldsetTitulo}>Identificação</span>
                </button>
              </legend>
              {dataOcorrenciaVisible && (
                <div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}>
                  <div>
                    <Field
                      name="De"
                      label="De"
                      value={dataDe}
                      onChange={(e) => {
                        setDataDe(e.value)
                         setMinDate(e.value);
                      }}  
                      format="dd/MM/yyyy HH:mm"
                      component={DateTimePicker}
                    />
                  </div>
                  <div>
                    <Field
                      name="Ate"
                      label="Até"
                      format="dd/MM/yyyy HH:mm"
                      onChange={(e) => {
                        setDataAte(e.value)
                      }}             
                      min={minData}        
                     component={DateTimePicker}
                    />
                  </div>
                  <div>
                    <Field
                      name="indeterminado"
                      label="Indeterminada"
                      title="Marque esta caixa se não souber a data exata da ocorrência"
                      component={Checkbox}
                      className="checkbox-indeterminado"
                    />
                  </div>
                </div>
              )}
            </fieldset>

            <FieldsetCombosLocalizacao  isPesquisaAvancada={true} styles={styles} formRenderProps={formRenderProps}/>


            <fieldset>
              <legend>
                <button type='button' className={styles.itemExpandBase} onClick={toggleTipologia}>
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span className={tipologiaVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                  </div>
                  <span className={styles.fieldsetTitulo}>Tipologia</span>
                </button>
              </legend>
              {tipologiaVisible && (
                <div className={styles.espacoVerticalFieldSet}>
                  <div>
                    <Field
                      name="instabiladeArribas"
                      label="Instabilidade em arribas"
                      component={Checkbox}
                    />
                  </div>
                  <div>
                    <Field
                      name="erosaoRecuo"
                      label="Erosão/recuo da linha de costa"
                      component={Checkbox}
                    />
                  </div>
                  <div>
                    <Field
                      name="galgamento"
                      label="Galgamento costeiro"
                      component={Checkbox}
                    />
                  </div>
                  <div>
                    <Field
                      name="protecaoDefesa"
                      label="Em estruturas de proteção/defesa costeira"
                      component={Checkbox}
                    />
                  </div>
                </div>
              )}
            </fieldset>

          </FormElement>
          <div className={`${styles.footer} ${styles.justify_multiple_children}`}>

            <Button
            onClick={() => {
            formRenderProps.onFormReset();
            handleEnableDistrito();
            setTipologiaVisible(false)
            }}             
             className={styles.btnLimparPesquisa}
             title='Limpar pesquisa'
            >
              <span className="icon-siarl icon-limparcampos"></span>
              <span className={styles.rotulo}>
                {' '}&nbsp;{' '}
                Limpar pesquisa
              </span>              
            </Button>

            <Button
              onClick={formRenderProps.onSubmit}
              className={styles.btnPesquisar}
              title='Pesquisar'
            >
              <span className="icon-siarl icon-pesquisar"></span>
              <span className={styles.rotulo}>
                {' '}&nbsp;{' '}
                Pesquisar
              </span>
            </Button>

          </div>
        </>
      )}
    />
  )
}