import {
  Form,
  FormElement
} from '@progress/kendo-react-form'

import {
  MouseEvent as ReactMouseEvent,
  useEffect,
  useLayoutEffect,
  useState
} from 'react'


import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from '@progress/kendo-react-layout'



import { Button, DropDownButton } from '@progress/kendo-react-buttons'


import styles from '../../styles/apoiogestao/ApoioGestao.module.scss'

import { GetUserNameTecnico, agDecisoes, agEstados, agTipoPretensoes, arhs, b64toBlob, capitania, concelhos, coordenadasPoligono, distritos, fillAgEstadosAsync, fillDecisoesAsync, fillTipoPretensoesAsync, freguesias, get, getPretensaoSelected, handleGenericError, hasPermission, massaagua, nuts1, nuts2, nuts3, post, put, refreshPesquisaSearch, setCoordenadasPoligono, setPretensaoSelected, useCombos } from '../../services/utils'
import { useAgPretensaoStore } from '../../stores/agPretensao'
import { AG_PRETENSAO_POINT_NAME, AUTH_LOCALSTORAGE, CONSULTAR_AG_PRETENSAO, CREATE_PRETENSAO, GET_PRETENSAO, GET_URL_FROM_FILE_DOC, IMPORT_GEOM_PRETENSAO, NIF_MAX_SIZE, NIF_MIN_LENGTH, PERMISSION_APOIO_GESTAOPERFIL_INTERMEDIO_EDICAO, PERMISSION_APOIO_GESTAO_PERFIL_COMPLETO_EDICAO, PERMISSION_APOIO_GESTAO_PERFIL_TOTAL, PERMISSION_ATUALIZAR_PRETENSAO, PERMISSION_INSERIR_PRETENSAO, PESQUISAR_AG_PRETENSAO, PESQUISAR_AG_PRETENSAO_BY_ID, RELATORIO_PRETENSAO, REMOVER_AREA_AG_PRETENSAO, UPDATE_PRETENSAO, URL_AJUDA_APOIO_GESTAO_PRETENSAO, clearLayers } from '../../utils/constants'
import { AssociacaoListagem } from '../associacao/Associacao'
import { DocumentoListagem } from '../documentos/registar/documento/documento'
import PretensaoRegistar from './registar/PretensaoRegistar'
import ImportarGeometriaComponent from './registar/ImportarGeometriaForm'
import { Dialog } from '@progress/kendo-react-dialogs'
import SessionStorageService from '../../services/SessionStorageService'
import useClearMap from '../../hooks/useClearMap'
import html2canvas from 'html2canvas'
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais'
import ReactGA from 'react-ga4';

type IProps = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
}

type PageState = {
  skip: number
  take: number
}


let _pdfExport: any;
const exportExcel = () => {
  _export.save();
};
let _export: any;
const exportPDF = () => {
  _pdfExport.save();
};
const TOTAL_TABS = 3

const initialDataState: PageState = { skip: 0, take: 10 }

export default function RegistarApoioGestaoForm({ onCloseClick }: IProps) {
  const [isMobile, setIsMobile] = useState(false);
  const [openImportModal, setOpenImportModal] = useState<any>()

  const [selected, setSelected] = useState<number>(0)

  const [canUpdate, setCanUpdate] = useState(false);
  const [canInsert, setCanInsert] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const [viewMode, setViewMode] = useState(false);

  const [percentLeftError, setPercentLeftError] = useState("21%");

  const [openRemoveAreaModal, setOpenRemoveAreaModal] = useState<any>()

  const selectAgPretensaoResultId: any = useAgPretensaoStore(
    (state) => state.selectAgPretensaoResultId
  )
  const selectAgPretensaoResult = useAgPretensaoStore(
    (state) => state.selectAgPretensaoResult
  )

  const selectedAgPretensaoResult: any = useAgPretensaoStore(
    (state) => state.selectedAgPretensaoResult
  ) 
  const selectedAgPretensaoResultId: any = useAgPretensaoStore(
    (state) => state.selectedAgPretensaoResultId
  )

    const getPretensaoById = async (id: any) =>
    await get<any, any>(`${PESQUISAR_AG_PRETENSAO_BY_ID}/` + id)
  const importGeomIntPretensao = async (data: any) =>
    await post<any, any>(`${IMPORT_GEOM_PRETENSAO}`, data)

  const getUrlFileDoc = async (data: any) => await get<any>(`${GET_URL_FROM_FILE_DOC}/` + encodeURIComponent(data));

  const removerAreaPretensao = async (data: any) =>
    await post<any, any>(`${REMOVER_AREA_AG_PRETENSAO}`, data)

  const handleSelect = async (e: TabStripSelectEventArguments) => {
    if (e.selected > 0 && !selectedAgPretensaoResult) {
      postMessage({type:"infoMsg", value: {message: "É necessário gravar primeiro."}})
      return;
    }
    setSelected(e.selected)
  }
  const savePretensao = async (data: any) =>
    await post<any, any>(`${CREATE_PRETENSAO}`, data)

  const relatorioPretensao = async (data: any) =>
    await post<any, any>(`${RELATORIO_PRETENSAO}`, data)

  const [deleteClicked, setDeleteClicked] = useState(false);

  const onItemClickExportar = async (event: any) => {

    let mapImage = ''

    if (!window.location.href.includes('localhost')) {
      const mapIframe = document.querySelector(
        '#iframeGeoPortal'
      ) as HTMLIFrameElement

      const mapIframeInnerContent =
        mapIframe.contentWindow?.document.querySelector(
          'div.ol-viewport'
        ) as HTMLElement;

      const mapBussula =  mapIframe.contentWindow?.document.querySelector(
        'button.fixedNorthArrowButton'
      ) as HTMLElement;

      mapIframeInnerContent.appendChild(mapBussula);
      
      const generatedImage = await html2canvas(mapIframeInnerContent);
      mapImage = generatedImage.toDataURL()
    }
    console.log(selectedAgPretensaoResult);

    try {
      postMessage({ type: 'showLoader' }, '*')

      const documentoBase64 = await relatorioPretensao({
        id: selectedAgPretensaoResult?.id,
        dados: {
          //REGISTO
          'REGISTO' : '',
          'Técnico:': selectedAgPretensaoResult?.utilizador?.name ?? '',
          'Nº Processo APA:': selectedAgPretensaoResult?.file_doc_numero ?? '',
          'Estado:': selectedAgPretensaoResult?.ag_estado?.descricao ?? '',
          'Data entrada:': selectedAgPretensaoResult?.dataEntrada ? new Date(selectedAgPretensaoResult?.dataEntrada).toLocaleDateString() : '',
          'Nº de processo:': selectedAgPretensaoResult?.numero_do_processo ?? '',
          'Tipo de pretensão:': selectedAgPretensaoResult?.ag_tipo_pretensao?.descricao ?? '',
          'Decisão:': selectedAgPretensaoResult?.ag_decisao?.descricao ?? '',
          'Data decisão:': selectedAgPretensaoResult?.data_decisao ? new Date(selectedAgPretensaoResult?.data_decisao).toLocaleDateString() : '',

          //LOCALIZAÇÃO
          'LOCALIZAÇÃO' : '',
          'Nuts1:': selectedAgPretensaoResult?.nuts1[0]?.nome ?? '',
          'Nuts2:': selectedAgPretensaoResult?.nuts2[0]?.nome ?? '',
          'Nuts3:': selectedAgPretensaoResult?.nuts3[0]?.nome ?? '',
          'Distritos:': selectedAgPretensaoResult?.distritos[0]?.nome ?? '',
          'Concelhos:': selectedAgPretensaoResult?.concelhos[0]?.nome ?? '',
          'Freguesias:': selectedAgPretensaoResult?.lstFreguesias[0]?.nome ?? '',

          //IDENTIFICAÇÃO
          'IDENTIFICAÇÃO' : '',
          'Data de registo:': selectedAgPretensaoResult?.data_registo ? new Date(selectedAgPretensaoResult?.data_registo).toLocaleDateString() : '',
          'Assunto:': selectedAgPretensaoResult?.assunto ?? '',

          //REQUERENTE
          'REQUERENTE' : '',
          'NIF de requerente:': selectedAgPretensaoResult?.requerente_nif ?? '',
          'Nome de requerente:': selectedAgPretensaoResult?.requerente_nome ?? '',

          //OBSERVAÇÕES
          'OBSERVAÇÕES' : '',
          'Observações:': selectedAgPretensaoResult?.observacoes ?? '',
        },       
        img_mapa: mapImage
      });

      if (documentoBase64) {
        const link = document.createElement('a')
        link.href = 'data:application/octet-stream;base64,' + documentoBase64
        link.download = 'reporte_pretensão.pdf'
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        postMessage({ type: 'hideLoader' }, '*')
      }
    } catch (error) {
      postMessage({ type: 'errorMsg', value: { message: "Não foi possivel efetuar esta operação" } }, '*')
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }

  };

  useEffect(() => {
    
    ReactGA.send({ hitType: "pageview", page: "/RegistarApoioAGestaoPretensao" });

  }, []);

  useEffect(() => {
    setCanInsert(
      hasPermission(PERMISSION_INSERIR_PRETENSAO) ||
      hasPermission(PERMISSION_APOIO_GESTAOPERFIL_INTERMEDIO_EDICAO) ||
      hasPermission(PERMISSION_APOIO_GESTAO_PERFIL_COMPLETO_EDICAO) ||
      hasPermission(PERMISSION_APOIO_GESTAO_PERFIL_TOTAL)
      );
    setCanUpdate(
      hasPermission(PERMISSION_ATUALIZAR_PRETENSAO) ||
      hasPermission(PERMISSION_APOIO_GESTAOPERFIL_INTERMEDIO_EDICAO) ||
      hasPermission(PERMISSION_APOIO_GESTAO_PERFIL_COMPLETO_EDICAO) ||
      hasPermission(PERMISSION_APOIO_GESTAO_PERFIL_TOTAL)
    );
  }, []);

  const selectMapAction =  (item: any) => {
    const iframe: any = document.getElementById('iframeGeoPortal')

    if (item.key == "removerArea") {
      setOpenRemoveAreaModal(true)
    } else {
      iframe.contentWindow?.postMessage({ type: item.key, value: getPretensaoSelected()?.id, editGpLayer: AG_PRETENSAO_POINT_NAME }, '*')
    }
  }

const removeArea = async () => {
  const iframe: any = document.getElementById('iframeGeoPortal')
  iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: 'true' }, '*')
  setCoordenadasPoligono(undefined);
  if (!selectedAgPretensaoResult?.id) {
    setOpenRemoveAreaModal(false)
    return;
  }
  await removerAreaPretensao(selectedAgPretensaoResult?.id);
  
  iframe.contentWindow?.postMessage(
    { type: 'SiarlPretensaoSearch', value: [selectedAgPretensaoResult?.id] },
    '*'
  )
  refreshForm(selectedAgPretensaoResult?.id)
  setOpenRemoveAreaModal(false)
}
  const updatePretensao = async (data: any) =>
    await put<any, any>(`${UPDATE_PRETENSAO}`, data)
  
  const getPretensao = async (data: any) =>
    await get<any>(`${GET_PRETENSAO}/` + data)

  const formIsValid = (values: any) => {
    if (!values) {
      return;
    }
    if (!values?.assunto || !values.assunto.trim()
    ) {
      postMessage({ type: 'errorMsg', value: { message: "Preencha os campos obrigatórios", left : percentLeftError } }, '*');
       
      return false;
    }
    if (values.nifRequerente) {
      const nif = Number.parseInt(values.nifRequerente);
      if (nif > NIF_MAX_SIZE ||
        values.nifRequerente.length < NIF_MIN_LENGTH || nif == 0) {
        postMessage({ type: 'errorMsg', value: { message: "NIF inválido" } }, '*');
        return false;
      }
    }
    return true;
  }

   const importGeo = async (objToSend: any) => {
    postMessage({ type: "showLoader" }, "*");
    if (!objToSend) {
      postMessage({ type: "errorMsg", value: "Não foi possivel salvar" }, "*");
      postMessage({ type: "hideLoader" }, "*");
      return;

    }
    let pretensao;
    let pretensao_id;
    const pretensaoSelected = selectedAgPretensaoResult ?? getPretensaoSelected();
    if (pretensaoSelected && pretensaoSelected?.id) {
      pretensao = pretensaoSelected;
      pretensao_id = pretensaoSelected?.id;
    } else {
      pretensao = await savePretensao({});
      pretensao_id = pretensao?.id;
      selectAgPretensaoResult(pretensao);
      selectAgPretensaoResultId(pretensao_id);

    }
    try {
      objToSend = { ...objToSend, pretensao_id }
      const result = await importGeomIntPretensao(objToSend)
      if (!result) {
        postMessage({ type: "errorMsg", value: "Não foi possivel salvar" }, "*");
        return;
      }

      try {
        pretensao = await getPretensaoById(pretensao_id);
      } catch (error) {
        handleGenericError(error);

      } finally {
        pretensao = { ...pretensao, ...objToSend };
        selectAgPretensaoResultId(pretensao_id);
        selectAgPretensaoResult(pretensao);
        setPretensaoSelected(pretensao)
      }
      
      try {
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage(
          { type: 'SiarlPretensaoSearch', value: [pretensao_id] },
          '*'
        )
      } catch (error) {
        handleGenericError(error);
      }

      setOpenImportModal(false)
      refreshForm(pretensao_id);
    } catch (err) {
      handleGenericError(err);

    } finally {
      postMessage({ type: "hideLoader" }, "*");
    }

  }

  const [openModalDescartar, setOpenModalDescartar] = useState<any>()

  const cancelModalDescartar = () => {
    setOpenModalDescartar(false)
  }

  const handleOpenModalDescartar = () => {
    if (
      SessionStorageService.getItem('novaPretensao') &&
      SessionStorageService.getItem('novaPretensao') == 'true'
    ) {
      setOpenModalDescartar(true);
    } else {
      onEditItemClick("SiarlVisualizar");
      handleCancel();   
   }
  }

  const handleCancel = ()  => {
    refreshForm();
    postMessage({ type: "form", value: "" }, "*");
    setTimeout(() => {
      postMessage({ type: "form", value: "registarApoioGestaoPretensao" }, "*");
    }, 10);
}

  const refreshForm = async(id: any = undefined) => {
    let pretensaoId = id;
    if (!id) {
      if (selectedAgPretensaoResult?.id) {
        pretensaoId = selectedAgPretensaoResult?.id;
      } else {
        return;
      }
    }

    refreshPesquisaSearch("resultadoApoioGestaoPretensao", "SiarlPretensaoSearch");
    const pretensao = await getPretensao(pretensaoId);
    const tecnico = await GetUserNameTecnico(pretensao.utilizador_id);
    

    const objToShow = {
      ...pretensao,
      decisao: agDecisoes.filter((x: any) => x.id == pretensao.ag_decisao_id)[0],
      id: pretensao.id,
      file_doc_numero: pretensao.file_doc_numero,
      numeroProcesso: pretensao.numero_do_processo,
      nifRequerente: pretensao.requerente_nif,
      nomeRequerente: pretensao.requerente_nome,
      dataHoraRegisto: new Date(pretensao.data_registo),
      assunto: pretensao.assunto,
      estado: agEstados.filter((x: any) => x.id == pretensao.ag_estado_id)[0],
      tipoPretensao: agTipoPretensoes.filter((x: any) => x.id == pretensao.ag_tipo_pretensao_id)[0],
      dataEntrada: new Date(pretensao.data_entrada),
      data_decisao: new Date(pretensao.data_decisao),
      local: pretensao.local,
      obs: pretensao.observacoes,
      geom_imported: pretensao.geom_imported,
      tecnicoRegisto: tecnico
    }
    selectAgPretensaoResult(objToShow);
    setPretensaoSelected(objToShow);
  }

  const [estadoFalse, setEstado] = useState(false)
  const [estadoTrue, setEstadoTrue] = useState(true)


  const handleDesativar = async (e: any) => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
       {
         type: 'SiarlCoordenadaBoxClose',
         value: 'true',
       },
       '*'
     )
     iframe.contentWindow?.postMessage(
      {
        type: 'SiarlRemoveSelection',
        value: 'true',
      },
      '*'
    )
  setOpenModalDescartar(false)
  setDeleteClicked(false);
   const pretensaoSelected = getPretensaoSelected()

    const dataToSend : any = {
      id: pretensaoSelected?.id ?? undefined,
      status: estadoFalse
    }

      let PretensaoSaved: any
   
    if (pretensaoSelected?.id) {
          postMessage({ type: 'showLoader' }, '*')
          PretensaoSaved = await updatePretensao(dataToSend)
          postMessage({ type: 'hideLoader' }, '*')
          selectAgPretensaoResult({
            ...selectedAgPretensaoResult,
            ...PretensaoSaved,})
          }
    SessionStorageService.removeItem("firstCallAgPretensao");
    postMessage({ type: 'form', value: 'pesquisarApoioGestaoPretensao' }, '*')


}

  const isSubmit = () => {
    if (selected === TOTAL_TABS) {
      return true
    } else {
      return false
    }
  }

  const userId = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '')?.user?.id
  const userName = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '')?.user?.name

  const handleSubmit = async (e: any, isSubmitAction?: boolean) => {

    const userId = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '')?.user?.id
    if (selected == 0 && !formIsValid(e.values)) {
      return false;
    }

    const pretensaoRequest: any = {
      id: selectedAgPretensaoResult?.id ?? undefined,
      utilizador_id: selectedAgPretensaoResult?.utilizador_id ? selectedAgPretensaoResult?.utilizador_id : userId,
      ag_estado_id: e?.values.estado ? e?.values.estado?.id : undefined,
      data_registo: e?.values.data_registo,
      data_decisao: e?.values?.data_decisao ?? undefined,
      nif_requerente: e?.values?.nifRequerente,
      nome_requerente: e?.values?.nomeRequerente,
      data_entrada: e?.values?.dataEntrada,
      numero_do_processo: e?.values?.numeroProcesso,
      assunto: e?.values?.assunto,
      local: e?.values.local,
      ag_tipo_pretensao_id: e?.values.tipoPretensao ? e?.values.tipoPretensao?.id : undefined,
      requerente_nif: e?.values.nifRequerente ?? undefined,
      requerente_nome: e?.values.nomeRequerente ?? undefined,
      file_doc_numero: e?.values.file_doc_numero,
      ag_decisao_id: e?.values.decisao ? e?.values.decisao?.id : undefined,
      observacoes: e?.values?.obs,
      flatCoordinates: coordenadasPoligono,
      geom_imported: selectedAgPretensaoResult?.geom_imported ?? undefined
    }
    try {
      let pretensao: any;
      postMessage({ type: 'showLoader' }, '*')
      if (selectedAgPretensaoResult?.id) {
        pretensao = await updatePretensao(pretensaoRequest)
      } else {
        pretensao = await savePretensao(pretensaoRequest)
      }
      refreshForm(pretensao.id);
      selectAgPretensaoResultId(pretensao?.id as number);

      postMessage({ type: 'sucessMsg' }, '*')      
    } catch (error) {
      handleGenericError(error);
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }
  const innerStyleTabContent = {
    scrollBehavior: 'smooth',
    top: 55,
    height: '83vh',
  }
  const voltar = () => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
       {
         type: 'SiarlCoordenadaBoxClose',
         value: 'true',
       },
       '*'
     )
    clearPretensaoMap();
    postMessage({ type: 'form', value: 'pesquisarApoioGestaoPretensao' }, '*')
  }


  const [clearMap] = useClearMap();
  const clearPretensaoMap = () => {
    clearMap(clearLayers["CLEAR_LAYERS_PRETENSAO"]);
    setPretensaoSelected(undefined)
    selectAgPretensaoResult(undefined)
  }

  const onItemClick = (event: any) => {
    
    if(event?.item?.key == "ImportGeom"){
    ImportGeom(event.item)
   }
   else{
    selectMapAction(event.item)
   }
    
  }
  const optionsArea = [
    {
      key: 'SiarlGeoPortalAdicionarArea',
      text: 'Desenhar Área',
    },
    {
      key: 'SiarlEditarGeometria',
      text: 'Editar Área',
    },
    {
      key: 'removerArea',
      text: 'Remover Área',
    },
    {
      key: 'ImportGeom',
      text: 'Importar Geom',
    }, 
  ]

  const optionsEditar = [
    {
      key: 'SiarlEditar',
      text: 'Editar',
    },
    {
      key: 'SiarlApagar',
      text: 'Apagar',
    },
    
  ]

  const onItemClickImprimir = async (event: any) => {

    let mapImage = ''

    if (!window.location.href.includes('localhost')) {
      const mapIframe = document.querySelector(
        '#iframeGeoPortal'
      ) as HTMLIFrameElement

      const mapIframeInnerContent =
        mapIframe.contentWindow?.document.querySelector(
          'div.ol-viewport'
        ) as HTMLElement;

      const mapBussula =  mapIframe.contentWindow?.document.querySelector(
        'button.fixedNorthArrowButton'
      ) as HTMLElement;

      mapIframeInnerContent.appendChild(mapBussula);
      
      const generatedImage = await html2canvas(mapIframeInnerContent);
      mapImage = generatedImage.toDataURL()
    }
    console.log(selectedAgPretensaoResult);

    try {
      postMessage({ type: 'showLoader' }, '*')

      const documentoBase64 = await relatorioPretensao({
        id: selectedAgPretensaoResult?.id,
        dados: {
          //REGISTO
          'REGISTO' : '',
          'Técnico:': selectedAgPretensaoResult?.utilizador?.name ?? '',
          'Nº Processo APA:': selectedAgPretensaoResult?.file_doc_numero ?? '',
          'Estado:': selectedAgPretensaoResult?.ag_estado?.descricao ?? '',
          'Data entrada:': selectedAgPretensaoResult?.dataEntrada ? new Date(selectedAgPretensaoResult?.dataEntrada).toLocaleDateString() : '',
          'Nº de processo:': selectedAgPretensaoResult?.numero_do_processo ?? '',
          'Tipo de pretensão:': selectedAgPretensaoResult?.ag_tipo_pretensao?.descricao ?? '',
          'Decisão:': selectedAgPretensaoResult?.ag_decisao?.descricao ?? '',
          'Data decisão:': selectedAgPretensaoResult?.data_decisao ? new Date(selectedAgPretensaoResult?.data_decisao).toLocaleDateString() : '',

          //LOCALIZAÇÃO
          'LOCALIZAÇÃO' : '',
          'Nuts1:': selectedAgPretensaoResult?.nuts1[0]?.nome ?? '',
          'Nuts2:': selectedAgPretensaoResult?.nuts2[0]?.nome ?? '',
          'Nuts3:': selectedAgPretensaoResult?.nuts3[0]?.nome ?? '',
          'Distritos:': selectedAgPretensaoResult?.distritos[0]?.nome ?? '',
          'Concelhos:': selectedAgPretensaoResult?.concelhos[0]?.nome ?? '',
          'Freguesias:': selectedAgPretensaoResult?.lstFreguesias[0]?.nome ?? '',

          //IDENTIFICAÇÃO
          'IDENTIFICAÇÃO' : '',
          'Data de registo:': selectedAgPretensaoResult?.data_registo ? new Date(selectedAgPretensaoResult?.data_registo).toLocaleDateString() : '',
          'Assunto:': selectedAgPretensaoResult?.assunto ?? '',

          //REQUERENTE
          'REQUERENTE' : '',
          'NIF de requerente:': selectedAgPretensaoResult?.requerente_nif ?? '',
          'Nome de requerente:': selectedAgPretensaoResult?.requerente_nome ?? '',

          //OBSERVAÇÕES
          'OBSERVAÇÕES' : '',
          'Observações:': selectedAgPretensaoResult?.observacoes ?? '',
        },       
        img_mapa: mapImage
      });

      if (documentoBase64) {
        const blob = b64toBlob(documentoBase64, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
        postMessage({ type: 'hideLoader' }, '*')
      }
    } catch (error) {
      postMessage({ type: 'errorMsg', value: { message: "Não foi possivel efetuar esta operação" } }, '*')
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }

  };
  const itemRender = (props: { item: any; itemIndex: number }) => {
    const isTitleItem = props.item.key === 'titulo';
    if (isTitleItem) {
      // Item de título personalizado
      return (
        <div className={styles.DropDownButtonTitulo}>
          <span>{props.item.text}</span>
        </div>
      );
    } else {
      // Demais itens
      return (
        <div className={styles.DropDownButtonItem}>
          <span>{props.item.text}</span>
        </div>
      );
    }
  };

  const optionsImprimir = [
    {
      key: 'imprimirFichas',
      text: 'Fichas',
    },
    {
      key: 'imprimirResultados',
      text: 'Resultados',
    },
  ]

  const optionsExportar = [
    {
      key: 'exportarFichas',
      text: 'Fichas',
    },
    {
      key: 'exportarResultados',
      text: 'Resultados',
    },
  ]

  const importarGeometria = [
    {
      key: 'ImportGeom',
      text: 'Importar Geom',
    },
  ]

  const cancelImport = () => {
    setOpenImportModal(false)
  }
  
  const ImportGeom = async (item: any) => {
    if (item.key == 'ImportGeom') {
      setOpenImportModal(true)
    }
  }


  useEffect(() => {
    setViewMode(!editMode && SessionStorageService.getItem("novaPretensao") != 'true');
  }, [])

  useEffect(() => {
    if (viewMode) {
      SessionStorageService.removeItem("novaPretensao");
    }
  }, [viewMode])

  const onEditItemClick= (event:any) => {
    if (event == "SiarlEditar" || event?.item?.key == "SiarlEditar") {
      setEditMode(true);
      setViewMode(false);
      handleAlteraTitulo();
      return;
    }
    if (event == "SiarlVisualizar") {
      setEditMode(false);
      setViewMode(true);
    }
    if (event?.item?.key == "SiarlApagar") {
      setDeleteClicked(true);
      setOpenModalDescartar(true);
    }
  }

  const onClickOpenFileDoc = async(e: any) => {
    const pretensaoSelected = getPretensaoSelected();
    if (!pretensaoSelected?.file_doc_numero) {
      postMessage({ type: "infoMsg", value: {message: "É necessário preencher o nº processo APA (filedoc)."} }, "*");
      return;
    }
    postMessage({ type: 'showLoader' }, '*');
    const urlFileDoc = await getUrlFileDoc(pretensaoSelected?.file_doc_numero);
    postMessage({ type: 'hideLoader' }, '*')
    window.open(urlFileDoc, '_blank');
    return;
  }
  
  useLayoutEffect(() => {

    const handleResize = () => {

      if (window.innerWidth <= telaMobile) {
        
        setIsMobile(true);
        ocultaFloatingMenu_e_Mapa();

      }else{

        setIsMobile(false);
        mostraFloatingMenu_e_Mapa();

      }

    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);  

  const onItemClickMais = (event: any) => {

    elementoClickMais(event);

    onEditItemClick(event);
    onItemClick(event);
    
    if(optionsExportar.some(option => option.key === event.item.key)){
      onItemClickExportar(event);
    }

  };

  const optionsMais = [
    {
      key: 'titulo',
      text: 'Registo',
    },
    ...optionsEditar.map(option => ({
      ...option,
      className: 'icon-siarl icon-editar btnIconRegistar',
    })),
    {
      key: 'titulo',
      text: 'Opções',
    },
    {
      key: 'openFileDoc',
      text: 'Diretório de documentos',
      className: 'icon-siarl icon-filedoc btnIconRegistar'
    },
    {
      key: 'titulo',
      text: 'Geometria',
    },
    {
      key: 'titulo',
      text: 'Área',
    },
    ...optionsArea.map(option => ({
      ...option,
      className: 'icon-siarl icon-area btnIconRegistar',
    })),
    {
      key: 'titulo',
      text: 'Exportar',
    },
    ...optionsExportar.map(option => ({
      ...option,
      className: 'icon-siarl icon-exportar btnIconRegistar',
    })),
  ]

  const [tituloPretensao, setTituloPretensao] = useState(false);

  const handleAlteraTitulo = () => {
    setTituloPretensao(true);
  };

  const openAjuda = () => {
    window.open(URL_AJUDA_APOIO_GESTAO_PRETENSAO, "_blank")
  }

  return (

    <div className={styles.base}>
      <div className={styles.header}>
        <div>
          <div className={styles.nomeDoModulo}>
            Apoio à Gestão
          </div>
          <div className={styles.tituloDaPagina}>
            {!selectedAgPretensaoResult || !selectedAgPretensaoResult?.id ? 'Nova pretensão' : (tituloPretensao ? 'Editar Pretensão' : 'Pretensão')}

          </div>
        </div>
        <div>

        {!isMobile && canUpdate && (viewMode || editMode) && (SessionStorageService.getItem("novaPretensao") != 'true') ? 
          <DropDownButton
            items={optionsEditar}
            className={styles.btnEditar2}
            iconClass="icon-siarl icon-editar"
            //onItemClick={() => {
            //  onEditItemClick;
            //  handleAlteraTitulo()
            //}}
            onItemClick={onEditItemClick}
            fillMode={'flat'}
            itemRender={itemRender}
            title="Editar"
          ></DropDownButton>
          : ''}

          <button className={styles.btnEditar2}  title='Filedoc' onClick={onClickOpenFileDoc}
          style={{ display: isMobile ? 'none' : '' }} id='openFileDoc'>
            <span className="icon-siarl icon-filedoc"></span>
          </button>

        {!isMobile &&
          <DropDownButton items={optionsArea} className={styles.btnEditar2}
            iconClass='icon-siarl icon-area'
            onItemClick={onItemClick}
            fillMode={"flat"}
            title='Geometria - Área'
           disabled={viewMode}
            itemRender={itemRender}
          >
          </DropDownButton>
          }

          {/* <button className={styles.btnEditar2} onClick={onItemClickImprimir}
          style={{ display: isMobile ? 'none' : '' }} id='imprimirFicha' title='Imprimir'>
            <span className="icon-siarl icon-imprimir"></span>
          </button> */}

          <button className={styles.btnImprimir} title='Exportar' onClick={onItemClickExportar}
           style={{ display: isMobile ? 'none' : '' }} id='exportarFicha'>
            <span className="icon-siarl icon-exportar"></span>
          </button>

      

        {isMobile &&
            <DropDownButton
              items={optionsMais}
              className={styles.btnMais}
              iconClass="icon-siarl icon-mais"
              onItemClick={onItemClickMais}
              fillMode={'flat'}
              itemRender={itemRenderMais}
              title="Mais Opções"
            />
          }

          <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
            <span className="icon-siarl icon-ajuda"></span>
          </button>

          <button className={styles.btnFechar} onClick={voltar} title="Fechar">
            <span className="icon-siarl icon-fechar"></span>
          </button>

        </div>
      </div>

        {openImportModal ?
        <ImportarGeometriaComponent onSubmit={importGeo} cancelImport={cancelImport} /> : <></>
      }

{openRemoveAreaModal ? (
               <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={() => setOpenRemoveAreaModal(false)}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                    <span className="textDescartar">Remover área </span>
                    <br></br>
                    <span>{'Tem a certeza que pretende remover a área selecionada?'}</span>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={() => setOpenRemoveAreaModal(false)}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={removeArea}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}


      {openModalDescartar ? (
          <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={cancelModalDescartar}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                  <fieldset>
                    <span className="textDescartar">{deleteClicked ? 'Apagar' : 'Cancelar'} registo  </span>
                    <br></br>
                    <span>{deleteClicked ? 'Ao apagar este registo deixará de ter acesso, sendo necessária uma intervenção rigorosa para realizar a sua recuperação.' : 'Ao cancelar este registo irá perder os dados inseridos até ao momento.'}</span>
                      <br></br>
                    <span>{deleteClicked ? 'Tem a certeza que pretende apagar?' : 'Tem a certeza que pretende cancelar?'}</span>
                  </fieldset>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={cancelModalDescartar}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={handleDesativar}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}
      <Form
        onSubmitClick={handleSubmit}
        initialValues={{
          ...selectedAgPretensaoResult,
          data_registo: selectedAgPretensaoResult?.data_registo
          ? new Date(selectedAgPretensaoResult?.data_registo)
          : new Date(),           
          data_decisao: selectedAgPretensaoResult?.data_decisao ? new Date(selectedAgPretensaoResult?.data_decisao) : undefined,      
          dataEntrada: selectedAgPretensaoResult?.data_entrada ? new Date(selectedAgPretensaoResult?.data_entrada) : undefined,      
              utilizador_registo: selectedAgPretensaoResult?.utilizador ? selectedAgPretensaoResult?.utilizador.name : userName
        } ?? undefined}
        render={(formRenderProps) => (
          <FormElement>
            <TabStrip
              selected={selected}
              onSelect={handleSelect} 
              className={`${styles.innerStyleTabContent} ${viewMode? 'tabsVisualizar' : ''}`}
              scrollable={isMobile}
            >
              <TabStripTab title="Pretensão">
                <PretensaoRegistar viewMode={viewMode} onCloseClick={onCloseClick} />
              </TabStripTab>
              <TabStripTab title="Documentos">
                <DocumentoListagem viewMode={viewMode} pageName='pretensao' />
              </TabStripTab>

              <TabStripTab title="Associação">
                <AssociacaoListagem viewMode={viewMode} pageName='pretensao'/>
              </TabStripTab>
            </TabStrip>
            {(editMode || SessionStorageService.getItem("novaPretensao") == 'true')
                  && !viewMode ?
            <div className={`${styles.footer} ${styles.justify_multiple_children}`}>

                <Button type="button" className={styles.btnSeguinte} onClick={handleOpenModalDescartar} title='Cancelar'>
                  <span className="icon-siarl icon-fechar"></span>
                  <span className={styles.rotulo}>
                    {' '}&nbsp;{' '}
                    Cancelar
                  </span>
                 </Button>
                <Button
                  className={styles.btnSubmeter}
                  onClick={(e) => {
                    handleSubmit(e, true);
                  }}
                  title='Gravar'
                >
                  <span className="icon-siarl icon-submeter btnIconRegistar"></span>
                  <span className={styles.rotulo}>
                    {' '}&nbsp;{' '}
                    Gravar
                  </span>
                </Button>
              
            </div> : '' }
          </FormElement>
        )}
      />
    </div>


  )
}
