import { Button } from '@progress/kendo-react-buttons'
import { DateTimePicker } from '@progress/kendo-react-dateinputs'
import { ComboBox, ComboBoxFilterChangeEvent } from '@progress/kendo-react-dropdowns'
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from '@progress/kendo-react-form'

import {
  MouseEvent as ReactMouseEvent,
  useCallback,
  useEffect,
  useState,
  useLayoutEffect,
} from 'react'

import {
  Checkbox,
  Input,
  RadioButton,
  RadioButtonChangeEvent,
  RadioGroup,
} from '@progress/kendo-react-inputs'
import SessionStorageService from '../../services/SessionStorageService'
import {
  arhs,
  capitania,
  concelhos,
  coordenadasPoligono,
  distritos,
  entidades,
  estados,
  fillArhsAsync,
  fillCapitaniaAsync,
  fillCombosAuxiliares,
  fillConcelhosAsync,
  fillDistritosAsync,
  fillEntidadesAsync,
  fillEstadosProcessoAsync,
  fillFreguesiasAsync,
  fillMassaAguaAsync,
  fillNuts1Async,
  fillNuts2Async,
  fillNuts3Async,
  fillPraiasAsync,
  fillTecnicosAsync,
  freguesias,
  get,
  handleGenericError,
  massaagua,
  nuts1,
  nuts2,
  nuts3,
  ocEstados,
  post,
  praias,
  setCoordenadasPoligono,
  tecnicos,
  useCombos
} from '../../services/utils'
import styles from '../../styles/ocorrencia/ConsultarOcorrenciaForm.module.scss'
import { AREA_VISIVEL, AUTH_LOCALSTORAGE, GET_TECNICOS, NENHUM, PESQUISAR_ARHS, PESQUISAR_CONCELHOS, PESQUISAR_DISTRITOS, PESQUISAR_ENTIDADES, PESQUISAR_FREGUESIAS, PESQUISAR_MASSADAGUA, PESQUISAR_NUTS1, PESQUISAR_NUTS2, PESQUISAR_NUTS3, PESQUISAR_OCORRENCIAS_AVANCADA, PESQUISAR_PRAIAS, POLIGONO_SELECAO } from '../../utils/constants'
import { FilterDescriptor, filterBy } from '@progress/kendo-data-query'
import FieldsetCombosLocalizacao from '../localizacao/FieldsetCombosLocalizacao'
import { checkboxCheckedIcon } from '@progress/kendo-svg-icons'
import { mostraFloatingMenu_e_Mapa_e_Oculta_Form, telaMobile } from '../common/ItemRenderMais'
import ConsultarOcorrenciaTabsForm from './ConsultarOcorrenciaTabsForm'

type Props = {
  onCloseClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | undefined;
};

export default function ConsultarOcorrenciaAvancadaForm({onCloseClick,}: Props) {
  const [isMobile, setIsMobile] = useState(false);
  const [dataOcorrenciaInicio, setDataOcorrenciaInicio] = useState<any>()
  const [dataRegistoInicio, setDataRegistoInicio] = useState<any>()
  const [dataOcorrenciaFim, setDataOcorrenciaFim] = useState<any>()
  const [dataRegistoFim, setDataRegistoFim] = useState<any>()
  const [freguesiasFiltred, setFreguesiasFiltred] = useState<any>()
  const [pessoasRiscoSelected, setPessoasRiscoSelected] = useState<any>()
  const [registoVisible, setRegistoVisible] = useState(true)
  const [criterioGeograficoVisible, setCriterioGeograficoVisible] =
    useState(false)
  const [identificacaoVisible, setIdentifcacaoVisible] = useState(false)
  const [localizacaoVisible, setLocalizacaoVisible] = useState(false)
  const [tipologiaVisible, setTipologiaVisible] = useState(false)
  const [outrosCriteriosVisible, setOutrosCriteriosVisible] = useState(false)
  const [danosSistemasNaturaisSelected, setDanosSistemasNaturaisSelected] =
    useState<any>()
  const [rbCriteriosSelected, setRBCriteriosSelected] = useState<any>()
  const [concelhosFiltred, setConcelhosFiltred] = useState<any>()
  const [concelhosDisabled, setConcelhosDisabled] = useState<any>(true)
  const [freguesiasDisabled, setFreguesiasDisabled] = useState<any>(true)
  const [nuts2Filtered, setNuts2Filtered] = useState<any>()
  const [nuts2Disabled, setNuts2Disabled] = useState<any>(true)
  const [nuts3Disabled, setNuts3Disabled] = useState<any>(true)
  const [nuts3Filtered, setNuts3Filtered] = useState<any>()
  const [freguesiasData, setFreguesiasData] = useState(freguesias ? freguesias.slice() : []);
  const [concelhoData, setConcelhoData] = useState(concelhos ? concelhos.slice() : []);
  const [distritoData, setDistritoData] = useState(distritos ? distritos.slice() : []);
  const [nuts1Data, setNuts1Data] = useState(nuts1 ? nuts1.slice() : []);
  const [nuts2Data, setNuts2Data] = useState(nuts2 ? nuts2.slice() : []);
  const [nuts3Data, setNuts3Data] = useState(nuts3 ? nuts3.slice() : []);
  const [entidadesData, setEntidadesData] = useState(entidades ? entidades.slice() : []);
  const [arhsData, setArhsData] = useState(arhs ? arhs.slice() : []);
  const [ocEstadosData, setOcEstadosData] = useState(ocEstados ? ocEstados.slice() : []);
  const [tecnicosData, setTecnicosData] = useState(tecnicos ? tecnicos.slice() : []);

  const [distritosDisabled, setDistritosDisabled] = useState<any>(false);
  const getOcorrencias = async (data: any) =>
    await post<any, any>(`${PESQUISAR_OCORRENCIAS_AVANCADA}`, data)

    const getNuts1 = async () => get<any>(`${PESQUISAR_NUTS1}`);
    const getNuts2 = async () => get<any>(`${PESQUISAR_NUTS2}`);
    const getNuts3 = async () => get<any>(`${PESQUISAR_NUTS3}`);
    const getDistritos = async () => get<any>(`${PESQUISAR_DISTRITOS}`);
    const getFreguesias = async () => get<any>(`${PESQUISAR_FREGUESIAS}`);
    const getConcelhos = async () => get<any>(`${PESQUISAR_CONCELHOS}`);
    const getArhs = async () => get<any>(`${PESQUISAR_ARHS}`);
    const getPraias = async () => get<any>(`${PESQUISAR_PRAIAS}`);
    const getEntidades = async () => get<any>(`${PESQUISAR_ENTIDADES}`);
    const getTecnicos = async () => get<any>(GET_TECNICOS);
    const getMassaAgua = async () => get<any>(`${PESQUISAR_MASSADAGUA}`);
    const [minData, setMinDate] = useState<any>();

    const getCombos = async () => {
    const [nuts1, 
      nuts2, 
      nuts3, 
      distritos, 
      freguesias, 
      concelhos, 
      arhs, 
      praias,
    entidades,
  tecnicos,
massasAgua] = await Promise.all([
      getNuts1(),
      getNuts2(),
      getNuts3(),
      getDistritos(),
      getFreguesias(),
      getConcelhos(),
      getArhs(),
      getPraias(),
      getEntidades(),
      getTecnicos(),
      getMassaAgua()
    ]);
    console.log(nuts1);
    console.log(nuts2);
    console.log(nuts3);
    return { nuts1, 
      nuts2, 
      nuts3, 
      distritos, 
      freguesias, 
      concelhos, 
      arhs, 
      praias, 
      entidades,
      tecnicos,
    massasAgua };
  }

  const CRITERIO_AREA_VISIVEL = 1
  const CRITERIO_GEOGRAFICO_VALUE = 2

  useEffect(() => {
    if (!nuts1 || !nuts2 || !nuts3 || !distritos || !freguesias || !concelhos || !arhs || !tecnicos)
    getCombos().then(result => {
      setNuts1Data(result.nuts1);
      setNuts2Data(result.nuts2);
      setNuts3Data(result.nuts3);
      setDistritoData(result.distritos);
      setFreguesiasData(result.freguesias);
      setConcelhoData(result.concelhos);
      setArhsData(result.arhs);
      setEntidadesData(result.entidades);
      setTecnicosData(result.tecnicos);
      postMessage({type: "hideLoader"}, "*");
    });
 }, []);

  useEffect(() => {
    setFreguesiasFiltred(freguesias)
    setConcelhosFiltred(concelhos)
    setNuts2Filtered(nuts2)
    setNuts3Filtered(nuts3)
    SessionStorageService.setItem("ocorrenciasPesquisaTipo", "Avancada");
  },[]);

  
  
  const toggleRegisto = () => {
    setRegistoVisible(!registoVisible)
  }
  const toggleCriterioGeografico = () => {
    setCriterioGeograficoVisible(!criterioGeograficoVisible)
  }

  const toggleIdentificacao = () => {
    setIdentifcacaoVisible(!identificacaoVisible)
  }

  const toggleTipologia = () => {
    setTipologiaVisible(!tipologiaVisible)
  }

  const toggleLocalizacao = () => {
    setLocalizacaoVisible(!localizacaoVisible)
  }

  const toggleOutrosCriterios = () => {
    setOutrosCriteriosVisible(!outrosCriteriosVisible)
  }

  const filterDataFreguesia = (filter: FilterDescriptor) => {
    const data = freguesiasFiltred.slice();
    return filterBy(data, filter);
  };

  const filterChangeFreguesia = (event: ComboBoxFilterChangeEvent) => {
    setFreguesiasData(filterDataFreguesia(event.filter));
  };

  const filterDataConcelho = (filter: FilterDescriptor) => {
    const data = concelhosFiltred.slice();
    return filterBy(data, filter);
  };

  const filterChangeConcelho = (event: ComboBoxFilterChangeEvent) => {
    setConcelhoData(filterDataConcelho(event.filter));
  };

  const filterDataDistrito = (filter: FilterDescriptor) => {
    const data = distritos.slice();
    return filterBy(data, filter);
  };

  const filterChangeDistrito = (event: ComboBoxFilterChangeEvent) => {
    setDistritoData(filterDataDistrito(event.filter));
  };

  const filterDataNuts1 = (filter: FilterDescriptor) => {
    const data = nuts1.slice();
    return filterBy(data, filter);
  };

  const filterChangeNuts1 = (event: ComboBoxFilterChangeEvent) => {
    setNuts1Data(filterDataNuts1(event.filter));
  };

  const filterDataNuts2 = (filter: FilterDescriptor) => {
    const data = nuts2Filtered.slice();
    return filterBy(data, filter);
  };

  const filterChangeNuts2 = (event: ComboBoxFilterChangeEvent) => {
    setNuts2Data(filterDataNuts2(event.filter));
  };

  const filterDataNuts3 = (filter: FilterDescriptor) => {
    const data = nuts3Filtered.slice();
    return filterBy(data, filter);
  };

  const filterChangeNuts3 = (event: ComboBoxFilterChangeEvent) => {
    setNuts3Data(filterDataNuts3(event.filter));
  };

  const filterDataEntidades = (filter: FilterDescriptor) => {
    const data = entidades.slice();
    return filterBy(data, filter);
  };

  const filterChangeEntidades = (event: ComboBoxFilterChangeEvent) => {
    setEntidadesData(filterDataEntidades(event.filter));
  };

  const filterDataArhs = (filter: FilterDescriptor) => {
    const data = arhs.slice();
    return filterBy(data, filter);
  };

  const filterChangeArhs = (event: ComboBoxFilterChangeEvent) => {
    setArhsData(filterDataArhs(event.filter));
  };

  const filterDataEstados = (filter: FilterDescriptor) => {
    const data = ocEstados.slice();
    return filterBy(data, filter);
  };

  const filterChangeEstados = (event: ComboBoxFilterChangeEvent) => {
    setOcEstadosData(filterDataEstados(event.filter));
  };

  const filterDataTecnicos = (filter: FilterDescriptor) => {
    const data = tecnicos.slice();
    return filterBy(data, filter);
  };

  const filterChangeTecnicos = (event: ComboBoxFilterChangeEvent) => {
    setTecnicosData(filterDataTecnicos(event.filter));
  };

    const handleEnableDistrito = () => {
  setDistritosDisabled(false);
};
    const [isChecked, setIsChecked] = useState(false)

  const handleCheckBoxChange = () => {
   setIsChecked(!isChecked)
  }

  const handleCheckBoxClick = (formRenderProps : FormRenderProps) => {
    if(!isChecked){
    formRenderProps.onChange("data_ocorrencia_inicio", { value: null })
    formRenderProps.onChange("data_ocorrencia_fim", { value: null })
    }
   }

  const handleSubmit = async (e: any) => {

    if (dataRegistoInicio && dataRegistoFim < dataRegistoInicio || dataOcorrenciaFim && dataOcorrenciaFim < dataOcorrenciaInicio ) {
      postMessage({ type: 'errorMsg', value: { message: "A data de fím não pode ser inferior à data de início" } }, '*')
      return false;
    }

    console.log(JSON.stringify(e.values))
    console.log(pessoasRiscoSelected)
    const userLocal = JSON.parse(
      localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}'
    )
    try {
      postMessage({ type: 'showLoader' }, '*')
      const objToSearch = {
        id: e.values?.Id ?? null,
        minhas_ocorrencias: e.values?.minhasOcorrencias ?? null,
        estado_registo_id: e.values?.estadoDoRegisto?.id ?? null,
        data_ocorrencia_inicio: e.values?.data_ocorrencia_inicio ?? null,
        data_indeterminada : e.values?.indeterminado ?? null,
        data_ocorrencia_fim: e.values?.data_ocorrencia_fim ?? null,
        data_registo_inicio: e.values?.data_registo_inicio ?? null,
        data_registo_fim: e.values?.data_registo_fim ?? null,
        freguesia_cod: e.values?.freguesia
          ? e.values?.freguesia.codigo
          : undefined,
        praia_id: e.values?.praia?.id ?? undefined,
        local_id: e.values?.local ?? null,
        entidade_id: e.values?.entidade?.id ?? null,
        concelho_cod: e.values?.concelho ? e.values?.concelho.codigo : undefined,
        distrito_cod: e.values?.distrito ? e.values?.distrito.codigo : undefined,
        user_tecnico_id: e.values?.tecnico ? e.values?.tecnico.id : undefined,
        email: e.values?.email ?? null,
        nuts1_cod: e.values?.nuts1 ? e.values?.nuts1.codigo : undefined,
        nuts2_cod: e.values?.nuts2 ? e.values?.nuts2.codigo : undefined,
        nuts3_cod: e.values?.nuts3 ? e.values?.nuts3.codigo : undefined,
        arh_id: e.values?.arh ? e.values?.arh?.codigo : undefined,
        capitania_id : e.values?.capitania ? e.values?.capitania?.id : undefined,
        massa_agua_id : e.values?.massaAgua ? e.values?.massaAgua?.id : undefined,
        instabilidade_em_arribas: e.values?.instabiladeArribas,
        danos_em_edificacoes: e.values?.danosEdificacoes,
        erosao_recuo: e.values?.erosaoRecuo,
        galgamento_costeiro: e.values?.galgamento,
        estrutura_de_protecao: e.values?.protecaoDefesa,
        danos_em_area: e.values?.danosAreasConstruidas,
        outros_danos: e.values?.outrosDanos,
        nao_sabe: e.values?.nao_sabe,
        tempestade: e.values?.tempestade,
        sismos: e.values?.preciptacao,
        outros: e.values?.outras,
        pessoa_em_risco: pessoasRiscoSelected ?? null,
        danos_em_sistemas_naturais: danosSistemasNaturaisSelected ?? null,
        criterio_geografico: rbCriteriosSelected ?? null,
        flatCoordinatesCriterioGeografico: coordenadasPoligono ?? undefined,
        user_id: userLocal?.user?.id
      }

      SessionStorageService.setItem("lastSearchOcorrenciasAvancada", objToSearch);
      SessionStorageService.setItem("firstCallOcorrencias", true);
      const result: any = await getOcorrencias(objToSearch);

      if (result) {
        const iframe: any = document.getElementById('iframeGeoPortal')
        const ids: number[] = []
        const idsApoio: number[] = []
        result.forEach((element: any) => {
          ids.push(element.id)
          if (element.oc_ocorrencia_apoio_id) {
            idsApoio.push(element.oc_ocorrencia_apoio_id)
          }
        })
        iframe.contentWindow?.postMessage(
          { type: 'SiarlOcorrenciasSearch', value: ids },
          '*'
        )
        iframe.contentWindow?.postMessage(
          { type: 'SiarlOcorrenciasApoioSearch', value: idsApoio },
          '*'
        )
        SessionStorageService.setItem(
          'resultadoOcorrenciasPesquisa',
          JSON.stringify(result)
        )

        SessionStorageService.setItem(
          'resultadoOcorrenciasPesquisa',
          JSON.stringify(result)
        )

        iframe.contentWindow?.postMessage(
          {
            type: 'SiarlCoordenadaBoxClose',
            value: 'true',
          },
          '*'
        )

        setCoordenadasPoligono(undefined)
        postMessage({ type: 'resultadoOcorrenciasPesquisaChange' }, '*')
      }
      postMessage({ type: 'hideLoader' }, '*')
   } catch (error) {
      handleGenericError(error);
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }

    areaVisivelUnSelected()
  }

  const changePessoasRisco = useCallback(
    (e: RadioButtonChangeEvent) => {
      console.log(e)
      setPessoasRiscoSelected(e.value)
    },
    [setPessoasRiscoSelected]
  )
  const changeDanosSistemasNaturais = useCallback(
    (e: RadioButtonChangeEvent) => {
      setDanosSistemasNaturaisSelected(e.value)
    },
    [setDanosSistemasNaturaisSelected]
  )

  const filterConcelho = (e: any) => {
    if (e?.value?.codigo) {
      setNuts2Disabled(true);
      setNuts3Disabled(true);
      const concelhosLocal = concelhos.filter(
        (x: any) => x.distrito_cod == e.value.codigo
      )
      setConcelhosFiltred(concelhosLocal)
      setConcelhoData(concelhosLocal);
      setConcelhosDisabled(false)
    } else {
      setNuts2Disabled(false);
      setNuts3Disabled(false);
      setConcelhosFiltred(concelhos)
      setConcelhosDisabled(true)
    }
  }

  const filterFreguesia = (e: any) => {
    if (e?.value?.codigo) {
      const freguesiaLocal = freguesias.filter(
        (x: any) => x.concelho_cod == e.value.codigo
      )
      setFreguesiasFiltred(freguesiaLocal)
      setFreguesiasData(freguesiaLocal);
      setFreguesiasDisabled(false)
    } else {
      setFreguesiasDisabled(true)
      setFreguesiasFiltred(freguesias)
      setFreguesiasData(freguesias);
    }
  }

  const filterNuts2 = (e: any) => {
    if (e?.value?.codigo) {
      const nutsLocal = nuts2.filter((x: any) => x.nuts_i_cod == e.value.codigo)
      setNuts2Filtered(nutsLocal)
      setNuts2Data(nutsLocal);
      setNuts2Disabled(false)
    } else {
      setNuts2Disabled(true)
      setNuts2Filtered(nuts2)
      setNuts2Data(nuts2);
    }
  }
  const filterNuts3 = (e: any) => {
    if (e?.value?.codigo) {
      setDistritosDisabled(true);
      const nutsLocal = nuts3.filter(
        (x: any) => x.nuts_ii_cod == e.value.codigo
      )
      setNuts3Filtered(nutsLocal)
      setNuts3Data(nutsLocal);
      setNuts3Disabled(false)
    } else {
      setDistritosDisabled(false);
      setNuts3Disabled(true)
      setNuts3Filtered(nuts3)
      setNuts3Data(nuts3);
    }
  }

  const areaVisivelUnSelected = () =>  {
    
     try {
      const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.contentWindow?.postMessage({ type: "SiarlAreaVisivelBorder", value: "false" }, '*')

    } catch (error) {
      console.log({ error })
    }
  }

  const clearMap = () => {
    try {

      setCoordenadasPoligono(undefined)
      const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.contentWindow?.postMessage({ type: "SiarlOcorrenciasClearLayers", value: "true" }, '*')
      iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: "true" }, '*')
      iframe.contentWindow?.postMessage({ type: "SiarlDeselectMapArea", value: "true" }, '*')

    } catch (error) {
      console.log({ error })
    }
  }

  const changerbCriterio = useCallback(
    (e: RadioButtonChangeEvent) => {
      const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.contentWindow?.postMessage({ type: "SiarlDeselectMapArea", value: 'true' }, '*')
      setRBCriteriosSelected(e.value)
      if ((e.value === CRITERIO_GEOGRAFICO_VALUE || e.value == CRITERIO_AREA_VISIVEL) && isMobile) {
        mostraFloatingMenu_e_Mapa_e_Oculta_Form();
      }
      if (e.value == CRITERIO_GEOGRAFICO_VALUE) {
        postMessage(
          { type: 'informationMsg', value: 'Selecione a área de pesquisa' },
          '*'
        )
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage(
          { type: 'SiarlGeoPortalAdicionarArea', value: 'true' },
          '*'
        )
      }

      if (e.value == CRITERIO_AREA_VISIVEL) {
        postMessage({ type: "informationMsg", value: "Foi selecionada a área visível do mapa" }, "*");
       
        iframe.contentWindow?.postMessage({ type: "SiarlGetMapArea", value: 'true' }, '*')
        iframe.contentWindow?.postMessage({ type: "SiarlSelectMapArea", value: 'true' }, '*')
        
      }

      
    },
    [setRBCriteriosSelected , isMobile]
  )
  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= telaMobile);
    };
  
    window.addEventListener('resize', handleResize);
  
    // Configuração inicial
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Form
    initialValues={{
        nuts1: '',
        nuts2: '',
        nuts3: '',
        distrito: '',
        concelho: '',
        freguesia: '',
      }}
      onSubmitClick={handleSubmit}
      render={(formRenderProps: FormRenderProps) => (
        <>
          <FormElement className={styles.form}>
            <fieldset>
              <legend>
                <button
                  type="button"
                  className={styles.itemExpandBase}
                  onClick={toggleRegisto}
                >
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span
                      className={
                        registoVisible
                          ? 'icon-siarl icon-colapsar'
                          : 'icon-siarl icon-expandir'
                      }
                    ></span>
                  </div>
                  <span className={styles.fieldsetTitulo}>Registo</span>
                </button>
              </legend>
              {registoVisible && (
                <div className={styles.espacoVerticalFieldSet}>
                  <div>
                    <Field
                      name="minhasOcorrencias"
                      label="Minhas ocorrências"
                      component={Checkbox}
                    />
                  </div>
                  <div className={styles.espacoVerticalFieldSet}>
                    <div className={styles.fields}>
                      <div>
                        <Field name="Id" label="ID" component={Input} />
                      </div>
                      <div>
                        <Field
                          name="estadoDoRegisto"
                          label="Estado do Registo"
                          component={ComboBox}
                          textField={'designacao'}
                          filterable={true}
                          onFilterChange={filterChangeEstados}
                          data={ocEstadosData}
                        />
                      </div>
                     
                      </div>
                  <div className={styles.fields}>
                  <div>
                        <Field
                          name="tecnico"
                          label="Técnico"
                          component={ComboBox}
                          textField={'name'}
                          filterable={true}
                          onFilterChange={filterChangeTecnicos}
                          data={tecnicosData}
                        />
                      </div>
                      <div>
                        <Field
                          name="entidade"
                          label="Entidade"
                          component={ComboBox}
                          textField={'nome'}
                          filterable={true}
                          onFilterChange={filterChangeEntidades}
                          data={entidadesData}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}
                  >
                    <div>
                      <Field
                        name="data_registo_inicio"
                        label="Registos de"
                        value={dataRegistoInicio}
                        onChange={(e) => {
                          setDataRegistoInicio(e.value)
                           setMinDate(e.value);
                        }}  
                        format="dd/MM/yyyy HH:mm"
                        component={DateTimePicker}
                      />
                    </div>
                    <div>
                      <Field
                        name="data_registo_fim"
                        label="Registos até"
                        value={dataRegistoFim}
                        onChange={(e) => {
                          setDataRegistoFim(e.value)
                        }}
                        min={minData}
                        format="dd/MM/yyyy HH:mm"
                        component={DateTimePicker}
                      />
                    </div>
                  </div>
                </div>
              )}{' '}
            </fieldset>

            <fieldset>
              <legend>
                <button
                  type="button"
                  className={styles.itemExpandBase}
                  onClick={toggleIdentificacao}
                >
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span
                      className={
                        identificacaoVisible
                          ? 'icon-siarl icon-colapsar'
                          : 'icon-siarl icon-expandir'
                      }
                    ></span>
                  </div>
                  <span className={styles.fieldsetTitulo}>Identificação</span>
                </button>
              </legend>
              {identificacaoVisible && (
                <div className={styles.espacoVerticalFieldSet}>
                  <div>
                    <div className={styles.fields}>
                      <div>
                        <Field
                          name="data_ocorrencia_inicio"
                          label="Ocorrências de"
                          value={dataOcorrenciaInicio}
                          disabled={isChecked}
                          onChange={(e) => {
                            setDataOcorrenciaInicio(e.value)
                          }}
                          format="dd/MM/yyyy HH:mm"
                          component={DateTimePicker}
                        />
                      </div>
                      <div>
                        <Field
                          name="data_ocorrencia_fim"
                          label="Ocorrências até"
                          disabled={isChecked}
                          value={dataOcorrenciaFim}
                          onChange={(e) => {
                            setDataOcorrenciaFim(e.value)
                          }}
                          format="dd/MM/yyyy HH:mm"
                          component={DateTimePicker}
                        />
                      </div>
                      <div>
                        <Field
                          name="indeterminado"
                          label="Indeterminada"
                          component={Checkbox}
                          onChange={handleCheckBoxChange}
                          onClick={() => {
                              handleCheckBoxClick(formRenderProps);
                            }}
                                                    checked={isChecked}
                          className="checkbox-indeterminado"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </fieldset>
            
                  <fieldset>
              <legend>
                <button
                  type="button"
                  className={styles.itemExpandBase}
                  onClick={toggleCriterioGeografico}
                >
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span
                      className={
                        criterioGeograficoVisible
                          ? 'icon-siarl icon-colapsar'
                          : 'icon-siarl icon-expandir'
                      }
                    ></span>
                  </div>
                  <span className={styles.fieldsetTitulo}>
                  Critério geográfico
                  </span>
                </button>
              </legend>
              {criterioGeograficoVisible && (
                <div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}>
                    <Field
                      id={'rbCriterio'}
                      name={'rbCriterio'}
                      layout={'horizontal'}
                      component={RadioGroup}
                      onChange={(e) => {
                        changerbCriterio(e)
                      }}
                      data={[
                        { label: 'Nenhum', value: NENHUM},
                        { label: 'Área visível', value: AREA_VISIVEL },
                        { label: 'Polígono de seleção', value: POLIGONO_SELECAO },
                      ]}
                      defaultValue={NENHUM} // Set the default selected value to 0 (Nenhum)
                    />
                  </div>
              )}{' '}
            </fieldset>


            <FieldsetCombosLocalizacao  isPesquisaAvancada={true} styles={styles} formRenderProps={formRenderProps}/>
        

            <fieldset>
              <legend>
                <button
                  type="button"
                  className={styles.itemExpandBase}
                  onClick={toggleTipologia}
                >
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span
                      className={
                        tipologiaVisible
                          ? 'icon-siarl icon-colapsar'
                          : 'icon-siarl icon-expandir'
                      }
                    ></span>
                  </div>
                  <span className={styles.fieldsetTitulo}>Tipologia</span>
                </button>
              </legend>
              {tipologiaVisible && (
                <div className={styles.espacoVerticalFieldSet}>
                  <div>
                    <Field
                      name="instabiladeArribas"
                      label="Instabilidade em arribas"
                      component={Checkbox}
                    />
                  </div>
                  <div>
                    <Field
                      name="erosaoRecuo"
                      label="Erosão/recuo da linha de costa"
                      component={Checkbox}
                    />
                  </div>
                  <div>
                    <Field
                      name="galgamento"
                      label="Galgamento costeiro"
                      component={Checkbox}
                    />
                  </div>
                  <div>
                    <Field
                      name="protecaoDefesa"
                      label="Em estruturas de proteção/defesa costeira"
                      component={Checkbox}
                    />
                  </div>
                </div>
              )}
            </fieldset>


            <fieldset>
              <legend>
                <button
                  type="button"
                  className={styles.itemExpandBase}
                  onClick={toggleOutrosCriterios}
                >
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span
                      className={
                        outrosCriteriosVisible
                          ? 'icon-siarl icon-colapsar'
                          : 'icon-siarl icon-expandir'
                      }
                    ></span>
                  </div>
                  <span className={styles.fieldsetTitulo}>Outros critérios</span>
                </button>
              </legend>

              {outrosCriteriosVisible && (
                <div
                  className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}
                >
                  <div style={{ marginBottom:'25px' }}>
                    <legend  className={`${styles.boldTitle}` }>Pessoas em risco </legend>

                    <div>
                    <div>
                        <RadioButton
                          name="pessoasRisco"
                          label="Não sabe / não aplicável"
                          value={2}
                          onChange={changePessoasRisco}
                        />
                      </div>
                      <div>
                        <RadioButton
                          name="pessoasRisco"
                          label="Não"
                          value={0}
                          onChange={changePessoasRisco}
                        />
                      </div>
                      <div>
                        <RadioButton
                          name="pessoasRisco"
                          label="Sim"
                          value={1}
                          onChange={changePessoasRisco}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <legend className={`${styles.boldTitle}`} >Danos em edificações</legend>
                    <div title="Danos em áreas construídas de fruição/uso público (e.g. passeio pedonal/passadiços/miradouro/zonas de estadia)">
                      <Field
                        name="danosAreasConstruidas"
                        label="Danos em áreas construídas de fruição/uso público"
                        component={Checkbox}
                      />
                    </div>
                    <div title="Danos em Edificações (e.g. habitações/comerciais/apoios de praia)">
                      <Field
                        name="danosEdificacoes"
                        label="Danos em edificações"
                        component={Checkbox}
                      />
                    </div>
                    <div>
                      <Field
                        name="outrosDanos"
                        label="Outros danos"
                        component={Checkbox}
                      />
                    </div>
                  </div>

                  <div style={{ marginBottom:'27px' }}>
                    <legend className={`${styles.boldTitle}`}>Danos em sistemas naturais</legend>
                    <div>
                    <div>
                        <RadioButton
                          name="danosSistemasNaturais"
                          label="Não sabe / não aplicável"
                          value={2}
                          onChange={changeDanosSistemasNaturais}
                        />
                      </div>
                      <div>
                        <RadioButton
                          name="danosSistemasNaturais"
                          label="Não"
                          value={0}
                          onChange={changeDanosSistemasNaturais}
                        />
                      </div>
                      <div>
                        <RadioButton
                          name="danosSistemasNaturais"
                          label="Sim"
                          value={1}
                          onChange={changeDanosSistemasNaturais}
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <legend className={`${styles.boldTitle}`} >
                      Causas da ocorrência
                    </legend>
                    <div>
                      <Field
                        name="nao_sabe"
                        label="Não sabe / não aplicável"
                        component={Checkbox}
                      />
                    </div>
                    <div>
                      <Field
                        name="tempestade"
                        label="Tempestade"
                        component={Checkbox}
                      />
                    </div>
                    <div>
                      <Field
                        name="preciptacao"
                        label="Precipitação intensa"
                        component={Checkbox}
                      />
                    </div>
                    <div>
                      <Field
                        name="outras"
                        label="Outras"
                        component={Checkbox}
                      />
                    </div>
                  </div>
                </div>
              )}
            </fieldset>
          </FormElement>
          <div
            className={`${styles.footer} ${styles.justify_multiple_children}`}
          >
           <Button
            onClick={() => {
            formRenderProps.onFormReset();
            handleEnableDistrito();
            clearMap()
            }}             
             className={styles.btnLimparPesquisa}
             title='Limpar pesquisa'
            >
              <span className="icon-siarl icon-limparcampos"></span>
              <span className={styles.rotulo}>
                {' '}&nbsp;{' '}
                Limpar pesquisa
              </span> 
            </Button>
            <Button
              onClick={formRenderProps.onSubmit}
              className={styles.btnPesquisar}
              title='Pesquisar'
            >
              <span className="icon-siarl icon-pesquisar"></span> 
              <span className={styles.rotulo}>
                {' '}&nbsp;{' '}
                Pesquisar
              </span>    
            </Button>
          </div>
        </>
      )}
    />
  )
}
