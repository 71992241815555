import { Input, InputProps } from '@progress/kendo-react-inputs'
import { Error } from '@progress/kendo-react-labels'
import { useEffect, useState } from 'react'

import { useUsoDoSoloStore } from '../../../stores/usoDoSolo'

import type { FieldRenderProps } from '@progress/kendo-react-form'

export default function ValidatedIdentificacaoInput(fieldRenderProps: FieldRenderProps) {
  const addIdentificacaoTabValidatedField = useUsoDoSoloStore(state => state.addIdentificacaoTabValidatedField)

  useEffect(() => {
    const isValidValue = typeof fieldRenderProps.value === 'string' && fieldRenderProps?.value?.trim() !== '';
    addIdentificacaoTabValidatedField({ [fieldRenderProps.name]: isValidValue });
  }, [fieldRenderProps.value])

  return (
    <div>
     <Input {...fieldRenderProps as InputProps} />
      {fieldRenderProps.visited && fieldRenderProps.validationMessage && <Error>{fieldRenderProps.validationMessage}</Error>}
    </div>
  )
}
