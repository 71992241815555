import { process } from '@progress/kendo-data-query';
import {
  Dispatch,
  MouseEvent as ReactMouseEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { Button, DropDownButton } from '@progress/kendo-react-buttons';
import { PagerTargetEvent } from '@progress/kendo-react-data-tools';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Grid, GridCellProps, GridColumn, GridPageChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { format } from 'date-fns';
import React from 'react';
import { CSVLink } from 'react-csv';
import SessionStorageService from '../../services/SessionStorageService';
import { GetUserNameTecnico, b64toBlob, exportPDFWithMethod, get, handleGenericError, hasPermission, post, setCandidaturaSelected } from '../../services/utils';
import styles from '../../styles/intervencao/Intervencao.module.scss'
import {  AUTH_LOCALSTORAGE, CONSULTAR_CANDIDATURA, CURRENT_TIMEZONE, GET_DOCUMENTO, GET_DOCUMENTO_BY_CANDIDATURA, GET_LIST_RESULTS_INT_CANDIDATURA, PERMISSION_INSERIR_INTERVENCAO, PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO, PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA, PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO, PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_LEITURA, PERMISSION_INTERVENCAO_PERFIL_TOTAL, PESQUISAR_CANDIDATURA, RECORDS_LIMIT, RELATORIO_MERGE_CANDIDATURA, URL_AJUDA_INTERVENCOES_CANDIDATURA, clearLayers } from '../../utils/constants';
import { ResultadoCandidaturaCommandGridCell } from './ResultadoCandidaturaCommandGridCell';
import { useCandidaturaStore } from '../../stores/candidatura';
import clsx from 'clsx';
import { useCommonStore } from '../../stores/common';
import commonStyles from '../../styles/common/Common.module.scss'
import MaximizeButton from '../common/MaximizeButton';
import useClearMap from '../../hooks/useClearMap';
import VisualizadorColunas from '../Generico/VisualizadorColunas ';
import useVisibleColumns, { Key } from '../../hooks/useVIsibleColumns';
import ReactGA from 'react-ga4';
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais';
import { ResultadoDocumentoCommandGridCell } from '../documentos/ResultadoDocumentoCommandGridCell';
import ImagePreviewV2 from '../viewer/ImagePreviewV2';
import { Dialog } from '@progress/kendo-react-dialogs';
import moment from 'moment'
import "moment-timezone"

type Props = {
  onCloseClick?: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
  setShowResultadoCandidatura: Dispatch<SetStateAction<boolean>>
  setShowRegistarCandidatura: Dispatch<SetStateAction<boolean>>
}

const options = [
  {
    key: 'ExportarFichasResultados',
    text: 'Exportar fichas resultados',
    icon: <span className={`icon-siarl icon-exportar ${styles.btnImprimir}`}></span>,
  }
]
type PageState = {
  skip: number
  take: number
}
const initialDataState: PageState = { skip: 0, take: 20 }
const keyPainelPrincipal:Key = 'candidaturaColunasVisiveis';
const keyPainelDocumentos:Key = 'candidaturaDocumentosColunasVisiveis';
export default function ResultadoCandidaturaForm({
  onCloseClick,
  setShowResultadoCandidatura,
  setShowRegistarCandidatura,
}: Props) {
  const [isMobile, setIsMobile] = useState(false);
  const [candidaturas, setCandidaturas] = useState([])
  const [documentosCandidatura, setDocumentosCandidatura] = useState([])
  const [urlDoDocumento, setUrlDoDocumento] = useState("");
  const [idDoDocumento, setIdDoDocumento] = useState("");
  const [openForm, setOpenForm] = useState<boolean>(false)

  const documentosToPreview = documentosCandidatura

  const [selected, setSelected] = useState<number>(0)
  const [page, setPage] = useState<PageState>(initialDataState)

  const isCurrentViewMaximized = useCommonStore(state => state.isCurrentViewMaximized)

  const selectCandidaturaResult = useCandidaturaStore(
    (state) => state.selectCandidaturaResult
  )
  const selectCandidaturaResultId = useCandidaturaStore(
    (state) => state.selectCandidaturaResultId
  )
  const [pageSizeValue, setPageSizeValue] = useState<
    number | string | undefined
  >()

  const getCandidatura = async (data: any) =>
  await get<any, any>(`${PESQUISAR_CANDIDATURA}/` + data)
  const getDocumentosByCandidaturaIDs = async (data: any) =>
  await post<any, any>(`${GET_DOCUMENTO_BY_CANDIDATURA}` , data)
  const getDocumento = async (data: any) =>
    await get<any, any>(`${GET_DOCUMENTO}/` + data)

  const getCandidaturasPorIds = async (data: any) =>
    await post<any, any>(`${GET_LIST_RESULTS_INT_CANDIDATURA}`, data)

  const relatorioCandidaturaMerge = async (data: any) =>
    await post<any, any>(`${RELATORIO_MERGE_CANDIDATURA}`, data)


     const handleMessageEvent = useCallback((event: MessageEvent) => {
        if (event.data.type == "resultadoCandidaturaPesquisaChange") {
            SessionStorageService.setItem("resultadoCandidaturaPesquisa", event.data.value);
        }
    }, [])

     useEffect(() => {
        window.addEventListener(
            'message',
            handleMessageEvent,
            false
        )

        return () => {
            window.removeEventListener(
                'message',
                handleMessageEvent,
                false
            )
        }
    }, [handleMessageEvent])

  const consultarCandidatura = async (data: any) => await post<any, any>(`${CONSULTAR_CANDIDATURA}`, data)

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/ResultadoIntCandidatura" });
  }, []);

  const initialized = useRef(false)
    useEffect(() => {
        if (!initialized.current) {
          initialized.current = true;      if (SessionStorageService.getItem("lastSearchCandidatura") && !SessionStorageService.getItem("firstCallCandidatura") ) {
        postMessage({ type: "showLoader" }, "*");
        const objToSearch = SessionStorageService.getItem("lastSearchCandidatura") ?? '';
        consultarCandidatura(objToSearch).then((data) => {
          SessionStorageService.setItem("resultadoCandidaturaPesquisa", JSON.stringify(data));
          setResultsCandidatura();
          postMessage({ type: "hideLoader" }, "*");
        })
      } 
      else {
        setResultsCandidatura();
    }
  }
    }, [])

    const setResultsCandidatura = () => {
        const results = JSON.parse(SessionStorageService.getItem('resultadoCandidaturaPesquisa') ?? '')
        setCandidaturas(results)
        const iframe: any = document.getElementById('iframeGeoPortal')
        const ids: any[] = []
        results.forEach((element: any) => {
          ids.push(element.id)
          })
          iframe.contentWindow?.postMessage(
            { type: 'SiarlCandidaturasSearch', value: ids },
            '*'
          )
         
      getVisibleColumns(keyPainelPrincipal, setColums)
      getVisibleColumns(keyPainelDocumentos, setColumsDocuments)
        postMessage({ type: 'hideLoader' }, '*');

    }

    useEffect(() => {
      const iframe: any = document.getElementById('iframeGeoPortal')
      const ids: number[] = []
      candidaturas.forEach((element: any) => {
        ids.push(element.id)
      })
      iframe.contentWindow?.postMessage(
        { type: 'SiarlCandidaturasSearch', value: ids },
        '*'
      )
    })

  const [clearMap] = useClearMap();
  const clearSearchResults = () => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    SessionStorageService.removeItem('resultadoCandidaturaPesquisa')
    postMessage({ type: "form", value: "pesquisarCandidatura" }, "*");
    setCandidaturas([]);
    removeColumnsKeys(keyPainelDocumentos)
    removeColumnsKeys(keyPainelPrincipal)
    sessionStorage.removeItem("lastSearchCandidatura");
    clearMap(clearLayers["CLEAR_LAYERS_CANDIDATURA"])
    
  }

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected)
  }
  const handlePageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent
    const take = event.page.take

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value)
    }

    setPage({
      ...event.page,
      take,
    })
  }

  useEffect(() => {

    try {

      const results = JSON.parse(
        SessionStorageService.getItem('resultadoCandidaturaPesquisa') ?? ''
      )

      setCandidaturas(results);
      try {
        const idsCandidatura = results.map((o: any) => o.id)
        getDocumentosByCandidaturaIDs(idsCandidatura).then(response => {
          setDocumentosCandidatura(
            response.map((resultDocs: any) => ({
           ...resultDocs,
          data_registo: moment.utc(resultDocs.data_registo).tz(CURRENT_TIMEZONE).format("DD/MM/YYYY HH:mm"),
          data_publicacao: moment.utc(resultDocs.data_publicacao).tz(CURRENT_TIMEZONE).format("DD/MM/YYYY HH:mm"),
          })))
        })
        
      } catch (error) {
        console.error('Erro ao buscar os dados:', error)
      }

    } catch (e) {
      setCandidaturas([]);
    }

  }, [])

  const handleInfoClickById = (candidaturaLocal: Record<string, any>) => async () => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    const id = candidaturaLocal.int_candidatura_id ?? candidaturaLocal.id;
    const candidatura = await getCandidatura(id);
    if (!candidatura) {
      postMessage({type: "infoMsg", value: {message: "Registo desativado."}}, "*");
      return;
    }
    const tecnico = await GetUserNameTecnico(candidatura.utilizador_id);

    const objToShow = {
      ...candidatura,
      id: candidatura?.id,
      designacao: candidatura?.designacao ?? undefined,
      cod_candid: candidatura?.cod_candid ?? undefined,
      valor_candid: candidatura?.valor_candid ?? undefined,
      valor_comp: candidatura?.valor_comp ?? undefined,
      valor_total: candidatura?.valor_total ?? undefined,
      data_registo: candidatura?.data_registo? new Date(candidatura?.data_registo):null,
      data_hora_registo: candidatura?.data_hora_registo? new Date(candidatura?.data_hora_registo):null,
      data_submissao: candidatura?.data_submissao? new Date(candidatura?.data_submissao):null,
      id_tipo_in: candidatura?.tipo_incidencia ?? undefined,
      instr_financiamento_id: candidatura?.intInstrumentoFinanciamento ?? undefined,
      estado_candidatura_id: candidatura?.intCandidaturaEstado ?? undefined,
      filedoc_cod: candidatura?.filedoc_cod ?? undefined,
      utilizador_id : tecnico
    }
    sessionStorage.setItem('novaCandidatura', 'false')
    selectCandidaturaResult(objToShow)
    setCandidaturaSelected(objToShow);
    setShowResultadoCandidatura(false)
    setShowRegistarCandidatura(true)
    const idToSend = [candidatura?.id];
    iframe.contentWindow?.postMessage({ type: "SiarlCandidaturaCenter", value: idToSend }, '*')
  }

  
  const candidaturaCustomCell = (props: GridCellProps) => (
    <ResultadoCandidaturaCommandGridCell
      {...props}
      onInfoClick={handleInfoClickById}
      {...conditionalProps}

    />
  )

  const DocumentCommandCell = (props: GridCellProps) => (
    <ResultadoDocumentoCommandGridCell
    onLocationMouseEnter={function (documentos: Record<string, unknown>): void {
      return;
    } } onLocationMouseLeave={function (documentos: Record<string, unknown>): void {
      return;
    } } {...props}
    onInfoClick={handleInfoClickById}
    onDocumentPreviewClick={handleDocumentPreviewClickById}    />
  )

  const handleDocumentPreviewClickById = (documentosLocal: Record<string, any>) => async () => {

    try {

      postMessage({ type: "showLoader" }, "*");
      const documentos = await getDocumento(documentosLocal.id);

      setIdDoDocumento(documentosLocal.id);
      setUrlDoDocumento(documentos.url);
      setOpenForm(true);

    } catch (error) {

      handleGenericError(error, "Não foi possivel abrir o documento!")
      setOpenForm(false);

    } finally {

      postMessage({ type: "hideLoader" }, "*");

    }
  }

  const nextPhoto = async (e: any) => {

    try{

      postMessage({ type: "showLoader" }, "*");
      const nextIds = documentosToPreview
        .filter((doc: any) => doc.id < idDoDocumento && doc.url !== '')
        .map((doc: any) => doc.id);
  
      if (nextIds.length > 0) {
        const nextId = Math.max(...nextIds);
        console.log(`O próximo ID é ${nextId}`);
  
        const documento = await getDocumento(nextId);
  
        setIdDoDocumento(nextId.toString());
        setUrlDoDocumento(documento.url);

        const iframe: any = document.getElementById('iframeGeoPortal')
        
        iframe.contentWindow?.postMessage(
          {
            type: 'SiarlIntMobilizacaoSearchHighlightClick',
            value: [e.int_mobilizacao_id],
          },
          '*'
        )
  
      } else {
        console.log("Não há um próximo ID na lista.");
      }

    }catch(error){

      postMessage({ type: "errorMsg", value: "Não foi possivel abrir o documento!" }, "*");

    }finally{

      postMessage({ type: "hideLoader" }, "*");

      e.preventDefault();
    }
   
  }

  const onItemClickExportarFichas = async () => {
    try {
  
    
      const relatorios = [];
  
      const results = JSON.parse(
        SessionStorageService.getItem('resultadoCandidaturaPesquisa') ?? ''
      );
    
   
     

      const idsCandidatura = results.map((o: any) => o.id);

      if(idsCandidatura.length  > RECORDS_LIMIT){
        postMessage({ type: 'errorMsg', value: { message: "Não é possivel exportar mais de 100 fíchas" } }, '*')
        return false
        }
    
  
  
      const candidaturas = await getCandidaturasPorIds(idsCandidatura);
      
        postMessage({ type: 'showLoader' }, '*');
      
        for (const candidaturaId of idsCandidatura) {
  
        const candidatura = candidaturas.find((candidatura: any) => candidatura.id === candidaturaId);
  
     
        const relatorio = {
          id: candidatura?.id,
          dados: {
            'REGISTO' : '',
            'Nº Processo APA:': candidatura.filedoc_cod ?? '',
            'Data do registo:': candidatura.data_registo ? new Date(candidatura.data_registo).toLocaleDateString() : '',
            'Data de submissão:': candidatura.data_submissao ? new Date(candidatura.data_submissao).toLocaleDateString() : '',
            'Código da candidatura:': candidatura.cod_candid ?? '',
            'Designação:': candidatura.designacao ?? '',
            'Técnico:': candidatura.utilizador?.nome ?? '',
  
            'FONTE DE FINANCIAMENTO' : '',
            'Fonte de finaciamento:': candidatura.tipo_incidencia?.descricao ?? '',
            'INSTRUMENTO DE FINANCIAMENTO:' : '',
            'Instrumento de finaciamento:': candidatura.intInstrumentoFinanciamento?.designacao ?? '',
  
            'INVESTIMENTO' : '',
            'Despesa elegível:': candidatura.valor_candid?.toString() ?? '',
            'Despesa não elegível:': candidatura.valor_compo ?? '',
            'Despesa total:': candidatura.valor_total?.toString() ?? '',
  
            'ESTADO' : '',
            'Estado da candidatura:': candidatura.intCandidaturaEstado?.descricao ?? '',
         
          },
          img_mapa: ""
        };

        
        relatorios.push(relatorio);
    }
  
      const mergedPdfBase64 = await relatorioCandidaturaMerge(relatorios);
      if (mergedPdfBase64) {
        const blob = b64toBlob(mergedPdfBase64, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
      }
    } catch (error) {
      console.log(error);
      postMessage({ type: 'errorMsg', value: { left: '50%', message: "Verifique os campos obrigatórios." } }, '*');
    } finally {
      postMessage({ type: 'hideLoader' }, '*');
    }
  };

  const previousPhoto = async (e: any) => {

    try{

      postMessage({ type: "showLoader" }, "*");
      const previousIds = documentosToPreview
        .filter((doc: any) => doc.id > idDoDocumento && doc.url !== '')
        .map((doc: any) => doc.id);
  
      if (previousIds.length > 0) {
        const previousId = Math.min(...previousIds);
        console.log(`O anterior ID é ${previousId}`);
  
        const documento = await getDocumento(previousId);
  
        setIdDoDocumento(previousId.toString());
        setUrlDoDocumento(documento.url);

        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage(
          {
            type: 'SiarlIntMobilizacaoSearchHighlightClick',
            value: [e.int_mobilizacao_id],
          },
          '*'
        )
      } else {
        console.log("Não há um anterior ID na lista.");
      }

    }catch(error){

      handleGenericError(error, "Não foi possivel abrir o documento!")

    }finally{

      postMessage({ type: "hideLoader" }, "*");

      e.preventDefault();
    }
       
  }

  const [dataState, setDataState] = React.useState({
    skip: 0,
    take: 20,
    sort: [],
    group: [],
  });

  const [dataStateDocumentos, setDataStateDocumentos] = React.useState({
    skip: 0,
    take: 20,
    sort: [],
    group: [],
  });

  const dataStateChange = (event: any) => {
    setDataResult(process(candidaturas, event.dataState));
    setDataState(event.dataState);

    const skipPag: PageState = { skip: event.dataState.skip, take: event.dataState.take}
setPage({
  ...skipPag
})
    
  };

  const [dataResult, setDataResult] = React.useState(
    process(candidaturas, dataState)
  );

  const dataStateChangeDocumentos = (event: any) => {
    setDataResultDocumentos(process(documentosCandidatura, event.dataState));
    setDataStateDocumentos(event.dataState);
  };

  const [dataResultDocumentos, setDataResultDocumentos] = React.useState(
    process(documentosCandidatura, dataStateDocumentos)
  );


  const itemRender = (props: { item: any; itemIndex: number }) => {
    const isTitleItem = props.item.key === 'titulo';
    if (isTitleItem) {
      // Item de título personalizado
      return (
        <div className={styles.DropDownButtonTitulo}>
          <span>{props.item.text}</span>
        </div>
      );
    } else {
      // Demais itens
      return (
        <div className={styles.DropDownButtonItem}>
          <span>{props.item.text}</span>
        </div>
      );
    }
  };

  const optionsExportar = [
    {
      key: 'ExportarListaResultadosExcel',
      text: 'Excel',
    },
    {
      key: 'ExportarTodasFichas',
      text: 'Fichas',
    },
  ]
  
  let _pdfExport: any;
  const exportExcel = () => {
    _export.save();
  };

  const openPdfNewTab = () => {
    return new Promise<void>((resolve, reject) => {
        
        postMessage({ type: "showLoader" }, "*");

        setTimeout(() => {
           const columnsToShow = colums.columns.filter(x => x.show);
            const dataToExport = candidaturas.map((item: any) => {
                const filteredItem: any = {};
                columnsToShow.forEach((column: any) => {
                    filteredItem[column.field] = item[column.field];
                });
                return filteredItem;
            });
            
            exportPDFWithMethod(columnsToShow, dataToExport, 8)
                .then((pdfData: any) => {
                    postMessage({ type: "hideLoader" }, "*");

                        const base64Data = pdfData.split(";base64,")[1];
                        console.log(base64Data);
                        const blob = b64toBlob(base64Data, 'application/pdf');
                        const blobUrl = URL.createObjectURL(blob);

                      
                        window.open(blobUrl, '_blank');
                        resolve();
                  
                })
                .catch(error => {
                    console.error('Error exporting PDF:', error);
                    reject(error);
                });
        }, 1000);
    });
};

  const onItemClickExportar = async (event: any) => {
    
    if(event.item.key == "ExportarTodasFichas"){
      onItemClickExportarFichas();
    }
    if(event.item.key == "ExportarListaResultadosExcel"){
        exportExcel();
    }
  }

  let _export: any;
  const exportPDF = () => {
    _pdfExport.save();
  };

  const onItemClickImprimir = async (event: any) => {
    openPdfNewTab();
  }


  const {getVisibleColumns, removeColumnsKeys} = useVisibleColumns();

  const [columsDocuments, setColumsDocuments] = useState<{ columns: { field: string, title: string, width: number | string, show: boolean }[] }>({
    columns: [
      { field: "id", width: 70, title: "ID", show: true },
      { field: "data_registo", width: 170, title: "Data registo", show: true },
      { field: "data_publicacao", width: 190, title: "Data publicação", show: true },
      { field: "tipoRecurso.designacao", width: 190, title: "Tipo de recurso", show: true },
      { field: "titulo", width: 180, title: "Título", show: true },
      { field: "autores", width: 180, title: "Autor", show: true },
      { field: "entidade.nome", width: 190, title: "Entidade", show: true },
      { field: "estado.designacao", width: 180, title: "Estado", show: true },
    ]
  })

  const [colums, setColums]=useState<{columns:{field:string, title:string, width:number|string, show:boolean}[]}>({
    columns:[
      { field: "id", title: "ID", show: true,width:70 },  
      { field: "designacao", title: "Designação", show: true,width: 170}, 
      { field: "cod_candid", title: "Código Candidatura", show: true,width: 180 },  
      { field: "valor_candid", title: "Valor Candidatura", show: true,width: 190 },  
      { field: "valor_comp", title: "Valor Compartipação", show: true,width: 190},
      { field: "valor_total", title: "Valor Total", show: true,width: 190 },  
      { field: "intCandidaturaEstado.descricao", title: "Estado", show: true,width: 170},
      { field: "tipo_incidencia.descricao", title: "Tipo Incidencia", show: true,width: 190},
      { field: "utilizador.name", title: "Técnico", show: true,width: 190},
      { field: "intInstrumentoFinanciamento.designacao", title: "Inst. Financiamento", show: true,width: 190},
      
    ]
  })
  
  useEffect(() => {
    SessionStorageService.setItem(keyPainelDocumentos, JSON.stringify(columsDocuments));
  }, [columsDocuments]);
  
  useEffect(() => {
    SessionStorageService.setItem(keyPainelPrincipal, JSON.stringify(colums));
  }, [colums]);

  useLayoutEffect(() => {

    const handleResize = () => {

      if (window.innerWidth <= telaMobile) {
        
        setIsMobile(true);
        ocultaFloatingMenu_e_Mapa();

      }else{

        setIsMobile(false);
        mostraFloatingMenu_e_Mapa();

      }

    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);  


  const onItemClickMais = (event: any) => {

    elementoClickMais(event);

    onItemClickExportar(event);

    onItemClickImprimir(event);
    
  };

  const optionsMais = [
    {
      key: 'titulo',
      text: 'Imprimir',
    },
    {
      key: 'imprimirFicha',
      text: 'Resultados',
      className: 'icon-siarl icon-imprimir btnIconRegistar'
    },
    {
      key: 'titulo',
      text: 'Exportar',
    },
    ...optionsExportar.map(option => ({
      ...option,
      className: 'icon-siarl icon-exportar btnIconRegistar',
    })),
  ]

  const handleNewRegistoClick = () => {
      setCandidaturaSelected(undefined);
      setCandidaturaSelected(undefined);
      selectCandidaturaResult(undefined);
      setShowResultadoCandidatura(false)
      const loggedUser = JSON.parse(
        localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}'
      )
      setCandidaturaSelected({ utilizador_id: loggedUser?.user?.name })
      sessionStorage.setItem('novaCandidatura', 'true')
      if (setShowRegistarCandidatura) setShowRegistarCandidatura(true)
  }

  const openAjuda = () => {
    window.open(URL_AJUDA_INTERVENCOES_CANDIDATURA, "_blank")
  }

  const canSeeCandidatura = 
hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA) ||
hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO) ||
hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_LEITURA) ||
hasPermission(PERMISSION_INTERVENCAO_PERFIL_TOTAL);


const conditionalProps =  canSeeCandidatura
? { 
    onInfoClick: handleInfoClickById
} 
: {};

  return (
    <div className={clsx(styles.base, isCurrentViewMaximized && commonStyles.maximizedBaseForm)}>
      <div className={styles.header}>
        <div>
          <div className={styles.nomeDoModulo}>
            Candidaturas
          </div>
          <div className={styles.tituloDaPagina}>
            Resultados de pesquisa
          </div>
        </div>

        <div>

          {hasPermission(PERMISSION_INSERIR_INTERVENCAO) && (  
            hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO) ||
            hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO)
            )? 
            <Button
              onClick={handleNewRegistoClick}
              className={styles.btnNovoRegisto}
              style={{ display: isMobile ? 'none' : '' }}
              id='novoRegisto'
              title='Nova candidatura'
            >
              <span className="icon-siarl icon-registar btnIconRegistar" /> &nbsp;{' '}
              Nova candidatura
            </Button>
          :  ''}

          {canSeeCandidatura?<>
          <CSVLink
            title="Imprimir resultados"
            id="print-results-candidatura"
            separator={";"}
            data={
              candidaturas.map((candidatura: any) => ({
                ...candidatura,
              }))}
            filename={`siarl-candidatura-${format(new Date(), 'ddMMyyyHHmm')}`}
            hidden
          >
          </CSVLink>
          </>:<></>}

          {!isMobile &&
          <VisualizadorColunas 
              columns={selected===0?colums:columsDocuments} 
              setColumns={selected===0?setColums:setColumsDocuments}
              styles={styles.btnEditar2} 
           />
          }

        <button className={styles.btnEditar2} onClick={onItemClickImprimir} style={{ display: isMobile ? 'none' : '' }} id='imprimirFicha' title='Imprimir'>
          <span className="icon-siarl icon-imprimir"></span>
        </button>

          {!isMobile &&
          <DropDownButton 
            items={optionsExportar}
            className={styles.btnImprimir}
            style={{marginTop: '-4px'}}
            iconClass='icon-siarl icon-exportar'
            onItemClick={onItemClickExportar}
            fillMode={"flat"}
            itemRender={itemRender}
            title='Exportar'
          >
          </DropDownButton>
        }
 
          {isMobile &&
            <DropDownButton
              items={optionsMais}
              className={styles.btnMais}
              iconClass="icon-siarl icon-mais"
              onItemClick={onItemClickMais}
              fillMode={'flat'}
              itemRender={itemRenderMais}
              title="Mais Opções"
            />
          }

          {isMobile &&
            <DropDownButton
              items={optionsMais}
              className={styles.btnMais}
              iconClass="icon-siarl icon-mais"
              onItemClick={onItemClickMais}
              fillMode={'flat'}
              itemRender={itemRenderMais}
              title="Mais Opções"
            />
          }

          <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
              <span className="icon-siarl icon-ajuda"></span>
          </button>
          <button className={styles.btnFechar} onClick={onCloseClick} title="Minimizar">
            <span className="icon-siarl icon-minimizar"></span>
          </button>

          {!isMobile &&
          <MaximizeButton className={styles.btnMaximizar} />
          }
        </div>

      </div>

      {canSeeCandidatura?
      <TabStrip selected={selected} onSelect={handleSelect} scrollable={isMobile}>
        <TabStripTab title="Candidaturas">
          <ExcelExport
            fileName='Resultado_Candidaturas'
            data={candidaturas}
            ref={(exporter) => {
              _export = exporter;
            }}
          >
            <Grid className={styles.form}
              sortable={true}
              onDataStateChange={dataStateChange}
              data={dataResult.data.length > 0 ? dataResult : candidaturas.slice(page.skip, page.take + page.skip)}
              total={candidaturas.length}
              {...dataState}
              skip={page.skip}
              take={page.take}
              pageable={{
                buttonCount: 5,
                info: true,
                type: 'numeric',
                pageSizes: true,
                previousNext: true,
              }}
              //groupable={true}
            >
              <GridColumn cell={candidaturaCustomCell} width={30} />
              {colums?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
            </Grid>
          </ExcelExport>

          <GridPDFExport  
          fileName='Resultado_Candidatura'
            ref={(element) => {
              _pdfExport = element;
            }}
            margin="1cm"
            paperSize={['340mm', '297mm']}         

          >
            <Grid className={styles.form}
              sortable={true}
              onDataStateChange={dataStateChange}
              data={candidaturas}
              total={candidaturas.length}
              {...dataState}
              pageable={{
                buttonCount: 5,
                info: true,
                type: 'numeric',
                pageSizes: true,
                previousNext: true,
              }}
              //groupable={true}
            >
              
              <GridColumn cell={candidaturaCustomCell} width={30} />
              {colums?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
            </Grid>
          </GridPDFExport>

        </TabStripTab>

        <TabStripTab title="Documentos">

          <ExcelExport
            fileName='Resultado_DocumentosCandidatura'
            data={documentosCandidatura}
            ref={(exporter) => {
              _export = exporter;
            }}

          >
            <Grid className={styles.form}
              sortable={true}
              onDataStateChange={dataStateChangeDocumentos}
              data={dataResultDocumentos.data.length > 0 ? dataResultDocumentos : documentosCandidatura.slice(page.skip, page.take + page.skip)}
              {...dataState}
              pageable={{
                buttonCount: 5,
                info: true,
                type: 'numeric',
                pageSizes: true,
                previousNext: true,
              }}
               total={documentosCandidatura.length}
              //groupable={true}
            > 
            <GridColumn cell={DocumentCommandCell} width={56} />
                {columsDocuments?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
            </Grid>

          </ExcelExport>

          <GridPDFExport  
            fileName='Resultado_Documentos_Candidatura'
            ref={(element) => {
              _pdfExport = element;
            }}
            margin="1cm"
          >
            <Grid className={styles.form}
              sortable={true}
              onDataStateChange={dataStateChangeDocumentos}
              data={dataResultDocumentos.data.length > 0 ? dataResultDocumentos : documentosCandidatura}
              {...dataState}
              pageable={{
                buttonCount: 5,
                info: true,
                type: 'numeric',
                pageSizes: true,
                previousNext: true,
              }}
              //groupable={true}
            >
          {columsDocuments?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
            </Grid>

          </GridPDFExport>

        </TabStripTab>
      </TabStrip>:<></>}
      
      <div className={clsx(styles.footer, styles.justify_one_child, isCurrentViewMaximized && commonStyles.maximizedFooter)}>
        <Button
          className={styles.btnPesquisar}
          onClick={clearSearchResults}
          title='Nova Pesquisa'
        >
          <span className="icon-siarl icon-pesquisar"></span>
          <span className={styles.rotulo}>
            {' '}&nbsp;{' '}
            Nova pesquisa
          </span>          
        </Button>
      </div>
      {openForm && (
        <Dialog
        className="ModalDocumentos"
          title={`Documento: ${idDoDocumento}`}
          onClose={() => setOpenForm(false)}
          style={{ zIndex: 999 }}
        >
          
            <ImagePreviewV2
              documentUrl={urlDoDocumento}
              next={(e: any) => nextPhoto(e)}
              previous={(e: any) => previousPhoto(e)}
            />

        </Dialog>
      )}
    </div>
    
  );
}