import { Field, FormRenderProps } from "@progress/kendo-react-form"
import styles from '../../../styles/estrategiaAdaptacao/EstrategiaAdaptacao.module.scss';
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { entidades, get, getEaRegistoAnalise } from "../../../services/utils";
import { useEffect, useState } from "react";
import { filterBy } from "@progress/kendo-data-query";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import FieldsetCombosLocalizacao from "../../localizacao/FieldsetCombosLocalizacao";
import ValidatedIdentificacaoCheckbox from "./ValidationComponentes/ValidatedIdentificacaoCheckbox";
import { requiredFieldValidator } from "../../../validations/ocorrencia";
import useVisibleColumns, { Key } from "../../../hooks/useVIsibleColumns";
import SessionStorageService from "../../../services/SessionStorageService";
import { Grid, GridCellProps, GridColumn, GridPageChangeEvent, GridToolbar } from "@progress/kendo-react-grid";
import { PagerTargetEvent } from '@progress/kendo-react-data-tools'
import { ResultadoValorUsoDoSoloCommandGridCell } from "../../usosdosolo/ResultadoValorUsoDoSoloCommandGridCell";
import { useValorUsoDoSoloStore } from "../../../stores/valorUsoDoSolo";
import DetalheValorUsoDoSolo from "../../usosdosolo/DetalheValorUsoDoSolo";
import { Dialog } from '@progress/kendo-react-dialogs'
import VisualizadorColunas from "../../Generico/VisualizadorColunas ";
import { ResultadoAcaoCommandGridCell } from "../../intervencao/ResultadoAcaoCommandGridCell";
import { process } from '@progress/kendo-data-query';
import { PESQUISAR_ACAO } from "../../../utils/constants";
import { Line } from "react-chartjs-2";
import html2canvas from "html2canvas";
import ReactGA from 'react-ga4';

type IProps = {
    formRenderProps?: FormRenderProps
    viewMode?: boolean

}
type ValorTituloUsoDoSolo = {
    id: number;
    vus: string;
    tipo: string;
    valor: string;
}
type PageState = {
    skip: number
    take: number
}
type InfoGraph = {
    label: string
    borderColor: string
    backgroundColor: string
    data: any[],

}

const keyPainelPrincipal: Key = 'acaoColunasVisiveis';
export default function AnaliseEstrategiaAdaptacao({
    viewMode,formRenderProps
}: IProps) {
    const [labels] = useState<number[]>([new Date().getFullYear(), 2050,2100])
    const _graficos:InfoGraph[]  = [] ;

    const [analiseDados, setAnaliseDados] = useState({
        isRetirada: false,
        isAdaptacao: false,
        isDefesa: false,
        isDefesaAdaptacao: false,
        graficos:_graficos,
        vusFS1: 0,
        vusFS2: 0,
        custoRetirada50: 0,
        custoRetirada100: 0,
        custoAdaptacao50: 0,
        custoAdaptacao100: 0,
        custoDefesa50: 0,
        custoDefesa100: 0,
        custoDefesaAnoActual:0,
        custoDefesaAdaptacao50: 0,
        custoDefesaAdaptacao100: 0

    })

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/AnaliseEstrategiaAdaptacao" });
    }, []);

     useEffect(()=>{
        const eaRegistoAnalise = getEaRegistoAnalise();
        let graficos:InfoGraph[]  = [] ;
        let storedResultadosVusEA =SessionStorageService.getItem("resultadoVusEstrategiaAdaptacaoPesquisa");
        if(!storedResultadosVusEA) return;
        storedResultadosVusEA = JSON.parse(SessionStorageService.getItem("resultadoVusEstrategiaAdaptacaoPesquisa"));
        const edificiosFaixa1 = storedResultadosVusEA.resultadosPesquisa.filter((e:any)=>e.usTipoComponenteVus.is_edificio && e.faixa===1)
        const edificiosFaixa2 = storedResultadosVusEA.resultadosPesquisa.filter((e:any)=>e.usTipoComponenteVus.is_edificio && e.faixa===2)
        const valorTotal = storedResultadosVusEA.valorTotal;
        const sumVus1 = edificiosFaixa1.reduce((acc: any, vus: { valor: any; })=> acc+vus.valor,0)
        const sumVus2 = edificiosFaixa2.reduce((acc: any, vus: { valor: any; })=> acc+vus.valor,0)
        const vusFS1 = storedResultadosVusEA.valorTotalFS50Anos;
        const vusFS2 = storedResultadosVusEA.valorTotalFS100Anos;

        const custo_adaptacao_edificio = eaRegistoAnalise["custo_adaptacao_edificio"]?eaRegistoAnalise["custo_adaptacao_edificio"]:0;
        const custo_demolicao_edificio = eaRegistoAnalise["custo_demolicao"]?eaRegistoAnalise["custo_demolicao"]:0;
        const custo_renaturalizacao_edificio = eaRegistoAnalise["custo_renaturalizacao"]?eaRegistoAnalise["custo_renaturalizacao"]:0;

        const custoAdaptacao50 = custo_adaptacao_edificio ? custo_adaptacao_edificio*vusFS1:0;
        const custoAdaptacao100 = custo_adaptacao_edificio ? custo_adaptacao_edificio*vusFS2:0;
        const custoRetirada50 = valorTotal+(sumVus1*custo_demolicao_edificio)+(sumVus1*custo_renaturalizacao_edificio);
        const custoRetirada100 = valorTotal+(sumVus2*custo_demolicao_edificio)+(sumVus2*custo_renaturalizacao_edificio);
        let custoAdaptacaoStored =SessionStorageService.getItem("custoAdaptacao");
        let custoDefesa50 =0;
        let custoDefesa100 = 0;
        let  custoDefesaAnoActual = 0;
        if(custoAdaptacaoStored){
            custoAdaptacaoStored = JSON.parse(custoAdaptacaoStored);
            custoDefesa50 = custoAdaptacaoStored.custoAdaptacao50;
            custoDefesa100 = custoAdaptacaoStored.custoAdaptacao100;
            custoDefesaAnoActual = custoAdaptacaoStored.anoAtual;
        }
        const custoDefesaAdaptacao50 =custoDefesa50+custoAdaptacao50;
        const custoDefesaAdaptacao100 =custoDefesa100+custoAdaptacao100;
        const isRetirada =eaRegistoAnalise["retirada"];
        const isAdaptacao=  eaRegistoAnalise["adaptacao"];
        const isDefesa= eaRegistoAnalise["defesa"];
        const isDefesaAdaptacao= eaRegistoAnalise["defesa_adaptacao"];
        if(isRetirada){
            // setGraficos((prev:InfoGraph[])=>( [...prev,
                graficos = [ ...graficos, {
                     label: "VUS FS1",
                     data: Array(labels.length).fill(custoRetirada50),
                     borderColor: "red",
                     backgroundColor: "white",
                 },
                 {
                     label: "VUS FS2",
                     data: Array(labels.length).fill(custoRetirada100),
                     borderColor: "orange",
                     backgroundColor: "white",
                 },
                ]
             //] ));
         }
         if(isDefesa){
           //  setGraficos((prev:InfoGraph[])=>( [...prev,
           graficos = [ ...graficos, 
                 {
                     label:"Defesa [M€]",
                     data : [custoDefesaAnoActual,custoDefesa50,custoDefesa100],
                     borderColor:"green",
                     backgroundColor:"white"
                 }
             ]
             // ));
         }
         if(isDefesaAdaptacao){
           //  setGraficos((prev:InfoGraph[])=>( [...prev,
           graficos = [ ...graficos, 
                 {
                     label:"Defesa + Adaptação [M€]",
                     data : [0,custoDefesaAdaptacao50,custoDefesaAdaptacao100],
                     borderColor:"blue",
                     backgroundColor:"white"
                 }
             ] 
             //));
         }
         if(isAdaptacao){
            // setGraficos((prev:InfoGraph[])=>( [...prev,
            graficos = [ ...graficos, 
                 {
                     label:"Adaptação [M€]",
                     data:[0,custoAdaptacao50,custoAdaptacao100 ],
                     borderColor:"gray",
                     backgroundColor:"white"
                 },
             ] 
             //));
             
         }
         
        setAnaliseDados({
            isRetirada,
            isAdaptacao,
            isDefesa,
            isDefesaAdaptacao,
            graficos,
            vusFS1,
            vusFS2,
            custoRetirada50,
            custoRetirada100,
            custoDefesaAnoActual,
            custoAdaptacao50,
            custoAdaptacao100,
            custoDefesa50,
            custoDefesa100,
            custoDefesaAdaptacao50,
            custoDefesaAdaptacao100,
        });

        saveScreenById("analise", {
            vusFS1,
            vusFS2,
            custoRetirada50,
            custoRetirada100,
            ano:new Date().getFullYear(),
            custoAdaptacao50,
            custoAdaptacao100,
            custoDefesa50,
            custoDefesa100,
            custoDefesaAdaptacao50,
            custoDefesaAdaptacao100,
        })

     },[])
     
  //  useEffect(()=>{
        
 //   },[])
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "top" as const,
            },
            title: {
                display: true,
                text: "Comparação entre custos de retirada, defesa e adaptação",
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'tempo',
                    color: 'black'
                }
            },
            y: {
                title: {
                    display: true,
                    text: 'Investimento [M€]',
                    color: 'black'
                }
            }
        }
    };
    const data = {
        labels,
        datasets:analiseDados.graficos
    };

    const getFormatedEurNumber=(v:number)=>{
        return new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(v);
    }

    const dataCabecalho = () => {
        return (
            <div style={{ display: "flex", flexDirection: "row" }}>
                {analiseDados.isRetirada && (
                    <div style={{ flex: 1 }}>
                        <div>
                            <h1>Retirada</h1>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                            <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
                                <h2>FS1</h2> <span>{getFormatedEurNumber(analiseDados.vusFS1)}</span>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
                                <h2>FS2</h2> <span>{getFormatedEurNumber(analiseDados.vusFS2)}</span>
                            </div>
                        </div>
                    </div>
                )}
    
                {analiseDados.isAdaptacao && (
                    <div style={{ flex: 1 }}>
                         <div>
                            <h1>Adaptação [M€]</h1>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                            <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
                                <h2>{new Date().getFullYear()}</h2> <span>{getFormatedEurNumber(0)}</span>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
                                <h2>2050</h2> <span>{getFormatedEurNumber(analiseDados.custoAdaptacao50)}</span>
                            </div> 
                             <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
                                <h2>2100</h2> <span>{getFormatedEurNumber(analiseDados.custoAdaptacao100)}</span>
                            </div>
                        </div>
                    </div>
                )}
    
                {analiseDados.isDefesa && (
                    <div style={{ flex: 1 }}>
                      <div>
                            <h1>Defesa [M€]</h1>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                            <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
                                <h2>{new Date().getFullYear()}</h2> <span>{getFormatedEurNumber(0)}</span>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
                                <h2>2050</h2> <span>{getFormatedEurNumber(analiseDados.custoDefesa50)}</span>
                            </div> 
                             <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
                                <h2>2100</h2> <span>{getFormatedEurNumber(analiseDados.custoDefesa100)}</span>
                            </div>
                        </div>
                    </div>
                )}
    
                {analiseDados.isDefesaAdaptacao && (
                    <div style={{ flex: 1 }}>
                        <div>
                            <h1>Defesa + Adaptação [M€]</h1>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                            <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
                                <h2>{new Date().getFullYear()}</h2> <span>{getFormatedEurNumber(0)}</span>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
                                <h2>2050</h2> <span>{getFormatedEurNumber(analiseDados.custoDefesaAdaptacao50)}</span>
                            </div> 
                             <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
                                <h2>2100</h2> <span>{getFormatedEurNumber(analiseDados.custoDefesaAdaptacao100)}</span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };
    
    const saveScreenById = async (key:string, props:any)=>{
       
        const divGraficoArea= document.querySelector(
          '#graficoAnalise'
        ) as HTMLCanvasElement
  
        const generatedImageGrafico = await html2canvas(divGraficoArea)
        const grafico = generatedImageGrafico.toDataURL()
        SessionStorageService.setItem(key, JSON.stringify({
            img_grafico_analise:grafico,
            ...props
          }))
     }
    return (
        <>   <div className={`${styles.form} ${styles.removeScrollDuplicado}`}>
            <Line options={options} data={data}  id="graficoAnalise"/>
             <fieldset>
             <div className={styles.espacoVerticalFieldSet}>
                {dataCabecalho()}
            </div>
             </fieldset>
             {/* <fieldset> */}
                    {/* <div> */}
                    {/* </div> */}
             {/* </fieldset> */}
        </div>
        </>);
}
