import {
    Dispatch,
    MouseEvent as ReactMouseEvent,
    SetStateAction,
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react'
import styles from '../../styles/estrategiaAdaptacao/EstrategiaAdaptacao.module.scss'
import commonStyles from '../../styles/common/Common.module.scss'
import clsx from 'clsx'
import { useCommonStore } from '../../stores/common'
import { CONSULTAR_ANALISE, CURRENT_TIMEZONE, GET_ANALISE, GET_DOCUMENTO, GET_DOCUMENTOS_BY_REGISTO_ANALISE, GET_EA_SHAPEFILE, PERMISSION_INSERIR_ESTRATEGIA_ADAPTACAO, URL_AJUDA_ESTRATEGIA_ADAPTACAO, clearLayers } from '../../utils/constants'
import { Button, DropDownButton } from '@progress/kendo-react-buttons'
import VisualizadorColunas from '../Generico/VisualizadorColunas '
import MaximizeButton from '../common/MaximizeButton'
import { GetUserNameTecnico, b64toBlob, exportPDFWithMethod, get, getCheckedRetirada, handleDownload, handleGenericError, hasPermission, post, preventPropsNullValues, setCheckedDefesa, setEaRegistoAnalise, setEaResultado } from '../../services/utils'
import { format } from 'date-fns'
import { CSVLink } from 'react-csv'
import SessionStorageService from '../../services/SessionStorageService'
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout'
import { Grid, GridCellProps, GridColumn, GridPageChangeEvent } from '@progress/kendo-react-grid'
import CustomGridCell from '../ocorrencia/CustomGridCell'
import { PagerTargetEvent } from '@progress/kendo-react-data-tools'
import { ResultadoDocumentoCommandGridCell } from '../documentos/ResultadoDocumentoCommandGridCell'
import { ResultadoEstrategiaCommandGridCell } from './ResultadoOcorrenciaCommandGridCell'
import ImagePreviewV2 from '../viewer/ImagePreviewV2'
import { Dialog } from '@progress/kendo-react-dialogs'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { process } from '@progress/kendo-data-query';
import React from 'react'
import { GridPDFExport } from '@progress/kendo-react-pdf'
import useVisibleColumns, { Key } from '../../hooks/useVIsibleColumns';
import { useEstrategiaAdaptacaoStore } from '../../stores/estrategiaAdaptacao'
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais'
import ReactGA from 'react-ga4';
import moment from 'moment'
import "moment-timezone"

type Props = {
    onCloseClick: (
        e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
    ) => void | undefined
    setShowResults: Dispatch<SetStateAction<boolean>>
    setShowEstrategiaAdaptacaoForm: Dispatch<SetStateAction<boolean>>
}

type PageState = {
    skip: number
    take: number
}
const initialDataState: PageState = { skip: 0, take: 20 }

const keyPainelPrincipal: Key = 'estrategiaAdaptacaoColunasVisiveis';
const keyPainelDocumentos: Key = 'estrategiaAdaptacaoDocumentosColunasVisiveis';
export default function ResultadoEstrategiaAdaptacao({
    onCloseClick,
    setShowResults,
    setShowEstrategiaAdaptacaoForm,
}: Props) {
  const [isMobile, setIsMobile] = useState(false);
    const [page, setPage] = useState<PageState>(initialDataState)
    const [pageSizeValue, setPageSizeValue] = useState<
      number | string | undefined
    >()
    const [openFormViewDocumento, setOpenFormViewDocumento] = useState<boolean>(false)
    const [urlDoDocumento, setUrlDoDocumento] = useState("");
    const [idDoDocumento, setIdDoDocumento] = useState("");
    const [estrategiasAdaptacao, setEstrategiasAdaptacao] = useState([])
    const [documentos, setDocumentos] = useState([])
    const isCurrentViewMaximized = useCommonStore(state => state.isCurrentViewMaximized)
    const [analises, setAnalises] = useState<any[]>([])
    const {getVisibleColumns, removeColumnsKeys} = useVisibleColumns();

 const getAnalise = async (data: any) =>
    await get<any, any>(`${GET_ANALISE}/` + data)
 
const getDocumentosByAnalises = async (data: any) =>
await post<any, any>(GET_DOCUMENTOS_BY_REGISTO_ANALISE, data)
const getShapeFile = async (data: any) =>
await post<any, any>(`${GET_EA_SHAPEFILE}`, data)

       const selectedEstrategiaResult: any = useEstrategiaAdaptacaoStore(
    (state) => state.selectedEstrategiaResult
     ) 
      const selectedEstrategiaResultId = useEstrategiaAdaptacaoStore(
        (state) => state.selectedEstrategiaResultId
      )

      const selectEstrategiaResultId = useEstrategiaAdaptacaoStore(
        (state) => state.selectEstrategiaResultId
      )

      const selectEstrategiaResult = useEstrategiaAdaptacaoStore(
        (state) => state.selectEstrategiaResult
    )

    const [dataState, setDataState] = React.useState({
    skip: 0,
    take: 20,
    sort: [],
    group: [],
  });

  const [dataStateDocumentos, setDataStateDocumentos] = React.useState({
    skip: 0,
    take: 20,
    sort: [],
    group: [],
  });

  const dataStateChange = (event: any) => {
    setDataResult(process(analises, event.dataState));
    setDataState(event.dataState);
  };

  const [dataResult, setDataResult] = React.useState(
    process(analises, dataState)
  );

   const dataStateChangeDocumentos = (event: any) => {
     setDataResultDocumentos(process(documentos, event.dataState));
     setDataStateDocumentos(event.dataState);
   };

   const [dataResultDocumentos, setDataResultDocumentos] = React.useState(
     process(documentos, dataStateDocumentos)
  );

   useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/ResultadoEstrategiaAdaptacao" });
   }, []);

    const restoreView = useCommonStore(state => state.restoreView)
    const onItemClick = (event: any) => {
        const link: HTMLAnchorElement | null = document.querySelector('#print-results');
        if (link) {
            link.click();
        }
    };

    const registarNovoClick = async () => {
      sessionStorage.setItem('novaAnaliseEstrategiaAdaptacao', 'true')
      sessionStorage.setItem('analiseAntiga', 'false')
      setEaRegistoAnalise(undefined);
      selectEstrategiaResult(undefined);
      selectEstrategiaResultId(undefined);
      setEaResultado(false);
      postMessage({ type: 'form', value: 'novaAnaliseEstrategiaAdaptacao' }, '*')
      restoreView();
    }

    const clearSearchResults = () => {
      const iframe: any = document.getElementById('iframeGeoPortal')
      SessionStorageService.removeItem('resultadoEstrategiaAdaptacaoPesquisa')
      postMessage({ type: "form", value: "pesquisarEstrategiaAdaptacao" }, "*");
      SessionStorageService.removeItem("lastSearchEstrategiaAdaptacao");
      iframe.contentWindow?.postMessage({ type: "SiarlEaRegistoAnaliseClearLayers", value: "true" }, '*')
      
      setAnalises([]);
      removeColumnsKeys(keyPainelDocumentos)
      removeColumnsKeys(keyPainelPrincipal)
      }
    const getDocumento = async (data: any) =>
    await get<any, any>(`${GET_DOCUMENTO}/` + data)

    const [selected, setSelected] = useState<number>(0)

    const handleSelect = (e: TabStripSelectEventArguments) => {
        setSelected(e.selected)
    }
    const CustomCell = (props: GridCellProps) => (
        <CustomGridCell
          {...props}
          onMouseEnter={handleRowMouseEnterById}
          onMouseLeave={handleRowMouseLeaveById}
        />
      )
      const handleRowMouseEnterById = (item: Record<string, unknown>) => {
 
       // todo 
      }
      const handleRowMouseLeaveById = (item: Record<string, unknown>) => {
               // todo 
      }
      const handlePageChange = (event: GridPageChangeEvent) => {
        const targetEvent = event.targetEvent as PagerTargetEvent
        const take = event.page.take
    
        if (targetEvent.value) {
          setPageSizeValue(targetEvent.value)
        }
    
        setPage({
          ...event.page,
          take,
        })
      }

      const handleDocumentPreviewClickById = (documentosLocal: Record<string, any>) => async () => {
        try {
          postMessage({ type: "showLoader" }, "*");
          const documentos = await getDocumento(documentosLocal.id);
          setIdDoDocumento(documentosLocal.id);
          setUrlDoDocumento(documentos.url);
          setOpenFormViewDocumento(true);
    
        } catch (error) {
          handleGenericError(error, "Não foi possivel abrir o documento!")
          setOpenFormViewDocumento(false);
    
        } finally {
    
          postMessage({ type: "hideLoader" }, "*");
    
        }
      }
      const EstrategiaAdaptacaoCommandCell = (props: GridCellProps) => (
        <ResultadoEstrategiaCommandGridCell
          {...props}
          onLocationClick={handleLocationClickById}
          onInfoClick={handleInfoClickById}
          onLocationMouseEnter={handleRowMouseEnterById}
          onLocationMouseLeave={handleRowMouseLeaveById}
        />
      )

    const consultarAnalise = async (data: any) => await post<any, any>(`${CONSULTAR_ANALISE}`, data)
      

    const initialized = useRef(false)
    useEffect(() => {
        if (!initialized.current) {
          initialized.current = true;           
           if (SessionStorageService.getItem("lastSearchEstrategiaAdaptacao") && !SessionStorageService.getItem("firstCallAnalise")) {
          const objToSearch = SessionStorageService.getItem("lastSearchEstrategiaAdaptacao") ?? '';
          consultarAnalise(objToSearch).then((data) => {
            SessionStorageService.setItem("resultadoEstrategiaAdaptacaoPesquisa", data);
            setResultsEstrategiaAdaptacao();
          })
        } 
        else {
          setResultsEstrategiaAdaptacao();
      }
    }
      }, [])
      
      const setResultsEstrategiaAdaptacao = () => {

    try {

      const results = 
        SessionStorageService.getItem('resultadoEstrategiaAdaptacaoPesquisa') ?? ''
      
      getVisibleColumns(keyPainelPrincipal, setColums)
      getVisibleColumns(keyPainelDocumentos, setColumsDocuments)

      const formattedResults = results.map((analise: any) => {
        const data_hora_relatorio = analise.data_hora_relatorio ? new Date(analise.data_hora_relatorio + 'Z').toLocaleString() : undefined;
        const dataRegisto = new Date(analise.data_registo + 'Z').toLocaleString();
        return {
          ...analise,
          data_hora_relatorio: data_hora_relatorio,
          data_registo: dataRegisto,
        };
      });
      postMessage( { type:"showLoader"}, "*");
      setAnalises(formattedResults);

      if (results) {
        const iframe: any = document.getElementById('iframeGeoPortal')
        const ids: number[] = []
        const idsApoio: number[] = []
        results.forEach((element: any) => {
          ids.push(element.id)
        })
        iframe.contentWindow?.postMessage(
          { type: 'SiarlEaRegistoAnaliseSearch', value: ids },
          '*'
        )
      }

      try {
         const idsAnalise = results.map((o: any) => o.id)
         getDocumentosByAnalises(idsAnalise).then(response => {
          setDocumentos(response)
           console.log(response)
         })

       } catch (error) {
         console.error('Erro ao buscar os dados:', error)
       }
       postMessage({type:"hideLoader"}, "*");

    } catch (e) {
       setAnalises([]);
    }

  }

  useEffect(() => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    const ids: number[] = []
    analises.forEach((element: any) => {
      ids.push(element.id)
    })
    iframe.contentWindow?.postMessage(
      { type: 'SiarlEaRegistoAnaliseSearch', value: ids },
      '*'
    )
  }, [])

    const itemRender = (props: { item: any; itemIndex: number }) => {
    const isTitleItem = props.item.key === 'titulo';
    if (isTitleItem) {
      // Item de título personalizado
      return (
        <div className={styles.DropDownButtonTitulo}>
          <span>{props.item.text}</span>
        </div>
      );
    } else {
      // Demais itens
      return (
        <div className={styles.DropDownButtonItem}>
          <span>{props.item.text}</span>
        </div>
      );
    }
  };


  const optionsExportar = [
    {
      key: 'ExportarListaResultadosExcel',
      text: 'Excel',
    },
    {
      key: 'ExportarListaResultadosShapeFile',
      text: 'Shapefile',
    },
  ]
  
  let _pdfExport: any;
  const exportExcel = () => {
    _export.save();
  };

  const onItemClickExportar = async (event: any) => {
    if (event.item.key == 'ExportarListaResultadosExcel') {
      exportExcel();
    }
    else if (event.item.key == "ExportarListaResultadosShapeFile") {
      const results = 
        SessionStorageService.getItem('resultadoEstrategiaAdaptacaoPesquisa') 
      
  
      if (results) {
        const ids: number[] = []
        results.forEach((element: any) => {
          ids.push(element.id)
        })

        const file: any = await getShapeFile(ids);
        const filePath = 'shapeFileEstrategiaAdaptacao.zip';

        handleDownload(file, filePath);
    }
  } 
}

  let _export: any;
  const exportPDF = () => {
    _pdfExport.save();
  };

  const onItemClickImprimir = async (event: any) => {
    openPdfNewTab();
  }


    const handleInfoClickById = (item: Record<string, any>) => async () => {
      const id = item.ea_registo_analise_id ?? item.id;
      const analise = await getAnalise(id);
      const tecnico = await GetUserNameTecnico(analise.utilizador_id);

       if (!analise) {
         postMessage({type: "infoMsg", value: { message: "Registo desativado" }}, "*");
         postMessage({ type: "hideLoader" }, "*");
         return;
       }
       postMessage({ type: "hideLoader" }, "*");
      
      const iframe: any = document.getElementById('iframeGeoPortal')
      
      const objToShow = {
        ...analise,
        id: analise.id,
        nuts1: analise.nuts1 ?? undefined,
        nuts2: analise.nuts2 ?? undefined,
        nuts3: analise.nuts3 ?? undefined,
        distrito : analise.distrito ?? undefined,
        concelho: analise.mobilizacao ?? undefined,
        freguesia: analise.freguesia ?? undefined,
        arh: analise.arh ?? undefined,
        capitania : analise.capitania ?? undefined,
        massaagua: analise.massaagua ?? undefined,
        data_registo: analise.data_registo ? moment.utc(analise.data_registo).tz(CURRENT_TIMEZONE) : undefined,
        data_hora_relatorio: analise.data_hora_relatorio ? moment.utc(analise.data_hora_relatorio).tz(CURRENT_TIMEZONE) : undefined,
        dt_periodo_analise_de: analise.dt_periodo_analise_de ? moment.utc(analise.dt_periodo_analise_de).tz(CURRENT_TIMEZONE) : undefined,
        dt_periodo_analise_para: analise.dt_periodo_analise_para ? moment.utc(analise.dt_periodo_analise_para).tz(CURRENT_TIMEZONE) : undefined,
        filedoc_cod: analise?.filedoc_cod ?? undefined,
        estado_id: analise?.eaEstado ?? undefined,
        utilizador_id: tecnico,
        entidade_id: analise?.entidade_id ?? undefined,
        titulo: analise?.titulo ?? undefined,
        poligono_area_analise: analise?.poligono_area_analise ?? undefined,
        poligono_area_abrangencia_obras_execucao: analise?.poligono_area_abrangencia_obras_execucao ?? undefined,
        retirada: analise?.retirada ?? undefined,
        defesa: analise?.defesa ?? undefined,
        adaptacao: analise?.adaptacao ?? undefined,
        defesa_adaptacao: analise?.defesa_adaptacao ?? undefined,
        salvaguarda_faixa_i: analise?.salvaguarda_faixa_i ?? undefined,
        salvaguarda_faixa_ii: analise?.salvaguarda_faixa_ii ?? undefined,
        markrisk_edificios: analise?.markrisk_edificios ?? undefined,
        carta_ocupacao_solo: analise?.carta_ocupacao_solo ?? undefined,
        universidade_nova: analise?.universidade_nova ?? undefined,
        servicos_ecosistema: analise?.servicos_ecosistema ?? undefined,
        lstTipologiasObra: preventPropsNullValues(analise?.lstTipologiasObra, "nome") ?? undefined,
        custo_adaptacao_edificio: analise?.custo_adaptacao_edificio ?? undefined,
        custo_demolicao: analise?.custo_demolicao ?? undefined,
        custo_renaturalizacao: analise?.custo_renaturalizacao ?? undefined,
        hasGeom: analise?.hasGeom
      }
      sessionStorage.setItem('novaAnaliseEstrategiaAdaptacao', 'false')
      sessionStorage.setItem('analiseAntiga', 'true')
      selectEstrategiaResult(objToShow)
      selectEstrategiaResultId(analise?.id)
      setEaRegistoAnalise(objToShow);
      setShowResults(false)
      setShowEstrategiaAdaptacaoForm(true)
      setEaResultado(true)
      const idToSend = [analise?.id];

      iframe.contentWindow?.postMessage(
        { type: 'SiarlEaRegistoAnaliseSearch', value: idToSend },
        '*'
      )
      iframe.contentWindow?.postMessage(
        { type: 'SiarlEaRegistoAnaliseCenter', value: idToSend },
        '*'
      )
    }
      
      const DocumentCommandCell = (props: GridCellProps) => (
        <ResultadoDocumentoCommandGridCell
          {...props}
          onLocationClick={handleLocationClickById}
          onInfoClick={handleInfoClickById}
          onDocumentPreviewClick={handleDocumentPreviewClickById}
          onLocationMouseEnter={handleRowMouseEnterById}
          onLocationMouseLeave={handleRowMouseLeaveById}
        />
      )
      const handleLocationClickById = (item: Record<string, any>) => () => {
        const iframe: any = document.getElementById('iframeGeoPortal')
        const id = item.analise_id ?? item.id;
        iframe.contentWindow?.postMessage(
          {
            type: 'SiarlEaRegistoAnaliseCenter',
            value: [id],
          },
          '*'
        )
        restoreView();
      }

      const closePreview = () => {
        setOpenFormViewDocumento(false);
      }
    
    const [columsDocuments, setColumsDocuments] = useState<{ columns: { field: string, title: string, width: number | string, show: boolean }[] }>({
        columns: [
            { field: "id", width: 70, title: "ID", show: true },
            { field: "filedoc_cod", width: 190, title: "ID Documento Filedoc", show: true },
            { field: "data_publicacao", width: 150, title: "Data publicação", show: true },
            { field: "tipoRecurso.designacao", width: 150, title: "Tipo de recurso", show: true },
            { field: "titulo", width: 90, title: "Título", show: true },
            { field: "autores", width: 90, title: "Autor", show: true },
            { field: "entidade.nome", width: 90, title: "Entidade", show: true },
            { field: "id_estado", width: 'auto', title: "Estado", show: true },
        ]
    })

    const [colums, setColums] = useState<{ columns: { field: string, title: string, cell?:any,width: number | string, show: boolean }[] }>({
        columns: [
            { field: "id", title: "Id", show: true, width: 70 },
            { field: "filedoc_cod", title: "Nº Processo APA", show: true, width: 170 },
            { field: "eaEstado.designacao", title: "Estado", show: true, width: 100 },
            {
              field: "data_registo",
              title: "Data Registo",
              show: true,
              width: 160,
            },
            { field: "tecnico", title: "Técnico", show: true, width: 100 },
            { field: "entidade_id", title: "Entidade", show: true, width: 110 },
            {
              field: "data_hora_relatorio",
              title: "Data Relatório",
              show: true,
              width: 160,
            },
            { field: "titulo", title: "Titulo", show: true, width: 100 },
            { field: "custo_adaptacao_edificio", title: "Custo de adaptação de edifício", show: true, width: "auto" },
            { field: "custo_demolicao", title: "Custo de demolição", show: true, width: "auto " },
            { field: "custo_renaturalizacao", title: "Custo de renaturalização", show: true, width: "auto " },


        ]
    })
    useEffect(() => {
        SessionStorageService.setItem(keyPainelDocumentos, JSON.stringify(columsDocuments));
    }, [columsDocuments]);

    useEffect(() => {
        SessionStorageService.setItem(keyPainelPrincipal, JSON.stringify(colums));
    }, [colums]);
    const nextPhoto = async (e: any) => {

        try{
    
          postMessage({ type: "showLoader" }, "*");
          const nextIds = documentos
            .filter((doc: any) => doc.id < idDoDocumento && doc.url !== '')
            .map((doc: any) => doc.id);
      
          if (nextIds.length > 0) {
            const nextId = Math.max(...nextIds);
            console.log(`O próximo ID é ${nextId}`);
      
            const documento = await getDocumento(nextId);
      
            setIdDoDocumento(nextId.toString());
            setUrlDoDocumento(documento.url);
    
            const iframe: any = document.getElementById('iframeGeoPortal')
            const idOcorrencia = documento.ocorrencia_id;
            iframe.contentWindow?.postMessage(
              {
                type: 'SiarlOcorrenciasCenter',
                value: [idOcorrencia],
              },
              '*'
            )
      
          } else {
            console.log("Não há um próximo ID na lista.");
          }
    
        }catch(error){
    
          handleGenericError(error, "Não foi possivel abrir o documento!")
    
        }finally{
    
          postMessage({ type: "hideLoader" }, "*");
    
          e.preventDefault();
        }
       
      }
    
      const previousPhoto = async (e: any) => {
    
        try{
    
          postMessage({ type: "showLoader" }, "*");
          const previousIds = documentos
            .filter((doc: any) => doc.id > idDoDocumento && doc.url !== '')
            .map((doc: any) => doc.id);
      
          if (previousIds.length > 0) {
            const previousId = Math.min(...previousIds);
            console.log(`O anterior ID é ${previousId}`);
      
            const documento = await getDocumento(previousId);
      
            setIdDoDocumento(previousId.toString());
            setUrlDoDocumento(documento.url);
    
            const iframe: any = document.getElementById('iframeGeoPortal')
            const idOcorrencia = documento.ocorrencia_id;
            iframe.contentWindow?.postMessage(
              {
                type: 'SiarlOcorrenciasCenter',
                value: [idOcorrencia],
              },
              '*'
            )
            restoreView();
    
          } else {
            console.log("Não há um anterior ID na lista.");
          }
    
        }catch(error){
    
          handleGenericError(error, "Não foi possivel abrir o documento!")
    
        }finally{
    
          postMessage({ type: "hideLoader" }, "*");
    
          e.preventDefault();
        }
           
      }

      useLayoutEffect(() => {

        const handleResize = () => {
    
          if (window.innerWidth <= telaMobile) {
            
            setIsMobile(true);
            ocultaFloatingMenu_e_Mapa();
    
          }else{
    
            setIsMobile(false);
            mostraFloatingMenu_e_Mapa();
    
          }
    
        };
      
        window.addEventListener('resize', handleResize);
      
        handleResize();
      
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    
      }, []);  
    
      const openPdfNewTab = () => {
        return new Promise<void>((resolve, reject) => {
            
            postMessage({ type: "showLoader" }, "*");
    
            setTimeout(() => {
               const columnsToShow = colums.columns.filter(x => x.show);
                const dataToExport = analises.map((item: any) => {
                    const filteredItem: any = {};
                    columnsToShow.forEach((column: any) => {
                        filteredItem[column.field] = item[column.field];
                    });
                    return filteredItem;
                });
                
                exportPDFWithMethod(columnsToShow, dataToExport, 4)
                    .then((pdfData: any) => {
                        postMessage({ type: "hideLoader" }, "*");
    
                            const base64Data = pdfData.split(";base64,")[1];
                            console.log(base64Data);
                            const blob = b64toBlob(base64Data, 'application/pdf');
                            const blobUrl = URL.createObjectURL(blob);
    
                          
                            window.open(blobUrl, '_blank');
                            resolve();
                      
                    })
                    .catch(error => {
                        console.error('Error exporting PDF:', error);
                        reject(error);
                    });
            }, 1000);
        });
    };
    
      const onItemClickMais = (event: any) => {
    
        elementoClickMais(event);

        onItemClickExportar(event);

        onItemClickImprimir(event);
    
      };
    
      const optionsMais = [
        {
          key: 'novoRegisto',
          text: 'Nova análise',
          elementType: 'button',
          className: 'icon-siarl icon-registar btnIconRegistar'
        },
        {
          key: 'titulo',
          text: 'Imprimir',
        },
        {
          key: 'imprimirFicha',
          text: 'Resultados',
          className: 'icon-siarl icon-imprimir btnIconRegistar'
        },
        {
          key: 'titulo',
          text: 'Exportar',
        },
        ...optionsExportar.map(option => ({
          ...option,
          className: 'icon-siarl icon-exportar btnIconRegistar',
        })),
      ]

    const openAjuda = () => {
      window.open(URL_AJUDA_ESTRATEGIA_ADAPTACAO, "_blank");
    }

    return (
        <div className={clsx(styles.base, isCurrentViewMaximized && commonStyles.maximizedBaseForm)}>
            <div className={styles.header}>
                <div>
                    <div className={styles.nomeDoModulo}> Estratégia de adaptação</div>
                    <div className={styles.tituloDaPagina}>Resultados de pesquisa</div>
                </div>

                <div>
                    <CSVLink
                        title="Imprimir resultados"
                        id="print-results"
                        data={estrategiasAdaptacao}
                        filename={`siarl-estrategiasAdaptacao-${format(new Date(), 'ddMMyyyHHmm')}`}
                        hidden
                    ></CSVLink>



                    {hasPermission(PERMISSION_INSERIR_ESTRATEGIA_ADAPTACAO) ?
                        <Button onClick={registarNovoClick} className={styles.btnNovoRegisto}
                        style={{ display: isMobile ? 'none' : '' }}
                        id='novoRegisto' title='Nova Análise'>
                            <span className="icon-siarl icon-registar btnIconRegistar"></span>{' '}
                            &nbsp;{' '}
                            Nova Análise
                        </Button>
                        : ''}

                  {!isMobile &&
                    <VisualizadorColunas
                        columns={selected === 0 ? colums : columsDocuments}
                        setColumns={selected === 0 ? setColums : setColumsDocuments}
                        styles={styles.btnEditar2}
                    />
                  }

                      <button className={styles.btnEditar2} onClick={onItemClickImprimir} style={{ display: isMobile ? 'none' : '' }} id='imprimirFicha' title='Imprimir'>
                        <span className="icon-siarl icon-imprimir"></span>
                      </button>
                    
                      {!isMobile &&
                        <DropDownButton
                          items={optionsExportar}
                          className={styles.btnImprimir}
                          style={{marginTop: '-4px'}}
                          iconClass="icon-siarl icon-exportar"
                          onItemClick={onItemClickExportar}
                          fillMode={'flat'}
                          title='Exportar'
                          itemRender={itemRender}
                        />
                      }

                    {isMobile &&
                        <DropDownButton
                            items={optionsMais}
                            className={styles.btnMais}
                            iconClass="icon-siarl icon-mais"
                            onItemClick={onItemClickMais}
                            fillMode={'flat'}
                            itemRender={itemRenderMais}
                            title="Mais Opções"
                        />
                    }
                    
                    <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
                      <span className="icon-siarl icon-ajuda"></span>
                    </button>
                    <button className={styles.btnFechar} onClick={onCloseClick} title="Minimizar">
                        <span className="icon-siarl icon-minimizar"></span>
                    </button>

                    {!isMobile &&
                    <MaximizeButton className={styles.btnMaximizar} />
                    }
                </div>
            </div>
            
    <TabStrip selected={selected} onSelect={handleSelect} scrollable={isMobile}>
      <TabStripTab title="Analises">
        <ExcelExport 
          fileName='Resultado_EstrategiaAdaptacao'
          data={analises}
          ref={(exporter) => {
            _export = exporter
          }}
        >
          <Grid
            className={styles.form}
            sortable={true}
            onDataStateChange={dataStateChange}
            data={dataResult.data.length > 0 ? dataResult : analises.slice(page.skip, page.take + page.skip)}
            {...dataState}
            pageable={{
              buttonCount: 5,
              info: true,
              type: 'numeric',
              pageSizes: true,
              previousNext: true,
            }}
            total={analises.length}
            //groupable={true}
          >
            <GridColumn cell={EstrategiaAdaptacaoCommandCell} width={60} />

            {colums?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
          </Grid>
        </ExcelExport>

        <GridPDFExport
          fileName='Resultado_EstrategiaAdaptacao'
          ref={(element) => {
            _pdfExport = element
          }}
          margin="1cm"
          paperSize={['340mm', '297mm']}         

        >
          <Grid
            className={styles.form}
            sortable={true}
            onDataStateChange={dataStateChange}
            data={analises}
            {...dataState}
            pageable={{
              buttonCount: 5,
              info: true,
              type: 'numeric',
              pageSizes: true,
              previousNext: true,
            }}
            total={analises.length}
            skip={page.skip}
            take={page.take}
            onPageChange={handlePageChange}
            //groupable={true}
          >
            <GridColumn cell={EstrategiaAdaptacaoCommandCell} width={60} />
            {colums?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
          </Grid>
        </GridPDFExport>
      </TabStripTab>

                <TabStripTab title="Documentos">
                    <Grid
                        className={styles.form}
                        data={documentos?.slice(page.skip, page.take + page.skip)}
                        pageable={{
                            buttonCount: 5,
                            info: true,
                            type: 'numeric',
                            pageSizes: true,
                            previousNext: true,
                        }}
                        skip={page.skip}
                        take={page.take}
                        total={documentos.length}
                        onPageChange={handlePageChange}
                        cells={{ data: CustomCell }}
                    >
                        <GridColumn cell={DocumentCommandCell} width={86} />
                        {columsDocuments?.columns?.map((column, i) => {
                            return (
                                column.show && (
                                    <GridColumn key={i} field={column.field} width={column.width} title={column.title} />
                                )
                            );
                        })}
                    </Grid>
                </TabStripTab>
            </TabStrip>
      {openFormViewDocumento && (
        <Dialog
          className="ModalDocumentos"
          title={`Documento: ${idDoDocumento}`}
          onClose={closePreview}
          style={{ zIndex: 999 }}
        >
          <ImagePreviewV2
            documentUrl={urlDoDocumento}
            next={(e: any) => nextPhoto(e)}
            previous={(e: any) => previousPhoto(e)}
          />
        </Dialog>
      )}
       <div
      className={clsx(styles.footer, styles.justify_one_child, isCurrentViewMaximized && commonStyles.maximizedFooter)}
    >
      <Button className={styles.btnPesquisar} onClick={clearSearchResults} title='Nova Pesquisa'>
        <span className="icon-siarl icon-pesquisar"></span>
        <span className={styles.rotulo}>
          {' '}&nbsp;{' '}
          Nova pesquisa
        </span>
      </Button>
    </div>
        </div>
    )

}