import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import styles from '../../styles/intervencao/Intervencao.module.scss'

type Props = {
  onInfoClick: (modelo: Record<string, unknown>) => () => void
  dataItem: Record<string, unknown>
}

export const ResultadoCandidaturaCommandGridCell = ({
  onInfoClick,
  dataItem,
}: Props) => {
  return (
    <td className="k-command-cell">
      <div className={styles.gridCommand}>
        <div
          role="button"
          className={styles.gridCommandIconButton}
          onClick={onInfoClick(dataItem)}
        >
          <FontAwesomeIcon icon={faInfoCircle} title="Detalhes de registo" />
        </div>
      </div>
    </td>
  )
}
