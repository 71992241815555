import { faInfoCircle, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from '../../styles/ocorrencia/ConsultarOcorrenciaForm.module.scss'
import clsx from 'clsx'

type Props = {
  onInfoClick: (ocorrencia: Record<string, unknown>) => () => void
  onMapCenter: (ocorrencia: Record<string, unknown>) => () => void
  dataItem: Record<string, unknown>
}

export const ResultadoProcessoCommandGridCell = ({
  onInfoClick,
  onMapCenter,
  dataItem,
}: Props) => {
  return (
    <td className="k-command-cell">
      <div className={'gridCommand'}>
        {dataItem.hasGeom ? 
        <div
          role="button"
          onClick={onMapCenter(dataItem)}
          className={clsx(styles.gridCommandIconButton, 'espacoGridAction')}
        >
          <FontAwesomeIcon icon={faLocationDot} title="Mostrar no Mapa" />
        </div>
        : <div className='espacoGridAction'>&nbsp;</div>}
        <div
          role="button"
          className={styles.gridCommandIconButton}
          onClick={onInfoClick(dataItem)}
        >
          <FontAwesomeIcon icon={faInfoCircle} title="Detalhes de registo" />
        </div>
      </div>
    </td>
  )
}
