import { Field, FormRenderProps } from "@progress/kendo-react-form"
import styles from '../../../styles/estrategiaAdaptacao/EstrategiaAdaptacao.module.scss';
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { coordenadasPoligono, entidades, fillVusAsync, getEaRegistoAnalise, handleGenericError, post, vus } from "../../../services/utils";
import { useEffect, useState } from "react";
import { filterBy } from "@progress/kendo-data-query";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import FieldsetCombosLocalizacao from "../../localizacao/FieldsetCombosLocalizacao";
import ValidatedIdentificacaoCheckbox from "./ValidationComponentes/ValidatedIdentificacaoCheckbox";
import { requiredFieldValidator } from "../../../validations/ocorrencia";
import useVisibleColumns, { Key } from "../../../hooks/useVIsibleColumns";
import SessionStorageService from "../../../services/SessionStorageService";
import { Grid, GridCellProps, GridColumn, GridPageChangeEvent, GridToolbar } from "@progress/kendo-react-grid";
import { PagerTargetEvent } from '@progress/kendo-react-data-tools'
import { ResultadoValorUsoDoSoloCommandGridCell } from "../../usosdosolo/ResultadoValorUsoDoSoloCommandGridCell";
import { useValorUsoDoSoloStore } from "../../../stores/valorUsoDoSolo";
import DetalheValorUsoDoSolo from "../../usosdosolo/DetalheValorUsoDoSolo";
import { Dialog } from '@progress/kendo-react-dialogs'
import VisualizadorColunas from "../../Generico/VisualizadorColunas ";
import { GET_EA_VALOR_USO_DE_SOLO_BY_CUSTOM_FILTERS, GET_VALOR_USO_DE_SOLO_BY_CUSTOM_FILTERS } from "../../../utils/constants";
import { useCommonStore } from "../../../stores/common";
import html2canvas from "html2canvas";
import { process } from '@progress/kendo-data-query';
import ReactGA from 'react-ga4';

type IProps = {
    formRenderProps?: FormRenderProps
    viewMode?: boolean
    colunasVisiveis?:any
}
type ValorTituloUsoDoSolo = {
    id: number;
    vus: string;
    tipo: string;
    valor: string;
}
type PageState = {
    skip: number
    take: number
}

const keyPainelPrincipal: Key = 'valorUsoSoloColunasVisiveis';
export default function VusEstrategiaAdaptacao({ 
    viewMode,formRenderProps,colunasVisiveis
 }: IProps) {
    const isMarRisk = formRenderProps?.valueGetter('markrisk_edificios');
    const isUniversidadeNova = formRenderProps?.valueGetter('universidade_nova');
    const isCarta_ocupacao_solo = formRenderProps?.valueGetter('carta_ocupacao_solo');
    const isServicos_ecosistema = formRenderProps?.valueGetter('servicos_ecosistema');
    const isSalvaguarda_faixa1 = formRenderProps?.valueGetter('salvaguarda_faixa_i');
    const isSalvaguarda_faixa2 = formRenderProps?.valueGetter('salvaguarda_faixa_ii');
    const [valoresUsoDoSolo, setValoresUsoDoSolo] = useState<ValorTituloUsoDoSolo[]>([])
    const [Analise_Resultado, setAnalise_Resultado] = useState<any>()
    let valorTotal = 0;
    const { getVisibleColumns } = useVisibleColumns();
    const { ano, faixaSalvaguarda } = Analise_Resultado?.pesquisa ?? {};
    const closeModal = () => {
        setShowDetailModal(!showDetailModal)
    }
    const [showDetailModal, setShowDetailModal] = useState<boolean>(false)
    const getValoresUsoDoSolo = async (data: any) =>
    await post<any, any>(GET_EA_VALOR_USO_DE_SOLO_BY_CUSTOM_FILTERS, data)
    
    const getVusOnRender = async ()=>{
        try{
            postMessage({ type: 'showLoader' }, '*')
            let id_vus: any[] | undefined = undefined;
            const isMark = getEaRegistoAnalise()?.markrisk_edificios
            const isUni = getEaRegistoAnalise()?.universidade_nova
            const IsCarta = getEaRegistoAnalise()?.carta_ocupacao_solo
            const isSEconsistema = getEaRegistoAnalise()?.servicos_ecosistema
            if(isMark){
                id_vus= Array.isArray(id_vus) ? [...id_vus, 1]:[1];
            }
            if(isUni){
                id_vus= Array.isArray(id_vus) ? [...id_vus, 2]:[2];
            }
            if(IsCarta){
                id_vus= Array.isArray(id_vus) ? [...id_vus, 3]:[3];
            }  
            if(isSEconsistema){
                id_vus= Array.isArray(id_vus) ? [...id_vus, 4]:[4];
            }
              const dataToSend = {
                flatCoordinates: coordenadasPoligono ?? undefined, 
                id_vus,
                ids_faixa_salva_guarda : (isSalvaguarda_faixa1 && isSalvaguarda_faixa2) ? [1, 2] : (isSalvaguarda_faixa1 ? [1] : (isSalvaguarda_faixa2 ? [2] : undefined))
            }
              const shouldSendData = Object.values(dataToSend).some((value) => value !== undefined);
                if(shouldSendData){
                    const resultadoValoresUsoDoSolo = await getValoresUsoDoSolo(dataToSend);
                    SessionStorageService.setItem(
                        'resultadoVusEstrategiaAdaptacaoPesquisa',
                        JSON.stringify(resultadoValoresUsoDoSolo)
                      )
                    setValoresUsoDoSolo(resultadoValoresUsoDoSolo.resultadosPesquisa);
                    setAnalise_Resultado(resultadoValoresUsoDoSolo);
                    const valor = resultadoValoresUsoDoSolo.valorTotal;
                    valorTotal = valor;
                  
                        const ids = resultadoValoresUsoDoSolo.resultadosPesquisa.map((v:{id?:number})=> v.id);
                        const iframe: any = document.getElementById('iframeGeoPortal')
                        iframe.contentWindow?.postMessage(
                            { type: 'SiarlUsVusPoligonoSearch', value:ids},
                            '*'
                        )
                        saveScreenById("imgsVus")
                }
              
        }catch(error){
            handleGenericError(error);
        }
        finally{
            postMessage({ type: 'hideLoader' }, '*')

        }
 
    }
    const ACell = (props: any) => {

        return (
            <td colSpan={props.colSpan} style={props.style}>
                Taxa atualizacão: {''}
            </td>
        );

    };

    const BCell = (props: any) => {

        return (
            <td colSpan={props.colSpan} style={props.style}>
                Ano de análise: {ano}
            </td>
        );

    };

    const CCell = (props: any) => {

        return (
            <td colSpan={props.colSpan} style={props.style}>
                Faixa 1: {faixaSalvaguarda}
            </td>
        );

    };

    const DCell = (props: any) => {

        return (
            <td colSpan={props.colSpan} style={props.style}>
                Faixa 2: {faixaSalvaguarda}
            </td>
        );

    };
    const ECell = (props: any) => {
        return (
            <td colSpan={props.colSpan} style={props.style}>
                Total: <span style={{ textShadow: '0 0 black' }}>{ getFormatedEurNumber()}</span>
            </td>
        );
        
    };
    const getFormatedEurNumber=()=>{
        const v =Analise_Resultado?.valorTotal
        return new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(v);
    }

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/VusEstrategiaAdaptacao" });
    }, []);

    useEffect(() => {
        if(!vus){
         fillVusAsync();
         }
       
       
     }, []);
    const initialDataState: PageState = { skip: 0, take: 10 }
    const [page, setPage] = useState<PageState>(initialDataState)
    const [pageSizeValue, setPageSizeValue] = useState<
        number | string | undefined
    >()
    const handlePageChange = (event: GridPageChangeEvent) => {
        const targetEvent = event.targetEvent as PagerTargetEvent
        const take = event.page.take

        if (targetEvent.value) {
            setPageSizeValue(targetEvent.value)
        }

        setPage({
            ...event.page,
            take,
        })
    }
    const selectValorUsoDoSolo = useValorUsoDoSoloStore((state) => state.selectValorUsoDoSoloResult)
    const handleInfoClickById = (valorUsoDoSolo: Record<string, any>) => async () => {
        selectValorUsoDoSolo(valorUsoDoSolo)
    }
    const restoreView = useCommonStore(state => state.restoreView)

    const handleLocationClickById = (item: Record<string, any>) => () => {
        const iframe: any = document.getElementById('iframeGeoPortal')
        const id = item.id;
        iframe.contentWindow?.postMessage(
          {
            type: 'SiarlUsVusPoligonoSearch',
            value: [id],
          },
          '*'
        )
       restoreView();
      }

    const CommandCell = (props: GridCellProps) => (
        <ResultadoValorUsoDoSoloCommandGridCell
            {...props}
            onMapCenter={handleLocationClickById}
        />
    )
    useEffect(()=>{
        getVusOnRender();
    },[])

    const getCurrentColumn =(column:string)=>{
        if(column==="A"){
            return ACell;
        }    if(column==="B"){
            return BCell;
        }    if(column==="C"){
            return CCell;
        }    if(column==="D"){
            return DCell;
        } if(column==="E"){
            return ECell;
        }
    }
    const saveScreenById = async (key:string)=>{
        let mapImage = ''
    
        if (!window.location.href.includes('localhost')) {
          const mapIframe = document.querySelector(
            '#iframeGeoPortal'
          ) as HTMLIFrameElement
    
          const mapIframeInnerContent =
            mapIframe.contentWindow?.document.querySelector(
              'section.content'
            ) as HTMLElement
    
          const generatedImage = await html2canvas(mapIframeInnerContent)
          mapImage = generatedImage.toDataURL()
          SessionStorageService.setItem(key, JSON.stringify({
            img_mapa_vus:mapImage
          }))
    
    
        }
     }

     const [dataState, setDataState] = useState({
        skip: 0,
        take: 20,
        sort: [],
        group: [],
      });
     const [dataResult, setDataResult] = useState(
        process(valoresUsoDoSolo, dataState)
      );
     const dataStateChange = (event: any) => {
        setDataResult(process(valoresUsoDoSolo, event.dataState));
        setDataState(event.dataState);
      };
     
    
    return (
        <>   <div className={`${styles.form} ${styles.removeScrollDuplicado}`}>
            <Grid
                data={valoresUsoDoSolo?.slice(page.skip, page.take + page.skip).map((vus: any) => (
                    {
                        ...vus,
                        valor: new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(vus.valor)
                    }
                ))}
                onDataStateChange={dataStateChange}
                {...dataState}
                pageable={{
                    buttonCount: 5,
                    info: true,
                    type: 'numeric',
                    pageSizes: true,
                    previousNext: true,
                }}
                sortable={true}
                total={valoresUsoDoSolo?.length}
                //groupable={true}
            >
                <GridColumn cell={CommandCell} width={156} footerCell={ACell} />
                {colunasVisiveis?.columns?.map((column:any, i:number) => {
                    return (
                        column.show && (
                            <GridColumn key={i} field={column.field} width={column.width} title={column.title} footerCell={getCurrentColumn(column.footerCell)} />
                        )
                    );
                })}

            </Grid>
            {
                showDetailModal ?
                    <Dialog width={950} height={550} onClose={closeModal} title={"Detalhes"}>
                        < DetalheValorUsoDoSolo />
                    </Dialog >
                    : <></>
            }
        </div>

        </>);
}