import { useEffect, useState, SyntheticEvent, useCallback } from 'react'
import styles from '../../styles/viewer/ViewerMap.module.scss'
import commonStyles from '../../styles/common/Common.module.scss'
import {
  AUTH_LOCALSTORAGE,
  GEOPORTAL_MAPA_PUBLICO_URL,
  GEOPORTAL_MAPA_URL,
  PASS_GUEST,
  ROLE_MAPAS_PRIVADOS,
  USER_GUEST,
} from '../../utils/constants'
import {
  geoPortalUrl,
  mapStore,
  sendUserLogin,
  setGeoPortalUrl,
  setGeoPortalUser,
} from '../../services/utils'
import { login } from '../../services/auth'
import clsx from 'clsx'
import { useCommonStore } from '../../stores/common'

export default function ViewerMap() {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const gpUrl = mapStore((state: any) => state.url)
  const setGPUrl = mapStore((state: any) => state.setUrl)
  const [randomHex, setRandomHex] = useState(
    Math.floor(Math.random() * new Date().getTime()).toString(16)
  )

  const isCurrentViewMaximized = useCommonStore(
    (state) => state.isCurrentViewMaximized
  )
  const setIsMapLoaded = useCommonStore(
    (state) => state.setIsMapLoaded
  )

  const handleIframeLoading = useCallback(
    (event: SyntheticEvent<HTMLIFrameElement, Event>) => {
      const currentSrc = event.currentTarget.src
      if (currentSrc.includes('geoportal/Account/Logout')) {
        setRandomHex(
          Math.floor(Math.random() * new Date().getTime()).toString(16)
        )
        setIsMapLoaded(true)
      }
    },
    []
  )

  useEffect(() => {
    const syncLogin = async (guestLogin: any) => {
      const loginResponse = await login(guestLogin)
      if (loginResponse) {
        setIsLoggedIn(true)
        location.reload();
      }
    }

    addEventListener(
      'message',
      (event: MessageEvent) => {
        if (event.data.type == 'userLoginSended') {
          sendUserLogin()
        } 
        else if (event.data.type == "MapLoaded") {
          setIsMapLoaded(true);
        }
      },
      false
    )
    setGPUrl(GEOPORTAL_MAPA_PUBLICO_URL)
    if (!localStorage.getItem(AUTH_LOCALSTORAGE)) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      //return <Navigate to="/login" state={{ from: location }} replace />;
      const guestLogin = { username: USER_GUEST, password: PASS_GUEST }
      setGeoPortalUser(guestLogin)
      syncLogin(guestLogin)
    }
    
  }, [])

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     const objLogin = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE)?? "");
  //     const roles : any[] = objLogin.user?.roles;
  //     const role = roles?.filter(x => x.name?.toUpperCase() == ROLE_MAPAS_PRIVADOS);
  //     console.log("Role: " + JSON.stringify(role));
  //     if (role?.length > 0) {
  //       setGPUrl(GEOPORTAL_MAPA_URL);
  //     } else {
  //       setGPUrl(GEOPORTAL_MAPA_PUBLICO_URL);
  //     }
  //   }
  // }, [isLoggedIn])
  //  return <iframe id="iframeGeoPortal"
  //   src="http://localhost:44301/Maps?ID=125" className={styles.map} />
  return (
    <iframe
      id="iframeGeoPortal"
      src={gpUrl}
      key={randomHex}
      onLoad={handleIframeLoading}
      className={clsx(
        styles.map,
        isCurrentViewMaximized && commonStyles.maximizedHidden
      )}
    />
  )
}
