import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Field, FormElement } from '@progress/kendo-react-form'
import { Button } from '@progress/kendo-react-buttons'
import {
  Notification,
  NotificationGroup,
} from '@progress/kendo-react-notification'
import { Fade } from '@progress/kendo-react-animation'

import ValidatedInput from '../forms/ValidatedInput'
import styles from '../../styles/auth/RegisterForm.module.scss'
import { post } from '../../services/utils'
import { SAVE_NEW_USER } from '../../utils/constants'
import { registerFormValidator } from '../../validations/auth'
import clsx from 'clsx'
import ReactGA from 'react-ga4';

export default function RegisterForm() {
  const navigate = useNavigate()
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const formRef = useRef<Form>(null)

  const saveNewUser = async (data: unknown) =>
    await post<unknown, unknown>(`${SAVE_NEW_USER}`, data)

  const handleSubmit = async (dataItem: { [name: string]: string }) => {
    const dataToSend = {
      id: 0,
      UserName: dataItem.username,
      Name: dataItem.name,
      Password: dataItem.password,
      EmailAddress: dataItem.email,
      NormalizedEmailAddress: dataItem.email,
      AccessFailedCount: 0,
      Surname: dataItem.surname,
      NormalizedUserName: dataItem.username,
      IsDeleted: false,
      IsActive: true,
      IsEmailConfirmed: true,
      IsLockoutEnabled: true,
      IsPhoneNumberConfirmed: true,
      IsTwoFactorEnabled: false,
      active: true,
      roles_id: 'USER',
    }


    try {
      postMessage({ type: 'showLoader' }, '*')
      await saveNewUser(dataToSend)
      formRef.current?.onReset()
      postMessage({type: "sucessMsg", value: { message: "Gravado com sucesso."} }, "*");
      handleLoginClick();
    } catch {
      setError(true)
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  const handleLoginClick = () => navigate('/login')

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/RegisterForm" });
  }, []);

  return (
    <>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        validator={registerFormValidator}
        render={() => (
          <FormElement className={styles.form}>
            <fieldset className={'k-form-fieldset'}>
            
            <div className={styles.bottomSpacer}>
                <Field
                  name="name"
                  component={ValidatedInput}
                  label="Nome"
                />
              </div>
              <div className={styles.bottomSpacer}>
                <Field
                  name="surname"
                  component={ValidatedInput}
                  label="Apelido"
                />
              </div>
              
              <div className={styles.bottomSpacer}>
                <Field
                  name="username"
                  component={ValidatedInput}
                  label="Nome de utilizador (Login)"
                />
              </div>

              <div className={styles.bottomSpacer}>
                <Field
                  name="email"
                  component={ValidatedInput}
                  label="E-mail"
                />
              </div>

              <div>
                <Field
                  name="password"
                  type="password"
                  component={ValidatedInput}
                  label="Palavra-passe"
                />
              </div>
            </fieldset>
            <div className={`k-form-buttons ${styles.buttons}`}>
              <Button
                className={clsx(styles.submit, styles.registarCor)}
                type="submit"
              >
                Registar
              </Button>
              <Button
                className={clsx(styles.login, styles.iniciarCor)}
                fillMode="outline"
                onClick={handleLoginClick}
                type="button"
              >
                Iniciar sessão
              </Button>
            </div>
          </FormElement>
        )}
      />
      <NotificationGroup
        style={{ top: 0, left: '25%', transform: 'translateX(-50%)' }}
      >
        <Fade>
          {success && (
            <Notification
              type={{ style: 'success', icon: true }}
              closable={true}
              onClose={() => setSuccess(false)}
            >
              <span>Salvo com sucesso.</span>
            </Notification>
          )}
          {error && (
            <Notification
              type={{ style: 'error', icon: true }}
              closable={true}
              onClose={() => setError(false)}
            >
              <span>Ocorreu um erro ao gravar.</span>
            </Notification>
          )}
        </Fade>
      </NotificationGroup>
    </>
  )
}
