import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import styles from '../../styles/ocorrencia/OcorrenciasStylesv2.module.scss'
import { useEffect, useState } from 'react'
import { hasPermission } from '../../services/utils'
import { PERMISSION_CONSULTAR_OCORRENCIA, PERMISSION_DOCUMENTOS_CONSULTA_AVANCADA, PERMISSION_OCORRENCIA_PERFIL_COMPLETO_EDICAO, PERMISSION_OCORRENCIA_PERFIL_COMPLETO_LEITURA, PERMISSION_OCORRENCIA_PERFIL_INTERMEDIO_EDICAO, PERMISSION_OCORRENCIA_PERFIL_INTERMEDIO_LEITURA, PERMISSION_OCORRENCIA_PERFIL_TOTAL } from '../../utils/constants'

type HoverState = 'idle' | 'enter' | 'leave'


type Props = {
  onLocationClick: (ocorrencia: Record<string, unknown>) => () => void
  onInfoClick: (ocorrencia: Record<string, unknown>) => () => void
  dataItem: Record<string, unknown>
  onLocationMouseEnter: (ocorrencia: Record<string, unknown>) => void
  onLocationMouseLeave: (ocorrencia: Record<string, unknown>) => void
}

export const ResultadoOcorrenciaCommandGridCell = ({
  onLocationClick,
  onInfoClick,
  dataItem,
  onLocationMouseEnter,
  onLocationMouseLeave
}: Props) => {
  
  const [locationHoverState, setLocationHoverState] =
    useState<HoverState>('idle')

  const handleMouseEnter = () => setLocationHoverState('enter')
  const handleMouseLeave = () => setLocationHoverState('leave')

  useEffect(() => {
    if (locationHoverState === 'enter') {
      onLocationMouseEnter(dataItem)
    } else if (locationHoverState === 'leave') {
      onLocationMouseLeave(dataItem)
    }
  }, [locationHoverState])

  return (
    <td className="k-command-cell"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <div className={'gridCommand'}>
        <div
          role="button"
          onClick={onLocationClick(dataItem)}
          className={styles.gridCommandIconButton}
        > 
          <FontAwesomeIcon
            icon={faLocationDot}
            title="Mostrar no Mapa"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        </div>
        {hasPermission(PERMISSION_CONSULTAR_OCORRENCIA) || 
         hasPermission(PERMISSION_OCORRENCIA_PERFIL_INTERMEDIO_EDICAO) ||
         hasPermission(PERMISSION_OCORRENCIA_PERFIL_COMPLETO_EDICAO) ||
         hasPermission(PERMISSION_OCORRENCIA_PERFIL_COMPLETO_LEITURA) ||
         hasPermission(PERMISSION_OCORRENCIA_PERFIL_TOTAL)
         ?
        <div
        role="button"
        className={styles.gridCommandIconButton}
        onClick={onInfoClick(dataItem)}
      >
        <FontAwesomeIcon
          icon={faInfoCircle}
          title="Detalhes de registo"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      </div> : ''}
      </div>
    </td>

  )
}
