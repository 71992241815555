import clsx from 'clsx'
import styles from '../../styles/geovisualizadores/Geovisualizadores.module.scss'

import type { ConsultarMapasResult } from './ListarGeovisualizadores'
import { useEffect, useState } from 'react'
import MapaItemGrid from './MapaItemGrid'
import { AUTH_LOCALSTORAGE } from '../../utils/constants'

type Props = {
  item: ConsultarMapasResult[number]
  variant?: 'user' | 'platform'
}

export default function CollapsableMapaItem({
  item,
  variant = 'platform',
}: Props) {
  const [open, setOpen] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [openTema, setOpenTema] = useState<any[]>([]);

  useEffect(() => {
    let openTemaLocal;
    const openTemaLst: any[] = [];
    item.gvSubTemas.forEach(x => {
      openTemaLocal = {
        key: x.descricao,
        open: false
      }
      openTemaLst.push(openTemaLocal);
    });
    setOpenTema(openTemaLst);
  }, []);

  const openSubtema = (subtema: any) => {
    const tema = openTema.find(x => x.key == subtema.descricao)
    if (tema) {
      const temaLocal = openTema.filter(x => x.key != subtema.descricao);
      temaLocal.push({
        key: subtema.descricao,
        open: !tema.open
      })
      setOpenTema(temaLocal);
    } else {
      const openTemaFull = openTema;
      const temaLocal2 = {
        key: subtema.descricao,
        open: true
      };
      openTemaFull.push(temaLocal2);
      setOpenTema(openTemaFull);
    }
  }

  const getOpenSubtema = (subtema: any) : boolean | undefined => {
    return openTema.find(x => x.key == subtema.descricao)?.open;
  }

  const userLocal = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}')

  return (
    <div className={styles.itemBase}>
      <div
        className={styles.item}
        onClick={() => setOpen((previousState) => !previousState)}
      >
        <div>
          <h3 className={styles.name}>{item.tema}</h3>
          {variant === 'user' ? (
            <span className={styles.description}>
              {item.gvSubTemas.reduce((acc, current) => {
                const count = current.gvMapaPartilhados.filter(
                  (mapaPartilhado) =>
                    mapaPartilhado.id_utilizador === userLocal?.user?.id
                ).length

                return acc + count
              }, 0)}{' '}
              geovisualizadores disponíveis
            </span>
          ) : variant === 'platform' ? (
            <span className={styles.description}>
              {item.quantidade} geovisualizadores disponíveis
            </span>
          ) : null}
        </div>
        <span
          className={clsx(
            styles.icon,
            'icon-siarl',
            open ? 'icon-colapsar' : 'icon-expandir'
          )}
        />
      </div>
      {open && (
        <div className={styles.subtemas}>
          {item.gvSubTemas.map((subtema) => (
            <div className={styles.subtema}>
              <div style={{cursor:'pointer'}} onClick={() => openSubtema(subtema)}>
                <span className={styles.subtemaDescricao}>
                  {subtema.descricao}
                </span>
              </div>
              <span
                className={clsx(
                  styles.iconExpand,
                  'icon-siarl',
                  open2 ? 'icon-colapsar' : 'icon-expandir'
                )}
              />
              {getOpenSubtema(subtema) ? 
              <>
              {variant === 'user' ? (
                <MapaItemGrid 
                  mapas={subtema.gvMapaPartilhados.filter(
                    (mapaPartilhado) =>
                      mapaPartilhado.id_utilizador === userLocal?.user?.id
                  )}
                />
              ) : variant === 'platform' ? (
                <MapaItemGrid  mapas={subtema.gvMapaPartilhados} />
              ) : null}</>: ''}
            
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
