import { process } from '@progress/kendo-data-query';
import {
  Dispatch,
  MouseEvent as ReactMouseEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { Button, DropDownButton } from '@progress/kendo-react-buttons';
import { PagerTargetEvent } from '@progress/kendo-react-data-tools';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Grid, GridCellProps, GridColumn, GridPageChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { format } from 'date-fns';
import React from 'react';
import { CSVLink } from 'react-csv';
import SessionStorageService from '../../services/SessionStorageService';
import { GetUserNameTecnico, b64toBlob, exportPDFWithMethod, get, handleDownload, handleGenericError, hasPermission, post, setAcaoSelected, setCoordenadasPoligono, setIntervencaoSelected, setModeloSelected } from '../../services/utils';
import styles from '../../styles/intervencao/Intervencao.module.scss'
import { AUTH_LOCALSTORAGE, CONSULTAR_ACAO, CURRENT_TIMEZONE, GET_DOCUMENTO, GET_DOCUMENTO_BY_ACAO, GET_DOCUMENTO_BY_INTERVENCAO, GET_INT_ACAO_SHAPEFILE, GET_LIST_RESULTS_INT_ACAO, LAYER_INDEX_INT_ACAO, OPERATIONAL_LAYER, PERMISSION_INSERIR_INTERVENCAO, PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO, PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA, PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO, PESQUISAR_ACAO, PESQUISAR_DOCUMENTOS_INTERVENCAO, PESQUISAR_INTERVENCAO,RECORDS_LIMIT,RELATORIO_MERGE_ACAO,URL_AJUDA_INTERVENCOES_ACAO, clearLayers } from '../../utils/constants';
import { ResultadoIntervencaoCommandGridCell } from './ResultadoIntervencaoCommandGridCell';
import { useIntervencaoStore } from '../../stores/intervencao';
import { useAcaoStore } from '../../stores/acao';
import { ResultadoAcaoCommandGridCell } from './ResultadoAcaoCommandGridCell';
import { useCommonStore } from '../../stores/common';
import commonStyles from '../../styles/common/Common.module.scss'
import clsx from 'clsx';
import MaximizeButton from '../common/MaximizeButton';
import { ResultadoDocumentoCommandGridCell } from '../documentos/ResultadoDocumentoCommandGridCell';
import { Dialog } from '@progress/kendo-react-dialogs';
import ImagePreviewV2 from '../viewer/ImagePreviewV2'
import VisualizadorColunas from '../Generico/VisualizadorColunas ';
import useVisibleColumns, { Key } from '../../hooks/useVIsibleColumns';
import ReactGA from 'react-ga4';
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais';
import useClearMap from '../../hooks/useClearMap';
import moment from 'moment';

type Props = {
  onCloseClick?: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
  setShowResultadoAcao: Dispatch<SetStateAction<boolean>>
  setShowRegistarAcao: Dispatch<SetStateAction<boolean>>
}

const options = [
  {
    key: 'ExportarFichasResultados',
    text: 'Exportar fichas resultados',
    icon: <span className={`icon-siarl icon-exportar ${styles.btnImprimir}`}></span>,
  }
]
type PageState = {
  skip: number
  take: number
}
const initialDataState: PageState = { skip: 0, take: 20 }

const keyPainelPrincipal:Key = 'acaoColunasVisiveis';
const keyPainelDocumentos:Key = 'acaoDocumentosColunasVisiveis';

export default function ResultadoAcaoForm({
  onCloseClick,
  setShowResultadoAcao,
  setShowRegistarAcao,
}: Props) {
  const [isMobile, setIsMobile] = useState(false);
  const [acoes, setacoes] = useState([])
  const [documentosacoes, setDocumentosacoes] = useState([])

  const [selected, setSelected] = useState<number>(0)
  const [page, setPage] = useState<PageState>(initialDataState)
  const [urlDoDocumento, setUrlDoDocumento] = useState("");
  const [idDoDocumento, setIdDoDocumento] = useState("");
  const [openForm, setOpenForm] = useState<boolean>(false)

  const isCurrentViewMaximized = useCommonStore(state => state.isCurrentViewMaximized)

  const selectAcaoResult = useAcaoStore(
    (state) => state.selectAcaoResult
  )
  const selectAcaoResultId = useAcaoStore(
    (state) => state.selectAcaoResultId
  )
  const [pageSizeValue, setPageSizeValue] = useState<
    number | string | undefined
  >()

  const getAcao = async (data: any) =>
    await get<any, any>(`${PESQUISAR_ACAO}/` + data)

      const getDocumentosByAcaoIDs = async (data: any) =>
  await post<any, any>(`${GET_DOCUMENTO_BY_ACAO}` , data)
  const getDocumento = async (data: any) =>
    await get<any, any>(`${GET_DOCUMENTO}/` + data)
  const getShapeFile = async (data: any) =>
  await post<any, any>(`${GET_INT_ACAO_SHAPEFILE}`, data)

  
  const getAcaoPorIds = async (data: any) =>
    await post<any, any>(`${GET_LIST_RESULTS_INT_ACAO}`, data)

  const relatorioAcaoMerge = async (data: any) =>
    await post<any, any>(`${RELATORIO_MERGE_ACAO}`, data)

    const documentosToPreview = documentosacoes
     const handleMessageEvent = useCallback((event: MessageEvent) => {
        if (event.data.type == "resultadoAcaoPesquisaChange") {
            SessionStorageService.setItem("resultadoAcaoPesquisa", event.data.value);
        }
    }, [])

     useEffect(() => {
        window.addEventListener(
            'message',
            handleMessageEvent,
            false
        )

        return () => {
            window.removeEventListener(
                'message',
                handleMessageEvent,
                false
            )
        }
    }, [handleMessageEvent])
  const {getVisibleColumns, removeColumnsKeys} = useVisibleColumns();

 
  const consultarAcao = async (data: any) => await post<any, any>(`${CONSULTAR_ACAO}`, data)
  
  const initialized = useRef(false)
    useEffect(() => {
        if (!initialized.current) {
          initialized.current = true;    
          if (SessionStorageService.getItem("lastSearchAcao") && !SessionStorageService.getItem("firstCallAcao")) {
      postMessage({ type: "showLoader" }, "*");
      const objToSearch = SessionStorageService.getItem("lastSearchAcao") ?? '';
      consultarAcao(objToSearch).then((data) => {
        SessionStorageService.setItem("resultadoAcaoPesquisa", JSON.stringify(data));
        setResultsAcao();
         postMessage({ type: "hideLoader" }, "*");

      })
    } 
    else {
      setResultsAcao();
  }
}
  }, [])

  const setResultsAcao = () => {
        const results =  JSON.parse(SessionStorageService.getItem('resultadoAcaoPesquisa') ?? ''
      )
        setacoes(results)
        const iframe: any = document.getElementById('iframeGeoPortal')
        const ids: any[] = []
        results.forEach((item: any) => {
          ids.push(item.id)
          })
          iframe.contentWindow?.postMessage(
            { type: 'SiarlIntAcaoSearch', value: ids },
            '*'
          )

          getVisibleColumns(keyPainelPrincipal, setColums)
          getVisibleColumns(keyPainelDocumentos, setColumsDocuments)
        postMessage({ type: 'hideLoader' }, '*');

    }

    useEffect(() => {
      const iframe: any = document.getElementById('iframeGeoPortal')
      const ids: number[] = []
      acoes.forEach((element: any) => {
        ids.push(element.id)
      })
      iframe.contentWindow?.postMessage(
        { type: 'SiarlIntAcaoSearch', value: ids },
        '*'
      )
    }, [])

    const openPdfNewTab = () => {
      return new Promise<void>((resolve, reject) => {
          
          postMessage({ type: "showLoader" }, "*");
  
          setTimeout(() => {
             const columnsToShow = colums.columns.filter(x => x.show);
              const dataToExport = acoes.map((item: any) => {
                  const filteredItem: any = {};
                  columnsToShow.forEach((column: any) => {
                      filteredItem[column.field] = item[column.field];
                  });
                  return filteredItem;
              });
              
              exportPDFWithMethod(columnsToShow, dataToExport, 8)
                  .then((pdfData: any) => {
                      postMessage({ type: "hideLoader" }, "*");
  
                          const base64Data = pdfData.split(";base64,")[1];
                          console.log(base64Data);
                          const blob = b64toBlob(base64Data, 'application/pdf');
                          const blobUrl = URL.createObjectURL(blob);
  
                        
                          window.open(blobUrl, '_blank');
                          resolve();
                    
                  })
                  .catch(error => {
                      console.error('Error exporting PDF:', error);
                      reject(error);
                  });
          }, 1000);
      });
  };
    
  const [clearMap] = useClearMap();

  const clearSearchResults = () => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlIntAcaoClearLayers", value: "true" }, '*')
    SessionStorageService.removeItem('resultadoAcaoPesquisa')
    removeColumnsKeys(keyPainelDocumentos)
    removeColumnsKeys(keyPainelPrincipal)
    setCoordenadasPoligono(undefined);
    clearMap(clearLayers["CLEAR_LAYERS_ACAO"])
    SessionStorageService.removeItem("lastSearchAcao");
    postMessage({ type: "form", value: "pesquisarAcao" }, "*");
    setacoes([]);
  }

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected)
  }
  const handlePageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent
    const take = event.page.take

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value)
    }

    setPage({
      ...event.page,
      take,
    })
  }

  useEffect(() => {

    try {

      const results =  JSON.parse(SessionStorageService.getItem('resultadoAcaoPesquisa') ?? ''
    )
      

      setacoes(results);
      try {
        const idsAcao = results.map((o: any) => o.id);

        const iframe: any = document.getElementById('iframeGeoPortal');
        iframe.contentWindow?.postMessage({ type: "SiarlIntAcaoSearch", value: idsAcao }, '*');

        getDocumentosByAcaoIDs(idsAcao).then(response => {
          setDocumentosacoes(response)
          console.log(response)
        })

      } catch (error) {
        console.error('Erro ao buscar os dados:', error)
      }

    } catch (e) {
      setacoes([]);
    }

  }, [])

  const handleLocationClickById = (acaoLocal: Record<string, any>) => async () => { 
    const id = acaoLocal.int_acao_id ?? acaoLocal.id;
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlIntAcaoCenter", value: [id] }, '*')
  }

  const handleInfoClickById = (acaoLocal: Record<string, any>) => async () => {

     const id = acaoLocal.int_acao_id ?? acaoLocal.id;
     const acao = await getAcao(id);
     const tecnico = await GetUserNameTecnico(acao.utilizador_id);

     if (!acao) {
      postMessage({type: "infoMsg", value: { message: "Registo desativado" }}, "*");
      return;
    }


    const iframe: any = document.getElementById('iframeGeoPortal')
    const objToShow = {
      ...acao,
      id: acao.id,
      n_processo_aquisitivo: acao.n_processo_aquisitivo ?? undefined,
      n_filedoc: acao.n_filedoc ?? undefined,
      designacao: acao.designacao ?? undefined,
      id_estado: acao.id_estado ?? undefined,
      nuts1: acao.nuts1 ?? undefined,
      nuts2: acao.nuts2 ?? undefined,
      nuts3: acao.nuts3 ?? undefined,
      distrito : acao.distrito ?? undefined,
      concelho: acao.mobilizacao ?? undefined,
      freguesia: acao.freguesia ?? undefined,
      arh: acao.arh ?? undefined,
      capitania : acao.capitania ?? undefined,
      massaagua: acao.massaagua ?? undefined,


      data_ini_p : acao.data_ini_p?new Date(acao?.data_ini_p):null,
      data_con_p : acao.data_con_p?new Date(acao?.data_con_p):null,
      data_ini_e : acao.data_ini_e?new Date(acao?.data_ini_e):null,
      data_con_e : acao.data_con_e?new Date(acao?.data_con_e):null,
      id_tipo_n1: acao?.tipo_n1 ?? undefined,
      id_tipo_n2: acao?.tipo_n2 ?? undefined,
      id_tipo_n3: acao?.tipo_n3 ?? undefined,
      id_tipo_n4: acao?.tipo_n4 ?? undefined,


      entidade : acao.diEntidade ?? undefined,
      tipo_engenharia : acao.tipo_engenharia ?? undefined,
      id_natureza_acao : acao.id_natureza_acao ?? undefined,
      id_int_acao_componente : acao.int_acao_componente ?? undefined,
      id_int_acao_planeamento: acao.int_acao_planeamento ?? undefined,
      comunitario : acao.comunitario ?? undefined,
      nacional : acao.nacional ?? undefined,
      numero_candidatura : acao.numero_candidatura ?? undefined,
      designacao_candidatura : acao.designacao_candidatura ?? undefined,
      inv_previs : acao.inv_previs ?? undefined,
      val_contrat : acao.val_contrat ?? undefined,
      inv_realizado : acao.inv_realizado ?? undefined,
      revisao_precos : acao.revisao_precos ?? undefined,
      local: acao.local ?? undefined,
      utilizador_id: tecnico,
      taxa_execucao : acao.taxa_execucao ?? undefined,
      valor_por_executar : acao.valor_por_executar ?? undefined,
      data_adjudicacao : acao.data_adjudicacao?new Date(acao?.data_adjudicacao):null,
      data_artigo : acao.data_artigo?new Date(acao?.data_artigo):null,
      data_tutela : acao.data_tutela?new Date(acao?.data_tutela):null,
      data_tc : acao.data_tc?new Date(acao?.data_tc):null,
      obs: acao.obs ?? undefined,
      data_registo: acao.data_registo?new Date(acao?.data_registo):null,


    }
    sessionStorage.setItem('novaAcao', 'false')
    selectAcaoResult(objToShow)
    setAcaoSelected(objToShow);
    setShowResultadoAcao(false)
    setShowRegistarAcao(true)
    const idToSend = [acao?.id];

    iframe.contentWindow?.postMessage({ type: "SiarlIntAcaoSearch", value: idToSend }, '*')


    iframe.contentWindow?.postMessage({ type: "SiarlIntAcaoCenter", value: idToSend }, '*')
  }
  
  const acaoCustomCell = (props: GridCellProps) => (
    <ResultadoAcaoCommandGridCell
      {...props}
      onInfoClick={handleInfoClickById}
      onLocationClick={handleLocationClickById}
      onDocumentPreviewClick={handleDocumentPreviewClickById}
      {...conditionalProps}

    />
  )

  const handleDocumentPreviewClickById = (documentosLocal: Record<string, any>) => async () => {

    try {

      postMessage({ type: "showLoader" }, "*");
      const documentos = await getDocumento(documentosLocal.id);

      setIdDoDocumento(documentosLocal.id);
      setUrlDoDocumento(documentos.url);
      setOpenForm(true);

    } catch (error) {
      handleGenericError(error, "Não foi possivel abrir o documento!")
      setOpenForm(false);

    } finally {

      postMessage({ type: "hideLoader" }, "*");

    }
  }

  const nextPhoto = async (e: any) => {

    try{

      postMessage({ type: "showLoader" }, "*");
      const nextIds = documentosToPreview
        .filter((doc: any) => doc.id < idDoDocumento && doc.url !== '')
        .map((doc: any) => doc.id);
  
      if (nextIds.length > 0) {
        const nextId = Math.max(...nextIds);
        console.log(`O próximo ID é ${nextId}`);
  
        const documento = await getDocumento(nextId);
  
        setIdDoDocumento(nextId.toString());
        setUrlDoDocumento(documento.url);

        const iframe: any = document.getElementById('iframeGeoPortal')
        
        iframe.contentWindow?.postMessage(
          {
            type: 'SiarlIntAcaoHighlight',
            value: [e.int_acao_id],
          },
          '*'
        )
  
      } else {
        console.log("Não há um próximo ID na lista.");
      }

    }catch(error){

      postMessage({ type: "errorMsg", value: "Não foi possivel abrir o documento!" }, "*");

    }finally{

      postMessage({ type: "hideLoader" }, "*");

      e.preventDefault();
    }
   
  }


  const previousPhoto = async (e: any) => {

    try{

      postMessage({ type: "showLoader" }, "*");
      const previousIds = documentosToPreview
        .filter((doc: any) => doc.id > idDoDocumento && doc.url !== '')
        .map((doc: any) => doc.id);
  
      if (previousIds.length > 0) {
        const previousId = Math.min(...previousIds);
        console.log(`O anterior ID é ${previousId}`);
  
        const documento = await getDocumento(previousId);
  
        setIdDoDocumento(previousId.toString());
        setUrlDoDocumento(documento.url);

        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage(
          {
            type: 'SiarlIntAcaoHighlight',
            value: [e.int_acao_id],
          },
          '*'
        )
      } else {
        console.log("Não há um anterior ID na lista.");
      }

    }catch(error){

      handleGenericError(error, "Não foi possivel abrir o documento!")

    }finally{

      postMessage({ type: "hideLoader" }, "*");

      e.preventDefault();
    }
       
  }

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/ResultadoIntAcao" });
  }, []);

  useEffect(() => {

    const elemento: any = document.querySelector(".k-window-content.k-dialog-content");

    if (elemento) {
      elemento.style.padding = "0px";
    }

  }, [openForm]);

  const close = () => {
    setOpenForm(false);
  }

  const [dataState, setDataState] = React.useState({
    skip: 0,
    take: 20,
    sort: [],
    group: [],
  });

  const [dataStateDocumentos, setDataStateDocumentos] = React.useState({
    skip: 0,
    take: 20,
    sort: [],
    group: [],
  });

  const dataStateChange = (event: any) => {
    setDataResult(process(acoes, event.dataState));
    setDataState(event.dataState);
  };

  const [dataResult, setDataResult] = React.useState(
    process(acoes, dataState)
  );

  const dataStateChangeDocumentos = (event: any) => {
    setDataResultDocumentos(process(documentosacoes, event.dataState));
    setDataStateDocumentos(event.dataState);
  };

  const [dataResultDocumentos, setDataResultDocumentos] = React.useState(
    process(documentosacoes, dataStateDocumentos)
  );

  const optionsImprimir = [
    {
      key: 'ExportarListaResultados',
      text: 'Resultados',
    },
  ]

  const optionsExportar = [
    {
      key: 'ExportarListaResultadosExcel',
      text: 'excel',
    },
    {
      key: 'ExportarListaResultadosShapeFile',
      text: 'Shapefile',
    },
    {
      key: 'ExportarTodasFichas',
      text: 'Fichas',
    },
  ]

  const itemRender = (props: { item: any; itemIndex: number }) => {
    const isTitleItem = props.item.key === 'titulo';
    if (isTitleItem) {
      // Item de título personalizado
      return (
        <div className={styles.DropDownButtonTitulo}>
          <span>{props.item.text}</span>
        </div>
      );
    } else {
      // Demais itens
      return (
        <div className={styles.DropDownButtonItem}>
          <span>{props.item.text}</span>
        </div>
      );
    }
  };

  const canSeeIntervencao = 
  hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO) ||
  hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO) 
  const completoLeitura =   hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA) 


  const onItemClickExportarFichas = async () => {
    try {
  
    
      const relatorios = [];
  
      const results =  JSON.parse( SessionStorageService.getItem('resultadoAcaoPesquisa') ?? ''
    )
  
      const idsAcao = results.map((o: any) => o.id);
  
      if(idsAcao.length  > RECORDS_LIMIT){
        postMessage({ type: 'errorMsg', value: { message: "Não é possivel exportar mais de 100 fíchas" } }, '*')
        return false
      }
    
  
      const acoes = await getAcaoPorIds(idsAcao);
      
        postMessage({ type: 'showLoader' }, '*');
      
        for (const acaoId of idsAcao) {
  
        const acao = acoes.find((acao: any) => acao.id === acaoId);
  
        const objToSendImgMapa : any = {
          CodPrintTemplate: "RELATORIO_INT_ACAO",
          extendInfo: {
            spatialReference: {
              wkid: 0
            },
            xmin: 0,
            ymin: 0,
            xmax: 0,
            ymax: 0
          },
          ListOperationalLayerInfo: [
            {
              IdOperationLayer: OPERATIONAL_LAYER,
              UseLayerExtent: false,
              DefinitionExpression: "",
              ListServiceLayer: [
                {
                    LayerId: LAYER_INDEX_INT_ACAO,
                    DefinitionExpression: "id=" + acao?.id,
                    UseLayerExtent: true
                }
              ]
            }
          ]
        }
     
        const relatorio = {
          id: acao?.id,
          dados: {
            'REGISTO' : '',
            'Nº de processo aquisitivo:': acao?.n_processo_aquisitivo ?? '',
            'Nº processo APA:': acao?.n_filedoc ?? '',
            'Designação:': acao?.designacao ?? '',
            'Estado:': acao?.acaoEstado?.descricao ?? '',
            'Técnico:': acao?.tecnico ?? '',

  
            'LOCALIZAÇÃO' : '',
            'Nuts1:': acao?.nuts1[0]?.nome ?? '',
            'Nuts2:': acao?.nuts2[0]?.nome ?? '',
            'Nuts3:': acao?.nuts3[0]?.nome ?? '',
            'Distritos:': acao?.distritos[0]?.nome ?? '',
            'Concelhos:': acao?.concelhos[0]?.nome ?? '',
            'Freguesias:': acao?.freguesias[0]?.nome ?? '',
  
            'DATAS' : '',
            'Data prevista início:': acao?.data_ini_p ? new Date(acao?.data_ini_p).toLocaleDateString() : '',  
            'Data prevista conclusão:': acao?.data_con_p ? new Date(acao?.data_con_p).toLocaleDateString() : '', 
            'Data de início de execução:': acao?.data_ini_e ? new Date(acao?.data_ini_e).toLocaleDateString() : '',
            'Data de conclusão de execução:': acao?.data_con_e ? new Date(acao?.data_con_e).toLocaleDateString() : '',
  
            'CARACTERIZAÇÃO' : '',
            'Tipologia nível 1:': acao?.tipo_n1?.descricao ?? '',
            'Tipologia nível 2:': acao?.tipo_n2?.descricao ?? '',
            'Tipologia nível 3:': acao?.tipo_n3?.descricao ?? '',
            'Tipologia nível 4:': acao?.tipo_n4?.descricao ?? '',
            'Natureza da ação:': acao?.acao_natureza?.descricao ?? '',
            'Componente da ação:': acao?.int_acao_componente?.descricao ?? '',
            'Tipo de planeamento:': acao?.int_acao_planeamento?.descricao ?? '',
  
            'FONTE FINANCEIRA' : '',
            'Nº de candidatura: ': acao?.numero_candidatura ?? '',
            'Designação de candidatura:': acao?.designacao_candidatura ?? '',
  
            'INVESTIMENTO' : '',
            'Investimento previsto:': acao?.inv_previs ?? '',
            'Valor do contrato:': acao?.val_contrat ?? '',
            'Investimento realizado:': acao?.inv_realizado ?? '',

          },     
          img_mapa: "",
          objJsonToCreateImg: objToSendImgMapa
        };
  
        
        relatorios.push(relatorio);
    }
  
      const mergedPdfBase64 = await relatorioAcaoMerge(relatorios);
      if (mergedPdfBase64) {
        const blob = b64toBlob(mergedPdfBase64, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
      }
    } catch (error) {
      console.log(error);
      postMessage({ type: 'errorMsg', value: { left: '50%', message: "Verifique os campos obrigatórios." } }, '*');
    } finally {
      postMessage({ type: 'hideLoader' }, '*');
    }
  };

  
  const conditionalProps =  canSeeIntervencao || completoLeitura
  ? { 
      onInfoClick: handleInfoClickById
  } 
  : {};

  const onItemClickExportar = async(event: any) => {


    if (event.item.key == 'ExportarTodasFichas') {

      onItemClickExportarFichas();

    } 
    else if (event.item.key == 'ExportarListaResultadosExcel') {

      exportExcel();

    } else if (event.item.key == 'ExportarListaResultadosPdf') {

      exportPDF();

    } else if (event.item.key == "ExportarListaResultadosShapeFile") {
      const results = JSON.parse(
        SessionStorageService.getItem('resultadoAcaoPesquisa')?? ''
      )
      
  
      if (results) {
        const iframe: any = document.getElementById('iframeGeoPortal')
        const ids: number[] = []
        results.forEach((element: any) => {
          ids.push(element.id)
        })

        const file: any = await getShapeFile(ids);
        const filePath = 'shapeFileIntAcao.zip';

        handleDownload(file, filePath);
    } 
  }
};

  const onItemClickImprimirAcao = (event: any) => {

    const link: HTMLAnchorElement | null = document.querySelector('#print-results-acao');

    if (link) {
      link.click();
    }

    // if (event.item.key == 'ExportarListaResultados') {

      

    // }

  };

  const onItemClickImprimirDocumentos = (event: any) => {

    if (event.item.key == 'ExportarListaResultados') {

      const link: HTMLAnchorElement | null = document.querySelector('#print-results-documentos');

      if (link) {
        link.click();
      }

    }

  };

  const onItemClick = (event: any) => {

    const link: HTMLAnchorElement | null = document.querySelector('#print-results');

    if (link) {
      link.click();
    }

    // if (event.item.key == 'ExportarFichasResultados') {

      

    // } else if (event.item.key == 'ExportarListaResultados') {

    //   console.log(event.item.text);

    // }

  };

  const options = [
    {
      key: 'ExportarListaResultadosExcel',
      text: 'Excel',
    },
  ]
  
  let _pdfExport: any;
  const exportExcel = () => {
    _export.save();
  };

  let _export: any;
  const exportPDF = () => {
    _pdfExport.save();
  };

  const onItemClickImprimir = async (event: any) => {
    openPdfNewTab();
  }

  const [columsDocuments, setColumsDocuments] = useState<{ columns: { field: string, title: string, width: number | string, show: boolean }[] }>({
    columns: [
      { field: "id", width: 70, title: "ID", show: true },
      { field: "filedoc_cod", width: 190, title: "ID Documento Filedoc", show: true },
      { field: "data_publicacao", width: 150, title: "Data publicação", show: true },
      { field: "tipoRecurso.designacao", width: 150, title: "Tipo de recurso", show: true },
      { field: "titulo", width: 90, title: "Título", show: true },
      { field: "autores", width: 90, title: "Autor", show: true },
      { field: "entidade.nome", width: 90, title: "Entidade", show: true },
      { field: "id_estado", width: 'auto', title: "Estado", show: true },
    ]
  })

  const [colums, setColums]=useState<{columns:{field:string, title:string, width:number|string, show:boolean}[]}>({
    columns:[
      { field: "id", title: "ID", show: true,width:70 },  
      { field: "acaoEstado.descricao", title: "Estado", show: true,width: 140  },  
      { field: "tipo_n1.descricao", title: "Tipologia nivel 1", show: true,width: 180  },  
      { field: "tipo_n2.descricao", title: "Tipologia nivel 2", show: true,width: 180  },  
      { field: "tipo_n3.descricao", title: "Tipologia nivel 3", show: true,width: 180  },  
      { field: "tipo_n4.descricao", title: "Tipologia nivel 4", show: true,width: 180 },  
      { field: "int_acao_componente.descricao", title: "Componentes da ação", show: true,width: 180 },  
      { field: "acao_natureza.descricao", title: "Natureza da ação", show: true,width: 180 },  
      { field: "designacao", title: "Designação", show: true,width: 140  },  
      { field: "proc_contratacao.descricao", title: "Procedimento de contratação", show: true,width: 140  },  
      { field: "proc_contratacao.descricao", title: "Procedimento de contratação", show: true,width: 140  },  
      { field: "data_ini_p", title: "Data início prevista", show: true,width: 140  },  
      { field: "data_con_p", title: "Data conclusão prevista", show: true,width: 140  },  
      { field: "data_ini_e", title: "Data início de execução", show: true,width: 140  },  
      { field: "data_con_e", title: "Data conclusão de execução", show: true,width: 140  },  
      { field: "inv_previs", title: "Investimento Previsto", show: true,width:180 },  
      { field: "val_contrat", title: "Valor contratual", show: true,width:180},  
      { field: "data_adjudicacao", title: "Data adjudicação", show: true,width:180 },  
      { field: "revisao_precos", title: "Revisão de preços", show: true,width:180 },  
      { field: "inv_realizado", title: "Investimento realizado", show: true,width:180 },  
    ]
  })
  useEffect(() => {
    SessionStorageService.setItem(keyPainelDocumentos, JSON.stringify(columsDocuments));
  }, [columsDocuments]);

  useEffect(() => {
    SessionStorageService.setItem(keyPainelPrincipal, JSON.stringify(colums));
  }, [colums]);

  useLayoutEffect(() => {

    const handleResize = () => {

      if (window.innerWidth <= telaMobile) {
        
        setIsMobile(true);
        ocultaFloatingMenu_e_Mapa();

      }else{

        setIsMobile(false);
        mostraFloatingMenu_e_Mapa();

      }

    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);  


  const onItemClickMais = (event: any) => {

    elementoClickMais(event);

    if(optionsExportar.some(option => option.key === event.item.key)){
      onItemClickExportar(event);
      return;
    }
    
    onItemClickImprimirDocumentos(event);

    onItemClickImprimir(event);
  };

  const optionsMais = [
    {
      key: 'titulo',
      text: 'Imprimir',
    },
    {
      key: 'imprimirFicha',
      text: 'Resultados',
      className: 'icon-siarl icon-imprimir btnIconRegistar'
    },
    {
      key: 'titulo',
      text: 'Exportar',
    },
    ...optionsExportar.map(option => ({
      ...option,
      className: 'icon-siarl icon-exportar btnIconRegistar',
    })),
  ]

  const handleNewRegistoClick = () => {
      setAcaoSelected(undefined);
      selectAcaoResult(undefined);
      setShowResultadoAcao(false)
      const loggedUser = JSON.parse(
        localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}'
      )
      setAcaoSelected({ utilizador_id: loggedUser?.user?.name })

      sessionStorage.setItem('novaAcao', 'true')
      if (setShowRegistarAcao) setShowRegistarAcao(true)
  }

  const openAjuda = () => {
    window.open(URL_AJUDA_INTERVENCOES_ACAO, "_blank")
  }

  return (
  <div className={clsx(styles.base, isCurrentViewMaximized && commonStyles.maximizedBaseForm)}>
      <div className={styles.header}>
        <div>
          <div className={styles.nomeDoModulo}>
            Intervenções
          </div>
          <div className={styles.tituloDaPagina}>
            Resultados de pesquisa
          </div>
        </div>

        <div>

          {hasPermission(PERMISSION_INSERIR_INTERVENCAO) && (  
            hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO) ||
            hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO)
            )? 
            <Button
              onClick={handleNewRegistoClick}
              className={styles.btnNovoRegisto}
              style={{ display: isMobile ? 'none' : '' }}
              id='novoRegisto'
              title='Nova ação'
            >
              <span className="icon-siarl icon-registar btnIconRegistar" /> &nbsp;{' '}
              Nova ação
            </Button>
          :  ''}

          <CSVLink
            title="Imprimir resultados"
            id="print-results-acao"
            separator={";"}
            data={
              acoes.map((acao: any) => ({
                ...acao,
              }))}
            filename={`siarl-acao-${format(new Date(), 'ddMMyyyHHmm')}`}
            hidden
          >
          </CSVLink>

          {!isMobile &&
          <VisualizadorColunas 
              columns={selected===0?colums:columsDocuments} 
              setColumns={selected===0?setColums:setColumsDocuments}
              styles={styles.btnEditar2} 
           />
          }

        <button className={styles.btnEditar2} onClick={onItemClickImprimir} style={{ display: isMobile ? 'none' : '' }} id='imprimirFicha' title='Imprimir'>
          <span className="icon-siarl icon-imprimir"></span>
        </button>

          {!isMobile &&
          <DropDownButton
          items={optionsExportar}
          className={styles.btnImprimir}
          style={{marginTop: '-4px'}}
          iconClass="icon-siarl icon-exportar"
          onItemClick={onItemClickExportar}
          fillMode={'flat'}
          title='Exportar'
          itemRender={itemRender}
        ></DropDownButton>
          }

          {isMobile &&
            <DropDownButton
              items={optionsMais}
              className={styles.btnMais}
              iconClass="icon-siarl icon-mais"
              onItemClick={onItemClickMais}
              fillMode={'flat'}
              itemRender={itemRenderMais}
              title="Mais Opções"
            />
          }

          {isMobile &&
            <DropDownButton
              items={optionsMais}
              className={styles.btnMais}
              iconClass="icon-siarl icon-mais"
              onItemClick={onItemClickMais}
              fillMode={'flat'}
              itemRender={itemRenderMais}
              title="Mais Opções"
            />
          }

          <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
              <span className="icon-siarl icon-ajuda"></span>
          </button>
          <button className={styles.btnFechar} onClick={onCloseClick} title="Minimizar">
            <span className="icon-siarl icon-minimizar"></span>
          </button>

          {!isMobile &&
          <MaximizeButton className={styles.btnMaximizar} />
          }
        </div>

      </div>

      <TabStrip selected={selected} onSelect={handleSelect} scrollable={isMobile}>
        <TabStripTab title="Ações">
      

          <ExcelExport
            fileName='Resultado_Acoes'
            data={acoes}
            ref={(exporter) => {
              _export = exporter;
            }}
          >
            <Grid className={styles.form}
              sortable={true}
              onDataStateChange={dataStateChange}
               data={dataResult.data.length > 0 ? dataResult : acoes?.map((acao:any)=>({
                ...acao, 
                data_ini_p: acao?.data_ini_p ? format(new Date(acao?.data_ini_p), 'dd/MM/yyyy') : '',
                data_con_p: acao?.data_con_p ? format(new Date(acao?.data_con_p), 'dd/MM/yyyy') : '',
                data_ini_e: acao?.data_ini_e ? format(new Date(acao?.data_ini_e), 'dd/MM/yyyy') : '', 
                data_con_e: acao?.data_con_e ? format(new Date(acao?.data_con_e), 'dd/MM/yyyy') : '', 
              })).slice(page.skip, page.take + page.skip)
            }
            total={acoes.length}
              {...dataState}
              pageable={{
                buttonCount: 5,
                info: true,
                type: 'numeric',
                pageSizes: true,
                previousNext: true,
              }}
              skip={page.skip}
              take={page.take}
              //groupable={true}
            >
              <GridColumn cell={acaoCustomCell} width={50} />

              {colums?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
            </Grid>
          </ExcelExport>

          <GridPDFExport  
            fileName='Resultado_Acao'
            ref={(element) => {
              _pdfExport = element;
            }}
            margin="1cm"
           paperSize={['340mm', '297mm']}         

          >
            <Grid className={styles.form}
              sortable={true}
              onDataStateChange={dataStateChange}
              data={acoes}
              total={acoes.length}
              {...dataState}
              pageable={{
                buttonCount: 5,
                info: true,
                type: 'numeric',
                pageSizes: true,
                previousNext: true,
              }}
              //groupable={true}
            >
              
              <GridColumn cell={acaoCustomCell} width={50} />
              {colums?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
            </Grid>
          </GridPDFExport>

        </TabStripTab>

        <TabStripTab title="Documentos">

          <ExcelExport
            fileName='Resultado_DocumentosAcoes'
            data={documentosacoes}
            ref={(exporter) => {
              _export = exporter;
            }}

          >
            <Grid className={styles.form}
              sortable={true}
              onDataStateChange={dataStateChangeDocumentos}
              data={dataResultDocumentos.data.length > 0 ? dataResultDocumentos : documentosacoes}
              {...dataState}
              pageable={{
                buttonCount: 5,
                info: true,
                type: 'numeric',
                pageSizes: true,
                previousNext: true,
              }}
              //groupable={true}
            >
              <GridColumn cell={acaoCustomCell} width={86} />
              {columsDocuments?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
            </Grid>

          </ExcelExport>

          <GridPDFExport
            fileName='Resultado_Documentos_Acao'
            ref={(element) => {
              _pdfExport = element;
            }}
            margin="1cm"
          >
            <Grid className={styles.form}
              sortable={true}
              onDataStateChange={dataStateChangeDocumentos}
              data={dataResultDocumentos.data.length > 0 ? dataResultDocumentos : documentosacoes}
              {...dataState}
              pageable={{
                buttonCount: 5,
                info: true,
                type: 'numeric',
                pageSizes: true,
                previousNext: true,
              }}
              //groupable={true}
            >

              <GridColumn field="id" width={70} title="ID" />
              <GridColumn
                field="filedoc_cod"
                width={190}
                title="ID Documento Filedoc"
              />
              <GridColumn
                field="data_publicacao"
                width={150}
                title="Data publicação"
              />
              <GridColumn
                field="tipoRecurso.designacao"
                width={150}
                title="Tipo de recurso"
              />
              <GridColumn field="titulo" width={90} title="Título" />
              <GridColumn field="autores" width={90} title="Autor" />
              <GridColumn field="entidade.nome" width={300} title="Entidade" />
              <GridColumn field="estado_id" width={"auto"} title="Estado" />
            </Grid>

          </GridPDFExport>

        </TabStripTab>
      </TabStrip>
      {openForm && (
        <Dialog
        className="ModalDocumentos"
          title={`Documento: ${idDoDocumento}`}
          onClose={close}
          style={{ zIndex: 999 }}
        >
          
            <ImagePreviewV2
              documentUrl={urlDoDocumento}
              next={(e: any) => nextPhoto(e)}
              previous={(e: any) => previousPhoto(e)}
            />

        </Dialog>
      )}
      <div className={clsx(styles.footer, styles.justify_one_child, isCurrentViewMaximized && commonStyles.maximizedFooter)}>
        <Button
          className={styles.btnPesquisar}
          onClick={clearSearchResults}
          title='Nova Pesquisa'
        >
          <span className="icon-siarl icon-pesquisar"></span>
          <span className={styles.rotulo}>
            {' '}&nbsp;{' '}
            Nova pesquisa
          </span>
        </Button>

      </div>

    </div>
  );
}