import { useState } from "react"
import { freguesias, concelhos, distritos, nuts1, nuts2, nuts3, massaagua, arhs, capitania, praias } from "../../services/utils"
import { ComboBox, ComboBoxFilterChangeEvent } from "@progress/kendo-react-dropdowns"
import { FilterDescriptor, filterBy } from "@progress/kendo-data-query"
import { Field } from "@progress/kendo-react-form"
import { Input } from "@progress/kendo-react-inputs"


type Props = {
    styles?:any
    isPesquisaAvancada?:boolean
    haveLocal?:boolean
    NomeLocal ? : string | undefined
    havePraia?:boolean
    isEditCreateMode?:boolean
    formRenderProps?: any
    isRegisto?: boolean
    praiaEnabled?: boolean
    viewMode?: boolean
}
export default function FieldsetCombosLocalizacao({styles,isPesquisaAvancada,haveLocal,NomeLocal,havePraia,isEditCreateMode,formRenderProps, isRegisto = false, viewMode}:Props){

  const nomeLocalFinal = NomeLocal !== undefined ? NomeLocal : 'Local';

  const praiaEnabled= viewMode && (isRegisto || !isRegisto);

    const [concelhosFiltred, setConcelhosFiltred] = useState<any>([])
    const [concelhosDisabled, setConcelhosDisabled] = useState<any>(false)
    const [freguesiasDisabled, setFreguesiasDisabled] = useState<any>(false)
    const [nuts1Disabled, setNuts1Disabled] = useState<any>(false)
    const [nuts2Filtered, setNuts2Filtered] = useState<any>([])
    const [nuts3Filtered, setNuts3Filtered] = useState<any>([])
    const [nuts2Disabled, setNuts2Disabled] = useState<any>(false)
    const [nuts3Disabled, setNuts3Disabled] = useState<any>(false)
    const [distritosDisabled, setDistritosDisabled] = useState<any>(false);
    const [freguesiasData, setFreguesiasData] = useState(freguesias ? freguesias.slice() : []);
    const [concelhoData, setConcelhoData] = useState(concelhos ? concelhos.slice() : []);
    const [distritoData, setDistritoData] = useState(distritos ? distritos.slice() : []);
    const [nuts1Data, setNuts1Data] = useState(nuts1 ? nuts1.slice() : []);
    const [nuts2Data, setNuts2Data] = useState(nuts2 ? nuts2.slice() : []);
    const [nuts3Data, setNuts3Data] = useState(nuts3 ? nuts3.slice() : []);

    const [arhData, setArhData] = useState(arhs ? arhs.slice() : []);
    const [capitaniaData, setCapitaniaData] = useState(capitania ? capitania.slice() : []);
    const [massaaguaData, setMassaaguaData] = useState(massaagua ? massaagua.slice() : []);
    const [praiasData, setPraiasData] = useState(praias ? praias.slice() : []);

    const [freguesiasFiltred, setFreguesiasFiltred] = useState<any>([])
    const [localizacaoVisible, setLocalizacaoVisible] = useState(isEditCreateMode?true:false)

    const filterConcelho = (e: any) => {
        if (e?.value?.codigo) {
          const concelhosLocal = concelhos.filter(
            (x: any) => x.distrito_cod == e.value.codigo
          ) 
          setNuts1Disabled(true);
          setNuts2Disabled(true);
          setNuts3Disabled(true);
          setConcelhosDisabled(false);
          setFreguesiasDisabled(true);
          formRenderProps?.onChange('nuts1', { value: null });
          formRenderProps?.onChange('nuts2', { value: null });  
          formRenderProps?.onChange('nuts3', { value: null });  
          setConcelhosFiltred(concelhosLocal)
          setConcelhoData(concelhosLocal);
        } else {
          formRenderProps?.onChange('concelho', { value: null });
          formRenderProps?.onChange('freguesia', { value: null });  
          setConcelhosFiltred(concelhos)
          setNuts1Disabled(false);
          setNuts2Disabled(false);
          setNuts3Disabled(false);
        }
      }
    
      const filterFreguesia = (e: any) => {
        if (e?.value?.codigo) {
          const freguesiaLocal = freguesias.filter(
            (x: any) => x.concelho_cod == e.value.codigo 
          )
          if (formRenderProps.valueGetter("nuts2") && formRenderProps.valueGetter("nuts3")){
          setNuts1Disabled(true)
          setNuts2Disabled(true)
          setNuts3Disabled(true)
          setDistritosDisabled(true)
          setFreguesiasDisabled(false)
          }
          else{
          formRenderProps?.onChange('nuts1', { value: null });
          formRenderProps?.onChange('nuts2', { value: null }); 
          setNuts1Disabled(true)
          setDistritosDisabled(true)
          setNuts2Disabled(true)
          setNuts3Disabled(true)
          setFreguesiasDisabled(false)
          }
         
          setFreguesiasFiltred(freguesiaLocal)
          setFreguesiasData(freguesiaLocal);

        } else {
          if (formRenderProps.valueGetter("nuts3")) {
          setDistritosDisabled(true)
          setNuts1Disabled(true)
          setNuts2Disabled(true)
          setNuts3Disabled(false)
          setFreguesiasDisabled(true)
          setFreguesiasFiltred(freguesias)
          setFreguesiasData(freguesias);
           }
           else{
           setDistritosDisabled(false)
           setNuts1Disabled(false)
            setNuts2Disabled(false)
            setNuts3Disabled(false)
            setFreguesiasDisabled(false)
            setFreguesiasFiltred(freguesias)
            setFreguesiasData(freguesias);
           }
           if (formRenderProps.valueGetter("distrito")) {
            setNuts2Disabled(true)
            setNuts3Disabled(true)
           }
           setConcelhoData(concelhoData)
           
         
        }
      }
    
      const filterDataPraias = (filter: FilterDescriptor) => {
        const data = praias.slice();
        return filterBy(data, filter);
      };
      const filterChangePraias = (event: ComboBoxFilterChangeEvent) => {
        setPraiasData(filterDataPraias(event.filter));
      };
    
      const filterDataFreguesia = (filter: FilterDescriptor) => {
        const data = freguesias.slice();
        return filterBy(data, filter);
      };  
      
      const filterChangeFreguesia = (event: ComboBoxFilterChangeEvent) => {
        setFreguesiasData(filterDataFreguesia(event.filter));
      };
    
      const filterDataConcelho = (filter: FilterDescriptor) => {
        const data = concelhos.slice();
        return filterBy(data, filter);
      };
    
      const filterChangeConcelho = (event: ComboBoxFilterChangeEvent) => {
        setConcelhoData(filterDataConcelho(event.filter));
      };
    
      const filterDataDistrito = (filter: FilterDescriptor) => {
        const data = distritos.slice();
        return filterBy(data, filter);
      };
    
      const filterChangeDistrito = (event: ComboBoxFilterChangeEvent) => {
        setDistritoData(filterDataDistrito(event.filter));
      };
    
      const filterDataNuts1 = (filter: FilterDescriptor) => {
        const data = nuts1.slice();
        return filterBy(data, filter);
      };
    
      const filterChangeNuts1 = (event: ComboBoxFilterChangeEvent) => {
        setNuts1Data(filterDataNuts1(event.filter));
      };
    
      const filterDataNuts2 = (filter: FilterDescriptor) => {
        const data = nuts2.slice();
        return filterBy(data, filter);
      };
    
      const filterChangeNuts2 = (event: ComboBoxFilterChangeEvent) => {
        setNuts2Data(filterDataNuts2(event.filter));
      };
    
      const filterDataNuts3 = (filter: FilterDescriptor) => {
        const data = nuts3.slice();
        return filterBy(data, filter);
      };
    
      const filterChangeNuts3 = (event: ComboBoxFilterChangeEvent) => {
        setNuts3Data(filterDataNuts3(event.filter));
      };

      const filterFreg = (e: any) => {
        if (e?.value?.codigo) {
         if (formRenderProps.valueGetter("nuts2") && formRenderProps.valueGetter("nuts3")){
          setNuts1Disabled(true)
          setNuts2Disabled(true)
          setNuts3Disabled(true)
          setConcelhosDisabled(true)
          setDistritosDisabled(true)
         }
         else{
          formRenderProps?.onChange('nuts1', { value: null });
          formRenderProps?.onChange('nuts2', { value: null });
         }
          setNuts1Disabled(true)
          setNuts2Disabled(true)
          setNuts3Disabled(true)
          setDistritosDisabled(true)
          setConcelhosDisabled(true)
        
        } else {
          if (formRenderProps.valueGetter("concelho")){
          setConcelhosDisabled(false)
          setNuts1Disabled(true)
          setNuts2Disabled(true)
          setNuts3Disabled(true)
          setDistritosDisabled(true)
          setFreguesiasFiltred(freguesias)  
          setFreguesiasData(freguesias);
          }
          else{
          formRenderProps?.onChange('freguesia', { value: null });
            setNuts1Disabled(false)
            setNuts2Disabled(false)
            setNuts3Disabled(false)
            setConcelhosDisabled(false)
            setDistritosDisabled(false)
            setFreguesiasFiltred(freguesias)  
            setFreguesiasData(freguesias);
          }
         
        }
      }

      const filterConcelhoFromNuts = (e: any) => {
        if (e?.value?.codigo) {
           const concelhosNuts = concelhos.filter(
            (x: any) => x.nuts_3_cod === e.value.codigo
          )
          setNuts1Disabled(true)
          setNuts2Disabled(true)
          setDistritosDisabled(true)
          setFreguesiasDisabled(true)
          setConcelhoData(concelhosNuts)
          setConcelhosFiltred(concelhosNuts)
        } else {
          setNuts3Data(nuts3Data)
          setConcelhoData(concelhos)
          setConcelhosFiltred(concelhos)
          setDistritosDisabled(false)
          setNuts1Disabled(true)
          setNuts2Disabled(false)
          setFreguesiasDisabled(false)
        }
      }
     
      const filterNuts2 = (e: any) => {
        if (e?.value?.codigo) {
          const nutsLocal = nuts2.filter((x: any) => x.nuts_i_cod == e.value.codigo)
          setNuts2Filtered(nutsLocal)
          setNuts2Data(nutsLocal);
          setNuts2Disabled(false)
          setDistritosDisabled(false)
        } else {
          formRenderProps?.onChange('nuts2', { value: null });
          formRenderProps?.onChange('nuts3', { value: null });
          formRenderProps?.onChange('concelho', { value: null });
          formRenderProps?.onChange('freguesia', { value: null });
          formRenderProps?.onChange('distrito', { value: null });
          setDistritosDisabled(false)
        }
      }

      const filterNuts3 = (e: any) => {
        if (e?.value?.codigo) {
          const nutsLocal = nuts3.filter(
            (x: any) => x.nuts_ii_cod == e.value.codigo
          )
          setDistritosDisabled(true);
          setConcelhosDisabled(false)
          setNuts1Disabled(true)
          setNuts3Disabled(false)
          setNuts3Filtered(nutsLocal)
          setNuts3Data(nutsLocal);
        } else {
          setDistritosDisabled(false)
          setNuts1Disabled(false)
          formRenderProps?.onChange('nuts3', { value: null });
          formRenderProps?.onChange('concelho', { value: null });
          formRenderProps?.onChange('freguesia', { value: null });
          setNuts2Data(nuts2Data)
          setNuts3Filtered(nuts3Data)
          setNuts3Data(nuts3Data);
        }
      }      


      type combosKeys = 'praias';
    
      
      const [combos, setCombos] = useState<any>({
        praias
      })
    
      const setDataOption: Record<combosKeys, { setFn: any, data: any[] }> = {
        praias: { setFn: setCombos, data: praias },
      };
    
      const filterCombo_Multiselect = (e: any, tipoCombo: combosKeys) => {
        const { setFn, data } = setDataOption[tipoCombo];
          setFn({
              ...combos,
              [tipoCombo]: filterBy(data?.slice()??[], e.filter)
          }
          );
      }

      const toggleLocalizacao = () => {
        setLocalizacaoVisible(!localizacaoVisible)
      }

      const filterDataArh = (filter: FilterDescriptor) => {
        const data = arhs.slice();
        return filterBy(data, filter);
      };

      const filterChangeArh = (event: ComboBoxFilterChangeEvent) => {
        setArhData(filterDataArh(event.filter));
      };

      const filterDatCapitania = (filter: FilterDescriptor) => {
        const data = capitania.slice();
        return filterBy(data, filter);
      };

      const filterChangCapitania = (event: ComboBoxFilterChangeEvent) => {
        setCapitaniaData(filterDatCapitania(event.filter));
      };

      const filterDatMassaagua = (filter: FilterDescriptor) => {
        const data = massaagua.slice();
        return filterBy(data, filter);
      };

      const filterChangMassaagua = (event: ComboBoxFilterChangeEvent) => {
        setMassaaguaData(filterDatMassaagua(event.filter));
      };

      return (<>
        <fieldset>
          {isEditCreateMode ?
            <legend>
              <span className={styles.fieldsetTitulo}>Localização</span>
            </legend> :
            <legend>
              <button type='button' className={styles.itemExpandBase} onClick={toggleLocalizacao}>
                <div className={styles.btn_Expandir_Colapsar}>
                  <span className={localizacaoVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                </div>
                <span className={styles.fieldsetTitulo}>Localização</span>
              </button>
            </legend>
          }

          {localizacaoVisible && (<>
            <div className={styles.espacoVerticalFieldSet}>
              <div className={styles.fields}>
                <div>
                  <Field
                    name="nuts1"
                    label="NUTS I"
                    component={ComboBox}
                    onChange={filterNuts2}
                    textField={'nome'}
                    filterable={true}
                    disabled={nuts1Disabled || isRegisto}
                    onFilterChange={filterChangeNuts1}
                    data={nuts1Data} />
                </div>
                <div>
                  <Field
                    name="nuts2"
                    label="NUTS II"
                    component={ComboBox}
                    disabled={nuts2Disabled || isRegisto}
                    textField={'nome'}
                    filterable={true}
                    onChange={filterNuts3}
                    onFilterChange={filterChangeNuts2}
                    data={nuts2Data} />
                </div>
                <div>
                  <Field
                    name="nuts3"
                    label="NUTS III"
                    component={ComboBox}
                    disabled={nuts3Disabled || isRegisto}
                    textField={'nome'}
                    filterable={true}
                    onChange={filterConcelhoFromNuts}
                    onFilterChange={filterChangeNuts3}
                    data={nuts3Data} />
                </div>
              </div>
              <div className={styles.fields}>
                <div>
                  <Field
                    name="distrito"
                    label="Distrito"
                    component={ComboBox}
                    onChange={filterConcelho}
                    disabled={distritosDisabled || isRegisto}
                    textField={'nome'}
                    filterable={true}
                    onFilterChange={filterChangeDistrito}
                    data={distritoData} />
                </div>
                <div>
                  <Field
                    name="concelho"
                    label="Concelho"
                    component={ComboBox}
                    onChange={filterFreguesia}
                    disabled={concelhosDisabled || isRegisto}
                    textField={'nome'}
                    filterable={true}
                    onFilterChange={filterChangeConcelho}
                    data={concelhoData} />
                </div>
                <div>
                  <Field
                    name="freguesia"
                    label="Freguesia"
                    component={ComboBox}
                    onChange={filterFreg}
                    disabled={freguesiasDisabled || isRegisto}
                    textField={'nome'}
                    filterable={true}
                    onFilterChange={filterChangeFreguesia}
                    data={freguesiasData} />
                </div>
              </div>
              {isPesquisaAvancada ?
                <div className={styles.fields}>
                  <div>
                    <Field
                      name="arh"
                      label="ARH"
                      component={ComboBox}
                      textField={'nome'}
                      filterable={true}
                      disabled={isRegisto}
                      onFilterChange={filterChangeArh}
                      data={arhData} />
                  </div>
                  <div>
                    <Field
                      name="capitania"
                      label="Capitania"
                      component={ComboBox}
                      textField={'nome'}
                      filterable={true}
                      disabled={isRegisto}
                      onFilterChange={filterChangCapitania}
                      data={capitaniaData} />
                  </div>
                  <div>
                    <Field
                      name="massaAgua"
                      label="Massa de água"
                      component={ComboBox}
                      textField={'nome'}
                      filterable={true}
                      disabled={isRegisto}
                      onFilterChange={filterChangMassaagua}
                      data={massaaguaData} />
                  </div>
                </div>
              : <></>}
            </div>

            <div className={styles.espacoVerticalFieldSet}>
                <div className={styles.fields}>
                  {haveLocal ?
                    <div>
                      <Field name="local" label={nomeLocalFinal} component={Input} disabled={isRegisto}/>
                    </div>
                  : ''}

                  {havePraia ?
                    <div>
                      <Field
                        name="praia"
                        label="Praia (se aplicável)"
                        component={ComboBox}
                        textField={'nome'}
                        filterable={true}
                        disabled={praiaEnabled}
                        onFilterChange={(e: any) => { filterCombo_Multiselect(e, 'praias') } }
                        data={combos?.praias}
                      />
                    </div>
                  : ''}
                </div>
              </div>
          </>)}
        </fieldset>
      </>);
}