import { DatePicker, DateTimePicker, MultiViewCalendar, MultiViewCalendarProps } from '@progress/kendo-react-dateinputs'
import { ComboBox, ComboBoxFilterChangeEvent, MultiSelect } from '@progress/kendo-react-dropdowns'
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form'
import {
  Input,
  RadioGroup,
  TextArea
} from '@progress/kendo-react-inputs'
import { MouseEvent as ReactMouseEvent, useEffect, useState } from 'react'

import {
  agEstados,
  arhs,
  capitania,
  concelhos,
  del,
  distritos,
  entidades,
  entidadeUser,
  estadoIntervencao,
  fillArhsAsync,
  fillCapitaniaAsync,
  fillCombosAuxiliares,
  fillConcelhosAsync,
  fillDistritosAsync,
  fillEntidadesAsync,
  fillFreguesiasAsync,
  fillInstrumentoFinanciamentoAsync,
  fillInstrumentoPlaneamentoAsync,
  fillIntComponenteAsync,
  fillIntIndicadorAsync,
  fillMassaAguaAsync,
  fillNuts1Async,
  fillNuts2Async,
  fillNuts3Async,
  fillPlaneamento1Async,
  fillPlaneamento2Async,
  fillPlaneamento3Async,
  fillPrioridadeAsync,
  fillPrioridadePalXXIAsync,
  fillTip1Async,
  fillTip2Async,
  fillTip3Async,
  freguesias,
  get,
  getIntervencaoSelected,
  hasPermission,
  instrumentoFinanciamento,
  instrumentoPlaneamento,
  intComponente,
  intIndicador,
  massaagua,
  nuts1,
  nuts2,
  nuts3,
  planeamento1,
  planeamento2,
  planeamento3,
  post,
  praias,
  prioridade,
  prioridadePalXXI,
  put,
  setIntervencaoSelected,
  tip1,
  tip2,
  tip3,
  useCombos
} from '../../services/utils'
import styles from '../../styles/intervencao/Intervencao.module.scss'

import { Button } from '@progress/kendo-react-buttons'
import { useIntervencaoStore } from '../../stores/intervencao'
import {
  DELETE_ASSOCIACAO_INTERVENCAO_PLANEAMENTO,
  GET_INT_INDICADORES,
  GET_INT_PLANEAMENTOS,
  IMPORT_GEOM_INT_INTERVENCAO,
  INT_PLANEAMENTO_ASSOCIACAO,
  PERMISSION_INTERVENCAO_PERFIL_BASICO,
  PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO,
  PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA,
  PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO,
  PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_LEITURA,
  PERMISSION_INTERVENCAO_PERFIL_TOTAL,
  PESQUISAR_ENTIDADEBYUSER,
  PESQUISAR_INTERVENCAO,
  PESQUISAR_PLANEAMENTO1,

  SAVE_NEW_INTERVENCAO,
  UPDATE_ASSOCIACAO_INTERVENCAO_PLANEAMENTO,
  UPDATE_INTERVENCAO
} from '../../utils/constants'
import ImportarGeometriaComponent from '../apoiogestao/registar/ImportarGeometriaForm'
import CheckMoreItem from '../documentos/registar/fields/CheckMoreItem'
import { Grid, GridCellProps, GridColumn, GridPageChangeEvent, GridToolbar } from '@progress/kendo-react-grid'
import { PagerTargetEvent } from '@progress/kendo-react-data-tools'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faFileEdit, faInfoCircle, faRemove } from '@fortawesome/free-solid-svg-icons'
import AssociacaoEntidadeIntervencao from './AssociacaoEntidade';
import { filterBy, FilterDescriptor } from '@progress/kendo-data-query'
import { Dialog } from '@progress/kendo-react-dialogs'
import { requiredFieldValidator } from '../../validations/ocorrencia'
import ValidatedTextArea from '../forms/ValidatedTextArea'
import React from 'react'
import SessionStorageService from '../../services/SessionStorageService'
import { v4 as uuidv4 } from 'uuid';


type Props = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
  formRenderProps: FormRenderProps
  datagrid?: any
  setDataGrid?: any
  viewMode?: boolean
}

type PageState = {
  skip: number
  take: number
}
interface FormData {
  id?: any;
  id_int_instrumento_planeamento?: string;
  id_tipo_n1?: string;
  id_tipo_n2?: string;
  id_tipo_n3?: string;
  numero_intervencao?: string;
  id_prioridade_instr_plan?: string;
}


 //let datagrid : any[] = []

export default function IdentificacaoIntervencao({ onCloseClick, formRenderProps, viewMode, datagrid, setDataGrid}: Props) {

  const [openImportModal, setOpenImportModal] = useState<any>()

  const selectIntervencaoResult = useIntervencaoStore(
    (state) => state.selectIntervencaoResult
  )
  const selectIntervencaoResultId = useIntervencaoStore(
    (state) => state.selectIntervencaoResultId
  )

  const selectedIntervencaoResult: any = useIntervencaoStore(
    (state) => state.selectedIntervencaoResult
  )

  const [selectedGridItemId, setSelectedGridItemId] = useState<string | null>(null);
  const [itemToRemove, setItemToRemove] = useState<any>();
  const [itemToRemoveIndicador, setItemToRemoveIndicador] = useState<any>();

  
  const [multiEntidades, setMultiEntidades] = useState([]);

  const [isInstrumentoPlaneamentoFilled, setIsInstrumentoPlaneamentoFilled] = useState(false);

  const getPlaneamentos = async (data: any) =>
    await post<any, any>(`${GET_INT_PLANEAMENTOS}`, data)

  const getIndicadores = async (data: any) =>
    await post<any, any>(`${GET_INT_INDICADORES}`, data)

  const [formData, setFormData] = useState<FormData>({});
  const [gridData, setGridData] = useState<FormData[]>([]);
  const [gridDataIndicadores, setGridDataIndicadores] = useState<FormData[]>([]);
  const [page2, setPage2] = useState({ skip: 0, take: 10 });

  const [filteredPlaneamento1, setFilteredPlaneamento1] = useState<any[]>([]);
  const [filteredPlaneamento2, setFilteredPlaneamento2] = useState<any[]>([]);
  const [filteredPlaneamento3, setFilteredPlaneamento3] = useState<any[]>([]);
  const [filteredPrioridade, setFilteredPrioridade] = useState<any[]>([]);

  const [selectedIndicador, setSelectedIndicador] = useState(null);
  const [valorIndicador, setValorIndicador] = useState(null);
  const [entidadesData, setEntidadesData] = useState(entidades ? entidades.slice() : []);
  
 
  const clearFilters = (formRenderProps : FormRenderProps) => {
    setFilteredPlaneamento1([]);
    setFilteredPlaneamento2([]);
    setFilteredPlaneamento3([]);
    setFilteredPrioridade([]);
  
    formRenderProps.onChange('id_tipo_n1', { value: "" })
    formRenderProps.onChange('id_tipo_n2', { value: "" })
    formRenderProps.onChange('id_tipo_n3', { value: "" })
    formRenderProps.onChange('id_prioridade_instr_plan', { value: "" }) 

  };

  useEffect(() => {
    if (getIntervencaoSelected()?.id && getIntervencaoSelected()?.praia) {
      formRenderProps.onChange('praia', { value: getIntervencaoSelected()?.praia }) 
    }
  }, [getIntervencaoSelected()?.praia]);

  const handleInstrumentoPlaneamentoChange = (event: any, formRenderProps: FormRenderProps) => {
    const selectedId = event.value?.id;
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });

    clearFilters(formRenderProps);

    if (selectedId) {
      setIsInstrumentoPlaneamentoFilled(true);
      applyFilters(selectedId);
  } else {
      setIsInstrumentoPlaneamentoFilled(false);
  }
    };

  const applyFilters = (idInstrPlaneamento: number) => {

    const filteredP1 = planeamento1.filter(item => item.id_instr_planeamento  == idInstrPlaneamento);
    const filteredP2 = planeamento2.filter(item => item.id_instr_planeamento  == idInstrPlaneamento);
    // const filteredP3 = planeamento3.filter(item => item.id_instr_planeamento  == idInstrPlaneamento);
    const filteredPrio = prioridade.filter(item => item.id_instr_planeamento  == idInstrPlaneamento);

    setFilteredPlaneamento1(filteredP1);
    setFilteredPlaneamento2(filteredP2);
    // setFilteredPlaneamento3(filteredP3);
    setFilteredPrioridade(filteredPrio);
  };
  
  useEffect(() => {
    SessionStorageService.setItem("dataInstrumentoPlaneamento", gridData);
    SessionStorageService.setItem("dataIndicadores", gridDataIndicadores);
  });

  const totalValorExecucao = parseFloat(SessionStorageService.getItem("total_valor_execucao"));
        
  const formattedTotalValorExecucao = isNaN(totalValorExecucao) ? "" : totalValorExecucao.toFixed(2);

useEffect(() => {
    formRenderProps.onChange('val_executado', { value: formattedTotalValorExecucao});

  }, [totalValorExecucao]);

  useEffect(() => {
    if(selectedIntervencaoResult?.id){
    const fetchData = async () => {
      const dataPlaneamentos = await getPlaneamentos(selectedIntervencaoResult?.id);
      const dataIndicadores = await getIndicadores(selectedIntervencaoResult?.id);
      setGridData(dataPlaneamentos);
      setGridDataIndicadores(dataIndicadores);
    };
    fetchData();
  }
    
  }, []);

  
  const filterDataEntidades = (filter: FilterDescriptor) => {
    const data = entidades.slice();
    return filterBy(data, filter);
  };
  
  const filterChangeEntidades = (event: ComboBoxFilterChangeEvent) => {
    setEntidadesData(filterDataEntidades(event.filter));
  };



interface EditCommandCellProps extends GridCellProps {
  enterEdit: (item: any) => void;
  deleteItem: (item: any) => void;
  pointOnMap: (item: any) => void;
}

const CustomCalendar = (props: MultiViewCalendarProps) => {
  return <MultiViewCalendar {...props} views={1} />
}

const EditCommandCell = (props: EditCommandCellProps) => {
  return (
    <td className="k-command-cell">
      <div style={
        {
          display: "flex",
          flexDirection: "row",
          margin: '0 0 0 8px'
        }
      }> <span ></span>
        <div
          role="button"
          onClick={() => props.enterEdit(props.dataItem)}
        >
          <FontAwesomeIcon icon={faFileEdit} />
        </div>
      </div>
    </td>

  );
};

   const MyEditCommandCell = (props: GridCellProps) => (
    <EditCommandCell {...props} enterEdit={enterEdit} pointOnMap={pointOnMap} deleteItem={deleteItem} />
  );

const initialDataState: PageState = { skip: 0, take: 10 }

const [fonteFinanciamentoData, setFonteFinanciamentoData] = useState(instrumentoFinanciamento ? instrumentoFinanciamento.slice() : []);

 const [page, setPage] = useState<PageState>(initialDataState)

 const [pageSizeValue, setPageSizeValue] = useState<
    number | string | undefined
  >()

    const addNew = (e: any) => {
    setOpenForm(true);
    setEditItem({ id: undefined });
    e.preventDefault();
    return;
  };

  const handlePageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent
    const take = event.page.take

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value)
    }

    setPage({
      ...event.page,
      take,
    })
  }

  const handleAddItem = (item : any) => {
    const newItem = { ...item, id: uuidv4() };
    setGridData([...gridData, newItem]);
};

  const handleUpdateItem = (id : any, updatedItem : any) => {
      setGridData(prevData => prevData.map(item => item.id === id ? { ...updatedItem, id } : item));
  };

  const removeContacto: any = () => {
    const item = itemToRemove;
    const dataInstrumentoLocal = gridData.filter(x => !(x.id == item.id)).slice();
    setGridData(dataInstrumentoLocal);
    setOpenModalDescartar(false);
  }

  const confirmRemoveContato = (item: any) => {
    setItemToRemove(item);
    setOpenModalDescartar(true);
  }

  const removeIndicador: any = () => {
    const item = itemToRemoveIndicador;
    const dataIndicadorLocal = gridDataIndicadores.filter(x => !(x.id == item.id)).slice();
    setGridDataIndicadores(dataIndicadorLocal);
    setOpenModalDescartarIndicador(false);
  }

  const confirmRemoveIndicador = (item: any) => {
    setItemToRemoveIndicador(item);
    setOpenModalDescartarIndicador(true);
  }



  const isUUID = (str: string) => {
    const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidPattern.test(str);
  };

  const handleSubmitModal = (e : any) => {

    if (selectedGridItemId) {
        handleUpdateItem(selectedGridItemId, formData);
    } else {
        const newId = uuidv4();
        handleAddItem({ ...formData, id: newId });
        setSelectedGridItemId(newId);
    }

    setOpenModalInstrumentosPlaneamento(false);
};


  const handleRowClick = (dataItem : any) => {

    const id = dataItem?.id; 
 
    setSelectedGridItemId(id); 

    setFormData({
      id_int_instrumento_planeamento: dataItem.id_int_instrumento_planeamento,
      id_tipo_n1: dataItem.id_tipo_n1,
      id_tipo_n2: dataItem.id_tipo_n2,
      id_tipo_n3: dataItem.id_tipo_n3,
      numero_intervencao: dataItem.numero_intervencao,
      id_prioridade_instr_plan: dataItem.id_prioridade_instr_plan,
    });
    setOpenModalInstrumentosPlaneamento(true);
  };

  const handlePageChange2 = (event : any) => {
    setPage2({ skip: event.page2.skip, take: event.page2.take });
  };

  const handleFormChange = (event : any) => {
    
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };


  const [openForm, setOpenForm] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<any>({ id: undefined });

  const enterEdit = (item: any) => {
    setOpenForm(true);
    setEditItem(item);
  };
  const pointOnMap = (item: any) => {
    console.log(item);
    return;
  };
  const deleteItem = (item: any) => {
    console.log(item);
    return;
  };

  const editField = "inEdit";

    const HandleOnChangeMultiEntidade = (e: any) => {

    if(e?.value){
    
      setMultiEntidades(e?.value)
    
    }
   
  }

  const getEntidadeByUser = async (data: any) =>
    await get<any, any>(`${PESQUISAR_ENTIDADEBYUSER}/` + data)
  
  useEffect(() => {
    
     if(!nuts1 ){
      fillNuts1Async();
      }
     if(!nuts2) {
      fillNuts2Async();
     }
     if(!nuts3){
      fillNuts3Async();
     }
     if(!distritos){
      fillDistritosAsync();
     }
     if(!concelhos){
      fillConcelhosAsync();
     }
     if(!freguesias){
      fillFreguesiasAsync();
     }
     if(!arhs){
      fillArhsAsync();
     }
     if(!capitania){
      fillCapitaniaAsync();
     }
     if(!massaagua){
      fillMassaAguaAsync();
     }
     if(!instrumentoFinanciamento){
      fillInstrumentoFinanciamentoAsync();
     }
     if(!tip1){
      fillTip1Async();
     }
     if(!tip2){
      fillTip2Async();
     }
     if(!tip2){
      fillTip3Async();
     }
     if(!prioridade){
      fillPrioridadeAsync();
     }
     if(!instrumentoPlaneamento){
      fillInstrumentoPlaneamentoAsync();
     }
     if(!planeamento1){
      fillPlaneamento1Async();
     }
      if(!planeamento2){
      fillPlaneamento2Async();
     }
      if(!planeamento3){
      fillPlaneamento3Async();
     }
     if(!prioridade){
      fillPrioridadeAsync();
     }
     if(!entidades){
      fillEntidadesAsync();
     }
     if(!intComponente){
      fillIntComponenteAsync();
     }
     if(!intIndicador){
      fillIntIndicadorAsync();
     }
     if(!prioridadePalXXI){
      fillPrioridadePalXXIAsync();
     }


     if(getIntervencaoSelected()?.utilizador) {
      getEntidadeByUser(getIntervencaoSelected()?.utilizador?.id).then(resultEntidade => {
        formRenderProps.onChange('entidade_registo', { value: resultEntidade?.nome });
      });
    }else{
      formRenderProps.onChange('entidade_registo', { value: entidadeUser });

    }

  }, []);

  const getIntervencaoById = async (id: any) =>
    await get<any, any>(`${PESQUISAR_INTERVENCAO}/` + id)
  const importGeomIntIntervencao = async (data: any) =>
    await post<any, any>(`${IMPORT_GEOM_INT_INTERVENCAO}`, data)

  const [concelhosFiltred, setConcelhosFiltred] = useState<any>(undefined)
  const [concelhosDisabled, setConcelhosDisabled] = useState<any>(true)
  const [freguesiasFiltred, setFreguesiasFiltred] = useState<any>(undefined)
  const [freguesiasDisabled, setFreguesiasDisabled] = useState<any>(true)
  const [nuts2Filtered, setNuts2Filtered] = useState<any>(undefined)
  const [nuts2Disabled, setNuts2Disabled] = useState<any>(true)
  const [nuts3Filtered, setNuts3Filtered] = useState<any>(undefined)
  const [nuts3Disabled, setNuts3Disabled] = useState<any>(true)


const [openModalDescartar, setOpenModalDescartar] = useState<any>()
const [openModalDescartarIndicador, setOpenModalDescartarIndicador] = useState<any>()
const [openModalInstrumentosPlaneamento, setOpenModalInstrumentosPlaneamento] = useState<any>()

  const availableData = gridData.slice();
  const initialData = availableData.splice(0, 20);
  
  const [dataScroll, setDataScroll] = React.useState(initialData);

 
  const filterConcelho = (e: any) => {
    if (e?.value?.codigo) {
      const concelhosLocal = concelhos.filter(
        (x: any) => x.distrito_cod == e.value.codigo
      )
      setConcelhosFiltred(concelhosLocal)
      setConcelhosDisabled(false)
    } else {
      setConcelhosFiltred(concelhos)
      setConcelhosDisabled(true)
    }
  }

  const filterFreguesia = (e: any) => {
    if (e?.value?.codigo) {
      const freguesiaLocal = freguesias.filter(
        (x: any) => x.concelho_cod == e.value.codigo
      )
      setFreguesiasFiltred(freguesiaLocal)
      setFreguesiasDisabled(false)
    } else {
      setFreguesiasDisabled(true)
      setFreguesiasFiltred(freguesias)
    }
  }
  const filterNuts2 = (e: any) => {
    if (e?.value?.codigo) {
      const nutsLocal = nuts2.filter((x: any) => x.nuts_i_cod == e.value.codigo)
      setNuts2Filtered(nutsLocal)
      setNuts2Disabled(false)
    } else {
      setNuts2Disabled(true)
      setNuts2Filtered(nuts2)
    }
  }
  const filterNuts3 = (e: any) => {
    if (e?.value?.codigo) {
      const nutsLocal = nuts3.filter(
        (x: any) => x.nuts_ii_cod == e.value.codigo
      )
      setNuts3Filtered(nutsLocal)
      setNuts3Disabled(false)
    } else {
      setNuts3Disabled(true)
      setNuts3Filtered(nuts3)
    }
  }

  interface CellProps {
    dataItem: {
      data_publicacao: string // Assuming data_publicacao is a string
    }
  }

  type combosKeys = 'estadoIntervencao'|'instrumentoFinanciamento'
  |'tip1'| 'tip2' | 'tip3'|'instrumentoPlaneamento'
  |'prioridade' | 'planeamento1' |'planeamento2' | 'planeamento3'
  |'entidades' | 'praias' | 'prioridadePalXXI';

  
  const [combos, setCombos] = useState<any>({
    estadoIntervencao,
    instrumentoPlaneamento,
    instrumentoFinanciamento,
    tip1,
    tip2,
    tip3,
    prioridade,
    planeamento1,
    planeamento2,
    planeamento3,
    entidades,
    praias,
    prioridadePalXXI
  })

  const setDataOption: Record<combosKeys, { setFn: any, data: any[] }> = {
    estadoIntervencao: { setFn: setCombos, data: estadoIntervencao },
    instrumentoFinanciamento: { setFn: setCombos, data: instrumentoFinanciamento },
    tip1: { setFn: setCombos, data: tip1 },
    tip2: { setFn: setCombos, data: tip2 },
    tip3: { setFn: setCombos, data: tip3 },
    prioridade: { setFn: setCombos, data: prioridade },
    planeamento1: { setFn: setCombos, data: planeamento1 },
    planeamento2: { setFn: setCombos, data: planeamento2 },
    planeamento3: { setFn: setCombos, data: planeamento3 },
    entidades: { setFn: setCombos, data: entidades },
    instrumentoPlaneamento: { setFn: setCombos, data: instrumentoPlaneamento },
    praias: { setFn: setCombos, data: praias },
    prioridadePalXXI: { setFn: setCombos, data: prioridadePalXXI },
  };

  const filterCombo_Multiselect = (e: any, tipoCombo: combosKeys) => {
    const { setFn, data } = setDataOption[tipoCombo];
      setFn({
          ...combos,
          [tipoCombo]: filterBy(data?.slice()??[], e.filter)
      }
      );
  }
  const filterChange = (event: any) => {
    setFonteFinanciamentoData(filterBy(instrumentoFinanciamento.slice(), event.filter));
  };

  const [multiData, setMultiData] = useState([])

  async function HandleOnChangeMultiData(e: any) {
    if (e?.value) {
      setMultiData(e?.value) 
    }
  }

  
const changeFileDoc = (e: any) => {
  setIntervencaoSelected({...getIntervencaoSelected(), filedoc_cod: e?.value});
}
const canSeeIntervencao = hasPermission(PERMISSION_INTERVENCAO_PERFIL_BASICO) ||
hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO) ||
hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA) ||
hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO) ||
hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_LEITURA) ||
hasPermission(PERMISSION_INTERVENCAO_PERFIL_TOTAL);

const openModal = (e : any) => {
  setFormData({
    id_int_instrumento_planeamento: "",
    id_tipo_n1: "",
    id_tipo_n2: "",
    id_tipo_n3: "",
    numero_intervencao: "",
    id_prioridade_instr_plan: "",
    });
    setSelectedGridItemId(null)
    setIsInstrumentoPlaneamentoFilled(false)
  e.preventDefault();
  setOpenModalInstrumentosPlaneamento(true)
}

const cancelModal = () => {
  setOpenModalInstrumentosPlaneamento(false)
}

const handleInsertIndicador = () => {
  if (selectedIndicador) {
    const newItem = {
      id: uuidv4(),
      id_indicador: selectedIndicador,
      valor: valorIndicador
    };
    setGridDataIndicadores([...gridDataIndicadores, newItem]);
  }
  formRenderProps.onChange('id_indicador', { value: "" }) 
  formRenderProps.onChange('valor', { value: "" }) 

  setSelectedIndicador(null);
  setValorIndicador(null)

};

 

  return (
    <>
       {canSeeIntervencao?
      <div className={`${styles.form} ${styles.removeScrollDuplicado}`}>
        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Registo</span>
          </legend>
          <div className={styles.espacoVerticalFieldSet}>
          <div className={`${styles.fields}`}>
            <div>
              <Field name="id" label="ID" component={Input} disabled />
            </div>
            <div>
              <Field name="filedoc_cod" onChange={changeFileDoc} label="Nº Processo APA" component={Input} disabled={viewMode} />
            </div>
            <div>
                <Field
                  name="estadoIntervencao"
                  label="Estado"
                  component={ComboBox}
                  textField={'descricao'}
                  filterable={true}
                  onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'estadoIntervencao')}}
                  data={combos?.estadoIntervencao}
                  disabled={viewMode}       />
              </div>
            </div>
          </div>
          <div className={styles.espacoVerticalFieldSet}>
            <div className={`${styles.fields}`}>
            <div>
                <Field
                  name="data_registo"
                  label="Data/hora registo"
                  format="dd/MM/yyyy HH:mm"
                  component={DateTimePicker}
                  disabled={true}
                />
              </div>
              <div>
              <Field
                name="utilizador_id"
                label="Técnico Registo"
                disabled
                component={Input}
              />
            </div>
            <div>
              <Field
                name="entidade_registo"
                label="Entidade"
                disabled
                component={Input}
              />
            </div>
            
            </div>
          </div>
         
        </fieldset>

        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Identificação</span>
          </legend>
          <div className={`${styles.fields}`}>
            <div>
                <Field name="designacao" label="Designação" component={Input} disabled={viewMode}/>
            </div>
            </div>
            <div className={`${styles.fields}`}>
            <div>
                <Field name="numero_intervencao" label="Código da Intervenção" component={Input} disabled={viewMode}/>
            </div>
            <div>
                <Field
                  name="id_prioridade"
                  label="Prioridade"
                  component={ComboBox}
                  textField={'descricao'}
                  filterable={true}
                  onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'prioridadePalXXI')}}
                  data={combos?.prioridadePalXXI}
                  disabled={viewMode}       />
              </div>
                <div>
              
              </div>
            </div>


            <div className={`${styles.fields} `}>

            <div>
                <Field
                  name="inv_global"
                  label="Investimento global"
                  disabled={viewMode}
                  component={Input}
                />
              </div>
              <div>
                <Field
                  name="fin_nacional"
                  label="Financiamento nacional"
                  disabled={viewMode}
                  component={Input}
                />
              </div>
              <div>
                <Field
                  name="fin_comunitario"
                  label="Financiamento comunitário"
                  disabled={viewMode}
                  component={Input}
                />
              </div>
           </div>
            <div className={`${styles.fields} `}>
            <div>
              <Field
                name="inicio"
                label="Início"
                format="dd/MM/yyyy"
                disabled={viewMode}
                component={DatePicker}
                calendar={CustomCalendar}
                />
            </div>
            <div>
              <Field
                name="fim"
                label="Conclusão"
                component={DatePicker}
                calendar={CustomCalendar}
                format="dd/MM/yyyy"
                disabled={viewMode}
                />
            </div>
            <div>
                <Field
                  name="val_executado"
                  label="Valor executado"
                  disabled
                  component={Input}
                />
          </div>
          </div>
          {hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO) ||
    hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA) ||
    hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO) ||
    hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_LEITURA) ||
    hasPermission(PERMISSION_INTERVENCAO_PERFIL_TOTAL)?
            <div className={`${styles.fields} `}>
                <div>
                  <Field
                    name="lstFinanciamento"
                    label="Fonte de financiamento previsto"
                    component={MultiSelect}
                    data={fonteFinanciamentoData}
                    textField={'designacao'}
                    onChange={HandleOnChangeMultiData}
                    filterable={true}
                    onFilterChange={filterChange}
                    disabled={viewMode} 
                  />
              </div>  
            </div>:<></>}

            <div className={`${styles.fields} `}>


              <div>
              <AssociacaoEntidadeIntervencao viewMode={viewMode} dataCombo={entidades} label={"Entidades"} title1={"Entidade"} title2={"Intervenção"} textField={'nome'} dataGrid={datagrid} setDataGrid={setDataGrid} initialValues={getIntervencaoSelected()}/>  <></>
              </div>       
            </div>
           <div className={styles.espacoVerticalFieldSet}>

            <div className={`${styles.fields} `}>
              <div>
                    <Field
                      name="objetivo"
                      label="Objetivo"
                      disabled={viewMode}
                      component={Input}
                    />
              </div>
            </div>       
          </div>
         
        </fieldset>
        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Localização</span>
          </legend>
          <div className={styles.fields}>
            <div>
              {getIntervencaoSelected() ?
                <CheckMoreItem
                  list={getIntervencaoSelected()?.nuts1}
                  property={'nome'}
                  label="NUTS I"
                /> :
                <Field
                  name="nuts1"
                  label="NUTS I"
                  component={ComboBox}
                  textField={'nome'}
                  data={nuts1}
                  disabled
                  onChange={filterNuts2}

                />
              }
            </div>
            <div>
              {getIntervencaoSelected() ?
                <CheckMoreItem
                  list={getIntervencaoSelected()?.nuts2}
                  property={'nome'}
                  label="NUTS II"
                /> :
                <Field
                  name="nuts2"
                  label="NUTS II"
                  component={ComboBox}
                  textField={'nome'}
                  data={nuts2Filtered}
                  onChange={filterNuts3}
                  disabled
                />}
            </div>
            <div>
              {getIntervencaoSelected() ?
                <CheckMoreItem
                  list={getIntervencaoSelected()?.nuts3}
                  property={'nome'}
                  label="NUTS III"
                /> :
                <Field
                  name="nuts3"
                  label="NUTS III"
                  component={ComboBox}
                  textField={'nome'}
                  data={nuts3Filtered}
                  disabled
                />}
            </div>
          </div>
          <div className={styles.fields}>
            <div>
              {getIntervencaoSelected() ?
                <CheckMoreItem
                  list={getIntervencaoSelected()?.distritos}
                  property={'nome'}
                  label="Distrito"
                /> :
                <Field
                  name="distrito"
                  label="Distrito"
                  component={ComboBox}
                  onChange={filterConcelho}
                  textField={'nome'}
                  data={distritos}
                  disabled
                />}
            </div>
            <div>
              {getIntervencaoSelected() ?
                <CheckMoreItem
                  list={getIntervencaoSelected()?.concelhos}
                  property={'nome'}
                  label="Concelho"
                /> :
                <Field
                  name="concelho"
                  label="Concelho"
                  component={ComboBox}
                  onChange={filterFreguesia}
                  disabled
                  textField={'nome'}
                  data={concelhosFiltred}
                />}
            </div>
            <div>
              {getIntervencaoSelected() ?
                <CheckMoreItem
                  list={getIntervencaoSelected()?.freguesias}
                  property={'nome'}
                  label="Freguesia"
                /> :
                <Field
                  name="freguesia"
                  label="Freguesia"
                  component={ComboBox}
                  disabled
                  textField={'nome'}
                  data={freguesiasFiltred}
                />}
            </div>
          </div>

         <div className={styles.fields}>
          <div>
            {getIntervencaoSelected() ?
              <CheckMoreItem
                list={getIntervencaoSelected()?.arhs}
                property={'nome'}
                label="ARH"
              /> :
              <Field
                name="arh"
                label="ARH"
                component={ComboBox}
                textField={'nome'}
                data={arhs}
                disabled
                />}
            </div>
            <div>
            {getIntervencaoSelected() ?
              <CheckMoreItem
                list={getIntervencaoSelected()?.capitanias}
                property={'nome'}
                label="Capitania"
              /> :
              <Field
                name="capitania"
                label="Capitania"
                component={ComboBox}
                textField={'nome'}
                data={capitania}
                disabled
                />}
              </div>
              <div>
            {getIntervencaoSelected() ?
              <CheckMoreItem
                list={getIntervencaoSelected()?.massasAgua}
                property={'nome'}
                label="Massa de água"
              /> :
              <Field
                name="massaagua"
                label="Massa de água"
                component={ComboBox}
                textField={'nome'}
                data={massaagua}
                disabled
                />}
              </div>
          </div>
          <div className={styles.fields}>
            <div>
              <Field
                name="praia"
                label="Praia (se aplicável)"
                component={ComboBox}
                textField={'nome'}
                filterable={true}
                onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'praias')}}
                data={combos?.praias}
                disabled={viewMode}
                />
            </div>
            <div>
              <Field name="localizacao" label="Local" component={Input} disabled={viewMode}

/>
            </div>
          </div>
          &nbsp;
        </fieldset>

        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Tipologia</span>
          </legend>
          <div className={styles.espacoVerticalFieldSet}>
            <div className={styles.fields}>
              <div>
                <Field
                  name="id_int_pal_xxi_tip_nivel_1"
                  label="Tipologia Nivel 1 (PAL XXI)"
                  component={ComboBox}
                  textField={'descricao'}
                  filterable={true}
                  onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'tip1')}}
                  data={combos?.tip1}
                  disabled={viewMode}                />
              </div>
           </div>
           <div className={styles.fields}>
              <div>
                <Field
                  name="id_int_pal_xxi_tip_nivel_2"
                  label="Tipologia Nivel 2 (PAL XXI)"
                  component={ComboBox}
                  textField={'descricao'}
                  filterable={true}
                  onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'tip2')}}
                  data={combos?.tip2}
                  disabled={viewMode}
                />
              </div>
           </div>
           <div className={styles.fields}>
              <div>
                <Field
                  name="id_int_pal_xxi_tip_nivel_3"
                  label="Tipologia Nivel 3 (PAL XXI)"
                  component={ComboBox}
                  textField={'descricao'}
                  filterable={true}
                  onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'tip3')}}
                  data={combos?.tip3}
                  disabled={viewMode}
                />
            </div>
           </div>
           <div className={styles.fields}>
            <div>
              <Field
                name="id_tipo_in"
                label="Tipo de intervenção"
                component={MultiSelect}
                data={intComponente}
                textField={'descricao'}
                filterable={true}
                onFilterChange={filterChange}
                disabled={viewMode} 
              />
            </div>  
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>
              Instrumentos de Planeamento
            </span>
          </legend>
          {!viewMode  ? (

          <div className={styles.espacoVerticalFieldSet}>
          <button
              title="Adicionar novo"
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary buttonAdicionarNovo btnPrimaryColor"
              onClick={(e) => {
                openModal(e);
              }}
              // disabled={viewMode || !selectedIntervencaoResult?.id}
              >
              <span className="icon-siarl icon-registar k-button-icon"></span>{' '}
              &nbsp;{' '}
              Inserir
            </button>
         </div>
        ) : ""}

         
        <div className={styles.espacoVerticalFieldSet}>
          <div className={styles.scrollableArea}>
          <Grid
            className={'customGrid'}
            data={gridData.slice(page2.skip, page2.take + page2.skip)}
            pageable={{
              buttonCount: 5,
              info: false,  
              type: 'numeric',
              pageSizes: false,  
              previousNext: true,
            }}
            rowHeight={50}
            total={gridData.length}
            skip={page2.skip}
            take={page2.take}
            onPageChange={handlePageChange2}

          >
            {!viewMode ?
           <GridColumn width={56}
                cell={(props) => (
                  <td className="k-command-cell">
                    <div style={{cursor:'pointer'}} className={'gridCommand'}>
                      <div
                        role="button"
                        className={styles.gridCommandIconButton}
                        onClick={() => handleRowClick(props.dataItem)}
                      >
                        <FontAwesomeIcon icon={faInfoCircle} title="Editar associação" />
                      </div>
                      <div style={{cursor:'pointer'}}
                        role="button"
                        onClick={() => confirmRemoveContato(props.dataItem)}
                        className={styles.gridCommandIconButton}
                      >
                        <FontAwesomeIcon icon={faRemove} title="Remover associação" />
                      </div>
                    </div>
                  </td>
                )}
             />
              : ''}
            <GridColumn field="id_int_instrumento_planeamento.designacao" className={styles.centerAlign} width="170px" title="Instrumento planeamento" />
            <GridColumn field="id_tipo_n1.descricao" className={styles.centerAlign}  width="80px" title="Tipo n1" />
            <GridColumn field="id_tipo_n2.descricao" className={styles.centerAlign} width="80px" title="Tipo n2" />
            <GridColumn field="id_tipo_n3.descricao" className={styles.centerAlign} width="80px" title="Tipo n3" />
            <GridColumn field="numero_intervencao" className={styles.centerAlign} width="80px" title="Código" />
            <GridColumn field="id_prioridade_instr_plan.descricao" className={styles.centerAlign} width="100px" title="Prioridade" />
          </Grid>
          </div>
        </div>
        </fieldset>

        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>
              Indicadores de concretização
            </span>
          </legend>
          {!viewMode  ? (

          <div className={styles.espacoVerticalFieldSet}>
             <div className={styles.fields}>     
              <div>
              <Field
                name="id_indicador"
                label="Indicadores"
                component={ComboBox}
                data={intIndicador}
                onChange={(e) => setSelectedIndicador(e.target.value)}
                textField={'descricao'}
                filterable={true}
                value={selectedIndicador}
                onFilterChange={filterChange}
                disabled={viewMode} 
              />
              </div>
              <div>
                <Field
                  name="valor"
                  label="Valor"
                  component={Input}
                  onChange={(e) => setValorIndicador(e.target.value)}
                  disabled={viewMode}
                  value={valorIndicador}
                  type="number"
                />
            </div>  
          <button
              title="Adicionar novo"
              className={styles.btnInserirIndicador}
              onClick={handleInsertIndicador}
              type="button"
              // disabled={viewMode || !selectedIntervencaoResult?.id}
              >
              <span className="icon-siarl icon-registar k-button-icon"></span>{' '}
              &nbsp;{' '}
            </button>
         </div>
         </div>

        ) : ""}

         
        <div className={styles.espacoVerticalFieldSet}>
          <div className={styles.scrollableArea}>
          <Grid
            className={'customGrid'}
            data={gridDataIndicadores.slice(page2.skip, page2.take + page2.skip)}
            pageable={{
              buttonCount: 5,
              info: false,  
              type: 'numeric',
              pageSizes: false,  
              previousNext: true,
            }}
            rowHeight={50}
            total={gridDataIndicadores.length}
            skip={page2.skip}
            take={page2.take}
            onPageChange={handlePageChange2}

          >
            {!viewMode? (
            <GridColumn
              width="50px"
              cell={(props) => (
                <td>
                  <button
                    onClick={() => confirmRemoveIndicador(props.dataItem)}
                    className={styles.gridCommandIconButton}>
                  <FontAwesomeIcon icon={faRemove} />
                  </button>
                
                </td>
              )}
            />
          ) : ""}
                   
            <GridColumn field="id_indicador.descricao" className={styles.centerAlign}  width="170px" title="Indicador de concretização" />
            <GridColumn field="valor" width="170px" className={styles.centerAlign}  title="Valor" />
            
          </Grid>
          </div>
        </div>
        </fieldset>

        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Observações</span>
          </legend>

          <div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}>
            <Field
              name="observacoes"
              label="Observações"
              component={TextArea}
              textField={'nome'}
              disabled={viewMode}

            />
          </div>
        </fieldset>
     
           
          
      </div>:<></>}

      {openModalInstrumentosPlaneamento ? (
          <Dialog
            title="Instrumentos Planeamento"
            className="ModalInstumentoPlaneamento"
            onClose={cancelModal}
          >
            
            <Form
              onSubmit={handleSubmitModal}
              initialValues={formData}
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 620 }}>
                  <div className={`${styles.form} ${styles.removeScrollDuplicado}`}>
                    
                      <div className={styles.fields}>
                        <div>
                        <Field
                            name="id_int_instrumento_planeamento"
                            label="Instrumento planeamento"
                            component={ComboBox}
                            textField={'designacao'}
                            filterable={true}
                            onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'instrumentoPlaneamento')}}
                            data={combos?.instrumentoPlaneamento}
                            disabled={viewMode}
                            onChange={(e: any) => handleInstrumentoPlaneamentoChange(e, formRenderProps)}                     />
                        </div>
                      </div>
                      <div className={styles.fields}>
                        <div>
                          <Field
                            name="id_tipo_n1"
                            label="Tipologia Nivel 1"
                            component={ComboBox}
                            textField={'descricao'}
                            filterable={true}
                            onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'planeamento1')}}
                            data={filteredPlaneamento1}
                            disabled={viewMode || !isInstrumentoPlaneamentoFilled}
                            onChange={handleFormChange}
                          />
                        </div>
                      </div>
                      <div className={styles.fields}>
                        <div>
                        <Field
                          name="id_tipo_n2"
                          label="Tipologia Nivel 2"
                          component={ComboBox}
                          textField={'descricao'}
                          filterable={true}
                          onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'planeamento2')}}
                          data={filteredPlaneamento2}
                          disabled={viewMode || !isInstrumentoPlaneamentoFilled}
                          onChange={handleFormChange}
                          />
                      </div>
                    </div>
                    {/* <div className={styles.fields}>
                      <div>
                        <Field
                          name="id_tipo_n3"
                          label="Tipologia Nivel 3"
                          component={ComboBox}
                          textField={'descricao'}
                          filterable={true}
                          onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'planeamento3')}}
                          data={filteredPlaneamento3}
                          disabled={viewMode || !isInstrumentoPlaneamentoFilled}
                          onChange={handleFormChange}
                        />
                    </div> */}
                  {/* </div> */}
                  
                  <div className={`${styles.fields}`}>
                    <div>
                            <Field name="numero_intervencao" onChange={handleFormChange}  label="Código da Intervenção" component={Input}  disabled={viewMode}  />
                    </div>
                  </div>
                <div className={`${styles.fields}`}>
                  <div>
                    <Field
                      name="id_prioridade_instr_plan"
                      label="Prioridade"
                      component={ComboBox}
                      textField={'descricao'}
                      filterable={true}
                      onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'prioridade')}}
                      data={prioridade}
                      disabled={viewMode}
                      onChange={handleFormChange}
                      />
                  </div>
               </div>             
                  <div className="k-form-buttons btnsModal">
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                      onClick={formRenderProps.onSubmit}
                    >
                      Submeter
                    </button>
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                      onClick={cancelModal}
                    >
                      Cancelar
                    </button>
                  </div>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}


{openModalDescartarIndicador ? (
          <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={() => setOpenModalDescartarIndicador(false)}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                  <fieldset>
                    <span className="textDescartar">{'Apagar'} indicador  </span>
                    <br></br>
                    <span>{'Tem a certeza que pretende apagar o indicador?'}</span>
                  </fieldset>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={() => setOpenModalDescartarIndicador(false)}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={removeIndicador}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}

{openModalDescartar ? (
          <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={() => setOpenModalDescartar(false)}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                  <fieldset>
                    <span className="textDescartar">{'Apagar'} registo  </span>
                    <br></br>
                    <span>{'Tem a certeza que pretende apagar a associação?'}</span>
                  </fieldset>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={() => setOpenModalDescartar(false)}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={removeContacto}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}
    </>
  )
}
