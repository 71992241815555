import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faInfo } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@progress/kendo-react-buttons'

import styles from '../../styles/viewer/UserMenu.module.scss'
import { AUTH_LOCALSTORAGE, BASE_GEOPORTAL_URL, GUEST_USER_NAME } from '../../utils/constants'
import useOutsideListener from '../../hooks/useOutsideClickListener'
import { useRef } from 'react'
import { useNavigate } from 'react-router'
import { clearSession } from '../../services/utils'
import clsx from 'clsx'

type Props = {
  onClose: () => void
}

export default function UserMenu({ onClose }: Props) {
  const loggedUser = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) || '{}')
  const wrapperRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate();
  useOutsideListener(wrapperRef, onClose)

  const handleButtonClick = () => {
    if (loggedUser?.user) {
      clearSession();
      const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.src = BASE_GEOPORTAL_URL + "Account/Logout"
    }
    setTimeout(() => {
      navigate("/login");
      onClose()
    }, 1000)
    
  }

  return (
    <div className={styles.base} ref={wrapperRef}>
      <div className={styles.user}>
        <div className={styles.userIcon}>
          <FontAwesomeIcon icon={faUser} />
        </div>
        <span className={styles.userName}>
          {!loggedUser?.user?.username || loggedUser?.user?.username == GUEST_USER_NAME ? 'Utilizador público' : loggedUser?.user?.username}
        </span>
      </div>
      <div className={styles.content}>
        {!loggedUser?.user && (
          <div className={styles.alert}>
            <div className={styles.alertIcon}>
              <FontAwesomeIcon icon={faInfo} />
            </div>
            <span className={styles.alertText}>
              Não tem sessão iniciada
            </span>
          </div>
        )}
        <Button
          fillMode="outline"
          themeColor={loggedUser?.user ? 'error' : 'base'}
          className={clsx(styles.button, styles.btnLogged)}
          onClick={handleButtonClick}
        >
          {(loggedUser?.user && loggedUser?.user?.username != GUEST_USER_NAME) ? 'Terminar sessão' : 'Iniciar sessão'}
        </Button>
      </div>
    </div>
  )
}
