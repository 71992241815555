import { Button, DropDownButton } from '@progress/kendo-react-buttons'
import {
  Form,
  FormElement
} from '@progress/kendo-react-form'
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab
} from '@progress/kendo-react-layout'
import {
  MouseEvent as ReactMouseEvent,
  useEffect,
  useLayoutEffect,
  useState
} from 'react'
import { GetUserNameTecnico, clearProcesso, coordenadasLinhaSelected, coordenadasPoligono, coordenadasSelected, del, dhPareceres, get, getPatrimonioSelected, getProcessoSelected, handleGenericError, hasPermission, post, put, setCoordenadasLinhaSelected, setCoordenadasPoligono, setCoordenadasSelected, setDemarcacaoSelected, setPatrimonioSelected, setProcessoSelected, useCombos } from '../../services/utils'
import { useDhProcessoStore } from '../../stores/dhProcesso'
import styles from '../../styles/dominiohidrico/RegistarProcessoForm.module.scss'
import { AUTH_LOCALSTORAGE, CONSULTAR_DH_PROCESSO, DELETE_DH_PROCESSO, DH_PROCESSO_POINT_NAME, GET_PROCESSO, GET_RELATORIO_DE_PROCESSOS, GET_URL_FROM_FILE_DOC, IMPORT_GEOM_DH_PROCESSO, PERMISSION_ATUALIZAR_PROCESSO, PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_EDICAO, PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_LEITURA, PERMISSION_DOMINIO_HIDRICO_PERFIL_TOTAL, PERMISSION_INSERIR_PROCESSO, PROCESSO_GRAVAR_LINHA, PROCESSO_MODULE_NAME, REMOVER_AREA_DH_PROCESSO, REMOVER_LINHA_DH_PROCESSO, REMOVER_PONTO_DH_PROCESSO, SAVE_NEW_DH_PROCESSO, UPDATE_DH_PROCESSO, URL_AJUDA_DOMINIO_HIDRICO_PROCESSO, clearLayers } from '../../utils/constants'
import { AssociacaoListagem } from '../associacao/Associacao'
import { DocumentoListagem } from '../documentos/registar/documento/documento'
import Identificacao from './RegistarProcesso/Identificacao'
import ImportarGeometriaComponent from '../apoiogestao/registar/ImportarGeometriaForm'
import { Dialog } from '@progress/kendo-react-dialogs'
import SessionStorageService from '../../services/SessionStorageService'
import useClearMap from '../../hooks/useClearMap'
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais'
import html2canvas from 'html2canvas'
import ReactGA from 'react-ga4';
type IProps = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined,
  initialValuesParam: any
}

const TOTAL_TABS = 3

export default function RegistarProcessoForm({ onCloseClick, initialValuesParam}: IProps) {
  const [isMobile, setIsMobile] = useState(false);
  const [selected, setSelected] = useState<number>(0);
  const [openImportModal, setOpenImportModal] = useState<any>()
  const [openRemoveAreaModal, setOpenRemoveAreaModal] = useState<any>()
  const [openRemoveLinhaModal, setOpenRemoveLinhaModal] = useState<any>()
  const [openRemovePontoModal, setOpenRemovePontoModal] = useState<any>()
  const [percentLeftSucess, setPercentLeftSucess] = useState("22%");


  const selectedProcessoResult: any = useDhProcessoStore((state) => state.selectedDhProcessoResult);
  const selectedProcessoResultId: any = useDhProcessoStore((state) => state.selectedDhProcessoResultId);
  const selectProcessoResultId = useDhProcessoStore((state) => state.selectDhProcessoResultId)

  const importGeomDhProcesso = async (data: any) =>
        await post<any, any>(`${IMPORT_GEOM_DH_PROCESSO}`, data)
    const getDhProcessoById = async (id: any) =>
        await get<any, any>(`${GET_PROCESSO}/` + id)
  const selectProcessoResult = useDhProcessoStore((state) => state.selectDhProcessoResult)

   const [deleteClicked, setDeleteClicked] = useState(false);

   const removerAreaProcesso = async (data: any) =>
    await post<any, any>(`${REMOVER_AREA_DH_PROCESSO}`, data)

   const removerLinhaProcesso = async (data: any) =>
    await post<any, any>(`${REMOVER_LINHA_DH_PROCESSO}`, data)

   const gravarLinhaProcesso = async (data: any) => await post <any,any>(PROCESSO_GRAVAR_LINHA, data);

   const removerPontoProcesso = async (data: any) =>
    await post<any, any>(`${REMOVER_PONTO_DH_PROCESSO}`, data)

   const [canUpdate, setCanUpdate] = useState(false);
   const [canInsert, setCanInsert] = useState(false);

   const [editMode, setEditMode] = useState(false);
   const [viewMode, setViewMode] = useState(false);

     useEffect(() => {
    setViewMode(!editMode && sessionStorage.getItem("novoProcesso") != 'true');
    setPatrimonioSelected(undefined);
    setDemarcacaoSelected(undefined);
  }, [])


  useEffect(() => {
    setCanUpdate(hasPermission(PERMISSION_ATUALIZAR_PROCESSO));
    setCanInsert(hasPermission(PERMISSION_INSERIR_PROCESSO))
  }, [])
  

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/RegistarProcesso" });
  }, []);

      const canSeeDominioHidrico = 
                            hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_LEITURA) ||
                            hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_EDICAO) ||
                            hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_TOTAL) 
                            
  const getProcesso = async (data: any) => await get<any, any>(`${GET_PROCESSO}/` + data)

  
  const deleteProcesso = (id: number) => del(`${DELETE_DH_PROCESSO}/${id}`, {})

  const saveDhProcesso = async (data: any) => await post<any, any>(`${SAVE_NEW_DH_PROCESSO}`, data);
  const updateDhProcesso = async (data: any) => await put<any, any>(`${UPDATE_DH_PROCESSO}`, data);
  const getUrlFileDoc = async (data: any) => await get<any>(`${GET_URL_FROM_FILE_DOC}/` + encodeURIComponent(data));

  const relatorioProcesso = async (data: any) => await post<any, any>(`${GET_RELATORIO_DE_PROCESSOS}`, data)


  const handleSelect = (e: TabStripSelectEventArguments) => {
    if (e.selected > 0 && !selectedProcessoResult) {
      postMessage({type:"infoMsg", value: {message: "É necessário gravar primeiro."}})
      return;
    }
    setSelected(e.selected)
  }

  const refreshForm = async(id: any = undefined) => {
  let iProcesso = id;
  if (!iProcesso) {
    if (selectedProcessoResult?.id) {
      iProcesso = selectedProcessoResult?.id;
    } else {
      return;
    }
  }
  const processo = await getProcesso(iProcesso);

  const tecnico = await GetUserNameTecnico(processo.tecnico_id);

  
 const seletedProcessoReulst = {
            ...processo,
            tecnico_id : tecnico,
            id_tipo_processo: processo.dhTipoProcesso ?? undefined,
            id_localizacao_processo: processo.dhLocalizacaoProcesso ?? undefined,
            id_fase_pr: processo.dhFaseProcedimentoDelimitacao ?? undefined,
            id_tipo_va: processo.dhValidacaoProva ?? undefined,
            id_tipo_pa: processo.dhTipoParcela ?? undefined,
            id_tipo_se: processo.dhTipoSentenca ?? undefined,
            id_tipo_ac: processo.dhTipoDecisaoFinal ?? undefined,
            id_tipo_es: processo.dhEstadoProcesso ?? undefined,
            id_tipo_pe: processo.dhTipoPendencia ?? undefined,
            id_tipo_no: processo.dhTipoNotificacao ?? undefined,
            id_situ_no: processo.dhTipoSituacaoNotificacao ?? undefined,
            id_cond_pr: processo.dhCondicionantesProcesso ?? undefined,
            id_tipo_aj: processo.dhTipoAcaoJudicial ?? undefined,
            id_tipo_de: processo.dhTipoDesafetacao ?? undefined,
            id_tipo_aq: processo.dhTipoAquisicao ?? undefined,
            id_tipo_in: processo.dhTipoIniciativa ?? undefined,
            data_requer: processo.data_requer ? new Date(processo.data_requer) : undefined
        }

        selectProcessoResult(seletedProcessoReulst);
        setProcessoSelected(seletedProcessoReulst);

        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage(
          { type: 'SiarlProcessoSearch', value: [processo.id] },
          '*'
      )
        iframe.contentWindow?.postMessage(
            { type: 'SiarlProcessoCenter', value: [processo.id] },
            '*'
        )

        if (!!processo.dhAutos && processo.dhAutos.length > 0) {
          const iframe: any = document.getElementById('iframeGeoPortal')
          const ids = processo.dhAutos.map((x: any) => x.id);

          iframe.contentWindow?.postMessage({ type: "SiarlDhAutosSearch", value: ids }, '*')
      }
      if (!!processo.dhAutosVertices && processo.dhAutosVertices.length > 0) {
          const ids = processo.dhAutosVertices.map((x: any) => x.id);
          const iframe: any = document.getElementById('iframeGeoPortal')
          iframe.contentWindow?.postMessage({ type: "SiarlDhAutosVerticesSearch", value: ids }, '*')
      }
}

    const selectMapAction =  (item: any) => {
      const iframe: any = document.getElementById('iframeGeoPortal')

      if (item.key == "removerArea") {
        setOpenRemoveAreaModal(true)
      } else if(item.key == "removerLinha") {
        setOpenRemoveLinhaModal(true)
      } else if(item.key == "removerPonto"){
        setOpenRemovePontoModal(true)
      }
      else{
        iframe.contentWindow?.postMessage({ type: item.key, value: getProcessoSelected()?.id, editGpLayer: DH_PROCESSO_POINT_NAME }, '*')
        setpontoAtivo(true)
      }
    }

  const removeArea = async () => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: 'true' }, '*')
    setCoordenadasPoligono(undefined);
    if (!selectedProcessoResult?.id) {
      setOpenRemoveAreaModal(false)
      return;
    }
    await removerAreaProcesso(selectedProcessoResult?.id);
    
    iframe.contentWindow?.postMessage(
      { type: 'SiarlProcessoSearch', value: [selectedProcessoResult?.id] },
      '*'
    )
    refreshForm(selectedProcessoResult?.id)
    setOpenRemoveAreaModal(false)

  }

  const removeLinha = async () => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: 'true' }, '*')
    if (!selectedProcessoResult?.dhAutos[0]?.id_dh_proc) {
      setOpenRemoveLinhaModal(false)
      return;
    }
    setCoordenadasLinhaSelected(undefined);
    await removerLinhaProcesso(selectedProcessoResult?.dhAutos[0]?.id_dh_proc);
    
    iframe.contentWindow?.postMessage(
      { type: 'SiarlDhAutosSearch', value: [selectedProcessoResult?.dhAutos[0]?.id_dh_proc] },
      '*'
    )
    setOpenRemoveLinhaModal(false)
  }

  const removePonto = async () => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: 'true' }, '*')
    setCoordenadasSelected(undefined)
    if (!selectedProcessoResult?.dhAutosVertices[0]?.id_dh_proc) {
      setOpenRemovePontoModal(false)
      return;
    }
    await removerPontoProcesso(selectedProcessoResult?.dhAutosVertices[0]?.id_dh_proc);
    
    iframe.contentWindow?.postMessage(
      { type: 'SiarlDhAutosVerticesSearch', value: [selectedProcessoResult?.dhAutosVertices[0]?.id_dh_proc] },
      '*'
    )
    setOpenRemovePontoModal(false)
  }

  const [pontoAtivo, setpontoAtivo] = useState(false)

  const DesativarSelecaoPonto = () => {
    setpontoAtivo(false)
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: 'SiarlGeoPortalAdicionarPonto', value: 'false' }, '*')
  }

  const cancelImport = () => {
        setOpenImportModal(false)
    }
    const importGeo = async (objToSend: any) => {
        postMessage({ type: "showLoader" }, "*");

        if (!objToSend) {
            postMessage({ type: "errorMsg", value: "Não foi possivel salvar" }, "*");
            postMessage({ type: "hideLoader" }, "*");
            return;

        }
        let dh_processo;
        let dh_processo_id;
        const processoSelected = selectedProcessoResult ?? getProcessoSelected();
        if (processoSelected && processoSelected?.id) {
            dh_processo = processoSelected;
            dh_processo_id = processoSelected?.id;
        } else {
            dh_processo = await saveDhProcesso({});
            dh_processo_id = dh_processo?.id;
            selectProcessoResult(dh_processo);
            selectProcessoResultId(dh_processo);

        }
        try {
            objToSend = { ...objToSend, dh_processo_id }
            const result = await importGeomDhProcesso(objToSend)
            if (!result) {
                postMessage({ type: "errorMsg", value: "Não foi possivel salvar" }, "*");
                return;
            }

            try {
                dh_processo = await getDhProcessoById(dh_processo_id);
            } catch (error) {
              handleGenericError(error, "Registo salvo mas, Não é possivel mostrar os dados neste momento")

            } finally {
                selectProcessoResultId(dh_processo_id);
                selectProcessoResult({ ...dh_processo, ...objToSend });
                setProcessoSelected({ ...dh_processo, ...objToSend })
                refreshForm(dh_processo_id)
            }
            try {
                const iframe: any = document.getElementById('iframeGeoPortal')
                iframe.contentWindow?.postMessage(
                    { type: 'SiarlProcessoCenter', value: [dh_processo_id] },
                    '*'
                )
            } catch (error) {
                handleGenericError(error, "Registo salvo mas, Não é possivel mostrar os dados neste momento")
            }

            setOpenImportModal(false)
        } catch (err) {
          handleGenericError(err)

        } finally {
            postMessage({ type: "hideLoader" }, "*");
        }


    }

      const [openModalDescartar, setOpenModalDescartar] = useState<any>()

  const cancelModalDescartar = () => {
    setOpenModalDescartar(false)
  }

  const getProcessos = async (data: any) => await post<any, any>(`${CONSULTAR_DH_PROCESSO}`, data)


  const handleCancel = ()  => {
        refreshForm();
        postMessage({ type: "form", value: "" }, "*");
        setTimeout(() => {
            postMessage({ type: "form", value: "registarProcesso" }, "*");
          }, 10)
    }


   const handleOpenModalDescartar = () => {
    if (
      sessionStorage.getItem('novoProcesso') &&
      sessionStorage.getItem('novoProcesso') == 'true'
    ) {
      setOpenModalDescartar(true);

      if(pontoAtivo){
        DesativarSelecaoPonto()
      }

    } else {
      onEditItemClick("SiarlVisualizar");
      handleCancel();

      if(pontoAtivo){
        DesativarSelecaoPonto()
      }
      
    }  }

    const [isCheckedIndefinicaoGeom, setIsCheckedIndefinicaoGeom] = useState(false);

    const handleCheckboxValueChange = (newCheckedValue: boolean) => {
      setIsCheckedIndefinicaoGeom(newCheckedValue);
    };

  const [estadoFalse, setEstado] = useState(false)
  const [estadoTrue, setEstadoTrue] = useState(true)


  const handleDescartar = async (e: any) => {

    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
      {
        type: 'SiarlCoordenadaBoxClose',
        value: 'true',
      },
      '*'
    )
    iframe.contentWindow?.postMessage(
      {
        type: 'SiarlRemoveSelection',
        value: 'true',
      },
      '*'
    )
     setOpenModalDescartar(false)
     setDeleteClicked(false);
   
      const processoSelected = getProcessoSelected()

    const dataToSend : any = {
      id: processoSelected?.id ?? undefined,
      status: estadoFalse
    }

      let ProcessoSaved: any
    if (processoSelected?.id) {
         postMessage({ type: 'showLoader' }, '*')
          ProcessoSaved = await updateDhProcesso(dataToSend)
          selectProcessoResult({
            ...selectedProcessoResult,
            ...ProcessoSaved,})
          postMessage({ type: 'hideLoader' }, '*')

          }
          
    SessionStorageService.removeItem("firstCallProcesso");
    postMessage({ type: 'form', value: 'pesquisarDominioHidrico' }, '*')

}

  const handleSubmit = async (e: any) => {

    if (!isFormValid) {
      return
    }

    const processoSelected = getProcessoSelected();
    const isSubmitAction: boolean = JSON.parse(SessionStorageService.getItem("isSubmitActionProcesso") ?? false);

    const userId = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '')?.user?.id

    const processoRequest: any = {
      // REGISTO
      id: processoSelected?.id ?? undefined,
      np_filedoc: e.values?.np_filedoc ?? undefined,
      id_tipo_es: e.values?.id_tipo_es?.id ?? undefined,
      data_registo: e.values?.data_registo ?? undefined,
      tecnico_id: userId,


      // TIPOLOGIA
      id_tipo_processo: e.values?.id_tipo_processo?.id ?? undefined,
      id_tipo_aj: e.values?.id_tipo_aj?.id ?? undefined,
      id_tipo_in: e.values?.id_tipo_in?.id ?? undefined,
      id_tipo_aq: e.values?.id_tipo_aq?.id ?? undefined,


      // iDENTIFICAÇÃO PROCESSO
      requerente: e.values?.requerente ?? undefined,
      assunto: e.values?.assunto ?? undefined,
      np_legado: e.values?.np_legado ?? undefined,
      np_cdpm: e.values?.n_cdpm ?? undefined,
      np_mp: e.values?.np_mp ?? undefined,
      np_comum: e.values?.np_comum ?? undefined,
      np_ent_por: e.values?.np_ent_por ?? undefined,
      num_doc: e.values?.num_doc ?? undefined,
      id_localizacao_processo: e.values?.id_localizacao_processo?.id ?? undefined,
      obs_tipo_e: e.values?.obs_tipo_e ?? undefined,

      // LOCALIZAÇÃO
      nuts1_cod: e.values?.nuts1?.codigo ?? undefined,
      nuts2_cod: e.values?.nuts2?.codigo ?? undefined,
      nuts3_cod: e.values?.nuts3?.codigo ?? undefined,
      distrito_cod: e.values?.distrito?.codigo ?? undefined,
      concelho_cod: e.values?.concelho?.codigo ?? undefined,
      freguesia_cod: e.values?.freguesia?.codigo ?? undefined,
      arh_cod: e.values?.arh?.codigo ?? undefined,
      capitania_cod: e.values?.capitania?.codigo ?? undefined,
      massa_agua_id: e.values?.massaagua?.id ?? undefined,
      lugar: e.values?.local ?? undefined,


      // FASE PROCESSO
      id_fase_pr: e.values?.id_fase_pr?.id ?? undefined,
      representante: e.values?.representante ?? undefined,
      obs_fase_p: e.values?.obs_fase_p ?? undefined,


      // RECONHECIMENTO PROPRIEDADE PRIVADA
      dh_rpp_antes_dl_id: e.values?.rpp_antes_DL ? e.values?.rpp_antes_DL?.id : undefined,
      dh_tipo_acao_reconhecimento_id: e.values?.rpp_depois_DL?.id ?? undefined,
      obs_rpp: e.values?.obs_rpp ?? undefined,
      dhPareceres: SessionStorageService.getItem("gridDataPareceres") ? SessionStorageService.getItem("gridDataPareceres") : undefined,


      // OUTROS CRITÉRIOS
      def_licenciamento: e.values?.comDeferimento?.id ?? undefined,
      

      // PARECER CDPM


      // AUTO DELIMITAÇÃO
      com_auto_del: e.values?.comAuto ?? undefined,
      id_tipo_ad: e.values?.tipo_DominioAuto?.id ?? undefined,

      
      // DIPLOMA PUBLICADO
      obs_ad: e.values?.obs_ad ?? undefined,
      id_cond_pr: e.values?.id_cond_pr?.id ?? undefined,
      obs_condicionantes: e.values?.obs_condicionantes ?? undefined,
      dr: e.values?.diarioRepublica ?? undefined,


      // DECISÃO
      td_dh_dec_final: e.values?.dec_final?.id ?? undefined,
      id_tipo_se: e.values?.id_tipo_se?.id ?? undefined,
      obs_senten: e.values?.obs_senten ?? undefined,
      id_tipo_ac: e.values?.id_tipo_ac?.id ?? undefined,
      obs_acorda: e.values?.obs_acorda ?? undefined,
      
      

      flatCoordinates: coordenadasPoligono ?? undefined,
      flatCoordinatesPonto: coordenadasSelected ?? undefined,
      flatCoordinatesLinha: coordenadasLinhaSelected ?? undefined,
      tipo_geom_indefinida: e.values?.tipo_geom_indefinida,
      status: e?.values?.status?.valor ?? undefined,
      geom_imported: selectedProcessoResult?.geom_imported,
    }

  
    postMessage({ type: 'showLoader' }, '*');

    try {
      let ProcessoSaved: any;
      const processoSelected = getProcessoSelected();

      if (selected != 1 && selected != 2) {
        if (processoSelected?.id) {

          ProcessoSaved = await updateDhProcesso(processoRequest);
          setProcessoSelected(ProcessoSaved);

        } else {

          ProcessoSaved = await saveDhProcesso(processoRequest);
          setProcessoSelected(ProcessoSaved);

        }
        if (SessionStorageService.getItem("gridDataAutos")) {
          const processo = await getProcesso(ProcessoSaved?.id);
          
          if (!!processo.dhAutos && processo.dhAutos.length > 0) {
            await removerLinhaProcesso(processo?.id);
          }
          const gridData : any[] = SessionStorageService.getItem("gridDataAutos");

          const lstDhAutosLinha: any[] = [];
          
          gridData.forEach((item)  => {
            let coordinatesToSend : any[] = [] // SessionStorageService.getItem(item.id);
            
            const fields : any [] = SessionStorageService.getItem("fieldsDHAuto");
            coordinatesToSend = [];
            fields.forEach((itemField, index) => {
                const objToSend = {
                    id: item.id,
                    Latitude : item.e['latitude' + index] ? Number.parseFloat( item.e['latitude' + index]) : undefined,
                    Longitude :  item.e['longitude' + index] ? Number.parseFloat( item.e['longitude' + index]) : undefined,
                    Latitude_Corrigida:  item.e['latitudeCorrigida' + index] ? Number.parseFloat( item.e['latitudeCorrigida' + index]) : undefined,
                    Longitude_Corrigida:  item.e['longitudeCorrigida' + index] ? Number.parseFloat( item.e['longitudeCorrigida' + index]) : undefined,
                    indice: Number.parseInt( item.e['indice' + index])
                }
                coordinatesToSend.push(objToSend);
            })


            const objToSendLinha = {
                 id_dominio: item.e.tipo_DominioAuto?.id,
                 coodinateDtos: coordinatesToSend
              }
              lstDhAutosLinha.push(objToSendLinha);
          }) 
          const objToDTOToSend = {
            id_processo: processo.id,
            lstDhAutosLinha: lstDhAutosLinha
          }
          await gravarLinhaProcesso(objToDTOToSend);
          // const coordinatesToSend = await gridData.map((item: any) => ({
          //   Latitude: item.Latitude ? JSON.parse(item.Latitude) : undefined,
          //   Longitude: item.Longitude ? JSON.parse(item.Longitude) : undefined,
          //   Latitude_Corrigida: item.Latitude_Corrigida ? JSON.parse(item.Latitude_Corrigida) : undefined,
          //   Longitude_Corrigida: item.Longitude_Corrigida ? JSON.parse(item.Longitude_Corrigida) : undefined,
          //   indice: JSON.parse(item.indice)
          // }))
          // const objToSendLinha = {
          //   id_processo: processo?.id,
          //   id_dh_auto: idDhAuto,
          //   coodinateDtos: coordinatesToSend
          // }

          // await gravarLinhaProcesso(objToSendLinha);
        }
      }

      setCoordenadasPoligono(undefined);
      
      refreshForm(ProcessoSaved?.id);

      if(pontoAtivo){
        DesativarSelecaoPonto()
      }

      setTimeout(() => {
    postMessage({ type: 'sucessMsg' , value :{left : (isMobile? (Number(percentLeftSucess.split('%')[0])-40)+'%' : percentLeftSucess)
        } },'*')
      }, 500)

    } catch (error) {
      handleGenericError(error);
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  const voltar = () => {
   
    clearProcessoMap();

    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
      {
        type: 'SiarlCoordenadaBoxClose',
        value: 'true',
      },
      '*'
    )

    if(pontoAtivo){
      DesativarSelecaoPonto()
    }

    iframe.contentWindow?.postMessage(
      { type: 'SiarlRemoveSelection', value: 'true' },
      '*'
    )
    iframe.contentWindow?.postMessage(
      { type: 'SiarlDhAutosClearLayers', value: 'true' },
      '*'
    )
        postMessage({ type: 'form', value: 'pesquisarDominioHidrico' }, '*');
  }

  const [clearMap] = useClearMap();
  const clearProcessoMap = () => {
    clearMap(clearLayers["CLEAR_LAYERS_PROCESSOS"]);
    const iframe: any = document.getElementById('iframeGeoPortal')

    iframe.contentWindow?.postMessage(
      { type: 'SiarlDhAutosClearLayers', value: "true" },
      '*'
    )

    iframe.contentWindow?.postMessage(
      { type: 'SiarlDhAutosVerticesClearLayers', value: "true" },
      '*'
    )
    setProcessoSelected(undefined)
    setCoordenadasSelected(undefined)
    setCoordenadasLinhaSelected(undefined)
    selectProcessoResult(undefined)
  }

  const itemRender = (props: { item: any; itemIndex: number }) => {
    const isTitleItem = props.item.key === 'titulo'
    if (isTitleItem) {
      // Item de título personalizado
      return (
        <div className={styles.DropDownButtonTitulo}>
          <span>{props.item.text}</span>
        </div>
      )
    } else {
      // Demais itens
      return (
        <div className={styles.DropDownButtonItem}>
          <span>{props.item.text}</span>
        </div>
      )
    }
  } 

  const onItemClick = (event: any) => {
    if(event?.item?.key == "ImportGeom"){
      ImportGeom(event.item)
     } else {
      selectMapAction(event.item)
     }
  }

  const optionsArea = [
    {
      key: 'SiarlGeoPortalAdicionarArea',
      text: 'Desenhar Área',
    },
    {
      key: 'SiarlEditarGeometria',
      text: 'Editar Área',
    },
    {
      key: 'removerArea',
      text: 'Remover Área',
    },
    {
      key: 'ImportGeom',
      text: 'Importar Geom',
    }, 
  ]

  const optionsPonto = [
    {
      key: 'SiarlGeoPortalAdicionarPonto',
      text: 'Adicionar Ponto',
    },
    {
      key: 'SiarlEditarGeometria',
      text: 'Editar Ponto',
    },
    {
      key: 'removerPonto',
      text: 'Remover Ponto',
    },
  ]

  const optionsLinha = [
    {
      key: 'SiarlGeoPortalAdicionarLinha',
      text: 'Adicionar Linha',
    },
    {
      key: 'SiarlEditarGeometria',
      text: 'Editar Linha',
    },
    {
      key: 'removerLinha',
      text: 'Remover Linha',
    },
  ]

  const onItemClickImprimir = async (event: any) => {

    let mapImage = ''

    if (!window.location.href.includes('localhost')) {
      const mapIframe = document.querySelector(
        '#iframeGeoPortal'
      ) as HTMLIFrameElement

      const mapIframeInnerContent =
        mapIframe.contentWindow?.document.querySelector(
          'section.content'
        ) as HTMLElement

      const generatedImage = await html2canvas(mapIframeInnerContent)
      mapImage = generatedImage.toDataURL()
    }

    console.log(getProcessoSelected());

    try {
      postMessage({ type: 'showLoader' }, '*')

      const documentoBase64 = await relatorioProcesso({
        id: getProcessoSelected()?.id,
        dados: {
          'Tipo de processo': getProcessoSelected()?.dhTipoProcesso?.descricao ?? '',
          'Utilizador': getProcessoSelected()?.utilizador?.name ?? '',
          'Localização do processo': getProcessoSelected()?.dhLocalizacaoProcesso?.descricao ?? '',
          'Fase de procedimento de delimitação': getProcessoSelected()?.dhFaseProcedimentoDelimitacao?.descricao ?? '',
          'Prova de validação': getProcessoSelected()?.dhValidacaoProva?.descricao ?? '',
          'Tipo de parcela': getProcessoSelected()?.dhTipoParcela?.descricao ?? '',
          'Tipo de sentença': getProcessoSelected()?.dhTipoSentenca?.descricao ?? '',
          'Tipo de decisão final': getProcessoSelected()?.dhTipoDecisaoFinal?.descricao ?? '',
          'Estado do processo': getProcessoSelected()?.dhEstadoProcesso?.descricao ?? '',
          'Tipo de notificação': getProcessoSelected()?.dhTipoNotificacao?.descricao ?? '',
          'Tipo de situação da notificação': getProcessoSelected()?.dhTipoSituacaoNotificacao?.descricao ?? '',
          'Condicionantes de processo': getProcessoSelected()?.dhCondicionantesProcesso?.descricao ?? '',
          'Tipo de ação judicial': getProcessoSelected()?.dhTipoAcaoJudicial?.descricao ?? '',
          'Tipo de desafetação': getProcessoSelected()?.dhTipoDesafetacao?.descricao ?? '',
          'Tipo de aquisição': getProcessoSelected()?.dhTipoAquisicao?.descricao ?? '',
          'Tipo de iniciativa': getProcessoSelected()?.dhTipoIniciativa?.descricao ?? '',
          'Observação do tipo de processo': getProcessoSelected()?.obs_t_proc ?? '',
          'Nº de processo - Filedoc': getProcessoSelected()?.np_filedoc ?? '',
          'Nº de processo - legado': getProcessoSelected()?.np_legado ?? '',
          'Requerente': getProcessoSelected()?.requerente ?? '',
          'Data de requerimento': getProcessoSelected()?.data_requer ?? '',
          'Data de registo': getProcessoSelected()?.data_registo ?? '',
          'Assunto': getProcessoSelected()?.assunto ?? '',
          'Histórico': getProcessoSelected()?.histor ?? '',
          'Observação da fase de processo': getProcessoSelected()?.obs_fase_p ?? '',
          'Representante': getProcessoSelected()?.representante ?? '',
          'n_cdpm': getProcessoSelected()?.n_cdpm ?? '',
          'np_ent_por': getProcessoSelected()?.np_ent_por ?? '',
          'np_apa_rpp': getProcessoSelected()?.np_apa_rpp ?? '',
          'np_mp': getProcessoSelected()?.np_mp ?? '',
          'np_comum': getProcessoSelected()?.np_comum ?? '',
          'Observação da sentença': getProcessoSelected()?.obs_senten ?? '',
          'obs_acorda': getProcessoSelected()?.obs_acorda ?? '',
          'obs_tipo_e': getProcessoSelected()?.obs_tipo_e ?? '',
          'freguesias': getProcessoSelected()?.freguesias[0]?.nome ?? '',
          'concelhos': getProcessoSelected()?.concelhos[0]?.nome ?? '',
          'distritos': getProcessoSelected()?.distritos[0]?.nome ?? '',
          'nuts3': getProcessoSelected()?.nuts3[0]?.nome ?? '',
          'nuts2': getProcessoSelected()?.nuts2[0]?.nome ?? '',
          'nuts1': getProcessoSelected()?.nuts1[0]?.nome ?? ''
        },     
        img_mapa: mapImage
      });

      if (documentoBase64) {
        const link = document.createElement('a')
        link.href = 'data:application/octet-stream;base64,' + documentoBase64
        link.download = 'ficha_processo.pdf'
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        postMessage({ type: 'hideLoader' }, '*')
      }
    } catch (error) {
      postMessage({ type: 'errorMsg', value: { message: "Não foi possivel efetuar esta operação" } }, '*')
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }

  };

  const optionsImprimir = [
    {
      key: 'imprimirFichas',
      text: 'Fichas',
    },
    {
      key: 'imprimirResultados',
      text: 'Resultados',
    },
  ]

  const onItemClickExportar = (event: any) => {

    if (event.item.key == 'ExportarFichasResultados') {

      const link: HTMLAnchorElement | null = document.querySelector('#print-results');

      if (link) {
        link.click();
      }

    } else if (event.item.key == 'ExportarListaResultados') {

      console.log(event.item.text);

    }

  };

  const optionsExportar = [
    {
      key: 'exportarFichas',
      text: 'Fichas',
    },
    {
      key: 'exportarResultados',
      text: 'Resultados',
    },
  ]

  const isFormValid =
    (selected === 0) ||
    (selected === 1) ||
    (selected === 2)

  useEffect(() => {
    sessionStorage.setItem("SubModulo", PROCESSO_MODULE_NAME);
  }, []);

   

  const importarGeometria = [
    {
      key: 'ImportGeom',
      text: 'Importar Geom',
    },
  ]
 const ImportGeom = async (item: any) => {
    if (item.key == 'ImportGeom') {
      setOpenImportModal(true)
    }
  }

   const onImportGeomClick = (event: any) => {
    ImportGeom(event.item)
  }

 const onEditItemClick= (event:any) => {
    if (event == "SiarlEditar" || event?.item?.key == "SiarlEditar") {
      setEditMode(true);
      setViewMode(false);
      return;
    }
    if (event == "SiarlVisualizar") {
      setEditMode(false);
      setViewMode(true);
    }
    if (event?.item?.key == "SiarlApagar") {
      setDeleteClicked(true);
      setOpenModalDescartar(true);
    }
  }
  
  useEffect(() => {
    if (viewMode) {
      sessionStorage.removeItem("novoProcesso");
    }
  })

  const optionsEditar = [
    {
      key: 'SiarlEditar',
      text: 'Editar',
    },
    {
      key: 'SiarlApagar',
      text: 'Apagar',
    },
    
  ]

  const onClickOpenFileDoc = async() => {
    const processoSelected = getProcessoSelected()
    if (!processoSelected?.np_filedoc) {
      postMessage({ type: "infoMsg", value: {message: "É necessário preencher o nº processo APA (filedoc)."} }, "*");
      return;
    }
    postMessage({ type: 'showLoader' }, '*');
    const urlFileDoc = await getUrlFileDoc(processoSelected?.np_filedoc);
    postMessage({ type: 'hideLoader' }, '*')
    window.open(urlFileDoc, '_blank');
    return;
  }

  useLayoutEffect(() => {

    const handleResize = () => {

      if (window.innerWidth <= telaMobile) {
        
        setIsMobile(true);
        ocultaFloatingMenu_e_Mapa();

      }else{

        setIsMobile(false);
        mostraFloatingMenu_e_Mapa();

      }

    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);  

  const onItemClickMais = (event: any) => {

    elementoClickMais(event);

    onEditItemClick(event);
    onImportGeomClick(event);
    onItemClick(event);
    onItemClickExportar(event);
  };

  const optionsMais = [
    {
      key: 'titulo',
      text: 'Registo',
    },
    ...optionsEditar.map(option => ({
      ...option,
      className: 'icon-siarl icon-editar btnIconRegistar',
    })),
    {
      key: 'titulo',
      text: 'Opções',
    },
    {
      key: 'openFileDoc',
      text: 'Diretório de documentos',
      className: 'icon-siarl icon-filedoc btnIconRegistar'
    },
    {
      key: 'titulo',
      text: 'Geometria',
    },
    {
      key: 'titulo',
      text: 'Área',
    },
    ...optionsArea.map(option => ({
      ...option,
      className: 'icon-siarl icon-area btnIconRegistar',
    })),  
    {
      key: 'titulo',
      text: 'Ponto',
    },
    ...optionsPonto.map(option => ({
      ...option,
      className: 'icon-siarl icon-pin btnIconRegistar',
    })),
    {
      key: 'titulo',
      text: 'Linha',
    },
    ...optionsLinha.map(option => ({
      ...option,
      className: 'icon-siarl icon-linha btnIconRegistar',
    })),
  ]

  const openAjuda = () => {
    window.open(URL_AJUDA_DOMINIO_HIDRICO_PROCESSO, "_blank")
  }

  return (

    <div className={styles.base}>

      <div className={styles.header}>
        <div>
          <div className={styles.nomeDoModulo}>
            Domínio Hídrico
          </div>
          <div className={styles.tituloDaPagina}>
           {!selectedProcessoResult || !selectedProcessoResult?.id ? 'Novo processo' : (editMode ? 'Editar processo' : 'Processo  ')}
          </div>
        </div>

        <div>

          {!isMobile && canUpdate && (viewMode || editMode) && (sessionStorage.getItem("novoProcesso") != 'true') ? 
          <DropDownButton
            items={optionsEditar}
            className={styles.btnEditar2}
            iconClass="icon-siarl icon-editar"
            onItemClick={onEditItemClick}
            fillMode={'flat'}
            itemRender={itemRender}
            title="Editar"
          ></DropDownButton>
          : ''}

          <button className={styles.btnEditar2} title='Filedoc' onClick={onClickOpenFileDoc}
          style={{ display: isMobile ? 'none' : '' }} id='openFileDoc'>
            <span className="icon-siarl icon-filedoc"></span>
          </button>

          {!isMobile &&
            <DropDownButton
              items={optionsArea}
              className={styles.btnEditar2}
              iconClass="icon-siarl icon-area"
              onItemClick={onItemClick}
              fillMode={'flat'}
              title='Geometria - Área'
              itemRender={itemRender}
              disabled={viewMode || isCheckedIndefinicaoGeom} 
            ></DropDownButton>
          }

          {!isMobile &&
            <DropDownButton
              items={optionsPonto}
              className={styles.btnEditar2}
              iconClass="icon-siarl icon-pin"
              onItemClick={onItemClick}
              fillMode={'flat'}
              title='Geometria Ponto'
              itemRender={itemRender}
              disabled={viewMode || isCheckedIndefinicaoGeom} 
            ></DropDownButton>
          }

          {/* {!isMobile &&
            <DropDownButton
              items={optionsLinha}
              className={styles.btnEditar2}
              iconClass="icon-siarl icon-linha"
              onItemClick={onItemClick}
              fillMode={'flat'}
              title='Linha'
              itemRender={itemRender}
              disabled={viewMode || isCheckedIndefinicaoGeom} 
            ></DropDownButton>
          } */}

          <button className={styles.btnEditar2} title='Imprimir' onClick={onItemClickImprimir}
          style={{ display: isMobile ? 'none' : '' }} id='imprimirFicha'>
            <span className="icon-siarl icon-imprimir"></span>
          </button>

          {!isMobile &&
          <DropDownButton
            items={optionsExportar}
            className={styles.btnImprimir}
            style={{marginTop: '-4px'}}
            iconClass='icon-siarl icon-exportar'
            onItemClick={onItemClickExportar}
            fillMode={"flat"}
            itemRender={itemRender}
            title='Exportar'
          />
          }

          {isMobile &&
            <DropDownButton
              items={optionsMais}
              className={styles.btnMais}
              iconClass="icon-siarl icon-mais"
              onItemClick={onItemClickMais}
              fillMode={'flat'}
              itemRender={itemRenderMais}
              title="Mais Opções"
            />
          }

          <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
            <span className="icon-siarl icon-ajuda"></span>
          </button>

          <button className={styles.btnFechar} onClick={voltar} title="Fechar">
            <span className="icon-siarl icon-fechar"></span>
          </button>

        </div>

      </div>

        {openImportModal ?
                <ImportarGeometriaComponent onSubmit={importGeo} cancelImport={cancelImport} /> : <></>
            }

        {openRemoveAreaModal ? (
               <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={() => setOpenRemoveAreaModal(false)}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                    <span className="textDescartar">Remover área </span>
                    <br></br>
                    <span>{'Tem a certeza que pretende remover a área selecionada?'}</span>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={() => setOpenRemoveAreaModal(false)}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={removeArea}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}


          {openModalDescartar ? (
          <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={cancelModalDescartar}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                  <fieldset>
                    <span className="textDescartar">{deleteClicked ? 'Apagar' : 'Cancelar'} registo  </span>
                    <br></br>
                    <span>{deleteClicked ? 'Ao apagar este registo deixará de ter acesso, sendo necessária uma intervenção rigorosa para realizar a sua recuperação.' : 'Ao cancelar este registo irá perder os dados inseridos até ao momento.'}</span>
                      <br></br>
                    <span>{deleteClicked ? 'Tem a certeza que pretende apagar?' : 'Tem a certeza que pretende cancelar?'}</span>
                  </fieldset>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={cancelModalDescartar}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={handleDescartar}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}

{openRemoveLinhaModal ? (
               <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={() => setOpenRemoveLinhaModal(false)}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                    <span className="textDescartar">Remover linha </span>
                    <br></br>
                    <span>{'Tem a certeza que pretende remover a linha desenhada?'}</span>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={() => setOpenRemoveLinhaModal(false)}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={removeLinha}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}

{openRemovePontoModal ? (
               <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={() => setOpenRemovePontoModal(false)}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                    <span className="textDescartar">Remover ponto </span>
                    <br></br>
                    <span>{'Tem a certeza que pretende remover o ponto selecionado?'}</span>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={() => setOpenRemovePontoModal(false)}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={removePonto}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}

        {canSeeDominioHidrico ? 
      <Form
        initialValues={
          initialValuesParam ?
            {
              ...initialValuesParam,
              data_registo: initialValuesParam?.data_registo ? new Date(initialValuesParam?.data_registo) : new Date(),
              data_requer: initialValuesParam?.data_requer ? new Date(initialValuesParam?.data_requer) : undefined,
              status: status?.[0],
              tecnico_id: JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '')?.user?.name
            } :
            {
              ...selectedProcessoResult,
              data_registo: selectedProcessoResult?.data_registo ? new Date(selectedProcessoResult?.data_registo) : new Date(),
              data_requer: selectedProcessoResult?.data_requer ? new Date(selectedProcessoResult?.data_requer) : undefined,
              status: status?.[0],
              tecnico_id: selectedProcessoResult?.tecnico_id ?? JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '')?.user?.name
            } ?? undefined}
        onSubmitClick={handleSubmit}
        render={(formRenderProps) => (
          <FormElement>
            <TabStrip selected={selected} onSelect={handleSelect} 
            className={`${styles.innerStyleTabContent} ${styles.backgroundWhite} ${viewMode? 'tabsVisualizar' : ''}`} scrollable={isMobile}>

              <TabStripTab title="Identificação" key="Identificação">
                <Identificacao onCloseClick={onCloseClick} viewMode={viewMode} onCheckboxValueChange={handleCheckboxValueChange} formRenderProps={formRenderProps} />
              </TabStripTab>

              <TabStripTab title="Documentos" key={"Documentos"}>
                <DocumentoListagem pageName='processos' viewMode={viewMode}/>
              </TabStripTab>

              <TabStripTab title="Associação"  key={"Associação"}>
                <AssociacaoListagem pageName='processos' viewMode={viewMode}/>
              </TabStripTab>

            </TabStrip>

            {(editMode || sessionStorage.getItem("novoProcesso") == 'true') && !viewMode ?
                  <div
                    className={`${styles.footer} ${styles.justify_multiple_children}`}  
                  >

                  <Button type="button" className={styles.btnSeguinte}  onClick={handleOpenModalDescartar} title='Cancelar'>
                  <span className="icon-siarl icon-fechar"></span>
                  <span className={styles.rotulo}>
                    {' '}&nbsp;{' '}
                    Cancelar
                  </span>
                 </Button>    

                    <Button
                      className={styles.btnSubmeter}
                      onClick={(e) => {
                    formRenderProps.onSubmit(e);
                    e.preventDefault();
                  }}
                  title='Gravar'
                    >
                      <span className="icon-siarl icon-submeter btnIconRegistar"></span>
                      <span className={styles.rotulo}>
                        {' '}&nbsp;{' '}
                        Gravar
                      </span>
                    </Button>
                  </div>
            : ''}
          </FormElement>
        )}
      />
       : <></>}
    </div>
  )
}
