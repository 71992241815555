import { Error } from '@progress/kendo-react-labels'
import { useEffect } from 'react'

import { useDocumentosStore } from '../../../../stores/documentos'
import styles from '../../../../styles/documentos/Documentos.module.scss'

import type { FieldRenderProps } from '@progress/kendo-react-form'
import { ComboBox, ComboBoxProps } from '@progress/kendo-react-dropdowns'

export default function ValidatedDetalheComboBox(fieldRenderProps: FieldRenderProps) {
  const addDetalheTabValidatedField = useDocumentosStore((state) => state.addDetalheTabValidatedField)

  useEffect(() => {
    addDetalheTabValidatedField({ [fieldRenderProps.name]: (!!fieldRenderProps.value || fieldRenderProps.value == 0)})
  }, [fieldRenderProps.value])

  return (
    <div className={styles.fullWidth}>
      <ComboBox {...(fieldRenderProps as ComboBoxProps)} />
      {fieldRenderProps.visited && fieldRenderProps.validationMessage && (
        <Error>{fieldRenderProps.validationMessage}</Error>
      )}
    </div>
  )
}
