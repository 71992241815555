import type { ReactNode } from 'react'

import styles from '../../styles/auth/AuthLayout.module.scss'
import siarlLogo from '../../assets/images/siarl_logo_sFundo.svg'
import { Navigate, NavigateOptions, useLocation, useNavigate } from 'react-router'
import { AUTH_LOCALSTORAGE, PASS_GUEST, USER_GUEST } from '../../utils/constants'
import { setGeoPortalUser } from '../../services/utils'
import { login } from '../../services/auth'

type Props = {
  children: ReactNode
}

export default function AuthProvider({ children }: Props) {

  const location = useLocation();
  const navigate = useNavigate();
  
  return <>{children}</>
}
