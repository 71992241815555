import { Button, DropDownButton } from '@progress/kendo-react-buttons'
import { Field, Form, FormElement } from '@progress/kendo-react-form'
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from '@progress/kendo-react-layout'
import { useEffect, useLayoutEffect, useState } from 'react'

import styles from '../../styles/entidade/RegistarEntidade.module.scss'

import { MouseEvent as ReactMouseEvent } from 'react'
import { Dialog } from '@progress/kendo-react-dialogs'

import {
  arhs,
  concelhos,
  agEstados,
  coordenadasPoligono,
  coordenadasSelected,
  distritos,
  freguesias,
  get,
  getEntidadeSelected,
  massaagua,
  nuts1,
  nuts2,
  nuts3,
  post,
  put,
  setCoordenadasPoligono,
  setCoordenadasSelected,
  setEntidadeSelected,
  useCombos,
  handleGenericError,
  del,
  hasPermission,
  fillLocation,
  b64toBlob,
  GetUserNameTecnico,
  refreshEntidadesSearch,
} from '../../services/utils'
import { useEntidadeStore } from '../../stores/entidade'
import {
  AUTH_LOCALSTORAGE,
  DELETE_ENTIDADE,
  DELETE_GEOM_JURISDICAO_ENTIDADE,
  ENTIDADE_POINT_NAME,
  GET_ENTIDADE,
  GET_USER_BY_ID,
  IMPORT_GEOM_ENTIDADE,
  PERMISSION_ATUALIZAR_ENTIDADE,
  PERMISSION_ENTIDADES_PERFIL_COMPLETO_EDICAO,
  PERMISSION_ENTIDADES_PERFIL_COMPLETO_LEITURA,
  PERMISSION_ENTIDADES_PERFIL_TOTAL,
  PERMISSION_INSERIR_ENTIDADE,
  RELATORIO_ENTIDADE,
  RELATORIO_ENTIDADE_RDLC,
  SAVE_NEW_ENTIDADE,
  UPDATE_ENTIDADE,
  URL_AJUDA_ENTIDADES,
  VERIFICA_CODIGO,
  clearLayers,
} from '../../utils/constants'
import { AssociacaoListagem } from '../associacao/Associacao'
import { DocumentoListagem } from '../documentos/registar/documento/documento'
import { EntidadeIdentificacao } from './registar/EntidadeIdentificacao'
import ImportarGeometriaComponent from '../apoiogestao/registar/ImportarGeometriaForm'
import { DateTimePicker } from '@progress/kendo-react-dateinputs'
import { ComboBox } from '@progress/kendo-react-dropdowns'
import { Input } from '@progress/kendo-react-inputs'
import clsx from 'clsx'
import { requiredFieldValidator } from '../../validations/ocorrencia'
import ValidatedIdentificacaoInput from './fields/ValidatedIdentificacaoInput'
import SessionStorageService from '../../services/SessionStorageService'
import useClearMap from '../../hooks/useClearMap'
import html2canvas from 'html2canvas'
import ReactGA from 'react-ga4';
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais'
import { id } from 'date-fns/locale'
import { EntidadeContactos } from './registar/EntidadeContactos'
type Props = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
}


const TOTAL_TABS = 3
const TAB_IDENTIFICACAO = 0;
const TAB_CONTACTOS = 1;
const TAB_DOCUMENTOS = 2;
const TAB_ASSOCIACAO = 3;

export default function RegistarEntidade({ onCloseClick }: Props) {
  const [isMobile, setIsMobile] = useState(false);
   const getUserById = async (data: any) =>
  await get<any, any>(`${GET_USER_BY_ID}/` + data)

    const deleteEntidade = (id: number) => del(`${DELETE_ENTIDADE}/${id}`, {})

  const [selected, setSelected] = useState<number>(0)
  let formState: any

  const deleteGeomApoio = async (data: any) =>
    await post<any, any>(`${DELETE_GEOM_JURISDICAO_ENTIDADE}`, data)
  const selectedEntidadeResult: any = useEntidadeStore(
    (state) => state.selectedEntidadeResult
  )

 const getEntidadeById = async (id: any) =>
    await get<any, any>(`${GET_ENTIDADE}/` + id)

    const verificaCodigo = async (sigla: any, id: any) =>
    await get<any, any>(`${VERIFICA_CODIGO}/${sigla}/${id}`)

  const createEntidade = async (data: any) =>
    await post<any, any>(`${SAVE_NEW_ENTIDADE}`, data)
  const updateEntidade = async (data: any) =>
    await put<any, any>(`${UPDATE_ENTIDADE}`, data)
  const getEntidade = async (data: any) =>
    await get<any, any>(`${GET_ENTIDADE}/` + data)

  const relatorioEntidade = async (data: any) =>
    await post<any, any>(`${RELATORIO_ENTIDADE_RDLC}`, data)

  const [openModalDescartar, setOpenModalDescartar] = useState<any>()
  const [deleteClicked, setDeleteClicked] = useState(false);

  const [percentLeftInfo, setPercentLeftInfo] = useState("19%");
  const [percentLeftSucess, setPercentLeftSucess] = useState("22%");


   const [canUpdate, setCanUpdate] = useState(false);
   const [canInsert, setCanInsert] = useState(false);

   const [editMode, setEditMode] = useState(false);
   const [viewMode, setViewMode] = useState(false);

  useEffect(() => {
    const permissionEdit = hasPermission(PERMISSION_ENTIDADES_PERFIL_COMPLETO_EDICAO) ||
    hasPermission(PERMISSION_ENTIDADES_PERFIL_TOTAL);
    setCanUpdate(permissionEdit);
    setCanInsert(permissionEdit)
  }, [])
  
   useEffect(() => {
    setViewMode(!editMode && SessionStorageService.getItem("novaEntidade") != 'true');
  }, [])

  useEffect(() => {
    if(sessionStorage.getItem("diGeomEdited") == 'true'){
      setEditMode(true)
      setViewMode(false)
    }
  }, [])

  const cancelModalDescartar = () => {
    setOpenModalDescartar(false)
  }


  const selectEntidadeResult = useEntidadeStore(
    (state) => state.selectEntidadeResult
  )
  const selectEntidadeResultId = useEntidadeStore(
    (state) => state.selectEntidadeResultId
  )
  const [entityId, setEntityId] = useState<any>()
  const isIdentificacaoTabValid = useEntidadeStore(
    (state) => state.isIdentificacaoTabValid
  )
  const isDocumentosTabValid = useEntidadeStore(
    (state) => state.isDocumentosTabValid
  )
  const isAssociacaoTabValid = useEntidadeStore(
    (state) => state.isAssociacaoTabValid
  )

  const entidadeSelected = getEntidadeSelected()

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/RegistarEntidade" });
  }, [])

  
  useEffect(() => {
    setFreguesiasFiltred(freguesias)
    setConcelhosFiltred(concelhos)

  }, []);

  const [freguesiasFiltred, setFreguesiasFiltred] = useState<any>()
  const [concelhosFiltred, setConcelhosFiltred] = useState<any>()
  const [concelhosDisabled, setConcelhosDisabled] = useState<any>(true)
  const [freguesiasDisabled, setFreguesiasDisabled] = useState<any>(true)

  const [openImportModal, setOpenImportModal] = useState<any>()
  const [freguesiaSelected, setFreguesiaSelected] = useState<any>()
  const [distritoSelected, setDistritoSelected] = useState<any>()
  const [concelhoSelected, setConcelhoSelected] = useState<any>()
  const [nuts1Selected, setNuts1Selected] = useState<any>()
  const [nuts2Selected, setNuts2Selected] = useState<any>()
  const [nuts3Selected, setNuts3Selected] = useState<any>()
  const [massaaguaSelected, setMassaaguaSelected] = useState<any>()
  const [arhSelectected, setArhSelected] = useState<any>()
  const [nuts2Filtred, setNuts2Filtered] = useState<any>()
  const [nuts3Filtred, setNuts3Filtered] = useState<any>()
  const [percentLeftMapa, setPercentLeft] = useState("16%");

const refreshForm = async(id: any = undefined) => {
  let iEntidade = id;
  if (!iEntidade) {
    if (selectedEntidadeResult?.id) {
      iEntidade = selectedEntidadeResult?.id;
    } else {
      return;
    }
  }

  postMessage({type: "removeInfoPonto", value : "true"}, "*");

  refreshEntidadesSearch();
   const entidade = await getEntidade(iEntidade);

   const tecnico = await GetUserNameTecnico(entidade.utilizador_id);

    const locationObj: any = await fillLocation(entidade);

    const entidadeResult = {
        id: entidade.id,
        nome: entidade.nome,
        tecnicoRegisto: tecnico,
        sigla: entidade.codigo,
        morada: entidade.morada,
        localidade: entidade.localidade,
        tipo_entidade_id: entidade.tipo_entidade_id,
        tipoEntidade: entidade.tipoEntidade,
        email: entidade.oc_focal_point_email,
        url: entidade.url,
        jurisdicao: entidade.oc_notify_email_new_issue,
        telefone: entidade.telefone,
        codigoPostal: entidade.codigo_postal,
        tipoAcesso: entidade.tipoAcesso,
        tipo_acesso_id: entidade.tipo_acesso_id,
        dataHoraRegisto: entidade.data_registo ? new Date(entidade.data_registo) : undefined,
        lstFreguesiasJurisdicao: entidade?.lstFreguesiasJurisdicao ?? undefined,
        lstConcelhosJurisdicao: entidade?.lstConcelhosJurisdicao ?? undefined,
        lstDistritosJurisdicao: entidade?.lstDistritosJurisdicao ?? undefined,
        lstNuts1Jurisdicao: entidade?.lstNuts1Jurisdicao ?? undefined,
        lstNuts2Jurisdicao: entidade?.lstNuts2Jurisdicao ?? undefined,
        lstNuts3Jurisdicao: entidade?.lstNuts3Jurisdicao ?? undefined,
        lstMassaAguaJurisdicao : entidade?.lstMassaAguaJurisdicao ?? undefined,
        lstArhsJurisdicao: entidade?.lstArhsJurisdicao ?? undefined,
        lstCapitaniasJurisdicao: entidade?.lstCapitaniasJurisdicao ?? undefined,
        freguesia: locationObj?.freguesia ?? undefined,
        concelho: locationObj?.concelho ?? undefined,
        distrito: locationObj?.distrito ?? undefined,
        nuts1: locationObj?.nuts1 ?? undefined,
        nuts2: locationObj?.nuts2 ?? undefined,
        nuts3: locationObj?.nuts3 ?? undefined,
        diEntidadeJurisdicoes: entidade.diEntidadeJurisdicoes,
        geom_imported: entidade?.geom_imported,
      }

      selectEntidadeResult(entidadeResult);
      setEntidadeSelected(entidadeResult);

      if (entidade?.diEntidadeJurisdicoes && entidade?.diEntidadeJurisdicoes.length > 0) {
      const idToSend = [entidade?.diEntidadeJurisdicoes[0].id];
      const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.contentWindow?.postMessage({ type: "SiarlEntidadesApoioSearch", value: idToSend }, '*')
    }

}

   const handleCancel = ()  => {
        refreshForm();
        postMessage({ type: "form", value: "" }, "*");
        setTimeout(() => {
            postMessage({ type: "form", value: "registarEntidade" }, "*");
          }, 10)
    }

  const handleOpenModalDescartar = () => {
 if (
      SessionStorageService.getItem('novaEntidade') &&
      SessionStorageService.getItem('novaEntidade') == 'true'
    ) {
      setOpenModalDescartar(true);

      if(getPontoAtivo()){
        DesativarSelecaoPonto()
      }
    } else {
      onEditItemClick("SiarlVisualizar");
      handleCancel();

      if(getPontoAtivo()){
        DesativarSelecaoPonto()
      }

    }  }

   const importGeomEntidade = async (data: any) =>
    await post<any, any>(`${IMPORT_GEOM_ENTIDADE}`, data)

  const cancelImport = () => {
    setOpenImportModal(false)
  }

  const filterFreguesia = (e: any) => {
    if (e?.value?.codigo) {
      const freguesiaLocal = freguesias?.filter(
        (x: any) => x.concelho_cod == e.value.codigo
      )
      setFreguesiasFiltred(freguesiaLocal)
      setFreguesiasDisabled(false)
      const concelhoSelectedLocal = concelhos?.filter(
        (x) => x.codigo == e?.value?.codigo
      )
      deselectNuts()
      deselectMassaArh()
      setConcelhoSelected(concelhoSelectedLocal[0])
    } else {
      setFreguesiasDisabled(true)
      setFreguesiasFiltred(freguesias)
    }
  }

  const filterNuts2SelectNuts1 = (e: any) => {
    setNuts2Filtered([])
    if (e?.value?.codigo) {
      const nuts1SelectedLocal = nuts1?.filter(
        (x) => x.codigo == e?.value?.codigo
      )
      deselectDistConc()
      deselectMassaArh()
      setNuts1Selected(nuts1SelectedLocal[0])
      setNuts2Filtered(
        nuts2?.filter((nts: any) => nts?.nuts_i_cod === e?.value?.codigo)
      )
    }
  }

  const filterNuts3SelectNuts2 = (e: any) => {
    setNuts3Filtered([])
    if (e?.value?.codigo) {
      const nuts2SelectedLocal = nuts2?.filter(
        (x) => x.codigo == e?.value?.codigo
      )
      deselectDistConc()
      deselectMassaArh()
      setNuts2Selected(nuts2SelectedLocal[0])
      setNuts3Filtered(
        nuts3?.filter((nts: any) => nts?.nuts_ii_cod === e?.value?.codigo)
      )
    }
  }
  const selectNuts3 = (e: any) => {
    if (e?.value?.codigo) {
      const nuts3SelectedLocal = nuts3?.filter(
        (x) => x.codigo == e?.value?.codigo
      )
      deselectAll()
      setNuts3Selected(nuts3SelectedLocal[0])
    }
  }
  const freguesiaChange = (e: any) => {
    if (e?.value?.codigo) {
      const freguesiaSelectedLocal = freguesias?.filter(
        (x) => x.codigo == e?.value?.codigo
      )
      setFreguesiaSelected(freguesiaSelectedLocal[0])
      const entidadeSelected = getEntidadeSelected()
      setEntidadeSelected({
        ...entidadeSelected,
        geom_imported: 'Freguesia: ' + freguesiaSelectedLocal[0]?.nome,
      })
      selectEntidadeResult({
        ...selectedEntidadeResult,
        geom_imported: 'Freguesia: ' + freguesiaSelectedLocal[0]?.nome,
      })
    }
  }
  const filterConcelhoSelectDistrito = (e: any) => {
    if (e?.value?.codigo) {
      const concelhosLocal = concelhos?.filter(
        (x: any) => x.distrito_cod == e.value.codigo
      )
      setConcelhosFiltred(concelhosLocal)
      setConcelhosDisabled(false)
      const distritoSelectedLocal = distritos?.filter(
        (x) => x.codigo == e?.value?.codigo
      )
      deselectNuts()
      deselectMassaArh()
      setDistritoSelected(distritoSelectedLocal[0])
    } else {
      setConcelhosFiltred(concelhos)
      setConcelhosDisabled(true)
    }
  }

  const selectArh = (e: any) => {
    if (e?.value?.codigo) {
      const arhSelectedLocal = arhs.filter((x) => x.codigo == e?.value?.codigo)
      deselectAll()
      setArhSelected(arhSelectedLocal[0])
    }
  }

  const deselectAll = () => {
    deselectDistConc()
    deselectNuts()
    deselectMassaArh()
  }
  const deselectMassaArh = () => {
    setMassaaguaSelected(undefined)
    setArhSelected(undefined)
  }

  const deselectNuts = () => {
    setNuts1Selected(undefined)
    setNuts2Selected(undefined)
    setNuts3Selected(undefined)
  }

  const deselectDistConc = () => {
    setFreguesiaSelected(undefined)
    setDistritoSelected(undefined)
    setConcelhoSelected(undefined)
  }

  const selectMassaAgua = (e: any) => {
    if (e?.value?.codigo) {
      const massaAguaLocal = massaagua?.filter(
        (x) => x.codigo == e?.value?.codigo
      )
      deselectAll()
      setMassaaguaSelected(massaAguaLocal[0])
    }
  }


const importGeo = async (objToSend: any) => {
    postMessage({ type: "showLoader" }, "*");
    if (!objToSend) {
      postMessage({ type: "errorMsg", value: "Não foi possivel salvar" }, "*");
      postMessage({ type: "hideLoader" }, "*");
      return;

    }
    let entidade;
    let entidade_id;
    
    const entidadeSelected = selectedEntidadeResult ?? getEntidadeSelected();
    if (entidadeSelected && entidadeSelected?.id) {
      entidade_id = entidadeSelected?.id;
      entidade = entidadeSelected;
    } else {
      entidade = await createEntidade({});
      entidade_id = entidade?.id;
      objToSend.entidade_id = entidade_id;
      selectEntidadeResult(entidade);
      selectEntidadeResultId(entidade_id);

    }
    try {
      entidade.geom_imported = objToSend.geom_imported;
      objToSend = { ...entidade, ...objToSend, entidade_id: entidade_id }
      const result = await importGeomEntidade(objToSend)
      if (!result) {
        postMessage({ type: "errorMsg", value: "Não foi possivel salvar" }, "*");
        return;
      }

      try {
        entidade = await getEntidadeById(entidade_id);
      } catch (error) {
        handleGenericError(error, "Registo salvo mas, Não é possivel mostrar os dados neste momento")

      } finally {
        entidade = { ...entidade, ...objToSend };
        selectEntidadeResultId(entidade_id);
        selectEntidadeResult(entidade);
        setEntidadeSelected(entidade)
        refreshForm(entidade_id)
      }
      try {
        const entidadeLocal = await getEntidade(entidade_id);
        if (entidadeLocal.diEntidadeJurisdicoes && entidadeLocal.diEntidadeJurisdicoes.length > 0) {
          const iframe: any = document.getElementById('iframeGeoPortal')
          iframe.contentWindow?.postMessage(
            { type: 'SiarlEntidadesApoioSearch', value: [entidadeLocal.diEntidadeJurisdicoes[0].id] },
            '*'
          )
        }
      } catch (error) {
        handleGenericError(error, "Registo salvo mas, Não é possivel mostrar os dados neste momento")
      }

      setOpenImportModal(false)
    } catch (err) {
      handleGenericError(err)

    } finally {
      postMessage({ type: "hideLoader" }, "*");
    }

  }


  const [hasGeom, setHasGeom] = useState(false);
  
  useEffect(() => {
    console.log(freguesias);
    if(entidadeSelected?.id){
    getEntidadeById(entidadeSelected?.id).then((result) => {
      if (result && result.hasGeom !== undefined) {
        setHasGeom(result.hasGeom);
      }
    });
  }
  });

  const selectedOptionIndex = hasGeom ? 1 : 0; 


  useEffect(() => {
    addEventListener('message', (event: MessageEvent) => {
      if (event.data.type == 'CoordenadasSelected') {
        console.log(event.data.value)
        if (event.data.value) {
          const flatPointCoordinates =
            event.data.value.x + ', ' + event.data.value.y
          setCoordenadasSelected(flatPointCoordinates)
          
        }
      } else if (event.data.type == 'CoordenadasFlatSelected') {
        let polygonString = ''
        const values: any[] = event.data.value
        values?.forEach((value, index) => {
          if (index % 2 == 0) {
            polygonString += value + ' '
          } else {
            polygonString += value + ','
          }
        })
        polygonString = polygonString.substring(0, polygonString.length - 1)
        setCoordenadasPoligono(polygonString)
        console.log(polygonString)
      } else if (event.data.type == 'guardarEntidade') {
        setEntityId(event.data.value.id)
        selectEntidadeResult({ id: event.data.value.id })
        console.log(selectedEntidadeResult)
      }
       else if (event.data.type == 'desativarPonto') {
          if (getPontoAtivo()) {
              DesativarSelecaoPonto();
              setpontoAtivo(false);
          }
       }
    })
    // if (sessionStorage.getItem('entidadeId')) {
    //   setEntityId(JSON.parse(sessionStorage.getItem('entidadeId') ?? ''))
    // }
  }, [])

  const handleSelect = (e: TabStripSelectEventArguments, formRenderProps: any) => {
     if (e.selected > 0 && !selectedEntidadeResult?.id) {
      postMessage({type:"infoMsg", value: {message: "É necessário gravar primeiro."}})
      return;
    }
    if (!isFormValid()) {
      postMessage({ type: 'errorMsg', value: { message: "Verifique os campos obrigatórios." } }, '*');
      formRenderProps.onSubmit();
      return false;
    }
    setSelected(e.selected)
  }
  const [openRemoveAreaModal, setOpenRemoveAreaModal] = useState<any>()

  const selectMapAction = async (item: any) => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    if (item.key == 'removerArea') {
      setOpenRemoveAreaModal(true)    
    } 
    else if (item.key == "SiarlEditarGeometriaPonto") {
      refreshEntidadesSearch();
      postMessage(
        { type: 'infoEditGeom',  value: 
        { left: (isMobile? (Number(percentLeftMapa.split('%')[0])-5)+'%' : percentLeftMapa) ,
        message: "O ponto encontra-se em edição", geom : 'ponto'
        } },
        '*'
      )
      iframe.contentWindow?.postMessage(
        {
          type: 'SiarlEntidadesHighlightRemove',
          value: [getEntidadeSelected()?.id],
        },
        '*'
      )

      iframe.contentWindow?.postMessage({ type: 'SiarlEditarGeometria', value: getEntidadeSelected()?.id, editGpLayer: ENTIDADE_POINT_NAME }, '*')
    }
    else if (item.key == "SiarlEditarGeometriaArea") {
      refreshEntidadesSearch();
      postMessage(
        { type: 'infoEditGeom',  value: 
        { left: (isMobile? (Number(percentLeftMapa.split('%')[0])-5)+'%' : percentLeftMapa),
          message: "A área encontra-se em edição" ,geom : 'area'
        } },
        '*'
      )

      iframe.contentWindow?.postMessage(
        {
          type: 'SiarlEntidadesHighlightRemove',
          value: [getEntidadeSelected()?.id],
        },
        '*'
      )

      iframe.contentWindow?.postMessage({ type: 'SiarlEditarGeometria', value: getEntidadeSelected()?.id, editGpLayer: ENTIDADE_POINT_NAME }, '*')
    }
    else if (item.key == "SiarlGeoPortalAdicionarPonto") {
      if (!getPontoAtivo()) {
        setpontoAtivo(true);
      } else {
        setpontoAtivo(false);
      }
      
      iframe.contentWindow?.postMessage({ type: item.key, value: getEntidadeSelected()?.id, editGpLayer: ENTIDADE_POINT_NAME }, '*')
    }
    else {
      iframe.contentWindow?.postMessage({ type: item.key, value: getEntidadeSelected()?.id, editGpLayer: ENTIDADE_POINT_NAME }, '*')
    }
  }

  const removeArea = async () => {
    const iframe: any = document.getElementById('iframeGeoPortal')
      setCoordenadasPoligono(undefined)
      const entidadeSelected = getEntidadeSelected()
      iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: 'true' }, '*')

      if (!entidadeSelected?.id) {
        setOpenRemoveAreaModal(false)
        return;
      }
      await deleteGeomApoio(entidadeSelected?.id)
      
      iframe.contentWindow?.postMessage(
        { type: 'SiarlEntidadesSearch', value: [entidadeSelected?.id], nocenter: true },
        '*'
      )
      iframe.contentWindow?.postMessage({ type: "SiarlEntidadesApoioSearch", value: [] }, '*')

      setOpenRemoveAreaModal(false)
      postMessage({ type: 'sucessMsg' , value :{left : (isMobile? (Number(percentLeftSucess.split('%')[0])-40)+'%' : percentLeftSucess)
        } },'*')

  }

  const setpontoAtivo = (value: any) => {
    SessionStorageService.setItem("pontoAtivo", value)
  }
  const getPontoAtivo = () => {
    return SessionStorageService.getItem("pontoAtivo");
  }

  const DesativarSelecaoPonto = () => {
    setpontoAtivo(false)
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: 'SiarlGeoPortalAdicionarPonto', value: 'false' }, '*')
  }

  const ImportGeom = async (item: any) => {
    if (item.key == 'ImportGeom') {
      setOpenImportModal(true)
    }
  }

  const DisableselectMapAction = (item: any) => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: item.key, value: 'false' }, '*')
  }

  const voltar = () => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
       {
         type: 'SiarlCoordenadaBoxClose',
         value: 'true',
       },
       '*'
    )

    if(getPontoAtivo()){
      DesativarSelecaoPonto()
    }
    sessionStorage.setItem('diGeomEdited', 'false')

    clearEntidadeMap();
    postMessage({ type: 'form', value: 'pesquisarEntidades' }, '*')
  }
  const [clearMap] = useClearMap();
  const clearEntidadeMap = () => {
    clearMap(clearLayers["CLEAR_LAYERS_ENTIDADES"]);
    SessionStorageService.removeItem("entidade");
    postMessage({ type: 'resultadoEntidadePesquisaChange' }, '*')
    selectEntidadeResult(undefined)
  }
  
    const [estadoFalse, setEstado] = useState(false)
    const [estadoTrue, setEstadoTrue] = useState(true)

  

  const handleDescartar = async (e: any) => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
       {
         type: 'SiarlCoordenadaBoxClose',
         value: 'true',
       },
       '*'
     )
     iframe.contentWindow?.postMessage(
      {
        type: 'SiarlRemoveSelection',
        value: 'true',
      },
      '*'
    )
  setOpenModalDescartar(false)
  setDeleteClicked(false)
   const entidadeSelected = getEntidadeSelected()

    const dataToSend : any = {
      id: entidadeSelected?.id ?? undefined,
      status: estadoFalse
    }

      let EntidadeSaved: any
    if (entidadeSelected?.id) {
         postMessage({ type: 'showLoader' }, '*')
          EntidadeSaved = await updateEntidade(dataToSend)
          selectEntidadeResult(EntidadeSaved)
          selectEntidadeResult({
            ...selectedEntidadeResult,
            ...EntidadeSaved,})
          postMessage({ type: 'hideLoader' }, '*')

          }

    SessionStorageService.removeItem("firstCallEntidade")
    postMessage({ type: 'form', value: 'pesquisarEntidades' }, '*')
        

}
  const sedeObrigatoriaMsg = "É necessário informar a morada da sede";
  const handleSubmit = async (e: any, isSubmitAction?: boolean) => {
    if (!isFormValid() || !e.values.nome.trim()) {
      return
    }
    const entidadeSelected = getEntidadeSelected()
    if (!entidadeSelected.id) {
      postMessage({type: "infoMsg", value: {message: sedeObrigatoriaMsg , left: percentLeftInfo}}, "*");
      return;
    }
    const ent = await getEntidadeById(entidadeSelected.id);
    if (!ent.hasGeom) {
      postMessage({type: "infoMsg", value: {message: sedeObrigatoriaMsg}}, "*");
      return;
    }

    const validaSigla = await verificaCodigo(e.values.sigla, entidadeSelected?.id )

    if (validaSigla == false) {
      postMessage({ type: 'errorMsg', value: { message: "A sigla escolhida já existe" } }, '*')
      return;
    }

    const userId = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '')
      ?.user?.id
    
    console.log(e)

    const lstContactos = SessionStorageService.getItem("dataContactos");

    const dataToSend: any = {
      id: entidadeSelected?.id ?? undefined,
      nome: e.values?.nome,
      codigo: e.values?.sigla,
      morada: e.values?.morada,
      codigo_postal: e.values?.codigoPostal,
      localidade: e.values?.localidade,
      tipo_entidade_id: e.values?.tipoEntidade
        ? e.values?.tipoEntidade?.id
        : undefined,
      oc_focal_point_email: e.values?.email,
      oc_notify_email_new_issue: sessionStorage.getItem('jurisdicaoEntidade')
        ? JSON.parse(sessionStorage.getItem('jurisdicaoEntidade') ?? 'false')
        : undefined,
      url: e.values?.url,
      telefone: e.values?.telefone,
      tipo_acesso_id: e.values?.tipoAcesso
        ? e.values?.tipoAcesso.id
        : undefined,
      data_registo: e.values?.dataHoraRegisto,
      flatCoordinatesJurisdicao: coordenadasPoligono,
      flatCoordinates: coordenadasSelected,
      freguesia_cod: entidadeSelected?.freguesia?.codigo ?? undefined,
      nuts3_cod: entidadeSelected?.nuts3?.codigo ?? undefined,
      geom_imported: getEntidadeSelected()?.geom_imported,
      status: true,
      utilizador_id: userId,
      lstContatos: lstContactos
    }

    try {
      let EntidadeSaved: any
      const entidadeSelected = getEntidadeSelected()
        if (entidadeSelected?.id) {
          EntidadeSaved = await updateEntidade(dataToSend)
          selectEntidadeResult(EntidadeSaved)
          selectEntidadeResult({
            ...selectedEntidadeResult,
            ...EntidadeSaved,
          })
          setEntidadeSelected(EntidadeSaved)
        } else {
          EntidadeSaved = await createEntidade(dataToSend)
          setEntidadeSelected({...getEntidadeSelected(), 
          EntidadeSaved})
          selectEntidadeResult({
            ...selectedEntidadeResult,
            ...EntidadeSaved,
          })
        }
        refreshForm(EntidadeSaved.id);
      setCoordenadasPoligono(undefined)
      setCoordenadasSelected(undefined)
          
    if(getPontoAtivo()){
      DesativarSelecaoPonto()
    }

     
     

      if(EntidadeSaved) {
        postMessage({ type: 'sucessMsg' }, '*')
      }

    } catch (e) {
      console.log(e)
      handleGenericError(e);
    }
    postMessage({ type: 'hideLoader' }, '*')
  }

  const [selectedOption, setSelectedOption] = useState(null)

  const handleOptionSelect = (option: any) => {
    setSelectedOption(option)
    // You can perform any additional actions when an option is selected here
  }

  const mapActionsSelectItemChange = (item: any) => {
    selectMapAction(item.value)
  }

  const itemRender = (props: { item: any; itemIndex: number }) => {
    const isTitleItem = props.item.key === 'titulo'
    if (isTitleItem) {
      // Item de título personalizado
      return (
        <div className={styles.DropDownButtonTitulo}>
          <span>{props.item.text}</span>
        </div>
      )
    } else {
      // Demais itens
      return (
        <div className={styles.DropDownButtonItem}>
          <span>{props.item.text}</span>
        </div>
      )
    }
  }

  const onImportGeomClick = (event: any) => {
    ImportGeom(event.item)
  }

 const onItemClick = (event: any) => {
  if(event?.item?.key == "ImportGeom"){
    ImportGeom(event.item)
   } else {
    selectMapAction(event.item)
   }
  }

  const importarGeometria = [
    {
      key: 'ImportGeom',
      text: 'Importar Geom',
    },
  ]

   const onEditItemClick= (event:any) => {
    if (event == "SiarlEditar" || event?.item?.key == "SiarlEditar") {
      setEditMode(true);
      setViewMode(false);
      return;
    }
    if (event == "SiarlVisualizar") {
      setEditMode(false);
      setViewMode(true);
    }
    if (event?.item?.key == "SiarlApagar") {
      setDeleteClicked(true);
      setOpenModalDescartar(true);
    }
  }
  useEffect(() => {
    if (viewMode) {
      SessionStorageService.removeItem("novaEntidade");
    }
  })

  const optionsEditar = [
    {
      key: 'SiarlEditar',
      text: 'Editar',
    },
    {
      key: 'SiarlApagar',
      text: 'Apagar',
    },
    
  ]

  const optionsArea = [
    {
      key: 'SiarlGeoPortalAdicionarArea',
      text: 'Desenhar Área',
    },
    {
      key: 'SiarlEditarGeometriaArea',
      text: 'Editar Área',
    },
    {
      key: 'removerArea',
      text: 'Remover Área',
    },
    {
      key: 'ImportGeom',
      text: 'Importar Geom',
    },
  ]

  const onItemClickImprimir = async (event: any) => {
    
    let mapImage = ''

    if (!window.location.href.includes('localhost')) {
      const mapIframe = document.querySelector(
        '#iframeGeoPortal'
      ) as HTMLIFrameElement

      const mapIframeInnerContent =
        mapIframe.contentWindow?.document.querySelector(
          'div.ol-viewport'
        ) as HTMLElement;

      const mapBussula =  mapIframe.contentWindow?.document.querySelector(
        'button.fixedNorthArrowButton'
      ) as HTMLElement;

      mapIframeInnerContent.appendChild(mapBussula);
      
      const generatedImage = await html2canvas(mapIframeInnerContent);
      mapImage = generatedImage.toDataURL()
    }

    console.log(getEntidadeSelected());

    try {
      postMessage({ type: 'showLoader' }, '*')

      const documentoBase64 = await relatorioEntidade({
        id: getEntidadeSelected()?.id,
        dados: {
          //Registo
          'REGISTO' : '',
          'Técnico:': getEntidadeSelected()?.tecnicoRegisto ?? '',

          //Identificacao
          'IDENTIFICAÇÃO' : '',
          'Nome:': getEntidadeSelected()?.nome ?? '',
          'Sigla:': getEntidadeSelected()?.sigla ?? '',
          'Url:': getEntidadeSelected()?.url ?? '',
          'Email:': getEntidadeSelected()?.email ?? '',
          'Telefone:': getEntidadeSelected()?.telefone ?? '',

          //Localizacao sede
          'LOCALIZAÇÃO' : '',
          'Nuts1:' : getEntidadeSelected()?.nuts1?.nome ?? '',
          'Nuts2:': getEntidadeSelected()?.nuts2?.nome ?? '',
          'Nuts3:' : getEntidadeSelected()?.nuts3?.nome  ?? '',
          'Concelho:' : getEntidadeSelected()?.concelho?.nome ?? '',
          'Distrito:' : getEntidadeSelected()?.distrito?.nome ?? '',
          'Freguesia:' : getEntidadeSelected()?.freguesia?.nome ?? '',
          'Morada:' : getEntidadeSelected()?.morada ?? '',
          'Código postal:': getEntidadeSelected()?.codigoPostal ?? '',
          'Localidade:': getEntidadeSelected()?.localidade ?? '',
         
          //Entidade
          'ENTIDADE': '',
          'Tipo de entidade:' : getEntidadeSelected()?.tipoEntidade?.designacao ?? '',
          'Tipo de acesso:' : getEntidadeSelected()?.tipoAcesso?.designacao ?? ''
          
        },       
        img_mapa: mapImage?.replace("data:image/png;base64,", ""),
      });

      if (documentoBase64) {
        const blob = b64toBlob(documentoBase64, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
        postMessage({ type: 'hideLoader' }, '*')
      }
    } catch (error) {
      postMessage({ type: 'errorMsg', value: { message: "Não foi possivel efetuar esta operação" } }, '*')
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }

  }

  const optionsImprimir = [

    {
      key: 'imprimirFichas',
      text: 'Fichas',
    },
    {
      key: 'imprimirResultados',
      text: 'Resultados',
    },
  ]

  const onItemClickExportar = async (event: any) => {
    let mapImage = ''

    if (!window.location.href.includes('localhost')) {
      const mapIframe = document.querySelector(
        '#iframeGeoPortal'
      ) as HTMLIFrameElement

      const mapIframeInnerContent =
        mapIframe.contentWindow?.document.querySelector(
          'div.ol-viewport'
        ) as HTMLElement;

      const mapBussula =  mapIframe.contentWindow?.document.querySelector(
        'button.fixedNorthArrowButton'
      ) as HTMLElement;

      mapIframeInnerContent.appendChild(mapBussula);
      
      const generatedImage = await html2canvas(mapIframeInnerContent);
      mapImage = generatedImage.toDataURL()
    }

    console.log(getEntidadeSelected());

    try {
      postMessage({ type: 'showLoader' }, '*')

      const documentoBase64 = await relatorioEntidade({
        id: getEntidadeSelected()?.id,
        dados: {
          //Registo
          'REGISTO' : '',
          'Técnico:': getEntidadeSelected()?.tecnicoRegisto ?? '',

          //Identificacao
          'IDENTIFICAÇÃO' : '',
          'Nome:': getEntidadeSelected()?.nome ?? '',
          'Sigla:': getEntidadeSelected()?.sigla ?? '',
          'Url:': getEntidadeSelected()?.url ?? '',
          'Email:': getEntidadeSelected()?.email ?? '',
          'Telefone:': getEntidadeSelected()?.telefone ?? '',

          //Localizacao sede
          'LOCALIZAÇÃO' : '',
          'Nuts1:' : getEntidadeSelected()?.nuts1?.nome ?? '',
          'Nuts2:': getEntidadeSelected()?.nuts2?.nome ?? '',
          'Nuts3:' : getEntidadeSelected()?.nuts3?.nome  ?? '',
          'Concelho:' : getEntidadeSelected()?.concelho?.nome ?? '',
          'Distrito:' : getEntidadeSelected()?.distrito?.nome ?? '',
          'Freguesia:' : getEntidadeSelected()?.freguesia?.nome ?? '',
          'Morada:' : getEntidadeSelected()?.morada ?? '',
          'Código postal:': getEntidadeSelected()?.codigoPostal ?? '',
          'Localidade:': getEntidadeSelected()?.localidade ?? '',
         
          //Entidade
          'ENTIDADE': '',
          'Tipo de Entidade:' : getEntidadeSelected()?.tipoEntidade?.designacao ?? '',
          'Tipo de Acesso:' : getEntidadeSelected()?.tipoAcesso?.designacao ?? ''
          
        },       
        img_mapa: mapImage?.replace("data:image/png;base64,", ""),
      });

      if (documentoBase64) {
        const link = document.createElement('a')
        link.href = 'data:application/octet-stream;base64,' + documentoBase64
        link.download = 'reporte_entidade.pdf'
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        postMessage({ type: 'hideLoader' }, '*')
      }
    } catch (error) {
      postMessage({ type: 'errorMsg', value: { message: "Não foi possivel efetuar esta operação" } }, '*')
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }

    
  }

  const optionsExportar = [
    {
      key: 'exportarFichas',
      text: 'Fichas',
    },
    {
      key: 'exportarResultados',
      text: 'Resultados',
    },
  ]



  const optionsPonto = [
    {
      key: 'SiarlGeoPortalAdicionarPonto',
      text: 'Adicionar Ponto',
    },
    {
      key: 'SiarlEditarGeometriaPonto',
      text: 'Editar Ponto',
    },
  ]

  
  const isFormValid = () => {
    return (selected === TAB_IDENTIFICACAO && isIdentificacaoTabValid) ||
    (selected === TAB_DOCUMENTOS && isDocumentosTabValid) ||
    (selected === TAB_ASSOCIACAO && isAssociacaoTabValid) ||
    (selected === TAB_CONTACTOS);
  }
    useLayoutEffect(() => {

      const handleResize = () => {
  
        if (window.innerWidth <= telaMobile) {
          
          setIsMobile(true);
          ocultaFloatingMenu_e_Mapa();
  
        }else{
  
          setIsMobile(false);
          mostraFloatingMenu_e_Mapa();
  
        }
  
      };
    
      window.addEventListener('resize', handleResize);
    
      handleResize();
    
      return () => {
        window.removeEventListener('resize', handleResize);
      };
  
    }, []);  
  
    const onItemClickMais = (event: any) => {
  
      elementoClickMais(event);

      onEditItemClick(event);
      onImportGeomClick(event);
      onItemClick(event);
      
      if(optionsExportar.some(option => option.key === event.item.key)){
        onItemClickExportar(event);
      }
  
    };
  
    const optionsMais = [
      {
        key: 'titulo',
        text: 'Registo',
      },
      ...optionsEditar.map(option => ({
        ...option,
        className: 'icon-siarl icon-editar btnIconRegistar',
      })),
      {
        key: 'titulo',
        text: 'Geometria',
      },
      ...importarGeometria.map(option => ({
        ...option,
        className: 'icon-siarl icon-registar btnIconRegistar',
      })),      
      {
        key: 'titulo',
        text: 'Área',
      },
      ...optionsArea.map(option => ({
        ...option,
        className: 'icon-siarl icon-area btnIconRegistar',
      })),
      {
        key: 'titulo',
        text: 'Ponto',
      },
      ...optionsPonto.map(option => ({
        ...option,
        className: 'icon-siarl icon-pin btnIconRegistar',
      })),
      {
        key: 'titulo',
        text: 'Imprimir',
      },
      {
        key: 'imprimirFicha',
        text: 'Fichas',
        className: 'icon-siarl icon-imprimir btnIconRegistar'
      },
      {
        key: 'titulo',
        text: 'Exportar',
      },
      ...optionsExportar.map(option => ({
        ...option,
        className: 'icon-siarl icon-exportar btnIconRegistar',
      })),
    ]
  
    const canSeeEntidadesAllPermissions = hasPermission(PERMISSION_ENTIDADES_PERFIL_COMPLETO_EDICAO) || hasPermission(PERMISSION_ENTIDADES_PERFIL_TOTAL) || hasPermission(PERMISSION_ENTIDADES_PERFIL_COMPLETO_LEITURA);

  const openAjuda = () => {
    window.open(URL_AJUDA_ENTIDADES, "_blank");
  }

  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <div>
          <div className={styles.nomeDoModulo}>Entidades</div>
          <div className={styles.tituloDaPagina}>
           {!selectedEntidadeResult || !selectedEntidadeResult?.id ? 'Nova entidade' : (editMode ? 'Editar entidade' : 'Entidade')}

          </div>
        </div>

        <div>

          {!isMobile && canUpdate && (viewMode || editMode) && (SessionStorageService.getItem("novaEntidade") != 'true') ? 
          <DropDownButton
            items={optionsEditar}
            className={styles.btnEditar2}
            iconClass="icon-siarl icon-editar"
            onItemClick={onEditItemClick}
            fillMode={'flat'}
            itemRender={itemRender}
            title="Editar"
          ></DropDownButton>
          : ''}
          
          {!isMobile &&
          <DropDownButton
            items={optionsArea}
            className={styles.btnEditar2}
            title='Geometria - Área'
            iconClass="icon-siarl icon-area"
            onItemClick={onItemClick}
            fillMode={'flat'}
            itemRender={itemRender}
            disabled={viewMode}
          ></DropDownButton>
          }

          {!isMobile &&
          <DropDownButton
            items={[optionsPonto[selectedOptionIndex]]}            
            className={styles.btnEditar2}
            title='Gemoetria - Ponto'
            iconClass="icon-siarl icon-pin"
            onItemClick={onItemClick}
            fillMode={'flat'}
            itemRender={itemRender}
            disabled={viewMode}

          ></DropDownButton>
          }

          <button className={styles.btnEditar2} onClick={onItemClickImprimir}
          style={{ display: isMobile ? 'none' : '' }} id='imprimirFicha' title='Imprimir'>
            <span className="icon-siarl icon-imprimir"></span>
          </button>

          <button className={styles.btnImprimir} title='Exportar' onClick={onItemClickExportar}
           style={{ display: isMobile ? 'none' : '' }} id='exportarFicha'>
            <span className="icon-siarl icon-exportar"></span>
          </button>

{isMobile &&
            <DropDownButton
              items={optionsMais}
              className={styles.btnMais}
              iconClass="icon-siarl icon-mais"
              onItemClick={onItemClickMais}
              fillMode={'flat'}
              itemRender={itemRenderMais}
              title="Mais Opções"
            />
          }

          <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
            <span className="icon-siarl icon-ajuda"></span>
          </button>

          <button className={styles.btnFechar} onClick={voltar} title="Fechar">
            <span className="icon-siarl icon-fechar"></span>
          </button>
        </div>
      </div>

      {openImportModal ?
        <ImportarGeometriaComponent onSubmit={importGeo} cancelImport={cancelImport} /> : <></>
      }

{openRemoveAreaModal ? (
               <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={() => setOpenRemoveAreaModal(false)}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                    <span className="textDescartar">Remover área </span>
                    <br></br>
                    <span>{'Tem a certeza que pretende remover a área selecionada?'}</span>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={() => setOpenRemoveAreaModal(false)}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={removeArea}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}


      {openModalDescartar ? (
          <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={cancelModalDescartar}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                  <fieldset>
                    <span className="textDescartar">{deleteClicked ? 'Apagar' : 'Cancelar'} registo  </span>
                    <br></br>
                    <span>{deleteClicked ? 'Ao apagar este registo deixará de ter acesso, sendo necessária uma intervenção rigorosa para realizar a sua recuperação.' : 'Ao cancelar este registo irá perder os dados inseridos até ao momento.'}</span>
                      <br></br>
                    <span>{deleteClicked ? 'Tem a certeza que pretende apagar?' : 'Tem a certeza que pretende cancelar?'}</span>
                  </fieldset>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={cancelModalDescartar}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={handleDescartar}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}
      {canSeeEntidadesAllPermissions ?
        <Form
          initialValues={
            {
              ...getEntidadeSelected(),
              dataHoraRegisto: selectedEntidadeResult?.dataHoraRegisto
              ? new Date(selectedEntidadeResult?.dataHoraRegisto)
              : new Date(),
              status: status[0],
            } ?? undefined
          }
          onSubmitClick={handleSubmit}
          render={(formRenderProps) => (
            <FormElement>
              <TabStrip
                selected={selected}
                onSelect={(e) => {handleSelect(e, formRenderProps)}}
                className={`${styles.innerStyleTabContent} ${viewMode ? 'tabsVisualizar' : ''}`}
                scrollable={isMobile}
              >
                <TabStripTab title="Identificação">
                  <EntidadeIdentificacao viewMode={viewMode} />
                </TabStripTab>
                <TabStripTab title="Contactos">
                  <EntidadeContactos viewMode={viewMode} />
                </TabStripTab>
                <TabStripTab title="Documento">
                  <DocumentoListagem pageName="entidade" viewMode={viewMode} />
                </TabStripTab>
              </TabStrip>

              {(editMode || SessionStorageService.getItem("novaEntidade") == 'true')
                && !viewMode ?

                <div
                  className={`${styles.footer} ${styles.justify_multiple_children}`}
                >
                  <Button type="button" className={styles.btnSeguinte} onClick={handleOpenModalDescartar} title='Cancelar'>
                    <span className="icon-siarl icon-fechar"></span>
                    <span className={styles.rotulo}>
                      {' '}&nbsp;{' '}
                      Cancelar
                    </span>
                  </Button>


                  <Button
                    className={styles.btnSubmeter}
                    onClick={(e) => {
                      formRenderProps.onSubmit(e);
                      e.preventDefault();
                    }}
                    title='Gravar'
                  >
                    <span className="icon-siarl icon-submeter btnIconRegistar"></span>
                    <span className={styles.rotulo}>
                      {' '}&nbsp;{' '}
                      Submeter
                    </span>
                  </Button>
                </div>
                : ''}

            </FormElement>
          )}
        />
        : <></>}


    </div>
  )
}

