import AuthLayout from '../components/auth/AuthLayout'
import PasswordRecoverForm from '../components/auth/PasswordRecover'
import RegisterForm from '../components/auth/RegisterForm'

export default function RecoverPassword() {
  return (
    <AuthLayout>
      <PasswordRecoverForm />
    </AuthLayout>
  )
}
