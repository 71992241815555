import { Button } from '@progress/kendo-react-buttons'
import { Dialog } from '@progress/kendo-react-dialogs'
import { ComboBox } from '@progress/kendo-react-dropdowns'
import { Field, Form, FormElement } from '@progress/kendo-react-form'
import { Input } from '@progress/kendo-react-inputs'
import { useEffect, useState } from 'react'
import {
  arhs,
  capitania,
  concelhos,
  distritos,
  fillArhsAsync,
  fillCapitaniaAsync,
  fillConcelhosAsync,
  fillDistritosAsync,
  fillFreguesiasAsync,
  fillNuts1Async,
  fillNuts2Async,
  fillNuts3Async,
  freguesias,
  get,
  getModeloSelected,
  handleGenericError,
  hasPermission,
  nuts1,
  nuts2,
  nuts3,
  post,
  setModeloSelected,
  tipoacesso,
  useCombos
} from '../../../services/utils'
import { useModeloStore } from '../../../stores/modelo'
import styles from '../../../styles/modelos/RegistarModeloForm.module.scss'
import { GET_MODELO, IMPORT_GEOM_MODELO, PERMISSION_MODELO_PERFIL_COMPLETO_LEITURA, PERMISSION_MODELO_PERFIL_TOTAL, SAVE_NEW_MODELO } from '../../../utils/constants'
import UploadImgPreview from './fields/UploadImgPreview'
import ValidatedDetalheComboBox from './fields/ValidatedDetalheComboBox'
import { requiredValidator } from './fields/validators'
import { filterBy } from '@progress/kendo-data-query'
import ImagePreviewV2 from '../../viewer/ImagePreviewV2'
import { requiredFieldValidator } from '../../../validations/ocorrencia'

type IProps = {
  formRenderProps?: any,
  viewMode: boolean
}
export function ModeloDetalhe({ formRenderProps, viewMode }: IProps) {
  const [freguesiasFiltred, setFreguesiasFiltred] = useState<any>()
  const [concelhosFiltred, setConcelhosFiltred] = useState<any>()
  const [concelhosDisabled, setConcelhosDisabled] = useState<any>(true)
  const [freguesiasDisabled, setFreguesiasDisabled] = useState<any>(true)
  const [openImportModal, setOpenImportModal] = useState<any>()
  const [freguesiaSelected, setFreguesiaSelected] = useState<any>()
  const [distritoSelected, setDistritoSelected] = useState<any>()
  const [concelhoSelected, setConcelhoSelected] = useState<any>()
  const [nuts1Selected, setNuts1Selected] = useState<any>()
  const [nuts2Selected, setNuts2Selected] = useState<any>()
  const [nuts3Selected, setNuts3Selected] = useState<any>()
  const [massaaguaSelected, setMassaaguaSelected] = useState<any>()
  const [arhSelectected, setArhSelected] = useState<any>()
  const [nuts2Filtred, setNuts2Filtered] = useState<any>()
  const [nuts3Filtred, setNuts3Filtered] = useState<any>()
  const [geomImported, setGeomImported] = useState<any>()
  const [urlDoDocumento, setUrlDoDocumento] = useState("");

  type combosKeys = 'tipoacesso';

  const [combos, setCombos] = useState<any>({
    tipoacesso,

  })
  const setDataOption: Record<combosKeys, { setFn: any, data: any[] }> = {
    tipoacesso: {
      setFn: setCombos,
      data: tipoacesso
    }
  };

  const filterCombo_Multiselect = (e: any, tipoCombo: combosKeys) => {
    const { setFn, data } = setDataOption[tipoCombo];
    setFn({
      ...combos,
      [tipoCombo]: filterBy(data?.slice() ?? [], e.filter)
    }
    );
  }


  useEffect(() => {

    if (!nuts1) {
      fillNuts1Async();
    }
    if (!nuts2) {
      fillNuts2Async();
    }
    if (!nuts3) {
      fillNuts3Async();
    }
    if (!distritos) {
      fillDistritosAsync();
    }
    if (!concelhos) {
      fillConcelhosAsync();
    }
    if (!freguesias) {
      fillFreguesiasAsync();
    }
    if (!arhs) {
      fillArhsAsync();
    }
    if (!capitania) {
      fillCapitaniaAsync();
    }

    setUrlDoDocumento(getModeloSelected()?.foto);

  }, []);

  const selectModeloResult = useModeloStore((state) => state.selectModeloResult)
  const selectModeloResultId = useModeloStore((state) => state.selectModeloResultId)
  const selectedModeloResult: any = useModeloStore(
    (state) => state.selectedModeloResult
  )
  const saveModelo = async (data: any) =>
    await post<any, any>(`${SAVE_NEW_MODELO}`, data)

  const importGeomModelo = async (data: any) =>
    await post<any, any>(`${IMPORT_GEOM_MODELO}`, data)

  const getModelo = async (data: any) =>
    await get<any, any>(`${GET_MODELO}/` + data)

  const filterConcelhoSelectDistrito = (e: any) => {
    if (e?.value?.codigo) {
      const concelhosLocal = concelhos.filter(
        (x: any) => x.distrito_cod == e.value.codigo
      )
      setConcelhosFiltred(concelhosLocal)
      setConcelhosDisabled(false)
      const distritoSelectedLocal = distritos.filter(
        (x) => x.codigo == e?.value?.codigo
      )
      deselectNuts()
      deselectMassaArh()
      setConcelhoSelected(distritoSelectedLocal[0])
    } else {
      setConcelhosFiltred(concelhos)
      setConcelhosDisabled(true)
    }
  }

  const filterFreguesia = (e: any) => {
    if (e?.value?.codigo) {
      const freguesiaLocal = freguesias.filter(
        (x: any) => x.concelho_cod == e.value.codigo
      )
      setFreguesiasFiltred(freguesiaLocal)
      setFreguesiasDisabled(false)
      const concelhoSelectedLocal = concelhos.filter(
        (x) => x.codigo == e?.value?.codigo
      )
      deselectNuts()
      deselectMassaArh()
      setConcelhoSelected(concelhoSelectedLocal[0])
    } else {
      setFreguesiasDisabled(true)
      setFreguesiasFiltred(freguesias)
    }
  }

  const freguesiaChange = (e: any) => {
    if (e?.value?.codigo) {
      const freguesiaSelectedLocal = freguesias.filter(
        (x) => x.codigo == e?.value?.codigo
      )
      setFreguesiaSelected(freguesiaSelectedLocal[0])
      const modeloSelected = getModeloSelected()
      setModeloSelected({
        ...modeloSelected,
        geom_imported: 'Freguesia: ' + freguesiaSelectedLocal[0]?.nome,
      })
    }
  }

  const filterNuts2SelectNuts1 = (e: any) => {
    setNuts2Filtered([])
    if (e?.value?.codigo) {
      const nuts1SelectedLocal = nuts1.filter(
        (x) => x.codigo == e?.value?.codigo
      )
      deselectDistConc()
      deselectMassaArh()
      setNuts1Selected(nuts1SelectedLocal[0])
      setNuts2Filtered(
        nuts2?.filter((nts: any) => nts?.nuts_i_cod === e?.value?.codigo)
      )
    }
  }

  const filterNuts3SelectNuts2 = (e: any) => {
    setNuts3Filtered([])
    if (e?.value?.codigo) {
      const nuts2SelectedLocal = nuts2.filter(
        (x) => x.codigo == e?.value?.codigo
      )
      deselectDistConc()
      deselectMassaArh()
      setNuts2Selected(nuts2SelectedLocal[0])
      setNuts3Filtered(
        nuts3?.filter((nts: any) => nts?.nuts_ii_cod === e?.value?.codigo)
      )
    }
  }

  const cancelImport = () => {
    setOpenImportModal(false)
  }

  const selectArh = (e: any) => {
    if (e?.value?.codigo) {
      const arhSelectedLocal = arhs.filter((x) => x.codigo == e?.value?.codigo)
      deselectAll()
      setArhSelected(arhSelectedLocal[0])
    }
  }

  const selectNuts3 = (e: any) => {
    if (e?.value?.codigo) {
      const nuts3SelectedLocal = nuts3.filter(
        (x) => x.codigo == e?.value?.codigo
      )
      deselectAll()
      setNuts3Selected(nuts3SelectedLocal[0])
    }
  }

  const deselectAll = () => {
    deselectDistConc()
    deselectNuts()
    deselectMassaArh()
  }

  const deselectMassaArh = () => {
    setMassaaguaSelected(undefined)
    setArhSelected(undefined)
  }

  const deselectNuts = () => {
    setNuts1Selected(undefined)
    setNuts2Selected(undefined)
    setNuts3Selected(undefined)
  }

  const deselectDistConc = () => {
    setFreguesiaSelected(undefined)
    setDistritoSelected(undefined)
    setConcelhoSelected(undefined)
  }

  const importGeom = async (obj: any) => {
    const modeloSelected: any = getModeloSelected()
    let modeloId
    if (modeloSelected?.id) {
      modeloId = modeloSelected?.id
    } else {
      const modeloInserted = await saveModelo({})
      modeloId = modeloInserted.id
      selectModeloResult(modeloInserted)
      setModeloSelected(modeloInserted)
    }

    let dataToSend
    if (freguesiaSelected) {
      dataToSend = {
        modelo_id: modeloId,
        freguesia_cod: obj?.freguesiaImport?.codigo,
        geom_imported: 'Freguesia: ' + freguesiaSelected?.nome,
      }
    } else if (concelhoSelected) {
      dataToSend = {
        modelo_id: modeloId,
        concelho_cod: concelhoSelected?.codigo,
        geom_imported: 'Concelho: ' + concelhoSelected?.nome,
      }
    } else if (distritoSelected) {
      dataToSend = {
        modelo_id: modeloId,
        distrito_cod: distritoSelected?.codigo,
        geom_imported: 'Distrito: ' + distritoSelected?.nome,
      }
    } else if (nuts3Selected) {
      dataToSend = {
        modelo_id: modeloId,
        nuts3_cod: nuts3Selected?.codigo,
        geom_imported: 'Nuts 3: ' + nuts3Selected?.nome,
      }
    } else if (nuts2Selected) {
      dataToSend = {
        modelo_id: modeloId,
        nuts2_cod: nuts2Selected?.codigo,
        geom_imported: 'Nuts 2: ' + nuts2Selected?.nome,
      }
    } else if (nuts1Selected) {
      dataToSend = {
        modelo_id: modeloId,
        nuts1_cod: nuts1Selected?.codigo,
        geom_imported: 'Nuts 1: ' + nuts1Selected?.nome,
      }
    } else if (massaaguaSelected) {
      dataToSend = {
        modelo_id: modeloId,
        massa_agua_cod: massaaguaSelected?.codigo,
        geom_imported: 'Massa Agua: ' + massaaguaSelected?.nome,
      }
    } else if (arhSelectected) {
      dataToSend = {
        modelo_id: modeloId,
        arh_cod: arhSelectected?.codigo,
        geom_imported: 'ARH: ' + arhSelectected?.nome,
      }
    }
    setModeloSelected({
      ...modeloSelected,
      geom_imported: dataToSend?.geom_imported,
    })
    console.log(dataToSend)
    postMessage({ type: "showLoader" }, "*");
    const result = await importGeomModelo(dataToSend)
    if (result) {
      postMessage(
        { type: 'informationMsg', value: 'Importado com sucesso.' },
        '*'
      )
      const modelo = await getModelo(modeloId);
      selectModeloResult(modelo);
      selectModeloResultId(modelo?.id)
      setModeloSelected(modelo);
      setOpenImportModal(false)
      setGeomImported(true)
      postMessage({ type: "hideLoader" }, "*");
      const idToSend = [modeloSelected?.id]
      const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.contentWindow?.postMessage(
        { type: 'SiarlModelosSearch', value: idToSend },
        '*'
      )
    }
  }

  const nextPhoto = async (e: any) => {

    try {

      postMessage({ type: "showLoader" }, "*");


    } catch (error) {
      handleGenericError(error, "Não foi possivel abrir o documento!");

    } finally {

      postMessage({ type: "hideLoader" }, "*");

      e.preventDefault();
    }

  }

  const previousPhoto = async (e: any) => {

    try {

      postMessage({ type: "showLoader" }, "*");


    } catch (error) {
      handleGenericError(error, "Não foi possivel abrir o documento!")

    } finally {

      postMessage({ type: "hideLoader" }, "*");

      e.preventDefault();
    }

  }

  const abrirLinkUrl = () => {
    const url = selectedModeloResult?.url ?? '';
    if (!url) {
      postMessage({type:"errorMsg", value: {message: "Não é possível abrir o link. A URL está vazia."}})
    } else {
      window.open(url, '_blank');
    }
  };

  return (
    <>
      <div className={`${styles.form} ${styles.removeScrollDuplicado}`}>

      <span style={{ marginLeft: '08px' }}>Os campos com simbolo * (asterisco) são de preenchimento obrigatório</span>


        {urlDoDocumento && (
          <fieldset>
            <legend>
              <span className={styles.fieldsetTitulo}>Documento</span>
            </legend>

            <div className={styles.espacoVerticalFieldSet}>

              {urlDoDocumento && (
                <ImagePreviewV2
                  documentUrl={urlDoDocumento}
                  next={(e: any) => nextPhoto(e)}
                  previous={(e: any) => previousPhoto(e)}
                />

              )}

            </div>
          </fieldset>
        )}
        {!viewMode ? 
        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Imagem</span>
          </legend>

          <div className={styles.espacoVerticalFieldSet}>

            <div>
              <Field name="arquivo" disabled={viewMode} component={UploadImgPreview} />
            </div>

          </div>
        </fieldset>
          : ''}
        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Detalhes</span>
          </legend>
          <div className={styles.espacoVerticalFieldSet}>
            <div className={styles.fields}>
            {hasPermission(PERMISSION_MODELO_PERFIL_TOTAL) || hasPermission(PERMISSION_MODELO_PERFIL_COMPLETO_LEITURA)?
              <div>
                <Field
                  name="descricao"
                  label="Descrição"
                  component={Input}
                  maxLength={250}
                  disabled={viewMode}
                />
              </div> :<></>}
              <div>
                <Field
                  name="tipoAcesso"
                  label="Tipo de acesso *"
                  component={ValidatedDetalheComboBox}
                  textField={'designacao'}
                  validator={requiredFieldValidator}
                  filterable={true}
                  disabled={viewMode}
                  onFilterChange={(e: any) => { filterCombo_Multiselect(e, 'tipoacesso') }}
                  data={combos?.tipoacesso} />
              </div>
            </div>

            {hasPermission(PERMISSION_MODELO_PERFIL_TOTAL) || hasPermission(PERMISSION_MODELO_PERFIL_COMPLETO_LEITURA)? <>
              <div className={styles.espacoVerticalFieldSet}>
                <div className={styles.fieldsUrl}>
                  <div>
                    <Field name="url" label="URL" className={styles.inputUrl} disabled={viewMode} component={Input} maxLength={250} />
                  </div>
                  <Button
                    className={styles.btnOpenURL}
                    title='Abrir URL'
                    type="button"
                    onClick={abrirLinkUrl}
                  >
                    {/* <span className="icon-siarl icon-submeter"></span> */}
                    . . .
                  </Button>
                </div>
              </div>

              <div className={styles.espacoVerticalFieldSet}>
                <div className={styles.fields}>
                  <Field name="fonte" disabled={viewMode} label="Fonte" component={Input} maxLength={250} />
                </div>
              </div>
            </>:<></>}

          </div>


        </fieldset>
        {hasPermission(PERMISSION_MODELO_PERFIL_TOTAL) || hasPermission(PERMISSION_MODELO_PERFIL_COMPLETO_LEITURA)?
        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Observações</span>
          </legend>
          <div className={styles.espacoVerticalFieldSet}>
            <div className={styles.fields}>
              <Field
                name="obs"
                label="Observações"
                component={Input}
                textField={'nome'}
                disabled={viewMode}
                maxLength={528}
              />
            </div>
          </div>

        </fieldset>:<></>}
      </div>
      <></>

    </>
  )
}
