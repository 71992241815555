
import { Button } from '@progress/kendo-react-buttons'
import { DatePicker, DateTimePicker, MultiViewCalendar, MultiViewCalendarProps } from '@progress/kendo-react-dateinputs'
import { ComboBox, MultiSelect } from '@progress/kendo-react-dropdowns'

import clsx from 'clsx'
import styles from '../../styles/documentos/Documentos.module.scss'
import { AUTH_LOCALSTORAGE, NENHUM, PERMISSION_DOCUMENTOS_PERFIL_COMPLETO_EDICAO, PERMISSION_DOCUMENTOS_PERFIL_COMPLETO_LEITURA, PERMISSION_DOCUMENTOS_PERFIL_INTERMEDIO_EDICAO, PERMISSION_DOCUMENTOS_PERFIL_INTERMEDIO_LEITURA, PERMISSION_DOCUMENTOS_PERFIL_TOTAL, PESQUISAR_DOCUMENTOS_AVANCADA } from '../../utils/constants'


import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from '@progress/kendo-react-form'

import {
  MouseEvent as ReactMouseEvent,
  useCallback,
  useEffect,
  useState,
} from 'react'

import {
  arhs,
  capitania,
  concelhos,
  coordenadasPoligono,
  distritos,
  entidades,
  estados,
  fillArhsAsync,
  fillCapitaniaAsync,
  fillConcelhosAsync,
  fillDistritosAsync,
  fillEntidadesAsync,
  fillEstadoAsync,
  fillFreguesiasAsync,
  fillNuts1Async,
  fillNuts2Async,
  fillNuts3Async,
  freguesias,
  handleGenericError,
  hasPermission,
  massaagua,
  nuts1,
  nuts2,
  nuts3,
  palavraschave,
  post,
  setCoordenadasPoligono,
  tiporecurso,
  useCombos
} from '../../services/utils'

import {
  Input,
  RadioButton,
  RadioButtonChangeEvent,
  RadioGroup,
} from '@progress/kendo-react-inputs'
import SessionStorageService from '../../services/SessionStorageService'
import FieldsetCombosLocalizacao from '../localizacao/FieldsetCombosLocalizacao'
import ReactGA from 'react-ga4';

type Props = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
}

export default function PesquisarDocumentosAvancadoForm({
  onCloseClick,
}: Props) {
  const [dataDocumentoInicio, setDataDocumentoInicio] = useState<any>()
  const [dataDocumentoFim, setDataDocumentoFim] = useState<any>()
  const [freguesiasFiltred, setFreguesiasFiltred] = useState<any>()
  const [concelhosFiltred, setConcelhosFiltred] = useState<any>();
  const [rbCriteriosSelected, setRBCriteriosSelected] = useState<any>()
  const geDocumentos = async (data: any) =>
    await post<any, any>(`${PESQUISAR_DOCUMENTOS_AVANCADA}`, data)

  const [RegistoVisible, setDataRegistoVisible] = useState(true)
  const [DataPublicacaoVisible, setDataPublicacaoVisible] = useState(false)
  const [CriterioVisible, setDataCriterioVisible] = useState(false)
  const [LocalizacaoVisible, setLocalizacaoVisible] = useState(false)
  const [TipologiaVisible, setTipologiaVisible] = useState(false)
  const [IdentificacaoVisible, setDataIdentificacaoVisible] = useState(false)
  const [OutrosCriteriosVisible, setDataOutrosCriteriosVisible] =
    useState(false)
  const [concelhosDisabled, setConcelhosDisabled] = useState(true);
  const [freguesiasDisabled, setFreguesiasDisabled] = useState(true);
  const [nuts2Disabled, setNuts2Disabled] = useState(false);
  const [nuts3Disabled, setNuts3Disabled] = useState(false);
  const [distritosDisabled, setDistritosDisabled] = useState(false);
  const [minData, setMinDate] = useState<any>();


  
useEffect(() => {
    
     if(!nuts1 ){
      fillNuts1Async();
      }
     if(!nuts2) {
      fillNuts2Async();
     }
     if(!nuts3){
      fillNuts3Async();
     }
     if(!distritos){
      fillDistritosAsync();
     }
     if(!concelhos){
      fillConcelhosAsync();
     }
     if(!freguesias){
      fillFreguesiasAsync();
     }
     if(!arhs){
      fillArhsAsync();
     }
     if(!capitania){
      fillCapitaniaAsync();
     }
     if(!estados){
      fillEstadoAsync();
     }
     if(!entidades){
      fillEntidadesAsync();
     }

  }, []);

  const toggleDataRegisto = () => {
    setDataRegistoVisible(!RegistoVisible)
  }
  const toggleDataPublicacao = () => {
    setDataPublicacaoVisible(!DataPublicacaoVisible)
  }
  const toggleDataCriterio = () => {
    setDataCriterioVisible(!CriterioVisible)
  }
  const toggleDataLocalizacao = () => {
    setLocalizacaoVisible(!LocalizacaoVisible)
  }
  const toggleDataTipologia = () => {
    setTipologiaVisible(!TipologiaVisible)
  }
  const toggleDataIdentificacao = () => {
    setDataIdentificacaoVisible(!IdentificacaoVisible)
  }
  const toggleDataOutrosCriterios = () => {
    setDataOutrosCriteriosVisible(!OutrosCriteriosVisible)
  }

  const AREA_VISIVEL = 1
  const POLIGONO_SELECAO = 2

  const concelhoSelectChange = (item: any) => {
    if (!item) {
      setFreguesiasFiltred(freguesias)
      setNuts2Disabled(false);
      setNuts3Disabled(false);
      setFreguesiasDisabled(true);
      return
    }
    setNuts2Disabled(true);
    setNuts3Disabled(true);
    setFreguesiasDisabled(false);
    setFreguesiasFiltred(
      freguesias.filter((x) => x.concelho_cod == item.codigo)
    )
  }

  useEffect(() => {
    SessionStorageService.setItem("DocumentosPesquisaTipo", "Avancada");
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/PesquisarDocumentosAvancadoForm" });
    // ReactGA.pageview(window.location.pathname + "PesquisarDocumentosAvancadoForm");
  }, []);

  const distritoSelectChange = (item: any) => {
    if (!item) {
      setConcelhosFiltred([]);
      setNuts2Disabled(false);
      setNuts3Disabled(false);
      setConcelhosDisabled(true);
      return;
    }
    setConcelhosFiltred(concelhos.filter(x => x.distrito_cod == item.codigo));
    setConcelhosDisabled(false);
    setNuts2Disabled(true);
    setNuts3Disabled(true);
  }

  const changeNuts2 = (item: any) => {
    if (!item) {
      setDistritosDisabled(false);
      setConcelhosFiltred([]);
      setFreguesiasFiltred([]);
      return;
    }
    setDistritosDisabled(true);
  }

  const handleSubmit = async (e: any) => {
    try {

      const userLocal = JSON.parse(
        localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}'
      );

      postMessage({ type: 'showLoader' }, '*')
      const objToSearch = {

        // Registo
        id_documento: e.values?.IdDoc ?? null,
        id_filedoc: e.values?.Idfiledoc ?? null,
        estado_id: e.values?.estadoRegisto?.id,

        // Data de Publicação
        data_inicio: e.values?.De ?? null,
        data_fim: e.values?.Ate ?? null,

        // Critério Geográfico
        criterio_geografico: rbCriteriosSelected ?? null,

        // Localização
        nuts1_cod: e.values?.nuts1?.codigo,
        nuts2_cod: e.values?.nuts2?.codigo,
        nuts3_cod: e.values?.nuts3?.codigo,
        distrito_cod: e.values?.distrito?.codigo,
        concelho_cod: e.values?.concelho?.codigo,
        freguesia_cod: e.values?.freguesia?.dicofre,
        arh_cod: e.values?.arh?.codigo ?? undefined,
        capitania_cod: e.values?.captania?.codigo,
        massa_agua_id: e.values?.massaAgua?.id,

        // Tipologia
        tipo_recurso_id: e.values?.recurso?.id,

        // Identificação
        titulo: e.values?.titulo ?? null,
        autores: e.values?.autores ?? null,
        entidade: e.values?.entidade?.codigo,
        resumo: e.values?.resumo ?? null,

        // Palavras-Chave
        palavrasChaveIds: e.values?.palavraChave ?? null,
        
        flatCoordinates: coordenadasPoligono ?? undefined,
        id_user: userLocal?.user?.id
      }

      SessionStorageService.setItem("lastSearchDocumentosAvançada", JSON.stringify(objToSearch));
      SessionStorageService.setItem("firstCallDocumentoAvancada", true);

      const result: any = await geDocumentos(objToSearch);
      setCoordenadasPoligono(undefined)
      if (result) {
        SessionStorageService.setItem(
          'resultadoDocumentosPesquisa',
          JSON.stringify(result)
        )

        postMessage({ type: 'resultadoDocumentosPesquisaChange' }, '*')
      }
    } catch (error) {
      handleGenericError(error);
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
    
  }

  const clearMap = () => {
    try {

      setCoordenadasPoligono(undefined)
      const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.contentWindow?.postMessage({ type: "SiarlDocumentosClearLayers", value: "true" }, '*')
      iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: "true" }, '*')
      iframe.contentWindow?.postMessage({ type: "SiarlDeselectMapArea", value: "true" }, '*')

    } catch (error) {
      console.log({ error })
    }
  }

  const changerbCriterio = useCallback(
    (e: RadioButtonChangeEvent) => {
      const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlDeselectMapArea", value: 'true' }, '*')
      setRBCriteriosSelected(e.value)
      if (e.value == POLIGONO_SELECAO) {
        postMessage(
          { type: 'informationMsg', value: 'Selecione a área de pesquisa' },
          '*'
        )
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage(
          { type: 'SiarlGeoPortalAdicionarArea', value: 'true' },
          '*'
        )
      }
      if (e.value == AREA_VISIVEL) {
        postMessage(
          {
            type: 'informationMsg',
            value: 'Foi selecionada a área visível do mapa',
          },
          '*'
        )
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage(
          { type: 'SiarlGetMapArea', value: 'true' },
          '*'
        )
        iframe.contentWindow?.postMessage({ type: "SiarlSelectMapArea", value: 'true' }, '*')
      }
    },
    [setRBCriteriosSelected]
  )

  const CustomCalendar = (props: MultiViewCalendarProps) => {
    return <MultiViewCalendar {...props} views={1} />
  }

  const canSeeDocumentos =  hasPermission(PERMISSION_DOCUMENTOS_PERFIL_COMPLETO_EDICAO) || 
                            hasPermission(PERMISSION_DOCUMENTOS_PERFIL_COMPLETO_LEITURA) || 
                            hasPermission(PERMISSION_DOCUMENTOS_PERFIL_TOTAL) ||
                            hasPermission(PERMISSION_DOCUMENTOS_PERFIL_INTERMEDIO_EDICAO)
                            hasPermission(PERMISSION_DOCUMENTOS_PERFIL_INTERMEDIO_LEITURA); 

  return (
    <Form
      onSubmitClick={handleSubmit}
      render={(formRenderProps: FormRenderProps) => (
        <>
          <FormElement className={styles.form}>
            <fieldset>
              <legend>
                <button
                  type="button"
                  className={styles.itemExpandBase}
                  onClick={toggleDataRegisto}
                >
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span
                      className={
                        RegistoVisible
                          ? 'icon-siarl icon-colapsar'
                          : 'icon-siarl icon-expandir'
                      }
                    ></span>
                  </div>
                  <span className={styles.fieldsetTitulo}>Registo</span>
                </button>
              </legend>
              {RegistoVisible && (
                <div className={styles.espacoVerticalFieldSet}>
                  <div className={styles.fields}>
                    <div>
                      <Field
                        name="IdDoc"
                        label="ID Documento"
                        component={Input}
                      />
                    </div>
                    <div>
                      <Field
                        name="Idfiledoc"
                        label="Nº Registo SGD"
                        component={Input}
                      />
                    </div>
                    <div>
                      <Field
                        name="estadoDoRegisto"
                        label="Estado do Registo"
                        component={ComboBox}
                        textField={'designacao'}
                        data={estados}
                      />
                    </div>
                  </div>
                </div>
              )}
            </fieldset>
            <fieldset className={clsx(styles.dateFieldset, styles.topSpacer)}>
              <legend>
                <button
                  type="button"
                  className={styles.itemExpandBase}
                  onClick={toggleDataPublicacao}
                >
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span
                      className={
                        DataPublicacaoVisible
                          ? 'icon-siarl icon-colapsar'
                          : 'icon-siarl icon-expandir'
                      }
                    ></span>
                  </div>
                  <span className={styles.fieldsetTitulo}>
                    Data de publicação
                  </span>
                </button>
              </legend>
              {DataPublicacaoVisible && (
                <div
                  className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}
                >
                  <div>
                    <Field
                      name="De"
                      label="De"
                      value={dataDocumentoInicio}
                      onChange={(e) => {
                        setDataDocumentoInicio(e.value)
                         setMinDate(e.value);
                      }}     
                      format="dd/MM/yyyy"
                      component={DatePicker}
                      calendar={CustomCalendar}
                    />
                  </div>
                  <div>
                    <Field
                      name="Ate"
                      label="Até"
                      value={dataDocumentoFim}
                      onChange={(e) => {
                        setDataDocumentoFim(e.value)
                      }}
                      min={minData}
                      format="dd/MM/yyyy"
                      component={DatePicker}
                      calendar={CustomCalendar}
                    />
                  </div>
                </div>
              )}
            </fieldset>
            {canSeeDocumentos?
              <fieldset>
                <legend>
                  <button type='button' className={styles.itemExpandBase} onClick={toggleDataCriterio}>
                    <div className={styles.btn_Expandir_Colapsar}>
                      <span className={CriterioVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                    </div>
                    <span className={styles.fieldsetTitulo}>Critério geográfico</span>
                  </button>
                </legend>

                {CriterioVisible && (
                  <div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}>
                    <Field
                      id={'rbCriterio'}
                      name={'rbCriterio'}
                      layout={'horizontal'}
                      component={RadioGroup}
                      onChange={(e) => {
                        changerbCriterio(e)
                      }}
                      data={[
                        { label: 'Nenhum', value: NENHUM},
                        { label: 'Área visível', value: AREA_VISIVEL },
                        { label: 'Polígono de seleção', value: POLIGONO_SELECAO },
                      ]}
                      defaultValue={NENHUM} // Set the default selected value to 0 (Nenhum)
                    />
                  </div>
                )}
              </fieldset>
            :<></>
          }
            <FieldsetCombosLocalizacao styles={styles} isPesquisaAvancada={true} formRenderProps={formRenderProps}/>
            {canSeeDocumentos?
              <fieldset>
                <legend>
                  <button
                    type="button"
                    className={styles.itemExpandBase}
                    onClick={toggleDataTipologia}
                  >
                    <div className={styles.btn_Expandir_Colapsar}>
                      <span
                        className={
                          TipologiaVisible
                            ? 'icon-siarl icon-colapsar'
                            : 'icon-siarl icon-expandir'
                        }
                      ></span>
                    </div>
                    <span className={styles.fieldsetTitulo}>Tipologia</span>
                  </button>
                </legend>
                {TipologiaVisible && (
                  <div className={styles.espacoVerticalFieldSet}>
                    <div>
                      <Field
                        name="recurso"
                        label="Tipo de recurso"
                        component={ComboBox}
                        textField={'designacao'}
                        data={tiporecurso}
                      />
                    </div>
                  </div>
                )}
              </fieldset>
            :<></>}
            {canSeeDocumentos ?
              <fieldset>
                <legend>
                  <button
                    type="button"
                    className={styles.itemExpandBase}
                    onClick={toggleDataIdentificacao}
                  >
                    <div className={styles.btn_Expandir_Colapsar}>
                      <span
                        className={
                          IdentificacaoVisible
                            ? 'icon-siarl icon-colapsar'
                            : 'icon-siarl icon-expandir'
                        }
                      ></span>
                    </div>
                    <span className={styles.fieldsetTitulo}>Identificação</span>
                  </button>
                </legend>
                {IdentificacaoVisible && (
                  <div className={styles.espacoVerticalFieldSet}>
                    <div className={styles.fields}>
                      <div>
                        <Field
                          name="entidade"
                          label="Entidade"
                          component={ComboBox}
                          textField={'nome'}
                          data={entidades}
                        />
                      </div>
                      <div>
                        <Field
                          name="titulo"
                          label="Título"
                          component={Input}
                          textField={'nome'}
                        />
                      </div>
                    </div>
                    <div className={styles.fields}>
                      <div>
                        <Field
                          name="autores"
                          label="Autores"
                          component={Input}
                          textField={'nome'}
                        />
                      </div>
                      <div className={styles.fields}>
                        <div>
                          <Field
                            name="resumo"
                            label="Resumo"
                            component={Input}
                            textField={'nome'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </fieldset>
              : <></>}
            {canSeeDocumentos?
              <fieldset>
                <legend
                  className={clsx(styles.legend, styles.MiddleTitle)}
                  onClick={toggleDataOutrosCriterios}
                >
                  <button
                    type="button"
                    className={styles.itemExpandBase}
                    onClick={toggleDataOutrosCriterios}
                  >
                    <div className={styles.btn_Expandir_Colapsar}>
                      <span
                        className={
                          OutrosCriteriosVisible
                            ? 'icon-siarl icon-colapsar'
                            : 'icon-siarl icon-expandir'
                        }
                      ></span>
                    </div>
                    <span className={styles.fieldsetTitulo}>Palavras-chave</span>
                  </button>
                </legend>
                {OutrosCriteriosVisible && (
                  <div className={styles.espacoVerticalFieldSet}>
                    <div>
                      <Field
                        name="palavraChave"
                        label="Palavras-chave"
                        component={MultiSelect}
                        textField={'designacao'}
                        data={palavraschave}
                      />
                    </div>
                  </div>
                )}
              </fieldset>
            :<></>}
          </FormElement>
          <div
            className={`${styles.footer} ${styles.justify_multiple_children}`}
          >
            <Button
              onClick={() => { formRenderProps.onFormReset(); clearMap()}}
              className={styles.btnLimparPesquisa}
              title='Limpar pesquisa'
            >
              <span className="icon-siarl icon-limparcampos"></span>
              <span className={styles.rotulo}>
                {' '}&nbsp;{' '}
                Limpar pesquisa
              </span>
            </Button>

            <Button
              onClick={formRenderProps.onSubmit}
              className={styles.btnPesquisar}
              title='Pesquisar'
            >
              <span className="icon-siarl icon-pesquisar"></span>
              <span className={styles.rotulo}>
                {' '}&nbsp;{' '}
                Pesquisar
              </span> 
            </Button>
          </div>
        </>
      )}
    />
  )
}
