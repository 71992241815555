import { ComboBox } from '@progress/kendo-react-dropdowns'
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form'
import { useEffect, useState } from 'react'
import { designacaoAcao, fillCombosAuxiliares, fillInstrumentoFinanciamentoAsync, instrumentoFinanciamento, post, useCombos } from '../../services/utils'
import styles from '../../styles/intervencao/Intervencao.module.scss'
import { Grid, GridColumn } from '@progress/kendo-react-grid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faRemove } from '@fortawesome/free-solid-svg-icons'
import { Input } from '@progress/kendo-react-inputs'
import { Dialog } from '@progress/kendo-react-dialogs'
import { v4 as uuidv4 } from 'uuid';
import { GET_INTERVENCAO_ACAO_BY_ID_INTERVENCAO, PESQUISAR_ACAO_INTERVENCAO } from '../../utils/constants'
import { filterBy } from '@progress/kendo-data-query'
import { useIntervencaoStore } from '../../stores/intervencao'
import SessionStorageService from '../../services/SessionStorageService'

type IProps = {
  formRenderProps: FormRenderProps
  viewMode?: boolean
}

interface FormData {
  id?: any;
  id_acao?: any;
  designacao?: any;
  id_estado?: any;
  id_tipo_n4?: any;
  percentagem?: any;
  valor_execucao?: any;
}

export function TabAcaoIntervencao({ formRenderProps, viewMode }: IProps) {

  const [formData, setFormData] = useState<FormData>({});
  const [gridData, setGridData] = useState<FormData[]>([]);
  const [page2, setPage2] = useState({ skip: 0, take: 10 });
  const [openModalAcaoIntervencao, setOpenModalAcaoIntervencao] = useState<any>()
  const [selectedGridItemId, setSelectedGridItemId] = useState<string | null>(null);
  const [findAcao, setFindAcao] = useState<any>(false)
  const [validated, setValidated] = useState(false);
  const [idAcao, setIdAcao] = useState<any>();
  const [designacao, setDesignacao] = useState<any>();
  
  const [itemToRemove, setItemToRemove] = useState<any>();
  const [openModalDescartar, setOpenModalDescartar] = useState<any>()

  const pesquisarAcao = async (data: any) => await post<any, any>(`${PESQUISAR_ACAO_INTERVENCAO}`, data)

  const [acaoDesignacaoData, setAcaoDesignacaoData] = useState(designacaoAcao ? designacaoAcao.slice() : []);

  const getAcaoIntervencao = async (data: any) =>
    await post<any, any>(`${GET_INTERVENCAO_ACAO_BY_ID_INTERVENCAO}`, data)

  const selectedIntervencaoResult: any = useIntervencaoStore(
    (state) => state.selectedIntervencaoResult
  )

  useEffect(() => {
    SessionStorageService.setItem("dataAcaoIntervencao", gridData);
  });
  
  
  let data : any;
  useEffect(() => {
    if(selectedIntervencaoResult?.id){
    const fetchData = async () => {
       data = await getAcaoIntervencao(selectedIntervencaoResult?.id);

       const designacaoObject = {
        designacao: data?.designacao,
      };

      let updatedData : any
      if (Array.isArray(data)) {
         updatedData = data.map(item => ({
          ...item,
          designacao: { designacao: item?.designacao }
        }));
      }

      setGridData(updatedData);

    };
    fetchData();
    
  }
  
  },[data]);

  const filterChange = (event: any) => {
    setAcaoDesignacaoData(filterBy(designacaoAcao.slice(), event.filter));
  };

  const handlePageChange2 = (event: any) => {
    setPage2({ skip: event.page.skip, take: event.page.take });
};

  const handleFormChange = (event : any) => {
    
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    
  };

  const isUUID = (str: string) => {
    const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidPattern.test(str);
  };

  const openModal = (e : any) => {
    setFormData({
      id: "",
      id_acao: "",
      designacao: "",
      id_estado: "",
      id_tipo_n4: "",
      percentagem: "",
      valor_execucao: "",
      });
      setSelectedGridItemId(null)
      setIdAcao(undefined);
      setDesignacao(undefined);
    e.preventDefault();
    setOpenModalAcaoIntervencao(true)
    setFindAcao(false)
  }

  const cancelModal = () => {
    setOpenModalAcaoIntervencao(false)
  }

  const handleUpdateItem = (id : any, updatedItem : any) => {
    setGridData(prevData => prevData.map(item => item.id === id ? { ...updatedItem, id } : item));
};

  const handleAddItem = (item : any) => {
    const newItem = { ...item, id: uuidv4()};
    setGridData([...gridData, newItem]);

  };

  const handleRowClick = (dataItem : any) => {

    setSelectedGridItemId(dataItem?.id); 

  
    setFormData({
      id_acao: dataItem?.id_acao,
      designacao : dataItem?.designacao,
      id_estado : dataItem?.id_estado,
      id_tipo_n4: dataItem?.id_tipo_n4?.descricao,
      percentagem: dataItem?.percentagem,
      valor_execucao: dataItem?.valor_execucao
    });
    setOpenModalAcaoIntervencao(true);
  };

  const handleSubmitModal = (e: any) => {
    const designacaoObject = {
        designacao: formData?.designacao,
    };

    const valorPercentagem = formData?.percentagem / 100;
    let valorExecucao = SessionStorageService.getItem("inv_realizado") * valorPercentagem;
    valorExecucao = isNaN(valorExecucao) ? 0 : valorExecucao;


    let totalValorExecucao = parseFloat(SessionStorageService.getItem("total_valor_execucao")) ?? 0;

    if (isNaN(totalValorExecucao)) {
      totalValorExecucao = 0;
  }

    totalValorExecucao += valorExecucao;

    // Log para verificar o valor
    console.log("Total Valor Execução antes de armazenar:", totalValorExecucao);
    SessionStorageService.setItem("total_valor_execucao", totalValorExecucao)


    if (selectedGridItemId) {
        handleUpdateItem(selectedGridItemId, {
            ...formData,
            valor_execucao : valorExecucao

        });
    } else {
        const newId = uuidv4();
        handleAddItem({
            ...formData,
            id: newId,
            designacao: designacaoObject, 
            valor_execucao : valorExecucao
        });
        setSelectedGridItemId(newId);
    }
  
    setOpenModalAcaoIntervencao(false);
  };

  const confirmRemoveContato = (item: any) => {
    setItemToRemove(item);
    setOpenModalDescartar(true);
  }

  const removeAcaoIntervencao: any = () => {
    const item = itemToRemove;
    const dataAcaoIntervencao = gridData.filter(x => !(x.id == item.id)).slice();
    setGridData(dataAcaoIntervencao);
    setOpenModalDescartar(false);

  }

  const handlePesquisarAcao = async (e : any, formRenderProps : FormRenderProps) => {
    e.preventDefault();

    if ((!idAcao || idAcao == "") && !designacao) {
      postMessage({type: "infoMsg", value: {message: "Preencha o id ou a designação"}}, "*");
      return;
    }
  
    const intervencaoRequest = {
      id_acao: idAcao && idAcao !== "" ? idAcao : undefined,
      designacao: designacao ?? undefined,
    };

  
    try {
      const data = await pesquisarAcao(intervencaoRequest);
      if (data) {
       setFindAcao(true)
       const dataObject = {
        designacao: data?.designacao,
      };
       formRenderProps.onChange('id_acao', { value: data?.id_acao}) 
       formRenderProps.onChange('designacao', { value: dataObject}) 
       formRenderProps.onChange('id_estado', { value:  data?.acaoEstado}) 
       formRenderProps.onChange('id_tipo_n4', { value: data?.intTipoAcaoPlaneamentoNivel4}) 

       SessionStorageService.setItem("inv_realizado" , data?.valor_realizado ?? 0)
  

       setFormData({
        id_acao: data?.id_acao,
        designacao: data?.designacao,
        id_estado: data?.acaoEstado,
        id_tipo_n4: data?.intTipoAcaoPlaneamentoNivel4,
        });
      
      } else {
        postMessage({ type: 'errorMsg', value: { left: "15%" , message: "Não foi encontrada nenhuma ação." } }, '*');
      }
    } catch (error) {
      console.error("Erro ao pesquisar ação:", error);
    }
  };

  
  return (
    <div className={`${styles.form} ${styles.removeScrollDuplicado}`}>
     <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>
            Ações associadas
            </span>
          </legend>
          {!viewMode  ? (

          <div className={styles.espacoVerticalFieldSet}>
          <button
              title="Adicionar novo"
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary buttonAdicionarNovo btnPrimaryColor"
              onClick={(e) => {
                openModal(e);
              }}
              // disabled={viewMode || !selectedIntervencaoResult?.id}
              >
              <span className="icon-siarl icon-registar k-button-icon"></span>{' '}
              &nbsp;{' '}
              Inserir
            </button>
         </div>
        ) : ""}

        <div className={styles.espacoVerticalFieldSet}>
        <div className={styles.scrollableArea}>
          <Grid
            className={'customGrid'}
            data={gridData.slice(page2.skip, page2.take + page2.skip)}
            pageable={{
              buttonCount: 5,
              info: false,  
              type: 'numeric',
              pageSizes: false,  
              previousNext: true,
            }}
            rowHeight={50}
            total={gridData.length}
            skip={page2.skip}
            take={page2.take}
            onPageChange={handlePageChange2}

          >
            {!viewMode ?
            
            <GridColumn width={56}
                        cell={(props) => (
                          <td className="k-command-cell">
                            <div style={{cursor:'pointer'}} className={'gridCommand'}>
                              <div
                                role="button"
                                className={styles.gridCommandIconButton}
                                onClick={() => handleRowClick(props.dataItem)}
                              >
                                <FontAwesomeIcon icon={faInfoCircle} title="Editar associação" />
                              </div>
                              <div style={{cursor:'pointer'}}
                                role="button"
                                onClick={() => confirmRemoveContato(props.dataItem)}
                                className={styles.gridCommandIconButton}
                              >
                                <FontAwesomeIcon icon={faRemove} title="Remover associação" />
                              </div>
                            </div>
                          </td>
                        )}
                      />
              : ''}
            <GridColumn field="id_acao" className={styles.centerAlign} width="70px" title="ID ação" />
            <GridColumn field="designacao.designacao" className={styles.centerAlign} width="120px" title="Designação" />
            <GridColumn field="id_estado.descricao" className={styles.centerAlign}width="120px" title="Estado da ação" />
            <GridColumn field="id_tipo_n4.descricao" className={styles.centerAlign} width="120px" title="Tipo n4" />
            <GridColumn field="percentagem" className={styles.centerAlign}  width="90px" title="Percentagem" />
            <GridColumn field="valor_execucao" className={styles.centerAlign}  width="120px" title="Valor da execução" />
          
          </Grid>
        </div>
        </div>
        </fieldset>

        {openModalAcaoIntervencao ? (
          <Dialog
            title="Associação de ação à intervenção"
            className="ModalInstumentoPlaneamento"
            onClose={cancelModal}
          >
            
            <Form
              onSubmit={handleSubmitModal}
              initialValues={formData}
              render={(formRenderProps : any) => (
                <FormElement style={{ maxWidth: 620 }}>
                  <div className={`${styles.form} ${styles.removeScrollDuplicado}`}>
                    
                      <div className={styles.fields}>
                        <Field
                          name="id_acao"
                          label="ID da ação"
                          component={Input}
                          type="number"
                          value={idAcao}
                          onChange={(e) => {
                            setIdAcao(e.value);
                          }}
                          />
                      </div>
                      <div className={styles.fields}>
                        <div>
                        <Field
                          name="designacao"
                          label="Designação"
                          component={ComboBox}
                          textField={'designacao'}
                          filterable={true}
                          onFilterChange={filterChange}
                          data={acaoDesignacaoData}
                          value={designacao}
                          onChange={(e) => {
                            setDesignacao(e.value.designacao)
                          }}
                        />
                      </div>
                  
                      </div>
                      <div className={styles.fields}>
                        <div>
                       <Field
                          name="id_estado"
                          label="Estado"
                          component={ComboBox}
                          textField={'descricao'}
                          disabled
                      />
                      </div>
                    </div>
                  <div className={`${styles.fields}`}>

                  {/* tipologia que vem da ação mas ainda não existe da ação */}
                  <div>
                      <Field
                          name="id_tipo_n4"
                          label="Tipologia nível 4"
                          component={ComboBox}
                          textField={'descricao'}
                          filterable={true}
                          disabled
                      />
                  </div>
               </div>      
                <div className={`${styles.fields}`}>
                  <div>
                      <Field
                        type="number"
                        name="percentagem"
                        label="Percentagem"
                        component={Input}
                        disabled={!findAcao}
                        onChange={handleFormChange}
                        />                       
                  </div>
               </div>  
           
            
               <div className="k-form-buttons btnsModal">
               <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                      onClick={cancelModal}
                    >
                      Cancelar
                    </button>
                    <button
                      type={'button'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                      onClick={(e) => handlePesquisarAcao(e, formRenderProps)}                    
                       >
                    
                      Pesquisar
                    </button>
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                      onClick={formRenderProps.onSubmit}
                      disabled={!findAcao}

                    >
                      Submeter
                    </button>
                    </div>              
                    </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}

        
      {openModalDescartar ? (
          <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={() => setOpenModalDescartar(false)}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                  <fieldset>
                    <span className="textDescartar">{'Apagar'} registo  </span>
                    <br></br>
                    <span>{'Tem a certeza que pretende apagar o associação?'}</span>
                  </fieldset>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={() => setOpenModalDescartar(false)}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={removeAcaoIntervencao}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}

  
    </div>
  )
}
