//  export const API_URL = "https://siarl.ambisig.com/be/api"
//  export const BASE_GEOPORTAL_URL = "https://siarl.ambisig.com/geoportal/"


export const API_URL = "https://siarl.apambiente.pt/be/api/"
export const BASE_GEOPORTAL_URL = "https://siarl.apambiente.pt/geoportal/"

//export const API_URL = "http://10.241.10.249:3000/be/api/"
//export const BASE_GEOPORTAL_URL = "http://10.241.10.249:3000/geoportal/"

// export const API_URL = "http://localhost:44922/api/"
// export const BASE_GEOPORTAL_URL = "http://localhost:44301/"

export const URL_FONT_GRID_PDF = "https://siarl.apambiente.pt/fonts/DejaVuSans.ttf"
//export const URL_FONT_GRID_PDF = "http://localhost:3000/fonts/DejaVuSans.ttf"
//export const URL_FONT_GRID_PDF = "http://10.241.10.249:3000/fonts/DejaVuSans.ttf"
//export const URL_FONT_GRID_PDF = "https://siarl.ambisig.com/fonts/DejaVuSans.ttf"

//export const OPERATIONAL_LAYER = "siarl_negocio_dev"
export const OPERATIONAL_LAYER = "siarl_negocio"

export const production = false;

export const PROPRIEDADE_GA = 'G-N6THED81KC'

export const CURRENT_TIMEZONE = 'Europe/Lisbon'
export const RECORDS_PER_PAGE = 20;
export const RECORDS_LIMIT = 100;

export const TIPO_USOS_SOLO_SILIAMB = 2;
export const USER_GUEST = "Guest"
export const PASS_GUEST = "Guest123"
export const ROLE_MAPAS_PRIVADOS = "MAPAS_PRIVADOS"

export const LAYER_INDEX_OCORRENCIAS = 1;
export const LAYER_INDEX_OCORRENCIA_APOIO = 7;
export const LAYER_INDEX_INT_INTERVENCOES = 17;
export const LAYER_INDEX_INT_ACAO = 16;
export const LAYER_INDEX_INT_MOBILIZACAO = 18;
export const LAYER_INDEX_US_TITULO = 19

export const PERMISSION_BACKOFFICE = "Pages.Backoffice";
export const PERMISSION_CONSULTAR_OCORRENCIA = "OcOcorrencia.ConsultarOcorrenciasAvancada"
export const PERMISSION_DOCUMENTOS_CONSULTA_AVANCADA = "DoDocumento.ConsultaAvancada"
export const PERMISSION_ATUALIZAR_OCORRENCIA = "OcOcorrencia.Update"
export const PERMISSION_LISTAR_ENTIDADE = "DiEntidade.GetDiEntidade"
export const PERMISSION_MENU_ENTIDADE = "DiEntidade.MenuDiEntidade"
export const PERMISSION_LISTAR_OCORRENCIA = "OcOcorrencia.GetOcOcorrencia";
export const PERMISSION_LISTAR_DOCUMENTOS = "DoDocumento.GetDoDocumento"
export const PERMISSION_LISTAR_MODELOS = "MoModelos.Consultar"
export const PERMISSION_LISTAR_INTERVENCAO = "IntIntervencao.Consultar"
export const PERMISSION_LISTAR_INT_ACAO = "IntAcao.Consultar"
export const PERMISSION_LISTAR_INT_CANDIDATURA = "IntCandidatura.Consultar"
export const PERMISSION_LISTAR_INT_MOBILIZACAO = "IntMobilizacao.Consultar"
export const PERMISSION_LISTAR_AG_PRETENSAO = "AgPretensao.Consultar"
export const PERMISSION_LISTAR_AG_DESCONFORMIDADE = "AgDesconformidade.Consultar"
export const PERMISSION_LISTAR_VOO_DRONE = "VzVooDrone.Consultar"
export const PERMISSION_LISTAR_FOTO_AEREA = "FoRegistoVoo.Consultar"
export const PERMISSION_CALCULO_PERFIL = "CalculoPerfil.Calcular"
export const PERMISSION_LISTAR_DH_PATRIMONIO = "DhPatrimonio.Consultar"
export const PERMISSION_LISTAR_DH_PROCESSO = "DhProcesso.Consultar"
export const PERMISSION_LISTAR_DH_DEMARCACAO = "DhDemarcacao.Consultar"
export const PERMISSION_LISTAR_US_TITULO = "UsTitulo.Consultar"
export const PERMISSION_LISTAR_US_VALOR = "UsVus.Consultar"
export const PERMISSION_LISTAR_GEOVISUALIZADORES = "GvMapaPartilhado.Consultar"
export const PERMISSION_PARTILHAR_MAPA = "GvMapaPartilhado.PartilharMapa"
export const PERMISSION_INSERIR_OCORRENCIA = "OcOcorrencia.Insert"
export const PERMISSION_INSERIR_DOCUMENTO = "DoDocumento.Insert"
export const PERMISSION_ATUALIZAR_DOCUMENTO = "DoDocumento.Update"
export const PERMISSION_INSERIR_ENTIDADE = "DiEntidade.Insert"
export const PERMISSION_INSERIR_ESTRATEGIA_ADAPTACAO = "EaRegistoAnalise.Insert"
export const PERMISSION_ATUALIZAR_ESTRATEGIA_ADAPTACAO = "EaRegistoAnalise.Update"
export const PERMISSION_LISTAR_ESTRATEGIA_ADAPTACAO = "EaRegistoAnalise.Consultar"
export const PERMISSION_ATUALIZAR_ENTIDADE = "DiEntidade.Update"
export const PERMISSION_INSERIR_PROCESSO = "DhProcesso.Insert"
export const PERMISSION_ATUALIZAR_PROCESSO = "DhProcesso.Update"
export const PERMISSION_INSERIR_PATRIMONIO = "DhPatrimonio.Insert"
export const PERMISSION_ATUALIZAR_PATRIMONIO = "DhPatrimonio.Update"
export const PERMISSION_INSERIR_DEMARCACAO = "DhDemarcacao.Insert"
export const PERMISSION_ATUALIZAR_DEMARCACAO = "DhDemarcacao.Update"

export const PERMISSION_ATUALIZAR_INTERVENCAO = "IntIntervencao.Update"
export const PERMISSION_INSERIR_INTERVENCAO = "IntIntervencao.Insert"
export const PERMISSION_INSERIR_ACAO = "IntAcao.Insert"
export const PERMISSION_ATUALIZAR_ACAO = "IntAcao.Update"
export const PERMISSION_INSERIR_MOBILIZACAO = "IntMobilizacao.Insert"
export const PERMISSION_ATUALIZAR_MOBILIZACAO = "IntMobilizacao.Update"
export const PERMISSION_ATUALIZAR_CANDIDATURA = "IntCandidatura.Update"
export const PERMISSION_INSERIR_CANDIDATURA = "IntCandidatura.Insert"
export const PERMISSION_INSERIR_PRETENSAO = "AgPretensao.Insert"
export const PERMISSION_ATUALIZAR_PRETENSAO = "AgPretensao.Update"
export const PERMISSION_ATULIZAR_DESCONFORMIDADE = "AgDesconformidade.Update"
export const PERMISSION_INSERIR_DESCONFORMIDADE = "AgDesconformidade.Insert"
export const PERMISSION_INSERIR_MODELO = "MoModelos.Insert"
export const PERMISSION_ATUALIZAR_MODELO = "MoModelos.Update"
export const PERMISSION_INSERIR_US_TITULO = "UsTitulo.Insert"
export const PERMISSION_ATUALIZAR_US_TITULO = "UsTitulo.Update"
export const PERMISSION_ATUALIZAR_VOO_DRONE = "VzVooDrone.Update"
export const PERMISSION_INSERIR_VOO_DRONE = "VzVooDrone.Insert"
export const PERMISSION_INSERIR_FO_REGISTO_VOO = "FoRegistoVoo.Insert"
export const PERMISSION_ATUALIZAR_FO_REGISTO_VOO = "FoRegistoVoo.Update"
export const PERMISSION_SOBRE = "Pages.Sobre"
export const PERMISSION_CONTACTENOS = "Pages.Contactenos"
export const PERMISSION_LISTAR_METADADOS = "Pages.Metadados"
export const PERMISSION_LISTAR_INDICADORES = "Pages.Indicadores"

export const PERMISSION_DOMINIO_HIDRICO_PERFIL_BASICO  = "DominioHidrico.PerfilBasico"
export const PERMISSION_DOMINIO_HIDRICO_PERFIL_INTERMEDIO_LEITURA  = "DominioHidrico.PerfilIntermedioLeitura"
export const PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_LEITURA  = "DominioHidrico.PerfilCompletoLeitura"
export const PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_EDICAO  = "DominioHidrico.PerfilCompletoEdicao"
export const PERMISSION_DOMINIO_HIDRICO_PERFIL_TOTAL  = "DominioHidrico.PerfilTotal"

export const PERMISSION_OCORRENCIA_PERFIL_BASICO = "Ocorrencia.PerfilBasico"
export const PERMISSION_OCORRENCIA_PERFIL_INTERMEDIO_LEITURA  = "Ocorrencia.PerfilIntermedioLeitura"
export const PERMISSION_OCORRENCIA_PERFIL_INTERMEDIO_EDICAO  = "Ocorrencia.PerfilIntermedioEdicao"
export const PERMISSION_OCORRENCIA_PERFIL_COMPLETO_LEITURA  = "Ocorrencia.PerfilCompletoLeitura"
export const PERMISSION_OCORRENCIA_PERFIL_COMPLETO_EDICAO  = "Ocorrencia.PerfilCompletoEdicao"
export const PERMISSION_OCORRENCIA_PERFIL_TOTAL  = "Ocorrencia.PerfilTotal"

export const PERMISSION_APOIO_GESTAO_PERFIL_BASICO = "ApoioGestao.PerfilBasico"
export const PERMISSION_APOIO_GESTAOPERFIL_INTERMEDIO_LEITURA  = "ApoioGestao.PerfilIntermedioLeitura"
export const PERMISSION_APOIO_GESTAOPERFIL_INTERMEDIO_EDICAO  = "ApoioGestao.PerfilIntermedioEdicao"
export const PERMISSION_APOIO_GESTAO_PERFIL_COMPLETO_LEITURA  = "ApoioGestao.PerfilCompletoLeitura"
export const PERMISSION_APOIO_GESTAO_PERFIL_COMPLETO_EDICAO  = "ApoioGestao.PerfilCompletoEdicao"
export const PERMISSION_APOIO_GESTAO_PERFIL_TOTAL  = "ApoioGestao.PerfilTotal"

export const PERMISSION_INTERVENCAO_PERFIL_BASICO  = "Intervencao.PerfilBasico"
export const PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_LEITURA  = "Intervencao.PerfilIntermedioLeitura"
export const PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO  = "Intervencao.PerfilIntsrmedioEdicao"
export const PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA  = "Intervencao.PerfilCompletoLeitura"
export const PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO  = "Intervencao.PerfilCompletoEdicao"
export const PERMISSION_INTERVENCAO_PERFIL_TOTAL  = "Intervencao.PerfilTotal"

export const PERMISSION_ESTRATEGIA_ADAPTACAO_PERFIL_BASICO  = "EstrategiaAdaptacao.PerfilBasico"
export const PERMISSION_ESTRATEGIA_ADAPTACAO_PERFIL_INTERMEDIO_LEITURA  = "EstrategiaAdaptacao.PerfilIntermedioLeitura"
export const PERMISSION_ESTRATEGIA_ADAPTACAO_PERFIL_INTERMEDIO_EDICAO  = "EstrategiaAdaptacao.PerfilIntermedioEdicao"
export const PERMISSION_ESTRATEGIA_ADAPTACAO_PERFIL_COMPLETO_LEITURA  = "EstrategiaAdaptacao.PerfilCompletoLeitura"
export const PERMISSION_ESTRATEGIA_ADAPTACAO_PERFIL_COMPLETO_EDICAO  = "EstrategiaAdaptacao.PerfilCompletoEdicao"
export const PERMISSION_ESTRATEGIA_ADAPTACAO_PERFIL_TOTAL  = "EstrategiaAdaptacao.PerfilTotal"


export const PERMISSION_GEOVISUALIZADOR_PERFIL_BASICO  = "Geovisualizadores.PerfilBasico"
export const PERMISSION_GEOVISUALIZADOR_PERFIL_INTERMEDIO_LEITURA  = "Geovisualizadores.PerfilIntermedioLeitura"
export const PERMISSION_GEOVISUALIZADOR_PERFIL_INTERMEDIO_EDICAO  = "Geovisualizadores.PerfilIntermedioEdicao"
export const PERMISSION_GEOVISUALIZADOR_PERFIL_COMPLETO_LEITURA  = "Geovisualizadores.PerfilCompletoLeitura"
export const PERMISSION_GEOVISUALIZADOR_PERFIL_COMPLETO_EDICAO  = "Geovisualizadores.PerfilCompletoEdicao"
export const PERMISSION_GEOVISUALIZADOR_PERFIL_TOTAL  = "Geovisualizadores.PerfilTotal"

export const PERMISSION_MODELO_PERFIL_BASICO  = "MoModelo.PerfilBasico"
export const PERMISSION_MODELO_PERFIL_INTERMEDIO_LEITURA  = "MoModelo.PerfilIntermedioLeitura"
export const PERMISSION_MODELO_PERFIL_INTERMEDIO_EDICAO  = "MoModelo.PerfilIntermedioEdicao"
export const PERMISSION_MODELO_PERFIL_COMPLETO_LEITURA  = "MoModelo.PerfilCompletoLeitura"
export const PERMISSION_MODELO_PERFIL_COMPLETO_EDICAO  = "MoModelo.PerfilCompletoEdicao"
export const PERMISSION_MODELO_PERFIL_TOTAL  = "MoModelo.PerfilTotal"

export const PERMISSION_ENTIDADES_PERFIL_BASICO  = "Entidades.PerfilBasico"
export const PERMISSION_ENTIDADES_PERFIL_INTERMEDIO_LEITURA  = "Entidades.PerfilIntermedioLeitura"
export const PERMISSION_ENTIDADES_PERFIL_INTERMEDIO_EDICAO  = "Entidades.PerfilIntermedioEdicao"
export const PERMISSION_ENTIDADES_PERFIL_COMPLETO_LEITURA  = "Entidades.PerfilCompletoLeitura"
export const PERMISSION_ENTIDADES_PERFIL_COMPLETO_EDICAO  = "Entidades.PerfilCompletoEdicao"
export const PERMISSION_ENTIDADES_PERFIL_TOTAL  = "Entidades.PerfilTotal"

export const PERMISSION_DOCUMENTOS_PERFIL_BASICO  = "Documentos.PerfilBasico"
export const PERMISSION_DOCUMENTOS_PERFIL_INTERMEDIO_LEITURA  = "Documentos.PerfilIntermedioLeitura"
export const PERMISSION_DOCUMENTOS_PERFIL_INTERMEDIO_EDICAO  = "Documentos.PerfilIntermedioEdicao"
export const PERMISSION_DOCUMENTOS_PERFIL_COMPLETO_LEITURA  = "Documentos.PerfilCompletoLeitura"
export const PERMISSION_DOCUMENTOS_PERFIL_COMPLETO_EDICAO  = "Documentos.PerfilCompletoEdicao"
export const PERMISSION_DOCUMENTOS_PERFIL_TOTAL  = "Documentos.PerfilTotal"

export const PERMISSION_USOSOLO_PERFIL_BASICO  = "UsoSolo.PerfilBasico"
export const PERMISSION_USOSOLO_PERFIL_INTERMEDIO_LEITURA  = "UsoSolo.PerfilIntermedioLeitura"
export const PERMISSION_USOSOLO_PERFIL_INTERMEDIO_EDICAO  = "UsoSolo.PerfilIntermedioEdicao"
export const PERMISSION_USOSOLO_PERFIL_COMPLETO_LEITURA  = "UsoSolo.PerfilCompletoLeitura"
export const PERMISSION_USOSOLO_PERFIL_COMPLETO_EDICAO  = "UsoSolo.PerfilCompletoEdicao"
export const PERMISSION_USOSOLO_PERFIL_TOTAL  = "UsoSolo.PerfilTotal"

export const URL_SOBRE = "https://siarl.apambiente.pt/docs/";
export const URL_NEWS = "https://siarl.apambiente.pt/docs/noticias/"
export const URL_METADADOS = "https://siarl.apambiente.pt/geonetwork/"
export const URL_AJUDA_MODELOS = "https://siarl.apambiente.pt/docs/ajuda/modelos/"
export const URL_AJUDA_APOIO_GESTAO_DESCONFORMIDADE = "https://siarl.apambiente.pt/docs/ajuda/apoio-a-gestao/desconformidades/"
export const URL_AJUDA_APOIO_GESTAO_PRETENSAO = "https://siarl.apambiente.pt/docs/ajuda/apoio-a-gestao/pretensoes/"
export const URL_AJUDA_ENTIDADES = "https://siarl.apambiente.pt/docs/ajuda/entidades/"
export const URL_AJUDA_DOCUMENTOS_AVANCADA = "https://siarl.apambiente.pt/docs/ajuda/documentos/avancado/"
export const URL_AJUDA_DOCUMENTOS_SIMPLES = "https://siarl.apambiente.pt/docs/ajuda/documentos/simples/"
export const URL_AJUDA_OCORRENCIA_PESQUISA_SIMPLES = "https://siarl.apambiente.pt/docs/ajuda/ocorrencias/simples/"
export const URL_AJUDA_OCORRENCIA_PESQUISA_AVANCADA = "https://siarl.apambiente.pt/docs/ajuda/ocorrencias/avancado/"
export const URL_AJUDA_INTERVENCOES_ACAO = "https://siarl.apambiente.pt/docs/ajuda/intervencoes/acoes/"
export const URL_AJUDA_INTERVENCOES_INTERVENCAO = "https://siarl.apambiente.pt/docs/ajuda/intervencoes/intervencoes/"
export const URL_AJUDA_INTERVENCOES_CANDIDATURA = "https://siarl.apambiente.pt/docs/ajuda/intervencoes/candidaturas/"
export const URL_AJUDA_INTERVENCOES_MOBILIZACAO = "https://siarl.apambiente.pt/docs/ajuda/intervencoes/mobilizacoes/"
export const URL_AJUDA_DOMINIO_HIDRICO_PROCESSO = "https://siarl.apambiente.pt/docs/ajuda/dominio-hidrico/processos/"
export const URL_AJUDA_DOMINIO_HIDRICO_PATRIMONIO = "https://siarl.apambiente.pt/docs/ajuda/dominio-hidrico/patrimonio/"
export const URL_AJUDA_DOMINIO_HIDRICO_DEMARCACAO = "https://siarl.apambiente.pt/docs/ajuda/dominio-hidrico/demarcacoes/"
export const URL_AJUDA_ESTRATEGIA_ADAPTACAO = "https://siarl.apambiente.pt/docs/ajuda/estrategia-de-adaptacao/"
export const URL_AJUDA_USOS_SOLO_TITULO = "https://siarl.apambiente.pt/docs/ajuda/usos-do-solo/titulos/"
export const URL_AJUDA_USOS_SOLO_VALOR = "https://siarl.apambiente.pt/docs/ajuda/usos-do-solo/valores/"
export const URL_AJUDA_FOTOGRAFIA_AEREA = "https://siarl.apambiente.pt/docs/ajuda/visualizador_geografico/fotografias_aereas/"
export const URL_AJUDA_VOO_DRONE = "https://siarl.apambiente.pt/docs/ajuda/visualizador_geografico/videos_aereos/"
export const URL_AJUDA_CALCULO_PERFIL = "https://siarl.apambiente.pt/docs/ajuda/visualizador_geografico/perfis_dinamicos/"
export const URL_AJUDA_GEOVISUALIZADORES = "https://siarl.apambiente.pt/docs/ajuda/visualizador_geografico/"
export const URL_AJUDA_BACKOFFICE_CLASSIFICADORES = "https://siarl.apambiente.pt/docs/ajuda/backoffice/classificadores"

export const scalePDF = 0.7

export const PERMISSION_ERROR_STATUS_CODE = 401
export const NOT_AUTHORIZED_ERROR_STATUS_CODE = 403

export const MAPA_ID = "124"
export const AUTH_LOCALSTORAGE = "AUTH_USER"
export const GEOPORTAL_MAPA_URL = BASE_GEOPORTAL_URL + "Maps?ID=" + MAPA_ID;
export const GEOPORTAL_MAPA_PUBLICO_URL = BASE_GEOPORTAL_URL + "Maps/Public?ID=" + MAPA_ID

export const COS = "COS"
export const ECOSISTEMA = "EcoSistema"
export const URBANAS = "Urbanas"


export const NAO = 0
export const SIM = 1
export const NAO_SABE = 2

export const POR_ADOTAR = 1
export const ADOTADA = 2

export const AVISO_CHAMADA = 1
export const VISITA = 2

export const NENHUM = 0;
export const AREA_VISIVEL = 1;
export const POLIGONO_SELECAO = 2;
export const SEM_GEOMETRIA = 3;

export const CRITERIO_JURISDICAO = 0
export const CRITERIO_SEDE = 1

export const NIF_MAX_SIZE = 2147483647;
export const NIF_MIN_LENGTH = 9;


//export const PROJECTION_MAP = "+proj=tmerc +lat_0=39.6682583333333 +lon_0=-8.13310833333333 +k=1 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs"
export const PROJECTION_MAP = "+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs +type=crs"
export const EPSG_MAP = "EPSG:3857"
export const EPSG_GLOBAL = "EPSG:4326"
export const GUEST_USER_NAME = "Guest"

export const ENTIDADES_MODULE_NAME = "Entidades"
export const OCORRENCIAS_MODULE_NAME = "Ocorrências"
export const DOCUMENTOS_MODULE_NAME = "Documentos"
export const DOMINIO_HIDRICO_MODULE_NAME = "Domínio Hídrico"
export const PATRIMONIO_MODULE_NAME = "Património"
export const PROCESSO_MODULE_NAME = "Processo"
export const USO_DO_SOLO_MODULE_NAME = "Uso Do Solo"
export const MODELOS_MODULE_NAME = "Modelos"
export const APOIO_GESTAO_MODULE_NAME = "Apoio à Gestão"
export const DEMARCACAO_MODULE_NAME = "Demarcação"
export const INTERVENCAO_MODULE_NAME = "Intervenções"
export const SOBRE_MODULE_NAME = "Sobre"
export const NEWS_MODULE_NAME = "News"
export const METADADOS_MODULE_NAME = "Metadados"
export const VOO_DRONE_MODULE_NAME = "Voo de Drone"
export const INICIO_MODULE_NAME = "Inicio"
export const CONTACTENOS_MODULE_NAME = "Contacte-nos"
export const GEOVISUALIZADORES_MODULE_NAME = "Geovisualizadores"
export const CALCULO_PERFIL_MODULE_NAME = "CalculoPerfil";
export const ESTRATEGIA_ADAPATACAO_MODULE_NAME = "Estratégia Adaptação"
export const FOTO_AEREA_MODULE_NAME = "FotoAerea"
export const OCORRENCIA_TABLE_NAME = "oc_ocorrencia";
export const OCORRENCIA_APOIO_TABLE_NAME = "oc_ocorrencia_apoio"
export const ENTIDADE_JURISDICAO_TABLE_NAME = "di_entidade_jurisdicao"
export const ENTIDADE_TABLE_NAME = "di_entidade"
export const DOCUMENTO_TABLE_NAME = "do_documento"
export const MODELO_TABLE_NAME = "mo_modelo"
export const PRETENSAO_TABLE_NAME = "ag_pretensao"
export const DESCONFORMIDADE_TABLE_NAME = "ag_desconformidade"
export const PATRIMONIO_TABLE_NAME = "dh_patrimonio"
export const PROCESSO_TABLE_NAME = "dh_processo"
export const DEMARCACAO_TABLE_NAME = "dh_demarcacao"
export const INT_INTERVENCAO_TABLE_NAME = "int_intervencao"
export const OCORRENCIA_POINT_NAME = "Siarl:ocorrencia"
export const OCORRENCIA_APOIO_POINT_NAME = "Siarl:ocorrenciaApoio"
export const ENTIDADE_JURISDICAO_POINT_NAME = "Siarl:entidade_jurisdicao"
export const ENTIDADE_POINT_NAME = "Siarl:entidade"
export const US_TITULO_POINT_NAME = "Siarl:usTitulo"
export const DOCUMENTO_POINT_NAME = "Siarl:documento"
export const MODELO_POINT_NAME = "Siarl:modelo"
export const AG_PRETENSAO_POINT_NAME = "Siarl:pretensao"
export const AG_DESCONFORMIDADE_POINT_NAME = "Siarl:desconformidade"
export const DH_PROCESSO_POINT_NAME = "Siarl:processo"
export const DH_PATRIMONIO_POINT_NAME = "Siarl:patrimonio"
export const DH_DEMARCACAO_POINT_NAME = "Siarl:demarcacao"
export const INT_INTERVENCAO_POINT_NAME = "Siarl:intIntervencao"
export const INT_ACAO_POINT_NAME = "Siarl:intAcao"
export const INT_MOBILIZACAO_POINT_NAME = "Siarl:intMobilizacao"
export const INT_CANDIDATURA_POINT_NAME = "Siarl:intCandidatura"
export const US_TITULOS_POINT_NAME = "Siarl:usTitulo"
export const EA_POINT_NAME = "Siarl:eaRegistoAnalise"
export const FOTO_AEREA_POINT_NAME = "Siarl:fotoObliquaPoint"

export const EMAIL_USERS_LIST_TABLE_NAME = "int_email_users_list";
export const MDT_SELECTION_NAME = "Mdt";

export const GEOPORTAL_BACKOFFICE_URL = BASE_GEOPORTAL_URL + "Service"
export const CONTACTE_NOS_URL = "https://survey123.arcgis.com/share/e87b08ad8e0846bc9a5a8dc10e44cefa"

export const SAVE_NEW_USER = '/AbpUsers/Insert'
export const RELATORIO_MERGE = '/OcOcorrencia/MergeRelatoriosRDLC'
export const RELATORIO_MERGE_ENTIDADE = '/DiEntidade/MergeRelatoriosRDLC'
export const RELATORIO_MERGE_DOCUMENTO= '/DoDocumento/MergeRelatoriosRDLC'
export const PESQUISAR_OCORRENCIAS_LIST_RESULTS = '/OcOcorrencia/ConsultarOcorrenciasPorIds'
export const PESQUISAR_DOCUMENTO_LIST_RESULTS = '/DoDocumento/GetListResultsDocumento'
export const PESQUISAR_MODELO_LIST_RESULTS = '/MoModelo/GetListResultsModelo'
export const RELATORIO_MERGE_MODELO = '/MoModelo/MergeRelatoriosRDLC'
export const GET_LIST_RESULTS_INT_CANDIDATURA = "/IntCandidaturas/GetListResultsCandidatura"
export const RELATORIO_MERGE_CANDIDATURA = '/IntCandidaturas/MergeRelatoriosRDLC'
export const GET_LIST_RESULTS_INT_MOBILIZACAO = "/IntMobilizacoes/GetListResultsMobilizacao"
export const RELATORIO_MERGE_MOBILIZACAO = '/IntMobilizacoes/MergeRelatoriosRDLC'
export const RELATORIO_MERGE_INTERVENCAO = '/IntIntervencoes/MergeRelatoriosRDLC'
export const GET_LIST_RESULTS_INT_INTERVENCAO = "/IntIntervencoes/GetListResultsIntervencao"
export const RELATORIO_MERGE_ACAO = '/IntAcoes/MergeRelatoriosRDLC'
export const GET_LIST_RESULTS_INT_ACAO = "/IntAcoes/GetListResultsAcao"
export const GET_ALL_DESIGNACAO_ACAO = "/IntAcoes/GetAllDesignacao"
export const GET_MOBILIZACAO_CARACTERISTICAS_BY_MOBILIZACAO = "/IntMobilizacaoCaracteristica/GetByMobilizacao"
export const UPDATE_MOBILIZACAO_CARACTERISTICA = "/IntMobilizacaoCaracteristica/Update"
export const INSERT_MOBILIZACAO_CARACTERISTICA = "/IntMobilizacaoCaracteristica/Insert"
export const DELETE_MOBILIZACAO_CARACTERISTICA = "/IntMobilizacaoCaracteristica/Delete"
export const DELETE_MOBILIZACAO_CARACTERISTICA_BY_MOBILIZACAO = "/IntMobilizacaoCaracteristica/DeleteByMobilizacao"
export const GET_ACAO_MOBILIZACAO_BY_MOBILIZACAO_ID = "/IntMobilizacaoAcao/GetByIDMobilizacao"
export const GET_MOBILIZACAO_CARACTERISTICAS_BY_ID = "/IntMobilizacaoCaracteristica/GetById"

export const SAVE_OCORRENCIA = '/OcOcorrencia/Insert'
export const UPDATE_OCORRENCIA = '/OcOcorrencia/Update'
export const UPLOAD_DOCUMENTO_PROCESSO = '/DoDocumento/Processo/Upload'
export const UPLOAD_DOCUMENTO_OCORRENCIA = '/DoDocumento/Ocorrencia/Upload'
export const UPLOAD_DOCUMENTO_ENTIDADE = '/DoDocumento/Entidade/Upload'
export const UPLOAD_DOCUMENTO_US_TITULO = '/DoDocumento/UsTitulo/Upload'
export const UPLOAD_DOCUMENTO_MODELO = '/DoDocumento/Modelo/Upload'
export const UPLOAD_DOCUMENTO_PRETENSAO = "/DoDocumento/Pretensao/Upload"
export const UPLOAD_DOCUMENTO_DESCONFORMIDADE = "/DoDocumento/Desconformidade/Upload"
export const UPLOAD_DOCUMENTO_EA_REGISTO_ANALISE = "/DoDocumento/EaRegistoAnalise/Upload"
export const UPLOAD_DOCUMENTO_PATRIMONIO = '/DoDocumento/Patrimonio/Upload'
export const UPLOAD_DOCUMENTO_INTERVENCAO = '/DoDocumento/Intervencao/Upload'
export const UPLOAD_DOCUMENTO_ACAO = '/DoDocumento/Acao/Upload'
export const UPLOAD_DOCUMENTO_CANDIDATURA = '/DoDocumento/Candidatura/Upload'
export const UPLOAD_DOCUMENTO_MOBILIZACAO = '/DoDocumento/Mobilizacao/Upload'
export const UPLOAD_DOCUMENTO_DEMARCACAO = '/DoDocumento/Demarcacao/Upload'
export const UPLOAD_DOCUMENTO_VOO_DRONE = '/VzVooDronePath/Upload'
export const UPLOAD_DOCUMENTO_REGISTO_VOO_DRONE = '/DoDocumento/VzRegistoVooDrone/Upload'
export const UPLOAD_DOCUMENTO_VOO_DRONE_VIDEO = 'VzVooDronePath/Upload/Video'
export const UPLOAD_DOCUMENTO = "DoDocumento/Documento/Upload"
export const PESQUISAR_VOO_DRONE = '/VzVooDronePath/GetVooDrone'

export const PESQUISAR_VOO_DRONE_PATH_BY_UUID = '/VzVooDronePath/guid'
export const DOWNLOAD_RESOURCE_VOO_DRONE_PATH_BY_ID = '/VzVooDronePath/Download'
export const DOWNLOAD_VIDEO_BY_ID = '/VzVideo/DownloadByVooId'
export const GET_DOCUMENTO_BY_VOOS_DRONE = '/DoDocumento/GetByVzRegistosVooDrone'
export const PESQUISAR_DOCUMENTOS_REGISTO_VOO_DRONE = '/DoDocumento/VzRegistoVooDrone'
export const PESQUISAR_DOCUMENTOS_OCORRENCIA = '/DoDocumento/Ocorrencia'
export const PESQUISAR_DOCUMENTOS_BY_OCORRENCIAS = '/DoDocumento/GetByOcorrencias'
export const PESQUISAR_DOCUMENTOS_BY_ENTIDADES = '/DoDocumento/GetByEntidades'
export const PESQUISAR_DOCUMENTOS_BY_MODELOS = '/DoDocumento/GetByModelos'
export const PESQUISAR_DOCUMENTOS_ENTIDADE = "/DoDocumento/Entidade"
export const PESQUISAR_DOCUMENTOS_MODELO = '/DoDocumento/Modelo'
export const PESQUISAR_DOCUMENTOS_PRETENSAO = '/DoDocumento/Pretensao'
export const PESQUISAR_DOCUMENTOS_BY_PRETENSOES = '/DoDocumento/GetByPretensoes'
export const PESQUISAR_DOCUMENTOS_DESCONFORMIDADE = '/DoDocumento/Desconformidade'
export const PESQUISAR_DOCUMENTOS_EA_REGISTO_ANALISE = "/DoDocumento/EaRegistoAnalise"

export const PESQUISAR_DOCUMENTOS_BY_USO_SOLO= '/DoDocumento/UsTitulo'
export const PESQUISAR_DOCUMENTOS_PATRIMONIO = '/DoDocumento/Patrimonio'
export const PESQUISAR_DOCUMENTOS_INTERVENCAO = '/DoDocumento/Intervencao'
export const PESQUISAR_DOCUMENTOS_ACAO = '/DoDocumento/Acao'
export const PESQUISAR_DOCUMENTOS_CANDIDATURA = '/DoDocumento/Candidatura'
export const PESQUISAR_DOCUMENTOS_MOBILIZACAO = '/DoDocumento/Mobilizacao'
export const PESQUISAR_DOCUMENTOS_DEMARCACAO = '/DoDocumento/Demarcacao'
export const PESQUISAR_DOCUMENTOS_PROCESSO = '/DoDocumento/Processo'
export const PESQUISAR_OCORRENCIAS_SIMPLES = "/OcOcorrencia/ConsultarOcorrenciasSimples"
export const PESQUISAR_PATRIMONIO = "/DhPatrimonio/ConsultarDhPatrimonios"
export const PESQUISAR_DEMARCACAO = "/DhDemarcacao/ConsultarDhDemarcacoes"
export const SAVE_NEW_PATRIMONIO = "/DhPatrimonio/Insert"
export const UPDATE_PATRIMONIO = "/DhPatrimonio/Update"
export const IMPORT_GEOM_DH_PATRIMONIO = "/DhPatrimonio/ImportGeom"
export const IMPORT_GEOM_INT_INTERVENCAO = "/IntIntervencoes/ImportGeom"
export const IMPORT_GEOM_USOSOLO = "/UsoSolo/GetGeom"
export const GET_USOS_SOLO_SOFT = "/UsoSolo/GetUsTitulosSoft"
export const SOFT_DELETE_MODELO = "/MoModelo/SoftDeleteModelo"

export const SAVE_NEW_DEAMARCACAO = "/DhDemarcacao/Insert"
export const UPDATE_DEMARCACAO = "/DhDemarcacao/Update"

export const PESQUISAR_INTERVENCAO = "IntIntervencoes"
export const CONSULTAR_INTERVENCAO = "IntIntervencoes/Consultar"
export const PESQUISAR_INTERVENCAO_BY_ID = "IntIntervencoes"
export const SAVE_NEW_INTERVENCAO = "/IntIntervencoes/Insert"
export const DELETE_INTERVENCAO_BY_ID = "IntIntervencoes/Delete"
export const UPDATE_INTERVENCAO = "/IntIntervencoes/Update"
export const IMPORT_GEOM_INTERVENCOES = "/IntIntervencoes/ImportGeom"
export const GET_DOCUMENTO_BY_INTERVENCAO = "/DoDocumento/GetByIntervencoes"
export const GET_DOCUMENTO_BY_ACAO = "/DoDocumento/GetByAcoes"
export const GET_DOCUMENTO_BY_CANDIDATURA = "/DoDocumento/GetByCandidaturas"
export const GET_DOCUMENTO_BY_MOBILIZACAO = "/DoDocumento/GetByMobilizacoes"
export const GET_DOCUMENTOS_BY_REGISTO_ANALISE = "/DoDocumento/GetByEaRegistosAnalise"
export const GET_DOCUMENTO_BY_US_TITULO = "/DoDocumento/GetByUsTitulo"
export const GET_DOCUMENTOS_BY_PROCESSOS = "/DoDocumento/GetByProcessos"
export const GET_DOCUMENTOS_BY_PATRIMONIOS = "/DoDocumento/GetByPatrimonios"
export const GET_DOCUMENTOS_BY_DEMARCACOES = "/DoDocumento/GetByDemarcacoes"
export const GET_OCORRENCIAS_SHAPEFILE = "/OcOcorrencia/GetShapeFileFull"
export const GET_MODELOS_SHAPEFILE = "/MoModelo/GetShapeFileFull"
export const GET_ENTIDADE_SHAPEFILE = "/DiEntidade/GetShapeFileFull"
export const GET_DOCUMENTOS_SHAPEFILE = "/DoDocumento/GetShapeFileFull"
export const GET_INT_ACAO_SHAPEFILE = "/IntAcoes/GetShapeFile"
export const GET_INT_INTERVENCOES_SHAPEFILE = "/IntIntervencoes/GetShapeFile"
export const GET_INT_MOBILIZACAO_SHAPEFILE = "/IntMobilizacoes/GetShapeFile"
export const GET_US_TITULO_SHAPEFILE = "/UsoSolo/GetShapeFile"
export const GET_DH_PROCESSO_SHAPEFILE = "/DhProcesso/GetShapeFile"
export const GET_DH_PATRIMONIO_SHAPEFILE = "/DhPatrimonio/GetShapeFile"
export const GET_DH_DEMARCACAO_SHAPEFILE = "/DhDemarcacao/GetShapeFile"
export const GET_AG_PRETENSAO_SHAPEFILE = "/AgPretensao/GetShapeFile"
export const GET_AG_DESCONFORMIDADE_SHAPEFILE = "/AgDesconformidade/GetShapeFile"
export const GET_EA_SHAPEFILE = "/EaRegistoAnalise/GetShapeFile"
export const GET_ENTIDADE_CONTATOS = "/DiContacto/GetByEntidade"
export const GET_ENTIDADE_JURISDICAO_BY_ENTIDADE = "/DiEntidadeJurisdicao/GetByEntidade"
export const GET_LIST_RESULTS_DI_ENTIDADE = "/DiEntidade/GetListResultsEntidade"
export const REMOVER_AREA_MODELO = "/MoModelo/RemoverArea"
export const REMOVER_AREA_DOCUMENTO = "/DoDocumento/RemoverArea"
export const REMOVER_AREA_DH_PROCESSO = "/DhProcesso/RemoverArea"
export const REMOVER_AREA_DH_PATRIMONIO = "/DhPatrimonio/RemoverArea"
export const REMOVER_AREA_INT_INTERVENCAO = "/IntIntervencoes/RemoverArea"
export const REMOVER_AREA_INT_ACAO = "/IntAcoes/RemoverArea"
export const REMOVER_AREA_INT_MOBILIZACAO= "/IntMobilizacoes/RemoverArea"
export const REMOVER_AREA_US_TITULO = "/UsoSolo/RemoverArea"
export const REMOVER_AREA_AG_PRETENSAO = "/AgPretensao/RemoverArea"
export const REMOVER_AREA_AG_DESCONFORMIDADE = "/AgDesconformidade/RemoverArea"
export const REMOVER_AREA_PRINCIPAL_ESTRATEGIA_ADAPTACAO = "/EaRegistoAnalise/RemoverAreaPrincipal"
export const REMOVER_AREA_APOIO_ESTRATEGIA_ADAPTACAO = "/EaRegistoAnalise/RemoverAreaApoio"
export const REMOVER_LINHA_DH_DEMARCACAO = "/DhDemarcacao/RemoverLinha"
export const REMOVER_LINHA_DH_PROCESSO = "/DhProcesso/RemoverLinha"
export const REMOVER_PONTO_DH_PROCESSO = "/DhProcesso/RemoverPonto"
export const REMOVER_PONTO_US_TITULO = "/UsoSolo/RemoverPonto"
export const DELETE_ASSOCIACAO_INTERVENCAO_PLANEAMENTO = "IntPlaneamento/Delete"
// export const UPDATE_ASSOCIACAO_INTERVENCAO_PLANEAMENTO = "IntPlaneamento/Update"
export const UPDATE_ASSOCIACAO_INTERVENCAO_PLANEAMENTO = "IntPlaneamento/UpdateAssociarPlaneamento"



export const GET_GV_SESSION_STATE = "GvSessionState/GetSessionStateMap"
export const SAVE_GV_SESSION_STATE = "GvSessionState/SaveSessionState"
export const SAVE_SHARED_MAP = "GvMapaPartilhado/Insert"
export const EDIT_SHARED_MAP = "GvMapaPartilhado/Update"
export const DELETE_SHARED_MAP = "GvMapaPartilhado/Delete"
export const DELETE_SESSION_STATE = "GvSessionState/DeleteSessionState"
export const GET_ALL_GV_TEMAS = "GvTemas/GetAll"
export const GET_ALL_GV_SUB_TEMAS = "GvTemaSubTemas/GetAll"
export const GET_GV_ESTADO = "GvMapaPartilhadoEstado/GetGvMapaPartilhadoEstados"
export const GET_ALL_GV_MAP = "GvMapaPartilhado/GetAll"
export const CONSULTAR_GV_MAP = "GvMapaPartilhado/Consultar"
export const GV_NAMESPACE = "GvMapaPartilhado"

export const PESQUISAR_ACAO = "IntAcoes"
export const CONSULTAR_ACAO = "IntAcoes/Consultar"
export const PESQUISAR_ACAO_BY_ID = "IntAcoes"
export const SAVE_NEW_ACAO = "/IntAcoes/Insert"
export const DELETE_ACAO_BY_ID = "IntAcoes/Delete"
export const UPDATE_ACAO = "/IntAcoes/Update"
export const IMPORT_GEOM_ACAO = "/IntAcoes/ImportGeom"
export const GET_ALL_ACOES = "/IntAcoes/GetAll"

export const PESQUISAR_CANDIDATURA= "IntCandidaturas"
export const CONSULTAR_CANDIDATURA = "/IntCandidaturas/Consultar"
export const PESQUISAR_CANDIDATURA_BY_ID = "IntCandidaturas"
export const SAVE_NEW_CANDIDATURA = "/IntCandidaturas/Insert"
export const DELETE_CANDIDATURA_BY_ID = "/IntCandidaturas/Delete"
export const UPDATE_CANDIDATURA = "/IntCandidaturas/Update"

export const PESQUISAR_MOBILIZACAO = "IntMobilizacoes"
export const CONSULTAR_MOBILIZACAO = "IntMobilizacoes/Consultar"
export const PESQUISAR_MOBILIZACAO_BY_ID = "IntMobilizacoes"
export const SAVE_NEW_MOBILIZACAO = "/IntMobilizacoes/Insert"
export const DELETE_MOBILIZACAO_BY_ID = "IntMobilizacoes/Delete"
export const UPDATE_MOBILIZACAO = "/IntMobilizacoes/Update"
export const IMPORT_GEOM_MOBILIZACAO = "/IntMobilizacoes/ImportGeom"
export const GET_ALL_MOBILIZACOES = "IntMobilizacoes/GetAll"

export const PESQUISAR_OCORRENCIAS_AVANCADA = "/OcOcorrencia/ConsultarOcorrenciasAvancada"
export const PESQUISAR_DOCUMENTOS_SIMPLES = "/DoDocumento/ConsultaSimples"
export const PESQUISAR_DOCUMENTOS_AVANCADA = "/DoDocumento/ConsultaAvancada"
export const PESQUISAR_FREGUESIAS = "/ApFreguesia/GetApFreguesias"
export const PESQUISAR_CONCELHOS = "/ApConcelho/GetApConcelhos"
export const PESQUISAR_ARHS = "/ApArh/GetApArhs"
export const PESQUISAR_PRAIAS = "/ApPraia/GetApPraias"
export const PESQUISAR_DISTRITOS = "/ApDistrito/GetApDistritos"
export const PESQUISAR_NUTS1 = "/ApNuts1/GetApNuts1"
export const PESQUISAR_NUTS2 = "/ApNuts2/GetApNuts2"
export const PESQUISAR_NUTS3 = "/ApNuts3/GetApNuts3"
export const GET_TIPO_TITULOS = "/UsTipoTitulo/GetUsTipoTitulos"
export const GET_US_TIPO_UTILIZACAO = "/UsTipoUtilizacao/UsTipoUtilizacao"


//INTERVENCOES
export const PESQUISAR_MANCHA_NIVEL1 = "/IntMobMancEmprestimoNivel1/GetIntMobMancEmprestimoNivel1s"
export const PESQUISAR_MANCHA_NIVEL2 = "/IntMobMancEmprestimoNivel2/GetIntMobMancEmprestimoNivel2s"
export const PESQUISAR_DEPOSICAO_OBJETIVO_NIVEL1 = "/IntMobObjetivoDeposicaoNivel1/GetIntMobObjetivoDeposicaoNivel1s"
export const PESQUISAR_DEPOSICAO_OBJETIVO_NIVEL2 = "/IntMobObjetivoDeposicaoNivel2/GetIntMobObjetivoDeposicaoNivel2s"
export const PESQUISAR_TIP_NIVEL1 = "/IntPalXXITipNivel1/GetIntPalXXITipNivel1s"
export const PESQUISAR_TIP_NIVEL2 = "/IntPalXXITipNivel2/GetIntPalXXITipNivel2s"
export const PESQUISAR_TIP_NIVEL3 = "/IntPalXXITipNivel3/GetIntPalXXITipNivel3s"
export const PESQUISAR_PRIORIDADE = "/IntPrioridade/GetIntPrioridade"
export const PESQUISAR_TIP_ENGENHARIA = "/AcaoTipEngenharia/GetAcaoTipEngenharia"
export const PESQUISAR_TIPOLOGIA_MOB = "/MobTipologiaControler/GetMobTipologia"   
export const PESQUISAR_METODO_DRAGAGEM = "/MobMetodoDragagem/GetMobMetodoDragagem"   
export const PESQUISAR_METODO_DEPOSICAO = "/MobMetodoDeposicao/GetMobMetodoDeposicao"  
export const PESQUISAR_DEPOSICAO_LOCAL_NIVEL1 = "/IntMobDescritivoLocalDeposicaoNivel1/GetIntMobDescritivoLocalDeposicaoNivel1s"
export const PESQUISAR_DEPOSICAO_LOCAL_NIVEl2 = "/IntMobDescritivoLocalDeposicaoNivel2/GetIntMobDescritivoLocalDeposicaoNivel2s"
export const PESQUISAR_QUALIDADE_SEDIMENTOS = "/MobQualidade/GetMobQualidade"
export const PESQUISAR_GRANULAMETRIA_SEDIMENTOS = "/MobGranulometria/GetMobGranulometria"
export const PESQUISAR_COMPONENTE_ACAO = "/AcaoComponente/GetAcaoComponente"
export const PESQUISAR_ACAO_NATUREZA = "/AcaoNatureza/GetAcaoNatureza"
export const PESQUISAR_TIPO_ENGENHARIA = "/AcaoTipEngenharia/GetAcaoTipEngenharia"
export const PESQUISAR_TIPO_PLANEAMENTO = "/AcaoPlaneamento/GetAcaoPlaneamento"
export const PESQUISAR_PLANEAMENTO1 = "/IntTiposIntervencaoPlaneamentoNivel1/GetAllAsync"
export const PESQUISAR_PLANEAMENTO2 = "/IntTiposIntervencaoPlaneamentoNivel2/GetAllAsync"
export const PESQUISAR_PLANEAMENTO3 = "/IntTiposIntervencaoPlaneamentoNivel3/GetAllAsync"
export const PESQUISAR_ESTADO_INTERVENCOES = "/IntEstado/GetIntEstado"
export const PESQUISAR_INSTRUMENTO_PLANEAMENTO = "/IntInstrumentoPlaneamento/GetIntInstrumentoPlaneamentos"
export const PESQUISAR_INSTRUMENTO_FINANCIAMENTO = "/IntInstrumentoFinanciamento/GetIntInstrumentoFinanciamentos"
export const PESQUISAR_ACAO_ESTADO = "/AcaoEstado/GetAcaoEstado"
export const PESQUISAR_MOBILIZACOES_ESTADOS = "/IntMobilizacaoEstados/GetMobilizacaoEstado"
export const PESQUISAR_FUNCAO = "/IntMobilizacaoFuncoes"
export const PESQUISAR_ACAO_PLANEAMENTO1 = "/IntTiposAcaoPlaneamentoNivel1"
export const PESQUISAR_ACAO_PLANEAMENTO2 = "/IntTiposAcaoPlaneamentoNivel2"
export const PESQUISAR_ACAO_PLANEAMENTO3 = "/IntTiposAcaoPlaneamentoNivel3"
export const PESQUISAR_ACAO_PLANEAMENTO4 = "/IntTiposAcaoPlaneamentoNivel4"
export const PESQUISAR_ESTADO_CANDIDATURA = "/CandidaturaEstado/GetCandidaturaEstado"
export const PESQUISAR_ACAO_CANDIDATURA = "/IntCandidaturasAcoes"
export const SAVE_ACAO_CANDIDATURA = "/IntCandidaturasAcoes/Insert"
export const UPDATE_ACAO_CANDIDATURA = "/IntCandidaturasAcoes/Update"
export const PESQUISAR_MOBILIZACAO_FUNCOES = "/IntMobilizacaoFuncoes"
export const GET_CANDIDATURA_ACAO_BY_CANDIDATURA = "/IntCandidaturasAcoes/GetByCandidatura"
export const PESQUISAR_MOBILIZACAO_TIPOLOGIA = "/MobTipologia/GetMobTipologia"
export const GET_ALL_INT_INTERVENCOES = "/IntIntervencoes/GetAll"
export const PESQUISAR_MOBILIZACAO_ESTADO = "/IntMobilizacaoEstados/GetMobilizacaoEstado"
export const VZ_ESTADO = "/VzEstados/GetVzEstados"
export const INT_EMAIL_USERS_LIST_GET_ALL = "/IntEmailUsersList/GetAll"
export const INT_EMAIL_USERS_UPDATE = "/IntEmailUsersList/Update"
export const INT_EMAIL_USERS_INSERT = "/IntEmailUsersList/Insert"
export const INT_PLANEAMENTO_ASSOCIACAO = "/IntPlaneamento/AssociarPlaneamento"
export const GET_INT_PLANEAMENTOS = "/IntPlaneamento/GetByIdIntervencao"
export const GET_INT_INDICADORES= "/IntIntervencaoIndicador/GetByIdIntervencao"
export const PESQUISAR_INTERVENCOES_ACAO = "/IntIntervencaoAcao/PesquisarIntervencao"
export const GET_INTERVENCAO_ACAO_BY_ID_ACAO = "/IntIntervencaoAcao/GetByIdAcao"
export const GET_INTERVENCAO_ACAO_BY_ID_INTERVENCAO = "/IntIntervencaoAcao/GetByIdIntervencao"
export const GET_EXECUCAO_ACAO_BY_ID_ACAO = "/IntExecucaoAcoes/GetByIDAcao"
export const GET_RESUMO_EXECUCAO_ACAO_BY_ID_ACAO = "/IntAcaoResumoExecucao/GetByIDAcao"
export const GET_DESIGNACAO_INTERVENCAO = "/IntIntervencoes/GetAllDesignacao"
export const GET_CODIGO_INTERVENCAO = "/IntIntervencoes/GetAllCodigo"
export const PESQUISAR_ACAO_INTERVENCAO = "/IntIntervencaoAcao/PesquisarAcao"
export const GET_INT_ACAO_INDICADORES= "/IntAcaoIndicador/GetByIDAcao"
export const CONSULTAR_CANDIDATURA_ACOES = "/IntCandidaturasAcoes/Consultar"
export const GET_CODIGO_CANDIDATURA = "/IntCandidaturas/GetAllCodigo"
export const GET_ASYNC_PRIORIDADE_PAL_XXI = "/IntPrioridadePalXXI/GetPrioridadePalAsync"

export const GET_INT_COMPONENTE = "/IntComponente/GetIntComponenteAsync"
export const GET_INT_INDICADOR = "/IntIndicador/GetIntIndicadorAsync"

//ESTRATEGIA ADAPTACAO
export const GET_ANALISE = "EaRegistoAnalise"
export const CONSULTAR_ANALISE = "EaRegistoAnalise/Consultar"
export const SAVE_ANALISE = "EaRegistoAnalise/Insert"
export const UPDATE_ANALISE = "EaRegistoAnalise/Update"
export const DELETE_ANALISE = "EaRegistoAnalise/Delete"
export const GET_ALL_TIPOLOGIA_OBRA_EXEC = "EaTipologiaObraExecucao/GetAll"
export const IMPORT_GEOM_EA_REGISTO_ANALISE = "/EaRegistoAnalise/ImportGeom"
export const GET_EA_ESTADO = "/EaEstado/GetAll"



export const PESQUISAR_ASSOCIACAO_PROCESSO = "/ApAssociacoes/GetByProcesso"
export const PESQUISAR_ASSOCIACAO_ENTIDADE = "/ApAssociacoes/GetByEntidade"
export const PESQUISAR_ASSOCIACAO_OCORRENCIA = "/ApAssociacoes/GetByOcorrencia"
export const PESQUISAR_ASSOCIACAO_DOCUMENTO = "/ApAssociacoes/GetByDocumento"
export const PESQUISAR_ASSOCIACAO_MODELO = "/ApAssociacoes/GetByModelo"
export const PESQUISAR_ASSOCIACAO_PRETENSAO = "/ApAssociacoes/GetByPretensao"
export const PESQUISAR_ASSOCIACAO_DESCONFORMIDADE = "/ApAssociacoes/GetByDesconformidade"
export const PESQUISAR_ASSOCIACAO_PATRIMONIO = "/ApAssociacoes/GetByPatrimonio"
export const PESQUISAR_ASSOCIACAO_INTERVENCAO = "/ApAssociacoes/GetByIntervencao"
export const PESQUISAR_ASSOCIACAO_ACAO = "/ApAssociacoes/GetByAcao"
export const PESQUISAR_ASSOCIACAO_CANDIDATURA = "/ApAssociacoes/GetByCandidatura"
export const PESQUISAR_ASSOCIACAO_MOBILIZACAO = "/ApAssociacoes/GetByMobilizacao"
export const PESQUISAR_ASSOCIACAO_DEMARCACAO = "/ApAssociacoes/GetByDemarcacao"
export const PESQUISAR_ASSOCIACAO_REGISTO_VOO_DRONE = "/ApAssociacoes/GetByVzRegistoVooDrone"
export const PESQUISAR_DOCUMENTOS_ALL = "/doDocumento/GetDoDocumentos"
export const PESQUISAR_ASSOCIACAO_USOS_SOLO = "/ApAssociacoes/GetByUsTitulo"
export const DELETE_ASSOCIACAO = "/ApAssociacoes/Delete"

export const PESQUISAR_DOCUMENTOS_ALL_BY_ID = "/DoDocumento/GetDoDocumentosById"
export const SAVE_NEW_DOCUMENTO = "/DoDocumento/Insert"
export const DELETE_DOCUMENTO = "/DoDocumento/Delete"
export const DELETE_DOCUMENTO_FISICO = "/DoDocumento/DeleteDocumentoFisico"
export const UPDATE_DOCUMENTO = "/DoDocumento/Update"
export const SOFT_REMOVE_DOCUMENT = "/DoDocumento/SoftRemoveDocument"
export const UPDATE_DOCUMENTO_GEOM = "/DoDocumento/UpdateGeom"
export const BAIXAR_DOCUMENTO = "/DoDocumento/GetDoDocumentoFisico"
export const PESQUISAR_OCORRENCIA_ALL = "/OcOcorrencia/GetOcOcorrencias"
export const SAVE_NEW_ASSOCIATION = "/ApAssociacoes/Insert"
export const SAVE_NEW_ENTIDADE = "/DiEntidade/Insert"
export const GET_ENTIDADE_BY_USER = '/DiEntidade/GetEntidadeIdByUtilizadorId'
export const SAVE_NEW_FOTO = "/foFoto/Insert"
export const SAVE_REGISTO_VOO = "/FoRegistoVoo/Insert"
export const UPLOAD_FO_FOTO = "/foFoto/Upload"
export const CONSULTAR_FO_FOTO = "/foFoto/ConsultarFotos"
export const GET_REGISTO_VOO_BY_ID = "/FoRegistoVoo/GetRegistoVoo"
export const CONSULTAR_REGISTO_VOO = "/FoRegistoVoo/ConsultarRegistosVoo"
export const UPDATE_REGISTO_VOO = "/FoRegistoVoo/Update"
export const UPDATE_ENTIDADE = "/DiEntidade/Update"
export const UPDATE_GEOM_ENTIDADE = "/DiEntidade/UpdateGeom"
export const DELETE_GEOM_JURISDICAO_ENTIDADE = "/DiEntidade/DeleteJurisdicaoGeom"
export const UPDATE_ASSOCIATION = "/ApAssociacoes/Update"
export const PESQUISAR_ESTADO = "/DoEstado/GetDoEstados"
export const PESQUISAR_CAPTANIA = "/ApCapitania/GetApCapitanias"
export const PESQUISAR_MASSADAGUA = "/ApMassaAgua/GetApMassasAgua"
export const PESQUISAR_ENTIDADES = "/DiEntidade/GetDiEntidades"
export const CONSULTAR_ENTIDADES = "/diEntidade/Consulta"
export const UPDATE_ENTIDADE_JURISDICAO = "/diEntidadeJurisdicao/Update"
export const PESQUISAR_PALAVRACHAVE = "/DoPalavraChave/GetDoPalavrasChave"
export const PESQUISAR_IDIOMA = "/DoIdioma/GetDoIdiomas"
export const PESQUISAR_TIPOACESSO = "/DoTipoAcesso/GetDoTiposAcesso"
export const PESQUISAR_TIPORECURSO = "/DoTipoRecurso/GetDoTiposRecurso"
export const PESQUISAR_CATEGORIA = "/DoCategoria/GetDoCategorias"
export const PESQUISAR_TIPOENTIDADE = "/DiTipoEntidade/GetDiTiposEntidade"
export const UPDATE_OCORRENCIA_APOIO = "/OcOcorrenciaApoio/Update"
export const UPDATE_OCORRENCIA_GEOMETRY = "/OcOcorrencia/UpdateGeometry"
export const PESQUISAR_ENTIDADEBYUSER = "/DiEntidade/GetEntidadeIdByUtilizadorId"
export const GET_EMAIL_BY_USER_ID = "/login/GetEmailByUserId"
export const GET_OCORRENCIA = "/OcOcorrencia/GetOcOcorrencia"
export const FIND_OCORRENCIA = "/OcOcorrencia/FindOcorrencia"
export const DELETE_OCORRENCIA_APOIO_GEOM = "OcOcorrencia/DeleteOcorrenciaApoio"
export const DELETE_OCORRENCIA = "OcOcorrencia/Delete"
export const RELATORIO_OCORRENCIA = "OcOcorrencia/RelatorioDeOcorrencia"
export const RELATORIO_OCORRENCIA_RDLC = "OcOcorrencia/CriarRelatorioRDLC"
export const RELATORIO_MODELO_RDLC = "MoModelo/CriarRelatorioRDLC"
export const RELATORIO_ENTIDADE_RDLC = "DiEntidade/CriarRelatorioRDLC"
export const RELATORIO_DOCUMENTO_RDLC = "DoDocumento/CriarRelatorioRDLC"
export const RELATORIO_ESTRATEGIA_ADAPTACAO = "EaRegistoAnalise/RelatorioDeEstrategiaAdaptacao"

export const RELATORIO_PATRIMONIO = "DhPatrimonio/RelatorioDePatrimonio"
export const RELATORIO_DEMARCACAO = "DhDemarcacao/RelatorioDeDemarcacao"
export const RELATORIO_INTERVENCAO = "IntIntervencoes/CriarRelatorioRDLC"
export const RELATORIO_ACAO = "IntAcoes/CriarRelatorioRDLC"
export const RELATORIO_CANDIDATURA = "IntCandidaturas/CriarRelatorioRDLC"
export const RELATORIO_MOBILIZACAO = "IntMobilizacoes/CriarRelatorioRDLC"
export const RELATORIO_TITULO = "UsoSolo/CriarRelatorioRDLC"
export const RELATORIO_PRETENSAO = "AgPretensao/RelatorioDePretensao"
export const RELATORIO_DESCONFORMIDADE = "AgDesconformidade/RelatorioDeDesconformidade"
export const RELATORIO_ENTIDADE = "DiEntidade/RelatorioDeEntidade"
export const RELATORIO_MODELO = "MoModelo/RelatorioDeModelo"
export const RELATORIO_DOCUMENTO = "DoDocumento/RelatorioDeDocumento"
export const DELETE_ENTIDADE = "DiEntidade/Delete"
export const GET_ENTIDADE = "/DiEntidade/GetDiEntidade"
export const PESQUISAR_MO_MODELO_TIPOS = "/MoModeloTipo/GetMoModeloTipos"
export const PESQUISAR_MO_MODELO_SUBTIPOS = "/MoModeloSubtipo/GetMoModeloSubTipos"
export const UPDATE_MODELO_TIPO = "/MoModeloTipo/Update"
export const INSERT_MODELO_TIPO = "/MoModeloTipo/Insert"
export const UPDATE_OC_ESTADO = "/OcEstado/Update"
export const INSERT_OC_ESTADO = "/OcEstado/Insert"
export const SAVE_NEW_MODELO = "/MoModelo/Insert"
export const UPDATE_MODELO = "/MoModelo/Update"
export const DELETE_MODELO = "/MoModelo/Delete"
export const UPDATE_MO_ESTADO = "/MoEstado/Update"
export const INSERT_MO_ESTADO = "/MoEstado/Insert"
export const GET_MO_ESTADO = "/MoEstado/GetMoEstado"
export const GET_ALL_MODELOS = "/MoModelo/GetMoModelos"
export const GET_MODELO_BY_ID = "/MoModelo/GetMoModelo"
export const IMPORT_GEOM_MO_MODELO = "/MoModelo/ImportGeom"
export const IMPORT_GEOM_ENTIDADE = "/DiEntidade/ImportGeom"
export const PESQUISAR_MODELOS = "/MoModelo/ConsultarModelos"
export const GET_ADDRESS_BY_LOCATION = "/DiEntidade/address"
export const GET_DO_DOCUMENTO_FREGUESIAS = "/DoDocumentoFreguesia/GetDoDocumentoFreguesia/documento"
export const GET_DO_DOCUMENTO_ARH = "/DoDocumentoArh/GetDoDocumentoArh/documento"
export const GET_DO_DOCUMENTO_CAPITANIA = "/DoDocumentoCapitania/GetDoDocumentoCapitania/documento"
export const GET_DO_DOCUMENTO_CATEGORIA = "/DoDocumentoCategoria/GetDoDocumentoCategoria/documento"
export const GET_DO_DOCUMENTO_CONCELHO = "/DoDocumentoConcelho/GetDoDocumentoConcelho/documento"
export const GET_DO_DOCUMENTO_DISTRITO = "/DoDocumentoDistrito/GetDoDocumentoDistrito/documento"
export const GET_DO_DOCUMENTO_MASSA_AGUA = "/DoDocumentoMassaAgua/GetDoDocumentoMassaAgua/documento"
export const GET_DO_DOCUMENTO_NUTS1 = "/DoDocumentoNuts1/GetDoDocumentoNuts1/documento"
export const GET_DO_DOCUMENTO_NUTS2 = "/DoDocumentoNuts2/GetDoDocumentoNuts2/documento"
export const GET_DO_DOCUMENTO_NUTS3 = "/DoDocumentoNuts3/GetDoDocumentoNuts3/documento"
export const GET_DO_DOCUMENTO_PALAVRA_CHAVE = "/DoDocumentoPalavraChave/GetDoDocumentoPalavraChave/documento"
export const GET_COORDINATES_BY_ADDRESS = "/DiEntidade/coordinates"
export const IMPORT_GEOM_DOCUMENTO = "/DoDocumento/ImportGeom"
export const IMPORT_GEOM_MODELO = "/MoModelo/ImportGeom"
export const GET_DOCUMENTO = "/DoDocumento/GetDoDocumento"
export const GET_MODELO = "/MoModelo/GetMoModelo"
export const GET_OCORRENCIAS_PARA_ASSOCIAR = "/OcOcorrencia/GetOcOcorrenciasSoft"

export const GET_US_ESTADO = "/UsEstadoVus/GetUsEstadoVuss"
export const GET_US_TITULO = "/UsoSolo/GetUsTitulos"
export const GET_US_TITULO_BY_ID = "/UsoSolo/GetUsTitulo"
export const CONSULTAR_US_TITULO = "/UsoSolo/ConsultarUsTitulos"
export const SAVE_NEW_US_TITULO = "/UsoSolo/Insert"
export const DELETE_US_TITULO_BY_ID = "/UsoSolo/Delete"
export const UPDATE_US_TITULO = "/UsoSolo/Update"
export const UPDATE_US_TITULO_GEOM = "/UsoSolo/UpdateGeom"
export const GET_GEOM_USO_SOLO = "/UsoSolo/GetGeom"
export const CONSULTAR_VALOR_USO_SOLO = "/UsoSolo/ConsultarValor"
export const PESQUISAR_US_TIPO_PROCESSO = "/UsTipoProcesso/GetUsTipoProcesso"

export const VERIFICA_CODIGO = "/DiEntidade/VerificaCodigo"



export const GET_AG_ESTADOS = "/AgEstado/GetAll"
export const PESQUISAR_AG_PRETENSAO = "/AgPretensao"
export const PESQUISAR_AG_PRETENSAO_BY_ID = "/AgPretensao/GetPretensao"
export const CONSULTAR_AG_PRETENSAO = "/AgPretensao/Consultar"
export const GET_AG_TIPO_PRETENSOES = "/AgPretensao/GetTipoPretensoes"
export const GET_AG_DECISOES = "/AgPretensao/GetDecisoes"
export const CREATE_PRETENSAO = "/AgPretensao/Insert"
export const UPDATE_PRETENSAO = "/AgPretensao/Update"
export const GET_PRETENSAO = "/AgPretensao/GetPretensao"
export const GET_AG_TEMA = "/Agtema/GetAll"


export const GET_AG_TEMAS = "/AgTema/GetAll"
export const GET_AG_DESCONFORMIDADE = "/AgDesconformidade"
export const GET_AG_DESCONFORMIDADE_BY_ID = "/AgDesconformidade"
export const DELETE_AG_DESCONFORMIDADE_BY_ID = "/AgDesconformidade/Delete"
export const SAVE_NEW_AG_DESCONFORMIDADE = "/AgDesconformidade/Insert"
export const UPDATE_AG_DESCONFORMIDADE = "/AgDesconformidade/Update"
export const CONSULTA_AG_DESCONFORMIDADE = "/AgDesconformidade/Consulta"
export const PESQUISAR_DOCUMENTOS_BY_DESCONFORMIDADES_IDS = "/DoDocumento/GetByDesconformidades"

export const SAVE_NEW_DH_PROCESSO = "/DhProcesso/Insert"
export const UPDATE_DH_PROCESSO = "/DhProcesso/Update"
export const DELETE_DH_PROCESSO = "DHProcesso/Delete"
export const CONSULTAR_DH_PROCESSO = "/DhProcesso/ConsultarDhProcessos"
export const GET_PROCESSO = "/DhProcesso/GetDhProcesso"
export const GET_PATRIMONIO = "/DhPatrimonio/GetDhPatrimonio"
export const DELETE_DH_PATRIMONIO = "DHPatrimonio/Delete"
export const DELETE_DH_DEMARCACAO = "DHDEMARCACAO/Delete"
export const GET_DEMARCACAO = "/DhDemarcacao/GetDhDemarcacao"
export const PESQUISAR_TIPO_PROCESSO = "/DhTipoProcesso/GetDhTiposProcesso"
export const PESQUISAR_LOCALIZACAO_PROCESSO = "/DhLocalizacaoProcesso/GetDhLocalizacoesProcesso"
export const PESQUISAR_FASE_PROCEDIMENTO_DE_ELIMINACAO = "/DhFaseProcedimentoDelimitacao/GetDhFasesProcedimentoDelimitacao"
export const PESQUISAR_VALIDACAO_PROVA = "/DhValidacaoProva/GetDhValidacoesProva"
export const PESQUISAR_TIPO_PARCELA = "/DhTipoParcela/GetDhTiposParcela"
export const PESQUISAR_TIPO_SENTENCA = "/DhTipoSentenca/GetDhTiposSentenca"
export const PESQUISAR_TIPO_DECISAO_FINAL = "/DhTipoDecisaoFinal/GetDhTiposDecisaoFinal"
export const PESQUISAR_ESTADO_PROCESSO = "/DhEstadoProcesso/GetDhEstadosProcesso"
export const PESQUISAR_TIPO_PENDENCIA = "/DhTipoPendencia/GetDhTiposPendencia"
export const PESQUISAR_TIPO_NOTIFICACAO = "/DhTipoNotificacao/GetDhTiposNotificacao"
export const PESQUISAR_TIPO_SITUACAO_NOTIFICACAO = "/DhTipoSituacaoNotificacao/GetDhTiposSituacaoNotificacao"
export const PESQUISAR_CONDICIONANTES_PROCESSO = "/DhCondicionantesProcesso/GetDhCondicionantesProcessos"
export const PESQUISAR_TIPO_JUDICIAL = "/DhTipoAcaoJudicial/GetDhTiposAcaoJudicial"
export const PESQUISAR_TIPO_DESAFETACAO = "/DhTipoDesafetacao/GetDhTiposDesafetacao"
export const PESQUISAR_TIPO_AQUISICAO = "/DhTipoAquisicao/GetDhTiposAquisicao"
export const PESQUISAR_TIPO_INICIATIVA = "/DhTipoIniciativa/GetDhTiposIniciativa"
export const IMPORT_GEOM_PRETENSAO = "/AgPretensao/ImportGeom"
export const IMPORT_GEOM_DESCONFORMIDADE = "/AgDesconformidade/ImportGeom"
export const IMPORT_GEOM_DH_PROCESSO = "/DhProcesso/ImportGeom"
export const GET_MO_ESTADOS = "/MoModelo/GetEstados"
export const GET_RELATORIO_DE_PROCESSOS = "/DhProcesso/RelatorioDeProcessos"

export const GET_VALOR_USO_DE_SOLO = "/UsVus/GetUsVus"
export const GET_VALOR_USO_DE_SOLO_BY_CUSTOM_FILTERS = "/UsVus/GetUsVus/ConsultarVus"
export const GET_EA_VALOR_USO_DE_SOLO_BY_CUSTOM_FILTERS = "/UsVus/ConsultarEaVus"
export const GET_VALOR_USO_DE_SOLO_BY_ID = "/UsVus/GetUsVus"
export const SAVE_NEW_VALOR_USO_DE_SOLO = "/UsVus/Insert"
export const DELETE_VALOR_USO_DE_SOLO_BY_ID = "/UsVus/Delete"
export const UPDATE_VALOR_USO_DE_SOLO = "/UsVus/Update"
export const GET_RELATORIO_DE_USO_DE_SOLO = "/UsVus/RelatorioDeVus"
export const GET_US_TIPO_VUS = "/UsTipoVus/GetUsTipoVuss"
export const GET_US_ESTADO_VUS = "/UsEstadoVus/GetUsEstadoVuss"
export const GET_US_ESTADO_TITULO = "/UsEstadoTitulo/GetUsTitulos"
export const GET_TIPO_INCIDENCIA = "/IntTipoIncidencia/GetIntTipoIncidencia"
export const GET_US_COMPONENTE_VUS = "/UsTipoComponenteVus/GetUsTipoComponenteVuss"
export const GET_US_FONTE_DADOS_VUS = "/UsFonteDadosVus/GetUsFonteDadosVuss"
export const GET_US_VUS = "/UsVus/GetUsVus"
export const SAVE_VOO_DRONE = "/VzVooDrone/Insert"
export const UPDATE_VOO_DRONE = "/VzVooDrone/Update"
export const GET_VOOS_DRONE = "/VzVooDrone/GetAll"
export const GET_VOO_DRONE_BY_VOO_DRONE_PATH_ID = "/VzVooDrone/GetByVzVooDronePath/"
export const GET_VIDEO_BY_VOO_DRONE_PATH_ID = "/VzVideo/GetVideoByVooId/"
export const GET_VOO_DRONES_BY_VOO_DRONE_PATH_ID = "/VzVooDrone/GetVzVooDronesByIdPath/"
export const UPDATE_REGISTO_VOO_DRONE = "/VzRegistosVooDrone/Update"


export const CONSULTAR_REGISTOS_VOO_DRONE = "/VzRegistosVooDrone/Consultar"
export const SEND_MAIL_TOKEN_PASSWORD_RESET = "/login/GenerateResetPassToken"
export const RESET_PASSWORD = "/login/ChangeUserPassword"
export const GET_REGISTO_VOO_DRONE = "/VzRegistosVooDrone/GetByUid"
export const GET_REGISTO_VOO_DRONE_BY_ID = "/VzRegistosVooDrone/GetVzRegistoVooDrone"
export const GET_FO_IMAGE_BASE64 = "/FoFoto/GetImagebase64"
export const GET_FO_FOTO_BY_ID = "/FoFoto/GetById"
export const GET_FOTOS_REGISTO_VOO = "/FoFoto/GetByRegistoVooWithPath"
export const GET_COORDINATES_FOTO_OBLIQUA = "/FoFoto/GetCoordinatesFromPhoto"
export const CREATE_LINE_FROM_POINTS = "/FoRegistoVoo/createLineFromPoints"
export const GET_FREGUESIA_FROM_CODE = "/ApFreguesia/GetApFreguesiaFromCode"
export const GET_CONCELHO_FROM_CODE = "/ApConcelho/GetFromCode"
export const GET_DISTRITO_FROM_CODE = "/ApDistrito/GetFromCode"
export const GET_NUTS_3_FROM_CODE = "/ApNuts3/GetFromCode"
export const GET_URL_FROM_FILE_DOC = "/FileDoc/GetUrlFromProcess"
export const GET_USER_BY_ID = "/AbpUsers/GetAbpUsers"
export const GET_ALT_FROM_LINE = "/CalculoPerfil/GetAltFromLine"
export const UPLOAD_MDT = "/CalculoPerfil/UploadMdt"
export const PROCESS_MDT = "/CalculoPerfil/ProcessMdt"
export const INSERT_MDT = "/CalculoPerfil/Insert"
export const MDT_NAME_EXISTS = "/CalculoPerfil/MdtNameExists"
export const UPDATE_MDT = "/CalculoPerfil/Update"
export const GET_ESTADOS_OCORRENCIA = "/OcEstado/GetOcEstados"
export const PESQUISAR_DOCUMENTOS_ALL_SOFT = "/DoDocumento/GetDoDocumentosSoft"
export const PESQUISAR_DH_DEMARCACOES = "/DhDemarcacao/GetDhDemarcacoes"
export const PESQUISAR_DH_PROCESSOS = "/DhProcesso/GetDhProcessos"
export const PESQUISAR_DH_PATRIMONIOS = "/DhPatrimonio/GetDhPatrimonios"
export const GET_PROCESSOS_MARCACOES = "/DhProcessosMarcacao/GetDhProcessosMarcacoes"
export const GET_PROCESSOS_PARECERES = "/DhParecer/GetDhPareceres"
export const PROCESSO_GRAVAR_LINHA = "/DhProcesso/GerarLinhas"
export const DH_AUTOS_VERTICES_GET_BY_PROC = "/DhAutosVertices/GetByProcId"
export const DH_AUTOS_GET_BY_PROC = "/DhAutos/GetByProcId"
export const DH_PARECERES_BY_PROC = "/DhParecer/GetDhParecerByIdProc"

export const INSERT_INT_ESTADO = "/IntEstado/Insert"
export const UPDATE_INT_ESTADO = "/IntEstado/Update"
export const INSERT_ACAO_ESTADO = "/AcaoEstado/Insert"
export const UPDATE_ACAO_ESTADO = "/AcaoEstado/Update"
export const INSERT_MOBILIZACAO_ESTADO = "/IntMobilizacaoEstados/Insert"
export const UPDATE_MOBILIZACAO_ESTADO = "/IntMobilizacaoEstados/Update"
export const GET_ESTADO_MOBILIZACAO = "/IntMobilizacaoEstados/GetMobilizacaoEstado"
export const INSERT_CANDIDATURA_ESTADO = "/CandidaturaEstado/Insert"
export const UPDATE_CANDIDATURA_ESTADO = "/CandidaturaEstado/Update"
export const GET_TECNICOS = "/AbpUsers/GetAbpsUsers"
export const INSERT_PROCESSO_ESTADO = "/DhEstadoProcesso/Insert"
export const UPDATE_PROCESSO_ESTADO = "/DhEstadoProcesso/Update"
export const INSERT_US_TITULO_ESTADO = "/UsEstadoTitulo/Insert"
export const UPDATE_US_TITULO_ESTADO = "/UsEstadoTitulo/Update"
export const INSERT_AG_ESTADO = "/AgEstado/Insert"
export const UPDATE_AG_ESTADO = "/AgEstado/Update"
export const INSERT_EA_ESTADO = "/EaEstado/Insert"
export const UPDATE_EA_ESTADO = "/EaEstado/Update"
export const INSERT_DO_ESTADO = "/DoEstado/Insert"
export const UPDATE_DO_ESTADO = "/DoEstado/Update"
export const GET_INT_PLANEAMENTO_1 = "/IntTiposIntervencaoPlaneamentoNivel1/GetAllAsync"
export const INSERT_INT_PLANEAMENTO_1 = "/IntTiposIntervencaoPlaneamentoNivel1/Insert"
export const UPDATE_INT_PLANEAMENTO_1 = "/IntTiposIntervencaoPlaneamentoNivel1/Update"
export const GET_INT_PLANEAMENTO_2 = "/IntTiposIntervencaoPlaneamentoNivel2/GetAllAsync"
export const INSERT_INT_PLANEAMENTO_2 = "/IntTiposIntervencaoPlaneamentoNivel2/Insert"
export const UPDATE_INT_PLANEAMENTO_2 = "/IntTiposIntervencaoPlaneamentoNivel2/Update"
export const GET_INT_PLANEAMENTO_3 = "/IntTiposIntervencaoPlaneamentoNivel3/GetAllAsync"
export const INSERT_INT_PLANEAMENTO_3 = "/IntTiposIntervencaoPlaneamentoNivel3/Insert"
export const UPDATE_INT_PLANEAMENTO_3 = "/IntTiposIntervencaoPlaneamentoNivel3/Update"
export const INSERT_INT_PRIORIDADE = "/IntPrioridade/Insert"
export const UPDATE_INT_PRIORIDADE = "/IntPrioridade/Update"
export const GET_INT_TIPO_INTERVENCAO = "/IntTipoIntervacao/GetIntTipoIntervacao"
export const INSERT_INT_TIPO_INTERVENCAO = "/IntTipoIntervacao/Insert"
export const UPDATE_INT_TIPO_INTERVENCAO = "/IntTipoIntervacao/Update"
export const GET_INT_INSTRUMENTO_PLANEAMENTO_ESTADO = "/IntInstrumentoPlaneamentoEstado/GetIntInstrumentoPlaneamentoEstado"
export const INSERT_INSTRUMENTO_PLANEAMENTO_ESTADO = "/IntInstrumentoPlaneamentoEstado/Insert"
export const UPDATE_INSTRUMENTO_PLANEAMENTO_ESTADO = "/IntInstrumentoPlaneamentoEstado/Update"
export const INSERT_INT_TIPO_INCIDENCIA = "/IntTipoIncidencia/Insert"
export const UPDATE_INT_TIPO_INCIDENCIA = "/IntTipoIncidencia/Update"
export const GET_INT_TIPO_MOBILIZACAO = "/IntTipoMobilizacao/GetIntTipoMobilizacao"
export const INSERT_INT_TIPO_MOBILIZACAO = "/IntTipoMobilizacao/Insert"
export const UPDATE_INT_TIPO_MOBILIZACAO = "/IntTipoMobilizacao/Update"
export const INSERT_DH_TIPO_PROCESSO = "/DhTipoProcesso/Insert"
export const UPDATE_DH_TIPO_PROCESSO = "/DhTipoProcesso/Update"
export const INSERT_DH_FASE_PROCEDIMENTO = "/DhFaseProcedimentoDelimitacao/Insert"
export const UPDATE_DH_FASE_PROCEDIMENTO = "/DhFaseProcedimentoDelimitacao/Update"
export const INSERT_DH_TIPO_DOMINIO = "/DhTipoDominio/Insert"
export const UPDATE_DH_TIPO_DOMINIO = "/DhTipoDominio/Update"
export const GET_DH_TIPO_DOMINIO = "/DhTipoDominio/GetDhTipoDominio";
export const PESQUISAR_TIPO_RECURSO = "/DhRecurso/GetDhRecursos";
export const PESQUISAR_DEF_LICENCIAMENTO = "/DhDefLicenciamento/GetDhDefLicenciamentos";
export const GET_DH_TIPO_ACAO_RECONHECIMENTOS = "/DhTipoAcaoReconhecimento/GetDhTipoAcaoReconhecimentos";
export const INSERT_DH_LOCALIZACAO_PROCESSO = "/DhLocalizacaoProcesso/Insert"
export const UPDATE_DH_LOCALIZACAO_PROCESSO = "/DhLocalizacaoProcesso/Update";
export const INSERT_DH_VALIDACAO_PROVA= "/DhValidacaoProva/Insert"
export const UPDATE_DH_VALIDACAO_PROVA = "/DhValidacaoProva/Update";
export const INSERT_DH_TIPO_SENTENÇA = "/DhTipoSentenca/Insert"
export const UPDATE_DH_TIPO_SENTEÇA = "/DhTipoSenteca/Update";
export const INSERT_DH_TIPO_PENDENCIA = "/DhTipoPendencia/Insert"
export const UPDATE_DH_TIPO_PENDENCIA = "/DhTipoPendencia/Update";
export const INSERT_DH_TIPO_NOTIFICACAO = "/DhTipoNotificacao/Insert"
export const UPDATE_DH_TIPO_NOTIFICACAO = "/DhTipoNotificacao/Update";
export const INSERT_DH_TIPO_SITUACAO_NOTIFICACAO = "/DhTipoSituacaoNotificacao/Insert"
export const UPDATE_DH_SITUACAO_NOTIFICACAO = "/DhTipoSituacaoNotificacao/Update";
export const INSERT_DH_CONDICIONANTES_PROCESSO = "/DhCondicionantesProcesso/Insert"
export const UPDATE_DH_CONDICIONANTES_PROCESSO = "/DhCondicionantesProcesso/Update";
export const INSERT_DH_TIPO_PARCELA = "/DhTipoParcela/Insert"
export const UPDATE_DH_TIPO_PARCELA= "/DhTipoParcela/Update";
export const INSERT_DH_TIPO_ACAO_JUDICIAL = "/DhTipoAcaoJudicial/Insert"
export const UPDATE_DH_TIPO_ACAO_JUDICIAL= "/DhTipoAcaoJudicial/Update";
export const INSERT_DH_TIPO_DESAFETACAO = "/DhTipoDesafetacao/Insert"
export const UPDATE_DH_TIPO_DESAFETACAO= "/DhTipoDesafetacao/Update";
export const INSERT_DH_TIPO_AQUISICAO = "/DhTipoAquisicao/Insert"
export const UPDATE_DH_TIPO_AQUISICAO= "/DhTipoAquisicao/Update";
export const UPDATE_DH_RECONHECIMENTO_PROPRIEDADE_PRIVADA= "/DhReconhecimentoPropriedadePrivada/Update";
export const INSERT_DH_RECONHECIMENTO_PROPRIEDADE_PRIVADA = "/DhReconhecimentoPropriedadePrivada/Insert"
export const GET_DH_RECONHECIMENTO_PROPRIEDADE_PRIVADA= "/DhReconhecimentoPropriedadePrivada/GetDhReconhecimentoPropriedadePrivada";
export const INSERT_DH_TIPO_INICIATIVA = "/DhTipoIniciativa/Insert"
export const UPDATE_DH_TIPO_INICIATIVA= "/DhTipoIniciativa/Update";
export const INSERT_DH_TIPO_LINHA_DEMARCACAO = "/DhTipoLinhaDemarcacao/Insert"
export const UPDATE_DH_TIPO_LINHA_DEMARCACAO= "/DhTipoLinhaDemarcacao/Update";
export const GET_TIPO_LINHA_DEMARCACAO= "/DhTipoLinhaDemarcacao/GetDhTipoLinhaDemarcacao";
export const UPDATE_US_TIPO_PROCESSO= "/UsTipoProcesso/Update";
export const INSERT_US_TIPO_PROCESSO= "/UsTipoProcesso/Insert";
export const UPDATE_AG_TIPO_PRETENSAO = "/AgTipoPretensao/Update";
export const INSERT_AG_TIPO_PRETENSAO = "/AgTipoPretensao/Insert";
export const GET_AG_TIPO_PRETENSAO = "/AgTipoPretensao/GetAgTipoPretensao";
export const GET_SPECIFIC_COLUMNS_MO_SUBTIPO = "/MoModeloSubTipo/GetSpecificColumns";
export const UPDATE_AG_DECISAO = "/AgDecisao/Update";
export const INSERT_AG_DECISAO = "/AgDecisao/Insert";
export const GET_AG_DECISAO = "/AgDecisao/GetAgDecisao";
export const UPDATE_AG_TEMA = "/AgTema/Update";
export const INSERT_AG_TEMA = "/AgTema/Insert";
export const GET_AG_TEMA_ASYNC = "/AgTema/GetAgTema";
export const INSERT_DI_TIPO_ENTIDADE = "/DiTipoEntidade/Insert";
export const UPDATE_DI_TIPO_ENTIDADE = "/DiTipoEntidade/Update";
export const INSERT_DO_TIPO_ACESSO = "/DoTipoAcesso/Insert";
export const UPDATE_DO_TIPO_ACESSO = "/DoTipoAcesso/Update";
export const INSERT_DO_TIPO_RECURSO = "/DoTipoRecurso/Insert";
export const UPDATE_DO_TIPO_RECURSO = "/DoTipoRecurso/Update";
export const INSERT_MO_SUBTIPO_MODELO = "/MoModeloSubTipo/Insert";
export const UPDATE_MO_SUBTIPO_MODELO = "/MoModeloSubTipo/Update";
export const DELETE_MODELO_TIPO = "/MoModeloTipo/Delete";
export const DELETE_OC_ESTADO = "/OcEstado/Delete";
export const DELETE_MO_ESTADO = "/MoEstado/Delete";
export const DELETE_INT_ESTADO = "/IntEstado/Delete";
export const DELETE_ACAO_ESTADO = "/AcaoEstado/Delete";
export const DELETE_MOBILIZACAO_ESTADO = "/IntMobilizacaoEstados/Delete";
export const DELETE_CANDIDATURA_ESTADO = "/CandidaturaEstado/Delete";
export const DELETE_PROCESSO_ESTADO = "/DhEstadoProcesso/Delete";
export const DELETE_US_TITULO_ESTADO = "/UsEstadoTitulo/Delete";
export const DELETE_AG_ESTADO = "/AgEstado/Delete";
export const DELETE_EA_ESTADO = "/EaEstado/Delete";
export const DELETE_DO_ESTADO = "/DoEstado/Delete";
export const DELETE_INT_TIPOS_PLANEAMENTO_ESTADO = "/IntTiposIntervencaoPlaneamentoNivel1/Delete";
export const DELETE_INT_PRIORIDADE = "/IntPrioridade/Delete";
export const DELETE_TIPO_INTERVENCAO = "/IntTipoIntervacao/Delete";
export const DELETE_INT_INSTUMENTO_PLANEAMENTO = "/IntInstrumentoPlaneamentoEstado/Delete";
export const DELETE_INT_TIPO_INCIDENCIA = "/IntTipoIncidencia/Delete";
export const DELETE_INT_TIPO_MOBILIZACAO = "/IntTipoMobilizacao/Delete";
export const DELETE_DH_TIPO_PROCESSO = "/DhTipoProcesso/Delete";
export const DELETE_DH_FASES_PROCEDIMENTO_DELIMITACAO = "/DhFaseProcedimentoDelimitacao/Delete";
export const DELETE_DH_TIPO_DOMINIO = "/DhTipoDominio/Delete";
export const DELETE_DH_LOCALIZACAO_PROCESSO = "/DhLocalizacaoProcesso/Delete";
export const DELETE_DH_VALIDACAO_PROVA = "/DhValidacaoProva/Delete";
export const DELETE_DH_TIPO_SENTENCA = "/DhTipoSentenca/Delete";
export const DELETE_DH_TIPO_PENDENCIA = "/DhTipoPendencia/Delete";
export const DELETE_DH_TIPO_NOTIFICACAO = "/DhTipoNotificacao/Delete";
export const DELETE_DH_TIPO_SITUACAO_NOTIFICACAO = "/DhTipoSituacaoNotificacao/Delete";
export const DELETE_DH_CONDICIONANTES_PROCESSO = "/DhCondicionantesProcesso/Delete";
export const DELETE_DH_TIPO_PARCELA = "/DhTipoParcela/Delete";
export const DELETE_DH_TIPO_ACAO_JUDICIAL = "/DhTipoAcaoJudicial/Delete";
export const DELETE_DH_TIPO_DESAFETACAO = "/DhTipoDesafetacao/Delete";
export const DELETE_DH_TIPO_AQUISICAO= "/DhTipoAquisicao/Delete";
export const DELETE_DH_RECONHECIMENTO_PROPRIEDADE_PRIVADA = "/DhReconhecimentoPropriedadePrivada/Delete";
export const DELETE_DH_TIPO_INICIATIVA = "/DhTipoIniciativa/Delete";
export const DELETE_DH_TIPO_LINHA_DEMARCACAO = "/DhTipoLinhaDemarcacao/Delete";
export const DELETE_US_TIPO_PROCESSO = "/UsTipoProcesso/Delete";
export const DELETE_AG_TIPO_PRETENSAO = "/AgTipoPretensao/Delete";
export const DELETE_AG_DECISAO = "/AgDecisao/Delete";
export const DELETE_AG_TEMA = "/AgTema/Delete";
export const DELETE_DI_TIPO_ENTIDADE = "/DiTipoEntidade/Delete";
export const DELETE_DO_TIPO_ACESSO = "/DoTipoAcesso/Delete";
export const DELETE_DO_TIPO_RECURSO= "/DoTipoRecurso/Delete";
export const DELETE_MO_MODELO_SUBTIPO= "/MoModeloSubtipo/Delete";
export const DELETE_VOO_DRONE = "/VzRegistosVooDrone/Delete"
export const DELETE_VOO_DRONE_PATH = "/VzVooDronePath/Delete"
export const IMAGE_BASE_64_DOCS = "/DoDocumento/GetImagebase64"
export const GET_ASYNC_PROC_CONTRATACAO = "/ProcContratacao/GetAllAsync"

//CLASSIFICADORES_TABLE_NAME  
export const OC_ESTADO_TABLE_NAME = "oc_estado"
export const MO_MODELOS_TIPO_TABLE_NAME = "mo_modelo_tipo"
export const MO_ESTADO_TABLE_NAME = "mo_estado"
export const INT_ESTADO_TABLE_NAME = "int_estado"
export const ACAO_ESTADO_TABLE_NAME = "acao_estado"
export const MOBILIZACAO_ESTADO_TABLE_NAME = "mobilizacao_estado"
export const CANDIDATURA_ESTADO_TABLE_NAME = "candidatura_estado"
export const DH_ESTADO_PROCESSO_TABLE_NAME = "dh_estado_processo"
export const US_ESTADO_TITULO_TABLE_NAME = "us_estado_titulo"
export const AG_ESTADO_TABLE_NAME = "ag_estado"
export const EA_ESTADO_TABLE_NAME = "ea_estado"
export const DO_ESTADO_TABLE_NAME = "do_estado"
export const INT_PLANEAMENTO_NIVEL_1_TABLE_NAME = "int_planeamento_tipo_nivel_1"
export const INT_PRIORIDADE_TABLE_NAME = "int_prioridade"
export const INT_TIPO_INTERVENCAO_TABLE_NAME = "int_tipo_intervencao"
export const INT_INSTRUMENTO_PLANEAMENTO_ESTADO_TABLE_NAME = "int_instrumento_planeamento_estados"
export const INT_TIPO_INCIDENCIA_TABLE_NAME = "int_tipo_incidencia"
export const INT_TIPO_MOBILIZACAO_TABLE_NAME = "int_tipo_mobilizacao"
export const DH_TIPO_PROCESSO_TABLE_NAME = "dh_tipo_processo"
export const DH_FASES_PROCEDIMENTO_DELIMITACAO_TABLE_NAME = "dh_fases_procedimento_delimitacao"
export const DH_TIPO_DOMINIO_TABLE_NAME = "dh_tipo_dominio"
export const DH_LOCALIZACAO_PROCESSO_TABLE_NAME = "dh_localizacao_processo"
export const DH_VALIDACAO_PROVA_TABLE_NAME = "dh_validacao_prova"
export const DH_TIPO_SENTENCA_TABLE_NAME = "dh_tipo_sentença"
export const DH_TIPO_PENDENCIA_TABLE_NAME = "dh_tipo_pendencia"
export const DH_TIPO_NOTIFICACAO_TABLE_NAME = "dh_tipo_notificacao"
export const DH_TIPO_SITUACAO_NOTIFICACAO_TABLE_NAME = "dh_tipo_situacao_notificacao"
export const DH_CONDICIONANTES_PROCESSO_TABLE_NAME = "dh_condicionantes_processo"
export const DH_TIPO_PARCELA_TABLE_NAME = "dh_tipo_parcela"
export const DH_TIPO_ACAO_JUDICIAL_TABLE_NAME = "dh_tipo_acao_judicial"
export const DH_TIPO_DESAFETACAO_TABLE_NAME = "dh_tipo_desafetacao"
export const DH_TIPO_AQUISICAO_TABLE_NAME = "dh_tipo_aquisicao"
export const DH_RECONHECIMENTO_PROPRIEDADE_PRIVADA_TABLE_NAME = "dh_reconhecimento_propriedade_privada"
export const DH_TIPO_INICIATIVA = "dh_tipo_iniciativa"
export const DH_TIPO_LINHA_DEMARCACAO_TABLE_NAME = "dh_tipo_linha_demarcacao"
export const US_TIPO_PROCESSO_TABLE_NAME = "us_tipo_processo"
export const AG_TIPO_PRETENSAO_TABLE_NAME = "ag_tipo_pretensao"
export const AG_DECISAO_TABLE_NAME = "ag_decisao"
export const AG_TEMA_TABLE_NAME = "ag_tema"
export const DI_TIPO_ENTIDADE_TABLE_NAME = "di_tipo_entidade"
export const DO_TIPO_ACESSO_TABLE_NAME = "do_tipo_acesso"
export const DO_TIPO_RECURSO_TABLE_NAME = "do_tipo_recurso"
export const MO_MODELO_SUBTIPO_TABLE_NAME = "mo_modelo_subtipo"

export const XS_WINDOW_WIDTH = 600
export const SM_WINDOW_WIDTH = 760
export const MD_WINDOW_WIDTH = 1000

export const DELETE_FO_FOTO = "/FoFoto/Delete"
export const DELETE_FO_REGISTO_VOO = "/FoRegistoVoo/Delete"

export const GET_MDTS = "/CpgdMdt/GetCpgdMdts";
export const GET_MDTS_FROM_GEOM = "/CpgdMdt/GetMdtsFromGeom"
export const DELETE_MDT = "/CpgdMdt/Delete"
export const GET_MDT = "/CpgdMdt/GetCpgdMdt"



export const clearLayers: Record<string, string> =
{
     "CLEAR_LAYERS_OCORRENCIAS":"SiarlOcorrenciasClearLayersNoMove",
     "CLEAR_LAYERS_ENTIDADES":"SiarlEntidadesClearLayers",
     "CLEAR_LAYERS_DOCUMENTOS":"SiarlDocumentosClearLayers",
     "CLEAR_LAYERS_MODELOS":"SiarlModelosClearLayers",

     "CLEAR_LAYERS_PROCESSOS":"SiarlProcessoClearLayers",
     "CLEAR_LAYERS_PATRIMONIO":"SiarlPatrimonioClearLayers",
     "CLEAR_LAYERS_DEMARCACAO":"SiarlDemarcacaoClearLayers",

     "CLEAR_LAYERS_ACAO":"SiarlIntAcaoClearLayers",
     "CLEAR_LAYERS_INTERVENCAO":"SiarlIntIntervencaoClearLayers",
     "CLEAR_LAYERS_MOBILIZACAO":"SiarlIntMobilizacaoClearLayers",
     "CLEAR_LAYERS_CANDIDATURA":"SiarlCandidaturaClearLayers",

     "CLEAR_LAYERS_PRETENSAO":"SiarlPretensaoClearLayers",
     "CLEAR_LAYERS_DESCONFORMIDADE":"SiarlDesconformidadeClearLayers",

     "CLEAR_LAYERS_US_TITULO":"SiarlUsTituloClearLayers",

     "CLEAR_LAYERS_VOO_DRONE":"SiarlClearVooDroneLine",
     "CLEAR_LAYERS_FOTO_OBLIQUA":"SiarlFotoObliquaClearLayers",
     "CLEAR_LAYERS_ALL":"SiarlClearLayers",
}