import { Button } from '@progress/kendo-react-buttons'
import { DatePicker, MultiViewCalendar, MultiViewCalendarProps } from '@progress/kendo-react-dateinputs'
import { ComboBox, ComboBoxFilterChangeEvent } from '@progress/kendo-react-dropdowns'
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from '@progress/kendo-react-form'

import {
  MouseEvent as ReactMouseEvent,
  useCallback,
  useEffect,
  useState,
} from 'react'

import {
  Input,
  RadioButtonChangeEvent,
  RadioGroup
} from '@progress/kendo-react-inputs'
import clsx from 'clsx'
import {
  entidades,
  fillEntidadesAsync,
  coordenadasPoligono,
  setCoordenadasPoligono,
  hasPermission,
  post,

  //Localização
  nuts1,
  nuts2,
  nuts3,
  distritos,
  concelhos,
  freguesias,
  arhs,
  capitania,

  fillNuts1Async,
  fillNuts2Async,
  fillNuts3Async,
  fillDistritosAsync,
  fillConcelhosAsync,
  fillFreguesiasAsync,
  fillArhsAsync,
  fillCapitaniaAsync,

} from '../../services/utils'
import { useDocumentosStore } from '../../stores/documentos'
import styles from '../../styles/documentos/Documentos.module.scss'
import { AREA_VISIVEL, AUTH_LOCALSTORAGE, NENHUM, PERMISSION_DOCUMENTOS_PERFIL_COMPLETO_EDICAO, PERMISSION_DOCUMENTOS_PERFIL_COMPLETO_LEITURA, PERMISSION_DOCUMENTOS_PERFIL_INTERMEDIO_EDICAO, PERMISSION_DOCUMENTOS_PERFIL_INTERMEDIO_LEITURA, PERMISSION_DOCUMENTOS_PERFIL_TOTAL, PESQUISAR_DOCUMENTOS_SIMPLES, POLIGONO_SELECAO } from '../../utils/constants'

import SessionStorageService from '../../services/SessionStorageService'
import { FilterDescriptor, filterBy } from '@progress/kendo-data-query'
import FieldsetCombosLocalizacao from '../localizacao/FieldsetCombosLocalizacao'
import ReactGA from 'react-ga4';

type Props = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
}

export default function PesquisarDocumentosSimplesForm({
  onCloseClick,
}: Props) {
  const [dataDe, setDataDe] = useState<any>()
  const [dataAte, setDataAte] = useState<any>()
  const [freguesiasFiltred, setFreguesiasFiltred] = useState<any>()
  const [concelhosFiltred, setConcelhosFiltred] = useState<any>();
  const [rbCriteriosSelected, setRBCriteriosSelected] = useState<any>()
  const getDocumentos = async (data: any) =>
    await post<any, any>(`${PESQUISAR_DOCUMENTOS_SIMPLES}`, data)

  const selectedDocumentosResult = useDocumentosStore(
    (state) => state.selectedDocumentosResult
  )

  const [DataPublicacaoVisible, setDataPublicacaoVisible] = useState(true)
  const [CriterioVisible, setDataCriterioVisible] = useState(false)
  const [LocalizacaoVisible, setLocalizacaoVisible] = useState(false)
  const [IdentificacaoVisible, setDataIdentificacaoVisible] = useState(false)
  const [distritosDisabled, setDistritosDisabled] = useState(false);
  const [nuts2Disabled, setNuts2Disabled] = useState(false);
  const [nuts3Disabled, setNuts3Disabled] = useState(false);
  const [concelhosDisabled, setConcelhosDisabled] = useState(true);
  const [freguesiasDisabled, setFreguesiasDisabled] = useState(true);

  const [freguesiasData, setFreguesiasData] = useState(freguesias ? freguesias.slice() : []);
  const [entidadesData, setEntidadesData] = useState(entidades ? entidades.slice() : []);
  const [concelhoData, setConcelhoData] = useState(concelhos ? concelhos.slice() : []);
  const [distritoData, setDistritoData] = useState(distritos ? distritos.slice() : []);
  const [nuts1Data, setNuts1Data] = useState(nuts1 ? nuts1.slice() : []);
  const [nuts2Data, setNuts2Data] = useState(nuts2 ? nuts2.slice() : []);
  const [nuts3Data, setNuts3Data] = useState(nuts3 ? nuts3.slice() : []);
  const [nuts2Filtered, setNuts2Filtered] = useState<any>()
  const [nuts3Filtered, setNuts3Filtered] = useState<any>()
  const [minData, setMinDate] = useState<any>();


  const toggleDataPublicacao = () => {
    setDataPublicacaoVisible(!DataPublicacaoVisible)
  }
  const toggleDataCriterio = () => {
    setDataCriterioVisible(!CriterioVisible)
  }
  const toggleDataLocalizacao = () => {
    setLocalizacaoVisible(!LocalizacaoVisible)
  }
  const toggleDataIdentificacao = () => {
    setDataIdentificacaoVisible(!IdentificacaoVisible)
  }

  useEffect(() => {
    //ReactGA.pageview(window.location.pathname + "PesquisarDocumentosSimplesForm");
    ReactGA.send({ hitType: "pageview", page: "/PesquisarDocumentosSimplesForm" });
  }, [])

useEffect(() => {
    
     if(!nuts1 ){
      fillNuts1Async();
      }
     if(!nuts2) {
      fillNuts2Async();
     }
     if(!nuts3){
      fillNuts3Async();
     }
     if(!distritos){
      fillDistritosAsync();
     }
     if(!concelhos){
      fillConcelhosAsync();
     }
     if(!freguesias){
      fillFreguesiasAsync();
     }
     if(!arhs){
      fillArhsAsync();
     }
     if(!capitania){
      fillCapitaniaAsync();
     }
     
     if(!entidades){
      fillEntidadesAsync();
     }

  }, []);

  useEffect(() => {
    SessionStorageService.setItem("DocumentosPesquisaTipo", "Simples");
  }, []);


  const concelhoSelectChange = (item: any) => {
    if (!item) {
      setFreguesiasFiltred(freguesias)
      setNuts2Disabled(false);
      setNuts3Disabled(false);
      setFreguesiasDisabled(true);
      return
    }
    setNuts2Disabled(true);
    setNuts3Disabled(true);
    setFreguesiasDisabled(false);
    setFreguesiasFiltred(
      freguesias.filter((x) => x.concelho_cod == item.codigo)
    )
  }

  const distritoSelectChange = (item: any) => {
    if (!item) {
      setConcelhosFiltred([]);
      setNuts2Disabled(false);
      setNuts3Disabled(false);
      setConcelhosDisabled(true);
      return;
    }
    setConcelhosFiltred(concelhos.filter(x => x.distrito_cod == item.codigo));
    setConcelhosDisabled(false);
    setNuts2Disabled(true);
    setNuts3Disabled(true);
  }

  const changeNuts2 = (item: any) => {
    if (!item?.value?.codigo) {
      setDistritosDisabled(false);
      setConcelhosFiltred([]);
      setFreguesiasFiltred([]);
      setNuts3Disabled(true)
      setNuts3Filtered(nuts3)
      setNuts3Data(nuts3);
      return;
    }else{
      const nutsLocal = nuts3.filter(
        (x: any) => x.nuts_ii_cod == item.value.codigo
      )
      setNuts3Filtered(nutsLocal)
      setNuts3Data(nutsLocal);
      setNuts3Disabled(false) 
    }
    setDistritosDisabled(true);
  }

  const filterDataNuts3 = (filter: FilterDescriptor) => {
    const data = nuts3Filtered.slice();
    return filterBy(data, filter);
  };

  const filterChangeNuts3 = (event: ComboBoxFilterChangeEvent) => {
    setNuts3Data(filterDataNuts3(event.filter));
  };

  const handleSubmit = async (e: any) => {


    console.log(JSON.stringify(e.values))
    const userLocal = JSON.parse(
      localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}'
    );

    postMessage({ type: 'showLoader' }, '*')
    const objToSearch = {
      
      // Data de Publicação
      data_inicio: e.values?.De ?? null,
      data_fim: e.values?.Ate ?? null,

      // Critério Geográfico
      criterio_geografico: rbCriteriosSelected ?? null,

      // Localização
      nuts1_cod: e.values?.nuts1?.codigo,
      nuts2_cod: e.values?.nuts2?.codigo,
      nuts3_cod: e.values?.nuts3?.codigo,
      distrito_cod: e.values?.distrito?.codigo,
      concelho_cod: e.values?.concelho?.codigo,
      freguesia_cod: e.values?.freguesia?.dicofre,

      // Identificação
      entidade: e.values?.entidade?.codigo,
      titulo: e.values?.titulo ?? null,
      autores: e.values?.autores ?? null,
      
      flatCoordinates: coordenadasPoligono,
      id_user: userLocal?.user?.id
    }

    sessionStorage.setItem("lastSearchDocumentosSimples", JSON.stringify(objToSearch));
    const result: any = await getDocumentos(objToSearch);
    SessionStorageService.setItem("firstCallDocumentoSimples", true);

    setCoordenadasPoligono(undefined);
    if (result) {
      postMessage({ type: 'resultadoDocumentosPesquisaChange' }, '*')
      SessionStorageService.setItem(
        'resultadoDocumentosPesquisa',
        JSON.stringify(result)
      )

    }
    postMessage({ type: 'hideLoader' }, '*')
  }

  const clearMap = () => {
    try {

      setCoordenadasPoligono(undefined)
      const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.contentWindow?.postMessage({ type: "SiarlDocumentosClearLayers", value: "true" }, '*')
      iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: "true" }, '*')
      iframe.contentWindow?.postMessage({ type: "SiarlDeselectMapArea", value: "true" }, '*')

    } catch (error) {
      console.log({ error })
    }
  }

  const changerbCriterio = useCallback((e: RadioButtonChangeEvent) => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlDeselectMapArea", value: 'true' }, '*')
      setRBCriteriosSelected(e.value)
      if (e.value == POLIGONO_SELECAO) {
        postMessage({ type: "informationMsg", value: "Selecione a área de pesquisa" }, "*");
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage({ type: "SiarlGeoPortalAdicionarArea", value: 'true' }, '*')
      }
      if (e.value == AREA_VISIVEL) {
        postMessage({ type: "informationMsg", value: "Foi selecionada a área visível do mapa" }, "*");
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage({ type: "SiarlGetMapArea", value: 'true' }, '*')
        iframe.contentWindow?.postMessage({ type: "SiarlSelectMapArea", value: 'true' }, '*')
      }
    },
    [setRBCriteriosSelected]
  )

  const filterNuts2 = (e: any) => {
    if (e?.value?.codigo) {
      const nutsLocal = nuts2.filter((x: any) => x.nuts_i_cod == e.value.codigo)
      setNuts2Filtered(nutsLocal)
      setNuts2Data(nutsLocal);
      setNuts2Disabled(false)
    } else {
      setNuts2Disabled(true)
      setNuts2Filtered(nuts2)
      setNuts2Data(nuts2);
    }
  }

  const filterDataNuts1 = (filter: FilterDescriptor) => {
    const data = nuts1.slice();
    return filterBy(data, filter);
  };
  
  const filterChangeNuts1 = (event: ComboBoxFilterChangeEvent) => {
    setNuts1Data(filterDataNuts1(event.filter));
  };

  
  const filterChangeNuts2 = (event: ComboBoxFilterChangeEvent) => {
    setNuts2Data(filterDataNuts2(event.filter));
  };
  const filterDataNuts2 = (filter: FilterDescriptor) => {
    const data = nuts2Filtered.slice();
    return filterBy(data, filter);
  };
  const filterDataDistrito = (filter: FilterDescriptor) => {
    const data = distritos.slice();
    return filterBy(data, filter);
  };

  const filterChangeDistrito = (event: ComboBoxFilterChangeEvent) => {
    setDistritoData(filterDataDistrito(event.filter));
  };
  const filterDataConcelho = (filter: FilterDescriptor) => {
    const data = concelhosFiltred.slice();
    return filterBy(data, filter);
  };

    const filterChangeConcelho = (event: ComboBoxFilterChangeEvent) => {
    setConcelhoData(filterDataConcelho(event.filter));
  };
    const filterDataFreguesia = (filter: FilterDescriptor) => {
    const data = freguesiasFiltred.slice();
    return filterBy(data, filter);
  };

  const filterChangeFreguesia = (event: ComboBoxFilterChangeEvent) => {
    setFreguesiasData(filterDataFreguesia(event.filter));
  }; 
  const filterDataEntidades = (filter: FilterDescriptor) => {
    const data = entidades.slice();
    return filterBy(data, filter);
  };
  const filterChangeEntidades = (event: ComboBoxFilterChangeEvent) => {
    setEntidadesData(filterDataEntidades(event.filter));
  };

  const CustomCalendar = (props: MultiViewCalendarProps) => {
    return <MultiViewCalendar {...props} views={1} />
  }

  const canSeeDocumentos = hasPermission(PERMISSION_DOCUMENTOS_PERFIL_COMPLETO_EDICAO) || 
                           hasPermission(PERMISSION_DOCUMENTOS_PERFIL_COMPLETO_LEITURA) || 
                           hasPermission(PERMISSION_DOCUMENTOS_PERFIL_TOTAL) ||
                           hasPermission(PERMISSION_DOCUMENTOS_PERFIL_INTERMEDIO_EDICAO)
                           hasPermission(PERMISSION_DOCUMENTOS_PERFIL_INTERMEDIO_LEITURA); 
  return (
    <Form
      onSubmitClick={handleSubmit}
      initialValues={selectedDocumentosResult ?? undefined}
      render={(formRenderProps: FormRenderProps) => (
        <>

          <FormElement className={styles.form}>
            <div
              className="containerActions"
              style={{ minHeight: 'calc(100vh - 215px)' }}
            >
              <div className="contentActions">
                <fieldset>
                  <legend>
                    <button type='button' className={styles.itemExpandBase} onClick={toggleDataPublicacao}>
                      <div className={styles.btn_Expandir_Colapsar}>
                        <span className={DataPublicacaoVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                      </div>
                      <span className={styles.fieldsetTitulo}>
                        Data de publicação
                      </span>
                    </button>
                  </legend>
                  {DataPublicacaoVisible && (
                    <div className={clsx(styles.fields, styles.espacoVerticalFieldSet)}>
                      <div>
                        <Field
                          name="De"
                          label="De"
                          value={dataDe}
                          onChange={(e) => {
                            setDataDe(e.value)
                             setMinDate(e.value);
                          }}     
                          format="dd/MM/yyyy"
                          component={DatePicker}
                          calendar={CustomCalendar}
                        />
                      </div>
                      <div>
                        <Field
                          name="Ate"
                          label="Ate"
                          format="dd/MM/yyyy"
                          component={DatePicker}
                          onChange={(e) => {
                            setDataAte(e.value)
                          }}
                          min={minData}
                          calendar={CustomCalendar}
                        />
                      </div>
                    </div>
                  )}
                </fieldset>
               {canSeeDocumentos?
              <fieldset>
              <legend>
                <button type='button' className={styles.itemExpandBase} onClick={toggleDataCriterio}>
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span className={CriterioVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                  </div>
                  <span className={styles.fieldsetTitulo}>Critério geográfico</span>
                </button>
              </legend>

              {CriterioVisible && (
                <div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}>
                  <Field
                    id={'rbCriterio'}
                    name={'rbCriterio'}
                    layout={'horizontal'}
                    component={RadioGroup}
                    onChange={(e) => {
                      changerbCriterio(e)
                    }}
                    data={[
                      { label: 'Nenhum', value: NENHUM},
                      { label: 'Área visível', value: AREA_VISIVEL },
                      { label: 'Polígono de seleção', value: POLIGONO_SELECAO },
                    ]}
                    defaultValue={NENHUM} // Set the default selected value to 0 (Nenhum)
                  />
                </div>
              )}
            </fieldset>
            :<></>
          }
              <FieldsetCombosLocalizacao styles={styles} formRenderProps={formRenderProps}/>
                {canSeeDocumentos ?
                  <fieldset>
                    <legend>
                      <button type='button' className={styles.itemExpandBase} onClick={toggleDataIdentificacao}>
                        <div className={styles.btn_Expandir_Colapsar}>
                          <span className={IdentificacaoVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                        </div>
                        <span className={styles.fieldsetTitulo}>Identificação</span>
                      </button>
                    </legend>
                    {IdentificacaoVisible && (
                      <div className={styles.espacoVerticalFieldSet}>
                        <div className={styles.fields}>
                          <div>
                            <Field
                              name="entidade"
                              label="Entidade"
                              component={ComboBox}
                              textField={'nome'}
                              filterable={true}
                              onFilterChange={filterChangeEntidades}
                              data={entidadesData} />
                          </div>
                          <div>
                            <Field
                              name="titulo"
                              label="Título"
                              component={Input}
                              textField={'nome'}
                            />
                          </div>
                          <div>
                            <Field
                              name="autores"
                              label="Autores"
                              component={Input}
                              textField={'nome'}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </fieldset>
                  : <></>}
              </div>
            </div>
          </FormElement>
          <div className={`${styles.footer} ${styles.justify_multiple_children}`}>
            <Button
              onClick={() => { formRenderProps.onFormReset(); clearMap()}}
              className={styles.btnLimparPesquisa}
              title='Limpar pesquisa'
            >
              <span className="icon-siarl icon-limparcampos"></span>
              <span className={styles.rotulo}>
                {' '}&nbsp;{' '}
                Limpar pesquisa
              </span>          
            </Button>
            <Button
              onClick={formRenderProps.onSubmit}
              className={styles.btnPesquisar}
              title='Pesquisar'
            >
              <span className="icon-siarl icon-pesquisar"></span>
              <span className={styles.rotulo}>
                {' '}&nbsp;{' '}
                Pesquisar
              </span>              
            </Button>
          </div>
        </>
      )}></Form>
  )
}
