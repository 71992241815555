import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import {
  Form,
  Field,
  FormElement,
  FieldRenderProps,
} from "@progress/kendo-react-form";
import { Input, NumericTextBox, TextArea } from "@progress/kendo-react-inputs";
import { ComboBox, ComboBoxFilterChangeEvent, DropDownList } from "@progress/kendo-react-dropdowns";
import { Error } from "@progress/kendo-react-labels";
import { APOIO_GESTAO_MODULE_NAME, CONSULTAR_AG_PRETENSAO, CONSULTAR_REGISTOS_VOO_DRONE, CONSULTA_AG_DESCONFORMIDADE, DOCUMENTOS_MODULE_NAME, DOMINIO_HIDRICO_MODULE_NAME, ENTIDADES_MODULE_NAME, GET_ALL_ACOES, GET_ALL_INT_INTERVENCOES, GET_ALL_MOBILIZACOES, GET_ALL_MODELOS, GET_OCORRENCIAS_PARA_ASSOCIAR, GET_PATRIMONIO, GET_USOS_SOLO_SOFT, INTERVENCAO_MODULE_NAME, MODELOS_MODULE_NAME, OCORRENCIAS_MODULE_NAME, PESQUISAR_DEMARCACAO, PESQUISAR_DH_DEMARCACOES, PESQUISAR_DH_PATRIMONIOS, PESQUISAR_DH_PROCESSOS, PESQUISAR_DOCUMENTOS_ALL, PESQUISAR_DOCUMENTOS_ALL_SOFT, PESQUISAR_ENTIDADES, PESQUISAR_OCORRENCIA_ALL, USO_DO_SOLO_MODULE_NAME, VOO_DRONE_MODULE_NAME } from "../../utils/constants";
import { get, getAcaoSelected, getDemarcacaoSelected, getDesconformidadeSelected, getIntervencaoSelected, getMobilizacaoSelected, getPatrimonioSelected, getPretensaoSelected, getProcessoSelected, post } from "../../services/utils";
import { useEffect, useState } from "react";
import { useEntidadeStore } from "../../stores/entidade";
import { Drawer, DrawerContent } from "@progress/kendo-react-layout";
import { FilterDescriptor, filterBy } from "@progress/kendo-data-query";
import parse from 'html-react-parser';
import { format } from "date-fns";
import { Button } from "@progress/kendo-react-buttons";
import { dropdownRequiredValidator, requiredValidator } from "../modelos/registar/fields/validators";

const getDocumentos = async (data: any) => await get<any, any>(`${PESQUISAR_DOCUMENTOS_ALL_SOFT}`);
const getOcorrencias = async (data: any) => await get<any, any>(`${GET_OCORRENCIAS_PARA_ASSOCIAR}`);
const getEntidades = async (data: any) => await get<any, any>(`${PESQUISAR_ENTIDADES}`);
const getPretensoes = async (data: any) => await post<any, any>(`${CONSULTAR_AG_PRETENSAO}`, data);
const getDesconformidades = async (data: any) => await post<any, any>(`${CONSULTA_AG_DESCONFORMIDADE}`, data);
const getModelos = async (data: any) => await get<any, any>(`${GET_ALL_MODELOS}`);
const getUsosSolo = async (data: any) => await get<any>(GET_USOS_SOLO_SOFT);
const getRegistosVooDrone = async () => await post<any, any>(`${CONSULTAR_REGISTOS_VOO_DRONE}`, {});
const getDhDemarcacoes = async () => await get<any>(PESQUISAR_DH_DEMARCACOES);
const getDhProcessos = async () => await get<any>(PESQUISAR_DH_PROCESSOS);
const getDhPatrimonios = async () => await get<any>(PESQUISAR_DH_PATRIMONIOS);
const getIntIntervencoes = async () => await get<any>(GET_ALL_INT_INTERVENCOES);
const getIntAcoes = async () => await get<any>(GET_ALL_ACOES);
const getIntMobilizacoes = async () => await get<any>(GET_ALL_MOBILIZACOES);
//      const voosData = await post<FiltroVzRegistoVooDroneDTO, any>(`${CONSULTAR_REGISTOS_VOO_DRONE}`, voo_registos_drone_dto);

interface EditFormProps {
  cancelEdit: () => void;
  onSubmit: (event: any) => void;
  item: any;
}

const minValueValidator = (value: number) =>
  value >= 0 ? "" : "The value must be 0 or higher";
const NonNegativeNumericInput = (fieldRenderProps: FieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <NumericTextBox {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

export default function EditAssociacaoForm(props: EditFormProps) {
  const [documentos, setDocumentos] = useState<any>();
  const [ocorrencias, setOcorrencias] = useState<any>();
  const [entidades, setEntidades] = useState<any>();
  const [pretensoes, setPretensoes] = useState<any>();
  const [usosSoloTitulo, setUsosSoloTitulo] = useState<any>();
  const [registosVooDrone, setRegistosVooDrone] = useState<any>();
  const [desconformidades, setDesconformidades] = useState<any>();
  const [demarcacoes, setDemarcacoes] = useState<any>();
  const [demarcacoesData, setDemarcoesData] = useState<any>();
  const [processos, setProcessos] = useState<any>();
  const [patrimonios, setPatrimonios] = useState<any>();
  const [processosData, setProcessosData] = useState<any>();
  const [patrimoniosData, setPatrimoniosData] = useState<any>();
  const [acoes, setAcoes] = useState<any>();
  const [acoesData, setAcoesData] = useState<any>();
  const [mobilizacoes, setMobilizacoes] = useState<any>();
  const [mobilizacoesData, setMobilizacoesData] = useState<any>();
  const [intervencoes, setIntervencoes] = useState<any>();
  const [intervencoesData, setIntervencoesData] = useState<any>();
  const selectedEntidadeResult = useEntidadeStore(state => state.selectedEntidadeResult);
  const [modulos, setModulos] = useState<any>();
  const [selectedModulo, setSelectedModulo] = useState<string>("");
  const AG_PRETENSOES = APOIO_GESTAO_MODULE_NAME + ' - ' + 'Pretensões';
  const AG_DESCONFORMIDADES = APOIO_GESTAO_MODULE_NAME + ' - ' + 'Desconformidades';
  const DH_PROCESSOS = DOMINIO_HIDRICO_MODULE_NAME + ' - Processos';
  const DH_DEMARCACAO = DOMINIO_HIDRICO_MODULE_NAME + ' - Demarcação';
  const DH_PATRIMONIO = DOMINIO_HIDRICO_MODULE_NAME + ' - Património';
  const INT_INTERVENCAO = INTERVENCAO_MODULE_NAME + ' - Intervenção';
  const INT_ACAO = INTERVENCAO_MODULE_NAME + ' - Ação';
  const INT_MOBILIZACAO = INTERVENCAO_MODULE_NAME + ' - Mobilização';
  const US_TITULO = USO_DO_SOLO_MODULE_NAME + ' - Titulos';
  const [entidadesData, setEntidadesData] = useState(entidades ? entidades.slice() : []);
  const [usosdeSoloTituloData, setUsosDeSoloTituloData] = useState(usosSoloTitulo ? usosSoloTitulo.slice() : []);
  const [ocorrenciasData, setOcorrenciasData] = useState(ocorrencias ? ocorrencias.slice() : []);
  const [documentosData, setDocumentosData] = useState(documentos ? documentos.slice() : []);
  const [registoResumo, setRegistoResumo] = useState<string>('');
  const [algumaOpcaoSelecionada, setAlgumaOpcaoSelecionada] = useState(false);

  useEffect(() => {
    addModulos();
    console.log(selectedEntidadeResult);
  }, []);

  const addModulos = () => {
    const modulosLocal: any[] = [];
    if (sessionStorage.getItem("Modulo") != OCORRENCIAS_MODULE_NAME) {
      modulosLocal.push({ value: OCORRENCIAS_MODULE_NAME });
    }
    if (sessionStorage.getItem("Modulo") != USO_DO_SOLO_MODULE_NAME) {
      modulosLocal.push({ value: US_TITULO });
    }
   
    const pretensoesSelected = getPretensaoSelected();
    const desconformidadeSelected = getDesconformidadeSelected();
    if (!pretensoesSelected || !pretensoesSelected?.id) {
      modulosLocal.push({ value: AG_PRETENSOES });
    }
    if (!desconformidadeSelected || !desconformidadeSelected?.id) {
      modulosLocal.push({ value: AG_DESCONFORMIDADES });
    }
    const processosSelected = getProcessoSelected();
    const demarcacaoSelected = getDemarcacaoSelected();
    const patrimonioSelected = getPatrimonioSelected();
    if (!processosSelected || !processosSelected?.id) {
      modulosLocal.push({ value: DH_PROCESSOS });
    }
    if (!demarcacaoSelected || !demarcacaoSelected?.id) {
      modulosLocal.push({ value: DH_DEMARCACAO });
    }  
    if (!patrimonioSelected || !patrimonioSelected?.id) {
      modulosLocal.push({ value: DH_PATRIMONIO });
    }

    const intIntervencaoSelected = getIntervencaoSelected();
    if (!intIntervencaoSelected || !intIntervencaoSelected?.id) {
      modulosLocal.push({ value: INT_INTERVENCAO });
    }

    const intAcaoSelected = getAcaoSelected();
    if (!intAcaoSelected || !intAcaoSelected?.id) {
      modulosLocal.push({ value: INT_ACAO })
    }

    const intMobilizacaoSelected = getMobilizacaoSelected();
    if (!intMobilizacaoSelected || !intMobilizacaoSelected?.id) {
      modulosLocal.push({ value: INT_MOBILIZACAO });
    }

    setModulos(modulosLocal);
  }

  const handleModuloSelected = async (e: any) => {
    const moduloSelectedLocal = e.value?.value;
    setSelectedModulo(moduloSelectedLocal);
    setOcorrenciasData([]);
    setOcorrencias([]);
    setRegistoResumo('');
    if (moduloSelectedLocal == OCORRENCIAS_MODULE_NAME) {
      postMessage({ type: "showLoader" }, "*");
      const result: any[] = await getOcorrencias([]);
      setOcorrencias(
        result
      )
      setOcorrenciasData(
        result
      )
      postMessage({ type: "hideLoader" }, "*");
    }

    if (moduloSelectedLocal == ENTIDADES_MODULE_NAME) {
      postMessage({ type: "showLoader" }, "*");
      const result = await getEntidades([]);
      setEntidades(result);
      setEntidadesData(result);
      postMessage({ type: "hideLoader" }, "*");
    }

    if (moduloSelectedLocal == DOCUMENTOS_MODULE_NAME) {
      postMessage({ type: "showLoader" }, "*");
      const result = await getDocumentos([]);
      setDocumentos(result);
      setDocumentosData(result);
      postMessage({ type: "hideLoader" }, "*");
    }

    if (moduloSelectedLocal == AG_PRETENSOES) {
      postMessage({ type: "showLoader" });
      const result = await getPretensoes({});
      setPretensoes(result);
      postMessage({ type: "hideLoader" }, "*");
    }

    if (moduloSelectedLocal == AG_DESCONFORMIDADES) {
      postMessage({ type: "showLoader" });
      const result = await getDesconformidades({});
      setDesconformidades(result);
      postMessage({ type: "hideLoader" }, "*");

    } if (moduloSelectedLocal == US_TITULO) {
      postMessage({ type: "showLoader" });
      const result = await getUsosSolo({});
      setUsosSoloTitulo(result);
      setUsosDeSoloTituloData(result);
      postMessage({ type: "hideLoader" }, "*");
    }
    if (moduloSelectedLocal == VOO_DRONE_MODULE_NAME) {
      postMessage({ type: "showLoader" });
      const result = await getRegistosVooDrone();
      setRegistosVooDrone(result);
      postMessage({ type: "hideLoader" }, "*");
    }

    if (moduloSelectedLocal == DH_DEMARCACAO) {
      postMessage({ type: "showLoader" });
      const result = await getDhDemarcacoes();
      setDemarcacoes(result);
      setDemarcoesData(result);
      postMessage({ type: "hideLoader" }, "*");
    }

    if (moduloSelectedLocal == DH_PROCESSOS) {
      postMessage({ type: "showLoader" });
      const result = await getDhProcessos();
      setProcessos(result);
      setProcessosData(result);
      postMessage({ type: "hideLoader" }, "*");
    }

    if (moduloSelectedLocal == DH_PATRIMONIO) {
      postMessage({ type: "showLoader" });
      const result = await getDhPatrimonios();
      setPatrimonios(result);
      setPatrimoniosData(result);
      postMessage({ type: "hideLoader" }, "*");
    }

    if (moduloSelectedLocal == INT_INTERVENCAO) {
      postMessage({ type: "showLoader" });
      const result = await getIntIntervencoes();
      setIntervencoes(result);
      setIntervencoesData(result);
      postMessage({ type: "hideLoader" }, "*");
    }

    if (moduloSelectedLocal == INT_ACAO) {
      postMessage({ type: "showLoader" });
      const result = await getIntAcoes();
      setAcoes(result);
      setAcoesData(result);
      postMessage({ type: "hideLoader" }, "*");
    }

    if (moduloSelectedLocal == INT_MOBILIZACAO) {
      postMessage({ type: "showLoader" });
      const result = await getIntMobilizacoes();
      setMobilizacoes(result);
      setMobilizacoesData(result);
      postMessage({ type: "hideLoader" }, "*");
    }

    console.log(selectedModulo);
  }

  const filterDataEntidades = (filter: FilterDescriptor) => {
    const data = entidades.slice();
    return filterBy(data, filter);
  };

  const filterChangeEntidades = (event: ComboBoxFilterChangeEvent) => {
    setEntidadesData(filterDataEntidades(event.filter));
  };

  const filterDataUsosDeSolo = (filter: FilterDescriptor) => {
    const data = usosSoloTitulo?.slice();
    return filterBy(data, filter);
  };

  const filterChangeUsosDeSolo = (event: ComboBoxFilterChangeEvent) => {
    setUsosDeSoloTituloData(filterDataUsosDeSolo(event.filter));
  };

  const filterDataOcorrencias = (filter: FilterDescriptor) => {
    const data = ocorrencias?.slice();
    return filterBy(data, filter);
  };

  const filterChangeOcorrencias = (event: ComboBoxFilterChangeEvent) => {
    setOcorrenciasData(filterDataOcorrencias(event?.filter));
  };

  const filterDataDocumentos = (filter: FilterDescriptor) => {
    const data = documentos.slice();
    return filterBy(data, filter);
  };

  const filterChangeDocumentos = (event: ComboBoxFilterChangeEvent) => {
    setDocumentosData(filterDataDocumentos(event.filter));
  };

  const filterDataDemarcacoes = (filter: FilterDescriptor) => {
    const data = demarcacoes?.slice();
    return filterBy(data, filter);
  };

  const filterChangeDemarcacoes = (event: ComboBoxFilterChangeEvent) => {
    setDemarcoesData(filterDataDemarcacoes(event?.filter));
  };

  const filterDataProcessos = (filter: FilterDescriptor) => {
    const data = processos?.slice();
    return filterBy(data, filter);
  };

  const filterChangeProcessos = (event: ComboBoxFilterChangeEvent) => {
    setProcessosData(filterDataProcessos(event?.filter));
  };


  const filterDataPatrimonios = (filter: FilterDescriptor) => {
    const data = patrimonios?.slice();
    return filterBy(data, filter);
  };

  const filterChangePatrimonio = (event: ComboBoxFilterChangeEvent) => {
    setPatrimoniosData(filterDataPatrimonios(event?.filter));
  };


  const filterDataIntAcoes = (filter: FilterDescriptor) => {
    const data = processos?.slice();
    return filterBy(data, filter);
  };

  const filterChangeIntAcoes = (event: ComboBoxFilterChangeEvent) => {
    setAcoesData(filterDataIntAcoes(event?.filter));
  };

  const filterDataIntervencoes = (filter: FilterDescriptor) => {
    const data = intervencoes?.slice();
    return filterBy(data, filter);
  };

  const filterChangeIntervencoes = (event: ComboBoxFilterChangeEvent) => {
    setIntervencoesData(filterDataIntervencoes(event?.filter));
  };

  const filterDataIntMobilizacoes = (filter: FilterDescriptor) => {
    const data = mobilizacoes?.slice();
    return filterBy(data, filter);
  };

  const filterChangeIntMobilizacoes = (event: ComboBoxFilterChangeEvent) => {
    setMobilizacoesData(filterDataIntMobilizacoes(event?.filter));
  };

  const initialText = "<br/><div>Resumo do registo selecionado</div><fieldset class='fieldset-associacao'>";

  const OcorrenciasChange = async (event: any) => {
    if (event?.value?.id) {
      const text = initialText + 'Id:' +
        event.value.id + "<br/>Data: " + format(
          new Date(event.value.data_registo),
          'dd/MM/yyy HH:mm'
        ) + "<br/> Tipo: " +
        event.value.tipo.replaceAll('|', '<br/>');
      "</fieldset>"
      setRegistoResumo(text);
    } else {
      setRegistoResumo('');
    }
  }

  const UsoSoloTituloChange = async (event: any) => {
    if (event?.value?.id) {
      const text = initialText + 'Id:' +
        event.value.id + "<br/>Data: " + format(
          new Date(event.value.data),
          'dd/MM/yyy HH:mm'
        ) + "<br/> Tipo: " +
        event.value.tipo;
      "</fieldset>"
      setRegistoResumo(text);
    } else {
      setRegistoResumo('');
    }
  }

  const DocumentosChange = async (event: any) => {
    if (event?.value?.id) {
      const text = initialText + 'Id:' +
        event.value.id + "<br/>Data: " + format(
          new Date(event.value.data),
          'dd/MM/yyy HH:mm'
        ) +
        (event.value.tipo ? "<br/> Tipo: " + event.value.tipo : '');
      "</fieldset>"
      setRegistoResumo(text);
    } else {
      setRegistoResumo('');
    }
  }

  const AgDesconformidadeChange = async (event: any) => {
    if (event?.value?.id) {
      const text = initialText + 'Id:' +
        event.value.id + "<br/>Data: " + format(
          new Date(event.value.data_registo),
          'dd/MM/yyy HH:mm'
        ) +
        (event.value.assunto ? "<br/> Assunto: " + event.value.assunto : '');
      "</fieldset>"
      setRegistoResumo(text);
    } else {
      setRegistoResumo('');
    }
  }

  const AgPretensaoChange = async (event: any) => {
    if (event?.value?.id) {
      const text = initialText + 'Id:' +
        event.value.id + "<br/>Data: " + format(
          new Date(event.value.data_registo),
          'dd/MM/yyy HH:mm'
        ) +
        (event.value.tipo ? "<br/> Tipo: " + event.value.tipo : '');
      "</fieldset>"
      setRegistoResumo(text);
    } else {
      setRegistoResumo('');
    }
  }
  const DhDemarcacaoChange = async (event: any) => {
    if (event?.value?.id) {
      const text = initialText + 'Id:' +
        event.value.id + "<br/>Data: " + format(
          new Date(event.value.data_registo),
          'dd/MM/yyy HH:mm'
        ) +
        (event.value.dhTipoLinhaDemarcacao ? "<br/> Tipo linha: " + event.value.dhTipoLinhaDemarcacao.designacao : '');
      "</fieldset>"
      setRegistoResumo(text);
    } else {
      setRegistoResumo('');
    }
  }
  
  const DhProcessoChange = async (event: any) => {
    if (event?.value?.id) {
      const text = initialText + 'Id:' +
        event.value.id + "<br/>Data: " + format(
          new Date(event.value.data_registo),
          'dd/MM/yyy HH:mm'
        ) +
        (event.value.dhTipoProcesso ? "<br/> Tipo processo: " + event.value.dhTipoProcesso.descricao : '');
      "</fieldset>"
      setRegistoResumo(text);
    } else {
      setRegistoResumo('');
    }
  }

  const DhPatrimonioChange = async (event: any) => {
    if (event?.value?.id) {
      const text = initialText + 'Id:' +
        event.value.id + "<br/>Data: " + format(
          new Date(event.value.data_registo),
          'dd/MM/yyy HH:mm'
        ) 
      "</fieldset>"
      setRegistoResumo(text);
    } else {
      setRegistoResumo('');
    }
  }

  const intIntervencaoChange = async (event: any) => {
    if (event?.value?.id) {
      const text = initialText + 'Id:' +
        event.value.id + "<br/>Data Inicio: " + (event.value.inicio ? format(
          new Date(event.value.inicio),
          'dd/MM/yyy HH:mm'
        ) : '') +
        (event.value.int_pal_xxi_tip_nivel_3 ? "<br/> Tipologia n3: " + event.value.int_pal_xxi_tip_nivel_3.descricao : '');
      "</fieldset>"
      setRegistoResumo(text);
    } else {
      setRegistoResumo('');
    }
  }

  const intAcaoChange = async (event: any) => {
    if (event?.value?.id) {
      const text = initialText + 'Id:' +
        event.value.id + "<br/>Data Inicio Ex.: " + (event.value.data_ini_e ? format(
          new Date(event.value.data_ini_e),
          'dd/MM/yyy HH:mm'
        ) : '') +
        (event.value.tipo_n4 ? "<br/> Tipologia n3: " + event.value.tipo_n4.descricao : '');
      "</fieldset>"
      setRegistoResumo(text);
    } else {
      setRegistoResumo('');
    }
  }

  const intMobilizacaoChange = async (event: any) => {
    if (event?.value?.id) {
      const text = initialText + 'Id:' +
        event.value.id + "<br/>Data de Mobilização: " + (event.value.data ? format(
          new Date(event.value.data),
          'dd/MM/yyy HH:mm'
        ) : '') +
        (event.value.int_mobilizacao_funcao ? "<br/> Função: " + event.value.int_mobilizacao_funcao.descricao : '');
      "</fieldset>"
      setRegistoResumo(text);
    } else {
      setRegistoResumo('');
    }
  }


  return (
    <Dialog
      className="ModalDocumentos"
      title={props.item.id ? `Editar Associação ${props.item.id}` : `Registar Associação`}
      onClose={props.cancelEdit}
      style={{ zIndex: 999 }}
    >
      <Form
        onSubmit={props.onSubmit}
        initialValues={props.item}
        render={(formRenderProps) => (
          <FormElement>

            <div className='form'>

              <fieldset>

                <div className='espacoVerticalFieldSet'>

                  <div className="fields">

                    <div>
                      <Field
                        name={"modulo"}
                        component={ComboBox}
                        data={modulos}
                        textField={"value"}
                        label={"Módulo"}
                        onChange={(e) => {

                          formRenderProps?.onChange('diEntidade', { value: null });
                          formRenderProps?.onChange('doDocumento', { value: null });
                          formRenderProps?.onChange('ocOcorrencia', { value: null });
                          formRenderProps?.onChange('agPretensao', { value: null });
                          formRenderProps?.onChange('agDesconformidade', { value: null });
                          formRenderProps?.onChange('dhDemarcacao', { value: null });
                          formRenderProps?.onChange('dhProcessos', { value: null });
                          formRenderProps?.onChange('dhPatrimonio', { value: null });
                          formRenderProps?.onChange('usTitulo', { value: null });
                          formRenderProps?.onChange('intIntervencao', { value: null });
                          formRenderProps?.onChange('intAcao', { value: null });
                          formRenderProps?.onChange('intMobilizacao', { value: null });
                          formRenderProps?.onChange('vz_registo_voo_drone', { value: null });
    
                          handleModuloSelected(e);
                        }}
                      />
                    </div>

                  </div>

                  <div className="fields">

                    {selectedModulo == ENTIDADES_MODULE_NAME ?
                      <div>
                        <Field
                          name={"diEntidade"}
                          component={DropDownList}
                          validator={dropdownRequiredValidator}
                          data={entidadesData && entidadesData.filter((entidade: Record<string, unknown>) => Boolean(entidade.nome))}
                          textField={"nome"}
                          filterable={true}
                          onFilterChange={filterChangeEntidades}
                          label={"Entidade"}
                          onChange={() => setAlgumaOpcaoSelecionada(true)}
                        />
                      </div> : <></>
                    }

                    {selectedModulo == DOCUMENTOS_MODULE_NAME ?
                      <div>
                        <Field
                          name={"doDocumento"}
                          component={DropDownList}
                          validator={dropdownRequiredValidator}
                          data={documentosData}
                          filterable={true}
                          onChange={(value) => {
                            DocumentosChange(value); 
                            setAlgumaOpcaoSelecionada(true); 
                          }}                         
                          onFilterChange={filterChangeDocumentos}
                          textField={"id"}
                          label={"Documento"}
                        />
                        <div>
                          {parse(registoResumo)}
                        </div>
                      </div> : <></>
                    }

                    {selectedModulo == OCORRENCIAS_MODULE_NAME ?
                      <div>
                        <Field
                          name={"ocOcorrencia"}
                          component={DropDownList}
                          validator={dropdownRequiredValidator}
                          data={ocorrenciasData}
                          filterable={true}
                          onFilterChange={filterChangeOcorrencias}
                          onChange={(value) => {
                            OcorrenciasChange(value); 
                            setAlgumaOpcaoSelecionada(true); 
                          }}                           
                          textField={"id"}
                          label={"Ocorrência"} />
                        <div>
                          {parse(registoResumo)}
                        </div>
                      </div> : <></>
                    }

                    {selectedModulo == AG_PRETENSOES ?
                      <div>
                        <Field
                          name={"agPretensao"}
                          component={DropDownList}
                          validator={dropdownRequiredValidator}
                          data={pretensoes}
                          filterable={true}
                          textField={"id"}
                          onChange={(value) => {
                            AgPretensaoChange(value); 
                            setAlgumaOpcaoSelecionada(true); 
                          }} 
                          label={"AG - Pretensao"}
                        />
                        <div>
                          {parse(registoResumo)}
                        </div>
                      </div> : <></>
                    }

                    {selectedModulo == AG_DESCONFORMIDADES ?
                      <div>
                        <Field
                          name={"agDesconformidade"}
                          component={DropDownList}
                          validator={dropdownRequiredValidator}
                          filterable={true}
                          data={desconformidades}
                          textField={"id"}
                          onChange={(value) => {
                            AgPretensaoChange(value); 
                            setAlgumaOpcaoSelecionada(true); 
                          }}                           
                          label={"AG - Desconformidade"}
                        />
                        <div>
                          {parse(registoResumo)}
                        </div>
                      </div> : <></>
                    }

                    {selectedModulo == DH_DEMARCACAO ?
                      <div>
                        <Field
                          name={"dhDemarcacao"}
                          component={DropDownList}
                          validator={dropdownRequiredValidator}
                          filterable={true}
                          data={demarcacoesData}
                          onFilterChange={filterChangeDemarcacoes}
                          textField={"id"}
                          onChange={(value) => {
                            DhDemarcacaoChange(value); 
                            setAlgumaOpcaoSelecionada(true); 
                          }}                           
                          label={"DH - Demarcação"}
                        />
                        <div>
                          {parse(registoResumo)}
                        </div>
                      </div> : <></>
                    }

                    {selectedModulo == DH_PROCESSOS ?
                      <div>
                        <Field
                          name={"dhProcessos"}
                          component={DropDownList}
                          validator={dropdownRequiredValidator}
                          filterable={true}
                          data={processosData}
                          onFilterChange={filterChangeProcessos}
                          textField={"id"}
                          onChange={(value) => {
                            DhProcessoChange(value); 
                            setAlgumaOpcaoSelecionada(true); 
                          }}                           
                          label={"DH - Processos"}
                        />
                        <div>
                          {parse(registoResumo)}
                        </div>
                      </div> : <></>
                    }

                      {selectedModulo == DH_PATRIMONIO ?
                      <div>
                        <Field
                          name={"dhPatrimonio"}
                          component={DropDownList}
                          validator={dropdownRequiredValidator}
                          filterable={true}
                          data={patrimoniosData}
                          onFilterChange={filterChangePatrimonio}
                          textField={"id"}
                          onChange={(value) => {
                            DhPatrimonioChange(value); 
                            setAlgumaOpcaoSelecionada(true); 
                          }}                           
                          label={"DH - Património"}
                        />
                        <div>
                          {parse(registoResumo)}
                        </div>
                      </div> : <></>
                    }

                    {selectedModulo == US_TITULO ?
                      <div>
                        <Field
                          name={"usTitulo"}
                          component={DropDownList}
                          validator={dropdownRequiredValidator}
                          data={usosdeSoloTituloData}
                          textField={"id"}
                          filterable={true}
                          onFilterChange={filterChangeUsosDeSolo}
                          onChange={(value) => {
                            UsoSoloTituloChange(value); 
                            setAlgumaOpcaoSelecionada(true); 
                          }}                         
                          label={"US - Título"}
                        />
                        <div>
                          {parse(registoResumo)}
                        </div>
                      </div> : <></>
                    }

                    {selectedModulo == INT_INTERVENCAO ?
                      <div>
                        <Field
                          name={"intIntervencao"}
                          component={DropDownList}
                          validator={dropdownRequiredValidator}
                          data={intervencoesData}
                          textField={"id"}
                          filterable={true}
                          onFilterChange={filterChangeIntervencoes}
                          onChange={(value) => {
                            intIntervencaoChange(value); 
                            setAlgumaOpcaoSelecionada(true); 
                          }}                           
                          label={"INT - Intervenção"}
                        />
                        <div>
                          {parse(registoResumo)}
                        </div>
                      </div> : <></>
                    }

                    {selectedModulo == INT_ACAO ?
                      <div>
                        <Field
                          name={"intAcao"}
                          component={DropDownList}
                          validator={dropdownRequiredValidator}
                          data={acoesData}
                          textField={"id"}
                          filterable={true}
                          onFilterChange={filterChangeIntAcoes}
                          onChange={(value) => {
                            intAcaoChange(value); 
                            setAlgumaOpcaoSelecionada(true); 
                          }}                           
                          label={"INT - Ação"}
                        />
                        <div>
                          {parse(registoResumo)}
                        </div>
                      </div> : <></>
                    }

                    {selectedModulo == INT_MOBILIZACAO ?
                      <div>
                        <Field
                          name={"intMobilizacao"}
                          component={DropDownList}
                          validator={dropdownRequiredValidator}
                          data={mobilizacoesData}
                          textField={"id"}
                          filterable={true}
                          onFilterChange={filterChangeIntMobilizacoes}
                          onChange={(value) => {
                            intMobilizacaoChange(value); 
                            setAlgumaOpcaoSelecionada(true); 
                          }} 
                          label={"INT - Mobilização"}
                        />
                        <div>
                          {parse(registoResumo)}
                        </div>
                      </div> : <></>
                    }

                    {selectedModulo == VOO_DRONE_MODULE_NAME ?
                      <div>
                        <Field
                          name={"vz_registo_voo_drone"}
                          component={DropDownList}
                          validator={dropdownRequiredValidator}
                          data={registosVooDrone && registosVooDrone.filter((vzr: Record<string, unknown>) => Boolean(vzr.name))}
                          textField={"name"}
                          onChange={setAlgumaOpcaoSelecionada}
                          label={"Video Aéreo"}
                        />
                      </div> : <></>
                    }

                  </div>

                </div>

              </fieldset>

            </div>

            <div className="footerModal justify_multiple_children">
              <Button
                type={"submit"}
                onClick={props.cancelEdit}
                className='btnLimparPesquisa'
                title="Fechar"
              >
                <span className="icon-siarl icon-fechar btnIconRegistar"></span> &nbsp;{' '}
                Cancelar
              </Button>

              <Button
                type={"submit"}
                disabled={!formRenderProps.allowSubmit || !algumaOpcaoSelecionada}
                className="btnPesquisar"
              >
                Associar
              </Button>
            </div>

          </FormElement>
        )}
      />
    </Dialog>
  )
}