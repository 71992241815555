
import {
    MouseEvent as ReactMouseEvent, useEffect, useState
} from 'react'

import { Button, DropDownButton } from '@progress/kendo-react-buttons'
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
} from "chart.js"
import clsx from 'clsx'
import { Line } from "react-chartjs-2"
import SessionStorageService from '../../services/SessionStorageService'
import { useCommonStore } from '../../stores/common'
import commonStyles from '../../styles/common/Common.module.scss'
import styles from '../../styles/calculoPerfilGeografico/CalculoPerfilGeografico.module.scss';
import MaximizeButton from '../common/MaximizeButton'
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form'
import { ComboBox, ComboBoxFilterChangeEvent } from '@progress/kendo-react-dropdowns'
import { FilterDescriptor, filterBy } from '@progress/kendo-data-query'
import { CALCULO_PERFIL_MODULE_NAME, GET_ALT_FROM_LINE, GET_MDTS_FROM_GEOM, URL_AJUDA_CALCULO_PERFIL } from '../../utils/constants'
import { coordenadasLinhaSelected, post } from '../../services/utils'
import ReactGA from 'react-ga4';
import html2canvas from 'html2canvas'
import * as XLSX from 'xlsx';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
);

type Props = {
    onCloseClick: (
        e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
    ) => void | undefined
}

export const listaResolucaoPontos: number[] = [0.25, 0.5, 1, 2, 5, 10, 25, 50];

export default function GraficoCPGDForm({ onCloseClick }: Props) {

    const isCurrentViewMaximized = useCommonStore(state => state.isCurrentViewMaximized)
    const [labels, setLabels] = useState<number[]>([])
    const [labelsY, setLabelsY] = useState<number[]>([])
    const [percentLeftLinha, setPercentLeftLinha] = useState("34%");
    const getMdtsFromGeom = async (data: any) => await post<any, any>(`${GET_MDTS_FROM_GEOM}`, data);


    const getAltFromLine = async (data: any) => await post<any, any>(GET_ALT_FROM_LINE, data);

    const getFlatCoordinatesFromObj = (value: any) => {
        let polygonString = ''
        const values: any[] = value
        values?.forEach((value, index) => {
            if (index % 2 == 0) {
                polygonString += value + ' '
            } else {
                polygonString += value + ','
            }
        })
        polygonString = polygonString.substring(0, polygonString.length - 1)
        return polygonString
    }

    const resolucaoPontosData = listaResolucaoPontos.map((valor) => ({
        designacao: valor.toString(),
        valor
    }));

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/GraficoCPGD" });
      }, []);

    const [resolucaoPontos, setResolucaoPontos] = useState(resolucaoPontosData);
    const [resolucaoPontosSelected, setResolucaoPontosSelected] = useState(listaResolucaoPontos[0]);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: "top" as const,
            },
            title: {
                display: true,
                text: "Perfil geográfico dinâmico",
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Perfil (metros)',
                    color: 'black'
                }
            },
            y: {
                title: {
                    display: true,
                    text: 'Altitude (metros)',
                    color: 'black'
                }
            }
        }
    };

    const [data, setData] = useState<any>({
        labels,
        datasets: [
            // {
            //     label: "mdt 1",
            //     data: labelsY,
            //     borderColor: "rgb(27, 164, 156)",
            //     backgroundColor: "rgba(27, 164, 156, 0.5)",
            // },
        ],
    });

    useEffect(() => {
        if (SessionStorageService.getItem("resultadoMDTsPesquisa")) {
            const resultsAll: any[] = JSON.parse(SessionStorageService.getItem('resultadoMDTsPesquisa') ?? '')

            if (!resultsAll) {
                return;
            }
            // resultsAll.forEach(result => {
            //      setLabelsY({...labelsY,...result[0].map((y: any) => y.toFixed(2))});
            //      setLabels(result[1].map((x: any) => x.toFixed(2)));
            // })
            
        }
    }, [resolucaoPontosSelected])

    const clearSearchResults = () => {
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage(
           {
             type: 'SiarlCoordenadaBoxClose',
             value: 'true',
           },
           '*'
         )
         iframe.contentWindow?.postMessage(
          {
            type: 'SiarlRemoveSelection',
            value: 'true',
          },
          '*'
        )
        SessionStorageService.removeItem('resultadoMDTsPesquisa')
        postMessage({ type: "form", value: "inicio" }, "*");

    }

    const filterResolucaoPontos = (filter: FilterDescriptor) => {
        const data = resolucaoPontosData.slice();
        return filterBy(data, filter);
    };

    const filterChangeResolucaoPontos = (event: ComboBoxFilterChangeEvent) => {
        setResolucaoPontos(filterResolucaoPontos(event.filter));
    };

    
    const perfilGeograficoClick = (item: any) => {
        postMessage(
          { type: 'infoMsgMapa', value: { message: 'Desenhe a linha para cálculo de perfis geográficos dinâmicos', left: percentLeftLinha  } },
          '*')
        postMessage({type: "form", value: ""}, "*");
        sessionStorage.setItem("perfilGeograficoClicked" , '');
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage({ type: item.key, value: 'true' }, '*')
        SessionStorageService.setItem("perfilGeograficoInProgress", true);
        sessionStorage.setItem("Modulo", CALCULO_PERFIL_MODULE_NAME);
      } 
    
    const handleNewPerfil = () => {
        SessionStorageService.removeItem("perfilGeograficoInProgress");
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: 'true' }, '*')
        iframe.contentWindow?.postMessage(
           {
             type: 'SiarlCoordenadaBoxClose',
             value: 'true',
           },
           '*'
         )
        postMessage({ type: 'form', value: 'minimizarCalculoPerfil' }, '*');
        iframe.contentWindow?.postMessage(
            {
              type: 'SiarlHideInfoMap',
              value: 'true',
            },
            '*'
          )
          perfilGeograficoClick({
            key: 'SiarlGeoPortalAdicionarLinhaCalculoPerfil'
          })  
    };

    const changeResolucaoPontos = async (e: any) => {
        const valor = e?.value?.valor ?? e
        if (valor) {
            console.log('Resolucao de pontos', valor);

            const dataLocal = JSON.parse(SessionStorageService.getItem('CoordenadasLineSelectedCPGD') ?? '')

            const polygonString = getFlatCoordinatesFromObj(dataLocal.value)

            if (sessionStorage.getItem('Modulo') == CALCULO_PERFIL_MODULE_NAME) {
                const lineLength = dataLocal.lineLenght;
                const coordenadas = polygonString;
                const idsMdts: any[] = JSON.parse(sessionStorage.getItem("idsMDT") ?? '');
                postMessage({ type: "showLoader" }, "*");
                try {
                    const resultsAll: any[] = [];
                    const resulucaoPontos = valor;
                    for(let i=0; i< idsMdts.length; i++) {
                        const result = await getAltFromLine({
                            flatCoordinates: coordenadas, // "-975460.6 5066342.3,-974998.7 5066489.2",
                            lineLengthMts: lineLength,
                            resolucaoPontos: resulucaoPontos,
                            mdtId: idsMdts[i]
                        });
                        resultsAll.push(result);
                    }
                    SessionStorageService.setItem('resultadoMDTsPesquisa', JSON.stringify(resultsAll))
                    const dataLocal1 :any= {
                        labels,
                        datasets: [],
                    }
                    for(let i=0;i< resultsAll.length; i++) {
                        const labelsYLocal = resultsAll[i][0].map((y: any) => y != undefined && y != null ? y.toFixed(2) : null);
                        const results= await getMdtsFromGeom(coordenadasLinhaSelected);


                        if (i == 0) {
                            dataLocal1.datasets.push(
                            {
                                label: "mdt " + (results[0]?.designacao ? results[0]?.designacao : results[0]?.nome),
                                data: labelsYLocal,
                                borderColor: "rgb(164, 208, 103)",
                                backgroundColor: "rgba(164, 208, 103, 0.5)",
                                pointRadius : 1
                            });
                        }
                        else if (i == 1) {
                            dataLocal1.datasets.push(
                            {
                                label: "mdt " + (results[1]?.designacao ? results[1]?.designacao : results[1]?.nome),
                                data: labelsYLocal,
                                borderColor: "rgb(0, 163, 155)",
                                backgroundColor: "rgba(0, 163, 155, 0.5)",
                                pointRadius : 1
                            });
                        }
                        else if (i == 2) {
                            dataLocal1.datasets.push(
                            {
                                label: "mdt " + (results[2]?.designacao ? results[2]?.designacao : results[2]?.nome),
                                data: labelsYLocal,
                                borderColor: "rgb(0, 19, 57)",
                                backgroundColor: "rgba(0, 19, 57, 0.5)",
                                pointRadius : 1
                            });
                        }
                        else if (i == 3) {
                            dataLocal1.datasets.push(
                            {
                                label: "mdt " + (results[3]?.designacao ? results[3]?.designacao : results[3]?.nome),
                                data: labelsYLocal,
                                borderColor: "rgb(71, 77, 59)",
                                backgroundColor: "rgba(71, 77, 59, 0.5)",
                                pointRadius : 1
                            });
                        }
                        else if (i == 4) {
                            dataLocal1.datasets.push(
                            {
                                label: "mdt " + (results[4]?.designacao ? results[4]?.designacao : results[4]?.nome),
                                data: labelsYLocal,
                                borderColor: "rgb(82, 186, 129)",
                                backgroundColor: "rgba(82, 186, 129, 0.5)",
                                pointRadius : 1
                            });
                        } else {
                            dataLocal1.datasets.push(
                                {
                                    label: "mdt " + (results[5]?.designacao ? results[5]?.designacao : results[5]?.nome),
                                    data: labelsYLocal,
                                    borderColor: "rgb(" + (i * 40) + ", 164, " + (i * 100) + ")",
                                    backgroundColor: "rgba(" + (i * 40) + ", 164, " + (i * 100) + ", 0.5)",
                                    pointRadius : 1
                                });
                        }
                    }
                    dataLocal1.labels = resultsAll[0][1].map((x: any) => x.toFixed(2));
                    setLabels(resultsAll[0][1].map((x: any) => x.toFixed(2)));
                    setData(dataLocal1);
                    console.log(labels);
                    console.log(labelsY);
                    setResolucaoPontosSelected(valor);
                } catch {
                    postMessage({ type: 'errorMsg' }, '*')
                } finally {
                    postMessage({ type: "hideLoader" }, "*");
                }
            }

        } else {
            console.log('Resolucao de pontos: erro');
        }
    }

    useEffect(() => {
        changeResolucaoPontos(resolucaoPontos[7].valor)
    }, [])

    const openAjuda = () => {
        window.open(URL_AJUDA_CALCULO_PERFIL, "_blank");
    }
    
    const exportChart = (format : any) => {
        const chartElement = document.getElementById('chart-container') as HTMLIFrameElement;
        html2canvas(chartElement).then((canvas) => {
            const link = document.createElement('a');
            link.href = canvas.toDataURL(`image/${format}`);
            link.download = `gráfico.${format}`;
            link.click();
        });
    };

    const exportChartToExcel = () => {
        const labels = data.labels;
        const datasets = data.datasets;
    
        const worksheetData = [
            ['Perfil (metros)', 'Altitude (metros)']
        ];
    
        labels.forEach((label : any, index : any) => {
            const row = [label];
            datasets.forEach((dataset : any) => {
                row.push(dataset.data[index]);
            });
            worksheetData.push(row);
        });
    
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Perfil Geográfico');
    
        XLSX.writeFile(workbook, 'perfil_geografico.xlsx');
    };

    const optionsExportar = [
        {
          key: 'ExportarGraficoPng',
          text: 'PNG',
        },
        {
          key: 'ExportarGraficoJPG',
          text: 'JPG',
        },
        {
            key:'ExportGraficoExcel',
            text: 'Excel',

        }
      ]

      const onItemClickExportar = async (event: any) => {

        if (event.item.key == 'ExportarGraficoPng') {
            exportChart('png')     
        }
        else if (event.item.key == 'ExportarGraficoJPG'){
            exportChart('jpg')
        }
        else if (event.item.key == 'ExportGraficoExcel'){
            exportChartToExcel()
        }
    };

    const itemRender = (props: { item: any; itemIndex: number }) => {
        const isTitleItem = props.item.key === 'titulo';
        if (isTitleItem) {
          // Item de título personalizado
          return (
            <div className={styles.DropDownButtonTitulo}>
              <span>{props.item.text}</span>
            </div>
          );
        } else {
          // Demais itens
          return (
            <div className={styles.DropDownButtonItem}>
              <span>{props.item.text}</span>
            </div>
          );
        }
      };

    return (
        <div className={clsx(styles.base, isCurrentViewMaximized && commonStyles.maximizedBaseForm)}>

            <div className={styles.header}>

                <div>
                    <div className={styles.nomeDoModulo}>
                        Cálculo de perfis geográficos dinâmicos
                    </div>
                    <div className={styles.tituloDaPagina}>
                        Cálculo de perfis geográficos dinâmicos
                    </div>
                </div>

                <div>
                    <DropDownButton
                        items={optionsExportar}
                        className={styles.btnImprimir}
                        iconClass="icon-siarl icon-exportar"
                        onItemClick={onItemClickExportar}
                        fillMode={'flat'}
                        itemRender={itemRender}
                        title='Exportar'
                    />

                    <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
                        <span className="icon-siarl icon-ajuda"></span>
                    </button>

                    <button className={styles.btnFechar} onClick={onCloseClick} title="Minimizar">
                        <span className="icon-siarl icon-minimizar"></span>
                    </button>

                    <MaximizeButton className={styles.btnMaximizar} />

                </div>

            </div>

            <Form
                initialValues={
                    {
                        'valor': resolucaoPontos[7]
                    }
                }
                render={(formRenderProps: FormRenderProps) => (
                    <FormElement className={`${styles.form} ${styles.formCalculoPerfil}`}>
                        <div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}>
                            <div>
                                <Field
                                    name="valor"
                                    label="Resolução de pontos"
                                    component={ComboBox}
                                    onChange={changeResolucaoPontos}
                                    textField={'designacao'}
                                    filterable={true}
                                    onFilterChange={filterChangeResolucaoPontos}
                                    data={resolucaoPontos}
                                />
                            </div>
                        </div>

                        <div id="chart-container" className={styles.desenharGrafico}>
                            <Line id="graficoPerfilGeografico" options={options} data={data} className={styles.graficoLinhas}/>
                        </div>        
                    </FormElement>
                )}
            />

            <div className={clsx(styles.footer, styles.justify_multiple_children, isCurrentViewMaximized && commonStyles.maximizedFooter)}>
                <Button className={styles.btnSeguinte} onClick={handleNewPerfil}>
                    Novo perfil
                </Button>
                <Button
                    className={styles.btnPesquisar}
                    onClick={clearSearchResults}
                >
                    Alterar seleção de camada
                </Button>

            </div>

        </div>
    )
    
}