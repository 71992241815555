import styles from '../../styles/viewer/CollapsedSidebarMenu.module.scss'
import commonStyles from '../../styles/common/Common.module.scss'
import siarlWhiteLogo from '../../assets/images/siarl-white-logo.png'

import type { Item } from './SidebarMenu'
import { useCommonStore } from '../../stores/common'
import clsx from 'clsx'

type Props = {
  selectedMenuItem?: Item
}

export default function CollapsedSidebarMenu({ selectedMenuItem }: Props) {
  const isCurrentViewMaximized = useCommonStore(
    (state) => state.isCurrentViewMaximized
  )

  return (
    <div
      className={clsx(
        styles.base,
        isCurrentViewMaximized && commonStyles.maximizedHidden
      )}
    >
      <div className={styles.logo}>
        <img
          src={siarlWhiteLogo}
          alt="Siarl - Sistema de Administração do Recurso Litoral"
        />
      </div>
      {/* <div className={styles.selectedItem}>
        <div className={styles.icon}>{selectedMenuItem.icon}</div>
        <span className={styles.text}>{selectedMenuItem.text}</span>
      </div> */}
    </div>
  )
}
