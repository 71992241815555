import { Button } from '@progress/kendo-react-buttons'
import { ComboBox, DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns'
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from '@progress/kendo-react-form'

import {
  MouseEvent as ReactMouseEvent,
  useEffect,
  useLayoutEffect,
  useState
} from 'react'

import { Input } from '@progress/kendo-react-inputs'
import {
  del,
  get,
  post,
  postFormData,
  put
} from '../../services/utils'
import { useEntidadeStore } from '../../stores/entidade'
import styles from '../../styles/classificadores/ConsultarClassificadoresForm.module.scss'
import { Dialog } from '@progress/kendo-react-dialogs'
import {
  ACAO_ESTADO_TABLE_NAME,
  AG_DECISAO_TABLE_NAME,
  AG_ESTADO_TABLE_NAME,
  AG_TEMA_TABLE_NAME,
  AG_TIPO_PRETENSAO_TABLE_NAME,
  CANDIDATURA_ESTADO_TABLE_NAME,
  CONSULTAR_ENTIDADES,
  DELETE_ACAO_ESTADO,
  DELETE_AG_DECISAO,
  DELETE_AG_ESTADO,
  DELETE_AG_TEMA,
  DELETE_AG_TIPO_PRETENSAO,
  DELETE_CANDIDATURA_ESTADO,
  DELETE_DH_CONDICIONANTES_PROCESSO,
  DELETE_DH_FASES_PROCEDIMENTO_DELIMITACAO,
  DELETE_DH_LOCALIZACAO_PROCESSO,
  DELETE_DH_RECONHECIMENTO_PROPRIEDADE_PRIVADA,
  DELETE_DH_TIPO_ACAO_JUDICIAL,
  DELETE_DH_TIPO_AQUISICAO,
  DELETE_DH_TIPO_DESAFETACAO,
  DELETE_DH_TIPO_DOMINIO,
  DELETE_DH_TIPO_INICIATIVA,
  DELETE_DH_TIPO_LINHA_DEMARCACAO,
  DELETE_DH_TIPO_NOTIFICACAO,
  DELETE_DH_TIPO_PARCELA,
  DELETE_DH_TIPO_PENDENCIA,
  DELETE_DH_TIPO_PROCESSO,
  DELETE_DH_TIPO_SENTENCA,
  DELETE_DH_TIPO_SITUACAO_NOTIFICACAO,
  DELETE_DH_VALIDACAO_PROVA,
  DELETE_DI_TIPO_ENTIDADE,
  DELETE_DO_ESTADO,
  DELETE_DO_TIPO_ACESSO,
  DELETE_DO_TIPO_RECURSO,
  DELETE_EA_ESTADO,
  DELETE_INT_ESTADO,
  DELETE_INT_INSTUMENTO_PLANEAMENTO,
  DELETE_INT_PRIORIDADE,
  DELETE_INT_TIPOS_PLANEAMENTO_ESTADO,
  DELETE_INT_TIPO_INCIDENCIA,
  DELETE_INT_TIPO_MOBILIZACAO,
  DELETE_MOBILIZACAO_ESTADO,
  DELETE_MODELO_TIPO,
  DELETE_MO_ESTADO,
  DELETE_MO_MODELO_SUBTIPO,
  DELETE_OC_ESTADO,
  DELETE_PROCESSO_ESTADO,
  DELETE_TIPO_INTERVENCAO,
  DELETE_US_TIPO_PROCESSO,
  DELETE_US_TITULO_ESTADO,
  DH_CONDICIONANTES_PROCESSO_TABLE_NAME,
  DH_ESTADO_PROCESSO_TABLE_NAME,
  DH_FASES_PROCEDIMENTO_DELIMITACAO_TABLE_NAME,
  DH_LOCALIZACAO_PROCESSO_TABLE_NAME,
  DH_RECONHECIMENTO_PROPRIEDADE_PRIVADA_TABLE_NAME,
  DH_TIPO_ACAO_JUDICIAL_TABLE_NAME,
  DH_TIPO_AQUISICAO_TABLE_NAME,
  DH_TIPO_DESAFETACAO_TABLE_NAME,
  DH_TIPO_DOMINIO_TABLE_NAME,
  DH_TIPO_INICIATIVA,
  DH_TIPO_LINHA_DEMARCACAO_TABLE_NAME,
  DH_TIPO_NOTIFICACAO_TABLE_NAME,
  DH_TIPO_PARCELA_TABLE_NAME,
  DH_TIPO_PENDENCIA_TABLE_NAME,
  DH_TIPO_PROCESSO_TABLE_NAME,
  DH_TIPO_SENTENCA_TABLE_NAME,
  DH_TIPO_SITUACAO_NOTIFICACAO_TABLE_NAME,
  DH_VALIDACAO_PROVA_TABLE_NAME,
  DI_TIPO_ENTIDADE_TABLE_NAME,
  DO_ESTADO_TABLE_NAME,
  DO_TIPO_ACESSO_TABLE_NAME,
  DO_TIPO_RECURSO_TABLE_NAME,
  EA_ESTADO_TABLE_NAME,
  EMAIL_USERS_LIST_TABLE_NAME,
  GET_AG_DECISAO,
  GET_AG_TEMA_ASYNC,
  GET_AG_TIPO_PRETENSAO,
  GET_DH_RECONHECIMENTO_PROPRIEDADE_PRIVADA,
  GET_DH_TIPO_DOMINIO,
  GET_EA_ESTADO,
  GET_ESTADOS_OCORRENCIA,
  GET_ESTADO_MOBILIZACAO,
  GET_INT_INSTRUMENTO_PLANEAMENTO_ESTADO,
  GET_INT_PLANEAMENTO_1,
  GET_INT_PLANEAMENTO_2,
  GET_INT_PLANEAMENTO_3,
  GET_INT_TIPO_INTERVENCAO,
  GET_INT_TIPO_MOBILIZACAO,
  GET_MO_ESTADO,
  GET_TIPO_INCIDENCIA,
  GET_TIPO_LINHA_DEMARCACAO,
  GET_US_ESTADO_TITULO,
  INSERT_ACAO_ESTADO,
  INSERT_AG_DECISAO,
  INSERT_AG_ESTADO,
  INSERT_AG_TEMA,
  INSERT_AG_TIPO_PRETENSAO,
  INSERT_CANDIDATURA_ESTADO,
  INSERT_DH_CONDICIONANTES_PROCESSO,
  INSERT_DH_FASE_PROCEDIMENTO,
  INSERT_DH_LOCALIZACAO_PROCESSO,
  INSERT_DH_RECONHECIMENTO_PROPRIEDADE_PRIVADA,
  INSERT_DH_TIPO_ACAO_JUDICIAL,
  INSERT_DH_TIPO_AQUISICAO,
  INSERT_DH_TIPO_DESAFETACAO,
  INSERT_DH_TIPO_DOMINIO,
  INSERT_DH_TIPO_INICIATIVA,
  INSERT_DH_TIPO_LINHA_DEMARCACAO,
  INSERT_DH_TIPO_NOTIFICACAO,
  INSERT_DH_TIPO_PARCELA,
  INSERT_DH_TIPO_PENDENCIA,
  INSERT_DH_TIPO_PROCESSO,
  INSERT_DH_TIPO_SENTENÇA,
  INSERT_DH_TIPO_SITUACAO_NOTIFICACAO,
  INSERT_DH_VALIDACAO_PROVA,
  INSERT_DI_TIPO_ENTIDADE,
  INSERT_DO_ESTADO,
  INSERT_DO_TIPO_ACESSO,
  INSERT_DO_TIPO_RECURSO,
  INSERT_EA_ESTADO,
  INSERT_INSTRUMENTO_PLANEAMENTO_ESTADO,
  INSERT_INT_ESTADO,
  INSERT_INT_PLANEAMENTO_1,
  INSERT_INT_PLANEAMENTO_2,
  INSERT_INT_PLANEAMENTO_3,
  INSERT_INT_PRIORIDADE,
  INSERT_INT_TIPO_INCIDENCIA,
  INSERT_INT_TIPO_INTERVENCAO,
  INSERT_INT_TIPO_MOBILIZACAO,
  INSERT_MOBILIZACAO_ESTADO,
  INSERT_MODELO_TIPO,
  INSERT_MO_ESTADO,
  INSERT_MO_SUBTIPO_MODELO,
  INSERT_OC_ESTADO,
  INSERT_PROCESSO_ESTADO,
  INSERT_US_TIPO_PROCESSO,
  INSERT_US_TITULO_ESTADO,
  INT_ESTADO_TABLE_NAME,
  INT_INSTRUMENTO_PLANEAMENTO_ESTADO_TABLE_NAME,
  INT_PLANEAMENTO_NIVEL_1_TABLE_NAME,
  INT_PRIORIDADE_TABLE_NAME,
  INT_TIPO_INCIDENCIA_TABLE_NAME,
  INT_TIPO_INTERVENCAO_TABLE_NAME,
  INT_TIPO_MOBILIZACAO_TABLE_NAME,
  MOBILIZACAO_ESTADO_TABLE_NAME,
  MO_ESTADO_TABLE_NAME,
  MO_MODELOS_TIPO_TABLE_NAME,
  MO_MODELO_SUBTIPO_TABLE_NAME,
  INT_EMAIL_USERS_INSERT,
  INT_EMAIL_USERS_LIST_GET_ALL,
  INT_EMAIL_USERS_UPDATE,
  OC_ESTADO_TABLE_NAME,
  PESQUISAR_ACAO_ESTADO,
  PESQUISAR_CONDICIONANTES_PROCESSO,
  PESQUISAR_ESTADO,
  PESQUISAR_ESTADO_CANDIDATURA,
  PESQUISAR_ESTADO_INTERVENCOES,
  PESQUISAR_ESTADO_PROCESSO,
  PESQUISAR_FASE_PROCEDIMENTO_DE_ELIMINACAO,
  PESQUISAR_LOCALIZACAO_PROCESSO,
  PESQUISAR_MO_MODELO_SUBTIPOS,
  PESQUISAR_MO_MODELO_TIPOS,
  PESQUISAR_PRIORIDADE,
  PESQUISAR_TIPOACESSO,
  PESQUISAR_TIPOENTIDADE,
  PESQUISAR_TIPORECURSO,
  PESQUISAR_TIPO_AQUISICAO,
  PESQUISAR_TIPO_DESAFETACAO,
  PESQUISAR_TIPO_INICIATIVA,
  PESQUISAR_TIPO_JUDICIAL,
  PESQUISAR_TIPO_NOTIFICACAO,
  PESQUISAR_TIPO_PARCELA,
  PESQUISAR_TIPO_PENDENCIA,
  PESQUISAR_TIPO_PROCESSO,
  PESQUISAR_TIPO_SENTENCA,
  PESQUISAR_TIPO_SITUACAO_NOTIFICACAO,
  PESQUISAR_US_TIPO_PROCESSO,
  PESQUISAR_VALIDACAO_PROVA,
  UPDATE_ACAO_ESTADO,
  UPDATE_AG_DECISAO,
  UPDATE_AG_TEMA,
  UPDATE_AG_TIPO_PRETENSAO,
  UPDATE_CANDIDATURA_ESTADO,
  UPDATE_DH_CONDICIONANTES_PROCESSO,
  UPDATE_DH_FASE_PROCEDIMENTO,
  UPDATE_DH_LOCALIZACAO_PROCESSO,
  UPDATE_DH_RECONHECIMENTO_PROPRIEDADE_PRIVADA,
  UPDATE_DH_SITUACAO_NOTIFICACAO,
  UPDATE_DH_TIPO_ACAO_JUDICIAL,
  UPDATE_DH_TIPO_AQUISICAO,
  UPDATE_DH_TIPO_DESAFETACAO,
  UPDATE_DH_TIPO_DOMINIO,
  UPDATE_DH_TIPO_INICIATIVA,
  UPDATE_DH_TIPO_LINHA_DEMARCACAO,
  UPDATE_DH_TIPO_NOTIFICACAO,
  UPDATE_DH_TIPO_PARCELA,
  UPDATE_DH_TIPO_PENDENCIA,
  UPDATE_DH_TIPO_PROCESSO,
  UPDATE_DH_TIPO_SENTEÇA,
  UPDATE_DH_VALIDACAO_PROVA,
  UPDATE_DI_TIPO_ENTIDADE,
  UPDATE_DO_ESTADO,
  UPDATE_DO_TIPO_ACESSO,
  UPDATE_DO_TIPO_RECURSO,
  UPDATE_EA_ESTADO,
  UPDATE_INSTRUMENTO_PLANEAMENTO_ESTADO,
  UPDATE_INT_ESTADO,
  UPDATE_INT_PLANEAMENTO_1,
  UPDATE_INT_PLANEAMENTO_2,
  UPDATE_INT_PLANEAMENTO_3,
  UPDATE_INT_PRIORIDADE,
  UPDATE_INT_TIPO_INCIDENCIA,
  UPDATE_INT_TIPO_INTERVENCAO,
  UPDATE_INT_TIPO_MOBILIZACAO,
  UPDATE_MOBILIZACAO_ESTADO,
  UPDATE_MODELO_TIPO,
  UPDATE_MO_ESTADO,
  UPDATE_MO_SUBTIPO_MODELO,
  UPDATE_OC_ESTADO,
  UPDATE_PROCESSO_ESTADO,
  UPDATE_US_TIPO_PROCESSO,
  UPDATE_US_TITULO_ESTADO,
  US_ESTADO_TITULO_TABLE_NAME,
  US_TIPO_PROCESSO_TABLE_NAME,
  GET_SPECIFIC_COLUMNS_MO_SUBTIPO,
  MDT_SELECTION_NAME,
  GET_MDTS,
  UPLOAD_MDT,
  DELETE_MDT,
  PROCESS_MDT,
  INSERT_MDT,
  GET_MDT,
  URL_AJUDA_BACKOFFICE_CLASSIFICADORES,
  MDT_NAME_EXISTS,
  UPDATE_MDT,

  
} from '../../utils/constants'

import { elementoClickMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais'
import { Grid } from '@progress/kendo-react-grid'
import { useCommonStore } from '../../stores/common'
import { Upload, UploadOnProgressEvent, UploadOnRemoveEvent } from '@progress/kendo-react-upload'
import { DateTimePicker } from '@progress/kendo-react-dateinputs'

type Props = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
}

export default function ConsultarClassificadoresForm({ onCloseClick }: Props) {
  const [isMobile, setIsMobile] = useState(false);
  const getEntidades = async (data: any) =>
    await post<any, any>(`${CONSULTAR_ENTIDADES}`, data)
  const [tipoPesquisaVisible, setTipoPesquisaVisible] = useState(true)
  const [criterioGeograficoVisible, setCriterioGeograficoVisible] =
    useState(false)
  const [localizacaoVisible, setLocalizacaoVisible] = useState(false)
  const [entidadeVisible, setEntidadeVisible] = useState(false)
  const [data, setData] = useState([{"": "Selecione o classificador"}]);
  const [showDialog, setShowDialog] = useState(false);
  const toggleMaximizeView = useCommonStore((state) => state.toggleMaximizeView)
  const [idField, setIdField] = useState<any>();
  const [designacaoField, setDesignacaoField] = useState<any>();
  const [descricaoField, setDescricaoField] = useState<any>();
  const [nomeField, setNomeField] = useState<any>();
  const [showPesquisar, setShowPesquisar] = useState<any>(true);
  const [showGravar, setShowGravar] = useState<any>();
  const [tabelaClassificador, setTabelaClassificador] = useState<any>();
  const [novoRegisto, setNovoRegisto] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showUserId, setShowUserId] = useState(false);
  const [emailField, setEmailField] = useState<any>();
  const [userIdField, setUserIdField] = useState<any>();
  const [showUploadDocumentos, setShowUploadDocumentos] = useState(false);
  const [fileField, setFileField] = useState<any>();
  const [descricaoDisabled, setDescricaoDisabled] = useState(false);
  const [gravarDisabled, setGravarDisabled] = useState(false);
  const [showTipo, setShowTipo] = useState(false);
  const [showDataVoo, setshowDataVoo] = useState(false);
  const [nomeDisabled, setNomeDisabled] = useState(false);
  const [tipoField, setTipoField] = useState<any>();
  const [dataField, setDataField] = useState<any>();
  const [densidadeField, setDensidadeField] = useState<any>();

  const toggletipoPesquisa = () => {
    setTipoPesquisaVisible(!tipoPesquisaVisible)
  }
  const togglecriterioGeografico = () => {
    setCriterioGeograficoVisible(!criterioGeograficoVisible)
  }
  const toggleLocalizacao = () => {
    setLocalizacaoVisible(!localizacaoVisible)
  }
  const toogleEntidade = () => {
    setEntidadeVisible(!entidadeVisible)
  }

  const selectEntidadeResult = useEntidadeStore(
    (state) => state.selectEntidadeResult
  )

  const getOcEstados = async () => await get<any>(`${GET_ESTADOS_OCORRENCIA}`);
  const getMoModeloTipos = async () => await get<any>(`${PESQUISAR_MO_MODELO_TIPOS}`);
  const updateModeloTipo = async (data: any) => await put<any, any>(`${UPDATE_MODELO_TIPO}`, data);
  const insertModeloTipo = async (data: any) => await post<any, any>(`${INSERT_MODELO_TIPO}`, data);
  const updateOcEstado = async (data: any) => await put<any, any>(`${UPDATE_OC_ESTADO}`, data);
  const insertOcEstado = async (data: any) => await post<any, any>(`${INSERT_OC_ESTADO}`, data);
  const updateMoEstado = async (data: any) => await put<any, any>(`${UPDATE_MO_ESTADO}`, data);
  const insertMoEstado = async (data: any) => await post<any, any>(`${INSERT_MO_ESTADO}`, data);
  const getMoEstado = async () => await get<any>(`${GET_MO_ESTADO}`);
  const updateIntEstado = async (data: any) => await put<any, any>(`${UPDATE_INT_ESTADO}`, data);
  const insertIntEstado = async (data: any) => await post<any, any>(`${INSERT_INT_ESTADO}`, data);
  const getIntEstado = async () => await get<any>(`${PESQUISAR_ESTADO_INTERVENCOES}`);
  const updateAcaoEstado = async (data: any) => await put<any, any>(`${UPDATE_ACAO_ESTADO}`, data);
  const insertAcaoEstado = async (data: any) => await post<any, any>(`${INSERT_ACAO_ESTADO}`, data);
  const getAcaoEstado = async () => await get<any>(`${PESQUISAR_ACAO_ESTADO}`);
  const updateMobilizacaoEstado = async (data: any) => await put<any, any>(`${UPDATE_MOBILIZACAO_ESTADO}`, data);
  const insertMobilizacaoEstado = async (data: any) => await post<any, any>(`${INSERT_MOBILIZACAO_ESTADO}`, data);
  const getMobilizacaoEstado = async () => await get<any>(`${GET_ESTADO_MOBILIZACAO}`);
  const updateCandidaturaEstado = async (data: any) => await put<any, any>(`${UPDATE_CANDIDATURA_ESTADO}`, data);
  const insertCandidaturaEstado = async (data: any) => await post<any, any>(`${INSERT_CANDIDATURA_ESTADO}`, data);
  const getCandidaturaEstado = async () => await get<any>(`${PESQUISAR_ESTADO_CANDIDATURA}`);
  const updateEstadoProcesso = async (data: any) => await put<any, any>(`${UPDATE_PROCESSO_ESTADO}`, data);
  const insertEstadoProcesso = async (data: any) => await post<any, any>(`${INSERT_PROCESSO_ESTADO}`, data);
  const getEstadoProcesso = async () => await get<any>(`${PESQUISAR_ESTADO_PROCESSO}`);
  const updateUsTituloEstado= async (data: any) => await put<any, any>(`${UPDATE_US_TITULO_ESTADO}`, data);
  const insertUsTituloEstado = async (data: any) => await post<any, any>(`${INSERT_US_TITULO_ESTADO}`, data);
  const getUsEstadoTitulo = async () => await get<any>(`${GET_US_ESTADO_TITULO}`);
  const updateAgEstado= async (data: any) => await put<any, any>(`${UPDATE_US_TITULO_ESTADO}`, data);
  const insertAgEstado = async (data: any) => await post<any, any>(`${INSERT_AG_ESTADO}`, data);
  const getAgEstado = async () => await get<any>(`${GET_EA_ESTADO}`);
  const updateEaEstado= async (data: any) => await put<any, any>(`${UPDATE_EA_ESTADO}`, data);
  const insertEaEstado = async (data: any) => await post<any, any>(`${INSERT_EA_ESTADO}`, data);
  const getDoEstado = async () => await get<any>(`${PESQUISAR_ESTADO}`);
  const updateDoEstado= async (data: any) => await put<any, any>(`${UPDATE_DO_ESTADO}`, data);
  const insertDoEstado = async (data: any) => await post<any, any>(`${INSERT_DO_ESTADO}`, data);
  const getIntPlaneamento1 = async () => await get<any>(`${GET_INT_PLANEAMENTO_1}`);
  const updateIntPlaneamento1= async (data: any) => await put<any, any>(`${UPDATE_INT_PLANEAMENTO_1}`, data);
  const insertIntPlaneamento1 = async (data: any) => await post<any, any>(`${INSERT_INT_PLANEAMENTO_1}`, data);
  const getIntPlaneamento2 = async () => await get<any>(`${GET_INT_PLANEAMENTO_2}`);
  const updateIntPlaneamento2= async (data: any) => await put<any, any>(`${UPDATE_INT_PLANEAMENTO_2}`, data);
  const insertIntPlaneamento2 = async (data: any) => await post<any, any>(`${INSERT_INT_PLANEAMENTO_2}`, data);
  const getIntPlaneamento3 = async () => await get<any>(`${GET_INT_PLANEAMENTO_3}`);
  const updateIntPlaneamento3= async (data: any) => await put<any, any>(`${UPDATE_INT_PLANEAMENTO_3}`, data);
  const insertIntPlaneamento3 = async (data: any) => await post<any, any>(`${INSERT_INT_PLANEAMENTO_3}`, data);
  const getIntPrioridade = async () => await get<any>(`${PESQUISAR_PRIORIDADE}`);
  const updateIntPrioridade= async (data: any) => await put<any, any>(`${UPDATE_INT_PRIORIDADE}`, data);
  const insertIntPrioridade = async (data: any) => await post<any, any>(`${INSERT_INT_PRIORIDADE}`, data);
  const getIntTipoIntervencao = async () => await get<any>(`${GET_INT_TIPO_INTERVENCAO}`);
  const insertIntTipoIntervencao= async (data: any) => await post<any, any>(`${INSERT_INT_TIPO_INTERVENCAO}`, data);
  const updateIntTipoIntervencao = async (data: any) => await put<any, any>(`${UPDATE_INT_TIPO_INTERVENCAO}`, data);
  const getIntIntrumentoPlaneamentoEstado = async () => await get<any>(`${GET_INT_INSTRUMENTO_PLANEAMENTO_ESTADO}`);
  const insertIntIntrumentoPlaneamentoEstado= async (data: any) => await post<any, any>(`${INSERT_INSTRUMENTO_PLANEAMENTO_ESTADO}`, data);
  const updateIntIntrumentoPlaneamentoEstado = async (data: any) => await put<any, any>(`${UPDATE_INSTRUMENTO_PLANEAMENTO_ESTADO}`, data);
  const getIntTipoIncidencia = async () => await get<any>(`${GET_TIPO_INCIDENCIA}`);
  const insertIntTipoIncidencia= async (data: any) => await post<any, any>(`${INSERT_INT_TIPO_INCIDENCIA}`, data);
  const updateIntTipoIncidencia = async (data: any) => await put<any, any>(`${UPDATE_INT_TIPO_INCIDENCIA}`, data);
  const getIntTipoMobilizacao = async () => await get<any>(`${GET_INT_TIPO_MOBILIZACAO}`);
  const insertIntTipoMobilizacao= async (data: any) => await post<any, any>(`${INSERT_INT_TIPO_MOBILIZACAO}`, data);
  const updateIntTipoMobilizacao = async (data: any) => await put<any, any>(`${UPDATE_INT_TIPO_MOBILIZACAO}`, data);
  const insertDhTipoProcesso= async (data: any) => await post<any, any>(`${INSERT_DH_TIPO_PROCESSO}`, data);
  const updateDhTipoProcesso = async (data: any) => await put<any, any>(`${UPDATE_DH_TIPO_PROCESSO}`, data);
  const getDhTipoProcesso = async () => await get<any>(`${PESQUISAR_TIPO_PROCESSO}`);
  const insertDhFasesProcedimento= async (data: any) => await post<any, any>(`${INSERT_DH_FASE_PROCEDIMENTO}`, data);
  const updateDhFasesProcedimento = async (data: any) => await put<any, any>(`${UPDATE_DH_FASE_PROCEDIMENTO}`, data);
  const getDhFasesProcedimento= async () => await get<any>(`${PESQUISAR_FASE_PROCEDIMENTO_DE_ELIMINACAO}`);
  const updateDhTipoDominio = async (data: any) => await put<any, any>(`${UPDATE_DH_TIPO_DOMINIO}`, data);
  const insertDhTipoDominio= async (data: any) => await post<any, any>(`${INSERT_DH_TIPO_DOMINIO}`, data);
  const getDhTipoDominio = async () => await get<any>(`${GET_DH_TIPO_DOMINIO}`);
  const updateDhLocalizacaoProcesso = async (data: any) => await put<any, any>(`${UPDATE_DH_LOCALIZACAO_PROCESSO}`, data);
  const insertDhLocalizacaoProcesso= async (data: any) => await post<any, any>(`${INSERT_DH_LOCALIZACAO_PROCESSO}`, data);
  const getDhLocalizacaoProcesso = async () => await get<any>(`${PESQUISAR_LOCALIZACAO_PROCESSO}`);
  const updateDhValidacaoProva = async (data: any) => await put<any, any>(`${UPDATE_DH_VALIDACAO_PROVA}`, data);
  const insertDhValidacaoProva= async (data: any) => await post<any, any>(`${INSERT_DH_VALIDACAO_PROVA}`, data);
  const getDhValidacaoProva = async () => await get<any>(`${PESQUISAR_VALIDACAO_PROVA}`);
  const updateDhTipoSenteça = async (data: any) => await put<any, any>(`${UPDATE_DH_TIPO_SENTEÇA}`, data);
  const insertDhTipoSentença = async (data: any) => await post<any, any>(`${INSERT_DH_TIPO_SENTENÇA}`, data);
  const getDhTipoSentença = async () => await get<any>(`${PESQUISAR_TIPO_SENTENCA}`);
  const updateDhTipoPendencia = async (data: any) => await put<any, any>(`${UPDATE_DH_TIPO_PENDENCIA}`, data);
  const insertDhTipoPendencia = async (data: any) => await post<any, any>(`${INSERT_DH_TIPO_PENDENCIA}`, data);
  const getDhTipoPendencia = async () => await get<any>(`${PESQUISAR_TIPO_PENDENCIA}`);
  const updateDhTipoNotificacao = async (data: any) => await put<any, any>(`${UPDATE_DH_TIPO_NOTIFICACAO}`, data);
  const insertDhTipoNotificacao = async (data: any) => await post<any, any>(`${INSERT_DH_TIPO_NOTIFICACAO}`, data);
  const getDhTipoNotificacao = async () => await get<any>(`${PESQUISAR_TIPO_NOTIFICACAO}`);
  const updateDhTipoSituacaoNotificacao = async (data: any) => await put<any, any>(`${UPDATE_DH_SITUACAO_NOTIFICACAO}`, data);
  const insertDhTipoSituacaoNotificacao = async (data: any) => await post<any, any>(`${INSERT_DH_TIPO_SITUACAO_NOTIFICACAO}`, data);
  const getDhTipoSituacaoNotificacao = async () => await get<any>(`${PESQUISAR_TIPO_SITUACAO_NOTIFICACAO}`);
  const updateDhCondicionantesProcesso = async (data: any) => await put<any, any>(`${UPDATE_DH_CONDICIONANTES_PROCESSO}`, data);
  const insertDhCondicionantesProcesso = async (data: any) => await post<any, any>(`${INSERT_DH_CONDICIONANTES_PROCESSO}`, data);
  const getDhCondicionantesProcesso = async () => await get<any>(`${PESQUISAR_CONDICIONANTES_PROCESSO}`);
  const updateDhTipoParcela = async (data: any) => await put<any, any>(`${UPDATE_DH_TIPO_PARCELA}`, data);
  const insertDhTipoParcela = async (data: any) => await post<any, any>(`${INSERT_DH_TIPO_PARCELA}`, data);
  const getDhTipoParcela = async () => await get<any>(`${PESQUISAR_TIPO_PARCELA}`);
  const updateDhTipoAcaoJudicial = async (data: any) => await put<any, any>(`${UPDATE_DH_TIPO_ACAO_JUDICIAL}`, data);
  const insertDhTipoAcaoJudicial = async (data: any) => await post<any, any>(`${INSERT_DH_TIPO_ACAO_JUDICIAL}`, data);
  const getDhTipoAcaoJudicial = async () => await get<any>(`${PESQUISAR_TIPO_JUDICIAL}`);
  const updateDhTipoDesafetacao = async (data: any) => await put<any, any>(`${UPDATE_DH_TIPO_DESAFETACAO}`, data);
  const insertDHTipoDesafetacao = async (data: any) => await post<any, any>(`${INSERT_DH_TIPO_DESAFETACAO}`, data);
  const getDhTipoDesafetacao = async () => await get<any>(`${PESQUISAR_TIPO_DESAFETACAO}`);
  const updateDhTipoAquisicao = async (data: any) => await put<any, any>(`${UPDATE_DH_TIPO_AQUISICAO}`, data);
  const insertDHTipoAquisicao = async (data: any) => await post<any, any>(`${INSERT_DH_TIPO_AQUISICAO}`, data);
  const getDhTipoAquisicao = async () => await get<any>(`${PESQUISAR_TIPO_AQUISICAO}`);
  const updateDhReconhecimentoPropriedadePrivada = async (data: any) => await put<any, any>(`${UPDATE_DH_RECONHECIMENTO_PROPRIEDADE_PRIVADA}`, data);
  const insertDhReconhecimentoPropriedadePrivada = async (data: any) => await post<any, any>(`${INSERT_DH_RECONHECIMENTO_PROPRIEDADE_PRIVADA}`, data);
  const getDhReconhecimentoPropriedadePrivada = async () => await get<any>(`${GET_DH_RECONHECIMENTO_PROPRIEDADE_PRIVADA}`);
  const updateDhtipoIniciativa = async (data: any) => await put<any, any>(`${UPDATE_DH_TIPO_INICIATIVA}`, data);
  const insertDhTipoIniciativa = async (data: any) => await post<any, any>(`${INSERT_DH_TIPO_INICIATIVA}`, data);
  const getDhTipoIniciativa = async () => await get<any>(`${PESQUISAR_TIPO_INICIATIVA}`);
  const updateDhTipoLinhaDemarcacao = async (data: any) => await put<any, any>(`${UPDATE_DH_TIPO_LINHA_DEMARCACAO}`, data);
  const insertDhTipoLinhaDemarcacao = async (data: any) => await post<any, any>(`${INSERT_DH_TIPO_LINHA_DEMARCACAO}`, data);
  const getDhTipoLinhaDemarcacao = async () => await get<any>(`${GET_TIPO_LINHA_DEMARCACAO}`);
  const updateUsTipoProcesso = async (data: any) => await put<any, any>(`${UPDATE_US_TIPO_PROCESSO}`, data);
  const insertUsTipoProcesso = async (data: any) => await post<any, any>(`${INSERT_US_TIPO_PROCESSO}`, data);
  const getUsTipoProcesso = async () => await get<any>(`${PESQUISAR_US_TIPO_PROCESSO}`);
  const updateAgTipoPretensao = async (data: any) => await put<any, any>(`${UPDATE_AG_TIPO_PRETENSAO}`, data);
  const insertAgTipoPretensao = async (data: any) => await post<any, any>(`${INSERT_AG_TIPO_PRETENSAO}`, data);
  const getAgTipoPretensao = async () => await get<any>(`${GET_AG_TIPO_PRETENSAO}`);
  const updateAgDecisao = async (data: any) => await put<any, any>(`${UPDATE_AG_DECISAO}`, data);
  const insertAgDecisao = async (data: any) => await post<any, any>(`${INSERT_AG_DECISAO}`, data);
  const getAgDecisao = async () => await get<any>(`${GET_AG_DECISAO}`);
  const updateAgTema = async (data: any) => await put<any, any>(`${UPDATE_AG_TEMA}`, data);
  const insertAgTema = async (data: any) => await post<any, any>(`${INSERT_AG_TEMA}`, data);
  const getAgtema = async () => await get<any>(`${GET_AG_TEMA_ASYNC}`);
  const updateDiTipoEntidade = async (data: any) => await put<any, any>(`${UPDATE_DI_TIPO_ENTIDADE}`, data);
  const insertDiTipoEntidade = async (data: any) => await post<any, any>(`${INSERT_DI_TIPO_ENTIDADE}`, data);
  const getDiTipoEntidade = async () => await get<any>(`${PESQUISAR_TIPOENTIDADE}`);
  const updateDoTipoAcesso = async (data: any) => await put<any, any>(`${UPDATE_DO_TIPO_ACESSO}`, data);
  const insertDoTipoAcesso = async (data: any) => await post<any, any>(`${INSERT_DO_TIPO_ACESSO}`, data);
  const getDoTipoAcesso = async () => await get<any>(`${PESQUISAR_TIPOACESSO}`);
  const updateDoTipoRecurso = async (data: any) => await put<any, any>(`${UPDATE_DO_TIPO_RECURSO}`, data);
  const insertDoTipoRecurso = async (data: any) => await post<any, any>(`${INSERT_DO_TIPO_RECURSO}`, data);
  const getDoTipoRecurso = async () => await get<any>(`${PESQUISAR_TIPORECURSO}`);
  const updateMoSubTipoModelo= async (data: any) => await put<any, any>(`${UPDATE_MO_SUBTIPO_MODELO}`, data);
  const insertMoSubTipoModelo = async (data: any) => await post<any, any>(`${INSERT_MO_SUBTIPO_MODELO}`, data);
  const getMoSubtipoModelo = async () => await get<any>(`${PESQUISAR_MO_MODELO_SUBTIPOS}`);
  const deleteModeloTipo = (id: number) => del(`${DELETE_MODELO_TIPO}/${id}`, {})
  const deleteOcorrenciaEstado = (id: number) => del(`${DELETE_OC_ESTADO}/${id}`, {})
  const deleteMoEstado = (id: number) => del(`${DELETE_MO_ESTADO}/${id}`, {})
  const deleteIntEstado = (id: number) => del(`${DELETE_INT_ESTADO}/${id}`, {})
  const deleteAcaoEstado = (id: number) => del(`${DELETE_ACAO_ESTADO}/${id}`, {})
  const deleteMobilizacaoEstado = (id: number) => del(`${DELETE_MOBILIZACAO_ESTADO}/${id}`, {})
  const deleteCandidaturaEstado = (id: number) => del(`${DELETE_CANDIDATURA_ESTADO}/${id}`, {})
  const deleteProcessoEstado = (id: number) => del(`${DELETE_PROCESSO_ESTADO}/${id}`, {})
  const deleteUsEstadoTitulo = (id: number) => del(`${DELETE_US_TITULO_ESTADO}/${id}`, {})
  const deleteAgEstado = (id: number) => del(`${DELETE_AG_ESTADO}/${id}`, {})
  const deleteEaEstado = (id: number) => del(`${DELETE_EA_ESTADO}/${id}`, {})
  const deleteDoEstado = (id: number) => del(`${DELETE_DO_ESTADO}/${id}`, {})
  const deleteIntPlaneamento = (id: number) => del(`${DELETE_INT_TIPOS_PLANEAMENTO_ESTADO}/${id}`, {})
  const deleteIntPrioridade = (id: number) => del(`${DELETE_INT_PRIORIDADE}/${id}`, {})
  const deleteIntTipoIntervencao = (id: number) => del(`${DELETE_TIPO_INTERVENCAO}/${id}`, {})
  const deleteIntInstrumentosPlaneamentos = (id: number) => del(`${DELETE_INT_INSTUMENTO_PLANEAMENTO}/${id}`, {})
  const deleteIntTipoIncidencia= (id: number) => del(`${DELETE_INT_TIPO_INCIDENCIA}/${id}`, {})
  const deleteIntTipoMobilizacao= (id: number) => del(`${DELETE_INT_TIPO_MOBILIZACAO}/${id}`, {})
  const deleteDhTipoProcesso= (id: number) => del(`${DELETE_DH_TIPO_PROCESSO}/${id}`, {})
  const deleteDhFasesProdecimentoDelimitacao= (id: number) => del(`${DELETE_DH_FASES_PROCEDIMENTO_DELIMITACAO}/${id}`, {})
  const deleteDhTipoDominio= (id: number) => del(`${DELETE_DH_TIPO_DOMINIO}/${id}`, {})
  const deleteDhLocalizacaoProcesso= (id: number) => del(`${DELETE_DH_LOCALIZACAO_PROCESSO}/${id}`, {})
  const deleteDhValidacaoProva= (id: number) => del(`${DELETE_DH_VALIDACAO_PROVA}/${id}`, {})
  const deleteDhTipoSentenca= (id: number) => del(`${DELETE_DH_TIPO_SENTENCA}/${id}`, {})
  const deleteDhTipoPendencia= (id: number) => del(`${DELETE_DH_TIPO_PENDENCIA}/${id}`, {})
  const deleteDhTipoNotificacao= (id: number) => del(`${DELETE_DH_TIPO_NOTIFICACAO}/${id}`, {})
  const deleteDhTipoSituacaoNotificacao= (id: number) => del(`${DELETE_DH_TIPO_SITUACAO_NOTIFICACAO}/${id}`, {})
  const deleteDhConcicionantesProcesso= (id: number) => del(`${DELETE_DH_CONDICIONANTES_PROCESSO}/${id}`, {})
  const deleteDhTipoParcela= (id: number) => del(`${DELETE_DH_TIPO_PARCELA}/${id}`, {})
  const deleteDhTipoAcaoJudicial= (id: number) => del(`${DELETE_DH_TIPO_ACAO_JUDICIAL}/${id}`, {})
  const deleteDhTipoDesafetacao= (id: number) => del(`${DELETE_DH_TIPO_DESAFETACAO}/${id}`, {})
  const deleteDhTipoAquisicao= (id: number) => del(`${DELETE_DH_TIPO_AQUISICAO}/${id}`, {})
  const deleteDhReconhecimentoProprieidadePrivada= (id: number) => del(`${DELETE_DH_RECONHECIMENTO_PROPRIEDADE_PRIVADA}/${id}`, {})
  const deleteDhTipoIniciativa= (id: number) => del(`${DELETE_DH_TIPO_INICIATIVA}/${id}`, {})
  const deleteDhTipoLinhaDemarcacao= (id: number) => del(`${DELETE_DH_TIPO_LINHA_DEMARCACAO}/${id}`, {})
  const deleteUsTipoProcesso= (id: number) => del(`${DELETE_US_TIPO_PROCESSO}/${id}`, {})
  const deleteAgTipoPretensao= (id: number) => del(`${DELETE_AG_TIPO_PRETENSAO}/${id}`, {})
  const deleteAgDecisao= (id: number) => del(`${DELETE_AG_DECISAO}/${id}`, {})
  const deleteAgTema= (id: number) => del(`${DELETE_AG_TEMA}/${id}`, {})
  const deleteDiTipoEntidade= (id: number) => del(`${DELETE_DI_TIPO_ENTIDADE}/${id}`, {})
  const deleteDoTipoAcesso= (id: number) => del(`${DELETE_DO_TIPO_ACESSO}/${id}`, {})
  const deleteDoTipoRecurso= (id: number) => del(`${DELETE_DO_TIPO_RECURSO}/${id}`, {})
  const deleteMoModeloSubTipo= (id: number) => del(`${DELETE_MO_MODELO_SUBTIPO}/${id}`, {})
  const getIntEmailUsersList = async () => await get<any>(INT_EMAIL_USERS_LIST_GET_ALL);
  const insertEmailUsersList = async (data: any) => await post<any, any>(`${INT_EMAIL_USERS_INSERT}`, data);
  const updateEmailUsersList = async (data: any) => await put<any, any>(`${INT_EMAIL_USERS_UPDATE}`, data);
  const getSpecificColumnsMoSubTipo = async () => await get<any>(GET_SPECIFIC_COLUMNS_MO_SUBTIPO);
  const getMDTs = async () => get<any>(GET_MDTS); 
  const getMDT = async (id: any) => get<any>(`${GET_MDT}/${id}`);
  const uploadMdt = async (data: any) => await postFormData<any, any>(`${UPLOAD_MDT}`, data);
  const deleteMdt = async(id: number) => await del(`${DELETE_MDT}/${id}`, {});
  const processMdt = async (data: any) => await post<any, any>(`${PROCESS_MDT}`, data);
  const insertMdt = async(data: any) => await post<any, any>(INSERT_MDT, data);
  const mdtNameExists = async(data: any) => await post<any, any>(MDT_NAME_EXISTS, data);
  const updateMdt = async(data: any) => await post<any, any>(UPDATE_MDT, data);

  const remover = async (e: any) => {
    setShowDialog(false);
    switch (tabelaClassificador) {
        case OC_ESTADO_TABLE_NAME:
            await deleteOcorrenciaEstado(idField);
            break;
        case MO_ESTADO_TABLE_NAME:
            await deleteMoEstado(idField);
            break;
        case MO_MODELOS_TIPO_TABLE_NAME:
            await deleteModeloTipo(idField);
            break;
        case INT_ESTADO_TABLE_NAME:
            await deleteIntEstado(idField);
            break;
        case ACAO_ESTADO_TABLE_NAME:
            await deleteAcaoEstado(idField);
            break;
        case MOBILIZACAO_ESTADO_TABLE_NAME:
            await deleteMobilizacaoEstado(idField);
            break;
        case CANDIDATURA_ESTADO_TABLE_NAME:
            await deleteCandidaturaEstado(idField);
            break;
        case DH_ESTADO_PROCESSO_TABLE_NAME:
            await deleteProcessoEstado(idField);
            break;
        case US_ESTADO_TITULO_TABLE_NAME:
            await deleteUsEstadoTitulo(idField);
            break;
        case AG_ESTADO_TABLE_NAME:
            await deleteAgEstado(idField);
            break;
        case EA_ESTADO_TABLE_NAME:
            await deleteEaEstado(idField);
            break;
        case DO_ESTADO_TABLE_NAME:
            await deleteDoEstado(idField);
            break;
        case INT_PLANEAMENTO_NIVEL_1_TABLE_NAME:
            await deleteIntPlaneamento(idField);
            break;
        case INT_PRIORIDADE_TABLE_NAME:
            await deleteIntPrioridade(idField);
            break;
        case INT_TIPO_INTERVENCAO_TABLE_NAME:
            await deleteIntTipoIntervencao(idField);
            break;
        case INT_INSTRUMENTO_PLANEAMENTO_ESTADO_TABLE_NAME:
            await deleteIntInstrumentosPlaneamentos(idField);
            break;
        case INT_TIPO_INCIDENCIA_TABLE_NAME:
            await deleteIntTipoIncidencia(idField);
            break;
        case INT_TIPO_MOBILIZACAO_TABLE_NAME:
            await deleteIntTipoMobilizacao(idField);
            break;
        case DH_TIPO_PROCESSO_TABLE_NAME:
            await deleteDhTipoProcesso(idField);
            break;
        case DH_FASES_PROCEDIMENTO_DELIMITACAO_TABLE_NAME:
            await deleteDhFasesProdecimentoDelimitacao(idField);
            break;
        case DH_TIPO_DOMINIO_TABLE_NAME:
            await deleteDhTipoDominio(idField);
            break;
        case DH_LOCALIZACAO_PROCESSO_TABLE_NAME:
            await deleteDhLocalizacaoProcesso(idField);
            break;
        case DH_VALIDACAO_PROVA_TABLE_NAME:
            await deleteDhValidacaoProva(idField);
            break;
        case DH_TIPO_SENTENCA_TABLE_NAME:
            await deleteDhTipoSentenca(idField);
            break;
        case DH_TIPO_PENDENCIA_TABLE_NAME:
            await deleteDhTipoPendencia(idField);
            break;
        case DH_TIPO_NOTIFICACAO_TABLE_NAME:
            await deleteDhTipoNotificacao(idField);
            break;
        case DH_TIPO_SITUACAO_NOTIFICACAO_TABLE_NAME:
            await deleteDhTipoSituacaoNotificacao(idField);
            break;
        case DH_CONDICIONANTES_PROCESSO_TABLE_NAME:
            await deleteDhConcicionantesProcesso(idField);
            break;
        case DH_TIPO_PARCELA_TABLE_NAME:
            await deleteDhTipoParcela(idField);
            break;
        case DH_TIPO_ACAO_JUDICIAL_TABLE_NAME:
            await deleteDhTipoAcaoJudicial(idField);
            break;
        case DH_TIPO_DESAFETACAO_TABLE_NAME:
            await deleteDhTipoDesafetacao(idField);
            break;
        case DH_TIPO_AQUISICAO_TABLE_NAME:
            await deleteDhTipoAquisicao(idField);
            break;
        case DH_RECONHECIMENTO_PROPRIEDADE_PRIVADA_TABLE_NAME:
            await deleteDhReconhecimentoProprieidadePrivada(idField);
            break;
        case DH_TIPO_INICIATIVA:
            await deleteDhTipoIniciativa(idField);
            break;
        case DH_TIPO_LINHA_DEMARCACAO_TABLE_NAME:
            await deleteDhTipoLinhaDemarcacao(idField);
            break;
        case US_TIPO_PROCESSO_TABLE_NAME:
            await deleteUsTipoProcesso(idField);
            break;
        case AG_TIPO_PRETENSAO_TABLE_NAME:
            await deleteAgTipoPretensao(idField);
            break;
        case AG_DECISAO_TABLE_NAME:
            await deleteAgDecisao(idField);
            break;
        case AG_TEMA_TABLE_NAME:
            await deleteAgTema(idField);
            break;
        case DI_TIPO_ENTIDADE_TABLE_NAME:
            await deleteDiTipoEntidade(idField);
            break;
        case DO_TIPO_ACESSO_TABLE_NAME:
            await deleteDoTipoAcesso(idField);
            break;
        case DO_TIPO_RECURSO_TABLE_NAME:
            await deleteDoTipoRecurso(idField);
            break;
        case MO_MODELO_SUBTIPO_TABLE_NAME:
            await deleteMoModeloSubTipo(idField);
            break;
        case MDT_SELECTION_NAME:
          await deleteMdt(idField);
          break;
        default:
    }
    refreshGrid(tabelaClassificador);
}
  useEffect(() => {
    setShowGravar(false);
    setShowPesquisar(true);
  }, []);

  const handleSubmit = async (e: any) => {
    setTabelaClassificador(e.values?.tipoClassificador?.tabela);
    refreshGrid(e.values?.tipoClassificador?.tabela);
  }

  const [showDescricao , setShowDescricao] = useState(false);
  const [showDesignacao , setShowDesignacao] = useState(false);
  const [showNome , setShowNome] = useState(false);
  const [classificadores, setClassificadores] = useState<any[]>();

  const [showRenderizaNovoRegisto, setShowRenderizaNovoRegisto] = useState(false);

  const classificadoresConst: any[] = [
    {
      tabela: OC_ESTADO_TABLE_NAME,
      descricao: "Ocorrencias - Estado"
    },
    {
      tabela: MO_ESTADO_TABLE_NAME,
      descricao: "Modelos - Estado"
    },
    {
      tabela: MO_MODELOS_TIPO_TABLE_NAME,
      descricao: "Modelos - Tipo"
    }, 
    {
      tabela: INT_ESTADO_TABLE_NAME,
      descricao: "Intervenção - Estado"
    },
    {
      tabela: ACAO_ESTADO_TABLE_NAME,
      descricao: "Ação - Estado"
    },
    {
      tabela: MOBILIZACAO_ESTADO_TABLE_NAME,
      descricao: "Mobilização - Estado"
    },
    {
      tabela: CANDIDATURA_ESTADO_TABLE_NAME,
      descricao: "Candidatura - Estado"
    },
    {
      tabela: DH_ESTADO_PROCESSO_TABLE_NAME,
      descricao: "Processo - Estado"
    },
    {
      tabela: US_ESTADO_TITULO_TABLE_NAME,
      descricao: "Uso do Solo - Estado"
    },
    {
      tabela: AG_ESTADO_TABLE_NAME,
      descricao: "Apoio á gestão - Estado"
    },
    {
      tabela: EA_ESTADO_TABLE_NAME,
      descricao: "Estrategia de Adaptação - Estado"
    },
    {
      tabela: DO_ESTADO_TABLE_NAME,
      descricao: "Documento - Estado"
    },
    {
      tabela: INT_PLANEAMENTO_NIVEL_1_TABLE_NAME,
      descricao: "Intervenção - Planeamento Tipo Nível 1"
    },
    {
      tabela: INT_PRIORIDADE_TABLE_NAME,
      descricao: "Intervenção - Prioridade"
    }, 
    {
      tabela: INT_TIPO_INTERVENCAO_TABLE_NAME,
      descricao: "Intervenção - Tipo Intervenção"
    }, 
    {
      tabela: INT_INSTRUMENTO_PLANEAMENTO_ESTADO_TABLE_NAME,
      descricao: "Intervenção - Instrumento Planeamento Estados"
    },
    {
      tabela: INT_TIPO_INCIDENCIA_TABLE_NAME,
      descricao: "Intervenção - Tipo Incidência"
    },
    {
      tabela: INT_TIPO_MOBILIZACAO_TABLE_NAME,
      descricao: "Mobilização - Tipo Mobilização"
    },
    {
      tabela: DH_TIPO_PROCESSO_TABLE_NAME,
      descricao: "Domínio Hidrico - Tipo Processo"
    },
    {
      tabela: DH_FASES_PROCEDIMENTO_DELIMITACAO_TABLE_NAME,
      descricao: "Domínio Hidrico - Fases de Procedimento Delimitação"
    },
    {
      tabela: DH_TIPO_DOMINIO_TABLE_NAME,
      descricao: "Domínio Hidrico - Tipo Domínio"
    },
    {
      tabela: DH_LOCALIZACAO_PROCESSO_TABLE_NAME,
      descricao: "Domínio Hidrico - Localização Processo"
    },
    {
      tabela: DH_VALIDACAO_PROVA_TABLE_NAME,
      descricao: "Domínio Hidrico - Validacao Prova"
    },
    {
      tabela: DH_TIPO_SENTENCA_TABLE_NAME,
      descricao: "Domínio Hidrico - Tipo Sentença"
    },  
    {
      tabela: DH_TIPO_PENDENCIA_TABLE_NAME,
      descricao: "Domínio Hidrico - Tipo Pendência"
    },
    {
      tabela: DH_TIPO_NOTIFICACAO_TABLE_NAME,
      descricao: "Domínio Hidrico - Tipo Notificacao"
    },
    {
      tabela: DH_TIPO_SITUACAO_NOTIFICACAO_TABLE_NAME,
      descricao: "Domínio Hidrico - Tipo Situação Notificação"
    },  
    {
      tabela: DH_CONDICIONANTES_PROCESSO_TABLE_NAME,
      descricao: "Domínio Hidrico - Condicionantes Processo"
    },
    {
      tabela: DH_TIPO_PARCELA_TABLE_NAME,
      descricao: "Domínio Hidrico - Tipo Parcela"
    },
    {
      tabela: DH_TIPO_ACAO_JUDICIAL_TABLE_NAME,
      descricao: "Domínio Hidrico - Tipo Ação Judicial"
    },
    {
      tabela: DH_TIPO_DESAFETACAO_TABLE_NAME,
      descricao: "Domínio Hidrico - Tipo Desafetação"
    },
    {
      tabela: DH_TIPO_AQUISICAO_TABLE_NAME,
      descricao: "Domínio Hidrico - Tipo Aquisição"
    },
    {
      tabela: DH_RECONHECIMENTO_PROPRIEDADE_PRIVADA_TABLE_NAME,
      descricao: "Domínio Hidrico - Reconhecimento Propriedade Privada"
    },
    {
      tabela: DH_TIPO_INICIATIVA,
      descricao: "Domínio Hidrico - Tipo Iniciativa"
    },  
    {
      tabela: DH_TIPO_LINHA_DEMARCACAO_TABLE_NAME,
      descricao: "Domínio Hidrico - Tipo Linha Demarcação"
    },
    {
      tabela: US_TIPO_PROCESSO_TABLE_NAME,
      descricao: "Uso do Solo - Tipo de Processo"
    },
    {
      tabela: AG_TIPO_PRETENSAO_TABLE_NAME,
      descricao: "Apoio à Gestão - Tipo Pretensão"
    },
    {
      tabela: AG_DECISAO_TABLE_NAME,
      descricao: "Apoio à Gestão - Decisao"
    },
    {
      tabela: AG_TEMA_TABLE_NAME,
      descricao: "Apoio à Gestão - Tema"
    },
    {
      tabela: DI_TIPO_ENTIDADE_TABLE_NAME,
      descricao: "Entidade - Tipo Entidade"
    },
    {
      tabela: DO_TIPO_ACESSO_TABLE_NAME,
      descricao: "Documentos - Tipo Acesso"
    },
    {
      tabela: DO_TIPO_RECURSO_TABLE_NAME,
      descricao: "Documentos - Tipo Recurso"
    },
    {
      tabela: MO_MODELO_SUBTIPO_TABLE_NAME,
      descricao: "Modelos - SubTipo"
    },
    {
      tabela: EMAIL_USERS_LIST_TABLE_NAME,
      descricao: "Intervenções - Lista de envio de emails"
    },
    {
      tabela: MDT_SELECTION_NAME,
      descricao: "MDTs - Calculo de perfis geograficos"
    }
   
  ]

  useEffect(() => {
    setClassificadores(classificadoresConst.sort((a, b) => a.descricao.localeCompare(b.descricao)));
  }, []);

  const hideAll = () => {
    setShowUploadDocumentos(false);
    setShowDesignacao(false)
    setShowNome(false)
    setShowDescricao(false);
    setShowUserId(false);
    setShowEmail(false);
    setDescricaoDisabled(false);
    setGravarDisabled(false);
    setshowDataVoo(false);
    setShowTipo(false);
    setshowDataVoo(false);
    setNomeDisabled(false);
  }

  const refreshGrid = async (tabelaClassificadorLocal: any) => {
    postMessage({ type: 'showLoader' }, '*')
    let result: any = [];
    setTabelaClassificador(tabelaClassificadorLocal);
    hideAll();
    if (tabelaClassificadorLocal == OC_ESTADO_TABLE_NAME) {
      result = await getOcEstados();
      setShowDescricao(true)
      setShowDesignacao(true)
    }
    else if (tabelaClassificadorLocal == MO_ESTADO_TABLE_NAME) {
      result = await getMoEstado();
      setShowDescricao(true)
      setShowDesignacao(true)
    } else if (tabelaClassificadorLocal == MO_MODELOS_TIPO_TABLE_NAME) {
      result =await getMoModeloTipos();
      setShowDescricao(true)
      setShowDesignacao(true)
    } else if (tabelaClassificadorLocal == INT_ESTADO_TABLE_NAME) {
      setShowDescricao(true)
      result =await getIntEstado();
    } else if (tabelaClassificadorLocal == ACAO_ESTADO_TABLE_NAME) {
      result =await getAcaoEstado();
      setShowDescricao(true)
    } else if (tabelaClassificadorLocal == MOBILIZACAO_ESTADO_TABLE_NAME) {
      result =await getMobilizacaoEstado();
      setShowDescricao(true)
    } else if (tabelaClassificadorLocal == CANDIDATURA_ESTADO_TABLE_NAME) {
      result =await getCandidaturaEstado();
      setShowDescricao(true)
    } else if (tabelaClassificadorLocal == DH_ESTADO_PROCESSO_TABLE_NAME) {
      result =await getEstadoProcesso();
      setShowDescricao(true)
    } else if (tabelaClassificadorLocal == US_ESTADO_TITULO_TABLE_NAME) {
      result =await getUsEstadoTitulo();
      setShowDescricao(true) 
    } else if (tabelaClassificadorLocal == AG_ESTADO_TABLE_NAME) {
      setShowDescricao(true)
      setShowDesignacao(true)
      result =await getAgEstado();
    }else if (tabelaClassificadorLocal == EA_ESTADO_TABLE_NAME) {
      result =await getAgEstado();
      setShowDesignacao(true)
     }else if (tabelaClassificadorLocal == DO_ESTADO_TABLE_NAME) {
      setShowDescricao(true)
      setShowDesignacao(true)
      result =await getDoEstado();
    }else if (tabelaClassificadorLocal == INT_PLANEAMENTO_NIVEL_1_TABLE_NAME) {
      result =await getIntPlaneamento1();
      setShowDescricao(true)
    }else if (tabelaClassificadorLocal == INT_PRIORIDADE_TABLE_NAME) {
      result =await getIntPrioridade();
      setShowDescricao(true)
    }else if (tabelaClassificadorLocal == INT_TIPO_INTERVENCAO_TABLE_NAME) {
      result =await getIntTipoIntervencao();
      setShowDescricao(true)
      
    }else if (tabelaClassificadorLocal == INT_INSTRUMENTO_PLANEAMENTO_ESTADO_TABLE_NAME) {
      result =await getIntIntrumentoPlaneamentoEstado();
      setShowDescricao(true)
    }else if (tabelaClassificadorLocal == INT_TIPO_INCIDENCIA_TABLE_NAME) {
      result =await getIntTipoIncidencia();
      setShowDescricao(true)
    }else if (tabelaClassificadorLocal == INT_TIPO_MOBILIZACAO_TABLE_NAME) {
      result =await getIntTipoMobilizacao();
      setShowDescricao(true)
    }else if (tabelaClassificadorLocal == DH_TIPO_PROCESSO_TABLE_NAME) {
      result =await getDhTipoProcesso();
      setShowDescricao(true)
    }else if (tabelaClassificadorLocal == DH_FASES_PROCEDIMENTO_DELIMITACAO_TABLE_NAME) {
      result =await getDhFasesProcedimento();
      setShowDescricao(true)
    }else if (tabelaClassificadorLocal == DH_TIPO_DOMINIO_TABLE_NAME) {
      result =await getDhTipoDominio();
      setShowDescricao(true)
    }else if (tabelaClassificadorLocal == DH_LOCALIZACAO_PROCESSO_TABLE_NAME) {
      result =await getDhLocalizacaoProcesso();
      setShowDescricao(true)
    }else if (tabelaClassificadorLocal == DH_VALIDACAO_PROVA_TABLE_NAME) {
      setShowDescricao(true)
      result =await getDhValidacaoProva();
    }else if (tabelaClassificadorLocal == DH_TIPO_SENTENCA_TABLE_NAME) {
      setShowDescricao(true)
      result =await getDhTipoSentença();
    }else if (tabelaClassificadorLocal == DH_TIPO_PENDENCIA_TABLE_NAME) {
      setShowDescricao(true)
      result =await getDhTipoPendencia();
    }else if (tabelaClassificadorLocal == DH_TIPO_NOTIFICACAO_TABLE_NAME) {
      setShowDescricao(true)
      result =await getDhTipoNotificacao();
    }else if (tabelaClassificadorLocal == DH_TIPO_SITUACAO_NOTIFICACAO_TABLE_NAME) {
      setShowDescricao(true)
      result =await getDhTipoSituacaoNotificacao();
    }else if (tabelaClassificadorLocal == DH_CONDICIONANTES_PROCESSO_TABLE_NAME) {
      setShowDescricao(true)
      result =await getDhCondicionantesProcesso();
    }else if (tabelaClassificadorLocal == DH_TIPO_PARCELA_TABLE_NAME) {
      setShowDescricao(true)
      result =await getDhTipoParcela();
    }else if (tabelaClassificadorLocal == DH_TIPO_ACAO_JUDICIAL_TABLE_NAME) {
      setShowDescricao(true)
      result =await getDhTipoAcaoJudicial();
    }else if (tabelaClassificadorLocal == DH_TIPO_DESAFETACAO_TABLE_NAME) {
      setShowDescricao(true)
      result =await getDhTipoDesafetacao();
    }else if (tabelaClassificadorLocal == DH_TIPO_AQUISICAO_TABLE_NAME) {
      setShowDescricao(true)
      result =await getDhTipoAquisicao();
    }else if (tabelaClassificadorLocal == DH_RECONHECIMENTO_PROPRIEDADE_PRIVADA_TABLE_NAME) {
      setShowDescricao(true)
      result =await getDhReconhecimentoPropriedadePrivada();
    }else if (tabelaClassificadorLocal == DH_TIPO_INICIATIVA) {
      setShowDescricao(true)
      result =await getDhTipoIniciativa();
    }else if (tabelaClassificadorLocal == DH_TIPO_LINHA_DEMARCACAO_TABLE_NAME) {
      setShowDescricao(true)
      result =await getDhTipoLinhaDemarcacao();
    }else if (tabelaClassificadorLocal == US_TIPO_PROCESSO_TABLE_NAME) {
      setShowNome(true)
      result =await getUsTipoProcesso();
    }else if (tabelaClassificadorLocal == AG_TIPO_PRETENSAO_TABLE_NAME) {
      setShowDescricao(true)
      result =await getAgTipoPretensao();
    }else if (tabelaClassificadorLocal == AG_DECISAO_TABLE_NAME) {
      setShowDescricao(true)
      result =await getAgDecisao();
    }else if (tabelaClassificadorLocal == AG_TEMA_TABLE_NAME) {
      setShowDescricao(true)
      result =await getAgtema();
    }else if (tabelaClassificadorLocal == DI_TIPO_ENTIDADE_TABLE_NAME) {
      setShowDesignacao(true)
      result =await getDiTipoEntidade();
    }else if (tabelaClassificadorLocal == DO_TIPO_ACESSO_TABLE_NAME) {
      setShowDescricao(true)
      setShowDesignacao(true)  
      result =await getDoTipoAcesso();
    }else if (tabelaClassificadorLocal == DO_TIPO_RECURSO_TABLE_NAME) {
      setShowDescricao(true)
      setShowDesignacao(true)
      result =await getDoTipoRecurso();
    }else if (tabelaClassificadorLocal == MO_MODELO_SUBTIPO_TABLE_NAME) {
      setShowDescricao(true)
      setShowDesignacao(true)
      result = await getSpecificColumnsMoSubTipo(); 
    } else if (tabelaClassificadorLocal == EMAIL_USERS_LIST_TABLE_NAME) {
      result = await getIntEmailUsersList();
      setShowEmail(true);
      setShowUserId(true);
    }
    else if (tabelaClassificadorLocal == MDT_SELECTION_NAME) {
      result = await getMDTs();
      setShowDescricao(true);     
      setShowUploadDocumentos(true);
      setGravarDisabled(true);
      setShowNome(true);
      setShowTipo(true);
      setshowDataVoo(true);
      setNomeDisabled(true);
      setShowDesignacao(true);
    }
    
    if (result) {
      setData(result);
    }
    postMessage({ type: 'hideLoader' }, '*')
  }

  const registarNovoClick = () => {
    setShowDialog(true);
    setNovoRegisto(true);
    clearFieldsClassificador();
    setEdit(false)
  }

  const onChangeIdField = (e: any) => {
    setIdField(e.value);
  }
 
  useLayoutEffect(() => {

    const handleResize = () => {

      if (window.innerWidth <= telaMobile) {
        
        setIsMobile(true);
        ocultaFloatingMenu_e_Mapa();

      }else{

        setIsMobile(false);
        mostraFloatingMenu_e_Mapa();

      }

    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  const onItemClickMais = (event: any) => {

    elementoClickMais(event);

  };

  const optionsMais = [
    {
      key: 'novoRegisto',
      text: 'Nova entidade',
      elementType: 'button',
      className: 'icon-siarl icon-registar btnIconRegistar'
    }
  ]

  const [edit, setEdit] = useState(false);


  const gridRowClick = (e: any) => {
    console.log(e);
    setNovoRegisto(false);
    setIdField(e.dataItem.id);
    setDesignacaoField(e.dataItem.designacao);
    setDescricaoField(e.dataItem.descricao);
    setNomeField(e.dataItem.nome);
    setEmailField(e.dataItem.email);
    setUserIdField(e.dataItem.user_id);
    setTipoField(e.dataItem.tipo);
    if(hasClassificador == true){
      setShowDialog(true);

    }
    setEdit(true)
  }

  const [percentTop, setPercentTop] = useState("3%");

  const gravar = async (e:any) => {
    if (!idField) {
    if (fileField == undefined || fileField.length === 0) {

      postMessage({ type: 'errorMsg', value: { top: (isMobile? (Number(percentTop.split('%')[0])-5)+'%' : percentTop) , message: "Carregue o MDT." } }, '*');
      return;
    }
  }


    setShowGravar(false);
    setShowPesquisar(true);
    if (tabelaClassificador == MO_MODELOS_TIPO_TABLE_NAME) {
      const objToSend = {
        id: idField,
        designacao: designacaoField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }

        await insertModeloTipo(objToSend);
      }
      else {
        await updateModeloTipo(objToSend);
      }
    }

    if (tabelaClassificador == OC_ESTADO_TABLE_NAME) {
      const objToSend = {
        id: idField,
        designacao: designacaoField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }
        await insertOcEstado(objToSend);
      }
      else {
        await updateOcEstado(objToSend);
      }
    }
    
    if (tabelaClassificador == MO_ESTADO_TABLE_NAME) {
      const objToSend = {
        id: idField,
        designacao: designacaoField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }

        await insertMoEstado(objToSend);
      }
      else {
        await updateMoEstado(objToSend);
      }
    }
    if (tabelaClassificador == INT_ESTADO_TABLE_NAME) {
      const objToSend = {
        id: idField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }

        await insertIntEstado(objToSend);
      }
      else {
        await updateIntEstado(objToSend);
      }
    }

    if (tabelaClassificador == ACAO_ESTADO_TABLE_NAME) {
      const objToSend = {
        id: idField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }

        await insertAcaoEstado(objToSend);
      }
      else {
        await updateAcaoEstado(objToSend);
      }
    }

    if (tabelaClassificador == MOBILIZACAO_ESTADO_TABLE_NAME) {
      const objToSend = {
        id: idField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }

        await insertMobilizacaoEstado(objToSend);
      }
      else {
        await updateMobilizacaoEstado(objToSend);
      }
    }
    
    if (tabelaClassificador == CANDIDATURA_ESTADO_TABLE_NAME) {
      const objToSend = {
        id: idField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }

        await insertCandidaturaEstado(objToSend);
      }
      else {
        await updateCandidaturaEstado(objToSend);
      }
    }
    
    if (tabelaClassificador == DH_ESTADO_PROCESSO_TABLE_NAME) {
      const objToSend = {
        id: idField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }
        await insertUsTituloEstado(objToSend);
      }
      else {
        await updateUsTituloEstado(objToSend);
      }
    }

    if (tabelaClassificador == US_ESTADO_TITULO_TABLE_NAME) {
      const objToSend = {
        id: idField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }
        await insertUsTituloEstado(objToSend);
      }
      else {
        await updateUsTituloEstado(objToSend);
      }
    }
    
    if (tabelaClassificador == AG_ESTADO_TABLE_NAME) {
      const objToSend = {
        id: idField,
        designacao: designacaoField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        objToSend['id'] = undefined;
        await insertAgEstado(objToSend);
      }
      else {
        await updateAgEstado(objToSend);
      }
    }
    
    if (tabelaClassificador == EA_ESTADO_TABLE_NAME) {
      const objToSend = {
        id: idField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        objToSend['id'] = undefined;
        await insertEaEstado(objToSend);
      }
      else {
        await updateEaEstado(objToSend);
      }
    }
    
    if (tabelaClassificador == DO_ESTADO_TABLE_NAME) {
      const objToSend = {
        id: idField,
        designacao: designacaoField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }
        await insertDoEstado(objToSend);
      }
      else {
        await updateDoEstado(objToSend);
      }
    }
    
    if (tabelaClassificador == INT_PLANEAMENTO_NIVEL_1_TABLE_NAME) {
      const objToSend = {
        id: idField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }

        await insertIntPlaneamento1(objToSend);
      }
      else {
        await updateIntPlaneamento1(objToSend);
      }

    }
    
    if (tabelaClassificador == INT_PRIORIDADE_TABLE_NAME) {
      const objToSend = {
        id: idField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }

        await insertIntPrioridade(objToSend);
      }
      else {
        await updateIntPrioridade(objToSend);
      }
    }
    
    if (tabelaClassificador == INT_TIPO_INTERVENCAO_TABLE_NAME) {
      const objToSend = {
        id: idField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }

        await insertIntTipoIntervencao(objToSend);
      }
      else {
        await updateIntTipoIntervencao(objToSend);
      }
    }
    
    if (tabelaClassificador == INT_INSTRUMENTO_PLANEAMENTO_ESTADO_TABLE_NAME) {
      const objToSend = {
        id: idField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }

        await insertIntIntrumentoPlaneamentoEstado(objToSend);
      }
      else {
        await updateIntIntrumentoPlaneamentoEstado(objToSend);
      }
    }
    
    if (tabelaClassificador == INT_TIPO_INCIDENCIA_TABLE_NAME) {
      const objToSend = {
        id: idField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }
        await insertIntTipoIncidencia(objToSend);
      }
      else {
        await updateIntTipoIncidencia(objToSend);
      }
    }
    
    if (tabelaClassificador == INT_TIPO_MOBILIZACAO_TABLE_NAME) {
      const objToSend = {
        id: idField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }


        await insertIntTipoMobilizacao(objToSend);
      }
      else {
        await updateIntTipoMobilizacao(objToSend);
      }
    }
    
    if (tabelaClassificador == DH_TIPO_PROCESSO_TABLE_NAME) {
      const objToSend = {
        id: idField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }

        await insertDhTipoProcesso(objToSend);
      }
      else {
        await updateDhTipoProcesso(objToSend);
      }
    }
    
    if (tabelaClassificador == DH_FASES_PROCEDIMENTO_DELIMITACAO_TABLE_NAME) {
      const objToSend = {
        id: idField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }

        await insertDhFasesProcedimento(objToSend);
      }
      else {
        await updateDhFasesProcedimento(objToSend);
      }
    }
    
    if (tabelaClassificador == DH_TIPO_DOMINIO_TABLE_NAME) {
      const objToSend = {
        id: idField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }

        await insertDhTipoDominio(objToSend);
      }
      else {
        await updateDhTipoDominio(objToSend);
      }
    }
    
    if (tabelaClassificador == DH_LOCALIZACAO_PROCESSO_TABLE_NAME) {
      const objToSend = {
        id: idField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }
        
        await insertDhLocalizacaoProcesso(objToSend);
      }
      else {
        await updateDhLocalizacaoProcesso(objToSend);
      }
    }
    
    if (tabelaClassificador == DH_VALIDACAO_PROVA_TABLE_NAME) {
      const objToSend = {
        id: idField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }

        await insertDhValidacaoProva(objToSend);
      }
      else {
        await updateDhValidacaoProva(objToSend);
      }
    }
    
    if (tabelaClassificador == DH_TIPO_SENTENCA_TABLE_NAME) {
      const objToSend = {
        id: idField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }

        await insertDhTipoSentença(objToSend);
      }
      else {
        await updateDhTipoSenteça(objToSend);
      }
    }

    if (tabelaClassificador == DH_TIPO_PENDENCIA_TABLE_NAME) {
      const objToSend = {
        id: idField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }

        await insertDhTipoPendencia(objToSend);
      }
      else {
        await updateDhTipoPendencia(objToSend);
      }
    }
    
    if (tabelaClassificador == DH_TIPO_NOTIFICACAO_TABLE_NAME) {
      const objToSend = {
        id: idField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }

        await insertDhTipoNotificacao(objToSend);
      }
      else {
        await updateDhTipoNotificacao(objToSend);
      }
    }
    
    if (tabelaClassificador == DH_TIPO_SITUACAO_NOTIFICACAO_TABLE_NAME) {
      const objToSend = {
        id: idField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }

        await insertDhTipoSituacaoNotificacao(objToSend);
      }
      else {
        await updateDhTipoSituacaoNotificacao(objToSend);
      }
    }
      if (tabelaClassificador == DH_CONDICIONANTES_PROCESSO_TABLE_NAME) {
        const objToSend = {
          id: idField,
          descricao: descricaoField ?? undefined
        }
        if (novoRegisto) {
          if (!idField) {
            postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
            return;
          }
          for(let i=0; i<= data.length; i++) {
            const dataObj: any = data[i];
            if (dataObj?.id == idField) {
              postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
              return;
            }
          }

          await insertDhCondicionantesProcesso(objToSend);
        }
        else {
          await updateDhCondicionantesProcesso(objToSend);
        } 
    }
    
    if (tabelaClassificador == DH_TIPO_PARCELA_TABLE_NAME) {
      const objToSend = {
        id: idField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }

        await insertDhTipoParcela(objToSend);
      }
      else {
        await updateDhTipoParcela(objToSend);
      }
    }

    if (tabelaClassificador == DH_TIPO_ACAO_JUDICIAL_TABLE_NAME) {
      const objToSend = {
        id: idField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }

        await insertDhTipoAcaoJudicial(objToSend);
      }
      else {
        await updateDhTipoAcaoJudicial(objToSend);
      }
    }

    if (tabelaClassificador == DH_TIPO_DESAFETACAO_TABLE_NAME) {
      const objToSend = {
        id: idField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }

        await insertDHTipoDesafetacao(objToSend);
      }
      else {
        await updateDhTipoDesafetacao(objToSend);
      }
    }

    if (tabelaClassificador == DH_TIPO_AQUISICAO_TABLE_NAME) {
      const objToSend = {
        id: idField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }

        await insertDHTipoAquisicao(objToSend);
      }
      else {
        await updateDhTipoAquisicao(objToSend);
      }
    }

    if (tabelaClassificador == DH_RECONHECIMENTO_PROPRIEDADE_PRIVADA_TABLE_NAME) {
      const objToSend = {
        id: idField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }

        await insertDhReconhecimentoPropriedadePrivada(objToSend);
      }
      else {
        await updateDhReconhecimentoPropriedadePrivada(objToSend);
      }
    }

    if (tabelaClassificador == DH_TIPO_INICIATIVA) {
      const objToSend = {
        id: idField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }

        await insertDhTipoIniciativa(objToSend);
      }
      else {
        await updateDhtipoIniciativa(objToSend);
      }
    }
    if (tabelaClassificador == DH_TIPO_LINHA_DEMARCACAO_TABLE_NAME) {
      const objToSend = {
        id: idField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }

        await insertDhTipoLinhaDemarcacao(objToSend);
      }
      else {
        await updateDhTipoLinhaDemarcacao(objToSend);
      }
    }
    
    if (tabelaClassificador == US_TIPO_PROCESSO_TABLE_NAME) {
      const objToSend = {
        id: idField,
        nome: nomeField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }

        await insertUsTipoProcesso(objToSend);
      }
      else {
        await updateUsTipoProcesso(objToSend);
      }
    }
    
    if (tabelaClassificador == AG_TIPO_PRETENSAO_TABLE_NAME) {
      const objToSend = {
        id: idField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }

        await insertAgTipoPretensao(objToSend);
      }
      else {
        await updateAgTipoPretensao(objToSend);
      }
    }
    
    if (tabelaClassificador == AG_DECISAO_TABLE_NAME) {
      const objToSend = {
        id: idField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }

        await insertAgDecisao(objToSend);
      }
      else {
        await updateAgDecisao(objToSend);
      }
    }
    
    if (tabelaClassificador == AG_TEMA_TABLE_NAME) {
      const objToSend = {
        id: idField,
        descricao: descricaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }

        await insertAgTema(objToSend);
      }
      else {
        await updateAgTema(objToSend);
      }
    }
    
    if (tabelaClassificador == DI_TIPO_ENTIDADE_TABLE_NAME) {
      const objToSend = {
        id: idField,
        designacao: designacaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }

        await insertDiTipoEntidade(objToSend);
      }
      else {
        await updateDiTipoEntidade(objToSend);
      }
    }
    
    if (tabelaClassificador == DO_TIPO_ACESSO_TABLE_NAME) {
      const objToSend = {
        id: idField,
        descricao: descricaoField ?? undefined,
        designacao: designacaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }

        await insertDoTipoAcesso(objToSend);
      }
      else {
        await updateDoTipoAcesso(objToSend);
      }
    }
    
    if (tabelaClassificador == DO_TIPO_RECURSO_TABLE_NAME) {
      const objToSend = {
        id: idField,
        descricao: descricaoField ?? undefined,
        designacao: designacaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }   

        await insertDoTipoRecurso(objToSend);
      }
      else {
        await updateDoTipoRecurso(objToSend);
      }
    }
    
    if (tabelaClassificador == MO_MODELO_SUBTIPO_TABLE_NAME) {
      const objToSend = {
        id: idField,
        descricao: descricaoField,
        designacao: designacaoField ?? undefined
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }

        await insertMoSubTipoModelo(objToSend);
      }
      else {
        await updateMoSubTipoModelo(objToSend);
      }
    }

    if (tabelaClassificador == EMAIL_USERS_LIST_TABLE_NAME) {
      const objToSend = {
        id: idField,
        email: emailField,
        user_id: userIdField
      }
      if (novoRegisto) {
        if (!idField) {
          postMessage({type: "infoMsg", value: {message: "Preencha o id"}});
          return;
        }
        for(let i=0; i<= data.length; i++) {
          const dataObj: any = data[i];
          if (dataObj?.id == idField) {
            postMessage({type: "errorMsg", value: {message: "O id ja existe"}}, "*");
            return;
          }
        }

        await insertEmailUsersList(objToSend);
      }
      else {
        await updateEmailUsersList(objToSend);
      }
    }

    if (tabelaClassificador == MDT_SELECTION_NAME) {
      console.log(MDT_SELECTION_NAME);
      if (!idField) {
      const nomeArquivo = fileField[0].name;

      const exists = await mdtNameExists(nomeArquivo);

      if (exists) {
        postMessage({type: "infoMsg", value: { message: "Já existe um ficheiro com esse nome." }}, "*");
        return;
      }
    
      const id_mdt = await insertMdt({
        tipo: tipoField,
        designacao: designacaoField,
        descricao: descricaoField,
        data: dataField,
        densidade: densidadeField
      });      
      const formData = new FormData();
      formData.append("mdt", fileField[0].getRawFile());
      formData.append("id_mdt", id_mdt);
      postMessage({type: "showLoader"}, "*");
      const cgmdt = await uploadMdt(formData);
      processMdt(cgmdt.id);
      postMessage({type: "sucessMsg", value: "Classificador gravado, aguarde o processamento..."}, "*");
      const intervalId = setInterval(async() => {
        const mdt = await getMDT(cgmdt.id);
        if (mdt.data_fim_processamento) {
          clearInterval(intervalId);
          postMessage({type: "hideLoader"}, "*");
          setShowDialog(false);
          refreshGrid(tabelaClassificador);
        }
      }, 10000);
    } else {
      const objectToSend = {
        id : idField,
        tipo: tipoField,
        designacao: designacaoField,
        descricao: descricaoField,
        data: dataField,
        densidade: densidadeField
      }

      await updateMdt(objectToSend);
      postMessage({type: "sucessMsg", value: "Classificador gravado"}, "*");
      setShowDialog(false);
      refreshGrid(tabelaClassificador);
    }
    console.log(e);
    } else 
    {
      refreshGrid(tabelaClassificador);
      postMessage({type: "sucessMsg", value: "Classificador gravado"}, "*");
      setShowDialog(false);
      refreshGrid(tabelaClassificador);
    }
    
  }

  const clearFieldsClassificador = () => {
    setIdField('');
    setDesignacaoField('');
    setDescricaoField('');
    setNomeField('');
    setTipoField('');
    setDataField(undefined);
    setFileField(undefined);
  }

  const limparPesquisa = (e: any, formRenderProps:FormRenderProps) => {
    refreshGrid(e?.value?.tabela);
    formRenderProps.onChange('tipoClassificador', { value: null })
  }

  const [hasClassificador, sethasClassificador] = useState(false);

  const tipoClassificadorChange = (e: any) => {
    sethasClassificador(true);
    clearFieldsClassificador();
    refreshGrid(e?.value?.tabela);
    setShowRenderizaNovoRegisto(true)
  }

  const onAddUpload = (e: any) => {
    setFileField(e.affectedFiles);
    console.log(e);
  }

  const onProgressHandler = (event: UploadOnProgressEvent) => {
    setFileField(event.newState);
  }

  const onChangeUpload = (e: any) => {
    console.log(e);
  }

  const onRemoveUpload = (e: UploadOnRemoveEvent) => {
    const updatedFiles = fileField.filter((file: { uid: string }) => file.uid !== e.affectedFiles[0].uid);
    setFileField(updatedFiles);
    console.log(e);
  }

  const openAjuda = () => {
    window.open(URL_AJUDA_BACKOFFICE_CLASSIFICADORES, "_blank")
  }


  const TipoCalculo = ["MDS", "MDT"];

  const handleChange = (event: DropDownListChangeEvent) => {
    setTipoField(event.target.value);
  };

  return (
    <div className={styles.base}>

      <div className={styles.header}>

        <div>
          <div className={styles.nomeDoModulo}>
            Classificadores
          </div>
          <div className={styles.tituloDaPagina}>
            Classificadores
          </div>
        </div>

        <div>
        {showRenderizaNovoRegisto && (

          <Button onClick={registarNovoClick} className={styles.btnNovoRegisto}
          style={{ display: isMobile ? 'none' : '' }}
          id='novoRegisto' title='Novo Registo'>
            <span className="icon-siarl icon-registar btnIconRegistar"></span>{' '}
            &nbsp;{' '}
            Novo Registo
          </Button>
        
          )}
          <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
            <span className="icon-siarl icon-ajuda"></span>
          </button>

          <button className={styles.btnFechar} onClick={onCloseClick} title="Minimizar">
            <span className="icon-siarl icon-minimizar"></span>
          </button>

        </div>
      </div>
        <Form
          onSubmitClick={handleSubmit}
          render={(formRenderProps: FormRenderProps) => (
            <>
            <div className={`${styles.form} ${styles.removeScrollDuplicado}`}>
              <FormElement >
                
                <fieldset>

                  <legend>
                    <span className={styles.fieldsetTitulo}>Classificadores</span>
                  </legend>
                  <div className={styles.espacoVerticalFieldSet}>

                    <div className={styles.fields}>
                      <Field
                        name="tipoClassificador"
                        label="Classificador"
                        data={classificadores}
                        onChange={tipoClassificadorChange}
                        component={ComboBox}
                        textField="descricao"
                      />
                    </div>
                  </div>
                </fieldset>
              </FormElement>

              <div>
                  <Grid
                  className={styles.customGrid}
                    style={{cursor: 'pointer'}} 
                    onRowClick={gridRowClick}
                    data={data}
                  />       
              </div>
            </div>
              <div className={`${styles.footer} ${styles.justify_multiple_children}`}>
                <Button
                  onClick={(e: any) => {limparPesquisa('', formRenderProps)}}
                  className={styles.btnLimparPesquisa}
                  title='Limpar pesquisa'
                >
                  <span className="icon-siarl icon-limparcampos"></span>
                  <span className={styles.rotulo}>
                    {' '}&nbsp;{' '}
                    Limpar pesquisa
                  </span>
                </Button>
                <Button
                  onClick={formRenderProps.onSubmit}
                  className={styles.btnPesquisar}
                  title='Pesquisar'
                >
                  <span className="icon-siarl icon-pesquisar"></span>
                  <span className={styles.rotulo}>
                    {' '}&nbsp;{' '}
                    Pesquisar
                  </span>
                </Button>
                
              </div>
            </>
          )}
        />
        {showDialog ? 
          <Dialog className={`ModalDocumentos  ${styles.removeScrollDuplicado}`} onClose={() => {
            setShowDialog(false)
          }}>
            <div>
            <Input
              name="Id"
              label="Id"
              value={idField}
              onChange={onChangeIdField}
              disabled={edit}
              />
            </div>
            <div> 
              {showDesignacao && (
              <Input
                name="designacao"
                label="Designação"
                style={{ width: '100%' }}
                onChange={(e: any) => {
                  setDesignacaoField(e.value);
                }}
                value={designacaoField}
              />
              )}

            {showDescricao && (
              <Input
                name="descricao"
                label="Descrição"
                style={{ width: '100%' }}
                disabled={descricaoDisabled}
                onChange={(e: any) => {
                  setDescricaoField(e.value);
                }}
                value={descricaoField}
              />
            )}

          {showNome && (
            <Input
              name="nome"
              label="Nome"
              disabled={nomeDisabled}
              style={{ width: '100%' }}
              onChange={(e: any) => {
                setNomeField(e.value);
              }}
              value={nomeField}
            />
          )}
              {showUserId && (
            <Input
              name="userId"
              label="Utilizador Id"
              style={{ width: '100%' }}
              onChange={(e: any) => {
                setUserIdField(e.value);
              }}
              value={userIdField}
            />
          )}
             {showEmail && (
            <Input
              name="email"
              label="Email"
              style={{ width: '100%' }}
              onChange={(e: any) => {
                setEmailField(e.value);
              }}
              value={emailField}
            />)} 

          {showTipo && (
            <DropDownList
              name="tipo"
              label="Tipo"
              data={TipoCalculo}
              value={tipoField}
              onChange={handleChange}
              style={{width: '100%'}}
            />
          )}

        {showDataVoo && (
            <DateTimePicker
              name="data"
              label="Data"
              onChange={(e: any) => {
                setDataField(e.value);
              }}
              value={dataField}
            />
          )}

          {showUploadDocumentos ?
            <Upload
            autoUpload={true}
            showActionButtons={false}
            multiple={true}
            files={fileField}
            restrictions={{
              allowedExtensions: [".tif"],
            }}
            onAdd={onAddUpload}
            onRemove={onRemoveUpload}
            onProgress={onProgressHandler}
            />
          : '' }
        </div>
            <div className="footerModal justify_multiple_children">
              <Button
                type={"submit"}
                onClick={() => {
                  setShowDialog(false);
                }}
                className='btnLimparPesquisa'
                title="Fechar"
              >
                <span className="icon-siarl icon-fechar btnIconRegistar"></span> &nbsp;{' '}
                Cancelar
              </Button>

              <Button
                type={"submit"}
                onClick={remover}

                className='btnLimparPesquisa'
                title="Remover"
              >
                <span className="icon-siarl icon-descartar btnIconRegistar"></span> &nbsp;{' '}
                Remover
              </Button>
              
              <Button
                type={"submit"}
                onClick={gravar}
                className={styles.btnGravar}
                title="Gravar"
              >
                  <span className="icon-siarl icon-submeter btnIconRegistar"></span> &nbsp;{' '}
                  Gravar
              </Button>

            </div>
          </Dialog>
    : ''}
    </div>
    
  )
}
