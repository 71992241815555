import AuthLayout from '../components/auth/AuthLayout'
import RegisterForm from '../components/auth/RegisterForm'

export default function Login() {
  return (
    <AuthLayout>
      <RegisterForm />
    </AuthLayout>
  )
}
