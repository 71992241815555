import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form'
import { Label, Error } from '@progress/kendo-react-labels'
import { Upload, UploadOnProgressEvent } from '@progress/kendo-react-upload'

import { setIsOcorrenciaDocumentosValid } from '../../../validations/ocorrencia'
import { useEffect } from 'react'

export default function ValidatedDocumentosUploadSingle(
  fieldRenderProps: FieldRenderProps
) {
  const {
    value,
    id,
    optional,
    label,
    hint,
    validationMessage,
    touched,
    ...others
  } = fieldRenderProps


  const {
    allowedExtensions = ['jpg', 'jpeg', 'png', 'gif', 'pdf'],
  } = others.restrictions || {};
  const showValidationMessage: string | false | null = touched && validationMessage
  const showHint: boolean = !showValidationMessage && hint
  const hintId: string = showHint ? `${id}_hint` : ''
  const errorId: string = showValidationMessage ? `${id}_error` : ''
  const labelId: string = label ? `${id}_label` : ''

  const onChangeHandler = (event: any) => {
    const file = event.newState[0]; // Assuming single file upload
    if (file) {
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      if (!allowedExtensions.includes(fileExtension)) {
        // Arquivo com extensão não permitida
        const errorMessage = "O tipo de ficheiro não é permitido.";
        postMessage({ type: 'errorMsg', value: { message: errorMessage } }, '*');
        return;
      }
    }
    fieldRenderProps.onChange({ value: event.newState });
  };

  const onRemoveHandler = (event: any) => {
    fieldRenderProps.onChange({ value: event.newState })
  }
  const onProgressHandler = (event: UploadOnProgressEvent) => {
    fieldRenderProps.onChange({ value: event.newState })
  }

  useEffect(() => {
    setIsOcorrenciaDocumentosValid(!!fieldRenderProps.value)
  }, [fieldRenderProps.value])

  return (
    <FieldWrapper>
      <Label id={labelId} editorId={id} optional={optional}>
        {label}
      </Label>
      <Upload
        id={id}
        autoUpload={true}
        showActionButtons={false}
        multiple={false}
        files={value}
        onAdd={onChangeHandler}
        onRemove={onRemoveHandler}
        onProgress={onProgressHandler}
        ariaDescribedBy={`${hintId} ${errorId}`}
        ariaLabelledBy={labelId}
        {...others}
      />
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  )
}
