import { ComboBox, ComboBoxProps } from '@progress/kendo-react-dropdowns'
import { Error } from '@progress/kendo-react-labels'
import { useEffect } from 'react'

import { useDocumentosStore } from '../../../../stores/documentos'

import type { FieldRenderProps } from '@progress/kendo-react-form'

export default function ValidatedIdentificacaoComboBox(fieldRenderProps: FieldRenderProps) {
  const addIdentificacaoTabValidatedField = useDocumentosStore(
    (state) => state.addIdentificacaoTabValidatedField
  )
  
  useEffect(() => {
    addIdentificacaoTabValidatedField({ [fieldRenderProps.name]: !!fieldRenderProps.value })
  }, [fieldRenderProps.value])

  return (
    <div>
      <ComboBox {...fieldRenderProps as ComboBoxProps} />
      {fieldRenderProps.visited && fieldRenderProps.validationMessage && <Error>{fieldRenderProps.validationMessage}</Error>}
    </div>
  )
}
