import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form'
import { Label, Error } from '@progress/kendo-react-labels'
import { Upload, UploadListItemProps, UploadOnProgressEvent } from '@progress/kendo-react-upload'

import { Key, useEffect } from 'react'
import { blobToBase64 } from '../../../../services/utils'

export default function UploadImgPreview(
  fieldRenderProps: FieldRenderProps
) {
  const {
    value,
    id,
    optional,
    label,
    hint,
    validationMessage,
    touched,
    ...others
  } = fieldRenderProps

  const showValidationMessage: string | false | null =
    touched && validationMessage
  const showHint: boolean = !showValidationMessage && hint
  const hintId: string = showHint ? `${id}_hint` : ''
  const errorId: string = showValidationMessage ? `${id}_error` : ''
  const labelId: string = label ? `${id}_label` : ''

  const onChangeHandler = (event: any) => {
    fieldRenderProps.onChange({ value: event.newState })
  }
  const onRemoveHandler = (event: any) => {
    fieldRenderProps.onChange({ value: event.newState })
  }
  const onProgressHandler = (event: UploadOnProgressEvent) => {
    fieldRenderProps.onChange({ value: event.newState })
  }


 const customPreview =(props:any):any =>{
const { files } = props;
// files = files.map(async (f:any)=>({...f,
//     preview:await blobToBase64(f.getRawFile())
// }))
// console.log(files)
  return (<>
  {files && files.length>0 && 
   <ul>
        {files.map((file: any) => {
          return (
            <li key={file.name}>
                <img src={file?.preview} style={{width: 70, height: 70}}/>
            </li>
          );
        })}
      </ul>
 }
  </>);
 }
  return (
    <FieldWrapper>
      <Label id={labelId} editorId={id} optional={optional}>
        {label}
      </Label>
      <Upload
        id={id}
        autoUpload={true}
        showActionButtons={false}
        multiple={false}
        files={value}
        onAdd={onChangeHandler}
        onRemove={onRemoveHandler}
        onProgress={onProgressHandler}
        ariaDescribedBy={`${hintId} ${errorId}`}
        ariaLabelledBy={labelId}
        // listItemUI={customPreview}
        {...others}
      />
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  )
}


