import { Button, DropDownButton } from '@progress/kendo-react-buttons'
import { PagerTargetEvent } from '@progress/kendo-react-data-tools'
import { format } from 'date-fns'
import { CSVLink } from 'react-csv'
import { b64toBlob, clearPatrimonio, exportPDFWithMethod, get, GetUserNameTecnico, handleDownload, handleGenericError, hasPermission, post, setPatrimonioSelected } from '../../services/utils'
import { process } from '@progress/kendo-data-query';


import styles from '../../styles/ocorrencia/ConsultarOcorrenciaForm.module.scss'
import commonStyles from '../../styles/common/Common.module.scss'

import {
    Dispatch,
    MouseEvent as ReactMouseEvent,
    SetStateAction,
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react'

import {
    Grid,
    GridCellProps,
    GridColumn,
    GridPageChangeEvent,
} from '@progress/kendo-react-grid'

import {
    TabStrip,
    TabStripSelectEventArguments,
    TabStripTab,
} from '@progress/kendo-react-layout'
import SessionStorageService from '../../services/SessionStorageService'
import { useDhPatrimonioStore } from '../../stores/dhPatrimonio'
import { ResultadoPatrimonioCommandGridCell } from './ResultadoPatrimonioCommandGridCell'
import { CURRENT_TIMEZONE, GET_DH_PATRIMONIO_SHAPEFILE, GET_DOCUMENTO, GET_DOCUMENTOS_BY_PATRIMONIOS, GET_PATRIMONIO, PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_EDICAO, PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_LEITURA, PERMISSION_DOMINIO_HIDRICO_PERFIL_TOTAL, PESQUISAR_PATRIMONIO,URL_AJUDA_DOMINIO_HIDRICO_PATRIMONIO, clearLayers } from '../../utils/constants'
import clsx from 'clsx'
import { useCommonStore } from '../../stores/common'
import MaximizeButton from '../common/MaximizeButton'
import useClearMap from '../../hooks/useClearMap'
import VisualizadorColunas from '../Generico/VisualizadorColunas '
import useVisibleColumns, { Key } from '../../hooks/useVIsibleColumns'
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais'
import ReactGA from 'react-ga4';
import { ExcelExport } from '@progress/kendo-react-excel-export'
import React from 'react'
import { GridPDFExport } from '@progress/kendo-react-pdf'
import { ResultadoDocumentoCommandGridCell } from '../documentos/ResultadoDocumentoCommandGridCell'
import { Dialog } from '@progress/kendo-react-dialogs'
import ImagePreviewV2 from '../viewer/ImagePreviewV2'
import moment from 'moment'
import "moment-timezone"

type Props = {
    onCloseClick: (
        e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
    ) => void | undefined
    setShowResults: Dispatch<SetStateAction<boolean>>
    setShowRegistarPatrimonioForm?: Dispatch<SetStateAction<boolean>>
}

type PageState = {
    skip: number
    take: number
}

const initialDataState: PageState = { skip: 0, take: 20 }
const keyPainelPrincipal:Key = 'patrimonioColunasVisiveis';
const keyPainelDocumentos:Key = 'patrimonioDocumentosColunasVisiveis';
export default function ResultadoPatrimonioForm({
    onCloseClick,
    setShowResults,
    setShowRegistarPatrimonioForm,
}: Props) {
    const [isMobile, setIsMobile] = useState(false);
    const [page, setPage] = useState<PageState>(initialDataState)
    const [pageSizeValue, setPageSizeValue] = useState<number | string | undefined>()
    const [patrimonios, setPatrimonios] = useState([])
    const selectPatrimonioResult = useDhPatrimonioStore((state) => state.selectDhPatrimonioResult)
    const selectPatrimonioResultId = useDhPatrimonioStore((state) => state.selectDhPatrimonioResultId)
    const [clearMap] = useClearMap();
    const {getVisibleColumns, removeColumnsKeys} = useVisibleColumns();
    const [documentosPatrimonio, setDocumentosPatrimonio] = useState([])
    const [idDoDocumento, setIdDoDocumento] = useState("");
    const [urlDoDocumento, setUrlDoDocumento] = useState("");
    const [openForm, setOpenForm] = useState<boolean>(false)
    const [patrimoniosFinal, setPatrimoniosFinal] = useState<any>([]);
    const [pageable, setPageable] = useState<any>({
      buttonCount: 5,
      info: true,
      type: 'numeric',
      pageSizes: true,
      previousNext: true,
    })

    const isCurrentViewMaximized = useCommonStore(state => state.isCurrentViewMaximized)

    const getPatrimonio = async (data: any) => await get<any, any>(`${GET_PATRIMONIO}/` + data)
    const getDocumento = async (data: any) =>
    await get<any, any>(`${GET_DOCUMENTO}/` + data)
    const getDocumentosByPatrimonios =async (data: any) => await post<any, any>(`${GET_DOCUMENTOS_BY_PATRIMONIOS}`, data)
    const getShapeFile = async (data: any) =>
    await post<any, any>(`${GET_DH_PATRIMONIO_SHAPEFILE}`, data)

    const consultarPatrimonio = async (data: any) => await post<any, any>(`${PESQUISAR_PATRIMONIO}`, data)

     const canSeeDominioHidricoEdit = hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_EDICAO) || hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_TOTAL);

     const completoLeitura =  hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_LEITURA)


     const [dataState, setDataState] = React.useState({
      skip: 0,
      take: 20,
      sort: [],
      group: [],
    });

    const [dataStateDocumentos, setDataStateDocumentos] = React.useState({
      skip: 0,
      take: 20,
      sort: [],
      group: [],
    });

    const dataStateChangeDocumentos = (event: any) => {
      setDataResultDocumentos(process(documentosPatrimonio, event.dataState));
      setDataStateDocumentos(event.dataState);
    };
  
    const [dataResultDocumentos, setDataResultDocumentos] = React.useState(
      process(documentosPatrimonio, dataStateDocumentos)
    );


   const [dataResult, setDataResult] = React.useState(
      process(patrimonios, dataState)
    );
    
   const dataStateChange = (event: any) => {
      setDataResult(process(patrimonios, event.dataState));
      setDataState(event.dataState);
    };
    
    const handleMessageEvent = useCallback((event: MessageEvent) => {
        if (event.data.type == "resultadoPatrimoniosPesquisaChange") {
            SessionStorageService.setItem("resultadoPatrimoniosPesquisa", event.data.value);
        }
    }, [])

    useEffect(() => {
        window.addEventListener(
            'message',
            handleMessageEvent,
            false
        )

        return () => {
            window.removeEventListener(
                'message',
                handleMessageEvent,
                false
            )
        }
    }, [handleMessageEvent])


    useEffect(() => {
      ReactGA.send({ hitType: "pageview", page: "/ResultadoPatrimonio" });
    }, []);

    
    const initialized = useRef(false)
    useEffect(() => {
        if (!initialized.current) {
          initialized.current = true;
              if (SessionStorageService.getItem("lastSearchPatrimonio") && !SessionStorageService.getItem("firstCallPatrimonio")) {
        const objToSearch = SessionStorageService.getItem("lastSearchPatrimonio") ?? '';
        consultarPatrimonio(objToSearch).then((data) => {
          SessionStorageService.setItem("resultadoPatrimoniosPesquisa", JSON.stringify(data));
          setResultsPatrimonios();
        })
      } 
      else {
        setResultsPatrimonios();
    }
  }
    }, [])

    const handleDocumentPreviewClickById = (documentosLocal: Record<string, any>) => async () => {

      try {
  
        postMessage({ type: "showLoader" }, "*");
        const documentos = await getDocumento(documentosLocal.id);
  
        setIdDoDocumento(documentosLocal.id);
        setUrlDoDocumento(documentos.url);
        setOpenForm(true);
  
      } catch (error) {
  
        handleGenericError(error, "Não foi possivel abrir o documento!")
        setOpenForm(false);
  
      } finally {
  
        postMessage({ type: "hideLoader" }, "*");
  
      }
    }
    const documentosToPreview = documentosPatrimonio;
    const nextPhoto = async (e: any) => {

      try{
  
        postMessage({ type: "showLoader" }, "*");
        const nextIds = documentosToPreview
          .filter((doc: any) => doc.id < idDoDocumento && doc.url !== '')
          .map((doc: any) => doc.id);
    
        if (nextIds.length > 0) {
          const nextId = Math.max(...nextIds);
          console.log(`O próximo ID é ${nextId}`);
    
          const documento = await getDocumento(nextId);
    
          setIdDoDocumento(nextId.toString());
          setUrlDoDocumento(documento.url);
  
          const iframe: any = document.getElementById('iframeGeoPortal')
          const patrimonio_id = e.patrimonio_id;
          iframe.contentWindow?.postMessage(
            {
              type: 'SiarlIntIntervencaoHighlight',
              value: [patrimonio_id],
            },
            '*'
          )
    
        } else {
          console.log("Não há um próximo ID na lista.");
        }
  
      }catch(error){
  
        postMessage({ type: "errorMsg", value: "Não foi possivel abrir o documento!" }, "*");
  
      }finally{
  
        postMessage({ type: "hideLoader" }, "*");
  
        e.preventDefault();
      }
     
    }
  
    const previousPhoto = async (e: any) => {
  
      try{
  
        postMessage({ type: "showLoader" }, "*");
        const previousIds = documentosToPreview
          .filter((doc: any) => doc.id > idDoDocumento && doc.url !== '')
          .map((doc: any) => doc.id);
    
        if (previousIds.length > 0) {
          const previousId = Math.min(...previousIds);
          console.log(`O anterior ID é ${previousId}`);
    
          const documento = await getDocumento(previousId);
    
          setIdDoDocumento(previousId.toString());
          setUrlDoDocumento(documento.url);
  
          const iframe: any = document.getElementById('iframeGeoPortal')
          const patrimonio_id = e.patrimonio_id;
          iframe.contentWindow?.postMessage(
            {
              type: 'SiarlIntIntervencaoHighlight',
              value: [patrimonio_id],
            },
            '*'
          )
        } else {
          console.log("Não há um anterior ID na lista.");
        }
  
      } catch(error){
  
        handleGenericError(error, "Não foi possivel abrir o documento!")
  
      }finally{
  
        postMessage({ type: "hideLoader" }, "*");
  
        e.preventDefault();
      }
         
    }

    const documentsCustomCell = (props: GridCellProps) => (
      <ResultadoDocumentoCommandGridCell
      onLocationMouseEnter={function (documentos: Record<string, unknown>): void {
        console.log("n/a");
      } } onLocationMouseLeave={function (documentos: Record<string, unknown>): void {
        console.log("n/a");
      } } onLocationClick={mapCenter} onDocumentPreviewClick={handleDocumentPreviewClickById} {...props}
      onInfoClick={handleInfoClickById}    />
    )

      const setResultsPatrimonios = () => {
        const results = JSON.parse(SessionStorageService.getItem('resultadoPatrimoniosPesquisa') ?? '')

        setPatrimonios(results)
        const iframe: any = document.getElementById('iframeGeoPortal')
        const ids: any[] = []
        results.forEach((element: any) => {
            ids.push(element.id)
          })
          iframe.contentWindow?.postMessage(
            { type: 'SiarlPatrimonioSearch', value: ids },
            '*'
          )

          getDocumentosByPatrimonios(ids).then(result => {
            setDocumentosPatrimonio(result);
          })

          setPatrimoniosFinal(results.slice(page.skip, page.take + page.skip))

          getVisibleColumns(keyPainelPrincipal, setColums)
          getVisibleColumns(keyPainelDocumentos, setColumsDocuments)

        postMessage({ type: 'hideLoader' }, '*');

    }

    const openPdfNewTab = () => {
      return new Promise<void>((resolve, reject) => {
          
          postMessage({ type: "showLoader" }, "*");
  
          setTimeout(() => {
             const columnsToShow = colums.columns.filter(x => x.show);
              const dataToExport = patrimonios.map((item: any) => {
                  const filteredItem: any = {};
                  columnsToShow.forEach((column: any) => {
                      filteredItem[column.field] = item[column.field];
                  });
                  return filteredItem;
              });
              
              exportPDFWithMethod(columnsToShow, dataToExport, 8)
                  .then((pdfData: any) => {
                      postMessage({ type: "hideLoader" }, "*");
  
                          const base64Data = pdfData.split(";base64,")[1];
                          console.log(base64Data);
                          const blob = b64toBlob(base64Data, 'application/pdf');
                          const blobUrl = URL.createObjectURL(blob);
  
                        
                          window.open(blobUrl, '_blank');
                          resolve();
                    
                  })
                  .catch(error => {
                      console.error('Error exporting PDF:', error);
                      reject(error);
                  });
          }, 1000);
      });
  };
  

    useEffect(() => {
      const iframe: any = document.getElementById('iframeGeoPortal')
      const ids: number[] = []
      patrimonios.forEach((element: any) => {
        ids.push(element.id)
      })
      iframe.contentWindow?.postMessage(
        { type: 'SiarlPatrimonioSearch', value: ids },
        '*'
      )
    }, [])

    const clearSearchResults = () => {
        SessionStorageService.removeItem('resultadoPatrimoniosPesquisa')
        postMessage({ type: 'resultadoPatrimoniosPesquisaChange' }, '*')
        SessionStorageService.removeItem("lastSearchPatrimonio");
        selectPatrimonioResult(undefined)
        removeColumnsKeys(keyPainelDocumentos)
        removeColumnsKeys(keyPainelPrincipal)
        clearPatrimonio();
        clearMap(clearLayers["CLEAR_LAYERS_PATRIMONIO"]);

    }

    const handleInfoClickById = (patrimonioLocal: Record<string, any>) => async () => {

        const patrimonio = await getPatrimonio(patrimonioLocal.id);

        const tecnico = await GetUserNameTecnico(patrimonioLocal.tecnico_id);

        if (!patrimonio) {
          postMessage({type: "infoMsg", value: { message: "Registo desativado" }}, "*");
          return;
        }

        const seletedPatrimonioReulst = {
            ...patrimonio,
            tecnicoRegisto: tecnico,
            id_tipo_patrimonio: patrimonio.dhTipoPatrimonio ?? undefined,
            id_localizacao_patrimonio: patrimonio.dhLocalizacaoPatrimonio ?? undefined,
            id_fase_pr: patrimonio.dhFaseProcedimentoDelimitacao ?? undefined,
            id_tipo_va: patrimonio.dhValidacaoProva ?? undefined,
            id_tipo_pa: patrimonio.dhTipoParcela ?? undefined,
            id_tipo_se: patrimonio.dhTipoSentenca ?? undefined,
            id_tipo_ac: patrimonio.dhTipoDecisaoFinal ?? undefined,
            id_tipo_es: patrimonio.dhEstadoPatrimonio ?? undefined,
            id_tipo_pe: patrimonio.dhTipoPendencia ?? undefined,
            id_tipo_no: patrimonio.dhTipoNotificacao ?? undefined,
            id_situ_no: patrimonio.dhTipoSituacaoNotificacao ?? undefined,
            id_cond_pr: patrimonio.dhCondicionantesPatrimonio ?? undefined,
            id_tipo_aj: patrimonio.dhTipoAcaoJudicial ?? undefined,
            id_tipo_de: patrimonio.dhTipoDesafetacao ?? undefined,
            id_tipo_aq: patrimonio.dhTipoAquisicao ?? undefined,
            id_tipo_in: patrimonio.dhTipoIniciativa ?? undefined,
            data_registo: patrimonio.data_registo ? moment.utc(patrimonio.data_registo).tz(CURRENT_TIMEZONE) : undefined,
            filedoc_cod: patrimonio.filedoc_cod ?? undefined,
            diploma: patrimonio.diploma ?? undefined,
            designacao: patrimonio.designacao ?? undefined,
            lugar: patrimonio.lugar ?? undefined,
        }

        sessionStorage.setItem('novoPatrimonio', 'false')
        selectPatrimonioResultId(patrimonio.id as number)
        selectPatrimonioResult(seletedPatrimonioReulst)
        setPatrimonioSelected(seletedPatrimonioReulst);
        setShowResults(false)
        const iframe: any = document.getElementById('iframeGeoPortal')

        iframe.contentWindow?.postMessage(
          { type: 'SiarlPatrimonioSearch', value: [patrimonioLocal.id] },
          '*'
          )
        iframe.contentWindow?.postMessage(
        { type: 'SiarlPatrimonioCenter', value: [patrimonioLocal.id] },
        '*'
        )
        if (setShowRegistarPatrimonioForm) setShowRegistarPatrimonioForm(true)
    }

      const conditionalProps = completoLeitura || canSeeDominioHidricoEdit
    ? { 
        onInfoClick: handleInfoClickById
    } 
    : {};

    const handleNewRegistoClick = () => {
      setPatrimonioSelected(undefined);
      selectPatrimonioResult(undefined);
      setShowResults(false)
      sessionStorage.setItem('novoPatrimonio', 'true')

    if (setShowRegistarPatrimonioForm)
      setShowRegistarPatrimonioForm(true)
  }

    const handlePageChange = (event: GridPageChangeEvent) => {
        const targetEvent = event.targetEvent as PagerTargetEvent
        const take = event.page.take

        if (targetEvent.value) {
            setPageSizeValue(targetEvent.value)
        }

        setPage({
            ...event.page,
            take,
        })
    }

    const CommandCell = (props: GridCellProps) => (
        <ResultadoPatrimonioCommandGridCell
            {...props}
            onInfoClick={handleInfoClickById}
            onMapCenter={mapCenter}
            {...conditionalProps}
        />
    )

    const mapCenter = (item: Record<string, any>) => async () => {
       const id = item.patrimonio_id ?? item.id
        const iframe: any = document.getElementById('iframeGeoPortal');
        iframe.contentWindow?.postMessage(
          { type: 'SiarlPatrimonioCenter', value: [id] },
          '*'
        )
       }

    const [selected, setSelected] = useState<number>(0)

    const handleSelect = (e: TabStripSelectEventArguments) => {
        setSelected(e.selected)
    }

  const optionsExportar = [
    {
      key: 'ExportarListaResultadosExcel',
      text: 'Excel',
    },
    {
      key: 'ExportarListaResultadosShapeFile',
      text: 'Shapefile',
    },
  ]
  
  let _pdfExport: any;
  const exportExcel = () => {
    _export.save();
  };

  const onItemClickExportar = async (event: any) => {
    if (event.item.key == 'ExportarListaResultadosExcel') {
      exportExcel();
    }
    else if (event.item.key == "ExportarListaResultadosShapeFile") {
      const results = JSON.parse(
        SessionStorageService.getItem('resultadoPatrimoniosPesquisa') ?? ''
      )
  
      if (results) {
        const ids: number[] = []
        results.forEach((element: any) => {
          ids.push(element.id)
        })

        const file: any = await getShapeFile(ids);
        const filePath = 'shapeFileDhPatrimonios.zip';

        handleDownload(file, filePath);
    }
  } 
}


  let _export: any;
  const exportPDF = () => {
    _pdfExport.save();
  };

  const onItemClickImprimir = async (event: any) => {
    //exportPDF();
    openPdfNewTab();
  }

    const itemRender = (props: { item: any; itemIndex: number }) => {
        const isTitleItem = props.item.key === 'titulo';
        if (isTitleItem) {
            // Item de título personalizado
            return (
                <div className={styles.DropDownButtonTitulo}>
                    <span>{props.item.text}</span>
                </div>
            );
        } else {
            // Demais itens
            return (
                <div className={styles.DropDownButtonItem}>
                    <span>{props.item.text}</span>
                </div>
            );
        }
    };

    const [columsDocuments, setColumsDocuments] = useState<{ columns: { field: string, title: string, width: number | string, show: boolean }[] }>({
        columns: [
          { field: "id", width: 70, title: "ID", show: true },
          { field: "filedoc_cod", width: 190, title: "ID Documento Filedoc", show: true },
          { field: "data_publicacao", width: 170, title: "Data publicação", show: true },
          { field: "tipoRecurso.designacao", width: 170, title: "Tipo de recurso", show: true },
          { field: "titulo", width: 180, title: "Título", show: true },
          { field: "autores", width: 180, title: "Autor", show: true },
          { field: "entidade.nome", width: 180, title: "Entidade", show: true },
          { field: "id_estado", width: 180, title: "Estado", show: true },
        ]
      })
    
      const [colums, setColums]=useState<{columns:{field:string, title:string, width:number|string, show:boolean}[]}>({
        columns:[
            { field: "id", title: "Id", show: true, width: 70 },
            { field: "lugar", title: "Lugar toponímico", show: true, width: 190 },
            { field: "dico", title: "Concelho", show: true, width: 190 },
            { field: "di", title: "Distrito", show: true, width: 190 },
            { field: "id_rh", title: "ARH", show: true, width: 190 },
            { field: "id_magua", title: "Massa de água", show: true, width: 190 },
            { field: "diploma", title: "Diploma legal", show: true, width: 190 },
        ]
      })
      useEffect(() => {
        SessionStorageService.setItem(keyPainelDocumentos, JSON.stringify(columsDocuments));
      }, [columsDocuments]);
      
      useEffect(() => {
        SessionStorageService.setItem(keyPainelPrincipal, JSON.stringify(colums));
      }, [colums]);

      useLayoutEffect(() => {

        const handleResize = () => {
    
          if (window.innerWidth <= telaMobile) {
            
            setIsMobile(true);
            ocultaFloatingMenu_e_Mapa();
    
          }else{
    
            setIsMobile(false);
            mostraFloatingMenu_e_Mapa();
    
          }
    
        };
      
        window.addEventListener('resize', handleResize);
      
        handleResize();
      
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    
      }, []);  
    
    
      const onItemClickMais = (event: any) => {
    
        elementoClickMais(event);

        onItemClickExportar(event);

        onItemClickImprimir(event);
    
      };
    
      const optionsMais = [
        {
          key: 'titulo',
          text: 'Imprimir',
        },
        {
          key: 'imprimirFicha',
          text: 'Resultados',
          className: 'icon-siarl icon-imprimir btnIconRegistar'
        },
        {
          key: 'titulo',
          text: 'Exportar',
        },
        ...optionsExportar.map(option => ({
          ...option,
          className: 'icon-siarl icon-exportar btnIconRegistar',
        })),
      ]

    const openAjuda = () => {
      window.open(URL_AJUDA_DOMINIO_HIDRICO_PATRIMONIO, "_blank");
    }

return (
   <div className={clsx(styles.base, isCurrentViewMaximized && commonStyles.maximizedBaseForm)}>

            <div className={styles.header}>

                <div>
                    <div className={styles.nomeDoModulo}>
                        Patrimónios
                    </div>
                    <div className={styles.tituloDaPagina}>
                        Resultados de pesquisa
                    </div>
                </div>

                <div>

                  { canSeeDominioHidricoEdit &&
                        <Button
                        onClick={handleNewRegistoClick}
                        className={styles.btnNovoRegisto}
                        style={{ display: isMobile ? 'none' : '' }}
                        id='novoRegisto'
                        title='Novo património'
                        >
                        <span className="icon-siarl icon-registar btnIconRegistar" /> &nbsp;{' '}
                        Novo património
                        </Button>
                    }

                    <CSVLink
                        title="Imprimir resultados"
                        id="print-results"
                        data={patrimonios}
                        filename={`siarl-patrimonios-${format(
                            new Date(),
                            'ddMMyyyHHmm'
                        )}`}
                        hidden
                    >
                    </CSVLink>

                    {!isMobile &&
                      <VisualizadorColunas
                        columns={selected === 0 ? colums : columsDocuments}
                        setColumns={selected === 0 ? setColums : setColumsDocuments}
                        styles={styles.btnEditar2}
                      />
                    }

                    <button className={styles.btnEditar2} onClick={onItemClickImprimir}
                    style={{ display: isMobile ? 'none' : '' }} id='imprimirFicha' title='Imprimir'>
                        <span className="icon-siarl icon-imprimir"></span>
                    </button>

                    {!isMobile &&
                    <DropDownButton
                        items={optionsExportar}
                        className={styles.btnImprimir}
                        style={{marginTop: '-4px'}}
                        iconClass="icon-siarl icon-exportar"
                        onItemClick={onItemClickExportar}
                        fillMode={'flat'}
                        itemRender={itemRender}
                        title='Exportar'
                    ></DropDownButton>
                    }

                    {isMobile &&
                      <DropDownButton
                        items={optionsMais}
                        className={styles.btnMais}
                        iconClass="icon-siarl icon-mais"
                        onItemClick={onItemClickMais}
                        fillMode={'flat'}
                        itemRender={itemRenderMais}
                        title="Mais Opções"
                      />
                    }

                        {isMobile &&
                        <DropDownButton
                            items={optionsMais}
                            className={styles.btnMais}
                            iconClass="icon-siarl icon-mais"
                            onItemClick={onItemClickMais}
                            fillMode={'flat'}
                            itemRender={itemRenderMais}
                            title="Mais Opções"
                        />
                        }

                    <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
                        <span className="icon-siarl icon-ajuda"></span>
                    </button>

                    <button className={styles.btnFechar} onClick={onCloseClick} title="Minimizar">
                        <span className="icon-siarl icon-minimizar"></span>
                    </button>

                    {!isMobile &&
                    <MaximizeButton className={styles.btnMaximizar} />
}
                </div>

            </div>

    <TabStrip selected={selected} onSelect={handleSelect} scrollable={isMobile}>
      <TabStripTab title="Patrimónios">
      <ExcelExport
        fileName='Resultado_Patrimonio'
            data={patrimonios}
            ref={(exporter) => {
              _export = exporter;
            }}
          >
        <Grid
          className={styles.form}
          data={(dataResult.data.length > 0 ? dataResult : patrimoniosFinal).slice(page.skip, page.take + page.skip)}
          pageable={pageable}
          skip={page.skip}
          take={page.take}
          total={dataResult.data.length > 0 ? dataResult.total : patrimoniosFinal.length}
          onPageChange={handlePageChange}
        >
          <GridColumn cell={CommandCell} width={56} />
          {colums?.columns?.map((column, i) => {
                                return (
                                column.show && (
                                    <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
                                )
                                );
                            })}
        </Grid>
        </ExcelExport>

        <GridPDFExport  
          fileName='Resultado_Patrimonio'
          ref={(element) => {
            _pdfExport = element
          }}
          paperSize={['400mm', '297mm']}         

          margin="1cm"
        >
          <Grid
            className={styles.form}
            sortable={true}
            onDataStateChange={dataStateChange}
            data={patrimonios}

          total={patrimonios.length}
          {...dataState}
            pageable={{
              buttonCount: 5,
              info: true,
              type: 'numeric',
              pageSizes: true,
              previousNext: true,
            }}
            //groupable={true}
          >
              <GridColumn cell={CommandCell} width={30} />
              {colums?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
          </Grid>
        </GridPDFExport>
        
      </TabStripTab>
      <TabStripTab title="Documentos">
        <ExcelExport
          fileName='Resultado_Documentos_Patrimonio'
          data={documentosPatrimonio}
          ref={(exporter) => {
            _export = exporter
          }}
        >
          
          <Grid
            className={styles.form}
            sortable={true}
            onDataStateChange={dataStateChangeDocumentos}
            data={dataResultDocumentos.data.length > 0 ? dataResultDocumentos : documentosPatrimonio.map((documentos: any) => ({
              ...documentos,
              data_publicacao: documentos?.data_publicacao ? format(new Date(documentos.data_publicacao), 'dd/MM/yyyy HH:mm') : undefined,
      })).slice(page.skip, page.take + page.skip)}
            {...dataState}
            pageable={{
              buttonCount: 5,
              info: true,
              type: 'numeric',
              pageSizes: true,
              previousNext: true,
            }}
            //groupable={true}
            total={documentosPatrimonio.length}

          >
            <GridColumn cell={documentsCustomCell} width={86} />
            {columsDocuments?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
          </Grid>
        </ExcelExport>

        <GridPDFExport
          fileName='Resultado_Documentos_Patrimonio'
          ref={(element) => {
            _pdfExport = element
          }}
          margin="1cm"
        >
          <Grid
            className={styles.form}
            sortable={true}
            onDataStateChange={dataStateChangeDocumentos}
            data={
              dataResultDocumentos.data.length > 0
                ? dataResultDocumentos
                : documentosPatrimonio.map((documentos: any) => ({
                  ...documentos,
                  data_publicacao: documentos?.data_publicacao ? format(new Date(documentos.data_publicacao), 'dd/MM/yyyy HH:mm') : undefined,
          }))
            }
            {...dataState}
            pageable={{
              buttonCount: 5,
              info: true,
              type: 'numeric',
              pageSizes: true,
              previousNext: true,
            }}
            //groupable={true}
          >
           {columsDocuments?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
          </Grid>
        </GridPDFExport>
      </TabStripTab>
    </TabStrip>
    {openForm && (
        <Dialog
        className="ModalDocumentos"
          title={`Documento: ${idDoDocumento}`}
          onClose={() => setOpenForm(false)}
          style={{ zIndex: 999 }}
        >
            <ImagePreviewV2
              documentUrl={urlDoDocumento}
              next={(e: any) => nextPhoto(e)}
              previous={(e: any) => previousPhoto(e)}
            />

        </Dialog>
      )}
    <div
      className={clsx(styles.footer, styles.justify_one_child, isCurrentViewMaximized && commonStyles.maximizedFooter)}
    >
      <Button className={styles.btnPesquisar} onClick={clearSearchResults} title='Nova Pesquisa'>
        <span className="icon-siarl icon-pesquisar"></span>
        <span className={styles.rotulo}>
          {' '}&nbsp;{' '}
          Nova pesquisa
        </span>
      </Button>
    </div>
  </div>
)

}