import { MultiSelect } from '@progress/kendo-react-dropdowns'
import styles from '../../styles/estrategiaAdaptacao/EstrategiaAdaptacao.module.scss'
import { Field, FormRenderProps } from '@progress/kendo-react-form'
import { Checkbox, TextArea, RadioButton, RadioGroup, Input } from '@progress/kendo-react-inputs'
import { useEffect, useState } from 'react'
import { acaoPlaneamento4, get, getEaRegistoAnalise, palavraschave } from '../../services/utils'
import { GET_ALL_TIPOLOGIA_OBRA_EXEC } from '../../utils/constants'
import { DateTimePicker } from '@progress/kendo-react-dateinputs'
import ReactGA from 'react-ga4';

type IProps = {
  formRenderProps?: FormRenderProps
  viewMode?: boolean
  onCheckboxChangeDefesa?: any
  onCheckboxChangeRetirada?: any
  onCheckboxChangeAdaptacao?: any
  onCheckboxChangeDA?: any


}
export function EstrategiaAdaptacaoParametros({ formRenderProps,viewMode, onCheckboxChangeDefesa,  onCheckboxChangeRetirada,  onCheckboxChangeAdaptacao, onCheckboxChangeDA 
 }: IProps) {

  const [tipologiasObraExecucao, setTipologiasObraExecucao] = useState<any>()

  const getTipologiasObraExecucao = async() => await get<any>(GET_ALL_TIPOLOGIA_OBRA_EXEC);

   useEffect(() => {
     postMessage({type: "showLoader"}, "*");
     getTipologiasObraExecucao().then(result => {
       setTipologiasObraExecucao(result);
       postMessage({type: "hideLoader"}, "*");
     });
  }, [])

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/EstrategiaAdaptacaoParametros" });
  }, []);

  return (
    <div className={`${styles.form} ${styles.removeScrollDuplicado}`}>

       <span style={{ marginLeft: '08px' }}>Os campos com simbolo * (asterisco) são de preenchimento obrigatório</span>

      <fieldset>
        <legend>
          <span className={styles.fieldsetTitulo}>Componentes de análise *</span>
        </legend>

            <div className={styles.espacoVerticalFieldSet}>
              <div style={{ marginLeft: '08px' }}> 
                  <div>
                    <Field
                      name="retirada"
                      label="Retirada"
                      component={Checkbox}
                      onChange={onCheckboxChangeRetirada}
                      disabled={viewMode}

                    />
                  </div>
                  <div>
                    <Field
                      name="defesa"
                      label="Defesa"
                      component={Checkbox}
                      onChange={onCheckboxChangeDefesa}
                      disabled={viewMode}
                    />
                  </div>
                  <div>
                    <Field
                      name="adaptacao"
                      label="Adaptação"
                      component={Checkbox}
                      onChange={onCheckboxChangeAdaptacao}
                      disabled={viewMode}
                    />
                  </div>
                  <div>
                    <Field
                      name="defesa_adaptacao"
                      label="Defesa + adaptação"
                      component={Checkbox}
                      onChange={onCheckboxChangeDA}
                      disabled={viewMode}
                    />
                  </div>
                </div>
             </div>
      </fieldset>

      <fieldset>
        <legend>
          <span className={styles.fieldsetTitulo}>Seleção de faixas de salvaguarda</span>
        </legend>

            <div className={styles.espacoVerticalFieldSet}>
             <div style={{ marginLeft: '08px' }}> 
                  <div>
                    <Field
                      name="salvaguarda_faixa_i"
                      label="Faixa nível 1"
                      component={Checkbox}
                      disabled={viewMode}
                    />
                  </div>
                  <div>
                    <Field
                      name="salvaguarda_faixa_ii"
                      label="Faixa nível 2"
                      component={Checkbox}
                      disabled={viewMode}
                    />
                  </div>
                </div> 
             </div>
       </fieldset>

       <fieldset>
        <legend>
          <span className={styles.fieldsetTitulo}>Seleção de camada VUS</span>
        </legend>

            <div className={styles.espacoVerticalFieldSet}>
               <div style={{ marginLeft: '08px' }}> 
                  <div>
                    <Field
                      name="markrisk_edificios"
                      label="MarRisk Edificios"
                      component={ Checkbox}
                      disabled={viewMode}

                    />
                  </div>
                  <div>
                    <Field
                      name="universidade_nova"
                      label="Universidade Nova"
                      component={Checkbox}
                      disabled={viewMode}

                    />
                  </div>
                  <div>
                    <Field
                      name="carta_ocupacao_solo"
                      label="Carta de ocupação do solo"
                      component={Checkbox}
                      disabled={viewMode}
                    />
                  </div>
                   <div>
                    <Field
                      name="servicos_ecosistema"
                      label="Serviços de ecossistema"
                      component={Checkbox}
                      disabled={viewMode}
                    />
                  </div>
                </div> 
             </div> 

       </fieldset>

           <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Tipologias de obra de execução</span>
          </legend>

           <div className={styles.espacoVerticalFieldSet}>
            <div>
            <Field
                name="lstTipologiasObra"
                label="Tipologias da obra"
                component={MultiSelect}
                textField={'descricao'}
                data={acaoPlaneamento4}
                disabled={viewMode}             
                 />
            </div> 
          </div> 
        </fieldset>

          <fieldset>
          <legend> &nbsp;
            <span className={styles.fieldsetTitulo}>Custo de adaptação de edifício [€/m<sup>2</sup>]</span>{' '}
          </legend>
          <div className={styles.espacoVerticalFieldSet}>
            <div className={styles.fields}>
              <div>
                <Field
                  name="custo_adaptacao_edificio"
                  label="Custo de adaptação de edifício"
                  component={Input}
                  disabled={viewMode}
                  type={'number'}
                  onKeyDown={(evt: any) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                />
              </div>
              </div>
            </div>
        </fieldset>

        <fieldset>
          <legend> &nbsp;
            <span className={styles.fieldsetTitulo}>Custo de demolição [€/m<sup>2</sup>]</span>{' '}
          </legend>
          <div className={styles.espacoVerticalFieldSet}>
            <div className={styles.fields}>
              <div>
                <Field
                  name="custo_demolicao"
                  label="Custo de demolição"
                  component={Input}
                  disabled={viewMode}
                  type={'number'}
                  onKeyDown={(evt: any) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                />
              </div>
              </div>
            </div>  
        </fieldset>

        <fieldset>
          <legend> &nbsp;
            <span className={styles.fieldsetTitulo}>Custo de renaturalização [€/m<sup>2</sup>]</span>{' '}
          </legend>
          <div className={styles.espacoVerticalFieldSet}>
            <div className={styles.fields}>
              <div>
                  <Field
                    name="custo_renaturalizacao"
                    label="Custo de renaturalização"
                    component={Input}
                    disabled={viewMode}
                    type={'number'}
                    onKeyDown={(evt: any) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                  />
              </div>
              </div>
            </div>  
        </fieldset>

        <fieldset>
          <legend> &nbsp;
            <span className={styles.fieldsetTitulo}>Período de análise</span>{' '}
          </legend>
           <div className={styles.espacoVerticalFieldSet}>
                  <div className={styles.fields}>
                    <div>
                      <Field
                        name="dt_periodo_analise_de"
                        label="De"
                        component={DateTimePicker}
                        disabled={viewMode}
                      />
                    </div>
                    <div>
                      <Field
                        name="dt_periodo_analise_para"
                        label="Para"
                        component={DateTimePicker}
                        disabled={viewMode}
                      />
                    </div>
                  </div>
             </div>
        </fieldset>
    </div>
  )
}
