import { Button } from '@progress/kendo-react-buttons';
import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import { ComboBox, ComboBoxFilterChangeEvent } from '@progress/kendo-react-dropdowns';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { Input, RadioGroup } from '@progress/kendo-react-inputs';
import clsx from 'clsx';
import {
  MouseEvent as ReactMouseEvent,
  useEffect,
  useState
} from 'react';
import SessionStorageService from '../../services/SessionStorageService';
import { agDecisoes, agEstados, agTipoPretensoes, arhs, capitania, concelhos, coordenadasPoligono, distritos, fillAgEstadosAsync, fillAgTipoPretensaosync, fillArhsAsync, fillCapitaniaAsync, fillCombosAuxiliares, fillConcelhosAsync, fillDistritosAsync, fillFreguesiasAsync, fillNuts1Async, fillNuts2Async, fillNuts3Async, fillTecnicosAsync, freguesias, get, handleGenericError, massaagua, nuts1, nuts2, nuts3, post, setCoordenadasPoligono, tecnicos, useCombos } from '../../services/utils';
import { useAgDesconformidadeStore } from '../../stores/agDesconformidade';
import styles from '../../styles/apoiogestao/ApoioGestao.module.scss';
import { AREA_VISIVEL, CONSULTAR_AG_PRETENSAO, GET_AG_DECISOES, GET_AG_ESTADOS, GET_AG_TIPO_PRETENSOES, NENHUM, POLIGONO_SELECAO, clearLayers } from '../../utils/constants';
import FieldsetCombosLocalizacao from '../localizacao/FieldsetCombosLocalizacao';
import { FilterDescriptor, filterBy } from '@progress/kendo-data-query';
import ReactGA from 'react-ga4';

type IProps = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
}
interface PretensaoSearchDto {
  id?: number;
  utilizador_id?: number;
  ag_estado_id?: number;
  data_entrada?: string;
  tecnico?: string;
  registo_entrada?: string;
  file_doc_numero?: string;
  requerente_nif?: string;
  requerente_nome?: string;
  numero_do_processo?: string;
  assunto?: string;
  dominio_hidrico?: string;
  ag_tipo_pretensao_id?: number;
  ag_decisao_id?: number;
  freguesia_cod?: string;
  concelho_cod?: string;
  distrito_cod?: string;
  arh_cod?: string;
  nuts1_cod?: string;
  nuts2_cod?: string;
  nuts3_cod?: string;
  capitania_cod?: string;
  criterio_geografico?: string;
  flatCoordinates?: string;
  de_entrada?: string;
  ate_entrada?: string;
  de_registo?: string;
  ate_registo?: string;
  massa_agua_id?: number;
}

export default function PretensaoForm({ onCloseClick }: IProps) {
  const [dataOcorrenciaInicio, setDataOcorrenciaInicio] = useState<any>()
  const [dataRegistoInicio, setDataRegistoInicio] = useState<any>()
  const [dataOcorrenciaFim, setDataOcorrenciaFim] = useState<any>()
  const [dataRegistoFim, setDataRegistoFim] = useState<any>()
  const [dataEntradaInicio, setDataEntradaInicio] = useState<any>();
  const [dataEntradaFim, setDataEntradaFim] = useState<any>();
  const [freguesiasFiltred, setFreguesiasFiltred] = useState<any>()
  const [pessoasRiscoSelected, setPessoasRiscoSelected] = useState<any>()
  const [registoVisible, setRegistoVisible] = useState(true)
  const [criterioGeograficoVisible, setCriterioGeograficoVisible] =
    useState(false)
  const [identificacaoVisible, setIdentifcacaoVisible] = useState(false)
  const [localizacaoVisible, setLocalizacaoVisible] = useState(false)
  const [tipologiaVisible, setTipologiaVisible] = useState(false)
  const [requerenteVisible, setRequerenteVisible] = useState(false)
  const [outrosCriteriosVisible, setOutrosCriteriosVisible] = useState(false)
  const [danosSistemasNaturaisSelected, setDanosSistemasNaturaisSelected] =
    useState<any>()
  const [rbCriteriosSelected, setRBCriteriosSelected] = useState<any>()
  const [concelhosFiltred, setConcelhosFiltred] = useState<any>()
  const [concelhosDisabled, setConcelhosDisabled] = useState<any>(true)
  const [freguesiasDisabled, setFreguesiasDisabled] = useState<any>(true)
  const [nuts2Filtered, setNuts2Filtered] = useState<any>()
  const [nuts3Filtered, setNuts3Filtered] = useState<any>()
  const [nuts2Disabled, setNuts2Disabled] = useState<any>(true)
  const [nuts3Disabled, setNuts3Disabled] = useState<any>(true)
  const [dataDeEntrada, setDataDeEntrada] = useState<any>()
  const [dataAteEntrada, setDataAteEntrada] = useState<any>()
  const selectAgDesconformidadeResult = useAgDesconformidadeStore(
    (state) => state.selectAgDesconformidadeResult
  )
  const getPretensoes = async (data: any) =>
    await post<any, any>(`${CONSULTAR_AG_PRETENSAO}`, data)
  const getAgEstados = async (data: any) =>
    await get<any, any>(`${GET_AG_ESTADOS}`)
  const getAgDecisoes = async (data: any) =>
    await get<any, any>(`${GET_AG_DECISOES}`)
  const getAgTipoPretensoes = async (data: any) =>
    await get<any, any>(`${GET_AG_TIPO_PRETENSOES}`)

  const [tecnicosData, setTecnicosData] = useState(tecnicos ? tecnicos.slice() : []);
  const [minData, setMinDate] = useState<any>();

  
  useEffect(() => {
    
    ReactGA.send({ hitType: "pageview", page: "/ConsultarPretensao" });
  
    //ReactGA.pageview(window.location.pathname + "ConsultarPretensao")
  }, []);

  useEffect(() => {

       if(!nuts1 ){
      fillNuts1Async();
      }
     if(!nuts2) {
      fillNuts2Async();
     }
     if(!nuts3){
      fillNuts3Async();
     }
     if(!distritos){
      fillDistritosAsync();
     }
     if(!concelhos){
      fillConcelhosAsync();
     }
     if(!freguesias){
      fillFreguesiasAsync();
     }
     if(!arhs){
      fillArhsAsync();
     }
     if(!capitania){
      fillCapitaniaAsync();
     }
     if(!agEstados){
      fillAgEstadosAsync();
     }
     if(!agTipoPretensoes){
      fillAgTipoPretensaosync();
     }
     if(!tecnicos){
      fillTecnicosAsync();
     }
    
  }, []);

    type combosKeys= 'agEstados' | 'agTipoPretensoes' |'agDecisoes';
    const [combos, setCombos] = useState<any>({
      agEstados,
      agTipoPretensoes,
      agDecisoes
    })
    const setDataOption: Record<combosKeys, { setFn: any, data: any[] }> = {
      agEstados: { setFn: setCombos, data: agEstados },
      agTipoPretensoes: { setFn: setCombos, data: agTipoPretensoes },
      agDecisoes: { setFn: setCombos, data: agDecisoes },
    };
    const filterCombo_Multiselect = (e: any, tipoCombo: combosKeys) => {
      const { setFn, data } = setDataOption[tipoCombo];
      setFn({
          ...combos,
          [tipoCombo]: filterBy(data?.slice()??[], e.filter)
      }
      );
  }

  const toggleRegisto = () => {
    setRegistoVisible(!registoVisible)
  }
  const toggleCriterioGeografico = () => {
    setCriterioGeograficoVisible(!criterioGeograficoVisible)
  }

  const toggleIdentificacao = () => {
    setIdentifcacaoVisible(!identificacaoVisible)
  }

  const toggleTipologia = () => {
    setTipologiaVisible(!tipologiaVisible)
  }
  const toggleRequerente = () => {
    setRequerenteVisible(!requerenteVisible)
  }

  const toggleLocalizacao = () => {
    setLocalizacaoVisible(!localizacaoVisible)
  }

  const toggleOutrosCriterios = () => {
    setOutrosCriteriosVisible(!outrosCriteriosVisible)
  }

  const filterConcelho = (e: any) => {
    if (e?.value?.codigo) {
      const concelhosLocal = concelhos.filter(
        (x: any) => x.distrito_cod == e.value.codigo
      )
      setConcelhosFiltred(concelhosLocal)
      setConcelhosDisabled(false)
    } else {
      setConcelhosFiltred(concelhos)
      setConcelhosDisabled(true)
    }
  }

  const filterFreguesia = (e: any) => {
    if (e?.value?.codigo) {
      const freguesiaLocal = freguesias.filter(
        (x: any) => x.concelho_cod == e.value.codigo
      )
      setFreguesiasFiltred(freguesiaLocal)
      setFreguesiasDisabled(false)
    } else {
      setFreguesiasDisabled(true)
      setFreguesiasFiltred(freguesias)
    }
  }
  const filterNuts2 = (e: any) => {
    if (e?.value?.codigo) {
      const nutsLocal = nuts2.filter((x: any) => x.nuts_i_cod == e.value.codigo)
      setNuts2Filtered(nutsLocal)
      setNuts2Disabled(false)
    } else {
      setNuts2Disabled(true)
      setNuts2Filtered(nuts2)
    }
  }
  const filterNuts3 = (e: any) => {
    if (e?.value?.codigo) {
      const nutsLocal = nuts3.filter(
        (x: any) => x.nuts_ii_cod == e.value.codigo
      )
      setNuts3Filtered(nutsLocal)
      setNuts3Disabled(false)
    } else {
      setNuts3Disabled(true)
      setNuts3Filtered(nuts3)
    }
  }

  const filterDataTecnicos = (filter: FilterDescriptor) => {
    const data = tecnicos.slice();
    return filterBy(data, filter);
  };

  const filterChangeTecnicos = (event: ComboBoxFilterChangeEvent) => {
    setTecnicosData(filterDataTecnicos(event.filter));
  };

  const clearMap = () => {
    try {

      setCoordenadasPoligono(undefined)
      const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.contentWindow?.postMessage({ type: "SiarlPretensaoClearLayers", value: "true" }, '*')
      iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: "true" }, '*')
      iframe.contentWindow?.postMessage({ type: "SiarlDeselectMapArea", value: "true" }, '*')

    } catch (error) {
      console.log({ error })
    }
  }

  const changerbCriterio = (e: any) => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlDeselectMapArea", value: 'true' }, '*')
    if (e.value == POLIGONO_SELECAO) {
        postMessage(
            { type: 'informationMsg', value: 'Selecione a área de pesquisa' },
            '*'
        )
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage(
            { type: 'SiarlGeoPortalAdicionarArea', value: 'true' },
            '*'
        )
    }
    if (e.value == AREA_VISIVEL) {
      const iframe: any = document.getElementById('iframeGeoPortal')

      iframe.contentWindow?.postMessage({ type: "SiarlSelectMapArea", value: 'true' }, '*')
      postMessage(
          {
              type: 'informationMsg',
              value: 'Foi selecionada a área visível do mapa',
          },
          '*'
      )
      iframe.contentWindow?.postMessage(
        { type: 'SiarlGetMapArea', value: 'true' },
        '*'
      )
    }

    if (e.value == NENHUM) {
      setCoordenadasPoligono(undefined)
      clearMap()
  }
  }

  useEffect(() => {
    selectAgDesconformidadeResult(undefined);
  }, []);
  const handleSubmit = async (e: any) => {

   
    try {
      postMessage({ type: 'showLoader' }, '*')
      console.log({ values: e.values })
      const formValues = e?.values;
      const dataToSend: unknown = {
        id: formValues?.id ? Number(formValues?.id) : undefined,
        ag_estado_id: formValues?.estado?.id,
        tecnico_responsavel: formValues.tecnico_responsavel ? formValues.tecnico_responsavel.id : undefined,
        //tecnico_responsavel: formValues?.tecnico_responsavel?.id,
        file_doc_numero: formValues?.file_doc_numero,
        requerente_nif: formValues?.nifRequerente,
        requerente_nome: formValues?.nomeRequerente,
        numero_do_processo: formValues?.numero_do_processo,
        num_processo_file_doc: formValues?.num_processo_file_doc,
        assunto: formValues?.assunto,
        dominio_hidrico: formValues?.dominioHidrico,
        ag_tipo_pretensao_id: formValues?.tipoPretensao?.id,
        ag_decisao_id: formValues?.decisao?.id,
        freguesia_cod: formValues?.freguesia?.codigo,
        concelho_cod: formValues?.concelho?.codigo,
        distrito_cod: formValues?.distrito?.codigo,
        arh_cod: formValues?.arh?.codigo,
        nuts1_cod: formValues?.nuts1?.codigo,
        nuts2_cod: formValues?.nuts2?.codigo,
        nuts3_cod: formValues?.nuts3?.codigo,
        capitania_cod: formValues?.capitania?.codigo,
        massa_agua_id: formValues?.massaAgua?.id,
        criterio_geografico: formValues?.rbCriterio,
        flatCoordinates: coordenadasPoligono ?? undefined,
        de_entrada: formValues?.deEntrada,
        ate_entrada: formValues?.ateEntrada,
        ate_registo: formValues?.ateRegisto,
        de_registo: formValues?.deRegisto,
        local: formValues?.local
      }

      SessionStorageService.setItem("firstCallAgPretensao", true);
      SessionStorageService.setItem("lastSearchPretensao", dataToSend);

      const resultadoPretensoes = await getPretensoes(dataToSend);
      SessionStorageService.setItem(
        'resultadoApoioGestaoPretensao',
        JSON.stringify(resultadoPretensoes)
      )

      postMessage({ type: "resultadoApoioGestaoPretensaoPesquisaChange" }, "*");
    } catch (error) {
      handleGenericError(error);
    } finally {
      postMessage({ type: 'hideLoader' }, '*')

    }
    return;

  }

  const PermitirNumeros = (e: React.ChangeEvent<HTMLInputElement>) => {
    const ApenasNumeros = e.target.value.replace(/\D/g, '');
    e.target.value = ApenasNumeros;
  };

  return (
    <Form
      onSubmitClick={handleSubmit}
      render={(formRenderProps: FormRenderProps) => (
        <>
          <FormElement className={styles.form}>
            <fieldset>
              <legend>
                <button type='button' className={styles.itemExpandBase} onClick={toggleRegisto}>
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span className={registoVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                  </div>
                  <span className={styles.fieldsetTitulo}>Registo</span>
                </button>
              </legend>
              {registoVisible && (
                <div className={styles.espacoVerticalFieldSet}>
                  <div className={styles.fields} >
                    <div>
                      <Field name="id" label="ID" component={Input} />
                    </div>
                    <div>
                      <Field name="file_doc_numero" label="Nº Processo APA" component={Input} />
                    </div>
                    
                  </div>
                  
                  <div className={styles.fields}>
                    
                    <div>
                      <Field
                        name="tecnico_responsavel"
                        label="Técnico de registo"
                        component={ComboBox}
                        textField={'name'}
                        filterable={true}
                        onFilterChange={filterChangeTecnicos}
                        data={tecnicosData}
                      />
                    </div>
                    <div>
                      <Field
                        name="estado"
                        label="Estado"
                        component={ComboBox}
                        textField={'descricao'}
                        filterable={true}
                        onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'agEstados')}}
                        data={combos?.agEstados}
                      />
                    </div>
                  </div>


                  
                  <div style={{ marginTop: 15 }}>

      <div className={styles.fieldsetSubTitulo}>
          Data de Registo
      </div>
      <div className={styles.fields}>
          <div>
            <Field
              name="deRegisto"
              label="De"
              value={dataRegistoInicio}
              format="dd/MM/yyyy HH:mm"
              component={DateTimePicker}
              onChange={(e) => {
                setDataRegistoInicio(e.value)
                 setMinDate(e.value);
              }}     
            />
          </div>
          <div>
            <Field
              name="ateRegisto"
              label="Até"
              value={dataRegistoFim}
              format="dd/MM/yyyy HH:mm"
              component={DateTimePicker}
              onChange={(e) => {
                setDataRegistoFim(e.value)
              }}
              min={minData}
            />
          </div>
        </div>
        </div>
                </div>

              )}
            </fieldset>
            <fieldset style={{paddingBottom: '10px'}}>
              <legend>
                <button type='button' className={styles.itemExpandBase} onClick={toggleIdentificacao}>
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span className={identificacaoVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                  </div>
                  <span className={styles.fieldsetTitulo}>Identificação</span>
                </button>
              </legend>
              {identificacaoVisible && (
                <><div className={styles.espacoVerticalFieldSet}>
                  <div className={styles.fields}>
                    <div>
                      <Field
                        name="numero_do_processo"
                        label="Núm. do Processo"
                        component={Input} />
                    </div>


                  </div>
                  <div className={styles.fields}>

                    <div>
                      <Field
                        name="tipoPretensao"
                        label="Tipo de Pretensão"
                        component={ComboBox}
                        textField={"descricao"}
                        filterable={true}
                        onFilterChange={(e: any) => { filterCombo_Multiselect(e, 'agTipoPretensoes'); } }
                        data={combos?.agTipoPretensoes} />
                    </div>
                    <div>
                      <Field
                        name="decisao"
                        label="Decisão"
                        filterable={true}
                        onFilterChange={(e: any) => { filterCombo_Multiselect(e, 'agDecisoes'); } }
                        data={combos?.agDecisoes}
                        textField={'descricao'}
                        component={ComboBox} />
                    </div>
                  </div>
                  <div className={styles.espacoVerticalFieldSet}>
                  <div>
                      <Field
                        name="assunto"
                        label="Assunto"
                        component={Input} />
                    </div>
                    <div className={styles.fields}>
                      <div>
                        <Field
                          name="nifRequerente"
                          label="NIF do requerente"
                          component={Input}
                          onInput={PermitirNumeros}
                          maxLength={9} />
                      </div>
                      <div>
                        <Field
                          name="nomeRequerente"
                          label="Nome do requerente"
                          component={Input} />
                      </div>
                    </div>
                    
                  </div>
                </div><div style={{ marginTop: 15 }}>
                    <div className={styles.fieldsetSubTitulo}>
                      Registo de entrada
                    </div>
                    <div className={styles.fields}>
                      <div>
                        <Field
                          name="deEntrada"
                          label="De"
                          value={dataEntradaInicio}
                          format="dd/MM/yyyy HH:mm"
                          component={DateTimePicker}
                          onChange={(e) => {
                            setDataDeEntrada(e.value);
                            setMinDate(e.value);
                          } } />
                      </div>
                      <div>
                        <Field
                          name="ateEntrada"
                          label="Até"
                          value={dataEntradaFim}
                          format="dd/MM/yyyy HH:mm"
                          component={DateTimePicker}
                          onChange={(e) => {
                            setDataAteEntrada(e.value);
                          } }
                          min={minData} />
                      </div>
                    </div>

                  </div></>
            )}
            </fieldset>
            <fieldset>
              <legend>
                <button type='button' className={styles.itemExpandBase} onClick={toggleCriterioGeografico}>
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span className={criterioGeograficoVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                  </div>
                  <span className={styles.fieldsetTitulo}>Critério geográfico</span>
                </button>
              </legend>
              {criterioGeograficoVisible ?
                <div className={styles.fields}>
                <Field
                  id={'rbCriterio'}
                  name={'rbCriterio'}
                  layout={'horizontal'}
                  component={RadioGroup}
                  onChange={changerbCriterio}
                  data={[
                    { label: 'Nenhum', value: 0 },
                    { label: 'Área visível', value: 1 },
                    { label: 'Polígono de seleção', value: 2 },
                  ]}
                  defaultValue={0} // Set the default selected value to 0 (Nenhum)
                />
              </div> : <></>}
              
            </fieldset>
                <FieldsetCombosLocalizacao isPesquisaAvancada={true} haveLocal={true} styles={styles} formRenderProps={formRenderProps}/>

          </FormElement>
          <div className={`${styles.footer} ${styles.justify_multiple_children}`}>
            <Button
              onClick={() => { formRenderProps.onFormReset(); clearMap()}}
              className={styles.btnLimparPesquisa}
              title='Limpar pesquisa'
            >
              <span className="icon-siarl icon-limparcampos"></span>
              <span className={styles.rotulo}>
                {' '}&nbsp;{' '}
                Limpar pesquisa
              </span>
            </Button>
            <Button
              onClick={formRenderProps.onSubmit}
              className={styles.btnPesquisar}
              title='Pesquisar'
            >
              <span className="icon-siarl icon-pesquisar"></span>
              <span className={styles.rotulo}>
                {' '}&nbsp;{' '}
                Pesquisar
              </span>
            </Button>
          </div>


        </>
      )}
    />
  );
}