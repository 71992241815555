
import { MouseEvent as ReactMouseEvent, useEffect, useLayoutEffect, useState } from 'react'

import styles from '../../styles/contactenos/ContactenosFormDialog.module.scss'
import { CONTACTE_NOS_URL } from '../../utils/constants'

type Props = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
}

export default function Contactenos({ onCloseClick }: Props) {
  
  return (
    <div className={styles.base}>
      <div className={styles.header}>  
        <button className={styles.btnFechar} onClick={onCloseClick} title="Minimizar">
          <span className="icon-siarl icon-minimizar" />
        </button>
      </div>

        <iframe
          src={CONTACTE_NOS_URL}
          title="Document Preview"
          className={styles.iframeFull}
        ></iframe>
    </div>
  )
}
