import { Button } from '@progress/kendo-react-buttons'
import { DateTimePicker } from '@progress/kendo-react-dateinputs'
import { ComboBox, MultiSelect } from '@progress/kendo-react-dropdowns'
import { Field, FormRenderProps } from '@progress/kendo-react-form'
import { Input } from '@progress/kendo-react-inputs'
import { useEffect, useState } from 'react'
import {
  arhs,
  capitania,
  concelhos,
  distritos,
  entidades,
  estados,
  fillArhsAsync,
  fillCapitaniaAsync,
  fillConcelhosAsync,
  fillDistritosAsync,
  fillEntidadesAsync,
  fillEstadoAsync,
  fillFreguesiasAsync,
  fillModelosSubTiposAsync,
  fillModelosTiposAsync,
  fillNuts1Async,
  fillNuts2Async,
  fillNuts3Async,
  freguesias,
  get,
  getModeloSelected,
  handleGenericError,
  hasPermission,
  massaagua,
  moEstados,
  modelosSubtipos,
  modelosTipos,
  nuts1,
  nuts2,
  nuts3,
  post,
  setModeloSelected,
  useCombos
} from '../../../services/utils'
import { useModeloStore } from '../../../stores/modelo'
import styles from '../../../styles/modelos/RegistarModeloForm.module.scss'
import { GET_MODELO, IMPORT_GEOM_MO_MODELO, PERMISSION_MODELO_PERFIL_COMPLETO_LEITURA, PERMISSION_MODELO_PERFIL_TOTAL, SAVE_NEW_MODELO } from '../../../utils/constants'
import ImportarGeometriaComponent from '../../apoiogestao/registar/ImportarGeometriaForm'
import CheckMoreItem from '../../documentos/registar/fields/CheckMoreItem'
import ValidatedIdentificacaoInput from './fields/ValidatedIdentificacaoInput'
import { requiredValidator } from './fields/validators'
import { filterBy } from '@progress/kendo-data-query'

type IProps = {
  formRenderProps?: FormRenderProps,
  viewMode: boolean
}

export function ModeloIdentificacao({ formRenderProps, viewMode }: IProps) {
  const [freguesiasFiltred, setFreguesiasFiltred] = useState<any>()
  const [subtipoModelosFiltered, setSubtipoModelosFiltered] = useState<any>([])
  const [freguesiasFiltered, setFreguesiasFiltered] = useState<any>([])
  const [concelhosFiltered, setConcelhosFiltered] = useState<any>([])
  const [nuts2Filtered, setNuts2Filtered] = useState<any>([])
  const [nuts3Filtered, setNuts3Filtered] = useState<any>([])
  const [openImportModal, setOpenImportModal] = useState<any>()

  const selectedModeloResult: any = useModeloStore((state) => state.selectedModeloResult)
  const selectedModeloResultId = useModeloStore((state) => state.selectedModeloResultId)
  const selectModeloResult: any = useModeloStore((state) => state.selectModeloResult)
  const selectModeloResultId = useModeloStore((state) => state.selectModeloResultId)

  type combosKeys = 'entidades'|'estados'|'modelosTipos' |'modelosSubtipos';

  const [combos, setCombos] = useState<any>({
    entidades,
    moEstados,
    modelosTipos,
    modelosSubtipos
  })
  const setDataOption: Record<combosKeys, { setFn: any, data: any[] }> = {
    entidades: { setFn: setCombos, data: entidades },
    estados: { setFn: setCombos, data: moEstados },
    modelosTipos: { setFn: setCombos, data: modelosTipos },
    modelosSubtipos: { setFn: setCombos, data: subtipoModelosFiltered }
  };

  const filterCombo_Multiselect = (e: any, tipoCombo: combosKeys) => {
    const { setFn, data } = setDataOption[tipoCombo];
      setFn({
          ...combos,
          [tipoCombo]: filterBy(data?.slice()??[], e.filter)
      }
      );
}


  useEffect(() => {
    
      if(!nuts1 ){
      fillNuts1Async();
      }
     if(!nuts2) {
      fillNuts2Async();
     }
     if(!nuts3){
      fillNuts3Async();
     }
     if(!distritos){
      fillDistritosAsync();
     }
     if(!concelhos){
      fillConcelhosAsync();
     }
     if(!freguesias){
      fillFreguesiasAsync();
     }
     if(!arhs){
      fillArhsAsync();
     }
     if(!capitania){
      fillCapitaniaAsync();
     }
     if(!estados){
      fillEstadoAsync();
     }
     if(!entidades){
      fillEntidadesAsync();
     }
     if(!modelosTipos){
      fillModelosTiposAsync();
     }
     if(!modelosSubtipos){
      fillModelosSubTiposAsync();
     }

  }, []);

  const importGeomMoModelo = async (data: any) => await post<any, any>(`${IMPORT_GEOM_MO_MODELO}`, data)
  const getMoModeloById = async (id: any) => await get<any, any>(`${GET_MODELO}/` + id)

  const concelhoSelectChange = (item: any) => {
    if (!item) {
      setFreguesiasFiltred(freguesias)
      return
    }
    setFreguesiasFiltred(
      freguesias.filter((x) => x.concelho_cod == item.codigo)
    )
  }

  const onTipoModeloChange = (e: any) => {
    setSubtipoModelosFiltered([]);
    const tipoModelo = e?.value;
    if (tipoModelo) {
      setSubtipoModelosFiltered(modelosSubtipos.filter((sbm: { mo_modelo_tipo_id: any }) => sbm.mo_modelo_tipo_id === tipoModelo.id))
    }

  }

  const saveMoModelo = async (data: any) => await post<any, any>(`${SAVE_NEW_MODELO}`, data);

  const cancelImport = () => {
    setOpenImportModal(false)
  }

  const importGeo = async (objToSend: any) => {
    postMessage({ type: "showLoader" }, "*");

    if (!objToSend) {
      postMessage({ type: "errorMsg", value: "Não foi possivel salvar" }, "*");
      postMessage({ type: "hideLoader" }, "*");
      return;
    }
    let mo_modelo;
    let mo_modelo_id;
    const modeloSelected = selectedModeloResult ?? getModeloSelected();
    if (modeloSelected && modeloSelected?.id) {
      mo_modelo = modeloSelected;
      mo_modelo_id = modeloSelected?.id;
    } else {
      mo_modelo = await saveMoModelo({});
      mo_modelo_id = mo_modelo?.id;
      selectModeloResult(mo_modelo);
      selectModeloResultId(mo_modelo);

    }
    try {
      objToSend = { ...objToSend, modelo_id: mo_modelo_id }
      const result = await importGeomMoModelo(objToSend)
      if (!result) {
        postMessage({ type: "errorMsg", value: "Não foi possivel salvar" }, "*");
        return;
      }

      try {
        mo_modelo = await getMoModeloById(mo_modelo_id);
      } catch (error) {
        postMessage({ type: "errorMsg", value: "Registo salvo mas, Não é possivel mostrar os dados neste momento" }, "*");

      } finally {
        selectModeloResultId(mo_modelo_id);
        selectModeloResult({ ...mo_modelo, ...objToSend });
        setModeloSelected({ ...mo_modelo, ...objToSend })
      }
      try {
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage(
          { type: 'SiarlModeloSearch', value: [mo_modelo_id] },
          '*'
        )
      } catch (error) {
        postMessage({ type: "errorMsg", value: "Registo salvo mas, Não é possivel mostrar os dados neste momento" }, "*");
      }

      setOpenImportModal(false)
    } catch (err) {
      handleGenericError(err);

    } finally {
      postMessage({ type: "hideLoader" }, "*");
    }

  }

  return (
    <>
      <div className={`${styles.form} ${styles.removeScrollDuplicado}`}>

       <span style={{ marginLeft: '08px' }}>Os campos com simbolo * (asterisco) são de preenchimento obrigatório</span>


        <fieldset>

          <legend>
            <span className={styles.fieldsetTitulo}>Registo</span>
          </legend>

          <div className={styles.espacoVerticalFieldSet}>
            <div className={styles.fields}>
              <div>
                <Field name="Iddoc" label="ID" component={Input} disabled />
              </div>
              <div>
                <Field
                  name="dataHoraRegisto"
                  label="Data/hora registo"
                  format="dd/MM/yyyy HH:mm"
                  component={DateTimePicker}
                  disabled
                />
              </div>
              <div>
                <Field
                  name="estadoDoRegisto"
                  label="Estado do Registo"
                  component={ComboBox}
                  textField={'nome'}
                  filterable={true}
                        onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'estados')}}
                        data={combos?.estados}
                  disabled={viewMode}
                />
              </div>
              </div>
            <div className={styles.fields}>
              <div>
                <Field
                  name="tecnicoRegisto"
                  label="Técnico de registo"
                  component={Input}
                  disabled
                />
              </div>

              <div>
               <Field
              name="entidade_registo"
              label="Entidade"
              disabled
              component={Input}
                />
            </div>
          </div>
          </div>

        </fieldset>

        <fieldset>

          <legend>
            <span className={styles.fieldsetTitulo}>Identificação</span>
          </legend>

          <div className={styles.espacoVerticalFieldSet}>
            <div className={styles.fields}>
              <div>
                <Field
                  name="titulo"
                  label="Título modelo *"
                  component={ValidatedIdentificacaoInput}
                  textField={'nome'}
                  validator={requiredValidator}
                  maxLength={250}
                  disabled={viewMode}
                />
              </div>
              <div>
                <Field
                  name="dataHoraPublicacao"
                  label="Data/hora publicacao"
                  format="dd/MM/yyyy HH:mm"
                  component={DateTimePicker}
                  disabled={viewMode}
                />
              </div>
            </div>
            <div className={styles.fields}>
              <div>
                <Field
                  name="autores"
                  label="Autor(es)"
                  component={Input}
                  textField={'nome'}
                  maxLength={50}
                  disabled={viewMode}
                />
              </div>
              {hasPermission(PERMISSION_MODELO_PERFIL_TOTAL) || hasPermission(PERMISSION_MODELO_PERFIL_COMPLETO_LEITURA)?
              <div>
                <Field
                  name="entidade"
                  label="Entidade"
                  component={ComboBox}
                  textField={'nome'}
                  filterable={true}
                  onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'entidades')}}
                  data={combos?.entidades}
                  disabled={viewMode}
                />
              </div>:<></>}
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Localização</span>
          </legend>

          <div className={styles.espacoVerticalFieldSet}>
            <div className={styles.fields}>
              {selectedModeloResult ? (
                <div>
                  <CheckMoreItem
                    list={selectedModeloResult?.nuts1}
                    property={'nome'}
                    label="NUTS I"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="nuts1"
                    label="NUTS I"
                    component={MultiSelect}
                    textField={'nome'}
                    data={nuts1}
                    disabled
                  />
                </div>
              )}
              {selectedModeloResult ? (
                <div>
                  <CheckMoreItem
                    list={selectedModeloResult?.nuts2}
                    property={'nome'}
                    label="NUTS II"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="nuts2"
                    label="NUTS II"
                    component={MultiSelect}
                    textField={'nome'}
                    data={nuts2}
                    disabled
                  />
                </div>
              )}
              {selectedModeloResult ? (
                <div>
                  <CheckMoreItem
                    list={selectedModeloResult?.nuts3}
                    property={'nome'}
                    label="NUTS III"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="nuts3"
                    label="NUTS III"
                    component={MultiSelect}
                    textField={'nome'}
                    data={nuts3}
                    disabled
                  />
                </div>
              )}
            </div>

            <div className={styles.fields}>
              {selectedModeloResult ? (
                <div>
                  <CheckMoreItem
                    list={selectedModeloResult?.distritos}
                    property={'nome'}
                    label="Distrito"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="distrito"
                    label="Distrito"
                    component={MultiSelect}
                    textField={'nome'}
                    data={distritos}
                    disabled
                  />
                </div>
              )}
              {selectedModeloResult ? (
                <div>
                  <CheckMoreItem
                    list={selectedModeloResult?.concelhos}
                    property={'nome'}
                    label="Concelho"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="concelho"
                    label="Concelho"
                    component={MultiSelect}
                    onChange={(e) => concelhoSelectChange(e.value)}
                    textField={'nome'}
                    data={concelhos}
                    disabled
                  />
                </div>
              )}
              {selectedModeloResult ? (
                <div>
                  <CheckMoreItem
                    list={selectedModeloResult?.lstFreguesias}
                    property={'nome'}
                    label="Freguesia"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="freguesia"
                    label="Freguesia"
                    component={MultiSelect}
                    textField={'designacao'}
                    data={freguesiasFiltred}
                    disabled
                  />
                </div>
              )}
            </div>

            <div className={styles.fields}>
              {selectedModeloResult ? (
                <div>
                  <CheckMoreItem
                    list={selectedModeloResult?.arhs}
                    property={'nome'}
                    label="ARH"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="arh"
                    label="ARH"
                    component={MultiSelect}
                    textField={'nome'}
                    data={arhs}
                    disabled
                  />
                </div>
              )}
              {selectedModeloResult ? (
                <div>
                  <CheckMoreItem
                    list={selectedModeloResult?.capitanias}
                    property={'nome'}
                    label="Capitania"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="capitania"
                    label="Capitania"
                    component={MultiSelect}
                    textField={'nome'}
                    data={capitania}
                    disabled
                  />
                </div>
              )}
              {selectedModeloResult ? (
                <div>
                  <CheckMoreItem
                    list={selectedModeloResult?.massasAgua}
                    property={'nome'}
                    label="Massa de água"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="massaAgua"
                    label="Massa de água"
                    component={MultiSelect}
                    textField={'nome'}
                    data={massaagua}
                    disabled
                  />
                </div>
              )}
            </div>
          </div>
          <div>


            <div className={styles.fields}>
              <Field name="local" label="Local" disabled={viewMode} component={Input} />
            </div>
          </div>
        </fieldset>

        {hasPermission(PERMISSION_MODELO_PERFIL_TOTAL) || hasPermission(PERMISSION_MODELO_PERFIL_COMPLETO_LEITURA)?
        <fieldset>

          <legend>
            <span className={styles.fieldsetTitulo}>Tipologia</span>
          </legend>

          <div className={styles.espacoVerticalFieldSet}>
            <div className={styles.fields}>
              <div>
                <Field
                  name="tipoModelo"
                  label="Tipo de modelo"
                  component={ComboBox}
                  textField={'designacao'}
                  onChange={(e) => onTipoModeloChange(e)}
                  filterable={true}
                  onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'modelosTipos')}}
                  data={combos?.modelosTipos}
                  disabled={viewMode}
                />
              </div>
              <div>
                <Field
                  name="subtipoModelo"
                  label="Subtipo de Modelo"
                  component={ComboBox}
                  textField={'descricao'}
                  filterable={true}
                  onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'modelosSubtipos')}}
                  data={combos?.modelosSubtipos}
                  disabled={viewMode}
                />
              </div>
            </div>
          </div>
        </fieldset>:<></>
        }

      </div>

      {openImportModal ?
        <ImportarGeometriaComponent onSubmit={importGeo} cancelImport={cancelImport} /> : <></>
      }
    </>
  )
}

