import { Button, DropDownButton } from '@progress/kendo-react-buttons'
import {
  Form,
  FormElement
} from '@progress/kendo-react-form'
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from '@progress/kendo-react-layout'
import { useEffect, useLayoutEffect, useState } from 'react'

import {
  MouseEvent as ReactMouseEvent,
} from 'react'
import {
  GetUserNameTecnico,
  b64toBlob,
  clearDemarcacao,
  coordenadasLinhaSelected,
  del,
  get,
  getDemarcacaoSelected,
  handleGenericError,
  hasPermission,
  post,
  put,
  setCoordenadasLinhaSelected,
  setCoordenadasPoligono,
  setCoordenadasSelected,
  setDemarcacaoSelected,
  setPatrimonioSelected,
  setProcessoSelected,
  useCombos
} from '../../services/utils'
import { useDemarcacaoStore } from '../../stores/demarcacao'
import styles from '../../styles/dominiohidrico/DominioHidrico.module.scss'
import {
  AUTH_LOCALSTORAGE,
  DELETE_DH_PATRIMONIO,
  DEMARCACAO_MODULE_NAME,
  DH_DEMARCACAO_POINT_NAME,
  GET_DEMARCACAO,
  GET_URL_FROM_FILE_DOC,
  PERMISSION_ATUALIZAR_DEMARCACAO,
  RELATORIO_DEMARCACAO,
  REMOVER_LINHA_DH_DEMARCACAO,
  SAVE_NEW_DEAMARCACAO,
  UPDATE_DEMARCACAO,
  URL_AJUDA_DOMINIO_HIDRICO_DEMARCACAO,
  clearLayers
} from '../../utils/constants'
import { AssociacaoListagem } from '../associacao/Associacao'
import { DocumentoListagem } from '../documentos/registar/documento/documento'
import IdentificacaoDemarcacao from './IdentificacaoDemarcacao'
import { Dialog } from '@progress/kendo-react-dialogs'
import SessionStorageService from '../../services/SessionStorageService'
import useClearMap from '../../hooks/useClearMap'
import html2canvas from 'html2canvas'
import ReactGA from 'react-ga4';
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais'
type Props = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
}
type DemarcacaoForm = {
  id: number
  id_tipo_li: number
  id_rh: number
  data_registo: unknown
  obs: string
  designacao: string
  tecnico_id: number
  geom: unknown
  flatCoordinatesLinha: string
  filedoc_cod: string
}

const TOTAL_TABS = 3

export default function RegistarDemarcacaoForm({ onCloseClick }: Props) {
  const [isMobile, setIsMobile] = useState(false);
  const [selected, setSelected] = useState<number>(0)

  const selectedDemarcacaoResult: any = useDemarcacaoStore((state) => state.selectedDemarcacaoResult);
  const selectedDemarcacaoResultId: any = useDemarcacaoStore((state) => state.selectedDemarcacaoResultId);

  const selectDemarcacaoResult: any = useDemarcacaoStore((state) => state.selectDemarcacaoResult);
  const selectDemarcacaoResultId: any = useDemarcacaoStore((state) => state.selectDemarcacaoResultId);

  const getDemarcacao = async (data: any) => await get<any, any>(`${GET_DEMARCACAO}/` + data)

  const removerLinha = async (data: any) =>
    await post<any, any>(`${REMOVER_LINHA_DH_DEMARCACAO}`, data)

  const saveDemarcacao = async (data: any) => await post<any, any>(`${SAVE_NEW_DEAMARCACAO}`, data)
  const updateDemarcacao = async (data: any) => await put<any, any>(`${UPDATE_DEMARCACAO}`, data)

  const getUrlFileDoc = async (data: any) => await get<any>(`${GET_URL_FROM_FILE_DOC}/` + encodeURIComponent(data));

  const relatorioDemarcacao = async (data: any) =>
    await post<any, any>(`${RELATORIO_DEMARCACAO}`, data)

  const handleSelect = (e: TabStripSelectEventArguments) => {
    const demarcacaoSelected = getDemarcacaoSelected()

     if (e.selected > 0 && !demarcacaoSelected?.id) {
      postMessage({type:"infoMsg", value: {message: "É necessário gravar primeiro."}})
      return;
    }
    setSelected(e.selected)
  }

  const selectMapAction =  (item: any) => {
    const iframe: any = document.getElementById('iframeGeoPortal')

    if (item.key == "removerLinha") {
      setOpenRemoveLinhaModal(true)
    } else {
      iframe.contentWindow?.postMessage({ type: item.key, value: getDemarcacaoSelected()?.id, editGpLayer: DH_DEMARCACAO_POINT_NAME }, '*')
    }
  }

 const voltar = () => {
  const iframe: any = document.getElementById('iframeGeoPortal')
  iframe.contentWindow?.postMessage(
    {
      type: 'SiarlCoordenadaBoxClose',
      value: 'true',
    },
    '*'
  )
    clearMobilizacaoMap();
    postMessage({ type: 'form', value: 'pesquisarDemarcacao' }, '*');
   
  }

  const [clearMap] = useClearMap();
  const clearMobilizacaoMap = () => {
    clearMap(clearLayers["CLEAR_LAYERS_DEMARCACAO"]);
    setDemarcacaoSelected(undefined)
    setCoordenadasLinhaSelected(undefined )
    selectDemarcacaoResult(undefined)
  }

  useEffect(() => {
    sessionStorage.setItem("SubModulo", DEMARCACAO_MODULE_NAME);
    setProcessoSelected(undefined);
    setPatrimonioSelected(undefined);
  }, []);

    const [openModalDescartar, setOpenModalDescartar] = useState<any>()
  const [openRemoveLinhaModal, setOpenRemoveLinhaModal] = useState<any>()


  const cancelModalDescartar = () => {
    setOpenModalDescartar(false)
  }

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/RegistarDemarcacao" });
  }, []);

   const handleCancel = ()  => {
      postMessage({ type: "form", value: "" }, "*");
      setTimeout(() => {
          postMessage({ type: "form", value: "registarDemarcacao" }, "*");
        }, 10)
  }

     const handleOpenModalDescartar = () => {
    if 
    (
      sessionStorage.getItem('novaDemarcacao') &&
      sessionStorage.getItem('novaDemarcacao') == 'true'
    ) {
      setOpenModalDescartar(true);
    } else 
    {
      onEditItemClick("SiarlVisualizar");
      handleCancel();
    }  
  }

  const deleteDemarcacao = (id: number) => del(`${DELETE_DH_PATRIMONIO}/${id}`, {})

  const [estadoFalse, setEstado] = useState(false)
  const [estadoTrue, setEstadoTrue] = useState(true)
  const [deleteClicked, setDeleteClicked] = useState(false);


  const [canUpdate, setCanUpdate] = useState(false);
  const [canInsert, setCanInsert] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [viewMode, setViewMode] = useState(false);


   useEffect(() => {
    setViewMode(!editMode && sessionStorage.getItem("novaDemarcacao") != 'true');
  }, [])

  useEffect(() => {
    const demarcacaoSelected = getDemarcacaoSelected()
    console.log('ID PATRIMONIO : ' + demarcacaoSelected?.id)
  }, [])

  useEffect(() => {
    setCanUpdate(hasPermission(PERMISSION_ATUALIZAR_DEMARCACAO));
    setCanInsert(hasPermission(PERMISSION_ATUALIZAR_DEMARCACAO));
  }, [])

   const handleDescartar = async (e: any) => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
      {
        type: 'SiarlCoordenadaBoxClose',
        value: 'true',
      },
      '*'
    )
    iframe.contentWindow?.postMessage(
      {
        type: 'SiarlRemoveSelection',
        value: 'true',
      },
      '*'
    )
     setOpenModalDescartar(false)
     setDeleteClicked(false);
   const demarcacaoSelected = getDemarcacaoSelected()

    const dataToSend : any = {
      id: demarcacaoSelected?.id ?? undefined,
      status: estadoFalse
    }

      let DemarcacaoSaved: any
    if (demarcacaoSelected?.id) {
         postMessage({ type: 'showLoader' }, '*')
          DemarcacaoSaved = await updateDemarcacao(dataToSend)
          selectDemarcacaoResult({
            ...selectedDemarcacaoResult,
            ...DemarcacaoSaved,})
          postMessage({ type: 'hideLoader' }, '*')

          }

    SessionStorageService.removeItem("firstCallDemarcacao");
    postMessage({ type: 'form', value: 'pesquisarDemarcacao' }, '*')

}


  const refreshForm = async(id: any = undefined) => {
    let iDemarcacao = id;
    if (!iDemarcacao) {
      if (selectedDemarcacaoResult?.id) {
        iDemarcacao = selectedDemarcacaoResult?.id;
      } else {
        return;
      }
    }
    const demarcacao = await getDemarcacao(iDemarcacao);
    const tecnico = await GetUserNameTecnico(demarcacao.tecnico_id);

    if (!demarcacao) {
      postMessage({type: "infoMsg", value: { message: "Registo desativado" }}, "*");
      return;
    }
    const dataToSend : any = {
      ...demarcacao,
      tecnicoRegisto : tecnico,
      nuts1: demarcacao.nuts1 ?? undefined,
      nuts2: demarcacao.nuts2 ?? undefined,
      nuts3: demarcacao.nuts3 ?? undefined,
      distrito : demarcacao.distritos ?? undefined,
      concelho: demarcacao.mobilizacaos ?? undefined,
      freguesia: demarcacao.freguesias ?? undefined,
      arh: demarcacao.arhs ?? undefined,
      capitania : demarcacao.capitanias ?? undefined,
      massaagua: demarcacao.massasAgua ?? undefined,

    }
    const iframe: any = document.getElementById('iframeGeoPortal')
    selectDemarcacaoResult(dataToSend)
    selectDemarcacaoResult(demarcacao?.id)
    setDemarcacaoSelected(dataToSend);
  }

  const handleSubmit = async (e: any) => {

    if (!isFormValid) {
      return;
    }

    const demarcacaoSelected = getDemarcacaoSelected();
    const userId = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}')?.user?.id

    const demarcacaoRequest: DemarcacaoForm = {
      id: demarcacaoSelected?.id ?? undefined,
      id_tipo_li: e.values?.id_tipo_li ?? undefined,
      id_rh: e.values?.id_rh ?? undefined,
      data_registo: e.values?.dataHoraRegisto ?? undefined,
      obs: e.values?.obs ?? undefined,
      designacao: e.values?.designacao ?? undefined,
      tecnico_id: userId,
      geom: undefined,
      flatCoordinatesLinha: coordenadasLinhaSelected ?? undefined,
      filedoc_cod: e.values?.filedoc_cod ?? undefined
    }

    postMessage({ type: 'showLoader' }, '*');

    try {
      let DemarcacaoSaved: any;
      const demarcacaoSelected = getDemarcacaoSelected();

      if (selected != 1 && selected != 2) {
        if (demarcacaoSelected?.id) {

          DemarcacaoSaved = await updateDemarcacao(demarcacaoRequest);
          selectDemarcacaoResult(DemarcacaoSaved);
          selectDemarcacaoResult({
            ...selectedDemarcacaoResult,
            ...DemarcacaoSaved
          });
          setDemarcacaoSelected(DemarcacaoSaved);

        } else {

          DemarcacaoSaved = await saveDemarcacao(demarcacaoRequest);

          setDemarcacaoSelected(DemarcacaoSaved);
          selectDemarcacaoResult({
            ...selectedDemarcacaoResult,
            ...DemarcacaoSaved
          });

        }
      }
        refreshForm(DemarcacaoSaved?.id);

       setTimeout(() => {
          postMessage({ type: 'sucessMsg' }, '*')
        }, 500)
      

    } catch (error) {
      handleGenericError(error);
    }

    postMessage({ type: 'hideLoader' }, '*')
  }

  const removeLinha = async () => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: 'true' }, '*')
    if (!getDemarcacaoSelected()?.id) {
      setOpenRemoveLinhaModal(false)
      return;
    }
    await removerLinha(getDemarcacaoSelected()?.id);
    setCoordenadasLinhaSelected(undefined)

    iframe.contentWindow?.postMessage(
      { type: 'SiarlDemarcacaoSearch', value: [getDemarcacaoSelected()?.id] },
      '*'
    )
    refreshForm(getDemarcacaoSelected()?.id)
    setOpenRemoveLinhaModal(false)
  
  }

  const itemRender = (props: { item: any; itemIndex: number }) => {
    const isTitleItem = props.item.key === 'titulo';
    if (isTitleItem) {
      // Item de título personalizado
      return (
        <div className={styles.DropDownButtonTitulo}>
          <span>{props.item.text}</span>
        </div>
      );
    } else {
      // Demais itens
      return (
        <div className={styles.DropDownButtonItem}>
          <span>{props.item.text}</span>
        </div>
      );
    }
  };

  const onItemClick = (event: any) => {

    selectMapAction(event.item);

  };

  const [hasGeom, setHasGeom] = useState(false);
  
  useEffect(() => {
    if(getDemarcacaoSelected()?.id){
    getDemarcacao(getDemarcacaoSelected()?.id).then((result) => {
      if (result && result.hasGeom !== undefined) {
        setHasGeom(result.hasGeom);
      }
    });
  }
  });

  const optionsLinha = hasGeom
  ? [
      {
        key: 'SiarlEditarGeometria',
        text: 'Editar Linha',
      },
      {
        key: 'removerLinha',
        text: 'Remover Linha',
      },
    ]
  : [
      {
        key: 'SiarlGeoPortalAdicionarLinha',
        text: 'Adicionar Linha',
      },
    ];

  const onItemClickImprimir = async (event: any) => {

    let mapImage = ''

    if (!window.location.href.includes('localhost')) {
      const mapIframe = document.querySelector(
        '#iframeGeoPortal'
      ) as HTMLIFrameElement

      const mapIframeInnerContent =
        mapIframe.contentWindow?.document.querySelector(
          'div.ol-viewport'
        ) as HTMLElement;

      const mapBussula =  mapIframe.contentWindow?.document.querySelector(
        'button.fixedNorthArrowButton'
      ) as HTMLElement;

      mapIframeInnerContent.appendChild(mapBussula);
      
      const generatedImage = await html2canvas(mapIframeInnerContent);
      mapImage = generatedImage.toDataURL()
    }

    console.log(getDemarcacaoSelected());

    try {
      postMessage({ type: 'showLoader' }, '*')

      const documentoBase64 = await relatorioDemarcacao({
        id: getDemarcacaoSelected()?.id,
        dados: {
          //REGISTO
          'REGISTO' : '',
          'Nº Processo Apa:': getDemarcacaoSelected()?.filedoc_cod ?? '',
          'Técnico:': getDemarcacaoSelected()?.utilizador?.name ?? '',
          'Data Registo: ' : getDemarcacaoSelected()?.data_registo ? new Date(getDemarcacaoSelected()?.data_registo).toLocaleDateString() : '',
          'Designação:': getDemarcacaoSelected()?.designacao ?? '',

          //LOCALIZAÇÃO
          'LOCALIZAÇÃO' : '',
          'Nuts1:': getDemarcacaoSelected()?.nuts1[0]?.nome ?? '',
          'Nuts2:': getDemarcacaoSelected()?.nuts2[0]?.nome ?? '',
          'Nuts3:': getDemarcacaoSelected()?.nuts3[0]?.nome ?? '',
          'Distritos:': getDemarcacaoSelected()?.distritos[0]?.nome ?? '',
          'Concelhos:': getDemarcacaoSelected()?.concelhos[0]?.nome ?? '',
          'Freguesias:': getDemarcacaoSelected()?.freguesias[0]?.nome ?? '',

          //OBSERVAÇÕES
          'OBSERVAÇÕES' : '',
          'Observação:': getDemarcacaoSelected()?.obs ?? '',
        },        
        img_mapa: mapImage
      });

      if (documentoBase64) {
        const blob = b64toBlob(documentoBase64, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
        postMessage({ type: 'hideLoader' }, '*')
      }
    } catch (error) {
      postMessage({ type: 'errorMsg', value: { message: "Não foi possivel efetuar esta operação" } }, '*')
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }

  };

  const optionsImprimir = [
    {
      key: 'imprimirFichas',
      text: 'Fichas',
    },
    {
      key: 'imprimirResultados',
      text: 'Resultados',
    },
  ]

  const onItemClickExportar = async (event: any) => {
    let mapImage = ''

    if (!window.location.href.includes('localhost')) {
      const mapIframe = document.querySelector(
        '#iframeGeoPortal'
      ) as HTMLIFrameElement

      const mapIframeInnerContent =
        mapIframe.contentWindow?.document.querySelector(
          'div.ol-viewport'
        ) as HTMLElement;

      const mapBussula =  mapIframe.contentWindow?.document.querySelector(
        'button.fixedNorthArrowButton'
      ) as HTMLElement;

      mapIframeInnerContent.appendChild(mapBussula);
      
      const generatedImage = await html2canvas(mapIframeInnerContent);
      mapImage = generatedImage.toDataURL()
    }

    console.log(getDemarcacaoSelected());

    try {
      postMessage({ type: 'showLoader' }, '*')

      const documentoBase64 = await relatorioDemarcacao({
        id: getDemarcacaoSelected()?.id,
        dados: {
          //REGISTO
          'REGISTO' : '',
          'Nº Processo Apa:': getDemarcacaoSelected()?.filedoc_cod ?? '',
          'Técnico:': getDemarcacaoSelected()?.utilizador?.name ?? '',
          'Data Registo: ' : getDemarcacaoSelected()?.data_registo ? new Date(getDemarcacaoSelected()?.data_registo).toLocaleDateString() : '',
          'Designação:': getDemarcacaoSelected()?.designacao ?? '',

          //LOCALIZAÇÃO
          'LOCALIZAÇÃO' : '',
          'Nuts1:': getDemarcacaoSelected()?.nuts1[0]?.nome ?? '',
          'Nuts2:': getDemarcacaoSelected()?.nuts2[0]?.nome ?? '',
          'Nuts3:': getDemarcacaoSelected()?.nuts3[0]?.nome ?? '',
          'Distritos:': getDemarcacaoSelected()?.distritos[0]?.nome ?? '',
          'Concelhos:': getDemarcacaoSelected()?.concelhos[0]?.nome ?? '',
          'Freguesias:': getDemarcacaoSelected()?.freguesias[0]?.nome ?? '',

          //OBSERVAÇÕES
          'OBSERVAÇÕES' : '',
          'Observação:': getDemarcacaoSelected()?.obs ?? '',
        },        
        img_mapa: mapImage
      });

      if (documentoBase64) {
        const blob = b64toBlob(documentoBase64, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
    
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = 'reporte_demarcação.pdf'; 
        link.click();
        
        postMessage({ type: 'hideLoader' }, '*')
      }
    } catch (error) {
      postMessage({ type: 'errorMsg', value: { message: "Não foi possivel efetuar esta operação" } }, '*')
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }

 

  };

 const optionsEditar = [
    {
      key: 'SiarlEditar',
      text: 'Editar',
    },
    {
      key: 'SiarlApagar',
      text: 'Apagar',
    },
    
  ]
  const optionsExportar = [
    {
      key: 'exportarFichas',
      text: 'Fichas',
    },
    {
      key: 'exportarResultados',
      text: 'Resultados',
    },
  ]

    const onEditItemClick= (event:any) => {
    if (event == "SiarlEditar" || event?.item?.key == "SiarlEditar") {
      setEditMode(true);
      setViewMode(false);
      return;
    }
    if (event == "SiarlVisualizar") {
      setEditMode(false);
      setViewMode(true);
    }
    if (event?.item?.key == "SiarlApagar") {
      setDeleteClicked(true);
      setOpenModalDescartar(true);
    }
  }

   useEffect(() => {
    if (viewMode) {
      sessionStorage.removeItem("novaDemarcacao");
    }
  })


  const isFormValid =
    (selected === 0) ||
    (selected === 1) ||
    (selected === 2)

  const onFiledocClick = async() => {
    const demarcacaoSelected = getDemarcacaoSelected();
    if (!demarcacaoSelected?.filedoc_cod) {
      postMessage({ type: "infoMsg", value: {message: "É necessário preencher o nº processo APA (filedoc)."} }, "*");
      return;
    }
    postMessage({ type: 'showLoader' }, '*');
    const urlFileDoc = await getUrlFileDoc(demarcacaoSelected?.filedoc_cod);
    postMessage({ type: 'hideLoader' }, '*')
    window.open(urlFileDoc, '_blank');
    return;
  }

  useLayoutEffect(() => {

    const handleResize = () => {

      if (window.innerWidth <= telaMobile) {
        
        setIsMobile(true);
        ocultaFloatingMenu_e_Mapa();

      }else{

        setIsMobile(false);
        mostraFloatingMenu_e_Mapa();

      }

    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);  

  const onItemClickMais = (event: any) => {

      elementoClickMais(event);

      onEditItemClick(event);
      onItemClick(event);
      
      if(optionsExportar.some(option => option.key === event.item.key)){
        onItemClickExportar(event);
      }

  };

  const optionsMais = [
    {
      key: 'titulo',
      text: 'Registo',
    },
    ...optionsEditar.map(option => ({
      ...option,
      className: 'icon-siarl icon-editar btnIconRegistar',
    })),
    {
      key: 'titulo',
      text: 'Opções',
    },
    {
      key: 'openFileDoc',
      text: 'Diretório de documentos',
      className: 'icon-siarl icon-filedoc btnIconRegistar'
    },    
    {
      key: 'titulo',
      text: 'Linha',
    },
    ...optionsLinha.map(option => ({
      ...option,
      className: 'icon-siarl icon-linha btnIconRegistar',
    })),
    {
      key: 'titulo',
      text: 'Imprimir',
    },
    {
      key: 'imprimirFicha',
      text: 'Fichas',
      className: 'icon-siarl icon-imprimir btnIconRegistar'
    },
    {
      key: 'titulo',
      text: 'Exportar',
    },
    ...optionsExportar.map(option => ({
      ...option,
      className: 'icon-siarl icon-exportar btnIconRegistar',
    })),
  ]

  const openAjuda = () => {
    window.open(URL_AJUDA_DOMINIO_HIDRICO_DEMARCACAO, "_blank");
  }

  return (

    <div className={styles.base}>

      <div className={styles.header}>

        <div>
          <div className={styles.nomeDoModulo}>
            Domínio Hidríco
          </div>
          <div className={styles.tituloDaPagina}>
          {getDemarcacaoSelected() ? (editMode ? 'Editar demarcação' : 'Demarcação') : 'Nova demarcação'}
           {/* {!selectedDemarcacaoResult || !selectedDemarcacaoResult?.id ? 'Nova demarcação' : (editMode ? 'Editar demarcação' : 'Demarcação')} */}
          </div>
        </div>  
        <div>

          {!isMobile && canUpdate && (viewMode || editMode) && (sessionStorage.getItem("novaDemarcacao") != 'true') ? 
          <DropDownButton
            items={optionsEditar}
            className={styles.btnEditar2}
            iconClass="icon-siarl icon-editar"
            onItemClick={onEditItemClick}
            fillMode={'flat'}
            itemRender={itemRender}
            title="Editar"
          ></DropDownButton>
          : ''}

          <button className={styles.btnEditar2} title='Filedoc' onClick={onFiledocClick}
          style={{ display: isMobile ? 'none' : '' }} id='openFileDoc'>
            <span className="icon-siarl icon-filedoc"></span>
          </button>

          
          {!isMobile &&
          <DropDownButton items={optionsLinha} className={styles.btnEditar2}
            iconClass='icon-siarl icon-linha'
            disabled={viewMode}
            onItemClick={onItemClick}
            fillMode={"flat"}
            title='Linha'
            itemRender={itemRender}
          >
          </DropDownButton>
          }

          <button className={styles.btnEditar2} onClick={onItemClickImprimir}
          style={{ display: isMobile ? 'none' : '' }} id='imprimirFicha' title='Imprimir'>
            <span className="icon-siarl icon-imprimir"></span>
          </button>

          <button id='exportarFicha' className={styles.btnImprimir} onClick={onItemClickExportar}
          style={{ display: isMobile ? 'none' : '' }} title='Exportar'>
            <span className="icon-siarl icon-exportar"></span>
          </button>

          {isMobile &&
            <DropDownButton
              items={optionsMais}
              className={styles.btnMais}
              iconClass="icon-siarl icon-mais"
              onItemClick={onItemClickMais}
              fillMode={'flat'}
              itemRender={itemRenderMais}
              title="Mais Opções"
            />
          }

          <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
            <span className="icon-siarl icon-ajuda"></span>
          </button>

          <button className={styles.btnFechar} onClick={voltar} title="Fechar">
            <span className="icon-siarl icon-fechar"></span>
          </button>

        </div>

      </div>

      {openModalDescartar ? (
          <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={cancelModalDescartar}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                  <fieldset>
                    <span className="textDescartar">{deleteClicked ? 'Apagar' : 'Cancelar'} registo  </span>
                    <br></br>
                    <span>{deleteClicked ? 'Ao apagar este registo deixará de ter acesso, sendo necessária uma intervenção rigorosa para realizar a sua recuperação.' : 'Ao cancelar este registo irá perder os dados inseridos até ao momento.'}</span>
                      <br></br>
                    <span>{deleteClicked ? 'Tem a certeza que pretende apagar?' : 'Tem a certeza que pretende cancelar?'}</span>
                  </fieldset>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={cancelModalDescartar}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={handleDescartar}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}

{openRemoveLinhaModal ? (
               <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={() => setOpenRemoveLinhaModal(false)}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                    <span className="textDescartar">Remover linha </span>
                    <br></br>
                    <span>{'Tem a certeza que pretende remover a linha desenhada?'}</span>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={() => setOpenRemoveLinhaModal(false)}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={removeLinha}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}

      <Form
        initialValues={
          {
            ...getDemarcacaoSelected(),
            //dataHoraRegisto: new Date()} ?? undefined
            dataHoraRegisto: getDemarcacaoSelected()?.data_registo ? new Date(getDemarcacaoSelected()?.data_registo) : new Date(),
          }
        }
        onSubmitClick={handleSubmit}
        render={(formRenderProps) => (

          <FormElement>
            <TabStrip
              selected={selected}
              onSelect={handleSelect} 
              className={`${styles.innerStyleTabContent} ${styles.backgroundWhite} ${viewMode? 'tabsVisualizar' : ''}`}
              scrollable={isMobile}
            >
              <TabStripTab title="Identificação" key={"Identificação"}>
                <IdentificacaoDemarcacao onCloseClick={onCloseClick} viewMode={viewMode} formRenderProps={formRenderProps} />
              </TabStripTab>
              <TabStripTab title="Documentos" key={"Documentos"} >
                <DocumentoListagem  pageName='demarcacao' viewMode={viewMode}/>
              </TabStripTab>
              <TabStripTab title="Associação" key={"Associação"} > 
                <AssociacaoListagem pageName='demarcacao' viewMode={viewMode} />
              </TabStripTab>
            </TabStrip>

          {(editMode || sessionStorage.getItem("novaDemarcacao") == 'true')
            && !viewMode ?

                  <div
                    className={`${styles.footer} ${styles.justify_multiple_children}`}
                  >

                  <Button type="button" className={styles.btnSeguinte} onClick={handleOpenModalDescartar} title='Cancelar'>
                  <span className="icon-siarl icon-fechar"></span>
                  <span className={styles.rotulo}>
                    {' '}&nbsp;{' '}
                    Cancelar
                  </span>
                 </Button>    
                <Button
                      className={styles.btnSubmeter}
                      onClick={(e) => {
                      formRenderProps.onSubmit(e);
                      e.preventDefault();
                  }}
                  title='Gravar'
                >
                      <span className="icon-siarl icon-submeter btnIconRegistar"></span>
                      <span className={styles.rotulo}>
                        {' '}&nbsp;{' '}
                        Gravar
                      </span>
                    </Button>
                  </div>
            : ''}
          </FormElement>
        )}
      />
    </div>
  )
}