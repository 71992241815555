import { Button } from '@progress/kendo-react-buttons'
import { Field, Form } from '@progress/kendo-react-form'
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from '@progress/kendo-react-layout'
import { useState } from 'react'
import { requiredFieldValidator, validateUrl } from '../../../../validations/documento'
import ValidatedDocumentosUpload from '../../../ocorrencia/fields/ValidatedDocumentosUpload'
import ValidatedDocumentosInput from '../fields/ValidatedDocumentosInput'

type Props = {
  handleSubmitDocumento: (values: Record<string, any>) => Promise<void>
  handleSubmitExternalDocumento: (values: Record<string, any>) => Promise<void>
}

export default function EditDocumentoFormTabsSimplified({
  handleSubmitDocumento,
  handleSubmitExternalDocumento,
}: Props) {
  const [tabSelected, setTabSelected] = useState<number>(0)

  const handleTabSelect = (e: TabStripSelectEventArguments) => {
    setTabSelected(e.selected)
  }
  return (
    <div className="col-12 col-md-12 mb-12" style={{ marginTop: 16 }}>
      <TabStrip selected={tabSelected} onSelect={handleTabSelect}>
        <TabStripTab title="Documento digital">
          <Form
            onSubmitClick={async (event) => {
              await handleSubmitDocumento(event.values)
            }}
            render={(formRenderProps) => (
              <>
                <Field
                  name="arquivo"
                  component={ValidatedDocumentosUpload}
                  validator={requiredFieldValidator}
                />
                <Button
                  fillMode="solid"
                  themeColor="primary"
                  type="button"
                  onClick={formRenderProps.onSubmit}
                  style={{ width: '100%', marginTop: 24 }}
                >
                  Anexar
                </Button>
              </>
            )}
          />
        </TabStripTab>
        <TabStripTab title="Link externo">
          <Form
            onSubmitClick={async (event) => {
              await handleSubmitExternalDocumento(event.values)
            }}
            render={(formRenderProps) => (
              <>
                <Field
                  name="url"
                  label="URL *"
                  component={ValidatedDocumentosInput}
                  validator={validateUrl}
                />
                <Button
                  fillMode="solid"
                  themeColor="primary"
                  type="button"
                  onClick={formRenderProps.onSubmit}
                  style={{ width: '100%', marginTop: 24 }}
                >
                  Anexar
                </Button>
              </>
            )}
          />
        </TabStripTab>
      </TabStrip>
    </div>
  )
}
