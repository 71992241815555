import { Button, DropDownButton } from '@progress/kendo-react-buttons'
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout'
import {
  MouseEvent as ReactMouseEvent,
  useLayoutEffect,
  useState
} from 'react'
import { useUsoDoSoloStore } from '../../stores/usoDoSolo'
import styles from '../../styles/usodosolo/ConsultarUsoDoSoloTabsForm.module.scss'
import TituloForm from './TituloForm'
import UsoDeSoloForm from './ValorUsoDoSoloForm'
import { hasPermission } from '../../services/utils'
import { PERMISSION_USOSOLO_PERFIL_INTERMEDIO_EDICAO, PERMISSION_USOSOLO_PERFIL_COMPLETO_EDICAO, PERMISSION_USOSOLO_PERFIL_COMPLETO_LEITURA, PERMISSION_USOSOLO_PERFIL_TOTAL, URL_AJUDA_USOS_SOLO_TITULO } from '../../utils/constants'
import SessionStorageService from '../../services/SessionStorageService'
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais'

type IProps = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
}

export default function ConsultarUsoDoSoloTabsForm({ onCloseClick }: IProps) {
  const [isMobile, setIsMobile] = useState(false);
  const [selected, setSelected] = useState<number>(0)

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected)
  }
  const selectUsoDoSoloResultId = useUsoDoSoloStore(
    (state) => state.selectUsoDoSoloResultId
  )
  const selectUsoDoSoloResult = useUsoDoSoloStore(
    (state) => state.selectUsoDoSoloResult
  )

  const registarNovoClick = async () => {
    selectUsoDoSoloResultId(undefined);
    selectUsoDoSoloResult(undefined);
    SessionStorageService.setItem("novoUsTitulo", 'true');
    sessionStorage.setItem('usGeomEdited', 'false')
    postMessage({ type: "form", value: "registarUsoDoSolo" }, "*");

  }
  const options = [
    {
      key: 'SiarlGeoPortalAdicionarPonto',
      text: 'Selecionar Ponto',
      icon: <span className="icon-siarl icon-s"></span>

    },
    {
      key: 'SiarlGeoPortalRemoverPonto',
      text: 'Remover Ponto',
      icon: <span className="icon-siarl icon-ajuda"></span>

    },
    {
      key: 'SiarlGeoPortalAdicionarArea',
      text: 'Desenhar Área',
      icon: <span className="icon-siarl icon-ajuda"></span>

    },
  ]

  const selectMapAction = (item: any) => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: item.key, value: 'true' }, '*')
  }

  useLayoutEffect(() => {

    const handleResize = () => {

      if (window.innerWidth <= telaMobile) {
        
        setIsMobile(true);
        ocultaFloatingMenu_e_Mapa();

      }else{

        setIsMobile(false);
        mostraFloatingMenu_e_Mapa();

      }

    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  const onItemClickMais = (event: any) => {

    elementoClickMais(event);

  };

  const optionsMais = [
    {
      key: 'novoRegisto',
      text: 'Novo título',
      elementType: 'button',
      className: 'icon-siarl icon-registar btnIconRegistar'
    }
  ]

  const openAjuda = () => {
    window.open(URL_AJUDA_USOS_SOLO_TITULO, "_blank")
  }

  return (
    <div className={styles.base}>

      <div className={styles.header}>

        <div>
          <div className={styles.nomeDoModulo}>
            Uso de solo
          </div>
          <div className={styles.tituloDaPagina}>
            {selected>0?"Nova análise":"Critérios de pesquisa"}
          </div>
        </div>

        <div>
          {hasPermission(PERMISSION_USOSOLO_PERFIL_TOTAL) || hasPermission(PERMISSION_USOSOLO_PERFIL_COMPLETO_EDICAO) || hasPermission(PERMISSION_USOSOLO_PERFIL_INTERMEDIO_EDICAO)?
          <Button
            onClick={registarNovoClick}
            className={styles.btnNovoRegisto}
            hidden={selected>0}
            style={{ display: isMobile ? 'none' : '' }}
            id='novoRegisto'
            title='Novo título'
          >
            <span className="icon-siarl icon-registar btnIconRegistar"></span>{' '}
            &nbsp;{' '}
            Novo título
          </Button>
          : ''}

          {isMobile &&
            <DropDownButton
              items={optionsMais}
              className={styles.btnMais}
              iconClass="icon-siarl icon-mais"
              onItemClick={onItemClickMais}
              fillMode={'flat'}
              itemRender={itemRenderMais}
              title="Mais Opções"
            />
          }

          <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
            <span className="icon-siarl icon-ajuda"></span>
          </button>

          <button onClick={onCloseClick} className={styles.btnFechar} title="Minimizar">
            <span className="icon-siarl icon-minimizar"></span>
          </button>

        </div>

      </div>
      <TabStrip selected={selected} className={styles.backgroundWhite} onSelect={handleSelect}
      scrollable={isMobile}>
        <TabStripTab title="Títulos" key={"Títulos"}>
          <TituloForm onCloseClick={onCloseClick} />
        </TabStripTab>

        {hasPermission(PERMISSION_USOSOLO_PERFIL_TOTAL) || hasPermission(PERMISSION_USOSOLO_PERFIL_COMPLETO_EDICAO) || hasPermission(PERMISSION_USOSOLO_PERFIL_COMPLETO_LEITURA)?
          <TabStripTab title="Valores do uso de solo" key={"UsosDoSolo"}>
            <UsoDeSoloForm onCloseClick={onCloseClick} />
          </TabStripTab>
        :''}
      </TabStrip>

    </div>
  );
}