
import { Button } from '@progress/kendo-react-buttons'
import { DateTimePicker } from '@progress/kendo-react-dateinputs'
import { ComboBox } from '@progress/kendo-react-dropdowns'
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from '@progress/kendo-react-form'
import { Checkbox, Input } from '@progress/kendo-react-inputs'
import { MouseEvent as ReactMouseEvent, useEffect, useState } from 'react'
import SessionStorageService from '../../services/SessionStorageService'
import {
  concelhos,
  distritos,
  estadoCandidatura,
  fillCombosAuxiliares,
  fillEstadoCandidaturaAsync,
  fillInstrumentoFinanciamentoAsync,
  fillTipoIncidenciaAsync,
  freguesias,
  hasPermission,
  instrumentoFinanciamento,
  instrumentoPlaneamento,
  nuts1,
  nuts2,
  nuts3,
  post,
  tipoIncidencia,
  useCombos
} from '../../services/utils'
import styles from '../../styles/intervencao/Intervencao.module.scss'
import { CONSULTAR_CANDIDATURA, PERMISSION_INTERVENCAO_PERFIL_BASICO, PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO, PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA, PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO, PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_LEITURA, PERMISSION_INTERVENCAO_PERFIL_TOTAL } from '../../utils/constants'
import { filterBy } from '@progress/kendo-data-query'
import ReactGA from 'react-ga4';

const getCandidatura = async (data: any) => await post<any, any>(`${CONSULTAR_CANDIDATURA}`, data)

type Props = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
  onClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
}

export default function PesquisarCandidatura({ onClick, }: Props) {
  const [identificacaoVisible, setIdentificacaoVisible] = useState(true)
  const toggleIdentificacao = () =>
    setIdentificacaoVisible((previousState) => !previousState)

  const [criterioGeograficoVisible, setCriterioGeograficoVisible] =
    useState(false)
  const toggleCriterioGeografico = () => {
    setCriterioGeograficoVisible((previousState) => !previousState)
  }

  const [localizacaoVisible, setLocalizacaoVisible] = useState(false)
  const toggleLocalizacao = () => {
    setLocalizacaoVisible((previousState) => !previousState)
  }

  const [dataVisible, setDataVisible] = useState(false)
  const toggleData = () => {
    setDataVisible((previousState) => !previousState)
  }

  const [fonteFincaceiraVisible, setFonteFinanceiraVisible] = useState(false)
  const toggleFonteFinanceira = () => {
    setFonteFinanceiraVisible((previousState) => !previousState)
  }

  const [instrumentoFinanciamentoVisible, setInstrumentoFinanciamentoVisible] =
    useState(false)
  const toggleInstrumentoFinanciamento = () => {
    setInstrumentoFinanciamentoVisible((previousState) => !previousState)
  }

  const [investimentoVisible, setInvestimentoVisible] = useState(false)
  const toggleInvestimento = () => {
    setInvestimentoVisible((previousState) => !previousState)
  }

  const [estadoVisible, setEstadoVisible] = useState(false)
  const toggleEstado = () => {
    setEstadoVisible((previousState) => !previousState)
  }

  const [concelhosFiltred, setConcelhosFiltred] = useState<any>(undefined)
  const [concelhosDisabled, setConcelhosDisabled] = useState<any>(true)
  const [freguesiasFiltred, setFreguesiasFiltred] = useState<any>(undefined)
  const [freguesiasDisabled, setFreguesiasDisabled] = useState<any>(true)
  const [nuts2Filtered, setNuts2Filtered] = useState<any>(undefined)
  const [nuts2Disabled, setNuts2Disabled] = useState<any>(true)
  const [nuts3Filtered, setNuts3Filtered] = useState<any>(undefined)
  const [nuts3Disabled, setNuts3Disabled] = useState<any>(true)
  const [minData, setMinDate] = useState<any>();


  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/PesquisarIntCandidatura" });
  }, []);

 useEffect(() => {

     if(!estadoCandidatura)
     {
      fillEstadoCandidaturaAsync();
     }
     if(!instrumentoFinanciamento )
      {
      fillInstrumentoFinanciamentoAsync();
      }
      if(!tipoIncidencia){
        fillTipoIncidenciaAsync();
      }


  }, []);

  const filterConcelho = (e: any) => {
    if (e?.value?.codigo) {
      const concelhosLocal = concelhos.filter(
        (x: any) => x.distrito_cod == e.value.codigo
      )
      setConcelhosFiltred(concelhosLocal)
      setConcelhosDisabled(false)
    } else {
      setConcelhosFiltred(concelhos)
      setConcelhosDisabled(true)
    }
  }

  const filterFreguesia = (e: any) => {
    if (e?.value?.codigo) {
      const freguesiaLocal = freguesias.filter(
        (x: any) => x.concelho_cod == e.value.codigo
      )
      setFreguesiasFiltred(freguesiaLocal)
      setFreguesiasDisabled(false)
    } else {
      setFreguesiasDisabled(true)
      setFreguesiasFiltred(freguesias)
    }
  }

  const filterNuts2 = (e: any) => {
    if (e?.value?.codigo) {
      const nutsLocal = nuts2.filter((x: any) => x.nuts_i_cod == e.value.codigo)
      setNuts2Filtered(nutsLocal)
      setNuts2Disabled(false)
    } else {
      setNuts2Disabled(true)
      setNuts2Filtered(nuts2)
    }
  }

  const filterNuts3 = (e: any) => {
    if (e?.value?.codigo) {
      const nutsLocal = nuts3.filter(
        (x: any) => x.nuts_ii_cod == e.value.codigo
      )
      setNuts3Filtered(nutsLocal)
      setNuts3Disabled(false)
    } else {
      setNuts3Disabled(true)
      setNuts3Filtered(nuts3)
    }
  }
  const [dataRegistoDe, setDataRegistoDe] = useState('');
  const [dataSubmissaoDe, setDataSubmissaoDe] = useState('');


  const handleSubmit = async (e: any) => {

    postMessage({ type: 'showLoader' }, '*')

    const objToSearch = {
      id: e.values?.id ?? undefined,
      designacao: e.values?.designacao ?? undefined,
      cod_candid: e.values?.cod_candid ?? undefined,
      valor_candid: e.values?.valor_candid ?? undefined,
      valor_comp: e.values?.valor_comp ?? undefined,
      de_valor_total: e.values?.de_valor_total ?? undefined,
      ate_valor_total: e.values?.ate_valor_total ?? undefined,
      id_tipo_in: e.values?.id_tipo_in?.id ?? undefined,
      status: e.values?.status ?? undefined,
      data_registo: e.values?.data_registo ?? undefined,
      de_data_registo: e.values?.de_data_registo ?? undefined,
      ate_data_registo: e.values?.ate_data_registo ?? undefined,
      data_submissao: e.values?.data_submissao ?? undefined,
      de_data_submissao: e.values?.de_data_submissao ?? undefined,
      ate_data_submissao: e.values?.ate_data_submissao ?? undefined,
      instr_financiamento_id: e.values?.instr_financiamento_id?.id ?? undefined,
      estado_candidatura_id: e.values?.estado?.id ?? undefined
    }
          
    sessionStorage.setItem("lastSearchCandidatura", JSON.stringify(objToSearch));
    const result: any = await getCandidatura(objToSearch);

    if (result) {
      SessionStorageService.setItem('resultadoCandidaturaPesquisa', JSON.stringify(result))
      postMessage({ type: 'resultadoCandidaturaPesquisaChange', value: result }, '*')
    }
    SessionStorageService.setItem("firstCallCandidatura", true);

  }

   type combosKeys = 'instrumentoFinanciamento'
  |'estadoCandidatura' | 'tipoIncidencia';

  const [combos, setCombos] = useState<any>({
    estadoCandidatura, 
    instrumentoFinanciamento,
    tipoIncidencia
  })
  const setDataOption: Record<combosKeys, { setFn: any, data: any[] }> = {
    estadoCandidatura: {
      setFn: setCombos,
      data: estadoCandidatura
    },
    instrumentoFinanciamento: {
      setFn: setCombos,
      data: instrumentoFinanciamento
    },
    tipoIncidencia: {
      setFn: setCombos,
      data: tipoIncidencia
    },
  };

  const filterCombo_Multiselect = (e: any, tipoCombo: combosKeys) => {
    const { setFn, data } = setDataOption[tipoCombo];
    setFn({
        ...combos,
        [tipoCombo]: filterBy(data?.slice()??[], e.filter)
    });
}

const canSeeCandidatura = hasPermission(PERMISSION_INTERVENCAO_PERFIL_BASICO) ||
hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO) ||
hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA) ||
hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO) ||
hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_LEITURA) ||
hasPermission(PERMISSION_INTERVENCAO_PERFIL_TOTAL);


  return (
    <Form
      onSubmitClick={handleSubmit}
      render={(formRenderProps: FormRenderProps) => (
        <>
          <FormElement className={styles.form}>
            <fieldset>
              <legend>
                <button
                  type="button"
                  className={styles.itemExpandBase}
                  onClick={toggleIdentificacao}
                >
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span
                      className={
                        identificacaoVisible
                          ? 'icon-siarl icon-colapsar'
                          : 'icon-siarl icon-expandir'
                      }
                    />
                  </div>
                  <span className={styles.fieldsetTitulo}>Identificação</span>
                </button>
              </legend>

              {identificacaoVisible && (
                <div className={styles.espacoVerticalFieldSet}>
                  <div className={styles.fields}>
                    <div>
                      <Field name="id" label="ID (Código)" component={Input} />
                    </div>
                    <div>
                      <Field
                        name="cod_candid"
                        label="N.º de candidatura"
                        component={Input}
                      />
                    </div>

                  </div>
                  <div>
                    <Field
                      name="designacao"
                      label="Designação"
                      component={Input}
                    />
                  </div>
                </div>
              )}
            </fieldset>
            <fieldset>
              <legend>
                <button
                  type="button"
                  className={styles.itemExpandBase}
                  onClick={toggleData}
                >
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span
                      className={
                        dataVisible
                          ? 'icon-siarl icon-colapsar'
                          : 'icon-siarl icon-expandir'
                      }
                    />
                  </div>
                  <span className={styles.fieldsetTitulo}>
                    Intervalo de datas
                  </span>
                </button>
              </legend>
              {dataVisible && (
                <div className={styles.espacoVerticalFieldSet}>
                  <legend className={styles.fieldsetSubTitulo}>
                    Data de registo
                  </legend>
                  <div className={styles.fields}>
                    <div>
                      <Field
                        name="de_data_registo"
                        label="De"
                        component={DateTimePicker}
                        format="dd/MM/yyyy HH:mm"
                        onChange={(e) => {
                          setDataRegistoDe(e.value)
                           setMinDate(e.value);
                        }}                        />
                    </div>
                    <div>
                      <Field
                        name="ate_data_registo"
                        label="Até"
                        component={DateTimePicker}
                        format="dd/MM/yyyy HH:mm"
                        min={minData}
                      />
                    </div>
                  </div>
                  <legend className={styles.fieldsetSubTitulo}>
                    Data de submissão
                  </legend>
                  <div className={styles.fields}>
                    <div>
                      <Field
                        name="de_data_submissao"
                        label="De"
                        component={DateTimePicker}
                        format="dd/MM/yyyy HH:mm"
                        onChange={(e) => {
                          setDataSubmissaoDe(e.value)
                           setMinDate(e.value);
                        }}                        />
                    </div>
                    <div>
                      <Field
                        name="ate_data_submissao"
                        label="Até"
                        component={DateTimePicker}
                        format="dd/MM/yyyy HH:mm"
                        min={minData}
                      />
                    </div>
                  </div>
                </div>
              )}
            </fieldset>
            <fieldset>
              <legend>
                <button
                  type="button"
                  className={styles.itemExpandBase}
                  onClick={toggleFonteFinanceira}
                >
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span
                      className={
                        fonteFincaceiraVisible
                          ? 'icon-siarl icon-colapsar'
                          : 'icon-siarl icon-expandir'
                      }
                    />
                  </div>
                  <span className={styles.fieldsetTitulo}>
                    Tipologia
                  </span>
                </button>
              </legend>
              {fonteFincaceiraVisible && (
                <div className={styles.espacoVerticalFieldSet}>
                  <div className={styles.fields}>
                  <Field
                    name="id_tipo_in"
                    label="Tipo de Incidência"
                    component={ComboBox}
                    textField={'descricao'}
                    filterable={true}
                    data={combos?.tipoIncidencia}            
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'tipoIncidencia')}}        
                  />
                  </div>
                </div>
              )}
            </fieldset>
            <fieldset>
              <legend>
                <button
                  type="button"
                  className={styles.itemExpandBase}
                  onClick={toggleInstrumentoFinanciamento}
                >
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span
                      className={
                        instrumentoFinanciamentoVisible
                          ? 'icon-siarl icon-colapsar'
                          : 'icon-siarl icon-expandir'
                      }
                    />
                  </div>
                  <span className={styles.fieldsetTitulo}>
                    Instrumento de financiamento
                  </span>
                </button>
              </legend>
              {instrumentoFinanciamentoVisible && (
                <div className={styles.espacoVerticalFieldSet}>
                  <div className={styles.fields}>
                    <div>
                      <Field
                        name="instr_financiamento_id"
                        label="Instrumento"
                        component={ComboBox}
                        textField={'designacao'}
                        filterable={true}
                        onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'instrumentoFinanciamento')}}
                        data={combos?.instrumentoFinanciamento}

                      />
                    </div>
                  </div>
                </div>
              )}
            </fieldset>
            <fieldset>
              <legend>
                <button
                  type="button"
                  className={styles.itemExpandBase}
                  onClick={toggleInvestimento}
                >
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span
                      className={
                        investimentoVisible
                          ? 'icon-siarl icon-colapsar'
                          : 'icon-siarl icon-expandir'
                      }
                    />
                  </div>
                  <span className={styles.fieldsetTitulo}>Investimento</span>
                </button>
              </legend>
              {investimentoVisible && (
                <div className={styles.espacoVerticalFieldSet}>
                  <legend className={styles.fieldsetSubTitulo}>Investimento previsto total</legend>
                  <div className={styles.fields}>
                    <div>
                      <Field name="de_valor_total" label="De" component={Input} />
                    </div>
                    <div>
                      <Field name="ate_valor_total" label="Até" component={Input} />
                    </div>
                  </div>
                </div>
              )}
            </fieldset>
            <fieldset>
              <legend>
                <button
                  type="button"
                  className={styles.itemExpandBase}
                  onClick={toggleEstado}
                >
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span
                      className={
                        estadoVisible
                          ? 'icon-siarl icon-colapsar'
                          : 'icon-siarl icon-expandir'
                      }
                    />
                  </div>
                  <span className={styles.fieldsetTitulo}>Estado</span>
                </button>
              </legend>
              {estadoVisible && (
                <div
                  className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}
                >
                  <div>
                    <Field
                      name="estado"
                      label="Estado"
                      component={ComboBox}
                      textField={'descricao'}
                      data={combos?.estadoCandidatura}
                      filterable={true}
                      onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'estadoCandidatura')}}
                    />
                  </div>
                </div>
              )}
            </fieldset>
          </FormElement>

          <div
            className={`${styles.footer} ${styles.justify_multiple_children}`}
          >
            <Button
              onClick={formRenderProps.onFormReset}
              className={styles.btnLimparPesquisa}
              title='Limpar pesquisa'
            >
              <span className="icon-siarl icon-limparcampos"></span>
              <span className={styles.rotulo}>
                {' '}&nbsp;{' '}
                Limpar pesquisa
              </span>              
            </Button>

            <Button
              onClick={formRenderProps.onSubmit}
              className={styles.btnPesquisar}
              title='Pesquisar'
            >
              <span className="icon-siarl icon-pesquisar"></span>
              <span className={styles.rotulo}>
                {' '}&nbsp;{' '}
                Pesquisar
              </span>      
            </Button>
          </div>
        </>
      )}
    />
  )
}
