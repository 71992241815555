import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs'
import { Form, FormElement, Field } from '@progress/kendo-react-form'
import { Input, TextArea } from '@progress/kendo-react-inputs'
import { ComboBox } from '@progress/kendo-react-dropdowns'
import { Button } from '@progress/kendo-react-buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCopy,
  faLink,
  faPenToSquare,
} from '@fortawesome/free-solid-svg-icons'
import { v4 as uuid } from 'uuid';

import styles from '../../styles/viewer/ShareModal.module.scss'
import clsx from 'clsx'
import {
  getCurrentMapId,
  post,
  setSharedMap,
  get,
  put,
  allCombosString,
  isJsonString,
  hasPermission,
} from '../../services/utils'
import {
  AUTH_LOCALSTORAGE,
  EDIT_SHARED_MAP,
  GET_ALL_GV_SUB_TEMAS,
  GET_ALL_GV_TEMAS,
  GET_GV_ESTADO,
  PERMISSION_GEOVISUALIZADOR_PERFIL_COMPLETO_EDICAO,
  SAVE_GV_SESSION_STATE,
  SAVE_SHARED_MAP,
} from '../../utils/constants'
import SessionStorageService from '../../services/SessionStorageService'
import { useEffect, useState } from 'react'
import ValidatedShareModalCombobox from './fields/ValidatedShareModalCombobox'
import ValidatedShareModalInput from './fields/ValidatedShareModalInput'
import { useGeovisualizadoresStore } from '../../stores/geovisualizador'
import { requiredFieldValidator } from '../../validations/ocorrencia'
import html2canvas from 'html2canvas'

type Props = {
  toggleDialog: () => void
}

type GetAllTemasResult = { id: number; descricao: string }

type GetAllSubTemasResult = {
  id: number
  id_tema: number
  tema: { id: number; descricao: string }
  id_sub_tema: number
  sub_tema: { id: number; descricao: string }
}

type GetEstadoResult = { id: number; descricao: string }

export default function ShareModal({ toggleDialog }: Props) {
  const [temas, setTemas] = useState<GetAllTemasResult[]>([])
  const [subTemas, setSubTemas] = useState<GetAllSubTemasResult[]>([])
  const [selectedTema, setSelectedTema] = useState<number | null>(null)
  const [estados, setEstados] = useState<GetEstadoResult[]>([])
  const [generatedLink, setGeneratedLink] = useState('')
  const [showGeneratedLink, setShowGeneratedLink] = useState(false)
  const [copying, setCopyng] = useState(false)

  const shareModalIsValid = useGeovisualizadoresStore(
    (state) => state.shareModalIsValid
  )
  const selectedGVResult = useGeovisualizadoresStore(
    (state) => state.selectedGVResult
  )
  const selectedGVResultId = useGeovisualizadoresStore(
    (state) => state.selectedGVResultId
  )
  const isEditMode = useGeovisualizadoresStore(
    (state) => !!state.selectedGVResultId
  )
  const setShouldRefetchList = useGeovisualizadoresStore(
    (state) => state.setShouldRefetchList
  )

  const saveMapaPartilhado = async (data: any) =>
    await post<any, any>(`${SAVE_SHARED_MAP}`, data)

  const editMapaPartilhado = async (data: any) =>
    await put<any, any>(`${EDIT_SHARED_MAP}`, data)

  const saveSessionState = async (data: any) =>
    await post<any, any>(`${SAVE_GV_SESSION_STATE}`, data)

  const handleSubmit = async (e: any) => {
    if (!shareModalIsValid || copying) {
      return false
    }

    const filteredSessionStorage = Object.fromEntries(
      Object.entries(SessionStorageService.getItens()).filter(([key]) => !allCombosString.includes(key))
    )

    try {
      postMessage({ type: 'showLoader' }, '*')

      const mapid = getCurrentMapId() ?? uuid()
      const mapUrl = window.location.href.replace('/#', '') + '?mapid=' + mapid

      const userLocal = JSON.parse(
        localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}'
      )

      let mapImage = ''

      if (!window.location.href.includes('localhost')) {
        const mapIframe = document.querySelector(
          '#iframeGeoPortal'
        ) as HTMLIFrameElement
  
        const mapIframeInnerContent =
          mapIframe.contentWindow?.document.querySelector(
            'section.content'
          ) as HTMLElement
  
        const generatedImage = await html2canvas(mapIframeInnerContent)
        mapImage = generatedImage.toDataURL()
      }

      const sharedMap = {
        titulo: e.values.titulo,
        descricao: e.values?.descricao ?? undefined,
        link_gerado: mapUrl,
        uid_mapa: mapid,
        data_registo: new Date(),
        img_mapa: mapImage,
        id_gv_estado: e.values?.estado?.id ?? undefined,
        id_gv_tema: e.values.tema.id,
        id_gv_sub_tema: e.values.subtema.id,
        id_utilizador: userLocal?.user?.id,
      }

      Object.entries(filteredSessionStorage).forEach(async ([key, value]) => {
        const sessionState = {
          uid_mapa: mapid,
          key: key,
          user_id: userLocal?.user?.id,
          value: isJsonString(value as string) ? value : JSON.stringify(value),
          data_registo: new Date(),
        }

        await saveSessionState(sessionState)
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage({ type: "SiarlShareMapSave", value: mapid }, '*')
      })

      if (isEditMode) {
        const editMapaPartilhadoResult = await editMapaPartilhado({
          ...sharedMap,
          id: selectedGVResultId,
        })
        setGeneratedLink(mapUrl)
        postMessage({ type: 'hideLoader' }, '*')

        if (editMapaPartilhadoResult?.id) {
          setSharedMap(sharedMap)
          setShowGeneratedLink(true)
          setShouldRefetchList(true)
        }
      } else {
        const saveMapaPartilhadoResult = await saveMapaPartilhado(sharedMap)
        setGeneratedLink(mapUrl)
        postMessage({ type: 'hideLoader' }, '*')

        if (saveMapaPartilhadoResult?.id) {
          setSharedMap(sharedMap)
          setShowGeneratedLink(true)
        }
      }
    } catch (error) {
      postMessage(
        {
          type: 'errorMsg',
          value: { message: 'Não foi possivel efetuar esta operação' },
        },
        '*'
      )
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  const copyToClipboard = async (textToCopy: any) => {
    // Navigator clipboard api needs a secure context (https)
    setCopyng(true)
    if (navigator.clipboard && window.isSecureContext) {
      await navigator.clipboard.writeText(textToCopy)
    } else {
      //hack to avoid http issue
      // Use the 'out of viewport hidden text area' trick
      const textArea = document.createElement('textarea')
      textArea.value = textToCopy

      // Move textarea out of the viewport so it's not visible
      textArea.style.position = 'absolute'
      textArea.style.left = '-999999px'

      document.body.prepend(textArea)
      textArea.select()

      try {
        document.execCommand('copy')
      } catch (error) {
        console.error(error)
      } finally {
        textArea.remove()
      }
    }
  }

  const submitClicked = async (formRenderProps: any) => {
    setCopyng(false)
  }

  useEffect(() => {
    const getAllData = async () => {
      const allTemasResult = await get<GetAllTemasResult[]>(GET_ALL_GV_TEMAS)
      if (allTemasResult) setTemas(allTemasResult)

      const allSubTemasResult = await get<GetAllSubTemasResult[]>(
        GET_ALL_GV_SUB_TEMAS
      )
      if (allSubTemasResult) setSubTemas(allSubTemasResult)

      const estadosResult = await get<GetEstadoResult[]>(GET_GV_ESTADO)
      if (estadosResult) setEstados(estadosResult)
    }

    getAllData()
  }, [])

  return (
    <Dialog
      className="dialog-center"
      title={isEditMode ? 'Editar mapa partilhado' : 'Partilhar mapa'}
      onClose={toggleDialog}
    >
      <Form
        initialValues={
          selectedGVResult
            ? {
                titulo: selectedGVResult.titulo,
                tema: selectedGVResult.tema,
                subtema: selectedGVResult.sub_tema,
                estado: selectedGVResult.gvMapaPartilhadoEstado,
                descricao: selectedGVResult.descricao,
              }
            : undefined
        }
        onSubmitClick={handleSubmit}
        render={(formRenderProps) => (
          <FormElement>
            <Field
              name="titulo"
              label="Título *"
              component={ValidatedShareModalInput}
              validator={requiredFieldValidator}
            />
            <div className={styles.fields}>
              <div>
                <Field
                  name="tema"
                  label="Tema *"
                  component={ValidatedShareModalCombobox}
                  validator={requiredFieldValidator}
                  textField="descricao"
                  dataItemKey="id"
                  data={temas}
                  onChange={(event) => setSelectedTema(event.target?.value?.id)}
                />
              </div>
              <div className={styles.leftSpacer}>
                <Field
                  name="subtema"
                  label="Subtema *"
                  component={ValidatedShareModalCombobox}
                  validator={requiredFieldValidator}
                  textField="descricao"
                  dataItemKey="id"
                  data={subTemas
                    .filter((subTema) => subTema.id_tema === selectedTema)
                    .map((subTema) => subTema.sub_tema)}
                />
              </div>
            </div>
            <div className={styles.bottomSpacer}>
              <Field
                name="estado"
                label="Estado"
                component={ComboBox}
                textField="descricao"
                dataItemKey="id"
                disabled={!hasPermission(PERMISSION_GEOVISUALIZADOR_PERFIL_COMPLETO_EDICAO)}
                data={estados}
              />
            </div>
            <Field
              name="descricao"
              className={styles.textarea}
              component={TextArea}
              label="Descrição"
            />
            {showGeneratedLink && (
              <div className={styles.generateMapSection}>
                <div className={styles.generateMapSectionHeader}>
                  <span className={styles.generateMapSectionHeaderText}>
                    Link gerado
                  </span>
                  <Button
                    type="button"
                    fillMode="flat"
                    className={styles.generateMapSectionHeaderIconButton}
                    onClick={async (e) => await copyToClipboard(generatedLink)}
                  >
                    <FontAwesomeIcon
                      icon={faCopy}
                      className={styles.generateMapSectionHeaderIcon}
                    />
                  </Button>
                </div>
                <div className={styles.generateMapSectionBody}>
                  <span className={styles.generateMapSectionLink}>
                    {generatedLink}
                  </span>
                </div>
              </div>
            )}
            <DialogActionsBar layout="end">
              <Button onClick={toggleDialog} className={styles.cancelButton}>
                Cancelar
              </Button>
              {isEditMode ? (
                <Button
                  onClick={formRenderProps.onSubmit}
                  className={clsx(styles.leftSpacer, styles.buttonWithIcon)}
                  themeColor="primary"
                >
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    className={styles.btnIconObterLink}
                  />
                  Guardar
                </Button>
              ) : (
                <Button
                  onClick={() => submitClicked(formRenderProps)}
                  className={clsx(styles.leftSpacer, styles.buttonWithIcon)}
                  themeColor="primary"
                >
                  <FontAwesomeIcon
                    icon={faLink}
                    className={styles.btnIconObterLink}
                  />
                  Obter link
                </Button>
              )}
            </DialogActionsBar>
          </FormElement>
        )}
      />
    </Dialog>
  )
}
