import { useEffect, useRef, useState } from 'react'
import styles from '../../styles/geovisualizadores/Geovisualizadores.module.scss'
import { get } from '../../services/utils'
import { GV_NAMESPACE } from '../../utils/constants'

type Props = {
  dataItem: Record<string, unknown>
}

export default function ImagemMapaCell({ dataItem }: Props) {
  const [image, setImage] = useState('')
  const [altText, setAltText] = useState('')
  const mounted = useRef(false)

  useEffect(() => {
    const getGvById = async () => {
      const result = await get<{ img_mapa: string; titulo: string }>(
        `${GV_NAMESPACE}/${dataItem.id}`
      )
      if (result?.img_mapa) setImage(result.img_mapa)
      if (result?.titulo) setAltText(result.titulo)
    }

    if (!mounted.current) {
      mounted.current = true
      getGvById()
    }
  }, [])

  return (
    <td className="k-command-cell">
      <div className={styles.imagemCell}>
        {image && <img src={image} alt={altText} className={styles.imagem} />}
      </div>
    </td>
  )
}
