import {
  MouseEvent as ReactMouseEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import styles from '../../styles/geovisualizadores/Geovisualizadores.module.scss'
import commonStyles from '../../styles/common/Common.module.scss'
import { get, hasPermission } from '../../services/utils'
import { AUTH_LOCALSTORAGE, CONSULTAR_GV_MAP, GUEST_USER_NAME, PERMISSION_GEOVISUALIZADOR_PERFIL_BASICO, PERMISSION_GEOVISUALIZADOR_PERFIL_COMPLETO_EDICAO, PERMISSION_GEOVISUALIZADOR_PERFIL_COMPLETO_LEITURA, PERMISSION_GEOVISUALIZADOR_PERFIL_INTERMEDIO_EDICAO, PERMISSION_GEOVISUALIZADOR_PERFIL_INTERMEDIO_LEITURA, PERMISSION_GEOVISUALIZADOR_PERFIL_TOTAL, URL_AJUDA_GEOVISUALIZADORES } from '../../utils/constants'
import CollapsableMapaItem from './CollapsableMapaItem'
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from '@progress/kendo-react-layout'
import { useGeovisualizadoresStore } from '../../stores/geovisualizador'
import MaximizeButton from '../common/MaximizeButton'
import clsx from 'clsx'
import { useCommonStore } from '../../stores/common'
import { mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais'

type Props = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
}

export type ConsultarMapasResult = {
  quantidade: number
  tema: string
  gvSubTemas: {
    descricao: string | null
    gvMapaPartilhados: {
      id: number
      id_utilizador: number | null
      titulo: string
      img_mapa: string | null
      link_gerado: string | null
      data_registo: string
    }[]
  }[]
}[]

export default function ListarGeovisualizadores({ onCloseClick }: Props) {
  const [isMobile, setIsMobile] = useState(false);
  const [selected, setSelected] = useState(0)
  const [consultarMapasResult, setConsultarMapasResult] =
    useState<ConsultarMapasResult>([])
  const [userMaps, setUserMaps] = useState<ConsultarMapasResult>();
  const [publicMaps, setpublicMaps] = useState<ConsultarMapasResult>();

  const isCurrentViewMaximized = useCommonStore(
    (state) => state.isCurrentViewMaximized
  )

  const shouldRefetchList = useGeovisualizadoresStore(
    (state) => state.shouldRefetchList
  )
  const setShouldRefetchList = useGeovisualizadoresStore(
    (state) => state.setShouldRefetchList
  )

  const loggedUser = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) || '{}');

  const mounted = useRef(false)

  useEffect(() => {
    const consultarMapas = async () => {
      postMessage({ type: 'showLoader' }, '*')
      const userId = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '')?.user?.id
      const result = await get<ConsultarMapasResult>(CONSULTAR_GV_MAP + "/" + userId)
      const resultPublic = await get<ConsultarMapasResult>(CONSULTAR_GV_MAP)
      postMessage({ type: 'hideLoader' }, '*')
      if (result) setUserMaps(result)
      if (resultPublic) setpublicMaps(resultPublic)
    }

    if (!mounted.current) {
      mounted.current = true
      consultarMapas()
    }
  }, [])

  useEffect(() => {
    const consultarMapas = async () => {
      setConsultarMapasResult([])
      postMessage({ type: 'showLoader' }, '*')
      const userId = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '')?.user?.id
      const result = await get<ConsultarMapasResult>(CONSULTAR_GV_MAP + "/" + userId)
      const resultPublic = await get<ConsultarMapasResult>(CONSULTAR_GV_MAP)
      postMessage({ type: 'hideLoader' }, '*')
      if (result) setUserMaps(result)
      if (resultPublic) setpublicMaps(resultPublic)
    }

    if (shouldRefetchList) {
      consultarMapas()
      setShouldRefetchList(false)
      postMessage({ type: "infoMsg", value: {message: "Removido com sucesso."} }, "*");
      postMessage({ type: "form", value: "" }, "*");
        setTimeout(() => {
            postMessage({ type: "form", value: "listarGeovisualizadores" }, "*");
          }, 10)
    }
  }, [shouldRefetchList])

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected)
  }

  useLayoutEffect(() => {

    const handleResize = () => {

      if (window.innerWidth <= telaMobile) {
        
        setIsMobile(true);
        ocultaFloatingMenu_e_Mapa();

      }else{

        setIsMobile(false);
        mostraFloatingMenu_e_Mapa();

      }

    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);  

  const canSeeTema = hasPermission(PERMISSION_GEOVISUALIZADOR_PERFIL_BASICO) ||
  hasPermission(PERMISSION_GEOVISUALIZADOR_PERFIL_COMPLETO_EDICAO) ||
  hasPermission(PERMISSION_GEOVISUALIZADOR_PERFIL_COMPLETO_LEITURA) ||
  hasPermission(PERMISSION_GEOVISUALIZADOR_PERFIL_INTERMEDIO_EDICAO) ||
  hasPermission(PERMISSION_GEOVISUALIZADOR_PERFIL_INTERMEDIO_LEITURA) ||
  hasPermission(PERMISSION_GEOVISUALIZADOR_PERFIL_TOTAL);

  const temasDesejados = [
    'RH Norte',
    'RH Centro ',
    'RH Tejo e Oeste',
    'RH Alentejo',
    'RH Algarve ',
    'Erosão Costeira',
  ];

  const openAjuda = () => {
    window.open(URL_AJUDA_GEOVISUALIZADORES, "_blank")
  }

  return (
    <div
      className={clsx(
        styles.base,
        isCurrentViewMaximized && commonStyles.maximizedBaseForm
      )}
    >
      <div className={styles.header}>
        <div>
          <div className={styles.tituloDaPagina}>Geovisualizadores</div>
        </div>

        <div>
          <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
            <span className="icon-siarl icon-ajuda" />
          </button>

          <button className={styles.btnFechar} onClick={onCloseClick} title="Minimizar">
            <span className="icon-siarl icon-minimizar" />
          </button>

          {!isMobile &&
          <MaximizeButton className={styles.btnMaximizar} />
        }
        </div>
      </div>
      <TabStrip
        selected={selected}
        onSelect={handleSelect}
        className={styles.geoTabStrip}
        scrollable={isMobile}
      >
        <TabStripTab title="Plataforma" key="plaforma">
          <div className={styles.list}>
            {publicMaps?.map((mapa) => {

              const temaEncontrado = temasDesejados.includes(mapa.tema);

              if (temaEncontrado && canSeeTema) {
                return (
                  <CollapsableMapaItem item={mapa} variant="platform" />
                );
              }

              return null;

            })}
          </div>
        </TabStripTab>
        {loggedUser?.user?.username != GUEST_USER_NAME ? 
        <TabStripTab title="Utilizador" key="utilizador">
          <div className={styles.list}>
            {userMaps?.map((mapa) => {

              const temaEncontrado = temasDesejados.includes(mapa.tema);

              if (temaEncontrado && canSeeTema) {
                return (
                  <CollapsableMapaItem item={mapa} variant="user" />
                );
              }

              return null;

            })}
          </div>
        </TabStripTab>
        : ''}
      </TabStrip>
    </div>
  )
}
