import { Button, DropDownButton } from '@progress/kendo-react-buttons'
import { PagerTargetEvent } from '@progress/kendo-react-data-tools'
import { format } from 'date-fns'
import { CSVLink } from 'react-csv'
import { GetUserNameTecnico, b64toBlob, clearDemarcacao, exportPDFWithMethod, get, handleDownload, handleGenericError, hasPermission, post, setDemarcacaoSelected } from '../../services/utils'
import {
    AUTH_LOCALSTORAGE,
    CURRENT_TIMEZONE,
    DEMARCACAO_MODULE_NAME,
    GET_DEMARCACAO,
    GET_DH_DEMARCACAO_SHAPEFILE,
    GET_DOCUMENTO,
    GET_DOCUMENTOS_BY_DEMARCACOES,
    PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_EDICAO,
    PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_LEITURA,
    PERMISSION_DOMINIO_HIDRICO_PERFIL_TOTAL,
    PESQUISAR_DEMARCACAO,
    URL_AJUDA_DOMINIO_HIDRICO_DEMARCACAO,
    clearLayers
} from '../../utils/constants'
import { ResultadoDemarcacaoCommandGridCell } from './ResultadoDemarcacaoCommandGridCell'


import styles from '../../styles/ocorrencia/ConsultarOcorrenciaForm.module.scss'

import {
    Dispatch,
    MouseEvent as ReactMouseEvent,
    SetStateAction,
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react'

import {
    Grid,
    GridCellProps,
    GridColumn,
    GridPageChangeEvent,
} from '@progress/kendo-react-grid'

import {
    TabStrip,
    TabStripSelectEventArguments,
    TabStripTab,
} from '@progress/kendo-react-layout'
import SessionStorageService from '../../services/SessionStorageService'
import { useDhDemarcacaoStore } from '../../stores/dhDemarcacao'
import MaximizeButton from '../common/MaximizeButton'
import clsx from 'clsx'
import commonStyles from '../../styles/common/Common.module.scss'
import { useCommonStore } from '../../stores/common'
import useClearMap from '../../hooks/useClearMap'
import VisualizadorColunas from '../Generico/VisualizadorColunas '
import useVisibleColumns, { Key } from '../../hooks/useVIsibleColumns'
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais'
import ReactGA from 'react-ga4';
import React from 'react'
import { process } from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { GridPDFExport } from '@progress/kendo-react-pdf'
import { ResultadoDocumentoCommandGridCell } from '../documentos/ResultadoDocumentoCommandGridCell'
import { Dialog } from '@progress/kendo-react-dialogs'
import ImagePreviewV2 from '../viewer/ImagePreviewV2'
import moment from 'moment'
import "moment-timezone"

type Props = {
    onCloseClick: (
        e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
    ) => void | undefined
    setShowResults: Dispatch<SetStateAction<boolean>>
    setShowRegistarDemarcacaoForm?: Dispatch<SetStateAction<boolean>>
    
}

type PageState = {
    skip: number
    take: number
}

const initialDataState: PageState = { skip: 0, take: 20 }

const keyPainelPrincipal:Key = 'demarcacaoColunasVisiveis';
const keyPainelDocumentos:Key = 'demarcacaoDocumentosColunasVisiveis';

export default function ResultadoDemarcacaoForm({
    onCloseClick,
    setShowResults,
    setShowRegistarDemarcacaoForm,
}: Props) {
    const [isMobile, setIsMobile] = useState(false);
    const [page, setPage] = useState<PageState>(initialDataState)
    const [pageSizeValue, setPageSizeValue] = useState<number | string | undefined>()
    const [demarcacoes, setDemarcacoes] = useState([])
    const selectDemarcacaoResult = useDhDemarcacaoStore((state) => state.selectDhDemarcacaoResult)
    const selectDemarcacaoResultId = useDhDemarcacaoStore((state) => state.selectDhDemarcacaoResultId)
    const [processos, setProcessos] = useState([])
    const [documentosDemarcacoes, setDocumentosDemarcacoes] = useState([])
    const [idDoDocumento, setIdDoDocumento] = useState("");
    const [urlDoDocumento, setUrlDoDocumento] = useState("");
    const [openForm, setOpenForm] = useState<boolean>(false)
    const [demarcacoesFinal, setDemarcacoesFinal] = useState<any>([]);
    const [pageable, setPageable] = useState<any>({
      buttonCount: 5,
      info: true,
      type: 'numeric',
      pageSizes: true,
      previousNext: true,
    })

    const getDemarcacao = async (data: any) => await get<any, any>(`${GET_DEMARCACAO}/` + data)
    const isCurrentViewMaximized = useCommonStore(state => state.isCurrentViewMaximized)

    const pesquisarDemarcacao = async (data: any) =>
    await post<any, any>(`${PESQUISAR_DEMARCACAO}`, data)

    const getDocumento = async (data: any) =>
    await get<any, any>(`${GET_DOCUMENTO}/` + data)

    const getDocumentosByDemarcacoes = async(data: any) => post<any,any>(GET_DOCUMENTOS_BY_DEMARCACOES, data);

    const getShapeFile = async (data: any) =>
    await post<any, any>(`${GET_DH_DEMARCACAO_SHAPEFILE}`, data)

    const handleMessageEvent = useCallback((event: MessageEvent) => {
        if (event.data.type == "resultadoDemarcacoesPesquisaChange") {
            SessionStorageService.setItem("resultadoDemarcacoesPesquisa", event.data.value);
        }
    }, [])
    const [clearMap] = useClearMap();
    const {getVisibleColumns, removeColumnsKeys} = useVisibleColumns();

    const [dataState, setDataState] = React.useState({
        skip: 0,
        take: 20,
        sort: [],
        group: [],
      });

      const [dataStateDocumentos, setDataStateDocumentos] = React.useState({
        skip: 0,
        take: 20,
        sort: [],
        group: [],
      });
  
     const [dataResult, setDataResult] = React.useState(
        process(demarcacoes, dataState)
      );
      
     const dataStateChange = (event: any) => {
        setDataResult(process(demarcacoes, event.dataState));
        setDataState(event.dataState);
      };
      

    useEffect(() => {
        window.addEventListener(
            'message',
            handleMessageEvent,
            false
        )

        return () => {
            window.removeEventListener(
                'message',
                handleMessageEvent,
                false
            )
        }
    }, [handleMessageEvent])


    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/ResultadoDemarcacao" });
      }, []);

     const canSeeDominioHidricoEdit = hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_EDICAO) 
     || hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_TOTAL);

     const completoLeitura = hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_LEITURA)



     const initialized = useRef(false)
     useEffect(() => {
         if (!initialized.current) {
           initialized.current = true;
        if (SessionStorageService.getItem("lastSearchDemarcacao") && !SessionStorageService.getItem("firstCallDemarcacao"))  {
          const objToSearch = SessionStorageService.getItem("lastSearchDemarcacao") ?? '';
          pesquisarDemarcacao(objToSearch).then((data) => {
            SessionStorageService.setItem("resultadoDemarcacoesPesquisa", JSON.stringify(data));
            setResultsDemarcacao();
          })
        } 
        else {
            setResultsDemarcacao();
      }
    }
      }, [])
  
        const setResultsDemarcacao = () => {
        let results = JSON.parse(SessionStorageService.getItem('resultadoDemarcacoesPesquisa') ?? '')

        if (results) {
            results = results.map((demarcacao: any) => ({
              ...demarcacao,
              data_registo: demarcacao.data_registo
                ? format(new Date(demarcacao.data_registo), 'dd/MM/yyyy HH:mm')
                : null,
             
            }));
          }

        setDemarcacoes(results)
        const ids: any[] = [];
        results.forEach((item: any) => {
            ids.push(item.id);
        })
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage(
        { type: 'SiarlDemarcacaoSearch', value: ids },
        '*'
        )
        
        getDocumentosByDemarcacoes(ids).then(result => {
          setDocumentosDemarcacoes(result);
        });

        setDemarcacoesFinal(results.slice(page.skip, page.take + page.skip))
        getVisibleColumns(keyPainelPrincipal, setColums)
        getVisibleColumns(keyPainelDocumentos, setColumsDocuments)
        postMessage({ type: 'hideLoader' }, '*');

    }

    const openPdfNewTab = () => {
      return new Promise<void>((resolve, reject) => {
          
          postMessage({ type: "showLoader" }, "*");
  
          setTimeout(() => {
             const columnsToShow = colums.columns.filter(x => x.show);
              const dataToExport = demarcacoes.map((item: any) => {
                  const filteredItem: any = {};
                  columnsToShow.forEach((column: any) => {
                      filteredItem[column.field] = item[column.field];
                  });
                  return filteredItem;
              });
              
              exportPDFWithMethod(columnsToShow, dataToExport, 8)
                  .then((pdfData: any) => {
                      postMessage({ type: "hideLoader" }, "*");
  
                          const base64Data = pdfData.split(";base64,")[1];
                          console.log(base64Data);
                          const blob = b64toBlob(base64Data, 'application/pdf');
                          const blobUrl = URL.createObjectURL(blob);
  
                        
                          window.open(blobUrl, '_blank');
                          resolve();
                    
                  })
                  .catch(error => {
                      console.error('Error exporting PDF:', error);
                      reject(error);
                  });
          }, 1000);
      });
  };

    useEffect(() => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    const ids: number[] = []
    demarcacoes.forEach((element: any) => {
      ids.push(element.id)
    })
    iframe.contentWindow?.postMessage(
      { type: 'SiarlDemarcacaoSearch', value: ids },
      '*'
    )
  }, [])

    const clearSearchResults = () => {
        SessionStorageService.removeItem('resultadoDemarcacoesPesquisa')
        postMessage({ type: 'resultadoDemarcacoesPesquisaChange' }, '*')
        sessionStorage.removeItem("lastSearchDemarcacao");
        selectDemarcacaoResult(null)
        clearDemarcacao();
        removeColumnsKeys(keyPainelDocumentos)
        removeColumnsKeys(keyPainelPrincipal)
        clearMap(clearLayers["CLEAR_LAYERS_DEMARCACAO"])
    }

    const handleInfoClickById = (demarcacaoLocal: Record<string, any>) => async () => {

        const demarcacao = await getDemarcacao(demarcacaoLocal.id);
        const tecnico = await GetUserNameTecnico(demarcacao.tecnico_id);

        if (!demarcacao) {
            postMessage({type: "infoMsg", value: { message: "Registo desativado" }}, "*");
            return;
          }

        const seletedDemarcacaoReulst = {
            ...demarcacao,
            tecnicoRegisto : tecnico,
            id_tipo_demarcacao: demarcacao.dhTipoDemarcacao ?? undefined,
            id_localizacao_demarcacao: demarcacao.dhLocalizacaoDemarcacao ?? undefined,
            id_fase_pr: demarcacao.dhFaseProcedimentoDelimitacao ?? undefined,
            id_tipo_va: demarcacao.dhValidacaoProva ?? undefined,
            id_tipo_pa: demarcacao.dhTipoParcela ?? undefined,
            id_tipo_se: demarcacao.dhTipoSentenca ?? undefined,
            id_tipo_ac: demarcacao.dhTipoDecisaoFinal ?? undefined,
            id_tipo_es: demarcacao.dhEstadoDemarcacao ?? undefined,
            id_tipo_pe: demarcacao.dhTipoPendencia ?? undefined,
            id_tipo_no: demarcacao.dhTipoNotificacao ?? undefined,
            id_situ_no: demarcacao.dhTipoSituacaoNotificacao ?? undefined,
            id_cond_pr: demarcacao.dhCondicionantesDemarcacao ?? undefined,
            id_tipo_aj: demarcacao.dhTipoAcaoJudicial ?? undefined,
            id_tipo_de: demarcacao.dhTipoDesafetacao ?? undefined,
            id_tipo_aq: demarcacao.dhTipoAquisicao ?? undefined,
            id_tipo_in: demarcacao.dhTipoIniciativa ?? undefined,
            data_registo: demarcacao.data_registo ? moment.utc(demarcacao.data_registo).tz(CURRENT_TIMEZONE) : undefined,

            filedoc_cod: demarcacao.filedoc_cod ?? undefined,
            designacao: demarcacao.designacao ?? undefined
          }
        sessionStorage.setItem('novaDemarcacao', 'false')
        selectDemarcacaoResultId(demarcacao.id as number)
        selectDemarcacaoResult(seletedDemarcacaoReulst)
        setDemarcacaoSelected(seletedDemarcacaoReulst);
        setShowResults(false)

        const iframe: any = document.getElementById('iframeGeoPortal')

        iframe.contentWindow?.postMessage(
          { type: 'SiarlDemarcacaoSearch', value: [demarcacao.id] },
          '*'
          )
        iframe.contentWindow?.postMessage(
        { type: 'SiarlDemarcacaoCenter', value: [demarcacao.id] },
        '*'
        )

        if (setShowRegistarDemarcacaoForm) setShowRegistarDemarcacaoForm(true)
    }

      const conditionalProps = completoLeitura || canSeeDominioHidricoEdit
    ? { 
        onInfoClick: handleInfoClickById
    } 
    : {};

    const handlePageChange = (event: GridPageChangeEvent) => {
        const targetEvent = event.targetEvent as PagerTargetEvent
        const take = event.page.take

        if (targetEvent.value) {
            setPageSizeValue(targetEvent.value)
        }

        setPage({
            ...event.page,
            take,
        })
    }

    const CommandCell = (props: GridCellProps) => (
        <ResultadoDemarcacaoCommandGridCell
            {...props}
            onInfoClick={handleInfoClickById}
            onMapCenter={mapCenter}
            {...conditionalProps}
        />
    )

    const mapCenter = (item: Record<string, any>) => async () => {
        const iframe: any = document.getElementById('iframeGeoPortal');
        iframe.contentWindow?.postMessage(
          { type: 'SiarlDemarcacaoCenter', value: [item.id] },
          '*'
        )
       }

    const [selected, setSelected] = useState<number>(0)

    const handleSelect = (e: TabStripSelectEventArguments) => {
        setSelected(e.selected)
    }

    const optionsExportar = [
      {
        key: 'ExportarListaResultadosExcel',
        text: 'Excel',
      },
      {
        key: 'ExportarListaResultadosShapeFile',
        text: 'Shapefile',
      },
    ]
    
    let _pdfExport: any;
    const exportExcel = () => {
      _export.save();
    };
  
    const onItemClickExportar = async (event: any) => {
      if (event.item.key == 'ExportarListaResultadosExcel') {
        exportExcel();
      }
      else if (event.item.key == "ExportarListaResultadosShapeFile") {
        const results = JSON.parse(
          SessionStorageService.getItem('resultadoDemarcacoesPesquisa') ?? ''
        )
    
        if (results) {
          const ids: number[] = []
          results.forEach((element: any) => {
            ids.push(element.id)
          })
  
          const file: any = await getShapeFile(ids);
          const filePath = 'shapeFileDhDemarcacoes.zip';
  
          handleDownload(file, filePath);
      }
    } 
  }
  
  
    let _export: any;
    const exportPDF = () => {
      _pdfExport.save();
    };
  
    const onItemClickImprimir = async (event: any) => {
      openPdfNewTab();
    }

    const itemRender = (props: { item: any; itemIndex: number }) => {
        const isTitleItem = props.item.key === 'titulo';
        if (isTitleItem) {
            // Item de título personalizado
            return (
                <div className={styles.DropDownButtonTitulo}>
                    <span>{props.item.text}</span>
                </div>
            );
        } else {
            // Demais itens
            return (
                <div className={styles.DropDownButtonItem}>
                    <span>{props.item.text}</span>
                </div>
            );
        }
    };

    useEffect(() => {
        sessionStorage.setItem("SubModulo", DEMARCACAO_MODULE_NAME);
      }, []);


      const [columsDocuments, setColumsDocuments] = useState<{ columns: { field: string, title: string, width: number | string, show: boolean }[] }>({
        columns: [
          { field: "id", width: 70, title: "ID", show: true },
          { field: "filedoc_cod", width: 190, title: "ID Documento Filedoc", show: true },
          { field: "data_publicacao", width: 190, title: "Data publicação", show: true },
          { field: "tipoRecurso.designacao", width: 190, title: "Tipo de recurso", show: true },
          { field: "titulo", width: 280, title: "Título", show: true },
          { field: "autores", width: 180, title: "Autor", show: true },
          { field: "entidade.nome", width: 190, title: "Entidade", show: true },
          { field: "id_estado", width: 180, title: "Estado", show: true },
        ]
      })
    
      const [colums, setColums]=useState<{columns:{field:string, title:string, width:number|string, show:boolean}[]}>({
        columns:[
            { field: "id", title: "Id", show: true, width: 70 },
            { field: "designacao", title: "Designação da linha", show: true, width: 180 },
            { field: "tipo_li", title: "Tipo de linha", show: true, width: 180 },
            { field: "rh", title: "ARH", show: true, width: 130 },
            { field: "data_registo", title: "Data de atualização", show: true, width: 190 },
        ]
      })

      useEffect(() => {
        SessionStorageService.setItem(keyPainelDocumentos, JSON.stringify(columsDocuments));
      }, [columsDocuments]);
      
      useEffect(() => {
        SessionStorageService.setItem(keyPainelPrincipal, JSON.stringify(colums));
      }, [colums]);
      
      useLayoutEffect(() => {

        const handleResize = () => {
    
          if (window.innerWidth <= telaMobile) {
            
            setIsMobile(true);
            ocultaFloatingMenu_e_Mapa();
    
          }else{
    
            setIsMobile(false);
            mostraFloatingMenu_e_Mapa();
    
          }
    
        };
      
        window.addEventListener('resize', handleResize);
      
        handleResize();
      
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    
      }, []); 
    
    
      const onItemClickMais = (event: any) => {
    
        elementoClickMais(event);

        onItemClickImprimir(event);

        onItemClickExportar(event);
    
      };
    
      const optionsMais = [
        {
            key: 'titulo',
            text: 'Imprimir',
        },
        {
            key: 'imprimirFicha',
            text: 'Resultados',
            className: 'icon-siarl icon-imprimir btnIconRegistar'
        },
        {
            key: 'titulo',
            text: 'Exportar',
          },
          ...optionsExportar.map(option => ({
            ...option,
            className: 'icon-siarl icon-exportar btnIconRegistar',
          })),
      ]

      const handleNewRegistoClick = () => {
        setDemarcacaoSelected(undefined);
        selectDemarcacaoResult(undefined);
        setShowResults(false)
        sessionStorage.setItem('novaDemarcacao', 'true')
        const loggedUser = JSON.parse(
          localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}'
        )
        setDemarcacaoSelected({ tecnicoRegisto: loggedUser?.user?.name })
        if (setShowRegistarDemarcacaoForm)
            setShowRegistarDemarcacaoForm(true)
    }

    const dataStateChangeDocumentos = (event: any) => {
      setDataResultDocumentos(process(documentosDemarcacoes, event.dataState));
      setDataStateDocumentos(event.dataState);
    };
  
    const [dataResultDocumentos, setDataResultDocumentos] = React.useState(
      process(documentosDemarcacoes, dataStateDocumentos)
    );

    const documentsCustomCell = (props: GridCellProps) => (
      <ResultadoDocumentoCommandGridCell
      onLocationMouseEnter={function (documentos: Record<string, unknown>): void {
        console.log("n/a");
      } } onLocationMouseLeave={function (documentos: Record<string, unknown>): void {
        console.log("n/a");
      } } onLocationClick={mapCenter} onDocumentPreviewClick={handleDocumentPreviewClickById} {...props}
      onInfoClick={handleInfoClickById}    />
    )

    const handleDocumentPreviewClickById = (documentosLocal: Record<string, any>) => async () => {

      try {
  
        postMessage({ type: "showLoader" }, "*");
        const documentos = await getDocumento(documentosLocal.id);
  
        setIdDoDocumento(documentosLocal.id);
        setUrlDoDocumento(documentos.url);
        setOpenForm(true);
  
      } catch (error) {
  
        handleGenericError(error, "Não foi possivel abrir o documento!")
        setOpenForm(false);
  
      } finally {
  
        postMessage({ type: "hideLoader" }, "*");
  
      }
    }
    const documentosToPreview = documentosDemarcacoes;
    const nextPhoto = async (e: any) => {

      try{
  
        postMessage({ type: "showLoader" }, "*");
        const nextIds = documentosToPreview
          .filter((doc: any) => doc.id < idDoDocumento && doc.url !== '')
          .map((doc: any) => doc.id);
    
        if (nextIds.length > 0) {
          const nextId = Math.max(...nextIds);
          console.log(`O próximo ID é ${nextId}`);
    
          const documento = await getDocumento(nextId);
    
          setIdDoDocumento(nextId.toString());
          setUrlDoDocumento(documento.url);
  
          const iframe: any = document.getElementById('iframeGeoPortal')
          const processo_id = e.processo_id;
          iframe.contentWindow?.postMessage(
            {
              type: 'SiarlIntIntervencaoHighlight',
              value: [processo_id],
            },
            '*'
          )
    
        } else {
          console.log("Não há um próximo ID na lista.");
        }
  
      }catch(error){
  
        postMessage({ type: "errorMsg", value: "Não foi possivel abrir o documento!" }, "*");
  
      }finally{
  
        postMessage({ type: "hideLoader" }, "*");
  
        e.preventDefault();
      }
     
    }
  
    const previousPhoto = async (e: any) => {
  
      try{
  
        postMessage({ type: "showLoader" }, "*");
        const previousIds = documentosToPreview
          .filter((doc: any) => doc.id > idDoDocumento && doc.url !== '')
          .map((doc: any) => doc.id);
    
        if (previousIds.length > 0) {
          const previousId = Math.min(...previousIds);
          console.log(`O anterior ID é ${previousId}`);
    
          const documento = await getDocumento(previousId);
    
          setIdDoDocumento(previousId.toString());
          setUrlDoDocumento(documento.url);
  
          const iframe: any = document.getElementById('iframeGeoPortal')
          const processo_id = e.processo_id;
          iframe.contentWindow?.postMessage(
            {
              type: 'SiarlIntIntervencaoHighlight',
              value: [processo_id],
            },
            '*'
          )
        } else {
          console.log("Não há um anterior ID na lista.");
        }
  
      } catch(error){
  
        handleGenericError(error, "Não foi possivel abrir o documento!")
  
      }finally{
  
        postMessage({ type: "hideLoader" }, "*");
  
        e.preventDefault();
      }
         
    }


    const openAjuda = () => {
      window.open(URL_AJUDA_DOMINIO_HIDRICO_DEMARCACAO, "_blank")
    }

return (
  <div className={clsx(styles.base, isCurrentViewMaximized && commonStyles.maximizedBaseForm)}>

            <div className={styles.header}>

                <div>
                    <div className={styles.nomeDoModulo}>
                        Demarcações
                    </div>
                    <div className={styles.tituloDaPagina}>
                        Resultados de pesquisa
                    </div>
                </div>

                <div>

                    { canSeeDominioHidricoEdit &&
                        <Button
                        onClick={handleNewRegistoClick}
                        className={styles.btnNovoRegisto}
                        style={{ display: isMobile ? 'none' : '' }}
                        id='novoRegisto'
                        title='Nova demarcação'
                        >
                        <span className="icon-siarl icon-registar btnIconRegistar" /> &nbsp;{' '}
                        Nova demarcação
                        </Button>
                    }

                    <CSVLink
                        title="Imprimir resultados"
                        id="print-results"
                        data={demarcacoes}
                        filename={`siarl-demarcacoes-${format(
                            new Date(),
                            'ddMMyyyHHmm'
                        )}`}
                        hidden
                    >
                    </CSVLink>

                    {!isMobile &&
                    <VisualizadorColunas
                        columns={selected === 0 ? colums : columsDocuments}
                        setColumns={selected === 0 ? setColums : setColumsDocuments}
                        styles={styles.btnEditar2}
                    />
                    }

                    <button className={styles.btnEditar2} onClick={onItemClickImprimir}
                    style={{ display: isMobile ? 'none' : '' }} id='imprimirFicha' title='Imprimir'>
                        <span className="icon-siarl icon-imprimir"></span>
                    </button>

                    {!isMobile &&
                    <DropDownButton
                        items={optionsExportar}
                        className={styles.btnImprimir}
                        style={{marginTop: '-4px'}}
                        iconClass="icon-siarl icon-exportar"
                        onItemClick={onItemClickExportar}
                        fillMode={'flat'}
                        itemRender={itemRender}
                        title='Exportar'
                    ></DropDownButton>
                    }

                    {isMobile &&
                        <DropDownButton
                            items={optionsMais}
                            className={styles.btnMais}
                            iconClass="icon-siarl icon-mais"
                            onItemClick={onItemClickMais}
                            fillMode={'flat'}
                            itemRender={itemRenderMais}
                            title="Mais Opções"
                        />
                    }

                        {isMobile &&
                        <DropDownButton
                            items={optionsMais}
                            className={styles.btnMais}
                            iconClass="icon-siarl icon-mais"
                            onItemClick={onItemClickMais}
                            fillMode={'flat'}
                            itemRender={itemRenderMais}
                            title="Mais Opções"
                        />
                        }

                    <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
                        <span className="icon-siarl icon-ajuda"></span>
                    </button>

                    <button className={styles.btnFechar} onClick={onCloseClick} title="Minimizar">
                        <span className="icon-siarl icon-minimizar"></span>
                    </button>

                    {!isMobile &&
                    <MaximizeButton className={styles.btnMaximizar} />
                    }
                </div>

            </div>

    <TabStrip selected={selected} onSelect={handleSelect} scrollable={isMobile}>
      <TabStripTab title="Demarcações">
        
      <ExcelExport
        fileName='Resultado_Demarcacao'
            data={demarcacoes}
            ref={(exporter) => {
              _export = exporter;
            }}
          >
        <Grid
          className={styles.form}
          data={(dataResult.data.length > 0 ? dataResult : demarcacoesFinal).slice(page.skip, page.take + page.skip)}
          pageable={pageable}
          skip={page.skip}
          take={page.take}
          total={dataResult.total > 0 ? dataResult.total : demarcacoesFinal.length}
          onPageChange={handlePageChange}
        >
          <GridColumn cell={CommandCell} width={56} />
          {colums?.columns?.map((column, i) => {
            return (
                column.show && (
                    <GridColumn key={i} field={column.field} width={column.width} title={column.title} />
                )
            );
        })}
        </Grid>
        </ExcelExport>

      

        <GridPDFExport
          fileName='Resultado_Demarcacao'
          ref={(element) => {
            _pdfExport = element
          }}
          paperSize={['390mm', '297mm']}         

          margin="1cm"
        >
          <Grid
            className={styles.form}
            sortable={true}
            onDataStateChange={dataStateChange}
            data={demarcacoes}

          total={demarcacoes.length}
          {...dataState}
            pageable={{
              buttonCount: 5,
              info: true,
              type: 'numeric',
              pageSizes: true,
              previousNext: true,
            }}
            //groupable={true}
          >
              <GridColumn cell={CommandCell} width={30} />
              {colums?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
          </Grid>
        </GridPDFExport>
        
      </TabStripTab>
      <TabStripTab title="Documentos">
        <ExcelExport
          fileName='Resultado_Documentos_Demarcacao'
          data={documentosDemarcacoes}
          ref={(exporter) => {
            _export = exporter
          }}
        >
          
          <Grid
            className={styles.form}
            sortable={true}
            onDataStateChange={dataStateChangeDocumentos}
            data={dataResultDocumentos.data.length > 0 ? dataResultDocumentos : documentosDemarcacoes.map((documentos: any) => ({
                ...documentos,
                data_publicacao: documentos?.data_publicacao ? format(new Date(documentos.data_publicacao), 'dd/MM/yyyy HH:mm') : undefined,
        })).slice(page.skip, page.take + page.skip)
      }        
            {...dataState}
            pageable={{
              buttonCount: 5,
              info: true,
              type: 'numeric',
              pageSizes: true,
              previousNext: true,
            }}
            //groupable={true}
            total={documentosDemarcacoes.length}

          >
            <GridColumn cell={documentsCustomCell} width={86} />
            {columsDocuments?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
          </Grid>
        </ExcelExport>

        <GridPDFExport
          fileName='Resultado_Documentos_Demarcacao'
          ref={(element) => {
            _pdfExport = element
          }}
          margin="1cm"
        >
          <Grid
            className={styles.form}
            sortable={true}
            onDataStateChange={dataStateChangeDocumentos}
            data={
              dataResultDocumentos.data.length > 0
                ? dataResultDocumentos
                : documentosDemarcacoes.map((documentos: any) => ({
                    ...documentos,
                    data_publicacao: documentos?.data_publicacao ? format(new Date(documentos.data_publicacao), 'dd/MM/yyyy HH:mm') : undefined,
            }))        
            }
            {...dataState}
            pageable={{
              buttonCount: 5,
              info: true,
              type: 'numeric',
              pageSizes: true,
              previousNext: true,
            }}
            //groupable={true}
          >
           {columsDocuments?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
          </Grid>
        </GridPDFExport>
      </TabStripTab>
    </TabStrip>
    {openForm && (
        <Dialog
        className="ModalDocumentos"
          title={`Documento: ${idDoDocumento}`}
          onClose={() => setOpenForm(false)}
          style={{ zIndex: 999 }}
        >
            <ImagePreviewV2
              documentUrl={urlDoDocumento}
              next={(e: any) => nextPhoto(e)}
              previous={(e: any) => previousPhoto(e)}
            />

        </Dialog>
      )}
   <div className={clsx(styles.footer, styles.justify_one_child, isCurrentViewMaximized && commonStyles.maximizedFooter)}>

                <Button
                    className={styles.btnPesquisar}
                    onClick={clearSearchResults}
                    title='Nova Pesquisa'
                >
                    <span className="icon-siarl icon-pesquisar"></span>
                    <span className={styles.rotulo}>
                      {' '}&nbsp;{' '}
                      Nova pesquisa
                    </span>
                </Button>
    </div>
  </div>
)
}