import { create } from 'zustand'

type IntervencaoState = {
    selectedIntervencaoResult?: unknown
    selectIntervencaoResult: (payload: unknown) => void
    selectedIntervencaoResultId?: number
    selectIntervencaoResultId: (payload?: number) => void
    identificacaoTabIsValid: boolean
    selectIntervencaoIdentificacaoTabIsValid: (payload: unknown) => void
     identificacaoTabValidationObject: Record<string, boolean>
    addIdentificacaoTabValidatedField: (payload: Record<string, boolean>) => void
   
}

export const useIntervencaoStore = create<IntervencaoState>((set, get) => ({
    selectedIntervencaoResult: null,
    identificacaoTabIsValid: false,
    identificacaoTabValidationObject: {},
    selectIntervencaoResult: (payload: unknown) =>
        set({ selectedIntervencaoResult: payload }),
    selectIntervencaoResultId: (payload?: number) =>
        set({ selectedIntervencaoResultId: payload }),
    selectIntervencaoIdentificacaoTabIsValid: (payload?: any) =>
        set({ identificacaoTabIsValid: payload }),
    addIdentificacaoTabValidatedField: (payload) => {
        const validationObj = get().identificacaoTabValidationObject
        const newValidationObj = { ...validationObj, ...payload }
        set({ identificacaoTabValidationObject: newValidationObj })
        const validationValues = Object.values(newValidationObj)
        set({ identificacaoTabIsValid: validationValues.every(value => value === true) })
    }
}))
