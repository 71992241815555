import { create } from 'zustand'

type VooDroneState = {
    selectedVooDroneResult?: unknown,
    selectedVooDronePathResult?: unknown,
    selectVooDroneResult: (payload: unknown) => void
    selectVooDronePathResult: (payload: unknown) => void
    selectedVooDroneResultId?: number
    selectVooDroneResultId: (payload?: number) => void
    identificacaoTabIsValid: boolean
    selectVooDroneIdentificacaoTabIsValid: (payload: unknown) => void
    detalheTabIsValid: boolean
    selectVooDroneDetalheTabIsValid: (payload: unknown) => void
    identificacaoTabValidationObject: Record<string, boolean>
    addIdentificacaoTabValidatedField: (payload: Record<string, boolean>) => void
    detalheTabValidationObject: Record<string, boolean>
    addDetalheTabValidatedField: (payload: Record<string, boolean>) => void
}

export const useVooDroneStore = create<VooDroneState>((set, get) => ({
    selectedVooDroneResult: null,
    selectedVooDronePathResult: null,
    identificacaoTabIsValid: false,
    detalheTabIsValid: true,
    identificacaoTabValidationObject: {},
    detalheTabValidationObject: {},
    selectVooDroneResult: (payload: unknown) =>
        set({ selectedVooDroneResult: payload }),
    selectVooDronePathResult: (payload: unknown) =>
        set({ selectedVooDronePathResult: payload }),
    selectVooDroneResultId: (payload?: number) =>
        set({ selectedVooDroneResultId: payload }),
    selectVooDroneIdentificacaoTabIsValid: (payload?: any) =>
        set({ identificacaoTabIsValid: payload }),
    selectVooDroneDetalheTabIsValid: (payload?: any) =>
        set({ identificacaoTabIsValid: payload }),
    addIdentificacaoTabValidatedField: (payload) => {
        const validationObj = get().identificacaoTabValidationObject
        const newValidationObj = { ...validationObj, ...payload }
        set({ identificacaoTabValidationObject: newValidationObj })
        const validationValues = Object.values(newValidationObj)
        set({ identificacaoTabIsValid: validationValues.every(value => value === true) })
    },
    addDetalheTabValidatedField: (payload) => {
        const validationObj = get().detalheTabValidationObject
        const newValidationObj = { ...validationObj, ...payload }
        set({ detalheTabValidationObject: newValidationObj })
        const validationValues = Object.values(newValidationObj)
        set({ detalheTabIsValid: validationValues.every(value => value === true) })
    }
}))
