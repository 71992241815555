import {
  MouseEvent as ReactMouseEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react'

import {
  Grid,
  GridCellProps,
  GridColumn,
  GridPageChangeEvent,
  GridToolbar
} from '@progress/kendo-react-grid'

import { Button, DropDownButton } from '@progress/kendo-react-buttons'
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from '@progress/kendo-react-layout'
import { format } from 'date-fns'
import { CSVLink } from 'react-csv'
import SessionStorageService from '../../services/SessionStorageService'
import styles from '../../styles/apoiogestao/ApoioGestao.module.scss'
import commonStyles from '../../styles/common/Common.module.scss'
import { ResultadoApoioGestaoPretensaoCommandGridCell } from './ResultadoApoioGestaoPretensaoCommandGridCell'

import { process } from '@progress/kendo-data-query'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { GridPDFExport } from '@progress/kendo-react-pdf'
import clsx from 'clsx'
import { GetUserNameTecnico, agDecisoes, agEstados, agTipoPretensoes, b64toBlob, exportPDFWithMethod, fillAgEstadosAsync, fillAgTipoPretensaosync, fillDecisoesAsync, get, getPretensaoSelected, handleDownload, handleGenericError, hasPermission, post, refreshPesquisaSearch, setCoordenadasPoligono, setPretensaoSelected, useCombos } from '../../services/utils'
import { useAgPretensaoStore } from '../../stores/agPretensao'
import { useCommonStore } from '../../stores/common'
import { CONSULTAR_AG_PRETENSAO, CONSULTA_AG_DESCONFORMIDADE, CURRENT_TIMEZONE, GET_AG_PRETENSAO_SHAPEFILE, GET_DOCUMENTO, GET_PRETENSAO, PERMISSION_APOIO_GESTAOPERFIL_INTERMEDIO_EDICAO, PERMISSION_APOIO_GESTAO_PERFIL_COMPLETO_EDICAO, PERMISSION_APOIO_GESTAO_PERFIL_TOTAL, PERMISSION_INSERIR_PRETENSAO, PESQUISAR_DOCUMENTOS_BY_PRETENSOES, URL_AJUDA_APOIO_GESTAO_PRETENSAO, clearLayers } from '../../utils/constants'
import MaximizeButton from '../common/MaximizeButton'
import VisualizadorColunas from '../Generico/VisualizadorColunas '
import useVisibleColumns, { Key } from '../../hooks/useVIsibleColumns'
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais'
import { PagerTargetEvent } from '@progress/kendo-react-data-tools'
import ReactGA from 'react-ga4';
import { ResultadoDocumentoCommandGridCell } from '../documentos/ResultadoDocumentoCommandGridCell'
import { Dialog } from '@progress/kendo-react-dialogs'
import ImagePreviewV2 from '../viewer/ImagePreviewV2'
import moment from 'moment'
import "moment-timezone"
import React from 'react'

type IProps = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
}

type PageState = {
  skip: number
  take: number
}

const initialDataState: PageState = { skip: 0, take: 20 }
const keyPainelPrincipal:Key = 'pretensaoColunasVisiveis';
const keyPainelDocumentos:Key = 'pretensaoDocumentosColunasVisiveis';
export default function ResultadoApoioGestaoPretensao({ onCloseClick }: IProps) {
  const [isMobile, setIsMobile] = useState(false);
  const [selected, setSelected] = useState<number>(0)
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false)
  const [agPretensoes, setAgPretensoes] = useState<any[]>([])
  const [documentosPretensoes, setDocumentosPretensoes] = useState([])
  const {getVisibleColumns, removeColumnsKeys} = useVisibleColumns();
  const [page, setPage] = useState<PageState>(initialDataState)

  const isCurrentViewMaximized = useCommonStore(state => state.isCurrentViewMaximized)


  useEffect(() => {

    if (!agDecisoes) {
      fillDecisoesAsync();
    }
    if (!agEstados) {
      fillAgEstadosAsync();
    }
    if (!agTipoPretensoes) {
      fillAgTipoPretensaosync();
    }


  }, []);

  useEffect(() => {
    
    ReactGA.send({ hitType: "pageview", page: "/ResultadoApoioAGestaoPretensao" });

  }, []);

  const getPretensao = async (data: any) =>
    await get<any>(`${GET_PRETENSAO}/` + data)

  const getDocumentosByPretensoes = async (data: any) => await post<any, any>(`${PESQUISAR_DOCUMENTOS_BY_PRETENSOES}`, data);

  const getDocumento = async (data: any) =>
  await get<any, any>(`${GET_DOCUMENTO}/` + data)

  const getShapeFile = async (data: any) =>
  await post<any, any>(`${GET_AG_PRETENSAO_SHAPEFILE}`, data)

  const [dataState, setDataState] = useState({
    skip: 0,
    take: 20,
    sort: [],
    group: [],
  });

  const [dataStateDocumentos, setDataStateDocumentos] = useState({
    skip: 0,
    take: 20,
    sort: [],
    group: [],
  });

  const [urlDoDocumento, setUrlDoDocumento] = useState("");
  const [idDoDocumento, setIdDoDocumento] = useState("");
  const [openForm, setOpenForm] = useState<boolean>(false)

  const dataStateChange = (event: any) => {
    try{
      setDataResult(process(agPretensoes, event.dataState));
      setDataState(event.dataState);
    }catch(ex){
      // todo handling 
    }
  };

  const [dataResult, setDataResult] = useState(
    process(agPretensoes, dataState)
  );

  const dataStateChangeDocumentos = (event: any) => {
    setDataResultDocumentos(process(documentosPretensoes, event.dataState));
    setDataStateDocumentos(event.dataState);
  };

  const [dataResultDocumentos, setDataResultDocumentos] = useState(
    process(documentosPretensoes, dataStateDocumentos)
  );

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected)
  }

  const [pageSizeValue, setPageSizeValue] = useState<
    number | string | undefined
  >()

  const handlePageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent
    const take = event.page.take

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value)
    }

    setPage({
      ...event.page,
      take,
    })
  }

  const selectAgPretensaoResultId = useAgPretensaoStore(
    (state) => state.selectAgPretensaoResultId
  )
  const selectAgPretensaoResult = useAgPretensaoStore(
    (state) => state.selectAgPretensaoResult
  )

  const itemRender = (props: { item: any; itemIndex: number }) => {
    const isTitleItem = props.item.key === 'titulo';
    if (isTitleItem) {
      // Item de título personalizado
      return (
        <div className={styles.DropDownButtonTitulo}>
          <span>{props.item.text}</span>
        </div>
      );
    } else {
      // Demais itens
      return (
        <div className={styles.DropDownButtonItem}>
          <span>{props.item.text}</span>
        </div>
      );
    }
  };


  const optionsExportar = [
    {
      key: 'ExportarListaResultadosExcel',
      text: 'Excel',
    },
    {
      key: 'ExportarListaResultadosShapeFile',
      text: 'Shapefile',
    },
  ]
  
  let _pdfExport: any;
  const exportExcel = () => {
    _export.save();
  };

  const onItemClickExportar = async (event: any) => {
    if (event.item.key == 'ExportarListaResultadosExcel') {
      exportExcel();
    }
    else if (event.item.key == "ExportarListaResultadosShapeFile") {
      const results = JSON.parse(
        SessionStorageService.getItem('resultadoApoioGestaoPretensao') ?? ''
      )
  
      if (results) {
        const ids: number[] = []
        results.forEach((element: any) => {
          ids.push(element.id)
        })

        const file: any = await getShapeFile(ids);
        const filePath = 'shapeFileAgPretensao.zip';

        handleDownload(file, filePath);
    }
  } 
}


  let _export: any;
  const exportPDF = () => {
    _pdfExport.save();
  };

  const openPdfNewTab = () => {
    return new Promise<void>((resolve, reject) => {
        
        postMessage({ type: "showLoader" }, "*");

        setTimeout(() => {
           const columnsToShow = colums.columns.filter(x => x.show);
            const dataToExport = agPretensoes.map((item: any) => {
                const filteredItem: any = {};
                columnsToShow.forEach((column: any) => {
                    filteredItem[column.field] = item[column.field];
                });
                return filteredItem;
            });
            
            exportPDFWithMethod(columnsToShow, dataToExport, 8)
                .then((pdfData: any) => {
                    postMessage({ type: "hideLoader" }, "*");

                        const base64Data = pdfData.split(";base64,")[1];
                        console.log(base64Data);
                        const blob = b64toBlob(base64Data, 'application/pdf');
                        const blobUrl = URL.createObjectURL(blob);

                      
                        window.open(blobUrl, '_blank');
                        resolve();
                  
                })
                .catch(error => {
                    console.error('Error exporting PDF:', error);
                    reject(error);
                });
        }, 1000);
    });
};

  const onItemClickImprimir = async (event: any) => {
    openPdfNewTab();
  }


  const clearSearchResults = () => {
    SessionStorageService.removeItem('resultadoApoioGestaoPretensao')
    SessionStorageService.removeItem('lastSearchPretensao')
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
      { type: clearLayers["CLEAR_LAYERS_PRETENSAO"], value: 'true' },
      '*'
    )
    iframe.contentWindow?.postMessage(
      { type: 'SiarlRemoveSelection', value: 'true' },
      '*'
    )
    removeColumnsKeys(keyPainelDocumentos)
    removeColumnsKeys(keyPainelPrincipal)
  
    setCoordenadasPoligono(undefined);
    postMessage({ type: 'resultadoApoioGestaoPretensaoPesquisaChange' }, '*')
  }

  const consultarPretensao = async (data: any) =>
    await post<any, any>(`${CONSULTAR_AG_PRETENSAO}`, data)

  const initialized = useRef(false)
    useEffect(() => {
        if (!initialized.current) {
          initialized.current = true;    if (SessionStorageService.getItem("lastSearchPretensao") && !SessionStorageService.getItem("firstCallAgPretensao")) {
      postMessage({ type: "showLoader" }, "*");
      const objToSearch = SessionStorageService.getItem("lastSearchPretensao") ?? '';
      consultarPretensao(objToSearch).then((data) => {
        SessionStorageService.setItem("resultadoApoioGestaoPretensao", JSON.stringify(data));
        setResultsPretensao();
        postMessage({ type: "hideLoader" }, "*");
      })
    } 
    else {
      setResultsPretensao();
  }
}
  }, [])

  const setResultsPretensao = () => {

    if (SessionStorageService.getItem('resultadoApoioGestaoPretensao')) {
      let pretensoes: any[] = JSON.parse(
        SessionStorageService.getItem('resultadoApoioGestaoPretensao') ?? ''
      )
      pretensoes = pretensoes?.map((pretensao: any) => ({
        ...pretensao,
        data_registo_formated: pretensao.data_registo 
        ? format(new Date(pretensao.data_registo), 'dd/MM/yyyy HH:mm') 
        : ' ',
        data_decisao_formated: pretensao.data_decisao 
        ? format(new Date(pretensao.data_decisao), 'dd/MM/yyyy HH:mm') 
        : ' ',

      }))
      setAgPretensoes(pretensoes);
      const ids: any[] = [];
       pretensoes.forEach((item: any) => {
         ids.push(item.id);
       });
      // const iframe: any = document.getElementById('iframeGeoPortal');
      // iframe.contentWindow?.postMessage(
      //   { type: 'SiarlPretensaoSearch', value: ids },
      //   '*'
      // )
      refreshPesquisaSearch("resultadoApoioGestaoPretensao", "SiarlPretensaoSearch", true);
      getVisibleColumns(keyPainelPrincipal, setColums)
      getVisibleColumns(keyPainelDocumentos, setColumsDocuments)
    
    
      postMessage({ type: "showLoader" }, "*");
      getDocumentosByPretensoes(ids).then(result => {
        setDocumentosPretensoes(result);
        postMessage({ type: "hideLoader" }, "*");
      })
    }
  }

  useEffect(() => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    const ids: number[] = []
    agPretensoes.forEach((element: any) => {
      ids.push(element.id)
    })
    iframe.contentWindow?.postMessage(
      { type: 'SiarlPretensaoSearch', value: ids },
      '*'
    )
  }, [])

  const handleInfoClickById = (pretensaoRecord: Record<string, any>) => async () => {

    const pretensao = await getPretensao(pretensaoRecord.id);
    const tecnico = await GetUserNameTecnico(pretensao.utilizador_id);

    
    if (!pretensao) {
      postMessage({ type: "infoMsg", value: { message: "Registo desativado" } }, "*");
      return;
    }
    SessionStorageService.setItem("novaPretensao", 'false');
    postMessage({ type: "showLoader" }, "*");

    console.log(pretensao);
    const objToShow = {
      ...pretensao,
      decisao: agDecisoes.filter((x: any) => x.id == pretensao.ag_decisao_id)[0],
      id: pretensao.id,
      file_doc_numero: pretensao.file_doc_numero ?? undefined,
      numeroProcesso: pretensao.numero_do_processo ?? undefined,
      nifRequerente: pretensao.requerente_nif ?? undefined,
      nomeRequerente: pretensao.requerente_nome ?? undefined,
      data_registo: pretensao.data_registo ? moment.utc(pretensao.data_registo).tz(CURRENT_TIMEZONE) : undefined,
      assunto: pretensao.assunto,
      estado: agEstados.filter((x: any) => x.id == pretensao.ag_estado_id)[0],
      tipoPretensao: agTipoPretensoes.filter((x: any) => x.id == pretensao.ag_tipo_pretensao_id)[0],
      data_entrada: pretensao.data_entrada ? moment.utc(pretensao.data_entrada).tz(CURRENT_TIMEZONE) : undefined,
      data_decisao: pretensao.data_decisao ? moment.utc(pretensao.data_decisao).tz(CURRENT_TIMEZONE) : undefined,
      local: pretensao.local,
      obs: pretensao.observacoes,
      geom_imported: pretensao.geom_imported,
      utilizador_registo: pretensao.utilizador?.Name,
      tecnicoRegisto : tecnico
    }
    selectAgPretensaoResult(objToShow);
    setPretensaoSelected(pretensao);
    selectAgPretensaoResultId(pretensao.id);
    postMessage({ type: "hideLoader" }, "*");
    const iframe: any = document.getElementById('iframeGeoPortal');

    // iframe.contentWindow?.postMessage(
    //   { type: 'SiarlPretensaoSearch', value: [pretensao.id] },
    //   '*'
    // )
    iframe.contentWindow?.postMessage(
      { type: 'SiarlPretensaoCenter', value: [pretensao.id] },
      '*'
    )
    postMessage({ type: "form", value: "registarApoioGestaoPretensao" }, "*");
  }

  const CommandCell = (props: GridCellProps) => (
    <ResultadoApoioGestaoPretensaoCommandGridCell
      {...props}
      onDetailsClick={handleInfoClickById}
      onMapCenter={mapCenter}
    />
  )
  const closeModal = () => {
    setShowDetailModal(!showDetailModal)
  }

  const mapCenter = (item: Record<string, any>) => async () => {
    const id = item.pretensao_id ?? item.id;
    const iframe: any = document.getElementById('iframeGeoPortal');
    iframe.contentWindow?.postMessage(
      { type: 'SiarlPretensaoCenter', value: [id] },
      '*'
    )
  }
  const [columsDocuments, setColumsDocuments] = useState<{ columns: { field: string, title: string, width: number | string, show: boolean }[] }>({
    columns: [
      { field: "id", width: 90, title: "ID", show: true },
      { field: "filedoc_cod", width: 200, title: "ID Documento Filedoc", show: true },
      { field: "data_publicacao", width: 170, title: "Data publicação", show: true },
      { field: "tipoRecurso.designacao", width: 170, title: "Tipo de recurso", show: true },
      { field: "titulo", width: 170, title: "Título", show: true },
      { field: "autores", width: 150, title: "Autor", show: true },
      { field: "entidade.nome", width: 180, title: "Entidade", show: true },
      { field: "id_estado", width: 180, title: "Estado", show: true },
    ]
  })

  const [colums, setColums]=useState<{columns:{field:string, title:string, width:number|string, show:boolean}[]}>({
    columns:[
      { field: "id", title: "ID", show: true, width: 90 },
      { field: "numeroProcesso", title: "Nr. Processo", show: true, width: 180 },
      { field: "assunto", title: "Assunto", show: true, width: 170 },
      { field: "tecnico", title: "Técnico", show: true, width: 170 },
      { field: "requerente_Nif", title: "Requerente NIF", show: true, width: 190 },
      { field: "requerente_nome", title: "Requerente Nome", show: true, width: 190 },
      { field: "local", title: "Local", show: true, width: 170 },
      { field: "tipoPretensao", title: "Tipo", show: true, width: 170 },
      { field: "estado", title: "Estado", show: false, width: 170 },
      { field: "decisao", title: "Decisao", show: false, width: 170 },
      { field: "data_registo_formated", title: "Data registo", show: false, width: 190 },
      { field: "data_decisao_formated", title: "Data decisão", show: false, width: 190 } 
    ]
  })

  
useEffect(() => {
  SessionStorageService.setItem(keyPainelDocumentos, JSON.stringify(columsDocuments));
}, [columsDocuments]);

useEffect(() => {
  SessionStorageService.setItem(keyPainelPrincipal, JSON.stringify(colums));
}, [colums]);


const combinedData = dataResult.data.length > 0
  ? dataResult.data
  : agPretensoes.slice(page.skip, page.take + page.skip)
    .map((pretensao: any) => (
      {
        ...pretensao,
        data_registo: format(
          new Date(pretensao.data_registo),
          'dd/MM/yyyyHHmm'
        ),
      }
    ));


useLayoutEffect(() => {

  const handleResize = () => {

    if (window.innerWidth <= telaMobile) {
      
      setIsMobile(true);
      ocultaFloatingMenu_e_Mapa();

    }else{

      setIsMobile(false);
      mostraFloatingMenu_e_Mapa();

    }

  };

  window.addEventListener('resize', handleResize);

  handleResize();

  return () => {
    window.removeEventListener('resize', handleResize);
  };

}, []);  


const onItemClickMais = (event: any) => {

  elementoClickMais(event);

  onItemClickExportar(event);
};

const optionsMais = [
  {
    key: 'titulo',
    text: 'Imprimir',
  },
  {
    key: 'titulo',
    text: 'Exportar',
  },
  ...optionsExportar.map(option => ({
    ...option,
    className: 'icon-siarl icon-exportar btnIconRegistar',
  })),
]

const openAjuda = () => {
  window.open(URL_AJUDA_APOIO_GESTAO_PRETENSAO, "_blank");
}

const documentosToPreview = documentosPretensoes

const handleDocumentPreviewClickById = (documentosLocal: Record<string, any>) => async () => {

  try {

    postMessage({ type: "showLoader" }, "*");
    const documentos = await getDocumento(documentosLocal.id);

    setIdDoDocumento(documentosLocal.id);
    setUrlDoDocumento(documentos.url);
    setOpenForm(true);

  } catch (error) {

    handleGenericError(error, "Não foi possivel abrir o documento!")
    setOpenForm(false);

  } finally {

    postMessage({ type: "hideLoader" }, "*");

  }
}

const nextPhoto = async (e: any) => {

  try{

    postMessage({ type: "showLoader" }, "*");
    const nextIds = documentosToPreview
      .filter((doc: any) => doc.id < idDoDocumento && doc.url !== '')
      .map((doc: any) => doc.id);

    if (nextIds.length > 0) {
      const nextId = Math.max(...nextIds);
      console.log(`O próximo ID é ${nextId}`);

      const documento = await getDocumento(nextId);

      setIdDoDocumento(nextId.toString());
      setUrlDoDocumento(documento.url);

      const iframe: any = document.getElementById('iframeGeoPortal')
      const pretensao_id = e.pretensao_id;
      iframe.contentWindow?.postMessage(
        {
          type: 'SiarlPretensaoCenter',
          value: [pretensao_id],
        },
        '*'
      )

    } else {
      console.log("Não há um próximo ID na lista.");
    }

  }catch(error){

    postMessage({ type: "errorMsg", value: "Não foi possivel abrir o documento!" }, "*");

  }finally{

    postMessage({ type: "hideLoader" }, "*");

    e.preventDefault();
  }
 
}

const previousPhoto = async (e: any) => {

  try{

    postMessage({ type: "showLoader" }, "*");
    const previousIds = documentosToPreview
      .filter((doc: any) => doc.id > idDoDocumento && doc.url !== '')
      .map((doc: any) => doc.id);

    if (previousIds.length > 0) {
      const previousId = Math.min(...previousIds);
      console.log(`O anterior ID é ${previousId}`);

      const documento = await getDocumento(previousId);

      setIdDoDocumento(previousId.toString());
      setUrlDoDocumento(documento.url);

      const iframe: any = document.getElementById('iframeGeoPortal')
      const pretensao_id = e.pretensao_id;
      iframe.contentWindow?.postMessage(
        {
          type: 'SiarlPretensaoCenter',
          value: [pretensao_id],
        },
        '*'
      )
    } else {
      console.log("Não há um anterior ID na lista.");
    }

  } catch(error){

    handleGenericError(error, "Não foi possivel abrir o documento!")

  }finally{

    postMessage({ type: "hideLoader" }, "*");

    e.preventDefault();
  }
     
}

const documentsCustomCell = (props: GridCellProps) => (
  <ResultadoDocumentoCommandGridCell
  onLocationMouseEnter={function (documentos: Record<string, unknown>): void {
    console.log("n/a");
  } } onLocationMouseLeave={function (documentos: Record<string, unknown>): void {
    console.log("n/a");
  } } onLocationClick={mapCenter} onDocumentPreviewClick={handleDocumentPreviewClickById} {...props}
  onInfoClick={handleInfoClickById}    />
)


const registarNovoClick = () => {
    selectAgPretensaoResultId(undefined);
    setPretensaoSelected(undefined);
    selectAgPretensaoResult(undefined);
    SessionStorageService.setItem("novaPretensao", 'true');
    postMessage({ type: "form", value: "registarApoioGestaoPretensao" }, "*");
}

  return (
    <>
      <div className={clsx(styles.base, isCurrentViewMaximized && commonStyles.maximizedBaseForm)}>
        <div className={styles.header}>
          <div>
            <div className={styles.nomeDoModulo}>Apoio à Gestão</div>
            <div className={styles.tituloDaPagina}>Resultados de pesquisa</div>
          </div>

          <div>

            {hasPermission(PERMISSION_INSERIR_PRETENSAO) ||
              (hasPermission(PERMISSION_APOIO_GESTAOPERFIL_INTERMEDIO_EDICAO)) ||
              (hasPermission(PERMISSION_APOIO_GESTAO_PERFIL_TOTAL)) ||
              (hasPermission(PERMISSION_APOIO_GESTAO_PERFIL_COMPLETO_EDICAO))
              ?
              <Button
                onClick={registarNovoClick}
                className={styles.btnNovoRegisto}
                style={{ display: isMobile ? 'none' : '' }}
                id='novoRegisto'
                title='Nova pretensão'
              >
                <span className="icon-siarl icon-registar btnIconRegistar"></span>{' '}
                &nbsp;{' '}
                Nova pretensão
              </Button>
            : ''}

            <CSVLink
              title="Imprimir resultados"
              id="print-results-pretensoes"
              separator={';'}
              data={agPretensoes.map((pretensao: any) => ({
                ...pretensao,
                data_registo: format(
                  new Date(pretensao.data_registo),
                  'dd/MM/yyyHHmm'
                ),
              }))}
              filename={`siarl-pretensoes-${format(
                new Date(),
                'ddMMyyyHHmm'
              )}`}
              hidden
            ></CSVLink>

            {!isMobile &&
              <VisualizadorColunas
                columns={selected === 0 ? colums : columsDocuments}
                setColumns={selected === 0 ? setColums : setColumsDocuments}
                styles={styles.btnEditar2}
              />
            }

            {/* <button className={styles.btnEditar2} onClick={onItemClickImprimir} style={{ display: isMobile ? 'none' : '' }} id='imprimirFicha' title='Imprimir'>
              <span className="icon-siarl icon-imprimir"></span>
            </button> */}

            {!isMobile &&
              <DropDownButton
                items={optionsExportar}
                className={styles.btnImprimir}
                style={{marginTop: '-4px'}}
                iconClass="icon-siarl icon-exportar"
                onItemClick={onItemClickExportar}
                fillMode={'flat'}
                title='Exportar'
                itemRender={itemRender}
            ></DropDownButton>
            }

            {isMobile &&
              <DropDownButton
                items={optionsMais}
                className={styles.btnMais}
                iconClass="icon-siarl icon-mais"
                onItemClick={onItemClickMais}
                fillMode={'flat'}
                itemRender={itemRenderMais}
                title="Mais Opções"
              />
            }

            <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
              <span className="icon-siarl icon-ajuda"></span>
            </button>
            <button onClick={onCloseClick} className={styles.btnFechar} title="Minimizar">
              <span className="icon-siarl icon-minimizar"></span>
            </button>

            {!isMobile &&
            <MaximizeButton className={styles.btnMaximizar} />
            }
          </div>
        </div>
        <TabStrip selected={selected} onSelect={handleSelect} scrollable={isMobile}>
          <TabStripTab title="Pretensões">
            <ExcelExport
              fileName='Resultado_Pretensao'
              data={agPretensoes.map((pretensao: any) => ({
                ...pretensao,
                data_registo: format(
                  new Date(pretensao.data_registo),
                  'dd/MM/yyyHHmm'
                ),
              }))}
              ref={(exporter) => {
                _export = exporter
              }}
            >
              <Grid
                className={styles.form}
                sortable={true}
                onDataStateChange={dataStateChange}
                data={
                  dataResult.data.length > 0
                    ? dataResult
                    : agPretensoes
                }
                {...dataState}
                pageable={
                  {
                    buttonCount: 5,
                    info: true,
                    type: 'numeric',
                    pageSizes: true,
                    previousNext: true,
                  }
              }
             
               //groupable={true}
              >
                <GridColumn cell={CommandCell} width={56} />

                {colums?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
          
              </Grid>
            </ExcelExport>

            <GridPDFExport
              fileName='Resultado_Pretensao'
              ref={(element) => {
                _pdfExport = element
              }}
              paperSize={['340mm', '297mm']}         

              margin="1cm"
            >
              <Grid
                className={styles.form}
                sortable={true}
                onDataStateChange={dataStateChange}
                data={
                  dataResult.data.length > 0
                    ? dataResult
                    : agPretensoes.map((pretensao: any) => ({
                      ...pretensao,
                      data_registo: format(
                        new Date(pretensao.data_registo),
                        'dd/MM/yyyHHmm'
                      ),
                    }))
                }
          
                {...dataState}
                pageable={{
                  buttonCount: 5,
                  info: true,
                  type: 'numeric',
                  pageSizes: true,
                  previousNext: true,
                }}
                //groupable={true}
              >
                <GridColumn cell={CommandCell} width={56} />

                {colums?.columns?.map((column, i) => {
                  return (
                    column.show && (
                      <GridColumn key={i} field={column.field} width={column.width} title={column.title} />
                    )
                  );
                })}
              </Grid>
            </GridPDFExport>
          </TabStripTab>

          <TabStripTab title="Documentos">
            <ExcelExport
              fileName='Resultado_Documentos_Pretensao'
              data={documentosPretensoes}
              ref={(exporter) => {
                _export = exporter
              }}
            >
              <Grid
                className={styles.form}
                sortable={true}
                onDataStateChange={dataStateChangeDocumentos}
                data={
                  dataResultDocumentos.data.length > 0
                    ? dataResultDocumentos
                    : documentosPretensoes
                }
                {...dataStateDocumentos}
                pageable={{
                  buttonCount: 5,
                  info: true,
                  type: 'numeric',
                  pageSizes: true,
                  previousNext: true,
                }}
                //groupable={true}
              >
                <GridColumn cell={documentsCustomCell} width={86} />
                  {columsDocuments?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
              </Grid>
            </ExcelExport>

            <GridPDFExport
              fileName='Resultado_Documentos_Pretensao'
              ref={(element) => {
                _pdfExport = element
              }}
              margin="1cm"
            >
              <Grid
                className={styles.form}
                sortable={true}
                onDataStateChange={dataStateChangeDocumentos}
                data={
                  dataResultDocumentos.data.length > 0
                    ? dataResultDocumentos
                    : documentosPretensoes
                }
                {...dataState}
                pageable={{
                  buttonCount: 5,
                  info: true,
                  type: 'numeric',
                  pageSizes: true,
                  previousNext: true,
                }}
                //groupable={true}
              >
                <GridColumn cell={documentsCustomCell} width={86} />
                     {columsDocuments?.columns?.map((column, i) => {
            return (
              column.show && (
                <GridColumn key={i}  field={column.field} width={column.width} title={column.title} />
              )
            );
          })}
              </Grid>
            </GridPDFExport>
          </TabStripTab>
        </TabStrip>

        <div
          className={clsx(styles.footer, styles.justify_one_child, isCurrentViewMaximized && commonStyles.maximizedFooter)}
        >
          <Button className={styles.btnPesquisar} onClick={clearSearchResults} title='Nova Pesquisa'>
            <span className="icon-siarl icon-pesquisar btnIconPesquisar"></span>
            <span className={styles.rotulo}>
              {' '}&nbsp;{' '}
              Nova Pesquisa
            </span>
          </Button>
        </div>
      </div>

      {openForm && (
        <Dialog
        className="ModalDocumentos"
          title={`Documento: ${idDoDocumento}`}
          onClose={() => setOpenForm(false)}
          style={{ zIndex: 999 }}
        >
          
            <ImagePreviewV2
              documentUrl={urlDoDocumento}
              next={(e: any) => nextPhoto(e)}
              previous={(e: any) => previousPhoto(e)}
            />

        </Dialog>
      )}
    </>
  )
}