import { Input, InputProps } from '@progress/kendo-react-inputs'
import { Error } from '@progress/kendo-react-labels'
import { useEffect } from 'react'

import { setIsDocumentoDetalhesValid } from '../../../../validations/documento'

import type { FieldRenderProps } from '@progress/kendo-react-form'

export default function ValidatedDocumentosInput(
  fieldRenderProps: FieldRenderProps
) {
  useEffect(() => {
    setIsDocumentoDetalhesValid(!!fieldRenderProps.value)
  }, [fieldRenderProps.value])

  return (
    <div>
      <Input {...(fieldRenderProps as InputProps)} style={{ width: '100%' }} />
      {fieldRenderProps.visited && fieldRenderProps.validationMessage && (
        <Error>{fieldRenderProps.validationMessage}</Error>
      )}
    </div>
  )
}
