import { Field, FormRenderProps } from "@progress/kendo-react-form"
import styles from '../../../styles/estrategiaAdaptacao/EstrategiaAdaptacao.module.scss';
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { ComboBox, MultiSelect } from "@progress/kendo-react-dropdowns";
import { arhs, capitania, concelhos, distritos, eaEstado, entidades, get, getEaRegistoAnalise, getEaResultado, GetUserNameTecnico, massaagua, nuts1, nuts2, nuts3, tecnico } from "../../../services/utils";
import { useEffect, useState } from "react";
import { filterBy } from "@progress/kendo-data-query";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import FieldsetCombosLocalizacao from "../../localizacao/FieldsetCombosLocalizacao";
import ValidatedIdentificacaoCheckbox from "./ValidationComponentes/ValidatedIdentificacaoCheckbox";
import { requiredFieldValidator } from "../../../validations/ocorrencia";
import CheckMoreItem from "../../documentos/registar/fields/CheckMoreItem";
import ReactGA from 'react-ga4';
import { AUTH_LOCALSTORAGE, GET_ANALISE } from "../../../utils/constants";
import { useEstrategiaAdaptacaoStore } from "../../../stores/estrategiaAdaptacao";

type IProps = {
    formRenderProps: FormRenderProps
    viewMode?: boolean
    onCheckboxChangeFlatCordinates?: any
    onCheckboxChangeFlatCordinatesApoio?: any
    disabled?: boolean
    isChecked?: boolean,
    realizeNovaAnalise: boolean

}
export default function IdentificacaoEstrategiaAdaptacao({ formRenderProps, viewMode, onCheckboxChangeFlatCordinates, onCheckboxChangeFlatCordinatesApoio, disabled, isChecked, realizeNovaAnalise }: IProps) {
  const getAnalise = async (data: any) =>
    await get<any, any>(`${GET_ANALISE}/` + data)

  const selectedEstrategiaResult: any = useEstrategiaAdaptacaoStore(
    (state) => state.selectedEstrategiaResult
  )
  
    type combosKeys = 'entidades' | 'eaEstado';

    const [combos, setCombos] = useState<any>({
      eaEstado,
      entidades
    })
    const setDataOption: Record<combosKeys, { setFn: any, data: any[] }> = {
        entidades: {
          setFn: setCombos,
          data: entidades
        },
        eaEstado: {
          setFn: setCombos,
          data: eaEstado
        },
    };
    const filterCombo_Multiselect = (e: any, tipoCombo: combosKeys) => {
        const { setFn, data } = setDataOption[tipoCombo];
        setFn({
            ...combos,
            [tipoCombo]: filterBy(data?.slice() ?? [], e.filter)
        }
        );
    }

    
    const changeFileDoc = (e: any) => {
        // to do 
    }

    useEffect(() => {
      ReactGA.send({ hitType: "pageview", page: "/IdentificacaoEstrategiaAdaptacao" });
    }, [])

    useEffect(() => {
      const fetchTecnico = async () => {
        if(selectedEstrategiaResult){
          //registo que já existe (user que registou o formulário)
          const utilizador_id = selectedEstrategiaResult?.utilizador?.id
          const tecnicoName = await GetUserNameTecnico(utilizador_id);
          formRenderProps.onChange('utilizador_id', { value: tecnicoName });

        }
        else{
        //novo registo (user que está logado)
        const userName = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '')
        ?.user?.name;
        formRenderProps.onChange('utilizador_id', { value: userName });

      }
    }
      fetchTecnico();
    }, []);

  const shouldRenderField = sessionStorage.getItem('getEaResultado');

    return (
        <>   <div className={`${styles.form} ${styles.removeScrollDuplicado}`}>
            <fieldset>
                <legend>
                    <span className={styles.fieldsetTitulo}>Registo</span>
                </legend>
                <div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}>
                    <div>
                        <Field
                            name="id"
                            label="ID"
                            component={Input}
                            disabled
                        />
                    </div>
                    <div>
                        <Field
                            name="filedoc_cod"
                            label="Nº Processo APA"
                            component={Input}
                            onChange={changeFileDoc}
                            disabled={viewMode}
                        />
                    </div>
                    <div>
                        <Field
                            name="estado_id"
                            label="Estado"
                            component={ComboBox}
                            filterable={true}
                            onFilterChange={(e: any) => { filterCombo_Multiselect(e, 'eaEstado') }}
                            data={combos?.eaEstado}
                            textField={'designacao'}
                            disabled={viewMode}
                        />
                    </div>
                </div>
                <div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}>
                    <div>
                        <Field
                            name="data_registo"
                            label="Data / Hora de registo"
                            format="dd/MM/yyyy HH:mm"
                            component={DateTimePicker}
                            disabled
                        />
                    </div>
                 <div>
                    <Field
                        name="utilizador_id"
                        label="Técnico"
                        component={Input}
                        disabled
                    />
            </div>
                    <div>
                        <Field
                            name="entidade_registo"
                            label="Entidade"
                            textField={'nome'}
                            component={Input}
                            filterable={true}
                            disabled
                        />
                    </div>
                </div>
            </fieldset>

            <fieldset>
                <legend>
                    <span className={styles.fieldsetTitulo}>Identificação</span>
                </legend>
                <div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}>
                    <div>
                        <Field
                            name="data_hora_relatorio"
                            label="Data / Hora de relatório"
                            format="dd/MM/yyyy HH:mm"
                            component={DateTimePicker}
                            disabled={viewMode}
                        />
                    </div>
                    <div>
                        <Field
                            name="titulo"
                            label="Titulo"
                            component={Input}
                            disabled={viewMode}
                        />
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <legend>
                    <span className={styles.fieldsetTitulo}>Definição de área de análise</span>
                </legend>
                <div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}>
                    <div>
                    {getEaResultado() == true ? (
                      <Field
                        name="poligono_area_analise"
                        label="Polígono da área de análise*"
                        component={ValidatedIdentificacaoCheckbox}
                        onChange={onCheckboxChangeFlatCordinates}
                        checked={true}
                      />
                    ) : (
                      <div>
                      <Field
                        name="poligono_area_analise"
                        label="Polígono da área de análise*"
                        component={ValidatedIdentificacaoCheckbox}
                        validator={requiredFieldValidator}
                        onChange={onCheckboxChangeFlatCordinates}
                        checked={isChecked}
                      />                      
                      </div>
                    )}
                  </div>
                </div>
                <div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}>
                    <div>
                        <Field
                            name="poligono_area_abrangencia_obras_execucao"
                            label="Polígono da área de abrangência de obras de execução (não obrigatório)"
                            component={Checkbox}
                            onChange={onCheckboxChangeFlatCordinatesApoio}
                            disabled={viewMode}
                        />
                    </div>
                </div>
                {realizeNovaAnalise ? (
                      <div className={styles.outrosPontosDetalhes}>
                        <span className='customErrorText'>
                          Não se pode alterar as geometrias, realize uma nova análise.
                        </span>
                      </div>
                    ) : ''}
            </fieldset>
            <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Localização</span>
          </legend>

          <div className={styles.espacoVerticalFieldSet}>
            <div className={styles.fields}>
              {getEaRegistoAnalise() ? (
                <div>
                  <CheckMoreItem
                    list={getEaRegistoAnalise()?.nuts1}
                    property={'nome'}
                    label="NUTS I"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="nuts1"
                    label="NUTS I"
                    component={MultiSelect}
                    textField={'nome'}
                    data={nuts1}
                    disabled
                  />
                </div>
              )}
              {getEaRegistoAnalise() ? (
                <div>
                  <CheckMoreItem
                    list={getEaRegistoAnalise()?.nuts2}
                    property={'nome'}
                    label="NUTS II"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="nuts2"
                    label="NUTS II"
                    component={MultiSelect}
                    textField={'nome'}
                    disabled
                    data={nuts2}
                  />
                </div>
              )}
              {getEaRegistoAnalise() ? (
                <div>
                  <CheckMoreItem
                    list={getEaRegistoAnalise()?.nuts3}
                    property={'nome'}
                    label="NUTS III"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="nuts3"
                    label="NUTS III"
                    component={MultiSelect}
                    textField={'nome'}
                    data={nuts3}
                    disabled
                  />
                </div>
              )}
            </div>

            <div className={styles.fields}>
              {getEaRegistoAnalise() ? (
                <div>
                  <CheckMoreItem
                    list={getEaRegistoAnalise()?.distritos}
                    property={'nome'}
                    label="Distrito"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="distrito"
                    label="Distrito"
                    component={MultiSelect}
                    textField={'nome'}
                    disabled
                    data={distritos}
                  />
                </div>
              )}
              {getEaRegistoAnalise() ? (
                <div>
                  <CheckMoreItem
                    list={getEaRegistoAnalise()?.concelhos}
                    property={'nome'}
                    label="Concelho"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="concelho"
                    label="Concelho"
                    component={MultiSelect}
                    disabled
                    textField={'nome'}
                    data={concelhos}
                  />
                </div>
              )}
              {getEaRegistoAnalise() ? (
                <div>
                  <CheckMoreItem
                    list={getEaRegistoAnalise()?.freguesias}
                    property={'nome'}
                    label="Freguesia"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="freguesia"
                    label="Freguesia"
                    component={MultiSelect}
                    textField={'designacao'}
                    disabled
                  />
                </div>
              )}
            </div>

            <div className={styles.fields}>
              {getEaRegistoAnalise()  ? (
                <div>
                  <CheckMoreItem
                    list={getEaRegistoAnalise()?.arhs}
                    property={'nome'}
                    label="ARH"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="arh"
                    label="ARH"
                    component={MultiSelect}
                    textField={'nome'}
                    data={arhs}
                  />
                </div>
              )}
              {getEaRegistoAnalise()  ? (
                <div>
                  <CheckMoreItem
                    list={getEaRegistoAnalise()?.capitanias}
                    property={'nome'}
                    label="Capitania"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="capitania"
                    label="Capitania"
                    component={MultiSelect}
                    textField={'nome'}
                    data={capitania}
                    disabled
                  />
                </div>
              )}
              {getEaRegistoAnalise()  ? (
                <div>
                  <CheckMoreItem
                    list={getEaRegistoAnalise()?.massasAgua}
                    property={'nome'}
                    label="Massa de água"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="massaAgua"
                    label="Massa de água"
                    component={MultiSelect}
                    textField={'nome'}
                    disabled
                    data={massaagua}
                  />
                </div>
              )}
            </div>
          </div>
        </fieldset>

        </div>

        </>);
}