import { create } from 'zustand'

type FotografiaState = {
    selectedFotoResult?: unknown,
    selectedFotoPathResult?: unknown,
    selectFotoResult: (payload: unknown) => void
    selectFotoPathResult: (payload: unknown) => void
    selectedFotoResultId?: number
    selectFotoResultId: (payload?: number) => void
    identificacaoTabIsValid: boolean
    selectFotoIdentificacaoTabIsValid: (payload: unknown) => void
    detalheTabIsValid: boolean
    selectFotoDetalheTabIsValid: (payload: unknown) => void
    identificacaoTabValidationObject: Record<string, boolean>
    addIdentificacaoTabValidatedField: (payload: Record<string, boolean>) => void
    detalheTabValidationObject: Record<string, boolean>
    addDetalheTabValidatedField: (payload: Record<string, boolean>) => void
}

export const useFotografiaStore = create<FotografiaState>((set, get) => ({
    selectedFotoResult: null,
    selectedFotoPathResult: null,
    identificacaoTabIsValid: false,
    detalheTabIsValid: true,
    identificacaoTabValidationObject: {},
    detalheTabValidationObject: {},
    selectFotoResult: (payload: unknown) =>
        set({ selectedFotoResult: payload }),
    selectFotoPathResult: (payload: unknown) =>
        set({ selectedFotoPathResult: payload }),
    selectFotoResultId: (payload?: number) =>
        set({ selectedFotoResultId: payload }),
    selectFotoIdentificacaoTabIsValid: (payload?: any) =>
        set({ identificacaoTabIsValid: payload }),
    selectFotoDetalheTabIsValid: (payload?: any) =>
        set({ identificacaoTabIsValid: payload }),
    addIdentificacaoTabValidatedField: (payload) => {
        const validationObj = get().identificacaoTabValidationObject
        const newValidationObj = { ...validationObj, ...payload }
        set({ identificacaoTabValidationObject: newValidationObj })
        const validationValues = Object.values(newValidationObj)
        set({ identificacaoTabIsValid: validationValues.every(value => value === true) })
    },
    addDetalheTabValidatedField: (payload) => {
        const validationObj = get().detalheTabValidationObject
        const newValidationObj = { ...validationObj, ...payload }
        set({ detalheTabValidationObject: newValidationObj })
        const validationValues = Object.values(newValidationObj)
        set({ detalheTabIsValid: validationValues.every(value => value === true) })
    }
}))
