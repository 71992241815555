import { Button } from '@progress/kendo-react-buttons'
import { DateTimePicker } from '@progress/kendo-react-dateinputs'
import { ComboBox } from '@progress/kendo-react-dropdowns'
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from '@progress/kendo-react-form'
import { Input, RadioGroup } from '@progress/kendo-react-inputs'
import { MouseEvent as ReactMouseEvent, useEffect, useState } from 'react'
import SessionStorageService from '../../services/SessionStorageService'
import {
  arhs,
  capitania,
  concelhos,
  coordenadasPoligono,
  coordenadasSelected,
  deposicaoLocal1,
  deposicaoLocal2,
  deposicaoObjetivo1,
  deposicaoObjetivo2,
  distritos,
  entidades,
  fillArhsAsync,
  fillCapitaniaAsync,
  fillCombosAuxiliares,
  fillConcelhosAsync,
  fillDeposicaoNivel1Async,
  fillDeposicaoNivel2Async,
  fillDistritosAsync,
  fillEntidadesAsync,
  fillFreguesiasAsync,
  fillMetodoDeposicaoAsync,
  fillMetodoDragagemAsync,
  fillMobilizacaoEstadoAsync,
  fillMobilizacaoFuncoesAsync,
  fillMobilizacaoTipologiaAsync,
  fillNuts1Async,
  fillNuts2Async,
  fillNuts3Async,
  fillObjetivoDeposicao1Async,
  fillObjetivoDeposicao2Async,
  fillQualidadeSedimentosAsync,
  freguesias,
  hasPermission,
  metodoDeposicao,
  metotodoDragagem,
  mobilizacaoEstado,
  mobilizacaoFuncoes,
  nuts1,
  nuts2,
  nuts3,
  post,
  qualidadeSedimentos,
  setCoordenadasPoligono,
  tipologiaMob,
  useCombos
} from '../../services/utils'
import styles from '../../styles/intervencao/Intervencao.module.scss'
import { AREA_VISIVEL, CONSULTAR_MOBILIZACAO, NENHUM, PERMISSION_INTERVENCAO_PERFIL_BASICO, PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO, PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA, PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO, PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_LEITURA, PERMISSION_INTERVENCAO_PERFIL_TOTAL, POLIGONO_SELECAO, clearLayers } from '../../utils/constants'
import FieldsetCombosLocalizacao from '../localizacao/FieldsetCombosLocalizacao'
import { filterBy } from '@progress/kendo-data-query'
import ReactGA from 'react-ga4';

type Props = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
  onClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
}

export default function PesquisarMobilizacao({ onCloseClick, onClick }: Props) {
  const [identificacaoVisible, setIdentificacaoVisible] = useState(true)
  const toggleIdentificacao = () =>
    setIdentificacaoVisible((previousState) => !previousState)

  const [criterioGeograficoVisible, setCriterioGeograficoVisible] =
    useState(false)
  const toggleCriterioGeografico = () => {
    setCriterioGeograficoVisible((previousState) => !previousState)
  }

  const [localizacaoVisible, setLocalizacaoVisible] = useState(false)
  const toggleLocalizacao = () => {
    setLocalizacaoVisible((previousState) => !previousState)
  }

  const [dataVisible, setDataVisible] = useState(false)
  const toggleData = () => {
    setDataVisible((previousState) => !previousState)
  }

  const [caracterizacaoVisible, setCaracterizacaoVisible] = useState(false)
  const toggleCaracterizacao = () => {
    setCaracterizacaoVisible((previousState) => !previousState)
  }

  const [intervaloVisible, setIntervaloVisible] = useState(false)
  const toggleIntervalo = () => {
    setIntervaloVisible((previousState) => !previousState)
  }

  const [estadoVisible, setEstadoVisible] = useState(false)
  const toggleEstado = () => {
    setEstadoVisible((previousState) => !previousState)
  }

  const [entidadeVisible, setEntidadeVisible] = useState(false)
  const toggleEntidade = () => {
    setEntidadeVisible((previousState) => !previousState)
  }
  const [rbCriteriosSelected, setRBCriteriosSelected] = useState<any>()

  const [concelhosFiltred, setConcelhosFiltred] = useState<any>(undefined)
  const [concelhosDisabled, setConcelhosDisabled] = useState<any>(true)
  const [freguesiasFiltred, setFreguesiasFiltred] = useState<any>(undefined)
  const [freguesiasDisabled, setFreguesiasDisabled] = useState<any>(true)
  const [nuts2Filtered, setNuts2Filtered] = useState<any>(undefined)
  const [nuts2Disabled, setNuts2Disabled] = useState<any>(true)
  const [nuts3Filtered, setNuts3Filtered] = useState<any>(undefined)
  const [nuts3Disabled, setNuts3Disabled] = useState<any>(true)


  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/PesquisarIntMobilizacao" });
  }, []);

    useEffect(() => {
    
      if(!nuts1 ){
        fillNuts1Async();
        }
       if(!nuts2) {
        fillNuts2Async();
       }
       if(!nuts3){
        fillNuts3Async();
       }
       if(!distritos){
        fillDistritosAsync();
       }
       if(!concelhos){
        fillConcelhosAsync();
       }
       if(!freguesias){
        fillFreguesiasAsync();
       }
       if(!arhs){
        fillArhsAsync();
       }
       if(!capitania){
        fillCapitaniaAsync();
       }
       if(!mobilizacaoFuncoes){
        fillMobilizacaoFuncoesAsync();
       }
       if(!tipologiaMob){
        fillMobilizacaoTipologiaAsync();
       }
       if(!deposicaoLocal1){
        fillDeposicaoNivel1Async();
       }
       if(!deposicaoLocal2){
        fillDeposicaoNivel2Async();
       }
       if(!entidades){
        fillEntidadesAsync();
       }
       if(!mobilizacaoEstado){
        fillMobilizacaoEstadoAsync();
       }
       if(!metotodoDragagem){
        fillMetodoDragagemAsync();
       }
       if(!metodoDeposicao){
        fillMetodoDeposicaoAsync();
       }
       if(!deposicaoObjetivo1){
        fillObjetivoDeposicao1Async();
       }
        if(!deposicaoObjetivo2){
        fillObjetivoDeposicao2Async();
       }
       if(!qualidadeSedimentos){
        fillQualidadeSedimentosAsync();
       }
    

  }, []);

  const filterConcelho = (e: any) => {
    if (e?.value?.codigo) {
      const concelhosLocal = concelhos.filter(
        (x: any) => x.distrito_cod == e.value.codigo
      )
      setConcelhosFiltred(concelhosLocal)
      setConcelhosDisabled(false)
    } else {
      setConcelhosFiltred(concelhos)
      setConcelhosDisabled(true)
    }
  }

  const filterFreguesia = (e: any) => {
    if (e?.value?.codigo) {
      const freguesiaLocal = freguesias.filter(
        (x: any) => x.concelho_cod == e.value.codigo
      )
      setFreguesiasFiltred(freguesiaLocal)
      setFreguesiasDisabled(false)
    } else {
      setFreguesiasDisabled(true)
      setFreguesiasFiltred(freguesias)
    }
  }

  const filterNuts2 = (e: any) => {
    if (e?.value?.codigo) {
      const nutsLocal = nuts2.filter((x: any) => x.nuts_i_cod == e.value.codigo)
      setNuts2Filtered(nutsLocal)
      setNuts2Disabled(false)
    } else {
      setNuts2Disabled(true)
      setNuts2Filtered(nuts2)
    }
  }

  const filterNuts3 = (e: any) => {
    if (e?.value?.codigo) {
      const nutsLocal = nuts3.filter(
        (x: any) => x.nuts_ii_cod == e.value.codigo
      )
      setNuts3Filtered(nutsLocal)
      setNuts3Disabled(false)
    } else {
      setNuts3Disabled(true)
      setNuts3Filtered(nuts3)
    }
  }

  const changerbCriterio = (e: any) => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlDeselectMapArea", value: 'true' }, '*')
    if (e.value == POLIGONO_SELECAO) {
        postMessage(
            { type: 'informationMsg', value: 'Selecione a área de pesquisa' },
            '*'
        )
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage(
            { type: 'SiarlGeoPortalAdicionarArea', value: 'true' },
            '*'
        )
    }
    if (e.value == AREA_VISIVEL) {
        iframe.contentWindow?.postMessage({ type: "SiarlSelectMapArea", value: 'true' }, '*')
        postMessage(
            {
                type: 'informationMsg',
                value: 'Foi selecionada a área visível do mapa',
            },
            '*'
        )
       
        iframe.contentWindow?.postMessage(
            { type: 'SiarlGetMapArea', value: 'true' },
            '*'
        )
    }
    if (e.value == NENHUM) {
        setCoordenadasPoligono(undefined)
        clearMap()
    }
}


const clearMap = () => {
  try {

    setCoordenadasPoligono(undefined)
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlIntMobilizacaoClearLayers", value: "true" }, '*')
    iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: "true" }, '*')
    iframe.contentWindow?.postMessage({ type: "SiarlDeselectMapArea", value: "true" }, '*')

  } catch (error) {
    console.log({ error })
  }
}

  const getMobilizacao = async (data: any) =>
    await post<any, any>(`${CONSULTAR_MOBILIZACAO}`, data)

  const handleSubmit = async (e: any) => {
    postMessage({ type: 'showLoader' }, '*')

    const objToSearch = {
      id: e.values?.id,
      descricao: e.values?.descricao,
      id_tipo_mo: e.values?.id_tipo_mo ? e.values?.id_tipo_mo.id : undefined,
      funcao: e.values?.funcao ? e.values?.funcao.id : undefined,
      tipo_local_deposicao_1_id: e.values?.tipo_local_deposicao_1_id
        ? e.values?.tipo_local_deposicao_1_id.id
        : undefined,
      tipo_local_deposicao_2_id: e.values?.tipo_local_deposicao_2_id
        ? e.values?.tipo_local_deposicao_2_id.id
        : undefined,
      arh_cod: e.values.arh ? e.values?.arh.codigo : undefined,
      freguesia_cod: e.values.freguesia?.codigo ?? undefined,
      concelho_cod: e.values.concelho?.codigo ?? undefined,
      distrito_cod: e.values.distrito?.codigo ?? undefined,
      nuts1_cod: e.values?.nuts1?.codigo ?? undefined,
      nuts2_cod: e.values?.nuts2?.codigo ?? undefined,
      nuts3_cod: e.values?.nuts3?.codigo ?? undefined,
      massa_agua_id: e.values?.massaagua ? e.values.massaagua.id : undefined,
      status: e.values?.estado ? e.values?.estado.valor : undefined,
      volume: e.values?.volume ?? undefined,
      criterio_geografico: e?.values?.rbCriterio ?? undefined,
      hasGeom: e?.values?.hasGeom,
      flatCoordinates: coordenadasPoligono,
      data_de_registo: e.values?.data_de_registo ? new Date(e.values?.data_de_registo) : undefined,
      data_ate_registo: e.values?.data_ate_registo ? new Date(e.values?.data_ate_registo) : undefined,
      data_de_mobilizacao: e.values?.data_de_mobilizacao ? new Date(e.values?.data_de_mobilizacao) : undefined,
      data_ate_mobilizacao : e.values?.data_ate_mobilizacao ? new Date(e.values?.data_ate_mobilizacao) : undefined,
        }

     SessionStorageService.setItem("lastSearchMobilizacao", objToSearch);
     SessionStorageService.setItem("firstCallMobilizacao", true);
     
    const result: any[] = await getMobilizacao(objToSearch);

    if (result) {
      SessionStorageService.setItem(
        'resultadoMobilizacaoPesquisa',
        JSON.stringify(result)
      )
      postMessage(
        { type: 'resultadoMobilizacaoPesquisaChange', value: result },
        '*'
      )

      const idsCaracteristicas : any[] = [];
      result.forEach((item: any) => {
        if (item.lstMobilizacoesCaracteristicasIds && item.lstMobilizacoesCaracteristicasIds.length > 0) {
         idsCaracteristicas.push(item.lstMobilizacoesCaracteristicasIds)
        }
      })
      const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.contentWindow?.postMessage({ type: "SiarlMobilizacaoCaraterizacaoSearch", value: idsCaracteristicas }, '*')
      
    }
  }

  type combosKeys = 'mobilizacaoFuncoes'
  |'tipologiaMob' |'deposicaoLocal1'|'deposicaoLocal2' | 'mobilizacaoEstado'
  | 'entidades'
  ;
  const [combos, setCombos] = useState<any>({
    mobilizacaoFuncoes, 
    tipologiaMob,
    deposicaoLocal1,
    deposicaoLocal2,
    mobilizacaoEstado,
    entidades,
  })
  const setDataOption: Record<combosKeys, { setFn: any, data: any[] }> = {
    mobilizacaoFuncoes: { setFn: setCombos, data: mobilizacaoFuncoes },
    tipologiaMob: { setFn: setCombos, data: tipologiaMob },
    deposicaoLocal1: { setFn: setCombos, data: deposicaoLocal1 },
    deposicaoLocal2: { setFn: setCombos, data: deposicaoLocal2 },
    mobilizacaoEstado: { setFn: setCombos, data: mobilizacaoEstado },
    entidades: { setFn: setCombos, data: entidades },

  };

  const filterCombo_Multiselect = (e: any, tipoCombo: combosKeys) => {
    const { setFn, data } = setDataOption[tipoCombo];
      setFn({
          ...combos,
          [tipoCombo]: filterBy(data?.slice()??[], e.filter)
      }
      );
}

const canSeeMobilizacao = hasPermission(PERMISSION_INTERVENCAO_PERFIL_BASICO) ||
                          hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO) ||
                          hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA) ||
                          hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO) ||
                          hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_LEITURA) ||
                          hasPermission(PERMISSION_INTERVENCAO_PERFIL_TOTAL);
  return (
    <Form
      onSubmitClick={handleSubmit}
      render={(formRenderProps: FormRenderProps) => (
        <>
          <FormElement className={styles.form}>
            <fieldset>
              <legend>
                <button
                  type="button"
                  className={styles.itemExpandBase}
                  onClick={toggleIdentificacao}
                >
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span
                      className={
                        identificacaoVisible
                          ? 'icon-siarl icon-colapsar'
                          : 'icon-siarl icon-expandir'
                      }
                    />
                  </div>
                  <span className={styles.fieldsetTitulo}>Identificação</span>
                </button>
              </legend>

              {identificacaoVisible && (
                <div className={styles.espacoVerticalFieldSet}>
                  <div className={styles.fields}>
                    <div>
                      <Field name="id" label="ID (Código)" component={Input} />
                    </div>
                    <div>
                      <Field
                        name="descricao"
                        label="Descrição"
                        component={Input}
                      />
                    </div>
                  </div>
                </div>
              )}
            </fieldset>
            <fieldset>
              <legend>
                <button
                  type="button"
                  className={styles.itemExpandBase}
                  onClick={toggleCriterioGeografico}
                >
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span
                      className={
                        criterioGeograficoVisible
                          ? 'icon-siarl icon-colapsar'
                          : 'icon-siarl icon-expandir'
                      }
                    ></span>
                  </div>
                  <span className={styles.fieldsetTitulo}>
                    Critério geográfico
                  </span>
                </button>
              </legend>
              {criterioGeograficoVisible ? (
                <div className={styles.fields}>
                  <Field
                    id={'rbCriterio'}
                    name={'rbCriterio'}
                    layout={'horizontal'}
                    component={RadioGroup}
                    onChange={(e) => {
                      changerbCriterio(e)
                  }} 
                    data={[
                      { label: 'Nenhum', value: 0},
                      { label: 'Área visível', value: 1 },
                      { label: 'Polígono de seleção', value: 2 },
                    ]}
                    defaultValue={0} // Set the default selected value to 0 (Nenhum)

                  />
                </div>
              ) : (
                <></>
              )}
            </fieldset>
            {/* <FieldsetCombosLocalizacao styles={styles} isPesquisaAvancada={true} formRenderProps={formRenderProps}></FieldsetCombosLocalizacao> */}
            <fieldset>
              <legend>
                <button
                  type="button"
                  className={styles.itemExpandBase}
                  onClick={toggleData}
                >
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span
                      className={
                        dataVisible
                          ? 'icon-siarl icon-colapsar'
                          : 'icon-siarl icon-expandir'
                      }
                    />
                  </div>
                  <span className={styles.fieldsetTitulo}>Intervalo de datas</span>
                </button>
              </legend>
              {dataVisible && (
                <div className={styles.espacoVerticalFieldSet}>
                  <div className={styles.fields}>
                  <legend className={styles.fieldsetSubTitulo}>
                    Data da mobilização
                  </legend>
                    <div>
                      <Field
                        name="data_de_mobilizacao"
                        label="De"
                        component={DateTimePicker}
                        format="dd/MM/yyyy HH:mm"
                      />
                    </div>

                    <div>
                      <Field
                        name="data_ate_mobilizacao"
                        label="Ate"
                        component={DateTimePicker}
                        format="dd/MM/yyyy HH:mm"
                      />
                    </div>
                    </div>
                    <legend className={styles.fieldsetSubTitulo}>
                    Data de registo
                  </legend>
                  <div className={styles.fields}>

                    {
                        hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO) ||
                        hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA) ||
                        hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO) ||
                        hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_LEITURA) ||
                        hasPermission(PERMISSION_INTERVENCAO_PERFIL_TOTAL)?
                        <>
                        <div>
                          <Field
                            name="data_de_registo"
                            label="De"
                            component={DateTimePicker}
                            format="dd/MM/yyyy HH:mm"
                          />
                    </div>

                    <div>
                          <Field
                            name="data_ate_registo"
                            label="Até"
                            component={DateTimePicker}
                            format="dd/MM/yyyy HH:mm"
                          />
                    </div>
                        </>:<></>
                    }
                    </div>
                  
                </div>
              )}
            </fieldset>
            {/* <fieldset>
              <legend>
                <button
                  type="button"
                  className={styles.itemExpandBase}
                  onClick={toggleCaracterizacao}
                >
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span
                      className={
                        caracterizacaoVisible
                          ? 'icon-siarl icon-colapsar'
                          : 'icon-siarl icon-expandir'
                      }
                    />
                  </div>
                  <span className={styles.fieldsetTitulo}>Caracterização</span>
                </button>
              </legend>
              {caracterizacaoVisible && (
                <div className={styles.espacoVerticalFieldSet}>
                  <div className={styles.fields}>
                    <div>
                      <Field
                        name="funcao"
                        label="Função"
                        component={ComboBox}
                        textField={'descricao'}
                        filterable={true}
                        onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'mobilizacaoFuncoes')}}
                        data={combos?.mobilizacaoFuncoes}

                      />
                    </div>
                    <div>
                      <Field
                        name="id_tipo_mo"
                        label="Tipologia (Extração, Deposição)"
                        component={ComboBox}
                        textField={'descricao'}
                        filterable={true}
                        onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'tipologiaMob')}}
                        data={combos?.tipologiaMob}
                      />
                    </div>
                  </div>
                  <div className={styles.espacoVerticalFieldSet}>
                    <div className={styles.fields}>
                      <div>
                        <Field
                          name="tipo_local_deposicao_1_id"
                          label="Tipo de local de deposição nivel 1"
                          component={ComboBox}
                          textField={'descricao'}
                          filterable={true}
                          onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'deposicaoLocal1')}}
                          data={combos?.deposicaoLocal1}
                        />
                      </div>
                      <div>
                        <Field
                          name="tipo_local_deposicao_2_id"
                          label="Tipo de local de deposição nivel 2"
                          component={ComboBox}
                          textField={'descricao'}
                          filterable={true}
                          onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'deposicaoLocal2')}}
                          data={combos?.deposicaoLocal2}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </fieldset> */}
            {/* <fieldset>
              <legend>
                <button
                  type="button"
                  className={styles.itemExpandBase}
                  onClick={toggleIntervalo}
                >
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span
                      className={
                        intervaloVisible
                          ? 'icon-siarl icon-colapsar'
                          : 'icon-siarl icon-expandir'
                      }
                    />
                  </div>
                  <span className={styles.fieldsetTitulo}>Volumes (m3)</span>
                </button>
              </legend>
              {intervaloVisible && (
                <div className={styles.espacoVerticalFieldSet}>
                  <div className={styles.fields}>
                    <div>
                      <Field
                        name="volume"
                        label="Volume em m3"
                        component={Input}
                      />
                    </div>
                  </div>
                </div>
              )}
            </fieldset> */}
            <fieldset>
              <legend>
                <button
                  type="button"
                  className={styles.itemExpandBase}
                  onClick={toggleEstado}
                >
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span
                      className={
                        estadoVisible
                          ? 'icon-siarl icon-colapsar'
                          : 'icon-siarl icon-expandir'
                      }
                    />
                  </div>
                  <span className={styles.fieldsetTitulo}>Estado</span>
                </button>
              </legend>
              {estadoVisible && (
                <div
                  className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}
                >
                  <div>
                    <Field
                      name="estado"
                      label="Estado"
                      component={ComboBox}
                      textField={'descricao'}
                      filterable={true}
                      onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'mobilizacaoEstado')}}
                      data={combos?.mobilizacaoEstado}
                    />
                  </div>
                </div>
              )}
            </fieldset>
            {/* <fieldset>
              <legend>
                <button
                  type="button"
                  className={styles.itemExpandBase}
                  onClick={toggleEntidade}
                >
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span
                      className={
                        entidadeVisible
                          ? 'icon-siarl icon-colapsar'
                          : 'icon-siarl icon-expandir'
                      }
                    />
                  </div>
                  <span className={styles.fieldsetTitulo}>
                    Entidade responsável
                  </span>
                </button>
              </legend>
              {entidadeVisible && (
                <div
                  className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}
                >
                  <div>
                    <Field
                      name="id_entidade"
                      label="Entidade"
                      component={ComboBox}
                      textField={'nome'}
                      filterable={true}
                      onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'entidades')}}
                      data={combos?.entidades}
                    />
                  </div>
                </div>
              )}
            </fieldset> */}
          </FormElement>

          <div
            className={`${styles.footer} ${styles.justify_multiple_children}`}
          >
            <Button
              onClick={() => { formRenderProps.onFormReset(); clearMap()}}
              className={styles.btnLimparPesquisa}
              title='Limpar pesquisa'
            >
              <span className="icon-siarl icon-limparcampos"></span>
              <span className={styles.rotulo}>
                {' '}&nbsp;{' '}
                Limpar pesquisa
              </span>        
            </Button>

            <Button
              onClick={formRenderProps.onSubmit}
              className={styles.btnPesquisar}
              title='Pesquisar'
            >
              <span className="icon-siarl icon-pesquisar"></span>
              <span className={styles.rotulo}>
                {' '}&nbsp;{' '}
                Pesquisar
              </span> 
            </Button>
          </div>
        </>
      )}
    />
  )
}
