import { Button } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import {
  MouseEvent as ReactMouseEvent,
  useEffect,
  useState
} from 'react';

import { DatePicker } from '@progress/kendo-react-dateinputs';
import { Input, RadioGroup } from '@progress/kendo-react-inputs';
import SessionStorageService from '../../services/SessionStorageService';
import { coordenadasPoligono, fillVusAsync, handleGenericError, post, setCoordenadasPoligono, useCombos, vus } from '../../services/utils';
import styles from '../../styles/usodosolo/ConsultarUsoDoSoloTabsForm.module.scss';
import { AREA_VISIVEL, AUTH_LOCALSTORAGE, GET_GEOM_USO_SOLO, GET_VALOR_USO_DE_SOLO_BY_CUSTOM_FILTERS, NENHUM, POLIGONO_SELECAO } from '../../utils/constants';
import ImportarGeometriaComponent from '../apoiogestao/registar/ImportarGeometriaForm';
import ReactGA from 'react-ga4';

type IProps = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
}

export default function ValorUsoDoSoloForm({ onCloseClick }: IProps) {
  const [criterioGeograficoVisible, setCriterioGeograficoVisible] = useState(true)
  const [camadasVusVisible, setCamadaVusVisible] = useState(false)
  const [faixaSalvaGuardaVisible, setFaixaSalvaGuardaVisible] = useState(false)
  const [atualizacaoPrecoVisible, setAtualizacaoPrecoVisible] = useState(false)
  const [openImportModal, setOpenImportModal] = useState<any>();
  const [criterioGeograficoValue, setCriterioGeograficoValue] = useState<any>(false)
  const [geometriaImportada, setGeometriaImportada] = useState<any>("")
  const faixasSalvaGuarda = [
    { id: 1, descricao: "Faixa Nível 1 2050" },
    { id: 2, descricao: "Faixa Nível 2 2100" },
    { id: 0, descricao: "Nenhuma" }
  ]

useEffect(() => {
    
     if(!vus){
      fillVusAsync();
      }
    
    
  }, []);

  const getValoresUsoDoSolo = async (data: any) =>
    await post<any, any>(GET_VALOR_USO_DE_SOLO_BY_CUSTOM_FILTERS, data)

  const getGeomUsosDeSolo = async (data: any) =>
    await post<any, any>(`${GET_GEOM_USO_SOLO}`, data)

  const handleSubmit = async (e: any) => {
    try {
      postMessage({ type: 'showLoader' }, '*')
      const userLocal = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}')
      const formValues = e?.values;
      const dataToSend = {
        ...formValues,
        flatCoordinates: coordenadasPoligono ?? undefined,
        geom_imported: geometriaImportada?.geo,
        utilizador_id: userLocal?.user?.id,
        tecnico_registo: userLocal?.user?.name,
      }
      if(formValues?.ano){
        dataToSend["anoSelecionado"] = new Date(e?.values.ano).getFullYear();
      }
      const resultadoValoresUsoDoSolo = await getValoresUsoDoSolo(dataToSend);
      SessionStorageService.setItem(
        'resultadoValorUsoDoSoloPesquisa',
        JSON.stringify(resultadoValoresUsoDoSolo)
      )
      postMessage({ type: "form", value: "resultadoValorUsoDoSolo" }, "*");

    } catch (error) {
      console.log({ errorMsg: error })
      handleGenericError(error);

    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/ConsultarUsosSoloValor" });
  }, []);

  const toggleCriterioGeograficoVisible = () => {
    setCriterioGeograficoVisible(!criterioGeograficoVisible)
  }
  const toggleCamadasVusVisible = () => {
    setCamadaVusVisible(!camadasVusVisible)
  }
  const toggleAtualizacaoPrecoVisible = () => {
    setAtualizacaoPrecoVisible(!atualizacaoPrecoVisible)
  }
  const toggleFaixaSalvaGuardaVisible = () => {
    setFaixaSalvaGuardaVisible(!faixaSalvaGuardaVisible)
  }
  
  const clearMap = () => {
    try {

      setCoordenadasPoligono(undefined)
      const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: "true" }, '*')
      iframe.contentWindow?.postMessage({ type: "SiarlDeselectMapArea", value: "true" }, '*')

    } catch (error) {
      console.log({ error })
    }
  }

  const changerbCriterio = (e: any) => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlDeselectMapArea", value: 'true' }, '*')
    clearMap();
    if (e.value == POLIGONO_SELECAO) {
      postMessage({ type: "informationMsg", value: "Selecione a área de pesquisa" }, "*");
      const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.contentWindow?.postMessage({ type: "SiarlGeoPortalAdicionarArea", value: 'true' }, '*')
    }
    if (e.value == AREA_VISIVEL) {
      postMessage({ type: "informationMsg", value: "Foi selecionada a área visível do mapa" }, "*");
      const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.contentWindow?.postMessage({ type: "SiarlGetMapArea", value: 'true' }, '*')
      iframe.contentWindow?.postMessage({ type: "SiarlSelectMapArea", value: 'true' }, '*')
    }
    setGeometriaImportada("")
    setCoordenadasPoligono(undefined)
  }

  const handleBtnCriterioGeorgrafico = (formRenderProps: FormRenderProps) => {
    formRenderProps.onChange("rbCriterio", { value: POLIGONO_SELECAO })
    clearMap()
    setOpenImportModal(true)
  }

  const cancelImport = () => {
    setOpenImportModal(false);
  }

  const importGeom = async (obj: any) => {
    const iframe: any = document.getElementById('iframeGeoPortal')

    const dataToSend = { ...obj };

    if (dataToSend?.geom_imported?.includes("Freguesia")) {
      iframe.contentWindow?.postMessage({ type: "SiarlFreguesiaMark", value: [obj?.id] }, '*')
    }
    try {
      setGeometriaImportada({ geo: dataToSend?.geom_imported })
      console.log({ ...dataToSend })
      setCriterioGeograficoValue(POLIGONO_SELECAO)
      setOpenImportModal(false)
      const coordenadas = await getGeomUsosDeSolo(dataToSend);
      setCoordenadasPoligono(coordenadas);

    } catch (error) {
      postMessage({ type: "errorMsg", value: "importação sem sucesso." }, "*");
      console.log({ error })
    }
    finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }

  return (
    <>
      <Form
        onSubmitClick={handleSubmit}
        render={(formRenderProps: FormRenderProps) => (
          <>
            <FormElement className={styles.form}>

              <fieldset>
                <legend>
                  <button type='button' className={styles.itemExpandBase} onClick={toggleCriterioGeograficoVisible}>
                    <div className={styles.btn_Expandir_Colapsar}>
                      <span className={criterioGeograficoVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                    </div>
                    <span className={styles.fieldsetTitulo}>Critério geográfico</span>{' '}
                  </button>
                </legend>
                <div hidden={!criterioGeograficoVisible}>
                  <div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}>
                      <Field
                        id={'rbCriterio'}
                        name={'rbCriterio'}
                        layout={'horizontal'}
                        component={RadioGroup}
                        onChange={(e) => {
                          changerbCriterio(e)
                        }}
                        data={[
                          { label: 'Nenhum', value: NENHUM},
                          { label: 'Área visível', value: AREA_VISIVEL },
                          { label: 'Polígono de seleção', value: POLIGONO_SELECAO },
                        ]}
                        defaultValue={NENHUM} // Set the default selected value to 0 (Nenhum)
                      />
                    </div>
                </div>
              </fieldset>
              

              <fieldset>
                <legend>
                  <button type='button' className={styles.itemExpandBase} onClick={toggleCamadasVusVisible}>
                    <div className={styles.btn_Expandir_Colapsar}>
                      <span className={camadasVusVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                    </div>
                    <span className={styles.fieldsetTitulo}>Camadas de VUS </span>
                  </button>
                </legend>
                <div hidden={!camadasVusVisible}>
                  <div className={styles.espacoVerticalFieldSet}>

                    <Field
                      name="camadaVUS"
                      label={"Camadas de VUS"}
                      id={'vus'}
                      layout={'vertical'}
                      component={RadioGroup}
                      data={vus?.map(vus => ({
                        label: vus.descricao,
                        value: vus.id
                      }))}
                    />

                  </div>
                </div>
              </fieldset>

              <fieldset>
                <legend>
                  <button type='button' className={styles.itemExpandBase} onClick={toggleFaixaSalvaGuardaVisible}>
                    <div className={styles.btn_Expandir_Colapsar}>
                      <span className={faixaSalvaGuardaVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                    </div>
                    <span className={styles.fieldsetTitulo}>Faixa de salvaguarda </span>{' '}
                  </button>
                </legend>
                <div hidden={!faixaSalvaGuardaVisible}>
                  <div className={styles.espacoVerticalFieldSet}>
                    <div>
                      <Field
                        name="faixaSalvaguarda"
                        label={"Faixa de salvaguarda"}
                        layout={'vertical'}
                        component={RadioGroup}
                        data={faixasSalvaGuarda.map(faixa => ({
                          label: faixa.descricao,
                          value: faixa.id
                        }))}
                      />
                    </div>

                  </div>
                </div>
              </fieldset>

              <fieldset>
                <legend>
                  <button type='button' className={styles.itemExpandBase} onClick={toggleAtualizacaoPrecoVisible}>
                    <div className={styles.btn_Expandir_Colapsar}>
                      <span className={atualizacaoPrecoVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                    </div>
                    <span className={styles.fieldsetTitulo}>Atualização de preços</span>{' '}
                  </button>
                </legend>
                <div hidden={!atualizacaoPrecoVisible} className={styles.espacoVerticalFieldSet}>
                  <div>
                    <Field
                      name="atualizacaoDePreco"
                      label={"Atualização de preços"}
                      id={'atualizacaoDePreco'}
                      layout={'horizontal'}
                      component={RadioGroup}
                      data={
                        [{ label: "IPC", value: 0 }
                          , { label: "Manual", value: 1 }
                          , { label: "Atual", value: 2 }
                          , { label: "Ano", value: 3 }
                        ]
                      }
                    />
                  </div>

                  {formRenderProps?.valueGetter("atualizacaoDePreco") == 1 ?
                    <div>
                      <Field
                        name="valor"
                        label="Valor de atualização de preço"
                        component={Input}
                      />
                    </div>
                    : <></>}
                  {formRenderProps?.valueGetter("atualizacaoDePreco") == 3 ?
                    <div>
                      <Field
                        name="ano"
                        label="Data de atualização de preço"
                        format="dd/MM/yyyy"
                        component={DatePicker}
                      />
                    </div>
                    : <></>}
                </div>
              </fieldset>

            </FormElement>
            <div className={`${styles.footer} ${styles.justify_multiple_children}`}>

              <Button
                onClick={(e) => {
                  formRenderProps.onFormReset();
                  clearMap();
                  setCoordenadasPoligono(undefined)
                  setGeometriaImportada(undefined)
                }}
                className={styles.btnLimparPesquisa}
                title='Limpar pesquisa'
              >
                <span className="icon-siarl icon-limparcampos"></span>
                <span className={styles.rotulo}>
                  {' '}&nbsp;{' '}
                  Limpar pesquisa
                </span>              
              </Button>

              <Button
                onClick={formRenderProps.onSubmit}
                className={styles.btnPesquisar}
                title='Pesquisar'
              >
                <span className="icon-siarl icon-pesquisar"></span>
                <span className={styles.rotulo}>
                  {' '}&nbsp;{' '}
                  Pesquisar
                </span>            
              </Button>

            </div>
          </>
        )}
      />
      {openImportModal ?
        <ImportarGeometriaComponent cancelImport={cancelImport} onSubmit={importGeom} />
        : <></>}
    </>
  );

}