import React, { useState } from 'react';

interface DocumentPreviewProps {
  documentUrl: string;
  setUrlDoDocumento: any;
  hideClose?: boolean;
}

const DocumentPreview: React.FC<DocumentPreviewProps> = ({ documentUrl, setUrlDoDocumento, hideClose }) => {
  const [showPreview, setShowPreview] = useState(true);

  const handleClosePreview = () => {
    setUrlDoDocumento('');
    setShowPreview(false);
  };

  return (
    <>
      {showPreview && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
          }}
        >
          <div style={{ width: '80%', height: '80%', backgroundColor: '#fff' }}>
            <iframe
              src={documentUrl}
              title="Document Preview"
              style={{ width: '100%', height: '100%', border: 'none' }}
              allowFullScreen
            />
            { hideClose ? '' :
            <button
              onClick={handleClosePreview}
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                padding: '8px',
                backgroundColor: 'transparent',
                color: '#fff',
                border: 'none',
                cursor: 'pointer',
                fontSize: '16px',
              }}
            >
              Close
            </button>}
          </div>
        </div>
      )}
    </>
  );
};

export default DocumentPreview;
