
class SessionStorageService {
  private static instance: SessionStorageService;
  private storage: Record<string, unknown>;

  private constructor() {
    this.storage = {};
  }

  public static getInstance(): SessionStorageService {
    if (!SessionStorageService.instance) {
      SessionStorageService.instance = new SessionStorageService();
    }
    return SessionStorageService.instance;
  }

  public setItem(key: string, value: any): void {
    this.storage[key] = value;
  }

  public getItem(key: string): any | null {
    return this.storage[key] || null;
  }

  public getItens(): any | null {
    return this.storage;
  }

  public removeItem(key: string): void {
    delete this.storage[key];
  }

  public clear(): void {
    this.storage = {};
  }
  public clearExceptKeyOf(key:string): void {
    const item = this.getItem(key);
    this.storage = {}; 
    if (item) {
      this.storage[key] = item;
    }
  }
  
}

export default SessionStorageService.getInstance();