import { ComboBox, ComboBoxFilterChangeEvent } from '@progress/kendo-react-dropdowns'

import { Button } from '@progress/kendo-react-buttons'

import{
  DatePicker,
  DateTimePicker,
  MultiViewCalendar,
  MultiViewCalendarProps,
} from '@progress/kendo-react-dateinputs'

import{
  Field,
  Form,
  FormElement,
  FormRenderProps
} from '@progress/kendo-react-form'

import{
  Checkbox,
  Input,
  RadioGroup
} from '@progress/kendo-react-inputs'

import{
  MouseEvent as ReactMouseEvent,
  useEffect,
  useState
} from 'react'


import {
  arhs,
  capitania,
  concelhos,
  coordenadasSelected,
  del,
  deposicaoLocal1,
  deposicaoLocal2,
  deposicaoObjetivo1,
  deposicaoObjetivo2,
  distritos,
  entidades,
  entidadeUser,
  fillArhsAsync,
  fillCapitaniaAsync,
  fillCombosAuxiliares,
  fillConcelhosAsync,
  fillDeposicaoNivel1Async,
  fillDeposicaoNivel2Async,
  fillDistritosAsync,
  fillEntidadesAsync,
  fillFreguesiasAsync,
  fillMetodoDeposicaoAsync,
  fillMetodoDragagemAsync,
  fillMobilizacaoEstadoAsync,
  fillMobilizacaoFuncoesAsync,
  fillMobilizacaoTipologiaAsync,
  fillNuts1Async,
  fillNuts2Async,
  fillNuts3Async,
  fillObjetivoDeposicao1Async,
  fillObjetivoDeposicao2Async,
  fillQualidadeSedimentosAsync,
  freguesias,
  get,
  getFlatCoordinatesFromObj,
  getMobilizacaoSelected,
  granulametriaSedimentos,
  hasPermission,
  mancha1,
  mancha2,
  massaagua,
  metodoDeposicao,
  metotodoDragagem,
  mobilizacaoEstado,
  mobilizacaoFuncoes,
  nuts1,
  nuts2,
  nuts3,
  post,
  put,
  qualidadeSedimentos,
  setMobilizacaoSelected,
  stringToDate,
  tipologiaMob,
  tiposMobilizacao,
  useCombos
} from '../../services/utils'
import { useMobilizacaoStore } from '../../stores/mobilizacao'
import styles from '../../styles/intervencao/Intervencao.module.scss'
import { CURRENT_TIMEZONE, DELETE_MOBILIZACAO_CARACTERISTICA, GET_MOBILIZACAO_CARACTERISTICAS_BY_ID, GET_MOBILIZACAO_CARACTERISTICAS_BY_MOBILIZACAO, IMPORT_GEOM_MOBILIZACAO, INSERT_MOBILIZACAO_CARACTERISTICA, INTERVENCAO_MODULE_NAME, PERMISSION_DOMINIO_HIDRICO_PERFIL_BASICO, PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_EDICAO, PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_LEITURA, PERMISSION_DOMINIO_HIDRICO_PERFIL_TOTAL, PERMISSION_INTERVENCAO_PERFIL_BASICO, PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO, PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA, PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO, PESQUISAR_ENTIDADEBYUSER, PESQUISAR_MOBILIZACAO, SAVE_NEW_MOBILIZACAO, UPDATE_MOBILIZACAO, UPDATE_MOBILIZACAO_CARACTERISTICA } from '../../utils/constants'
import ImportarGeometriaComponent from '../apoiogestao/registar/ImportarGeometriaForm'
import CheckMoreItem from '../documentos/registar/fields/CheckMoreItem'
import { filterBy, FilterDescriptor } from '@progress/kendo-data-query'
import { Grid, GridColumn } from '@progress/kendo-react-grid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { process } from '@progress/kendo-data-query'
import { faCheck, faGlobeAmericas, faInfoCircle, faPencilSquare, faPenSquare, faRemove, faRing, faStream, faTicket } from '@fortawesome/free-solid-svg-icons'
import { Dialog } from '@progress/kendo-react-dialogs'
import { v4 as uuidv4 } from 'uuid';
import SessionStorageService from '../../services/SessionStorageService'
import moment from 'moment'
import "moment-timezone"

type IProps = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
  formRenderProps: FormRenderProps
  viewMode?: boolean
}

export default function IdentificaoMobilizacao({ onCloseClick, formRenderProps,viewMode }: IProps) {
  const [concelhosFiltred, setConcelhosFiltred] = useState<any>(undefined)
  const [concelhosDisabled, setConcelhosDisabled] = useState<any>(true)
  const [freguesiasFiltred, setFreguesiasFiltred] = useState<any>(undefined)
  const [freguesiasDisabled, setFreguesiasDisabled] = useState<any>(true)
  const [nuts2Filtered, setNuts2Filtered] = useState<any>(undefined)
  const [nuts2Disabled, setNuts2Disabled] = useState<any>(true)
  const [nuts3Filtered, setNuts3Filtered] = useState<any>(undefined)
  const [nuts3Disabled, setNuts3Disabled] = useState<any>(true)
  const [openImportModal, setOpenImportModal] = useState<any>()
  const [isModalOpened, setIsModalOpened] = useState<any>();
  const [dataIniExecucao, setDataIniExecucao] = useState<any>();
  const [dataFimExecucao, setDataFimExecucao] = useState<any>();
  const [openDataIni, setOpenDataIni] = useState(false);
  const [openDataFim, setOpenDataFim] = useState(false);
  const [volume, setVolume] = useState<any>();
  const [entidadesData, setEntidadesData] = useState<any>();
  const [entidadeResponsavel, setEntidadeResponsavel] = useState<any>();
  const [tipologia, setTipologia] = useState<any>();
  const [tipologiaExtracaoSelected, setTipologiaExtracaoSelected] = useState(false);
  const [tipologiaDeposicaoSelected, setTipologiaDeposicaoSelected] = useState(false)
  const [unicID, setUnicID] = useState<any>();
  const[deposicaoNive2Filtred, setDeposicaoNive2Filtred] = useState<any>();
  const [deposicaoNivel1Selected, setDeposicaoNivel1Selected] = useState<any>();
  const [deposicaoNivel2Selected, setDeposicaoNivel2Selected] = useState<any>();
  const [metodoExecucao, setMetodoExecucao] = useState<any>();
  const [manchaEmprestimo, setManchaEmprestimo] = useState<any>();
  const [metodoDeposicaoValue, setMetodoDeposicaoValue] = useState<any>();
  const [localDeposicao, setLocalDeposicao] = useState<any>();
  const [qualidadeSedimentosSelected, setQualidadeSedimentosSelected] = useState<any>();
  const [granularidadeSedimentos, setGranularidadeSedimentos] = useState<any>();
  const [batimetrica, setBatimetrica] = useState<any>();
  const [gridDataMobilizacaoCaracterizacao, setGridDataMobilizacaoCaracterizacao] = useState<any[]>([]);
  const [dataState, setDataState] = useState({
    skip: 0,
    take: 20,
    sort: [],
    group: [],
  });
  const [openModalDescartar, setOpenModalDescartar] = useState<any>()
  const [itemToRemove, setItemToRemove] = useState<any>();


  const [metodoDeposicaoData, setMetodoDeposicaoData] = useState(metodoDeposicao ? metodoDeposicao.slice() : []);

  const filterDataMetodoDeposicao = (filter: FilterDescriptor) => {
    const data = metodoDeposicao.slice();
    return filterBy(data, filter);
  };

  const filterChangeMetodoDeposicao  = (event: ComboBoxFilterChangeEvent) => {
    setMetodoDeposicaoData(filterDataMetodoDeposicao(event.filter));
  };


  const [page2, setPage2] = useState({ skip: 0, take: 10 });
  const [isEditing, setIsEditing] = useState(false);

  const [dataResult, setDataResult] = useState(
    process(gridDataMobilizacaoCaracterizacao, dataState)
  );
  const dataStateChange = (event: any) => {
    setDataResult(process(gridDataMobilizacaoCaracterizacao, event.dataState));
    setDataState(event.dataState);

    const skipPag: any = { skip: event.dataState.skip, take: event.dataState.take}
    setPage2({
  ...skipPag
})}
  const getEntidadeByUser = async (data: any) =>
    await get<any, any>(`${PESQUISAR_ENTIDADEBYUSER}/` + data)

  const getCaracteristicasPorMobilizacao = async (data: any) =>
    await get<any, any>(`${GET_MOBILIZACAO_CARACTERISTICAS_BY_MOBILIZACAO}/` + data)

  const getCaracteristicasPorId = async (data: any) =>
    await get<any, any>(`${GET_MOBILIZACAO_CARACTERISTICAS_BY_ID}/` + data)

  const updateCaracteristicaMobilizacao = async (data: any) =>
    await put<any, any>(`${UPDATE_MOBILIZACAO_CARACTERISTICA}`, data)

  const insertCaracteristicaMobilizacao = async (data: any) =>
    await post<any, any>(`${INSERT_MOBILIZACAO_CARACTERISTICA}`, data)
  
       useEffect(() => {
    
     if(!nuts1 ){
      fillNuts1Async();
      }
     if(!nuts2) {
      fillNuts2Async();
     }
     if(!nuts3){
      fillNuts3Async();
     }
     if(!distritos){
      fillDistritosAsync();
     }
     if(!concelhos){
      fillConcelhosAsync();
     }
     if(!freguesias){
      fillFreguesiasAsync();
     }
     if(!arhs){
      fillArhsAsync();
     }
     if(!capitania){
      fillCapitaniaAsync();
     }
     if(!mobilizacaoFuncoes){
      fillMobilizacaoFuncoesAsync();
     }
     if(!tipologiaMob){
      fillMobilizacaoTipologiaAsync();
     }
     if(!deposicaoLocal1){
      fillDeposicaoNivel1Async();
     }
     if(!deposicaoLocal2){
      fillDeposicaoNivel2Async();
     }
     if(!entidades){
      fillEntidadesAsync();
     }
     if(!mobilizacaoEstado){
      fillMobilizacaoEstadoAsync();
     }
     if(!metotodoDragagem){
      fillMetodoDragagemAsync();
     }
     if(!metodoDeposicao){
      fillMetodoDeposicaoAsync();
     }
     if(!deposicaoObjetivo1){
      fillObjetivoDeposicao1Async();
     }
      if(!deposicaoObjetivo2){
      fillObjetivoDeposicao2Async();
     }
     if(!qualidadeSedimentos){
      fillQualidadeSedimentosAsync();
     }
     if(getMobilizacaoSelected()?.utilizador) {
      getEntidadeByUser(getMobilizacaoSelected()?.utilizador?.id).then(resultEntidade => {
        formRenderProps.onChange('entidade_registo', { value: resultEntidade?.nome });
      });
    } else {
      formRenderProps.onChange('entidade_registo', { value: entidadeUser });
    } 
    setEntidadesData(entidades.slice());
    setDeposicaoNive2Filtred([]);

  }, []);

  const changeDeposicaoNivel1 = (e:any) => {
    const id = e?.value?.id;
    const deplst = deposicaoObjetivo2.filter(x => x.id_int_mob_objetivo_dep_nivel_1 == id);
    setDeposicaoNivel1Selected(e.value);
    setDeposicaoNive2Filtred(deplst);
  }

  const saveMobilizacao = async (data: any) => await post<any, any>(`${SAVE_NEW_MOBILIZACAO}`, data)
  const updateMobilizacao = async (data: any) => await put<any, any>(`${UPDATE_MOBILIZACAO}`, data)
  const deleteMobilizacaoCaracteristica = (id: number) => del(`${DELETE_MOBILIZACAO_CARACTERISTICA}/${id}`, {})

  const selectMobilizacaoResult = useMobilizacaoStore(
    (state) => state.selectMobilizacaoResult
  )
  const selectMobilizacaoResultId = useMobilizacaoStore(
    (state) => state.selectMobilizacaoResultId
  )

  const selectedMobilizacaoResult: any = useMobilizacaoStore(
    (state) => state.selectedMobilizacaoResult
  )

  const cancelImport = () => {
    setOpenImportModal(false)
  }
 

  const filterConcelho = (e: any) => {
    if (e?.value?.codigo) {
      const concelhosLocal = concelhos.filter(
        (x: any) => x.distrito_cod == e.value.codigo
      )
      setConcelhosFiltred(concelhosLocal)
      setConcelhosDisabled(false)
    } else {
      setConcelhosFiltred(concelhos)
      setConcelhosDisabled(true)
    }
  }

  const filterFreguesia = (e: any) => {
    if (e?.value?.codigo) {
      const freguesiaLocal = freguesias.filter(
        (x: any) => x.concelho_cod == e.value.codigo
      )
      setFreguesiasFiltred(freguesiaLocal)
      setFreguesiasDisabled(false)
    } else {
      setFreguesiasDisabled(true)
      setFreguesiasFiltred(freguesias)
    }
  }
  const filterNuts2 = (e: any) => {
    if (e?.value?.codigo) {
      const nutsLocal = nuts2.filter((x: any) => x.nuts_i_cod == e.value.codigo)
      setNuts2Filtered(nutsLocal)
      setNuts2Disabled(false)
    } else {
      setNuts2Disabled(true)
      setNuts2Filtered(nuts2)
    }
  }
  const filterNuts3 = (e: any) => {
    if (e?.value?.codigo) {
      const nutsLocal = nuts3.filter(
        (x: any) => x.nuts_ii_cod == e.value.codigo
      )
      setNuts3Filtered(nutsLocal)
      setNuts3Disabled(false)
    } else {
      setNuts3Disabled(true)
      setNuts3Filtered(nuts3)
    }
  }
  type combosKeys = 'mobilizacaoFuncoes'
  |'tipologiaMob' |'deposicaoLocal1'|'deposicaoLocal2' | 'mobilizacaoEstado'
  | 'entidades'|'deposicaoObjetivo2'|'qualidadeSedimentos'|'granulametriaSedimentos'
  | 'deposicaoObjetivo1'|'metodoDeposicao'|'metotodoDragagem'|'mancha2'|'mancha1'
  ;
  const [combos, setCombos] = useState<any>({
    mobilizacaoFuncoes, 
    tipologiaMob,
    deposicaoLocal1,
    deposicaoLocal2,
    mobilizacaoEstado,
    entidades,
    deposicaoObjetivo2, 
    qualidadeSedimentos,
    granulametriaSedimentos,
    deposicaoObjetivo1,
    metodoDeposicao,
    metotodoDragagem,
    mancha2,
    mancha1
  })
  const setDataOption: Record<combosKeys, { setFn: any, data: any[] }> = {
    mobilizacaoFuncoes: { setFn: setCombos, data: mobilizacaoFuncoes },
    tipologiaMob: { setFn: setCombos, data: tipologiaMob },
    deposicaoLocal1: { setFn: setCombos, data: deposicaoLocal1 },
    deposicaoLocal2: { setFn: setCombos, data: deposicaoLocal2 },
    mobilizacaoEstado: { setFn: setCombos, data: mobilizacaoEstado },
    entidades: { setFn: setCombos, data: entidades },

    deposicaoObjetivo2: { setFn: setCombos, data: deposicaoObjetivo2 },
    qualidadeSedimentos: { setFn: setCombos, data: qualidadeSedimentos },
    granulametriaSedimentos: { setFn: setCombos, data: granulametriaSedimentos },
    deposicaoObjetivo1: { setFn: setCombos, data: deposicaoObjetivo1 },
    metodoDeposicao: { setFn: setCombos, data: metodoDeposicao },
    metotodoDragagem: { setFn: setCombos, data: metotodoDragagem },
    mancha2: { setFn: setCombos, data: mancha2 },
    mancha1: { setFn: setCombos, data: mancha1 }

  };
  const filterCombo_Multiselect = (e: any, tipoCombo: combosKeys) => {
    const { setFn, data } = setDataOption[tipoCombo];
      setFn({
          ...combos,
          [tipoCombo]: filterBy(data?.slice()??[], e.filter)
      }
      );
}

 const canSeeMobilizacao = 
                            hasPermission(PERMISSION_INTERVENCAO_PERFIL_BASICO) ||
                            hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO) ||
                            hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA) ||
                            hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO) 
                            
const changeFileDoc = (e: any) => {
  setMobilizacaoSelected({...getMobilizacaoSelected(), filedoc_cod: e?.value})
}

const CustomCalendar = (props: MultiViewCalendarProps) => {
  return <MultiViewCalendar {...props} views={1} />
}

const confirmRemoveCaracterizacao = (item: any) => {
  setItemToRemove(item);
  setOpenModalDescartar(true);
}


const handleDeleteModal = async (e:any) => {
  const id = itemToRemove?.id;
  postMessage({type: "showLoader"}, "*");
  if (!isUUID(id)) {
    await deleteMobilizacaoCaracteristica(id);
  }
  const list = gridDataMobilizacaoCaracterizacao.filter(x => x.id != id);
  setGridDataMobilizacaoCaracterizacao(list);
  const ids = list.map(item => item.id);
  const iframe: any = document.getElementById('iframeGeoPortal')
  iframe.contentWindow?.postMessage({ type: "SiarlMobilizacaoCaraterizacaoClearLayers", value: "true" }, '*')
  iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: "true" }, '*')
  iframe.contentWindow?.postMessage({ type: "SiarlMobilizacaoCaraterizacaoSearch", value: ids }, '*')
  postMessage({type: "hideLoader"}, "*");
  setOpenModalDescartar(false);

}

const handleEditModal = (e:any) => {
  const item = e?.dataItem;
  setEntidadeResponsavel(item?.entidade);
  setTipologia(item?.tipologia);
  setVolume(item?.volume);
  if (item?.data_inicio_execucao) {
    setDataIniExecucao(stringToDate(item?.data_inicio_execucao));
  }
  if (item?.data_fim_execucao) {
    setDataFimExecucao(stringToDate(item?.data_fim_execucao));
  }
  if (item.td_mob_granulometria) {
    setGranularidadeSedimentos(combos?.granulametriaSedimentos?.find((x: any) => x.id == item.td_mob_granulometria));
  }
  if (item.td_mob_qualidade) {
    setQualidadeSedimentosSelected(combos?.qualidadeSedimentos?.find((x: any) => x.id == item.td_mob_qualidade));
  }

  if (item.tipo_mancha_nivel_1_id) {
    setManchaEmprestimo(combos?.mancha1?.find((x: any) => x.id == item.tipo_mancha_nivel_1_id));
  }

  if(item.tipo_metodo_dragagem_id) {
    setMetodoExecucao(combos?.metotodoDragagem?.find((x: any) => x.id == item.tipo_metodo_dragagem_id));
  }

  if (item.tipo_mob_metodo_deposicao) {
    setMetodoDeposicaoValue(combos?.metodoDeposicao?.find((x: any) => x.id == item.tipo_mob_metodo_deposicao));
  }

  if (item.tipo_mob_objetivo_dep_1_id) {
    setDeposicaoNivel1Selected(combos?.deposicaoObjetivo1?.find((x: any) => x.id == item.tipo_mob_objetivo_dep_1_id));
  }

  if (item.tipo_mob_objetivo_dep_2_id) {
    setDeposicaoNivel2Selected(deposicaoObjetivo2?.find((x: any) => x.id == item.tipo_mob_objetivo_dep_2_id));
  }

  setIsModalOpened(true);
}

const openModal =(e: any) => {
  setIsModalOpened(true);
}

const clearFieldsModal = () => {
  setDataFimExecucao(undefined);
  setDataIniExecucao(undefined);
  setTipologia(undefined);
  setEntidadeResponsavel(undefined);
  setDeposicaoNivel1Selected(undefined);
  setDeposicaoNivel2Selected(undefined);
  setVolume(undefined);
  setQualidadeSedimentosSelected(undefined)
  setGranularidadeSedimentos(undefined)
  setLocalDeposicao(undefined)
  setMetodoExecucao(undefined)
  setMetodoDeposicaoValue(undefined)
  setManchaEmprestimo(undefined)
}

useEffect(() => {
  if (getMobilizacaoSelected()?.id) {
    montGridFromDB();
  }
}, [])

const montGridFromDB = () => {
  const lstCaracteristicas : any[] = [];
    getCaracteristicasPorMobilizacao(getMobilizacaoSelected()?.id).then((result: any[]) => {
      result.forEach(item => {
        const dataNewRow = {
          id: item.id,
          hasGeom: item.hasGeom,
          data_inicio_execucao: moment.utc(item.data_inicio_execucao).tz(CURRENT_TIMEZONE).format("DD/MM/YYYY"),
          data_fim_execucao: moment.utc(item.data_fim_execucao).tz(CURRENT_TIMEZONE).format("DD/MM/YYYY"),
          tipologia: item.tipologia,
          entidade: item.entidade,
          volume: item.volume,
          batimetrica: item.batimetrica,
          freguesiasDisplay: item.freguesiasDisplay,
          tipo_mancha_nivel_1_id: item?.tipo_mancha_nivel_1_id,
          td_mob_qualidade: item?.td_mob_qualidade,
          td_mob_granulometria: item?.td_mob_granulometria,
          tipo_metodo_dragagem_id: item?.tipo_metodo_dragagem_id,
          tipo_mob_metodo_deposicao: item?.tipo_mob_metodo_deposicao,
          tipo_mob_objetivo_dep_1_id: item?.tipo_mob_objetivo_dep_1_id,
          tipo_mob_objetivo_dep_2_id: item?.tipo_mob_objetivo_dep_2_id,
          deposicaoNivel1: combos?.deposicaoObjetivo1?.find((x: any) => x.id == item.tipo_mob_objetivo_dep_1_id),
          deposicaoNivel2: combos?.deposicaoObjetivo2?.find((x: any) => x.id == item?.tipo_mob_objetivo_dep_2_id)
        }
        lstCaracteristicas.push(dataNewRow);
      })
      setGridDataMobilizacaoCaracterizacao(lstCaracteristicas);
      SessionStorageService.setItem("gridDataMobilizacaoCaracterizacao", lstCaracteristicas);
      const ids = lstCaracteristicas.map(item => item.id);

      const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.contentWindow?.postMessage({ type: "SiarlMobilizacaoCaraterizacaoSearch", value: ids }, '*')
    });
}

const geomInsert = (e: any) => {
  postMessage({type: "infoMsg", value: {message: "Desenhe a geometria no mapa"}})
  const iframe: any = document.getElementById('iframeGeoPortal')

  iframe.contentWindow?.postMessage({ type: "SiarlGeoPortalAdicionarArea", value: "true" }, '*')
  SessionStorageService.setItem("currentUnicGridIdMobilizacao", e?.dataItem?.id);
}

const handleModalSubmit = (e: any) => {
  console.log(dataIniExecucao);
  console.log(e);
    const dataNewRow = {
      id: isEditing ? unicID : uuidv4(),
      data_inicio_execucao: moment.utc(dataIniExecucao).tz(CURRENT_TIMEZONE).format("DD/MM/YYYY"),
      data_fim_execucao: moment.utc(dataFimExecucao).tz(CURRENT_TIMEZONE).format("DD/MM/YYYY"),
      tipologia: tipologia,
      deposicaoNivel1: deposicaoNivel1Selected,
      deposicaoNivel2: deposicaoNivel2Selected,
      entidade: entidadeResponsavel,
      tipo_mancha_nivel_1_id: manchaEmprestimo?.id,
      td_mob_qualidade: qualidadeSedimentosSelected?.id,
      td_mob_granulometria: granularidadeSedimentos?.id,
      tipo_metodo_dragagem_id: metodoExecucao?.id,
      tipo_mob_metodo_deposicao: metodoDeposicaoValue?.id,
      tipo_mob_objetivo_dep_1_id: deposicaoNivel1Selected?.id,
      tipo_mob_objetivo_dep_2_id: deposicaoNivel2Selected?.id,
      batimetrica: batimetrica,
      volume: volume
    }
    let caracteristicasLst: any[] = [];
    if (!isEditing) {
      caracteristicasLst = gridDataMobilizacaoCaracterizacao.slice();
      caracteristicasLst.push(dataNewRow);
      setGridDataMobilizacaoCaracterizacao(caracteristicasLst);

  } else {
    const caracteristicasLst = gridDataMobilizacaoCaracterizacao.filter(x => x.id != unicID);
    caracteristicasLst.push(dataNewRow);
    setGridDataMobilizacaoCaracterizacao(caracteristicasLst);
  }
  const item = caracteristicasLst.find(x => x.id === dataNewRow.id);
  if (item) {
    handleSubmit(item);
  } else {
    handleSubmit(dataNewRow);
  }
  setIsModalOpened(false);
  SessionStorageService.setItem("gridDataMobilizacaoCaracterizacao", caracteristicasLst);

}

const filterDataEntidades = (filter: FilterDescriptor) => {
  const data = entidades?.slice();
  return filterBy(data, filter);
};

const filterChangeEntidades = (event: ComboBoxFilterChangeEvent) => {
  setEntidadesData(filterDataEntidades(event?.filter));
};

const isUUID = (str: string) => {
  const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidPattern.test(str);
};

const handleSubmit = async (item: any) => {
  const id = isUUID(item.id) ? undefined : item.id; 
  let idMobilizacao = getMobilizacaoSelected()?.id;

  if (!idMobilizacao) {
     const result = await saveMobilizacao({})
     idMobilizacao = result.id;
     setMobilizacaoSelected(result);
  }
  const objToSend = {
    id: id,
    flatCoordinates: item.flatCoordinates,
    id_mobilizacao: idMobilizacao,
    data_inicio_execucao: item.data_inicio_execucao ? stringToDate(item.data_inicio_execucao) : undefined,
    data_fim_execucao: item.data_fim_execucao ? stringToDate(item.data_fim_execucao) : undefined,
    id_tipo_mo: item.tipologia?.id,
    id_entidade: item.entidade?.id,
    tipo_mancha_nivel_1_id: item?.tipo_mancha_nivel_1_id,
    td_mob_qualidade: item?.td_mob_qualidade,
    td_mob_granulometria: item?.td_mob_granulometria,
    tipo_metodo_dragagem_id: item?.tipo_metodo_dragagem_id,
    tipo_mob_metodo_deposicao: item?.tipo_mob_metodo_deposicao,
    tipo_mob_objetivo_dep_1_id: item?.tipo_mob_objetivo_dep_1_id,
    tipo_mob_objetivo_dep_2_id: item?.tipo_mob_objetivo_dep_2_id,
    batimetrica: item?.batimetrica,
    volume: Number.parseFloat(item?.volume)
  }
  let objResult;
  if (id) {
    objResult = await updateCaracteristicaMobilizacao(objToSend);
  } else {
    objResult = await insertCaracteristicaMobilizacao(objToSend);
  }
  montGridFromDB();
}

useEffect(() => {
  // Defina a função do event listener
  const handleMessage = async (event: MessageEvent) => {
    if (event.data.type === 'CoordenadasFlatSelected') {
      const polygonString = getFlatCoordinatesFromObj(event.data.value);
      const currentGridId = SessionStorageService.getItem("currentUnicGridIdMobilizacao");
      const grid: any[] = SessionStorageService.getItem("gridDataMobilizacaoCaracterizacao");
      const item = grid.find(x => x.id === currentGridId);
      item.flatCoordinates = polygonString;
      if (item) {
        handleSubmit(item);
      }
    }
  };


  window.addEventListener('message', handleMessage);

  return () => {
    window.removeEventListener('message', handleMessage);
  };
}, []);

const handleTipologiaChange = (e: any) => {
  if (e.value?.descricao?.toUpperCase() == "EXTRAÇÃO") {
    setTipologiaExtracaoSelected(true);
    setTipologiaDeposicaoSelected(false);
  } 
  else if (e.value?.descricao?.toUpperCase() == "DEPOSIÇÃO") {
    setTipologiaExtracaoSelected(false);
    setTipologiaDeposicaoSelected(true);
  } else {
    setTipologiaDeposicaoSelected(false);
    setTipologiaExtracaoSelected(false);
  }
}

  return (
    <>
      {canSeeMobilizacao ?
      <div className={`${styles.form} ${styles.removeScrollDuplicado}`}>
        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Registo</span>
          </legend>

          <div className={styles.espacoVerticalFieldSet}>
            <div className={styles.fields}>
              <div>
                <Field name="id" label="ID (Código)" component={Input} disabled />
              </div>
              <div>
                <Field name="filedoc_cod" onChange={changeFileDoc} label="Nº Processo APA" component={Input} disabled={viewMode} />
              </div>
              <div>
              <Field
                name="int_mobilizacao_estado"
                label="Estado"
                component={ComboBox}
                textField="descricao"
                filterable={true}
                onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'mobilizacaoEstado')}}
                data={combos?.mobilizacaoEstado}
                disabled={viewMode}
              />
            </div>
            </div>
            <div className={styles.fields}>
            <div >
              <Field
                  name="data_registo"
                  label="Data de Registo"
                  component={DatePicker}
                  calendar={CustomCalendar}
                  format="dd/MM/yyyy"
                  disabled                 
                />
              </div>
            <div>
            <Field
                name="utilizador_id"
                label="Técnico Registo"
                disabled
                component={Input}
              />              
              </div>
              <div>
            <Field
              name="entidade_registo"
              label="Entidade"
              disabled
              component={Input}
            />
            </div>
              </div>
              <div>
              </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Identificação</span>
          </legend>
          <div className={styles.espacoVerticalFieldSet}>
            <div>
              <div className={styles.fields}>
              
              <div>
                <Field 
                name="descricao"
                label="Descrição"
                component={Input}
                disabled={viewMode}
                />
              </div>
              </div>
              {/* <div className={styles.fields}>
              <div>
                <Field
                  name="id_int_mobilizacao_funcao"
                  label="Função"
                  component={ComboBox}
                  textField={'descricao'}
                  filterable={true}
                  onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'mobilizacaoFuncoes')}}
                  data={combos?.mobilizacaoFuncoes}

                 disabled={viewMode}
                />
              </div> */}

           {/* <div className={styles.fields}>
              <div>
                <Field
                  name="tipo_mancha_nivel_1_id"
                  label="Tipo de mancha de emprestimo nivel 1"
                  component={ComboBox}
                  textField={'descricao'}
                  filterable={true}
                  onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'mancha1')}}
                  data={combos?.mancha1}
                  disabled={viewMode}
                />
              </div>
              <div>
                <Field
                  name="tipo_mancha_nivel_2_id"
                  label="Tipo de mancha de emprestimo nivel 2"
                  component={ComboBox}
                  textField={'descricao'}
                  filterable={true}
                  onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'mancha2')}}
                  data={combos?.mancha2}
                  disabled={viewMode}
                />
              </div>
            </div>
            <div className={styles.fields}>
              <div>
                <Field
                  name="tipo_local_deposicao_1_id"
                  label="Tipo de local de deposição nivel 1"
                  component={ComboBox}
                  textField={'descricao'}
                  filterable={true}
                  onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'deposicaoLocal1')}}
                  data={combos?.deposicaoLocal1}
                  disabled={viewMode}
                  
                />
              </div>
              <div>
                <Field
                  name="tipo_local_deposicao_2_id"
                  label="Tipo de local de deposição nivel 2"
                  component={ComboBox}
                  textField={'descricao'}
                  filterable={true}
                  onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'deposicaoLocal2')}}
                  data={combos?.deposicaoLocal2}
                  disabled={viewMode}

                />
              </div>
            </div>
            <div className={styles.fields}>
            <div>
                <Field
                  name="tipo_metodo_dragagem_id"
                  label="Método de Dragagem"
                  component={ComboBox}
                  textField={'descricao'}
                  filterable={true}
                  onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'metotodoDragagem')}}
                  data={combos?.metotodoDragagem}
                  disabled={viewMode}

                />
              </div>
              <div>
                <Field
                  name="tipo_mob_metodo_deposicao"
                  label="Método de Deposição"
                  component={ComboBox}
                  textField={'descricao'}
                  filterable={true}
                  onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'metodoDeposicao')}}
                  data={combos?.metodoDeposicao}
                  disabled={viewMode}
                />
              </div>
            </div>
            
            <div className={styles.fields}>
              <div>
                <Field
                  name="td_mob_qualidade"
                  label="Qualidade dos Sedimentos"
                  component={ComboBox}
                  textField={'descricao'}
              //    data={qualidadeSedimentos}
                  filterable={true}
                  onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'qualidadeSedimentos')}}
                  data={combos?.qualidadeSedimentos}
                 disabled={viewMode}
                />
              </div>
            
              <div>
                <Field
                  name="td_mob_granulometria"
                  label="Granulometria dos Sedimentos"
                  component={ComboBox}
                  textField={'descricao'}
                  //data={granulametriaSedimentos}
                  filterable={true}
                  onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'granulametriaSedimentos')}}
                  data={combos?.granulametriaSedimentos}
                  disabled={viewMode}
                />
              </div>
              </div> */}
            </div>
          </div>
        </fieldset>
       <fieldset>
        <legend>
          <span className={styles.fieldsetTitulo}>Caracterização</span>
        </legend>
        <div>
        <div className={styles.fields}>
          <button
            title="Adicionar caracteristica"
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary buttonAdicionarNovo btnPrimaryColor"
            onClick={(e) => {
              clearFieldsModal();
              setIsEditing(false);
              openModal(e);
              e.preventDefault();
            } }
            disabled={viewMode}
          >
            <span className="icon-siarl icon-registar k-button-icon"></span>{' '}
            &nbsp;{' '}
            Inserir
          </button>
        </div>
      <div className={styles.fields}>
          <div style={{width: '920px'}}>
            <Grid
              className={'customGrid'}
              onDataStateChange={dataStateChange}
              data={dataResult.data.length > 0 ? dataResult : gridDataMobilizacaoCaracterizacao?.slice(page2.skip, page2.take + page2.skip)}
              pageable={{
                buttonCount: 5,
                info: false,
                type: 'numeric',
                pageSizes: true,
                previousNext: true,
              }}
              total={dataResult.data.length > 0 ? dataResult.total : gridDataMobilizacaoCaracterizacao.length}
              skip={page2.skip}
              take={page2.take}
            >
              <GridColumn width={86} title='Ações'
                cell={(props: any) => (
                  <td className="k-command-cell">
                    {!viewMode ? 
                    <div style={{ cursor: 'pointer' }} className={'gridCommand'}>
                      <div
                        role="button"
                        className={styles.gridCommandIconButton}
                        onClick={() => { handleEditModal(props)
                          setUnicID(props.dataItem?.id);
                          setIsEditing(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faInfoCircle} title="Editar" />
                      </div>
                      <div style={{cursor:'pointer'}}
                            role="button"
                            onClick={() => confirmRemoveCaracterizacao(props.dataItem)}
                            className={styles.gridCommandIconButton}
                          >
                            <FontAwesomeIcon icon={faRemove} title="Remover associação" />
                      </div>
                      <div
                        role="button"
                        className={styles.gridCommandIconButton}
                        onClick={() => geomInsert(props)}
                      >
                        <FontAwesomeIcon icon={faPencilSquare} title="Inserir geometria" />
                      </div>
                    </div>
                    : <></>}
                  </td>
                )} />
                 <GridColumn width={86} title='Geometria'
                cell={(props: any) => (
                  <td className="k-command-cell">
                    <div style={{ cursor: 'pointer' }} className={'gridCommand'}>
                      
                      <div>
                        {props.dataItem?.flatCoordinates || props.dataItem?.hasGeom ? 
                      <FontAwesomeIcon icon={faCheck} title="Geometria inserida" />
                      : <></>}
                      </div>
                      </div>
                  </td>
                )} />
                {/* <GridColumn width={150} className={styles.centerAlign} field='id' title='id' /> */}
              <GridColumn width={150} className={styles.centerAlign} field='tipologia.descricao' title='Tipo de Mobilização' />
              <GridColumn width={150} className={styles.centerAlign} field='data_inicio_execucao' title='Data inicio' />
              <GridColumn width={150} className={styles.centerAlign} field='data_fim_execucao' title='Data conclusão' />
              <GridColumn width={150} className={styles.centerAlign} field='volume' title='Volume' />
              <GridColumn width={150} className={styles.centerAlign} field='freguesiasDisplay' title='Localização' />
              <GridColumn width={150} className={styles.centerAlign} field='deposicaoNivel1.descricao' title='Deposicao N1' />
              <GridColumn width={150} className={styles.centerAlign} field='deposicaoNivel2.descricao' title='Deposicao N2' />
              {/* <GridColumn width={150} className={styles.centerAlign} field='flatCoordinates' title='Coordenadas' /> */}
              <GridColumn width={150} className={styles.centerAlign} field='entidade.nome' title='Entidade' />
            </Grid>
          </div>
        </div>
        </div>
      </fieldset>
      </div>:<></> }
      {isModalOpened ?
        <Dialog
        title="Caracterização - Mobilização"
        className="ModalInstumentoPlaneamento"
        onClose={() => setIsModalOpened(false)}
      >
        <Form
          render={(formRenderProps) => (
            <FormElement style={{ maxWidth: 620 }}>
              <div className="form removeScrollDuplicado">
                <fieldset>
                  <div className="espacoVerticalFieldSet">
                    <div className="fields">
                    <div>
                    <DatePicker
                      name="data_inicio_execucao"
                      label="Data de início de execução"
                      format="dd/MM/yyyy"
                      value={dataIniExecucao}
                      onChange={(e: any) => {
                        setDataIniExecucao(e.value);
                      }}
                      disabled={viewMode}
                    />
                  </div>
                  <div>
                  <DatePicker
                      name="data_fim_execucao"
                      label="Data de fim de execução"
                      format="dd/MM/yyyy"
                      value={dataFimExecucao}
                      onChange={(e: any) => {
                        setDataFimExecucao(e.value);
                      }}
                      disabled={viewMode}
                    />
                    
                  </div>
                  </div>
                  <div className="fields">
                      <Input name="volume" label="Volume em m3" type='number' value={volume}
                      onChange={(e: any) => {
                        setVolume(e.value)
                      }}
                      disabled={viewMode} />
                    </div>
                    <div className="fields">
                    <ComboBox
                          name="entidade_responsavel" 
                          label="Entidade responsável"
                          textField={'nome'}
                          data={entidadesData}
                          filterable={true}
                          onFilterChange={filterChangeEntidades}
                          value={entidadeResponsavel}
                           onChange={(e: any) => {
                            setEntidadeResponsavel(e.value)
                          }}
                      disabled={viewMode} />
                    </div>
                    <div className="fields">
                    <ComboBox
                          name="tipo_mobilizacao" 
                          label="Tipologia (Extração, Deposição)"
                          textField={'descricao'}
                          data={tiposMobilizacao}
                          filterable={true}
                          onFilterChange={filterChangeEntidades}
                          value={tipologia}
                           onChange={(e: any) => {
                            setTipologia(e.value)
                            handleTipologiaChange(e);
                          }}
                      disabled={viewMode} />
                    </div>
                  </div>
                  <div className={styles.fields}>
              <div>
                <ComboBox
                  name="tipo_mob_objetivo_dep_1_id"
                  label="Objetivo da Deposição nivel 1"
                  textField={'descricao'}
                  value={deposicaoNivel1Selected}
                  //data={deposicaoObjetivo1}
                  onChange={changeDeposicaoNivel1}
                  filterable={true}
                  onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'deposicaoObjetivo1')}}
                  data={combos?.deposicaoObjetivo1}
                  disabled={viewMode}
                  
                />
              </div>
              <div>
              <ComboBox
                  name="tipo_mob_objetivo_dep_2_id"
                  label="Objetivo da Deposição nivel 2"
                  textField={'descricao'}
                  data={deposicaoNive2Filtred}
                  value={deposicaoNivel2Selected}
                  onChange={(e) => {
                    setDeposicaoNivel2Selected(e?.value)
                  }}
                  disabled={viewMode}

                />
              </div>
              {tipologiaExtracaoSelected ? 
              <><div>
                          <ComboBox
                            name="tipo_metodo_dragagem_id"
                            label="Método de Execução"
                            textField={'descricao'}
                            filterable={true}
                            value={metodoExecucao}
                            onChange={(e) => {
                              setMetodoExecucao(e.value)
                            }}
                            onFilterChange={(e: any) => { filterCombo_Multiselect(e, 'metotodoDragagem') } }
                            data={combos?.metotodoDragagem}
                            disabled={viewMode} />
                        </div><div>
                            <ComboBox
                              name="tipo_mancha_nivel_1_id"
                              label="Tipo de mancha de empréstimo"
                              textField={'descricao'}
                              filterable={true}
                              value={manchaEmprestimo}
                              onChange={(e) => {
                                setManchaEmprestimo(e.value);
                              }}
                              onFilterChange={(e: any) => { filterCombo_Multiselect(e, 'mancha1') } }
                              data={combos?.mancha1}
                              disabled={viewMode} />
                          </div></>
              : 
              tipologiaDeposicaoSelected ?
              <><div>
                          <ComboBox
                            name="tipo_mob_metodo_deposicao"
                            label="Método de Deposição"
                            textField={'descricao'}
                            value={metodoDeposicaoValue}
                            onChange={(e) => {
                              setMetodoDeposicaoValue(e.value);
                            }}
                            filterable={true}
                            onFilterChange={filterChangeMetodoDeposicao}
                            data={metodoDeposicaoData}
                            disabled={viewMode} />
                        </div><div>
                            <ComboBox
                              name="tipo_local_deposicao_1_id"
                              label="Tipo de local de deposição"
                              textField={'descricao'}
                              filterable={true}
                              value={localDeposicao}
                              onChange={(e) => {
                                setLocalDeposicao(e.value)
                              }}
                              onFilterChange={(e: any) => { filterCombo_Multiselect(e, 'deposicaoLocal1') } }
                              data={combos?.deposicaoLocal1}
                              disabled={viewMode} />
                          </div>
                          <div>
                            <Input value={batimetrica}
                            label={'Batimétrica'}
                            placeholder='Batimétrica'
                            onChange={(e) => {setBatimetrica(e.value)}} type='text' />
                          </div>
                          </>
                          : <></>
              }
              <div className={styles.fields}>
              <div>
                <ComboBox
                  name="td_mob_qualidade"
                  label="Qualidade dos Sedimentos"
                  textField={'descricao'}
                  value={qualidadeSedimentosSelected}
                  onChange={(e) =>
                    setQualidadeSedimentosSelected(e.value)
                  }
              //    data={qualidadeSedimentos}
                  filterable={true}
                  onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'qualidadeSedimentos')}}
                  data={combos?.qualidadeSedimentos}
                 disabled={viewMode}
                />
              </div>
            
              <div>
                <ComboBox
                  name="td_mob_granulometria"
                  label="Granulometria dos Sedimentos"
                  textField={'descricao'}
                  value={granularidadeSedimentos}
                  onChange={(e) => {
                    setGranularidadeSedimentos(e.value)
                  }}
                  //data={granulametriaSedimentos}
                  filterable={true}
                  onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'granulametriaSedimentos')}}
                  data={combos?.granulametriaSedimentos}
                  disabled={viewMode}
                />
              </div>
              </div>
            </div>
                </fieldset>
              </div>
              <div className="k-form-buttons btnsModal">
              <button
                  type="button"
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                  onClick={() => setIsModalOpened(false)}
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                  onClick={handleModalSubmit}
                >
                  Submeter
                </button>
              </div>
            </FormElement>
          )}
        />
      </Dialog> : <></>
}

{openModalDescartar ? (
          <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={() => setOpenModalDescartar(false)}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                  <fieldset>
                    <span className="textDescartar">{'Apagar'} caracterização  </span>
                    <br></br>
                    <span>{'Tem a certeza que pretende apagar a caracterização?'}</span>
                  </fieldset>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={() => setOpenModalDescartar(false)}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={handleDeleteModal}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}
    </>
    
  )
}
