export const requiredFieldValidator = (value: string) =>
typeof value !== 'string' || !value.trim() ? 'Campo obrigatório.' : '';

export let isUsoSoSoloIdentificacaoValid: boolean

export const setIsUsoSoSoloIdentificacaoValid = (value: boolean) => {
  isUsoSoSoloIdentificacaoValid = value
}

export let isUsoSoSoloDetalhesValid: boolean

export const setIsUsoSoSoloDetalhesValid = (value: boolean) => {
  isUsoSoSoloDetalhesValid = value
}

export let isUsoSoSoloDocumentosValid: boolean

export const setIsUsoSoSoloDocumentosValid = (value: boolean) => {
  isUsoSoSoloDocumentosValid = value
}
