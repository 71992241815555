import {
  faEye,
  faPenToSquare,
  faTrash,
  faCopy,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from '../../styles/ocorrencia/ConsultarOcorrenciaForm.module.scss'
import { ConsultarMapasResult } from './ListarGeovisualizadores'
import { AUTH_LOCALSTORAGE } from '../../utils/constants'

type DataItem =
  ConsultarMapasResult[number]['gvSubTemas'][number]['gvMapaPartilhados'][number]

type Props = {
  onCopyClick: (dataItem: DataItem) => () => void
  onShowClick: (dataItem: DataItem) => () => void
  onEditClick?: (dataItem: DataItem) => () => void
  onDeleteClick?: (dataItem: DataItem) => () => void
  dataItem: DataItem
}

export const ActionsCell = ({
  onCopyClick,
  onShowClick,
  onEditClick,
  onDeleteClick,
  dataItem,
}: Props) => {
  const userLocal = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}')
  const belongsToTheCurrentUser = dataItem.id_utilizador === userLocal?.user?.id
  const isAdmin = userLocal?.user?.roles
    ?.map((role: { name: string }) => role?.name)
    ?.includes('Admin')

  return (
    <td className="k-command-cell">
      <div className={styles.actionsCell}>
        {onCopyClick ?
        <div
          role="button"
          onClick={onCopyClick(dataItem)}
          className={styles.actionIconButton}
          title='Copiar Link'
        >
          <FontAwesomeIcon icon={faCopy} />
        </div>
        : <></>}

        {onShowClick ?
        <div
          role="button"
          onClick={onShowClick(dataItem)}
          className={styles.actionIconButton}
          title='Ver Link'
        >
          <FontAwesomeIcon icon={faEye} />
        </div>
        : <></>}
        {belongsToTheCurrentUser || isAdmin ? (
          <>
          {onEditClick ?
            <div
              role="button"
              className={styles.actionIconButton}
              onClick={onEditClick(dataItem)}
              title='Editar Partilha Mapa'
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </div>
             : <></>}

            {onDeleteClick ?
            <div
              role="button"
              onClick={onDeleteClick(dataItem)}
              className={styles.actionIconButton}
              title='Apagar'
            >
              <FontAwesomeIcon icon={faTrash} />
            </div>
            : <></>}
          </>
        ) : (
          false
        )}
      </div>
    </td>
  )
}
