import {
  MouseEvent as ReactMouseEvent,
  useEffect,
  useState
} from 'react'

import {
  GridPageChangeEvent
} from '@progress/kendo-react-grid'

import { Button } from '@progress/kendo-react-buttons'
import { PagerTargetEvent } from '@progress/kendo-react-data-tools'
import { ComboBox, ComboBoxFilterChangeEvent } from '@progress/kendo-react-dropdowns'
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form'
import { Input, RadioGroup } from '@progress/kendo-react-inputs'
import {
  TabStripSelectEventArguments
} from '@progress/kendo-react-layout'
import SessionStorageService from '../../services/SessionStorageService'
import { agDecisoes, agEstados, agTema, arhs, capitania, concelhos, coordenadasPoligono, coordenadasSelected, distritos, entidades, fillAgEstadosAsync, fillAgTemaAsync, fillArhsAsync, fillCapitaniaAsync, fillCombosAuxiliares, fillConcelhosAsync, fillDistritosAsync, fillFreguesiasAsync, fillMassaAguaAsync, fillNuts1Async, fillNuts2Async, fillNuts3Async, fillTecnicosAsync, freguesias, get, getDesconformidadeSelected, handleGenericError, hasPermission, massaagua, nuts1, nuts2, nuts3, post, setCoordenadasPoligono, setDesconformidadeSelected, tecnicos, useCombos } from '../../services/utils'
import { useAgPretensaoStore } from '../../stores/agPretensao'
import styles from '../../styles/apoiogestao/ApoioGestao.module.scss'
import { AG_DESCONFORMIDADE_POINT_NAME, AREA_VISIVEL, CONSULTA_AG_DESCONFORMIDADE, GET_AG_ESTADOS, GET_AG_TEMAS, NENHUM, PERMISSION_APOIO_GESTAOPERFIL_INTERMEDIO_EDICAO, PERMISSION_APOIO_GESTAOPERFIL_INTERMEDIO_LEITURA, PERMISSION_APOIO_GESTAO_PERFIL_COMPLETO_EDICAO, PERMISSION_APOIO_GESTAO_PERFIL_COMPLETO_LEITURA, PERMISSION_APOIO_GESTAO_PERFIL_TOTAL, POLIGONO_SELECAO, clearLayers } from '../../utils/constants'
import { FilterDescriptor, filterBy } from '@progress/kendo-data-query'
import FieldsetCombosLocalizacao from '../localizacao/FieldsetCombosLocalizacao'
import ReactGA from 'react-ga4';
import { DateTimePicker } from '@progress/kendo-react-dateinputs'

type IProps = {
  onCloseClick?: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
}

type PageState = {
  skip: number
  take: number
}

const initialDataState: PageState = { skip: 0, take: 10 }


export default function DesconformidadeApoioGestao({ onCloseClick }: IProps) {

  const [selected, setSelected] = useState<number>(0)
  const [page, setPage] = useState<PageState>(initialDataState)
  const [pageSizeValue, setPageSizeValue] = useState<
    number | string | undefined
  >()
  const [concelhosFiltred, setConcelhosFiltred] = useState<any>()
  const [concelhosDisabled, setConcelhosDisabled] = useState<any>(true)
  const [freguesiasDisabled, setFreguesiasDisabled] = useState<any>(true)
  const [nuts2Filtered, setNuts2Filtered] = useState<any>()
  const [nuts3Filtered, setNuts3Filtered] = useState<any>()
  const [nuts2Disabled, setNuts2Disabled] = useState<any>(true)
  const [nuts3Disabled, setNuts3Disabled] = useState<any>(true)
  const [freguesiasFiltred, setFreguesiasFiltred] = useState<any>()
  const [criterioGeograficoVisible, setCriterioGeograficoVisible] =
    useState(false)
  const [rbCriteriosSelected, setRBCriteriosSelected] = useState<any>()
  const [dataResolucaoDe, setDataResolucaoDe] = useState<any>()
  const [dataResolucaoFim, setDataResolucaoFim] = useState<any>()
  const [tecnicosData, setTecnicosData] = useState(tecnicos ? tecnicos.slice() : []);
  const [dataRegistoInicio, setDataRegistoInicio] = useState<any>()
  const [dataRegistoFim, setDataRegistoFim] = useState<any>()
  const [minData, setMinDate] = useState<any>();
  const [entidadesData, setEntidadesData] = useState(entidades ? entidades.slice() : []);
  const [agDecisoesData, setAgDecisoesData] = useState(agDecisoes ? agDecisoes.slice() : []);

  type combosKeys= 'agTema' | 'agEstados' ;
  const [combos, setCombos] = useState<any>({
    agEstados,
    agTema  })
  const setDataOption: Record<combosKeys, { setFn: any, data: any[] }> = {
    agEstados: { setFn: setCombos, data: agEstados },
    agTema: { setFn: setCombos, data: agTema },
  };
  const filterCombo_Multiselect = (e: any, tipoCombo: combosKeys) => {
    const { setFn, data } = setDataOption[tipoCombo];
    setFn({
        ...combos,
        [tipoCombo]: filterBy(data?.slice()??[], e.filter)
    }
    );
}

useEffect(() => {
  ReactGA.send({ hitType: "pageview", page: "/ConsultarDesconformidade" });
  //ReactGA.pageview(window.location.pathname + "ConsultarDesconformidade")
}, []);

  useEffect(() => {
    if(!nuts1 ){
      fillNuts1Async();
      }
     if(!nuts2) {
      fillNuts2Async();
     }
     if(!nuts3){
      fillNuts3Async();
     }
     if(!distritos){
      fillDistritosAsync();
     }
     if(!concelhos){
      fillConcelhosAsync();
     }
     if(!freguesias){
      fillFreguesiasAsync();
     }
     if(!arhs){
      fillArhsAsync();
     }
     if(!capitania){
      fillCapitaniaAsync();
     }
     if(!massaagua){
      fillMassaAguaAsync();
     }
     if(!agTema){
      fillAgTemaAsync();
     }
     if(!agEstados){
      fillAgEstadosAsync();
     }
     if(!tecnicos){
      fillTecnicosAsync();
     }
       }, []);


  const getDesconformidade = async (data: any) =>
    await post<any, any>(`${CONSULTA_AG_DESCONFORMIDADE}`, data)
  const [registoVisible, setRegistoVisible] = useState(true)
  const [localizacaoVisible, setLocalizacaoVisible] = useState(false)
  const [identificacaoVisible, setIdentifcacaoVisible] = useState(false)
  const [observacaoVisible, setObservacaoVisible] = useState(false)
  const selectAgPretensaoResult = useAgPretensaoStore(
    (state) => state.selectAgPretensaoResult
  )
  const toggleRegisto = () => {
    setRegistoVisible(!registoVisible)
  }
  const toggleObservacao = () => {
    setObservacaoVisible(!observacaoVisible)
  }
  const toggleLocalizacao = () => {
    setLocalizacaoVisible(!localizacaoVisible)
  }

  const toggleCriterioGeografico = () => {
    setCriterioGeograficoVisible(!criterioGeograficoVisible)
  }

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected)
  }

  const filterDataEntidades = (filter: FilterDescriptor) => {
    const data = entidades.slice();
    return filterBy(data, filter);
  };

  
  const filterChangeEntidades = (event: ComboBoxFilterChangeEvent) => {
    setEntidadesData(filterDataEntidades(event.filter));
  };

  const filterDataDecisoes = (filter: FilterDescriptor) => {
    const data = agDecisoes.slice();
    return filterBy(data, filter);
  };

  
  const filterChangeDecisoes  = (event: ComboBoxFilterChangeEvent) => {
    setAgDecisoesData(filterDataDecisoes(event.filter));
  };
  const options = [

    {
      key: 'exportarFicha',
      text: 'Exportar ficha',
      icon: <span className="icon-siarl icon-exportar"></span>

    },
    {
      key: 'unknown',
      text: 'Unknown',
      icon: <span className="icon-siarl icon-unknown"></span>

    },
  ]

  const handlePageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent
    const take = event.page.take

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value)
    }

    setPage({
      ...event.page,
      take,
    })
  }

  const clearSearchResults = () => {
    postMessage({ type: 'resultadoUsoDoSoloPes«quisaChange' }, '*')
  }
  const selectMapAction = (item: any) => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: item.key, value: getDesconformidadeSelected()?.id, editGpLayer: AG_DESCONFORMIDADE_POINT_NAME }, '*')
  }

  const filterConcelho = (e: any) => {
    if (e?.value?.codigo) {
      const concelhosLocal = concelhos.filter(
        (x: any) => x.distrito_cod == e.value.codigo
      )
      setConcelhosFiltred(concelhosLocal)
      setConcelhosDisabled(false)
    } else {
      setConcelhosFiltred(concelhos)
      setConcelhosDisabled(true)
    }
  }

  const filterFreguesia = (e: any) => {
    if (e?.value?.codigo) {
      const freguesiaLocal = freguesias.filter(
        (x: any) => x.concelho_cod == e.value.codigo
      )
      setFreguesiasFiltred(freguesiaLocal)
      setFreguesiasDisabled(false)
    } else {
      setFreguesiasDisabled(true)
      setFreguesiasFiltred(freguesias)
    }
  }
  const filterNuts2 = (e: any) => {
    if (e?.value?.codigo) {
      const nutsLocal = nuts2.filter((x: any) => x.nuts_i_cod == e.value.codigo)
      setNuts2Filtered(nutsLocal)
      setNuts2Disabled(false)
    } else {
      setNuts2Disabled(true)
      setNuts2Filtered(nuts2)
    }
  }
  const filterNuts3 = (e: any) => {
    if (e?.value?.codigo) {
      const nutsLocal = nuts3.filter(
        (x: any) => x.nuts_ii_cod == e.value.codigo
      )
      setNuts3Filtered(nutsLocal)
      setNuts3Disabled(false)
    } else {
      setNuts3Disabled(true)
      setNuts3Filtered(nuts3)
    }
  }

  const toggleIdentificacao = () => {
    setIdentifcacaoVisible(!identificacaoVisible)
  }

  const clearMap = () => {
    try {

      setCoordenadasPoligono(undefined)
      const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.contentWindow?.postMessage({ type: "SiarlDesconformidadeClearLayers", value: "true" }, '*')
      iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: "true" }, '*')
      iframe.contentWindow?.postMessage({ type: "SiarlDeselectMapArea", value: "true" }, '*')

    } catch (error) {
      console.log({ error })
    }
  }

const changerbCriterio = (e: any) => {
  const iframe: any = document.getElementById('iframeGeoPortal')
  iframe.contentWindow?.postMessage({ type: "SiarlDeselectMapArea", value: 'true' }, '*')
  if (e.value == POLIGONO_SELECAO) {
      postMessage(
          { type: 'informationMsg', value: 'Selecione a área de pesquisa' },
          '*'
      )
      iframe.contentWindow?.postMessage(
          { type: 'SiarlGeoPortalAdicionarArea', value: 'true' },
          '*'
      )
  }
  if (e.value == AREA_VISIVEL) {
      iframe.contentWindow?.postMessage({ type: "SiarlSelectMapArea", value: 'true' }, '*')
      postMessage(
          {
              type: 'informationMsg',
              value: 'Foi selecionada a área visível do mapa',
          },
          '*'
      )
     
      iframe.contentWindow?.postMessage(
          { type: 'SiarlGetMapArea', value: 'true' },
          '*'
      )
  }
  if (e.value == NENHUM) {
      //setCoordenadasPoligono(undefined)
      clearMap()
  }
}
  
  const filterDataTecnicos = (filter: FilterDescriptor) => {
    const data = tecnicos.slice();
    return filterBy(data, filter);
  };

  const filterChangeTecnicos = (event: ComboBoxFilterChangeEvent) => {
    setTecnicosData(filterDataTecnicos(event.filter));
  };


  useEffect(() => {
    selectAgPretensaoResult(undefined);
  }, [])
  const handleSubmit = async (e: any) => {
    try {
      postMessage({ type: 'showLoader' }, '*')

      const filtroDesconformidadeRequest = {
        ...e?.values,
        nuts1_cod: e?.values?.nuts1?.codigo,
        nuts2_cod: e?.values?.nuts2?.codigo,
        nuts3_cod: e?.values?.nuts3?.codigo,
        distrito_cod: e?.values?.distrito?.codigo,
        concelho_cod: e?.values?.concelho?.codigo,
        freguesia_cod: e?.values?.freguesia?.codigo,
        capitania_cod: e?.values?.capitania?.codigo,
        arh_cod: e?.values?.arh?.codigo,
        massa_agua_id: e?.values?.massaAgua?.id,
        flatCoordinates: coordenadasPoligono,
        ag_estado_id: e?.values?.estado?.id,
        criterio_geografico: e?.values?.rbCriterio,
        ag_tema_id: e?.values?.tema?.id,
        tecnico_responsavel: e.values?.tecnico_responsavel ? e.values?.tecnico_responsavel.id : undefined,
        de_registo: e?.values?.deRegisto,
        ate_registo: e?.values?.ateRegisto,
        ag_decisao_id : e?.values?.ag_decisao?.id,
        descricao : e?.values?.descricao,
        num_processo_file_doc: e.values?.file_doc_numero
      }
      SessionStorageService.setItem("lastSearchDesconformidade", filtroDesconformidadeRequest);

      const filtroDesconformidadeResponse = await getDesconformidade(filtroDesconformidadeRequest);
      SessionStorageService.setItem(
        'resultadoApoioGestaoDesconformidade',
        JSON.stringify(filtroDesconformidadeResponse)
      )
      SessionStorageService.setItem("firstCallAgDesconformidade", true);

      postMessage({ type: "resultadoApoioGestaoDesconformidadePesquisaChange" }, "*");
    } catch (error) {
      handleGenericError(error);
    }
    finally {
      postMessage({ type: 'hideLoader' }, '*')

    }
  }

  const changeFileDoc = (e: any) => {
    setDesconformidadeSelected({...getDesconformidadeSelected(), num_processo_file_doc: e?.value});
  }

  return (
    <Form
      onSubmitClick={handleSubmit}
      render={(formRenderProps: FormRenderProps) => (
        <>
          <FormElement className={styles.form}>
            <fieldset>
              <legend>
                <button type='button' className={styles.itemExpandBase} onClick={toggleRegisto}>
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span className={registoVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                  </div>
                  <span className={styles.fieldsetTitulo}>Registo</span>
                </button>
              </legend>
              {registoVisible && (
                <div className={styles.espacoVerticalFieldSet}>
                  <div className={styles.fields} >
                    <div>
                      <Field name="id" label="ID" component={Input} />
                    </div>
                    {hasPermission(PERMISSION_APOIO_GESTAO_PERFIL_TOTAL) || hasPermission(PERMISSION_APOIO_GESTAO_PERFIL_COMPLETO_EDICAO) || hasPermission(PERMISSION_APOIO_GESTAO_PERFIL_COMPLETO_LEITURA) ?
                    <div>
                      <Field name="file_doc_numero" label="Nº Processo APA" component={Input} />
                    </div>
                    : ''}
                  </div>
                  
                  <div className={styles.fields}>
                    
                    <div>
                      <Field
                        name="tecnico_responsavel"
                        label="Técnico de registo"
                        component={ComboBox}
                        textField={'name'}
                        filterable={true}
                        onFilterChange={filterChangeTecnicos}
                        data={tecnicosData}
                      />
                    </div>
                    {
                      hasPermission(PERMISSION_APOIO_GESTAO_PERFIL_TOTAL) ||
                      hasPermission(PERMISSION_APOIO_GESTAO_PERFIL_COMPLETO_EDICAO) ||
                      hasPermission(PERMISSION_APOIO_GESTAO_PERFIL_COMPLETO_LEITURA) ||
                      hasPermission(PERMISSION_APOIO_GESTAOPERFIL_INTERMEDIO_LEITURA)
                      ?
                    <div>
                      <Field
                        name="estado"
                        label="Estado"
                        component={ComboBox}
                        textField={'descricao'}
                        filterable={true}
                        onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'agEstados')}}
                        data={combos?.agEstados}
                      />
                    </div>
                     : ''
                    }
                  </div>
                  
                  <div style={{ marginTop: 15 }}>

              <div className={styles.fieldsetSubTitulo}>
                  Data de Registo
              </div>
              <div className={styles.fields}>
                  <div>
                    <Field
                      name="deRegisto"
                      label="De"
                      value={dataRegistoInicio}
                      format="dd/MM/yyyy HH:mm"
                      component={DateTimePicker}
                      onChange={(e) => {
                        setDataRegistoInicio(e.value)
                        setMinDate(e.value);
                      }}     
                    />
                  </div>
                  <div>
                    <Field
                      name="ateRegisto"
                      label="Até"
                      value={dataRegistoFim}
                      format="dd/MM/yyyy HH:mm"
                      component={DateTimePicker}
                      onChange={(e) => {
                        setDataRegistoFim(e.value)
                      }}
                      min={minData}
                    />
                  </div>
                </div>

              <div className={styles.fields}>
                  <div>
                        <Field
                          name="entidade"
                          label="Entidade"
                          component={ComboBox}
                          textField={'nome'}
                          filterable={true}
                          onFilterChange={filterChangeEntidades}
                          data={entidadesData}
                        />
                      </div>
                </div>
                </div>
             </div>

              )}
            </fieldset>

            <fieldset>
              <legend>
                <button type='button' className={styles.itemExpandBase} onClick={toggleIdentificacao}>
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span className={identificacaoVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                  </div>
                  <span className={styles.fieldsetTitulo}>Identificação</span>
                </button>
              </legend>

              {identificacaoVisible && (
                <div className={styles.espacoVerticalFieldSet}>
                  <div className={styles.fields}>


                    <div>
                      <Field
                        name="tema"
                        label="Tema"
                        textField={'descricao'}
                        component={ComboBox}
                        filterable={true}
                        onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'agTema')}}
                        data={combos?.agTema}
                        />
                    </div>
                    
                  </div>
                  <div className={styles.fields}>
                  <div>
                    <Field
                      name="medida_corretiva_a_aplicar"
                      label="Medida corretiva a aplicar"
                      component={Input}
                    />
                  </div>
                </div>
                <div className={styles.fields}>

                <div>
                <Field
                  name="medida_corretiva_aplicada"
                  label="Medida corretiva aplicada"
                  component={Input}
                />
              </div> 
              </div>
                  <div className={styles.fields}>

                      <div className={styles.fields}>
                    <Field
                      name="ag_decisao"
                      label="Decisão"
                      component={ComboBox}
                      textField={'descricao'}
                      onFilterChange={filterChangeDecisoes}
                      data={agDecisoesData}
                    />
                  </div>
                  </div>
                  <div className={styles.fields}>
                    <div>
                      <Field
                        name="descricao"
                        label="Descrição da desconformidade"
                        component={Input}
                        maxLength={500}

                      />
                    </div>
                  </div>
                </div>
              )}
            </fieldset>
            <fieldset>
              <legend>
                <button type='button' className={styles.itemExpandBase} onClick={toggleCriterioGeografico}>
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span className={criterioGeograficoVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                  </div>
                  <span className={styles.fieldsetTitulo}>Critério geográfico</span>
                </button>
              </legend>
              {criterioGeograficoVisible ?
                <div className={styles.fields}>
                  <Field
                    id={'rbCriterio'}
                    name={'rbCriterio'}
                    layout={'horizontal'}
                    component={RadioGroup}
                    onChange={changerbCriterio}
                    data={[
                      { label: 'Nenhum', value: 0},
                      { label: 'Área visível', value: 1 },
                      { label: 'Polígono de seleção', value: 2 },
                    ]}
                    defaultValue={0} // Set the default selected value to 0 (Nenhum)

                  />
                </div> : <></>}
            </fieldset>
            <FieldsetCombosLocalizacao isPesquisaAvancada={true} haveLocal={false} styles={styles} formRenderProps={formRenderProps}/>
          </FormElement>
          <div className={`${styles.footer} ${styles.justify_multiple_children}`}>
            <Button
              onClick={() => { formRenderProps.onFormReset(); clearMap()}}
              className={styles.btnLimparPesquisa}
              title='Limpar pesquisa'
            >
              <span className="icon-siarl icon-limparcampos"></span>
              <span className={styles.rotulo}>
                {' '}&nbsp;{' '}
                Limpar pesquisa
              </span>
            </Button>
            <Button
              onClick={formRenderProps.onSubmit}
              className={styles.btnPesquisar}
              title='Pesquisar'
            >
              <span className="icon-siarl icon-pesquisar"></span>
              <span className={styles.rotulo}>
                {' '}&nbsp;{' '}
                Pesquisar
              </span>
            </Button>
          </div>
        </>
      )} />



  );
}