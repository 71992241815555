import {
  faEye,
  faInfoCircle,
  faLocationDot,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import styles from '../../styles/documentos/Documentos.module.scss'
import SessionStorageService from '../../services/SessionStorageService'
import { hasPermission } from '../../services/utils'
import clsx from 'clsx'

type HoverState = 'idle' | 'enter' | 'leave'

type Props = {
  onLocationClick: (documentos: Record<string, unknown>) => () => void
  onInfoClick: (documentos: Record<string, unknown>) => () => void
  onDocumentPreviewClick: (documentos: Record<string, unknown>) => () => void
  dataItem: Record<string, unknown>
}

export const ResultadoFotoDocumentoCommandGridCell = ({
  onLocationClick,
  onInfoClick,
  onDocumentPreviewClick,
  dataItem,
}: Props) => {
  const [locationHoverState, setLocationHoverState] =
    useState<HoverState>('idle')

  const handleMouseEnter = () => setLocationHoverState('enter')
  const handleMouseLeave = () => setLocationHoverState('leave')

  return (
    <td className="k-command-cell">
      <div className={'gridCommand'}>
        <div className='espacoGridAction'>&nbsp;</div>
        <div
            role="button"
            onClick={onLocationClick(dataItem)}
            className={clsx(styles.gridCommandIconButton, 'espacoGridAction')}
          >
            <FontAwesomeIcon
              icon={faLocationDot}
              title="Mostrar no Mapa"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
          </div>
        <div
          role="button"
          className={styles.gridCommandIconButton}
          onClick={onInfoClick(dataItem)}
        >
          <FontAwesomeIcon
            icon={faInfoCircle}
            title="Detalhes de registo"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        </div>
        {dataItem?.url_externa != undefined && dataItem.url_externa !== '' && (
          <div
            role="button"
            className={styles.gridCommandIconButton}
            onClick={onDocumentPreviewClick(dataItem)}
          >
            <FontAwesomeIcon icon={faEye} title="Visualizar" />
          </div>
        )}
      </div>
    </td>

  )
}
