import { GridCustomCellProps } from '@progress/kendo-react-grid'

type Props = {
  onMouseEnter: (ocorrencia: Record<string, unknown>) => void
  onMouseLeave: (ocorrencia: Record<string, unknown>) => void
} & GridCustomCellProps

export default function CustomGridCell(props: Props) {
  const handleMouseEnter = () => {
    props.onMouseEnter(props.dataItem)
  }

  const handleMouseLeave = () => {
    props.onMouseLeave(props.dataItem)
  }

  return (
    <td
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...props.tdProps}
      colSpan={1}
    >
      {props.children}
    </td>
  )
}
