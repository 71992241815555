
import {
    MouseEvent as ReactMouseEvent
} from 'react'

import { Button } from '@progress/kendo-react-buttons'
import { Field, Form } from '@progress/kendo-react-form'
import { blobToBase64, handleGenericError, post } from '../../services/utils'
import styles from '../../styles/ocorrencia/ConsultarOcorrenciaForm.module.scss'
import { UPLOAD_DOCUMENTO_VOO_DRONE } from '../../utils/constants'
import { requiredFieldValidator } from '../../validations/documento'
import ValidatedDocumentosUpload from '../ocorrencia/fields/ValidatedDocumentosUpload'

type Props = {
    onCloseClick: (
        e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
    ) => void | undefined
}

export default function SobreForm({ onCloseClick }: Props) {

    const saveDocumentoVooDrone = async (data: any) => await post<any, any>(UPLOAD_DOCUMENTO_VOO_DRONE, data)

    const handleSubmit = async (e: any) => {

        try {
            postMessage({ type: 'showLoader' }, '*')
            const arquivoRaw = e?.values?.arquivo?.[0]?.getRawFile()
            const nomeArquivo = arquivoRaw?.name

            const arquivo = await blobToBase64(arquivoRaw)

            await saveDocumentoVooDrone({
                arquivo: arquivo,
                nome_arquivo: nomeArquivo
            })

            setTimeout(() => {
                postMessage({ type: 'sucessMsg' }, '*')
            }, 500)
        } catch (error) {
            handleGenericError(error);
        } finally {
            postMessage({ type: 'hideLoader' }, '*')
        }
    }

    return (
        <div className={styles.base}>

            <div className={styles.header}>

                <div>
                    <div className={styles.nomeDoModulo}>
                        Sobre
                    </div>
                    <div className={styles.tituloDaPagina}>
                        Sobre
                    </div>
                </div>

                

        </div></div>
    )
}