import { AUTH_LOCALSTORAGE } from '../utils/constants'
import SessionStorageService from './SessionStorageService'
import { post, setGeoPortalUser } from './utils'

type LoginPayload = {
  username: string
  password: string
}

type LoginResponse = {
  user: {
    username: string
    role: string,
    name: string
  }
  token: string
}

export const login = async (data: LoginPayload) => {
  const loginResponse = await post<LoginPayload, LoginResponse>('/login', data)
  const localUserData = {
    ...loginResponse,
    loggedDate: new Date(),
  }
  if (loginResponse) {
    localStorage.setItem(AUTH_LOCALSTORAGE, JSON.stringify(localUserData))
    setGeoPortalUser(data);
  } else {
    await login(data);
  }
  return loginResponse;
}
