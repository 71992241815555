import { filterBy } from '@progress/kendo-data-query'
import { Button } from '@progress/kendo-react-buttons'
import { DatePicker, DateTimePicker, MultiViewCalendar, MultiViewCalendarProps } from '@progress/kendo-react-dateinputs'
import { ComboBox } from '@progress/kendo-react-dropdowns'
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form'
import { Input, RadioButton } from '@progress/kendo-react-inputs'
import { useEffect, useState } from 'react'
import { entidadeUser, entidades, estados, fillEntidadesAsync, fillEstadoAsync, fillIdiomasAsync, fillTipoAcessoAsync, fillTiposProcessoAsync, idioma, tipoacesso, tiporecurso } from '../../../../services/utils'
import { AUTH_LOCALSTORAGE } from '../../../../utils/constants'
import {  requiredFieldValidator, validateUrl } from '../../../../validations/documento'
import ValidatedDocumentosUploadSingle from '../../../ocorrencia/fields/ValidatedDocumentosUploadSingle'
import ValidatedDetalheComboBox from '../fields/ValidatedDetalheComboBox'
import ValidatedDocumentosInput from '../fields/ValidatedDocumentosInput'
import ValidatedIdentificacaoComboBox from '../fields/ValidatedIdentificacaoComboBox'
import ValidatedIdentificacaoInput from '../fields/ValidatedIdentificacaoInput'
import styles from '../../../../styles/documentos/Documentos.module.scss'

type Props = {
  formRenderProps?: FormRenderProps
  handleSubmitDocumento?: any
  handleSubmitExternalDocumento?: any
  setItem?: any
  close?: any
  isEntidades?: boolean
  isOcorrencias?: boolean
  isModelos?: boolean
}

export function CarregarDocumentoTabs({
  formRenderProps,
  handleSubmitDocumento,
  handleSubmitExternalDocumento,
  close
}: Props) {

  type combosKeys = 'entidades';

  const [combos, setCombos] = useState<any>({
    entidades,
  })
  const setDataOption: Record<combosKeys, { setFn: any, data: any[] }> = {
    entidades: { setFn: setCombos, data: entidades },
  };

  const filterCombo_Multiselect = (e: any, tipoCombo: combosKeys) => {
    const { setFn, data } = setDataOption[tipoCombo];
    setFn({
      ...combos,
      [tipoCombo]: filterBy(data?.slice() ?? [], e.filter)
    }
    );
  }

  useEffect(() => {

    if (!estados) {
      fillEstadoAsync();
    }
    if (!entidades) {
      fillEntidadesAsync();
    }
    if (!idioma) {
      fillIdiomasAsync();
    }
    if (!tipoacesso) {
      fillTipoAcessoAsync();
    }
    if (!tiporecurso) {
      fillTiposProcessoAsync();
    }

  }, []);


  const [opcaoSelecionada, setOpcaoSelecionada] = useState('ficheiro') // Inicializa com 'ficheiro'

  const handleInternalSubmitDocumento = (e: any) => {

    const tipo = e?.values?.tipoRecurso
    const descricao = e?.values?.descricao
    const titulo = e?.values?.titulo
    const data_registo = e?.values?.data_registo
    const data_publicacao = e?.values?.data_publicacao
    const autores = e?.values?.autores
    const filedoc_cod = e?.values?.filedoc_cod
    const estadoDoRegisto = e?.values?.estadoDoRegisto

    console.log(e?.values)

    const isLink = opcaoSelecionada === 'link';
    const isFicheiro = opcaoSelecionada === 'ficheiro';

    if (isFicheiro && !e?.values?.arquivo || e?.values?.arquivo?.length === 0 || e?.values?.titulo?.length === 0 || !e.values.tipoRecurso || !e.values?.data_publicacao || !e.values?.estadoDoRegisto) {
        postMessage(
          { type: 'errorMsg', value: { message: 'Preencha os campos obrigatórios' } },
          '*'
        )
        return
    }else if (isLink && validateUrl(e?.values?.url)){
      postMessage(
        { type: 'errorMsg', value: { message: 'O URL do link externo é inválido' } },
        '*'
      )
      return
    }

    const interno = 'Interno'
    const submetido = 'Submetido'

    const idioma_id = e?.values?.idioma?.id

    const tipo_acesso_id = tipoacesso.find(
      (item) => item.designacao.toUpperCase() === interno.toUpperCase()
    )
    const estado_id = estados.find(
      (item) => item.designacao.toUpperCase() === submetido.toUpperCase()
    )



    const tipo_recurso_id = e?.values?.tipoRecurso?.id

    if (isLink) {
      const url = e?.values?.url

      handleSubmitExternalDocumento({
        tipo,
        descricao,
        data_publicacao,
        url,
        idioma_id,
        estado_id,
        tipo_acesso_id,
        tipo_recurso_id,
        data_registo,
        titulo,
        autores,
        filedoc_cod,
        estadoDoRegisto
      })
    } else if (isFicheiro) {
      const arquivo = e?.values?.arquivo?.[0]?.getRawFile()
      const nomeArquivo = arquivo?.name
      handleSubmitDocumento({
        tipo,
        descricao,
        data_publicacao,
        arquivo,
        nomeArquivo,
        idioma_id,
        estado_id,
        tipo_acesso_id,
        tipo_recurso_id,
        data_registo,
        titulo,
        autores,
        filedoc_cod,
        estadoDoRegisto
      })
    }
  }

  const userId = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '')

  const CustomCalendar = (props: MultiViewCalendarProps) => {
    return <MultiViewCalendar {...props} views={1} />
  }

  return (
    <Form
      onSubmitClick={handleInternalSubmitDocumento}
      initialValues={{
        data_registo: new Date(), idioma: idioma[1], estadoDoRegisto: estados[0],
        data_publicacao: formRenderProps?.valueGetter("dataHoraRegistro"),
        entidade: entidadeUser,
        tipoRecurso: tiporecurso[12],
        autores: formRenderProps?.valueGetter("tecnico"),
        titulo: ''
      }}
      render={(formRenderProps) => (
        <FormElement>

       <span style={{ marginLeft: '20px' }}>Os campos com simbolo * (asterisco) são de preenchimento obrigatório</span>

          <div className='form tabstrip-container'>

            <fieldset style={{ paddingTop: 0, marginTop: 0 }}>

              <div className='espacoVerticalFieldSet'>

                <div className="fields" style={{ display: 'none' }}>
                  <Field
                    name="utilizador_id"
                    label="Utilizador"
                    component={ValidatedIdentificacaoInput}
                    data={userId}
                    disabled
                    hidden
                  />

                  <Field
                    name="idioma"
                    label="Idioma *"
                    component={ValidatedDetalheComboBox}
                    validator={requiredFieldValidator}
                    textField={'designacao'}
                    data={idioma}
                    disabled
                    hidden
                  />

                </div>

                <div className="fields">

                  <div>
                    <Field
                      name="titulo"
                      label="Titulo *"
                      component={ValidatedIdentificacaoInput}
                      validator={requiredFieldValidator}
                    />
                  </div>

                </div>

                <div className="fields">

                  <div>
                    <Field
                      name="data_publicacao"
                      label="Data da Publicação *"
                      format="dd/MM/yyyy"
                      component={DatePicker}
                      calendar={CustomCalendar}
                      validator={requiredFieldValidator}
                    />
                  </div>

                  <div>
                    <Field
                      name="filedoc_cod"
                      label="Nº de processo APA"
                      component={Input}
                      maxLength={25}
                    />
                  </div>
                </div>

                <div className="fields">
                  <div>
                    <Field
                      name="autores"
                      label="Autor(es)"
                      component={Input}
                      textField={'nome'}
                      maxLength={50}
                    />
                  </div>
                </div>

                <div className="fields">
                  <div>
                    <Field
                      name="entidade"
                      label="Entidade"
                      component={Input}
                      disabled
                    />
                  </div>
                </div>

                <div className="fields">

                  <div>
                    <Field
                      name="tipoRecurso"
                      label="Tipo de recurso *"
                      component={ValidatedDetalheComboBox}
                      validator={requiredFieldValidator}
                      data={tiporecurso}
                      textField={'designacao'}
                    />
                  </div>

                  <div>
                    <Field
                      name="estadoDoRegisto"
                      label="Estado *"
                      component={ValidatedIdentificacaoComboBox}
                      validator={requiredFieldValidator}
                      textField={'designacao'}
                      data={estados}
                    />
                  </div>
                </div>
              </div>
            </fieldset>

            <fieldset>
            <div className='espacoVerticalFieldSet'>
              <div className={styles.tipoFicheiro}>
                <RadioButton
                  id='ficheiro'
                  name="opcao"
                  label="Documento digital"
                  value="ficheiro"
                  checked={opcaoSelecionada === 'ficheiro'}
                  onChange={() => {
                    formRenderProps?.onChange('arquivo', { value: null });
                    setOpcaoSelecionada('ficheiro');
                  }}
                />
              </div>
              <div className={styles.tipoFicheiro}>
                <RadioButton
                  id='link'
                  name="opcao"
                  label="Link externo"
                  value="link"
                  checked={opcaoSelecionada === 'link'}
                  onChange={() => {
                    formRenderProps?.onChange('arquivo', { value: null });
                    setOpcaoSelecionada('link');
                  }}
                />
              </div>
            </div>

            </fieldset>

            <fieldset>
              <div className='espacoVerticalFieldSet'>
                <div className="fields">

                  {opcaoSelecionada === 'ficheiro' && (
                    <div>
                      {/* Campo que será mostrado quando "Tipo de Ficheiro" estiver selecionado  */}
                      <Field
                        name="arquivo"
                        // label="Clique aqui ou arraste o ficheiro para esta área"
                        component={ValidatedDocumentosUploadSingle}
                        validator={requiredFieldValidator}
                      />
                    </div>
                  )}

                  {opcaoSelecionada === 'link' && (
                    <div>
                      {/* Campo que será mostrado quando "Link Externo" estiver selecionado  */}
                      <Field
                        name="url"
                        label="URL *"
                        component={ValidatedDocumentosInput}
                        validator={validateUrl}
                        //validate={validateUrl}
                      />
                    </div>
                  )}

                </div>
              </div>
            </fieldset>

          </div>

          <div className="footerModal justify_multiple_children">

            <Button
              type="button"
              onClick={close}
              className='btnLimparPesquisa'
              title='Fechar'
            >
              <span className="icon-siarl icon-fechar btnIconRegistar"></span> &nbsp;{' '}
              Cancelar
            </Button>

            <Button
              type="button"
              onClick={formRenderProps.onSubmit}
              className="btnPesquisar"
            >
              Anexar
            </Button>
          </div>

        </FormElement>
      )}
    />
  )
}
