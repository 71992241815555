
import { Button, DropDownButton } from '@progress/kendo-react-buttons'
import { Form, FormElement, FormRenderProps, FormSubmitClickEvent } from '@progress/kendo-react-form'
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout'
import {
    MouseEvent as ReactMouseEvent, useEffect, useState
} from 'react'
import useClearMap from '../../hooks/useClearMap'
import { arhs, blobToBase64, del, get, getVooDronePathSelected, getVooDroneSelected, getkmlDetalheSelected, handleGenericError, hasPermission, post, put, setCoordenadasLinhaSelected, setVooDronePathSelected, setVooDroneSelected, setkmlDetalheSelected } from '../../services/utils'
import { useVooDroneStore } from '../../stores/vooDrone'
import styles from '../../styles/vooDrone/VooDrone.module.scss'
import { API_URL, AUTH_LOCALSTORAGE, DELETE_VOO_DRONE, DELETE_VOO_DRONE_PATH, GET_REGISTO_VOO_DRONE, PERMISSION_ATUALIZAR_VOO_DRONE, SAVE_VOO_DRONE, UPDATE_REGISTO_VOO_DRONE, UPDATE_VOO_DRONE, UPLOAD_DOCUMENTO_VOO_DRONE, UPLOAD_DOCUMENTO_VOO_DRONE_VIDEO, URL_AJUDA_VOO_DRONE, clearLayers } from '../../utils/constants'
import { AssociacaoListagem } from '../associacao/Associacao'
import { DocumentoListagem } from '../documentos/registar/documento/documento'
import Dropdowns from './Dropdowns'
import workerScript from './Worker'
import IdentificacaoVooDrone from './identificacao/IdentificacaoVooDrone'
import SessionStorageService from '../../services/SessionStorageService'
import ReactGA from 'react-ga4';
import { Dialog } from '@progress/kendo-react-dialogs'

type Props = {
    onCloseClick: (
        e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
    ) => void | undefined
}
export const formatsAcceptToSubmit: string[] = [".KML",".kml"];
export const VIDEO_FORMATS: string[] = [".MP4", ".mp4", "MP4", "mp4"];

export default function RegistarVooDroneForm({ onCloseClick }: Props) {
    const [totalFileSize, setTotalFileSize] = useState<number>(0) // equivale a 100% do width
    const [uploadedFileSize, setUploadedFileSize] = useState<number>(0)
    const uploadedFileSizeWidthToFill = ((uploadedFileSize * 100)/ totalFileSize).toFixed(2);
    const [errorUpload, setErrorUpload] = useState(false)
    const [selected, setSelected] = useState<number>(0)
    const [idPathVooDrone, setIdPathVooDrone] = useState<number>()
    const [canUpdate, setCanUpdate] = useState(false);
    const [canInsert, setCanInsert] = useState(false);
  
    const [editMode, setEditMode] = useState(false);
    const [viewMode, setViewMode] = useState(false);
    const videoWorker = new Worker(workerScript);
    videoWorker.addEventListener("message", (message)=>{
        const {data} = message;
        const {success, error,totalMB,loadedMB} = data;
        if(totalMB && totalMB>0 && totalFileSize==0){
            setTotalFileSize(totalMB)
        }
        if(typeof success === 'boolean' && !success){
            postMessage({ type: 'errorMsg' }, '*')
            videoWorker.terminate();
        }
        if(success){
            postMessage({type:"hideLoader"}, "*");
            postMessage({ type: 'infoMsg', value: {message:"Carregamento concluido"} } , '*')
            videoWorker.terminate();
            if(getVooDronePathSelected()?.isSubmitted){
                voltar()
            }
        }
        if(error){
            setErrorUpload(true)
        } 
        if(loadedMB){ // o progresso do upload loadedMB % width
            setUploadedFileSize(loadedMB);
            if(loadedMB===totalMB){
                postMessage({ type: 'showLoader' }, '*')
            }
        }
    })

    const [openModalDescartar, setOpenModalDescartar] = useState<any>()
    const [deleteClicked, setDeleteClicked] = useState(false);

    const cancelModalDescartar = () => {
      setOpenModalDescartar(false)
    }
  
    const handleOpenModalDescartar = (e:any) => {
        e.preventDefault();

      if (
        SessionStorageService.getItem('novoVooDrone') &&
        SessionStorageService.getItem('novoVooDrone') == 'true'
      ) {
        setOpenModalDescartar(true);
      } else {
        onEditItemClick("SiarlVisualizar");
        handleCancel();   
     }
    }

    const handleCancel = ()  => {
        videoWorker.terminate();
        clearMap(clearLayers["SiarlClearVooDroneLine"]);
        postMessage({ type: "form", value: "" }, "*");
        setTimeout(() => {
          postMessage({ type: "form", value: "registarVooDrone" }, "*");
        }, 10);
      }
  
    const selectVooDroneResult = useVooDroneStore(
        (state) => state.selectVooDroneResult
    )
    const selectVooDronePathResult = useVooDroneStore(
        (state) => state.selectVooDronePathResult
    )
    const selectVooDroneResultId = useVooDroneStore(
      (state) => state.selectVooDroneResultId
  )
    const selectedVooDroneResultId: any = useVooDroneStore(
      (state) => state.selectedVooDroneResultId
  )
    const selectedVooDroneResult: any = useVooDroneStore(
        (state) => state.selectedVooDroneResult
    )
    const selectedVooDronePathResult: any = useVooDroneStore(
        (state) => state.selectedVooDronePathResult
    )
    const getFileToSend=async(file:any)=>{
        const {name, extension, getRawFile} = file;
        const base64 = await blobToBase64(getRawFile())
        return {name, extension, base64}
    }
    const saveDocumentoVooDrone = async (data: any) => await post<any, any>(UPLOAD_DOCUMENTO_VOO_DRONE, data)
    const saveVooDrone = async (data: any) => await post<any,any>(SAVE_VOO_DRONE, data);
    const updateVooDrone = async (data: any) => await put<any,any>(UPDATE_VOO_DRONE, data);

    const updateVzRegistoVooDrone = async (data: any) => await put<any,any>(UPDATE_REGISTO_VOO_DRONE, data);
    const getRegistoVooDrone = async(data: any) => await get<any, any>(GET_REGISTO_VOO_DRONE + '/' + data);
    
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/RegistarVooDrone" });
      }, []);

    const handleSubmit = async (e: FormSubmitClickEvent) => {
        postMessage({ type: 'showLoader' }, '*')
        try {
          const formValues: any = e?.values;
          const descricao = formValues?.descricao;
          if(!descricao){
              postMessage({type:"hideLoader"}, "*");
              postMessage({ type: "errorMsg", value: {message:"Preencha o campo Descrição" }}, "*");
              return;
          }
          if(typeof descricao === "string"){
            const isInvalid = descricao.toLowerCase().includes("<empty string>") 
            ||descricao?.length===0
            ||descricao==="";
            if(isInvalid){
              postMessage({type:"hideLoader"}, "*");
              postMessage({ type: "errorMsg", value: {message:"Preencha o campo Descrição" }}, "*");
              return;
            }
          }
            
          let objToSend = {}
          const id = formValues["id"];
          if(id!= null && id>0){
            objToSend ={
              ...selectedVooDronePathResult,
              id, 
              ...formValues, 
              data_voo: new Date(formValues?.data_voo),
              data_registo:formValues?.data_registo? new Date(formValues?.data_registo):new Date(),
              id_vz_estado:formValues.vz_estado?.id,
              vz_estado:undefined, 
              arhs:undefined,
              concelhos:undefined,
              distritos:undefined,
              freguesias:undefined,
              nuts1:undefined, 
              nuts2:undefined, 
              nuts3:undefined, 
              

              
            }
            try{
              const resultUpdateVzRegistoVooDrone:any = await updateVzRegistoVooDrone(objToSend);
                if(resultUpdateVzRegistoVooDrone==null || resultUpdateVzRegistoVooDrone=="" || resultUpdateVzRegistoVooDrone == undefined){
                    return;
                }
            }catch(error){
                handleGenericError(error);
                return;
            }
          }else{
          // create 
            let  arquivos:any[] = formValues?.arquivo;
            let video = undefined;
            if(arquivos && arquivos.length>0 ){
                video =  arquivos.find((_arquivo:any)=>(VIDEO_FORMATS.includes(_arquivo.extension)))
                if(!video){
                  postMessage({type:"hideLoader"}, "*");
                  postMessage({ type: "errorMsg", value: {message:"Carregue o ficheiro de vídeo (*.MP4)" }}, "*");
                  return;
                }
                const nomeVideo:string = video?.name;
                const caracteresEspeciais = ["-", "@"];
                if (caracteresEspeciais.some(caractere => nomeVideo.includes(caractere))) {
                  postMessage({type:"hideLoader"}, "*");
                  postMessage({ type: "errorMsg", value: {message:"Ficheiro de vídeo não pode conter caracteres especias (-, _, @, etc)" }}, "*");
                  return;
               }
                arquivos = arquivos.filter((_arquivo:any)=>(!VIDEO_FORMATS.includes(_arquivo.extension)))
                if(arquivos.length===0){
                  postMessage({type:"hideLoader"}, "*");
                  postMessage({ type: "errorMsg", value: {message:"Carregue o ficheiro de rota de drone (*.KML)"} }, "*");
                  return;
                }
                if(arquivos.length>1){
                  postMessage({type:"hideLoader"}, "*");
                  postMessage({ type: "errorMsg", value: {message:"Remover ficheiros que não sejam necessários"} }, "*");
                  return;
                }
                const ficheiroDeRotaKML:string = arquivos.at(0).extension;
                if(!ficheiroDeRotaKML.toLowerCase().includes("kml")){
                  postMessage({type:"hideLoader"}, "*");
                  postMessage({ type: "errorMsg", value: {message:"Carregue o ficheiro de rota de drone (*.KML)"} }, "*");
                  return;
                }
                const prepareAsyncFilesBase64= arquivos?.map(async (_arquivo:any)=>{
                   return getFileToSend(_arquivo)
                 })
                 const arquivosBase64 = await Promise.all(prepareAsyncFilesBase64);
                objToSend = { arquivos:arquivosBase64};
               }else{
                postMessage({ type: "errorMsg", value: {message:"Carregue os ficheiros"} }, "*");
                return;
               }
            const vooDronePath = await saveDocumentoVooDrone({
                id_vz_estado:formValues?.vz_estado?.id,
                ...formValues,
                vz_estado:undefined,
                ...objToSend,
                ...getkmlDetalheSelected(),
                 id:idPathVooDrone??selectedVooDronePathResult?.id, 
                 voo_unique_guid:selectedVooDronePathResult?.voo_unique_guid,
                id_registo_voo_drone:selectedVooDronePathResult?.id_registo_voo_drone,
            })
            setIdPathVooDrone(vooDronePath?.id)
            if(!vooDronePath){
                 postMessage({ type: 'errorMsg' }, '*')
                 return;
             }
            selectVooDronePathResult({...selectedVooDronePathResult, ...vooDronePath});
            // const vooDrone =selectedVooDroneResult?.id
            // ?await updateVooDrone({...formValues, id:selectedVooDroneResult?.id, id_vz_voo_drone_path:vooDronePath?.id??selectedVooDronePathResult?.id, ...getkmlDetalheSelected()})
            // :await saveVooDrone({...formValues, id_vz_voo_drone_path:vooDronePath?.id??selectedVooDronePathResult?.id,...getkmlDetalheSelected()});
            if(video){
                const objForm = {
                    video:  video.getRawFile(),
                    id_voo_drone_path: vooDronePath?.id
                }
                let endpointUploadVideo= API_URL;
                if(endpointUploadVideo.endsWith("/")){
                  endpointUploadVideo = `${API_URL}${UPLOAD_DOCUMENTO_VOO_DRONE_VIDEO}`;
                }else{
                  endpointUploadVideo = `${API_URL}/${UPLOAD_DOCUMENTO_VOO_DRONE_VIDEO}`;

                }
                videoWorker.postMessage({
                    objForm,
                    url:`${endpointUploadVideo}`,
                    token: userLocal?.token
                    })
            }
            const registoVooDrone = await getRegistoVooDrone(vooDronePath.voo_unique_guid);
            selectVooDronePathResult({...selectedVooDronePathResult,
                 id_registo_voo_drone:registoVooDrone?.id,
                 id:vooDronePath?.id
                 });
                 selectVooDroneResultId(registoVooDrone?.id)

            const iframe: any = document.getElementById('iframeGeoPortal')
            iframe.contentWindow?.postMessage(
                { type: 'SiarlVooDroneLine', value: [registoVooDrone?.id] },
                '*'
              )
              try {
                  const {concelhos, distritos, freguesias, nuts1, nuts2, nuts3} = registoVooDrone || {}
                  selectVooDronePathResult({...selectedVooDronePathResult,concelhos, distritos, freguesias, nuts1, nuts2, nuts3, id_registo_voo_drone:registoVooDrone?.id });
                
              } catch (error) { 
                console.log({expected:error})
              }
            }
              // end create 

              postMessage({ type: 'sucessMsg' }, '*')

        } catch (error) {
            console.log({ error });
            handleGenericError(error);
        }finally{
            postMessage({type:"hideLoader"}, "*");
        }

    }

    const optionsEditar = [
        {
          key: 'SiarlEditar',
          text: 'Editar',
        },
        {
          key: 'SiarlApagar',
          text: 'Apagar',
        },
        
      ]

      const onEditItemClick= (event:any) => {
        if (event == "SiarlEditar" || event?.item?.key == "SiarlEditar") {
          setEditMode(true);
          setViewMode(false);
          return;
        }
        if (event == "SiarlVisualizar") {
          setEditMode(false);
          setViewMode(true);
        }
        if (event?.item?.key == "SiarlApagar") {
          setDeleteClicked(true);
          setOpenModalDescartar(true);
        }
      }

  const deleteRegistoVooDrone = (id: number) => del(`${DELETE_VOO_DRONE}/${id}`, {})
  const deleteVooDronePath = (id: number) => del(`${DELETE_VOO_DRONE_PATH}/${id}`, {})
      
  const handleDescartar = async (e: any) => {

    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
      {
        type: 'SiarlCoordenadaBoxClose',
        value: 'true',
      },
      '*'
    )

    if (selectedVooDroneResultId) {
      const result = await deleteRegistoVooDrone(selectedVooDroneResultId);
      e.preventDefault();
    }
    if(selectedVooDronePathResult?.id){
      const result = await deleteVooDronePath(selectedVooDronePathResult?.id);

    }
     setOpenModalDescartar(false)
     setDeleteClicked(false);
    postMessage({ type: 'form', value: 'vooDrone' }, '*')
    SessionStorageService.removeItem("firstCallVooDrone");

    SessionStorageService

   
    }

    useEffect(() => {
        setCanUpdate(hasPermission(PERMISSION_ATUALIZAR_VOO_DRONE));
    }, [])

    useEffect(() => {
        setViewMode(!editMode && SessionStorageService.getItem("novoVooDrone") != 'true');
      }, [])
      
      useEffect(() => {
        if (viewMode) {
          SessionStorageService.removeItem("novoVooDrone");
        }
      }, [viewMode])

    const voltar = () => {
        clearVooDroneMap()
        setVooDronePathSelected(undefined)
        selectVooDronePathResult(undefined)
        selectVooDroneResult(undefined)
        setVooDroneSelected(undefined)
        setkmlDetalheSelected(undefined)
        postMessage({ type: "form", value: "vooDrone" }, "*");

    }

     const [clearMap] = useClearMap();
     const clearVooDroneMap = () => {
    clearMap(clearLayers["SiarlClearVooDroneLine"]);
    setCoordenadasLinhaSelected(undefined)
    setVooDroneSelected(undefined)

  }
    
    const handleSelect = (e: TabStripSelectEventArguments) => {
        if (e.selected > 0 && !selectedVooDroneResult) {
            postMessage({type:"infoMsg", value: {message: "É necessário gravar primeiro."}})
            return;
          }
        setSelected(e.selected)
    }
    const innerStyleTabContent = {
        scrollBehavior: 'smooth',
        top: 55,
        height: '80vh',
    }
    const itemRender = (props: { item: any; itemIndex: number }) => {
        const isTitleItem = props.item.key === 'titulo'
        if (isTitleItem) {
          // Item de título personalizado
          return (
            <div className={styles.DropDownButtonTitulo}>
              <span>{props.item.text}</span>
            </div>
          )
        } else {
          // Demais itens
          return (
            <div className={styles.DropDownButtonItem}>
              <span>{props.item.text}</span>
            </div>
          )
        }
      }

      const openAjuda = () => {
        window.open(URL_AJUDA_VOO_DRONE, "_blank")
      }

     const userLocal = JSON.parse(
    localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}')
    return (
        <div className={styles.base}>
            <div className={styles.header}>
                <div>
                    <div className={styles.nomeDoModulo}>Video Aéreo</div>
                    <div className={styles.tituloDaPagina}>
                        {selectedVooDronePathResult?.id_registo_voo_drone
                            ? 'Editar registo'
                            : 'Novo registo'}
                    </div>
                </div>
                <div>
                {canUpdate && (viewMode || editMode) && (SessionStorageService.getItem("novoVooDrone") != 'true') ? 
                  <DropDownButton
                    items={optionsEditar}
                    className={styles.btnEditar2}
                    style={{marginTop: '-4px'}}
                    iconClass="icon-siarl icon-editar"
                    onItemClick={onEditItemClick}
                    fillMode={'flat'}
                    itemRender={itemRender}
                    title="Editar"
                  />
                : ''}
                    
                    <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
                        <span className="icon-siarl icon-ajuda"></span>
                    </button>

                    <button className={styles.btnFechar} onClick={voltar} title="Fechar">
                        <span className="icon-siarl icon-fechar"></span>
                    </button>
                </div>
                    {openModalDescartar ? (
          <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={cancelModalDescartar}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                  <fieldset>
                    <span className="textDescartar">{deleteClicked ? 'Apagar' : 'Cancelar'} registo  </span>
                    <br></br>
                    <span>{deleteClicked ? 'Ao apagar este registo deixará de ter acesso, sendo necessária uma intervenção rigorosa para realizar a sua recuperação.' : 'Ao cancelar este registo irá perder os dados inseridos até ao momento.'}</span>
                      <br></br>
                    <span>{deleteClicked ? 'Tem a certeza que pretende apagar?' : 'Tem a certeza que pretende cancelar?'}</span>
                  </fieldset>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={cancelModalDescartar}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={handleDescartar}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}
            </div>
            <Form
                onSubmitClick={handleSubmit}
                initialValues={{
                    ...selectedVooDroneResult,
                    tecnico_registo:userLocal?.user?.name ??"",
                    id_utilizador: userLocal?.user?.id,
                    ...getkmlDetalheSelected(),
                    ...selectedVooDronePathResult,
                    id:selectedVooDronePathResult?.id_registo_voo_drone,
                    data_registo: new Date(),
                }}
                render={(formRenderProps) => (
                    <FormElement>
                        <TabStrip
                          selected={selected}
                          onSelect={handleSelect}
                          className={`${styles.innerStyleTabContent} ${styles.backgroundWhite} ${viewMode? 'tabsVisualizar' : ''}`}
                        >        
                             <TabStripTab title="Identificação">
                                <IdentificacaoVooDrone viewMode={viewMode} formRenderProps={formRenderProps}/>
                            </TabStripTab>
                         
                        </TabStrip>
                     
                          {!viewMode ?
                            <div className={`${styles.footer_custom} ${styles.justify_multiple_children}`} >
                              {totalFileSize > 0 ?
                                <>
                                  <div className={styles.progress_bar} id="progress" title={`${uploadedFileSizeWidthToFill}% (${uploadedFileSize}mb / ${totalFileSize}mb)`}>
                                          <div
                                              className={errorUpload ? styles.progress_filled_error : styles.progress_filled}
                                              id="progressFill"
                                              style={{ width: `${uploadedFileSizeWidthToFill}%` }}
                                          >
                                          </div>
                                          <span className={styles.progress_label}>A carregar video {`${uploadedFileSizeWidthToFill}% (${uploadedFileSize}mb / ${totalFileSize}mb)`} </span>
                                  </div>
                                </>
                              : <></>}
                              
                              <>
                                <Button
                                  className={styles.btnAnterior}
                                  onClick={handleOpenModalDescartar}
                                >
                                  Cancelar
                                </Button>
                                <Button
                                  className={styles.btnSubmeter}
                                  onClick={(e) => {
                                      e.preventDefault();
                                      setVooDronePathSelected({
                                          ...selectedVooDronePathResult,
                                          isSubmitted: true
                                      })
                                      formRenderProps.onSubmit(e)
                                  }}
                                >
                                  <span className="icon-siarl icon-submeter btnIconRegistar"></span>
                                  {' '}&nbsp;{' '}Submeter
                                </Button></>
                            </div> 
                          : '' }
                    </FormElement>
                )}
            />
        </div>

    )
}