//  PROPRIEDADES KENDO / REACT
import { Button } from '@progress/kendo-react-buttons'
import { DateTimePicker } from '@progress/kendo-react-dateinputs'
import { ComboBox, MultiSelect } from '@progress/kendo-react-dropdowns'
import { Input, TextArea } from '@progress/kendo-react-inputs'

import {
  Field
} from '@progress/kendo-react-form'

import {
  MouseEvent as ReactMouseEvent,
  useEffect,
  useState
} from 'react'



//  ICONS USADOS


//import { useEntidadeStore } from '@stores/entidade'

import styles from '../../../styles/apoiogestao/ApoioGestao.module.scss'

//  IMPORTAR DADOS
import {
  agDecisoes,
  agEstados,
  agTipoPretensoes,
  arhs,
  capitania,
  concelhos,
  distritos,
  fillAgEstadosAsync,
  fillAgTipoPretensaosync,
  fillArhsAsync,
  fillCapitaniaAsync,
  fillCombosAuxiliares,
  fillConcelhosAsync,
  fillDecisoesAsync,
  fillDistritosAsync,
  fillFreguesiasAsync,
  fillNuts1Async,
  fillNuts2Async,
  fillNuts3Async,
  freguesias,
  get,
  getPretensaoSelected,
  massaagua,
  nuts1,
  nuts2,
  nuts3,
  post,
  setPretensaoSelected,
  useCombos
} from '../../../services/utils'

import { useAgPretensaoStore } from '../../../stores/agPretensao'
import { CREATE_PRETENSAO, GET_AG_DECISOES, GET_AG_ESTADOS, GET_AG_TIPO_PRETENSOES, GET_PRETENSAO, IMPORT_GEOM_PRETENSAO } from '../../../utils/constants'
import { requiredFieldValidator } from '../../../validations/usoDoSolo'
import CheckMoreItem from '../../documentos/registar/fields/CheckMoreItem'
import ImportarGeometriaComponent from './ImportarGeometriaForm'
import { filterBy } from '@progress/kendo-data-query'
import ValidatedInput from '../../forms/ValidatedInput'
type IProps = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined,
  viewMode: boolean
}

export default function PretensaoRegistar({ onCloseClick, viewMode }: IProps) {
  const [freguesiasFiltred, setFreguesiasFiltred] = useState<any[]>([])
  const [concelhosFiltred, setConcelhosFiltred] = useState<any>()
  const [nuts2Filtred, setNuts2Filtered] = useState<any>()
  const [nuts3Filtred, setNuts3Filtered] = useState<any>()

  const [concelhosDisabled, setConcelhosDisabled] = useState<any>(true)
  const [freguesiasDisabled, setFreguesiasDisabled] = useState<any>(true)
  const [freguesiaSelected, setFreguesiaSelected] = useState<any>()
  const [distritoSelected, setDistritoSelected] = useState<any>()
  const [concelhoSelected, setConcelhoSelected] = useState<any>()
  const [nuts1Selected, setNuts1Selected] = useState<any>()
  const [nuts2Selected, setNuts2Selected] = useState<any>()
  const [nuts3Selected, setNuts3Selected] = useState<any>()
  const [massaaguaSelected, setMassaaguaSelected] = useState<any>()
  const [arhSelectected, setArhSelected] = useState<any>()
  const [geomImported, setGeomImported] = useState<any>()

  const selectedAgPretensaoResultId: any = useAgPretensaoStore((state) => state.selectedAgPretensaoResultId)
  const selectedAgPretensaoResult: any = useAgPretensaoStore((state) => state.selectedAgPretensaoResult)
  const selectAgPretensaoResult: any = useAgPretensaoStore((state) => state.selectAgPretensaoResult)
  const selectAgPretensaoResultId: any = useAgPretensaoStore((state) => state.selectAgPretensaoResultId)


  const [openImportModal, setOpenImportModal] = useState<any>()

  
  type combosKeys = 'agEstados' | 'agTipoPretensoes' | 'agDecisoes' |
    'capitania' | 'arhs' | 'nuts1' | 'nuts2' | 'nuts3' |
    'distritos' | 'concelhos' | 'freguesias' | 'massaagua';
    const [combos, setCombos] = useState<any>({
      agEstados,
      agTipoPretensoes,
      agDecisoes,
      nuts1,
      nuts2:nuts2Filtred,
      nuts3:nuts3Filtred,
      distritos,
      concelhos:concelhosFiltred,
      freguesias:freguesiasFiltred,
      massaagua, 
      arhs,
      capitania
    })
  const setDataOption: Record<combosKeys, { setFn: any, data: any[] }> = {
    agEstados: {
      setFn: setCombos,
      data: agEstados
    },
    agTipoPretensoes: {
      setFn: setCombos,
      data: agTipoPretensoes
    },
    agDecisoes: {
      setFn: setCombos,
      data: agDecisoes
    },
    nuts1: { setFn: setCombos, data: nuts1 },
    nuts2: { setFn: setCombos, data: nuts2Filtred },
    nuts3: { setFn: setCombos, data: nuts3Filtred },
    distritos: { setFn: setCombos, data: distritos },
    concelhos: { setFn: setCombos, data: concelhosFiltred },
    arhs: { setFn: setCombos, data: arhs },
    capitania: { setFn: setCombos, data: capitania },
    freguesias: { setFn: setCombos, data: freguesiasFiltred },
    massaagua: { setFn: setCombos, data: massaagua },
  };
    const filterCombo_Multiselect = (e: any, tipoCombo: combosKeys) => {
      const { setFn, data } = setDataOption[tipoCombo];
        setFn({
            ...combos,
            [tipoCombo]: filterBy(data?.slice()??[], e.filter)
        }
        );
  }

  const saveAgPretensao = async (data: any) =>
    await post<any, any>(`${CREATE_PRETENSAO}`, data)
  const importGeomAgPretensao = async (data: any) =>
    await post<any, any>(`${IMPORT_GEOM_PRETENSAO}`, data)
  const getAgPretensaoById = async (data: any) =>
    await get<any>(`${GET_PRETENSAO}/` + data)

  
  useEffect(() => {

      if(!nuts1 ){
      fillNuts1Async();
      }
     if(!nuts2) {
      fillNuts2Async();
     }
     if(!nuts3){
      fillNuts3Async();
     }
     if(!distritos){
      fillDistritosAsync();
     }
     if(!concelhos){
      fillConcelhosAsync();
     }
     if(!freguesias){
      fillFreguesiasAsync();
     }
     if(!arhs){
      fillArhsAsync();
     }
     if(!capitania){
      fillCapitaniaAsync();
     }
     if(!agEstados){
      fillAgEstadosAsync();
     }
     if(!agTipoPretensoes){
      fillAgTipoPretensaosync();
     }
     if(!agDecisoes){
      fillDecisoesAsync();
     }
    
  }, []);

  

  const filterConcelho = (e: any) => {
    if (e?.value) {
      let distritos:any[]= e?.value
      distritos=  distritos.map((distrito)=> distrito.codigo);
      const concelhosLocal = concelhos.filter(concelho=>distritos.includes(concelho.distrito_cod))
      
      setConcelhosFiltred((prev:any)=>{
        return concelhosLocal
      })
    } 
  }

  const filterFreguesia = (e: any) => {
    if (e?.value) {
      let concelhos:any[]= e?.value
      concelhos=  concelhos.map((concelho)=> concelho.codigo);
      const freguesiaLocal = freguesias.filter(freguesia=>concelhos.includes(freguesia.concelho_cod))
      setFreguesiasFiltred((prev:any)=>{
          return freguesiaLocal
      })
    }
  }
  const filterNuts2 = (e: any) => {
    if(e?.value){
      let _nuts1:any[]= e?.value
      _nuts1=  _nuts1.map((nut1)=> nut1.codigo);
     const _nuts2 = nuts2.filter(nt2=>_nuts1.includes(nt2.nuts_i_cod))
     setNuts2Filtered(_nuts2)
    }
   
  }
  const filterNuts3 = (e: any) => {
    if(e?.value){
      let _nuts2:any[]= e?.value
      _nuts2=  _nuts2.map((nut2)=> nut2.codigo);
     const _nuts3 = nuts3.filter(nt3=>_nuts2.includes(nt3.nuts_ii_cod))
     console.log({_nuts3})
     setNuts3Filtered(_nuts3)
    }
  }

 

  const changeFileDoc = (e:any) => {
    setPretensaoSelected({...getPretensaoSelected(), file_doc_numero: e?.value})
  }

  const PermitirNumeros = (e: React.ChangeEvent<HTMLInputElement>) => {
    const ApenasNumeros = e.target.value.replace(/\D/g, '');
    e.target.value = ApenasNumeros;
  };

  return (
    <>
      <div className={`${styles.form} ${styles.removeScrollDuplicado}`}>

       <span style={{ marginLeft: '08px' }}>Os campos com simbolo * (asterisco) são de preenchimento obrigatório</span>

        <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Registo</span>
          </legend>

          <div className={styles.espacoVerticalFieldSet}>

            <div className={styles.fields}>
              <div>
                <Field name="id" disabled={true} label="ID" component={Input} />
              </div>

              <div>
                <Field
                  name="file_doc_numero"
                  label="Nº Processo APA"
                  component={Input}
                  maxLength={25}
                  onChange={changeFileDoc}
                  disabled={viewMode}
                />
              </div>
              <div>
                <Field
                  name="estado"
                  label="Estado"
                  textField={'descricao'}
                  component={ComboBox}
                  filterable={true}
                  onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'agEstados')}}
                  data={combos?.agEstados}
                  disabled={viewMode}
                />
              </div>
            </div>
            <div className={styles.fields}>
          <div>
              <Field
                name="data_registo"
                label="Data registo"
                component={DateTimePicker}
                disabled
              />
            </div>
            <div>
            <Field
                name="utilizador_registo"
                label="Técnico de registo"
                disabled
                component={Input}
              />
            </div>
            <div>
            <Field
              name="entidade_registo"
              label="Entidade"
              disabled
              component={Input}
            />
            </div>
          </div>
            </div>
            </fieldset>
            <fieldset style={{paddingBottom: '10px'}}>
          <legend>
            <span className={styles.fieldsetTitulo}>Identificação</span>
          </legend>
            <div className={styles.fields}>
              <div>
                <Field
                  name="numeroProcesso"
                  label="Nr. Processo"
                  component={Input}
                  maxLength={50}
                  disabled={viewMode}
                />
              </div>
              
            </div>
            <div className={styles.fields}>
            <div>
                <Field
                  name="tipoPretensao"
                  label="Tipo Pretensão"
                  textField={'descricao'}
                  component={ComboBox}
                  filterable={true}
                  onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'agTipoPretensoes')}}
                  data={combos?.agTipoPretensoes}
                  disabled={viewMode}
                />
              </div>
              <div>
                <Field
                  name="decisao"
                  label="Decisão"
                  textField={'descricao'}
                  component={ComboBox}
                  filterable={true}
                  onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'agDecisoes')}}
                  data={combos?.agDecisoes}
                  disabled={viewMode}
                />
              </div>
              </div>
              <div className={styles.fields}>
              <div>
                <Field
                  name="data_decisao"
                  label="Data decisão"
                  format="dd/MM/yyyy HH:mm"
                  component={DateTimePicker}
                  disabled={viewMode}
                />
              </div>
              <div>
                <Field
                  name="dataEntrada"
                  label="Data entrada"
                  format="dd/MM/yyyy HH:mm"
                  component={DateTimePicker}
                  disabled={viewMode}
                />
              </div>
            </div>
            <div className={styles.fields}>
              <Field
                name="assunto"
                label="Assunto *"
                component={ValidatedInput}
                validator={requiredFieldValidator}
                maxLength={255}
                disabled={viewMode}
              />
            </div>
            <div className={styles.fields}>
              <div>
                <Field
                  name="nifRequerente"
                  label="NIF do requerente"
                  component={Input}
                  onInput={PermitirNumeros}
                  maxLength={9}
                  disabled={viewMode}
                />
              </div>
              <div>
                <Field
                  name="nomeRequerente"
                  label="Nome do requerente"
                  component={Input}
                  maxLength={250}
                  disabled={viewMode}
                />
              </div>
            </div>
            </fieldset>
            <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>Observações</span>
          </legend>
          <div className={styles.espacoVerticalFieldSet}>
            <div className={styles.fields}>
              <Field
                name="obs"
                label="Observações"
                placeHolder='Observações gerais'
                component={TextArea}
                disabled={viewMode}
                textField={'nome'}
                maxLength={500}
              />
            </div>
          </div>
        </fieldset>
       
        <fieldset>
          <legend>
            <span style={{paddingBottom: '10px'}} className={styles.fieldsetTitulo}>Localização</span>
          </legend>

          <div className={styles.espacoVerticalFieldSet}>
            <div className={styles.fields}>
              {selectedAgPretensaoResult ? (
                <div>
                  <CheckMoreItem
                    list={selectedAgPretensaoResult?.nuts1}
                    property={'nome'}
                    label="NUTS I"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="nuts1"
                    label="NUTS I"
                    component={MultiSelect}
                    textField={'nome'}
                    onChange={filterNuts2}
                    disabled
                    filterable={true}
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'nuts1')}}
                    data={combos?.nuts1}
                  />
                </div>
              )}
              {selectedAgPretensaoResult ? (
                <div>
                  <CheckMoreItem
                    list={selectedAgPretensaoResult?.nuts2}
                    property={'nome'}
                    label="NUTS II"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="nuts2"
                    label="NUTS II"
                    component={MultiSelect}
                    textField={'nome'}
                    onChange={filterNuts3}
                    disabled
                    filterable={true}
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'nuts2')}}
                    data={combos?.nuts2??nuts2Filtred}
                  />
                </div>
              )}
              {selectedAgPretensaoResult ? (
                <div>
                  <CheckMoreItem
                    list={selectedAgPretensaoResult?.nuts3}
                    property={'nome'}
                    label="NUTS III"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="nuts3"
                    label="NUTS III"
                    component={MultiSelect}
                    textField={'nome'}
                    filterable={true}
                    disabled
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'nuts3')}}
                    data={combos?.nuts3??nuts3Filtred}
                  />
                </div>
              )}
            </div>

            <div className={styles.fields}>
              {selectedAgPretensaoResult ? (
                <div>
                  <CheckMoreItem
                    list={selectedAgPretensaoResult?.distritos}
                    property={'nome'}
                    label="Distrito"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="distrito"
                    label="Distrito"
                    component={MultiSelect}
                    textField={'nome'}
                    onChange={filterConcelho}
                    filterable={true}
                    disabled
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'distritos')}}
                    data={combos?.distritos}
                  />
                </div>
              )}
              {selectedAgPretensaoResult ? (
                <div>
                  <CheckMoreItem
                    list={selectedAgPretensaoResult?.concelhos}
                    property={'nome'}
                    label="Concelho"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="concelho"
                    label="Concelho"
                    component={MultiSelect}
                    onChange={filterFreguesia}
                    textField={'nome'}
                    disabled
                    filterable={true}
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'concelhos')}}
                    data={combos?.concelhos??concelhosFiltred}
                  />
                </div>
              )}
              {selectedAgPretensaoResult ? (
                <div>
                  <CheckMoreItem
                    list={selectedAgPretensaoResult?.lstFreguesias}
                    property={'nome'}
                    label="Freguesia"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="freguesia"
                    label="Freguesia"
                    component={MultiSelect}
                    textField={'nome'}
                    filterable={true}
                    disabled
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'freguesias')}}
                    data={combos?.freguesias??freguesiasFiltred}
                  />
                </div>
              )}
            </div>

            <div className={styles.fields}>
              {selectedAgPretensaoResult ? (
                <div>
                  <CheckMoreItem
                    list={selectedAgPretensaoResult?.arhs}
                    property={'nome'}
                    label="ARH"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="arh"
                    label="ARH"
                    component={MultiSelect}
                    textField={'nome'}
                    disabled
                    filterable={true}
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'arhs')}}
                    data={combos?.arhs}
                  />
                </div>
              )}
              {selectedAgPretensaoResult ? (
                <div>
                  <CheckMoreItem
                    list={selectedAgPretensaoResult?.capitanias}
                    property={'nome'}
                    label="Capitania"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="capitania"
                    label="Capitania"
                    component={MultiSelect}
                    textField={'nome'}
                    disabled
                    filterable={true}
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'capitania')}}
                    data={combos?.capitania}
                  />
                </div>
              )}
              {selectedAgPretensaoResult ? (
                <div>
                  <CheckMoreItem
                    list={selectedAgPretensaoResult?.massasAgua}
                    property={'nome'}
                    label="Massa de água"
                  />
                </div>
              ) : (
                <div>
                  <Field
                    name="massaAgua"
                    label="Massa de água"
                    component={MultiSelect}
                    textField={'nome'}
                    filterable={true}
                    disabled
                    onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'massaagua')}}
                    data={combos?.massaagua}
                  />
                </div>
              )}
            </div>
          </div>
          <div>


            <div className={styles.fields}>
              <Field name="local" label="Local" disabled={viewMode} component={Input} />
            </div>
          </div>
        </fieldset>

      </div>

     
    </>
  )

}
