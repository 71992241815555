import { Button, DropDownButton } from '@progress/kendo-react-buttons'
import {
  Form,
  FormElement
} from '@progress/kendo-react-form'
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from '@progress/kendo-react-layout'
import { useEffect, useLayoutEffect, useState } from 'react'

import {
  MouseEvent as ReactMouseEvent,
} from 'react'
import {
  GetUserNameTecnico,
  arhs,
  b64toBlob,
  capitania,
  clearIntervencao,
  concelhos,
  coordenadasPoligono,
  del,
  distritos,
  entidadeUser,
  entidades,
  estadoIntervencao,
  fillCombosAuxiliares,
  freguesias,
  get,
  getIntervencaoSelected,
  handleGenericError,
  hasPermission,
  instrumentoFinanciamento,
  instrumentoPlaneamento,
  intComponente,
  massaagua,
  nuts1,
  nuts2,
  planeamento1,
  planeamento2,
  planeamento3,
  post,
  preventPropsNullValues,
  prioridade,
  put,
  setCoordenadasPoligono,
  setCoordenadasSelected,
  setIntervencaoSelected,
  tip1,
  tip2,
  tip3,
  useCombos
} from '../../services/utils'
import { useIntervencaoStore } from '../../stores/intervencao'
import styles from '../../styles/intervencao/Intervencao.module.scss'
import {
  AUTH_LOCALSTORAGE,
  CURRENT_TIMEZONE,
  DELETE_INTERVENCAO_BY_ID,
  GET_URL_FROM_FILE_DOC,
  IMPORT_GEOM_INT_INTERVENCAO,
  INT_INTERVENCAO_POINT_NAME,
  INT_PLANEAMENTO_ASSOCIACAO,
  LAYER_INDEX_INT_INTERVENCOES,
  OPERATIONAL_LAYER,
  PERMISSION_ATUALIZAR_INTERVENCAO,
  PERMISSION_INSERIR_INTERVENCAO,
  PERMISSION_INTERVENCAO_PERFIL_BASICO,
  PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO,
  PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA,
  PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO,
  PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_LEITURA,
  PERMISSION_INTERVENCAO_PERFIL_TOTAL,
  PESQUISAR_INTERVENCAO,
  RELATORIO_INTERVENCAO,
  REMOVER_AREA_INT_INTERVENCAO,
  SAVE_NEW_INTERVENCAO,
  UPDATE_ASSOCIACAO_INTERVENCAO_PLANEAMENTO,
  UPDATE_INTERVENCAO,
  URL_AJUDA_INTERVENCOES_INTERVENCAO,
  clearLayers
} from '../../utils/constants'
import { AssociacaoListagem } from '../associacao/Associacao'
import { DocumentoListagem } from '../documentos/registar/documento/documento'
import IdentificacaoIntervencao from './IndentificaoIntervencao'
import ImportarGeometriaComponent from '../apoiogestao/registar/ImportarGeometriaForm'
import { Dialog } from '@progress/kendo-react-dialogs'
import SessionStorageService from '../../services/SessionStorageService'
import useClearMap from '../../hooks/useClearMap'
import { async } from 'q'
import html2canvas from 'html2canvas'
import ReactGA from 'react-ga4';
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais'
import { select } from '@progress/kendo-react-upload/dist/npm/messages'
import moment from 'moment'
import { TabAcaoIntervencao } from './TabAcaoIntervencao'
type Props = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
    datagrid?: any
    setDataGrid?: any

  editMode?: boolean
}
type intervencaoForm = {
  id: number
  id_r_hidro: number
  id_concelh: number
  id_fregues: number
  id_tipo_massa: number
  designacao: string
  id_tipo_n1: number
  id_tipo_n2: number
  id_tipo_n3: number
  id_inst_fi: number
  id_prioridade: number
  objetivo: string
  id_tipo_in: number
  localizacao: string
  inicio: unknown
  fim: unknown
  flatCoordinates: string
  obs: string
  id_int_pal_xxi_tip_nivel_1: number
  id_estado: number
  id_int_pal_xxi_tip_nivel_2: number
  id_int_pal_xxi_tip_nivel_3: number
  id_int_instrumento_planeamento: number
  fonte_financiamento: number
  status: boolean
  lstEntidades :  number[];
  lstFinanciamento: number[];
  filedoc_cod: string,
  numero_intervencao: string | undefined,
  geom_imported: string | undefined,
  estado: any
  utilizador_id : number
}

const TOTAL_TABS = 3



export default function RegistarIntervencao({ onCloseClick }: Props) {

  const [isMobile, setIsMobile] = useState(false);
  const deleteIntervencao = (id: number) => del(`${DELETE_INTERVENCAO_BY_ID}/${id}`, {})

  const TAB_ACAO = 1;

  const [selected, setSelected] = useState<number>(0)

  const selectedIntervencaoResult: any = useIntervencaoStore((state) => state.selectedIntervencaoResult)
  const selectedIntervencaoResultId: any = useIntervencaoStore((state) => state.selectedIntervencaoResultId)

    const getIntervencaoById = async (id: any) =>
    await get<any, any>(`${PESQUISAR_INTERVENCAO}/` + id)
  const importGeomIntIntervencao = async (data: any) =>
    await post<any, any>(`${IMPORT_GEOM_INT_INTERVENCAO}`, data)

  const selectIntervencaoResult = useIntervencaoStore((state) => state.selectIntervencaoResult)
  const selectIntervencaoResultId = useIntervencaoStore((state) => state.selectIntervencaoResultId)
  const [openImportModal, setOpenImportModal] = useState<any>()

  const saveIntervencao = async (data: any) => await post<any, any>(`${SAVE_NEW_INTERVENCAO}`, data)
  const updateIntervencao = async (data: any) => await put<any, any>(`${UPDATE_INTERVENCAO}`, data)
  const getUrlFileDoc = async (data: any) => await get<any>(`${GET_URL_FROM_FILE_DOC}/` + encodeURIComponent(data));

  const associacaoPlaneamentoIntervencao = async (data: any) => await post<any, any>(`${INT_PLANEAMENTO_ASSOCIACAO}`, data)
  const updateAssociacaoIntervencaoPlaneamento = async (data: any) => await put<any, any>(`${UPDATE_ASSOCIACAO_INTERVENCAO_PLANEAMENTO}`, data)

  

  const relatorioIntervencao = async (data: any) =>
    await post<any, any>(`${RELATORIO_INTERVENCAO}`, data)

  const removerAreaIntervencao = async (data: any) =>
    await post<any, any>(`${REMOVER_AREA_INT_INTERVENCAO}`, data)

  const [openRemoveAreaModal, setOpenRemoveAreaModal] = useState<any>()


  const intervencaoSelected = getIntervencaoSelected();

  

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/RegistarIntervencao" });
  }, []);

  useEffect(() => {
    addEventListener('message', (event: MessageEvent) => {
      if (event.data.type == 'CoordenadasSelected') {
        console.log(event.data.value)
        if (event.data.value) {
          const flatPointCoordinates =
            event.data.value.x + ', ' + event.data.value.y
          setCoordenadasSelected(flatPointCoordinates)
        }
      } else if (event.data.type == 'CoordenadasFlatSelected') {
        let polygonString = ''
        const values: any[] = event.data.value
        values?.forEach((value, index) => {
          if (index % 2 == 0) {
            polygonString += value + ' '
          } else {
            polygonString += value + ','
          }
        })
        polygonString = polygonString.substring(0, polygonString.length - 1)
        setCoordenadasPoligono(polygonString)
        console.log(polygonString)
      }
    })
  }, [])

  const handleSelect = (e: TabStripSelectEventArguments) => {
    if (e.selected > TAB_ACAO && !selectedIntervencaoResult) {
      postMessage({type:"infoMsg", value: {message: "É necessário gravar primeiro."}})
      return;
    }
    setSelected(e.selected)
  }

  const selectMapAction =  (item: any) => {
    const iframe: any = document.getElementById('iframeGeoPortal')

    if (item.key == "removerArea") {
      setOpenRemoveAreaModal(true)
    } else {
      iframe.contentWindow?.postMessage({ type: item.key, value: getIntervencaoSelected()?.id, editGpLayer: INT_INTERVENCAO_POINT_NAME }, '*')
    }
  }

  const initialValues = {
    ...intervencaoSelected,
    estadoIntervencao: estadoIntervencao ? estadoIntervencao[3] : undefined,
    entidade_registo: entidadeUser,
    data_registo: selectedIntervencaoResult?.data_registo
      ? new Date(selectedIntervencaoResult.data_registo)
      : new Date(),
  };
  
const removeArea = async () => {
  const iframe: any = document.getElementById('iframeGeoPortal')
  iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: 'true' }, '*')
  setCoordenadasPoligono(undefined);
  if (!selectedIntervencaoResult?.id) {
    setOpenRemoveAreaModal(false)
    return;
  }
  await removerAreaIntervencao(selectedIntervencaoResult?.id);
  
  iframe.contentWindow?.postMessage(
    { type: 'SiarlIntIntervencaoSearch', value: [selectedIntervencaoResult?.id] },
    '*'
  )
  refreshForm(selectedIntervencaoResult?.id)
  setOpenRemoveAreaModal(false)

}

  const voltar = () => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
       {
         type: 'SiarlCoordenadaBoxClose',
         value: 'true',
       },
       '*'
     )
    clearIntervencaoMap();
    postMessage({ type: 'form', value: 'pesquisarIntervencao' }, '*');
   
  }

  const [clearMap] = useClearMap();
  const clearIntervencaoMap = () => {
    clearMap(clearLayers["CLEAR_LAYERS_INTERVENCAO"]);
    setIntervencaoSelected(undefined)
    selectIntervencaoResult(undefined)
  }

  const cancelImport = () => {
    setOpenImportModal(false)
  }
  
  const ImportGeom = async (item: any) => {
    if (item.key == 'ImportGeom') {
      setOpenImportModal(true)
    }
  }

  const onImportGeomClick = (event: any) => {
    ImportGeom(event.item)
  }

  const [openModalDescartar, setOpenModalDescartar] = useState<any>()

  const cancelModalDescartar = () => {
    setOpenModalDescartar(false)
  }

  const handleCancel = ()  => {
        refreshForm();
        postMessage({ type: "form", value: "" }, "*");
        setTimeout(() => {
            postMessage({ type: "form", value: "registarIntervencao" }, "*");
          }, 10)
    }

   const handleOpenModalDescartar = () => {
   if (
      sessionStorage.getItem('novaIntervencao') &&
      sessionStorage.getItem('novaIntervencao') == 'true'
    ) {
      setOpenModalDescartar(true);
    } else {
      onEditItemClick("SiarlVisualizar");
      handleCancel();

    }
  }
  
  const refreshForm = async(id: any = undefined) => {
  let iIntervencao = id;
  if (!iIntervencao) {
    if (selectedIntervencaoResult?.id) {
      iIntervencao = selectedIntervencaoResult?.id;
    } else {
      return;
    }
  }

  const intervencao = await getIntervencaoById(iIntervencao);
  const tecnico = await GetUserNameTecnico(intervencao.utilizador_id);


  const objToShow = {
      ...intervencao,
      utilizador_id: tecnico,
      id: intervencao.id,
      localizacao: intervencao?.localizacao ?? undefined,
      nuts1: intervencao.nuts1 ?? undefined,
      nuts2: intervencao.nuts2 ?? undefined,
      nuts3: intervencao.nuts3 ?? undefined,
      distrito : intervencao.distrito ?? undefined,
      concelho: intervencao.mobilizacao ?? undefined,
      freguesia: intervencao.freguesia ?? undefined,
      arh: intervencao.arh ?? undefined,
      capitania : intervencao.capitania ?? undefined,
      massaagua: intervencao.massaagua ?? undefined,
      inicio : intervencao.inicio? moment.utc(new Date(intervencao?.inicio)).tz(CURRENT_TIMEZONE).format("DD/MM/yyyy") : undefined,
      fim : intervencao.fim? moment.utc(new Date(intervencao?.fim)).tz(CURRENT_TIMEZONE).format("DD/MM/yyyy") : undefined,
      id_tipo_n1: intervencao?.tipo_n1 ?? undefined,
      id_tipo_n2: intervencao?.tipo_n2 ?? undefined,
      id_tipo_n3: intervencao?.tipo_n3 ?? undefined,
      id_prioridade: intervencao?.int_prioridade ?? undefined,
      id_int_pal_xxi_tip_nivel_1: intervencao?.int_pal_xxi_tip_nivel_1 ?? undefined,
      id_int_pal_xxi_tip_nivel_2: intervencao?.int_pal_xxi_tip_nivel_2 ?? undefined,
      id_int_pal_xxi_tip_nivel_3: intervencao?.int_pal_xxi_tip_nivel_3 ?? undefined,
      id_int_instrumento_planeamento: intervencao?.int_instrumento_planeamento ?? undefined,
      entidade: intervencao?.entidade,
      designacao: intervencao?.designacao ?? undefined,
      lstEntidades: preventPropsNullValues(intervencao.lstEntidades, "nome") ?? undefined,
      lstFinanciamento: preventPropsNullValues(intervencao.lstFinanciamento, "designacao") ?? undefined,
      fin_comunitario: intervencao?.fin_comunitario ?? undefined,
      fin_nacional: intervencao?.fin_nacional ?? undefined

    }

    selectIntervencaoResult(objToShow);
    setIntervencaoSelected(objToShow);
    const iframe: any = document.getElementById('iframeGeoPortal')

    iframe.contentWindow?.postMessage({ type: "SiarlIntIntervencaoSearch", value: [intervencao?.id] }, '*')
}

  const [deleteClicked, setDeleteClicked] = useState(false);

  const [estadoFalse, setEstado] = useState(false)
  const [estadoTrue, setEstadoTrue] = useState(true)

  const [canUpdate, setCanUpdate] = useState(false);
  const [canInsert, setCanInsert] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [viewMode, setViewMode] = useState(false);

  useEffect(() => {
    setCanUpdate(hasPermission(PERMISSION_ATUALIZAR_INTERVENCAO));
    setCanInsert(hasPermission(PERMISSION_INSERIR_INTERVENCAO))
  }, [])

  useEffect(() => {
    setViewMode(!editMode && sessionStorage.getItem("novaIntervencao") != 'true');
  }, [])

  const handleDescartar = async (e: any) => {

    const iframe: any = document.getElementById('iframeGeoPortal')
   iframe.contentWindow?.postMessage(
      {
        type: 'SiarlCoordenadaBoxClose',
        value: 'true',
      },
      '*'
    )
    iframe.contentWindow?.postMessage(
      {
        type: 'SiarlRemoveSelection',
        value: 'true',
      },
      '*'
    )
   setOpenModalDescartar(false)
   setDeleteClicked(false);

    const intervencaoSelected = getIntervencaoSelected()

    const dataToSend : any = {
      id: intervencaoSelected?.id ?? undefined,
      status: estadoFalse
    }

      let IntervencaoSaved: any
   
    if (intervencaoSelected?.id) {
      postMessage({ type: 'showLoader' }, '*')
          IntervencaoSaved = await updateIntervencao(dataToSend)
          selectIntervencaoResult({
            ...selectedIntervencaoResult,
            ...IntervencaoSaved,})
          }
          postMessage({ type: 'hideLoader' }, '*')

      SessionStorageService.removeItem("firstCallIntervencao");
      postMessage({ type: 'form', value: 'pesquisarIntervencao' }, '*')


}

const isUUID = (str: string) => {
  const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidPattern.test(str);
};

const handleSubmit = async (e: any) => {

    const lstInstrumentosPlaneamento = SessionStorageService.getItem("dataInstrumentoPlaneamento");

    const formattedInstrumentosPlaneamento = lstInstrumentosPlaneamento?.map((item : any) => ({
      id: isUUID(item.id) ? undefined : item.id,
      id_intr_pl: item.id_int_instrumento_planeamento?.id,
      id_tipo_n1: item.id_tipo_n1?.id,
      id_tipo_n2: item.id_tipo_n2?.id,
      id_tipo_n3: item.id_tipo_n3?.id,
      codigo: item.numero_intervencao,
      id_priorid: item.id_prioridade_instr_plan?.id,
  }));

    const lstAcaoIntervencao = SessionStorageService.getItem("dataAcaoIntervencao");

    const formattedAcaoIntervencao = lstAcaoIntervencao?.map((item : any) => ({
      id: isUUID(item.id) ? undefined : item.id,
      id_acao: item.id_acao ?? undefined,
      designacao: item.designacao?.designacao ?? undefined,
      id_estado: item.id_estado?.id ?? undefined,
      id_tipo_n4: item.id_tipo_n4?.id ?? undefined,
      percentagem: item.percentagem ?? undefined,
      valor_execucao: item.valor_execucao ?? undefined,
  }));
  const lstIndicadores = SessionStorageService.getItem("dataIndicadores");

  const formattedIndicadores = lstIndicadores?.map((item : any) => ({
    id: isUUID(item.id) ? undefined : item.id,
    id_indicador: item.id_indicador?.id,
    valor: item.valor
}));

    
    if (!isFormValid) {
      return
    }

    const intervencaoSelected = getIntervencaoSelected();

    const userId = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '')?.user?.id

    const entidadesGrid: any[] = [];

    datagrid.forEach(item => {
     const entidade = item?.Entidade?.id;

     entidadesGrid.push( entidade );

});

    const totalValorExecucao = parseFloat(SessionStorageService.getItem("total_valor_execucao"));

    const dataInicio: any = e.values?.inicio ? moment.utc(e.values?.inicio).tz(CURRENT_TIMEZONE) : undefined
    const dataFim : any = e.values?.fim ? moment.utc(e.values?.fim).tz(CURRENT_TIMEZONE) : undefined

    const intervencaoRequest: any = {
      id: intervencaoSelected?.id ?? undefined,
      id_r_hidro: e.values?.id_rh ?? undefined,
      id_concelh: e.values?.concelho ? e.values?.concelho?.id : undefined,
      id_fregues: e.values?.freguesia ? e.values?.freguesia?.id : undefined,
      id_tipo_massa: e.values?.massaAgua?.id ?? undefined,
      designacao: e.values?.designacao ?? undefined,
      id_tipo_n1: e.values?.id_tipo_n1 ? e.values?.id_tipo_n1.id : undefined,
      id_tipo_n2: e.values?.id_tipo_n2 ? e.values?.id_tipo_n2.id : undefined,
      id_tipo_n3: e.values?.id_tipo_n3 ? e.values?.id_tipo_n3.id : undefined,
      id_int_pal_xxi_tip_nivel_1: e.values?.id_int_pal_xxi_tip_nivel_1 ? e.values?.id_int_pal_xxi_tip_nivel_1.id : undefined,
      id_estado: e.values?.estadoIntervencao ? e.values?.estadoIntervencao.id : undefined,
      id_int_pal_xxi_tip_nivel_2: e.values?.id_int_pal_xxi_tip_nivel_2 ? e.values?.id_int_pal_xxi_tip_nivel_2.id : undefined,
      id_int_pal_xxi_tip_nivel_3: e.values?.id_int_pal_xxi_tip_nivel_3 ? e.values?.id_int_pal_xxi_tip_nivel_3.id : undefined,
      id_int_instrumento_planeamento: e.values?.id_int_instrumento_planeamento ? e.values?.id_int_instrumento_planeamento.id : undefined,
      id_inst_fi: e.values?.id_inst_fi ? e.values?.id_inst_fi?.id : undefined,
      id_prioridade: e.values?.id_prioridade ? e.values?.id_prioridade?.id : undefined,
      objetivo: e.values?.objetivo ?? undefined,
      localizacao: e.values?.localizacao ?? undefined,
      inicio : dataInicio ? dataInicio._d : undefined,
      fim : dataFim ? dataFim._d : undefined,
      obs: e.values?.observacoes ?? undefined,
      flatCoordinates: coordenadasPoligono ?? undefined,
      fonte_financiamento: e.values?.fonte_financiamento ? e.values?.fonte_financiamento?.id : undefined,
      status: true,
      lstEntidades: e.values?.lstEntidades ?? undefined,
      lstFinanciamento: e.values?.lstFinanciamento ?? undefined,
      filedoc_cod: e?.values?.filedoc_cod ?? undefined,
      estado: e?.values?.estadoIntervencao ?? undefined,
      numero_intervencao: e?.values?.numero_intervencao ?? undefined,
      geom_imported: intervencaoSelected?.geom_imported ?? undefined,
      utilizador_id : userId,
      praia_id: e?.values?.praia?.id ?? undefined,
      lstInstrumentoPlaneamento : formattedInstrumentosPlaneamento ?? undefined,
      lstAcaoIntervencao: formattedAcaoIntervencao ?? undefined,
      lstIndicadores : formattedIndicadores ?? undefined,
      inv_global : e.values?.inv_global ?? undefined,
      fin_nacional : e.values?.fin_nacional ?? undefined,
      fin_comunitario : e.values?.fin_comunitario ?? undefined,
      val_executado : totalValorExecucao ?? undefined,
      intComponente: e.values?.id_tipo_in ?? undefined,
      data_registo: e.values?.data_registo ?? undefined

    }

    postMessage({ type: 'showLoader' }, '*');

    try {
      let intervencaoSaved: any;
      const intervencaoSelected = getIntervencaoSelected();

      if (intervencaoSelected?.id) {

        intervencaoSaved = await updateIntervencao(intervencaoRequest);
        selectIntervencaoResult({
          ...selectedIntervencaoResult,
          ...intervencaoSaved
        });
        setIntervencaoSelected(intervencaoSaved);

      } else {

        intervencaoSaved = await saveIntervencao(intervencaoRequest);
        selectIntervencaoResultId(Number(intervencaoSaved?.id))


        setIntervencaoSelected(intervencaoSaved);
        selectIntervencaoResult({
          ...selectedIntervencaoResult,
          ...intervencaoSaved
        });
      }
      setCoordenadasPoligono(undefined);
      refreshForm(intervencaoSaved.id);
      if (intervencaoSaved) {
       setTimeout(() => {
          postMessage({ type: 'sucessMsg' }, '*')
        }, 500)
      }
0
    } catch (error) {
      handleGenericError(error);
    }

    postMessage({ type: 'hideLoader' }, '*')
  }

  const importGeo = async (objToSend: any) => {
    postMessage({ type: "showLoader" }, "*");
    if (!objToSend) {
      postMessage({ type: "errorMsg", value: "Não foi possivel salvar" }, "*");
      postMessage({ type: "hideLoader" }, "*");
      return;

    }
    let int_intervencao;
    let int_intervencao_id;
    const intervencaoSelected = selectedIntervencaoResult ?? getIntervencaoSelected();
    if (intervencaoSelected && intervencaoSelected?.id) {
      int_intervencao = intervencaoSelected;
      int_intervencao_id = intervencaoSelected?.id;
    } else {
      int_intervencao = await saveIntervencao({});
      int_intervencao_id = int_intervencao?.id;
      selectIntervencaoResult(int_intervencao);
      selectIntervencaoResultId(int_intervencao_id);

    }
    try {
      objToSend = { ...objToSend, int_intervencao_id }
      const result = await importGeomIntIntervencao(objToSend)
      if (!result) {
        postMessage({ type: "errorMsg", value: "Não foi possivel salvar" }, "*");
        return;
      }

      try {
        int_intervencao = await getIntervencaoById(int_intervencao_id);
      } catch (error) {
        postMessage({ type: "errorMsg", value: "Registo salvo mas, Não é possivel mostrar os dados neste momento" }, "*");

      } finally {
        int_intervencao = { ...int_intervencao, ...objToSend };
        selectIntervencaoResultId(int_intervencao_id);
        selectIntervencaoResult(int_intervencao);
        setIntervencaoSelected(int_intervencao)
        refreshForm(int_intervencao_id);

      }
      try {
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage(
          { type: 'SiarlIntIntervencaoSearch', value: [int_intervencao_id] },
          '*'
        )
      } catch (error) {
        postMessage({ type: "errorMsg", value: "Registo salvo mas, Não é possivel mostrar os dados neste momento" }, "*");
      }

      setOpenImportModal(false)
    } catch (err) {
      handleGenericError(err);

    } finally {
      postMessage({ type: "hideLoader" }, "*");
    }

  }

 const [datagrid,setDataGrid ] = useState<any[]>([]);

 

   useEffect(() => {
     console.log("dataGrid Registo")
     console.log(datagrid);

  } );


  const itemRender = (props: { item: any; itemIndex: number }) => {
    const isTitleItem = props.item.key === 'titulo';
    if (isTitleItem) {
      // Item de título personalizado
      return (
        <div className={styles.DropDownButtonTitulo}>
          <span>{props.item.text}</span>
        </div>
      );
    } else {
      // Demais itens
      return (
        <div className={styles.DropDownButtonItem}>
          <span>{props.item.text}</span>
        </div>
      );
    }
  };

const onItemClick = (event: any) => {
  if(event?.item?.key == "ImportGeom"){
    ImportGeom(event.item)
   } else {
    selectMapAction(event.item)
   }
  }

  const optionsArea = [
    {
      key: 'SiarlGeoPortalAdicionarArea',
      text: 'Desenhar Área',
    },
    {
      key: 'SiarlEditarGeometria',
      text: 'Editar Área',
    },
    {
      key: 'removerArea',
      text: 'Remover Área',
    },
    {
      key: 'ImportGeom',
      text: 'Importar Geom',
    },
  ]

  const onItemClickImprimir = async (event: any) => {

    let mapImage = ''

    if (!window.location.href.includes('localhost')) {
      const mapIframe = document.querySelector(
        '#iframeGeoPortal'
      ) as HTMLIFrameElement

      const mapIframeInnerContent =
        mapIframe.contentWindow?.document.querySelector(
          'section.content'
        ) as HTMLElement

      const generatedImage = await html2canvas(mapIframeInnerContent)
      mapImage = generatedImage.toDataURL()
    }

    console.log(selectedIntervencaoResult);

    const objToSendImgMapa : any = {
      CodPrintTemplate: "RELATORIO_INT_INTERVENCAO",
      extendInfo: {
        spatialReference: {
          wkid: 0
        },
        xmin: 0,
        ymin: 0,
        xmax: 0,
        ymax: 0
      },
      ListOperationalLayerInfo: [
        {
          IdOperationLayer: OPERATIONAL_LAYER,
          UseLayerExtent: false,
          DefinitionExpression: "",
          ListServiceLayer: [
            {
                LayerId: LAYER_INDEX_INT_INTERVENCOES,
                DefinitionExpression: "id=" + selectedIntervencaoResult?.id,
                UseLayerExtent: true
            }
          ]
        }
      ]
    }

    try {
      postMessage({ type: 'showLoader' }, '*')

      const documentoBase64 = await relatorioIntervencao({
        id: selectedIntervencaoResult?.id,
        dados: {
          'Nº Processo APA:': selectedIntervencaoResult?.filedoc_cod ?? '',
          'Designação:': selectedIntervencaoResult?.designacao ?? '',
          'Número da intervenção:' : selectedIntervencaoResult?.numero_intervencao ?? '',
          'Técnico:' : selectedIntervencaoResult?.tecnico ?? '',

          'Nuts1:': selectedIntervencaoResult?.nuts1[0]?.nome ?? '',
          'Nuts3:': selectedIntervencaoResult?.nuts3[0]?.nome ?? '',
          'Nuts2:': selectedIntervencaoResult?.nuts2[0]?.nome ?? '',
          'Distritos:': selectedIntervencaoResult?.distritos[0]?.nome ?? '',
          'Concelhos:': selectedIntervencaoResult?.concelhos[0]?.nome ?? '',
          'Freguesias:': selectedIntervencaoResult?.freguesias[0]?.nome ?? '',

          'Data de início:': selectedIntervencaoResult?.inicio ?? '',
          'Data de fim:': selectedIntervencaoResult?.fim ?? '',

          'Fonte financiamento previsto:' : selectedIntervencaoResult?.lstFinanciamento[0]?.designacao ?? '-',
          'Tipologia Nível 1:': selectedIntervencaoResult?.int_pal_xxi_tip_nivel_1?.descricao ?? '',
          'Tipologia Nível 2:': selectedIntervencaoResult?.int_pal_xxi_tip_nivel_2?.descricao ?? '',
          'Tipologia Nível 3:': selectedIntervencaoResult?.int_pal_xxi_tip_nivel_3?.descricao ?? '',
          'Prioridade:': selectedIntervencaoResult?.int_prioridade?.descricao ?? '',
          'Estado:': selectedIntervencaoResult?.estado?.descricao ?? '',

          'Instrumento de planeamento:': selectedIntervencaoResult?.int_instrumento_planeamento?.designacao ?? '',
          'Tipologia Nível 1': selectedIntervencaoResult?.tipo_n1?.descricao ?? '',
          'Tipologia Nível 2': selectedIntervencaoResult?.tipo_n2?.descricao ?? '',
          'Tipologia Nível 3': selectedIntervencaoResult?.tipo_n3?.descricao ?? '',
        },  
        img_mapa: "",
        objJsonToCreateImg: objToSendImgMapa
      });

      if (documentoBase64) {
        const blob = b64toBlob(documentoBase64, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
        postMessage({ type: 'hideLoader' }, '*')
      }
    } catch (error) {
      postMessage({ type: 'errorMsg', value: { message: "Não foi possivel efetuar esta operação" } }, '*')
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }

  };
  
   const importarGeometria = [
    {
      key: 'ImportGeom',
      text: 'Importar Geom',
    },
  ]

  const optionsImprimir = [
    {
      key: 'imprimirFichas',
      text: 'Fichas',
    },
    {
      key: 'imprimirResultados',
      text: 'Resultados',
    },
  ]

  const onItemClickExportar = async (event: any) => {

    let mapImage = ''

    if (!window.location.href.includes('localhost')) {
      const mapIframe = document.querySelector(
        '#iframeGeoPortal'
      ) as HTMLIFrameElement

      const mapIframeInnerContent =
        mapIframe.contentWindow?.document.querySelector(
          'section.content'
        ) as HTMLElement

      const generatedImage = await html2canvas(mapIframeInnerContent)
      mapImage = generatedImage.toDataURL()
    }

    console.log(selectedIntervencaoResult);

    try {
      postMessage({ type: 'showLoader' }, '*')

      const documentoBase64 = await relatorioIntervencao({
        id: selectedIntervencaoResult?.id,
        dados: {
          'Nº Processo APA:': selectedIntervencaoResult?.filedoc_cod ?? '',
          'Designação:': selectedIntervencaoResult?.designacao ?? '',
          'Número da intervenção:' : selectedIntervencaoResult?.numero_intervencao ?? '',
          'Técnico:' : selectedIntervencaoResult?.tecnico ?? '',

          'Nuts1:': selectedIntervencaoResult?.nuts1[0]?.nome ?? '',
          'Nuts3:': selectedIntervencaoResult?.nuts3[0]?.nome ?? '',
          'Nuts2:': selectedIntervencaoResult?.nuts2[0]?.nome ?? '',
          'Distritos:': selectedIntervencaoResult?.distritos[0]?.nome ?? '',
          'Concelhos:': selectedIntervencaoResult?.concelhos[0]?.nome ?? '',
          'Freguesias:': selectedIntervencaoResult?.freguesias[0]?.nome ?? '',

          'Data de início:': selectedIntervencaoResult?.inicio ?? '',
          'Data de fim:': selectedIntervencaoResult?.fim ?? '',

          'Fonte financiamento previsto:' : selectedIntervencaoResult?.lstFinanciamento[0]?.designacao ?? '',
          'Tipologia Nível 1:': selectedIntervencaoResult?.int_pal_xxi_tip_nivel_1?.descricao ?? '',
          'Tipologia Nível 2:': selectedIntervencaoResult?.int_pal_xxi_tip_nivel_2?.descricao ?? '',
          'Tipologia Nível 3:': selectedIntervencaoResult?.int_pal_xxi_tip_nivel_3?.descricao ?? '',
          'Prioridade:': selectedIntervencaoResult?.int_prioridade?.descricao ?? '',
          'Estado:': selectedIntervencaoResult?.estado?.descricao ?? '',

          'Instrumento de planeamento:': selectedIntervencaoResult?.int_instrumento_planeamento?.designacao ?? '',
          'Tipologia Nível 1': selectedIntervencaoResult?.tipo_n1?.descricao ?? '',
          'Tipologia Nível 2': selectedIntervencaoResult?.tipo_n2?.descricao ?? '',
          'Tipologia Nível 3': selectedIntervencaoResult?.tipo_n3?.descricao ?? '',
        },  
        img_mapa: mapImage
      });

      if (documentoBase64) {
        const link = document.createElement('a')
        link.href = 'data:application/octet-stream;base64,' + documentoBase64
        link.download = 'reporte_intervencao.pdf'
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        postMessage({ type: 'hideLoader' }, '*')
      }
    } catch (error) {
      postMessage({ type: 'errorMsg', value: { message: "Não foi possivel efetuar esta operação" } }, '*')
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }

  };

  const optionsExportar = [
    {
      key: 'exportarFichas',
      text: 'Fichas',
    },
    {
      key: 'exportarResultados',
      text: 'Resultados',
    },
  ]

  const optionsEditar = [
    {
      key: 'SiarlEditar',
      text: 'Editar',
    },
    {
      key: 'SiarlApagar',
      text: 'Apagar',
    },
    
  ]

  const optionsFileDoc = [

    {
      key: 'AbrirFileDoc',
      text: 'Abrir Filedoc',
    },
    
  ]

 
  const onEditItemClick= (event:any) => {
    if (event == "SiarlEditar" || event?.item?.key == "SiarlEditar") {
      setEditMode(true);
      setViewMode(false);
      return;
    }
    if (event == "SiarlVisualizar") {
      setEditMode(false);
      setViewMode(true);
    }
    if (event?.item?.key == "SiarlApagar") {
      setDeleteClicked(true);
      setOpenModalDescartar(true);
    }
  }

  useEffect(() => {
    if (viewMode) {
      sessionStorage.removeItem("novaIntervencao");
    }
  })

  const onClickOpenFileDoc = async() => {
    const intervencaoSelected = getIntervencaoSelected();
    if (!intervencaoSelected?.filedoc_cod) {
      postMessage({ type: "infoMsg", value: {message: "É necessário preencher o nº processo APA (filedoc)."} }, "*");
      return;
    }
    postMessage({ type: 'showLoader' }, '*');
    const urlFileDoc = await getUrlFileDoc(intervencaoSelected?.filedoc_cod);
    postMessage({ type: 'hideLoader' }, '*')
    window.open(urlFileDoc, '_blank');
    return;
  }

  const isFormValid =
    (selected === 0) ||
    (selected === 1) ||
    (selected === 2)

    useLayoutEffect(() => {

      const handleResize = () => {
  
        if (window.innerWidth <= telaMobile) {
          
          setIsMobile(true);
          ocultaFloatingMenu_e_Mapa();
  
        }else{
  
          setIsMobile(false);
          mostraFloatingMenu_e_Mapa();
  
        }
  
      };
    
      window.addEventListener('resize', handleResize);
    
      handleResize();
    
      return () => {
        window.removeEventListener('resize', handleResize);
      };
  
    }, []);  
  
    const onItemClickMais = (event: any) => {
  
      elementoClickMais(event);

      onEditItemClick(event);
      onImportGeomClick(event);
      onItemClick(event);
      
      if(optionsExportar.some(option => option.key === event.item.key)){
        onItemClickExportar(event);
      }
  
    };
  
    const optionsMais = [
      {
        key: 'titulo',
        text: 'Registo',
      },
      ...optionsEditar.map(option => ({
        ...option,
        className: 'icon-siarl icon-editar btnIconRegistar',
      })),
      {
        key: 'titulo',
        text: 'Opções',
      },
      {
        key: 'openFileDoc',
        text: 'Diretório de documentos',
        className: 'icon-siarl icon-filedoc btnIconRegistar'
      },    
      {
        key: 'titulo',
        text: 'Geometria',
      },
      {
        key: 'titulo',
        text: 'Área',
      },
      ...optionsArea.map(option => ({
        ...option,
        className: 'icon-siarl icon-area btnIconRegistar',
      })),
      {
        key: 'titulo',
        text: 'Imprimir',
      },
      {
        key: 'imprimirFicha',
        text: 'Fichas',
        className: 'icon-siarl icon-imprimir btnIconRegistar'
      },
      {
        key: 'titulo',
        text: 'Exportar',
      },
      ...optionsExportar.map(option => ({
        ...option,
        className: 'icon-siarl icon-exportar btnIconRegistar',
      })),
    ]

    const openAjuda = () => {
      window.open(URL_AJUDA_INTERVENCOES_INTERVENCAO, "_blank")
    }

    const canSeeIntervencao = hasPermission(PERMISSION_INTERVENCAO_PERFIL_BASICO) ||
    hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO) ||
    hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA) ||
    hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO) ||
    hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_LEITURA) ||
    hasPermission(PERMISSION_INTERVENCAO_PERFIL_TOTAL);
  return (

    <div className={styles.base}>

      <div className={styles.header}>

        <div>
          <div className={styles.nomeDoModulo}>
            Intervenções
          </div>
          <div className={styles.tituloDaPagina}>
           {!selectedIntervencaoResult || !selectedIntervencaoResult?.id ? 'Nova intervenção' : (editMode ? 'Editar intervenção' : 'Intervenção')}
          </div>
        </div>
        <div>

          {!isMobile && canUpdate && (viewMode || editMode) && (sessionStorage.getItem("novaIntervencao") != 'true') ? 
          <DropDownButton
            items={optionsEditar}
            className={styles.btnEditar2}
            iconClass="icon-siarl icon-editar"
            onItemClick={onEditItemClick}
            fillMode={'flat'}
            itemRender={itemRender}
            title="Editar"
          ></DropDownButton>
          : ''}

          <button className={styles.btnEditar2} title='Filedoc' onClick={onClickOpenFileDoc}
          style={{ display: isMobile ? 'none' : '' }} id='openFileDoc'>
            <span className="icon-siarl icon-filedoc"></span>
          </button>

        {!isMobile &&
          <DropDownButton items={optionsArea} className={styles.btnEditar2}
            iconClass='icon-siarl icon-area'
            onItemClick={onItemClick}
            fillMode={"flat"}
            title='Geometria - Área'
            itemRender={itemRender}
            disabled={viewMode}
          >
          </DropDownButton>
        }

          <button className={styles.btnEditar2} title='Imprimir' onClick={onItemClickImprimir}
          style={{ display: isMobile ? 'none' : '' }} id='imprimirFicha'>
            <span className="icon-siarl icon-imprimir"></span>
          </button>

          <button id='exportarFicha' className={styles.btnImprimir} onClick={onItemClickExportar}
          style={{ display: isMobile ? 'none' : '' }} title='Exportar'>
            <span className="icon-siarl icon-exportar"></span>
          </button>

          {isMobile &&
            <DropDownButton
              items={optionsMais}
              className={styles.btnMais}
              iconClass="icon-siarl icon-mais"
              onItemClick={onItemClickMais}
              fillMode={'flat'}
              itemRender={itemRenderMais}
              title="Mais Opções"
            />
          }

          <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
            <span className="icon-siarl icon-ajuda"></span>
          </button>

          <button className={styles.btnFechar} onClick={voltar} title="Fechar">
            <span className="icon-siarl icon-fechar"></span>
          </button>

        </div>

      </div>

      {openImportModal ?
        <ImportarGeometriaComponent onSubmit={importGeo} cancelImport={cancelImport} /> : <></>
      }

{openRemoveAreaModal ? (
               <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={() => setOpenRemoveAreaModal(false)}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                    <span className="textDescartar">Remover área </span>
                    <br></br>
                    <span>{'Tem a certeza que pretende remover a área selecionada?'}</span>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={() => setOpenRemoveAreaModal(false)}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={removeArea}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}


        {openModalDescartar ? (
          <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={cancelModalDescartar}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                  <fieldset>
                    <span className="textDescartar">{deleteClicked ? 'Apagar' : 'Cancelar'} registo  </span>
                    <br></br>
                    <span>{deleteClicked ? 'Ao apagar este registo deixará de ter acesso, sendo necessária uma intervenção rigorosa para realizar a sua recuperação.' : 'Ao cancelar este registo irá perder os dados inseridos até ao momento.'}</span>
                      <br></br>
                    <span>{deleteClicked ? 'Tem a certeza que pretende apagar?' : 'Tem a certeza que pretende cancelar?'}</span>
                  </fieldset>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={cancelModalDescartar}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={handleDescartar}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}
      
      <Form
    initialValues={
      {
      ...intervencaoSelected,
      data_registo: selectedIntervencaoResult?.data_registo
        ? new Date(selectedIntervencaoResult.data_registo)
        : new Date(),
      }
    }
        onSubmitClick={handleSubmit}
        render={(formRenderProps) => (

          <FormElement>
            <TabStrip selected={selected} onSelect={handleSelect} 
            className={`${styles.innerStyleTabContent} ${viewMode? 'tabsVisualizar' : ''}`}
            scrollable={isMobile}>
              {canSeeIntervencao?
              <TabStripTab title="Identificação" key={"Identificação"}>
                <IdentificacaoIntervencao onCloseClick={onCloseClick} viewMode={viewMode} datagrid={setDataGrid} setDataGrid={setDataGrid} formRenderProps={formRenderProps} />
              </TabStripTab>:<></>
            }
              
              <TabStripTab title="Ação" key={"Ação"}>
                <TabAcaoIntervencao  formRenderProps={formRenderProps} viewMode={viewMode}  />
              </TabStripTab>
              

              <TabStripTab title="Documentos" key={"Documentos"}>
                <DocumentoListagem  pageName='intervencoes' viewMode={viewMode} />
              </TabStripTab>
              <TabStripTab title="Associação"  key={"Associação"}>
                <AssociacaoListagem  pageName='intervencoes' viewMode={viewMode} />
              </TabStripTab>
            </TabStrip>


           {( editMode || sessionStorage.getItem("novaIntervencao") == 'true')
            && !viewMode ?
                  <div
                    className={`${styles.footer} ${styles.justify_multiple_children}`}
                  >

                  <Button type="button" className={styles.btnSeguinte} onClick={handleOpenModalDescartar} title='Cancelar'>
                  <span className="icon-siarl icon-fechar"></span>
                  <span className={styles.rotulo}>
                    {' '}&nbsp;{' '}
                    Cancelar
                  </span>
                 </Button>    

              <Button
                className={styles.btnSubmeter}
                onClick={formRenderProps.onSubmit}
                title='Gravar'
              >
                <span className="icon-siarl icon-submeter btnIconRegistar"></span>
                <span className={styles.rotulo}>
                  {' '}&nbsp;{' '}
                  Gravar
                </span>
              </Button>
                  </div>
            : ''}
          </FormElement>
        )}
      />
    </div>
  )
}