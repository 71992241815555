import { setCoordenadasPoligono } from "../services/utils"

export default function useClearMap() {
      
    const clearMap = (value?: string ) => {
      try {
        value =value?value :"SiarlClearLayers";
        setCoordenadasPoligono(undefined)
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: "true" }, '*')
        iframe.contentWindow?.postMessage({ type: value, value: "true" }, '*')
      } catch (error) {
        console.error({error})
      }
    }
      return [clearMap];
}
