import AuthLayout from '../components/auth/AuthLayout'
import ForgotPasswordForm from '../components/auth/ForgoPasswordForm'
import PasswordRecoverForm from '../components/auth/PasswordRecover'
import RegisterForm from '../components/auth/RegisterForm'

export default function ForgotPassword() {
  return (
    <AuthLayout>
      <ForgotPasswordForm />
    </AuthLayout>
  )
}
