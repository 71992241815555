import { Button } from '@progress/kendo-react-buttons'
import { DateTimePicker } from '@progress/kendo-react-dateinputs'
import { Dialog } from '@progress/kendo-react-dialogs'
import { ComboBox, MultiSelect } from '@progress/kendo-react-dropdowns'
import { Field, Form, FormElement } from '@progress/kendo-react-form'
import { Input, RadioButton } from '@progress/kendo-react-inputs'
import { useEffect, useState } from 'react'
import {
  arhs,
  concelhos,
  distritos,
  fillConcelhosAsync,
  fillDistritosAsync,
  fillFreguesiasAsync,
  fillNuts1Async,
  fillNuts2Async,
  fillNuts3Async,
  fillStatusLocal,
  fillTipoAcessoAsync,
  fillTipoEntidades,
  freguesias,
  get,
  getEntidadeSelected,
  hasPermission,
  massaagua,
  nuts1,
  nuts2,
  nuts3,
  post,
  setCoordenadasSelected,
  setEntidadeSelected,
  statusLocal,
  tipoEntidades,
  tipoacesso,
  useCombos
} from '../../../services/utils'
import { useEntidadeStore } from '../../../stores/entidade'
import styles from '../../../styles/entidade/RegistarEntidade.module.scss'
import {
  GET_COORDINATES_BY_ADDRESS,
  GET_ENTIDADE,
  GET_ENTIDADE_CONTATOS,
  IMPORT_GEOM_ENTIDADE,
  PERMISSION_ENTIDADES_PERFIL_COMPLETO_EDICAO,
  PERMISSION_ENTIDADES_PERFIL_COMPLETO_LEITURA,
  PERMISSION_ENTIDADES_PERFIL_TOTAL,
  SAVE_NEW_ENTIDADE
} from '../../../utils/constants'
import { requiredFieldValidator } from '../../../validations/ocorrencia'
import EntidadeCodigoPostalInputField from '../fields/EntidadeCodigoPostalInputField'
import EntidadeConcelhoField from '../fields/EntidadeConcelhoField'
import EntidadeDistritoField from '../fields/EntidadeDistritoField'
import EntidadeFreguesiaField from '../fields/EntidadeFrguesiaField'
import EntidadeLocalidadeInputField from '../fields/EntidadeLocalidadeInputField'
import EntidadeMoradaInputField from '../fields/EntidadeMoradaInputField'
import EntidadeNuts1Field from '../fields/EntidadeNuts1Field'
import EntidadeNuts2Field from '../fields/EntidadeNuts2Field'
import EntidadeNuts3Field from '../fields/EntidadeNuts3Field'
import ValidatedIdentificacaoComboBox from '../fields/ValidatedIdentificacaoComboBox'
import ValidatedIdentificacaoInput from '../fields/ValidatedIdentificacaoInput'
import ImportarGeometriaComponent from '../../apoiogestao/registar/ImportarGeometriaForm'
import clsx from 'clsx'
import SessionStorageService from '../../../services/SessionStorageService'
import { Grid, GridCellProps, GridColumn, GridItemChangeEvent } from '@progress/kendo-react-grid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faRemove } from '@fortawesome/free-solid-svg-icons'
import { ResultadoContactoCommandGridCell } from '../ResultadoContactoCommandGridCell copy'
import CheckMoreItem from '../../documentos/registar/fields/CheckMoreItem'

type IProps = {
  clickSave?: any
  viewMode?: boolean

}
export function EntidadeContactos({ clickSave , viewMode}: IProps) {
  const [freguesiasFiltred, setFreguesiasFiltred] = useState<any>()
  const [concelhosFiltred, setConcelhosFiltred] = useState<any>()
  const [concelhosDisabled, setConcelhosDisabled] = useState<any>(true)
  const [freguesiasDisabled, setFreguesiasDisabled] = useState<any>(true)
  const [nameUser, setNameUser] = useState<any>()
  const [freguesiaSelected, setFreguesiaSelected] = useState<any>()
  const [distritoSelected, setDistritoSelected] = useState<any>()
  const [concelhoSelected, setConcelhoSelected] = useState<any>()
  const [nuts1Selected, setNuts1Selected] = useState<any>()
  const [nuts2Selected, setNuts2Selected] = useState<any>()
  const [nuts3Selected, setNuts3Selected] = useState<any>()
  const [massaaguaSelected, setMassaaguaSelected] = useState<any>()
  const [arhSelectected, setArhSelected] = useState<any>()
  const [nuts2Filtred, setNuts2Filtered] = useState<any>()
  const [nuts3Filtred, setNuts3Filtered] = useState<any>()

  const [morada, setMorada] = useState<any>('')
  const [localidade, setLocalidade] = useState<any>('')
  const [codigoPostal, setCodigoPostal] = useState<any>('')
  const [openImportModal, setOpenImportModal] = useState<any>()

  const [showModalEditContactos, setShowModalEditContactos] = useState(false);
  const [openModalDescartar, setOpenModalDescartar] = useState<any>()

  const importGeomEntidade = async (data: any) =>
    await post<any, any>(`${IMPORT_GEOM_ENTIDADE}`, data)
  const createEntidade = async (data: any) =>
    await post<any, any>(`${SAVE_NEW_ENTIDADE}`, data)
  const getCoordinates = async (data: any) =>
    await post<any, any>(`${GET_COORDINATES_BY_ADDRESS}`, data)
  const getEntidade = async (data: any) =>
    await get<any, any>(`${GET_ENTIDADE}/` + data)
  const getContactos = async (data: any) => 
  await get<any,any>(GET_ENTIDADE_CONTATOS + "/" + data);

  const selectEntidadeResult = useEntidadeStore(
    (state) => state.selectEntidadeResult
  )
  const selectedEntidadeResult: any = useEntidadeStore(
    (state) => state.selectedEntidadeResult
  )

  const [dataContactos, setDataContactos] = useState<any[]>([]);
  const [idContato, setIdContato] = useState();
  const [nomeContato, setNomeContato] = useState();
  const [departamentoContato, setDepartamentoContato] = useState();
  const [funcaoContato, setFuncaoContato] = useState();
  const [telefoneContato, setTelefoneContato] = useState();
  const [emailContato, setEmailContato] = useState();
  const [itemToRemove, setItemToRemove] = useState<any>();
  const [isEditing, setIsEditing] = useState(false); 
  


useEffect(() => {
    
     if(!nuts1 ){
      fillNuts1Async();
      }
     if(!nuts2) {
      fillNuts2Async();
     }
     if(!nuts3){
      fillNuts3Async();
     }
     if(!distritos){
      fillDistritosAsync();
     }
     if(!concelhos){
      fillConcelhosAsync();
     }
     if(!freguesias){
      fillFreguesiasAsync();
     }
     if(!tipoEntidades){
      fillTipoEntidades();
     }
     if(!tipoacesso){
      fillTipoAcessoAsync();
     }

  }, []);


  useEffect(() => {
      console.log("viewMode")
      console.log(viewMode)
  }, []);

  useEffect(() => {
    setFreguesiasFiltred(freguesias)
    setConcelhosFiltred(concelhos)
    fillStatusLocal();
  },[]);

  const cancelImport = () => {
    setOpenImportModal(false)
  }

  const filterConcelho = (e: any) => {
    if (e?.value?.codigo) {
      const concelhosLocal = concelhos.filter(
        (x: any) => x.distrito_cod == e.value.codigo
      )
      setConcelhosFiltred(concelhosLocal)
      setConcelhosDisabled(false)
    } else {
      setConcelhosFiltred(concelhos)
      setConcelhosDisabled(true)
    }
  }

  const filterFreguesia = (e: any) => {
    if (e?.value?.codigo) {
      const freguesiaLocal = freguesias?.filter(
        (x: any) => x.concelho_cod == e.value.codigo
      )
      setFreguesiasFiltred(freguesiaLocal)
      setFreguesiasDisabled(false)
      const concelhoSelectedLocal = concelhos?.filter(
        (x) => x.codigo == e?.value?.codigo
      )
      deselectNuts()
      deselectMassaArh()
      setConcelhoSelected(concelhoSelectedLocal[0])
    } else {
      setFreguesiasDisabled(true)
      setFreguesiasFiltred(freguesias)
    }
  }

  const removeContacto: any = () => {
    const item = itemToRemove;
    const dataContatosLocal = dataContactos.filter(x => !(x.nome == item.nome)).slice();
    setDataContactos(dataContatosLocal);
    setOpenModalDescartar(false);
  }

  const confirmRemoveContato = (item: any) => {
    setItemToRemove(item);
    setOpenModalDescartar(true);
  }

  const onEditItemClick =(item: any) => {
    setShowModalEditContactos(true);
    setIdContato(item.id);
    setNomeContato(item.nome);
    setTelefoneContato(item.telefone);
    setEmailContato(item.email);
    setDepartamentoContato(item.departamento);
    setFuncaoContato(item.funcao);
    setIsEditing(true);
  }

  const gravarContato = () => {
    const entidade_id = getEntidadeSelected()?.id;
    const contatoToAdd = {
      entidade_id: entidade_id ?? undefined,
      nome: nomeContato,
      departamento: departamentoContato,
      email: emailContato,
      telefone: telefoneContato
    }
    const dataContatosLocal:any[] = dataContactos.filter((x) => !(x.nome == contatoToAdd.nome)).slice();
    dataContatosLocal.push(contatoToAdd);
    setDataContactos(dataContatosLocal);
        
  }

  const geoCode = async (e: any) => {
    const fullAddress = morada + (localidade ?  ', ' + localidade : '') + (codigoPostal ? ', ' + codigoPostal: '')
    const flatCoordinatesLocal = await getCoordinates(fullAddress)
    if (flatCoordinatesLocal) {
      const coords = flatCoordinatesLocal.split(',')
      const dataToSend = {
        longitude: coords[0],
        latitude: coords[1],
      }
      // const iframe: any = document.getElementById('iframeGeoPortal')
      // iframe.contentWindow?.postMessage(
      //   { type: 'SiarlGeoCodeSearch', value: dataToSend },
      //   '*'
      // )

      setCoordenadasSelected(flatCoordinatesLocal)
      postMessage(
        {
          type: 'CoordenadasSelected',
          value: { x: dataToSend.longitude, y: dataToSend.latitude },
        },
        '*'
      )
    }
  }

  const selectArh = (e: any) => {
    if (e?.value?.codigo) {
      const arhSelectedLocal = arhs.filter((x) => x.codigo == e?.value?.codigo)
      deselectAll()
      setArhSelected(arhSelectedLocal[0])
    }
  }
  const deselectAll = () => {
    deselectDistConc()
    deselectNuts()
    deselectMassaArh()
  }
  const deselectMassaArh = () => {
    setMassaaguaSelected(undefined)
    setArhSelected(undefined)
  }

  const deselectNuts = () => {
    setNuts1Selected(undefined)
    setNuts2Selected(undefined)
    setNuts3Selected(undefined)
  }

  const deselectDistConc = () => {
    setFreguesiaSelected(undefined)
    setDistritoSelected(undefined)
    setConcelhoSelected(undefined)
  }

  const filterNuts2SelectNuts1 = (e: any) => {
    setNuts2Filtered([])
    if (e?.value?.codigo) {
      const nuts1SelectedLocal = nuts1?.filter(
        (x) => x.codigo == e?.value?.codigo
      )
      deselectDistConc()
      deselectMassaArh()
      setNuts1Selected(nuts1SelectedLocal[0])
      setNuts2Filtered(
        nuts2?.filter((nts: any) => nts?.nuts_i_cod === e?.value?.codigo)
      )
    }
  }

  const filterNuts3SelectNuts2 = (e: any) => {
    setNuts3Filtered([])
    if (e?.value?.codigo) {
      const nuts2SelectedLocal = nuts2?.filter(
        (x) => x.codigo == e?.value?.codigo
      )
      deselectDistConc()
      deselectMassaArh()
      setNuts2Selected(nuts2SelectedLocal[0])
      setNuts3Filtered(
        nuts3?.filter((nts: any) => nts?.nuts_ii_cod === e?.value?.codigo)
      )
    }
  }



  const selectNuts3 = (e: any) => {
    if (e?.value?.codigo) {
      const nuts3SelectedLocal = nuts3?.filter(
        (x) => x.codigo == e?.value?.codigo
      )
      deselectAll()
      setNuts3Selected(nuts3SelectedLocal[0])
    }
  }
  const freguesiaChange = (e: any) => {
    if (e?.value?.codigo) {
      const freguesiaSelectedLocal = freguesias?.filter(
        (x) => x.codigo == e?.value?.codigo
      )
      setFreguesiaSelected(freguesiaSelectedLocal[0])
      const entidadeSelected = getEntidadeSelected()
      setEntidadeSelected({
        ...entidadeSelected,
        geom_imported: 'Freguesia: ' + freguesiaSelectedLocal[0]?.nome,
      })
      selectEntidadeResult({
        ...selectedEntidadeResult,
        geom_imported: 'Freguesia: ' + freguesiaSelectedLocal[0]?.nome,
      })
    }
  }
  const filterConcelhoSelectDistrito = (e: any) => {
    if (e?.value?.codigo) {
      const concelhosLocal = concelhos?.filter(
        (x: any) => x.distrito_cod == e.value.codigo
      )
      setConcelhosFiltred(concelhosLocal)
      setConcelhosDisabled(false)
      const distritoSelectedLocal = distritos?.filter(
        (x) => x.codigo == e?.value?.codigo
      )
      deselectNuts()
      deselectMassaArh()
      setDistritoSelected(distritoSelectedLocal[0])
    } else {
      setConcelhosFiltred(concelhos)
      setConcelhosDisabled(true)
    }
  }

  const selectMassaAgua = (e: any) => {
    if (e?.value?.codigo) {
      const massaAguaLocal = massaagua?.filter(
        (x) => x.codigo == e?.value?.codigo
      )
      deselectAll()
      setMassaaguaSelected(massaAguaLocal[0])
    }
  }
  const entidadeSelected = getEntidadeSelected()

  useEffect(() => {
    if (entidadeSelected?.id) {
      getContactos(entidadeSelected?.id).then(result => {
        setDataContactos(result);
        SessionStorageService.setItem("dataContactos", dataContactos);
      });
    }
  }, []);

  useEffect(() => {
    SessionStorageService.setItem("dataContactos", dataContactos);
  });

  const canSeeEntidadesAllPermissions = hasPermission(PERMISSION_ENTIDADES_PERFIL_COMPLETO_EDICAO) || hasPermission(PERMISSION_ENTIDADES_PERFIL_TOTAL) || hasPermission(PERMISSION_ENTIDADES_PERFIL_COMPLETO_LEITURA);

  return (
    <>
    
      <div className={`${styles.form} ${styles.removeScrollDuplicado}`}>


       <span style={{ marginLeft: '08px' }}>Os campos com simbolo * (asterisco) são de preenchimento obrigatório</span>
       <fieldset>

            <legend>
              <span className={styles.fieldsetTitulo}>Contactos</span>
            </legend>

            <div className={styles.fields}>
              {!viewMode ? 
                <div>
                  <Button
                    onClick={(e) => {
                      setNomeContato(undefined);
                      setEmailContato(undefined);
                      setDepartamentoContato(undefined);
                      setFuncaoContato(undefined);
                      setTelefoneContato(undefined);
                      setShowModalEditContactos(true)
                      setIsEditing(false);
                      e.preventDefault();
                    }}
                    style={{ marginTop: 12 }}
                    className={styles.btnImport}
                  >
                    Adicionar contacto
                  </Button>
                </div>
                : <></>}
              </div>
            <div>
              <Grid
                      data={dataContactos}
                    >
                      <GridColumn width={56}
                        cell={(props) => (
                          <td className="k-command-cell">
                            <div style={{cursor:'pointer'}} className={'gridCommand'}>
                              <div
                                role="button"
                                className={styles.gridCommandIconButton}
                                onClick={() => onEditItemClick(props.dataItem)}
                              >
                                <FontAwesomeIcon icon={faInfoCircle} title="Editar contacto" />
                              </div>
                              <div style={{cursor:'pointer'}}
                                role="button"
                                onClick={() => confirmRemoveContato(props.dataItem)}
                                className={styles.gridCommandIconButton}
                              >
                                <FontAwesomeIcon icon={faRemove} title="Remover contacto" />
                              </div>
                            </div>
                          </td>
                        )}
                      />
                      <GridColumn field='nome' title='nome'></GridColumn>
                      <GridColumn field='departamento' title='Departamento'></GridColumn>
                      <GridColumn field='funcao' title='Função'></GridColumn>
                      <GridColumn field='telefone' title='Telefone'></GridColumn>
                      <GridColumn field='email' title='Email'></GridColumn>
              </Grid>
            </div>      
          </fieldset>
      </div>
      {showModalEditContactos ? 
          <Dialog className={`ModalDocumentos ${styles.form} ${styles.removeScrollDuplicado}`} onClose={() => {
            setShowModalEditContactos(false)
          }}>
            <div>
            <Input
              name="Id"
              label="Id"
              value={idContato}
              disabled={true}
              />
            </div>
            <div> 
              <Input
                name="nome"
                label="Nome"
                style={{ width: '100%' }}
                disabled={isEditing}
                onChange={(e: any) => {
                  setNomeContato(e.value);
                }}
                value={nomeContato}
              />
              <Input
                name="departamento"
                label="Departamento"
                style={{ width: '100%' }}
                onChange={(e: any) => {
                  setDepartamentoContato(e.value);
                }}
                value={departamentoContato}
              />
              <Input
                name="funcao"
                label="Função"
                style={{ width: '100%' }}
                onChange={(e: any) => {
                  setFuncaoContato(e.value);
                }}
                value={funcaoContato}
              />
            <Input
              name="telefone"
              label="Telefone"
              style={{ width: '100%' }}
              onChange={(e: any) => {
                setTelefoneContato(e.value);
              }}
              value={telefoneContato}
            />
            <Input
              name="email"
              label="Email"
              style={{ width: '100%' }}
              onChange={(e: any) => {
                setEmailContato(e.value);
              }}
              value={emailContato}
              />
          
        </div>
            <div className="footerModal justify_multiple_children">
              <Button
                type={"submit"}
                onClick={() => {
                  setShowModalEditContactos(false);
                }}
                className='btnLimparPesquisa'
                title="Fechar"
              >
                <span className="icon-siarl icon-fechar btnIconRegistar"></span> &nbsp;{' '}
                Cancelar
              </Button>
              
              <Button
                type={"submit"}
                onClick={() => {
                  gravarContato();
                  setShowModalEditContactos(false);
                }}

                className={styles.btnGravar}
                title="Gravar"
              >
                  <span className="icon-siarl icon-submeter btnIconRegistar"></span> &nbsp;{' '}
                  Gravar
              </Button>

            </div>
          </Dialog>
    : ''}
    {openModalDescartar ? (
          <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={() => setOpenModalDescartar(false)}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                  <fieldset>
                    <span className="textDescartar">{'Apagar'} registo  </span>
                    <br></br>
                    <span>{'Tem a certeza que pretende apagar o contacto?'}</span>
                  </fieldset>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={() => setOpenModalDescartar(false)}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={removeContacto}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}
    </>
  )
}
