import { useCallback, useEffect, useState } from 'react';
import SessionStorageService from '../../../services/SessionStorageService';
import { entidadeUser, get } from '../../../services/utils';
import styles from '../../../styles/ocorrencia/ConsultarOcorrenciaForm.module.scss'
import { GET_OCORRENCIA, AUTH_LOCALSTORAGE, PESQUISAR_DOCUMENTOS_OCORRENCIA, CURRENT_TIMEZONE } from '../../../utils/constants';
import { useOcorrenciaStore } from '../../../stores/ocorrencias';
import { PagerTargetEvent } from '@progress/kendo-react-data-tools'
import clsx from 'clsx';
import { Grid, GridColumn, GridPageChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import moment from 'moment'
import "moment-timezone"
import React from 'react';

const getOcorrencia = async (data: any) =>
await get<any, any>(`${GET_OCORRENCIA}/` + data)
const getDocumentosByOcorrencia = async (data: any) =>
await get<any, any>(`${PESQUISAR_DOCUMENTOS_OCORRENCIA}/` + data)

type PageState = {
  skip: number
  take: number
}

const initialDataState: PageState = { skip: 0, take: 10 }

const detecaoDaOcorrência = {
  1: "Aviso/Chamada",
  2: "Visita Regular"
};

export function OcorrenciaTerrenoConclusao({ props, selectedOcorrenciaResult }: any) {
    const userLocal = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}');

    const [data, setData] = useState<any[]>([]);
    const [page, setPage] = useState<PageState>(initialDataState)
    const [pageSizeValue, setPageSizeValue] = useState<
    number | string | undefined
    >()
    const getDocumentosByOcorrencia = async (data: any) =>
    await get<any, any>(`${PESQUISAR_DOCUMENTOS_OCORRENCIA}/` + data)

    
    const _getDocumentosByOcorrencia = async (id?: any) => {
      try {
        postMessage({ type: 'showLoader' }, '*')

        const result = await getDocumentosByOcorrencia(id)

        if (Array.isArray(result)) {
          setData(result)
        }
      } catch (error) {
        setData([])
      } finally {
        postMessage({ type: 'hideLoader' }, '*')
      }

    }
    useEffect(() => {
      if (selectedOcorrenciaResult?.id) {
        _getDocumentosByOcorrencia(selectedOcorrenciaResult?.id);
      }
    }, [selectedOcorrenciaResult]);

    const handlePageChange = (event: GridPageChangeEvent) => {
      const targetEvent = event.targetEvent as PagerTargetEvent
      const take = event.page.take
    
      if (targetEvent.value) {
        setPageSizeValue(targetEvent.value)
      }
      setPage({
        ...event.page,
        take,
      })
    }

    const availableData = data.slice();
    const initialData = availableData.splice(0, 20);
    
    const [gridData, setGridData] = React.useState(initialData);

    const scrollHandler = (event: { nativeEvent: any; }) => {
      const ScroolLength = 10;
      const e = event.nativeEvent;
      if (
        e.target.scrollTop + ScroolLength >=
        e.target.scrollHeight - e.target.clientHeight
      ) {
        const moreData = availableData.splice(0, ScroolLength);
        if (moreData.length > 0) {
          setGridData((oldData) => oldData.concat(moreData));
        }
      }
    };

  return (
    <div className={`${styles.formResumo} ${styles.removeScrollDuplicado}`}>
      {/* <fieldset>
        <legend>
          <span className={styles.fieldsetTitulo}>Resumo da ocorrência</span>
        </legend>
        <div className={styles.DivCamposConclusao}>
          <div className={styles.fieldsetSubTitulo}>
            Resumo da ocorrência
          </div>
        </div>
      </fieldset> */}

      <fieldset>  {/*Fieldset Registo */}
        <legend>
          <span className={styles.fieldsetTitulo}>Registo</span>
        </legend>

        <div className={styles.DivCamposConclusao}>
          <div>
            <span className={styles.nomeCamposConclusao}>Data / Hora do registo</span>
            <div className="LastCampo">
              <span className={styles.resultadoCamposConclusao}>
                {selectedOcorrenciaResult?.dataHoraRegistro ? moment.utc(selectedOcorrenciaResult?.dataHoraRegistro).tz(CURRENT_TIMEZONE).format('YYYY-MM-DD HH:mm:ss') : undefined}
              </span>
            </div>
          </div>
          <div>
            <div>
              <span className={styles.nomeCamposConclusao}>Técnico</span>
            </div>
            <div className='LastCampo'>
              <span className={styles.resultadoCamposConclusao}>{selectedOcorrenciaResult?.tecnico} </span>
            </div>
          </div>
          <div>
            <div>
              <span className={styles.nomeCamposConclusao}>Entidade</span>
            </div>
            <div className='LastCampo'>
              
              <span className={styles.resultadoCamposConclusao}>{selectedOcorrenciaResult?.entidade ? selectedOcorrenciaResult?.entidade?.nome : entidadeUser} </span>
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset>  {/*Fieldset Identificação */}
         <legend>
          <span className={styles.fieldsetTitulo}>Identificação</span>
         </legend>
          <div className={styles.DivCamposConclusao}>
                <span className={styles.nomeCamposConclusao}>Data / Hora da ocorrência</span>
                <div className="LastCampo">
                  <span className={styles.resultadoCamposConclusao}>
                    {selectedOcorrenciaResult?.dataHoraOcorrencia ? moment.utc(selectedOcorrenciaResult?.dataHoraOcorrencia).tz(CURRENT_TIMEZONE).format('YYYY-MM-DD HH:mm:ss') : 'Data indeterminada'  }         
                  </span>
                </div>
            <div>
              <span className={styles.nomeCamposConclusao}>Deteção da ocorrência </span>
            </div>
            <div>
            {selectedOcorrenciaResult?.detecaoOcorrencia === 1 ? (
                <span className={styles.resultadoCamposConclusao}>Aviso/Chamada</span>
              ) : selectedOcorrenciaResult?.detecaoOcorrencia === 2 ? (
                <span className={styles.resultadoCamposConclusao}>Visita Regular</span>
              ) : null}
            </div>
         </div>
      </fieldset>

      <fieldset>  {/*Fieldset Localização */}
        <legend>
          <span className={styles.fieldsetTitulo}>Localização</span>
        </legend>

        <div className={styles.DivCamposConclusao}>            
          <div className='LastCampo' style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ flex: 1 }}>
              <div>
                <span className={styles.nomeCamposConclusao}>Distrito </span>
              </div>
              <div>
                <span className={styles.resultadoCamposConclusao}>{selectedOcorrenciaResult?.distrito?.nome ?? ''}</span>
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <div>
                <span className={styles.nomeCamposConclusao}>Concelho </span>
              </div>
              <div>
                <span className={styles.resultadoCamposConclusao}>{selectedOcorrenciaResult?.concelho?.nome ?? ''}</span>
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <div>
                <span className={styles.nomeCamposConclusao}>Freguesia</span>
              </div>
              <div>
                <span className={styles.resultadoCamposConclusao}>{selectedOcorrenciaResult?.freguesia?.nome ?? ''}</span>
              </div>
            </div>      
          </div>
        <div className='LastCampo' style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ flex: 1 }}>
            <div>
              <span className={styles.nomeCamposConclusao}>Capitania </span>
            </div>
            <div>
              <span className={styles.resultadoCamposConclusao}>{selectedOcorrenciaResult?.capitania?.nome}</span>
            </div>
          </div>
            <div style={{ flex: 1 }}>
              <div>
                <span className={styles.nomeCamposConclusao}>Praia </span>
              </div>
              <div>
                <span className={styles.resultadoCamposConclusao}>{selectedOcorrenciaResult?.praia?.nome ?? ''}</span>
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <div>
                <span className={styles.nomeCamposConclusao}>Local </span>
              </div>
              <div>
                <span className={styles.resultadoCamposConclusao}>{selectedOcorrenciaResult?.ref_localizacao}</span>
              </div>
            </div>          
          </div>
        </div>
      </fieldset>

      <fieldset>  {/*Fieldset Tipologia */}
        <legend>
          <span className={styles.fieldsetTitulo}>Tipologia</span>
        </legend>
        <div className={styles.DivCamposConclusao}>
          <div>
            <div>
              <span className={styles.fieldsetSubTitulo}>Instabilidades em arribas</span>
              <div className="LastCampo">
                <div className={styles.spacingPontosResumo}>
                  <span className={styles.nomeCamposConclusao}>
                    Movimento de massa vertente
                  </span>
                  <br />
                  <span className={styles.resultadoCamposConclusao}>
                    Alcance máximo do depósito/detritos:&nbsp;
                    {selectedOcorrenciaResult?.tip_mmv_alcance_deposito ? selectedOcorrenciaResult?.tip_mmv_alcance_deposito + "m" : ""}
                  </span>
                </div>
                <div className={styles.spacingPontosResumo}>
                  <span className={styles.resultadoCamposConclusao}>
                    {selectedOcorrenciaResult?.fendaTracao ? "Fenda de tração com abertura visível" : ""}
                  </span>
                </div>
              </div>
            </div>

            <div>
              <span className={styles.fieldsetSubTitulo}>Erosão/Recuo da linha da costa</span>
              <div className="LastCampo">
              <div className={styles.spacingPontosResumo}>
                  <span className={styles.nomeCamposConclusao}>
                    Escarpamento da duna
                  </span>
                  <br />
                  <span className={styles.resultadoCamposConclusao}>
                    Altura máxima da escarpa:&nbsp;
                    {selectedOcorrenciaResult?.tip_altura_maxima_escarpa ? selectedOcorrenciaResult?.tip_altura_maxima_escarpa + "m" : ""}
                  </span>
                </div>
                <div className={styles.spacingPontosResumo}>
                  <span className={styles.nomeCamposConclusao}>
                    Rebaixamento praia
                  </span>
                  <br />
                  <span className={styles.resultadoCamposConclusao}>
                    Altura máxima da praia:&nbsp;
                    {selectedOcorrenciaResult?.tip_reb_praia_altura_max ? selectedOcorrenciaResult?.tip_reb_praia_altura_max + "m" : ""}
                  </span>
                </div>
                <div className={styles.spacingPontosResumo}>
                  <span className={styles.nomeCamposConclusao}>
                    Galmento costeiro
                  </span>
                  <br />  
                  <span className={styles.resultadoCamposConclusao}>
                    Alcance máximo de água /detritos:&nbsp;
                    {selectedOcorrenciaResult?.tip_galg_cost_alcance_max ? selectedOcorrenciaResult?.tip_galg_cost_alcance_max + "m" : ""}
                  </span>
                </div>
              </div>
            </div>
            
            <div>
              <span className={styles.fieldsetSubTitulo}>Em estruturas de proteção/defesa costeira</span>
              <div className="LastCampo">
                <div>
                  <span className={styles.resultadoCamposConclusao}>
                    {selectedOcorrenciaResult?.estruturaAderente ? "Estrutura aderente; " : ""}
                    {selectedOcorrenciaResult?.esporao ? "Esporão/Quebra-mar; " : ""}
                    {selectedOcorrenciaResult?.protecaoEstabilizacao ? "Estrutura de proteção/estabilização de arriba" : ""}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset>  {/*Fieldset Consequências */}
        <legend>
          <span className={styles.fieldsetTitulo}>Consequências</span>
        </legend>
        <div className={styles.DivCamposConclusao}>
          <div>
            <div>
              <span className={styles.nomeCamposConclusao}>Pessoas em risco </span>
            </div>
            <div className='LastCampo'>
              {selectedOcorrenciaResult?.pessoaEmRisco === 0 ? (
                  <span className={styles.resultadoCamposConclusao}>Não</span>
                ) : selectedOcorrenciaResult?.pessoaEmRisco === 1 ? (
                  <span className={styles.resultadoCamposConclusao}>Sim</span>
                ) : selectedOcorrenciaResult?.pessoaEmRisco === 2 ? (
                  <span className={styles.resultadoCamposConclusao}>Não sabe</span>
                  ) : null}
            </div>
          <div className='LastCampo' style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ flex: 2 }}>
              <div>
                <span className={styles.nomeCamposConclusao}>Danos em áreas construídas de fruição/uso público </span>
              </div>
              <div>
                <span className={styles.resultadoCamposConclusao}>{selectedOcorrenciaResult?.fruicaoUsoPublico ? "Sim" : "Não"}</span>
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <div>
                <span className={styles.nomeCamposConclusao}>Danos em edificações </span>
              </div>
              <div>
                <span className={styles.resultadoCamposConclusao}>{selectedOcorrenciaResult?.danosEdificacoes ? "Sim" : "Não"}</span>
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <div>
                <span className={styles.nomeCamposConclusao}>Outros danos </span>
              </div>
              <div>
                <span className={styles.resultadoCamposConclusao}>{selectedOcorrenciaResult?.outrosDanos ? "Sim" : "Não"}</span>
              </div>
            </div>      
          </div>

            <h5>Danos em sistemas naturais</h5>
            {selectedOcorrenciaResult?.sistemasNaturais === 0 ? (
              <span className={styles.resultadoCamposConclusao}>Não</span>
            ) : selectedOcorrenciaResult?.sistemasNaturais === 1 ? (
              <span className={styles.resultadoCamposConclusao}>Sim</span>
            ) : selectedOcorrenciaResult?.sistemasNaturais === 2 ? (
              <span className={styles.resultadoCamposConclusao}>Não sabe</span>
              ) : null}
          </div>
        </div>
      </fieldset>

      <fieldset>  {/*Fieldset Causas */}
        <legend>
          <span className={styles.fieldsetTitulo}>Causas da ocorrência</span>
        </legend>
        <div className={styles.DivCamposConclusao}>
        <div className="LastCampo">
            <span className={styles.resultadoCamposConclusao}>{selectedOcorrenciaResult?.naoSabe ? "Não sabe/Não aplicável" : ""}</span>
          </div>

          <div>
              <span className={styles.nomeCamposConclusao}>Tempestade</span>
              <div className="LastCampo">
                  <span className={styles.resultadoCamposConclusao}>
                    {selectedOcorrenciaResult?.descricaoTempestade ? selectedOcorrenciaResult?.descricaoTempestade : ""}
                  </span>
              </div>
          </div>

          <div className="LastCampo">
            <span className={styles.resultadoCamposConclusao}>{selectedOcorrenciaResult?.sismos ? "Sismos" : ""}</span>
          </div>

          <div>
              <span className={styles.nomeCamposConclusao}>Outras</span>
              <div className="LastCampo">
                  <span className={styles.resultadoCamposConclusao}>
                    {selectedOcorrenciaResult?.descricaoOutras ? selectedOcorrenciaResult?.descricaoOutras : ""}
                  </span>
              </div>
          </div>
        </div>
      </fieldset>

      <fieldset>  {/*Fieldset Medidas Mitigação */}
        <legend>
          <span className={styles.fieldsetTitulo}>Medidas de mitigação do risco</span>
        </legend>
        <div className={styles.DivCamposConclusao}>
          <div>
              <span className={styles.nomeCamposConclusao}>Medidas de mitigação</span>
              <div className="LastCampo">
                <div>
                  {selectedOcorrenciaResult?.medidasMetigacao === 0 ? (
                    <span className={styles.resultadoCamposConclusao}>Não</span>
                  ) : selectedOcorrenciaResult?.medidasMetigacao === 1 ? (
                    <span className={styles.resultadoCamposConclusao}>Sim</span>
                  ) : selectedOcorrenciaResult?.medidasMetigacao === 2 ? (
                    <span className={styles.resultadoCamposConclusao}>Não sabe</span>
                  ) : null}
                </div>
              </div>
          </div>

          <div>
              <span className={styles.nomeCamposConclusao}>Imediata</span>
              <div className="LastCampo">
                <div>
                  {selectedOcorrenciaResult?.sinalizacao === 2 ? (
                    <span className={styles.resultadoCamposConclusao}>Sinalização aviso&nbsp;</span>
                  ) : null}
                </div>
                <div>
                  {selectedOcorrenciaResult?.delimitacao === 2 ? (
                    <span className={styles.resultadoCamposConclusao}>Delimitação da área de risco&nbsp;</span>
                  ) : null}
                </div>
                <div>
                  {selectedOcorrenciaResult?.saneamento === 2 ? (
                    <span className={styles.resultadoCamposConclusao}>Saneamento de blocos&nbsp;</span>
                  ) : null}
                </div>
                <div>
                  {selectedOcorrenciaResult?.reabilitacao === 2 ? (
                    <span className={styles.resultadoCamposConclusao}>Reabilitação de obra de proteção&nbsp;</span>
                  ) : null}
                </div>
                <div>
                  {selectedOcorrenciaResult?.reforco === 2 ? (
                    <span className={styles.resultadoCamposConclusao}>Reforço artificial de emergência</span>
                  ) : null}
                </div>
              </div>
          </div>

          <div>
              <span className={styles.nomeCamposConclusao}>A adotar</span>
              <div className="LastCampo">
                <div>
                  {selectedOcorrenciaResult?.sinalizacao === 1 ? (
                    <span className={styles.resultadoCamposConclusao}>Sinalização interdição&nbsp;</span>
                  ) : null}
                </div>
                <div>
                  {selectedOcorrenciaResult?.delimitacao === 1 ? (
                    <span className={styles.resultadoCamposConclusao}>Interdição da área de risco&nbsp;</span>
                  ) : null}
                </div>
                <div>
                  {selectedOcorrenciaResult?.saneamento === 1 ? (
                    <span className={styles.resultadoCamposConclusao}>Saneamento de reperfilamento&nbsp;</span>
                  ) : null}
                </div>
                <div>
                  {selectedOcorrenciaResult?.reabilitacao === 1 ? (
                    <span className={styles.resultadoCamposConclusao}>Reabilitação de obra de estabilização&nbsp;</span>
                  ) : null}
                </div>
                <div>
                  {selectedOcorrenciaResult?.reforco === 1 ? (
                    <span className={styles.resultadoCamposConclusao}>Alimentação artificial de emergência</span>
                  ) : null}
                </div>
              </div>
          </div>
        </div>
      </fieldset>

      <fieldset>  {/*Fieldset Observações */}
        <legend>
          <div>
            <span className={styles.fieldsetTitulo}>Observações</span>
          </div>
        </legend>
          <div className={styles.DivCamposConclusao}>
            <span className={styles.resultadoCamposConclusao}>{selectedOcorrenciaResult?.observacao}</span>
          </div>
        
      </fieldset>

      <fieldset>  {/*Fieldset Anexos */}
        <legend>
          <span className={styles.fieldsetTitulo}>Anexos</span>
        </legend>

        <div className={styles.espacoVerticalFieldSet}>
          <div className={styles.scrollableArea}>
            <Grid
              className={'customGrid'}
              data={data?.slice(page.skip, page.take + page.skip)}
              pageable={{
                buttonCount: 5,
                info: true,
                type: 'numeric',
                pageSizes: true,
                previousNext: true,
              }}
              onScroll={scrollHandler}
              fixedScroll={true}
              rowHeight={50}
              total={data.length}
              skip={page.skip}
              take={page.take}
              onPageChange={handlePageChange}
            >
              <GridColumn
                field="tipoRecurso.designacao"
                width="170px"
                title="Tipo"
              />
              <GridColumn field="path" width="170px" title="Propriedades" cell={(props) => (
                <td>
                  {props.dataItem.path ? props.dataItem.path.split('/').pop() : props.dataItem.url}
                </td>
              )}/>
            </Grid>
          </div>
        </div>
      </fieldset>
    </div>
  )
}
