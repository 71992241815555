import { Button } from '@progress/kendo-react-buttons'
import { ComboBox, ComboBoxFilterChangeEvent } from '@progress/kendo-react-dropdowns'
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from '@progress/kendo-react-form'
import { Checkbox, Input, RadioGroup } from '@progress/kendo-react-inputs'
import { MouseEvent as ReactMouseEvent, useEffect, useState } from 'react'

import { DateTimePicker } from '@progress/kendo-react-dateinputs'
import SessionStorageService from '../../services/SessionStorageService'
import {
  acaoEstado,
  acaoPlaneamento1,
  acaoPlaneamento2,
  acaoPlaneamento3,
  acaoPlaneamento4,
  arhs,
  capitania,
  componenteAcao,
  concelhos,
  coordenadasPoligono,
  coordenadasSelected,
  distritos,
  entidades,
  fillAcaoPlaneamento1Async,
  fillAcaoPlaneamento2Async,
  fillAcaoPlaneamento3Async,
  fillAcaoPlaneamento4Async,
  fillArhsAsync,
  fillCapitaniaAsync,
  fillCombosAuxiliares,
  fillComponenteAcaoAsync,
  fillConcelhosAsync,
  fillDistritosAsync,
  fillEntidadesAsync,
  fillFreguesiasAsync,
  fillMassaAguaAsync,
  fillNaturezaAcaoAsync,
  fillNuts1Async,
  fillNuts2Async,
  fillNuts3Async,
  fillPrioridadeAsync,
  fillTipoEngenhariaAsync,
  fillTipoPlanemanetoAsync,
  freguesias,
  hasPermission,
  massaagua,
  naturezaAcao,
  nuts1,
  nuts2,
  nuts3,
  post,
  prioridade,
  setCoordenadasPoligono,
  tip1,
  tip2,
  tip3,
  tipoEngenharia,
  tipoPlaneamento,
  useCombos
} from '../../services/utils'
import styles from '../../styles/intervencao/Intervencao.module.scss'
import { AREA_VISIVEL, CONSULTAR_ACAO, NENHUM, PERMISSION_INTERVENCAO_PERFIL_BASICO, PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO, PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA, PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO, PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_LEITURA, PERMISSION_INTERVENCAO_PERFIL_TOTAL, POLIGONO_SELECAO, clearLayers } from '../../utils/constants'
import FieldsetCombosLocalizacao from '../localizacao/FieldsetCombosLocalizacao'
import { filterBy, FilterDescriptor } from '@progress/kendo-data-query'
import ReactGA from 'react-ga4';

type Props = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
  onClick?: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
}

export default function PesquisarAcao({ onCloseClick, onClick }: Props) {

  const getAcao = async (data: any) => await post<any, any>(`${CONSULTAR_ACAO}`, data)

  const [identificacaoVisible, setIdentificacaoVisible] = useState(true)
  const toggleIdentificacao = () =>
    setIdentificacaoVisible((previousState) => !previousState)

  const [criterioGeograficoVisible, setCriterioGeograficoVisible] =
    useState(false)
  const toggleCriterioGeografico = () => {
    setCriterioGeograficoVisible((previousState) => !previousState)
  }

  const [localizacaoVisible, setLocalizacaoVisible] = useState(false)
  const toggleLocalizacao = () => {
    setLocalizacaoVisible((previousState) => !previousState)
  }

  const [dataVisible, setDataVisible] = useState(false)
  const toggleData = () => {
    setDataVisible((previousState) => !previousState)
  }

  const [entidadeVisible, setEntidadeVisible] = useState(false)
  const toggleEntidade = () => {
    setEntidadeVisible((previousState) => !previousState)
  }

  const [caracterizacaoVisible, setCaracterizacaoVisible] = useState(false)
  const toggleCaracterizacao = () => {
    setCaracterizacaoVisible((previousState) => !previousState)
  }

  const [fonteFinanciamentoVisible, setFonteFinanciamentoVisible] = useState(false)
  const toggleFonteFinanciamento = () => {
    setFonteFinanciamentoVisible((previousState) => !previousState)
  }

  const [investimentoVisible, setInvestimentoVisible] = useState(false)
  const toggleInvestimento = () => {
    setInvestimentoVisible((previousState) => !previousState)
  }

  const [concelhosFiltred, setConcelhosFiltred] = useState<any>(undefined)
  const [concelhosDisabled, setConcelhosDisabled] = useState<any>(true)
  const [freguesiasFiltred, setFreguesiasFiltred] = useState<any>(undefined)
  const [freguesiasDisabled, setFreguesiasDisabled] = useState<any>(true)
  const [nuts2Filtered, setNuts2Filtered] = useState<any>(undefined)
  const [nuts2Disabled, setNuts2Disabled] = useState<any>(true)
  const [nuts3Filtered, setNuts3Filtered] = useState<any>(undefined)
  const [nuts3Disabled, setNuts3Disabled] = useState<any>(true)
  const [minData, setMinDate] = useState<any>();

  const [tip1Data, setTip1Data] = useState(tip1 ? tip1.slice() : []);
  const [tip2Data, setTip2Data] = useState(tip2 ? tip2.slice() : []);
  const [tip3Data, setTip3Data] = useState(tip3 ? tip3.slice() : []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/PesquisarIntAcao" });
  }, []);

  useEffect(() => {
    
     if(!nuts1 ){
      fillNuts1Async();
      }
     if(!nuts2) {
      fillNuts2Async();
     }
     if(!nuts3){
      fillNuts3Async();
     }
     if(!distritos){
      fillDistritosAsync();
     }
     if(!concelhos){
      fillConcelhosAsync();
     }
     if(!freguesias){
      fillFreguesiasAsync();
     }
     if(!arhs){
      fillArhsAsync();
     }
     if(!capitania){
      fillCapitaniaAsync();
     }
     if(!massaagua){
      fillMassaAguaAsync();
     }
     if(!acaoPlaneamento1){
      fillAcaoPlaneamento1Async();
     }
     if(!acaoPlaneamento2){
      fillAcaoPlaneamento2Async();
     }
     if(!acaoPlaneamento3){
      fillAcaoPlaneamento3Async();
     }
     if(!acaoPlaneamento4){
      fillAcaoPlaneamento4Async();
     }
     if(!tipoEngenharia){
      fillTipoEngenhariaAsync();
     }
     if(!naturezaAcao){
      fillNaturezaAcaoAsync();
     }
      if(!componenteAcao){
      fillComponenteAcaoAsync();
     }
      if(!tipoPlaneamento){
      fillTipoPlanemanetoAsync();
     }
     if(!prioridade){
      fillPrioridadeAsync();
     }
     if(!entidades){
      fillEntidadesAsync();
     }

  }, []);

  const filterConcelho = (e: any) => {
    if (e?.value?.codigo) {
      const concelhosLocal = concelhos.filter(
        (x: any) => x.distrito_cod == e.value.codigo
      )
      setConcelhosFiltred(concelhosLocal)
      setConcelhosDisabled(false)
    } else {
      setConcelhosFiltred(concelhos)
      setConcelhosDisabled(true)
    }
  }

  const filterFreguesia = (e: any) => {
    if (e?.value?.codigo) {
      const freguesiaLocal = freguesias.filter(
        (x: any) => x.concelho_cod == e.value.codigo
      )
      setFreguesiasFiltred(freguesiaLocal)
      setFreguesiasDisabled(false)
    } else {
      setFreguesiasDisabled(true)
      setFreguesiasFiltred(freguesias)
    }
  }
  const filterNuts2 = (e: any) => {
    if (e?.value?.codigo) {
      const nutsLocal = nuts2.filter((x: any) => x.nuts_i_cod == e.value.codigo)
      setNuts2Filtered(nutsLocal)
      setNuts2Disabled(false)
    } else {
      setNuts2Disabled(true)
      setNuts2Filtered(nuts2)
    }
  }
  const filterNuts3 = (e: any) => {
    if (e?.value?.codigo) {
      const nutsLocal = nuts3.filter(
        (x: any) => x.nuts_ii_cod == e.value.codigo
      )
      setNuts3Filtered(nutsLocal)
      setNuts3Disabled(false)
    } else {
      setNuts3Disabled(true)
      setNuts3Filtered(nuts3)
    }
  }

  const changerbCriterio = (e: any) => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlDeselectMapArea", value: 'true' }, '*')
    if (e.value == POLIGONO_SELECAO) {
        postMessage(
            { type: 'informationMsg', value: 'Selecione a área de pesquisa' },
            '*'
        )
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage(
            { type: 'SiarlGeoPortalAdicionarArea', value: 'true' },
            '*'
        )
    }
    if (e.value == AREA_VISIVEL) {
        iframe.contentWindow?.postMessage({ type: "SiarlSelectMapArea", value: 'true' }, '*')
        postMessage(
            {
                type: 'informationMsg',
                value: 'Foi selecionada a área visível do mapa',
            },
            '*'
        )
       
        iframe.contentWindow?.postMessage(
            { type: 'SiarlGetMapArea', value: 'true' },
            '*'
        )
    }
    if (e.value == NENHUM) {
        setCoordenadasPoligono(undefined)
        clearMap()
    }
}

const filterDataTip1 = (filter: FilterDescriptor) => {
  const data = tip1.slice();
  return filterBy(data, filter);
};

const filterChangeTip1 = (event: ComboBoxFilterChangeEvent) => {
  setTip1Data(filterDataTip1(event.filter));
};

const filterDataTip2 = (filter: FilterDescriptor) => {
  const data = tip2.slice();
  return filterBy(data, filter);
};

const filterChangeTip2 = (event: ComboBoxFilterChangeEvent) => {
  setTip2Data(filterDataTip2(event.filter));
};

const filterDataTip3 = (filter: FilterDescriptor) => {
  const data = tip3.slice();
  return filterBy(data, filter);
};

const filterChangeTip3 = (event: ComboBoxFilterChangeEvent) => {
  setTip3Data(filterDataTip3(event.filter));
};


const clearMap = () => {
  try {

    setCoordenadasPoligono(undefined)
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlIntAcaoClearLayers", value: "true" }, '*')
    iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: "true" }, '*')
    iframe.contentWindow?.postMessage({ type: "SiarlDeselectMapArea", value: "true" }, '*')

  } catch (error) {
    console.log({ error })
  }
}

  
    const [dataPrevistaInicioDe, setDataPrevistaInicioDe] = useState('');
    const [dataPrevistaInicioAte, setDataPrevistaInicioAte] = useState('');
    const [dataPrevistaConclusaoDe, setDataPrevistaConclusaoDe] = useState('');
    const [dataPrevistaConclusaoAte, setDataPrevistaConclusaoAte] = useState('');
    const [dataPrevistaInicioExecucaoDe, setDataPrevistaInicioExecucaoDe] = useState('');
    const [dataPrevistaInicioExecucaoAte, setDataPrevistaInicioExecucaoAte] = useState('');
    const [dataConclusaoExecucaoDe, setDataConclusaoExecucaoDe] = useState('');
    const [dataConclusaoExecucaoAte, setDataConclusaoExecucaoAte] = useState('');

  const handleSubmit = async (e: any) => {

    
    try {
      postMessage({ type: 'showLoader' }, '*')

      const objToSearch = {
              id: e.values?.id,
              n_processo_aquisitivo: e.values?.n_processo_aquisitivo ?? undefined,
              n_filedoc: e.values?.numero_processo_filedoc ?? undefined,
              designacao: e.values?.designacao ?? undefined,
              descricao: e.values?.descricao ?? undefined,
              obs: e.values?.observacoes ?? undefined,
              id_tipo_n1: e.values?.id_tipo_n1 ? e.values?.id_tipo_n1.id : undefined,
              id_tipo_n2: e.values?.tipo_nivel_2 ? e.values?.id_tipo_n2.id : undefined,
              id_tipo_n3: e.values?.tipo_nivel_3 ? e.values?.id_tipo_n3.id : undefined,
              id_tipo_n4: e.values?.tipo_nivel_4 ? e.values?.id_tipo_n4.id : undefined,

              de_inv_previs: e.values?.de_inv_previs ?? undefined,
              ate_inv_previs: e.values?.ate_inv_previs ?? undefined,
              de_inv_realizado: e.values?.de_inv_realizado ?? undefined,
              ate_inv_realizado: e.values?.ate_inv_realizado ?? undefined,
              de_val_contrat: e.values?.de_val_contrat ?? undefined,
              ate_val_contrat: e.values?.ate_val_contrat ?? undefined,
              
              id_proc_contratacao: e.values?.id_proc_contratacao ?? undefined,
              numero_candidatura: e.values?.numero_candidatura ?? undefined,
              id_natureza_acao: e.values?.id_natureza_acao ? e.values?.id_natureza_acao.id : undefined,
              designacao_candidatura : e.values?.designacao_candidatura ?? undefined,
              id_estado: e.values?.estado? e.values?.estado.id : undefined,
              componente_acao : e.values?.componente_acao ? e.values?.componente_acao.id : undefined,
              tipo_planeamento : e.values?.tipo_planemaneto ? e.values?.tipo_planeamento.id : undefined,

            //Datas
            de_data_ini_p: e.values?.de_data_ini_p ?? undefined,
            ate_data_ini_p: e.values?.ate_data_ini_p ?? undefined,
            de_data_con_p: e.values?.de_data_con_p ?? undefined,
            ate_data_con_p : e.values?.ate_data_con_p ?? undefined,
            de_data_ini_e : e.values?.de_data_ini_e ?? undefined,
            ate_data_ini_e : e.values?.ate_data_ini_e ?? undefined,
            de_data_con_e: e.values?.de_data_con_e ?? undefined,
            ate_data_con_e: e.values?.ate_data_con_e ?? undefined,

              freguesia_cod: e.values?.freguesia?.codigo ?? undefined,
              concelho_cod: e.values?.concelho?.codigo ?? undefined,
              distrito_cod: e.values?.distrito?.codigo ?? undefined,
              nuts1_cod: e.values?.nuts1?.codigo ?? undefined,
              nuts2_cod: e.values?.nuts2?.codigo ?? undefined,
              nuts3_cod: e.values?.nuts3?.codigo ?? undefined,
              arh_cod: e.values?.arh?.codigo ?? undefined,
              massa_agua_id: e.values?.massaAgua?.id ?? undefined,
              capitania_cod : e.values?.capitania?.codigo ?? undefined,
              criterio_geografico: e?.values?.rbCriterio ?? undefined,
              flatCoordinates: coordenadasPoligono
      }
      
      SessionStorageService.setItem("lastSearchAcao", objToSearch);
      const result: any = await getAcao(objToSearch);

      if (result) {

        SessionStorageService.setItem('resultadoAcaoPesquisa', JSON.stringify(result))
        postMessage({ type: 'resultadoAcaoPesquisaChange', value: result }, '*')

      }
      SessionStorageService.setItem("firstCallAcao", true);

    } catch (error) {
      postMessage({ type: 'errorMsg', value:{message:"Não foi possivel efetuar esta operação"} }, '*')
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
  }
  type combosKeys = 'acaoEstado'
  |'acaoPlaneamento1'| 'acaoPlaneamento2' | 'acaoPlaneamento3'|'acaoPlaneamento4'
  |'tipoEngenharia' | 'naturezaAcao' |'componenteAcao' | 'tipoPlaneamento'
  |'entidades';

  const [combos, setCombos] = useState<any>({
    acaoEstado, 
    entidades, 
    acaoPlaneamento1, 
    acaoPlaneamento2,
    acaoPlaneamento3,
    acaoPlaneamento4,
    tipoEngenharia,
    naturezaAcao,
    componenteAcao,
    tipoPlaneamento
  })
  const setDataOption: Record<combosKeys, { setFn: any, data: any[] }> = {
    acaoEstado: { setFn: setCombos, data: acaoEstado },
    entidades: { setFn: setCombos, data: entidades },
    acaoPlaneamento1: { setFn: setCombos, data: acaoPlaneamento1 },
    acaoPlaneamento2: { setFn: setCombos, data: acaoPlaneamento2 },
    acaoPlaneamento3: { setFn: setCombos, data: acaoPlaneamento3 },
    acaoPlaneamento4: { setFn: setCombos, data: acaoPlaneamento4 },
    tipoEngenharia: { setFn: setCombos, data: tipoEngenharia },
    naturezaAcao: { setFn: setCombos, data: naturezaAcao },
    componenteAcao: { setFn: setCombos, data: componenteAcao },
    tipoPlaneamento: { setFn: setCombos, data: tipoPlaneamento },
  };


  const filterCombo_Multiselect = (e: any, tipoCombo: combosKeys) => {
    const { setFn, data } = setDataOption[tipoCombo];
      setFn({
          ...combos,
          [tipoCombo]: filterBy(data?.slice()??[], e.filter)
      }
      );
}

const canSeeAcao = hasPermission(PERMISSION_INTERVENCAO_PERFIL_BASICO) ||
                  hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO) ||
                  hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA) ||
                  hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO) ||
                  hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_LEITURA) ||
                  hasPermission(PERMISSION_INTERVENCAO_PERFIL_TOTAL);

  return (
    <Form
      onSubmitClick={handleSubmit}
      render={(formRenderProps: FormRenderProps) => (
        <>
          <FormElement className={styles.form}>
            <fieldset>
              <legend>
                <button
                  type="button"
                  className={styles.itemExpandBase}
                  onClick={toggleIdentificacao}
                >
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span
                      className={
                        identificacaoVisible
                          ? 'icon-siarl icon-colapsar'
                          : 'icon-siarl icon-expandir'
                      }
                    />
                  </div>
                  <span className={styles.fieldsetTitulo}>Identificação</span>
                </button>
              </legend>

              {identificacaoVisible && (
                <div className={styles.espacoVerticalFieldSet}>
                  <div className={styles.fields}>
                    <div>
                      <Field name="id" label="ID (Código)" component={Input} />
                    </div>
                    {
                      hasPermission(PERMISSION_INTERVENCAO_PERFIL_TOTAL) ||
                      hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO) ||
                      hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA)?<>
                      <div>
                        <Field
                          name="n_processo_aquisitivo"
                          label="N.º de processo aquisitivo"
                          component={Input}
                        />
                      </div>
                      <div>
                        <Field
                          name="numero_processo_filedoc"
                          label="N.º de processo filedoc"
                          component={Input}
                          textField={'descricao'}
                        />
                      </div>
                    </>:<></>
                    }
                  </div>
                  <div className={styles.fields}>
                    <div>
                      <Field
                        name="designacao"
                        label="Designação"
                        component={Input}
                        textField={'descricao'}
                      />
                    </div>
                  </div>
                  <div className={styles.fields}>
                    <div>
                      <Field
                        name="estado"
                        label="Estado"
                        component={ComboBox}
                        textField={'descricao'}
                        filterable={true}
                        onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'acaoEstado')}}
                        data={combos?.acaoEstado}

                      />
                    </div>
                  </div>
                </div>
              )}
            </fieldset>
            <fieldset>
              <legend>
                <button
                  type="button"
                  className={styles.itemExpandBase}
                  onClick={toggleCriterioGeografico}
                >
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span
                      className={
                        criterioGeograficoVisible
                          ? 'icon-siarl icon-colapsar'
                          : 'icon-siarl icon-expandir'
                      }
                    ></span>
                  </div>
                  <span className={styles.fieldsetTitulo}>
                    Critério geográfico
                  </span>
                </button>
              </legend>
              {criterioGeograficoVisible ? (
                <div className={styles.fields}>
                  <Field
                    id={'rbCriterio'}
                    name={'rbCriterio'}
                    layout={'horizontal'}
                    component={RadioGroup}
                    onChange={(e) => {
                      changerbCriterio(e)
                  }}                    
                    data={[
                      { label: 'Nenhum', value: 0},
                      { label: 'Área visível', value: 1 },
                      { label: 'Polígono de seleção', value: 2 },
                    ]}
                    defaultValue={0} // Set the default selected value to 0 (Nenhum)

                  />
                </div>
              ) : (
                <></>
              )}
            </fieldset>
            <FieldsetCombosLocalizacao styles={styles} isPesquisaAvancada={true} formRenderProps={formRenderProps}/>
            <fieldset>
              <legend>
                <button
                  type="button"
                  className={styles.itemExpandBase}
                  onClick={toggleData}
                >
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span
                      className={
                        dataVisible
                          ? 'icon-siarl icon-colapsar'
                          : 'icon-siarl icon-expandir'
                      }
                    />
                  </div>
                  <span className={styles.fieldsetTitulo}>
                    Intervalo de datas
                  </span>
                </button>
              </legend>
              {dataVisible && (
                <div className={styles.espacoVerticalFieldSet}>
                  <legend className={styles.fieldsetSubTitulo}>
                    Data prevista de início
                  </legend>
                  <div className={styles.fields}>
                    <div>
                      <Field
                        name="de_data_ini_p"
                        label="De"
                        component={DateTimePicker}
                        format="dd/MM/yyyy HH:mm"
                        onChange={(e) => {
                          setDataPrevistaInicioDe(e.value)
                           setMinDate(e.value);
                        }}                         />
                    </div>
                    <div>
                      <Field
                        name="ate_data_ini_p"
                        label="Até"
                        component={DateTimePicker}
                        format="dd/MM/yyyy HH:mm"
                        min={minData}
                      />
                    </div>
                  </div>
                  <legend className={styles.fieldsetSubTitulo}>
                    Data prevista de conclusão
                  </legend>
                  <div className={styles.fields}>
                    <div>
                      <Field
                        name="de_data_con_p"
                        label="De"
                        component={DateTimePicker}
                        format="dd/MM/yyyy HH:mm"
                        onChange={(e) => {
                          setDataPrevistaConclusaoDe(e.value)
                           setMinDate(e.value);
                        }}                       />
                    </div>
                    <div>
                      <Field
                        name="ate_data_con_p"
                        label="Até"
                        component={DateTimePicker}
                        format="dd/MM/yyyy HH:mm"
                        min={minData}
                      />
                    </div>
                  </div>
                  <legend className={styles.fieldsetSubTitulo}>
                    Data prevista de início da execução
                  </legend>
                  <div className={styles.fields}>
                    <div>
                      <Field
                        name="de_data_ini_e"
                        label="De"
                        component={DateTimePicker}
                        format="dd/MM/yyyy HH:mm"
                        onChange={(e) => {
                          setDataPrevistaInicioExecucaoDe(e.value)
                           setMinDate(e.value);
                        }}                       />
                    </div>
                    <div>
                      <Field
                        name="ate_data_ini_e"
                        label="Até"
                        component={DateTimePicker}
                        format="dd/MM/yyyy HH:mm"
                        min={minData}
                      />
                    </div>
                  </div>
                  <legend className={styles.fieldsetSubTitulo}>
                    Data de conclusão da execução
                  </legend>
                  <div className={styles.fields}>
                    <div>
                      <Field
                        name="de_data_con_e"
                        label="De"
                        component={DateTimePicker}
                        format="dd/MM/yyyy HH:mm"
                        onChange={(e) => {
                          setDataConclusaoExecucaoDe(e.value)
                           setMinDate(e.value);
                        }}                        />
                    </div>
                    <div>
                      <Field
                        name="ate_data_con_e"
                        label="Até"
                        component={DateTimePicker}
                        format="dd/MM/yyyy HH:mm"
                        min={minData}
                      />
                    </div>
                  </div>
                </div>
              )}
            </fieldset>
            <fieldset>
              <legend>
                <button
                  type="button"
                  className={styles.itemExpandBase}
                  onClick={toggleEntidade}
                >
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span
                      className={
                        entidadeVisible
                          ? 'icon-siarl icon-colapsar'
                          : 'icon-siarl icon-expandir'
                      }
                    />
                  </div>
                  <span className={styles.fieldsetTitulo}>
                    Entidade responsável
                  </span>
                </button>
              </legend>
              {entidadeVisible && (
                <div
                  className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}
                >
                  <div>
                    <Field
                      name="entidade"
                      label="Entidade"
                      component={ComboBox}
                      textField={'nome'}
                      filterable={true}
                      onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'entidades')}}
                      data={combos?.entidades}
                    />
                  </div>
                </div>
              )}
            </fieldset>
            <fieldset>
              <legend>
                <button
                  type="button"
                  className={styles.itemExpandBase}
                  onClick={toggleCaracterizacao}
                >
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span
                      className={
                        caracterizacaoVisible
                          ? 'icon-siarl icon-colapsar'
                          : 'icon-siarl icon-expandir'
                      }
                    />
                  </div>
                  <span className={styles.fieldsetTitulo}>
                    Caracterização
                  </span>
                </button>
              </legend>
              {caracterizacaoVisible && (
                <div className={styles.espacoVerticalFieldSet}>
                  <div className={styles.fields}>
                    <div>
                      <Field
                        name="id_tipo_n1"
                        label="Tipologia Nivel 1"
                        component={ComboBox}
                        textField={'descricao'}
                        filterable={true}
                        onFilterChange={filterChangeTip1}
                        data={tip1Data}
                      />
                    </div>
                    <div>
                      <Field
                        name="id_tipo_n2"
                        label="Tipologia Nivel 2"
                        component={ComboBox}
                        textField={'descricao'}
                        filterable={true}
                        onFilterChange={filterChangeTip2}
                        data={tip2Data}
                      />
                    </div>
                    <div>
                      <Field
                        name="id_tipo_n3"
                        label="Tipologia Nivel 3"
                        component={ComboBox}
                        textField={'descricao'}
                        filterable={true}
                        onFilterChange={filterChangeTip3}
                        data={tip3Data}

                      />
                    </div>
                  </div>
                  <div className={styles.fields}>
                    <div>
                      <Field
                        name="id_tipo_n4"
                        label="Tipologia Nivel 4"
                        component={ComboBox}
                        textField={'descricao'}
                        filterable={true}
                        onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'acaoPlaneamento4')}}
                        data={combos?.acaoPlaneamento4}



                      />
                    </div>
                    <div>
                      <Field
                        name="tipo_engenharia"
                        label="Tipo de Engenharia"
                        component={ComboBox}
                        textField={'descricao'}
                        filterable={true}
                        onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'tipoEngenharia')}}
                        data={combos?.tipoEngenharia}
                      />
                    </div>
                    <div>
                      <Field
                        name="id_natureza_acao"
                        label="Natureza da ação"
                        component={ComboBox}
                        textField={'descricao'}
                        filterable={true}
                        onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'naturezaAcao')}}
                        data={combos?.naturezaAcao}
                      />
                    </div>
                  </div>
                  <div className={styles.fields}>
                    <div>
                      <Field
                        name="componente_acao"
                        label="Componente da ação"
                        component={ComboBox}
                        textField={'descricao'}
                        filterable={true}
                        onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'componenteAcao')}}
                        data={combos?.componenteAcao}
                      />
                    </div>
                    <div>
                      <Field
                        name="tipo_planeamento"
                        label="Tipo de planeamento"
                        component={ComboBox}
                        textField={'descricao'}
                        filterable={true}
                        onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'tipoPlaneamento')}}
                        data={combos?.tipoPlaneamento}
                      />
                    </div>
                  </div>
                </div>
              )}
            </fieldset>
            <fieldset>
              <legend>
                <button
                  type="button"
                  className={styles.itemExpandBase}
                  onClick={toggleFonteFinanciamento}
                >
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span
                      className={
                        fonteFinanciamentoVisible
                          ? 'icon-siarl icon-colapsar'
                          : 'icon-siarl icon-expandir'
                      }
                    />
                  </div>
                  <span className={styles.fieldsetTitulo}>
                    Fonte de financiamento
                  </span>
                </button>
              </legend>
              {fonteFinanciamentoVisible && (
                <div className={styles.espacoVerticalFieldSet}>
                  <div className={styles.fields}>
                    <div>
                      <Field
                        name="comunitario"
                        label="Comunitário"
                        component={Checkbox}
                      />
                    </div>
                    <div>
                      <Field
                        name="nacional"
                        label="Nacional"
                        component={Checkbox}
                      />
                    </div>
                  </div>
                  <div className={styles.fields}>
                    <div>
                      <Field
                        name="numero_candidatura"
                        label="N.º de candidatura"
                        component={Input}
                      />
                    </div>
                  </div>
                  <div className={styles.fields}>
                    <div>
                      <Field
                        name="designacao_candidatura"
                        label="Designação da candidatura"
                        component={Input}
                      />
                    </div>
                  </div>
                </div>
              )}
            </fieldset>
            <fieldset>
              <legend>
                <button
                  type="button"
                  className={styles.itemExpandBase}
                  onClick={toggleInvestimento}
                >
                  <div className={styles.btn_Expandir_Colapsar}>
                    <span
                      className={
                        investimentoVisible
                          ? 'icon-siarl icon-colapsar'
                          : 'icon-siarl icon-expandir'
                      }
                    />
                  </div>
                  <span className={styles.fieldsetTitulo}>Investimento</span>
                </button>
              </legend>
              {investimentoVisible && (
                <div className={styles.espacoVerticalFieldSet}>
                  {hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_LEITURA) ||hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO) ||hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA)  ||hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO)  ||hasPermission(PERMISSION_INTERVENCAO_PERFIL_TOTAL)? <>
                  <legend className={styles.fieldsetSubTitulo}>
                    Valor previsto
                  </legend>
                  <div className={styles.fields}>
                    <div>
                      <Field
                        name="de_inv_previs"
                        label="De"
                        component={Input}
                      />
                    </div>
                    <div>
                      <Field
                        name="ate_inv_previs"
                        label="Até"
                        component={Input}
                      />
                    </div>
                  </div>
                  </>:<></>}
                  <legend className={styles.fieldsetSubTitulo}>
                    Valor do contrato
                  </legend>
                  <div className={styles.fields}>
                    <div>
                      <Field
                        name="de_val_contrat"
                        label="De"
                        component={Input}
                      />
                    </div>
                    <div>
                      <Field
                        name="ate_val_contrat"
                        label="Até"
                        component={Input}
                      />
                    </div>
                  </div>
                  <legend className={styles.fieldsetSubTitulo}>
                    Valor executado
                  </legend>
                  <div className={styles.fields}>
                    <div>
                      <Field
                        name="de_inv_realizado"
                        label="De"
                        component={Input}
                      />
                    </div>
                    <div>
                      <Field
                        name="ate_inv_realizado"
                        label="Até"
                        component={Input}
                      />
                    </div>
                  </div>
                </div>
              )}
            </fieldset>
          </FormElement>

          <div
            className={`${styles.footer} ${styles.justify_multiple_children}`}
          >
            <Button
              onClick={() => { formRenderProps.onFormReset(); clearMap()}}
              className={styles.btnLimparPesquisa}
              title='Limpar pesquisa'
            >
              <span className="icon-siarl icon-limparcampos"></span>
              <span className={styles.rotulo}>
                {' '}&nbsp;{' '}
                Limpar pesquisa
              </span>
            </Button>

            <Button
              onClick={formRenderProps.onSubmit}
              className={styles.btnPesquisar}
              title='Pesquisar'
            >
              <span className="icon-siarl icon-pesquisar"></span>
              <span className={styles.rotulo}>
                {' '}&nbsp;{' '}
                Pesquisar
              </span>       
            </Button>
          </div>
        </>
      )}
    />
  )
}
