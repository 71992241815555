import { Button, DropDownButton } from '@progress/kendo-react-buttons'
import { useEffect, useLayoutEffect, useState } from 'react'

import { Form, FormElement } from '@progress/kendo-react-form'
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from '@progress/kendo-react-layout'
import { MouseEvent as ReactMouseEvent } from 'react'
import {
  GetUserNameTecnico,
  b64toBlob,
  clearAcao,
  fillCombosAuxiliares,
  freguesias,
  get,
  getCandidaturaAcaoSelected,
  getCandidaturaSelected,
  handleGenericError,
  hasPermission,
  instrumentoFinanciamento,
  post,
  put,
  setCandidaturaAcaoSelected,
  setCandidaturaSelected,
  setCoordenadasPoligono,
  setCoordenadasSelected,
  useCombos
} from '../../services/utils'
import { useCandidaturaStore } from '../../stores/candidatura'
import styles from '../../styles/intervencao/Intervencao.module.scss'
import { AUTH_LOCALSTORAGE, GET_URL_FROM_FILE_DOC, PERMISSION_ATUALIZAR_CANDIDATURA, PERMISSION_INTERVENCAO_PERFIL_BASICO, PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO, PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA, PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO, PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_LEITURA, PERMISSION_INTERVENCAO_PERFIL_TOTAL, PERMISSION_INSERIR_CANDIDATURA, PESQUISAR_CANDIDATURA, PESQUISAR_MOBILIZACAO, PESQUISAR_MOBILIZACAO_BY_ID, RELATORIO_CANDIDATURA, SAVE_ACAO_CANDIDATURA, SAVE_NEW_CANDIDATURA, UPDATE_ACAO_CANDIDATURA, UPDATE_CANDIDATURA, URL_AJUDA_INTERVENCOES_CANDIDATURA, INT_CANDIDATURA_POINT_NAME, CURRENT_TIMEZONE } from '../../utils/constants'
import { AssociacaoListagem } from '../associacao/Associacao'
import { DocumentoListagem } from '../documentos/registar/documento/documento'
import AcaoCandidatura from './AcaoCandidatura'
import IdetificacaCandidatura from './IdentificaoCandidatura'
import { Dialog } from '@progress/kendo-react-dialogs'
import SessionStorageService from '../../services/SessionStorageService'
import html2canvas from 'html2canvas'
import ReactGA from 'react-ga4';
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais'
import moment from 'moment'
const TOTAL_TABS = 3

type Props = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
}
type candidaturaForm = {
  id: number
  designacao: string
  cod_candid: string
  valor_candid: number
  valor_comp: number
  valor_total: number
  id_tipo_in: number
  status: boolean
  data_registo: unknown,
  data_hora_registo: any,
  data_submissao: unknown
  instr_financiamento_id: number
  estado_candidatura_id: number
  filedoc_cod: string
  utilizador_id : number,
  obs?: string,
  lstCandidaturaAcao?: any[]
}
type candidaturaAcaoForm = {
  id: number
  id_candida: number
  id_acao: number
  valor_candid: number
  valor_comp: number
  valor_total: number
}


export default function RegistarCandidatura({ onCloseClick }: Props) {
  const [isMobile, setIsMobile] = useState(false);
  const [selected, setSelected] = useState<number>(0)
  const selectedCandidaturaResult: any = useCandidaturaStore(
    (state) => state.selectedCandidaturaResult
  )
  const selectCandidaturaResult = useCandidaturaStore((state) => state.selectCandidaturaResult)
  const selectCandidaturaResultId = useCandidaturaStore((state) => state.selectCandidaturaResultId)
  const saveCandidatura = async (data: any) => await post<any, any>(`${SAVE_NEW_CANDIDATURA}`, data)
  const updateCandidatura = async (data: any) => await put<any, any>(`${UPDATE_CANDIDATURA}`, data)
  const saveCandidaturaAcao = async (data: any) => await post<any, any>(`${SAVE_ACAO_CANDIDATURA}`, data)
  const updateCandidaturaAcao = async (data: any) => await put<any, any>(`${UPDATE_ACAO_CANDIDATURA}`, data)
  const getUrlFileDoc = async (data: any) => await get<any>(`${GET_URL_FROM_FILE_DOC}/` + encodeURIComponent(data));


  const relatorioCandidatura = async (data: any) =>
    await post<any, any>(`${RELATORIO_CANDIDATURA}`, data)

  const [deleteClicked, setDeleteClicked] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [canInsert, setCanInsert] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  
  const getCandidatura = async (data: any) =>
  await get<any, any>(`${PESQUISAR_CANDIDATURA}/` + data)

   useEffect(() => {
    setViewMode(!editMode && sessionStorage.getItem("novaCandidatura") != 'true');
  }, [])

  useEffect(() => {
    setCanUpdate(hasPermission(PERMISSION_ATUALIZAR_CANDIDATURA));
    setCanInsert(hasPermission(PERMISSION_INSERIR_CANDIDATURA))
  }, [])

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/RegistarIntCandidatura" });
  }, []);

  useEffect(() => {
    addEventListener('message', (event: MessageEvent) => {
      if (event.data.type == 'CoordenadasSelected') {
        console.log(event.data.value)
        if (event.data.value) {
          const flatPointCoordinates =
            event.data.value.x + ', ' + event.data.value.y
          setCoordenadasSelected(flatPointCoordinates)
        }
      } else if (event.data.type == 'CoordenadasFlatSelected') {
        let polygonString = ''
        const values: any[] = event.data.value
        values?.forEach((value, index) => {
          if (index % 2 == 0) {
            polygonString += value + ' '
          } else {
            polygonString += value + ','
          }
        })
        polygonString = polygonString.substring(0, polygonString.length - 1)
        setCoordenadasPoligono(polygonString)
        console.log(polygonString)
      }
    })
  }, [])

  const selectMapAction = (item: any) => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: item.key, value: getCandidaturaSelected()?.id, editGpLayer: INT_CANDIDATURA_POINT_NAME }, '*')
  }

  const voltar = () => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
       {
         type: 'SiarlCoordenadaBoxClose',
         value: 'true',
       },
       '*'
     )
    postMessage({ type: 'form', value: 'pesquisarCandidatura' }, '*')
    clearAcao()
  }

  const itemRender = (props: { item: any; itemIndex: number }) => {
    const isTitleItem = props.item.key === 'titulo'
    if (isTitleItem) {
      // Item de título personalizado
      return (
        <div className={styles.DropDownButtonTitulo}>
          <span>{props.item.text}</span>
        </div>
      )
    } else {
      // Demais itens
      return (
        <div className={styles.DropDownButtonItem}>
          <span>{props.item.text}</span>
        </div>
      )
    }
  }

  const [openModalDescartar, setOpenModalDescartar] = useState<any>()

  const cancelModalDescartar = () => {
    setOpenModalDescartar(false)
  }

  const refreshForm = async(id: any = undefined) => {

    const candidaturaSelected = getCandidaturaSelected();

     let idCandidatura = id;
    if (!idCandidatura) {
      idCandidatura = candidaturaSelected?.id
    }

    //const candidaturaSelected = getCandidaturaSelected();

    //const candidatura = await getCandidaturaById(candidaturaSelected?.id);

    const candidatura = await getCandidatura(idCandidatura);
    const tecnico = await GetUserNameTecnico(candidatura.utilizador_id);


   const objToShow = {
      ...candidatura,
      id: candidatura?.id,
      designacao: candidatura?.designacao ?? undefined,
      cod_candid: candidatura?.cod_candid ?? undefined,
      valor_candid: candidatura?.valor_candid ?? undefined,
      valor_comp: candidatura?.valor_comp ?? undefined,
      valor_total: candidatura?.valor_total ?? undefined,
      data_registo : candidatura.data_registo? moment.utc(new Date(candidatura?.data_registo)).tz(CURRENT_TIMEZONE).format("DD/MM/yyyy") :null,
      data_submissao : candidatura.data_submissao? moment.utc(new Date(candidatura?.data_submissao)).tz(CURRENT_TIMEZONE).format("DD/MM/yyyy") :null,
      instr_financiamento_id: candidatura?.intInstrumentoFinanciamento ?? undefined,
      id_tipo_in: candidatura?.tipo_incidencia ?? undefined,
      estado_candidatura_id: candidatura?.intCandidaturaEstado ?? undefined,
      utilizador_id : tecnico
    }

    selectCandidaturaResult(objToShow);
    setCandidaturaSelected(objToShow);
    const iframe: any = document.getElementById('iframeGeoPortal')

    iframe.contentWindow?.postMessage({ type: "SiarlCandidaturaSearch", value: candidatura?.id }, '*')
}

  const handleCancel = ()  => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
       {
         type: 'SiarlCoordenadaBoxClose',
         value: 'true',
       },
       '*'
    )
    refreshForm();
    postMessage({ type: "form", value: "" }, "*");
    setTimeout(() => {
      postMessage({ type: "form", value: "registarCandidatura" }, "*");
    }, 10)
  }

  const handleOpenModalDescartar = () => {
    if (
      sessionStorage.getItem('novaCandidatura') &&
      sessionStorage.getItem('novaCandidatura') == 'true'
    ) {
      setOpenModalDescartar(true);
    } else {
      onEditItemClick("SiarlVisualizar");
     handleCancel();

    }
  }


  const [estadoFalse, setEstado] = useState(false)
    const [estadoTrue, setEstadoTrue] = useState(true)


  const handleDescartar = async (e: any) => {

    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
      {
        type: 'SiarlRemoveSelection',
        value: 'true',
      },
      '*'
    )
  setDeleteClicked(false)
  setOpenModalDescartar(false)
   const candidaturaSelected = getCandidaturaSelected()

    const dataToSend : any = {
      id: candidaturaSelected?.id ?? undefined,
      status: estadoFalse
    }

      let candidaturaSaved: any
   
    if (candidaturaSelected?.id) {
      postMessage({ type: 'showLoader' }, '*')
          candidaturaSaved = await updateCandidatura(dataToSend)
          selectCandidaturaResult({
            ...selectedCandidaturaResult,
            ...candidaturaSaved,})
          }
          postMessage({ type: 'hideLoader' }, '*')

    SessionStorageService.removeItem("firstCallCandidatura");
    postMessage({ type: 'form', value: 'pesquisarCandidatura' }, '*')


}

  const onItemClick = (event: any) => {
    selectMapAction(event.item)
  }

  const onItemClickImprimir = async (event: any) => {

    let mapImage = ''

    if (!window.location.href.includes('localhost')) {
      const mapIframe = document.querySelector(
        '#iframeGeoPortal'
      ) as HTMLIFrameElement

      const mapIframeInnerContent =
        mapIframe.contentWindow?.document.querySelector(
          'div.ol-viewport'
        ) as HTMLElement;

      const mapBussula =  mapIframe.contentWindow?.document.querySelector(
        'button.fixedNorthArrowButton'
      ) as HTMLElement;

      mapIframeInnerContent.appendChild(mapBussula);
      
      const generatedImage = await html2canvas(mapIframeInnerContent);
      mapImage = generatedImage.toDataURL()
    }

    console.log(selectedCandidaturaResult);

    try {
      postMessage({ type: 'showLoader' }, '*')

      const documentoBase64 = await relatorioCandidatura({
        id: selectedCandidaturaResult?.id,
        dados: {
          'REGISTO' : '',
          'Nº Processo APA:': selectedCandidaturaResult?.filedoc_cod ?? '',
          'Data do registo:': selectedCandidaturaResult?.data_registo ? new Date(selectedCandidaturaResult?.data_registo).toLocaleDateString() : '',
          'Data de submissão:': selectedCandidaturaResult?.data_submissao ? new Date(selectedCandidaturaResult?.data_submissao).toLocaleDateString() : '',
          'Código da candidatura:': selectedCandidaturaResult?.cod_candid ?? '',
          'Designação:': selectedCandidaturaResult?.designacao ?? '',
          'Técnico:': selectedCandidaturaResult?.utilizador_id ?? '',

          'FONTE DE FINANCIAMENTO' : '',
          'Fonte de finaciamento:': selectedCandidaturaResult?.tipo_incidencia?.descricao ?? '',
          'INSTRUMENTO DE FINANCIAMENTO:' : '',
          'Instrumento de finaciamento:': selectedCandidaturaResult?.intInstrumentoFinanciamento?.designacao ?? '',

          'INVESTIMENTO' : '',
          'Despesa elegível:': selectedCandidaturaResult?.valor_candid?.toString() ?? '',
          'Despesa não elegível:': selectedCandidaturaResult?.valor_compo ?? '',
          'Despesa total:': selectedCandidaturaResult?.valor_total?.toString() ?? '',

          'ESTADO' : '',
          'Estado da candidatura:': selectedCandidaturaResult?.intCandidaturaEstado?.descricao ?? '',
       
        },
        img_mapa: mapImage
      });

      if (documentoBase64) {
        const blob = b64toBlob(documentoBase64, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
        postMessage({ type: 'hideLoader' }, '*')
      }
    } catch (error) {
      postMessage({ type: 'errorMsg', value: { message: "Não foi possivel efetuar esta operação" } }, '*')
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }

  }

  const optionsImprimir = [
    {
      key: 'imprimirFichas',
      text: 'Fichas',
    },
    {
      key: 'imprimirResultados',
      text: 'Resultados',
    },
  ]

  const onItemClickExportar = async (event: any) => {

    let mapImage = ''

    if (!window.location.href.includes('localhost')) {
      const mapIframe = document.querySelector(
        '#iframeGeoPortal'
      ) as HTMLIFrameElement

      const mapIframeInnerContent =
        mapIframe.contentWindow?.document.querySelector(
          'div.ol-viewport'
        ) as HTMLElement;

      const mapBussula =  mapIframe.contentWindow?.document.querySelector(
        'button.fixedNorthArrowButton'
      ) as HTMLElement;

      mapIframeInnerContent.appendChild(mapBussula);
      
      const generatedImage = await html2canvas(mapIframeInnerContent);
      mapImage = generatedImage.toDataURL()
    }

    console.log(selectedCandidaturaResult);

    try {
      postMessage({ type: 'showLoader' }, '*')

      const documentoBase64 = await relatorioCandidatura({
        id: selectedCandidaturaResult?.id,
        dados: {
          'REGISTO' : '',
          'Nº Processo APA:': selectedCandidaturaResult?.filedoc_cod ?? '',
          'Data do registo:: ': selectedCandidaturaResult?.data_registo ? new Date(selectedCandidaturaResult?.data_registo).toLocaleDateString() : '',
          'Data de submissão:': selectedCandidaturaResult?.data_submissao ? new Date(selectedCandidaturaResult?.data_submissao).toLocaleDateString() : '',
          'Código da candidatura:': selectedCandidaturaResult?.cod_candid ?? '',
          'Designação:': selectedCandidaturaResult?.designacao ?? '',
          'Técnico:': selectedCandidaturaResult?.tecnico ?? '',

          'FONTE DE FINANCIAMENTO' : '',
          'Fonte de finaciamento:': selectedCandidaturaResult?.tipo_incidencia?.descricao ?? '',
          'INSTRUMENTO DE FINANCIAMENTO:' : '',
          'Instrumento de finaciamento:': selectedCandidaturaResult?.intInstrumentoFinanciamento?.designacao ?? '',

          'INVESTIMENTO' : '',
          'Despesa elegível:': selectedCandidaturaResult?.valor_candid?.toString() ?? '',
          'Despesa não elegível:': selectedCandidaturaResult?.valor_compo ?? '',
          'Despesa total:': selectedCandidaturaResult?.valor_total?.toString() ?? '',

          'ESTADO' : '',
          'Estado da candidatura:': selectedCandidaturaResult?.intCandidaturaEstado?.descricao ?? '',
       
        },
        img_mapa: mapImage
      });

      if (documentoBase64) {
        const link = document.createElement('a')
        link.href = 'data:application/octet-stream;base64,' + documentoBase64
        link.download = 'reporte_candidatura.pdf'
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        postMessage({ type: 'hideLoader' }, '*')
      }
    } catch (error) {
      postMessage({ type: 'errorMsg', value: { message: "Não foi possivel efetuar esta operação" } }, '*')
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
   
  }

  const optionsExportar = [

    {
      key: 'exportarFichas',
      text: 'Fichas',
    },
    {
      key: 'exportarResultados',
      text: 'Resultados',
    },
  ]

   const optionsEditar = [
    {
      key: 'SiarlEditar',
      text: 'Editar',
    },
    {
      key: 'SiarlApagar',
      text: 'Apagar',
    },
    
  ]

   const onEditItemClick= (event:any) => {
    if (event == "SiarlEditar" || event?.item?.key == "SiarlEditar") {
      setEditMode(true);
      setViewMode(false);
      return;
    }
    if (event == "SiarlVisualizar") {
      setEditMode(false);
      setViewMode(true);
    }
    if (event?.item?.key == "SiarlApagar") {
      setDeleteClicked(true);
      setOpenModalDescartar(true);
    }
  }

  useEffect(() => {
    if (viewMode) {
      sessionStorage.removeItem("novaCandidatura");
    }
  })

  const onClickOpenFileDoc = async() => {
    const candidaturaSelected = getCandidaturaSelected();
    if (!candidaturaSelected?.filedoc_cod) {
      postMessage({ type: "infoMsg", value: {message: "É necessário preencher o nº processo APA (filedoc)."} }, "*");
      return;
    }
    postMessage({ type: 'showLoader' }, '*');
    const urlFileDoc = await getUrlFileDoc(candidaturaSelected?.filedoc_cod);
    postMessage({ type: 'hideLoader' }, '*')
    window.open(urlFileDoc, '_blank');
    return;
  }

  const handleSelect = (e: TabStripSelectEventArguments) => {
    if (e.selected > 0 && !selectedCandidaturaResult) {
      postMessage({type:"infoMsg", value: {message: "É necessário gravar primeiro."}})
      return;
    }
    setSelected(e.selected)
  }
  const handleSubmit = async (e: any) => {
    const candidaturaSelected = getCandidaturaSelected();
    const candidaturaAcaoSelected = getCandidaturaAcaoSelected();
    const userId = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '')?.user?.id

    const dataRegisto: any = e.values?.data_registo ? moment.utc(e.values?.data_registo).tz(CURRENT_TIMEZONE) : undefined
    const dataSubmissao : any = e.values?.data_submissao ? moment.utc(e.values?.data_submissao).tz(CURRENT_TIMEZONE) : undefined
    const dataHoraRegisto: any = e.values?.data_hora_registo ? moment.utc(e.values?.data_hora_registo).tz(CURRENT_TIMEZONE) : undefined
    const gridDataAcaoCandidatura: any[] = SessionStorageService.getItem("gridDataAcaoCandidatura") ?? undefined;
    const lstCandidaturaAcao = gridDataAcaoCandidatura?.map(item => ({
      id_acao: item.id_acao,
      iva: item.iva,
      despesa_elegivel: item.despesa_elegivel,
      despesa_nao_elegivel: item.despesa_nao_elegivel,
      percentagem_coparticipacao: item.percentagem_comparticipacao,
      inv_elegivel: item.inv_elegivel,
      inv_comunitario: item.inv_comunitario,
      inv_nacional: item.inv_nacional,
      inv_previsto: item.inv_previsto
    }));


    const candidaturaRequest: candidaturaForm = {
      id: candidaturaSelected?.id ?? undefined,
      designacao: e.values?.designacao ?? undefined,
      cod_candid: e.values?.cod_candid ?? undefined,
      valor_candid: e.values?.valor_candid ?? undefined,
      valor_comp: e.values?.valor_comp ?? undefined,
      valor_total: e.values?.valor_total ?? undefined,
      id_tipo_in: e.values?.id_tipo_in?.id ?? undefined,
      status: e.values?.status ?? undefined,
      data_registo: dataRegisto ? dataRegisto._d : undefined,
      data_hora_registo: dataHoraRegisto ? dataHoraRegisto._d : undefined,
      data_submissao: dataSubmissao ? dataSubmissao._d : undefined,
      instr_financiamento_id: e.values?.instr_financiamento_id?.id ?? undefined,
      estado_candidatura_id: e.values?.estado_candidatura_id?.id ?? undefined,
      filedoc_cod: e.values?.filedoc_cod ?? undefined,
      utilizador_id : userId,
      obs: e.values?.obs,
      lstCandidaturaAcao:  lstCandidaturaAcao ?? undefined
    }
    
    postMessage({ type: 'showLoader' }, '*');

    try {
      let candidaturaSaved: any;
      let candidaturaAcaoSaved: any;
      const candidaturaSelected = getCandidaturaSelected();
      const candidaturaAcaoSelected = getCandidaturaAcaoSelected();

        if (candidaturaSelected?.id) {
          candidaturaSaved = await updateCandidatura(candidaturaRequest);
         
          setCandidaturaSelected(candidaturaSaved);
        } else {
          candidaturaSaved = await saveCandidatura(candidaturaRequest);
          setCandidaturaSelected(candidaturaSaved);
         
        }
     
      setCoordenadasPoligono(undefined);

      
      refreshForm(candidaturaSaved?.id);
     

       setTimeout(() => {
          postMessage({ type: 'sucessMsg' }, '*')
        }, 500)

    } catch (error) {
      handleGenericError(error);
    }

    postMessage({ type: 'hideLoader' }, '*')
  }

  useLayoutEffect(() => {

    const handleResize = () => {

      if (window.innerWidth <= telaMobile) {
        
        setIsMobile(true);
        ocultaFloatingMenu_e_Mapa();

      }else{

        setIsMobile(false);
        mostraFloatingMenu_e_Mapa();

      }

    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []); 

  const onItemClickMais = (event: any) => {

    elementoClickMais(event);

    onEditItemClick(event);
    onItemClickExportar(event);

  };

  const optionsMais = [
    {
      key: 'titulo',
      text: 'Registo',
    },
    ...optionsEditar.map(option => ({
      ...option,
      className: 'icon-siarl icon-editar btnIconRegistar',
    })),
    {
      key: 'titulo',
      text: 'Opções',
    },
    {
      key: 'openFileDoc',
      text: 'Diretório de documentos',
      className: 'icon-siarl icon-filedoc btnIconRegistar'
    },    
    {
      key: 'titulo',
      text: 'Imprimir',
    },
    {
      key: 'imprimirFicha',
      text: 'Fichas',
      className: 'icon-siarl icon-imprimir btnIconRegistar'
    },
    {
      key: 'titulo',
      text: 'Exportar',
    },
    ...optionsExportar.map(option => ({
      ...option,
      className: 'icon-siarl icon-exportar btnIconRegistar',
    })),
  ]

  const openAjuda = () => {
    window.open(URL_AJUDA_INTERVENCOES_CANDIDATURA, "_blank")
  }

  const canSeeCandidatura = hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO) ||
                            hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA) ||
                            hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO) ||
                            hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_LEITURA) ||
                            hasPermission(PERMISSION_INTERVENCAO_PERFIL_TOTAL);


  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <div>
          <div className={styles.nomeDoModulo}>Candidaturas</div>
          <div className={styles.tituloDaPagina}>
           {!selectedCandidaturaResult || !selectedCandidaturaResult?.id ? 'Nova candidatura' : (editMode ? 'Editar candidatura' : 'Candidatura')}
          </div>
        </div>
        <div>
          {!isMobile && canUpdate && (viewMode || editMode) && (sessionStorage.getItem("novaCandidatura") != 'true') ? 
          <DropDownButton
            items={optionsEditar}
            className={styles.btnEditar}
            iconClass="icon-siarl icon-editar"
            onItemClick={onEditItemClick}
            fillMode={'flat'}
            itemRender={itemRender}
            title="Editar"
          ></DropDownButton>
          : ''}

          <button className={styles.btnEditar} title='Filedoc' onClick={onClickOpenFileDoc}
          style={{ display: isMobile ? 'none' : '' }} id='openFileDoc'>
            <span className="icon-siarl icon-filedoc"></span>
          </button>

          <button className={styles.btnEditar} title='Imprimir' onClick={onItemClickImprimir}
          style={{ display: isMobile ? 'none' : '' }} id='imprimirFicha'>
            <span className="icon-siarl icon-imprimir"></span>
          </button>

         
          <button id='exportarFicha' className={styles.btnImprimir} onClick={onItemClickExportar}
          style={{ display: isMobile ? 'none' : '' }} title='Exportar'>
            <span className="icon-siarl icon-exportar"></span>
          </button>

          {isMobile &&
            <DropDownButton
              items={optionsMais}
              className={styles.btnMais}
              iconClass="icon-siarl icon-mais"
              onItemClick={onItemClickMais}
              fillMode={'flat'}
              itemRender={itemRenderMais}
              title="Mais Opções"
            />
          }

          <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
            <span className="icon-siarl icon-ajuda" />
          </button>

          <button className={styles.btnFechar} onClick={voltar} title="Fechar">
            <span className="icon-siarl icon-fechar"></span>
          </button>
        </div>
      </div>

 {openModalDescartar ? (
          <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={cancelModalDescartar}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                  <fieldset>
                    <span className="textDescartar">{deleteClicked ? 'Apagar' : 'Cancelar'} registo  </span>
                    <br></br>
                    <span>{deleteClicked ? 'Ao apagar este registo deixará de ter acesso, sendo necessária uma intervenção rigorosa para realizar a sua recuperação.' : 'Ao cancelar este registo irá perder os dados inseridos até ao momento.'}</span>
                      <br></br>
                    <span>{deleteClicked ? 'Tem a certeza que pretende apagar?' : 'Tem a certeza que pretende cancelar?'}</span>
                  </fieldset>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={cancelModalDescartar}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={handleDescartar}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}
      <Form
        initialValues={{
          ...selectedCandidaturaResult,
          id: selectedCandidaturaResult?.id,
          designacao: selectedCandidaturaResult?.designacao,
          cod_candid: selectedCandidaturaResult?.cod_candid,
          valor_candid: selectedCandidaturaResult?.valor_candid,
          valor_comp: selectedCandidaturaResult?.valor_comp,
          valor_total: selectedCandidaturaResult?.valor_total,
          data_registo: selectedCandidaturaResult?.data_registo
          ? new Date(selectedCandidaturaResult?.data_registo)
          : new Date(),
          data_hora_registo: selectedCandidaturaResult?.data_hora_registo
          ? new Date(selectedCandidaturaResult?.data_hora_registo)
          : new Date(),
          data_submissao: selectedCandidaturaResult?.data_submissao,
          instr_financiamento_id: selectedCandidaturaResult?.instr_financiamento_id,
          id_tipo_in: selectedCandidaturaResult?.id_tipo_in,
          estado_candidatura_id: selectedCandidaturaResult?.estado_candidatura_id,
          ...getCandidaturaSelected()
        }}
        onSubmitClick={handleSubmit}
        render={(formRenderProps) => (
          <FormElement>
            <TabStrip
              selected={selected}
              onSelect={handleSelect}
              className={`${styles.innerStyleTabContent} ${viewMode? 'tabsVisualizar' : ''}`}
              scrollable={isMobile}
            >
              {canSeeCandidatura?
              <TabStripTab title="Identificação" key={'Identificação'}>
                <IdetificacaCandidatura viewMode={viewMode} formRenderProps={formRenderProps} onCloseClick={onCloseClick} />
              </TabStripTab>
              :<></>
            }
              <TabStripTab title="Ação" key={'Ação'}>
                <AcaoCandidatura onCloseClick={onCloseClick} viewMode={viewMode} formRenderProps={formRenderProps} />
              </TabStripTab>
              <TabStripTab title="Documentos" key={'Documentos'} >
                <DocumentoListagem pageName="candidatura" viewMode={viewMode}/>
              </TabStripTab>
            </TabStrip>

              {(editMode || sessionStorage.getItem("novaCandidatura") == 'true')
                && !viewMode ?
                  <div
                    className={`${styles.footer} ${styles.justify_multiple_children}`}
                  >

                  <Button type="button" className={styles.btnSeguinte} onClick={handleOpenModalDescartar} title='Cancelar'>
                  <span className="icon-siarl icon-fechar"></span>
                  <span className={styles.rotulo}>
                    {' '}&nbsp;{' '}
                    Cancelar
                  </span>
                 </Button>    

                 <Button
                      className={styles.btnSubmeter}
                       onClick={(e) => {
                    formRenderProps.onSubmit(e);
                    e.preventDefault();
                  }}
                  title='Gravar'
                    >
                      <span className="icon-siarl icon-submeter btnIconRegistar"></span>
                      <span className={styles.rotulo}>
                        {' '}&nbsp;{' '}
                        Gravar
                      </span>
                    </Button>
                  </div>
            : ''}
          </FormElement>
        )}
      />
    </div>
  )
}
