import { MouseEvent as ReactMouseEvent, useEffect, useLayoutEffect, useState } from 'react'
import { Button, DropDownButton } from '@progress/kendo-react-buttons'
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from '@progress/kendo-react-layout'
import SessionStorageService from '../../services/SessionStorageService'
import {
  clearDocumento,
  hasPermission,
  setDocumentoSelected,
} from '../../services/utils'
import { useDocumentosStore } from '../../stores/documentos'
import styles from '../../styles/documentos/Documentos.module.scss'
import {
  PERMISSION_DOCUMENTOS_CONSULTA_AVANCADA,
  PERMISSION_DOCUMENTOS_PERFIL_COMPLETO_EDICAO,
  PERMISSION_DOCUMENTOS_PERFIL_COMPLETO_LEITURA,
  PERMISSION_DOCUMENTOS_PERFIL_INTERMEDIO_EDICAO,
  PERMISSION_DOCUMENTOS_PERFIL_INTERMEDIO_LEITURA,
  PERMISSION_DOCUMENTOS_PERFIL_TOTAL,
  PESQUISAR_OCORRENCIAS_SIMPLES,
  URL_AJUDA_DOCUMENTOS_AVANCADA,
  URL_AJUDA_DOCUMENTOS_SIMPLES,
} from '../../utils/constants'
import PesquisarDocumentosAvancadoForm from './PesquisarDocumentosAvancadaForm'
import PesquisarDocumentosSimplesForm from './PesquisarDocumentosSimplesForm'
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais'

type Props = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
}

export default function PesquisarDocumentosTabsForm({ onCloseClick }: Props) {
  const [isMobile, setIsMobile] = useState(false);
  const [selected, setSelected] = useState<number>(0)
  const [seeAvancada, setSeeAvancada] = useState(false);
  const selectDocumentoResult = useDocumentosStore(
    (state) => state.selectDocumentosResult
  )
  const selectDocumentosResultId = useDocumentosStore(
    (state) => state.selectDocumentosResultId
  )

  useEffect(() => {
    setSeeAvancada(hasPermission(PERMISSION_DOCUMENTOS_CONSULTA_AVANCADA));
  }, [])

  const registarNovoClick = () => {
    SessionStorageService.setItem('novoDocumento', 'true')
    sessionStorage.setItem('documentoAntigo', 'false')
    SessionStorageService.setItem("isSubmitActionDocumentos", "false");
    SessionStorageService.setItem("documentoAnexado", "false");
    selectDocumentoResult(undefined)
    setDocumentoSelected(undefined);
    selectDocumentosResultId(undefined);
    clearDocumento();
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlDocumentosClearLayers", value: "true" }, '*')
    postMessage({ type: "form", value: "registarDocumentos" }, "*");
  }

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected)
  }

  useLayoutEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= telaMobile) {

        setIsMobile(true);
        ocultaFloatingMenu_e_Mapa();
      }
      else{
        setIsMobile(false);
        mostraFloatingMenu_e_Mapa();
      }

    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  const onItemClickMais = (event: any) => {

    elementoClickMais(event);

  };

  const openAjuda = () => {
    if (SessionStorageService.getItem("DocumentosPesquisaTipo")) {
      const documentoPesquisaTipo = SessionStorageService.getItem("DocumentosPesquisaTipo");
      if (documentoPesquisaTipo == "Simples") {
        window.open(URL_AJUDA_DOCUMENTOS_SIMPLES, "_blank");
      } else if (documentoPesquisaTipo == "Avancada") {
        window.open(URL_AJUDA_DOCUMENTOS_AVANCADA, "_blank");
      }
    }
  }

  const optionsMais = [
    {
      key: 'novoRegisto',
      text: 'Novo Documento',
      elementType: 'button',
      className: 'icon-siarl icon-registar btnIconRegistar'
    }
  ]
  
  const canCreateEditDocumentos = hasPermission(PERMISSION_DOCUMENTOS_PERFIL_COMPLETO_EDICAO) || hasPermission(PERMISSION_DOCUMENTOS_PERFIL_TOTAL) || hasPermission(PERMISSION_DOCUMENTOS_PERFIL_INTERMEDIO_EDICAO); 
  const canSeeDocumentos = hasPermission(PERMISSION_DOCUMENTOS_PERFIL_COMPLETO_EDICAO) || 
                           hasPermission(PERMISSION_DOCUMENTOS_PERFIL_COMPLETO_LEITURA) || 
                           hasPermission(PERMISSION_DOCUMENTOS_PERFIL_TOTAL) ||
                           hasPermission(PERMISSION_DOCUMENTOS_PERFIL_INTERMEDIO_EDICAO)
                           hasPermission(PERMISSION_DOCUMENTOS_PERFIL_INTERMEDIO_LEITURA); 

  return (
    <div className={styles.base}>
      <div className={styles.header}>

        <div>
          <div className={styles.nomeDoModulo}>
            Documentos
          </div>
          <div className={styles.tituloDaPagina}>
            Critérios de pesquisa
          </div>
        </div>

        <div>
         {canCreateEditDocumentos ? 
          <Button
            onClick={registarNovoClick}
            className={styles.btnNovoRegisto}
            style={{ display: isMobile ? 'none' : '' }}
            id='novoRegisto'
            title='Novo Documento'
          >
            <span className="icon-siarl icon-registar btnIconRegistar"></span>{' '}
            &nbsp;{' '}
            Novo Documento
          </Button>
        :  ''}

          {isMobile &&
            <DropDownButton
              items={optionsMais}
              className={styles.btnMais}
              iconClass="icon-siarl icon-mais"
              onItemClick={onItemClickMais}
              fillMode={'flat'}
              itemRender={itemRenderMais}
              title="Mais Opções"
            />
          }

          <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
            <span className="icon-siarl icon-ajuda"></span>
          </button>

          <button className={styles.btnFechar} onClick={onCloseClick} title='Minimizar'>
            <span className="icon-siarl icon-minimizar"></span>
          </button>
        </div>
      </div>
        <TabStrip selected={selected} className={styles.backgroundWhite} onSelect={handleSelect}
        scrollable={isMobile}>
          <TabStripTab title="Simples" key={"Simples"}>
            <PesquisarDocumentosSimplesForm onCloseClick={onCloseClick} />
          </TabStripTab>
          {seeAvancada? 
          <TabStripTab title="Avançada" key={"Avançada"}>
            <PesquisarDocumentosAvancadoForm onCloseClick={onCloseClick} />
          </TabStripTab>
          : ''}
        </TabStrip>
    </div>
  )
}
