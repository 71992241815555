
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form'
import { Input } from '@progress/kendo-react-inputs'

import styles from '../../styles/intervencao/Intervencao.module.scss'
import { CONSULTAR_ACAO, GET_ACAO_MOBILIZACAO_BY_MOBILIZACAO_ID, PERMISSION_DOMINIO_HIDRICO_PERFIL_BASICO, PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_EDICAO, PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_LEITURA, PERMISSION_DOMINIO_HIDRICO_PERFIL_TOTAL, PESQUISAR_ACAO_INTERVENCAO } from '../../utils/constants'
import { designacaoAcao, getMobilizacaoSelected, hasPermission, post, tipologiaMob, tiposMobilizacao } from '../../services/utils'
import { Grid, GridColumn } from '@progress/kendo-react-grid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { faInfoCircle, faRemove } from '@fortawesome/free-solid-svg-icons'
import { Dialog } from '@progress/kendo-react-dialogs'
import { ComboBox } from '@progress/kendo-react-dropdowns'
import { filterBy } from '@progress/kendo-data-query'
import SessionStorageService from '../../services/SessionStorageService'
import { v4 as uuidv4 } from 'uuid';
import { format } from 'date-fns'
import moment from 'moment'

type IProps = {
    formRenderProps:FormRenderProps
    viewMode?: boolean
}
interface FormData {
  id?: any;
  id_acao?: any;
  designacao?: any;
  id_tipologia?: any;
  volume?: any;
  data_ini_e?: any
  data_con_e?: any
  local?: any
  entidade_id?: any
  
}
export default function AcaoMobilizacao({ formRenderProps, viewMode }: IProps) {
 
  const [formData, setFormData] = useState<FormData>({});
  const [selectedGridItemId, setSelectedGridItemId] = useState<string | null>(null);
  const [idAcao, setIdAcao] = useState<any>();
  const [designacao, setDesignacao] = useState<any>();
  const [openModalAcaoMobilizacao, setOpenModalAcaoMobilizacao] = useState<any>()
  const [findAcao, setFindAcao] = useState<any>(false)
  const [editMode, setEditMode] = useState<any>(false)
  const [gridData, setGridData] = useState<FormData[]>([]);
  const [page2, setPage2] = useState({ skip: 0, take: 10 });
  const [itemToRemove, setItemToRemove] = useState<any>();
  const [openModalDescartar, setOpenModalDescartar] = useState<any>()
  
  const getAcaoMobilizacao = async (data: any) =>
    await post<any, any>(`${GET_ACAO_MOBILIZACAO_BY_MOBILIZACAO_ID}`, data)

  useEffect(() => {
    SessionStorageService.setItem("dataAcaoMobilizacao", gridData);
  });
  
  let data : any;
  useEffect(() => {
    if(getMobilizacaoSelected()?.id){
    const fetchData = async () => {
       data = await getAcaoMobilizacao(getMobilizacaoSelected()?.id);

       const designacaoObject = {
        designacao: data?.designacao,
      };

      let updatedData : any
      if (Array.isArray(data)) {
         updatedData = data.map(item => ({
          ...item,
          designacao: { designacao: item?.designacao },
          entidade_id:  item?.id_entidade,
          data_ini_e: formatDate(item?.data_ini_e),
          data_con_e: formatDate(item?.data_con_e)
        }));
      }

      setGridData(updatedData);

    };
    fetchData();
    
  }
  
  },[data]);

  const pesquisarAcao = async (data: any) => await post<any, any>(`${CONSULTAR_ACAO}`, data)

  const handlePageChange2 = (event: any) => {
    setPage2({ skip: event.page.skip, take: event.page.take });
};



  const canSeeDominioHidrico = 
  hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_BASICO) ||
  hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_LEITURA) ||
  hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_COMPLETO_EDICAO) ||
  hasPermission(PERMISSION_DOMINIO_HIDRICO_PERFIL_TOTAL) 

  const openModal = (e : any) => {
    setFormData({
      id: "",
      id_acao: "",
      designacao: "",
      id_tipologia: "",
      volume: "",
      data_ini_e : "",
      data_con_e : "",
      entidade_id: "",
      local: ""
      });
      setSelectedGridItemId(null)
      setIdAcao(undefined);
      setDesignacao(undefined);
    e.preventDefault();
    setOpenModalAcaoMobilizacao(true)
    setFindAcao(false)
    setEditMode(false)
  }

  const handleRowClick = (dataItem : any) => {
    setEditMode(true)
    setSelectedGridItemId(dataItem?.id); 
  
    setFormData({
      id_acao: dataItem?.id_acao,
      designacao : dataItem?.designacao,
      id_tipologia : dataItem?.id_tipologia,
      volume: dataItem?.volume,
      data_ini_e : dataItem?.data_ini_e,
      data_con_e : dataItem?.data_con_e,
      entidade_id: dataItem?.entidade_id,
      local: dataItem?.local

    });
    setOpenModalAcaoMobilizacao(true);
  };

  const confirmRemoveAssociacao = (item: any) => {
    setItemToRemove(item);
    setOpenModalDescartar(true);
  }

  const removeAcaoMobilizacao: any = () => {
    const item = itemToRemove;
    const dataAcaoMobilizacao= gridData.filter(x => !(x.id == item.id)).slice();
    setGridData(dataAcaoMobilizacao);
    setOpenModalDescartar(false);

  }

  const cancelModal = () => {
    setOpenModalAcaoMobilizacao(false)
  }
  
  const [acaoDesignacaoData, setAcaoDesignacaoData] = useState(designacaoAcao ? designacaoAcao.slice() : []);
  const filterChangeDesignacao = (event: any) => {
    setAcaoDesignacaoData(filterBy(designacaoAcao.slice(), event.filter));
  };

  const [tipologiaData, setTipologiaData] = useState(tiposMobilizacao ? tiposMobilizacao.slice() : []);
  const filterChangeTipologia = (event: any) => {
    setTipologiaData(filterBy(tiposMobilizacao.slice(), event.filter));
  };

  const handleFormChange = (event : any) => {
    
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    
  };

  const formatDate = (dateString: any) => {
    if (!dateString) return "";
    const date = moment(dateString).tz('Europe/Lisbon'); 
    const day = String(date.date()).padStart(2, '0');
    const month = String(date.month() + 1).padStart(2, '0');
    const year = date.year();
    return `${day}/${month}/${year}`;
  };

  const handlePesquisarAcao = async (e : any, formRenderProps : FormRenderProps) => {
    e.preventDefault();

    if ((!idAcao || idAcao == "") && !designacao) {
      postMessage({type: "infoMsg", value: {message: "Preencha o id ou a designação"}}, "*");
      return;
    }
  
    const intervencaoRequest = {
      id: idAcao && idAcao !== "" ? idAcao : undefined,
      designacao: designacao ?? undefined,
    }

    try {
      const data = await pesquisarAcao(intervencaoRequest);
      if (data) {
       setFindAcao(true)
       const dataObject = {
        designacao: data[0]?.designacao,
      };
       formRenderProps.onChange('id_acao', { value: data[0]?.id}) 
       formRenderProps.onChange('designacao', { value: dataObject}) 


       console.log("formatDate(data[0]?.data_con_e)")

       console.log(formatDate(data[0]?.data_con_e))
       setFormData((prevData) => ({
        ...prevData,
        id_acao: data[0]?.id,
        designacao: data[0]?.designacao,
        data_ini_e: formatDate(data[0]?.data_ini_e),
        data_con_e: formatDate(data[0]?.data_con_e),
        local: data[0]?.local,
        entidade_id: data[0]?.diEntidade
      }));
      
      } else {
        postMessage({ type: 'errorMsg', value: { left: "15%" , message: "Não foi encontrada nenhuma ação." } }, '*');
      }
    } catch (error) {
      console.error("Erro ao pesquisar ação:", error);
    }
  };

  const handleUpdateItem = (id : any, updatedItem : any) => {
    setGridData(prevData => prevData.map(item => item.id === id ? { ...updatedItem, id } : item));
};

  const handleAddItem = (item : any) => {
    const newItem = { ...item, id: uuidv4()};
    setGridData([...gridData, newItem]);

  };
  
  const handleSubmitModal = (e: any) => {
    const designacaoObject = {
        designacao: formData?.designacao,
    };

    if (selectedGridItemId) {
        handleUpdateItem(selectedGridItemId, {
            ...formData,
        });
    } else {
        const newId = uuidv4();
        handleAddItem({
            ...formData,
            id: newId,
            designacao: designacaoObject, 
        });
        setSelectedGridItemId(newId);
    }
  
    setOpenModalAcaoMobilizacao(false);
  };


  return (
    <>
      {canSeeDominioHidrico?
    <div className={`${styles.form} ${styles.removeScrollDuplicado}`}>
 <fieldset>
          <legend>
            <span className={styles.fieldsetTitulo}>
            Ações associadas
            </span>
          </legend>
          {!viewMode  ? (

          <div className={styles.espacoVerticalFieldSet}>
          <button
              title="Adicionar novo"
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary buttonAdicionarNovo btnPrimaryColor"
              onClick={(e) => {
                openModal(e);
              }}
              // disabled={viewMode || !selectedIntervencaoResult?.id}
              >
              <span className="icon-siarl icon-registar k-button-icon"></span>{' '}
              &nbsp;{' '}
              Inserir
            </button>
         </div>
        ) : ""}

        <div className={styles.espacoVerticalFieldSet}>
        <div className={styles.scrollableArea}>
          <Grid
            className={'customGrid'}
            data={gridData.slice(page2.skip, page2.take + page2.skip)}
            pageable={{
              buttonCount: 5,
              info: false,  
              type: 'numeric',
              pageSizes: false,  
              previousNext: true,
            }}
            rowHeight={50}
            total={gridData.length}
            skip={page2.skip}
            take={page2.take}
            onPageChange={handlePageChange2}

          >
            {!viewMode ?
            
            <GridColumn width={56}
                        cell={(props) => (
                          <td className="k-command-cell">
                            <div style={{cursor:'pointer'}} className={'gridCommand'}>
                              <div
                                role="button"
                                className={styles.gridCommandIconButton}
                                onClick={() => handleRowClick(props.dataItem)}
                              >
                                <FontAwesomeIcon icon={faInfoCircle} title="Editar associação" />
                              </div>
                              <div style={{cursor:'pointer'}}
                                role="button"
                                onClick={() => confirmRemoveAssociacao(props.dataItem)}
                                className={styles.gridCommandIconButton}
                              >
                                <FontAwesomeIcon icon={faRemove} title="Remover associação" />
                              </div>
                            </div>
                          </td>
                        )}
                      />
              : ''}
            <GridColumn field="id_acao" className={styles.centerAlign} width="70px" title="ID ação" />
            <GridColumn field="designacao.designacao" className={styles.centerAlign} width="120px" title="Designação" />
            <GridColumn field="id_tipologia.descricao" className={styles.centerAlign}width="120px" title="Tipologia" />
            <GridColumn field="data_ini_e" className={styles.centerAlign}width="120px" title="Data de início" />
            <GridColumn field="data_con_e" className={styles.centerAlign}width="120px" title="Data de conclusão" />
            <GridColumn field="volume" className={styles.centerAlign} width="120px" title="Volume" />
            <GridColumn field="local" className={styles.centerAlign} width="120px" title="Localização" />
            <GridColumn field="entidade_id.nome" className={styles.centerAlign} width="120px" title="Entidade" />
          
          </Grid>
        </div>
        </div>
        </fieldset>

        {openModalDescartar ? (
          <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={() => setOpenModalDescartar(false)}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                  <fieldset>
                    <span className="textDescartar">{'Apagar'} registo  </span>
                    <br></br>
                    <span>{'Tem a certeza que pretende apagar a associação?'}</span>
                  </fieldset>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={() => setOpenModalDescartar(false)}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={removeAcaoMobilizacao}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}

{openModalAcaoMobilizacao ? (
          <Dialog
            title="Associação de ação à mobilização"
            className="ModalAcaoMobilizacao"
            onClose={cancelModal}
          >
            
            <Form
              onSubmit={handleSubmitModal}
              initialValues={formData}
              render={(formRenderProps : any) => (
                <FormElement style={{ maxWidth: 620 }}>
                  <div className={`${styles.form} ${styles.removeScrollDuplicado}`}>
                    
                      <div className={styles.fields}>
                        <Field
                          name="id_acao"
                          label="ID da ação"
                          component={Input}
                          type="number"
                          value={idAcao}
                          onChange={(e) => {
                            handleFormChange(e);
                            setIdAcao(e.target.value);
                          }}
                          />
                      </div>
                      <div className={styles.fields}>
                        <div>
                        <Field
                          name="designacao"
                          label="Designação"
                          component={ComboBox}
                          textField={'designacao'}
                          filterable={true}
                          onFilterChange={filterChangeDesignacao}
                          data={acaoDesignacaoData}
                          value={designacao}
                          onChange={(e) => {
                            handleFormChange(e);
                            setDesignacao(e.value.designacao);
                          }}
                        />
                      </div>
                  
                      </div>
                      <div className={styles.fields}>
                      <div>
                        <Field
                          name="id_tipologia"
                          label="Tipologia (Extração, Deposição)"
                          component={ComboBox}
                          textField={'descricao'}
                          onFilterChange={filterChangeTipologia}
                          data={tipologiaData}
                          filterable={true}
                          disabled={viewMode}
                          onChange={(e) => {
                            handleFormChange(e);
                          }}
                        />
                      </div>
                      </div>
                  <div className={`${styles.fields}`}>

                  {/* tipologia que vem da ação mas ainda não existe da ação */}
                  <div>
                      <Field
                          name="volume"
                          label="Volume em m3"
                          component={Input}
                          textField={'descricao'}
                          filterable={true}
                          onChange={(e) => {
                            handleFormChange(e);
                          }}
                      />
                  </div>
               </div>      
            
               <div className="k-form-buttons btnsModal">
               <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                      onClick={cancelModal}
                    >
                      Cancelar
                    </button>
                    <button
                      type={'button'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                      onClick={(e) => handlePesquisarAcao(e, formRenderProps)}     
                       >
                    
                      Pesquisar
                    </button>
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                      onClick={formRenderProps.onSubmit}
                      disabled={!findAcao && !editMode}

                    >
                      Submeter
                    </button>
                    </div>              
                    </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}
        
     
    </div>:<></>}
    </>
  )
}


