export const requiredFieldValidator = (value: string) =>
!value ? 'campo obrigatorio' : ( typeof value == 'string' && !value.trim() ? 'Campo obrigatório.' : '');

export let isOcorrenciaIdentificacaoValid: boolean

export const setIsOcorrenciaIdentificacaoValid = (value: boolean) => {
  isOcorrenciaIdentificacaoValid = value
}

export let isOcorrenciaDetalhesValid: boolean

export const setIsOcorrenciaDetalhesValid = (value: boolean) => {
  isOcorrenciaDetalhesValid = value
}

export let isOcorrenciaDocumentosValid: boolean

export const setIsOcorrenciaDocumentosValid = (value: boolean) => {
  isOcorrenciaDocumentosValid = value
}
