import { Checkbox, CheckboxProps } from '@progress/kendo-react-inputs'
import { Error } from '@progress/kendo-react-labels'
import { useEffect } from 'react'


import type { FieldRenderProps } from '@progress/kendo-react-form'
import { useEstrategiaAdaptacaoStore } from '../../../../stores/estrategiaAdaptacao'

export default function ValidatedIdentificacaoCheckbox(fieldRenderProps: FieldRenderProps) {
  const addIdentificacaoTabValidatedField = useEstrategiaAdaptacaoStore(state => state.addIdentificacaoTabValidatedField)
  
  useEffect(() => {
    addIdentificacaoTabValidatedField({ [fieldRenderProps.name]: !!fieldRenderProps.value })
  }, [fieldRenderProps.value])

  return (
    <div>
      <Checkbox {...fieldRenderProps as CheckboxProps} checked={fieldRenderProps.checked} />
      {fieldRenderProps.visited && fieldRenderProps.validationMessage && <Error>{fieldRenderProps.validationMessage}</Error>}
    </div>
  )
}
