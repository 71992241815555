import { Button, DropDownButton } from '@progress/kendo-react-buttons'
import { ComboBox, ComboBoxFilterChangeEvent } from '@progress/kendo-react-dropdowns'
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from '@progress/kendo-react-form'

import {
  MouseEvent as ReactMouseEvent,
  useEffect,
  useLayoutEffect,
  useState
} from 'react'

import {
  Checkbox,
  Input,
  RadioButtonChangeEvent,
  RadioGroup
} from '@progress/kendo-react-inputs'
import SessionStorageService from '../../services/SessionStorageService'
import {
  concelhos,
  coordenadasPoligono,
  distritos,
  fillConcelhosAsync,
  fillDistritosAsync,
  fillFreguesiasAsync,
  fillNuts1Async,
  fillNuts2Async,
  fillNuts3Async,
  fillTipoEntidades,
  freguesias,
  getEntidadeSelected,
  hasPermission,
  nuts1,
  nuts2,
  nuts3,
  post,
  setCoordenadasPoligono,
  setEntidadeSelected,
  tipoEntidades,
  useCombos
} from '../../services/utils'
import { useEntidadeStore } from '../../stores/entidade'
import styles from '../../styles/entidade/ConsultarEntidadeForm.module.scss'
import {
  AREA_VISIVEL,
  AUTH_LOCALSTORAGE,
  CONSULTAR_ENTIDADES,
  CRITERIO_JURISDICAO,
  CRITERIO_SEDE,
  NENHUM,
  PERMISSION_ENTIDADES_PERFIL_COMPLETO_EDICAO,
  PERMISSION_ENTIDADES_PERFIL_COMPLETO_LEITURA,
  PERMISSION_ENTIDADES_PERFIL_TOTAL,
  PERMISSION_INSERIR_ENTIDADE,
  POLIGONO_SELECAO,
  URL_AJUDA_ENTIDADES
} from '../../utils/constants'
import { FilterDescriptor, filterBy } from '@progress/kendo-data-query'
import FieldsetCombosLocalizacao from '../localizacao/FieldsetCombosLocalizacao'
import ReactGA from 'react-ga4';
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais'
import clsx from 'clsx'

type Props = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
}

export default function ConsultarEntidadesForm({ onCloseClick }: Props) {
  const [isMobile, setIsMobile] = useState(false);
  const [dataDe, setDataDe] = useState<any>()
  const [freguesiasFiltred, setFreguesiasFiltred] = useState<any>()
  const [concelhosFiltred, setConcelhosFiltred] = useState<any>()
  const [nuts2Filtered, setNuts2Filtered] = useState<any>()
  const [nuts3Filtered, setNuts3Filtered] = useState<any>()
  const [nuts2Disabled, setNuts2Disabled] = useState<any>(true)
  const [nuts3Disabled, setNuts3Disabled] = useState<any>(true)
  const [distritosDisabled, setDistritosDisabled] = useState<any>(false);
  const [freguesiasData, setFreguesiasData] = useState(freguesias ? freguesias.slice() : []);
  const [concelhoData, setConcelhoData] = useState(concelhos ? concelhos.slice() : []);
  const [distritoData, setDistritoData] = useState(distritos ? distritos.slice() : []);
  const [nuts1Data, setNuts1Data] = useState(nuts1 ? nuts1.slice() : []);
  const [nuts2Data, setNuts2Data] = useState(nuts2 ? nuts2.slice() : []);
  const [nuts3Data, setNuts3Data] = useState(nuts3 ? nuts3.slice() : []);
  const [concelhosDisabled, setConcelhosDisabled] = useState<any>(true)
  const [freguesiasDisabled, setFreguesiasDisabled] = useState<any>(true)
  const [distritoDisabled, setDistritoDisabled] = useState(false);
  const [rbCriteriosSelected, setRBCriteriosSelected] = useState<any>()
  const [rbCriteriosGeometricoSelected, setRBCriteriosGeometricoSelected] =
    useState<any>()
  const getEntidades = async (data: any) =>
    await post<any, any>(`${CONSULTAR_ENTIDADES}`, data)
  const [tipoPesquisaVisible, setTipoPesquisaVisible] = useState(true)
  const [criterioGeograficoVisible, setCriterioGeograficoVisible] =
    useState(false)
  const [localizacaoVisible, setLocalizacaoVisible] = useState(false)
  const [entidadeVisible, setEntidadeVisible] = useState(false)


  type combosKeys= 'tipoEntidades';

    const [combos, setCombos] = useState<any>({
      tipoEntidades
    })


    const setDataOption: Record<combosKeys, { setFn: any, data: any[] }> = {
      tipoEntidades: {
        setFn: setCombos,
        data: tipoEntidades
      }
    };

  const filterCombo_Multiselect = (e: any, tipoCombo: combosKeys) => {
      const { setFn, data } = setDataOption[tipoCombo];
      setFn({
          ...combos,
          [tipoCombo]: filterBy(data?.slice()??[], e.filter)
      }
      );
  }


useEffect(() => {
    
     if(!nuts1 ){
      fillNuts1Async();
      }
     if(!nuts2) {
      fillNuts2Async();
     }
     if(!nuts3){
      fillNuts3Async();
     }
     if(!distritos){
      fillDistritosAsync();
     }
     if(!concelhos){
      fillConcelhosAsync();
     }
     if(!freguesias){
      fillFreguesiasAsync();
     }
  
     if(!tipoEntidades){
      fillTipoEntidades();

     }

  }, []);

  useEffect(() => {
    
    setFreguesiasFiltred([])
    setConcelhosFiltred([])
    setNuts2Filtered(nuts2)
    setNuts3Filtered(nuts3)
  }, []);

  const toggletipoPesquisa = () => {
    setTipoPesquisaVisible(!tipoPesquisaVisible)
  }
  const togglecriterioGeografico = () => {
    setCriterioGeograficoVisible(!criterioGeograficoVisible)
  }
  const toggleLocalizacao = () => {
    setLocalizacaoVisible(!localizacaoVisible)
  }
  const toogleEntidade = () => {
    setEntidadeVisible(!entidadeVisible)
  }

  const selectEntidadeResult = useEntidadeStore(
    (state) => state.selectEntidadeResult
  )

  const filterConcelho = (e: any) => {
    if (e?.value?.codigo) {
      const concelhosLocal = concelhos.filter(
        (x: any) => x.distrito_cod == e.value.codigo
      )
      setConcelhosFiltred(concelhosLocal)
      setConcelhoData(concelhosLocal);
      setConcelhosDisabled(false)
    } else {
      setNuts2Disabled(false);
      setNuts3Disabled(false);
      setConcelhosFiltred(concelhos)
      setConcelhosDisabled(true)
    }
  }

  const filterFreguesia = (e: any) => {
    if (e?.value?.codigo) {
      const freguesiaLocal = freguesias.filter(
        (x: any) => x.concelho_cod == e.value.codigo
      )
      setFreguesiasDisabled(false)
      setFreguesiasFiltred(freguesiaLocal)
      setFreguesiasData(freguesiaLocal);
    } else {
      setFreguesiasDisabled(true)
      setFreguesiasFiltred(freguesias)
      setFreguesiasData(freguesias);
    }
  }

  const filterNuts2 = (e: any) => {
    if (e?.value?.codigo) {
      const nutsLocal = nuts2.filter((x: any) => x.nuts_i_cod == e.value.codigo)
      setNuts2Filtered(nutsLocal)
      setNuts2Data(nutsLocal);
      setNuts2Disabled(false)
    } else {
      setNuts2Disabled(true)
      setNuts2Filtered(nuts2)
      setNuts2Data(nuts2);
    }
  }
  const filterNuts3 = (e: any) => {
    if (e?.value?.codigo) {
      setDistritosDisabled(true);
      const nutsLocal = nuts3.filter(
        (x: any) => x.nuts_ii_cod == e.value.codigo
      )
      setNuts3Filtered(nutsLocal)
      setNuts3Data(nutsLocal);
      setNuts3Disabled(false)
    } else {
      setDistritosDisabled(false);
      setNuts3Disabled(true)
      setNuts3Filtered(nuts3)
      setNuts3Data(nuts3);
    }
  }

  const handleSubmit = async (e: any) => {
    postMessage({ type: 'showLoader' }, '*')
    const objToSearch = {
      temJurisdicao: e?.values?.rbTemJurisdicao,
      designacao: e?.values?.designacao,
      tipoEntidadeId: e?.values?.tipoEntidade
        ? e?.values?.tipoEntidade.id
        : undefined,
      sigla: e?.values?.sigla,
      freguesiaCod: e?.values?.freguesia?.codigo ?? undefined,
      concelhoCod: e?.values?.concelho?.codigo ?? undefined,
      distritoCod: e?.values?.distrito?.codigo ?? undefined,
      nuts1Cod: e?.values?.nuts1?.codigo ?? undefined,
      nuts2Cod: e?.values?.nuts2?.codigo ?? undefined,
      nuts3Cod: e?.values?.nuts3?.codigo ?? undefined,
      arh_cod: e.values?.arh?.codigo ?? undefined,
      capitania_cod : e.values?.capitania?.codigo ?? undefined,
      massa_agua_cod: e.values?.massaAgua?.codigo ?? undefined,
      tipoPesquisa: e?.values?.rbTipoPesquisa,
      flatCoordinatesCriterioGeografico: coordenadasPoligono,
      criterioGeografico: e?.values?.rbCriterio,
    }

    SessionStorageService.setItem("firstCallEntidade", true);

    SessionStorageService.setItem("lastSearchEntidades", objToSearch);


    const result: any = await getEntidades(objToSearch);

    if (result) {
      setCoordenadasPoligono(undefined)
      const iframe: any = document.getElementById('iframeGeoPortal')
      SessionStorageService.setItem(
        'resultadoEntidadePesquisa',
        JSON.stringify(result)
      )

      if (e?.values?.rbTipoPesquisa == CRITERIO_JURISDICAO) {
        SessionStorageService.setItem("criterioEntidades", JSON.stringify(CRITERIO_JURISDICAO));
      } else {
        SessionStorageService.setItem("criterioEntidades", JSON.stringify(CRITERIO_SEDE));
      }
    }
    postMessage({ type: 'resultadoEntidadePesquisaChange' }, '*')
    postMessage({ type: 'hideLoader' }, '*')
  }

  const registarNovoClick = () => {
    sessionStorage.setItem('diGeomEdited', 'false')
    SessionStorageService.setItem('novaEntidade', 'true')
    const loggedUser = JSON.parse(
      localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}'
    )
    selectEntidadeResult({ tecnicoRegisto: loggedUser?.user?.name })
    setEntidadeSelected({ tecnicoRegisto: loggedUser?.user?.name })
    postMessage({ type: 'form', value: 'registarEntidade' }, '*')
  }

  const changerbCriterio = (e: any) => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage({ type: "SiarlDeselectMapArea", value: 'true' }, '*')
    if (e.value == POLIGONO_SELECAO) {
      postMessage(
        { type: 'informationMsg', value: 'Selecione a área de pesquisa' },
        '*'
      )
      const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.contentWindow?.postMessage(
        { type: 'SiarlGeoPortalAdicionarArea', value: 'true' },
        '*'
      )
    }
    if (e.value == AREA_VISIVEL) {
      iframe.contentWindow?.postMessage({ type: "SiarlSelectMapArea", value: 'true' }, '*')
      postMessage(
        {
          type: 'informationMsg',
          value: 'Foi selecionada a área visível do mapa',
        },
        '*'
      )
      iframe.contentWindow?.postMessage(
        { type: 'SiarlGetMapArea', value: 'true' },
        '*'
      )
    }
    if (e.value == NENHUM) {
      setCoordenadasPoligono(undefined)
      clearMap()
    }
  }
  
  const clearMap = () => {
    try {

      setCoordenadasPoligono(undefined)
      const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.contentWindow?.postMessage({ type: "SiarlEntidadesClearLayers", value: "true" }, '*')
      iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: "true" }, '*')
      iframe.contentWindow?.postMessage({ type: "SiarlDeselectMapArea", value: "true" }, '*')

    } catch (error) {
      console.log({ error })
    }
  }

  const changeCriterioGeograficoToNenhum = (
    formRenderProps: FormRenderProps
  ) => {
    formRenderProps.onChange('rbCriterio', {
      value: 0,
    })
    clearMap()
  }

  const filterDataFreguesia = (filter: FilterDescriptor) => {
    const data = freguesiasFiltred.slice();
    return filterBy(data, filter);
  };

  const filterChangeFreguesia = (event: ComboBoxFilterChangeEvent) => {
    setFreguesiasData(filterDataFreguesia(event.filter));
  };

  const filterDataConcelho = (filter: FilterDescriptor) => {
    const data = concelhosFiltred.slice();
    return filterBy(data, filter);
  };

  const filterChangeConcelho = (event: ComboBoxFilterChangeEvent) => {
    setConcelhoData(filterDataConcelho(event.filter));
  };

  const filterDataDistrito = (filter: FilterDescriptor) => {
    const data = distritos.slice();
    return filterBy(data, filter);
  };

  const filterChangeDistrito = (event: ComboBoxFilterChangeEvent) => {
    setDistritoData(filterDataDistrito(event.filter));
  };

  const filterDataNuts1 = (filter: FilterDescriptor) => {
    const data = nuts1.slice();
    return filterBy(data, filter);
  };

  const filterChangeNuts1 = (event: ComboBoxFilterChangeEvent) => {
    setNuts1Data(filterDataNuts1(event.filter));
  };

  const filterDataNuts2 = (filter: FilterDescriptor) => {
    const data = nuts2Filtered.slice();
    return filterBy(data, filter);
  };

  const filterChangeNuts2 = (event: ComboBoxFilterChangeEvent) => {
    setNuts2Data(filterDataNuts2(event.filter));
  };

  const filterDataNuts3 = (filter: FilterDescriptor) => {
    const data = nuts3Filtered.slice();
    return filterBy(data, filter);
  };

  const filterChangeNuts3 = (event: ComboBoxFilterChangeEvent) => {
    setNuts3Data(filterDataNuts3(event.filter));
  };

  const cleanCombosLocalizacao = (formRenderProps: FormRenderProps) => {
    formRenderProps.onChange('distrito', { value: null })
    formRenderProps.onChange('concelho', { value: null })
    formRenderProps.onChange('freguesia', { value: null })
    formRenderProps.onChange('nuts1', { value: null })
    formRenderProps.onChange('nuts2', { value: null })
    formRenderProps.onChange('nuts3', { value: null })
  }

  useLayoutEffect(() => {

    const handleResize = () => {

      if (window.innerWidth <= telaMobile) {
        
        setIsMobile(true);
        ocultaFloatingMenu_e_Mapa();

      }else{

        setIsMobile(false);
        mostraFloatingMenu_e_Mapa();

      }

    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  const onItemClickMais = (event: any) => {

    elementoClickMais(event);

  };

  const optionsMais = [
    {
      key: 'novoRegisto',
      text: 'Nova entidade',
      elementType: 'button',
      className: 'icon-siarl icon-registar btnIconRegistar'
    }
  ]

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/ConsultarEntidades" });
  }, [])

  const canSeeEntidadesAllPermissions = hasPermission(PERMISSION_ENTIDADES_PERFIL_COMPLETO_EDICAO) || hasPermission(PERMISSION_ENTIDADES_PERFIL_TOTAL) || hasPermission(PERMISSION_ENTIDADES_PERFIL_COMPLETO_LEITURA);
  const canSeeEntidadesEdit = hasPermission(PERMISSION_ENTIDADES_PERFIL_COMPLETO_EDICAO) || hasPermission(PERMISSION_ENTIDADES_PERFIL_TOTAL);

  const openAjuda = () => {
    window.open(URL_AJUDA_ENTIDADES, "_blank");
  }

  const [isPesquisaAvancada, setIsPesquisaAvancada] = useState<boolean>(false);

  const handleTypeChange = (event: RadioButtonChangeEvent) => {
    const selectedValue = event.value;
    setIsPesquisaAvancada(selectedValue === 0);
  };

  return (
    <div className={styles.base}>

      <div className={clsx(styles.header, styles.backgroundWhite)}>

        <div>
          <div className={styles.nomeDoModulo}>
            Entidades
          </div>
          <div className={styles.tituloDaPagina}>
            Critérios de pesquisa
          </div>
        </div>

        <div>
         {canSeeEntidadesEdit? 

          <Button onClick={registarNovoClick} className={styles.btnNovoRegisto}
          style={{ display: isMobile ? 'none' : '' }}
          id='novoRegisto' title='Nova entidade'>
            <span className="icon-siarl icon-registar btnIconRegistar"></span>{' '}
            &nbsp;{' '}
            Nova entidade
          </Button>
        :  ''}

          {isMobile &&
            <DropDownButton
              items={optionsMais}
              className={styles.btnMais}
              iconClass="icon-siarl icon-mais"
              onItemClick={onItemClickMais}
              fillMode={'flat'}
              itemRender={itemRenderMais}
              title="Mais Opções"
            />
          }

          <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
            <span className="icon-siarl icon-ajuda"></span>
          </button>

          <button className={styles.btnFechar} onClick={onCloseClick} title="Minimizar">
            <span className="icon-siarl icon-minimizar"></span>
          </button>

        </div>
      </div>
      {canSeeEntidadesAllPermissions ?
        <Form
          onSubmitClick={handleSubmit}
          initialValues={
            {
              ...getEntidadeSelected(),
              temJurisdicao: true,
              rbTipoPesquisa: 1,
              rbTemJurisdicao: 0,
            } ?? undefined
          }
          render={(formRenderProps: FormRenderProps) => (
            <>
              <FormElement className={styles.form}>
                <fieldset>
                  <legend>
                    <button type='button' className={styles.itemExpandBase} onClick={toggletipoPesquisa}>
                      <div className={styles.btn_Expandir_Colapsar}>
                        <span className={tipoPesquisaVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                      </div>
                      <span className={styles.fieldsetTitulo}>Tipo de Pesquisa</span>
                    </button>
                  </legend>

                  {tipoPesquisaVisible && (

                    <div className={styles.espacoVerticalFieldSet}>
                      <div>
                        <Field
                          id={'rbTipoPesquisa'}
                          name={'rbTipoPesquisa'}
                          layout={'horizontal'}
                          onChange={handleTypeChange}
                          component={RadioGroup}
                          data={[
                            { label: 'Geometria Jurisdição', value: 0 },
                            { label: 'Geometria Sede', value: 1 },
                          ]}
                        />
                      </div>
                    </div>

                  )}
                </fieldset>

                <fieldset>

                  <legend>
                    <button type='button' className={styles.itemExpandBase} onClick={togglecriterioGeografico}>
                      <div className={styles.btn_Expandir_Colapsar}>
                        <span className={criterioGeograficoVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                      </div>
                      <span className={styles.fieldsetTitulo}>Critério geográfico</span>
                    </button>
                  </legend>

                  {criterioGeograficoVisible && (

                    <><div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}>
                      <Field
                        id={'rbCriterio'}
                        name={'rbCriterio'}
                        layout={'horizontal'}
                        component={RadioGroup}
                        onChange={(e) => {
                          changerbCriterio(e)
                          cleanCombosLocalizacao(formRenderProps)
                        } }
                        data={[
                          { label: 'Nenhum', value: 0 },
                          { label: 'Área visível  ', value: 1 },
                          { label: 'Polígono de seleção', value: 2 },
                        ]}
                        defaultValue={0} // Set the default selected value to 0 (Nenhum)
                      />
                    </div><div className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}>
                        <Field
                          id={'rbTemJurisdicao'}
                          name={'rbTemJurisdicao'}
                          layout={'horizontal'}
                          component={RadioGroup}
                          data={[
                            { label: 'Todos', value: 0 },
                            { label: 'Tem jurisdição', value: 1 },
                            { label: 'Sem jurisdição', value: 2 },
                          ]}
                          defaultValue={0} // Set the default selected value to 0 (Todos)
                        />
                      </div></>
                  )}
                </fieldset>

                <FieldsetCombosLocalizacao isPesquisaAvancada={isPesquisaAvancada} styles={styles} formRenderProps={formRenderProps} />

                <fieldset>

                  <legend>
                    <button type='button' className={styles.itemExpandBase} onClick={toogleEntidade}>
                      <div className={styles.btn_Expandir_Colapsar}>
                        <span className={entidadeVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                      </div>
                      <span className={styles.fieldsetTitulo}>Entidade</span>
                    </button>
                  </legend>

                  {entidadeVisible && (

                    <div className={styles.espacoVerticalFieldSet}>
                      <div>
                          <Field
                            name="designacao"
                            label="Designação"
                            component={Input}
                            textField={'nome'}
                          />
                        </div>
                      <div className={styles.fields}>
                      <div>
                        <Field
                          name="tipoEntidade"
                          label="Tipo Entidade"
                          component={ComboBox}
                          textField={'designacao'}
                          data={combos?.tipoEntidades}
                          filterable={true}
                          onFilterChange={(e:any)=>{filterCombo_Multiselect(e,'tipoEntidades')}}
                        />
                      </div>
                        <div>
                          <Field
                            name="sigla"
                            label="Codigo"
                            component={Input}
                            textField={'nome'}
                          />
                        </div>
                      </div>
                    </div>

                  )}
                </fieldset>
              </FormElement>

              <div className={`${styles.footer} ${styles.justify_multiple_children}`}>
                <Button
                  onClick={(e: any) => {
                    formRenderProps.onFormReset()
                    setCoordenadasPoligono(undefined)
                    clearMap()
                  }}
                  className={styles.btnLimparPesquisa}
                  title='Limpar pesquisa'
                >
                  <span className="icon-siarl icon-limparcampos"></span>
                  <span className={styles.rotulo}>
                    {' '}&nbsp;{' '}
                    Limpar pesquisa
                  </span>
                </Button>
                <Button
                  onClick={formRenderProps.onSubmit}
                  className={styles.btnPesquisar}
                  title='Pesquisar'
                >
                  <span className="icon-siarl icon-pesquisar"></span>
                  <span className={styles.rotulo}>
                    {' '}&nbsp;{' '}
                    Pesquisar
                  </span>
                </Button>
              </div>
            </>
          )}
        />
        : <></>}

    </div>
  )
}
