import { Button, DropDownButton } from '@progress/kendo-react-buttons'

import {
  Dispatch,
  MouseEvent as ReactMouseEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'

import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from '@progress/kendo-react-layout'
import styles from '../../styles/intervencao/Intervencao.module.scss'
import PesquisarIntervencao from './PesquisarIntervencao'
import PesquisarAcao from './PesquisarAcao'
import PesquisarCandidatura from './PesquisarCandidatura'
import PesquisarMobilizacao from './PesquisarMobilizacao'
import { useAcaoStore } from '../../stores/acao'
import { useCandidaturaStore } from '../../stores/candidatura'
import { useMobilizacaoStore } from '../../stores/mobilizacao'
import { useIntervencaoStore } from '../../stores/intervencao'
import { hasPermission, setAcaoSelected, setCandidaturaAcaoSelected, setCandidaturaSelected, setIntervencaoSelected, setMobilizacaoSelected } from '../../services/utils'
import { AUTH_LOCALSTORAGE, PERMISSION_INSERIR_INTERVENCAO, PERMISSION_INTERVENCAO_PERFIL_BASICO, PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO, PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA, PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO, PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_LEITURA, PERMISSION_INTERVENCAO_PERFIL_TOTAL, PERMISSION_LISTAR_INTERVENCAO, PERMISSION_LISTAR_INT_ACAO, PERMISSION_LISTAR_INT_CANDIDATURA, PERMISSION_LISTAR_INT_MOBILIZACAO, URL_AJUDA_INTERVENCOES_INTERVENCAO } from '../../utils/constants'
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais'
import SessionStorageService from '../../services/SessionStorageService'

type Props = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
  setShowResults: Dispatch<SetStateAction<boolean>>
  setShowRegistarIntervencao?: Dispatch<SetStateAction<boolean>>
  setShowRegistarAcao?: Dispatch<SetStateAction<boolean>>
  setShowRegistarCandidatura?: Dispatch<SetStateAction<boolean>>
  setShowRegistarMobilizacao?: Dispatch<SetStateAction<boolean>>
}

export default function PesquisarIntervencaoTabs({
  onCloseClick,
  setShowRegistarIntervencao,
  setShowRegistarAcao,
  setShowResults,
  setShowRegistarCandidatura,
  setShowRegistarMobilizacao
}: Props) {
  const [isMobile, setIsMobile] = useState(false);
  const [selected, setSelected] = useState<number>(0)
  const [newRegistoLabel, setNewRegistoLabel] =
    useState<string>('Nova intervenção')
    

      const selectIntervencaoResult = useIntervencaoStore((state) => state.selectIntervencaoResult)
  const selectAcaoResult = useAcaoStore((state) => state.selectAcaoResult)
  const selectCandidaturaResult = useCandidaturaStore((state) => state.selectCandidaturaResult)
  const selectMobilizacaoResult = useMobilizacaoStore((state) => state.selectMobilizacaoResult)
  const [seeIntervencaoList, setSeeIntervencaoList] = useState<boolean>(false);
  const [seeAcao, setSeeAcao] = useState<boolean>(false);
  const [seeMobilizacao, setSeeMobilizacao] = useState<boolean>(false);
  const [seeCandidatura, setSeeCandidatura] = useState<boolean>(false);

  const INTERVENCAO = 0
  const ACAO = 1
  const CANDIDATURA = 2
  const MOBILIZACAO = 3
  const handleSelect = (e: TabStripSelectEventArguments) => {
    if (e.selected == INTERVENCAO) {
        setNewRegistoLabel('Nova intervenção')
      
    } else if (e.selected == ACAO) {
        setNewRegistoLabel('Nova ação')
      
    } else if (e.selected == CANDIDATURA) {
        setNewRegistoLabel('Nova candidatura')
      
    } else if (e.selected === MOBILIZACAO) {
        setNewRegistoLabel('Nova mobilização')
      
    }
    setSelected(e.selected)
  }
  const canSeeIntervencao = hasPermission(PERMISSION_INTERVENCAO_PERFIL_BASICO) ||
                            hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO) ||
                            hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA) ||
                            hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO) ||
                            hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_LEITURA) ||
                            hasPermission(PERMISSION_INTERVENCAO_PERFIL_TOTAL);

  const canSeeCandidatura = hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO) ||
                            hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA) ||
                            hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO) ||
                            hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_LEITURA) ||
                            hasPermission(PERMISSION_INTERVENCAO_PERFIL_TOTAL);
  
  useEffect(() => {
    if (hasPermission(PERMISSION_LISTAR_INTERVENCAO)) {
      setSeeIntervencaoList(true);
    } else {
      setSeeIntervencaoList(false);
    }
    if (hasPermission(PERMISSION_LISTAR_INT_ACAO)) {
      setSeeAcao(true)
    } else {
      setSeeAcao(false);
    }
    if (hasPermission(PERMISSION_LISTAR_INT_MOBILIZACAO)) {
      setSeeMobilizacao(true);
    } else {
      setSeeMobilizacao(false);
    }
    if (hasPermission(PERMISSION_LISTAR_INT_CANDIDATURA)) {
      setSeeCandidatura(true);
    } else {
      setSeeCandidatura(false);
    }
  }, []);

  const handleNewRegistoClick = useCallback(() => {
    if (selected === INTERVENCAO) {
      setIntervencaoSelected(undefined);
      selectIntervencaoResult(undefined);
      setShowResults(false)
      sessionStorage.setItem('novaIntervencao', 'true')
      SessionStorageService.removeItem("total_valor_execucao")

      const loggedUser = JSON.parse(
        localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}'
      )
      setIntervencaoSelected({ utilizador_id: loggedUser?.user?.name })

      if (setShowRegistarIntervencao) setShowRegistarIntervencao(true)
    }
    if (selected === ACAO) {
      setAcaoSelected(undefined);
      selectAcaoResult(undefined);
      setShowResults(false)
      SessionStorageService.removeItem("dataAcaoExecucao")
      sessionStorage.setItem('novaAcao', 'true')
      const loggedUser = JSON.parse(
        localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}'
      )
      setAcaoSelected({ utilizador_id: loggedUser?.user?.name })

      if (setShowRegistarAcao) setShowRegistarAcao(true)
    }
    if (selected === CANDIDATURA) {
      setCandidaturaSelected(undefined);
      setCandidaturaAcaoSelected(undefined);
      selectCandidaturaResult(undefined);
      setShowResults(false)
      sessionStorage.setItem('novaCandidatura', 'true')
      if (setShowRegistarCandidatura) setShowRegistarCandidatura(true)
      const loggedUser = JSON.parse(
        localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}'
      )
      setCandidaturaSelected({ utilizador_id: loggedUser?.user?.name })
    }
    if (selected === MOBILIZACAO) {
      setMobilizacaoSelected(undefined);
      selectMobilizacaoResult(undefined);
      SessionStorageService.setItem('mobilizacaoSubmetida', "false")
      SessionStorageService.setItem('mobilizacaoAntiga', "false")

      setShowResults(false)
      const loggedUser = JSON.parse(
        localStorage.getItem(AUTH_LOCALSTORAGE) ?? '{}'
      )
      setMobilizacaoSelected({ utilizador_id: loggedUser?.user?.name })
      sessionStorage.setItem('novaMobilizacao', 'true')
      if (setShowRegistarMobilizacao) setShowRegistarMobilizacao(true)
    }
  }, [newRegistoLabel])

  useLayoutEffect(() => {

    const handleResize = () => {

      if (window.innerWidth <= telaMobile) {
        
        setIsMobile(true);
        ocultaFloatingMenu_e_Mapa();

      }else{

        setIsMobile(false);
        mostraFloatingMenu_e_Mapa();

      }

    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  const onItemClickMais = (event: any) => {

    elementoClickMais(event);

  };

  const optionsMais = [
    {
      key: 'novoRegisto',
      text: newRegistoLabel,
      elementType: 'button',
      className: 'icon-siarl icon-registar btnIconRegistar'
    }
  ]

  const removeSessionAcao = () => {
    sessionStorage.removeItem("lastSearchAcao")
  };

  const removeSessionCandidatura = () => {
    sessionStorage.removeItem("lastSearchCandidatura")
  };
  
  const removeSessionMobilizacao= () => {
    sessionStorage.removeItem("lastSearchMobilizacao")
  };

  const openAjuda = () => {
    window.open(URL_AJUDA_INTERVENCOES_INTERVENCAO)
  }

  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <div>
          <div className={styles.nomeDoModulo}>Intervenção</div>
          <div className={styles.tituloDaPagina}>Critérios de pesquisa</div>
        </div>

        <div>
          {hasPermission(PERMISSION_INSERIR_INTERVENCAO) && (  
              hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO) ||
              hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO)
              )? 
          <Button
            onClick={handleNewRegistoClick}
            className={styles.btnNovoRegisto}
            style={{ display: isMobile ? 'none' : '' }}
            id='novoRegisto'
            title={newRegistoLabel}
          >
            <span className="icon-siarl icon-registar btnIconRegistar" /> &nbsp;{' '}
            {newRegistoLabel}
          </Button>
            :  ''}

          {isMobile &&
            <DropDownButton
              items={optionsMais}
              className={styles.btnMais}
              iconClass="icon-siarl icon-mais"
              onItemClick={onItemClickMais}
              fillMode={'flat'}
              itemRender={itemRenderMais}
              title="Mais Opções"
            />
          }

          <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
            <span className="icon-siarl icon-ajuda" />
          </button>

          <button className={styles.btnFechar} onClick={onCloseClick} title="Minimizar">
            <span className="icon-siarl icon-minimizar" />
          </button>
        </div>
      </div>
      <TabStrip selected={selected} className={styles.backgroundWhite} onSelect={handleSelect}
      scrollable={isMobile}>
        {canSeeIntervencao || seeIntervencaoList ?
        <TabStripTab title="Intervenções" key="Intervenções">
          <PesquisarIntervencao onCloseClick={onCloseClick} />
        </TabStripTab>
         : ''}
         {canSeeIntervencao || seeAcao  ? 
        <TabStripTab title="Ação" key="Ação">
          <PesquisarAcao onClick={removeSessionAcao} onCloseClick={onCloseClick} />
        </TabStripTab>
        : ''}
        {canSeeCandidatura || seeCandidatura ? 
        <TabStripTab title="Candidatura" key="Candidatura">
          <PesquisarCandidatura onClick={removeSessionCandidatura} onCloseClick={onCloseClick} />
        </TabStripTab>
        : ''}
        {canSeeIntervencao || seeMobilizacao ? 
        <TabStripTab title="Mobilização" key="Mobilização">
          <PesquisarMobilizacao onClick={removeSessionMobilizacao} onCloseClick={onCloseClick} />
        </TabStripTab>
        : ''}
      </TabStrip>
    </div>
  )
}
