import {
  useEffect,
  useState
} from 'react';

import { Dialog } from '@progress/kendo-react-dialogs';
import { ComboBox, ComboBoxFilterChangeEvent } from '@progress/kendo-react-dropdowns';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { arhs, blobToBase64, concelhos, distritos, fillArhsAsync, fillConcelhosAsync, fillDistritosAsync, fillFreguesiasAsync, fillNuts1Async, fillNuts2Async, fillNuts3Async, freguesias, massaagua, nuts1, nuts2, nuts3, useCombos } from '../../../services/utils';
import ValidatedDocumentosUpload from '../../ocorrencia/fields/ValidatedDocumentosUpload';
import { FilterDescriptor, filterBy } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';


type Props = {
  onSubmit: any
  cancelImport: any
}
type ID_CODIGO_NOME = {
  id: number,
  codigo: string,
  nome: string
}
type ImportGeoForm = {
  massaAgua: ID_CODIGO_NOME;
  arh: ID_CODIGO_NOME;
  nuts1Import: ID_CODIGO_NOME;
  nuts2Import: ID_CODIGO_NOME;
  nuts3Import: ID_CODIGO_NOME;
  distritoImport: ID_CODIGO_NOME;
  concelhoImport: ID_CODIGO_NOME;
  freguesiaImport: ID_CODIGO_NOME;
  arquivo: any[];
}

type GeoCode = {
  type: string;
  name: string;
  crs: Crs;
  features: Feature[];
}

type Feature = {
  type: string;
  properties: Properties2;
  geometry: Geometry;
}

type Geometry = {
  type: string;
  coordinates: number[][][][];
}

type Properties2 = {
  id: number;
  entity_code: string;
  name_pt: string;
  name_en: string;
  source_pt: string;
  source_en: string;
  geo_unit_type: number;
  created_date: string;
  updated_date: string;
  geo_unit_type_name: string;
  nome_composto: string;
}

type Crs = {
  type: string;
  properties: Name;
}

type Name = {
  name: string;
}

const fields = ["arquivo", "massaAgua", "arh", "nuts1Import", "nuts2Import", "nuts3Import", "distritoImport", "concelhoImport", "freguesiaImport"]
const fieldsUpload = ["massaAgua", "arh", "nuts1Import", "nuts2Import", "nuts3Import", "distritoImport", "concelhoImport", "freguesiaImport"]
const fieldsArh = ["massaAgua", "nuts1Import", "nuts2Import", "nuts3Import", "distritoImport", "concelhoImport", "freguesiaImport", "arquivo"]
const fieldsNutsToClean = ["arh", "massaAgua", "distritoImport", "concelhoImport", "freguesiaImport", "arquivo"];
const fieldsDisConFregToClean = ["arh", "massaAgua", "nuts2Import", "nuts3Import", "nuts1Import", "arquivo"];
export default function ImportarGeometriaComponent({ onSubmit, cancelImport }: Props) {
  const [openImportModal, setOpenImportModal] = useState<any>()
  const [opcaoSelecionada, setOpcaoSelecionada] = useState('ficheiro') // Inicializa com 'ficheiro'
  const [freguesiasData, setFreguesiasData] = useState(freguesias ? freguesias.slice() : []);
  const [concelhoData, setConcelhoData] = useState(concelhos ? concelhos.slice() : []);
  const [distritoData, setDistritoData] = useState(distritos ? distritos.slice() : []);
  const [nuts1Data, setNuts1Data] = useState(nuts1 ? nuts1.slice() : []);
  const [nuts2Data, setNuts2Data] = useState(nuts2 ? nuts2.slice() : []);
  const [nuts3Data, setNuts3Data] = useState(nuts3 ? nuts3.slice() : []);
  const [arhData, setArhData] = useState(arhs ? arhs.slice() : []);
  const [massaAguaData, setMassaAguaData] = useState(massaagua ? massaagua.slice() : []);


   useEffect(() => {
    
     if(!nuts1 ){
      fillNuts1Async();
      }
     if(!nuts2) {
      fillNuts2Async();
     }
     if(!nuts3){
      fillNuts3Async();
     }
     if(!distritos){
      fillDistritosAsync();
     }
     if(!concelhos){
      fillConcelhosAsync();
     }
     if(!freguesias){
      fillFreguesiasAsync();
     }
     if(!arhs){
      fillArhsAsync();
     }
    

  }, []);

  async function importGeomInternalSubmit(e: any) {
    const formValues: ImportGeoForm = e?.values;
    let obj;
    const arquivos: any[] = formValues?.arquivo;
    if (arquivos && arquivos.length > 0) {
      const prepareConvertAsyncFiles = arquivos?.map(async (_arquivo: any) => {
        const { name, extension, getRawFile } = _arquivo
        const base64 = await blobToBase64(getRawFile())
        return { name, extension, base64 }
      })
      const arquivosBase64 = await Promise.all(prepareConvertAsyncFiles);
      obj = {
        isFile: true,
        arquivos: arquivosBase64
      }
    }
    else if (formValues?.freguesiaImport) {
      obj = {
        freguesia_cod: formValues?.freguesiaImport.codigo,
        geom_imported: "Freguesia: " + formValues?.freguesiaImport.nome
      }
    }
    else if (formValues?.concelhoImport) {
      obj = {
        concelho_cod: formValues?.concelhoImport.codigo,
        geom_imported: "Concelho: " + formValues?.concelhoImport.nome
      }
    }
    else if (formValues?.distritoImport) {
      obj = {
        distrito_cod: formValues?.distritoImport.codigo,
        geom_imported: "Distrito: " + formValues?.distritoImport.nome
      }
    }
    else if (formValues?.nuts3Import) {
      obj = {
        nuts3_cod: formValues?.nuts3Import.codigo,
        geom_imported: "Nuts 3: " + formValues?.nuts3Import.nome
      }
    }
    else if (formValues?.nuts2Import) {
      obj = {
        nuts2_cod: formValues?.nuts2Import.codigo,
        geom_imported: "Nuts 2: " + formValues?.nuts2Import.nome
      }
    }
    else if (formValues?.nuts1Import) {
      obj = {
        nuts1_cod: formValues?.nuts1Import.codigo,
        geom_imported: "Nuts 1: " + formValues?.nuts1Import.nome
      }
    }
    else if (formValues?.massaAgua) {
      obj = {
        massa_agua_cod: formValues?.massaAgua.codigo,
        geom_imported: "Massa Agua: " + formValues?.massaAgua.nome
      }
    }
    else if (formValues?.arh) {
      obj = {
        arh_cod: formValues?.arh.codigo,
        geom_imported: "Arh: " + formValues?.arh.nome
      }
    }
    if (!obj) {
      postMessage({ type: 'errorMsg', value:{message:"Preencha os campos para importação de geometria"} }, '*')
      //postMessage({ type: "errorMsg", value: "Coloque pelo menos uma localização" }, "*");
    }
    onSubmit(obj);
  }

  const cleanCombosLocalizacao = (fieldsToClean: string[], formRenderProps: FormRenderProps) => {
    fieldsToClean.forEach(field => {
      formRenderProps.onChange(field, { value: null })
    })
  }

  const filterDataFreguesia = (filter: FilterDescriptor) => {
    const data = freguesias.slice();
    return filterBy(data, filter);
  };

  const filterChangeFreguesia = (event: ComboBoxFilterChangeEvent) => {
    setFreguesiasData(filterDataFreguesia(event.filter));
  };

  const filterDataConcelho = (filter: FilterDescriptor) => {
    const data = concelhos.slice();
    return filterBy(data, filter);
  };

  const filterChangeConcelho = (event: ComboBoxFilterChangeEvent) => {
    setConcelhoData(filterDataConcelho(event.filter));
  };

  const filterDataDistrito = (filter: FilterDescriptor) => {
    const data = distritos.slice();
    return filterBy(data, filter);
  };

  const filterChangeDistrito = (event: ComboBoxFilterChangeEvent) => {
    setDistritoData(filterDataDistrito(event.filter));
  };

  const filterDataNuts1 = (filter: FilterDescriptor) => {
    const data = nuts1.slice();
    return filterBy(data, filter);
  };

  const filterChangeNuts1 = (event: ComboBoxFilterChangeEvent) => {
    setNuts1Data(filterDataNuts1(event.filter));
  };

  const filterDataNuts2 = (filter: FilterDescriptor) => {
    const data = nuts2.slice();
    return filterBy(data, filter);
  };

  const filterChangeNuts2 = (event: ComboBoxFilterChangeEvent) => {
    setNuts2Data(filterDataNuts2(event.filter));
  };

  const filterDataNuts3 = (filter: FilterDescriptor) => {
    const data = nuts3.slice();
    return filterBy(data, filter);
  };

  const filterChangeNuts3 = (event: ComboBoxFilterChangeEvent) => {
    setNuts3Data(filterDataNuts3(event.filter));
  };

  const filterDataArhs = (filter: FilterDescriptor) => {
    const data = arhs.slice();
    return filterBy(data, filter);
  };

  const filterChangeArhs = (event: ComboBoxFilterChangeEvent) => {
    setArhData(filterDataArhs(event.filter));
  };

  const filterDataMassaAgua = (filter: FilterDescriptor) => {
    const data = massaagua.slice();
    return filterBy(data, filter);
  };

  const filterChangeMassaAgua = (event: ComboBoxFilterChangeEvent) => {
    setMassaAguaData(filterDataMassaAgua(event.filter));
  };


  return (
    <>
      <Dialog
        className="ModalDocumentos"
        title={
          <><div>
            Importar geometria
          </div><div>
              <button title="Obter Ajuda">
                <i
                  className="icon-siarl icon-ajuda"
                  style={{ fontSize: '15px' }}
                ></i>
              </button>
            </div></>
        }
        onClose={cancelImport}
        style={{ zIndex: 999 }}
      >
        <Form
          onSubmitClick={importGeomInternalSubmit}
          render={(formRenderProps) => (
            <FormElement>

              <div className='form'>

              <fieldset className={'k-form-fieldset'}>
                <label className="boldText">Origem da geometria </label>
                <span style={{ marginTop: 25 }}></span>

                <div>
                  <span style={{ marginRight: 10 }}></span>
                  <input
                    type="radio"
                    id="nutsI"
                    name="opcao"
                    value="nutsI"
                    checked={opcaoSelecionada === 'nutsI'}
                    onChange={() => setOpcaoSelecionada('nutsI')}
                  />
                  <label htmlFor="NUTS I"> NUTS I</label>

                  <br></br>
                  <span style={{ marginRight: 10 }}></span>
                  <input
                    type="radio"
                    id="nutsII"
                    name="opcao"
                    value="nutsII"
                    checked={opcaoSelecionada === 'nutsII'}
                    onChange={() => setOpcaoSelecionada('nutsII')}
                  />
                  <label htmlFor="NUTS II"> NUTS II</label>

                  <br></br>
                  <span style={{ marginRight: 10 }}></span>
                  <input
                    type="radio"
                    id="nutsIII"
                    name="opcao"
                    value="nutsIII"
                    checked={opcaoSelecionada === 'nutsIII'}
                    onChange={() => setOpcaoSelecionada('nutsIII')}
                  />
                  <label htmlFor="NUTS II"> NUTS III</label>

                  <br></br>
                  <span style={{ marginRight: 10 }}></span>
                  <input
                    type="radio"
                    id="distrito"
                    name="opcao"
                    value="distrito"
                    checked={opcaoSelecionada === 'distrito'}
                    onChange={() => setOpcaoSelecionada('distrito')}
                  />
                  <label htmlFor="Distrito"> Distrito</label>

                  <br></br>
                  <span style={{ marginRight: 10 }}></span>
                  <input
                    type="radio"
                    id="concelho"
                    name="opcao"
                    value="concelho"
                    checked={opcaoSelecionada === 'concelho'}
                    onChange={() => setOpcaoSelecionada('concelho')}
                  />
                  <label htmlFor="Concelho"> Concelho</label>

                  <br></br>
                  <span style={{ marginRight: 10 }}></span>
                  <input
                    type="radio"
                    id="freguesia"
                    name="opcao"
                    value="freguesia"
                    checked={opcaoSelecionada === 'freguesia'}
                    onChange={() => setOpcaoSelecionada('freguesia')}
                  />
                  <label htmlFor="NUTS II"> Freguesia</label>

                  <br></br>
                  <span style={{ marginRight: 10 }}></span>
                  <input
                    type="radio"
                    id="arh"
                    name="opcao"
                    value="ARH"
                    checked={opcaoSelecionada === 'arh'}
                    onChange={() => setOpcaoSelecionada('arh')}
                  />
                  <label htmlFor="Massa de água"> ARH</label>

                  <br></br>
                  <span style={{ marginRight: 10 }}></span>
                  <input
                    type="radio"
                    id="massaagua"
                    name="opcao"
                    value="massaagua"
                    checked={opcaoSelecionada === 'massaagua'}
                    onChange={() => setOpcaoSelecionada('massaagua')}
                  />
                  <label htmlFor="ARH"> Massa de água</label>

                  <br></br>
                  <span style={{ marginRight: 10 }}></span>

                  <input
                    type="radio"
                    id="ficheiro"
                    name="opcao"
                    value="ficheiro"
                    checked={opcaoSelecionada === 'ficheiro'}
                    onChange={() => setOpcaoSelecionada('ficheiro')}
                  />
                  <label htmlFor="ficheiro"> Ficheiro externo</label>
                </div>
                <br></br>
                {opcaoSelecionada === 'ficheiro' && (
                  <div>
                    <Field
                      name="arquivo"
                      label={
                        <span style={{ fontWeight: 'bold' }}>
                          Arrastar e largar ficheiros do tipo ShapeFile ou GeoJson
                        </span>
                      }
                      component={ValidatedDocumentosUpload}
                      onChange={(e) => {
                        cleanCombosLocalizacao(fieldsUpload, formRenderProps)
                      }}
                    />
                  </div>
                )}

                {opcaoSelecionada === 'nutsI' && (
                  <div>
                    <label className="boldText">Seleção da geometria </label>
                    <Field
                      name="nuts1Import"
                      label="NUTS I"
                      component={ComboBox}
                      textField={'nome'}
                      data={nuts1Data}
                      filterable={true}
                      onFilterChange={filterChangeNuts1}
                      onChange={(e) => {
                        cleanCombosLocalizacao(
                          fieldsNutsToClean,
                          formRenderProps
                        )
                      }}
                    />
                  </div>
                )}
                {opcaoSelecionada === 'nutsII' && (
                  <div>
                    <label className="boldText">Seleção da geometria </label>
                    <Field
                      name="nuts2Import"
                      label="NUTS II"
                      component={ComboBox}
                      textField={'nome'}
                      data={nuts2Data}
                      filterable={true}
                      onFilterChange={filterChangeNuts2}
                      onChange={(e) => {
                        cleanCombosLocalizacao(
                          fieldsNutsToClean,
                          formRenderProps
                        )
                      }}
                    />
                  </div>
                )}
                {opcaoSelecionada === 'nutsIII' && (
                  <div>
                    <label className="boldText">Seleção da geometria </label>
                    <Field
                      name="nuts3Import"
                      label="NUTS III"
                      component={ComboBox}
                      textField={'nome'}
                      filterable={true}
                      onFilterChange={filterChangeNuts3}
                      data={nuts3Data}
                    />
                  </div>
                )}
                {opcaoSelecionada === 'distrito' && (
                  <div>
                    <label className="boldText">Seleção da geometria </label>
                    <Field
                      name="distritoImport"
                      label="Distrito"
                      component={ComboBox}
                      textField={'nome'}
                      data={distritoData}
                      filterable={true}
                      onFilterChange={filterChangeDistrito}
                      onChange={(e) => {
                        cleanCombosLocalizacao(
                          fieldsDisConFregToClean,
                          formRenderProps
                        )
                      }}
                    />
                  </div>
                )}
                {opcaoSelecionada === 'concelho' && (
                  <div>
                    <label className="boldText">Seleção da geometria </label>

                    <Field
                      name="concelhoImport"
                      label="Concelho"
                      component={ComboBox}
                      textField={'nome'}
                      data={concelhoData}
                      filterable={true}
                      onFilterChange={filterChangeConcelho}
                      onChange={(e) => {
                        cleanCombosLocalizacao(
                          fieldsDisConFregToClean,
                          formRenderProps
                        )
                      }}
                    />
                  </div>
                )}
                {opcaoSelecionada === 'freguesia' && (
                  <div>
                    <label className="boldText">Seleção da geometria </label>
                    <Field
                      name="freguesiaImport"
                      label="Freguesia"
                      component={ComboBox}
                      textField={'nome'}
                      data={freguesiasData}
                      filterable={true}
                      onFilterChange={filterChangeFreguesia}
                    />
                  </div>
                )}
                {opcaoSelecionada === 'massaagua' && (
                  <div>
                    <label className="boldText">Seleção da geometria </label>
                    <Field
                      name="massaAgua"
                      label="Massa de água"
                      component={ComboBox}
                      textField={'nome'}
                      filterable={true}
                      onFilterChange={filterChangeMassaAgua}
                      data={massaAguaData}
                    />
                  </div>
                )}
                {opcaoSelecionada === 'arh' && (
                  <div>
                    <label className="boldText">Seleção da geometria </label>
                    <Field
                      name="arh"
                      label="ARH"
                      component={ComboBox}
                      textField={'nome'}
                      filterable={true}
                      onFilterChange={filterChangeArhs}
                      data={arhData}
                      onChange={(e) => {
                        cleanCombosLocalizacao(fieldsArh, formRenderProps)
                      }}
                    />
                  </div>
                )}

                </fieldset>
              </div>

              <div className="footerModal justify_multiple_children">
                <Button
                  type="button"
                  onClick={cancelImport}
                  className='btnLimparPesquisa'
                  title='Fechar'
                >
                  Cancelar
                </Button>

                <Button
                  type="submit"
                  className="btnPesquisar"
                >
                  Importar
                </Button>
              </div>

            </FormElement>
          )}
        />
      </Dialog>
    </>
  )
}