import { create } from 'zustand'

type DhDemarcacaoState = {
    selectedDhDemarcacaoResult?: unknown
    selectDhDemarcacaoResult: (payload: unknown) => void
    selectedDhDemarcacaoResultId?: number
    selectDhDemarcacaoResultId: (payload?: number) => void
}

export const useDhDemarcacaoStore = create<DhDemarcacaoState>((set, get) => ({
    selectedDhDemarcacaoResult: null,
    selectDhDemarcacaoResult: (payload: unknown) => set({ selectedDhDemarcacaoResult: payload }),
    selectDhDemarcacaoResultId: (payload?: number) => set({ selectedDhDemarcacaoResultId: payload }),
}))
