import React, { useState , useRef , useEffect} from 'react';
import { Checkbox, CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import { DropDownButton } from '@progress/kendo-react-buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTable } from '@fortawesome/free-solid-svg-icons';
import { display } from 'html2canvas/dist/types/css/property-descriptors/display';


interface ColumnSelectorProps {
  columns: any;
  setColumns: any;
  styles:any
}

const VisualizadorColunas: React.FC<ColumnSelectorProps> = ({ columns,styles,setColumns }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const columnsItems = columns?.columns?.map((c:any) => ({
    key: c.field,
    text: c.title,
  }));

  const columnsItemsRender = (props: { item: any; itemIndex: number }) => {
    const column:any = columns?.columns?.find((c:any) => c.field === props?.item?.key);
    const label = column?.title;
    const key = column?.field;
    const value = column?.show;
    const defaultChecked = column?.show;

    return (
      <Checkbox
        label={label}
        key={key}
        value={value}
        style={{color:'black'}}
        defaultChecked={defaultChecked}
        onChange={(e: CheckboxChangeEvent) => {
          handleViewColumns(e?.value, column);
        }}
      />
    );
  };

  function handleViewColumns(value: boolean, item: any) {
  const columnsUpdate = columns?.columns?.map((column:any) => {
    if (column.field === item.field) {
      return { ...column, show: value };
    } else {
      return column;
    }
  });

  // Verificar se a coluna que está sendo desmarcada é a única coluna marcada
  const checkedColumns = columnsUpdate.filter((column:any) => column.show);
  if (checkedColumns.length === 0 && !value) {
    // Se for a única coluna marcada e está sendo desmarcada, não fazer nada
    return;
  }

  setColumns({ columns: columnsUpdate });
}

useEffect(() => {
  function handleClickOutside(event: MouseEvent) {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  }

  document.addEventListener('mousedown', handleClickOutside);
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, []);
function handleButtonClick() {
  setIsOpen(!isOpen);
}
return (
  <div ref={dropdownRef} className='visualizadorColunasDropdown'>
    <DropDownButton
      className={styles}
      //style={{marginTop: '-4px'}}
      iconClass="icon-siarl icon-colunasgrid"
      items={columnsItems}
      fillMode={'flat'}
      itemRender={columnsItemsRender}
      title="Colunas"
      opened={isOpen}
       onOpen={handleButtonClick}
    >
      <FontAwesomeIcon icon={faTable} />
    </DropDownButton>
  </div>
);
};

export default VisualizadorColunas;
