import { Button } from '@progress/kendo-react-buttons'
import { DateTimePicker } from '@progress/kendo-react-dateinputs'
import { ComboBox } from '@progress/kendo-react-dropdowns'
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from '@progress/kendo-react-form'

import {
  MouseEvent as ReactMouseEvent,
  useEffect,
  useState
} from 'react'

import {
  Input,
  RadioGroup
} from '@progress/kendo-react-inputs'
import SessionStorageService from '../../services/SessionStorageService'
import {
  arhs,
  capitania,
  concelhos,
  coordenadasPoligono,
  coordenadasSelected,
  distritos,
  fillArhsAsync,
  fillCapitaniaAsync,
  fillConcelhosAsync,
  fillDistritosAsync,
  fillFreguesiasAsync,
  fillNuts1Async,
  fillNuts2Async,
  fillNuts3Async,
  freguesias,
  hasPermission,
  nuts1,
  nuts2,
  nuts3,
  post,
  setCoordenadasPoligono,
  useCombos
} from '../../services/utils'
import { useFotografiaStore } from '../../stores/fotoObliqua'
import styles from '../../styles/entidade/ConsultarEntidadeForm.module.scss'
import {
  AREA_VISIVEL,
  CONSULTAR_ENTIDADES,
  CONSULTAR_REGISTO_VOO,
  NENHUM,
  PERMISSION_INSERIR_FO_REGISTO_VOO,
  POLIGONO_SELECAO,
  URL_AJUDA_FOTOGRAFIA_AEREA
} from '../../utils/constants'
import FieldsetCombosLocalizacao from '../localizacao/FieldsetCombosLocalizacao'
import ReactGA from 'react-ga4';

type Props = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
}

type RegistoVoo = {
  id: number;
  id_estado: number;
  name: string;
  descricao: string;
  criterio_geografico: number;
  de_data_registo: string;
  ate_data_registo: string;
  de_data_voo: string;
  ate_data_voo: string;
  status: boolean;
  freguesia_cod: string;
  concelho_cod: string;
  distrito_cod: string;
  nuts1_cod: string;
  nuts2_cod: string;
  nuts3_cod: string;
  arh_cod: string;
  massa_agua_id: number;
  flatCoordinates: string;
}

export default function ConsultarFotografiasForm({ onCloseClick }: Props) {

  useEffect(() => {
    
     if(!nuts1 ){
      fillNuts1Async();
      }
     if(!nuts2) {
      fillNuts2Async();
     }
     if(!nuts3){
      fillNuts3Async();
     }
     if(!distritos){
      fillDistritosAsync();
     }
     if(!concelhos){
      fillConcelhosAsync();
     }
     if(!freguesias){
      fillFreguesiasAsync();
     }
     if(!arhs){
      fillArhsAsync();
     }
     if(!capitania){
      fillCapitaniaAsync();
     }
  }, []);
  
  const [freguesiasFiltred, setFreguesiasFiltred] = useState<any>()
  const [concelhosFiltred, setConcelhosFiltred] = useState<any>()
  const getFotografiaObliqua = async (data: any) =>
    await post<any, any>(`${CONSULTAR_ENTIDADES}`, data)
  const [tipoPesquisaVisible, setTipoPesquisaVisible] = useState(true)
  const [criterioGeograficoVisible, setCriterioGeograficoVisible] =
    useState(false)
  const [localizacaoVisible, setLocalizacaoVisible] = useState(false)
  const [nuts2Filtered, setNuts2Filtered] = useState<any>(undefined)
  const [nuts3Filtered, setNuts3Filtered] = useState<any>(undefined)
  const [dataVisible, setDataVisible] = useState(false)
  const [dataDe, setDataDe] = useState<any>()
  const [dataAte, setDataAte] = useState<any>()
  const [minData, setMinDate] = useState<any>();

 
  const toggleData = () => {
    setDataVisible((previousState) => !previousState)
  }


  const toggletipoPesquisa = () => {
    setTipoPesquisaVisible(!tipoPesquisaVisible)
  }

  const toggleCriterioGeografico = () => {
    setCriterioGeograficoVisible((previousState) => !previousState)
  }

  const toggleLocalizacao = () => {
    setLocalizacaoVisible(!localizacaoVisible)
  }

  const selectFotografiaResult = useFotografiaStore(
    (state) => state.selectFotoResult
  )

  const selectFotografiaResultId = useFotografiaStore(
    (state) => state.selectFotoResultId
  )

  const concelhoSelectChange = (item: any) => {
    if (!item) {
      setFreguesiasFiltred(freguesias)
      return
    }
    setFreguesiasFiltred(
      freguesias.filter((x) => x.concelho_cod == item.codigo)
    )
  }

  const filterConcelho = (e: any) => {
    if (e?.value?.codigo) {
      const concelhosLocal = concelhos.filter(
        (x: any) => x.distrito_cod == e.value.codigo
      )
      setConcelhosFiltred(concelhosLocal)
    } else {
      setConcelhosFiltred(concelhos)
    }
  }

  const filterFreguesia = (e: any) => {
    if (e?.value?.codigo) {
      const freguesiaLocal = freguesias.filter(
        (x: any) => x.concelho_cod == e.value.codigo
      )
      setFreguesiasFiltred(freguesiaLocal)
    } else {
      setFreguesiasFiltred(freguesias)
    }
  }

  const filterNuts2 = (e: any) => {
    setNuts2Filtered([])
    if (e?.value?.codigo) {
      setNuts2Filtered(
        nuts2?.filter((nts: any) => nts?.nuts_i_cod === e?.value?.codigo)
      )
    }
  }

  const filterNuts3 = (e: any) => {
    setNuts3Filtered([])
    if (e?.value?.codigo) {
      setNuts3Filtered(
        nuts3?.filter((nts: any) => nts?.nuts_ii_cod === e?.value?.codigo)
      )
    }
  }

  const handleSubmit = async (e: any) => {

    try {
      postMessage({ type: 'showLoader' }, '*')
      const formValues = e.values;
      const voo_registos_dto = 
      { 
        ...formValues, 
        flatCoordinates: coordenadasPoligono,
        freguesia_cod : formValues.freguesia?.codigo, 
        concelho_cod : formValues.concelho?.codigo,
        distrito_cod: formValues.distrito?.codigo,
        nuts1_cod: formValues.nuts1?.codigo,
        nuts2_cod: formValues.nuts2?.codigo,
        nuts3_cod: formValues.nuts3?.codigo,
        capitania_cod: formValues.capitania?.codigo,
        arh_cod: formValues.arh?.codigo
      };
      SessionStorageService.setItem("lastSearchFotografiaAerea",voo_registos_dto);

      const voosData = await post<RegistoVoo, any>(`${CONSULTAR_REGISTO_VOO}`, voo_registos_dto);
      SessionStorageService.setItem("resultadoFotoPesquisa", JSON.stringify(voosData));

      SessionStorageService.setItem("firstCallFotoObliqua", true);
      postMessage({ type: 'resultadoFotoPesquisaChange' }, '*')

    } catch (error) {
      console.log(error)
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }

  }

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/ConsultarFotografiaAerea" });
  }, []);

  const registarNovoClick = () => {
    selectFotografiaResult(undefined);
    selectFotografiaResultId(undefined);
    postMessage({ type: 'form', value: 'registarFotoObliqua' }, '*')
      sessionStorage.setItem('novaFoto', 'true')
  }

  const clearMap = () => {
    try {

      setCoordenadasPoligono(undefined)
      const iframe: any = document.getElementById('iframeGeoPortal')
      iframe.contentWindow?.postMessage({ type: "SiarlFotoObliquaClearLayers", value: "true" }, '*')
      iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: "true" }, '*')
      iframe.contentWindow?.postMessage({ type: "SiarlDeselectMapArea", value: "true" }, '*')

    } catch (error) {
      console.log({ error })
    }
  }

const changerbCriterio = (e: any) => {
  const iframe: any = document.getElementById('iframeGeoPortal')
  iframe.contentWindow?.postMessage({ type: "SiarlDeselectMapArea", value: 'true' }, '*')
  if (e.value == POLIGONO_SELECAO) {
      postMessage(
          { type: 'informationMsg', value: 'Selecione a área de pesquisa' },
          '*'
      )
      iframe.contentWindow?.postMessage(
          { type: 'SiarlGeoPortalAdicionarArea', value: 'true' },
          '*'
      )
  }
  if (e.value == AREA_VISIVEL) {
      iframe.contentWindow?.postMessage({ type: "SiarlSelectMapArea", value: 'true' }, '*')
      postMessage(
          {
              type: 'informationMsg',
              value: 'Foi selecionada a área visível do mapa',
          },
          '*'
      )
     
      iframe.contentWindow?.postMessage(
          { type: 'SiarlGetMapArea', value: 'true' },
          '*'
      )
  }
  if (e.value == NENHUM) {
      //setCoordenadasPoligono(undefined)
      clearMap()
  }
}

const openAjuda = () => {
  window.open(URL_AJUDA_FOTOGRAFIA_AEREA, "_blank")
}

  return (
    <div className={styles.base}>

      <div className={styles.header}>

        <div>
          <div className={styles.nomeDoModulo}>
            Foto aérea
          </div>
          <div className={styles.tituloDaPagina}>
            Critérios de pesquisa
          </div>
        </div>

        <div>
        {hasPermission(PERMISSION_INSERIR_FO_REGISTO_VOO) ?
          <Button onClick={registarNovoClick} className={styles.btnNovoRegisto}>
            <span className="icon-siarl icon-registar btnIconRegistar"></span>{' '}
            &nbsp;{' '}
            Nova Fotografia Aérea
          </Button>
          : '' }
          <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
            <span className="icon-siarl icon-ajuda"></span>
          </button>

          <button className={styles.btnFechar} onClick={onCloseClick} title="Minimizar">
            <span className="icon-siarl icon-minimizar"></span>
          </button>

        </div>
      </div>

      <Form
        onSubmitClick={handleSubmit}
        initialValues={
          {
            temJurisdicao: true,
            rbTipoPesquisa: 0,
            rbCriterio: 0,
          } ?? undefined
        }
        render={(formRenderProps: FormRenderProps) => (
          <>
            <FormElement className={styles.form}>
              <fieldset>
                <legend>
                  <button type='button' className={styles.btn_Expandir_Colapsar} onClick={toggletipoPesquisa}>
                    <span className={tipoPesquisaVisible ? 'icon-siarl icon-colapsar' : 'icon-siarl icon-expandir'}></span>
                  </button>
                  {' '}&nbsp;
                  <span className={styles.fieldsetTitulo}>Identificação</span>
                </legend>

                {tipoPesquisaVisible && (

                  <div
                    className={`${styles.fields} ${styles.espacoVerticalFieldSet}`}
                  >
                    <div>
                      <Field name="id" label="ID" component={Input} />
                    </div>
                    <div>
                      <Field
                        name="descricao"
                        label="Descrição"
                        component={Input}
                      />
                    </div>
                    <div>
                      <Field
                        name="estado"
                        label="Estado"
                        component={ComboBox}
                        textField={'descricao'}
                      />
                    </div>
                  </div>

                )}
              </fieldset>

              <fieldset>
                <legend>
                  <button
                    type="button"
                    className={styles.btn_Expandir_Colapsar}
                    onClick={toggleCriterioGeografico}
                  >
                    <span
                      className={
                        criterioGeograficoVisible
                          ? 'icon-siarl icon-colapsar'
                          : 'icon-siarl icon-expandir'
                      }
                    ></span>
                  </button>{' '}
                  &nbsp;
                  <span className={styles.fieldsetTitulo}>
                    Critério geográfico
                  </span>{' '}
                </legend>
                {criterioGeograficoVisible ? (
                  <div className={styles.fields}>
                    <Field
                      id={'rbCriterio'}
                      name={'rbCriterio'}
                      layout={'horizontal'}
                      component={RadioGroup}
                      onChange={changerbCriterio}
                      data={[
                        { label: 'Nenhum', value: 0},
                        { label: 'Área visível', value: 1 },
                        { label: 'Polígono de seleção', value: 2 },
                      ]}
                      defaultValue={0} // Set the default selected value to 0 (Nenhum)

                    />
                  </div>
                ) : (
                  <></>
                )}
              </fieldset>

             <FieldsetCombosLocalizacao isPesquisaAvancada={true} styles={styles} formRenderProps={formRenderProps}/>


              <fieldset>
                <legend>
                  <button
                    type="button"
                    className={styles.btn_Expandir_Colapsar}
                    onClick={toggleData}
                  >
                    <span
                      className={
                        dataVisible
                          ? 'icon-siarl icon-colapsar'
                          : 'icon-siarl icon-expandir'
                      }
                    />
                  </button>{' '}
                  &nbsp;
                  <span className={styles.fieldsetTitulo}>Intervalo de datas</span>{' '}
                </legend>
                {dataVisible && (
                  <div className={styles.espacoVerticalFieldSet}>
                    <legend className={styles.fieldsetSubTitulo}>
                      Data
                    </legend>
                    <div className={styles.fields}>
                      <div>
                        <Field
                          name="de_inicio"
                          label="De"
                          component={DateTimePicker}
                          format="dd/MM/yyyy HH:mm"
                          onChange={(e) => {
                            setDataDe(e.value);
                             
                             setMinDate(e.value);
                          }}
                        />
                      </div>
                      <div>
                        <Field
                          name="ate_inicio"
                          label="Até"
                          component={DateTimePicker}
                          format="dd/MM/yyyy HH:mm"
                          min={minData}                          
                          onChange={(e) => {
                            setDataAte(e.value)
                          }}
                        />
                      </div>
                    </div>
                  </div>

                )}
              </fieldset>

            </FormElement>

            <div className={`${styles.footer} ${styles.justify_multiple_children}`}>
              <Button
                onClick={(e: any) => {
                  formRenderProps.onFormReset()
                  setCoordenadasPoligono(undefined)
                  clearMap()
                }}
                className={styles.btnLimparPesquisa}
                title='Limpar pesquisa'
              >
                <span className="icon-siarl icon-limparcampos"></span>
                <span className={styles.rotulo}>
                  {' '}&nbsp;{' '}
                  Limpar pesquisa
                </span>
                
              </Button>
              <Button
                onClick={formRenderProps.onSubmit}
                className={styles.btnPesquisar}
                title='Pesquisar'
              >
                <span className="icon-siarl icon-pesquisar"></span>
                <span className={styles.rotulo}>
                  {' '}&nbsp;{' '}
                  Pesquisar
                </span>
                
              </Button>
            </div>
          </>
        )}
      />

    </div>
  )
}
