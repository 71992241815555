import { Button, DropDownButton } from '@progress/kendo-react-buttons'
import { useEffect, useLayoutEffect, useState } from 'react'

import { Form, FormElement } from '@progress/kendo-react-form'
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from '@progress/kendo-react-layout'
import { MouseEvent as ReactMouseEvent } from 'react'
import {
  GetUserNameTecnico,
  b64toBlob,
  clearAcao,
  coordenadasPoligono,
  del,
  get,
  getMobilizacaoSelected,
  handleGenericError,
  hasPermission,
  post,
  put,
  setCoordenadasPoligono,
  setCoordenadasSelected,
  setMobilizacaoSelected,
  tecnico,
  useCombos
} from '../../services/utils'
import styles from '../../styles/intervencao/Intervencao.module.scss'
import {
  AUTH_LOCALSTORAGE,
  CURRENT_TIMEZONE,
  DELETE_MOBILIZACAO_BY_ID,
  GET_URL_FROM_FILE_DOC,
  IMPORT_GEOM_MOBILIZACAO,
  INT_MOBILIZACAO_POINT_NAME,
  LAYER_INDEX_INT_MOBILIZACAO,
  OPERATIONAL_LAYER,
  PERMISSION_ATUALIZAR_INTERVENCAO,
  PERMISSION_ATUALIZAR_MOBILIZACAO,
  PERMISSION_INSERIR_INTERVENCAO,
  PERMISSION_INSERIR_MOBILIZACAO,
  PERMISSION_INTERVENCAO_PERFIL_BASICO,
  PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO,
  PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA,
  PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO,
  PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_LEITURA,
  PERMISSION_INTERVENCAO_PERFIL_TOTAL,
  PESQUISAR_MOBILIZACAO, 
  RELATORIO_MOBILIZACAO,
  REMOVER_AREA_INT_MOBILIZACAO,
  SAVE_NEW_MOBILIZACAO,
  UPDATE_MOBILIZACAO,
  URL_AJUDA_INTERVENCOES_MOBILIZACAO,
  clearLayers
} from '../../utils/constants'
import { AssociacaoListagem } from '../associacao/Associacao'
import { DocumentoListagem } from '../documentos/registar/documento/documento'
//import { AcaoListagem }  from '../associacao/Associacao'
import { useMobilizacaoStore } from '../../stores/mobilizacao'
import IdentificaoMobilizacao from './IdentificaoMobilizacao'
import AcaoMobilizacao from './acaoMobilizacao'
import ImportarGeometriaComponent from '../apoiogestao/registar/ImportarGeometriaForm'
import { Dialog } from '@progress/kendo-react-dialogs'
import SessionStorageService from '../../services/SessionStorageService'
import useClearMap from '../../hooks/useClearMap'
import html2canvas from 'html2canvas'
import ReactGA from 'react-ga4';
import { elementoClickMais, itemRenderMais, mostraFloatingMenu_e_Mapa, ocultaFloatingMenu_e_Mapa, telaMobile } from '../common/ItemRenderMais'
import moment from 'moment'

type Props = {
  onCloseClick: (
    e: ReactMouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined
}

type mobilizacaoForm = {
  //  TAB IDENTIFICAÇÃO

  //  Identificação
  id: number
  descricao: string

  // Localização
  distrito: string
  concelho: string
  freguesia: string

  //  Datas
  data: any
  data_registo: any

  //  Caracterização
  id_int_mobilizacao_funcao: number
  id_tipo_mo: number

  //  Volume
  volume: number

  //  Tipo Mancha Emprestimo
  tipo_mancha_nivel_1_id: number
  tipo_mancha_nivel_2_id: number

  //  Metodo Dragagem
  tipo_metodo_dragagem_id: number

  //  Tipo Local Reposição
  tipo_local_deposicao_1_id: number
  tipo_local_deposicao_2_id: number

  //  Método Deposição
  tipo_mob_metodo_deposicao: number

  //  Objeto Deposição
  tipo_mob_objetivo_dep_1_id: number
  tipo_mob_objetivo_dep_2_id: number

  //  Qualidade Sedimentos
  td_mob_qualidade: number

  //  Granulometria Sedimentos
  td_mob_granulometria: number

  //  Estado
  estado: number

  //  Entidade
  id_entidade: number
  fonte_financiamento: number


  flatCoordinates: string

  filedoc_cod: string

  geom_imported: string | undefined

  utilizador_id: number

}

const TOTAL_TABS = 4

export default function RegistarMobilizacao({ onCloseClick }: Props) {
  const [isMobile, setIsMobile] = useState(false);
  const [selected, setSelected] = useState<number>(0)

  const selectedMobilizacaoResult: any = useMobilizacaoStore(
    (state) => state.selectedMobilizacaoResult
  )
    const [openImportModal, setOpenImportModal] = useState<any>()

  const selectedMobilizacaoResultId: any = useMobilizacaoStore((state) => state.selectedMobilizacaoResultId)

  const selectMobilizacaoResult = useMobilizacaoStore((state) => state.selectMobilizacaoResult)
  const selectMobilizacaoResultId = useMobilizacaoStore((state) => state.selectMobilizacaoResultId)

  const getMobilizacaoById = async (id: any) =>
    await get<any, any>(`${PESQUISAR_MOBILIZACAO}/` + id)
  const importGeomIntMobilizacao = async (data: any) =>
    await post<any, any>(`${IMPORT_GEOM_MOBILIZACAO}`, data)
  const saveMobilizacao = async (data: any) => await post<any, any>(`${SAVE_NEW_MOBILIZACAO}`, data)
  const updateMobilizacao = async (data: any) => await put<any, any>(`${UPDATE_MOBILIZACAO}`, data)
  const getUrlFileDoc = async (data: any) => await get<any>(`${GET_URL_FROM_FILE_DOC}/` + encodeURIComponent(data));

  const relatorioMobilizacao = async (data: any) =>
  await post<any, any>(`${RELATORIO_MOBILIZACAO}`, data)

  const deleteMobilizacao = (id: number) => del(`${DELETE_MOBILIZACAO_BY_ID}/${id}`, {})


    const [deleteClicked, setDeleteClicked] = useState(false);

    const [canUpdate, setCanUpdate] = useState(false);
    const [canInsert, setCanInsert] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [viewMode, setViewMode] = useState(false);
    const [openRemoveAreaModal, setOpenRemoveAreaModal] = useState<any>()

    
  const removerAreaMobilizacao = async (data: any) =>
    await post<any, any>(`${REMOVER_AREA_INT_MOBILIZACAO}`, data)

    
  useEffect(() => {
    setViewMode(!editMode && sessionStorage.getItem("novaMobilizacao") != 'true');
  }, [])

    useEffect(() => {
    setCanUpdate(hasPermission(PERMISSION_ATUALIZAR_MOBILIZACAO));
    setCanInsert(hasPermission(PERMISSION_INSERIR_MOBILIZACAO))
  }, [])

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/RegistarIntMobilizacao" });
  }, []);

  useEffect(() => {
    addEventListener('message', (event: MessageEvent) => {
      if (event.data.type == 'CoordenadasSelected') {
        console.log(event.data.value)
        if (event.data.value) {
          const flatPointCoordinates =
            event.data.value.x + ', ' + event.data.value.y
          setCoordenadasSelected(flatPointCoordinates)
        }
      } else if (event.data.type == 'CoordenadasFlatSelected') {
        let polygonString = ''
        const values: any[] = event.data.value
        values?.forEach((value, index) => {
          if (index % 2 == 0) {
            polygonString += value + ' '
          } else {
            polygonString += value + ','
          }
        })
        polygonString = polygonString.substring(0, polygonString.length - 1)
        setCoordenadasPoligono(polygonString)
        console.log(polygonString)
      }
    })
  }, [])

  const selectMapAction =  (item: any) => {
    const iframe: any = document.getElementById('iframeGeoPortal')

    if (item.key == "removerArea") {
      setOpenRemoveAreaModal(true)
    } else {
      iframe.contentWindow?.postMessage({ type: item.key, value: getMobilizacaoSelected()?.id, editGpLayer: INT_MOBILIZACAO_POINT_NAME }, '*')
    }
  }

const removeArea = async () => {
  const iframe: any = document.getElementById('iframeGeoPortal')
  iframe.contentWindow?.postMessage({ type: "SiarlRemoveSelection", value: 'true' }, '*')
  setCoordenadasPoligono(undefined);
  if (!selectedMobilizacaoResult?.id) {
    setOpenRemoveAreaModal(false)
    return;
  }
  await removerAreaMobilizacao(selectedMobilizacaoResult?.id);
 
  iframe.contentWindow?.postMessage(
    { type: 'SiarlIntMobilizacaoSearch', value: [selectedMobilizacaoResult?.id] },
    '*'
  )
  refreshForm(selectedMobilizacaoResult?.id)
  setOpenRemoveAreaModal(false)

}
 const voltar = async () => {
  const iframe: any = document.getElementById('iframeGeoPortal')
  iframe.contentWindow?.postMessage(
     {
       type: 'SiarlCoordenadaBoxClose',
       value: 'true',
     },
     '*'
   )
   if (SessionStorageService.getItem("mobilizacaoSubmetida") == "true" || SessionStorageService.getItem("mobilizacaoAntiga") == "true") {
    postMessage({ type: 'form', value: 'pesquisarMobilizacao' }, '*');
    clearMobilizacaoMap();
  }
   else if ((SessionStorageService.getItem("mobilizacaoSubmetida") == "false" || SessionStorageService.getItem("mobilizacaoAntiga") == "false") && getMobilizacaoSelected()?.id) {
      await deleteMobilizacao(getMobilizacaoSelected()?.id);
  }
    clearMobilizacaoMap();
    postMessage({ type: 'form', value: 'pesquisarMobilizacao' }, '*');
   
  }

  const [clearMap] = useClearMap();
  const clearMobilizacaoMap = () => {
    clearMap(clearLayers["CLEAR_LAYERS_MOBILIZACAO"]);
    setMobilizacaoSelected(undefined)
    selectMobilizacaoResult(undefined)
  }

  const [openModalDescartar, setOpenModalDescartar] = useState<any>()

  const cancelModalDescartar = () => {
    setOpenModalDescartar(false)
  }

     const handleCancel = ()  => {
        refreshForm();
        postMessage({ type: "form", value: "" }, "*");
        setTimeout(() => {
            postMessage({ type: "form", value: "registarMobilizacao" }, "*");
          }, 10)
    }

   const handleOpenModalDescartar = () => {
    if (
      sessionStorage.getItem('novaMobilizacao') &&
      sessionStorage.getItem('novaMobilizacao') == 'true'
    ) {
      setOpenModalDescartar(true);
    } else {
      onEditItemClick("SiarlVisualizar");
      handleCancel();
    }  }

    const refreshForm = async(id: any = undefined) => {
      
      let iMobilizacao = id;
      if (!iMobilizacao) {
        if (selectedMobilizacaoResult?.id) {
          iMobilizacao = selectedMobilizacaoResult?.id;
        } else {
          return;
        }
      }
      const mobilizacao = await getMobilizacaoById(iMobilizacao);
      const tecnico = await GetUserNameTecnico(mobilizacao.utilizador_id);

     
        const objToShow = {
      ...mobilizacao,
      id: mobilizacao.id ?? undefined,
      descricao: mobilizacao.descricao ?? undefined,
      nuts1: mobilizacao.nuts1 ?? undefined,
      nuts2: mobilizacao.nuts2 ?? undefined,
      nuts3: mobilizacao.nuts3 ?? undefined,
      distrito : mobilizacao.distrito ?? undefined,
      concelho: mobilizacao.mobilizacao ?? undefined,
      freguesia: mobilizacao.freguesia ?? undefined,
      arh: mobilizacao.arh ?? undefined,
      capitania : mobilizacao.capitania ?? undefined,
      massaagua: mobilizacao.massaagua ?? undefined,
      data_mobilizacao : mobilizacao.data? moment.utc(new Date(mobilizacao?.data)).tz(CURRENT_TIMEZONE).format("DD/MM/yyyy") :null,
      data_registo : mobilizacao.data_registo? moment.utc(new Date(mobilizacao?.data_registo)).tz(CURRENT_TIMEZONE).format("DD/MM/yyyy") :null,
      id_int_mobilizacao_funcao: mobilizacao.int_mobilizacao_funcao ?? undefined,
      id_tipo_mo : mobilizacao.tipo_mo ?? undefined,
      volume: mobilizacao.volume ?? undefined,
      tipo_mancha_nivel_1_id: mobilizacao.intMobMancEmprestimoNivel1 ?? undefined,
      tipo_mancha_nivel_2_id: mobilizacao.intMobMancEmprestimoNivel2 ?? undefined,
      tipo_metodo_dragagem_id : mobilizacao.mobMetodoDragagem ?? undefined,
      tipo_local_deposicao_1_id : mobilizacao.intMobDescritivoLocalDeposicaoNivel1 ?? undefined,
      tipo_local_deposicao_2_id : mobilizacao.intMobDescritivoLocalDeposicaoNivel2 ?? undefined,
      tipo_mob_metodo_deposicao : mobilizacao.mobMetodoDeposicao ?? undefined,
      tipo_mob_objetivo_dep_1_id: mobilizacao.intMobObjetivoDeposicaoNivel1 ?? undefined,
      tipo_mob_objetivo_dep_2_id: mobilizacao.intMobObjetivoDeposicaoNivel2 ?? undefined,
      td_mob_qualidade: mobilizacao.mobQualidade ?? undefined,
      td_mob_granulometria : mobilizacao.mobGranulometria ?? undefined,
      estado: mobilizacao.status ?? undefined,
      id_entidade: mobilizacao.entidade ?? undefined,
      utilizador_id : tecnico
    }
    selectMobilizacaoResult(objToShow);
    setMobilizacaoSelected(objToShow);
    const iframe: any = document.getElementById('iframeGeoPortal')

    iframe.contentWindow?.postMessage({ type: "SiarlIntMobilizacaoSearch", value: [mobilizacao?.id] }, '*')
    }

  const [estadoFalse, setEstado] = useState(false)
    const [estadoTrue, setEstadoTrue] = useState(true)


  const handleDescartar = async (e: any) => {
    const iframe: any = document.getElementById('iframeGeoPortal')
    iframe.contentWindow?.postMessage(
       {
         type: 'SiarlCoordenadaBoxClose',
         value: 'true',
       },
       '*'
     )
     iframe.contentWindow?.postMessage(
      {
        type: 'SiarlRemoveSelection',
        value: 'true',
      },
      '*'
    )
  setOpenModalDescartar(false)
  setDeleteClicked(false)

   const mobilizacaoSelected = getMobilizacaoSelected()    
   
    if (mobilizacaoSelected?.id) {
      postMessage({ type: 'showLoader' }, '*')
      await deleteMobilizacao(mobilizacaoSelected.id);
         
          }
          postMessage({ type: 'hideLoader' }, '*')

    SessionStorageService.removeItem("firstCallMobilizacao");
    postMessage({ type: 'form', value: 'pesquisarMobilizacao' }, '*')

}

  const isUUID = (str: string) => {
    const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidPattern.test(str);
  };

  const parseDate = (dateString: any) => {
    if (!dateString) return undefined;
    
    // Divida a string na forma de DD/MM/YYYY
    const [day, month, year] = dateString.split('/');
    
    // Converta para o formato YYYY-MM-DD
    const formattedDate = `${year}-${month}-${day}`;
    
    // Crie o objeto Date
    return new Date(formattedDate);
  };
  

  const handleSubmit = async (e: any) => {

    if (!isFormValid) {
      return
    }

    const mobilizacaoSelected = getMobilizacaoSelected();

    const userId = JSON.parse(localStorage.getItem(AUTH_LOCALSTORAGE) ?? '')?.user?.id

    const dataMobilizacao: any = e.values?.data_mobilizacao ? moment.utc(e.values?.data_mobilizacao).tz(CURRENT_TIMEZONE) : undefined
    const dataRegisto : any = e.values?.data_registo ? moment.utc(e.values?.data_registo).tz(CURRENT_TIMEZONE) : undefined
    

    const lstAcaoMobilizacao = SessionStorageService.getItem("dataAcaoMobilizacao");
    const formattedAcaoMobilizacao= lstAcaoMobilizacao?.map((item : any) => ({
      id: isUUID(item?.id) ? undefined : item?.id,
      id_acao: item?.id_acao,
      designacao : item?.designacao?.designacao ?? undefined,
      id_tipologia: item?.id_tipologia?.id ?? undefined,
      data_conclusao: item?.data_con_e ? parseDate(item.data_con_e) : undefined,
      data_inicio: item?.data_ini_e ? parseDate(item.data_ini_e) : undefined, 
      volume: item?.volume === "" ? undefined : item?.volume,
      localizacao: item?.localizacao ?? undefined,
      id_entidade: item?.entidade_id?.id ?? undefined
  }));

    const mobilizacaoRequest: any = {
      id: mobilizacaoSelected?.id ?? undefined,
      descricao: e.values?.descricao ?? undefined,
      distrito: e.values?.distrito ? e.values?.distrito.id : undefined,
      concelho: e.values?.id_concelh ? e.values?.id_concelh.id : undefined,
      freguesia: e.values?.id_fregues ? e.values?.id_fregues.id : undefined,
      data: dataMobilizacao ? dataMobilizacao._d : undefined,
      data_registo: dataRegisto ? dataRegisto._d : undefined,
      id_int_mobilizacao_funcao: e.values?.id_int_mobilizacao_funcao ? e.values?.id_int_mobilizacao_funcao.id : undefined,
      id_tipo_mo: e.values?.id_tipo_mo ? e.values?.id_tipo_mo.id : undefined,
      volume: e.values?.volume ?? undefined,
      tipo_mancha_nivel_1_id: e.values?.tipo_mancha_nivel_1_id ? e.values?.tipo_mancha_nivel_1_id.id : undefined,
      tipo_mancha_nivel_2_id: e.values?.tipo_mancha_nivel_2_id ? e.values?.tipo_mancha_nivel_2_id.id : undefined,
      tipo_metodo_dragagem_id: e.values?.tipo_metodo_dragagem_id ? e.values?.tipo_metodo_dragagem_id.id : undefined,
      tipo_local_deposicao_1_id: e.values?.tipo_local_deposicao_1_id ? e.values?.tipo_local_deposicao_1_id.id : undefined,
      tipo_local_deposicao_2_id: e.values?.tipo_local_deposicao_2_id ? e.values?.tipo_local_deposicao_2_id.id : undefined,
      tipo_mob_metodo_deposicao: e.values?.tipo_mob_metodo_deposicao ? e.values?.tipo_mob_metodo_deposicao.id : undefined,
      tipo_mob_objetivo_dep_1_id: e.values?.tipo_mob_objetivo_dep_1_id ? e.values?.tipo_mob_objetivo_dep_1_id.id : undefined,
      tipo_mob_objetivo_dep_2_id: e.values?.tipo_mob_objetivo_dep_2_id ? e.values?.tipo_mob_objetivo_dep_2_id.id : undefined,
      td_mob_qualidade: e.values?.td_mob_qualidade ? e.values?.td_mob_qualidade.id : undefined,
      td_mob_granulometria: e.values?.td_mob_granulometria ? e.values?.td_mob_granulometria?.id : undefined,
      id_int_mobilizacao_estado: e.values?.int_mobilizacao_estado ? e.values?.int_mobilizacao_estado.id : undefined, // Não Definido
      id_entidade: e.values?.id_entidade ? e.values?.id_entidade.id : undefined,
      fonte_financiamento: e.values?.fonte_financiamento ? e.values?.fonte_financiamento.id : undefined,
      flatCoordinates: coordenadasPoligono ?? undefined,
      filedoc_cod: e.values?.filedoc_cod ?? undefined,
      geom_imported: mobilizacaoSelected?.geom_imported ?? undefined,
      utilizador_id: userId,
      lstMobilizacaoAcao: formattedAcaoMobilizacao ?? undefined
    }

    postMessage({ type: 'showLoader' }, '*');

    try {
      let mobilizacaoSaved: any;
      const mobilizacaoSelected = getMobilizacaoSelected();


      if (mobilizacaoSelected?.id) {

        mobilizacaoSaved = await updateMobilizacao(mobilizacaoRequest);
        selectMobilizacaoResult({
          ...selectedMobilizacaoResult,
          ...mobilizacaoSaved
        });
        setMobilizacaoSelected(mobilizacaoSaved);

      } else {

        mobilizacaoSaved = await saveMobilizacao(mobilizacaoRequest);
        selectMobilizacaoResultId(Number(mobilizacaoSaved?.id))


        setMobilizacaoSelected(mobilizacaoSaved);
        selectMobilizacaoResult({
          ...selectedMobilizacaoResult,
          ...mobilizacaoSaved
        });

      }

      setCoordenadasPoligono(undefined);

      refreshForm(mobilizacaoSaved.id);
      if (mobilizacaoSaved) {
       setTimeout(() => {
          postMessage({ type: 'sucessMsg' }, '*')
        }, 500)
      }

    } catch (error) {
      handleGenericError(error);
    }

    postMessage({ type: 'hideLoader' }, '*')
  }

  const itemRender = (props: { item: any; itemIndex: number }) => {
    const isTitleItem = props.item.key === 'titulo'
    if (isTitleItem) {
      // Item de título personalizado
      return (
        <div className={styles.DropDownButtonTitulo}>
          <span>{props.item.text}</span>
        </div>
      )
    } else {
      // Demais itens
      return (
        <div className={styles.DropDownButtonItem}>
          <span>{props.item.text}</span>
        </div>
      )
    }
  }

   const importGeo = async (objToSend: any) => {
    postMessage({ type: "showLoader" }, "*");
    if (!objToSend) {
      postMessage({ type: "errorMsg", value: "Não foi possivel salvar" }, "*");
      postMessage({ type: "hideLoader" }, "*");
      return;
    }

    let int_mobilizacao;
    let int_mobilizacao_id;
    const acaoSelected = selectedMobilizacaoResult ?? getMobilizacaoSelected();
    if (acaoSelected && acaoSelected?.id) {
      int_mobilizacao = acaoSelected;
      int_mobilizacao_id = acaoSelected?.id;
    } else {
      int_mobilizacao = await saveMobilizacao({});
      int_mobilizacao_id = int_mobilizacao?.id;
      selectMobilizacaoResult(int_mobilizacao);
      selectMobilizacaoResultId(int_mobilizacao_id);

    }
    try {
      objToSend = { ...objToSend, int_mobilizacao_id }
      const result = await importGeomIntMobilizacao(objToSend)
      if (!result) {
        postMessage({ type: "errorMsg", value: "Não foi possivel salvar" }, "*");
        return;
      }

      try {
        int_mobilizacao = await getMobilizacaoById(int_mobilizacao_id);
      } catch (error) {
        postMessage({ type: "errorMsg", value: "Registo salvo mas, Não é possivel mostrar os dados neste momento" }, "*");

      } finally {
        int_mobilizacao = { ...int_mobilizacao, ...objToSend };
        selectMobilizacaoResultId(int_mobilizacao_id);
        selectMobilizacaoResult(int_mobilizacao);
        setMobilizacaoSelected(int_mobilizacao)
        refreshForm(int_mobilizacao_id);
      }
      try {
        const iframe: any = document.getElementById('iframeGeoPortal')
        iframe.contentWindow?.postMessage(
          { type: 'SiarlIntMobilizacaoSearch', value: [int_mobilizacao_id] },
          '*'
        )
      } catch (error) {
        postMessage({ type: "errorMsg", value: "Registo salvo mas, Não é possivel mostrar os dados neste momento" }, "*");
      }

      setOpenImportModal(false)
    } catch (err) {
      postMessage({ type: "errorMsg", value: "Não foi possivel salvar" }, "*");

    } finally {
      postMessage({ type: "hideLoader" }, "*");
    }
  }

const onItemClick = (event: any) => {
  if(event?.item?.key == "ImportGeom"){
    ImportGeom(event.item)
   } else {
    selectMapAction(event.item)
   }
    
  }

  const optionsArea = [
    {
      key: 'SiarlGeoPortalAdicionarArea',
      text: 'Desenhar Área',
    },
    {
      key: 'SiarlGeoPortalAdicionarArea',
      text: 'Editar Área',
    },
    {
      key: 'removerArea',
      text: 'Remover Área',
    },
    {
      key: 'ImportGeom',
      text: 'Importar Geom',
    },
  ]

  const onItemClickImprimir = async (event: any) => {

    let mapImage = ''

    if (!window.location.href.includes('localhost')) {
      const mapIframe = document.querySelector(
        '#iframeGeoPortal'
      ) as HTMLIFrameElement

      const mapIframeInnerContent =
        mapIframe.contentWindow?.document.querySelector(
          'section.content'
        ) as HTMLElement

      const generatedImage = await html2canvas(mapIframeInnerContent)
      mapImage = generatedImage.toDataURL()
    }

    console.log(selectedMobilizacaoResult);

    try {
      postMessage({ type: 'showLoader' }, '*')

      const objToSendImgMapa : any = {
        CodPrintTemplate: "RELATORIO_INT_MOBILIZACAO",
        extendInfo: {
          spatialReference: {
            wkid: 0
          },
          xmin: 0,
          ymin: 0,
          xmax: 0,
          ymax: 0
        },
        ListOperationalLayerInfo: [
          {
            IdOperationLayer: OPERATIONAL_LAYER,
            UseLayerExtent: false,
            DefinitionExpression: "",
            ListServiceLayer: [
              {
                  LayerId: LAYER_INDEX_INT_MOBILIZACAO,
                  DefinitionExpression: "id=" + selectedMobilizacaoResult?.id,
                  UseLayerExtent: true
              }
            ]
          }
        ]
      }

      const documentoBase64 = await relatorioMobilizacao({
        id: selectedMobilizacaoResult?.id,
        dados: {
          //identificacao
          'Nº Processo APA:': selectedMobilizacaoResult?.filedoc_cod ?? '',
          'Técnico:': selectedMobilizacaoResult?.tecnico ?? '',

          //localizacao
          'Nuts1:': selectedMobilizacaoResult?.nuts1[0]?.nome ?? '',
          'Nuts2:': selectedMobilizacaoResult?.nuts2[0]?.nome ?? '',
          'Nuts3:': selectedMobilizacaoResult?.nuts3[0]?.nome ?? '',
          'Freguesias:': selectedMobilizacaoResult?.freguesias[0]?.nome ?? '',
          'Concelhos:': selectedMobilizacaoResult?.concelhos[0]?.nome ?? '',
          'Distritos:': selectedMobilizacaoResult?.distritos[0]?.nome ?? '',

          //data
          'Data de registo:': selectedMobilizacaoResult?.data_registo ? new Date(selectedMobilizacaoResult?.data_registo).toLocaleDateString() : '',
          'Data da mobilização:': selectedMobilizacaoResult?.data_mobilizacao ? new Date(selectedMobilizacaoResult?.data_mobilizacao).toLocaleDateString() :'',

          //caracterizacao
          'Função:': selectedMobilizacaoResult?.int_mobilizacao_funcao?.descricao ?? '',
          'Tipologia:': selectedMobilizacaoResult?.tipo_mob_metodo_deposicao?.descricao ?? '',

          //tipo de mancha de emprestimo
          'Mancha empréstimo nível 1:': selectedMobilizacaoResult?.intMobMancEmprestimoNivel1?.descricao ?? '',
          'Mancha empréstimo nível 2:': selectedMobilizacaoResult?.intMobMancEmprestimoNivel2?.descricao ?? '',

          //tipo metodo de dragagem
          'Método de dragagem:': selectedMobilizacaoResult?.mobMetodoDragagem?.descricao ?? '',

          // //tipo de local de deposição
          'Local de deposição nível 1:': selectedMobilizacaoResult?.intMobDescritivoLocalDeposicaoNivel1?.descricao ?? '',
          'Local de deposição nível 2:': selectedMobilizacaoResult?.intMobDescritivoLocalDeposicaoNivel2?.descricao ?? '',

          //metodo deposição
          'Método de deposição:': selectedMobilizacaoResult?.mobMetodoDeposicao?.descricao ?? '',

          //objetico deposição
          'Objetivo da deposição nivel 1:': selectedMobilizacaoResult?.intMobObjetivoDeposicaoNivel1?.descricao ?? '',
          'Objetivo da deposição nivel 2:': selectedMobilizacaoResult?.intMobObjetivoDeposicaoNivel2?.descricao ?? '',

          // //qualidade dos sedimentos
          'Qualidade dos sedimentos:': selectedMobilizacaoResult?.mobQualidade?.descricao ?? '',

          //granulametria dos sedimentos
          'Granulometria:': selectedMobilizacaoResult?.mobGranulometria?.descricao ?? '',

          //estado
          'Estado:': selectedMobilizacaoResult?.int_mobilizacao_estado?.descricao ?? '',

          //entidade
          'Entidade:': selectedMobilizacaoResult?.entidade?.nome ?? '',

          //volume
          'Volume:': selectedMobilizacaoResult?.volume?.toString() ?? '',
          // 'Descrição': selectedMobilizacaoResult?.descricao ?? '',
 
          // 'td_mob_qualidade': selectedMobilizacaoResult?.td_mob_qualidade?.descricao ?? '',
          // 'td_mob_granulometria': selectedMobilizacaoResult?.td_mob_granulometria?.descricao ?? '',
        },        
        img_mapa: "",
        objJsonToCreateImg: objToSendImgMapa
      });

      if (documentoBase64) {
        const blob = b64toBlob(documentoBase64, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
        postMessage({ type: 'hideLoader' }, '*')
      }
    } catch (error) {
      postMessage({ type: 'errorMsg', value: { message: "Não foi possivel efetuar esta operação" } }, '*')
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
    
  }

  const optionsImprimir = [
    {
      key: 'imprimirFichas',
      text: 'Fichas',
    },
    {
      key: 'imprimirResultados',
      text: 'Resultados',
    },
  ]

  const onItemClickExportar = async (event: any) => {

    let mapImage = ''

    if (!window.location.href.includes('localhost')) {
      const mapIframe = document.querySelector(
        '#iframeGeoPortal'
      ) as HTMLIFrameElement

      const mapIframeInnerContent =
        mapIframe.contentWindow?.document.querySelector(
          'section.content'
        ) as HTMLElement

      const generatedImage = await html2canvas(mapIframeInnerContent)
      mapImage = generatedImage.toDataURL()
    }

    console.log(selectedMobilizacaoResult);

    try {
      postMessage({ type: 'showLoader' }, '*')

      const documentoBase64 = await relatorioMobilizacao({
        id: selectedMobilizacaoResult?.id,
        dados: {
          //identificacao
          'Nº Processo APA:': selectedMobilizacaoResult?.filedoc_cod ?? '',
          'Técnico:': selectedMobilizacaoResult?.tecnico ?? '',

          //localizacao
          'Nuts1:': selectedMobilizacaoResult?.nuts1[0]?.nome ?? '',
          'Nuts2:': selectedMobilizacaoResult?.nuts2[0]?.nome ?? '',
          'Nuts3:': selectedMobilizacaoResult?.nuts3[0]?.nome ?? '',
          'Freguesias:': selectedMobilizacaoResult?.freguesias[0]?.nome ?? '',
          'Concelhos:': selectedMobilizacaoResult?.concelhos[0]?.nome ?? '',
          'Distritos:': selectedMobilizacaoResult?.distritos[0]?.nome ?? '',

          //data
          'Data de registo:': selectedMobilizacaoResult?.data_registo ? new Date(selectedMobilizacaoResult?.data_registo).toLocaleDateString() : '',
          'Data da mobilização:': selectedMobilizacaoResult?.data_mobilizacao ? new Date(selectedMobilizacaoResult?.data_mobilizacao).toLocaleDateString() :'',

          //caracterizacao
          'Função:': selectedMobilizacaoResult?.int_mobilizacao_funcao?.descricao ?? '',
          'Tipologia:': selectedMobilizacaoResult?.tipo_mob_metodo_deposicao?.descricao ?? '',

          //tipo de mancha de emprestimo
          'Mancha empréstimo nível 1:': selectedMobilizacaoResult?.intMobMancEmprestimoNivel1?.descricao ?? '',
          'Mancha empréstimo nível 2:': selectedMobilizacaoResult?.intMobMancEmprestimoNivel2?.descricao ?? '',

          //tipo metodo de dragagem
          'Método de dragagem:': selectedMobilizacaoResult?.mobMetodoDragagem?.descricao ?? '',

          // //tipo de local de deposição
          'Local de deposição nível 1:': selectedMobilizacaoResult?.intMobDescritivoLocalDeposicaoNivel1?.descricao ?? '',
          'Local de deposição nível 2:': selectedMobilizacaoResult?.intMobDescritivoLocalDeposicaoNivel2?.descricao ?? '',

          //metodo deposição
          'Método de deposição:': selectedMobilizacaoResult?.mobMetodoDeposicao?.descricao ?? '',

          //objetico deposição
          'Objetivo da deposição nivel 1:': selectedMobilizacaoResult?.intMobObjetivoDeposicaoNivel1?.descricao ?? '',
          'Objetivo da deposição nivel 2:': selectedMobilizacaoResult?.intMobObjetivoDeposicaoNivel2?.descricao ?? '',

          // //qualidade dos sedimentos
          'Qualidade dos sedimentos:': selectedMobilizacaoResult?.mobQualidade?.descricao ?? '',

          //granulametria dos sedimentos
          'Granulometria:': selectedMobilizacaoResult?.mobGranulometria?.descricao ?? '',

          //estado
          'Estado:': selectedMobilizacaoResult?.int_mobilizacao_estado?.descricao ?? '',

          //entidade
          'Entidade:': selectedMobilizacaoResult?.entidade?.nome ?? '',

          //volume
          'Volume:': selectedMobilizacaoResult?.volume?.toString() ?? '',
          // 'Descrição': selectedMobilizacaoResult?.descricao ?? '',
 
          // 'td_mob_qualidade': selectedMobilizacaoResult?.td_mob_qualidade?.descricao ?? '',
          // 'td_mob_granulometria': selectedMobilizacaoResult?.td_mob_granulometria?.descricao ?? '',
        },        
        img_mapa: mapImage
      });

      if (documentoBase64) {
        const link = document.createElement('a')
        link.href = 'data:application/octet-stream;base64,' + documentoBase64
        link.download = 'reporte_mobilizacao.pdf'
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        postMessage({ type: 'hideLoader' }, '*')
      }
    } catch (error) {
      postMessage({ type: 'errorMsg', value: { message: "Não foi possivel efetuar esta operação" } }, '*')
    } finally {
      postMessage({ type: 'hideLoader' }, '*')
    }
   
  }

  const optionsExportar = [
    {
      key: 'exportarFichas',
      text: 'Fichas',
    },
    {
      key: 'exportarResultados',
      text: 'Resultados',
    },
    
  ]

    const importarGeometria = [
    {
      key: 'ImportGeom',
      text: 'Importar Geom',
    },
  ]

   const ImportGeom = async (item: any) => {
    if (item.key == 'ImportGeom') {
      setOpenImportModal(true)
    }
  }

    const cancelImport = () => {
    setOpenImportModal(false)
  }

  const optionsEditar = [
    {
      key: 'SiarlEditar',
      text: 'Editar',
    },
    {
      key: 'SiarlApagar',
      text: 'Apagar',
    },
    
  ]

  const onEditItemClick= (event:any) => {
    if (event == "SiarlEditar" || event?.item?.key == "SiarlEditar") {
      setEditMode(true);
      setViewMode(false);
      return;
    }
    if (event == "SiarlVisualizar") {
      setEditMode(false);
      setViewMode(true);
    }
    if (event?.item?.key == "SiarlApagar") {
      setDeleteClicked(true);
      setOpenModalDescartar(true);
    }
  }
  useEffect(() => {
    if (viewMode) {
      sessionStorage.removeItem("novaMobilizacao");
    }
  })


  const onImportGeomClick = (event: any) => {
    ImportGeom(event.item)
  }
  const handleSelect = (e: TabStripSelectEventArguments) => {
    if (e.selected > 1 && !selectedMobilizacaoResult) {
      postMessage({type:"infoMsg", value: {message: "É necessário gravar primeiro."}})
      return;
    }
    setSelected(e.selected)
  }

  const isFormValid =
    (selected === 0) ||
    (selected === 1) ||
    (selected === 2) ||
    (selected === 3)

    
  const onClickOpenFileDoc = async() => {
    const mobilizacaoSelected = getMobilizacaoSelected()
    if (!mobilizacaoSelected?.filedoc_cod) {
      postMessage({ type: "infoMsg", value: {message: "É necessário preencher o nº processo APA (filedoc)."} }, "*");
      return;
    }
    postMessage({ type: 'showLoader' }, '*');
    const urlFileDoc = await getUrlFileDoc(mobilizacaoSelected?.filedoc_cod);
    postMessage({ type: 'hideLoader' }, '*')
    window.open(urlFileDoc, '_blank');
    return;
  }

  useLayoutEffect(() => {

    const handleResize = () => {

      if (window.innerWidth <= telaMobile) {
        
        setIsMobile(true);
        ocultaFloatingMenu_e_Mapa();

      }else{

        setIsMobile(false);
        mostraFloatingMenu_e_Mapa();

      }

    };
  
    window.addEventListener('resize', handleResize);
  
    handleResize();
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []); 

  const onItemClickMais = (event: any) => {

    elementoClickMais(event);

    onEditItemClick(event);
    onImportGeomClick(event);
    onItemClick(event);
    onItemClickExportar(event);

  };

  const optionsMais = [
    {
      key: 'titulo',
      text: 'Registo',
    },
    ...optionsEditar.map(option => ({
      ...option,
      className: 'icon-siarl icon-editar btnIconRegistar',
    })),
    {
      key: 'titulo',
      text: 'Opções',
    },
    {
      key: 'openFileDoc',
      text: 'Diretório de documentos',
      className: 'icon-siarl icon-filedoc btnIconRegistar'
    }, 
    {
      key: 'titulo',
      text: 'Geometria',
    },
    {
      key: 'titulo',
      text: 'Área',
    },
    ...optionsArea.map(option => ({
      ...option,
      className: 'icon-siarl icon-area btnIconRegistar',
    })),
    {
      key: 'titulo',
      text: 'Imprimir',
    },
    {
      key: 'imprimirFicha',
      text: 'Fichas',
      className: 'icon-siarl icon-imprimir btnIconRegistar'
    },
    {
      key: 'titulo',
      text: 'Exportar',
    },
    ...optionsExportar.map(option => ({
      ...option,
      className: 'icon-siarl icon-exportar btnIconRegistar',
    })),
  ]

  const openAjuda = () => {
    window.open(URL_AJUDA_INTERVENCOES_MOBILIZACAO, "_blank")
  }

  const canSeeMobilizacao = hasPermission(PERMISSION_INTERVENCAO_PERFIL_BASICO) ||
  hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_EDICAO) ||
  hasPermission(PERMISSION_INTERVENCAO_PERFIL_COMPLETO_LEITURA) ||
  hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_EDICAO) ||
  hasPermission(PERMISSION_INTERVENCAO_PERFIL_INTERMEDIO_LEITURA) ||
  hasPermission(PERMISSION_INTERVENCAO_PERFIL_TOTAL);

  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <div>
          <div className={styles.nomeDoModulo}>Intervenções</div>
          <div className={styles.tituloDaPagina}>
          {!selectedMobilizacaoResult || !selectedMobilizacaoResult?.id ? 'Nova mobilização' : (editMode ? 'Editar mobilização' : 'Mobilização')}
          </div>
        </div>
        <div>

          {!isMobile && canUpdate && (viewMode || editMode) && (sessionStorage.getItem("novaMobilizacao") != 'true') ? 
          <DropDownButton
            items={optionsEditar}
            className={styles.btnEditar2}
            iconClass="icon-siarl icon-editar"
            onItemClick={onEditItemClick}
            fillMode={'flat'}
            itemRender={itemRender}
            title="Editar"
          ></DropDownButton>
          : ''}

          <button className={styles.btnEditar2} title='Filedoc' onClick={onClickOpenFileDoc}
          style={{ display: isMobile ? 'none' : '' }} id='openFileDoc'>
            <span className="icon-siarl icon-filedoc"></span>
          </button>

        {/* {!isMobile &&
          <DropDownButton
            items={optionsArea}
            className={styles.btnEditar2}
            iconClass="icon-siarl icon-area"
            onItemClick={onItemClick}
            fillMode={'flat'}
            title='Geometria - Área'
            itemRender={itemRender}
            disabled={viewMode}
          />
          } */}


          <button className={styles.btnEditar2} title='Imprimir' onClick={onItemClickImprimir}
           style={{ display: isMobile ? 'none' : '' }} id='imprimirFicha'>
            <span className="icon-siarl icon-imprimir"></span>
          </button>

          <button className={styles.btnImprimir} title='Exportar' onClick={onItemClickExportar}
           style={{ display: isMobile ? 'none' : '' }} id='exportarFicha'>
            <span className="icon-siarl icon-exportar"></span>
          </button>

        

        {isMobile &&
            <DropDownButton
              items={optionsMais}
              className={styles.btnMais}
              iconClass="icon-siarl icon-mais"
              onItemClick={onItemClickMais}
              fillMode={'flat'}
              itemRender={itemRenderMais}
              title="Mais Opções"
            />
          }

          <button onClick={openAjuda} className={styles.btnInfo} title="Obter Ajuda">
            <span className="icon-siarl icon-ajuda" />
          </button>

          <button className={styles.btnFechar} onClick={voltar} title="Fechar">
            <span className="icon-siarl icon-fechar"></span>
          </button>
        </div>
      </div>

      {
        openImportModal ?
          <ImportarGeometriaComponent onSubmit={importGeo} cancelImport={cancelImport} /> : <></>
      }

{openRemoveAreaModal ? (
               <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={() => setOpenRemoveAreaModal(false)}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                    <span className="textDescartar">Remover área </span>
                    <br></br>
                    <span>{'Tem a certeza que pretende remover a área selecionada?'}</span>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={() => setOpenRemoveAreaModal(false)}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={removeArea}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}

       {openModalDescartar ? (
          <Dialog
            
            className={styles.dialogDesconformidades}
            onClose={cancelModalDescartar}
          >
            
            <Form
              initialValues={
                {
                } 
              }
              render={(formRenderProps) => (
                <FormElement style={{ maxWidth: 500 }}>
                  <fieldset>
                    <span className="textDescartar">{deleteClicked ? 'Apagar' : 'Cancelar'} registo  </span>
                    <br></br>
                    <span>{deleteClicked ? 'Ao apagar este registo deixará de ter acesso, sendo necessária uma intervenção rigorosa para realizar a sua recuperação.' : 'Ao cancelar este registo irá perder os dados inseridos até ao momento.'}</span>
                      <br></br>
                    <span>{deleteClicked ? 'Tem a certeza que pretende apagar?' : 'Tem a certeza que pretende cancelar?'}</span>
                  </fieldset>
                  <div className="k-form-buttons btnModal">         
                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base capitalizeText btnWhite"
                      onClick={cancelModalDescartar}
                    >
                      Não
                    </button>

                    <button
                      type={'submit'}
                      className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary btnImportar capitalizeText"
                      onClick={handleDescartar}
                    >
                      Sim
                    </button>
                  </div>
                </FormElement>
              )}
            />
          </Dialog>
        ) : (
          <></>
        )}

      <Form
        initialValues={
          { ...selectedMobilizacaoResult, status: true, data_registo: selectedMobilizacaoResult?.id ? new Date(selectedMobilizacaoResult?.data_registo) : new Date(), ...getMobilizacaoSelected() } ??
          undefined
        }
        onSubmitClick={handleSubmit}
        render={(formRenderProps) => (
          <FormElement>
            <TabStrip
              selected={selected}
              onSelect={handleSelect} 
              className={`${styles.innerStyleTabContent} ${viewMode? 'tabsVisualizar' : ''}`}
              scrollable={isMobile}
            >
                <TabStripTab title="Identificação" key={'Identificação'}>
                <IdentificaoMobilizacao onCloseClick={onCloseClick} viewMode={viewMode} formRenderProps={formRenderProps} />
              </TabStripTab>
              <TabStripTab title="Ação" key={'Ação'} >
                <AcaoMobilizacao formRenderProps={formRenderProps} viewMode={viewMode} />
              </TabStripTab>
              <TabStripTab title="Documentos"  key={'Documentos'}>
                <DocumentoListagem pageName="mobilizacao" viewMode={viewMode}/>
              </TabStripTab>
              <TabStripTab title="Associação" key={'Associação'}>
                <AssociacaoListagem pageName="mobilizacao" viewMode={viewMode} />
              </TabStripTab>
            </TabStrip>

                {(editMode || sessionStorage.getItem("novaMobilizacao") == 'true')
                            && !viewMode ?
                  <div
                    className={`${styles.footer} ${styles.justify_multiple_children}`}
                  >

                  <Button type="button" className={styles.btnSeguinte} onClick={handleOpenModalDescartar} title='Cancelar'>
                  <span className="icon-siarl icon-fechar"></span>
                  <span className={styles.rotulo}>
                    {' '}&nbsp;{' '}
                    Cancelar
                  </span>
                 </Button>    

                     <Button
                      className={styles.btnSubmeter}
                       onClick={(e) => {
                    formRenderProps.onSubmit(e);
                    e.preventDefault();
                    SessionStorageService.setItem('mobilizacaoSubmetida', "true")

                  }}
                  title='Gravar'
                    >
                      <span className="icon-siarl icon-submeter btnIconRegistar"></span>
                      <span className={styles.rotulo}>
                        {' '}&nbsp;{' '}
                        Gravar
                      </span>
                    </Button>
                  </div>
            : ''}
          </FormElement>
        )}
      />
    </div>
  )
}
