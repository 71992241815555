import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import styles from '../../styles/usodosolo/UsoDoSolo.module.scss'

type HoverState = 'idle' | 'enter' | 'leave'

type Props = {
  
    onDetailsClick?: (documentos: Record<string, unknown>) => () => void
    onMapCenter: (documentos: Record<string, unknown>) => () => void
  dataItem: Record<string, unknown>
}

export const ResultadoValorUsoDoSoloCommandGridCell = ({
    onDetailsClick,
  onMapCenter,
  dataItem,
}: Props) => {



  return (
    <td className="k-command-cell">
      <div className={styles.gridCommand}>
      <div
          role="button"
          className={styles.gridCommandIconButton}
          onClick={onMapCenter(dataItem)}
        >
          <FontAwesomeIcon icon={faLocationDot} title="Mostrar no Mapa"/>
        </div> 
        {onDetailsClick? <>
          <div
          role="button"
          className={styles.gridCommandIconButton}
          onClick={onDetailsClick(dataItem)}
        >
          <FontAwesomeIcon icon={faInfoCircle} title="Detalhes de registo"/>
        </div> </>:<></>}
             
      </div>
    </td>
  )
}
